namespace app.analysis {
   export function dashboardResolver(datacontext: data.IDataContextService) {
      'ngInject';
      return datacontext.analysis.getMyDashboard();
   }

   export function dataSourcesResolver(datacontext: data.IDataContextService) {
      'ngInject';
      return datacontext.analysis.getDataSources();
   }

   export function chartsResolver(datacontext: data.IDataContextService, $stateParams: angular.ui.IStateParamsService) {
      'ngInject';
      const { queryId } = $stateParams;
      return datacontext.analysis.getChartListByQueryId(queryId);
   }

   export function queryResolver(datacontext: data.IDataContextService, $stateParams: angular.ui.IStateParamsService) {
      'ngInject';
      const { queryId } = $stateParams;
      return datacontext.analysis.getQuery(queryId);
   }
}