namespace app.admin.identityManagement {
   export const permissionsRosolver = (datacontext: data.IDataContextService) => {
      'ngInject';
      return datacontext.admin.identityManagement.getPermissions();
   };

   export const roleResolver = (datacontext: data.IDataContextService, $stateParams: angular.ui.IStateParamsService) => {
      'ngInject';
      const { roleId } = $stateParams;
      return datacontext.admin.identityManagement.getRole(roleId);
   };

   export const rolesResolver = (datacontext: data.IDataContextService) => {
      'ngInject';
      return datacontext.admin.identityManagement.getRoles();
   };

   export const userResolver = (datacontext: data.IDataContextService, $stateParams: angular.ui.IStateParamsService) => {
      'ngInject';
      const { userId } = $stateParams;
      return datacontext.admin.identityManagement.getUser(userId);
   };
}