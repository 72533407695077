namespace app.utils {
   const MASK = '(999) 999-9999';

   @Component('app.utils', 'cePhoneInput', {
      template: `
<input name="$ctrl.inputName"
       ng-change="$ctrl.handleChange()"
       ng-model="$ctrl.inputValue"
       ng-model-options="{updateOn: 'blur'}"
       ui-mask="{{$ctrl.mask}}"
       ng-focus="$ctrl.enableMask()"
       ng-required="$ctrl.required" />`,
      bindings: {
         inputName: '@',
         required: '@',
         value: '='
      }
   })
   class PhoneInput {
      public mask: string;
      public inputValue: string;
      public value: string;

      public enableMask() {
         this.mask = MASK;
      }

      public disableMask() {
         this.mask = '';
         this.inputValue = null;
      }

      public handleChange() {
         if (this.inputValue && this.inputValue.match(/^\d{10}$/g)) {
            this.value = this.inputValue ? '+1' + this.inputValue : null;
         } else {
            this.value = null;
            this.disableMask();
         }
      }

      private $onChanges() {
         if (this.value) {
            this.inputValue = this.value.replace(/^\+1/, '');
         }
      }
   }
}