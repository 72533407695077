namespace app.persons.details {
   @Component('app.persons.details', 'cePersonSpecialConsiderations', {
      templateUrl: 'app/persons/details/special-considerations/special-considerations.html',
      bindings: {
         person: '<',
         onEdit: '&',
      }
   })
   class PersonSpecialConsiderations {
      static $inject = ['common', 'datacontext'];
      constructor(
         private common: core.ICommonService,
         private datacontext: data.IDataContextService) {
         'ngInject';
      }
      public isLoading: boolean = false;
      public editMode: boolean = false;
      public person: IPersonBasicInfo;
      public data = {} as ISpecialConsiderations;
      public isSubmitting: boolean = false;
      public onSubmit: (args: { result: ISpecialConsiderations }) => void;

      public $onInit() {
         this.getData();
      }

      public cancelEdit() {
         this.editMode = false;
      }

      public editData(data: ISpecialConsiderations) {
         this.editMode = true;
      }

      public getData() {
         this.isLoading = true;

         return this.datacontext.persons
            .getSpecialConsiderations(this.person.id)
            .then(result => {
               this.data = result;
               this.isLoading = false;
            });
      }

      public saveData(data: ISpecialConsiderations) {
         this.isSubmitting = true;
         this.datacontext.persons
            .updateSpecialConsiderations(this.person.id, data)
            .then((result) => {
               this.data = result;
               angular.extend(this.person, result);
               this.isSubmitting = false;
               this.cancelEdit();
            });
      }
   }
}