namespace app.agencies.details {
   @Component('app.agencies.details', 'ceAgencyContactInfo', {
      templateUrl: 'app/agencies/details/contact-info/contact-info.html',
      bindings: {
         agency: '<',
      }
   })
   class AgencyContactInfo {
      public agency: IAgencyBasicInfo;
   }
}