namespace app.analysis {
   @Component('app.analysis', 'aimsAnalysisColumnChart', {
      template: '<flot dataset="$ctrl.dataset" options="$ctrl.options"></flot>',
      bindings: {
         chartData: '<',
         showPercentages: '<',
         showTotal: '<'
      }
   })
   class ColumnChartComponent {
      static $inject = ['common', 'analysisConstants', 'analysisData'];
      constructor(
         private common: core.ICommonService,
         private analysisConstants: IAnalysisConstants,
         private analysisData: IAnalysisDataService) {
         'ngInject';
      }

      public chartData: IChartData[];
      public dataset: any[];
      public dataSeries: Series;
      public percentSeries: Series;
      public options = {
         series: {
            bars: {
               align: 'center',
               barWidth: 0.6,
               show: true,
               lineWidth: 0,
               fill: 0.9
            }
         },
         grid: {
            borderColor: '#eee',
            borderWidth: 1,
            hoverable: true,
            backgroundColor: '#fcfcfc',
            labelMargin: 25
         },
         tooltip: true,
         tooltipOpts: {
            content: (label, x, y, item) => {
               let tooltip = `${item.series.xaxis.ticks[x].label} : ${this.dataSeries[x][1].toFixed(0)}`;
               if (this.showPercentages) {
                  tooltip += `, ${this.percentSeries[x][1].toFixed(0)}%`;
               }
               return tooltip;
            },
            zIndex: 2000
         },
         xaxis: {
            tickColor: '#fcfcfc',
            autoscaleMargin: .1,
            ticks: null
         }
         ,
         yaxis: {
            tickColor: '#eee',
            tickDecimals: 0
         }
      };
      public showPercentages: boolean;
      public showTotal: boolean;
      public total: number;

      private $onChanges() {
         if (!this.chartData) return;

         const color = this.analysisConstants.colorPalette[0];
         this.dataSeries = this.analysisData.columnSeries(this.chartData);
         const ticks = this.analysisData.ticks(this.chartData);

         this.percentSeries = this.showPercentages
            ? this.analysisData.columnPercentageSeries(this.chartData)
            : null;

         if (this.showTotal) {
            this.total = this.analysisData.sum(this.chartData);
            this.dataSeries.push([this.dataSeries.length, this.total]);
            ticks.push([ticks.length, 'Total']);

            if (this.showPercentages) {
               this.percentSeries.push([this.percentSeries.length, 100]);
            }
         }

         const dataset = [{ color, data: this.percentSeries || this.dataSeries }];
         this.common.$timeout(() => {
            this.dataset = dataset;
            this.options.xaxis.ticks = ticks;
         }, 50);
      }
   }
}