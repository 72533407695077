namespace app.activities {
   @Component('app.activities', 'ceActivityListItem', {
      templateUrl: 'app/activities/activity-list/activity-list-item.html',
      bindings: {
         activity: '<',
         onClick: '&'
      }
   })
   class ActivityListItemComponent {
   }
}