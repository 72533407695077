namespace app.filters {
   @Component('app.filters', 'ceActivityTypeFilter', {
      templateUrl: 'app/filters/activityType/activityType-filter.html',
      bindings: {
         onChange: '&'
      }
   })
   class ActivityTypeFilter {
      static $inject = ['common', 'datacontext'];
      constructor(
         private common: core.ICommonService,
         private datacontext: data.IDataContextService) {
         'ngInject';
      }

      public activityTypes: ICheckableItem<string>[] = [
         { item: 'CaseAssessmentMeeting' },
         { item: 'CaseClosed' },
         { item: 'CaseCreated' },
         { item: 'CaseReopened' },
         { item: 'CaseReview' },
         { item: 'ConflictCheck' },
         { item: 'FollowUp' },
         { item: 'Note' },
      ];

      private onChange: ({ }) => {};
      private enabled: boolean;

      private $onInit() {
         this.changed();
      }

      private toggleState() {
         this.enabled = !this.enabled;

         if (this.enabled) {
            angular.element('#activityType0').focus();
         }

         this.changed();
      }

      private toggleType(activityType: ICheckableItem<string>) {
         activityType.isChecked = !activityType.isChecked;
         this.changed();
      }

      public changed() {
         if (this.enabled) {
            const activityTypes = _(this.activityTypes).filter(x => x.isChecked).map(x => x.item).value();
            this.onChange({ activityTypes: activityTypes });
         }
         else {
            this.onChange({ activityTypes: [] });
         }
      }
   }
}