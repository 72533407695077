namespace app.funding {
   @Component('app.funding', 'cePatbiSystemicOutcomeInfo', {
      templateUrl: 'app/funding/enrollments/patbi/systemic/patbi-outcome-info.html',
      bindings: {
         outcome: '<',
         onEdit: '&'
      }
   })
   class PATBISystemicOutcomeInfo {
      static $inject = ['common', 'datacontext'];
      constructor(
         private common: core.ICommonService,
         private datacontext: data.IDataContextService) {
         'ngInject';
      }

      public outcome: IPATBISystemicOutcome;
      public isInvestigation: boolean;

      public $onChanges() {
         this.checkInvestigation();
      }

      public checkInvestigation() {
         _.forEach(this.outcome.interventionStrategies, (interventionStrategy: any) => { if (interventionStrategy.contains('Investigation')) this.isInvestigation = true; });
      }

   }
}