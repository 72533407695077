namespace app.monitoring.details {
   @Component('app.monitoring.details', 'ceMonitoringDetails', {
      templateUrl: 'app/monitoring/details/details.html',
      bindings: {
         monitoring: '<',
         bypassCensus: '<'
      }
   })
   class MonitoringDetails {
      static $inject = ['$mdDialog', 'common', 'datacontext'];
      constructor(
         private $mdDialog: angular.material.IDialogService,
         private common: core.ICommonService,
         private datacontext: data.IDataContextService) {
         'ngInject';
      }

      private monitoring: IMonitoringBasicInfoView;
      private monitoringInformation: IMonitoringInformation;
      private clientInformation: persons.IDemographics;
      private editMonitoringInformation: boolean = false;
      private editClientInformation: boolean = false;
      public bypassCensus: boolean;

      public $onInit() {
         let promise = null;
         promise = this.getMonitoring();
         promise.then(
            () => {
               this.GetMonitoringInformation();
               this.GetClientInformation();
            });
      }

      public getMonitoring() {
         return this.datacontext.monitoring.getMonitoringBasicInfoView(this.monitoring.id)
            .then(cc => this.monitoring = cc);
      }

      public updateMonitoringInformation(result: IMonitoringInformation) {
         this.monitoringInformation = result;
         this.editMonitoringInformation = false;
      }

      public updateClientInformation(result: persons.IDemographics) {
         this.clientInformation = result;
         this.editClientInformation = false;
      }

      public GetMonitoringInformation() {
         this.datacontext.monitoring.getMonitoringInformation(this.monitoring.id).then(
            result => this.monitoringInformation = result
         );
      }

      public GetClientInformation() {
         if (this.monitoring.clientPersonId) {
            this.datacontext.persons.getDemographics(this.monitoring.clientPersonId).then(
               result => this.clientInformation = result
            );
         }
      }

   }
}