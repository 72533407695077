namespace app.entities {
   @Component('app.entities', 'ceEntityTypes', {
      templateUrl: 'app/entities/select-type/entity-types.html',
   })
   class SelectEntityType {
      static $inject = ['common'];
      constructor(
         private common: core.ICommonService) {
         'ngInject';
      }
   }
}