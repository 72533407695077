namespace app.calendars {
   export interface ICalendarRepository {
      getCalendars(): angular.IPromise<ICalendar[]>;
      getEvents(params: IGetEventsParams): angular.IPromise<IEvent[]>;
      getEventSchedule(id: string): angular.IPromise<IEventSchedule>;
      getEventSummary(event: IEventSummary): angular.IPromise<IEventSummary>;
      rescheduleEvent(event: IRescheduleEvent): angular.IPromise<IRescheduleEvent>;
      createEventSchedule(eventSchedule: IEventSchedule): angular.IPromise<IEventSchedule>;
      updateEventSchedule(eventSchedule: IEventSchedule): angular.IPromise<IEventSchedule>;
      updateEventScheduleStaff(eventScheduleId: string, staffIds: string[]): angular.IPromise<void>;
      getEventScheduleStaff(eventScheduleId: string): angular.IPromise<IUserBasic[]>;
      updateEventScheduleSources(eventScheduleId: string, sources: common.ISource[]): angular.IPromise<void>;
      getEventScheduleSources(eventScheduleId: string): angular.IPromise<ISourceBasic[]>;
      deleteEvent(event: IDeleteEvent): angular.IPromise<boolean>;
      deleteEventSchedule(eventScheduleId: string): angular.IPromise<boolean>;
      getNext10Events(userIds: string[]): angular.IPromise<any>;
   }

   const baseUrl = 'api/calendars';

   class CalendarRepository implements ICalendarRepository {
      constructor(
         private $http: angular.IHttpService,
         private exception: core.exception.IExceptionService) {
         'ngInject';
      }

      @ApiExecutor()
      public getCalendars() {
         return this.$http.get<ICalendar[]>(baseUrl) as any;
      }

      @ApiExecutor()
      public getEvents(params: IGetEventsParams) {
         return this.$http.get<IEvent[]>(`${baseUrl}/events`, { params }) as any;
      }

      @ApiExecutor()
      public getEventSchedule(id: string) {
         return this.$http.get<IEventSchedule>(`${baseUrl}/eventschedules/${id}`) as any;
      }

      @ApiExecutor()
      public getEventSummary(event: IEventSummary) {
         let startTime = moment(event.startTime).format();
         let endTime = event.allDay ? null : moment(event.endTime).format();
         return this.$http.get<IEventSummary>(`${baseUrl}/events/${event.id}/summary?startTime=${startTime}&endTime=${endTime}&eventScheduleId=${event.eventScheduleId}`) as any;
      }

      @ApiExecutor()
      public getEventScheduleStaff(eventScheduleId: string) {
         return this.$http.get<IUserBasic[]>(`${baseUrl}/eventschedules/${eventScheduleId}/staff`) as any;
      }

      @ApiExecutor()
      public getEventScheduleSources(eventScheduleId: string) {
         return this.$http.get<ISourceBasic[]>(`${baseUrl}/eventschedules/${eventScheduleId}/sources`) as any;
      }

      @ApiExecutor()
      public rescheduleEvent(event: IRescheduleEvent) {
         return this.$http.put<IRescheduleEvent>(`${baseUrl}/events/${event.id}/reschedule`, event) as any;
      }

      @ApiExecutor()
      public createEventSchedule(eventSchedule: IEventSchedule) {
         return this.$http.post<IEventSchedule>(`${baseUrl}/eventschedules`, eventSchedule) as any;
      }

      @ApiExecutor()
      public updateEventSchedule(eventSchedule: IEventSchedule) {
         return this.$http.put<IEventSchedule>(`${baseUrl}/eventschedules/${eventSchedule.id}`, eventSchedule) as any;
      }

      @ApiExecutor()
      public updateEventScheduleStaff(eventScheduleId: string, staffIds: string[]) {
         return this.$http.put<IEventSchedule>(`${baseUrl}/eventschedules/${eventScheduleId}/staff`, { eventScheduleId, staffIds }) as any;
      }

      @ApiExecutor()
      public updateEventScheduleSources(eventScheduleId: string, sources: common.ISource[]) {
         return this.$http.put<IEventSchedule>(`${baseUrl}/eventschedules/${eventScheduleId}/sources`, { eventScheduleId, sources }) as any;
      }

      @ApiExecutor()
      public deleteEvent(event: IDeleteEvent) {
         return this.$http.put<boolean>(`${baseUrl}/events/${event.id}/delete`, event) as any;
      }

      @ApiExecutor()
      public deleteEventSchedule(eventScheduleId: string) {
         return this.$http.delete<boolean>(`${baseUrl}/eventschedules/${eventScheduleId}`) as any;
      }

      @ApiExecutor()
      public getNext10Events(userIds: string[]) {
         return this.$http.get<any>(`${baseUrl}/events/next10Events`, { params: { userIds: userIds } }) as any;
      }
   }

   angular
      .module('app.calendars')
      .service('calendars.repository', CalendarRepository);
}