namespace app.analysis {
   @Controller('app.analysis', 'AnalysisRouteController')
   class AnalysisRouteController {
      static $inject = ['$scope', 'common'];
      constructor(
         private $scope: angular.IScope,
         private common: core.ICommonService) {
         'ngInject';
      }

      private $onInit() {
         const route = this.common.$stateParams['route'];
         switch (route) {
            case 'reports':
               this.common.$state.go('app.analysis.reports');
               break;
            case 'queries':
               this.common.$state.go('app.analysis.queries');
               break;
            default:
               this.common.$state.go('app.analysis.dashboard');
               break;
         }
      }
   }
}