namespace app.admin.tenancy {
   const routes: core.routing.IStateExtended[] = [
      {
         name: 'app.admin.tenancy',
         url: '/tenancy',
         template: '<ui-view layout="column" flex></ui-view>',
         data: { roles: ['Administrator'] },
         redirectTo: 'app.admin.tenancy.email'
      },
      {
         name: 'app.admin.tenancy.email',
         url: '/email',
         template: '<am-tenancy-email></am-tenancy-email>',
         data: { roles: ['Administrator'] }
      }
   ];

   const runner = (routingHelper: core.routing.IRoutingHelperService) => {
      'ngInject';
      routingHelper.configureRoutes(routes);
   };

   angular
      .module('app.admin.tenancy')
      .run(runner);
}