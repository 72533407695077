namespace app.requests.overview {
   @Component('app.requests.overview', 'ceRequestToDo', {
      templateUrl: 'app/requests/to-do-list/to-do-list.html',
      bindings: {
         request: '<'
      }
   })
   class RequestToDo {
      static $inject = ['$mdDialog', 'common', 'datacontext'];
      constructor(
         private $mdDialog: angular.material.IDialogService,
         private common: core.ICommonService,
         private datacontext: data.IDataContextService) {
         'ngInject';
      }

      public request: IRequestBasicInfo;
      public items: common.IToDoItem[];

      public $onInit() {
         this.datacontext.requests.getRequestToDo(this.request.id)
            .then(items => {
               this.items = items;
            });
      }
   }
}