namespace app.search {
   @Component('app.search', 'ceAdvancedInvolvementSearch', {
      templateUrl: 'app/search/advanced-search/involvements/advanced-involvement-search.html'
   })
   class AdvancedInvolvementSearch {
      static $inject = ['datacontext'];
      constructor(private datacontext: data.IDataContextService) {
         'ngInject';
      }

      public query: involvements.ISearchCasesParams = { pageSize: 10, order: 'OpenDate' };
      public cases: data.IPagedList<involvements.ICaseSearchView>;
      public isSubmitting: boolean;
      public promise: angular.IPromise<boolean>;

      public handlePaginate = (page: number, limit: number) => {
         this.query.page = page;
         this.search();
      };

      public handleReorder = (sortBy: string) => {
         this.search();
      };

      public search() {
         this.isSubmitting = true;
         this.promise = this.datacontext.involvements
            .searchCases(this.query)
            .then(result => {
               this.cases = result;
               return !!result;
            }).finally(() => this.isSubmitting = false);
         return this.promise;
      }
   }
}