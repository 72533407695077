namespace app.funding {
   @Component('app.funding', 'ceCapOutcomeForm', {
      templateUrl: 'app/funding/enrollments/cap/cap-outcome-form.html',
      bindings: {
         outcome: '<',
         onCancel: '&',
         onSubmit: '&'
      }
   })
   class CAPOutcomeForm {

      static $inject = ['datacontext'];
      constructor(
         private datacontext: data.IDataContextService) {
         'ngInject';
      }

      public interventionStrategies: app.config.ILookup[];
      public individualTypes: app.config.ILookup[];
      public outcome: ICAPOutcome;

      private $onInit() {
         this.getInterventionStrategies();
         this.getindividualTypes();
      }

      public getInterventionStrategies() {
         return this.datacontext.config.lookups.getLookupType('cap-intervention-strategies')
            .then(lookup => { this.interventionStrategies = lookup.lookups; });
      }

      public getindividualTypes() {
         return this.datacontext.config.lookups.getLookupType('cap-individual-types')
            .then(lookup => { this.individualTypes = lookup.lookups; });
      }

      public otherDisabilityToggle() {
         this.outcome.OtherAchievement = !this.outcome.OtherAchievement;
      }
   }
}