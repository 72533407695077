namespace app.admin.identityManagement {
   @Component('app.admin.identityManagement', 'ceAdminUserDetails', {
      templateUrl: 'app/admin/modules/identityManagement/views/user-details/user-details.html',
      bindings: {
         user: '<',
         roles: '<'
      }
   })
   class User {
      static $inject = ['common', 'datacontext'];
      constructor(
         private common: core.ICommonService,
         private datacontext: data.IDataContextService) {
         'ngInject';
      }

      public data: IUpdateUserData;
      public user: IUser;
      public roles: IRole[];
      public isSubmitting = false;

      public userSearchText: string;
      public users: users.IUser[] = [];

      public submit() {
         this.isSubmitting = true;
         if (this.data.userType !== 'Employee') {
            this.data.employeeType = null;
         }

         this.datacontext.admin.identityManagement
            .updateUser(this.user.id, this.data)
            .then(result => {
               if (result) {
                  this.common.$mdToast.showSimple('User updated');
                  this.common.$state.go('^');
               }
            })
            .finally(() => this.isSubmitting = false);
      }

      public hasRole(roleId: string) {
         return this.data.roleIds.indexOf(roleId) > -1;
      }

      public toggleRole(roleId: string) {
         const index = this.data.roleIds.indexOf(roleId);
         if (index > -1) {
            this.data.roleIds.splice(index, 1);
         }
         else {
            this.data.roleIds.push(roleId);
         }
      }

      private $onChanges() {
         this.common.setTitle(`${this.user.fullName} (User)`);
         this.initializeData();
      }

      private initializeData() {
         const { firstName, lastName, userName, userType, employeeType, isActive, timeslipTimerEnabled } = this.user;
         const roleIds = _.map(this.user.roles, r => r.id);

         this.data = {
            firstName,
            lastName,
            userName,
            userType,
            employeeType,
            isActive,
            timeslipTimerEnabled,
            roleIds
         };

         this.datacontext.entities
            .getRelatedEntities(this.user.id)
            .then(result => {
               result = _.filter(result, r => r.relatedEntityId === this.user.id);
               _.forEach(result, r => this.users.push({ id: r.entityId, fullName: r.fullName, firstName: '', lastName: '' }));
            });
      }

      public userSearch(searchText: string) {
         return this.datacontext.users
            .getUsers(searchText);
      }

      public addUser(user: users.IUser) {
         if (!user) return;

         this.userSearchText = null;
         const exists = _.find(this.users, u => u.id === user.id);
         if (!exists) {
            this.users.push(user);
            this.datacontext.entities.createAssociation({ entityId: user.id, relatedEntityId: this.user.id, roles: ['Supervisor'] });
         }
      }

      public removeUser(user: users.IUser) {
         _.remove(this.users, u => u.id === user.id);
         this.datacontext.entities.deleteAssociation(user.id, this.user.id);
      }
   }
}