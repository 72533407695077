namespace app.training.request {
   'use strict';

   const routes: core.routing.IStateExtended[] = [
      {
         name: 'app.createTrainingRequest',
         url: '/training-requests/create',
         template: '<ce-training-request-create layout="column" flex></ce-training-request-create>',
         data: {
            title: 'Create Request',
            permissions: ['training-requests.edit']
         }
      },
      {
         name: 'app.trainingRequest',
         url: '/training-requests/{requestId}',
         template: '<ce-training-request-view data="$resolve.request" layout="column" flex></ce-training-request-view>',
         redirectTo: 'app.trainingRequest.overview',
         data: { permissions: ['training-requests.view'] },
         resolve: { request: requestBasicInfoResolver }
      },
      {
         name: 'app.trainingRequest.overview',
         url: '/overview',
         template: '<ce-training-request-overview data="$resolve.request" layout="column" flex></ce-training-request-overview>',
         data: {
            title: 'Request Overview',
            permissions: ['training-requests.view']
         }
      },
      {
         name: 'app.trainingRequest.details',
         url: '/details',
         template: '<ce-training-request-details data="$resolve.request" layout="column" flex></ce-training-request-details>',
         data: {
            title: 'Request Details',
            permissions: ['training-requests.view']
         }
      },
   ];

   const runner = (routingHelper: core.routing.IRoutingHelperService) => {
      'ngInject';
      routingHelper.configureRoutes(routes);
   };

   angular
      .module('app.training.request')
      .run(runner);
}