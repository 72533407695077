namespace app.projects.activities {
   @Component('app.projects.activities', 'ceProjectsActivitiesNote', {
      templateUrl: 'app/projects/activities/note/note.html',
      bindings: {
         projectId: '<',
         activity: '<',
         caseOpenDate: '<'
      }
   })
   class Note {
      static $inject = ['common', 'datacontext', 'interrupt'];
      constructor(
         private common: core.ICommonService,
         private datacontext: data.IDataContextService,
         private interrupt: core.routing.IInterruptService) {
         'ngInject';
      }

      public projectId: string;
      public activity: app.activities.IActivity;

      public isSubmitting: boolean = false;
      public selectedStatus: string;
      public note = {} as ICreateProjectActivity;
      public maxDate: Date;

      private $onInit() {
         this.maxDate = moment().toDate();
         if (this.activity) {
            this.note.id = this.activity.id;
            this.note.projectId = this.activity.sourceId;
            this.note.title = this.activity.title;
            this.note.description = this.activity.description;
            this.note.activityDate = this.activity.activityDate;
         } else {
            this.note.projectId = this.projectId;
            this.note.activityDate = moment().toDate();
         }
      }

      public submit() {
         this.isSubmitting = true;

         if (typeof this.note.activityDate !== 'string') {
            this.note.activityDate = this.note.activityDate.toDateString();
         }

         const promise = this.note.id
            ? this.datacontext.projects.updateNote(this.note)
            : this.datacontext.projects.createNote(this.note);

         promise
            .then(note => {
               if (note) {
                  this.common.$mdToast.showSimple('Note ' + (this.note.id ? 'updated' : 'created'));
                  this.common.$state.go('^.list', { projectId: this.projectId });
               }
            })
            .finally(() => this.isSubmitting = false);
      }
   }
}