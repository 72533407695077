namespace app.training.request {
   @Component('app.training.request', 'ceTrainingRequestView', {
      templateUrl: 'app/training/request/view/request-view.html',
      bindings: {
         data: '<'
      }
   })
   class RequestInformation {
      static $inject = ['common', 'confirmation', 'datacontext'];
      constructor(
         private common: core.ICommonService,
         private confirmation: IConfirmationService,
         private datacontext: data.IDataContextService) {
         'ngInject';
         this.$state = common.$state;
      }

      public $state: angular.ui.IStateService;

      private $onInit() {
      }

      private showDeleteDialog(id: string) {
         this.confirmation.show({
            message: 'Are you sure you want to delete this Training Request? This will delete all data attached to this Training Request.',
            ok: 'DELETE',
            cancel: 'KEEP'
         }).then(() => { this.deleteTrainingRequest(id); });
      }

      private deleteTrainingRequest(id: string) {
         this.datacontext.training.deleteTrainingRequest(id).then((result) => {
            if (result) {
               this.common.$state.go('app.trainingRequestList');
            }
         });
      }
   }
}