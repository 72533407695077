namespace app.utils {
   declare var google: any;

   angular
      .module('app.utils')
      .directive('geoAutocomplete', geoAutocomplete);

   function geoAutocomplete(common: core.ICommonService) {
      'ngInject';

      return {
         restrict: 'A',
         require: 'ngModel',
         scope: {
            placeType: '@placesAutocomplete',
            onSelect: '&',
            placeholderText: '='
         },
         link: linkFn
      };

      function linkFn(scope: any, element: angular.IAugmentedJQuery, attrs, model) {
         let types = [];

         if (scope.placeType) {
            types.push(scope.placeType);
         }

         common.$timeout(function () {
            const autocomplete = new google.maps.places.Autocomplete(
               element.get(0),
               { types });

            google.maps.event.addListener(autocomplete, 'place_changed', function () {
               const place = autocomplete.getPlace();
               if (scope.onSelect) {
                  scope.onSelect({ place: place });
               }
               else {
                  model.$setViewValue(place.formatted_address);
               }
            });

            if (navigator.geolocation) {
               navigator.geolocation.getCurrentPosition(function (position) {
                  const geolocation = new google.maps.LatLng(position.coords.latitude, position.coords.longitude);
                  autocomplete.setBounds(new google.maps.LatLngBounds(geolocation, geolocation));
               });
            }
         });
      }
   }
}