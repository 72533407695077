namespace app.systemicCases {
   'use strict';

   export const caseBasicInfoResolver = (datacontext: data.IDataContextService, $stateParams: angular.ui.IStateParamsService) => {
      'ngInject';
      const { systemicCaseId } = $stateParams;
      return datacontext.systemicCases.getSystemicCaseBasicInfoView(systemicCaseId);
   };

   export const legalTeamsResolver = (datacontext: data.IDataContextService) => {
      'ngInject';
      return datacontext.config.legalTeams.getLegalTeams(false);
   };
}