namespace app.funding {
   @Component('app.funding', 'cePaatIAndROutcome', {
      templateUrl: 'app/funding/enrollments/paat/i-and-r/paat-outcome.html',
      bindings: {
         enrollmentId: '<'
      }
   })
   class PAATIAndROutcome {
      static $inject = ['common', 'datacontext'];
      constructor(
         private common: core.ICommonService,
         private datacontext: data.IDataContextService) {
         'ngInject';
      }
      public enrollmentId: string;
      public outcome = {} as IPAATIAndROutcome;
      public editing: boolean;

      public $onInit() {
         this.getOutcome();
      }

      public getOutcome() {
         return this.datacontext.funding.getPAATIAndROutcome(this.enrollmentId).then(
            result => {
               if (result) {
                  this.outcome = result;
               }
               else {
                  this.outcome.id = this.enrollmentId;
               }
            }
         );
      }

      public edit() {
         this.getOutcome().then(() => this.editing = true);
      }

      public updateOutcome() {
         this.datacontext.funding.updatePAATIAndROutcome(this.outcome).then(
            () => {
               this.editing = false;
            }
         );
      }

      public cancel() {
         this.getOutcome().then(() => {
            this.editing = false;
         });
      }

      public done() {
         this.common.$state.go('^.');
      }
   }
}