namespace app.informationAndReferrals {
   'use strict';

   const routes: core.routing.IStateExtended[] = [
      {
         name: 'app.createInformationAndReferral',
         url: '/information-and-referrals/create',
         template: '<ce-create-information-and-referral layout="column" flex></ce-create-information-and-referral>',
         data: {
            title: 'Create I&R',
            permissions: ['i-and-rs.edit']
         }
      },
      {
         name: 'app.informationAndReferrals',
         url: '/information-and-referrals/{informationAndReferralId}',
         template: '<ce-information-and-referral-view case="$resolve.case" layout="column" flex></ce-information-and-referral-view>',
         redirectTo: 'app.informationAndReferrals.overview',
         data: {
            title: 'I&R View',
            permissions: ['i-and-rs.view']
         },
         resolve: { case: caseBasicInfoResolver }
      },
      {
         name: 'app.informationAndReferrals.overview',
         url: '/overview',
         template: '<ce-information-and-referral-overview case="$resolve.case" layout="column" flex></ce-information-and-referral-overview>',
         data: {
            title: 'I&R Overview',
            permissions: ['i-and-rs.view']
         }
      },
      {
         name: 'app.informationAndReferrals.details',
         url: '/details',
         template: '<ce-information-and-referral-details case="$resolve.case" layout="column" flex></ce-information-and-referral-details>',
         data: {
            title: 'I&R Details',
            permissions: ['i-and-rs.view']
         }
      },
      {
         name: 'app.informationAndReferrals.documents',
         url: '/documents',
         template: '<ce-documents-list involvement-id="$resolve.case.involvementId" source="I&R" source-id="$resolve.case.id" layout="column" flex></ce-document-list>',
         data: {
            title: 'I&R Documents',
            permissions: ['documents.view']
         }
      },
      {
         name: 'app.informationAndReferrals.uploadDocuments',
         url: '/upload',
         params: {
            folderId: null
         },
         resolve: {
            folderId: ['$stateParams', p => p.folderId],
         },
         template: '<ce-upload-documents-activity layout flex folder-id="$resolve.folderId" involvement-id="$resolve.case.involvementId" source="I&R" source-id="$resolve.case.id"></ce-upload-documents-activity>',
         data: { permissions: ['documents.edit'] }
      },
      {
         name: 'app.informationAndReferrals.emails',
         url: '/emails',
         template: '<ui-view flex layout="column"></ui-view>',
         redirectTo: 'app.informationAndReferrals.emails.listing',
         data: {
            title: 'I&R Emails',
            permissions: ['mail.view']
         }
      },
      {
         name: 'app.informationAndReferrals.emails.listing',
         url: '',
         template: '<ce-email-listing involvement-id="$resolve.case.involvementId" source="I&R" source-id="$resolve.case.id" layout="column" flex></ce-email-listing>',
         data: {
            title: 'I&R Emails',
            permissions: ['mail.view']
         }
      },
      {
         name: 'app.informationAndReferrals.emails.message',
         url: '/{messageId}',
         template: '<ce-email-view layout="column" flex message-id="$resolve.messageId"></ce-email-view>',
         data: {
            title: 'Message',
            permissions: ['mail.view']
         },
         resolve: {
            messageId: ['$stateParams', p => p.messageId]
         }
      },
      {
         name: 'app.informationAndReferrals.emails.link',
         url: '/link',
         template: '<ce-link-emails layout="column" flex involvement-id="$resolve.case.involvementId" source="I&R" source-id="$resolve.case.id"></ce-link-emails>',
         data: {
            title: 'Inbox Messages',
            permissions: ['mail.link']
         }
      },
      {
         name: 'app.informationAndReferrals.involvement',
         url: '/involvement',
         template: '<ce-involvement-case-listing involvement-id="$resolve.case.involvementId" flex></ce-involvement-case-listing>',
         data: { title: 'I&R Involvement' }
      },
      {
         name: 'app.informationAndReferrals.timeslipListing',
         url: '/timeslips',
         template: '<ce-timeslip-listing source="I&R" source-name="$resolve.case.idNumber" source-name2="$resolve.case.requesterFullName" source-id="$resolve.case.id" involvement-id="$resolve.case.involvementId" enable-filters="false" flex layout="column"></ce-timeslip-listing>',
         data: {
            title: 'I&R Timeslips',
            permissions: ['timeslips.view']
         }
      },
      {
         name: 'app.informationAndReferrals.timeslipCalendar',
         url: '/timeslips-calendar',
         template: '<ce-timeslip-calendar-frame source="I&R" source-name="$resolve.case.idNumber" source-name2="$resolve.case.requesterFullName" source-id="$resolve.case.id" involvement-id="$resolve.case.involvementId" enable-filters="false" flex layout="column"></ce-timeslip-calendar-frame>',
         data: {
            title: 'I&R Timeslips',
            permissions: ['timeslips.view']
         }
      },
      {
         name: 'app.informationAndReferrals.grievances',
         url: '/grievances',
         template: '<ce-grievance-listing case="$resolve.case" case-type="I&R" flex></ce-grievance-listing>',
         data: {
            title: 'I&R Grievances',
            permissions: ['grievances.view']
         }
      }
   ];

   const runner = (routingHelper: core.routing.IRoutingHelperService) => {
      'ngInject';
      routingHelper.configureRoutes(routes);
   };

   angular
      .module('app.informationAndReferrals')
      .run(runner);
}