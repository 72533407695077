namespace app.monitoring {
   @Component('app.monitoring', 'ceMonitoringReview', {
      templateUrl: 'app/monitoring/activities/case-review/case-review.html',
      bindings: {
         involvementId: '<',
         monitoringId: '<',
         returnSref: '@',
         activity: '<',
         caseOpenDate: '<'
      }
   })
   class CaseReview {
      static $inject = ['common', 'datacontext'];
      constructor(
         private common: core.ICommonService,
         private datacontext: data.IDataContextService) {
         'ngInject';
      }

      public involvementId: string;
      public monitoringId: string;
      public returnSref: string;
      public activity: app.activities.IActivity;

      public isSubmitting: boolean = false;
      public review = {} as involvements.ICaseReview;
      public maxDate: Date;

      private $onInit() {
         this.maxDate = moment().toDate();
         if (this.activity) {
            this.review.id = this.activity.id;
            this.review.caseId = this.activity.sourceId;
            this.review.involvementId = this.activity.involvementId;
            this.review.note = this.activity.description;
            this.review.reviewDate = this.activity.activityDate;
         } else {
            this.getCaseStatus();
            this.review.involvementId = this.involvementId;
            this.review.caseId = this.monitoringId;
            this.review.reviewDate = moment().toDate();
         }

         this.returnSref = this.returnSref || '^.list';
      }

      private getCaseStatus() {
         return this.datacontext.monitoring.getMonitoringStatus(this.monitoringId).then(
            result => {
               let data: any = result;
               if (data.status) {
                  this.review.status = data.status;
               }
            });
      }

      public submit() {
         this.isSubmitting = true;

         if (typeof this.review.reviewDate !== 'string') {
            this.review.reviewDate = this.review.reviewDate.toDateString();
         }

         if (this.review.status !== 'Other') {
            this.review.statusDescription = null;
         }

         const promise = this.review.id
            ? this.datacontext.monitoring.updateCaseReview(this.review)
            : this.datacontext.monitoring.createCaseReview(this.review);

         promise
            .then(request => {
               if (request) {
                  this.common.$mdToast.showSimple('Monitoring Review ' + (this.review.id ? 'updated' : 'created'));
                  this.common.$rootScope.$broadcast('refreshBasicInfo');
                  this.common.$state.go(this.returnSref, { involvementId: this.involvementId, sourceId: this.monitoringId });
               }
            })
            .finally(() => this.isSubmitting = false);
      }
   }
}