namespace app.admin.tools {
   export interface IAgencyMergeData extends agencies.IAgency {
      cases: entities.ICaseListing[];
   }

   @Component('app.admin.tools', 'ceAdminAgencyMerge', {
      templateUrl: 'app/admin/modules/tools/entity-merge/views/agency-merge/agency-merge.html',
   })
   class AdminAgencyMerge {
      static $inject = ['common', 'datacontext'];
      constructor(
         private common: core.ICommonService,
         private datacontext: data.IDataContextService) {
         'ngInject';
      }

      public winner: IAgencyMergeData;
      public loser: IAgencyMergeData;
      public isSubmitting: boolean;

      public agencySearch(searchText: string) {
         const params: agencies.IGetAgenciesParams = { q: searchText };

         return this.datacontext.agencies
            .getAgencies(params)
            .then(results => {
               return results.list;
            });
      }

      private onWinnerChange(winner: agencies.IAgency) {
         this.setWinner(winner);
      }

      public setWinner(winner: agencies.IAgency) {
         this.winner = winner as IAgencyMergeData;
         this.getCases(this.winner);
      }

      private onLoserChange(loser: agencies.IAgency) {
         this.setLoser(loser);
      }

      public setLoser(loser: agencies.IAgency) {
         this.loser = loser as IAgencyMergeData;
         this.getCases(this.loser);
      }

      private getCases = (agency: IAgencyMergeData) => {
         if (!agency) {
            return;
         }

         this.datacontext.entities
            .getCaseListing(agency.id, { pageSize: 50 })
            .then(result => agency.cases = result.list);
      }

      private onSubmit() {
         this.isSubmitting = true;

         this.datacontext.agencies
            .merge(this.winner.id, this.loser.id)
            .then((result) => {
               if (!result) {
                  this.isSubmitting = false;
                  return;
               }

               this.common.$mdToast.showSimple('Merge completed');
               this.common.$state.go('^.select');
            });
      }
   }
}