namespace app.fiscal {
   @Component('app.fiscal', 'amCreateCheckButton', {
      template: `
      <md-button ng-click="$ctrl.openCheckDialog()" permission="['checks.edit']">
        <md-tooltip>Add Check</md-tooltip>
        <md-icon aria-hidden="true">add</md-icon>
      </md-button>`,
      bindings: {
         refresh: '&'
      }
   })
   class CreateCheckButtonComponent {
      static $inject = ['$mdDialog', '$scope'];
      constructor(
         private $mdDialog: angular.material.IDialogService,
         private $scope: angular.IScope) {
         'ngInject';
      }

      private refresh: () => {};
      private openCheckDialog() {
         const parent = angular.element(document.body);
         // not adding a .catch to dialogs causes possibly unhandled rejection error
         return this.$mdDialog.show({
            parent,
            fullscreen: true,
            templateUrl: 'app/fiscal/check/check-dialog.html',
            controller: 'CheckDialogController',
            controllerAs: '$ctrl',
            focusOnOpen: false,
            locals: {
               check: null
            }
         })
            .catch((error) => { if (error) throw error; })
            .finally(() => { this.refresh(); });
      }
   }
}
