namespace app.entities.details {
   @Component('app.entities.details', 'ceEntityPhoneNumberList', {
      templateUrl: 'app/entities/details/phone-number/phone-number-list.html',
      bindings: {
         phoneNumbers: '<',
         onAdd: '&',
         onEdit: '&',
         onDelete: '&'
      }
   })
   class PhoneNumberList {
   }
}