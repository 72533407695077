namespace app.informationAndReferrals.activities {
   @Component('app.informationAndReferrals.activities', 'ceInformationAndReferralActivityList', {
      templateUrl: 'app/information-and-referrals/activities/activity-list/activity-list.html',
      bindings: {
         case: '<',
         showCovidVaccine: '<'
      }
   })
   class InformationAndReferralActivityList {
      static $inject = ['$mdDialog', 'common', 'datacontext'];
      constructor(
         private $mdDialog: angular.material.IDialogService,
         private common: core.ICommonService,
         private datacontext: data.IDataContextService) {
         'ngInject';
      }

      private activities: data.IPagedList<app.activities.IActivity>;
      private case: IInformationAndReferralBasicInfoView;
      private showInvolvementActivities: boolean;
      private showCovidVaccine: boolean;
      private activityFilter = {} as app.activities.ISearchActivitiesData;
      public showFilters: boolean = false;
      private activityTypeFilterInitialized: boolean;
      private selectedActivityTypes: string[] = [];
      public noteFilter: string;
      public promise: angular.IPromise<any>;
      public paging: data.IPagedListParams = {
         page: 1,
         pageSize: 100
      };

      public $onInit() {
         this.showCovidVaccine = (this.showCovidVaccine || false).toString() === 'true';
         this.activityFilter.involvementId = this.case.involvementId;
         this.activityFilter.sourceId = this.case.id;
         this.activityFilter.source = 'I&R';
         this.getActivities();
      }

      public getActivities() {
         if (!this.filtersInitialized()) {
            return;
         }

         if (!this.showInvolvementActivities) {
            this.activityFilter.sourceId = this.case.id;
            this.activityFilter.source = 'I&R';
         } else {
            this.activityFilter.sourceId = null;
            this.activityFilter.source = null;
         }

         this.activityFilter = {
            ...this.activityFilter,
            ...this.paging,
            activityTypes: this.selectedActivityTypes,
            q: this.noteFilter
         };

         this.promise = this.datacontext.activities
            .searchActivities(this.activityFilter)
            .then(result => {
               this.activities = result;
            });
      }

      public refresh = () => {
         this.getActivities();
      }

      public getInvolvementActivities() {
         this.showInvolvementActivities = !this.showInvolvementActivities;
         this.getActivities();
      }

      public newActivity(targetEvent: MouseEvent) {
         const parent = angular.element(document.body);
         this.$mdDialog.show({
            parent,
            targetEvent,
            clickOutsideToClose: true,
            templateUrl: 'app/information-and-referrals/activities/new-activity/new-activity.html',
            controller: 'NewInformationAndReferralActivityDialogController',
            controllerAs: '$ctrl',
            locals: {
               caseview: this.case,
               vaccineFeatureFlag: this.showCovidVaccine
            }
         });
      }

      public toggleFilters() {
         this.showFilters = !this.showFilters;
      }

      private filtersInitialized() {
         return this.activityTypeFilterInitialized;
      }

      public activityTypeFilterChanged(activityTypes: string[]) {
         this.selectedActivityTypes = activityTypes;
         this.activityTypeFilterInitialized = true;
         this.getActivities();
      }

      public onEdit(activity: app.activities.IActivity) {
         switch (activity.activityType) {
            case 'Note':
               this.common.$state.go('^.note', { activity: activity });
               break;
            case 'CovidVaccine':
               this.common.$state.go('^.covidVaccine', { activityId: activity.id });
               break;
            case 'StatusChange':
               this.common.$state.go('^.statusChange', { activityId: activity.id });
               break;
         }
      }
   }
}