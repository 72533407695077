namespace app.entities.details {
   @Component('app.entities.details', 'ceEntityEmailAddressList', {
      templateUrl: 'app/entities/details/email-address/email-address-list.html',
      bindings: {
         emailAddresses: '<',
         onAdd: '&',
         onEdit: '&',
         onDelete: '&'
      }
   })
   class EmailAddressList {
   }
}