namespace app.requests.overview {
   @Component('app.requests.overview', 'ceRequestSummary', {
      templateUrl: 'app/requests/summary/summary.html',
      bindings: {
         request: '<'
      }
   })
   class RequestSummary {
      static $inject = ['$mdDialog', 'common', 'datacontext'];
      constructor(
         private $mdDialog: angular.material.IDialogService,
         private common: core.ICommonService,
         private datacontext: data.IDataContextService) {
         'ngInject';
      }

      public requestSummary: IRequestSummary;
      public request: IRequestBasicInfo;

      public $onInit() {
         this.datacontext.requests.getRequestSummary(this.request.id)
            .then(result => {
               let data: any = result;
               this.requestSummary = data;
            });
      }

      public init() {
      }
   }
}