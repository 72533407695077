namespace app.admin.configuration {
   const routes: core.routing.IStateExtended[] = [
      {
         name: 'app.admin.configuration',
         url: '/configuration',
         template: '<ui-view layout="column" flex></ui-view>',
         data: { roles: ['Administrator'] },
         redirectTo: 'app.admin.configuration.lookupTypeList'
      },
      {
         name: 'app.admin.configuration.lookupTypeList',
         url: '/lookup-types',
         template: '<aims-admin-lookup-type-list layout="column" flex lookup-types="$resolve.lookupTypes"></aims-admin-lookup-type-list>',
         data: {
            title: 'Lookup Type List',
            roles: ['Administrator']
         },
         resolve: {
            lookupTypes: lookupTypesResolver
         }
      },
      {
         name: 'app.admin.configuration.lookupTypeDetails',
         url: '/lookup-types/:lookupTypeId',
         template: '<aims-admin-lookup-type-details layout="column" flex lookup-type="$resolve.lookupType"></aims-admin-lookup-type-details>',
         data: { roles: ['Administrator'] },
         resolve: {
            lookupType: lookupTypeResolver
         }
      },
      {
         name: 'app.admin.configuration.lawCategoryList',
         url: '/law-categories',
         template: '<ce-admin-law-category-list layout="column" flex law-categories="$resolve.lawCategories"></ce-admin-law-category-list>',
         data: {
            title: 'Law Category List',
            roles: ['Administrator']
         },
         resolve: {
            lawCategories: lawCategoriesResolver
         }
      },
      {
         name: 'app.admin.configuration.createLawCategory',
         url: '/law-categories/create',
         template: '<ce-admin-create-law-category layout="column" flex></ce-admin-create-law-category>',
         data: { roles: ['Administrator'] }
      },
      {
         name: 'app.admin.configuration.lawCategoryDetails',
         url: '/law-categories/:lawCategoryId',
         template: '<ce-admin-law-category-details layout="column" flex law-category="$resolve.lawCategory"></ce-admin-law-category-details>',
         data: { roles: ['Administrator'] },
         resolve: {
            lawCategory: lawCategoryResolver
         }
      },
      {
         name: 'app.admin.configuration.legalTeamList',
         url: '/legal-teams',
         template: '<ce-admin-legal-team-list layout="column" flex legal-teams="$resolve.legalTeams"></ce-admin-legal-team-list>',
         data: {
            title: 'Legal Team List',
            roles: ['Administrator']
         },
         resolve: {
            legalTeams: legalTeamsResolver
         }
      },
      {
         name: 'app.admin.configuration.createLegalTeam',
         url: '/legal-teams/create',
         template: '<ce-admin-legal-team-details layout="column" flex></ce-admin-legal-team-details>',
         data: { roles: ['Administrator'] }
      },
      {
         name: 'app.admin.configuration.legalTeamDetails',
         url: '/legal-teams/:legalTeamId',
         template: '<ce-admin-legal-team-details layout="column" flex legal-team="$resolve.legalTeam"></ce-admin-legal-team-details>',
         data: { roles: ['Administrator'] },
         resolve: {
            legalTeam: legalTeamResolver
         }
      },
      {
         name: 'app.admin.configuration.taskCategoryList',
         url: '/task-categories',
         template: '<ce-admin-task-category-list layout="column" flex task-categories="$resolve.taskCategories"></ce-admin-task-category-list>',
         data: {
            title: 'Task Category List',
            roles: ['Administrator']
         },
         resolve: {
            taskCategories: taskCategoriesResolver
         }
      },
      {
         name: 'app.admin.configuration.createTaskCategory',
         url: '/task-categories/create',
         template: '<ce-admin-create-task-category layout="column" flex></ce-admin-create-task-category>',
         data: { roles: ['Administrator'] }
      },
      {
         name: 'app.admin.configuration.taskCategoryDetails',
         url: '/task-categories/:taskCategoryId',
         template: '<ce-admin-task-category-details layout="column" flex task-category="$resolve.taskCategory"></ce-admin-task-category-details>',
         data: { roles: ['Administrator'] },
         resolve: {
            taskCategory: taskCategoryResolver
         }
      },
   ];

   const runner = (routingHelper: core.routing.IRoutingHelperService) => {
      'ngInject';
      routingHelper.configureRoutes(routes);
   };

   angular
      .module('app.admin.configuration')
      .run(runner);
}