namespace app.projects {
   @Component('app.projects', 'ceProjectView', {
      templateUrl: 'app/projects/project/project-view.html',
      bindings: {
         project: '<'
      }
   })
   class ProjectView {
      static $inject = ['common', 'confirmation', 'datacontext', 'timerService'];
      constructor(
         private common: core.ICommonService,
         private confirmation: IConfirmationService,
         private datacontext: data.IDataContextService,
         private timerService: time.ITimerService) {
         'ngInject';
         this.$state = common.$state;
      }

      public isLoading: boolean = false;
      public project: IProjectView;
      public $state: angular.ui.IStateService;

      private $onInit() {
      }

      private showDeleteDialog(id: string) {
         this.confirmation.show({
            message: 'Are you sure you want to delete this Project? This will delete all data attached to this Project.',
            ok: 'DELETE',
            cancel: 'KEEP'
         }).then(() => { this.deleteProject(id); });
      }

      private deleteProject(id: string) {
         this.datacontext.projects.deleteProject(id).then((result) => {
            if (result) {
               this.timerService.killTimer();
               this.common.$state.go('app.projectList');
            }
         });
      }
   }
}