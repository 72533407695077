namespace app.core.logging {
   export interface ILogService {
      showToasts: boolean;
      error(message: string, data?: any);
      info(message: string, data?: any);
      success(message: string, data?: any);
      warning(message: string, data?: any);
   }

   class LogService implements ILogService {
      constructor(
         private $log: angular.ILogService,
         private $mdToast: angular.material.IToastService) {
         'ngInject';
      }

      public showToasts = true;

      public error(message: string, data: any) {
         if (!message) {
            return;
         }
         let toast = this.$mdToast.simple().textContent('Error: ' + message).hideDelay(0).action('CLOSE');
         this.$mdToast.show(toast);
         this.$log.error('Error: ' + message, data);
      }

      public info(message: string, data: any) {
         this.$mdToast.showSimple(message);
         this.$log.info('Info: ' + message, data);
      }

      public success(message: string, data: any) {
         this.$mdToast.showSimple(message);
         this.$log.info('Success: ' + message, data);
      }

      public warning(message: string, data: any) {
         this.$mdToast.showSimple(message);
         this.$log.warn('Warning: ' + message, data);
      }
   }

   angular
      .module('app.core.logging')
      .service('logger', LogService);
}