namespace app.informationAndReferrals.activities {
   const routes: core.routing.IStateExtended[] = [
      {
         name: 'app.informationAndReferrals.activities',
         url: '/activities',
         template: '<ui-view flex layout="column"></ui-view>',
         redirectTo: 'app.informationAndReferrals.activities.list'
      },
      {
         name: 'app.informationAndReferrals.activities.list',
         url: '',
         template: `<ce-information-and-referral-activity-list
                        case="$resolve.case"
                        show-Covid-Vaccine="$resolve.flag"
                        layout="column" flex>
                     </ce-information-and-referral-activity-list>`,
         data: {
            title: 'I&R Activities',
            permissions: ['access_activities']
         },
         resolve: { flag: showCovidVaccine }
      },
      {
         name: 'app.informationAndReferrals.activities.note',
         url: '/note',
         template: `<ce-information-and-referrals-activities-note layout="column" flex
                        involvement-id="$resolve.case.involvementId"
                        case-Id="$resolve.case.id"
                        activity-Type="InformationAndReferralNote"
                        name="Note"
                        activity="$resolve.activity"
                        case-open-date="$resolve.case.openDate"
                        return-sref="app.informationAndReferrals.activities.list">
                    </ce-information-and-referrals-activities-note>`,
         params: { activity: null },
         resolve: { activity: ['$stateParams', p => p.activity] },
         data: { title: 'Note' }
      },
      {
         name: 'app.informationAndReferrals.activities.review',
         url: '/review',
         template: `<ce-activities-activity layout="column" flex
                       involvement-id="$resolve.case.involvementId"
                       source="I&R"
                       source-Id="$resolve.case.id"
                       activity-Type="InformationAndReferralReview"
                       name="Review"
                       return-sref="app.informationAndReferrals.activities.list">
                    </ce-activities-activity>`,
         data: { title: 'Review' }
      },
      {
         name: 'app.informationAndReferrals.activities.conflictCheck',
         url: '/conflictCheck',
         template: `<ce-conflict-check layout="column" flex
                     involvement-id="$resolve.case.involvementId"
                     source="I&R"
                     source-Id="$resolve.case.id"
                     case-open-date="$resolve.case.openDate">
                    </ce-conflict-check>`,
         data: { title: 'Conflict Check' }
      },
      {
         name: 'app.informationAndReferrals.activities.close',
         url: '/close',
         template: `<ce-information-and-referral-activities-close
                     source="I&R"
                     bypass-Closure-Reasons="$resolve.bypass"
                     case="$resolve.case" flex>
                    </ce-information-and-referral-activities-close>`,
         data: { title: 'Close' },
         resolve: { bypass: bypassClosureReasons }
      },
      {
         name: 'app.informationAndReferrals.activities.reopen',
         url: '/reopen',
         template: `<ce-information-and-referral-activities-reopen
                     case="$resolve.case" flex>
                    </ce-information-and-referral-activities-reopen>`,
         data: { title: 'Reopen' }
      },
      {
         name: 'app.informationAndReferrals.activities.createClientCase',
         url: '/createclientcase',
         template: `<ce-create-client-case layout="column" flex
                        involvement-id="$resolve.case.involvementId"
                        information-and-referral-id="$resolve.case.id"
                        case-open-date="$resolve.case.openDate">
                    </ce-create-client-case>`,
         data: {
            title: 'Create Client Case',
            permissions: ['client-cases.edit']
         }
      },
      {
         name: 'app.informationAndReferrals.activities.createSystemicCase',
         url: '/createSystemicCase',
         template: `<ce-create-systemic-case layout="column" flex
                        involvement-id="$resolve.case.involvementId"
                        information-and-referral-id="$resolve.case.id"
                        case-open-date="$resolve.case.openDate">
                    </ce-create-systemic-case>`,
         data: {
            title: 'Create Systemic Case',
            permissions: ['systemic-cases.edit']
         }
      },
      {
         name: 'app.informationAndReferrals.activities.covidVaccine',
         url: '/covidVaccine',
         template: `<am-information-and-referrals-covid-vaccine layout="column" flex
                        [involvement-id]="$resolve.case.involvementId"
                        [source-id]="$resolve.case.id"
                        [activity-id]="$resolve.activityId">
                    </am-information-and-referrals-covid-vaccine>`,
         params: { activityId: null },
         resolve: { activityId: ['$stateParams', p => p.activityId] },
         data: {
            title: 'Covid Vaccine'
         }
      },
      {
         name: 'app.informationAndReferrals.activities.statusChange',
         url: '/statusChange',
         template: `<am-activities-status-change layout="column" flex
                        [involvement-id]="$resolve.case.involvementId"
                        [source-id]="$resolve.case.id"
                        source="informationandreferrals"
                        [activity-id]="$resolve.activityId">
                    </am-activities-status-change>`,
         params: { activityId: null },
         resolve: { activityId: ['$stateParams', p => p.activityId] },
         data: {
            title: 'Status Change'
            // permissions: ['informationAndReferrals.edit']
         }
      }
   ];

   const runner = (routingHelper: core.routing.IRoutingHelperService) => {
      'ngInject';
      routingHelper.configureRoutes(routes);
   };

   angular
      .module('app.informationAndReferrals.activities')
      .run(runner);
}