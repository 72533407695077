namespace app.requests.activities {
   @Component('app.requests.activities', 'ceRequestsActivitiesClose', {
      templateUrl: 'app/requests/activities/close/close.html',
      bindings: {
         request: '<'
      }
   })
   class CloseRequest {
      static $inject = ['common', 'datacontext'];
      constructor(
         private common: core.ICommonService,
         private datacontext: data.IDataContextService) {
         'ngInject';
      }

      public statuses: app.config.ILookup[] = [];
      public selectedStatus: string;
      public request: IRequestBasicInfo;
      public isSubmitting: boolean = false;
      public activity = {} as ICreateRequestActivity;
      public maxDate: Date;

      private $onInit() {
         this.activity.requestId = this.request.id;
         this.activity.involvementId = this.request.involvementId;
         this.activity.activityDate = moment().toDate();
         this.maxDate = this.activity.activityDate;
         this.getRequestStatus();
      }

      private getRequestStatus() {
         return this.datacontext.requests.getRequestStatus(this.request.id).then(
            result => {
               let data: any = result;
               if (data.status) {
                  this.selectedStatus = data.status;
                  this.activity.statusDescription = data.statusDescription;
               }
            });
      }

      public submit() {
         this.isSubmitting = true;

         if (typeof this.activity.activityDate !== 'string') {
            this.activity.activityDate = this.activity.activityDate.toDateString();
         }

         this.activity.status = this.selectedStatus;
         if (this.selectedStatus.indexOf('Other') === -1) {
            this.activity.statusDescription = null;
         }

         this.datacontext.requests
            .close(this.activity)
            .then(request => {
               if (request) {
                  this.common.$mdToast.showSimple('Request Closed');
                  this.common.$rootScope.$broadcast('refreshBasicInfo');
                  this.request.isActive = false;
                  this.common.$state.go('^.list', { requestId: this.request.id, involvementId: this.request.involvementId });
               }
            })
            .finally(() => this.isSubmitting = false);
      }
   }
}