namespace app.entities.details {
   @Component('app.entities.details', 'ceEntityNameList', {
      templateUrl: 'app/entities/details/name/name-list.html',
      bindings: {
         names: '<',
         onAdd: '&',
         onEdit: '&',
         onDelete: '&'
      }
   })
   class NameList {
   }
}