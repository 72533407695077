namespace app.training.cle {
   const routes: core.routing.IStateExtended[] = [
      {
         name: 'app.cle.entities',
         url: '/entities',
         template: '<ui-view flex></ui-view>',
         redirectTo: 'app.cle.entities.listing',
         data: { title: 'Request Entities' }
      },
      {
         name: 'app.cle.entities.listing',
         url: '/listing',
         template: '<ce-training-entity-listing training-id="$resolve.cle.id" layout="column" flex></ce-training-entity-listing>',
         data: { title: 'Request Entities' }
      },
      {
         name: 'app.cle.entities.add',
         url: '/add',
         template: '<ce-select-entity-type flex></ce-select-entity-type>',
         redirectTo: 'app.cle.entities.add.type',
         data: { title: 'Add' }
      },
      {
         name: 'app.cle.entities.add.type',
         url: '/types',
         template: '<ce-entity-types flex></ce-entity-types>'
      },
      {
         name: 'app.cle.entities.add.person',
         url: '/person',
         template: '<ui-view layout flex></ui-view>',
         redirectTo: 'app.cle.entities.add.person.search',
      },
      {
         name: 'app.cle.entities.add.person.search',
         url: '/search',
         template: '<ce-person-search flex></ce-person-search>',
         data: { title: 'Find Person' }
      },
      {
         name: 'app.cle.entities.add.person.create',
         url: '/create',
         template: '<ce-create-person flex></ce-create-person>',
         data: { title: 'Create Person' }
      },
      {
         name: 'app.cle.entities.add.person.roles',
         url: '/roles',
         params: { entity: null },
         resolve: { entity: ['$stateParams', p => p.entity] },
         template: '<ce-training-entity-roles role-lookup-type-prefix="cle" training-id="$resolve.cle.id" entity="$resolve.entity" layout="column" flex></ce-training-entity-roles>',
         data: { title: 'Select Roles' }
      },
      {
         name: 'app.cle.entities.add.agency',
         url: '/agency',
         template: '<ui-view layout flex></ui-view>',
         redirectTo: 'app.cle.entities.add.agency.search',
      },
      {
         name: 'app.cle.entities.add.agency.search',
         url: '/search',
         template: '<ce-agency-search flex></ce-agenyc-search>',
         data: { title: 'Find Agency' }
      },
      {
         name: 'app.cle.entities.add.agency.create',
         url: '/create',
         template: '<ce-create-agency flex></ce-create-agency>',
         data: { title: 'Create Agency' }
      },
      {
         name: 'app.cle.entities.add.agency.roles',
         url: '/roles',
         params: { entity: null },
         resolve: { entity: ['$stateParams', p => p.entity] },
         template: '<ce-training-entity-roles role-lookup-type-prefix="cle" training-id="$resolve.cle.id" entity="$resolve.entity" layout="column" flex></ce-training-entity-roles>',
         data: { title: 'Select Roles' }
      },
      {
         name: 'app.cle.entities.edit',
         url: '/edit',
         params: { entity: null },
         resolve: { entity: ['$stateParams', p => p.entity] },
         template: '<ce-edit-training-entity-roles role-lookup-type-prefix="cle" training-id="$resolve.cle.id" entity="$resolve.entity" layout="column" flex></ce-edit-training-entity-roles>',
         data: { title: 'Edit' }
      }
   ];

   const runner = (routingHelper: core.routing.IRoutingHelperService) => {
      'ngInject';
      routingHelper.configureRoutes(routes);
   };

   angular
      .module('app.training.cle.entities')
      .run(runner);
}