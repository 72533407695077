namespace app.funding {
   @Component('app.funding', 'cePaatOutcomeForm', {
      templateUrl: 'app/funding/enrollments/paat/paat-outcome-form.html',
      bindings: {
         outcome: '<',
         onCancel: '&',
         onSubmit: '&'
      }
   })
   class PAATOutcomeForm {
      private assistiveTechnologies: config.ILookup[];
      private services: config.ILookup[];

      static $inject = ['datacontext'];
      constructor(
         private datacontext: data.IDataContextService) {
         'ngInject';
      }

      public interventionStrategies: app.config.ILookup[];

      private $onInit() {
         this.getDevices();
         this.getServices();
         this.getInterventionStrategies();
      }

      private getDevices() {
         this.datacontext.config.lookups.getLookupType('paat-assistive-technology')
            .then(assistiveTechnologies => this.assistiveTechnologies = assistiveTechnologies.lookups);
      }

      private getServices() {
         this.datacontext.config.lookups.getLookupType('paat-services')
            .then(services => this.services = services.lookups);
      }

      public getInterventionStrategies() {
         return this.datacontext.config.lookups.getLookupType('paat-intervention-strategies')
            .then(lookup => { this.interventionStrategies = lookup.lookups; });
      }
   }
}