namespace app.training {
   @Component('app.training.entities', 'ceTrainingEntityListing', {
      templateUrl: 'app/training/entities/entity-listing/entity-listing.html',
      bindings: {
         trainingId: '<',
         showAdd: '@',
         summary: '<'
      }
   })
   class EntityListing {
      static $inject = ['$mdDialog', 'common', 'datacontext'];
      constructor(
         private $mdDialog: angular.material.IDialogService,
         private common: core.ICommonService,
         private datacontext: data.IDataContextService) {
         'ngInject';
      }

      public entities: IEntityListing[];
      public entity: IEntityBasicInfo;
      public trainingId: string;
      public showAdd: boolean;
      public summary: boolean;

      private $onInit = () => {
         this.showAdd = this.showAdd || true;
         this.summary = this.summary || false;
         this.getEntities();
      }

      private getEntities = () => {
         return this.datacontext.training
            .getEntityListing(this.trainingId)
            .then(result => {
               this.entities = result;
            });
      };

      public deleteEntity(entity: IEntityListing) {
         this.common.confirmation.show({
            title: 'Delete Relationship',
            message: 'Are you sure you want to delete this entity relationship?',
            ok: 'Delete',
            cancel: 'Keep'
         }).then(() => {
            this.datacontext.training
               .deleteEntity(entity.id)
               .then(() => {
                  this.getEntities();
               });
         }).catch(angular.noop);
      }
   }
}