namespace app.cases {
   const routes: core.routing.IStateExtended[] = [
      {
         name: 'app.cases',
         url: '/cases?caseId&caseType',
         controller: 'CaseRoutingController'
      }
   ];

   const runner = (routingHelper: core.routing.IRoutingHelperService) => {
      'ngInject';
      routingHelper.configureRoutes(routes);
   };

   angular
      .module('app.cases')
      .run(runner);
}