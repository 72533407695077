namespace app.involvements {
   @Component('app.involvements', 'ceCaseList', {
      templateUrl: 'app/involvements/case-listing/case-list.html',
      bindings: {
         cases: '<',
         totalCases: '<'
      }
   })
   class CaseList {
   }
}