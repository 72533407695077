namespace app.monitoring.overview {
   @Component('app.monitoring.overview', 'ceMonitoringSummary', {
      templateUrl: 'app/monitoring/summary/summary.html',
      bindings: {
         monitoring: '<'
      }
   })
   class MonitoringSummary {
      static $inject = ['$mdDialog', 'common', 'datacontext'];
      constructor(
         private $mdDialog: angular.material.IDialogService,
         private common: core.ICommonService,
         private datacontext: data.IDataContextService) {
         'ngInject';
      }

      public monitoringSummary: IMonitoringSummaryView;
      public monitoring: IMonitoringBasicInfoView;

      public $onInit() {
         this.datacontext.monitoring.getMonitoringSummaryView(this.monitoring.id)
            .then(result => {
               let data: any = result;
               this.monitoringSummary = data;
            }).then(() => angular.element('#summarycard').attr('aria-hidden', 'false'));
      }
   }
}