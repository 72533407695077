namespace app.activities {
   @Component('app.activities', 'ceActivityFilters', {
      templateUrl: 'app/activities/activity-list/activity-filters.html',
      bindings: {
         onSubmit: '&',
         personId: '<',
         params: '<'
      }
   })
   class ActivitiesFilterComponent {
      static $inject = ['$mdSidenav', 'datacontext'];
      constructor(
         private $mdSidenav: angular.material.ISidenavService,
         private datacontext: data.IDataContextService) {
         'ngInject';
      }

      public activityTypes: ICheckableItem<string>[] = [
         // { item: 'Assessment' },
         // { item: 'Attendance' },
         // { item: 'CheckIn' },
         // { item: 'Document' },
         // { item: 'DrugTest' },
         // { item: 'Hearing' },
         // { item: 'Note' },
         // { item: 'Sanction' },
         // { item: 'ParticipantDemoted' },
         // { item: 'ParticipantGraduated' },
         // { item: 'ParticipantPromoted' },
         // { item: 'ParticipantTerminated' },
         // { item: 'Referral' },
         // { item: 'ReportingRequirement' }
      ];
      public involvements: ICheckableItem<involvements.IInvolvement>[];
      public data: ISearchActivitiesData;
      public onSubmit: () => void;
      public params: ISearchActivitiesData;
      public personId: string;

      public close() {
         this.$mdSidenav('activity-filters').close();
      }

      public submit() {
         const involvementIds = _(this.involvements).filter(x => x.isChecked).map(x => x.item.id).value();
         const activityTypes = _(this.activityTypes).filter(x => x.isChecked).map(x => x.item).value();

         angular.extend(this.params, {
            ...this.data,
            involvementIds,
            activityTypes
         });

         this.onSubmit();
         this.close();
      }

      private $onChanges() {
         this.data = angular.copy(this.params);
         this.getInvolvements();
      }

      private getInvolvements = () => {
         return this.datacontext.involvements.getInvolvements({
            personId: this.personId,
            fields: 'id,idNumber'
         }).then(result => this.involvements = result.map(item => ({ item })));
      };
   }
}