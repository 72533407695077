namespace app.clientCases.activities {
   @Component('app.clientCases.activities', 'ceClientCasesActivitiesClose', {
      templateUrl: 'app/client-cases/activities/close/close.html',
      bindings: {
         case: '<'
      }
   })
   class Close {
      static $inject = ['common', 'datacontext'];
      constructor(
         private common: core.ICommonService,
         private datacontext: data.IDataContextService) {
         'ngInject';
      }

      public statuses: app.config.ILookup[] = [];
      public selectedStatus: string;
      public case: IClientCaseBasicInfoView;
      public isSubmitting: boolean = false;
      public activity = {} as ICreateClientCaseActivity;
      public items: common.IToDoItem[];
      public maxDate: Date;

      private $onInit() {
         this.activity.involvementId = this.case.involvementId;
         this.activity.caseId = this.case.id;
         this.activity.activityDate = moment().toDate();
         this.datacontext.clientCases.getClientCaseToDo(this.case.id, 'closing')
            .then(items => {
               this.items = items;
            });
         this.maxDate = this.activity.activityDate;
         this.getClientCaseStatus();
      }

      private getClientCaseStatus() {
         return this.datacontext.clientCases.getClientCaseStatus(this.case.id).then(
            result => {
               let data: any = result;
               if (data.status) {
                  this.selectedStatus = data.status;
                  this.activity.statusDescription = data.statusDescription;
               }
            });
      }

      public submit() {
         this.isSubmitting = true;

         if (typeof this.activity.activityDate !== 'string') {
            this.activity.activityDate = this.activity.activityDate.toDateString();
         }

         this.activity.status = this.selectedStatus;
         if (this.selectedStatus.indexOf('Other') === -1) {
            this.activity.statusDescription = null;
         }

         this.datacontext.clientCases
            .close(this.activity)
            .then(request => {
               if (request) {
                  this.common.$mdToast.showSimple('Case Closed');
                  this.common.$rootScope.$broadcast('refreshBasicInfo');
                  this.case.isActive = false;
                  this.common.$state.go('^.list', { caseId: this.case.id, involvementId: this.case.involvementId });
               }
            })
            .finally(() => this.isSubmitting = false);
      }
   }
}