namespace app.informationAndReferrals {
   @Component('app.informationAndReferrals', 'ceInformationAndReferralView', {
      templateUrl: 'app/information-and-referrals/information-and-referral/information-and-referral-view.html',
      bindings: {
         case: '<'
      }
   })
   class InformationAndReferralView {
      static $inject = ['$mdDialog', 'common', 'confirmation', 'timerService', 'datacontext'];
      constructor(
         private $mdDialog: angular.material.IDialogService,
         private common: core.ICommonService,
         private confirmation: IConfirmationService,
         private timerService: time.ITimerService,
         private datacontext: data.IDataContextService) {
         'ngInject';
         this.$state = common.$state;
      }

      public isLoading: boolean = false;
      public case: IInformationAndReferralBasicInfoView;
      public $state: angular.ui.IStateService;
      private unsubscribe: any;

      private $onInit() {
         this.unsubscribe = this.common.$rootScope.$on('refreshBasicInfo', this.refresh);
      }

      private $onDestroy() {
         this.unsubscribe();
      }

      private refresh = () => {
         this.datacontext.informationAndReferrals.getInformationAndReferralBasicInfoView(this.case.id).then(
            clientCase => this.case = clientCase
         );
      }

      private showDeleteDialog(id: string) {
         this.confirmation.show({
            message: 'Are you sure you want to delete this I&R? This will delete all data attached to this I&R.',
            ok: 'DELETE',
            cancel: 'KEEP'
         }).then(() => { this.deleteIandR(id); });
      }

      private deleteIandR(id: string) {
         this.datacontext.informationAndReferrals.deleteInformationAndReferral(id).then((result) => {
            if (result) {
               this.timerService.killTimer();
               this.common.$state.go('app.iAndRList');
            }
         });
      }
   }
}