namespace app.activities {
   @Component('app.activities', 'ceNewActivitySelector', {
      templateUrl: 'app/activities/new-activity/new-activity-selector.html',
      bindings: {
         activities: '<',
         involvement: '<',
         showInvolvementSelector: '<',
         onActivitySelected: '&',
         onChangeInvolvement: '&'
      }
   })
   class NewActivitySelector {
   }
}