namespace app.preferences {
   @Component('app.preferences', 'ceFavoriteToggle', {
      bindings: {
         type: '@',
         state: '@',
         param: '@',
         id: '<',
         maintitle: '@',
         subtitle: '@'
      },
      template: `
      <md-button class="md-icon-button" ng-click="$ctrl.toggle()" ng-if="!$ctrl.isFavorite">
        <md-tooltip>Add favorite</md-tooltip>
        <md-icon aria-hidden="true">star_border</md-icon>
      </md-button>
      <md-button class="md-icon-button" ng-click="$ctrl.toggle()" ng-if="$ctrl.isFavorite">
        <md-tooltip>Remove favorite</md-tooltip>
        <md-icon aria-hidden="true">star</md-icon>
      </md-button>`
   })
   class FavoriteToggleComponent {
      static $inject = ['preferenceService'];
      constructor(private preferenceService: IPreferenceService) {
         'ngInject';
      }

      public isFavorite: boolean;
      public type: favoriteType;
      public state: string;
      public param: string;
      public id: string;
      public maintitle: string;
      public subtitle: string;

      public toggle() {
         if (this.isFavorite) {
            const index = _.findIndex(this.preferenceService.favorites, f => { return f.type === this.type && f.id === this.id; });
            this.preferenceService.favorites.splice(index, 1);
         } else {
            this.preferenceService.favorites.unshift({ type: this.type, state: this.state, param: this.param, id: this.id, title: this.maintitle, subtitle: this.subtitle });
         }
         this.preferenceService.save();
         this.isFavorite = !this.isFavorite;
      }

      private $onChanges = () => {
         const index = _.findIndex(this.preferenceService.favorites, f => { return f.type === this.type && f.id === this.id; });
         this.isFavorite = index > -1;
      };
   }
}