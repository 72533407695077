namespace app.utils {
   const filter = () => {
      return (phone: string) => {
         if (phone) {
            let value = phone.replace(/^\+1/, '');
            return `(${value.substr(0, 3)}) ${value.substr(3, 3)}-${value.substr(6, 4)}`;
         }
      };
   };

   angular
      .module('app.utils')
      .filter('phone', filter);
}