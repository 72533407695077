namespace app.clientCases {
   'use strict';

   export const caseBasicInfoResolver = (datacontext: data.IDataContextService, $stateParams: angular.ui.IStateParamsService) => {
      'ngInject';
      const { clientCaseId } = $stateParams;
      return datacontext.clientCases.getClientCaseBasicInfoView(clientCaseId);
   };

   export const legalTeamsResolver = (datacontext: data.IDataContextService) => {
      'ngInject';
      return datacontext.config.legalTeams.getLegalTeams(false);
   };

   export const showClientCaseNameResolver = (datacontext: data.IDataContextService) => {
      'ngInject';
      const bypassReasonsPromise = datacontext.config.getConfigurationParameter<boolean>('ClientCase.ShowCaseName', false);
      return bypassReasonsPromise.then(result => {
         return result.toString() === 'true';
      });
   };

   export const showPovertyLevelResolver = (datacontext: data.IDataContextService) => {
      'ngInject';
      const bypassReasonsPromise = datacontext.config.getConfigurationParameter<boolean>('Entities.PovertyLevel', false);
      return bypassReasonsPromise.then(result => {
         return result.toString() === 'true';
      });
   };

   export const showAtPovertyLevelResolver = (datacontext: data.IDataContextService) => {
      'ngInject';
      const bypassReasonsPromise = datacontext.config.getConfigurationParameter<boolean>('Entities.AtPovertyLevel', false);
      return bypassReasonsPromise.then(result => {
         return result.toString() === 'true';
      });
   };
   export const showRaceSubCategoryResolver = (datacontext: data.IDataContextService) => {
      'ngInject';
      const bypassReasonsPromise = datacontext.config.getConfigurationParameter<boolean>('Entities.RaceSubCategory', false);
      return bypassReasonsPromise.then(result => {
         return result.toString() === 'true';
      });
   };
}