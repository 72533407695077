namespace app.core.routing {
   class RoutingConfig implements angular.IServiceProvider {
      public config = <IRoutingConfig>{};
      public $get(): IRoutingConfig {
         return this.config;
      }
   }

   angular
      .module('app.core.routing')
      .provider('routingConfig', RoutingConfig)
      .config(configure);

   function configure(
      $stateProvider: angular.ui.IStateProvider,
      $urlRouterProvider: angular.ui.IUrlRouterProvider,
      routingConfigProvider: RoutingConfig) {
      'ngInject';

      const config = routingConfigProvider.config;
      config.$stateProvider = $stateProvider;

      $urlRouterProvider.otherwise('/');

      // setup root state
      $stateProvider.state(<any>{
         name: 'app',
         url: '',
         template: '<ce-app layout flex></ce-app>'
      });
   }
}