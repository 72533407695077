namespace app.utils {
   @Component('app.utils', 'aimsProgressCircle', {
      bindings: {
         value: '<',
         size: '@',
         fractionSize: '@'
      },
      template: `
<div class="c100 center blue p{{$ctrl.value|number:0}}" ng-class="$ctrl.size">
  <span>{{$ctrl.value|number:$ctrl.fractionSize}}%</span>
  <div class="slice">
    <div class="bar"></div>
    <div class="fill"></div>
  </div>
</div>`
   })
   class ProgressCircle {
      public fractionSize: number;

      private $onInit() {
         this.fractionSize = this.fractionSize || 0;
      }
   }
}