namespace app.activities {
   export interface IActivityRepository {
      createActivity(data: ICreateActivity): angular.IPromise<IActivity>;
      getActivity(activityId: string): angular.IPromise<IActivity>;
      postComment(activityId: string, data: IPostCommentData): angular.IPromise<IEvent>;
      searchActivities(params: ISearchActivitiesData): data.IPagedListPromise<IActivity>;
   }

   const baseUrl = 'api/activities';

   class ActivityRepository implements IActivityRepository {
      constructor(private $http: angular.IHttpService) {
         'ngInject';
      }

      @ApiExecutor()
      public createActivity(data: ICreateActivity) {
         return this.$http.post<IActivity>(`${baseUrl}/activity`, data) as any;
      }

      @ApiExecutor()
      public getActivity(activityId: string) {
         return this.$http.get<IActivity>(`${baseUrl}/${activityId}`) as any;
      }

      @ApiExecutor()
      public postComment(activityId: string, data: IPostCommentData) {
         return this.$http.post<IEvent>(`${baseUrl}/${activityId}/events/comments`, data) as any;
      }

      @ApiExecutor()
      public searchActivities(data: ISearchActivitiesData) {
         return this.$http.post<data.IPagedList<IActivity>>(`${baseUrl}/search`, data) as any;
      }
   }

   angular
      .module('app.activities')
      .service('activities.repository', ActivityRepository);
}