namespace app.utils {
   export interface ISsnFilter {
      (value: string): string;
   }

   angular
      .module('app.utils')
      .filter('ssn', () => (ssn: string) => {
         if (ssn && ssn.length === 9) {
            return `${ssn.substr(0, 3)}-${ssn.substr(3, 2)}-${ssn.substr(5, 4)}`;
         }
      });
}