namespace app.analysis {
   @Component('app.analysis', 'aimsAnalysisCreateChart', {
      templateUrl: 'app/analysis/views/create-chart/create-chart.html',
      bindings: {
         query: '<'
      }
   })
   class NewChartViewComponent {
      static $inject = ['$scope', 'common', 'datacontext'];
      constructor(
         private $scope: angular.IScope,
         private common: core.ICommonService,
         private datacontext: data.IDataContextService) {
         'ngInject';
      }

      private steppers: any;

      public chartData: IChartData[];
      public data = {
         addToDashboard: true,
         metadata: {
            aggregateBy: 'Count',
            aggregateField: '*',
            groupBy: [],
            sortBy: 'Value',
            sortDirection: 'Desc'
         }
      } as ICreateChartData;
      public dataSource: IDataSource;
      public isSubmitting = false;
      public query: IQuery;

      public submit() {
         this.isSubmitting = true;
         this.datacontext.analysis
            .createChart(this.data)
            .then(result => {
               if (result) {
                  this.common.$state.go('app.analysis.query.charts', { queryId: this.query.id });
               }
            }).finally(() => this.isSubmitting = false);
      }

      private $onInit() {
         this.getDataSource();
         this.$scope.$watch(() => this.data.metadata, this.previewChart, true);
      }

      private $onChanges() {
         this.data.queryId = this.query.id;
      }

      private getDataSource() {
         this.datacontext.analysis
            .getDataSource(this.query.dataSourceId)
            .then(result => this.dataSource = result);
      }

      private previewChart = () => {
         if (!this.validateMetadata()) {
            this.chartData = null;
            return;
         }

         return this.datacontext.analysis
            .previewChartData(this.query.id, { metadata: this.data.metadata })
            .then(result => this.chartData = result);
      };

      private validateMetadata = () => {
         const { metadata } = this.data;

         if (!metadata.aggregateBy) return false;
         if (!metadata.aggregateField) return false;
         if (!metadata.groupBy.length) return false;
         if (!metadata.sortBy) return false;
         if (!metadata.sortDirection) return false;
         if (!metadata.type) return false;

         return true;
      };
   }
}