namespace app.systemicCases.activities {
   const routes: core.routing.IStateExtended[] = [
      {
         name: 'app.systemicCases.activities',
         url: '/activities',
         template: '<ui-view layout flex></ui-view>',
         redirectTo: 'app.systemicCases.activities.list'
      },
      {
         name: 'app.systemicCases.activities.list',
         url: '',
         template: '<ce-systemic-case-activity-list case="$resolve.case" layout="column" flex></ce-systemic-case-activity-list>',
         data: {
            title: 'Systemic Case Activities',
            permissions: ['access_activities']
         }
      },
      {
         name: 'app.systemicCases.activities.note',
         url: '/note',
         template: `<ce-systemic-cases-activities-note layout="column" flex
                        involvement-id="$resolve.case.involvementId"
                        case-Id="$resolve.case.id"
                        activity-Type="SystemicCaseNote"
                        name="Note"
                        activity="$resolve.activity"
                        case-open-date="$resolve.case.openDate"
                        return-sref="app.systemicCases.activities.list">
                    </ce-systemic-cases-activities-note>`,
         params: { activity: null },
         resolve: { activity: ['$stateParams', p => p.activity] },
         data: { title: 'Note' }
      },
      {
         name: 'app.systemicCases.activities.caseReview',
         url: '/review',
         template: `<ce-systemic-case-review
                     involvement-id="$resolve.case.involvementId"
                     case-id="$resolve.case.id"
                     activity="$resolve.activity"
                     case-open-date="$resolve.case.openDate"
                     flex>
                    </ce-systemic-case-review>`,
         params: { activity: null },
         resolve: { activity: ['$stateParams', p => p.activity] },
         data: { title: 'Review' }
      },
      {
         name: 'app.systemicCases.activities.caseAssessmentMeeting',
         url: '/cam',
         template: `<ce-systemic-case-assessment-meeting
                     involvement-id="$resolve.case.involvementId"
                     source="Systemic Case"
                     source-id="$resolve.case.id"
                     show-communication-date="true"
                     activity="$resolve.activity"
                     case-open-date="$resolve.case.openDate"
                     flex>
                    </ce-systemic-case-assessment-meeting>`,
         params: { activity: null },
         resolve: { activity: ['$stateParams', p => p.activity] },
         data: { title: 'CAM' }
      },
      {
         name: 'app.systemicCases.activities.conflictCheck',
         url: '/conflictCheck',
         template: `<ce-conflict-check layout="column" flex
                     involvement-id="$resolve.case.involvementId"
                     source="Systemic Case"
                     source-Id="$resolve.case.id"
                     case-open-date="$resolve.case.openDate">
                    </ce-conflict-check>`,
         data: { title: 'Conflict Check' }
      },
      {
         name: 'app.systemicCases.activities.close',
         url: '/close',
         template: `<ce-systemic-cases-activities-close flex
                     case="$resolve.case">
                    </ce-systemic-cases-activities-close>`,
         data: { title: 'Close' }
      },
      {
         name: 'app.systemicCases.activities.reopen',
         url: '/reopen',
         template: `<ce-systemic-cases-activities-reopen flex
                     case="$resolve.case">
                    </ce-systemic-cases-activities-reopen>`,
         data: { title: 'Reopen' }
      },
      {
         name: 'app.systemicCases.activities.createClientCase',
         url: '/createclientcase',
         template: `<ce-create-client-case layout="column" flex
                        involvement-id="$resolve.case.involvementId"
                        systemic-case-id="$resolve.case.id"
                        case-open-date="$resolve.case.openDate">
                    </ce-create-client-case>`,
         data: {
            title: 'Create Client Case',
            permissions: ['client-cases.edit']
         }
      },
      {
         name: 'app.systemicCases.activities.createInformationAndReferral',
         url: '/createInformationAndReferral',
         template: `<ce-create-information-and-referral layout="column" flex
                        involvement-id="$resolve.case.involvementId"
                        systemic-case-id="$resolve.case.id"
                        case-open-date="$resolve.case.openDate">
                    </ce-create-information-and-referral>`,
         data: {
            title: 'Create I&R',
            permissions: ['i-and-rs.edit']
         }
      },
      {
         name: 'app.systemicCases.activities.statusChange',
         url: '/statusChange',
         template: `<am-activities-status-change layout="column" flex
                        [involvement-id]="$resolve.case.involvementId"
                        [source-id]="$resolve.case.id"
                        source="systemiccases"
                        [activity-id]="$resolve.activityId">
                    </am-activities-status-change>`,
         params: { activityId: null },
         resolve: { activityId: ['$stateParams', p => p.activityId] },
         data: {
            title: 'Status Change',
            permissions: ['systemic-cases.edit']
         }
      }
   ];

   const runner = (routingHelper: core.routing.IRoutingHelperService) => {
      'ngInject';
      routingHelper.configureRoutes(routes);
   };

   angular
      .module('app.systemicCases.activities')
      .run(runner);
}