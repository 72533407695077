namespace app.training.training {
   @Component('app.training.training', 'ceTrainingCreate', {
      templateUrl: 'app/training/training/create/create.html',
      bindings: {
         trainingId: '<',
         onCancel: '&',
         onSubmit: '&'
      }
   })
   class TrainingInformationForm {
      static $inject = ['datacontext', 'common'];
      constructor(private datacontext: data.IDataContextService,
         private common: core.ICommonService) {
         'ngInject';
      }
      public onSubmit: (args: { result: ITraining }) => void;
      public data = {} as ITraining;
      public isLoading: boolean = false;
      public isSubmitting: boolean = false;
      public subjectMatters: app.config.ILookup[];
      public disabilityTypes: app.config.ILookup[];
      public trainingForums: app.config.ILookup[];

      public $onInit() {
         this.getData();
         this.data.forum = [];
         this.data.subjectMatter = [];
         this.data.disabilityType = [];
      }

      public forumToggle(value: string) {
         if (this.forumExists(value)) {
            _.pull(this.data.forum, value);
         }
         else {
            this.data.forum.push(value);
         }
      }

      public forumExists(value: string) {
         return _.includes(this.data.forum, value);
      }

      public subjectMatterToggle(value: string) {
         if (this.subjectMatterExists(value)) {
            _.pull(this.data.subjectMatter, value);
         }
         else {
            this.data.subjectMatter.push(value);
         }
      }

      public subjectMatterExists(value: string) {
         return _.includes(this.data.subjectMatter, value);
      }

      public disabilityTypeToggle(value: string) {
         if (value === '') {
            return;
         }

         if (this.disabilityTypeExists(value)) {
            _.pull(this.data.disabilityType, value);
         }
         else {
            this.data.disabilityType.push(value);
         }
      }

      public disabilityTypeExists(value: string) {
         if (this.isLoading) {
            return;
         }
         return _.includes(this.data.disabilityType, value);
      }

      public getData() {
         this.isLoading = true;
         this.datacontext.config.lookups.getLookupType('training-subject-matter').then(
            result => this.subjectMatters = result.lookups
         ).finally(() => this.isLoading = false);
         this.datacontext.config.lookups.getLookupType('training-disability').then(
            result => this.disabilityTypes = result.lookups
         );
         this.datacontext.config.lookups.getLookupType('training-forum').then(
            result => this.trainingForums = result.lookups
         );
      }

      public submit() {
         this.data.status = 'Scheduled';
         this.isSubmitting = true;
         this.datacontext.training
            .createTraining(this.data)
            .then(result => {
               if (result) this.common.$state.go('^.training', { trainingId: result });
            }).finally(() => this.isSubmitting = false);
      }
   }
}