namespace app.reports {
   @Controller('app.reports', 'ReportDialogController')
   class ReportDialogController {
      static $inject = ['blobHelper', '$mdDialog', 'common', 'datacontext', 'report'];
      constructor(
         private blobHelper: utils.IBlobHelperService,
         private $mdDialog: angular.material.IDialogService,
         private common: core.ICommonService,
         private datacontext: data.IDataContextService,
         private report: IReport) {
         'ngInject';
      }

      public isSubmitting: boolean;
      public selectedSource: common.ISource;
      public selectedUser: users.IUser;

      private $onInit() {
      }

      public sourceSearch(searchText: string) {
         return this.datacontext.time
            .getSources(searchText)
            .then(results => _.map(results, (r) => {
               if (!r) {
                  return;
               }
               return r;
            }));
      }

      public setSource(source: app.common.ISource) {
         this.selectedSource = source;
         let sourceTypeParam = _.find(this.report.parameters, p => p.name === 'SourceType');
         sourceTypeParam.value = source.source;
         let sourceIdParam = _.find(this.report.parameters, p => p.name === 'SourceId');
         sourceIdParam.value = source.sourceId;
         let sourceNameParam = _.find(this.report.parameters, p => p.name === 'SourceName');
         sourceNameParam.value = source.sourceDescription;
      }

      public timeslipUserSearch(searchText: string) {
         return this.datacontext.users
            .getTimeslipUsers(searchText);
      }

      public userSearch(searchText: string) {
         return this.datacontext.users
            .getUsers(searchText);
      }

      public setUser(user: users.IUser) {
         this.selectedUser = user;
         let userIdParam = _.find(this.report.parameters, p => p.name === 'UserId');
         userIdParam.value = user.id;
         let userNameParam = _.find(this.report.parameters, p => p.name === 'UserName');
         userNameParam.value = user.fullName;
      }

      public cancel() {
         this.$mdDialog.cancel();
      }

      public submit() {
         let contentType: string;
         this.processParameters();

         this.isSubmitting = true;
         const data: reports.IRunReportData = {
            name: this.report.name.replace(/ /g, ''),
            format: this.report.format,
            parameters: this.report.parameters
         };

         switch (this.report.format) {
            case ('xlsx'): {
               contentType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
               break;
            }
            case ('docx'): {
               contentType = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
               break;
            }
            default: {
               contentType = 'application/pdf';
               break;
            }
         }

         this.datacontext.reports
            .runReport(data)
            .then(buffer => {
               if (buffer) {
                  this.blobHelper.open({
                     buffer,
                     contentType: contentType,
                     fileName: `${this.report.name}.${this.report.format}`
                  });
               }
            }).then(() => this.$mdDialog.hide());
      }

      public processParameters() {
         // if you have a dictionary parameter, it's id/key only (often a guid) will be passed as a parameter
         // add an extra parameter for the name/value of the dictionary item
         // this allows you to use the value of the parameter in the header of reports
         _.forEach(this.report.parameters, p => {
            if (p.dataType === 'DictionarySelect') {
               let name = p.name.replace('Id', 'Name');
               let nameParameter = _.find(this.report.parameters, p => { return p.name === name; });
               if (!nameParameter) {
                  this.report.parameters.push(
                     {
                        name: p.name.replace('Id', 'Name'),
                        value: p.dictionaryOptions[p.value]
                     }
                  );
               } else {
                  nameParameter.value = p.dictionaryOptions[p.value];
               }
            }
         });
      }
   }
}