namespace app.entities.details {
   @Component('app.entities.details', 'ceEntityEmailAddresses', {
      templateUrl: 'app/entities/details/email-address/email-addresses.html',
      bindings: {
         entityId: '<'
      }
   })
   class EmailAddresses {
      static $inject = ['common', 'datacontext'];
      constructor(
         private common: core.ICommonService,
         private datacontext: data.IDataContextService) {
         'ngInject';
      }

      public editMode = false;
      public mouseOver: boolean = false;
      public entityId: string;
      public emailAddresses: IEntityEmailAddress[];
      public selectedEmailAddress: IEntityEmailAddress;

      public deleteEmailAddress(emailAddress: IEntityEmailAddress) {
         this.selectedEmailAddress = emailAddress;
         this.common.confirmation.show({
            title: 'Delete Phone Number',
            message: 'Are you sure you want to delete this emailAddress number?',
            ok: 'Delete',
            cancel: 'Keep'
         }).then(this.deleteEmailAddressCore);
      }

      public cancelEdit() {
         this.editMode = false;
         this.selectedEmailAddress = null;
      }

      public addEmailAddress() {
         let emailAddress = {} as IEntityEmailAddress;
         // emailAddress.emailAddressType = this.defaultEmailAddressType;
         this.editEmailAddress(emailAddress);
      }

      public editEmailAddress(emailAddress: IEntityEmailAddress) {
         this.selectedEmailAddress = emailAddress;
         this.editMode = true;
      }

      public saveEmailAddress(data: IEntityEmailAddress) {
         data.entityId = this.entityId;
         const promise = this.selectedEmailAddress.id
            ? this.datacontext.entities.updateEntityEmailAddress(data)
            : this.datacontext.entities.createEntityEmailAddress(data);

         if (data.isPrimary) {
            this.emailAddresses.forEach(a => a.isPrimary = false);
         }

         promise.then(result => {
            if (result) {
               if (!this.selectedEmailAddress.id) {
                  this.emailAddresses.push(result);
               } else {
                  angular.extend(this.selectedEmailAddress, result);
               }

               this.cancelEdit();
            }
         });
      }

      private $onInit() {
         // if (this.common.auth.checkPermission('person.emailAddresses')) {
         this.getEmailAddresses();
         // }
      }

      private deleteEmailAddressCore = () => {
         this.datacontext.entities.deleteEntityEmailAddress(this.selectedEmailAddress)
            .then(() => {
               const index = this.emailAddresses.indexOf(this.selectedEmailAddress);
               this.emailAddresses.splice(index, 1);
               this.cancelEdit();
            });
      };

      private getEmailAddresses = () => {
         this.datacontext.entities
            .getEntityEmailAddresses(this.entityId)
            .then(result => this.emailAddresses = result);
      };
   }
}