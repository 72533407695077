namespace app.clientCases.closure {
   @Component('app.clientCases.closure', 'ceClosureSpecialEducationForm', {
      templateUrl: 'app/client-cases/closure/closure-special-education/closure-special-education-form.html',
      bindings: {
         caseId: '<',
         involvementId: '<',
         onCancel: '&',
         onSubmit: '&'
      }
   })

   class ClosureSpecialEducationForm {
      static $inject = ['datacontext', 'common'];
      constructor(
         private datacontext: data.IDataContextService,
         private common: core.ICommonService) {
         'ngInject';
      }

      public onSubmit: (args: { result: IClosureSpecialEducation }) => void;
      public closureSpecialEducationOptions: IClosureSpecialEducationOption[];
      public specialEducation = {} as IClosureSpecialEducation;
      public isLoading: boolean = false;
      public isSubmitting: boolean = false;
      public caseId: string;

      public $onInit() {
         this.getSpecialEducation();
         this.getClosureSpecialEducationOptions();
      }

      public getSpecialEducation() {
         this.isLoading = true;
         this.datacontext.clientCases
            .getSpecialEducation(this.caseId)
            .then(result => this.specialEducation = result)
            .finally(() => this.isLoading = false);
      }

      private getClosureSpecialEducationOptions() {
         this.datacontext.clientCases.getClosureSpecialEducationOptions()
            .then(results => this.closureSpecialEducationOptions = results);
      }

      public isEmpoweredChanged(empowermentId: number) {
         if (!this.specialEducation) {
            this.specialEducation = { empowermentId: 0, outcomes: [] } as IClosureSpecialEducation;
         }

         const t = _.find(this.closureSpecialEducationOptions, o =>
            o.text === 'Parents WERE empowered as a result of P&A intervention');

         if (empowermentId !== t.id) {
            this.specialEducation.outcomes = [];
            this.specialEducation.isEmpowered = false;
         } else {
            this.specialEducation.isEmpowered = true,

               this.specialEducation.empowermentId = empowermentId;
         }
      }

      public isEmpowered() {
         let isEmpowered: boolean;

         const t = _.find(this.closureSpecialEducationOptions, o =>
            o.text === 'Parents WERE empowered as a result of P&A intervention');

         let m;

         if (this.specialEducation) {
            m = this.specialEducation;
            if (m.empowermentId === t.id) {
               isEmpowered = true;
            }
         }
         else {
            isEmpowered = false;
         }
         return isEmpowered;
      }

      public outcomeSelected(outcomeText: string) {
         const m = this.specialEducation;
         return _.findIndex(m.outcomes, o => o === outcomeText) > -1;
      }

      public outcomeChanged(outcome: IClosureSpecialEducationOption) {
         const o = _.findIndex(this.specialEducation.outcomes, o => o === outcome.text) > -1;

         if (!o) {
            this.specialEducation.outcomes.push(outcome.text);
         } else {
            _.pull(this.specialEducation.outcomes, outcome.text);
         }
      }

      public submit() {

         if (!this.specialEducation) {
            this.specialEducation = { empowermentId: 0, outcomes: [] } as IClosureSpecialEducation;
         }
         if (this.specialEducation.outcomes.length === 0 && this.isEmpowered() || this.specialEducation.empowermentId === 0) {
            return;
         }
         this.isSubmitting = true;
         this.specialEducation.id = this.caseId;

         let promise = this.common.$q.when<string>('');

         promise.then(() => this.datacontext.clientCases
            .updateSpecialEducation(this.specialEducation)
            .then(result => {
               if (result) this.onSubmit({ result });
            })).finally(() => this.isSubmitting = false);
      }
   }
}