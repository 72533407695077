namespace app.training.training.activities {
   interface IActivityViewModel {
      icon: string;
      text: string;
      sref: string;
      activityType?: string;
      name?: string;
   }

   const activities: IActivityViewModel[] = [
      {
         icon: 'comment',
         text: 'Note',
         sref: '^.note',
      }
   ];

   @Controller('app.training.training.activities', 'NewTrainingActivityDialogController')
   class NewTrainingActivityDialogController {
      static $inject = ['$mdDialog', 'common', 'trainingId'];
      constructor(
         private $mdDialog: angular.material.IDialogService,
         private common: core.ICommonService,
         private trainingId: string) {
         'ngInject';
      }

      public filteredActivities: IActivityViewModel[];

      private $onInit() {
         this.filteredActivities = activities;
      }

      public selectActivity(item: IActivityViewModel) {
         this.common.$state.go(item.sref, { trainingId: this.trainingId });
         this.$mdDialog.hide();
      }
   }
}