namespace app.persons {
   export interface IPersonRepository {
      createPerson(data: ICreatePerson): angular.IPromise<IPerson>;
      deletePhoto(id: string): angular.IPromise<boolean>;
      getDemographics(personId: string): angular.IPromise<IDemographics>;
      getSpecialConsiderations(personId: string): angular.IPromise<ISpecialConsiderations>;
      getImportantInformation(personId: string): angular.IPromise<IPersonImportantInformation>;
      getPerson(id: string): angular.IPromise<IPerson>;
      getPersons(params: IGetPersonsParams): data.IPagedListPromise<IPerson>;
      getUpcomingDates(personId: string): angular.IPromise<IUpcomingDate[]>;
      linkPhoto(id: string, photoId: string): angular.IPromise<boolean>;
      updateDemographics(personId: string, data: IDemographics): angular.IPromise<IDemographics>;
      updateBaseDemographics(personId: string, data: IBaseDemographics): angular.IPromise<IDemographics>;
      updateSpecialConsiderations(personId: string, data: ISpecialConsiderations): angular.IPromise<ISpecialConsiderations>;
      merge(winnerId: string, loserId: string): angular.IPromise<boolean>;
   }

   const baseUrl = 'api/persons';

   class PersonRepository implements IPersonRepository {
      constructor(
         private $http: angular.IHttpService,
         private exception: core.exception.IExceptionService) {
         'ngInject';
      }

      @ApiExecutor()
      public createPerson(data: ICreatePerson) {
         return this.$http.post<IPerson>(baseUrl, data) as any;
      }

      @ApiExecutor({ returnValue: true })
      public deletePhoneNumber(id: string, phoneNumberId: string) {
         return this.$http.delete<boolean>(`${baseUrl}/${id}/phone-numbers/${phoneNumberId}`) as any;
      }

      @ApiExecutor({ returnValue: true })
      public deletePhoto(id: string) {
         return this.$http.delete<boolean>(`${baseUrl}/${id}/photo`) as any;
      }

      @ApiExecutor()
      public getDemographics(personId: string) {
         return this.$http.get<IDemographics>(`${baseUrl}/${personId}/demographics`) as any;
      }

      @ApiExecutor()
      public getSpecialConsiderations(personId: string) {
         return this.$http.get<ISpecialConsiderations>(`${baseUrl}/${personId}/specialconsiderations`) as any;
      }

      @ApiExecutor()
      public getPerson(id: string) {
         return this.$http.get<IPerson>(`${baseUrl}/${id}`) as any;
      }

      @ApiExecutor()
      public getPersons(params: IGetPersonsParams) {
         return this.$http.get<data.IPagedList<IPerson>>('api/persons', { params }) as any;
      }

      @ApiExecutor()
      public getUpcomingDates(personId: string) {
         return this.$http.get<IUpcomingDate[]>(`${baseUrl}/${personId}/upcoming-dates`) as any;
      }
      @ApiExecutor()
      public getImportantInformation(personId: string) {
         return this.$http.get<IPersonImportantInformation>(`${baseUrl}/${personId}/importantinformation`) as any;
      }
      @ApiExecutor({ returnValue: true })
      public linkPhoto(id: string, photoId: string) {
         return this.$http.put<boolean>(`${baseUrl}/${id}/photo`, { photoId }) as any;
      }
      @ApiExecutor()
      public updateDemographics(personId: string, data: IDemographics) {
         return this.$http.put<IDemographics>(`${baseUrl}/${personId}/demographics`, data) as any;
      }
      @ApiExecutor()
      public updateBaseDemographics(personId: string, data: IDemographics) {
         return this.$http.put<IDemographics>(`${baseUrl}/${personId}/demographics`, data) as any;
      }
      @ApiExecutor()
      public updateSpecialConsiderations(personId: string, data: ISpecialConsiderations) {
         return this.$http.put<ISpecialConsiderations>(`${baseUrl}/${personId}/specialconsiderations`, data) as any;
      }
      @ApiExecutor()
      public merge(winnerId: string, loserId: string) {
         return this.$http.post(`${baseUrl}/merge`, {winnerId: winnerId, loserId: loserId}) as any;
      }
   }

   angular
      .module('app.persons')
      .service('persons.repository', PersonRepository);
}