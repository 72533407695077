namespace app.utils {
   export interface IUploadFileRequest {
      url?: string;
      file: any;
   }

   export interface IUploadFileResult {
      id: string;
   }

   export interface IFileUploaderService {
      upload(request: IUploadFileRequest): angular.angularFileUpload.IUploadPromise<IUploadFileResult>;
   }

   class FileUploaderService implements IFileUploaderService {
      constructor(private Upload: angular.angularFileUpload.IUploadService) {
         'ngInject';
      }

      public upload(request: IUploadFileRequest) {
         const { url, file } = request;

         const data: angular.angularFileUpload.IFileUploadConfigFile = {
            url: url || 'api/photos',
            method: 'POST',
            data: { file }
         };

         return this.Upload.upload<IUploadFileResult>(data);
      }
   }

   angular
      .module('app.utils')
      .service('fileUploader', FileUploaderService);
}