namespace app.analysis {
   @Component('app.analysis', 'aimsAnalysisQueryCharts', {
      templateUrl: 'app/analysis/views/query-details/query-charts.html',
      bindings: {
         query: '<',
         charts: '<'
      }
   })
   class QueryCharts {
      public charts: IChart[];

      public onDelete(chart: IChart) {
         const index = this.charts.indexOf(chart);
         this.charts.splice(index, 1);
      }
   }
}