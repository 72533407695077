namespace app.funding {
   @Component('app.funding', 'cePaimiSystemicOutcomeForm', {
      templateUrl: 'app/funding/enrollments/paimi/systemic/paimi-outcome-form.html',
      bindings: {
         outcome: '<',
         onCancel: '&',
         onSubmit: '&'
      }
   })
   class PAIMISystemicOutcomeForm {
      static $inject = ['common', 'datacontext'];
      constructor(
         private common: core.ICommonService,
         private datacontext: data.IDataContextService) {
         'ngInject';
      }

      public systemicOutcomeOptions: IPAIMIOption[];
      private selectedOutcomeOptions: IPAIMIOption[];
      public outcome: IPAIMISystemicOutcome;

      public $onInit() {
         this.getPAIMISystemicOutcomeOptions();

      }

      private getPAIMISystemicOutcomeOptions() {
         this.datacontext.funding.getPAIMISystemicOutcomeOptions()
            .then(results => this.systemicOutcomeOptions = results);
      }

      public outcomeOptionChanged(option: IPAIMIOption) {
         if (!this.outcome.interventionOutcomes) {
            this.outcome.interventionOutcomes = [];
         }

         const index = _.findIndex(this.outcome.interventionOutcomes, o => o === option.text);
         if (index === -1) {
            this.outcome.interventionOutcomes.push(option.text);
         }
         else {
            _.remove(this.outcome.interventionOutcomes, p => p === option.text);
         }
      }

      public outcomeOptionSelected(option: string) {
         return _.findIndex(this.outcome.interventionOutcomes, p => p === option) > -1;
      }
   }
}