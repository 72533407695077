namespace app.training {
   @Component('app.training.entities', 'ceTrainingEntitySummaryList', {
      templateUrl: 'app/training/entities/entity-listing/summary-list.html',
      bindings: {
         entities: '<',
         totalEntities: '<'
      }
   })
   class EntityList {
   }
}