namespace app.clientCases {
   @Component('app.clientCases', 'ceClientCaseNoTimeslipsListing', {
      templateUrl: 'app/client-cases/no-timeslips/no-timeslips-listing.html',
      bindings: {
         bucket: '<',
         userIds: '<'
      }
   })
   class ClientCaseNoTimeslipsListing {
      static $inject = ['datacontext'];
      constructor(
         private datacontext: data.IDataContextService) {
         'ngInject';
      }

      public bucket: number;
      public userIds: string[];

      public cases: data.IPagedList<clientCases.IClientCaseListingView>;
      public totalCases: number;
      public promise: angular.IPromise<any>;
      public paging: data.IPagedListParams = {
         page: 1,
         pageSize: 100
      };

      public $onInit() {
         this.getCases();
      }

      private getCases = () => {
         const params: clientCases.IGetClientCasesNoTimeslipsParams = {
            bucket: this.bucket,
            userIds: this.userIds,
            ...this.paging
         };

         this.promise = this.datacontext.clientCases.getClientCaseNoTimeslipsListing(params)
            .then((result) => {
               this.cases = result;
            });
      };
   }
}