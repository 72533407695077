namespace app.core.routing {
   export interface IRoutingHelperService {
      back(fallbackUrl: string);
      configureRoutes(routes: IStateExtended[]);
   }

   class RoutingHelperService implements IRoutingHelperService {
      constructor(
         private $rootScope: IRootScopeService,
         private $location: angular.ILocationService,
         private $state: angular.ui.IStateService,
         private routingConfig: IRoutingConfig) {
         'ngInject';
      }

      public back(fallbackUrl: string) {
         const { previousState } = this.$rootScope;
         if (previousState.name) {
            this.$state.go(previousState.name, previousState.params);
         } else if (fallbackUrl) {
            this.$location.path(fallbackUrl.replace(/^\#/, ''));
         } else {
            throw new Error('fallbackUrl not provided');
         }
      }

      public configureRoutes(routes: IStateExtended[]) {
         angular.forEach(routes, (route) => {
            const data = route.data || {};
            route.data = data;

            if (data.redirectPreference) {
               data.redirectFallback = route.redirectTo;
               route.redirectTo = redirectHandler;
            }

            this.routingConfig.$stateProvider.state(route);
         });
      }
   }

   angular
      .module('app.core.routing')
      .service('routingHelper', RoutingHelperService);
}