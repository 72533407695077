namespace app.requests.activities {
   @Component('app.requests.activities', 'ceRequestsActivitiesReopen', {
      templateUrl: 'app/requests/activities/reopen/reopen.html',
      bindings: {
         request: '<'
      }
   })
   class Reopen {
      static $inject = ['common', 'datacontext'];
      constructor(
         private common: core.ICommonService,
         private datacontext: data.IDataContextService) {
         'ngInject';
      }

      public request: IRequestBasicInfo;
      public isSubmitting: boolean = false;
      public activity = {} as ICreateRequestActivity;

      private $onInit() {
         this.activity.requestId = this.request.id;
         this.activity.involvementId = this.request.involvementId;
         this.activity.activityDate = moment().toDate();
      }

      public submit() {
         this.isSubmitting = true;

         if (typeof this.activity.activityDate !== 'string') {
            this.activity.activityDate = this.activity.activityDate.toDateString();
         }

         this.datacontext.requests
            .reopen(this.activity)
            .then(request => {
               if (request) {
                  this.common.$mdToast.showSimple('Request Reopened');
                  this.common.$rootScope.$broadcast('refreshBasicInfo');
                  this.request.isActive = true;
                  this.common.$state.go('^.list', { requestId: this.request.id, involvementId: this.request.involvementId });
               }
            })
            .finally(() => this.isSubmitting = false);
      }
   }
}