namespace app.mail {
   @Component('app.mail', 'ceMailMessageListToolbar', {
      templateUrl: 'app/mail/inbox/message-list-toolbar.html',
      bindings: {
         messagesSelected: '<',
         onDelete: '&',
         onFetch: '&',
         onLink: '&',
         onFilter: '&'
      }
   })
   class MessageListToolbar {
      public messagesSelected: IMessage[];
      public showMyMessagesOnly: boolean = true;
      public subjectFilter: string;
      public onFilter: (args: { myMessagesOnly: boolean, q: string }) => void;

      public clearSelected() {
         this.messagesSelected.splice(0);
      }

      public filterMyMessagesOnly() {
         this.showMyMessagesOnly = !this.showMyMessagesOnly;
         this.filterMessages();
      }

      public filterMessages() {
         this.onFilter({ myMessagesOnly: this.showMyMessagesOnly, q: this.subjectFilter });
      }
   }
}