namespace app.involvements {
   interface ISortedEntityListing extends IEntityListing {
      sequence: number;
      sortName: string;
   }

   @Component('app.involvements', 'cePartySummary', {
      templateUrl: 'app/involvements/parties/party-summary.html',
      bindings: {
         involvementId: '<'
      }
   })
   class PartySummary {
      static $inject = ['$mdDialog', 'common', 'datacontext'];
      constructor(
         private $mdDialog: angular.material.IDialogService,
         private common: core.ICommonService,
         private datacontext: data.IDataContextService) {
         'ngInject';
      }

      public parties: IEntityListing[];
      public involvementId: string;

      public getParties() {
         this.datacontext.involvements.getPartySummary(this.involvementId)
            .then(parties => {
               this.parties = parties;
               this.sortParties();
            }).then(() => angular.element('#partysummarycard').attr('aria-hidden', 'false'));
      }

      private $onInit() {
         this.getParties();
      }

      private sortParties() {
         let sortedParties: ISortedEntityListing[] = [];
         sortedParties = _.map(this.parties, p => {
            let s = p as ISortedEntityListing;
            if (_.includes(s.roles, 'PWD')) {
               s.sequence = 0;
               return s;
            }
            if (_.includes(s.roles, 'Client')) {
               s.sequence = 1;
               return s;
            }
            if (_.includes(s.roles, 'Caller')) {
               s.sequence = 2;
               return s;
            }
            if (_.includes(s.roles, 'Adverse Party')) {
               s.sequence = 3;
               return s;
            }
         });
         this.parties = _.sortBy(sortedParties, ['sequence', 'sortName']);
      }
   }
}