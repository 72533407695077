namespace app.projects.details {
   @Component('app.projects.details', 'ceProjectDetails', {
      templateUrl: 'app/projects/details/details.html',
      bindings: {
         data: '<',
         fundingPrograms: '<'
      }
   })
   class ProjectDetails {
      static $inject = ['datacontext'];
      constructor(private datacontext: data.IDataContextService) {
         'ngInject';
      }

      public data: IProject;
      public editProject: boolean = false;
      public fundingPrograms: any[];

      public $onInit() {
      }

      public updateProject(result: IProject) {
         angular.extend(this.data, result);
         this.editProject = false;
      }
   }
}