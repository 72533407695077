namespace app.projects {
   @Component('app.projects', 'ceProjectEntityList', {
      templateUrl: 'app/projects/entities/entity-listing/entity-list.html',
      bindings: {
         entities: '<',
         totalEntities: '<',
         projectId: '<',
         onDelete: '&'
      }
   })
   class ProjectEntityList {
   }
}