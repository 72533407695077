namespace app.agencies.details {
   @Component('app.agencies.details', 'ceAgencyNames', {
      templateUrl: 'app/agencies/details/names/names.html',
      bindings: {
         agency: '<',
      }
   })
   class AgencyNames {
      public agency: IAgencyBasicInfo;
   }
}