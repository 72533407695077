namespace app.clientCases {
   @Component('app.clientCases', 'ceClientCaseView', {
      templateUrl: 'app/client-cases/client-case/client-case-view.html',
      bindings: {
         case: '<'
      }
   })
   class ClientCaseView {
      static $inject = ['$mdDialog', 'common', 'confirmation', 'datacontext', 'timerService'];
      constructor(
         private $mdDialog: angular.material.IDialogService,
         private common: core.ICommonService,
         private confirmation: IConfirmationService,
         private datacontext: data.IDataContextService,
         private timerService: time.ITimerService) {
         'ngInject';
         this.$state = common.$state;
      }

      public isLoading: boolean = false;
      public case: IClientCaseBasicInfoView;
      public $state: angular.ui.IStateService;
      private unsubscribe: any;

      private $onInit() {
         this.unsubscribe = this.common.$rootScope.$on('refreshBasicInfo', this.refresh);
      }

      public showReportDialog() {
         let report = {
            category: 'Case Report',
            label: 'Notes Report',
            name: 'Notes Report',
            description: 'Notes Report',
            permission: 'reports.view',
            parameters: [
               { label: 'From Date', name: 'FromDate', dataType: 'Date', value: null },
               { label: 'To Date', name: 'ToDate', dataType: 'Date', value: null },
               { label: 'Note Type - Leave blank for All', name: 'NoteType', value: '', dataType: 'Select', options: ['', 'Case Description', 'CAM Decision', 'Case Note', 'Case Review', 'Closure Summary'] },
               { label: 'Case Type', name: 'CaseType', dataType: 'hidden', value: 'Client Case' },
               { label: 'CaseId', name: 'CaseId', dataType: 'hidden', value: this.case.id },
               { label: 'Involvement', name: 'InvolvementId', dataType: 'hidden', value: this.case.involvementId }
            ]
         } as app.reports.IReport;

         return this.$mdDialog.show({
            fullscreen: true,
            templateUrl: 'app/reports/report-dialog/report-dialog.html',
            controller: 'ReportDialogController',
            controllerAs: '$ctrl',
            focusOnOpen: false,
            locals: {
               report: report
            }
         })
            .catch((error) => { if (error) throw error; })
            .finally(() => angular.noop);
      }

      private $onDestroy() {
         this.unsubscribe();
      }

      private refresh = () => {
         this.datacontext.clientCases.getClientCaseBasicInfoView(this.case.id).then(
            clientCase => this.case = clientCase
         );
      }

      private showDeleteDialog(id: string) {
         this.confirmation.show({
            message: 'Are you sure you want to delete this Case? This will delete all data attached to this Case.',
            ok: 'DELETE',
            cancel: 'KEEP'
         }).then(() => { this.deleteCase(id); });
      }

      private deleteCase(id: string) {
         this.datacontext.clientCases.deleteClientCase(id).then((result) => {
            if (result) {
               this.timerService.killTimer();
               this.common.$state.go('app.clientCaseList');
            }
         });
      }
   }
}