namespace app.core {
   angular.module('app.core', [
      'ngMaterial',
      'ngMessages',
      'ngSanitize',
      'angularMoment',
      'ngFileUpload',
      'ngStorage',
      'md.data.table',
      'fixed.table.header',
      'mdSteppers',
      'ui.mask',
      'ui.validate',
      'luegg.directives', // angular-scroll-glue
      'easypiechart',
      'angular.filter',
      'angular-flot',
      'btford.markdown',
      'ngScrollbars',
      'ngAria',
      'app.core.logging',
      'app.core.exception',
      'app.core.routing',
      'app.core.security'
   ]);
}