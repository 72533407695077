namespace app.activities {
   const routes: core.routing.IStateExtended[] = [
      {
         name: 'app.activities',
         url: '/activities',
         template: '<ui-view layout flex></ui-view>',
         redirectTo: 'app.person.activities.list'
      },
      {
         name: 'app.activities.list',
         url: '',
         template: '<ce-activity-list-view layout flex person-id="$resolve.person.id"></ce-activity-list-view>'
      },
      {
         name: 'app.activities.activity',
         url: '/activities/activity?involvementId',
         template: '<ce-activities-activity layout flex involvement-id="$resolve.involvementId"></ce-activities-activity>',
         resolve: {
            involvementId: ['$stateParams', p => p.involvementId],
            name: ['$stateParams', p => p.name]
         }
      }
   ];

   const runner = (routingHelper: core.routing.IRoutingHelperService) => {
      'ngInject';
      routingHelper.configureRoutes(routes);
   };

   angular
      .module('app.activities')
      .run(runner);
}