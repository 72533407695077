namespace app.requests {
   'use strict';

   const routes: core.routing.IStateExtended[] = [
      {
         name: 'app.createRequest',
         url: '/requests/create',
         template: '<ce-requests-create-request layout="column" flex></ce-requests-create-request>',
         data: {
            title: 'Create Request',
            permissions: ['requests.edit']
         }
      },
      {
         name: 'app.requests',
         url: '/requests/{requestId}',
         template: '<ce-request-view request="$resolve.request" layout="column" flex></ce-request-view>',
         redirectTo: 'app.requests.overview',
         data: {
            title: 'Request View',
            permissions: ['requests.view']
         },
         resolve: { request: requestBasicInfoResolver }
      },
      {
         name: 'app.requests.overview',
         url: '/overview',
         template: '<ce-request-overview request="$resolve.request" layout="column" flex></ce-request-overview>',
         data: { title: 'Request Overview', permissions: ['requests.view'], }
      },
      {
         name: 'app.requests.details',
         url: '/details',
         template: '<ce-request-details request="$resolve.request" layout="column" flex></ce-request-details>',
         data: {
            title: 'Request Details', permissions: ['requests.view']
         },
      },
      {
         name: 'app.requests.timeslipListing',
         url: '/timeslips',
         template: '<ce-timeslip-listing involvement-id="$resolve.request.involvementId" source="Request" source-id="$resolve.request.id" source-name="$resolve.request.idNumber" source-name2="$resolve.request.requesterFullName"  enable-filters="false" layout="column" flex></ce-timeslip-listing>',
         data: {
            title: 'Request Timeslips',
            permissions: ['timeslips.view']
         }
      },
      {
         name: 'app.requests.timeslipCalendar',
         url: '/timeslips-calendar',
         template: '<ce-timeslip-calendar-frame involvement-id="$resolve.request.involvementId" source="Request" source-id="$resolve.request.id" source-name="$resolve.request.idNumber" source-name2="$resolve.request.requesterFullName"  enable-filters="false" layout="column" flex></ce-timeslip-calendar-frame>',
         data: {
            title: 'Request Timeslips',
            permissions: ['timeslips.view']
         }
      },
      {
         name: 'app.requests.documents',
         url: '/documents',
         template: '<ce-documents-list involvement-id="$resolve.request.involvementId" source="Request" source-id="$resolve.request.id" layout="column" flex></ce-document-list>',
         data: {
            title: 'Request Documents',
            permissions: ['documents.view']
         }
      },
      {
         name: 'app.requests.uploadDocuments',
         url: '/upload',
         params: {
            folderId: null
         },
         resolve: {
            folderId: ['$stateParams', p => p.folderId],
         },
         template: '<ce-upload-documents-activity layout flex folder-id="$resolve.folderId" involvement-id="$resolve.request.involvementId" source="Request" source-id="$resolve.request.id"></ce-upload-documents-activity>',
         data: { permissions: ['documents.edit'] }
      },
      {
         name: 'app.requests.emails',
         url: '/emails',
         template: '<ui-view flex layout="column"></ui-view>',
         redirectTo: 'app.requests.emails.listing',
         data: {
            title: 'Requests Emails',
            permissions: ['mail.view']
         },
      },
      {
         name: 'app.requests.emails.listing',
         url: '',
         template: '<ce-email-listing involvement-id="$resolve.request.involvementId" source="Request" source-id="$resolve.request.id" layout="column" flex></ce-email-listing>',
         data: {
            title: 'Request Emails',
            permissions: ['mail.view']
         }
      },
      {
         name: 'app.requests.emails.message',
         url: '/{messageId}',
         template: '<ce-email-view layout="column" flex message-id="$resolve.messageId"></ce-email-view>',
         data: {
            title: 'Message',
            permissions: ['mail.view']
         },
         resolve: {
            messageId: ['$stateParams', p => p.messageId]
         }
      },
      {
         name: 'app.requests.emails.link',
         url: '/link',
         template: '<ce-link-emails layout="column" flex involvement-id="$resolve.request.involvementId" source="Request" source-id="$resolve.request.id"></ce-link-emails>',
         data: {
            title: 'Inbox Messages',
            permissions: ['mail.link']
         }
      },
      {
         name: 'app.requests.involvement',
         url: '/involvement',
         template: '<ce-involvement-case-listing involvement-id="$resolve.request.involvementId" flex></ce-involvement-case-listing>',
         data: { title: 'Request Involvement' }
      },
      {
         name: 'app.requestReviewListing',
         url: '/requestReviewListing',
         template: '<ce-request-review-listing layout="column" flex></ce-request-review-listing>',
         data: { title: 'Request Listing' }
      },
      {
         name: 'app.requestReviewCompletedListing',
         url: '/requestListing',
         template: '<ce-request-review-completed-listing layout="column" flex></ce-request-review-completed-listing>',
         data: { title: 'Request Listing' }
      }
   ];

   const runner = (routingHelper: core.routing.IRoutingHelperService) => {
      'ngInject';
      routingHelper.configureRoutes(routes);
   };

   angular
      .module('app.requests')
      .run(runner);
}