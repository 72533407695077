namespace app.clientCases.details {
   @Component('app.clientCases.details', 'ceSpecialEducationForm', {
      templateUrl: 'app/client-cases/details/special-education/special-education-form.html',
      bindings: {
         caseId: '<',
         onCancel: '&',
         onSubmit: '&'
      }
   })
   class SpecialEducationForm {
      static $inject = ['datacontext'];
      constructor(private datacontext: data.IDataContextService) {
         'ngInject';
      }
      public onSubmit: (args: { result: IClientCaseInformation }) => void;
      public data: IClientCaseInformation;
      public isLoading: boolean = false;
      public isSubmitting: boolean = false;
      public caseId: string;
      public schoolDistrict: string;

      public $onInit() {
         this.getData();
      }

      public getData() {
         this.isLoading = true;
         this.datacontext.config
            .getConfigurationParameter('ClientCase.SpecialEdSchoolDistrict', null)
            .then(result => this.schoolDistrict = result);
         this.datacontext.clientCases
            .getClientCaseInformation(this.caseId)
            .then(result => this.data = result)
            .finally(() => this.isLoading = false);
      }

      public submit() {
         this.isSubmitting = true;
         this.datacontext.clientCases
            .updateClientCaseInformation(this.data)
            .then(result => {
               if (result) this.onSubmit({ result });
            }).finally(() => this.isSubmitting = false);
      }
   }
}