namespace app.documentManagement {
   interface IFileViewModel {
      name: string;
      size: number;
      progress?: number;
      documentId?: string;
   }

   @Component('app.documentManagement', 'ceUploadDocumentsActivity', {
      templateUrl: 'app/documentManagement/views/upload-documents/upload-documents.html',
      bindings: {
         involvementId: '<',
         sourceId: '<',
         source: '@',
         folderId: '<'
      }
   })
   class UploadDocumentsComponent {
      static $inject = ['fileUploader', 'datacontext'];
      constructor(
         private fileUploader: utils.IFileUploaderService,
         private datacontext: data.IDataContextService) {
         'ngInject';
      }

      public documentIds: string[] = [];
      public files: IFileViewModel[] = [];
      public involvementId: string;
      public sourceId: string;
      public source: string;
      public folderId: string;

      public deleteFile(index: number) {
         const { documentId } = this.files[index];
         this.datacontext.documentManagement.deleteDocument(documentId);
         this.files.splice(index, 1);
         this.documentIds.splice(index, 1);
      }

      public uploadFiles(files: IFileViewModel[]) {
         if (!files.length) return;

         let url = `api/document-management/documents/upload?sourceId=${this.sourceId}&source=${encodeURIComponent(this.source)}`;
         if (this.involvementId) {
            url += `&involvementId=${this.involvementId}`;
         }
         if (this.folderId) {
            url += `&folderId=${this.folderId}`;
         }

         for (let file of files) {
            this.files.push(file);
            this.fileUploader.upload({ url, file })
               .then(result => {
                  if (result.data) {
                     const document = result.data as documentManagement.IDocument;
                     file.documentId = document.id;
                     this.documentIds.push(file.documentId);
                  }
               }, (error) => { },
                  (progress) => file.progress = Math.floor(100.0 * progress.loaded / progress.total));
         }
      }
   }
}