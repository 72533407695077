namespace app.persons.overview {
   @Component('app.persons.overview', 'cePersonImportantInformation', {
      templateUrl: 'app/persons/important/important.html',
      bindings: {
         person: '<'
      }
   })
   class PersonImportantInformation {
      static $inject = ['$mdDialog', 'common', 'datacontext'];
      constructor(
         private $mdDialog: angular.material.IDialogService,
         private common: core.ICommonService,
         private datacontext: data.IDataContextService) {
         'ngInject';
      }

      public person: IPerson;
      public data: IPersonImportantInformation;

      public $onInit() {
         this.datacontext.persons.getImportantInformation(this.person.id)
            .then(result => this.data = result);
      }
   }
}