namespace app.users {
   'use strict';

   export interface IUserService {
      getCurrentUser(): angular.IPromise<users.IUser>;
      getSupervisedUsers(q: string): angular.IPromise<users.IUser[]>;
   }

   class UserService implements IUserService {
      constructor(private datacontext: data.IDataContextService) {
         'ngInject';
      }

      public getCurrentUser(): angular.IPromise<users.IUser> {
         return this.datacontext.users.getCurrentUser().then(
            (u) => {
               const user = {
                  id: u.id,
                  firstName: u.firstName,
                  lastName: u.lastName,
                  fullName: u.fullName
               };
               return user;
            }
         );
      }

      public getSupervisedUsers(q: string): angular.IPromise<users.IUser[]> {
         return this.datacontext.users.getSupervisedUsers(q).then(
            (users) => {
               return _.map(users, u => {
                  return {
                     id: u.id,
                     firstName: u.firstName,
                     lastName: u.lastName,
                     fullName: u.fullName
                  };
               });
            }
         );
      }
   }

   angular
      .module('app.users')
      .service('userService', UserService);
}