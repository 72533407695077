namespace app.fiscal {
   @Controller('app.fiscal', 'CheckDialogController')
   class CheckDialogController {
      static $inject = ['$scope', '$mdDialog', 'common', 'datacontext', 'check'];
      constructor(
         private $scope: angular.IScope,
         private $mdDialog: angular.material.IDialogService,
         private common: core.ICommonService,
         private datacontext: data.IDataContextService,
         private check: ICheck) {
         'ngInject';
      }

      public isSubmitting: boolean;
      public maxDate: Date;
      public keepOpen: boolean;
      public reviewed: boolean;

      private $onInit() {
         this.check = this.check || {} as ICheck;
         if (this.check.id) {
            this.getCheck();
         } else {
            this.check.receivedDate = moment().startOf('day').toDate();
         }

         this.maxDate = moment().endOf('day').toDate();
      }

      public getCheck() {
         this.datacontext.fiscal
            .getCheck(this.check.id)
            .then(result => {
               this.check = result;
            });
      }

      public cancel() {
         this.$mdDialog.cancel();
      }

      public submit() {
         this.isSubmitting = true;

         if (typeof this.check.checkDate !== 'string') {
            this.check.checkDate = this.check.checkDate.toDateString();
         }

         if (typeof this.check.receivedDate !== 'string') {
            this.check.receivedDate = this.check.receivedDate.toDateString();
         }

         if (this.check.processedDate && typeof this.check.processedDate !== 'string') {
            this.check.processedDate = this.check.processedDate.toDateString();
         }

         if (this.check.depositedDate && typeof this.check.depositedDate !== 'string') {
            this.check.depositedDate = this.check.depositedDate.toDateString();
         }

         const checkPromise = this.check.id ?
            this.datacontext.fiscal.updateCheck(this.check) :
            this.datacontext.fiscal.createCheck(this.check);

         checkPromise
            .then(check => {
               if (check) {
                  this.common.$mdToast.showSimple('Check Saved');
                  this.submitted();
               }
            })
            .finally(() => this.isSubmitting = false);
      }

      public reset() {
         this.check.processedDate = null;
         this.check.depositedDate = null;
         this.check.checkDate = null;
         this.check.checkNumber = null;
         this.check.amount = null;
         this.check.purpose = null;
         this.check.payorName = null;
         this.check.payorAddress = null;
         this.check.contactName = null;
         this.check.contactAddress = null;
         this.check.notes = null;
         this.check.reviewed = false;
         angular.element(document).find('#checkNumber').focus();
      }

      public submitted() {
         if (!this.keepOpen) {
            this.$mdDialog.hide(this.check);
         }
         else {
            this.reset();
         }
      }
   }
}