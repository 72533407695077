namespace app.training.request {
   @Component('app.training.request', 'ceTrainingRequestCreate', {
      templateUrl: 'app/training/request/create/create.html',
      bindings: {
      }
   })
   class RequestCreate {
      static $inject = ['datacontext', 'common'];
      constructor(
         private datacontext: data.IDataContextService,
         private common: core.ICommonService) {
         'ngInject';
      }
      public onSubmit: (args: { result: IRequest }) => void;
      public data: IRequest = {} as IRequest;
      public isLoading: boolean = false;
      public isSubmitting: boolean = false;

      public $onInit() {
         this.data.receivedDate = moment().toDate();
      }

      public submit() {
         this.isSubmitting = true;
         this.datacontext.training
            .createRequest(this.data)
            .then(result => {
               if (result) {
                  this.common.$state.go('^.trainingRequestList');
               }
            }).finally(() => this.isSubmitting = false);
      }
   }
}