namespace app.systemicCases.details {
   @Component('app.systemicCases.details', 'ceSystemicCaseDetails', {
      templateUrl: 'app/systemic-cases/details/details.html',
      bindings: {
         case: '<'
      }
   })
   class SystemicCaseDetails {
      static $inject = ['$mdDialog', 'common', 'datacontext'];
      constructor(
         private $mdDialog: angular.material.IDialogService,
         private common: core.ICommonService,
         private datacontext: data.IDataContextService) {
         'ngInject';
      }

      private case: ISystemicCaseBasicInfoView;
      private caseInformation: ISystemicCaseInformation;
      private editCaseInformation: boolean = false;

      public $onInit() {
         this.GetCaseInformation();
      }

      public updateCaseInformation(result: ISystemicCaseInformation) {
         this.caseInformation = result;
         this.editCaseInformation = false;
         angular.extend(this.case, this.caseInformation);
      }

      public GetCaseInformation() {
         this.datacontext.systemicCases.getSystemicCaseInformation(this.case.id).then(
            result => this.caseInformation = result
         );
      }
   }
}