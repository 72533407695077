namespace app.funding {
   @Component('app.funding', 'cePabssIntake', {
      templateUrl: 'app/funding/enrollments/pabss/pabss-intake.html',
      bindings: {
         enrollmentId: '<'
      }
   })
   class PABSSIntake {
      static $inject = ['common', 'datacontext'];
      constructor(
         private common: core.ICommonService,
         private datacontext: data.IDataContextService) {
         'ngInject';
      }
      public enrollmentId: string;
      public intake: IPABSSIntake;
      public editing: boolean;

      public $onInit() {
         this.getIntake();
      }

      public getIntake() {
         return this.datacontext.funding.getPABSSIntake(this.enrollmentId)
            .then(intake => { if (intake) this.intake = intake; });
      }

      public edit() {
         this.getIntake().then(() => {
            this.editing = true;
         });
      }

      public updateIntake() {
         if (this.intake.disability && this.intake.disability.indexOf('Other') === -1) {
            this.intake.disabilityOther = null;
         }
         if (this.intake.livingArrangement && this.intake.livingArrangement.indexOf('Other') === -1) {
            this.intake.livingArrangementOther = null;
         }
         if (this.intake.race && this.intake.race.indexOf('Other') === -1) {
            this.intake.raceOther = null;
         }
         if (this.intake.majorConcern && this.intake.majorConcern.indexOf('Other') === -1) {
            this.intake.majorConcernOther = null;
         }
         this.datacontext.funding.updatePABSSIntake(this.intake).then(
            () => {
               this.editing = false;
            }
         );
      }

      public cancel() {
         this.getIntake().then(() => {
            this.editing = false;
         });
      }

      public done() {
         this.common.$state.go('^.');
      }
   }
}