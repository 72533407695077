namespace app.entities.details {
   @Component('app.entities.details', 'ceEntityEmailAddressForm', {
      templateUrl: 'app/entities/details/email-address/email-address-form.html',
      bindings: {
         emailAddress: '<',
         onCancel: '&',
         onSubmit: '&'
      }
   })
   @Inject('common')
   class EmailAddressForm {
      static $inject = ['common'];
      constructor(
         private common: core.ICommonService) {
      }

      public data: IEntityEmailAddress;
      public emailAddress: IEntityEmailAddress;

      private phoneChanged(emailAddress: IEntityEmailAddress) {
         this.data = angular.extend(this.data, emailAddress);
      }

      private $onChanges() {
         this.data = angular.extend({}, this.emailAddress);
      }
   }
}