namespace app.systemicCases.details {
   @Component('app.systemicCases.details', 'ceSystemicCaseInformation', {
      templateUrl: 'app/systemic-cases/details/case-information/case-information.html',
      bindings: {
         data: '<',
         onEdit: '&'
      }
   })
   class SystemicCaseInformation {
   }
}