namespace app.requests.details {
      @Component('app.requests.details', 'ceRequestDetails', {
            templateUrl: 'app/requests/details/details.html',
            bindings: {
                  request: '<'
            }
      })
      class RequestDetails {
            static $inject = ['$mdDialog', 'common', 'datacontext'];
            constructor(
                  private $mdDialog: angular.material.IDialogService,
                  private common: core.ICommonService,
                  private datacontext: data.IDataContextService) {
                  'ngInject';
            }

            private request: IRequestBasicInfo;
            private requestInformation: IRequestInformation;
            private pwdInformation: persons.IDemographics;
            private callerInformation: persons.IDemographics;
            private editRequestInformation: boolean = false;
            private editPWDInformation: boolean = false;
            private editCallerInformation: boolean = false;

            public $onInit() {
                  let promise = null;

                  promise = this.getRequest();
                  promise.then(
                        () => {
                              this.GetRequestInformation();
                              this.GetPWDInformation();
                              this.GetCallerInformation();
                        });
            }
            public getRequest() {
                  return this.datacontext.requests.getRequestBasicInfo(this.request.id)
                        .then(request => this.request = request);
            }

            public updateRequestInformation(result: IRequestInformation) {
                  this.common.$rootScope.$broadcast('refreshBasicInfo');
                  this.requestInformation = result;
                  this.editRequestInformation = false;
            }
            public updatePWDInformation(result: persons.IDemographics) {
                  this.pwdInformation = result;
                  this.editPWDInformation = false;
            }
            public updateCallerInformation(result: persons.IDemographics) {
                  this.callerInformation = result;
                  this.editCallerInformation = false;
            }
            public GetRequestInformation() {
                  this.datacontext.requests.getRequestInformation(this.request.id).then(
                        result => this.requestInformation = result
                  );
            }
            public GetPWDInformation() {
                  if (this.request.pwdPersonId) {
                        this.datacontext.persons.getDemographics(this.request.pwdPersonId).then(
                              result => this.pwdInformation = result
                        );
                  }
            }
            public GetCallerInformation() {
                  if (this.request.requesterPersonId && this.request.pwdPersonId !== this.request.requesterPersonId) {
                        this.datacontext.persons.getDemographics(this.request.requesterPersonId).then(
                              result => this.callerInformation = result
                        );
                  }
            }
      }
}