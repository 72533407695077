namespace app.agencies.details {
   @Component('app.agencies.details', 'ceAgencyOtherInfoForm', {
      templateUrl: 'app/agencies/details/other-info/other-info-form.html',
      bindings: {
         otherInfo: '<',
         onCancel: '&',
         onSubmit: '&'
      }
   })
   class AgencyOtherInfoForm {
      static $inject = ['common', 'datacontext'];
      constructor(
         private common: core.ICommonService,
         private datacontext: data.IDataContextService) {
         'ngInject';
      }
      public isLoading: boolean = false;
      public otherInfo: IAgencyOtherInfo;
      public data = {} as IAgencyOtherInfo;

      private $onChanges() {
         this.data = angular.extend({}, this.otherInfo);
      }
   }
}