namespace app.training.training {
   @Component('app.training.training', 'ceTrainingInformation', {
      templateUrl: 'app/training/training/details/training-information.html',
      bindings: {
         data: '<',
         paramNumberOfBrochures: '<',
         onEdit: '&'
      }
   })
   class TrainingInformation {
      public paramNumberOfBrochures: boolean;
   }
}