 namespace app.informationAndReferrals.activities {
    export const bypassClosureReasons = (datacontext: data.IDataContextService) => {
        'ngInject';
        const bypassReasonsPromise = datacontext.config.getConfigurationParameter<boolean>('I&R.BypassClosureReasons', true);
        return bypassReasonsPromise.then(result => {
                return result.toString() === 'true';
        });
     };
     export const showCovidVaccine = (datacontext: data.IDataContextService) => {
        'ngInject';
        const showCovidVaccinePromise = datacontext.config.getConfigurationParameter<boolean>('Activity.CovidVaccine', false);
        return showCovidVaccinePromise.then(result => {
                return result.toString() === 'true';
        });
     };
 }