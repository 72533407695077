namespace app.systemicCases {
   'use strict';

   export interface ISystemicCaseRepository {
      getSystemicCaseActivities(caseId: string): angular.IPromise<ISystemicCaseActivityModel[]>;
      getSystemicCaseBasicInfoView(id: string): angular.IPromise<ISystemicCaseBasicInfoView>;
      getClosureInformation(id: string): angular.IPromise<ISystemicCaseClosureInformation>;
      getSystemicCaseInformation(caseId: string): angular.IPromise<ISystemicCaseInformation>;
      getSystemicCaseSummaryView(id: string): angular.IHttpPromise<ISystemicCaseSummaryView>;
      getSystemicCaseListing(params: IGetSystemicCasesParams): angular.IPromise<data.IPagedList<ISystemicCaseListingView>>;
      getSystemicCaseListingDownload(params: IGetSystemicCasesParams): angular.IPromise<app.analysis.IDownloadDataResult>;
      getSystemicCaseStatus(id: string): angular.IHttpPromise<ISystemicCaseStatus>;
      getSystemicCaseToDo(id: string, stage?: string): angular.IPromise<common.IToDoItem[]>;
      createSystemicCase(request: ICreateSystemicCase): angular.IPromise<ISystemicCase>;
      close(activity: ICreateSystemicCaseActivity): angular.IHttpPromise<ICreateSystemicCaseActivity>;
      reopen(activity: ICreateSystemicCaseActivity): angular.IHttpPromise<ICreateSystemicCaseActivity>;
      conflictCheck(activity: ICreateSystemicCaseActivity): angular.IHttpPromise<ICreateSystemicCaseActivity>;
      followUp(activity: ICreateSystemicCaseActivity): angular.IHttpPromise<ICreateSystemicCaseActivity>;
      createNote(activity: ICreateSystemicCaseActivity): angular.IHttpPromise<ICreateSystemicCaseActivity>;
      updateNote(activity: ICreateSystemicCaseActivity): angular.IHttpPromise<ICreateSystemicCaseActivity>;
      updateSystemicCaseInformation(info: ISystemicCaseInformation): angular.IPromise<ISystemicCaseInformation>;
      updateClosureInformation(closure: ISystemicCaseClosureInformation): angular.IPromise<ISystemicCaseClosureInformation>;
      createCaseReview(review: involvements.ICaseReview): angular.IPromise<involvements.ICaseReview>;
      updateCaseReview(review: involvements.ICaseReview): angular.IPromise<involvements.ICaseReview>;
      getActiveCaseCount(userIds: string[]): angular.IPromise<number>;
      deleteSystemicCase(id: string): angular.IPromise<string>;
   }

   const baseUrl = 'api/systemicCases';

   class SystemicCaseRepository implements ISystemicCaseRepository {
      static $inject = ['$http', 'exception'];
      constructor(
         private $http: angular.IHttpService,
         private exception: core.exception.IExceptionService,
      ) {
      }

      @ApiExecutor()
      public getSystemicCaseListing(params: IGetSystemicCasesParams) {
         return this.$http.get<data.IPagedList<ISystemicCaseListingView>>(baseUrl, { params }) as any;
      }

      public getSystemicCaseListingDownload(params: IGetSystemicCasesParams) {
         return this.$http.get<any>(`${baseUrl}/csv`, { params, responseType: 'arraybuffer' }).then(
            (response) => {
               return {
                  buffer: response.data,
                  contentType: response.headers()['content-type'],
                  fileName: response.headers()['content-disposition'].split(';')[1].split('=')[1].replace(/"/g, '')
               };
            }).catch(error => this.exception.catcher('XHR for getCsvData failed')(error)) as any;
      }

      @ApiExecutor()
      public getSystemicCaseActivities(caseId: string) {
         return this.$http.get<ISystemicCaseActivityModel[]>(`${baseUrl}/${caseId}/activities`) as any;
      }

      @ApiExecutor()
      public createSystemicCase(request: ICreateSystemicCase) {
         return this.$http.post<ICreateSystemicCase>(baseUrl, request) as any;
      }

      @ApiExecutor()
      public close(activity: ICreateSystemicCaseActivity) {
         return this.$http.post<ICreateSystemicCaseActivity>(`${baseUrl}/${activity.caseId}/close`, activity) as any;
      }

      @ApiExecutor()
      public reopen(activity: ICreateSystemicCaseActivity) {
         return this.$http.post<ICreateSystemicCaseActivity>(`${baseUrl}/${activity.caseId}/reopen`, activity) as any;
      }

      @ApiExecutor()
      public conflictCheck(activity: ICreateSystemicCaseActivity) {
         return this.$http.post<ICreateSystemicCaseActivity>(`${baseUrl}/${activity.caseId}/conflictCheck`, activity) as any;
      }

      @ApiExecutor()
      public followUp(activity: ICreateSystemicCaseActivity) {
         return this.$http.post<ICreateSystemicCaseActivity>(`${baseUrl}/${activity.caseId}/followUp`, activity) as any;
      }

      @ApiExecutor()
      public getSystemicCaseBasicInfoView(id: string) {
         return this.$http.get<ISystemicCaseBasicInfoView>(`${baseUrl}/${id}/basicinfo`) as any;
      }

      @ApiExecutor()
      public createNote(activity: ICreateSystemicCaseActivity) {
         return this.$http.post<ICreateSystemicCaseActivity>(`${baseUrl}/${activity.caseId}/notes`, activity) as any;
      }

      @ApiExecutor()
      public updateNote(activity: ICreateSystemicCaseActivity) {
         return this.$http.put<ICreateSystemicCaseActivity>(`${baseUrl}/${activity.caseId}/notes/${activity.id}`, activity) as any;
      }

      @ApiExecutor()
      public getSystemicCaseStatus(id: string) {
         return this.$http.get<ISystemicCaseStatus>(`${baseUrl}/${id}/status`) as any;
      }

      @ApiExecutor()
      public getSystemicCaseSummaryView(id: string) {
         return this.$http.get<ISystemicCaseSummaryView>(`${baseUrl}/${id}/summary`) as any;
      }

      @ApiExecutor()
      public getSystemicCaseToDo(id: string, stage?: string) {
         return this.$http.get<common.IToDoItem[]>(`${baseUrl}/${id}/todo/${stage || ''}`) as any;
      }

      @ApiExecutor()
      public getSystemicCaseInformation(id: string) {
         return this.$http.get<ISystemicCaseInformation>(`${baseUrl}/${id}/information`) as any;
      }

      @ApiExecutor()
      public updateSystemicCaseInformation(info: ISystemicCaseInformation) {
         return this.$http.post<ISystemicCaseInformation>(`${baseUrl}/${info.id}/information`, info) as any;
      }

      @ApiExecutor()
      public getClosureInformation(id: string) {
         return this.$http.get<ISystemicCaseClosureInformation>(`${baseUrl}/${id}/closure`) as any;
      }

      @ApiExecutor()
      public updateClosureInformation(closure: ISystemicCaseClosureInformation) {
         return this.$http.post<ISystemicCaseClosureInformation>(`${baseUrl}/${closure.id}/closure`, closure) as any;
      }

      @ApiExecutor()
      public createCaseReview(review: involvements.ICaseReview) {
         return this.$http.post<involvements.ICaseReview>(`${baseUrl}/${review.caseId}/caseReview`, review) as any;
      }

      @ApiExecutor()
      public updateCaseReview(review: involvements.ICaseReview) {
         return this.$http.put<involvements.ICaseReview>(`${baseUrl}/${review.caseId}/caseReview/${review.id}`, review) as any;
      }

      @ApiExecutor()
      public getActiveCaseCount(userIds: string[]) {
         return this.$http.get<number>(`${baseUrl}/activeCaseCount`, { params: { userIds: userIds } }) as any;
      }

      public deleteSystemicCase(id: string) {
         return this.$http.delete(`${baseUrl}/${id}`).catch(error => this.exception.catcher('Cannot Delete Case')(error)) as any;
      }
   }

   angular
      .module('app.systemicCases')
      .service('systemicCases.repository', SystemicCaseRepository);
}