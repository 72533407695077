namespace app.admin.identityManagement {
   const routes: core.routing.IStateExtended[] = [
      {
         name: 'app.admin.identityManagement',
         url: '/identity-management',
         template: '<ui-view layout="column" flex></ui-view>',
         redirectTo: 'app.admin.identityManagement.users',
         data: { roles: ['Administrator'] }
      },
      {
         name: 'app.admin.identityManagement.users',
         url: '/users',
         template: '<ui-view layout flex></ui-view>',
         redirectTo: 'app.admin.identityManagement.users.list',
         data: { roles: ['Administrator'] }
      },
      {
         name: 'app.admin.identityManagement.users.list',
         url: '',
         template: '<ce-admin-user-list layout="column" flex></ce-admin-user-list>',
         data: {
            title: 'Users',
            roles: ['Administrator']
         }
      },
      {
         name: 'app.admin.identityManagement.users.createUser',
         url: '/create',
         template: '<ui-view layout flex></ui-view>',
         redirectTo: 'app.admin.identityManagement.users.createUser.find',
         data: { roles: ['Administrator'] }
      },
      {
         name: 'app.admin.identityManagement.users.createUser.find',
         url: '/find',
         template: '<ce-admin-find-person layout="column" flex></ce-admin-find-person>',
         data: {
            roles: ['Administrator'],
            title: 'Create User'
         }
      },
      {
         name: 'app.admin.identityManagement.users.createUser.create',
         url: '',
         template: '<ce-admin-create-user layout="column" flex person="$resolve.person" roles="$resolve.roles"></ce-admin-create-user>',
         data: {
            roles: ['Administrator'],
            title: 'Create User'
         },
         params: { person: null },
         resolve: {
            person: ['$stateParams', p => p.person],
            roles: rolesResolver
         }
      },
      {
         name: 'app.admin.identityManagement.users.userDetails',
         url: '/:userId',
         template: '<ce-admin-user-details layout="column" flex user="$resolve.user" roles="$resolve.roles"></ce-admin-user-details>',
         data: { roles: ['Administrator'] },
         resolve: {
            user: userResolver,
            roles: rolesResolver
         }
      },
      {
         name: 'app.admin.identityManagement.roles',
         url: '/roles',
         template: '<ui-view layout flex></ui-view>',
         data: { roles: ['Administrator'] },
         redirectTo: 'app.admin.identityManagement.roles.list'
      },
      {
         name: 'app.admin.identityManagement.roles.list',
         url: '',
         template: '<ce-admin-role-list layout="column" flex roles="$resolve.roles"></ce-admin-role-list>',
         data: {
            title: 'Roles & Permissions',
            roles: ['Administrator']
         },
         resolve: {
            roles: rolesResolver
         }
      },
      {
         name: 'app.admin.identityManagement.roles.createRole',
         url: '/create',
         template: '<ce-admin-create-role layout="column" flex permissions="$resolve.permissions"></ce-admin-create-role>',
         data: { roles: ['Administrator'] },
         resolve: {
            permissions: permissionsRosolver
         }
      },
      {
         name: 'app.admin.identityManagement.roles.roleDetails',
         url: '/:roleId',
         template: '<ce-admin-role-details layout="column" flex role="$resolve.role" permissions="$resolve.permissions"></ce-admin-role-details>',
         data: { roles: ['Administrator'] },
         resolve: {
            role: roleResolver,
            permissions: permissionsRosolver
         }
      },
   ];

   const runner = (routingHelper: core.routing.IRoutingHelperService) => {
      'ngInject';
      routingHelper.configureRoutes(routes);
   };

   angular
      .module('app.admin.identityManagement')
      .run(runner);
}