namespace app.monitoring.closure {
   @Component('app.monitoring.closure', 'ceMonitoringClosure', {
      templateUrl: 'app/monitoring/closure-information/closure.html',
      bindings: {
         monitoring: '<'
      }
   })
   class MonitoringClosure {
      static $inject = ['$mdDialog', 'common', 'datacontext'];
      constructor(
         private $mdDialog: angular.material.IDialogService,
         private common: core.ICommonService,
         private datacontext: data.IDataContextService) {
         'ngInject';
      }

      private monitoring: IMonitoringBasicInfoView;
      private closureInformation: IMonitoringClosureInformation;
      private editClosureInformation: boolean = false;

      public $onInit() {
         this.GetClosureInformation();
      }

      public updateClosureInformation(result: IMonitoringClosureInformation) {
         this.closureInformation = result;
         this.editClosureInformation = false;
      }

      public GetClosureInformation() {
         this.datacontext.monitoring.getClosureInformation(this.monitoring.id).then(
            result => this.closureInformation = result
         );
      }
   }
}