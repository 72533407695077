namespace app.clientCases.overview {
   @Component('app.clientCases.overview', 'ceClientCaseSummary', {
      templateUrl: 'app/client-cases/summary/summary.html',
      bindings: {
         case: '<'
      }
   })
   class ClientCaseSummary {
      static $inject = ['$mdDialog', 'common', 'datacontext'];
      constructor(
         private $mdDialog: angular.material.IDialogService,
         private common: core.ICommonService,
         private datacontext: data.IDataContextService) {
         'ngInject';
      }

      public caseSummary: IClientCaseSummaryView;
      public case: IClientCaseBasicInfoView;
      private openDuration: string;
      private closeDuration: string;

      public $onInit() {
         this.datacontext.clientCases.getClientCaseSummaryView(this.case.id)
            .then(result => {
               let data: any = result;
               this.caseSummary = data;
            }).then(() => angular.element('#summarycard').attr('aria-hidden', 'false'));
      }
   }
}