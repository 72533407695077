namespace app.time {
   export interface ITimerService {
      source: string;
      sourceId: string;
      sourceName: string;
      sourceName2: string;
      involvementId: string;
      hours: number;
      seconds: number;
      time: string;
      tenthsOfHours: number;
      init(source: string, sourceId: string, sourceName: string, sourceName2: string, involvementId: string): void;
      startTimer(): void;
      killTimer(): void;
   }

   @Service('app.time', 'timerService')
   class TimerService implements ITimerService {
      constructor() { }

      public source: string;
      public sourceId: string;
      public sourceName: string;
      public sourceName2: string;
      public involvementId: string;
      public hours: number;
      public seconds: number;
      public time: string;
      public tenthsOfHours: number = 1;
      public timeout: any;
      private paused: boolean;
      private ariaLabel: string;
      private startDate: Date;
      private currentDate: number;
      private startPause: Date;
      private endPause: Date;
      private pauseOffset: number;
      private delay: number;

      public init(source, sourceId, sourceName, sourceName2, involvementId) {
         this.source = source;
         this.sourceId = sourceId;
         this.sourceName = sourceName;
         this.sourceName2 = sourceName2;
         this.involvementId = involvementId;
         this.seconds = 0;
         this.time = '00:00:00';
         this.tenthsOfHours = 1;
         this.hours = .1;
         this.ariaLabel = 'Timer started click to Pause.';
         this.startDate = new Date();
         this.currentDate = +new Date();
         this.delay = 1000;
         this.pauseOffset = 0;
      }

      public startTimer() {
         this.timeout = setTimeout(this.increment.bind(this.currentDate), 1000);
      }

      public restartTimer() {
         this.seconds = 0;
         this.time = '00:00:00';
         this.tenthsOfHours = 1;
         this.hours = .1;
         clearTimeout(this.timeout);
         this.startTimer();
      }

      public killTimer() {
         this.paused = false;
         this.source = null;
         this.sourceId = null;
         this.sourceName = null;
         this.sourceName2 = null;
         this.involvementId = null;
         this.seconds = 0;
         this.time = '00:00:00';
         this.tenthsOfHours = 0;
         this.hours = 0;
         clearTimeout(this.timeout);
      }

      public increment = (currentDate: number) => {

         this.currentDate = +Date.now();

         const count = (this.currentDate - this.pauseOffset - +this.startDate);

         const seconds = Math.floor((count / 1000)) % 60;
         const minutes = Math.floor((count / 60000)) % 60;
         const hours = Math.floor((count / 3600000)) % 60;
         const hoursDecimal = hours + (Math.ceil(minutes / 6)) / 10;

         this.hours = hoursDecimal > .1 ? hoursDecimal : .1;
         this.seconds++;

         this.setTimeString(hours, minutes, seconds);
         this.startTimer();
      }

      public pause() {
         clearTimeout(this.timeout);
         if (this.paused) {
            this.endPause = new Date();
            this.pauseOffset += +this.endPause - +this.startPause;
            this.startTimer();
            this.ariaLabel = 'Timer started at ' + this.time + ' click to pause';
         } else {
            this.ariaLabel = 'Timer paused at ' + this.time + ' click to start';
            this.startPause = new Date();
         }
         this.paused = !this.paused;
      }

      public setTimeString(hours: number, minutes: number, seconds: number) {
         this.time = this.padTime(hours) + ':' + this.padTime(minutes) + ':' + this.padTime(seconds);
      }

      public padTime(value: number): string {
         switch (true) {
            case (value === 0): {
               return '00';
            }
            case (value < 10): {
               return '0' + value;
            }
            default: {
               return value.toString();
            }
         }
      }
   }
}