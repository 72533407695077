namespace app.admin.identityManagement {
   @Component('app.admin.identityManagement', 'ceAdminCreateRole', {
      templateUrl: 'app/admin/modules/identityManagement/views/create-role/create-role.html',
      bindings: {
         permissions: '<'
      }
   })
   class Role {
      static $inject = ['common', 'datacontext'];
      constructor(
         private common: core.ICommonService,
         private datacontext: data.IDataContextService) {
         'ngInject';
      }

      public data = {} as ICreateRoleData;
      public isSubmitting = false;
      public permissions: IPermission[];
      public permissionsByCategory: IDictionary<IPermission[]>;

      public hasPermission(id: string) {
         return _.some(this.data.permissions, p => p.permission === id);
      }

      public togglePermission(id: string) {
         if (!this.hasPermission(id)) {
            this.addPermssion(id);
         } else {
            this.removePermission(id);
         }
      }

      public submit() {
         this.datacontext.admin.identityManagement
            .createRole(this.data)
            .then(result => {
               if (result) {
                  this.common.$mdToast.showSimple('Role created');
                  this.common.$state.go('^');
               }
            }).finally(() => this.isSubmitting = false);
      }

      private $onInit() {
         this.data.permissions = [];

         this.permissionsByCategory = _(this.permissions)
            .orderBy(p => p.category)
            .groupBy(p => p.category || 'No Category')
            .value();
      }

      private addPermssion = (id: string) => {
         if (!_.some(this.data.permissions, p => p.permission === id)) {
            this.data.permissions.push({ permission: id });
         }

         const permission = _.find(this.permissions, p => p.id === id);
         _.forEach(permission.dependencies, this.addPermssion);
      };

      private removePermission = (id: string) => {
         _.remove(this.data.permissions, p => p.permission === id);

         _(this.permissions)
            .filter(p => p.dependencies && p.dependencies.indexOf(id) > -1)
            .map(p => p.id)
            .forEach(this.removePermission);
      };
   }
}