namespace app.utils {
   const autofocus = ($timeout: angular.ITimeoutService) => {
      'ngInject';
      return {
         link: (scope, element, attrs) => {
            $timeout(() => element.focus());
         }
      };
   };

   angular
      .module('app.utils')
      .directive('autofocus', autofocus);
}