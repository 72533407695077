namespace app.config {
   export interface ILegalTeamRepository {
      getLegalTeams(activeOnly: boolean): angular.IPromise<ILegalTeam[]>;
   }

   const baseUrl = 'api/configuration/legal-teams';

   class LegalTeamRepository implements ILegalTeamRepository {
      constructor(private $http: angular.IHttpService) {
         'ngInject';
      }

      @ApiExecutor()
      public getLegalTeams(activeOnly: boolean) {
         return this.$http.get<ILegalTeam[]>(`${baseUrl}?activeOnly=${activeOnly}`) as any;
      }
   }

   angular
      .module('app.config')
      .service('config.legalTeams.repository', LegalTeamRepository);
}