namespace app.analysis {
   @Component('app.analysis', 'aimsAnalysisStackedColumnChart', {
      template: '<flot dataset="$ctrl.dataset" options="$ctrl.options" ></flot>',
      bindings: {
         data: '<'
      }
   })
   class StackedColumnChartComponent {
      static $inject = ['common', 'analysisConstants'];
      constructor(
         private common: core.ICommonService,
         private analysisConstants: IAnalysisConstants) {
         'ngInject';
      }
      public data: IChartData[];
      public dataset: any[];
      public options = {
         series: {
            stack: true,
            bars: {
               align: 'center',
               barWidth: 0.6,
               fill: 0.9,
               lineWidth: 0,
               show: true
            }
         },
         grid: {
            borderColor: '#eee',
            borderWidth: 1,
            hoverable: true,
            backgroundColor: '#fcfcfc',
            labelMargin: 25
         },
         tooltip: true,
         tooltipOpts: {
            content: (label, x, y, item) => {
               return `<div style="font-size:10pt; text-align: center; padding: 2px; color: white;">${item.series.xaxis.ticks[x].label}<br />${label} : ${y}</div>`;
            },
            zIndex: 2000
         },
         xaxis: {
            tickColor: '#fcfcfc',
            autoscaleMargin: .1,
            ticks: null
         },
         yaxis: {
            tickColor: '#eee',
            tickDecimals: 0
         },
         legend: {
            position: 'ne',
            noColumns: 0
         }
      };

      private $onChanges = () => {
         this.common.$timeout(() => {
            const yaxis = _(this.data).map('group0').uniq().value();
            const xaxis = _(this.data).map('group1').uniq().value();

            this.dataset = _.map(yaxis, (y, yi) => {
               return {
                  label: y,
                  color: this.analysisConstants.colorPalette[yi % this.analysisConstants.colorPalette.length],
                  data: _.map(xaxis, (x, i) => {
                     return [i, _(this.data).filter(d => d.group0 === y && d.group1 === x).map('data').sum()];
                  })
               };
            });

            this.options.xaxis.ticks = _(this.data).map(d => d.group1).uniq().map((g, i) => [i, g]).value();
         });
      };
   }
}