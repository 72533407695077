namespace app.agencies.details {
   @Component('app.agencies.details', 'ceAgencyOtherInfo', {
      templateUrl: 'app/agencies/details/other-info/other-info.html',
      bindings: {
         agency: '<',
         onEdit: '&',
      }
   })
   class AgencyOtherInfo {
      static $inject = ['common', 'datacontext'];
      constructor(
         private common: core.ICommonService,
         private datacontext: data.IDataContextService) {
         'ngInject';
      }
      public isLoading: boolean = false;
      public editMode: boolean = false;
      public agency: IAgencyBasicInfo;
      public data = {} as IAgencyOtherInfo;
      public incomeSources: app.config.ILookup[];
      public isSubmitting: boolean = false;
      public onSubmit: (args: { result: IAgencyOtherInfo }) => void;

      public $onInit() {
         this.getData();
      }

      public cancelEdit() {
         this.editMode = false;
      }

      public editData(data: IAgencyOtherInfo) {
         this.editMode = true;
      }

      public getData() {
         this.isLoading = true;

         return this.datacontext.agencies
            .getOtherInfo(this.agency.id)
            .then(result => {
               this.data = result;
               this.isLoading = false;
            });
      }

      public saveData(data: IAgencyOtherInfo) {
         this.isSubmitting = true;

         this.datacontext.agencies
            .updateOtherInfo(data)
            .then((result) => {
               this.data = data;
               angular.extend(this.agency, result);
               this.isSubmitting = false;
               this.cancelEdit();
            });
      }
   }
}