namespace app.funding {
   @Component('app.funding', 'cePatbiSystemicOutcomeForm', {
      templateUrl: 'app/funding/enrollments/patbi/systemic/patbi-outcome-form.html',
      bindings: {
         outcome: '<',
         onCancel: '&',
         onSubmit: '&'
      }
   })
   class PATBISystemicOutcomeForm {
      static $inject = ['common', 'datacontext'];
      constructor(
         private common: core.ICommonService,
         private datacontext: data.IDataContextService) {
         'ngInject';
      }

      public interventionStrategies: app.config.ILookup[];
      public isInvestigation: boolean;
      public outcome: IPATBISystemicOutcome;

      public $onInit() {
         this.getInterventionStrategies();
      }

      public checkInvestigation() {
         this.isInvestigation = false;
         _.forEach(this.outcome.interventionStrategies, (interventionStrategy: any) => { if (interventionStrategy.contains('Investigation')) this.isInvestigation = true; });
      }

      public getInterventionStrategies() {
         return this.datacontext.config.lookups.getLookupType('patbi-systemic-intervention-strategies')
            .then(lookup => { this.interventionStrategies = lookup.lookups; });
      }
   }
}