namespace app.alerts {
   export interface IAlertRepository {
      clearNotification(notificationId: string): angular.IPromise<boolean>;
      getNotificationPreferences(): angular.IPromise<INotificationPreferences>;
      getNotifications(params: IGetNotificationsParams): data.IPagedListPromise<INotification>;
      getNotificationsCount(): angular.IPromise<INotificationCount>;
      savePreferences(data: ISaveNotificationPreferencesData): angular.IPromise<boolean>;
   }

   const baseUrl = 'api/alerts';

   class AlertRepository implements IAlertRepository {
      constructor(private $http: angular.IHttpService) {
         'ngInject';
      }

      @ApiExecutor({ returnValue: true })
      public clearNotification(notificationId: string) {
         return this.$http.put<boolean>(`${baseUrl}/notifications/${notificationId}/clear`, null) as any;
      }

      @ApiExecutor()
      public getNotificationPreferences() {
         return this.$http.get<INotificationPreferences>(`${baseUrl}/notification-preferences`) as any;
      }

      @ApiExecutor()
      public getNotifications(params: IGetNotificationsParams) {
         return this.$http.get<data.IPagedList<INotification>>(`${baseUrl}/notifications`, { params }) as any;
      }

      @ApiExecutor()
      public getNotificationsCount() {
         return this.$http.get<INotificationCount>(`${baseUrl}/notifications/$count`) as any;
      }

      @ApiExecutor({ returnValue: true })
      public savePreferences(data: ISaveNotificationPreferencesData) {
         return this.$http.post<boolean>(`${baseUrl}/notification-preferences`, data) as any;
      }
   }

   angular
      .module('app.alerts')
      .service('alerts.repository', AlertRepository);
}