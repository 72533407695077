namespace app.search {
   @Component('app.search', 'ceSourceSearch', {
      templateUrl: 'app/search/source/source.html',
      bindings: {
         sourceId: '<',
         source: '<',
         involvementId: '<',
         sourceDescription: '<',
         onSourceSelected: '&',
         sourceRequired: '<',
         sourceTypes: '<',
         clearOnSelect: '<'
      }
   })
   class SourceSearchController {
      static $inject = ['$scope', 'common', 'datacontext'];
      constructor(
         private $scope: angular.IScope,
         private common: core.ICommonService,
         private datacontext: data.IDataContextService) {
         'ngInject';
      }

      public searchText: string;
      public sourceId: string;
      public source: string;
      public involvementId: string;
      public sourceDescription: string;
      public onSourceSelected: ({ }) => {};
      public selectedSource: common.ISource;
      public placeHolder: string;
      public sourceRequired: boolean;
      public sourceTypes: string[];
      public clearOnSelect: boolean;
      public unsubscribe: any;

      public $onInit() {
         if (!this.sourceTypes) {
            this.datacontext.config.lookups.getLookupType('source-types')
               .then(result => {
                  this.sourceTypes = result.lookups.map(l => { return l.value; });
               });
         }

         this.unsubscribe = this.$scope.$on('ResetSourceSearch', () => {
            this.selectedSource = null;
         });
      }

      public $onDestroy() {
         this.unsubscribe();
      }

      public handleSelectedItemChange(source: common.ISource) {
         this.onSourceSelected({ source });
         if (this.clearOnSelect) {
            this.selectedSource = null;
         }
         else {
            this.selectedSource = source;
         }
      }

      public $onChanges() {
         this.setSource();
      }

      public setSource() {
         if (!this.sourceDescription) {
            return;
         }

         this.selectedSource = {
            source: this.source,
            sourceId: this.sourceId,
            sourceDescription: this.sourceDescription,
            involvementId: this.involvementId
         };
      }

      public setType() {
         this.selectedSource = null;
         switch (this.source) {
            case 'CLE': {
               this.placeHolder = 'Search by Title';
               break;
            }
            case 'Client Case': {
               this.placeHolder = 'Search by Client Name';
               break;
            }
            case 'Monitoring': {
               this.placeHolder = 'Search by Case Name';
               break;
            }
            case 'I&R': {
               this.placeHolder = 'Search by Caller Name';
               break;
            }
            case 'Project': {
               this.placeHolder = 'Search by Project Name';
               break;
            }
            case 'Request': {
               this.placeHolder = 'Search by Caller Name';
               break;
            }
            case 'Systemic Case': {
               this.placeHolder = 'Search by Case Name';
               break;
            }
            case 'Training': {
               this.placeHolder = 'Search by Title';
               break;
            }
            default: {
               this.placeHolder = 'Select a Source type before searching';
            }
         }
      }

      public search() {
         if (!this.source) {
            return [];
         }

         switch (this.source) {
            case 'CLE':
               return this.getCLEs();
            case 'Client Case':
               return this.getClientCases();
            case 'I&R':
               return this.getIandRs();
            case 'Project':
               return this.getProjects();
            case 'Request':
               return this.getRequests();
            case 'Systemic Case':
               return this.getSystemicCases();
            case 'Training':
               return this.getTrainings();
            case 'Monitoring':
               return this.getMonitorings();
         }
      }
      private getRequests() {
         let searchResults = [];
         let requests = this.datacontext.requests.getRequestListing({ callerName: this.searchText, pageSize: 50 });

         return requests.then((result) => {
            let results: any[] = result.list;
            _.forEach(results, (r) => {
               r.source = 'Request';
               r.sourceId = r.id;
               r.sourceDescription = `${r.idNumber} ${r.requesterFullName}`;
               r.sortBy = r.requesterLastFirstName;
            });
            searchResults.push(...results);
            return _.sortBy(searchResults, 'sortBy');
         }
         );
      }
      private getIandRs() {
         let searchResults = [];
         let iandrs = this.datacontext.informationAndReferrals.getInformationAndReferralListing({ callerName: this.searchText, pageSize: 50 });

         return iandrs.then((result) => {
            let results: any[] = result.list;
            _.forEach(results, (r) => {
               r.source = 'I&R';
               r.sourceId = r.id;
               r.sourceDescription = `${r.idNumber} ${r.requesterFullName}`;
               r.sortBy = r.requesterLastFirstName;
            });
            searchResults.push(...results);
            return _.sortBy(searchResults, 'sortBy');
         }
         );
      }
      private getClientCases() {
         let searchResults = [];
         let cases = this.datacontext.clientCases.getClientCaseListing({ clientName: this.searchText, pageSize: 50 });

         return cases.then((result) => {
            let results: any[] = result.list;
            _.forEach(results, (r) => {
               r.source = 'Client Case';
               r.sourceId = r.id;
               r.sourceDescription = `${r.idNumber} ${r.clientFullName}`;
               r.sortBy = r.clientLastFirstName;
            });
            searchResults.push(...results);
            return _.sortBy(searchResults, 'sortBy');
         }
         );
      }
      private getSystemicCases() {
         let searchResults = [];
         let cases = this.datacontext.systemicCases.getSystemicCaseListing({ caseName: this.searchText, pageSize: 50 });

         return cases.then((result) => {
            let results: any[] = result.list;
            _.forEach(results, (r) => {
               r.source = 'Systemic Case';
               r.sourceId = r.id;
               r.sourceDescription = `${r.idNumber} ${r.name}`;
               r.sortBy = r.name;
            });
            searchResults.push(...results);
            return _.sortBy(searchResults, 'sortBy');
         }
         );
      }
      private getProjects() {
         let searchResults = [];
         let projects = this.datacontext.projects.getProjectListing({ projectName: this.searchText, pageSize: 50 });

         return projects.then((result) => {
            let results: any[] = result.list;
            _.forEach(results, (r) => {
               r.source = 'Project';
               r.sourceId = r.id;
               r.sourceDescription = `${r.name}`;
               r.sortBy = r.name;
            });
            searchResults.push(...results);
            return _.sortBy(searchResults, 'sortBy');
         }
         );
      }
      private getTrainings() {
         let searchResults = [];
         let trainings = this.datacontext.training.getTrainingListing({ q: this.searchText, pageSize: 50 });

         return trainings.then((result) => {
            let results: any[] = result.list;
            _.forEach(results, (r) => {
               r.source = 'Training';
               r.sourceId = r.id;
               r.sourceDescription = `${r.title}`;
               r.sortBy = r.title;
            });
            searchResults.push(...results);
            return _.sortBy(searchResults, 'sortBy');
         }
         );
      }
      private getCLEs() {
         let searchResults = [];
         let cles = this.datacontext.training.getCLEListing({ q: this.searchText, pageSize: 50 });

         return cles.then((result) => {
            let results: any[] = result.list;
            _.forEach(results, (r) => {
               r.source = 'CLE';
               r.sourceId = r.id;
               r.sourceDescription = `${r.title}`;
               r.sortBy = r.title;
            });
            searchResults.push(...results);
            return _.sortBy(searchResults, 'sortBy');
         }
         );
      }
      private getMonitorings() {
         let searchResults = [];
         let mons = this.datacontext.monitoring.getMonitoringList({ caseName: this.searchText, pageSize: 50 });

         return mons.then((result) => {
            let results: any[] = [];

            if (result) {
               results = result.list;
            }
            _.forEach(results, (r) => {
               r.source = 'Monitoring';
               r.sourceId = r.id;
               r.sourceDescription = `${r.idNumber} ${r.name}`;
               r.sortBy = r.name;
            });
            searchResults.push(...results);
            return _.sortBy(searchResults, 'sortBy');
         }
         );
      }

   }
}