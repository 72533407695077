namespace app.funding {
   @Component('app.funding', 'ceCapSystemicOutcomeInfo', {
      templateUrl: 'app/funding/enrollments/cap/systemic/cap-outcome-info.html',
      bindings: {
         outcome: '<',
         onEdit: '&'
      }
   })
   class CAPSystemicOutcomeInfo {
      static $inject = ['common', 'datacontext'];
      constructor(
         private common: core.ICommonService,
         private datacontext: data.IDataContextService) {
         'ngInject';
      }

      public outcome: ICAPSystemicOutcome;
      public isInvestigation: boolean;

      public $onChanges() {
         this.checkInvestigation();
      }

      public checkInvestigation() {
         _.forEach(this.outcome.InterventionStrategies, (interventionStrategy: any) => { if (interventionStrategy.contains('Investigation')) this.isInvestigation = true; });
      }
   }
}