namespace app.time {
    @Component('app.time', 'ceTimeslipList', {
        templateUrl: 'app/time/timeslip-listing/timeslip-list.html',
        bindings: {
            timeslips: '<',
            editTimeslip: '&',
            showDeleteDialog: '&',
            refresh: '&',
            paging: '<',
            promise: '<'
        }
    })
    class TimeslipList {
        static $inject = ['$mdDialog', 'common', 'datacontext'];
        constructor(
            private $mdDialog: angular.material.IDialogService,
            private common: core.ICommonService,
            private datacontext: data.IDataContextService) {
            'ngInject';
        }

        private timeslips: data.IPagedList<ITimeslipListingView>;
        private hoursSum: string;
        private currentUserId: string;
        private canUnlockTimeslips: boolean;

        private $onInit() {
            this.datacontext.users.getCurrentUser().then(user => this.currentUserId = user.id);
            this.canUnlockTimeslips = this.common.auth.checkPermissions('timeslips.unlock');
        }

        private $onChanges() {
            if (this.timeslips) {
                this.hoursSum = _.sumBy(this.timeslips.list, 'hours').toFixed(1);
            }
        }

        public showTimeslip(timeslip: ITimeslip) {
            const parent = angular.element(document.body);
            return this.$mdDialog.show({
                fullscreen: true,
                clickOutsideToClose: true,
                templateUrl: 'app/time/timeslip-dialog/timeslip-view-dialog.html',
                controller: 'TimeslipViewDialogController',
                controllerAs: '$ctrl',
                locals: { timeslip: timeslip }
            });
        }
    }
}