namespace app.admin.configuration {
   export interface IConfigurationRespository {
      getLookupType(lookupTypeId: string): angular.IPromise<ILookupType>;
      getLookupTypes(): angular.IPromise<ILookupTypeListItem[]>;
      updateLookupType(lookupTypeId: string, data: IUpdateLookupTypeData): angular.IPromise<boolean>;
      getLawCategory(lawCategoryId: string, activeSubcategoriesOnly: boolean): angular.IPromise<ILawCategory>;
      getLawCategories(activeOnly: boolean): angular.IPromise<ILawCategoryListItem[]>;
      createLawCategory(data: IUpdateLawCategoryData): angular.IPromise<ILawCategory>;
      updateLawCategory(lawCategoryId: string, data: IUpdateLawCategoryData): angular.IPromise<boolean>;
      createLawSubcategory(lawCategoryId: string, data: IUpdateLawSubcategoryData): angular.IPromise<ILawSubcategory>;
      updateLawSubcategory(lawCategoryId: string, lawSubcategoryId: string, data: IUpdateLawSubcategoryData): angular.IPromise<ILawSubcategory>;
      getLegalTeam(id: string): angular.IPromise<ILegalTeam>;
      getLegalTeams(activeOnly: boolean): angular.IPromise<ILegalTeamListItem[]>;
      createLegalTeam(data: IUpdateLegalTeamData): angular.IPromise<ILegalTeam>;
      updateLegalTeam(id: string, data: IUpdateLegalTeamData): angular.IPromise<ILegalTeam>;
      getTaskCategory(taskCategoryId: string): angular.IPromise<ITaskCategory>;
      getTaskCategories(): angular.IPromise<ITaskCategoryListItem[]>;
      createTaskCategory(data: IUpdateTaskCategoryData): angular.IPromise<ITaskCategory>;
      updateTaskCategory(taskCategoryId: string, data: IUpdateTaskCategoryData): angular.IPromise<boolean>;
      createTask(taskCategoryId: string, data: IUpdateTaskData): angular.IPromise<ITask>;
      updateTask(taskCategoryId: string, taskId: string, data: IUpdateTaskData): angular.IPromise<ITask>;
      getRaceSubcategories(): angular.IPromise<IRaceSubcategoryListItem[]>;
   }

   const baseUrl = 'api/configuration';

   @Service('app.admin.configuration', 'admin.configuration.repository')
   class ConfigurationRepository implements IConfigurationRespository {
      static $inject = ['$http'];
      constructor(private $http: angular.IHttpService) {
         'ngInject';
      }

      @ApiExecutor()
      public getLookupType(lookupTypeId: string) {
         return this.$http.get<ILookupType>(`${baseUrl}/lookup-types/${lookupTypeId}`) as any;
      }

      @ApiExecutor()
      public getLookupTypes() {
         return this.$http.get<ILookupTypeListItem[]>(`${baseUrl}/lookup-types`) as any;
      }

      @ApiExecutor({ returnValue: true })
      public updateLookupType(lookupTypeId: string, data: IUpdateLookupTypeData) {
         return this.$http.put<boolean>(`${baseUrl}/lookup-types/${lookupTypeId}`, data) as any;
      }

      @ApiExecutor()
      public getLawCategory(lawCategoryId: string, activeSubcategoriesOnly: boolean = true) {
         return this.$http.get<ILawCategory>(`${baseUrl}/law-categories/${lawCategoryId}?activeSubcategoriesOnly=${activeSubcategoriesOnly}`) as any;
      }

      @ApiExecutor()
      public getLawCategories(activeOnly: boolean = true) {
         return this.$http.get<ILawCategoryListItem[]>(`${baseUrl}/law-categories?activeOnly=${activeOnly}`) as any;
      }

      @ApiExecutor()
      public createLawCategory(data: IUpdateLawCategoryData) {
         return this.$http.post<ILawCategory>(`${baseUrl}/law-categories`, data) as any;
      }

      @ApiExecutor({ returnValue: true })
      public updateLawCategory(lawCategoryId: string, data: IUpdateLawCategoryData) {
         return this.$http.put<boolean>(`${baseUrl}/law-categories/${lawCategoryId}`, data) as any;
      }

      @ApiExecutor()
      public createLawSubcategory(lawCategoryId: string, data: IUpdateLawSubcategoryData) {
         return this.$http.post<ILawSubcategory>(`${baseUrl}/law-categories/${lawCategoryId}/subcategories`, data) as any;
      }

      @ApiExecutor()
      public updateLawSubcategory(lawCategoryId: string, lawSubcategoryId: string, data: IUpdateLawSubcategoryData) {
         return this.$http.put<ILawSubcategory>(`${baseUrl}/law-categories/${lawCategoryId}/subcategories/${lawSubcategoryId}`, data) as any;
      }

      @ApiExecutor()
      public getLegalTeam(id: string) {
         return this.$http.get<ILegalTeam>(`${baseUrl}/legal-teams/${id}`) as any;
      }

      @ApiExecutor()
      public getLegalTeams(activeOnly: boolean = true) {
         return this.$http.get<ILegalTeamListItem[]>(`${baseUrl}/legal-teams?activeOnly=${activeOnly}`) as any;
      }

      @ApiExecutor()
      public createLegalTeam(data: IUpdateLegalTeamData) {
         return this.$http.post<ILegalTeam>(`${baseUrl}/legal-teams`, data) as any;
      }

      @ApiExecutor()
      public updateLegalTeam(id: string, data: IUpdateLegalTeamData) {
         return this.$http.put<ILegalTeam>(`${baseUrl}/legal-teams/${id}`, data) as any;
      }

      @ApiExecutor()
      public getTaskCategory(taskCategoryId: string) {
         return this.$http.get<ITaskCategory>(`${baseUrl}/task-categories/${taskCategoryId}`) as any;
      }

      @ApiExecutor()
      public getTaskCategories() {
         return this.$http.get<ITaskCategoryListItem[]>(`${baseUrl}/task-categories`) as any;
      }

      @ApiExecutor()
      public createTaskCategory(data: IUpdateTaskCategoryData) {
         return this.$http.post<ITaskCategory>(`${baseUrl}/task-categories`, data) as any;
      }

      @ApiExecutor({ returnValue: true })
      public updateTaskCategory(taskCategoryId: string, data: IUpdateTaskCategoryData) {
         return this.$http.put<boolean>(`${baseUrl}/task-categories/${taskCategoryId}`, data) as any;
      }

      @ApiExecutor()
      public createTask(taskCategoryId: string, data: IUpdateTaskData) {
         return this.$http.post<ILawSubcategory>(`${baseUrl}/task-categories/${taskCategoryId}/tasks`, data) as any;
      }

      @ApiExecutor()
      public updateTask(taskCategoryId: string, taskId: string, data: IUpdateTaskData) {
         return this.$http.put<ITask>(`${baseUrl}/task-categories/${taskCategoryId}/tasks/${taskId}`, data) as any;
      }

      @ApiExecutor()
      public getRaceSubcategories() {
         return this.$http.get<IRaceSubcategoryListItem[]>(`${baseUrl}/racesubcategories`) as any;
      }
   }
}