namespace app.analysis {
   @Controller('app.analysis', 'AnalysisColumnSettingsController')
   class ColumnSettingsController {
      static $inject = ['$mdDialog', 'datacontext', 'queryId', 'columns', 'selectedColumns'];
      constructor(
         private $mdDialog: angular.material.IDialogService,
         private datacontext: data.IDataContextService,
         private queryId: string,
         public columns: IFieldMetadata[],
         public selectedColumns: string[]) {
         'ngInject';
      }

      public isSubmitting = false;

      public cancel() {
         this.$mdDialog.cancel();
      }

      public isColumnSelected(name: string) {
         return this.selectedColumns.indexOf(name) > -1;
      }

      public submit() {
         this.isSubmitting = true;
         this.datacontext.analysis
            .updateQueryColumns(this.queryId, this.selectedColumns)
            .then(result => {
               if (result) this.$mdDialog.hide(this.selectedColumns);
            }).finally(() => this.isSubmitting = false);
      }

      public toggleColumn(name: string) {
         const index = this.selectedColumns.indexOf(name);
         if (index > -1) {
            this.selectedColumns.splice(index, 1);
         } else {
            this.selectedColumns.push(name);
         }
      }
   }
}