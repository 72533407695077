namespace app.grievance {
   @Controller('app.grievance', 'GrievanceViewDialogController')
   class GrievanceDialogController {
      static $inject = ['$mdDialog', 'grievance'];
      constructor(
         private $mdDialog: angular.material.IDialogService,
         private grievance: IGrievance) {
         'ngInject';
      }

      public cancel() {
         this.$mdDialog.cancel();
      }
   }
}