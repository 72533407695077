namespace app.persons.details {
   @Component('app.persons.details', 'cePersonSpecialConsiderationsView', {
      templateUrl: 'app/persons/details/special-considerations/special-considerations-view.html',
      bindings: {
         data: '<',
         onEdit: '&'
      }
   })
   class PersonSpecialConsiderationsView {
   }
}