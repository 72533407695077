namespace app.informationAndReferrals.details {
   @Component('app.informationAndReferrals.details', 'ceInformationAndReferralDetails', {
      templateUrl: 'app/information-and-referrals/details/details.html',
      bindings: {
         case: '<'
      }
   })
   class InformationAndReferralDetails {
      static $inject = ['$mdDialog', 'common', 'datacontext'];
      constructor(
         private $mdDialog: angular.material.IDialogService,
         private common: core.ICommonService,
         private datacontext: data.IDataContextService) {
         'ngInject';
      }

      private case: IInformationAndReferralBasicInfoView;
      private caseInformation: IInformationAndReferralInformation;
      private pwdInformation: persons.IDemographics;
      private callerInformation: persons.IDemographics;
      private editCaseInformation: boolean = false;
      private editPWDInformation: boolean = false;
      private editCallerInformation: boolean = false;

      public $onInit() {
         let promise = null;

         promise = this.getCase();
         promise.then(
            () => {
               this.GetCaseInformation();
               this.GetPWDInformation();
               this.GetCallerInformation();
            });
      }

      public getCase() {
         return this.datacontext.informationAndReferrals.getInformationAndReferralBasicInfoView(this.case.id)
            .then(iandr => this.case = iandr);
      }

      public updateCaseInformation(result: IInformationAndReferralInformation) {
         this.caseInformation = result;
         this.editCaseInformation = false;
      }

      public updatePWDInformation(result: persons.IDemographics) {
         this.pwdInformation = result;
         this.editPWDInformation = false;
      }
      public updateCallerInformation(result: persons.IDemographics) {
         this.callerInformation = result;
         this.editCallerInformation = false;
      }
      public GetCaseInformation() {
         this.datacontext.informationAndReferrals.getInformationAndReferralInformation(this.case.id).then(
            result => this.caseInformation = result
         );
      }

      public GetPWDInformation() {
         if (this.case.pwdPersonId) {
            this.datacontext.persons.getDemographics(this.case.pwdPersonId).then(
               result => this.pwdInformation = result
            );
         }
      }
      public GetCallerInformation() {
         if (this.case.requesterPersonId && this.case.pwdPersonId !== this.case.requesterPersonId) {
            this.datacontext.persons.getDemographics(this.case.requesterPersonId).then(
               result => this.callerInformation = result
            );
         }
      }
   }
}