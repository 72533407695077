namespace app.data {
   const interceptorName = 'apiBaseUrlInterceptor';

   const interceptor = (configurationProvider: IConfigurationProvider) => {
      'ngInject';
      return {
         'request': (request: any) => {
            if (request.url.match(/^api\//i)) {
               request.url = configurationProvider.config.apiRootUrl + '/' + request.url.replace(/^api\//i, '');
            }
            return request;
         }
      };
   };

   const configure = ($httpProvider: angular.IHttpProvider) => {
      'ngInject';
      $httpProvider.interceptors.push(interceptorName);
   };

   angular
      .module('app.data')
      .factory(interceptorName, interceptor)
      .config(configure);
}