namespace app.requests.details {
   @Component('app.requests.details', 'ceRequestInformation', {
      templateUrl: 'app/requests/details/request-information/request-information.html',
      bindings: {
         data: '<',
         onEdit: '&'
      }
   })
   class RequestInformation {
   }
}