namespace app.persons {
   @Component('app.persons', 'ceCreatePerson', {
      templateUrl: 'app/persons/find-create-person/create-person.html',
      bindings: {
      }
   })
   class CreatePerson {
      static $inject = ['$scope', '$mdDialog', 'common', 'datacontext', 'addressHelper'];
      constructor(
         private $scope: angular.IScope,
         private $mdDialog: angular.material.IDialogService,
         private common: core.ICommonService,
         private datacontext: data.IDataContextService,
         private addressHelper: utils.IAddressHelperService) {
         'ngInject';
         this.init();
      }

      public person = {} as ICreatePerson;
      public address = {} as app.entities.IEntityAddress;
      public isSubmitting: boolean = false;
      public isLoading: boolean = true;
      public promise: angular.IPromise<any>;
      public personId: string;

      private init() {
      }

      public cancel() {
      }

      public refresh = () => {
      }

      public createNewPerson() {
      }

      public phoneChanged(phone: common.IPhone) {
         this.person.phoneNumber = phone;
      }

      public onPlaceSelected(place: any) {
         this.common.$timeout(() => {
            angular.extend(this.address, this.addressHelper.mapFromGooglePlaceResult(place));
         });
      }

      public submit() {
         this.isSubmitting = true;

         let personPromise = this.datacontext.persons
            .createPerson(this.person)
            .then(person => {
               if (person) {
                  angular.extend(this.person, person);
                  this.common.$mdToast.showSimple('Person created');
               }
            });

         if (this.person.dateOfBirth) {
            personPromise.then(() => {
               this.datacontext.persons.updateBaseDemographics(this.person.id, { id: this.person.id, dateOfBirth: this.person.dateOfBirth });
            });
         }

         if (this.address.line1) {
            personPromise.then(() => {
               this.address.entityId = this.person.id;
               this.address.addressType = 'Residential';
               this.address.isPrimary = true;
               this.datacontext.entities.createEntityAddress(this.address);
            });
         }

         personPromise.then(() => {
            this.isSubmitting = false;
            let entity = {} as app.entities.IEntityBasicInfo;
            entity.entityId = this.person.id;
            entity.fullName = `${this.person.firstName} ${this.person.lastName}`;
            entity.entityType = 'Person';
            entity.isUser = false;
            this.common.$state.go('^.roles', { entity: entity });
         });
      }
   }
}