namespace app.entities {
   @Component('app.entities', 'ceRelatedEntityList', {
      templateUrl: 'app/entities/related-entities/related-entity-list.html',
      bindings: {
         entities: '<',
         entityId: '<',
         onDelete: '&'
      }
   })
   class RelatedEntityList {
      static $inject = ['common'];
      constructor(
         private common: core.ICommonService) {
         'ngInject';
      }

      public hasSupervisorRole(association: IAssociationView) {
         return association.roles.some(r => r === 'Supervisor');
      }

      public editRoles(association: IAssociationView) {
         this.common.$state.go('^.edit', { association: association });
      }
   }
}