namespace app.training.training {
   'use strict';

   export const trainingBasicInfoResolver = (datacontext: data.IDataContextService, $stateParams: angular.ui.IStateParamsService) => {
      'ngInject';
      const { trainingId } = $stateParams;
      return datacontext.training.getTrainingInformation(trainingId);
   };

   export const showNumberOfBrochuresResolver = (datacontext: data.IDataContextService) => {
      'ngInject';
      const bypassReasonsPromise = datacontext.config.getConfigurationParameter<boolean>('Training.NumberOfBrochures', false);
      return bypassReasonsPromise.then(result => {
              return result.toString() === 'true';
      });
   };
}