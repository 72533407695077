namespace app.utils {
   export interface IStepperService {
      (handle: string): IStepper;
   }

   export interface IStepper {
      next(): void;
      prev(): void;
      addGroup(group: IStepperGroup): number;
      currentStep: string;
      currentGroupIndex: number;
      currentStepIndex: number;
      groups: IStepperGroup[];
      isActive(group: number, step: number);
      isCompleted(group: number, step?: number);
   }

   export interface IStepperGroup {
      label: string;
      steps: string[];
   }

   @Component('app.utils', 'ceStepper', {
      templateUrl: 'app/utils/stepper/stepper.html',
      transclude: true,
      bindings: {
         currentStep: '='
      }
   })
   class Stepper implements IStepper {
      static $inject = ['$attrs', '$mdComponentRegistry', 'common'];
      constructor(
         private $attrs: angular.IAttributes,
         private $mdComponentRegistry: any,
         private common: core.ICommonService) {
         'ngInject';
      }

      private registeredStepper;
      public currentStep: string;
      public currentStepIndex: number;
      public currentGroupIndex: number;

      public groups: IStepperGroup[] = [];

      public addGroup(group: IStepperGroup) {
         return this.groups.push(group) - 1;
      }

      public isActive(group: number, step?: number) {
         return angular.isDefined(step)
            ? this.currentGroupIndex === group && this.currentStepIndex === step
            : this.currentGroupIndex === group;
      }

      public isCompleted(group: number, step?: number) {
         if (angular.isDefined(step)) {
            if (this.currentGroupIndex > group) return true;
            else if (this.currentGroupIndex === group) return this.currentStepIndex > step;
            else return false;
         } else {
            return this.currentGroupIndex > group;
         }
      }

      private $onChanges() {
         this.currentGroupIndex = 0;
         this.currentStepIndex = 0;
         this.currentStep = '0.0';
      }

      private $postLink() {
         if (!this.$attrs['id']) {
            this.common.logger.warning('You must set an id attribute on your stepper');
         }

         this.registeredStepper = this.$mdComponentRegistry.register(this, this.$attrs['id']);
      }

      private $onDestroy() {
         this.registeredStepper && this.registeredStepper();
      }

      public next() {
         const group = this.groups[this.currentGroupIndex];
         if (this.currentStepIndex < group.steps.length - 1) {
            this.currentStepIndex++;
         } else if (this.currentGroupIndex < this.groups.length - 1) {
            this.currentGroupIndex++;
            this.currentStepIndex = 0;
         }
         this.currentStep = `${this.currentGroupIndex}.${this.currentStepIndex}`;
      }

      public prev() {
         if (this.currentStepIndex > 0) {
            this.currentStepIndex--;
         } else if (this.currentGroupIndex > 0) {
            const group = this.groups[--this.currentGroupIndex];
            this.currentStepIndex = group.steps.length - 1;
         }
         this.currentStep = `${this.currentGroupIndex}.${this.currentStepIndex}`;
      }
   }

   @Component('app.utils', 'ceStepperGroup', {
      templateUrl: 'app/utils/stepper/stepper-group.html',
      bindings: {
         label: '@',
         steps: '<'
      },
      require: {
         stepper: '^ceStepper'
      }
   })
   class StepperGroup implements IStepperGroup {
      private stepper: IStepper;

      public label: string;
      public number: number;
      public steps: string[];

      private $onInit() {
         this.number = this.stepper.addGroup(this);
      }
   }

   const StepperServiceFactor = ($mdComponentRegistry) => {
      'ngInject';
      return <IStepperService>(handle: string) => {
         const instance: IStepper = $mdComponentRegistry.get(handle);

         if (!instance) {
            $mdComponentRegistry.notFoundError(handle);
         }

         return instance;
      };
   };

   angular.module('app.utils')
      .factory('ceStepper', StepperServiceFactor);
}