namespace app.funding {
   @Component('app.funding', 'ceCreatePrimaryFunding', {
      templateUrl: 'app/funding/enrollments/create/create.html',
      bindings: {
         involvementId: '<'
      }
   })
   class CreatePrimaryFunding {
      static $inject = ['common', 'datacontext'];
      constructor(
         private common: core.ICommonService,
         private datacontext: data.IDataContextService) {
         'ngInject';
      }

      public fundingPrograms: IEnrollmentEligibilityView[];
      public involvementId: string;
      public enrollment = {} as IEnrollment;
      public isLoading: boolean = true;
      public maxDate: Date = moment().toDate();
      public isSubmitting: boolean;

      public $onInit = () => {
         this.getFundingSources();
         this.enrollment.involvementId = this.involvementId;
         this.enrollment.date = moment().toDate();
      }

      private getFundingSources = () => {
         this.datacontext.funding.getFundingSummaryView(this.involvementId)
            .then(result => {
               if (result) {
                  this.enrollment.fundingProgramId = result.primaryFundingProgramId;
               }
               this.datacontext.funding
                  .getEnrollmentEligibilityView(this.involvementId)
                  .then(result => {
                     this.fundingPrograms = result;
                     this.isLoading = false;
                  }
                  );
            });
      };

      public submit() {
         this.isSubmitting = true;
         if (typeof this.enrollment.date !== 'string') {
            this.enrollment.date = this.enrollment.date.toDateString();
         }

         this.datacontext.funding.createEnrollment(this.enrollment)
            .then(result => {
               this.common.$state.go('^.');
            });
      }
   }
}