namespace app.persons.details {
   @Component('app.persons.details', 'cePersonDemographicsForm', {
      templateUrl: 'app/persons/details/demographics/demographics-form.html',
      bindings: {
         demographics: '<',
         paramPovertyLevel: '<',
         paramAtPoverty: '<',
         paramRaceSubCategory: '<',
         onCancel: '&',
         onSubmit: '&'
      }
   })
   class PersonDemographicsForm {
      static $inject = ['common', 'datacontext', '$scope'];
      constructor(
         private common: core.ICommonService,
         private datacontext: data.IDataContextService,
         private $scope: angular.IScope) {
         'ngInject';
      }
      public isLoading: boolean = false;
      public demographics: IDemographics;
      public data = {} as IDemographics;
      public incomeSources: app.config.ILookup[];
      public paramPovertyLevel: boolean;
      public paramAtPoverty: boolean;
      public paramRaceSubCategory: boolean;
      public raceSubcategories: app.config.IRaceSubcategory[] = [];
      public validRaceSubcategories: app.config.IRaceSubcategory[] = [];

      public $onInit() {
         this.getData();
         this.getSubCategory();
         this.$scope.$watch(() => this.data.race, this.getValidRaceSubcategories);
      }

      private $onChanges() {
         this.data = angular.extend({}, this.demographics);
      }

      public toggle(value: string) {
         if (!this.data.incomeSources) {
            this.data.incomeSources = [];
         }

         if (this.exists(value)) {
            _.pull(this.data.incomeSources, value);
         }
         else {
            this.data.incomeSources.push(value);
         }
      }

      public exists(value: string) {
         return _.includes(this.data.incomeSources, value);
      }

      public getData() {
         this.isLoading = true;
         return this.datacontext.config.lookups.getLookupType('income-sources').then(
            result => {
               this.incomeSources = result.lookups;
               this.isLoading = false;
            });
      }

      public getSubCategory() {
         return this.datacontext.admin.configuration.getRaceSubcategories().then((raceSubcategories: any) => {
            this.raceSubcategories = raceSubcategories;
            this.getValidRaceSubcategories();
         });
      }

      public raceChanged(test: any) {
         this.validRaceSubcategories = null;
      }

      public getValidRaceSubcategories = () => {
         this.validRaceSubcategories = this.raceSubcategories.filter(sc => sc.raceName === this.data.race);
      }
   }
}