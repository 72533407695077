namespace app.admin {
   interface IModuleVm {
      title: string;
      baseSref: string;
      isExpanded?: boolean;
      links: {
         title: string;
         icon: string;
         sref: string;
      }[];
   }

   @Component('app.admin', 'ceAdmin', {
      templateUrl: 'app/admin/views/layout/layout.html'
   })
   class AdminLayout {
      static $inject = ['$state'];
      constructor(public $state: angular.ui.IStateService) {
         'ngInject';
      }

      public modules: IModuleVm[] = [
         {
            title: 'Users & Permissions',
            baseSref: 'app.admin.identityManagement',
            links: [
               { title: 'Users', icon: 'groups', sref: 'users' },
               { title: 'Roles', icon: 'security', sref: 'roles' }
            ]
         },
         {
            title: 'Configuration Settings',
            baseSref: 'app.admin.configuration',
            links: [
               { title: 'Lookups', icon: 'list', sref: 'lookupTypeList' },
               { title: 'Law Categories', icon: 'list', sref: 'lawCategoryList' },
               { title: 'Legal Teams', icon: 'list', sref: 'legalTeamList' },
               { title: 'Timeslip Tasks', icon: 'list', sref: 'taskCategoryList' }
            ]
         },
         {
            title: 'Tenant Settings',
            baseSref: 'app.admin.tenancy',
            links: [
               { title: 'Email', icon: 'mail', sref: 'email' }
            ]
         },
         {
            title: 'Tools',
            baseSref: 'app.admin.tools',
            links: [
               { title: 'Merge Entities', icon: 'merge_type', sref: 'entityMerge' },
               { title: 'Lock Timeslips', icon: 'lock', sref: 'timeslipLock' },
               { title: 'Documents', icon: 'insert_drive_file', sref: 'documents' }
            ]
         }
      ];

      public selectedModule = 'permissions';

      public toggleModule = (module: IModuleVm) => {
         if (module.isExpanded) {
            module.isExpanded = false;
         } else {
            angular.forEach(this.modules, x => x.isExpanded = false);
            module.isExpanded = true;
            this.$state.go(`${module.baseSref}.${module.links[0].sref}`);
         }
      };

      private $onInit() {
         const { name: currentState } = this.$state.current;

         for (let module of this.modules) {
            if (currentState.match(`^${module.baseSref}`)) {
               module.isExpanded = true;
               break;
            }
         }
      }
   }
}