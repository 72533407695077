namespace app.funding {
   @Component('app.funding', 'cePavaOutcomeForm', {
      templateUrl: 'app/funding/enrollments/pava/pava-outcome-form.html',
      bindings: {
         outcome: '<',
         onCancel: '&',
         onSubmit: '&'
      }
   })
   class PAVAOutcomeForm {
      static $inject = ['common', 'datacontext'];
      constructor(
         private common: core.ICommonService,
         private datacontext: data.IDataContextService) {
         'ngInject';
      }

      public interventionStrategies: app.config.ILookup[];

      private $onInit() {
         this.getInterventionStrategies();
      }

      public getInterventionStrategies() {
         return this.datacontext.config.lookups.getLookupType('pava-intervention-strategies')
            .then(lookup => { this.interventionStrategies = lookup.lookups; });
      }
   }
}