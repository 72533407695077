namespace app.documentManagement {
   export interface IDocumentManagementRepository {
      createDocument(data: ICreateDocumentData): angular.IPromise<any>;
      changeDocumentFolder(data: IChangeDocumentFolderData): angular.IPromise<any>;
      moveDocument(data: IMoveDocumentData): angular.IPromise<any>;
      copyDocument(data: ICopyDocumentData): angular.IPromise<any>;
      renameDocument(data: IRenameDocumentData): angular.IPromise<any>;
      deleteDocument(documentId: string): angular.IPromise<boolean>;
      downloadDocument(documentId: string): angular.IPromise<IDownloadDocumentResult>;
      getDocuments(params: IGetDocumentsParams): data.IPagedListPromise<IDocument>;
      getFolders(params: IGetFoldersParams): data.IPagedListPromise<IDocumentFolder>;
      createFolder(data: ISaveFolderData): angular.IPromise<IFolder>;
      updateFolder(data: ISaveFolderData): angular.IPromise<IFolder>;
      deleteFolder(folderId: string): angular.IPromise<boolean>;
   }

   const baseUrl = 'api/document-management';

   class DocumentManagementRepository implements IDocumentManagementRepository {
      constructor(
         private $http: angular.IHttpService,
         private exception: core.exception.IExceptionService) {
         'ngInject';
      }

      @ApiExecutor()
      public createDocument(data: ICreateDocumentData) {
         return this.$http.post<any>(`${baseUrl}/documents`, data, {
            responseType: 'arraybuffer'
         }) as any;
      }

      @ApiExecutor()
      public changeDocumentFolder(data: IChangeDocumentFolderData) {
         return this.$http.put<any>(`${baseUrl}/documents/${data.documentId}/change`, data) as any;
      }

      @ApiExecutor()
      public moveDocument(data: IMoveDocumentData) {
         return this.$http.put<any>(`${baseUrl}/documents/${data.documentId}/move`, data) as any;
      }

      @ApiExecutor()
      public copyDocument(data: ICopyDocumentData) {
         return this.$http.put<any>(`${baseUrl}/documents/${data.documentId}/copy`, data) as any;
      }

      @ApiExecutor()
      public renameDocument(data: IRenameDocumentData) {
         return this.$http.put<any>(`${baseUrl}/documents/${data.documentId}/rename`, data) as any;
      }

      @ApiExecutor({ returnValue: true })
      public deleteDocument(documentId: string) {
         return this.$http.delete<boolean>(`${baseUrl}/documents/${documentId}`) as any;
      }

      public downloadDocument(documentId: string) {
         const config = { responseType: 'arraybuffer' };

         return this.$http.get<any>(`${baseUrl}/documents/${documentId}/download`, config).then(
            (response) => {
               const contentType = response.headers()['content-type'];
               let fileName = response.headers()['content-disposition'];

               if (fileName) {
                  fileName = fileName.split(';')[1].split('=')[1].replace(/"/g, '');
               }

               return {
                  buffer: response.data,
                  contentType,
                  fileName
               };
            }).catch(error => this.exception.catcher('XHR for downloadDocument failed')(error)) as any;
      }

      @ApiExecutor()
      public getDocuments(params: IGetDocumentsParams) {
         return this.$http.get<data.IPagedListPromise<IDocument>>(`${baseUrl}/documents`, { params }) as any;
      }

      @ApiExecutor()
      public getFolders(params: IGetFoldersParams) {
         return this.$http.get<data.IPagedListPromise<IDocumentFolder>>(`${baseUrl}/folders`, { params }) as any;
      }

      @ApiExecutor()
      public createFolder(data: ISaveFolderData) {
         return this.$http.post<IFolder>(`${baseUrl}/folders`, data) as any;
      }

      @ApiExecutor()
      public updateFolder(data: ISaveFolderData) {
         return this.$http.put<IFolder>(`${baseUrl}/folders/${data.id}`, data) as any;
      }

      @ApiExecutor({ returnValue: true })
      public deleteFolder(folderId: string) {
         return this.$http.delete<boolean>(`${baseUrl}/folders/${folderId}`) as any;
      }
   }

   angular
      .module('app.documentManagement')
      .service('documentManagement.repository', DocumentManagementRepository);
}