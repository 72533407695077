namespace app.clientCases.details {
   @Component('app.clientCases.details', 'ceSpecialEducation', {
      templateUrl: 'app/client-cases/details/special-education/special-education.html',
      bindings: {
         data: '<',
         onEdit: '&'
      }
   })
   class SpecialEducation {
   }
}