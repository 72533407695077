namespace app.time {
   @Component('app.time', 'ceTimer', {
      templateUrl: 'app/time/timer/timer.html',
      bindings: {
         source: '@',
         sourceId: '<',
         sourceName: '<',
         sourceName2: '<',
         involvementId: '<'
      }
   })
   class Timer {
      static $inject = ['timerService', 'auth'];
      constructor(
         public timerService: ITimerService,
         private auth: core.security.IAuthorizationService
      ) {
         'ngInject';
      }

      private source: string;
      private sourceId: string;
      private sourceName: string;
      private sourceName2: string;
      private involvementId: string;
      public timeslipTimerEnabled: boolean = false;

      public $onInit() {
         this.timeslipTimerEnabled = this.auth.user.timeslipTimerEnabled;
         if (this.timeslipTimerEnabled) {
            this.timerService.init(this.source, this.sourceId, this.sourceName, this.sourceName2, this.involvementId);
            this.timerService.startTimer();
         }
      }
   }
}