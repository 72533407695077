namespace app.persons.details {
   @Component('app.persons.details', 'cePersonDemographics', {
      templateUrl: 'app/persons/details/demographics/demographics.html',
      bindings: {
         person: '<',
         paramPovertyLevel: '<',
         paramAtPoverty: '<',
         paramRaceSubCategory: '<',
         onEdit: '&',
      }
   })
   class PersonDemographics {
      static $inject = ['common', 'datacontext'];
      constructor(
         private common: core.ICommonService,
         private datacontext: data.IDataContextService) {
         'ngInject';
      }
      public isLoading: boolean = false;
      public editMode: boolean = false;
      public person: IPersonBasicInfo;
      public data = {} as IDemographics;
      public incomeSources: app.config.ILookup[];
      public isSubmitting: boolean = false;
      public onSubmit: (args: { result: IDemographics }) => void;

      public $onInit() {
         this.getData();
      }

      public cancelEdit() {
         this.editMode = false;
      }

      public editData(data: IDemographics) {
         this.editMode = true;
      }

      public getData() {
         this.isLoading = true;

         return this.datacontext.persons
            .getDemographics(this.person.id)
            .then(result => {
               this.data = result;
               this.isLoading = false;
            });
      }

      public saveData(data: IDemographics) {
         this.isSubmitting = true;

         if (data.highestUnmetLegalNeed !== 'Other') {
            data.highestUnmetLegalNeedOther = null;
         }
         if (data.communicationPreference !== 'Other') {
            data.communicationPreferenceOther = null;
         }

         if (typeof data.dateOfBirth !== 'string' && data.dateOfBirth) {
            data.dateOfBirth = data.dateOfBirth.toDateString();
         }

         if (typeof data.dateOfDeath !== 'string' && data.dateOfDeath) {
            data.dateOfDeath = data.dateOfDeath.toDateString();
         }

         this.datacontext.persons
            .updateDemographics(this.person.id, data)
            .then((result) => {
               this.data = result;
               angular.extend(this.person, result);
               this.isSubmitting = false;
               this.cancelEdit();
            });
      }
   }
}