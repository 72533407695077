namespace app.training.training {
   'use strict';

   const routes: core.routing.IStateExtended[] = [
      {
         name: 'app.createTraining',
         url: '/trainings/create',
         template: '<ce-training-create layout="column" flex></ce-training-create>',
         data: {
            title: 'Create Training',
            permissions: ['trainings.edit']
         }
      },
      {
         name: 'app.training',
         url: '/trainings/{trainingId}',
         template: '<ce-training-view data="$resolve.training" layout="column" flex></ce-training-view>',
         data: { permissions: ['trainings.view'] },
         redirectTo: 'app.training.overview',
         resolve: { training: trainingBasicInfoResolver }
      },
      {
         name: 'app.training.overview',
         url: '/overview',
         template: '<ce-training-overview data="$resolve.training" layout="column" flex></ce-training-overview>',
         data: {
            title: 'Training Overview',
            permissions: ['trainings.view']
         }
      },
      {
         name: 'app.training.details',
         url: '/details',
         template: '<ce-training-details data="$resolve.training" layout="column" flex param-number-of-brochures="$resolve.paramNumberOfBrochures"></ce-training-details>',
         data: {
            title: 'Training Details',
            permissions: ['trainings.view']
         },
         resolve: { paramNumberOfBrochures: showNumberOfBrochuresResolver }
      },
      {
         name: 'app.training.documents',
         url: '/documents',
         template: '<ce-documents-list layout="column" flex source="Training" source-id="$resolve.training.id"></ce-documents-list>',
         data: {
            title: 'Training Documents',
            permissions: ['documents.view']
         }
      },
      {
         name: 'app.training.uploadDocuments',
         url: '/upload',
         params: {
            folderId: null
         },
         resolve: {
            folderId: ['$stateParams', p => p.folderId],
         },
         template: '<ce-upload-documents-activity layout flex folder-id="$resolve.folderId" source="Training" source-id="$resolve.training.id"></ce-upload-documents-activity>',
         data: { permissions: ['documents.edit'] }
      },
      {
         name: 'app.training.emails',
         url: '/emails',
         template: '<ui-view flex layout="column"></ui-view>',
         redirectTo: 'app.training.emails.listing',
         data: {
            title: 'Training Emails',
            permissions: ['mail.view']
         }
      },
      {
         name: 'app.training.emails.listing',
         url: '',
         template: '<ce-email-listing source="Training" source-id="$resolve.training.id" layout="column" flex></ce-email-listing>',
         data: {
            title: 'Training Emails',
            permissions: ['mail.view']
         }
      },
      {
         name: 'app.training.emails.message',
         url: '/{messageId}',
         template: '<ce-email-view layout="column" flex message-id="$resolve.messageId"></ce-email-view>',
         data: {
            title: 'Message',
            permissions: ['mail.view']
         },
         resolve: {
            messageId: ['$stateParams', p => p.messageId]
         }
      },
      {
         name: 'app.training.emails.link',
         url: '/link',
         template: '<ce-link-emails layout="column" flex source="Training" source-id="$resolve.training.id"></ce-link-emails>',
         data: {
            title: 'Inbox Messages',
            permissions: ['mail.link']
         }
      },
      {
         name: 'app.training.timeslipListing',
         url: '/timeslips',
         template: '<ce-timeslip-listing source="Training" source-id="$resolve.training.id" source-name="$resolve.training.title" enable-filters="false" layout="column" flex></ce-timeslip-listing>',
         data: {
            title: 'Training Timeslips',
            permissions: ['timeslips.view']
         }
      },
      {
         name: 'app.training.timeslipCalendar',
         url: '/timeslips-calendar',
         template: '<ce-timeslip-calendar-frame source="Training" source-id="$resolve.training.id" source-name="$resolve.training.title" enable-filters="false" layout="column" flex></ce-timeslip-calendar-frame>',
         data: {
            title: 'Training Timeslips',
            permissions: ['timeslips.view']
         }
      },
      {
         name: 'app.training.funding',
         url: '/funding',
         template: '<ce-training-funding training-id="$resolve.training.id" source="Training" layout="column" flex></ce-training-funding>',
         data: { title: 'Training Funding' }
      },
      {
         name: 'app.training.calendar',
         url: '/calendar',
         template: '<ce-training-calendar source="Training" source-id="$resolve.training.id" layout="column" flex></ce-training-calendar>',
         data: {
            title: 'Calendar',
         }
      },
      {
         name: 'app.training.eventSchedule',
         url: '/eventSchedule',
         template: `<ce-calendar-event-schedule
                     event-schedule-id="$resolve.eventScheduleId"
                     target-date="$resolve.targetDate"
                     source="Training"
                     source-name="$resolve.training.title"
                     source-id="$resolve.training.id"></ce-calendar-event-schedule>`,
         params: {
            eventScheduleId: null,
            targetDate: null
         },
         resolve: {
            eventScheduleId: ['$stateParams', p => p.eventScheduleId],
            targetDate: ['$stateParams', p => p.targetDate],
         },
         data: { title: 'Event Schedule', permissions: ['events.view'], }
      },
      {
         name: 'app.training.event',
         url: '/event',
         template: '<ce-calendar-event event="$resolve.event"></ce-calendar-event>',
         params: {
            event: null
         },
         resolve: {
            event: ['$stateParams', e => e.event]
         },
         data: {
            title: 'Event',
            permissions: ['events.view']
         }
      },
   ];

   const runner = (routingHelper: core.routing.IRoutingHelperService) => {
      'ngInject';
      routingHelper.configureRoutes(routes);
   };

   angular
      .module('app.training.training')
      .run(runner);
}