namespace app.entities.activities {
   interface IActivityViewModel {
      icon: string;
      text: string;
      sref: string;
      name?: string;
   }

   const activities: IActivityViewModel[] = [
      {
         icon: 'contact_phone',
         text: 'Create Request',
         sref: '^.createRequest',
         name: 'Create Request'
      },
      {
         icon: 'info',
         text: 'Create I&R',
         sref: '^.createInformationAndReferral',
         name: 'Create I&R'
      },
      {
         icon: 'folder_shared',
         text: 'Create Client Case',
         sref: '^.createClientCase',
         name: 'Create Client Case'
      },
   ];

   @Controller('app.entities.activities', 'NewEntityActivityDialogController')
   class NewEntityActivityDialogController {
      static $inject = ['$mdDialog', 'common', 'entityId'];
      constructor(
         private $mdDialog: angular.material.IDialogService,
         private common: core.ICommonService,
         private entityId: string) {
         'ngInject';
      }

      public filteredActivities: IActivityViewModel[];

      private $onInit() {
         this.filteredActivities = activities;
      }

      public selectActivity(item: IActivityViewModel) {
         this.common.$state.go(item.sref, { entityId: this.entityId });
         this.$mdDialog.hide();
      }
   }
}