namespace app.funding {
   @Component('app.funding', 'cePaddIntakeForm', {
      templateUrl: 'app/funding/enrollments/padd/padd-intake-form.html',
      bindings: {
         intake: '<',
         onCancel: '&',
         onSubmit: '&'
      }
   })
   class PADDIntakeForm {
      static $inject = ['common', 'datacontext'];
      constructor(
         private common: core.ICommonService,
         private datacontext: data.IDataContextService) {
         'ngInject';
      }
   }
}