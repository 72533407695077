namespace app.utils {
   angular
      .module('app.utils')
      .filter('isEmpty', () => {
         let prop: string;
         return (obj: Object) => {
            for (prop in obj) {
               if (obj.hasOwnProperty(prop)) {
                  return false;
               }
            }
            return true;
         };
      });
}