namespace app.search {
   @Component('app.search', 'ceAdvancedInvolvementSearchResults', {
      templateUrl: 'app/search/advanced-search/involvements/advanced-involvement-search-results.html',
      bindings: {
         promise: '=',
         query: '=',
         results: '=',
         onPaginate: '&',
         onReorder: '&'
      }
   })
   class AdvancedInvolvementSearchResults {
      public onPaginate: (args: { page: number }) => void;
      public onReorder: () => void;

      public paginate = (page: number) => {
         this.onPaginate({ page });
      };

      public reorder = () => {
         this.onReorder();
      };
   }
}