namespace app.utils {
   angular
      .module('app.utils')
      .directive('noDirtyCheck', function () {
         return {
            restrict: 'A',
            require: 'ngModel',
            link: function (scope, elm, attrs, ctrl: angular.IFormController) {
               ctrl.$pristine = false;
            }
         };
      });
}