namespace app.monitoring.activities {
   @Component('app.monitoring.activities', 'ceMonitoringActivitiesReopen', {
      templateUrl: 'app/monitoring/activities/reopen/reopen.html',
      bindings: {
         monitoring: '<'
      }
   })
   class Reopen {
      static $inject = ['common', 'datacontext'];
      constructor(
         private common: core.ICommonService,
         private datacontext: data.IDataContextService) {
         'ngInject';
      }

      public monitoring: IMonitoringBasicInfoView;
      public isSubmitting: boolean = false;
      public activity = {} as ICreateMonitoringActivity;

      private $onInit() {
         this.activity.monitoringId = this.monitoring.id;
         this.activity.involvementId = this.monitoring.involvementId;
         this.activity.activityDate = moment().toDate();
      }

      public submit() {
         this.isSubmitting = true;

         if (typeof this.activity.activityDate !== 'string') {
            this.activity.activityDate = this.activity.activityDate.toDateString();
         }

         this.datacontext.monitoring
            .reopen(this.activity)
            .then(cc => {
               if (cc) {
                  this.common.$mdToast.showSimple('Monitoring Reopened');
                  this.common.$rootScope.$broadcast('refreshBasicInfo');
                  this.monitoring.isActive = true;
                  this.common.$state.go('^.list', { monitoringId: this.monitoring.id, involvementId: this.monitoring.involvementId });
               }
            })
            .finally(() => this.isSubmitting = false);
      }
   }
}