namespace app.funding {
   @Component('app.funding', 'ceMultipleChoiceFundingForm', {
      templateUrl: 'app/funding/multiple-choice/edit/edit.html',
      bindings: {
         selectedFundingPrograms: '<',
         onCancel: '&',
         onSubmit: '&',
         source: '<'
      }
   })
   class MultipleChoiceFundingForm {
      static $inject = ['common', 'datacontext'];
      constructor(
         private common: core.ICommonService,
         private datacontext: data.IDataContextService) {
         'ngInject';
      }
      public onSubmit: (args: { result: IFundingProgram[] }) => void;
      public fundingPrograms: IFundingProgram[];
      public selectedFundingPrograms: IFundingProgram[];
      public source: string;

      public $onInit() {
         this.getFundingSources();
      }

      public toggle(fundingProgram: IFundingProgram) {
         const program = this.exists(fundingProgram);
         if (program) {
            _.pull(this.selectedFundingPrograms, program);
         }
         else {
            this.selectedFundingPrograms.push(fundingProgram);
         }
      }

      public exists(fundingProgram: IFundingProgram) {
         const exists = _.find(this.selectedFundingPrograms, sfp => sfp.fundingProgramId === fundingProgram.fundingProgramId);
         return exists;
      }

      private getFundingSources = () => {
         return this.datacontext.funding
            .getFundingPrograms(true, this.source)
            .then(programs => this.fundingPrograms = programs);
      }

      public submit() {
         this.onSubmit({ result: this.selectedFundingPrograms });
      }
   }
}