namespace app.persons.views {
   @Component('app.persons.views', 'cePWDInformation', {
      templateUrl: 'app/persons/views/pwd-information/pwd-information.html',
      bindings: {
         data: '<',
         onEdit: '&'
      }
   })
   class PWDInformation {
   }
}