namespace app.training.training {
   export interface ITrainingCalendarDataService {
      eventSources: IEventSource[];
      refresh(source: string, sourceId: string): void;
   }

   class TrainingCalendarDataService {
      constructor(
         private common: core.ICommonService,
         private datacontext: data.IDataContextService,
         private trainingCalendarState: ITrainingCalendarStateService) {
         'ngInject';
         this.eventSources = [];
         this.activate();
      }

      private state = this.trainingCalendarState.state;
      public eventSources: IEventSource[];

      public refresh = (source: string, sourceId: string) => {
         const params: IGetEventsParams = {
            source: source,
            sourceId: sourceId,
            from: this.state.dateRange.start.toDate(),
            to: this.state.dateRange.end.toDate(),
            highPriorityOnly: this.state.viewType === 'highPriority'
         };

         this.eventSources.splice(0);
         this.getEvents(params);
      };

      private activate = () => {
         return this.getCalendars();
      };

       private getCalendars = () => {
          return this.datacontext.calendars.getCalendars().then(result => {
             this.trainingCalendarState.update({ calendars: result });
          }).finally(() => {
             let calendarIds: string[] = [];
             angular.forEach(this.state.calendars, c => calendarIds.push(c.id));
             this.trainingCalendarState.update({ calendarIds: calendarIds });
          });
       };

      private getEvents = (params: IGetEventsParams) => {
         this.datacontext.training
            .getTrainingEvents(params)
            .then(result => {
               if (result) {
                  let results = _(result);
                  let events = results.map(e => this.mapEvent(e)).value() as app.calendars.IEvent[];
                  this.eventSources.push({ events });
               }
            });
      };

      private mapEvent = (e: app.calendars.IEvent): app.calendars.IEvent => {
         let start = moment(e.rescheduledStartTime || e.startTime);
         let end;

         if (e.allDay) {
            start = moment(e.eventDate).startOf('day');
            end = moment(e.eventDate).endOf('day');
         } else {
            end = moment(e.rescheduledEndTime || e.endTime);
         }

         const calendar = _.find(this.state.calendars, c => c.id === e.calendar);
         let color;

         const isPast = end.diff(moment()) < 0;
         if (isPast) {
            color = calendar.completedColor;
         } else {
            color = calendar.color;
         }

         return { ...e, title: `${e.title}`, color, start, end };
      };
   }

   angular
      .module('app.training.training')
      .service('trainingCalendarData', TrainingCalendarDataService);
}