namespace app.identity {
   @Controller('app.identity', 'EditUserProfileController')
   class EditUserProfileController {
      static $inject = ['$mdDialog', 'datacontext', 'common'];
      constructor(
         private $mdDialog: angular.material.IDialogService,
         private datacontext: data.IDataContextService,
         private common: core.ICommonService) {
         'ngInject';
         this.activate();
      }

      public data: IUpdateUserData;
      public isSubmitting = false;
      public photoButton = { icon: 'camera_alt', toolTip: 'Take Photo' };
      public photoInputMethod = 'upload';
      public selectedTab = 'info';
      public useUploadMethod = true;
      public user: IUser;

      public cancel() {
         this.discardPhoto();
         this.$mdDialog.cancel();
      }

      public discardPhoto() {
      }

      public submit() {
         this.isSubmitting = true;
         this.datacontext.identity
            .updateCurrentUser(this.data)
            .then(result => {
               if (result) this.$mdDialog.hide(result);
            })
            .finally(() => this.isSubmitting = false);
      }

      public tabSelected(tab: string) {
         this.selectedTab = tab;
      }

      private activate = () => {
         this.getUser().then(() => {
            const { firstName, lastName } = this.user;
            this.data = {
               firstName,
               lastName
            };
         });
      };

      private getUser = () => {
         return this.datacontext.identity
            .getCurrentUser()
            .then(result => this.user = result);
      };
   }
}