namespace app.informationAndReferrals.overview {
   @Component('app.informationAndReferrals.overview', 'ceInformationAndReferralSummary', {
      templateUrl: 'app/information-and-referrals/summary/summary.html',
      bindings: {
         case: '<'
      }
   })
   class InformationAndReferralSummary {
      static $inject = ['$mdDialog', 'common', 'datacontext'];
      constructor(
         private $mdDialog: angular.material.IDialogService,
         private common: core.ICommonService,
         private datacontext: data.IDataContextService) {
         'ngInject';
      }

      public caseSummary: IInformationAndReferralSummaryView;
      public case: IInformationAndReferralBasicInfoView;
      private openDuration: string;
      private closeDuration: string;

      public $onInit() {
         this.datacontext.informationAndReferrals.getInformationAndReferralSummaryView(this.case.id)
            .then(result => {
               let data: any = result;
               this.caseSummary = data;
            });
      }

      public init() {
      }
   }
}