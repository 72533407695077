namespace app.analysis {
   @Controller('app.analysis', 'SaveAnalysisQueryController')
   class SaveAnalysisQueryController {
      static $inject = ['$mdDialog', 'datacontext', 'query'];
      constructor(
         private $mdDialog: angular.material.IDialogService,
         private datacontext: data.IDataContextService,
         public query: IQuery) {
         'ngInject';
      }

      public cancel() {
         this.$mdDialog.cancel();
      }

      public submit() {
         const queryPromise = this.query.id
            ? this.datacontext.analysis.updateQuery(this.query)
            : this.datacontext.analysis.createQuery(this.query);

         queryPromise.then(result => {
            if (result) {
               this.$mdDialog.hide(result);
            }
         });
      }
   }
}