namespace app.admin {
   const routes: core.routing.IStateExtended[] = [
      {
         name: 'app.admin',
         url: '/admin',
         template: '<ce-admin layout="column" flex></ce-admin>',
         redirectTo: 'app.admin.identityManagement',
         data: {
            title: 'System Administration',
            roles: ['Administrator']
         },
      }
   ];

   const runner = (routingHelper: core.routing.IRoutingHelperService) => {
      routingHelper.configureRoutes(routes);
   };

   angular
      .module('app.admin')
      .run(runner);
}