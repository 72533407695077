namespace app.core.exception {
   'use strict';

   interface IExceptionConfig {
   }

   class ExceptionConfig implements angular.IServiceProvider {
      public config = <IExceptionConfig>{};

      public $get(): IExceptionConfig {
         return this.config;
      }
   }

   angular
      .module('app.core.exception')
      .provider('exceptionConfig', ExceptionConfig)
      .config(configure);

   function configure($provide: angular.auto.IProvideService) {
      'ngInject';
      $provide.decorator('$exceptionHandler', extendExceptionHandler);
   }

   function extendExceptionHandler(
      $delegate: angular.IExceptionHandlerService,
      exceptionConfig: ExceptionConfig,
      $injector: angular.auto.IInjectorService) {
      'ngInject';

      return function (exception, cause) {
         $delegate(exception, cause);
         if (exception && exception.suppressToast) return;

         const errorData = { exception: exception, cause: cause };
         const msg = exception.message;

         /**
          * Could add the error to a service's collection,
          * add errors to $rootScope, log errors to remote web server,
          * or log locally. Or throw hard. It is entirely up to you.
          * throw exception;
          *
          * @example
          *     throw { message: 'error message we added' };
          *
          */
         const logger = $injector.get('logger') as logging.ILogService;
         logger.error(msg, errorData);
      };
   }
}