namespace app.calendars {
   @Component('app.calendars', 'ceCalendarEvent', {
      templateUrl: 'app/calendars/events/event.html',
      bindings: {
         event: '<'
      }
   })
   class CalendarEventSchedule {
      static $inject = ['common', 'datacontext', '$mdDialog'];
      constructor(
         private common: core.ICommonService,
         private datacontext: data.IDataContextService,
         private $mdDialog: angular.material.IDialogService) {
         'ngInject';
      }

      public event: IEvent;
      public isSubmitting: boolean;

      private $onInit() {
         this.event.rescheduledStartTime = this.event.rescheduledStartTime || this.event.startTime;
         this.event.rescheduledEndTime = this.event.rescheduledEndTime || this.event.endTime;
      }

      public cancel() {
         this.common.$state.go('^.calendar');
      }

      public delete() {
         this.common.confirmation.show({
            title: 'Delete Event',
            message: 'Are you sure you want to delete this Event?',
            ok: 'Delete',
            cancel: 'Keep'
         }).then(this.deleteEvent);
      }

      private deleteEvent = () => {
         this.isSubmitting = true;

         const event: IDeleteEvent = {
            id: this.event.id,
            eventScheduleId: this.event.eventScheduleId,
            startTime: this.event.startTime,
            endTime: this.event.endTime,
         };

         this.datacontext.calendars.deleteEvent(event)
            .then(() => {
               this.common.$mdToast.showSimple(`Event Deleted`);
               this.$mdDialog.hide();
               this.common.$state.go('^.calendar');
            })
            .finally(() => this.isSubmitting = false);
      }

      public submit() {
         this.isSubmitting = true;

         if (this.event.allDay) {
            this.event.rescheduledStartTime = new Date(this.event.rescheduledStartTime.getFullYear(), this.event.rescheduledStartTime.getMonth(), this.event.rescheduledStartTime.getDate());
            this.event.rescheduledEndTime = null;
         } else {
            // the ui does permit the user to update the endtime date, so use the rescheduled starttime date to fix this
            this.event.rescheduledEndTime = new Date(this.event.rescheduledStartTime.getFullYear(), this.event.rescheduledStartTime.getMonth(), this.event.rescheduledStartTime.getDate(),
               this.event.rescheduledEndTime.getHours(), this.event.rescheduledEndTime.getMinutes());
         }

         const event: IRescheduleEvent = {
            id: this.event.id,
            eventScheduleId: this.event.eventScheduleId,
            rescheduledStartTime: this.event.rescheduledStartTime,
            rescheduledEndTime: this.event.rescheduledEndTime,
            startTime: this.event.startTime,
            endTime: this.event.endTime,
         };

         this.datacontext.calendars.rescheduleEvent(event)
            .then(() => {
               this.common.$mdToast.showSimple(`Event Rescheduled`);
               this.$mdDialog.hide();
               this.common.$state.go('^.calendar');
            })
            .finally(() => this.isSubmitting = false);
      }
   }
}