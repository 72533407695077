namespace app.funding {
   @Component('app.funding', 'cePaatSystemicIntakeForm', {
      templateUrl: 'app/funding/enrollments/paat/systemic/paat-intake-form.html',
      bindings: {
         intake: '<',
         onCancel: '&',
         onSubmit: '&',
         livingArrangements: '<',
         problemAreas: '<'
      }
   })
   class PAATSystemicIntakeForm {
      static $inject = ['datacontext'];
      constructor(
         private datacontext: data.IDataContextService) {
         'ngInject';
      }

      public ageRanges: app.config.ILookup[];
      public races: app.config.ILookup[];

      public $onInit() {
         this.getAgeRanges();
         this.getRaces();
      }

      public getAgeRanges() {
         return this.datacontext.config.lookups.getLookupType('paat-systemic-age-ranges')
            .then(lookup => { this.ageRanges = lookup.lookups; });
      }

      public getRaces() {
         return this.datacontext.config.lookups.getLookupType('paat-systemic-races')
            .then(lookup => { this.races = lookup.lookups; });
      }
   }
}