namespace app.utils {
   @Controller('app.utils', 'TernaryConfirmationDialogController')
   class TernaryConfirmationDialogController {
      static $inject = ['$mdDialog', 'title', 'message', 'submitButtonText', 'submitButtonTooltip', 'leftButtonText', 'leftButtonTooltip', 'middleButtonText', 'middleButtonTooltip'];
      constructor(
         private $mdDialog: angular.material.IDialogService,
         private title: string,
         private message: string,
         private submitButtonText: string,
         private submitButtonTooltip: string,
         private leftButtonText: string,
         private leftButtonTooltip: string,
         private middleButtonText: string,
         private middleButtonTooltip: string
      ) {
         'ngInject';
      }

      public leftButtonClicked() {
         this.$mdDialog.hide(this.leftButtonText);
      }

      public middleButtonClicked() {
         this.$mdDialog.hide(this.middleButtonText);
      }

      public submit() {
         this.$mdDialog.hide(this.submitButtonText);
      }
   }
}