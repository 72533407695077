namespace app.layout {
   @Component('app.layout', 'ceSideNav', {
      templateUrl: 'app/layout/side-nav/side-nav.html'
   })
   @Inject('common')
   class SideNav {
      static $inject = ['common'];
      constructor(private common: core.ICommonService) {
      }
   }
}