namespace app.informationAndReferrals.activities {
   @Component('app.informationAndReferrals.activities', 'ceInformationAndReferralActivitiesClose', {
      templateUrl: 'app/information-and-referrals/activities/close/close.html',
      bindings: {
         case: '<',
         bypassClosureReasons: '<'
      }
   })
   class Close {
      static $inject = ['common', 'datacontext'];
      constructor(
         private common: core.ICommonService,
         private datacontext: data.IDataContextService) {
         'ngInject';
      }

      public bypassClosureReasons: boolean;
      public statuses: app.config.ILookup[] = [];
      public selectedStatus: string;
      public case: IInformationAndReferralBasicInfoView;
      public isSubmitting: boolean = false;
      public activity = {} as ICreateInformationAndReferralActivity;
      public items: common.IToDoItem[];
      public maxDate: Date;
      // public minDate: Date;

      public $onInit() {
         this.activity.involvementId = this.case.involvementId;
         this.activity.caseId = this.case.id;
         this.activity.activityDate = moment().toDate();
         this.bypassClosureReasons = (this.bypassClosureReasons || false).toString() === 'true';
         this.maxDate = this.activity.activityDate;
         this.getInformationAndReferralsCaseStatus();
         // this.getMinDate();
      }

      private getInformationAndReferralsCaseStatus() {
         return this.datacontext.informationAndReferrals.getInformationAndReferralStatus(this.case.id).then(
            result => {
               let data: any = result;
               if (data.status) {
                  this.selectedStatus = data.status;
                  this.activity.statusDescription = data.statusDescription;
               }
            });
      }

      /*private getMinDate() {
         return this.datacontext.informationAndReferrals.getInformationAndReferralSummaryView(this.case.id).then(
            result => this.minDate = (result as any).openDate
         );
      }*/

      public submit() {
         this.isSubmitting = true;

         if (typeof this.activity.activityDate !== 'string') {
            this.activity.activityDate = this.activity.activityDate.toDateString();
         }

         if (this.bypassClosureReasons) {
            this.selectedStatus = 'Closed';
         }

         this.activity.status = this.selectedStatus || 'Closed';
         this.activity.statusDescription = null;

         this.datacontext.informationAndReferrals
            .close(this.activity)
            .then(request => {
               if (request) {
                  this.common.$mdToast.showSimple('I&R Closed');
                  this.common.$rootScope.$broadcast('refreshBasicInfo');
                  this.case.isActive = false;
                  this.common.$state.go('^.list', { caseId: this.case.id, involvementId: this.case.involvementId });
               }
            })
            .finally(() => this.isSubmitting = false);
      }
   }
}