namespace app.systemicCases.activities {
   @Component('app.systemicCases.activities', 'ceSystemicCasesActivitiesNote', {
      templateUrl: 'app/systemic-cases/activities/note/note.html',
      bindings: {
         involvementId: '<',
         caseId: '<',
         activity: '<',
         caseOpenDate: '<'
      }
   })
   class Note {
      static $inject = ['common', 'datacontext', 'interrupt'];
      constructor(
         private common: core.ICommonService,
         private datacontext: data.IDataContextService,
         private interrupt: core.routing.IInterruptService) {
         'ngInject';
      }

      public caseId: string;
      public involvementId: string;
      public activity: app.activities.IActivity;

      public isSubmitting: boolean = false;
      public selectedStatus: string;
      public note = {} as ICreateSystemicCaseActivity;
      public maxDate: Date;

      private $onInit() {
         this.maxDate = moment().toDate();
         if (this.activity) {
            this.note.id = this.activity.id;
            this.note.caseId = this.activity.sourceId;
            this.note.involvementId = this.activity.involvementId;
            this.note.title = this.activity.title;
            this.note.description = this.activity.description;
            this.note.activityDate = this.activity.activityDate;
         } else {
            this.note.involvementId = this.involvementId;
            this.note.caseId = this.caseId;
            this.note.activityDate = moment().toDate();
            this.getStatus();
         }
      }

      private getStatus() {
         return this.datacontext.systemicCases.getSystemicCaseStatus(this.caseId).then(
            result => {
               let data: any = result;
               if (data.status) {
                  this.selectedStatus = data.status;
                  this.note.statusDescription = data.statusDescription;
               }
            });
      }

      public submit() {
         this.isSubmitting = true;

         if (typeof this.note.activityDate !== 'string') {
            this.note.activityDate = this.note.activityDate.toDateString();
         }

         this.note.status = this.selectedStatus;
         if (this.selectedStatus !== 'Other') {
            this.note.statusDescription = null;
         }

         const promise = this.note.id
            ? this.datacontext.systemicCases.updateNote(this.note)
            : this.datacontext.systemicCases.createNote(this.note);

         promise
            .then(note => {
               if (note) {
                  this.common.$mdToast.showSimple('Note ' + (this.note.id ? 'updated' : 'created'));
                  this.common.$rootScope.$broadcast('refreshBasicInfo');
                  this.common.$state.go('^.list', { caseId: this.caseId });
               }
            })
            .finally(() => this.isSubmitting = false);
      }
   }
}