namespace app.activities {
   @Component('app.activities', 'ceActivityList', {
      templateUrl: 'app/activities/activity-list/activity-list.html',
      bindings: {
         activities: '<',
         showInvolvementActivities: '<',
         refresh: '&',
         paging: '<',
         promise: '<',
         onEdit: '&',
      }
   })
   class ActivityListComponent {
      static $inject = ['$mdDialog'];
      constructor(private $mdDialog: angular.material.IDialogService) {
         'ngInject';
      }
      public activities: any;
      public showInvolvementActivities: boolean;

      public $onChanges() {
         if (this.activities && this.activities.list.length > 0) {
            this.activities.list = _.forEach(this.activities.list, (activity: any) => { activity.showReportIcon = this.showReportIcon(activity); });
            this.activities.list = _.forEach(this.activities.list, (activity: any) => { activity.canEdit = this.canEdit(activity); });
         }
      }

      public canEdit(activity: app.activities.IActivity): boolean {
         if (this.showInvolvementActivities === true) {
            return false;
         }

         const a = _.find(app.activities.ActivityConfig, a => a.activityType === activity.activityType);
         return a && a.canEdit === true;
      }

      public showReportIcon(activity: app.activities.IActivity) {
         return this.getReportName(activity) != null;
      }

      public getReportName(activity: app.activities.IActivity) {
         let reportName = null;
         const a = _.find(app.activities.ActivityConfig, a => a.activityType === activity.activityType);
         if (a && a.reportName) {
            reportName = a.reportName;
         }
         return reportName;
      }

      public showReportDialog(activity: app.activities.IActivity) {
         let report = {
            category: 'Activity Report',
            label: 'Single Note',
            name: 'Single Note',
            description: 'Single Note Report',
            permission: 'reports.view',
            parameters: [
               { label: 'Note Type', name: 'NoteType', value: activity.name, dataType: 'hidden' },
               { label: 'ActivityId', name: 'ActivityId', dataType: 'hidden', value: activity.id },
            ]
         } as app.reports.IReport;

         return this.$mdDialog.show({
            fullscreen: true,
            templateUrl: 'app/reports/report-dialog/report-dialog.html',
            controller: 'ReportDialogController',
            controllerAs: '$ctrl',
            focusOnOpen: false,
            locals: {
               report: report
            }
         })
            .catch((error) => { if (error) throw error; })
            .finally(() => angular.noop);
      }
   }
}