namespace app.training.training {
   export interface ITrainingCalendarState {
      dateRange: moment.Range;
      viewType: string;
      viewDate: moment.Moment;
      showCompleted: boolean;
      calendars: ICalendar[];
   }

   export interface ITrainingCalendarStateService {
      state: ITrainingCalendarState;
      save();
      update(state: any);
   }

   class TrainingCalendarStateService implements ITrainingCalendarStateService {
      constructor(private $localStorage: angular.storage.IStorageService) {
         'ngInject';

         if ($localStorage.calendarState) {
            delete $localStorage.calendarState.dateRange;
            delete $localStorage.calendarState.viewType;
            delete $localStorage.calendarState.viewDate;
         }

         this.loadState();
      }

      public state = {} as ITrainingCalendarState;

      public save() {
         this.$localStorage['trainingCalendarState'] = this.state;
      }

      public update(state) {
         angular.extend(this.state, state);
         angular.extend(this.$localStorage['trainingCalendarState'], state);
      }

      private loadState = () => {
         if (this.$localStorage['trainingCalendarState']) {
            angular.extend(this.state, this.$localStorage['trainingCalendarState']);
         } else {
            this.$localStorage['trainingCalendarState'] = this.state;
         }

         if (this.state.dateRange) {
            this.state.dateRange = moment.range(this.state.dateRange.start, this.state.dateRange.end);
         }
      }
   }

   angular
      .module('app.training.training')
      .service('trainingCalendarState', TrainingCalendarStateService);
}