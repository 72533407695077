namespace app.training.request {
   @Component('app.training.request', 'ceTrainingRequestDetails', {
      templateUrl: 'app/training/request/details/request-details.html',
      bindings: {
         data: '<',
         onEdit: '&'
      }
   })
   class Request {
      static $inject = ['datacontext'];
      constructor(private datacontext: data.IDataContextService) {
         'ngInject';
      }

      public data: IRequest;
      public editRequest: boolean = false;

      public updateRequest(result: IRequest) {
         angular.extend(this.data, result);
         this.editRequest = false;
      }
   }
}