namespace app.training.training {
   @Component('app.training.training', 'ceTrainingView', {
      templateUrl: 'app/training/training/view/training-view.html',
      bindings: {
         data: '<'
      }
   })
   class TrainingInformation {
      static $inject = ['common', 'confirmation', 'datacontext', 'timerService'];
      constructor(
         private common: core.ICommonService,
         private confirmation: IConfirmationService,
         private datacontext: data.IDataContextService,
         private timerService: time.ITimerService) {
         'ngInject';
      }

      private $onInit() {
      }

      private showDeleteDialog(id: string) {
         this.confirmation.show({
            message: 'Are you sure you want to delete this Training? This will delete all data attached to this Training.',
            ok: 'DELETE',
            cancel: 'KEEP'
         }).then(() => { this.deleteTraining(id); });
      }

      private deleteTraining(id: string) {
         this.datacontext.training.deleteTraining(id).then((result) => {
            if (result) {
               this.timerService.killTimer();
               this.common.$state.go('app.trainingList');
            }
         });
      }
   }
}