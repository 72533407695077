namespace app.core {
   const interceptorName = 'loadingInterceptor';

   const interceptor = ($q: angular.IQService, $rootScope: IRootScopeService) => {
      'ngInject';

      let loadingCount = 0;

      return {
         request: (config) => {
            if (!config.workingDisabled) {
               loadingCount++;
               $rootScope.isWorking = true;
            }
            return config || $q.when(config);
         },
         response: (response) => {
            if (loadingCount > 0) loadingCount--;

            if (!loadingCount) {
               $rootScope.isWorking = false;
            }

            return response || $q.when(response);
         },
         responseError: (response) => {
            if (loadingCount > 0) loadingCount--;

            if (!loadingCount) {
               $rootScope.isWorking = false;
            }

            return $q.reject(response);
         }
      };
   };

   const config = ($httpProvider: angular.IHttpProvider) => {
      'ngInject';
      $httpProvider.interceptors.push(interceptorName);
   };

   angular
      .module('app.core')
      .factory(interceptorName, interceptor)
      .config(config);
}