namespace app.cases {
   @Controller('app.cases', 'CaseRoutingController')
   class CaseRoutingController {
      static $inject = ['$scope', '$mdDialog', 'common', 'datacontext'];
      constructor(
         private $scope: angular.IScope,
         private $mdDialog: angular.material.IDialogService,
         private common: core.ICommonService,
         private datacontext: data.IDataContextService) {
         'ngInject';
         this.init();
      }

      private init() {
         const caseType = this.common.$stateParams['caseType'];
         const caseId = this.common.$stateParams['caseId'];

         switch (caseType) {
            case 'Request':
               this.common.$state.go('app.requests', { requestId: caseId });
               break;
            case 'Client Case':
               this.common.$state.go('app.clientCases', { clientCaseId: caseId });
               break;
            case 'I&R':
               this.common.$state.go('app.informationAndReferrals', { informationAndReferralId: caseId });
               break;
            case 'Systemic Case':
               this.common.$state.go('app.systemicCases', { systemicCaseId: caseId });
               break;
            case 'Monitoring':
               this.common.$state.go('app.monitoring', { monitoringId: caseId });
               break;
         }
      }
   }
}