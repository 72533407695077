namespace app.analysis {
   @Component('app.analysis', 'aimsAnalysisTabularData', {
      templateUrl: 'app/analysis/views/query-details/tabular-data.html',
      bindings: {
         onRefresh: '&',
         params: '<',
         promise: '<',
         tabularData: '<',
         visibleFields: '<'
      }
   })
   class TabularData {
      public onRefresh: () => angular.IPromise<any>;
      public promise: angular.IPromise<any>;

      public refresh = () => {
         this.promise = this.onRefresh();
      };
   }
}