namespace app.clientCases {
   @Component('app.clientCases', 'ceCreateClientCase', {
      templateUrl: 'app/client-cases/create-client-case/create-client-case.html',
      bindings: {
         requestId: '<',
         informationAndReferralId: '<',
         systemicCaseId: '<',
         involvementId: '<',
         entity: '<',
         entityType: '@',
         caseOpenDate: '<'
      }
   })
   class CreateClientCase {
      static $inject = ['common', 'datacontext'];
      constructor(
         private common: core.ICommonService,
         private datacontext: data.IDataContextService) {
         'ngInject';
      }

      public requestId: string;
      public informationAndReferralId: string;
      public systemicCaseId: string;
      public involvementId: string;
      public entity: app.entities.IEntityBasicInfo;
      public entityType: string;
      public roles: config.ILookup[];
      public selectedRoles: config.ILookup[] = [];
      public isSubmitting: boolean = false;
      public case = {} as ICreateClientCase;
      public maxDate: Date;

      private $onInit() {
         this.maxDate = moment().toDate();
         this.case.involvementId = this.involvementId;
         this.case.requestId = this.requestId;
         this.case.informationAndReferralId = this.informationAndReferralId;
         this.case.systemicCaseId = this.systemicCaseId;
         this.case.openDate = moment().toDate();

         if (this.entity) {
            this.getRoles();
         }
      }

      private transformChip(chip) {
         if (angular.isObject(chip)) {
            return chip;
         }
         return { name: chip, type: 'new' };
      }

      private querySearch(query) {
         let results = query ? _.filter(this.roles, (r: any) => {
            return query === '*' || r.value.toLowerCase().indexOf(query) > -1;
         }) : [];
         return results;
      }

      private getRoles = () => {
         const lookupTypeId: string = `involvement-${this.entityType}-role`;

         return this.datacontext.config.lookups.getLookupType(lookupTypeId).then(
            result => {
               this.roles = result.lookups;
            });
      }

      public submit() {
         this.isSubmitting = true;

         if (typeof this.case.openDate !== 'string') {
            this.case.openDate = this.case.openDate.toDateString();
         }

         this.datacontext.clientCases
            .createClientCase(this.case)
            .then(id => {
               if (id) {
                  if (this.entity) {
                     const roles: string[] = _.map(this.selectedRoles, 'value');

                     const data = {
                        entityId: this.entity.id,
                        involvementId: id,
                        roles: roles
                     } as involvements.IInvolvementEntity;

                     this.datacontext.involvements
                        .createEntity(data)
                        .then(() => {
                           this.common.$mdToast.showSimple('Client Case Created');
                           this.common.$state.go('app.clientCases', { clientCaseId: id });
                        });
                  } else {
                     this.common.$mdToast.showSimple('Client Case Created');
                     this.common.$state.go('app.clientCases', { clientCaseId: id });
                  }
               }
            })
            .finally(() => this.isSubmitting = false);
      }
   }
}