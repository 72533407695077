namespace app.systemicCases.closure {
   @Component('app.systemicCases.closure', 'ceSystemicCaseClosureInformation', {
      templateUrl: 'app/systemic-cases/closure/closure-information/closure-information.html',
      bindings: {
         data: '<',
         onEdit: '&'
      }
   })
   class SystemicCaseClosureInformation {
   }
}