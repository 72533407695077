namespace app.identity {
   @Controller('app.identity', 'SwitchTenantController')
   class SwitchTenantController {
      static $inject = ['$window', '$location', '$mdDialog', 'auth', 'datacontext', 'user', 'tenants'];
      constructor(
         private $window: angular.IWindowService,
         private $location: angular.ILocationService,
         private $mdDialog: angular.material.IDialogService,
         private auth: core.security.IAuthorizationService,
         private datacontext: data.IDataContextService,
         private user: IUser,
         public tenants: ITenant[]) {
         'ngInject';
         this.activate();
      }

      public data: ISwitchTenantData;
      public isSubmitting = false;
      public selectedTenant: ITenant;

      public cancel() {
         this.$mdDialog.cancel();
      }

      public submit() {
         this.isSubmitting = true;
         this.datacontext.identity
            .switchTenant(this.data)
            .then(result => {
               this.$location.path('/#!/homepage');
               this.$window.location.reload();
            }).finally(() => this.isSubmitting = false);
      }

      private activate = () => {
         this.data = {
            tenantId: this.user.tenantId
         };
      };
   }
}