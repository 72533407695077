namespace app.identity {
   export interface IIdentityRepository {
      getCurrentTenants(): angular.IPromise<ITenant[]>;
      getCurrentUser(): angular.IPromise<IUser>;
      getUsers(params?: IGetUserParams): angular.IPromise<IUser[]>;
      switchTenant(data: ISwitchTenantData): angular.IPromise<boolean>;
      updateCurrentUser(data: IUpdateUserData): angular.IPromise<IUser>;
   }

   const baseUrl = 'api/identity-management';

   class IdentityRepository implements IIdentityRepository {
      constructor(
         private $http: angular.IHttpService,
         private auth: core.security.IAuthorizationService) {
         'ngInject';
      }

      @ApiExecutor()
      public getCurrentTenants() {
         return this.$http.get<ITenant[]>(`${baseUrl}/users/current/tenants`) as any;
      }

      @ApiExecutor()
      public getCurrentUser() {
         return this.$http.get<IUser>(`${baseUrl}/users/current`) as any;
      }

      @ApiExecutor()
      public getUsers(params?: IGetUserParams) {
         return this.$http.get<IUser[]>(`${baseUrl}/users`, { params }) as any;
      }

      @ApiExecutor()
      public switchTenant(data: ISwitchTenantData) {
         return this.$http.put(`${baseUrl}/users/current/tenants/switch`, data) as any;
      }

      @ApiExecutor()
      public updateCurrentUser(data: IUpdateUserData) {
         const { id } = this.auth.user;
         return this.$http.put<IUser>(`${baseUrl}/users/${id}`, data) as any;
      }
   }

   angular
      .module('app.identity')
      .service('identity.repository', IdentityRepository) as any;
}