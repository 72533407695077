namespace app.monitoring {
   'use strict';

   const routes: core.routing.IStateExtended[] = [
      {
         name: 'app.monitoring',
         url: '/monitoring/{monitoringId}',
         template: '<ce-monitoring-view monitoring="$resolve.monitoring" layout="column" flex></ce-monitoring-view>',
         redirectTo: 'app.monitoring.overview',
         data: {
            title: 'Monitoring View',
            permissions: ['monitoring.view']
         },
         resolve: { monitoring: monitoringBasicInfoResolver }
      },
      {
         name: 'app.monitoring.overview',
         url: '/overview',
         template: '<ce-monitoring-overview monitoring="$resolve.monitoring" layout="column" flex></ce-monitoring-overview>',
         data: {
            title: 'Monitoring Overview',
            permissions: ['monitoring.view']
         }
      },
      {
         name: 'app.monitoring.details',
         url: '/details',
         template: '<ce-monitoring-details monitoring="$resolve.monitoring" bypass-census="$resolve.bypassCensus" layout="column" flex></ce-monitoring-details>',
         data: {
            title: 'Monitoring Details',
            permissions: ['monitoring.view']
         },
         resolve: { bypassCensus: bypassCensusInfoResolver }
      },
      {
         name: 'app.monitoring.involvement',
         url: '/involvement',
         template: '<ce-involvement-case-listing involvement-id="$resolve.monitoring.involvementId" flex></ce-involvement-case-listing>',
         data: { title: 'Monitoring Involvement' }
      },
      {
         name: 'app.monitoring.timeslipListing',
         url: '/timeslips',
         template: '<ce-timeslip-listing source="Monitoring" source-name="$resolve.monitoring.idNumber" source-name2="$resolve.monitoring.clientFullName" source-id="$resolve.monitoring.id" involvement-id="$resolve.monitoring.involvementId" enable-filters="false" flex layout="column"></ce-timeslip-listing>',
         data: {
            title: 'Monitoring Timeslips',
            permissions: ['timeslips.view']
         }
      },
      {
         name: 'app.monitoring.timeslipCalendar',
         url: '/timeslips-calendar',
         template: '<ce-timeslip-calendar-frame source="Monitoring" source-name="$resolve.monitoring.idNumber" source-name2="$resolve.monitoring.clientFullName" source-id="$resolve.monitoring.id" involvement-id="$resolve.monitoring.involvementId" enable-filters="false" flex layout="column"></ce-timeslip-calendar-frame>',
         data: {
            title: 'Monitoring Timeslips',
            permissions: ['timeslips.view']
         }
      },
      {
         name: 'app.monitoring.documents',
         url: '/documents',
         template: '<ce-documents-list involvement-id="$resolve.monitoring.involvementId" source="Monitoring" source-id="$resolve.monitoring.id" layout="column" flex></ce-document-list>',
         data: {
            title: 'Monitoring Documents',
            permissions: ['documents.view']
         }
      },
      {
         name: 'app.monitoring.uploadDocuments',
         url: '/upload',
         params: {
            folderId: null
         },
         resolve: {
            folderId: ['$stateParams', p => p.folderId],
         },
         template: '<ce-upload-documents-activity layout flex folder-id="$resolve.folderId" involvement-id="$resolve.monitoring.involvementId" source="Monitoring" source-id="$resolve.monitoring.id"></ce-upload-documents-activity>',
         data: { permissions: ['documents.edit'] }
      },
      {
         name: 'app.monitoring.emails',
         url: '/emails',
         template: '<ui-view flex layout="column"></ui-view>',
         redirectTo: 'app.monitoring.emails.listing',
         data: {
            title: 'Monitoring Emails',
            permissions: ['mail.view']
         }
      },
      {
         name: 'app.monitoring.emails.listing',
         url: '',
         template: '<ce-email-listing involvement-id="$resolve.monitoring.involvementId" source="Monitoring" source-id="$resolve.monitoring.id" layout="column" flex></ce-email-listing>',
         data: {
            title: 'Monitoring Emails',
            permissions: ['mail.view']
         }
      },
      {
         name: 'app.monitoring.emails.message',
         url: '/{messageId}',
         template: '<ce-email-view layout="column" flex message-id="$resolve.messageId"></ce-email-view>',
         data: {
            title: 'Message',
            permissions: ['mail.view']
         },
         resolve: {
            messageId: ['$stateParams', p => p.messageId]
         }
      },
      {
         name: 'app.monitoring.emails.link',
         url: '/link',
         template: '<ce-link-emails layout="column" flex involvement-id="$resolve.monitoring.involvementId" source="Monitoring" source-id="$resolve.monitoring.id"></ce-link-emails>',
         data: {
            title: 'Inbox Messages',
            permissions: ['mail.link']
         }
      },
      {
         name: 'app.monitoring.calendar',
         url: '/calendar',
         template: '<ce-involvements-calendar source="Monitoring" source-id="$resolve.monitoring.id" involvement-id="$resolve.monitoring.involvementId" layout="column" flex></ce-involvements-calendar>',
         data: {
            title: 'Calendar',
         }
      },
      {
         name: 'app.monitoring.eventSchedule',
         url: '/eventSchedule',
         template: `<ce-calendar-event-schedule
                     event-schedule-id="$resolve.eventScheduleId"
                     target-date="$resolve.targetDate"
                     source="Monitoring"
                     source-name="$resolve.monitoring.idNumber"
                     source-id="$resolve.monitoring.id"
                     involvement-id="$resolve.monitoring.involvementId"></ce-calendar-event-schedule>`,
         params: {
            eventScheduleId: null,
            targetDate: null
         },
         resolve: {
            eventScheduleId: ['$stateParams', p => p.eventScheduleId],
            targetDate: ['$stateParams', p => p.targetDate],
         },
         data: { title: 'Event Schedule', permissions: ['events.view'], }
      },
      {
         name: 'app.monitoring.event',
         url: '/event',
         template: '<ce-calendar-event event="$resolve.event"></ce-calendar-event>',
         params: {
            event: null
         },
         resolve: {
            event: ['$stateParams', e => e.event]
         },
         data: {
            title: 'Event',
            permissions: ['events.view']
         }
      },
      {
         name: 'app.monitoring.closure',
         url: '/closure',
         template: '<ce-monitoring-closure monitoring="$resolve.monitoring" layout="column" flex></ce-monitoring-closure>',
         data: {
            title: 'Monitoring Closure',
            permissions: ['monitoring.view']
         }
      }
   ];

   const runner = (routingHelper: core.routing.IRoutingHelperService) => {
      'ngInject';
      routingHelper.configureRoutes(routes);
   };

   angular
      .module('app.monitoring')
      .run(runner);
}