namespace app.time {
   @Component('app.time', 'ceTimeslipCalendarFrame', {
      templateUrl: 'app/time/calendar/timeslip-calendar.html',
      bindings: {
         enableFilters: '<',
         involvementId: '<',
         source: '@',
         sourceId: '<',
         sourceName: '<',
         sourceName2: '<?',
      }
   })
   class TimeslipCalendarFrame {
      static $inject = ['$mdDialog', 'common', 'datacontext'];
      constructor(
         private $mdDialog: angular.material.IDialogService,
         private common: core.ICommonService,
         private datacontext: data.IDataContextService) {
         'ngInject';
      }

      public selectedUsers: users.IUser[] = [];
      public enableFilters: boolean;
      public showFilters: boolean;

      private $onInit() {
         if (this.enableFilters === undefined) {
            this.enableFilters = true;
         }
      }

      public usersFilterChanged(users: users.IUser[]) {
         this.selectedUsers = [...users];
      }

      public toggleFilters() {
         this.showFilters = !this.showFilters;
      }
   }
}