namespace app.analysis {
   @Component('app.analysis', 'aimsAnalysisAddChartSidenav', {
      templateUrl: 'app/analysis/views/dashboard/add-chart-sidenav.html',
      bindings: {
         dashboard: '<',
         onChartAdded: '&'
      }
   })
   class NotificationsSidenavComponent {
      static $inject = ['$mdDialog', '$mdSidenav', 'common', 'datacontext', 'analysisConstants'];
      constructor(
         private $mdDialog: angular.material.IDialogService,
         private $mdSidenav: angular.material.ISidenavService,
         private common: core.ICommonService,
         private datacontext: data.IDataContextService,
         private analysisConstants: IAnalysisConstants) {
         'ngInject';
      }

      private cancelToken: Function;
      public charts: IChart[];
      public dashboard: IDashboard;
      public onChartAdded: (args: { chart: IDashboardChart }) => void;

      public addChart(chart: IChart, index: number) {
         const { id: chartId } = chart;
         this.datacontext.analysis
            .addChart(this.dashboard.id, { chartId })
            .then(result => {
               if (result) {
                  this.onChartAdded({ chart: result });
                  this.charts.splice(index, 1);
               }
            });
      }

      public close() {
         this.$mdSidenav(this.analysisConstants.ADD_CHART_SIDENAV_ID).close();
      }

      private $onInit = () => {
         this.cancelToken = this.common.$rootScope.$on(this.analysisConstants.ADD_CHART_SIDENAV_OPENED, this.getCharts);
      };

      private $onDestroy = () => {
         this.cancelToken();
      };

      private getCharts = () => {
         return this.datacontext.analysis
            .getChartList()
            .then(result => this.charts = _.filter(result, c => !_.some(this.dashboard.charts, dc => dc.chart.id === c.id)));
      };
   }
}