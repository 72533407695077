namespace app.config {
   export interface IConfigHelperService {
      getLookupValues(lookupTypeId: string, activeOnly?: boolean): angular.IPromise<string[]>;
   }

   class ConfigHelperService implements IConfigHelperService {
      constructor(private datacontext: data.IDataContextService) {
         'ngInject';
      }

      public getLookupValues(lookupTypeId: string, activeOnly = true) {
         return this.datacontext.config.lookups
            .getLookupType(lookupTypeId)
            .then(result => {
               let query = _(result.lookups);
               if (activeOnly) {
                  query = query.filter(l => l.isActive);
               }

               return query.map<string>('value').value();
            });
      }
   }

   angular
      .module('app.config')
      .service('configHelper', ConfigHelperService);
}