namespace app.identity {
   @Component('app.identity', 'ceUserMenu', {
      templateUrl: 'app/identity/user-menu/user-menu.html'
   })
   class UserMenuComponent {
      static $inject = ['$mdDialog', 'auth', 'datacontext', 'configurationProvider'];
      constructor(
         private $mdDialog: angular.material.IDialogService,
         private auth: core.security.IAuthorizationService,
         private datacontext: data.IDataContextService,
         private configurationProvider: IConfigurationProvider) {
         'ngInject';
         this.activate();
      }

      public user: IUser;
      public tenants: ITenant[];
      public apiDocsUrl: string;
      public wordAddinUrl: string;
      public supportUrl: string;
      public switchTenant(targetEvent: MouseEvent) {
         const parent = angular.element(document.body);

         this.$mdDialog.show({
            parent,
            targetEvent,
            templateUrl: 'app/identity/switch-tenant/switch-tenant.html',
            controller: 'SwitchTenantController',
            controllerAs: '$ctrl',
            focusOnOpen: true,
            fullscreen: true,
            locals: {
               tenants: this.tenants,
               user: this.user
            }
         });
      }

      public editProfile(targetEvent: MouseEvent) {
         const parent = angular.element(document.body);

         this.$mdDialog.show({
            parent,
            targetEvent,
            templateUrl: 'app/identity/edit-profile/edit-profile.html',
            controller: 'EditUserProfileController',
            controllerAs: '$ctrl',
            focusOnOpen: true,
            fullscreen: true
         }).then(this.getUser);
      }

      public signout() {
         this.auth.logout();
      }

      private activate = () => {
         this.getUser();
         this.getTenants();

         this.apiDocsUrl = this.configurationProvider.config.apiRootUrl.replace('/v1/', '/') + 'swagger';
         this.wordAddinUrl = this.configurationProvider.config.wordAddinUrl;
         this.supportUrl = this.configurationProvider.config.supportUrl;
      };

      private getUser = () => {
         return this.datacontext.identity
            .getCurrentUser()
            .then(result => this.user = result);
      };

      private getTenants = () => {
         this.datacontext.identity
            .getCurrentTenants()
            .then(result => this.tenants = result);
      };
   }
}