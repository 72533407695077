namespace app.agencies {
   @Component('app.agencies', 'ceAgencyView', {
      templateUrl: 'app/agencies/agency/agency-view.html',
      bindings: {
         agency: '<'
      }
   })
   class AgencyView {
      static $inject = ['$mdDialog', 'common'];
      constructor(
         private $mdDialog: angular.material.IDialogService,
         private common: core.ICommonService) {
         'ngInject';
      }

      public agency: IAgencyBasicInfo;
   }
}