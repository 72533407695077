namespace app.analysis {
   @Component('app.analysis', 'aimsAnalysisDashboard', {
      templateUrl: 'app/analysis/views/dashboard/dashboard.html',
      bindings: {
         dashboard: '<'
      }
   })
   class DashboardComponent {
      static $inject = ['$mdSidenav', 'common', 'datacontext', 'analysisConstants'];
      constructor(
         private $mdSidenav: angular.material.ISidenavService,
         private common: core.ICommonService,
         private datacontext: data.IDataContextService,
         private analysisConstants: IAnalysisConstants) {
         'ngInject';
      }

      public dashboard: IDashboard;
      public isEditing = false;

      public addChart() {
         this.$mdSidenav(this.analysisConstants.ADD_CHART_SIDENAV_ID).open();
         this.common.$broadcast(this.analysisConstants.ADD_CHART_SIDENAV_OPENED);
      }

      public editLayout() {
         const orderedIds = _(this.dashboard.charts)
            .sortBy(c => c.layout.sequenceNo)
            .map(c => c.chart.id)
            .value();

         angular.forEach(orderedIds, (id, i) => {
            _.find(this.dashboard.charts, { chart: { id } }).layout.sequenceNo = i + 1;
         });
         this.isEditing = true;
      }

      public onChartAdded(chart: IDashboardChart) {
         this.dashboard.charts.splice(0, 0, chart);
      }

      public onChartRemoved(chart: IDashboardChart) {
         const index = this.dashboard.charts.indexOf(chart);
         this.dashboard.charts.splice(index, 1);
      }

      public saveLayout() {
         const promises = angular.forEach(this.dashboard.charts, this.updateChartLayout);
         this.common.$q.all(promises)
            .then(() => this.isEditing = false);
      }

      private updateChartLayout = (chart: IDashboardChart) => {
         return this.datacontext.analysis
            .updateChartLayout(this.dashboard.id, chart.chart.id, chart.layout);
      };
   }
}