namespace app.admin.configuration {
   @Component('app.admin.configuration', 'ceAdminLegalTeamDetails', {
      templateUrl: 'app/admin/modules/configuration/views/legal-team-details/legal-team-details.html',
      bindings: {
         legalTeam: '<'
      }
   })
   class LegalTeamDetails {
      static $inject = ['common', 'datacontext'];
      constructor(
         private common: core.ICommonService,
         private datacontext: data.IDataContextService) {
         'ngInject';
      }

      public isSubmitting = false;
      public data = {} as IUpdateLegalTeamData;
      public legalTeam: ILegalTeam;

      private $onInit() {
         const { isActive = true, name } = this.legalTeam ?? {};
         this.data.name = name;
         this.data.isActive = isActive;
      }

      public submit() {
         this.isSubmitting = true;

         const promise = this.legalTeam
            ? this.datacontext.admin.configuration.updateLegalTeam(this.legalTeam.id, this.data)
            : this.datacontext.admin.configuration.createLegalTeam(this.data);

         promise
            .then(result => {
               this.common.$mdToast.showSimple('Legal Team ' + (this.legalTeam ? 'updated' : 'created'));
               this.common.$state.go('^.legalTeamList');
            }).finally(() => this.isSubmitting = false);
      }
   }
}