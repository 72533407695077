namespace app.systemicCases.closure {
   @Component('app.systemicCases.closure', 'ceSystemicCaseClosure', {
      templateUrl: 'app/systemic-cases/closure/closure.html',
      bindings: {
         case: '<'
      }
   })
   class SystemicCaseClosure {
      static $inject = ['$mdDialog', 'common', 'datacontext'];
      constructor(
         private $mdDialog: angular.material.IDialogService,
         private common: core.ICommonService,
         private datacontext: data.IDataContextService) {
         'ngInject';
      }

      private case: ISystemicCaseBasicInfoView;
      private closureInformation: ISystemicCaseClosureInformation;
      private editClosureInformation: boolean = false;

      public $onInit() {
         this.GetClosureInformation();
      }

      public updateClosureInformation(result: ISystemicCaseClosureInformation) {
         this.closureInformation = result;
         this.editClosureInformation = false;
      }

      public GetClosureInformation() {
         this.datacontext.systemicCases.getClosureInformation(this.case.id).then(
            result => this.closureInformation = result
         );
      }
   }
}