namespace app.time {
    @Component('app.time', 'ceTimeslipListing', {
        templateUrl: 'app/time/timeslip-listing/timeslip-listing.html',
        bindings: {
            involvementId: '<',
            source: '@',
            sourceId: '<',
            sourceName: '<',
            sourceName2: '<?',
            enableFilters: '<?',
            enableDateFilter: '<?',
            showCalendarIcon: '<',
            fundingProgramId: '<'
        }
    })
    class TimeslipListing {
        static $inject = ['$mdDialog', 'confirmation', 'common', 'datacontext', 'blobHelper'];
        constructor(
            private $mdDialog: angular.material.IDialogService,
            private confirmation: IConfirmationService,
            private common: core.ICommonService,
            private datacontext: data.IDataContextService,
            private blobHelper: utils.IBlobHelperService) {
            'ngInject';
        }

        public timeslips: data.IPagedList<ITimeslipListingView>;
        public params = {} as any;
        public selectedUsers = {} as any;
        public showFilters: boolean;
        public enableFilters: boolean;
        public enableDateFilter: boolean;
        public showCalendarIcon: boolean;
        today = new Date();
        public fromDate: Date;
        public toDate: Date;
        public userFilterInitialized: boolean;
        public dateFilterInitialized: boolean;
        public showInvolvementTimeslips: boolean;
        public involvementId: string;
        public source: string;
        public sourceId: string;
        public sourceName: string;
        public sourceName2: string;
        public fundingProgramId: string;
        public promise: angular.IPromise<any>;
        public paging: data.IPagedListParams = {
            page: 1,
            pageSize: 100
        };
        private currentUserId: string;

        private $onInit() {
            if (this.enableFilters === undefined) {
                this.enableFilters = true;
            }

            if (this.enableDateFilter === undefined) {
                this.enableDateFilter = true;
            }

            if (this.enableFilters && this.enableDateFilter) {
                this.fromDate = new Date(this.today.getFullYear(), this.today.getMonth(), 1);
                this.toDate = new Date(this.today.getFullYear(), this.today.getMonth(), this.today.getDate());
            } else {
                this.getTimeslips();
            }
        }

        public usersFilterChanged(users: users.IUser[]) {
            this.userFilterInitialized = true;
            this.selectedUsers = { ...users };
            this.getTimeslips();
        }

        public dateFilterChanged(dates: filters.IDateFilterFromTo) {
            this.dateFilterInitialized = true;
            this.fromDate = dates.fromDate;
            this.toDate = dates.toDate;
            this.getTimeslips();
        }

        public toggleFilters() {
            this.showFilters = !this.showFilters;
        }

        private filtersInitialized() {
            return this.userFilterInitialized && this.dateFilterInitialized;
        }

        public createTimeslip() {
            this.openTimeslipDialog(null);
        }

        public editTimeslip(id: string) {
            this.openTimeslipDialog(id);
        }

        private showDeleteDialog(id: string) {
            this.confirmation.show({
                message: 'Are you sure you want to delete this timeslip?',
                ok: 'DELETE',
                cancel: 'KEEP'
            }).then(() => { this.deleteTimeslip(id); });
        }

        private deleteTimeslip(id: string) {
            this.datacontext.time.deleteTimeslip(id).then(() => this.refresh());
        }

        private openTimeslipDialog(id: string) {
            const parent = angular.element(document.body);
            // not adding a .catch to dialogs causes possibly unhandled rejection error
            return this.$mdDialog.show({
                parent,
                fullscreen: true,
                templateUrl: 'app/time/timeslip-dialog/timeslip-dialog.html',
                controller: 'TimeslipDialogController',
                controllerAs: '$ctrl',
                focusOnOpen: false,
                locals: {
                    id: id,
                    involvementId: this.involvementId,
                    hours: null,
                    source: this.source,
                    sourceId: this.sourceId,
                    sourceName: this.sourceName,
                    date: moment().toDate(),
                    fundingProgramId: this.fundingProgramId,
                    sourceName2: this.sourceName2
                }
            })
                .catch((error) => { if (error) throw error; })
                .finally(() => { this.getTimeslips(); });
        }

        private refresh = () => {
            this.getTimeslips();
        }

        private export = () => {
            const params = this.getParams();

            this.promise = this.datacontext.time
                .getTimeslipListingDownload(params)
                .then(result => {
                    if (result) {
                        this.blobHelper.open({
                            buffer: result.buffer,
                            fileName: result.fileName,
                            contentType: result.contentType
                        });
                    }
                });
        };

        private getParams() {
            const userIds = _.map(this.selectedUsers, 'id') as string[];
            if (userIds.length === 0 && this.enableFilters) {
                this.timeslips = { count: 0, list: [], ...this.paging } as data.IPagedList<ITimeslipListingView>;
                return;
            }

            let fromDate = null;
            if (this.fromDate && typeof this.fromDate !== 'string') {
                fromDate = this.fromDate.toDateString();
            }

            let toDate = null;
            if (this.toDate && typeof this.toDate !== 'string') {
                toDate = this.toDate.toDateString();
            }

            const params: IGetTimeslipsParams = {
                fromDate: fromDate,
                toDate: toDate,
                userIds: userIds,
                involvementId: this.involvementId,
                source: this.showInvolvementTimeslips ? null : this.source,
                sourceId: this.showInvolvementTimeslips ? null : this.sourceId,
                ...this.paging
            };

            return params;
        }

        private getTimeslips() {
            if (this.enableFilters) {
                if (!this.filtersInitialized()) {
                    return;
                }
            }

            const params = this.getParams();

            this.promise = this.datacontext.time
                .getTimeslipListing(params)
                .then((result) => {
                    this.timeslips = result;
                });
        }

        public getInvolvementTimeslips() {
            this.showInvolvementTimeslips = !this.showInvolvementTimeslips;
            this.getTimeslips();
        }
    }
}