namespace app.search {
   @Component('app.search', 'ceAdvancedPersonSearch', {
      templateUrl: 'app/search/advanced-search/advanced-person-search.html'
   })
   class AdvancedPersonSearch {
      static $inject = ['datacontext'];
      constructor(private datacontext: data.IDataContextService) {
         'ngInject';
      }

      public query: persons.IGetPersonsParams = { pageSize: 10, order: 'FullName' };
      public persons: data.IPagedList<persons.IPerson>;
      public promise: angular.IPromise<boolean>;

      public handlePaginate = (page: number, limit: number) => {
         this.query.page = page;
         this.search();
      };

      public handleReorder = (sortBy: string) => {
         this.search();
      };

      public search() {
         this.promise = this.datacontext.persons
            .getPersons(this.query)
            .then(result => {
               this.persons = result;
               return !!result;
            });
         return this.promise;
      }
   }
}