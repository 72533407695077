namespace app.entities.activities {
   @Component('app.entities.activities', 'ceEntityActivities', {
      templateUrl: 'app/entities/activities/activity-list/activity-list.html',
      bindings: {
         entityId: '<'
      }
   })
   class EntityActivities {
      static $inject = ['$mdDialog', 'common', 'datacontext'];
      constructor(
         private $mdDialog: angular.material.IDialogService,
         private common: core.ICommonService,
         private datacontext: data.IDataContextService) {
         'ngInject';
      }

      private entityId: string;
      private activities: app.activities.IActivity[];

      public $onInit() {
         return this.datacontext.activities
            .searchActivities({ entityId: this.entityId })
            .then(result => {
               this.activities = result.list;
            });
      }

      public newActivity(targetEvent: MouseEvent) {
         const parent = angular.element(document.body);
         this.$mdDialog.show({
            parent,
            targetEvent,
            clickOutsideToClose: true,
            templateUrl: 'app/entities/activities/new-activity/new-activity.html',
            controller: 'NewEntityActivityDialogController',
            controllerAs: '$ctrl',
            locals: {
               entityId: this.entityId
            }
         });
      }
   }
}