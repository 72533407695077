namespace app.time {
   @Controller('app.time', 'TimeslipSummaryDialogController')
   class TimeslipSummaryDialogController {
      static $inject = ['$scope', '$mdDialog', 'common', 'datacontext', 'timeslips'];
      constructor(
         private $scope: angular.IScope,
         private $mdDialog: angular.material.IDialogService,
         private common: core.ICommonService,
         private datacontext: data.IDataContextService,
         private timeslips: ITimeslipListingView[]) {
         'ngInject';
      }

      private userName: string;
      private date: Date;

      public $onInit() {
         this.userName = `${this.timeslips[0].userFirstName} ${this.timeslips[0].userLastName}`;
         this.date = this.timeslips[0].date;
      }

      public edit(id: string) {
         this.$mdDialog.hide(id);
      }

      public cancel() {
         this.$mdDialog.cancel();
      }
   }
}