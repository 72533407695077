namespace app.utils {
   angular
      .module('app.utils')
      .filter('nth', () => (num: number) => {
         let postscript;
         if (num === 1) {
            postscript = 'st';
         }
         else if (num === 2) {
            postscript = 'nd';
              }
         else if (num === 3) {
            postscript = 'rd';
              }
         else {
            postscript = 'tn';
              }

         return `${num}${postscript}`;
      });
}