namespace app.admin.configuration {
   @Component('app.admin.configuration', 'ceAdminTaskCategoryDetails', {
      templateUrl: 'app/admin/modules/configuration/views/task-category-details/task-category-details.html',
      bindings: {
         taskCategory: '<'
      }
   })
   class TaskCategoryDetails {
      static $inject = ['common', 'datacontext'];
      constructor(
         private common: core.ICommonService,
         private datacontext: data.IDataContextService) {
         'ngInject';
      }

      public isSubmitting = false;
      public taskCategory: ITaskCategory;

      public addTask() {
         this.taskCategory.tasks.unshift({ isActive: true, isEditMode: true } as ITask);
      }

      public cancelTask(index: number) {
         let task = this.taskCategory.tasks[index];
         task.isEditMode = false;

         if (!task.id) {
            this.taskCategory.tasks.splice(index, 1);
         }
      }

      public saveTask(task: ITask) {
         this.isSubmitting = true;

         const promise = task.id
            ? this.datacontext.admin.configuration.updateTask(this.taskCategory.id, task.id, task)
            : this.datacontext.admin.configuration.createTask(this.taskCategory.id, task);

         promise
            .then((result) => {
               task.isEditMode = false;
               this.common.$mdToast.showSimple('Task ' + (task.id ? 'updated' : 'created'));

               if (result) {
                  task.id = result.id;
               }
            })
            .finally(() => this.isSubmitting = false);
      }

      public submit() {
         this.isSubmitting = true;
         this.datacontext.admin.configuration
            .updateTaskCategory(this.taskCategory.id, this.taskCategory)
            .then(result => {
               if (result) {
                  this.common.$mdToast.showSimple('Task Category updated');
               }
            }).finally(() => this.isSubmitting = false);
      }

      private $onChanges() {
         this.common.setTitle(`${this.taskCategory.name} (Task Category)`);
      }
   }
}