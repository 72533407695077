namespace app.clientCases.overview {
   @Component('app.clientCases.overview', 'ceClientCaseOverview', {
      templateUrl: 'app/client-cases/overview/overview.html',
      bindings: {
         case: '<'
      }
   })
   class ClientCaseOverview {
      static $inject = ['$mdDialog', 'common', 'datacontext'];
      constructor(
         private $mdDialog: angular.material.IDialogService,
         private common: core.ICommonService,
         private datacontext: data.IDataContextService) {
         'ngInject';
      }

      public $onInit() {
      }

      private case: IClientCaseBasicInfoView;
   }
}