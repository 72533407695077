namespace app.projects.details {
   @Component('app.projects.details', 'ceProjectInformationForm', {
      templateUrl: 'app/projects/details/project-information-form.html',
      bindings: {
         projectId: '<',
         onCancel: '&',
         onSubmit: '&',
         fundingPrograms: '<'
      }
   })
   class ProjectInformationForm {
      static $inject = ['common', 'datacontext'];
      constructor(
         private common: core.ICommonService,
         private datacontext: data.IDataContextService) {
         'ngInject';
      }

      public projectId: string;
      public data = {} as IProject;
      public isLoading: boolean;
      public isSubmitting: boolean;
      public onSubmit: (args: { result: IProject }) => void;
      public fundingPrograms: any[];

      private $onInit() {
         this.data.openDate = moment().toDate();
         this.getData();
      }

      public getData() {
         this.isLoading = true;
         this.datacontext.projects
            .getProject(this.projectId)
            .then(result => this.data = result)
            .finally(() => this.isLoading = false);
      }

      public submit() {
         this.isSubmitting = true;

         if (typeof this.data.openDate !== 'string') {
            this.data.openDate = this.data.openDate.toDateString();
         }

         if (this.data.closeDate && typeof this.data.closeDate !== 'string') {
            this.data.closeDate = this.data.closeDate.toDateString();
         }

         this.datacontext.projects
            .updateProject(this.data)
            .then(result => {
               if (result) this.onSubmit({ result });
            }).finally(() => this.isSubmitting = false);
      }
   }
}