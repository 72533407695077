namespace app.analysis {
   interface IFilterViewModel extends IFilter {
      field?: IFieldMetadata;
      operator?: IOperator;
   }

   interface IOperator {
      value: string;
      label: string;
      dataTypes: DataType[];
      lookupSupport?: boolean;
      multiple?: boolean;
   }

   const operators: IOperator[] = [
      { value: 'Equals', label: 'Equals', dataTypes: ['Boolean', 'Date', 'Number', 'String'], lookupSupport: true },
      { value: 'DoesNotEqual', label: 'Does not equal', dataTypes: ['Boolean', 'Date', 'Number', 'String'], lookupSupport: true },
      { value: 'Contains', label: 'Contains', dataTypes: ['String'] },
      { value: 'DoesNotContain', label: 'Does not contain', dataTypes: ['String'] },
      { value: 'Includes', label: 'Includes', dataTypes: ['String'], lookupSupport: true, multiple: true },
      { value: 'DoesNotInclude', label: 'Does not include', dataTypes: ['String'], lookupSupport: true, multiple: true },
      { value: 'StartsWith', label: 'Starts with', dataTypes: ['String'] },
      { value: 'DoesNotStartWith', label: 'Does not start with', dataTypes: ['String'] },
      { value: 'EndsWith', label: 'Ends with', dataTypes: ['String'] },
      { value: 'DoesNotEndWith', label: 'Does not end with', dataTypes: ['String'] },
      { value: 'GreaterThan', label: 'Greater than', dataTypes: ['Date', 'Number'] },
      { value: 'GreaterThanOrEqualTo', label: 'Greater than or equal to', dataTypes: ['Date', 'Number'] },
      { value: 'LessThan', label: 'Less than', dataTypes: ['Date', 'Number'] },
      { value: 'LessThanOrEqualTo', label: 'Less than or equal to', dataTypes: ['Date', 'Number'] }
   ];

   @Component('app.analysis', 'ceAnalysisManageQuery', {
      templateUrl: 'app/analysis/views/manage-query/manage-query.html',
      bindings: {
         dataSources: '<',
         queryId: '<'
      }
   })
   class ManageQuery {
      static $inject = ['$mdDialog', 'common', 'datacontext'];
      constructor(
         private $mdDialog: angular.material.IDialogService,
         private common: core.ICommonService,
         private datacontext: data.IDataContextService) {
         'ngInject';
      }

      public data = {} as IQuery;
      public queryId: string;

      public $onInit() {
         if (this.queryId) {
            this.loadData();
         } else {
            this.data = {
               metadata: {
                  columns: [],
                  filters: [{ type: 'And', criteria: [] }]
               }
            } as IQuery;
         }
      }

      public dataSource: IDataSource;
      public dataSources: IDataSource[];
      public lookups: IDictionary<string[]> = {};
      public tabularData: ITabularDataResult;
      public tabularDataParams: IPreviewTabularDataData;
      public tabularFields: IFieldMetadata[];

      private loadData() {
         this.datacontext.analysis.getQuery(this.queryId)
            .then((result) => {
               this.data = result;
               this.dataSource = _.find(this.dataSources, d => d.id === result.dataSourceId);
               _.forEach(this.data.metadata.filters, (f: IFilterViewModel) => {
                  f.field = _.find(this.dataSource.metadata.fields, field => field.name === f.criteria[0].field);
                  f.operator = _.find(operators, o => o.value === f.criteria[0].operator);
               });
            });
      }

      public addFilter(index: number) {
         let type: FilterType;
         if (index === 0) type = 'And';

         this.data.metadata.filters.splice(index, 0, { type, criteria: [] });
      }

      public deleteFilter(index: number) {
         this.data.metadata.filters.splice(index, 1);
      }

      public getLookups(type: string) {
         const deferred = this.common.$q.defer();

         if (this.lookups[type]) {
            deferred.resolve(this.lookups[type]);
         } else {
            this.datacontext.config.lookups
               .getLookupType(type)
               .then(result => {
                  this.lookups[type] = _.map(result.lookups, l => l.value);
                  deferred.resolve(this.lookups[type]);
               });
         }

         return deferred.promise;
      }

      public getOperators(field: IFieldMetadata) {
         if (!field) return;

         return _.filter(operators, o => o.dataTypes.indexOf(field.dataType) > -1);
      }

      public getTabularData() {
         return this.datacontext.analysis
            .previewTabularData(this.tabularDataParams)
            .then(result => this.tabularData = result);
      }

      public onDataSourceSelected() {
         this.data.dataSourceId = this.dataSource.id;
         this.tabularFields = _.filter(this.dataSource.metadata.fields, f => f.visible);
         this.data.metadata.columns = _.map(this.tabularFields, f => f.name);
      }

      public onOperatorChanged(filter: IFilterViewModel) {
         filter.criteria[0].operator = filter.operator.value;
         if (filter.operator.multiple) {
            filter.criteria[0].value = null;
            if (!filter.criteria[0].values) {
               filter.criteria[0].values = [];
            }
         } else {
            filter.criteria[0].values = null;
         }
      }

      public runQuery(form: ng.IFormController) {
         form.$setPristine();
         form.$setSubmitted();
         if (form.$invalid) {
            return;
         }

         this.tabularDataParams = {
            dataSourceId: this.dataSource.id,
            query: angular.copy(this.data.metadata),
            page: 1,
            pageSize: 10
         };

         this.getTabularData();
      }

      public saveQueryAs(targetEvent: MouseEvent) {
         this.data.id = null;
         this.data.name += ' Copy';
         this.saveQuery(targetEvent);
      }

      public saveQuery(targetEvent: MouseEvent) {
         const query = angular.copy(this.data);

         let filter: IFilterViewModel;
         for (filter of query.metadata.filters) {
            delete filter.field;
            delete filter.operator;
         }

         const parent = angular.element(document.body);
         this.$mdDialog.show({
            parent,
            targetEvent,
            clickOutsideToClose: false,
            fullscreen: true,
            templateUrl: 'app/analysis/views/manage-query/save-query.html',
            controller: 'SaveAnalysisQueryController',
            controllerAs: '$ctrl',
            locals: { query }
         }).then(result => {
            this.common.$mdToast.showSimple('Query saved');
            this.common.$state.go('^.query', { queryId: result.id });
         }).catch((error) => { if (error) throw error; });
      }
   }
}