namespace app.agencies.details {
   @Component('app.agencies.details', 'ceAgencyOtherInfoView', {
      templateUrl: 'app/agencies/details/other-info/other-info-view.html',
      bindings: {
         data: '<',
         onEdit: '&'
      }
   })
   class AgencyOtherInfoView {
   }
}