namespace app.calendars {
   @Component('app.calendars', 'ceCalendarFilters', {
      templateUrl: 'app/calendars/views/calendar/calendar-filters.html'
   })
   class CalendarFilters {
      static $inject = ['$mdSidenav', 'calendarData', 'calendarState'];
      constructor(
         private $mdSidenav: angular.material.ISidenavService,
         private calendarData: ICalendarDataService,
         private calendarState: ICalendarStateService) {
         'ngInject';
      }

      public state: ICalendarState;
      public users = {} as users.IUser[];

      public apply() {
         this.calendarState.save();
         this.calendarData.refresh();
         this.$mdSidenav('calendarFilters').close();
      }

      public usersFilterChanged(users: users.IUser[]) {
         this.state.users = users;
         this.state.userIds = _.map(users, 'id');
      }

      public isCalendarSelected(calendar: ICalendar) {
         return this.state.calendarIds.indexOf(calendar.id) >= 0;
      }

      public getStyle(calendar: ICalendar) {
         return;
      }

      public toggleCalendarSelected(calendar: ICalendar) {
         const index = this.state.calendarIds.indexOf(calendar.id);
         if (index >= 0) {
            this.state.calendarIds.splice(index, 1);
         } else {
            this.state.calendarIds.push(calendar.id);
         }
      }

      private $onInit() {
         this.state = this.calendarState.state;
         this.users = this.state.users;
      }
   }
}