namespace app.fiscal {
   @Component('app.fiscal', 'amEditCheckButton', {
      template: `
      <md-button class="" style="font-weight:normal;text-transform:none;" ng-click="$ctrl.openCheckDialog()" permission="['checks.edit']">
        <md-icon aria-hidden="true">edit</md-icon>
        <span class="pl-lg">Edit Check</span>
      </md-button>`,
      bindings: {
         check: '<',
         refresh: '&'
      }
   })
   class EditCheckButtonComponent {
      static $inject = ['$mdDialog', '$scope'];
      constructor(
         private $mdDialog: angular.material.IDialogService,
         private $scope: angular.IScope) {
         'ngInject';
      }

      private refresh: () => {};
      private check: ICheckView;
      private openCheckDialog() {
         const parent = angular.element(document.body);
         // not adding a .catch to dialogs causes possibly unhandled rejection error
         return this.$mdDialog.show({
            parent,
            fullscreen: true,
            templateUrl: 'app/fiscal/check/check-dialog.html',
            controller: 'CheckDialogController',
            controllerAs: '$ctrl',
            focusOnOpen: false,
            locals: {
               check: this.check
            }
         })
            .catch((error) => { if (error) throw error; })
            .finally(() => { this.refresh(); });
      }
   }
}
