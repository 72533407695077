namespace app.users {
   'use strict';

   export interface IUserRepository {
      getCurrentUser(): angular.IPromise<IUser>;
      getCurrentUserBasic(): angular.IPromise<IUser>;
      getSupervisedUsers(q: string): angular.IPromise<IUser[]>;
      getTimeslipUsers(q: string): angular.IPromise<IUser[]>;
      getUsers(q: string): angular.IPromise<IUser[]>;
   }

   const baseUrl = 'api/identity-management/users';

   class UserRepository implements IUserRepository {
      static $inject = ['$http', 'exception'];
      constructor(
         private $http: angular.IHttpService,
         private exception: core.exception.IExceptionService,
      ) {
      }

      @ApiExecutor()
      public getCurrentUser() {
         return this.$http.get<IUser>(`${baseUrl}/current`) as any;
      }

      @ApiExecutor()
      public getCurrentUserBasic() {
         return this.$http.get<IUser>(`${baseUrl}/current/basic`) as any;
      }

      @ApiExecutor()
      public getSupervisedUsers(q: string) {
         return this.$http.get<IUser[]>(`${baseUrl}/current/supervisedUsers`, { params: { q } }) as any;
      }

      @ApiExecutor()
      public getTimeslipUsers(q: string) {
         return this.$http.get<IUser[]>(`${baseUrl}/current/timeslipUsers`, { params: { q } }) as any;
      }

      @ApiExecutor()
      public getUsers(q: string) {
         return this.$http.get<IUser[]>(baseUrl, { params: { q } }) as any;
      }
   }

   angular
      .module('app.users')
      .service('users.repository', UserRepository);
}