namespace app.config {
   export interface ILookupRepository {
      getLookupType(id: string): angular.IPromise<ILookupType>;
   }

   const baseUrl = 'api/configuration/lookup-types';

   class LookupRepository implements ILookupRepository {
      constructor(private $http: angular.IHttpService) {
         'ngInject';
      }

      @ApiExecutor()
      public getLookupType(id: string) {
         return this.$http.get<ILookupType>(`${baseUrl}/${id}`) as any;
      }
   }

   angular
      .module('app.config')
      .service('config.lookups.repository', LookupRepository);
}