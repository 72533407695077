namespace app.projects {
   'use strict';

   export interface IProjectRepository {
      getProjects(): angular.IPromise<IProjectListingView[]>;
      getProject(id: string): angular.IPromise<IProjectView>;
      getProjectListing(params: IGetProjectsParams): data.IPagedListPromise<IProjectListingView>;
      getProjectListingDownload(params: IGetProjectsParams): angular.IPromise<app.analysis.IDownloadDataResult>;
      createProject(project: ICreateProject): angular.IPromise<ICreateProject>;
      updateProject(project: IProject): angular.IPromise<IProject>;
      getEntity(projectId: string, entityId: string): angular.IPromise<IEntityView>;
      getEntityListing(projectId: string): angular.IPromise<IEntityListing[]>;
      createEntity(entity: IProjectEntity): angular.IPromise<IEntityView>;
      updateEntity(entity: IProjectEntity): angular.IPromise<IEntityView>;
      deleteEntity(id: string): angular.IPromise<boolean>;
      deleteProject(id: string): angular.IPromise<string>;
      getEvents(params: IGetEventsParams): angular.IPromise<IEvent[]>;
      createNote(activity: ICreateProjectActivity): angular.IHttpPromise<ICreateProjectActivity>;
      updateNote(activity: ICreateProjectActivity): angular.IHttpPromise<ICreateProjectActivity>;
   }

   const baseUrl = 'api/projects';

   class ProjectRepository implements IProjectRepository {
      static $inject = ['$http', 'exception'];
      constructor(
         private $http: angular.IHttpService,
         private exception: core.exception.IExceptionService,
      ) {
      }

      @ApiExecutor()
      public getEvents(params: IGetEventsParams) {
         return this.$http.get<IEvent[]>(`api/calendars/projectevents`, { params }) as any;
      }

      @ApiExecutor()
      public getProjects() {
         return this.$http.get<IProjectView[]>(baseUrl) as any;
      }

      @ApiExecutor()
      public getProject(id: string) {
         return this.$http.get<IProjectView>(`${baseUrl}/${id}`) as any;
      }

      @ApiExecutor()
      public getProjectListing(params: IGetProjectsParams) {
         return this.$http.get<data.IPagedList<IProjectListingView>>(baseUrl, { params }) as any;
      }

      public getProjectListingDownload(params: IGetProjectsParams) {
         return this.$http.get<any>(`${baseUrl}/csv`, { params, responseType: 'arraybuffer' }).then(
            (response) => {
               return {
                  buffer: response.data,
                  contentType: response.headers()['content-type'],
                  fileName: response.headers()['content-disposition'].split(';')[1].split('=')[1].replace(/"/g, '')
               };
            }).catch(error => this.exception.catcher('XHR for getCsvData failed')(error)) as any;
      }

      @ApiExecutor()
      public createProject(project: ICreateProject) {
         return this.$http.post<ICreateProject>(baseUrl, project) as any;
      }

      @ApiExecutor()
      public updateProject(project: IProject) {
         return this.$http.put<IProject>(`${baseUrl}/${project.id}`, project) as any;
      }

      @ApiExecutor()
      public getEntity(projectId: string, entityId: string) {
         return this.$http.get<IEntityView>(`${baseUrl}/${projectId}/entities/${entityId}`) as any;
      }

      @ApiExecutor()
      public getEntityListing(projectId: string) {
         return this.$http.get<IEntityView[]>(`${baseUrl}/${projectId}/entities`) as any;
      }

      @ApiExecutor()
      public createEntity(entity: IProjectEntity) {
         return this.$http.post<IProjectEntity>(`${baseUrl}/entities`, entity) as any;
      }

      @ApiExecutor()
      public updateEntity(entity: IProjectEntity) {
         return this.$http.put<IProjectEntity>(`${baseUrl}/entities`, entity) as any;
      }

      @ApiExecutor()
      public deleteEntity(id: string) {
         return this.$http.delete<boolean>(`${baseUrl}/entities/${id}`) as any;
      }

      public deleteProject(id: string) {
         return this.$http.delete(`${baseUrl}/${id}`).catch(error => this.exception.catcher('Cannot Delete Project')(error)) as any;
      }

      @ApiExecutor()
      public createNote(activity: ICreateProjectActivity) {
         return this.$http.post<ICreateProjectActivity>(`${baseUrl}/${activity.projectId}/notes`, activity) as any;
      }

      @ApiExecutor()
      public updateNote(activity: ICreateProjectActivity) {
         return this.$http.put<ICreateProjectActivity>(`${baseUrl}/${activity.projectId}/notes/${activity.id}`, activity) as any;
      }
   }

   angular
      .module('app.projects')
      .service('projects.repository', ProjectRepository);
}