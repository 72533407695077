namespace app.utils {
   @Component('app.utils', 'aimsProgressBar', {
      template: `
        <div ng-style="::{width: $ctrl.value + '%'}">
          <div ng-transclude></div>
        </div>`,
      bindings: {
         value: '<'
      },
      transclude: true
   })
   class ProgressBar {
   }
}