namespace app.calendars {
   @Component('app.calendars', 'ceCalendarEventSchedule', {
      templateUrl: 'app/calendars/event-schedule/event-schedule.html',
      bindings: {
         eventScheduleId: '<',
         targetDate: '<',
         involvementId: '<',
         source: '@',
         sourceId: '<',
         sourceName: '<'
      }
   })
   class CalendarEventSchedule {
      static $inject = ['common', 'datacontext', '$mdDialog'];
      constructor(
         private common: core.ICommonService,
         private datacontext: data.IDataContextService,
         private $mdDialog: angular.material.IDialogService) {
         'ngInject';
      }

      public eventSchedule = {} as IEventSchedule;
      public scheduleData = {} as ISchedule;
      public isSubmitting: boolean;
      public action: string;
      public targetDate: moment.Moment;
      public eventScheduleId: string;
      public startDate: Date;
      public endDate: Date;
      public startTime: Date;
      public endTime: Date;
      public userSearchText: string;
      public users: IUserBasic[];
      public sources: common.ISource[];
      public involvementId: string;
      public source: string;
      public sourceId: string;
      public sourceName: string;
      public defaultCalendar: string;
      public calendarSourceTypes: string[];

      private $onInit() {
         this.eventSchedule.scheduleData = this.scheduleData;
         this.users = this.users || [];
         this.sources = this.sources || [];

         this.processTargetDate();

         this.eventSchedule.scheduleData.startDate = this.startDate;
         this.eventSchedule.scheduleData.endDate = this.endDate;
         this.eventSchedule.scheduleData.startTime = this.startTime;
         this.eventSchedule.scheduleData.endTime = this.endTime;

         if (this.eventScheduleId) {
            this.action = 'Update';
            this.loadEventSchedule();
         } else {
            this.action = 'Create';
            this.datacontext.users.getCurrentUserBasic()
               .then(result => this.users.push({ userId: result.id, fullName: result.fullName }));
            if (this.sourceId) {
               this.sources.push({ involvementId: this.involvementId, source: this.source, sourceDescription: this.sourceName, sourceId: this.sourceId });
            }
         }

         this.datacontext.config.lookups.getLookupType('calendar-source-types')
            .then(result => {
               this.calendarSourceTypes = result.lookups.map(l => { return l.value; });
            });
      }

      private transformChip(chip) {
         if (angular.isObject(chip)) {
            const index = _.findIndex(this.users, (user: users.IUser) => { return user.id === chip.id; });
            if (index > -1) {
               return null;
            }
            else {
               return chip;
            }
         }
      }

      private loadEventSchedule() {
         this.datacontext.calendars
            .getEventSchedule(this.eventScheduleId)
            .then(result => {
               angular.extend(this.eventSchedule, result);
            });
         this.datacontext.calendars
            .getEventScheduleStaff(this.eventScheduleId)
            .then(result => {
               angular.extend(this.users, result);
            });
         this.datacontext.calendars
            .getEventScheduleSources(this.eventScheduleId)
            .then(result => {
               _.forEach(result, r => {
                  this.sources.push({
                     involvementId: r.involvementId,
                     source: r.source,
                     sourceDescription: (r.idNumber ? r.idNumber + ' ' : '') + r.sourceName,
                     sourceId: r.sourceId
                  });
               });
            });
      }

      private onFrequencyChange() {
         this.resetSchedule();
         if (this.eventSchedule.scheduleData.frequency !== 'Single') {
            this.openFrequencyDialog();
         }
      }

      private resetSchedule() {
         this.eventSchedule.scheduleData.interval = null;
         this.eventSchedule.scheduleData.skipInterval = null;
         this.eventSchedule.scheduleData.repeatBy = null;
         this.eventSchedule.scheduleData.dayOfMonth = null;
         this.eventSchedule.scheduleData.weekNumber = null;
         this.eventSchedule.scheduleData.dayOfWeek = null;
         this.eventSchedule.scheduleData.weekdays = null;
         this.eventSchedule.scheduleData.endDate = null;
         this.eventSchedule.scheduleData.summary = null;
      }

      private openFrequencyDialog() {
         const parent = angular.element(document.body);

         return this.$mdDialog.show({
            parent,
            fullscreen: false,
            templateUrl: 'app/calendars/event-schedule/frequency-dialog.html',
            controller: 'FrequencyDialogController',
            controllerAs: '$ctrl',
            locals: {
               schedule: this.eventSchedule.scheduleData
            }
         })
            .then((schedule: ISchedule) =>
               this.frequencyChanged(schedule))
            .catch((error) => { if (error) throw error; });
      }

      private frequencyChanged(schedule: ISchedule) {
         this.eventSchedule.scheduleData = schedule;
      }

      public userSearch(searchText: string) {
         return this.datacontext.users
            .getUsers(searchText);
      }

      public addSource(sourceView: common.ISource) {
         if (!sourceView) return;

         const exists = _.find(this.sources, c => c.sourceId === sourceView.sourceId);
         if (!exists) {
            this.sources.push(sourceView);
         }
      }

      public removeSource(sourceView: common.ISource) {
         _.remove(this.sources, s => s.sourceId === sourceView.sourceId);
      }

      private processTargetDate() {
         // ui-calendar pass UTC dates, ie if you click on 4/13/18 you get 4/12/18 17:00 GMT-7
         // need to convert this to Date 4/13/18 XX:XX GMT-7
         let t: moment.Moment;
         if (this.targetDate) {
            t = angular.copy(this.targetDate);
            // if no hours passed in, set hours to current time plus 1 hour
            // preserve the 30 minute value passed in if user clicks bottom half of hour cell
            if (t.hours() === 0) {
               t.add(moment().hours() + 1, 'hours');
            }
         }
         else {
            // if no date was passed in, use current date/time and top of next hour
            t = moment().hours(moment().hours() + 1).minutes(0);
         }

         this.startDate = new Date(t.year(), t.month(), t.date());
         this.endDate = new Date(t.year(), t.month(), t.date());
         this.startTime = new Date(t.year(), t.month(), t.date(), t.hours(), t.minutes());
         this.endTime = new Date(t.year(), t.month(), t.date(), t.hours() + 1, t.minutes());
      }

      public cancel() {
         this.common.$state.go('^.calendar');
      }

      public delete() {
         this.common.confirmation.show({
            title: 'Delete Event Series',
            message: 'Are you sure you want to delete All Events in this Series?',
            ok: 'Delete',
            cancel: 'Keep'
         }).then(this.deleteEventSchedule);
      }

      private deleteEventSchedule = () => {
         this.isSubmitting = true;
         this.datacontext.calendars.deleteEventSchedule(this.eventSchedule.id)
            .then(() => {
               this.common.$mdToast.showSimple(`Event Series Deleted`);
               this.$mdDialog.hide();
               this.common.$state.go('^.calendar');
            })
            .finally(() => this.isSubmitting = false);
      }

      public isValid() {
         if (
            (this.users.length > 0) &&
            (this.eventSchedule.scheduleData.frequency === 'Single' && (!this.eventSchedule.scheduleData.summary || this.eventSchedule.scheduleData.summary.substr(0, 11) === 'Occurs once')) ||
            (this.eventSchedule.scheduleData.frequency !== 'Single' && this.eventSchedule.scheduleData.summary)) {
            return true;
         }
         else {
            return false;
         }
      }

      public submit() {
         this.isSubmitting = true;

         if (this.eventSchedule.scheduleData.allDay) {
            this.eventSchedule.scheduleData.startTime = new Date(this.eventSchedule.scheduleData.startDate.getFullYear(), this.eventSchedule.scheduleData.startDate.getMonth(), this.eventSchedule.scheduleData.startDate.getDate());
            this.eventSchedule.scheduleData.endTime = null;
         } else {
            this.eventSchedule.scheduleData.startTime = new Date(this.eventSchedule.scheduleData.startDate.getFullYear(), this.eventSchedule.scheduleData.startDate.getMonth(), this.eventSchedule.scheduleData.startDate.getDate(),
               this.eventSchedule.scheduleData.startTime.getHours(), this.eventSchedule.scheduleData.startTime.getMinutes());
            this.eventSchedule.scheduleData.endTime = new Date(this.eventSchedule.scheduleData.startDate.getFullYear(), this.eventSchedule.scheduleData.startDate.getMonth(), this.eventSchedule.scheduleData.startDate.getDate(),
               this.eventSchedule.scheduleData.endTime.getHours(), this.eventSchedule.scheduleData.endTime.getMinutes());
         }

         const eventPromise = this.action === 'Create'
            ? this.datacontext.calendars.createEventSchedule(this.eventSchedule)
            : this.datacontext.calendars.updateEventSchedule(this.eventSchedule);

         const staffPromise = eventPromise.then((eventSchedule) =>
            this.datacontext.calendars.updateEventScheduleStaff(eventSchedule.id, _.map(this.users, (u: any) => { return u.userId || u.id; })));

         const casePromise = eventPromise.then((eventSchedule) =>
            this.datacontext.calendars.updateEventScheduleSources(eventSchedule.id, this.sources));

         this.common.$q.all([eventPromise, staffPromise, casePromise])
            .then(() => {
               this.common.$mdToast.showSimple(`Event ${this.action}d`);
               this.$mdDialog.hide();
               this.common.$state.go('^.calendar');
            })
            .finally(() => this.isSubmitting = false);
      }
   }
}