namespace app.persons.details {
   @Component('app.persons.details', 'cePersonDemographicsView', {
      templateUrl: 'app/persons/details/demographics/demographics-view.html',
      bindings: {
         data: '<',
         paramPovertyLevel: '<',
         paramAtPoverty: '<',
         paramRaceSubCategory: '<',
         onEdit: '&'
      }
   })
   class PersonDemographicsView {
      public paramPovertyLevel: boolean;
      public paramAtPoverty: boolean;
      public paramRaceSubCategory: boolean;
   }
}