namespace app.training.training {
   @Component('app.training.training', 'ceTrainingInformationForm', {
      templateUrl: 'app/training/training/details/training-information-form.html',
      bindings: {
         trainingId: '<',
         paramNumberOfBrochures: '<',
         onCancel: '&',
         onSubmit: '&'
      }
   })
   class TrainingInformationForm {
      static $inject = ['datacontext'];
      constructor(private datacontext: data.IDataContextService) {
         'ngInject';
      }
      public onSubmit: (args: { result: ITraining }) => void;
      public data: ITraining;
      public isLoading: boolean = false;
      public isSubmitting: boolean = false;
      public trainingId: string;
      public subjectMatters: app.config.ILookup[];
      public disabilityTypes: app.config.ILookup[];
      public trainingForums: app.config.ILookup[];
      public paramNumberOfBrochures: boolean;

      public $onInit() {
         this.getData();
      }

      public forumToggle(value: string) {
         if (value === '') {
            return;
         }

         if (this.forumExists(value)) {
            _.pull(this.data.forum, value);
         }
         else {
            this.data.forum.push(value);
         }
      }

      public forumExists(value: string) {
         if (this.isLoading) {
            return;
         }
         return _.includes(this.data.forum, value);
      }

      public subjectMatterToggle(value: string) {
         if (value === '') {
            return;
         }

         if (this.subjectMatterExists(value)) {
            _.pull(this.data.subjectMatter, value);
         }
         else {
            this.data.subjectMatter.push(value);
         }
      }

      public subjectMatterExists(value: string) {
         if (this.isLoading) {
            return;
         }
         return _.includes(this.data.subjectMatter, value);
      }

      public disabilityTypeToggle(value: string) {
         if (value === '') {
            return;
         }

         if (this.disabilityTypeExists(value)) {
            _.pull(this.data.disabilityType, value);
         }
         else {
            this.data.disabilityType.push(value);
         }
      }

      public disabilityTypeExists(value: string) {
         if (this.isLoading) {
            return;
         }
         return _.includes(this.data.disabilityType, value);
      }

      public getData() {
         this.isLoading = true;
         this.datacontext.training
            .getTrainingInformation(this.trainingId)
            .then(result => this.data = result)
            .finally(() => this.isLoading = false);
         this.datacontext.config.lookups.getLookupType('training-subject-matter').then(
            result => this.subjectMatters = result.lookups
         );
         this.datacontext.config.lookups.getLookupType('training-disability').then(
            result => this.disabilityTypes = result.lookups
         );
         this.datacontext.config.lookups.getLookupType('training-forum').then(
            result => this.trainingForums = result.lookups
         );
      }

      public submit() {
         this.isSubmitting = true;
         this.datacontext.training
            .updateTrainingInformation(this.data)
            .then(result => {
               if (result) this.onSubmit({ result });
            }).finally(() => this.isSubmitting = false);
      }
   }
}