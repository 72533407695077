namespace app.utils {
   export interface IConfirmationOptions {
      message: string;
      title?: string;
      ok?: string;
      cancel?: string;
   }

   export interface IConfirmationService {
      show(options: IConfirmationOptions): angular.IPromise<any>;
   }

   class ConfirmationService implements IConfirmationService {
      constructor(
         private $mdDialog: angular.material.IDialogService) {
         'ngInject';
      }

      public show(options: IConfirmationOptions) {
         const { message, title, ok, cancel } = options;

         let confirm = this.$mdDialog.confirm()
            .textContent(message)
            .ok(ok || 'Yes')
            .cancel(cancel || 'No');

         if (title) {
            confirm = confirm.title(title);
         }

         return this.$mdDialog.show(confirm);
      }
   }

   angular
      .module('app.utils')
      .service('confirmation', ConfirmationService);
}