namespace app.mail {
   @Component('app.mail', 'ceLinkEmails', {
      templateUrl: 'app/mail/link-messages-from-source/link-messages.html',
      bindings: {
         involvementId: '<',
         source: '@',
         sourceId: '<'
      }
   })
   class LinkMessagesFromSource {
      static $inject = ['common', 'datacontext'];
      constructor(
         private common: core.ICommonService,
         private datacontext: data.IDataContextService) {
         'ngInject';
      }

      private involvementId: string;
      private source: string;
      private sourceId: string;

      public messages: data.IPagedList<IMessage>;
      public data = { messageIds: [], sources: [] } as ILinkMessagesData;
      public isSubmitting = false;
      public showMyMessagesOnly: boolean = true;
      public subjectFilter: string;

      public promise: angular.IPromise<any>;
      public paging: data.IPagedListParams = {
         page: 1,
         pageSize: 100,
         order: '-receivedAt'
      };

      private $onInit() {
         this.data.sources.push({
            involvementId: this.involvementId,
            sourceId: this.sourceId,
            source: this.source
         });

         this.getMessages();
      }

      public refresh = () => {
         this.getMessages();
      };

      private getMessages = () => {
         const params: IGetMessagesParams = {
            myMessagesOnly: this.showMyMessagesOnly,
            q: this.subjectFilter,
            ...this.paging
         };

         this.promise = this.datacontext.mail
            .getMessages(params)
            .then(result => this.messages = result);
      }

      public clearSelected() {
         this.data.messageIds.splice(0);
      }

      public fetch() {
         this.datacontext.mail.fetchNewMessages()
            .then(this.getMessages);
      }

      public link() {
         this.isSubmitting = true;

         this.datacontext.mail
            .linkMessages(this.data)
            .then(result => {
               if (result) {
                  this.common.$mdToast.showSimple('Messages linked');
                  this.common.$state.go('^');
               }
            }).finally(() => this.isSubmitting = false);
      }

      public filterMyMessagesOnly() {
         this.showMyMessagesOnly = !this.showMyMessagesOnly;
         this.getMessages();
      }
   }
}