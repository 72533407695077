namespace app.informationAndReferrals.details {
   @Component('app.informationAndReferrals.details', 'ceInformationAndReferralInformation', {
      templateUrl: 'app/information-and-referrals/details/case-information/case-information.html',
      bindings: {
         data: '<',
         onEdit: '&'
      }
   })
   class InformationAndReferralInformation {
   }
}