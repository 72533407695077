namespace app.funding {
   @Component('app.funding', 'ceCapSystemicIntake', {
      templateUrl: 'app/funding/enrollments/cap/systemic/cap-intake.html',
      bindings: {
         enrollmentId: '<'
      }
   })
   class CAPSystemicIntake {
      public enrollmentId: string;
      public intake: ICAPSystemicIntake;
      public editing: boolean;
      public livingArrangements: ILivingArrangement[];
      public problemAreas: IProblemArea[];

      static $inject = ['common', 'datacontext'];
      constructor(
         private common: core.ICommonService,
         private datacontext: data.IDataContextService) {
         'ngInject';
      }

      public $onInit() {
         this.intake = { id: this.enrollmentId };
         this.getLivingArrangements();
         this.getProblemAreas();
         this.getIntake();
      }

      public getIntake() {
         return this.datacontext.funding.getCAPSystemicIntake(this.enrollmentId)
            .then(intake => {
               if (intake) {
                  this.intake = intake;
               }
            });
      }

      public edit() {
         this.getIntake().then(() => {
            this.editing = true;
         });
      }

      public updateIntake() {
         this.datacontext.funding.updateCAPSystemicIntake(this.intake).then(
            () => {
               this.editing = false;
            }
         );
      }

      public cancel() {
         this.getIntake().then(() => {
            this.editing = false;
         });
      }

      public done() {
         this.common.$state.go('^.');
      }

      public getLivingArrangements() {
         return this.datacontext.funding.getCAPLivingArrangementOptions()
            .then(livingArrangements => { this.livingArrangements = livingArrangements; });
      }

      public getProblemAreas() {
         return this.datacontext.funding.getCAPProblemAreaOptions()
            .then(problemAreas => { this.problemAreas = problemAreas; });
      }

   }
}