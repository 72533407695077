namespace app.entities {
   @Controller('app.entities', 'EntityRoutingController')
   class EntityRoutingController {
      static $inject = ['common'];
      constructor(
         private common: core.ICommonService) {
         'ngInject';
         this.init();
      }

      private init() {
         const entityType = this.common.$stateParams['entityType'];
         const entityId = this.common.$stateParams['entityId'];

         switch (entityType) {
            case 'Person':
               this.common.$state.go('app.person', { personId: entityId });
               break;
            case 'Agency':
               this.common.$state.go('app.agency', { agencyId: entityId });
               break;
         }
      }
   }
}