namespace app.clientCases.details {
   @Component('app.clientCases.details', 'ceClientCaseInformation', {
      templateUrl: 'app/client-cases/details/case-information/case-information.html',
      bindings: {
         data: '<',
         paramCaseName: '<',
         onEdit: '&'
      }
   })
   class ClientCaseInformation {
      public paramCaseName: boolean;
   }
}