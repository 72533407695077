namespace app.entities {
   const routes: core.routing.IStateExtended[] = [
      {
         name: 'app.entities',
         url: '/entities?entityId&entityType',
         controller: 'EntityRoutingController'
      },
      {
         name: 'app.entities.details',
         url: '/details',
         template: '<ce-entity-details-view case="$resolve.entity" layout="column" flex></ce-entity-details-view>',
         redirectTo: 'app.entities.details.names',
         data: { title: 'Details' }
      },
      {
         name: 'app.entities.details.names',
         url: '/names',
         template: '<ce-entity-names entity="$resolve.entity" layout="column" flex></ce-entity-names>',
         data: { title: 'Names' }
      },

   ];

   const runner = (routingHelper: core.routing.IRoutingHelperService) => {
      'ngInject';
      routingHelper.configureRoutes(routes);
   };

   angular
      .module('app.entities')
      .run(runner);
}