namespace app.training.cle {
   'use strict';

   const routes: core.routing.IStateExtended[] = [
      {
         name: 'app.createCLE',
         url: '/cle-courses/create',
         template: '<ce-c-l-e-create layout="column" flex></ce-c-l-e-create>',
         data: {
            title: 'Create CLE',
            permissions: ['cle-courses.edit']
         }
      },
      {
         name: 'app.cle',
         url: '/cle-courses/{cleId}',
         template: '<ce-c-l-e-view data="$resolve.cle" layout="column" flex></ce-c-l-e-view>',
         redirectTo: 'app.cle.overview',
         data: { permissions: ['cle-courses.view'] },
         resolve: { cle: cleBasicInfoResolver }
      },
      {
         name: 'app.cle.overview',
         url: '/overview',
         template: '<ce-c-l-e-overview data="$resolve.cle" layout="column" flex></ce-c-l-e-overview>',
         data: {
            title: 'CLE Overview',
            permissions: ['cle-courses.view']
         }
      },
      {
         name: 'app.cle.details',
         url: '/details',
         template: '<ce-c-l-e-details data="$resolve.cle" layout="column" flex></ce-c-l-e-details>',
         data: {
            title: 'CLE Details',
            permissions: ['cle-courses.view']
         }
      },
      {
         name: 'app.cle.documents',
         url: '/documents',
         template: '<ce-documents-list layout="column" flex source="CLE" source-id="$resolve.cle.id"></ce-documents-list>',
         data: {
            title: 'CLE Documents',
            permissions: ['documents.view']
         }
      },
      {
         name: 'app.cle.uploadDocuments',
         url: '/upload',
         params: {
            folderId: null
         },
         resolve: {
            folderId: ['$stateParams', p => p.folderId],
         },
         template: '<ce-upload-documents-activity layout flex folder-id="$resolve.folderId" source="CLE" source-id="$resolve.cle.id"></ce-upload-documents-activity>',
         data: { permissions: ['documents.edit'] }
      },
      {
         name: 'app.cle.emails',
         url: '/emails',
         template: '<ui-view flex layout="column"></ui-view>',
         redirectTo: 'app.cle.emails.listing',
         data: {
            title: 'CLE Emails',
            permissions: ['mail.view']
         }
      },
      {
         name: 'app.cle.emails.listing',
         url: '',
         template: '<ce-email-listing source="CLE" source-id="$resolve.cle.id" layout="column" flex></ce-email-listing>',
         data: {
            title: 'CLE Emails',
            permissions: ['mail.view']
         }
      },
      {
         name: 'app.cle.emails.message',
         url: '/{messageId}',
         template: '<ce-email-view layout="column" flex message-id="$resolve.messageId"></ce-email-view>',
         data: {
            title: 'Message',
            permissions: ['mail.view']
         },
         resolve: {
            messageId: ['$stateParams', p => p.messageId]
         }
      },
      {
         name: 'app.cle.emails.link',
         url: '/link',
         template: '<ce-link-emails layout="column" flex source="CLE" source-id="$resolve.cle.id"></ce-link-emails>',
         data: {
            title: 'Inbox Messages',
            permissions: ['mail.link']
         }
      },
      {
         name: 'app.cle.timeslipListing',
         url: '/timeslips',
         template: '<ce-timeslip-listing source="CLE" source-id="$resolve.cle.id" source-name="$resolve.cle.title" enable-filters="false" layout="column" flex></ce-timeslip-listing>',
         data: {
            title: 'CLE Timeslips',
            permissions: ['timeslips.view']
         }
      },
      {
         name: 'app.cle.timeslipCalendar',
         url: '/timeslips-calendar',
         template: '<ce-timeslip-calendar-frame source="CLE" source-id="$resolve.cle.id" source-name="$resolve.cle.title" enable-filters="false" layout="column" flex></ce-timeslip-calendar-frame>',
         data: {
            title: 'CLE Timeslips',
            permissions: ['timeslips.view']
         }
      },
      {
         name: 'app.cle.funding',
         url: '/funding',
         template: '<ce-training-funding training-id="$resolve.cle.id" source="CLE" layout="column" flex></ce-training-funding>',
         data: { title: 'Training Funding' }
      },
      {
         name: 'app.cle.calendar',
         url: '/calendar',
         template: '<ce-c-l-e-calendar source="CLE" source-id="$resolve.cle.id" layout="column" flex></ce-c-l-e-calendar>',
         data: {
            title: 'Calendar',
         }
      },
      {
         name: 'app.cle.eventSchedule',
         url: '/eventSchedule',
         template: `<ce-calendar-event-schedule
                     event-schedule-id="$resolve.eventScheduleId"
                     target-date="$resolve.targetDate"
                     source="CLE"
                     source-name="$resolve.cle.title"
                     source-id="$resolve.cle.id"></ce-calendar-event-schedule>`,
         params: {
            eventScheduleId: null,
            targetDate: null
         },
         resolve: {
            eventScheduleId: ['$stateParams', p => p.eventScheduleId],
            targetDate: ['$stateParams', p => p.targetDate],
         },
         data: { title: 'Event Schedule', permissions: ['events.view'], }
      },
      {
         name: 'app.cle.event',
         url: '/event',
         template: '<ce-calendar-event event="$resolve.event"></ce-calendar-event>',
         params: {
            event: null
         },
         resolve: {
            event: ['$stateParams', e => e.event]
         },
         data: {
            title: 'Event',
            permissions: ['events.view']
         }
      },
   ];

   const runner = (routingHelper: core.routing.IRoutingHelperService) => {
      'ngInject';
      routingHelper.configureRoutes(routes);
   };

   angular
      .module('app.training.cle')
      .run(runner);
}