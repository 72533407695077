namespace app.filters {
   @Component('app.filters', 'ceActiveFilter', {
      templateUrl: 'app/filters/active/active-filter.html',
      bindings: {
         onChange: '&',
         enabled: '@',
      }
   })
   class DateFilter {
      constructor() {
         'ngInject';
      }

      private open: boolean;
      private closed: boolean;
      private onChange: ({ }) => {};
      private enabled: boolean;

      private $onInit() {
         this.open = true;
         this.closed = false;
         this.changed();
      }

      private toggleState() {
         this.enabled = !this.enabled;
         if (this.enabled) {
            angular.element('#showOpenCheckbox').focus();
         }
         this.changed();
      }

      private toggleOpen() {
         this.open = !this.open;
         this.changed();
      }

      private toggleClosed() {
         this.closed = !this.closed;
         this.changed();
      }

      public changed() {
         if (this.enabled) {
            switch (true) {
               case (this.open && this.closed): {
                  this.onChange({ isActive: 2 });
                  break;
               }
               case (this.open): {
                  this.onChange({ isActive: 1 });
                  break;
               }
               case (this.closed): {
                  this.onChange({ isActive: 0 });
                  break;
               }
               default: {
                  this.onChange({ isActive: null });
                  break;
               }
            }
         }
         else {
            this.onChange({ isActive: null });
         }
      }
   }
}