namespace app.analysis {
   @Component('app.analysis', 'aimsAnalysisDashboardChart', {
      templateUrl: 'app/analysis/views/dashboard/dashboard-chart.html',
      bindings: {
         dashboardId: '<',
         chart: '<',
         isEditing: '<',
         onRemove: '&'
      }
   })
   class DashboardChartComponent {
      static $inject = ['common', 'datacontext'];
      constructor(
         private common: core.ICommonService,
         private datacontext: data.IDataContextService) {
         'ngInject';
      }

      public chart: IDashboardChart;
      public dashboardId: string;
      public data: IChartData[];
      public onRemove: () => void;

      public remove() {
         this.common.confirmation.show({
            title: 'Remove Chart?',
            message: 'Are you sure you want to remove this chart?',
            ok: 'Remove',
            cancel: 'Keep'
         }).then(this.removeCore);
      }

      public scale(colSpan: number, rowSpan: number) {
         this.chart.layout.colSpan = colSpan;
         this.chart.layout.rowSpan = rowSpan;
      }

      private $onChanges() {
         this.getData();
      }

      private getData = () => {
         return this.datacontext.analysis
            .getChartData(this.chart.chart.id)
            .then(result => this.data = result);
      };

      private removeCore = () => {
         this.datacontext.analysis
            .removeChart(this.dashboardId, this.chart.chart.id)
            .then(result => {
               if (result) {
                  this.onRemove();
               }
            });
      };
   }
}