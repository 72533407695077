namespace app.mail {
   interface IAttachmentViewModel {
      message: IMessage;
      attachment: IAttachment;
   }

   @Component('app.mail', 'ceImportAttachmentsView', {
      templateUrl: 'app/mail/import-attachments/import-attachments-view.html',
      bindings: {
         messageIds: '<'
      }
   })
   class ImportAttachmentsView {
      static $inject = ['common', 'datacontext'];
      constructor(
         private common: core.ICommonService,
         private datacontext: data.IDataContextService) {
         'ngInject';
      }

      private messageIds: string;
      public attachments: IAttachmentViewModel[] = [];
      public data = { attachmentIds: [] } as IImportAttachmentsData;
      public cases: entities.ICaseListing[];
      public selectedCase: entities.ICaseListing;
      public isSubmitting = false;

      public getPersons(q: string) {
         return this.datacontext.persons
            .getPersons({ q })
            .then(result => result.list);
      }

      public onPersonChange(person: persons.IPerson) {
         if (!person) {
            this.selectedCase = null;
         } else {
            this.getCases(person.id);
         }
      }

      public submit() {
         this.isSubmitting = true;

         this.data.involvementId = this.selectedCase.involvementId;
         this.data.sourceId = this.selectedCase.id;
         this.data.source = this.selectedCase.caseType;

         this.datacontext.mail
            .importAttachments(this.data)
            .then(result => {
               if (result) {
                  this.common.$mdToast.showSimple('Attachments imported');
                  this.common.$state.go('^');
               }
            }).finally(() => this.isSubmitting = false);
      }

      private $onChanges = () => {
         this.getMessages();
      };

      private getCases = (personId: string) => {
         this.datacontext.entities
            .getCaseListing(personId, { pageSize: 50 })
            .then(result => this.cases = result.list);
      };

      private getMessages = () => {
         this.datacontext.mail
            .getMessages({ messageIds: this.messageIds })
            .then(result => {
               angular.forEach(result.list, message => {
                  angular.forEach(message.attachments, attachment => this.attachments.push({ message, attachment }));
               });

               this.data.attachmentIds = _.map(this.attachments, x => x.attachment.id);
            });
      };
   }
}