namespace app.monitoring.activities {
   @Component('app.monitoring.activities', 'ceMonitoringActivitiesClose', {
      templateUrl: 'app/monitoring/activities/close/close.html',
      bindings: {
         monitoring: '<'
      }
   })
   class Close {
      static $inject = ['common', 'datacontext'];
      constructor(
         private common: core.ICommonService,
         private datacontext: data.IDataContextService) {
         'ngInject';
      }

      public statuses: app.config.ILookup[] = [];
      public selectedStatus: string;
      public monitoring: IMonitoringBasicInfoView;
      public isSubmitting: boolean = false;
      public activity = {} as ICreateMonitoringActivity;
      public items: common.IToDoItem[];
      public maxDate: Date;

      private $onInit() {
         this.activity.involvementId = this.monitoring.involvementId;
         this.activity.monitoringId = this.monitoring.id;
         this.activity.activityDate = moment().toDate();
         this.maxDate = this.activity.activityDate;
         this.getMonitoringStatus();
      }

      private getMonitoringStatus() {
         return this.datacontext.monitoring.getMonitoringStatus(this.monitoring.id).then(
            result => {
               let data: any = result;
               if (data.status) {
                  this.selectedStatus = data.status;
                  this.activity.statusDescription = data.statusDescription;
               }
            });
      }

      public submit() {
         this.isSubmitting = true;

         if (typeof this.activity.activityDate !== 'string') {
            this.activity.activityDate = this.activity.activityDate.toDateString();
         }

         this.activity.status = this.selectedStatus;
         if (this.selectedStatus.indexOf('Other') === -1) {
            this.activity.statusDescription = null;
         }

         this.datacontext.monitoring
            .close(this.activity)
            .then(request => {
               if (request) {
                  this.common.$mdToast.showSimple('Monitoring Closed');
                  this.common.$rootScope.$broadcast('refreshBasicInfo');
                  this.monitoring.isActive = false;
                  this.common.$state.go('^.list', { monitoringId: this.monitoring.id, involvementId: this.monitoring.involvementId });
               }
            })
            .finally(() => this.isSubmitting = false);
      }
   }
}