namespace app.funding {
   @Component('app.funding', 'cePrimaryFundingSourceListing', {
      templateUrl: 'app/funding/enrollments/listing/enrollments-listing.html',
      bindings: {
         involvementId: '<',
         source: '@'
      }
   })
   class PrimaryFundingSourceListing {
      static $inject = ['$mdDialog', 'common', 'datacontext'];
      constructor(
         private $mdDialog: angular.material.IDialogService,
         private common: core.ICommonService,
         private datacontext: data.IDataContextService) {
         'ngInject';
      }

      public primaryFundingSourceList: IEnrollmentView[];
      public involvementId: string;

      public $onInit = () => {
         this.getEnrollmentView();
      }

      private getEnrollmentView = () => {
         return this.datacontext.funding
            .getEnrollmentView(this.involvementId)
            .then(result => {
               this.primaryFundingSourceList = result;
            });
      };
   }
}