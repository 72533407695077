namespace app {
   export interface IApiExecutorOptoins {
      keyBy?: string;
      returnValue?: any;
   }

   export function ApiExecutor(options?: IApiExecutorOptoins) {
      return function (target: Object, propertyKey: string, descriptor: TypedPropertyDescriptor<any>) {
         let originalMethod = descriptor.value;
         descriptor.value = function (...args: any[]) {
            return originalMethod.apply(this, args)
               .then(result => {
                  if (options && angular.isDefined(options.returnValue)) {
                     return options.returnValue;
                  }
                  else if (options && options.keyBy) {
                     return _.keyBy(result.data, options.keyBy);
                       }
                  else {
                     return result.data;
                       }
               })
               .catch((error: any) => {
                  if (error.status === 401 || error.status === 404) return;

                  const exception = angular.element(document.body).injector().get('exception') as core.exception.IExceptionService;

                  let message;
                  if (error.data) {
                     message = error.data.exceptionMessage || error.data.message;
                  } else if (error.status === 403) {
                     message = 'Access denied';
                  }

                  if (!message) {
                     message = error.statusText || `XHR for ${propertyKey} failed`;
                  }

                  exception.catcher(message)(error);
               });
         };
         return descriptor;
      };
   }

   export function Component(moduleName: string, selector: string, options: angular.IComponentOptions) {
      return (controller: any) => {
         angular.module(moduleName)
            .component(selector, { ...options, controller });
      };
   }

   export function Controller(moduleName: string, controllerName: string) {
      return (controller: any) => {
         angular
            .module(moduleName)
            .controller(controllerName, controller);
      };
   }

   export function Inject(...dependencies) {
      return function (target: any, decoratedPropertyName?: string): void {
         let normalizedDependencies = [];
         for (let dep of dependencies) {
            normalizedDependencies = normalizedDependencies.concat(dep.replace(/\s+/g, '').split(','));
         }

         let $inject = target.$inject = [];
         $inject = $inject.concat(normalizedDependencies);
         target.prototype.$inject = $inject;
         target.$inject = $inject;
      };
   }

   export function Service(moduleName: string, serviceName: string) {
      return (service: Function) => {
         angular
            .module(moduleName)
            .service(serviceName, service);
      };
   }
}