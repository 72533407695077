namespace app.requests {
   @Component('app.requests', 'ceRequestView', {
      templateUrl: 'app/requests/request/request-view.html',
      bindings: {
         request: '<'
      }
   })
   class RequestView {
      static $inject = ['common', 'datacontext', 'confirmation', 'timerService'];
      constructor(
         private common: core.ICommonService,
         private datacontext: data.IDataContextService,
         private confirmation: IConfirmationService,
         private timerService: time.ITimerService
      ) {
         'ngInject';
         this.$state = common.$state;
      }

      public isLoading: boolean = false;
      public request: IRequestBasicInfo;
      public $state: angular.ui.IStateService;
      private unsubscribe: any;

      private $onInit() {
         this.unsubscribe = this.common.$rootScope.$on('refreshBasicInfo', this.refresh);
      }

      private $onDestroy() {
         this.unsubscribe();
      }

      private refresh = () => {
         this.datacontext.requests.getRequestBasicInfo(this.request.id).then(
            request => this.request = request
         );
      }

      private showDeleteDialog(id: string) {
         this.confirmation.show({
            message: 'Are you sure you want to delete this Request? This will delete all data attached to this Request.',
            ok: 'DELETE',
            cancel: 'KEEP'
         }).then(() => { this.deleteRequest(id); });
      }

      private deleteRequest(id: string) {
         this.datacontext.requests.deleteRequest(id).then((result) => {
            if (result) {
               this.timerService.killTimer();
               this.common.$state.go('app.requestListView');
            }
         });
      }
   }
}