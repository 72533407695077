namespace app.analysis {
   export interface IAnalysisRepository {
      addChart(dashboardId: string, data: IAddChartData): angular.IPromise<IDashboardChart>;
      createChart(data: ICreateChartData): angular.IPromise<IChart>;
      createQuery(data: IQuery): angular.IPromise<IQuery>;
      updateQuery(data: IQuery): angular.IPromise<IQuery>;
      deleteChart(chartId: string): angular.IPromise<boolean>;
      deleteQuery(chartId: string): angular.IPromise<boolean>;
      downloadData(queryId: string): angular.IPromise<IDownloadDataResult>;
      getChartData(chartId: string): angular.IPromise<IChartData[]>;
      getChartList(): angular.IPromise<IChart[]>;
      getChartListByQueryId(queryId: string): angular.IPromise<IChart[]>;
      getDataSource(dataSourceId: string): angular.IPromise<IDataSource>;
      getDataSources(): angular.IPromise<IDataSource[]>;
      getMyDashboard(): angular.IPromise<IDashboard>;
      getQuery(queryId: string): angular.IPromise<IQuery>;
      getQueryList(params: IQuerySearch): angular.IPromise<IQueryListItem[]>;
      getTabularData(queryId: string, params?: IGetTabularDataParams): angular.IPromise<ITabularDataResult>;
      previewChartData(queryId: string, data: IPreviewChartDataData): angular.IPromise<IChartData[]>;
      previewTabularData(data: IPreviewTabularDataData): angular.IPromise<ITabularDataResult>;
      removeChart(dashboardId: string, chartId: string): angular.IPromise<boolean>;
      updateChartLayout(dashboardId: string, chartId: string, layout: IChartLayout): angular.IPromise<boolean>;
      updateQueryColumns(queryId: string, columns: string[]): angular.IPromise<boolean>;
   }

   const baseUrl = 'api/analysis';

   // Analysis repository
   @Service('app.analysis', 'analysis.repository')
   class AnalysisRepository implements IAnalysisRepository {
      static $inject = ['$http', 'exception'];
      constructor(
         private $http: angular.IHttpService,
         private exception: core.exception.IExceptionService) {
         'ngInject';
      }

      @ApiExecutor()
      public addChart(dashboardId: string, data: IAddChartData) {
         return this.$http.post<IDashboardChart>(`${baseUrl}/dashboards/${dashboardId}/charts`, data) as any;
      }

      @ApiExecutor()
      public createChart(data: ICreateChartData) {
         return this.$http.post<IChart>(`${baseUrl}/charts`, data) as any;
      }

      @ApiExecutor()
      public createQuery(data: IQuery) {
         return this.$http.post<IQuery>(`${baseUrl}/queries`, data) as any;
      }

      @ApiExecutor()
      public updateQuery(data: IQuery) {
         return this.$http.put<IQuery>(`${baseUrl}/queries/${data.id}`, data) as any;
      }

      @ApiExecutor({ returnValue: true })
      public deleteChart(chartId: string) {
         return this.$http.delete<boolean>(`${baseUrl}/charts/${chartId}`) as any;
      }

      @ApiExecutor({ returnValue: true })
      public deleteQuery(queryId: string) {
         return this.$http.delete<boolean>(`${baseUrl}/queries/${queryId}`) as any;
      }

      public downloadData(queryId: string) {
         return this.$http.get<any>(`${baseUrl}/queries/${queryId}/data/csv`, { responseType: 'arraybuffer' }).then(
            (response) => {
               return {
                  buffer: response.data,
                  contentType: response.headers()['content-type'],
                  fileName: response.headers()['content-disposition'].split(';')[1].split('=')[1].replace(/"/g, '')
               };
            }).catch(error => this.exception.catcher('XHR for getCsvData failed')(error)) as any;
      }

      @ApiExecutor()
      public getChartData(chartId: string) {
         return this.$http.get<IChartData[]>(`${baseUrl}/charts/${chartId}/data`) as any;
      }

      @ApiExecutor()
      public getChartList() {
         return this.$http.get<IChart[]>(`${baseUrl}/charts`) as any;
      }

      @ApiExecutor()
      public getChartListByQueryId(queryId: string) {
         return this.$http.get<IChart[]>(`${baseUrl}/queries/${queryId}/charts`) as any;
      }

      @ApiExecutor()
      public getDataSource(dataSourceId: string) {
         return this.$http.get<IDataSource>(`${baseUrl}/data-sources/${dataSourceId}`) as any;
      }

      @ApiExecutor()
      public getDataSources() {
         return this.$http.get<IDataSource[]>(`${baseUrl}/data-sources`) as any;
      }

      @ApiExecutor()
      public getMyDashboard() {
         return this.$http.get<IDashboard>(`${baseUrl}/dashboards/my`) as any;
      }

      @ApiExecutor()
      public getQuery(queryId: string) {
         return this.$http.get<IQuery>(`${baseUrl}/queries/${queryId}`) as any;
      }

      @ApiExecutor()
      public getQueryList(params: IQuerySearch) {
         return this.$http.get<IQueryListItem[]>(`${baseUrl}/queries`, { params }) as any;
      }

      @ApiExecutor()
      public getTabularData(queryId: string, params?: IGetTabularDataParams) {
         return this.$http.get<ITabularDataResult>(`${baseUrl}/queries/${queryId}/data/tabular`, { params }) as any;
      }

      @ApiExecutor()
      public previewChartData(queryId: string, data: IPreviewChartDataData) {
         return this.$http.post<IChartData[]>(`${baseUrl}/queries/${queryId}/data/chart`, data) as any;
      }

      @ApiExecutor()
      public previewTabularData(data: IPreviewTabularDataData) {
         return this.$http.post<ITabularDataResult>(`${baseUrl}/queries/data/tabular`, data) as any;
      }

      @ApiExecutor({ returnValue: true })
      public removeChart(dashboardId: string, chartId: string) {
         return this.$http.delete<boolean>(`${baseUrl}/dashboards/${dashboardId}/charts/${chartId}`) as any;
      }

      @ApiExecutor({ returnValue: true })
      public updateChartLayout(dashboardId: string, chartId: string, layout: IChartLayout) {
         return this.$http.put<boolean>(`${baseUrl}/dashboards/${dashboardId}/charts/${chartId}/layout`, { layout }) as any;
      }

      @ApiExecutor({ returnValue: true })
      public updateQueryColumns(queryId: string, columns: string[]) {
         return this.$http.put<boolean>(`${baseUrl}/queries/${queryId}/metadata/columns`, { columns }) as any;
      }
   }
}