namespace app.agencies {
   @Component('app.agencies', 'ceAgencySearch', {
      templateUrl: 'app/agencies/find-create-agency/agency-search.html',
      bindings: {
      }
   })
   class FindAgency {
      static $inject = ['common', 'datacontext'];
      constructor(
         private common: core.ICommonService,
         private datacontext: data.IDataContextService) {
         'ngInject';
      }

      public searchResults: data.IPagedList<IAgency>;
      public params = {
         page: 1,
         pageSize: 5
      } as IGetAgenciesParams;
      public searched: boolean = false;

      private navigate(agency: IAgencyBasicInfo) {
         // todo pass this in
         let entity = {} as app.entities.IEntityBasicInfo;
         entity.entityId = agency.id;
         entity.fullName = agency.fullName;
         entity.entityType = 'Agency';
         entity.isUser = false;
         this.common.$state.go('^.roles', { entity: entity });
      }

      public refresh = () => {
         this.search();
      }

      private search = () => {
         this.searched = true;
         if (!this.params.q) {
            this.searchResults = null;
            return;
         }

         return this.datacontext.agencies
            .getAgencies(this.params)
            .then(result => {
               this.searchResults = result;
            });
      }
   }
}