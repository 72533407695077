namespace app.clientCases {
   'use strict';

   export interface IClientCaseRepository {
      getClientCaseBasicInfoView(id: string): angular.IPromise<IClientCaseBasicInfoView>;
      getClientCaseSummaryView(id: string): angular.IHttpPromise<IClientCaseSummaryView>;
      getClientCaseActivities(caseId: string): angular.IPromise<IClientCaseActivityModel[]>;
      getClientCaseInformation(caseId: string): angular.IPromise<IClientCaseInformation>;
      getClientCaseListing(params: IGetClientCasesParams): angular.IPromise<data.IPagedList<IClientCaseListingView>>;
      getClientCaseListingDownload(params: IGetClientCasesParams): angular.IPromise<app.analysis.IDownloadDataResult>;
      getClientCaseNoTimeslipsListing(params: IGetClientCasesNoTimeslipsParams): angular.IPromise<data.IPagedList<IClientCaseListingView>>;
      getClientCaseStatus(id: string): angular.IHttpPromise<IClientCaseStatus>;
      getClientCaseToDo(id: string, stage?: string): angular.IPromise<common.IToDoItem[]>;
      getClosureInformation(id: string): angular.IPromise<IClientCaseClosureInformation>;
      getSurveyInformation(id: string): angular.IPromise<IClientCaseSurveyInformation>;
      updateSpecialEducation(closureSpecialEducation: IClosureSpecialEducation): angular.IPromise<IClosureSpecialEducation>;
      getSpecialEducation(id: string): angular.IPromise<IClosureSpecialEducation>;
      getClosureSpecialEducationOptions(): angular.IPromise<IClosureSpecialEducationOption[]>;
      createClientCase(clientCase: ICreateClientCase): angular.IPromise<string>;
      makeClientCase(source: string, sourceId: string, involvementId: string): angular.IPromise<string>;
      close(activity: ICreateClientCaseActivity): angular.IHttpPromise<ICreateClientCaseActivity>;
      reopen(activity: ICreateClientCaseActivity): angular.IHttpPromise<ICreateClientCaseActivity>;
      followUp(activity: ICreateClientCaseActivity): angular.IHttpPromise<ICreateClientCaseActivity>;
      createNote(activity: ICreateClientCaseActivity): angular.IHttpPromise<ICreateClientCaseActivity>;
      updateNote(activity: ICreateClientCaseActivity): angular.IHttpPromise<ICreateClientCaseActivity>;
      updateClientCaseInformation(info: IClientCaseInformation): angular.IPromise<IClientCaseInformation>;
      updateClosureInformation(closure: IClientCaseClosureInformation): angular.IPromise<IClientCaseClosureInformation>;
      updateSurveyInformation(survey: IClientCaseSurveyInformation): angular.IPromise<IClientCaseSurveyInformation>;
      createCaseReview(review: involvements.ICaseReview): angular.IPromise<involvements.ICaseReview>;
      updateCaseReview(review: involvements.ICaseReview): angular.IPromise<involvements.ICaseReview>;
      getActiveCaseCount(userIds: string[]): angular.IPromise<number>;
      getCasesWithNoTimeslips(userIds: string[]): angular.IPromise<any>;
      deleteClientCase(id: string): angular.IPromise<string>;
   }

   const baseUrl = 'api/clientCases';

   class ClientCaseRepository implements IClientCaseRepository {
      static $inject = ['$http', 'exception'];
      constructor(
         private $http: angular.IHttpService,
         private exception: core.exception.IExceptionService,
      ) {
      }

      @ApiExecutor()
      public getClientCaseListing(params: IGetClientCasesParams) {
         return this.$http.get<data.IPagedList<IClientCaseListingView>>(baseUrl, { params }) as any;
      }

      public getClientCaseListingDownload(params: IGetClientCasesParams) {
         return this.$http.get<any>(`${baseUrl}/csv`, { params: params, responseType: 'arraybuffer' }).then(
            (response) => {
               return {
                  buffer: response.data,
                  contentType: response.headers()['content-type'],
                  fileName: response.headers()['content-disposition'].split(';')[1].split('=')[1].replace(/"/g, '')
               };
            }).catch(error => this.exception.catcher('XHR for getCsvData failed')(error)) as any;
      }

      @ApiExecutor()
      public getClientCaseNoTimeslipsListing(params: IGetClientCasesNoTimeslipsParams) {
         return this.$http.get<data.IPagedList<IClientCaseListingView>>(`${baseUrl}/notimeslips`, { params }) as any;
      }

      @ApiExecutor()
      public getClientCaseActivities(caseId: string) {
         return this.$http.get<IClientCaseActivityModel[]>(`${baseUrl}/${caseId}/activities`) as any;
      }

      @ApiExecutor()
      public makeClientCase(source: string, sourceId: string, involvementId: string) {
         return this.$http.post<ICreateClientCase>(`${baseUrl}/make`, { params: { source: source, sourceId: sourceId, involvementId: involvementId } }) as any;
      }

      @ApiExecutor()
      public createClientCase(clientCase: ICreateClientCase) {
         return this.$http.post<ICreateClientCase>(baseUrl, clientCase) as any;
      }

      @ApiExecutor()
      public close(activity: ICreateClientCaseActivity) {
         return this.$http.post<ICreateClientCaseActivity>(`${baseUrl}/activities/${activity.caseId}/close`, activity) as any;
      }

      @ApiExecutor()
      public reopen(activity: ICreateClientCaseActivity) {
         return this.$http.post<ICreateClientCaseActivity>(`${baseUrl}/activities/${activity.caseId}/reopen`, activity) as any;
      }

      @ApiExecutor()
      public followUp(activity: ICreateClientCaseActivity) {
         return this.$http.post<ICreateClientCaseActivity>(`${baseUrl}/${activity.caseId}/followUp`, activity) as any;
      }

      @ApiExecutor()
      public createNote(activity: ICreateClientCaseActivity) {
         return this.$http.post<ICreateClientCaseActivity>(`${baseUrl}/activities/${activity.caseId}/notes`, activity) as any;
      }

      @ApiExecutor()
      public updateNote(activity: ICreateClientCaseActivity) {
         return this.$http.put<ICreateClientCaseActivity>(`${baseUrl}/activities/${activity.caseId}/notes/${activity.id}`, activity) as any;
      }

      @ApiExecutor()
      public getClientCaseStatus(id: string) {
         return this.$http.get<IClientCaseStatus>(`${baseUrl}/${id}/status`) as any;
      }

      @ApiExecutor()
      public getClientCaseBasicInfoView(id: string) {
         return this.$http.get<IClientCaseBasicInfoView>(`${baseUrl}/${id}/basicinfo`) as any;
      }

      @ApiExecutor()
      public getClientCaseSummaryView(id: string) {
         return this.$http.get<IClientCaseSummaryView>(`${baseUrl}/${id}/summary`) as any;
      }

      @ApiExecutor()
      public getClientCaseToDo(id: string, stage?: string) {
         return this.$http.get<common.IToDoItem[]>(`${baseUrl}/${id}/todo/${stage || ''}`) as any;
      }

      @ApiExecutor()
      public getClientCaseInformation(id: string) {
         return this.$http.get<IClientCaseInformation>(`${baseUrl}/${id}/information`) as any;
      }

      @ApiExecutor()
      public updateClientCaseInformation(info: IClientCaseInformation) {
         return this.$http.post<IClientCaseInformation>(`${baseUrl}/${info.id}/information`, info) as any;
      }

      @ApiExecutor()
      public getClosureInformation(id: string) {
         return this.$http.get<IClientCaseClosureInformation>(`${baseUrl}/${id}/closure`) as any;
      }
      @ApiExecutor()
      public updateClosureInformation(closure: IClientCaseClosureInformation) {
         return this.$http.post<IClientCaseClosureInformation>(`${baseUrl}/${closure.id}/closure`, closure) as any;
      }

      @ApiExecutor()
      public getSurveyInformation(id: string) {
         return this.$http.get<IClientCaseSurveyInformation>(`${baseUrl}/${id}/survey`) as any;
      }

      @ApiExecutor()
      public updateSpecialEducation(closureSpecialEducation: IClosureSpecialEducation) {
         return this.$http.post<IClosureSpecialEducation>(`${baseUrl}/${closureSpecialEducation.id}/closure/specialeducation`, closureSpecialEducation) as any;
      }

      @ApiExecutor()
      public getSpecialEducation(id: string) {
         return this.$http.get<IClosureSpecialEducation>(`${baseUrl}/${id}/closure/specialeducation`) as any;
      }

      @ApiExecutor()
      public getClosureSpecialEducationOptions() {
         return this.$http.get(`${baseUrl}/specialeducation/options`) as any;
      }

      @ApiExecutor()
      public createCaseReview(review: involvements.ICaseReview) {
         return this.$http.post<involvements.ICaseReview>(`${baseUrl}/activities/${review.caseId}/caseReview`, review) as any;
      }

      @ApiExecutor()
      public updateCaseReview(review: involvements.ICaseReview) {
         return this.$http.put<involvements.ICaseReview>(`${baseUrl}/activities/${review.caseId}/caseReview/${review.id}`, review) as any;
      }

      @ApiExecutor()
      public updateSurveyInformation(survey: IClientCaseSurveyInformation) {
         return this.$http.post<IClientCaseSurveyInformation>(`${baseUrl}/${survey.id}/survey`, survey) as any;
      }

      @ApiExecutor()
      public getActiveCaseCount(userIds: string[]) {
         return this.$http.get<number>(`${baseUrl}/activeCaseCount`, { params: { userIds: userIds } }) as any;
      }

      @ApiExecutor()
      public getCasesWithNoTimeslips(userIds: string[]) {
         return this.$http.get<any>(`${baseUrl}/casesWithNoTimeslipsCounts`, { params: { userIds: userIds } }) as any;
      }

      public deleteClientCase(id: string) {
         return this.$http.delete(`${baseUrl}/${id}`).catch(error => this.exception.catcher('Cannot Delete Case')(error)) as any;
      }
   }

   angular
      .module('app.clientCases')
      .service('clientCases.repository', ClientCaseRepository);
}