namespace app.activities {
   @Component('app.activities', 'ceNewActivityInvolvementSelector', {
      template: `
    <md-button class="mt" ng-click="$ctrl.onBack()">
      <md-icon>arrow_back</md-icon>
      Back
    </md-button>
    <md-list>
      <md-list-item ng-repeat="involvement in $ctrl.involvements" ng-click="$ctrl.onInvolvementSelected({involvement})">
        <span>{{involvement.idNumber}}</span>
        <md-icon class="md-secondary" ng-if="$ctrl.selectedInvolvement === involvement">check</md-icon>
      </md-list-item>
    </md-list>`,
      bindings: {
         involvements: '<',
         selectedInvolvement: '<',
         onBack: '&',
         onInvolvementSelected: '&'
      }
   })
   class InvolvementSelector {
   }
}