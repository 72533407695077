namespace app.funding {
   @Component('app.funding', 'cePrimaryFundingSourceList', {
      templateUrl: 'app/funding/enrollments/listing/enrollments-list.html',
      bindings: {
         primaryFundingSourceList: '<',
         source: '<'
      }
   })
   class PrimaryFundingSourceList {
      static $inject = ['common', 'datacontext'];
      constructor(
         private common: app.core.ICommonService,
         private datacontext: app.data.IDataContextService
      ) {
         'ngInject';
      }

      public primaryFundingSourceList: IEnrollmentView[];
      public source: string;

      private showDeleteDialog(id: string) {
         this.common.confirmation.show({
            title: 'Delete Primary Funding Source?',
            message: 'Are you sure you want to delete this Funding Source? The system will attempt to fix unlocked timeslips.',
            ok: 'Delete',
            cancel: 'Keep'
         }).then(() => { this.deleteEnrollment(id); });
      }

      private deleteEnrollment(id: string) {
         return this.datacontext.funding.deleteEnrollment(id)
            .then(() => this.removePrimaryFundingSource(id));
      }

      private removePrimaryFundingSource(id: string) {
         _.remove(this.primaryFundingSourceList, pfs => pfs.id === id);
      }

      public editIntake(enrollment: IEnrollmentView) {
         if (this.hasIntakeData(enrollment)) {
            const fundingProgramName = enrollment.parentFundingProgramName ?? enrollment.fundingProgramName;
            const stateName = `^.${fundingProgramName.toLowerCase()}Intake`;
            this.common.$state.go(stateName, { enrollmentId: enrollment.id });
         }
      }

      public editOutcome(enrollment: IEnrollmentView) {
         if (this.hasOutcomeData(enrollment)) {
            const fundingProgramName = enrollment.parentFundingProgramName ?? enrollment.fundingProgramName;
            const stateName = `^.${fundingProgramName.toLowerCase()}Outcome`;
            this.common.$state.go(stateName, { enrollmentId: enrollment.id });
         }
      }

      private hasIntakeData(enrollment: IEnrollmentView): boolean {
         switch (this.source) {
            case 'I&R':
               return enrollment.hasIAndRIntakeData;
            case 'Client Case':
               return enrollment.hasClientCaseIntakeData;
            case 'Systemic Case':
               return enrollment.hasSystemicCaseIntakeData;
            case 'Monitoring':
               return enrollment.hasMonitoringIntakeData;
         }
      }

      private hasOutcomeData(enrollment: IEnrollmentView): boolean {
         switch (this.source) {
            case 'I&R':
               return enrollment.hasIAndROutcomeData;
            case 'Client Case':
               return enrollment.hasClientCaseOutcomeData;
            case 'Systemic Case':
               return enrollment.hasSystemicCaseOutcomeData;
            case 'Monitoring':
               return enrollment.hasMonitoringOutcomeData;

         }
      }
   }
}