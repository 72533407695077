namespace app.training {
   'use strict';

   export interface ITrainingRepository {
      getCLEListing(params: IGetCLEsParams): data.IPagedListPromise<ICLEListing>;
      getCLEListingDownload(params: IGetCLEsParams): angular.IPromise<app.analysis.IDownloadDataResult>;
      getCLEInformation(id: string): angular.IPromise<ICLECourse>;
      updateCLEInformation(cle: ICLECourse): angular.IPromise<ICLECourse>;
      createCLE(cle: ICLECourse): angular.IPromise<ICLECourse>;
      getTrainingListing(params: IGetTrainingParams): data.IPagedListPromise<ITrainingListing>;
      getTrainingListingDownload(params: IGetTrainingParams): angular.IPromise<app.analysis.IDownloadDataResult>;
      getTrainingInformation(id: string): angular.IPromise<ITraining>;
      updateTrainingInformation(training: ITraining): angular.IPromise<ITraining>;
      createTraining(training: ITraining): angular.IPromise<ITraining>;
      getRequestListing(params: IGetRequestParams): data.IPagedListPromise<IRequestListing>;
      getRequestListingDownload(params: IGetRequestParams): angular.IPromise<app.analysis.IDownloadDataResult>;
      getRequestInformation(id: string): angular.IPromise<IRequest>;
      updateRequestInformation(training: IRequest): angular.IPromise<IRequest>;
      createRequest(training: IRequest): angular.IPromise<IRequest>;
      getEntitiesBasic(trainingId: string): angular.IPromise<involvements.IEntityBasicInfo[]>;
      getEntity(trainingId: string, entityId: string): angular.IPromise<IEntityView>;
      getEntitySummary(entityType: string, entityId: string): angular.IPromise<IEntityView>;
      getEntityListing(trainingId: string): angular.IPromise<IEntityListing[]>;
      createEntity(entity: ITrainingEntity): angular.IPromise<IEntityView>;
      updateEntity(entity: ITrainingEntity): angular.IPromise<IEntityView>;
      deleteEntity(id: string): angular.IPromise<boolean>;
      deleteCLE(id: string): angular.IPromise<string>;
      deleteTraining(id: string): angular.IPromise<string>;
      deleteTrainingRequest(id: string): angular.IPromise<string>;
      getCLEEvents(params: IGetEventsParams): angular.IPromise<IEvent[]>;
      getTrainingEvents(params: IGetEventsParams): angular.IPromise<IEvent[]>;
      createCLENote(activity: ICreateCLEActivity): angular.IHttpPromise<ICreateCLEActivity>;
      updateCLENote(activity: ICreateCLEActivity): angular.IHttpPromise<ICreateCLEActivity>;
      createTrainingNote(activity: ICreateTrainingActivity): angular.IHttpPromise<ICreateTrainingActivity>;
      updateTrainingNote(activity: ICreateTrainingActivity): angular.IHttpPromise<ICreateTrainingActivity>;
   }

   const baseUrl = 'api/trainings';

   class TrainingRepository implements ITrainingRepository {
      static $inject = ['$http', 'exception'];
      constructor(
         private $http: angular.IHttpService,
         private exception: core.exception.IExceptionService,
      ) {
      }

      @ApiExecutor()
      public getCLEEvents(params: IGetEventsParams) {
         return this.$http.get<IEvent[]>(`api/calendars/cleevents`, { params }) as any;
      }

      @ApiExecutor()
      public getTrainingEvents(params: IGetEventsParams) {
         return this.$http.get<IEvent[]>(`api/calendars/trainingevents`, { params }) as any;
      }

      @ApiExecutor()
      public getCLEListing(params: IGetCLEsParams) {
         return this.$http.get<data.IPagedList<ICLEListing>>(`${baseUrl}/cle`, { params }) as any;
      }

      public getCLEListingDownload(params: IGetCLEsParams) {
         return this.$http.get<any>(`${baseUrl}/cle/csv`, { params, responseType: 'arraybuffer' }).then(
            (response) => {
               return {
                  buffer: response.data,
                  contentType: response.headers()['content-type'],
                  fileName: response.headers()['content-disposition'].split(';')[1].split('=')[1].replace(/"/g, '')
               };
            }).catch(error => this.exception.catcher('XHR for getCsvData failed')(error)) as any;
      }

      @ApiExecutor()
      public getCLEInformation(id: string) {
         return this.$http.get<ICLECourse>(`${baseUrl}/cle/${id}`) as any;
      }

      @ApiExecutor()
      public updateCLEInformation(cle: ICLECourse) {
         return this.$http.put<ICLECourse>(`${baseUrl}/cle/${cle.id}`, cle) as any;
      }

      @ApiExecutor()
      public createCLE(cle: ICLECourse) {
         return this.$http.post<ICLECourse>(`${baseUrl}/cle`, cle) as any;
      }
      @ApiExecutor()
      public getRequestListing(params: IGetRequestParams) {
         return this.$http.get<data.IPagedList<IRequestListing>>(`${baseUrl}/request`, { params }) as any;
      }

      public getRequestListingDownload(params: IGetRequestParams) {
         return this.$http.get<any>(`${baseUrl}/request/csv`, { params, responseType: 'arraybuffer' }).then(
            (response) => {
               return {
                  buffer: response.data,
                  contentType: response.headers()['content-type'],
                  fileName: response.headers()['content-disposition'].split(';')[1].split('=')[1].replace(/"/g, '')
               };
            }).catch(error => this.exception.catcher('XHR for getCsvData failed')(error)) as any;
      }

      @ApiExecutor()
      public getRequestInformation(id: string) {
         return this.$http.get<IRequest>(`${baseUrl}/request/${id}`) as any;
      }

      @ApiExecutor()
      public updateRequestInformation(request: IRequest) {
         return this.$http.put<IRequest>(`${baseUrl}/request/${request.id}`, request) as any;
      }

      @ApiExecutor()
      public createRequest(request: IRequest) {
         return this.$http.post<IRequest>(`${baseUrl}/request`, request) as any;
      }
      @ApiExecutor()
      public getTrainingListing(params: IGetTrainingParams) {
         return this.$http.get<data.IPagedList<ITrainingListing>>(`${baseUrl}/training`, { params }) as any;
      }

      public getTrainingListingDownload(params: IGetTrainingParams) {
         return this.$http.get<any>(`${baseUrl}/training/csv`, { params, responseType: 'arraybuffer' }).then(
            (response) => {
               return {
                  buffer: response.data,
                  contentType: response.headers()['content-type'],
                  fileName: response.headers()['content-disposition'].split(';')[1].split('=')[1].replace(/"/g, '')
               };
            }).catch(error => this.exception.catcher('XHR for getCsvData failed')(error)) as any;
      }

      @ApiExecutor()
      public getTrainingInformation(id: string) {
         return this.$http.get<ITraining>(`${baseUrl}/training/${id}`) as any;
      }

      @ApiExecutor()
      public updateTrainingInformation(training: ITraining) {
         return this.$http.put<ITraining>(`${baseUrl}/training/${training.id}`, training) as any;
      }

      @ApiExecutor()
      public createTraining(training: ITraining) {
         return this.$http.post<ITraining>(`${baseUrl}/training`, training) as any;
      }

      @ApiExecutor()
      public getEntity(trainingId: string, entityId: string) {
         return this.$http.get<IEntityView>(`${baseUrl}/${trainingId}/entities/${entityId}`) as any;
      }

      @ApiExecutor()
      public getEntityListing(trainingId: string) {
         return this.$http.get<IEntityView[]>(`${baseUrl}/${trainingId}/entities`) as any;
      }

      @ApiExecutor()
      public getEntitySummary(entityType: string, entityId: string) {
         return this.$http.get<IEntityView[]>(`${baseUrl}/entitySummary?entityType=${entityType}&entityId=${entityId}`) as any;
      }

      @ApiExecutor()
      public getEntitiesBasic(trainingId: string) {
         return this.$http.get<IEntityBasicInfo[]>(`${baseUrl}/${trainingId}/entitiesbasic`) as any;
      }

      @ApiExecutor()
      public createEntity(entity: ITrainingEntity) {
         return this.$http.post<ITrainingEntity>(`${baseUrl}/entities`, entity) as any;
      }

      @ApiExecutor()
      public updateEntity(entity: ITrainingEntity) {
         return this.$http.put<ITrainingEntity>(`${baseUrl}/entities`, entity) as any;
      }

      @ApiExecutor()
      public deleteEntity(id: string) {
         return this.$http.delete<boolean>(`${baseUrl}/entities/${id}`) as any;
      }

      public deleteCLE(id: string) {
         return this.$http.delete(`${baseUrl}/cle/${id}`).catch(error => this.exception.catcher('Cannot Delete CLE')(error)) as any;
      }

      public deleteTraining(id: string) {
         return this.$http.delete(`${baseUrl}/training/${id}`).catch(error => this.exception.catcher('Cannot Delete Training')(error)) as any;
      }

      public deleteTrainingRequest(id: string) {
         return this.$http.delete(`${baseUrl}/request/${id}`).catch(error => this.exception.catcher('Cannot Delete Training Request')(error)) as any;
      }

      @ApiExecutor()
      public createCLENote(activity: ICreateCLEActivity) {
         return this.$http.post<ICreateCLEActivity>(`${baseUrl}/cle/${activity.cleId}/notes`, activity) as any;
      }

      @ApiExecutor()
      public updateCLENote(activity: ICreateCLEActivity) {
         return this.$http.put<ICreateCLEActivity>(`${baseUrl}/cle/${activity.cleId}/notes/${activity.id}`, activity) as any;
      }

      @ApiExecutor()
      public createTrainingNote(activity: ICreateTrainingActivity) {
         return this.$http.post<ICreateTrainingActivity>(`${baseUrl}/training/${activity.trainingId}/notes`, activity) as any;
      }

      @ApiExecutor()
      public updateTrainingNote(activity: ICreateTrainingActivity) {
         return this.$http.put<ICreateTrainingActivity>(`${baseUrl}/training/${activity.trainingId}/notes/${activity.id}`, activity) as any;
      }
   }

   angular
      .module('app.training')
      .service('training.repository', TrainingRepository);
}