namespace app.utils {
   export interface IAddress {
      line1?: string;
      city?: string;
      state?: string;
      postalCode?: string;
      county?: string;
      fullAddress?: string;
      geoLatitude?: number;
      geoLongitude?: number;
   }

   export interface IAddressHelperService {
      mapFromGooglePlaceResult(place): IAddress;
   }

   @Service('app.utils', 'addressHelper')
   class AddressHelperService implements IAddressHelperService {
      public mapFromGooglePlaceResult(place) {
         const address = <IAddress>{};

         const components = place.address_components;
         for (let i = 0, component; component = components[i]; i++) {
            if (!(component.types && component.types.length)) {
               continue;
            }

            if (component.types[0] === 'street_number') {
               address.line1 = component['long_name'];
            }
            if (component.types[0] === 'route') {
               address.line1 += ' ' + component['long_name'];
            }
            if (component.types[0] === 'locality') {
               address.city = component['long_name'];
            }
            if (component.types[0] === 'administrative_area_level_1') {
               address.state = component['short_name'];
            }
            if (component.types[0] === 'administrative_area_level_2') {
                address.county = component['short_name'].replace(' County', '');
             }
            if (component.types[0] === 'postal_code') {
               address.postalCode = component['long_name'];
            }
         }

         address.fullAddress = place.formatted_address;
         address.geoLatitude = place.geometry.location.lat();
         address.geoLongitude = place.geometry.location.lng();

         return address;
      }
   }
}