namespace app.requests.activities {
   interface IActivityViewModel {
      icon: string;
      text: string;
      sref: string;
      activityType?: string;
      filter?: (request: requests.IRequestBasicInfo) => boolean;
   }

   const activities: IActivityViewModel[] = [
      {
         icon: 'history',
         text: 'Conflict Check',
         sref: '^.conflictCheck',
         filter: i => i.isActive
      },
      {
         icon: 'info',
         text: 'Convert to I&R',
         sref: '^.createInformationAndReferral',
         filter: i => i.isActive
      },
      {
         icon: 'assignment_turned_in',
         text: 'Review',
         sref: '^.review',
         filter: i => i.isActive
      },

      {
         icon: 'folder_shared',
         text: 'Convert to Client Case',
         sref: '^.createClientCase',
         filter: i => i.isActive
      },
      {
         icon: 'record_voice_over',
         text: 'Follow-Up',
         sref: '^.followUp',
         filter: i => i.isActive
      },
      {
         icon: 'folder',
         text: 'Convert to Systemic Case',
         sref: '^.createSystemicCase',
         filter: i => i.isActive
      },
      {
         icon: 'comment',
         text: 'Note',
         sref: '^.note',
         filter: i => true
      },
      {
         icon: 'highlight_off',
         text: 'Close Request',
         sref: '^.close',
         filter: i => i.isActive
      },
      {
         icon: 'folder_open',
         text: 'Reopen Request',
         sref: '^.reopen',
         filter: i => !i.isActive
      },
      {
         icon: 'group_work',
         text: 'Case Assessment Meeting',
         sref: '^.caseAssessmentMeeting',
         filter: i => i.isActive
      },
      {
         icon: 'approval',
         text: 'Status Change',
         sref: '^.statusChange',
         filter: i => i.isActive
      },
   ];

   @Controller('app.requests.activities', 'NewRequestActivityDialogController')
   class NewRequestActivityDialogController {
      static $inject = ['$mdDialog', 'common', 'datacontext', 'request'];
      constructor(
         private $mdDialog: angular.material.IDialogService,
         private common: core.ICommonService,
         private datacontext: data.IDataContextService,
         private request: IRequestBasicInfo) {
         'ngInject';
      }

      public filteredActivities: IActivityViewModel[];

      private $onInit() {
         this.filteredActivities = activities.filter(this.filterActivity);
      }

      private filterActivity = (activity: IActivityViewModel) => {
         return activity.filter(this.request);
      }

      public selectActivity(item: IActivityViewModel) {
         this.common.$state.go(item.sref);
         this.$mdDialog.hide();
      }
   }
}