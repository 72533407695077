namespace app.utils {
   const errSrc = () => {
      return {
         link: (scope: angular.IScope, element: angular.IAugmentedJQuery, attrs: any) => {
            scope.$watch(() => attrs['ngSrc'], (value: string) => {
               if (!value) {
                  element.attr('src', attrs.errSrc);
               }
            });

            element.bind('error', () => element.attr('src', attrs.errSrc));
         }
      };
   };

   angular
      .module('app.utils')
      .directive('errSrc', errSrc);
}