namespace app.search {
   const routes: core.routing.IStateExtended[] = [
      {
         name: 'app.search',
         url: '/search',
         template: '<ce-advanced-search layout="column" flex></ce-advanced-search>',
         data: { title: 'Advanced Search' }
      }
   ];

   const runner = (routingHelper: core.routing.IRoutingHelperService) => {
      'ngInject';
      routingHelper.configureRoutes(routes);
   };

   angular
      .module('app.search')
      .run(runner);
}