namespace app.agencies.overview {
   @Component('app.agencies.overview', 'ceAgencyOverviewView', {
      templateUrl: 'app/agencies/overview/overview-view.html',
      bindings: {
         agency: '<'
      }
   })
   class AgencyOverviewView {
      public agency: IAgencyBasicInfo;
   }
}