namespace app.agencies.overview {
   @Component('app.agencies.overview', 'ceAgencySummary', {
      templateUrl: 'app/agencies/summary/summary.html',
      bindings: {
         agency: '<'
      }
   })
   class AgencySummary {
      static $inject = ['datacontext'];
      constructor(
         private datacontext: data.IDataContextService) {
         'ngInject';
      }

      public agency: IAgencyBasicInfo;

      public $onInit() {
      }
   }
}