namespace app.training {
   @Component('app.training.entities', 'ceTrainingEntityList', {
      templateUrl: 'app/training/entities/entity-listing/entity-list.html',
      bindings: {
         entities: '<',
         totalEntities: '<',
         onDelete: '&'
      }
   })
   class EntityList {
   }
}