namespace app.documentManagement {
   @Controller('app.documentManagement', 'ChangeDocumentFolderDialogController')
   class ChangeDocumentFolderDialogController {
      static $inject = ['$mdDialog', 'datacontext', 'document'];
      constructor(
         private $mdDialog: angular.material.IDialogService,
         private datacontext: data.IDataContextService,
         private document: IDocumentFolder) {
         'ngInject';
      }

      public data = {} as IChangeDocumentFolderData;
      public isSubmitting = false;
      public folders: IDocumentFolder[];

      public submit() {
         this.isSubmitting = true;
         this.datacontext.documentManagement
            .changeDocumentFolder(this.data)
            .then(() => this.$mdDialog.hide())
            .finally(() => this.isSubmitting = false);
      }

      private $onInit() {
         this.getFolders();
         this.setData();
      }

      private setData() {
         this.data.documentId = this.document.id;
      }

      private getFolders = () => {
         return this.datacontext.documentManagement
            .getFolders({ involvementId: this.document.involvementId, source: this.document.source, sourceId: this.document.sourceId, type: 'Folder', pageSize: 100 })
            .then(result => this.folders = result.list);
      };

      public cancel() {
         this.$mdDialog.cancel();
      }
   }
}