namespace app.time {
   export interface ICalendarDataService {
      eventSources: app.calendars.fc.IEventSource[];
      timeslips: ITimeslipListingView[];
      refresh(source: string, sourceId: string, involvementId: string): void;
      getLastLock(): ng.IPromise<ILastLock>;
   }

   class CalendarDataService {
      constructor(
         private common: core.ICommonService,
         private datacontext: data.IDataContextService,
         private timeslipCalendarState: ICalendarStateService) {
         'ngInject';
         this.eventSources = [];
      }

      private state = this.timeslipCalendarState.state;
      public timeslips: ITimeslipListingView[];
      public eventSources: app.calendars.fc.IEventSource[];

      public refresh = (source: string, sourceId: string, involvementId: string) => {
         const params: IGetTimeslipsParams = {
            userIds: this.state.userIds,
            fromDate: this.state.dateRange.start,
            toDate: this.state.dateRange.end,
            source: source,
            sourceId: sourceId,
            involvementId: involvementId
         };

         this.eventSources.splice(0);

         if (source === 'Project' && this.state.userIds.length < 1) {
            return;
         }

         if (sourceId || this.state.userIds.length > 0) {
            this.getTimeslips(params);
         }
      };

      private getTimeslips = (params: IGetTimeslipsParams) => {
         this.datacontext.time
            .getTimeslipCalendarItems(params)
            .then(result => {
               if (result) {
                  this.timeslips = result;
                  let reducedEvents = _(this.reduceEvents(this.timeslips));
                  let events = reducedEvents.map(e => this.mapTimeslipSummary(e)).value() as IEvent[];
                  this.eventSources.splice(0);
                  this.eventSources.push({ events });
               }
            });
      };

      private reduceEvents(results: any) {
         return _.reduce(results, function (result: any, value: IEvent, key) {
            if (result === null) {
               result = [{ ...value }];
            }
            else {
               let timeslipSummary: any = _.find(result, (r: any) => { return r.date.toString() === value.date.toString() && r.userId === value.userId; });
               if (timeslipSummary == null) {
                  result.push({ ...value });
               }
               else {
                  timeslipSummary.hours += value.hours;
               }
            }
            return result;
         }, null);
      }

      private mapTimeslipSummary = (timeslip: ITimeslipSummary): IEvent => {
         const start = moment(timeslip.date).startOf('day');
         const end = start.endOf('day');

         // TODO: if the timeslip is locked, we should use locked color
         const color = 'rgb(33, 150, 243)';
         const lockedColor = 'rgb(33, 150, 243, .5)';

         return { ...timeslip, allDay: true, title: `${timeslip.userInitials} ${timeslip.hours.toFixed(1)}h`, color, start, end };
      };

      private getLastLock(): ng.IPromise<ILastLock> {
         return this.datacontext.time.getLastLock();
      }
   }

   angular
      .module('app.time')
      .service('timeslipCalendarData', CalendarDataService);
}