namespace app.layout {
   @Component('app.layout', 'ceTopBar', {
      templateUrl: 'app/layout/top-bar/top-bar.html',
      bindings: {
         onMenuClick: '&'
      }
   })
   class TopBarComponent {
      static $inject = ['$rootScope'];
      constructor(private $rootScope: core.IRootScopeService) {
         'ngInject';
         this.$rootScope.$watch(() => this.$rootScope.isWorking, value => this.isWorking = value);
      }

      public isWorking: boolean;
   }
}