namespace app.core {
   function runner($rootScope: IRootScopeService) {
      'ngInject';

      const ua = window.navigator.userAgent;
      let ieVersion;

      const msie = ua.indexOf('MSIE ');
      if (msie > 0) {
         // IE 10 or older => return version number
         ieVersion = parseInt(ua.substring(msie + 5, ua.indexOf('.', msie)), 10);
      }

      const trident = ua.indexOf('Trident/');
      if (trident > 0) {
         // IE 11 => return version number
         const rv = ua.indexOf('rv:');
         ieVersion = parseInt(ua.substring(rv + 3, ua.indexOf('.', rv)), 10);
      }

      $rootScope.ieVersion = ieVersion;
   }

   angular.module('app.core').run(runner);
}