namespace app.persons.details {
   @Component('app.persons.details', 'cePersonNames', {
      templateUrl: 'app/persons/details/names/names.html',
      bindings: {
         person: '<',
      }
   })
   class PersonNames {
      public person: IPersonBasicInfo;
   }
}