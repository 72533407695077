namespace app.informationAndReferrals {
   'use strict';

   export const caseBasicInfoResolver = (datacontext: data.IDataContextService, $stateParams: angular.ui.IStateParamsService) => {
      'ngInject';
      const { informationAndReferralId } = $stateParams;
      return datacontext.informationAndReferrals.getInformationAndReferralBasicInfoView(informationAndReferralId);
   };

   export const legalTeamsResolver = (datacontext: data.IDataContextService) => {
      'ngInject';
      return datacontext.config.legalTeams.getLegalTeams(false);
   };
}