namespace app.funding {
   @Component('app.funding', 'ceCapSystemicIntakeForm', {
      templateUrl: 'app/funding/enrollments/cap/systemic/cap-intake-form.html',
      bindings: {
         intake: '<',
         onCancel: '&',
         onSubmit: '&',
         livingArrangements: '<'
      }
   })
   class CAPSystemicIntakeForm {
      static $inject = ['datacontext'];
      constructor(
         private datacontext: data.IDataContextService) {
         'ngInject';
      }

      public ageRanges: app.config.ILookup[];
      public races: app.config.ILookup[];
      public problemAreas: app.config.ILookup[];
      public livingArrangements: app.config.ILookup[];
      public outcome: ICAPSystemicIntake;

      public $onInit() {
         this.getAgeRanges();
         this.getRaces();
         this.getProblemAreas();
         this.getLivingArrangements();
      }

      public getAgeRanges() {
         return this.datacontext.config.lookups.getLookupType('cap-systemic-age-ranges')
            .then(lookup => { this.ageRanges = lookup.lookups; });
      }

      public getRaces() {
         return this.datacontext.config.lookups.getLookupType('cap-systemic-races')
            .then(lookup => { this.races = lookup.lookups; });
      }

      public getProblemAreas() {
         return this.datacontext.config.lookups.getLookupType('cap-problem-areas')
            .then(lookup => { this.problemAreas = lookup.lookups; });
      }

      public getLivingArrangements() {
         return this.datacontext.config.lookups.getLookupType('cap-living-arrangements')
            .then(lookup => { this.livingArrangements = lookup.lookups; });
      }
   }
}