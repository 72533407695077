namespace app.preferences {
   export interface IPreferenceRepository {
      getPreferences(): angular.IPromise<IPreferences>;
      savePreferences(data: IPreferences): angular.IPromise<boolean>;
   }

   const baseUrl = 'api/preferences';

   class PreferenceRepository implements IPreferenceRepository {
      constructor(private $http: angular.IHttpService) {
         'ngInject';
      }

      @ApiExecutor()
      public getPreferences() {
         return this.$http.get<IPreferences>(baseUrl) as any;
      }

      @ApiExecutor({ returnValue: true })
      public savePreferences(data: IPreferences) {
         return this.$http.put<boolean>(baseUrl, data) as any;
      }
   }

   angular
      .module('app.preferences')
      .service('preferences.repository', PreferenceRepository);
}