namespace app.config {
   @Component('app.config', 'pamLookupAutocomplete', {
      templateUrl: 'app/config/components/lookup-autocomplete/lookup-autocomplete.html',
      bindings: {
         inputName: '@',
         label: '@',
         lookupType: '@',
         placeholder: '@',
         required: '<',
         requireMatch: '<',
         value: '=',
      },
      require: {
         form: '^^form'
      }
   })
   class LookupAutocompleteComponent {
      static $inject = ['datacontext'];
      constructor(private datacontext: data.IDataContextService) {
         'ngInject';
      }

      public lookupType: string;
      public lookups: string[];

      private $onInit() {
         this.getLookups();
      }

      private getLookups = () => {
         return this.datacontext.config.lookups
            .getLookupType(this.lookupType)
            .then(result => this.lookups = _.map(result.lookups, r => r.value));
      }
   }
}