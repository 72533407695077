namespace app.entities {
   @Component('app.entities', 'ceEntityCaseListing', {
      templateUrl: 'app/entities/case-listing/case-listing.html',
      bindings: {
         entityId: '<'
      }
   })
   class CaseListingByEntity {
      static $inject = ['$mdDialog', 'common', 'datacontext'];
      constructor(
         private $mdDialog: angular.material.IDialogService,
         private common: core.ICommonService,
         private datacontext: data.IDataContextService) {
         'ngInject';
      }

      public cases: data.IPagedList<ICaseListing>;
      public entityId: string;

      public promise: angular.IPromise<any>;
      public paging: data.IPagedListParams = {
         page: 1,
         pageSize: 100
      };

      private $onChanges() {
         this.getCases();
      }

      private getCases = () => {
         if (!this.entityId) {
            return;
         }

         this.promise = this.datacontext.entities
            .getCaseListing(this.entityId, this.paging)
            .then(result => this.cases = result);
      }
   }
}