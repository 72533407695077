namespace app.activities {
   @Component('app.activities', 'ceActivitiesActivity', {
      templateUrl: 'app/activities/activity/activity.html',
      bindings: {
         involvementId: '<',
         source: '@',
         sourceId: '<',
         activityType: '@',
         name: '@',
         returnSref: '@'
      }
   })
   class Activity {
      static $inject = ['common', 'datacontext'];
      constructor(
         private common: core.ICommonService,
         private datacontext: data.IDataContextService) {
         'ngInject';
      }

      public involvementId: string;
      public source: string;
      public sourceId: string;
      public activityType: string;
      public name: string;
      public isSubmitting: boolean = false;
      public activity = {} as ICreateActivity;
      public returnSref: string = '^.list';

      private $onInit() {
         this.activity.involvementId = this.involvementId;
         this.activity.source = this.source;
         this.activity.sourceId = this.sourceId;
         this.activity.activityDate = moment().toDate();
         this.activity.activityType = this.activityType;
         this.activity.name = this.name;
         this.returnSref = this.returnSref || '^.list';
      }

      public submit() {
         this.isSubmitting = true;

         if (typeof this.activity.activityDate !== 'string') {
            this.activity.activityDate = this.activity.activityDate.toDateString();
         }

         this.datacontext.activities
            .createActivity(this.activity)
            .then(request => {
               if (request) {
                  this.common.$mdToast.showSimple(this.name + ' created');
                  this.common.$state.go(this.returnSref, { involvementId: this.involvementId, sourceId: this.sourceId });
               }
            })
            .finally(() => this.isSubmitting = false);
      }
   }
}