namespace app.alerts {
   @Component('app.alerts', 'ceNotificationPreferences', {
      bindings: {},
      templateUrl: 'app/alerts/views/notification-preferences/notification-preferences.html'
   })
   class NotificationPreferences {
      static $inject = ['$scope', '$mdDialog', 'common', 'datacontext'];
      constructor(
         private $scope: angular.IScope,
         private $mdDialog: angular.material.IDialogService,
         private common: core.ICommonService,
         private datacontext: data.IDataContextService
      ) {
         'ngInject';
         this.activate();
      }

      public isSubmitting = false;
      public isUploading = false;
      public photoId: string;

      public alertTypes: AlertType[] = ['CalendarEventScheduled', 'RequestAssigned', 'CaseAssigned', 'TrainingAssigned'];
      public alertTypeSettings: SettingOptions = 'any';
      public notificationPreferencesSettings: INotificationPreferencesSetting[] = [];
      public selectedAlertTypes: { [name: string]: boolean } = {};

      public cancel() {
         this.dismiss();
      }

      public clearAlertTypes(targetEvent: MouseEvent) {
         this.selectedAlertTypes = {};
      }

      public submit(form: angular.IFormController) {
         form.$setSubmitted();

         this.isSubmitting = true;
         const data: ISaveNotificationPreferencesData = {
            alertTypes: []
         };

         if (this.alertTypeSettings === 'specify') {
            data.alertTypes = _(this.selectedAlertTypes).keys().filter(x => this.selectedAlertTypes[x]).value() as AlertType[];
         }

         this.datacontext.alerts.savePreferences(data)
            .then(result => {
               if (result) {
                  this.$mdDialog.hide(result);
               }
            })
            .finally(() => this.isSubmitting = false);
      }

      private activate = () => {
         this.datacontext.alerts.getNotificationPreferences().then(result => this.notificationPreferencesSettings = result.settings || []).finally(() => {
            if (this.notificationPreferencesSettings.length >= 1) {
               this.alertTypeSettings = 'specify';
               _.forEach(this.notificationPreferencesSettings, t => this.selectedAlertTypes[t.value] = true);
            }
         });
      };

      private close = () => {
         this.$mdDialog.hide(this.photoId);
      };

      private dismiss = () => {
         this.$mdDialog.cancel();
      };
   }
}