namespace app.core.security {
   const interceptorName = 'authInterceptor';

   function interceptor($q: angular.IQService, $window: angular.IWindowService, configurationProvider: IConfigurationProvider, auth: any) {
      'ngInject';

      return {
         'request': (config: angular.IRequestConfig) => {
            return auth.loginSilent().then(() => {
               config.headers = config.headers || {};
               config.headers.Authorization = 'Bearer ' + auth.accessToken;
               return config;
            });
         },
         'responseError': (response: any) => {
            if (response.status === 401) {
               $window.location.replace('/');
            }

            let error: exception.IToastableException = Object.create(Error.prototype) as exception.IToastableException;
            if (response && response.data) {
               error.message = response.data.message;
            } else if (typeof (response) === 'string') {
               error.message = response as string;
            } else {
               error.message = 'Error';
            }
            error.name = 'XHR';
            error.status = response.status;
            error.statusText = response.statusText;
            error.xhrStatus = response.xhrStatus;

            if (response.suppressToast) {
               error.suppressToast = response.suppressToast;
            }

            return $q.reject(error);
         }
      };
   }

   function configure($httpProvider: angular.IHttpProvider) {
      'ngInject';
      $httpProvider.interceptors.push(interceptorName);
   }

   angular
      .module('app.data')
      .factory(interceptorName, interceptor)
      .config(configure);
}