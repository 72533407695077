namespace app.informationAndReferrals.funding {
    export const bypassEligibilityResolver = (datacontext: data.IDataContextService, $stateParams: angular.ui.IStateParamsService, common: core.ICommonService) => {
        'ngInject';
        const { informationAndReferralId } = $stateParams;
        const iandrPromise =  datacontext.informationAndReferrals.getInformationAndReferralBasicInfoView(informationAndReferralId);
        const forceBypassPromise = datacontext.config.getConfigurationParameter<boolean>('I&R.ForceBypassEligibility', true);
        return common.$q.all([iandrPromise, forceBypassPromise])
            .then(result => {
                const iandr = result[0];
                const forceBypass = result[1].toString() === 'true';
                const noClient = !iandr.clientPersonId;
                const noPWD = !iandr.pwdPersonId;
                return forceBypass || (noClient && noPWD);
            });
     };
 }