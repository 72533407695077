namespace app.persons {
   export const personResolver = (datacontext: data.IDataContextService, $stateParams: angular.ui.IStateParamsService) => {
      'ngInject';
      const { personId } = $stateParams;
      return datacontext.persons.getPerson(personId);
   };
   export const showAtPovertyLevelResolver = (datacontext: data.IDataContextService) => {
      'ngInject';
      const bypassReasonsPromise = datacontext.config.getConfigurationParameter<boolean>('Entities.AtPovertyLevel', false);
      return bypassReasonsPromise.then(result => {
         return result.toString() === 'true';
      });
   };
   export const showPovertyLevelResolver = (datacontext: data.IDataContextService) => {
      'ngInject';
      const bypassReasonsPromise = datacontext.config.getConfigurationParameter<boolean>('Entities.PovertyLevel', false);
      return bypassReasonsPromise.then(result => {
         return result.toString() === 'true';
      });
   };
   export const showRaceSubCategoryResolver = (datacontext: data.IDataContextService) => {
      'ngInject';
      const bypassReasonsPromise = datacontext.config.getConfigurationParameter<boolean>('Entities.RaceSubCategory', false);
      return bypassReasonsPromise.then(result => {
         return result.toString() === 'true';
      });
   };
}