namespace app.documentManagement {
   @Controller('app.documentManagement', 'RenameDocumentDialogController')
   class RenameDocumentDialogController {
      static $inject = ['common', '$mdDialog', 'datacontext', 'document'];
      constructor(
         private common: core.ICommonService,
         private $mdDialog: angular.material.IDialogService,
         private datacontext: data.IDataContextService,
         private document: IDocumentFolder) {
         'ngInject';
      }

      public data = {} as IRenameDocumentData;
      public isSubmitting = false;

      public submit() {
         this.isSubmitting = true;

         this.datacontext.documentManagement
            .renameDocument(this.data)
            .then(() => this.$mdDialog.hide())
            .finally(() => this.isSubmitting = false);
      }

      private $onInit() {
         this.data.documentId = this.document.id;
         this.data.title = this.document.name;
      }

      public cancel() {
         this.$mdDialog.cancel();
      }
   }
}