namespace app.informationAndReferrals.funding {
   const routes: core.routing.IStateExtended[] = [
      {
         name: 'app.informationAndReferrals.funding',
         url: '/funding',
         template: '<ce-funding involvement-id="$resolve.case.involvementId" layout="column" flex></ce-funding>',
         redirectTo: 'app.informationAndReferrals.funding.summary',
         data: { title: 'I&R Funding Summary' }
      },
      {
         name: 'app.informationAndReferrals.funding.summary',
         url: '/summary',
         template: '<ce-funding-summary involvement-id="$resolve.case.involvementId" layout="column" flex></ce-funding-summary>',
         data: { title: 'I&R Funding' }
      },
      {
         name: 'app.informationAndReferrals.funding.eligibility',
         url: '/eligibility',
         template: '<ui-view layout flex></ui-view>',
         redirectTo: 'app.informationAndReferrals.funding.eligibility.list',
         data: { title: 'I&R Funding' }
      },
      {
         name: 'app.informationAndReferrals.funding.eligibility.list',
         url: '/list',
         template: `<am-funding-eligibility-view
                    [involvement-id]="$resolve.case.involvementId"
                    source-type="informationAndReferrals"
                    layout="column" flex></am-funding-eligibility-view>`,
         data: { title: 'I&R Funding' }
      },
      {
         name: 'app.informationAndReferrals.funding.eligibility.create',
         url: '/create',
         template: '<ce-funding-eligibility-create involvement-id="$resolve.case.involvementId" source="I&R" bypass-eligibility-criteria="$resolve.bypass" layout="column" flex></ce-funding-eligibility-create>',
         data: { title: 'Create Eligibility' },
         resolve: { bypass: bypassEligibilityResolver }
      },
      {
         name: 'app.informationAndReferrals.funding.eligibility.cap',
         url: '/cap',
         params: { fundingProgramId: null },
         resolve: { fundingProgramId: ['$stateParams', p => p.fundingProgramId] },
         template: '<ce-cap-eligibility involvement-id="$resolve.case.involvementId" funding-program-id="$resolve.fundingProgramId" layout="column" flex></ce-cap-eligibility>',
         data: { title: 'CAP Eligibility' }
      },
      {
         name: 'app.informationAndReferrals.funding.eligibility.paat',
         url: '/paat',
         params: { fundingProgramId: null },
         resolve: { fundingProgramId: ['$stateParams', p => p.fundingProgramId] },
         template: '<ce-paat-eligibility involvement-id="$resolve.case.involvementId" funding-program-id="$resolve.fundingProgramId" layout="column" flex></ce-paat-eligibility>',
         data: { title: 'PAAT Eligibility' }
      },
      {
         name: 'app.informationAndReferrals.funding.eligibility.pabss',
         url: '/pabss',
         params: { fundingProgramId: null },
         resolve: { fundingProgramId: ['$stateParams', p => p.fundingProgramId] },
         template: '<ce-pabss-eligibility involvement-id="$resolve.case.involvementId" funding-program-id="$resolve.fundingProgramId" layout="column" flex></ce-pabss-eligibility>',
         data: { title: 'PABSS Eligibility' }
      },
      {
         name: 'app.informationAndReferrals.funding.eligibility.padd',
         url: '/padd',
         params: { fundingProgramId: null },
         resolve: { fundingProgramId: ['$stateParams', p => p.fundingProgramId] },
         template: '<ce-padd-eligibility involvement-id="$resolve.case.involvementId" funding-program-id="$resolve.fundingProgramId" layout="column" flex></ce-padd-eligibility>',
         data: { title: 'PADD Eligibility' }
      },
      {
         name: 'app.informationAndReferrals.funding.eligibility.paimi',
         url: '/paimi',
         params: { fundingProgramId: null },
         resolve: { fundingProgramId: ['$stateParams', p => p.fundingProgramId] },
         template: '<ce-paimi-eligibility involvement-id="$resolve.case.involvementId" funding-program-id="$resolve.fundingProgramId" layout="column" flex></ce-paimi-eligibility>',
         data: { title: 'PAIMI Eligibility' }
      },
      {
         name: 'app.informationAndReferrals.funding.eligibility.pair',
         url: '/pair',
         params: { fundingProgramId: null },
         resolve: { fundingProgramId: ['$stateParams', p => p.fundingProgramId] },
         template: '<ce-pair-eligibility involvement-id="$resolve.case.involvementId" funding-program-id="$resolve.fundingProgramId" layout="column" flex></ce-pair-eligibility>',
         data: { title: 'PAIR Eligibility' }
      },
      {
         name: 'app.informationAndReferrals.funding.eligibility.patbi',
         url: '/patbi',
         params: { fundingProgramId: null },
         resolve: { fundingProgramId: ['$stateParams', p => p.fundingProgramId] },
         template: '<ce-patbi-eligibility involvement-id="$resolve.case.involvementId" funding-program-id="$resolve.fundingProgramId" layout="column" flex></ce-patbi-eligibility>',
         data: { title: 'PATBI Eligibility' }
      },
      {
         name: 'app.informationAndReferrals.funding.enrollment',
         url: '/primary',
         template: '<ui-view layout flex></ui-view>',
         redirectTo: 'app.informationAndReferrals.funding.enrollment.list'
      },
      {
         name: 'app.informationAndReferrals.funding.enrollment.list',
         url: '/list',
         template: '<ce-primary-funding-source-listing involvement-id="$resolve.case.involvementId" layout="column" source="I&R" flex></ce-primary-funding-source-listing>',
         data: { title: 'Primary List' }
      },
      {
         name: 'app.informationAndReferrals.funding.enrollment.primary',
         url: '/primary',
         template: '<ce-create-primary-funding involvement-id="$resolve.case.involvementId" layout="column" flex></ce-create-primary-funding>',
         data: { title: 'Primary Funding' }
      },
      {
         name: 'app.informationAndReferrals.funding.enrollment.updatePrimary',
         url: '/updateprimary',
         params: { enrollment: null },
         resolve: { enrollment: ['$stateParams', p => p.enrollment] },
         template: '<ce-edit-primary-funding enrollment="$resolve.enrollment" layout="column" flex></ce-edit-primary-funding>',
         data: { title: 'Update Funding' }
      },
      {
         name: 'app.informationAndReferrals.funding.enrollment.pavaOutcome',
         url: '/pava-i-and-r-outcome/{enrollmentId}',
         template: '<ce-pava-i-and-r-outcome enrollment-id="$resolve.enrollmentId" layout="column" flex></ce-pava-i-and-r-outcome>',
         params: { enrollmentId: null },
         resolve: { enrollmentId: ['$stateParams', p => p.enrollmentId] },
         data: { title: 'PAVA Outcome' }
      },
      {
         name: 'app.informationAndReferrals.funding.enrollment.paddOutcome',
         url: '/padd-i-and-r-outcome/{enrollmentId}',
         template: '<ce-padd-i-and-r-outcome enrollment-id="$resolve.enrollmentId" layout="column" flex></ce-padd-i-and-r-outcome>',
         params: { enrollmentId: null },
         resolve: { enrollmentId: ['$stateParams', p => p.enrollmentId] },
         data: { title: 'PADD Outcome' }
      },
      {
         name: 'app.informationAndReferrals.funding.enrollment.capOutcome',
         url: '/cap-i-and-r-outcome/{enrollmentId}',
         template: '<ce-cap-i-and-r-outcome enrollment-id="$resolve.enrollmentId" layout="column" flex></ce-cap-i-and-r-outcome>',
         params: { enrollmentId: null },
         resolve: { enrollmentId: ['$stateParams', p => p.enrollmentId] },
         data: { title: 'CAP Outcome' }
      },
      {
         name: 'app.informationAndReferrals.funding.enrollment.paatOutcome',
         url: '/paat-i-and-r-outcome/{enrollmentId}',
         template: '<ce-paat-i-and-r-outcome enrollment-id="$resolve.enrollmentId" layout="column" flex></ce-paat-i-and-r-outcome>',
         params: { enrollmentId: null },
         resolve: { enrollmentId: ['$stateParams', p => p.enrollmentId] },
         data: { title: 'PAAT Outcome' }
      },
      {
         name: 'app.informationAndReferrals.funding.enrollment.patbiOutcome',
         url: '/patbi-i-and-r-outcome/{enrollmentId}',
         template: '<ce-patbi-i-and-r-outcome enrollment-id="$resolve.enrollmentId" layout="column" flex></ce-patbi-i-and-r-outcome>',
         params: { enrollmentId: null },
         resolve: { enrollmentId: ['$stateParams', p => p.enrollmentId] },
         data: { title: 'PATBI Outcome' }
      },
      {
         name: 'app.informationAndReferrals.funding.secondary',
         url: '/secondary',
         template: '<ui-view flex></ui-view>',
         redirectTo: 'app.informationAndReferrals.funding.secondary.view',
         data: { title: 'Secondary Funding' }
      },
      {
         name: 'app.informationAndReferrals.funding.secondary.edit',
         url: '/edit',
         params: { fundingProgramId: null },
         resolve: { fundingProgramId: ['$stateParams', p => p.fundingProgramId] },
         template: '<ce-edit-secondary-funding involvement-id="$resolve.case.involvementId" funding-program-id="$resolve.fundingProgramId" layout="column" flex></ce-edit-secondary-funding>',
         data: { title: 'Secondary Funding' }
      },
      {
         name: 'app.informationAndReferrals.funding.secondary.view',
         url: '/views',
         template: '<ce-secondary-funding-view involvement-id="$resolve.case.involvementId" layout="column" flex></ce-secondary-funding-view>',
         data: { title: 'Secondary Funding' }
      }
   ];

   const runner = (routingHelper: core.routing.IRoutingHelperService) => {
      'ngInject';
      routingHelper.configureRoutes(routes);
   };

   angular
      .module('app.informationAndReferrals.funding')
      .run(runner);
}