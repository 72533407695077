namespace app.grievance {
   @Controller('app.grievance', 'GrievanceDialogController')
   class GrievanceDialogController {
      static $inject = ['$mdDialog', 'common', 'datacontext', 'grievanceId', 'aCase'];
      constructor(
         private $mdDialog: angular.material.IDialogService,
         private common: core.ICommonService,
         private datacontext: data.IDataContextService,
         private grievanceId: string,
         private aCase: ICase) {
         'ngInject';
      }

      public grievance = {} as IGrievance;
      public cases: ICase[];
      public selectedCase: ICase;
      public fundingPrograms: funding.IFundingProgram[];
      public isSubmitting: boolean;
      public grievantSearchText: string;
      public selectedGrievant: persons.IPerson;
      public selectedStatus: string;
      public grievants: persons.IPerson[];
      public maxDate: Date;

      private $onInit() {
         this.maxDate = moment().toDate();

         if (this.aCase) {
            this.selectedCase = this.aCase;

            this.getGrievants();
            this.getFundingPrograms();
         }

         if (this.grievanceId) {
            this.getGrievance()
               .then(() => {
                  if (this.grievance) {
                     this.selectedCase = {
                        id: this.grievance.caseId,
                        caseType: this.grievance.caseType,
                        involvementId: this.grievance.involvementId,
                        caseIdNumber: this.grievance.caseIdNumber
                     } as ICase;
                     this.setGrievant({
                        id: this.grievance.personId,
                        fullName: this.grievance.grievantFullName
                     } as persons.IPerson);
                  }
               });
         } else {
            this.grievance.fileDate = moment().startOf('day').toDate();
            this.grievance.status = 'Pending';
         }
      }

      private getGrievance() {
         return this.datacontext.grievance
            .getGrievance(this.grievanceId)
            .then(result => this.grievance = result);
      }

      public cancel() {
         this.$mdDialog.cancel();
      }

      private getCases() {
         this.cases = [];

         if (!this.selectedGrievant) {
            return;
         }

         this.datacontext.grievance
            .getCases(this.selectedGrievant.id)
            .then(results => this.cases = results);
      }

      private onCaseChange() {
         if (this.selectedCase && this.selectedCase.id !== this.grievance.caseId) {
            this.grievance.fundingProgramId = null;
         }

         this.getFundingPrograms();
      }

      public getFundingPrograms() {
         this.fundingPrograms = [];

         if (!this.selectedCase) {
            return;
         }

         this.datacontext.funding
            .getFundingProgramsBySource(moment().toDate().toDateString(), this.selectedCase.caseType, null, this.selectedCase.involvementId)
            .then(results => this.fundingPrograms = results);
      }

      public grievantSearch(searchText: string) {
         const params: persons.IGetPersonsParams = { q: searchText };

         return this.datacontext.persons
            .getPersons(params)
            .then(results => {
               return results.list;
            });
      }

      private onGrievantChange(grievant: persons.IPerson) {
         if (this.grievanceId && this.selectedGrievant && grievant && this.selectedGrievant.id === grievant.id) {
            return;
         }

         this.selectedCase = null;
         this.grievance.fundingProgramId = null;

         this.setGrievant(grievant);
      }

      private getGrievants() {
         this.grievants = [];

         this.datacontext.involvements
            .getEntityListing(this.selectedCase.involvementId)
            .then(results => {
               results = results.filter(r => r.entityType === 'Person' && !r.isUser);
               this.grievants = results.map(r => { return { id: r.entityId, fullName: r.fullName } as persons.IPerson; });
            });
      }

      public setGrievant(grievant: persons.IPerson) {
         this.selectedGrievant = grievant;

         if (!this.aCase) {
            this.getCases();
            this.getFundingPrograms();
         }
      }

      public submit() {
         this.isSubmitting = true;

         const data = {
            ...this.grievance,
            fileDate: this.grievance.fileDate.toDateString(),
            escalationDate: this.grievance.escalationDate ? this.grievance.escalationDate.toDateString() : null,
            replyDueDate: this.grievance.replyDueDate ? this.grievance.replyDueDate.toDateString() : null,
            actualReplyDate: this.grievance.actualReplyDate ? this.grievance.actualReplyDate.toDateString() : null,
            personId: this.selectedGrievant.id,
            involvementId: this.selectedCase.involvementId,
            caseId: this.selectedCase.id,
            caseType: this.selectedCase.caseType,
            status: this.selectedStatus
         } as ISaveGrievanceData;

         const promise = this.grievanceId
            ? this.datacontext.grievance.updateGrievance(this.grievanceId, data)
            : this.datacontext.grievance.createGrievance(data);

         promise
            .then(() => {
               this.common.$mdToast.showSimple('Grievance ' + (this.grievanceId ? 'updated' : 'created'));
               this.$mdDialog.hide();
            })
            .finally(() => this.isSubmitting = false);
      }
   }
}