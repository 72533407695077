namespace app.funding {
   @Component('app.funding', 'ceSecondaryFundingView', {
      templateUrl: 'app/funding/secondary/view/view.html',
      bindings: {
         involvementId: '<'
      }
   })
   class SecondaryFundingView {
      static $inject = ['$mdDialog', 'common', 'datacontext'];
      constructor(
         private $mdDialog: angular.material.IDialogService,
         private common: core.ICommonService,
         private datacontext: data.IDataContextService) {
         'ngInject';
      }

      private showDeleteDialog() {
         this.common.confirmation.show({
            title: 'Delete Secondary Funding Source?',
            message: 'Are you sure you want to delete this Funding Source?',
            ok: 'Delete',
            cancel: 'Keep'
         }).then(() => this.deleteSecondaryFunder(this.involvementId));
      }

      private deleteSecondaryFunder(id: string) {
         return this.datacontext.funding.deleteSecondaryFundingSource(id)
            .then(() => this.common.$state.reload())
            .then(() => this.common.$state.go('^'));
      }

      public fundingSummary: IFundingSummaryView;
      public involvementId: string;

      public $onInit = () => {
         this.getFundingSummaryView();
      }

      private getFundingSummaryView = () => {
         return this.datacontext.funding
            .getFundingSummaryView(this.involvementId)
            .then(result => {
               this.fundingSummary = result;
            });
      };
   }
}