namespace app.core.security {
   const directive: angular.IDirectiveFactory = (auth: IAuthorizationService) => {
      'ngInject';

      const applyPermissions = (elem: angular.IAugmentedJQuery, permission: string[], enforceAll: boolean = true) => {
         if (permission) {
            const success = enforceAll
               ? auth.checkPermissions(...permission)
               : auth.checkPermissionsAny(...permission);

            if (!success) {
               elem.hide();
            }
         }
      };

      return {
         restrict: 'A',
         link: (scope: angular.IScope, elem: angular.IAugmentedJQuery, attrs: any) => {
            const permissions = scope.$eval(attrs.permission);
            if (permissions.constructor === Array) {
               applyPermissions(elem, permissions);
            }
            else {
               applyPermissions(elem, permissions.permissions, permissions.enforceAll);
            }
         }
      };
   };

   angular
      .module('app.core.security')
      .directive('permission', directive);
}