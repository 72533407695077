namespace app.time {
   @Component('app.time', 'amTimeslipButton', {
      template: `
      <md-button ng-click="$ctrl.openTimeslipDialog()" permission="['timeslips.edit']">
        <md-tooltip>Add Timeslip</md-tooltip>
        <md-icon aria-hidden="true">add</md-icon>
      </md-button>`,
      bindings: {
         refresh: '&'
      }
   })
   class TimeslipButtonComponent {
      static $inject = ['$mdDialog', '$scope'];
      constructor(
         private $mdDialog: angular.material.IDialogService,
         private $scope: angular.IScope) {
         'ngInject';
      }

      private refresh: () => {};
      private openTimeslipDialog() {
         const parent = angular.element(document.body);
         // not adding a .catch to dialogs causes possibly unhandled rejection error
         return this.$mdDialog.show({
            parent,
            fullscreen: true,
            templateUrl: 'app/time/timeslip-dialog/timeslip-dialog.html',
            controller: 'TimeslipDialogController',
            controllerAs: '$ctrl',
            focusOnOpen: false,
            locals: {
               id: null,
               involvementId: null,
               hours: null,
               source: null,
               sourceId: null,
               sourceName: null,
               date: moment().toDate(),
               fundingProgramId: null,
               sourceName2: null
            }
         })
            .catch((error) => { if (error) throw error; })
            .finally(() => { this.refresh(); });
      }
   }
}
