namespace app.persons.views {
   @Component('app.persons.views', 'ceCallerInformationForm', {
      templateUrl: 'app/persons/views/caller-information/caller-information-form.html',
      bindings: {
         personId: '<',
         onCancel: '&',
         onSubmit: '&'
      }
   })
   class CallerInformationForm {
      static $inject = ['common', 'datacontext'];
      constructor(
         private common: core.ICommonService,
         private datacontext: data.IDataContextService) {
         'ngInject';
      }
      public isLoading: boolean = false;
      public personId: string;
      public data = {} as IDemographics;
      public incomeSources: app.config.ILookup[];
      public isSubmitting: boolean = false;
      public onSubmit: (args: { result: IDemographics }) => void;

      public $onInit() {
         this.getData();
      }

      public getData() {
         this.isLoading = true;

         const getClientData =
            this.datacontext.persons
               .getDemographics(this.personId)
               .then(result => this.data = result)
               .finally(() => this.isLoading = false);
      }

      public submit() {
         this.isSubmitting = true;

         if (this.data.highestUnmetLegalNeed !== 'Other') {
            this.data.highestUnmetLegalNeedOther = null;
         }
         if (this.data.communicationPreference !== 'Other') {
            this.data.communicationPreferenceOther = null;
         }

         const updateCallerInformation = this.datacontext.persons
            .updateDemographics(this.personId, this.data).then((result) => {
               if (result) this.onSubmit({ result });
            }).finally(() => this.isSubmitting = false);
      }
   }
}