namespace app.preferences {
   export interface IPreferenceService {
      favorites?: IFavorite[];
      save(): void;
      loadPreferences(): angular.IPromise<void>;
      initialize(): void;
   }

   const defaultState: IPreferences = {
      favorites: []
   };

   @Service('app.preferences', 'preferenceService')
   class PreferenceService implements IPreferenceService {
      static $inject = ['datacontext'];
      constructor(
         private datacontext: data.IDataContextService) {
         'ngInject';
         this.initialize();
      }

      private state: IPreferences;

      get favorites(): IFavorite[] {
         return this.state.favorites;
      }

      public initialize() {
         this.state = defaultState;
         this.loadPreferences();
      }

      public loadPreferences() {
         return this.datacontext.preferences
            .getPreferences()
            .then(result => {
               if (result) {
                  this.state = angular.extend({}, defaultState, result);
               } else {
                  this.state = defaultState;
               }
            });
      }

      public save() {
         this.datacontext.preferences
            .savePreferences(this.state);
      }
   }
}