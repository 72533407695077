namespace app.entities {
   @Component('app.entities', 'ceSelectEntityType', {
      templateUrl: 'app/entities/select-type/select-type.html',
      bindings: {
      }
   })
   class SelectEntityType {
      static $inject = ['common', 'datacontext'];
      constructor(
         private common: core.ICommonService,
         private datacontext: data.IDataContextService) {
         'ngInject';
      }
   }
}