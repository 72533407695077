namespace app.systemicCases.closure {
   @Component('app.systemicCases.closure', 'ceSystemicCaseClosureInformationForm', {
      templateUrl: 'app/systemic-cases/closure/closure-information/closure-information-form.html',
      bindings: {
         caseId: '<',
         onCancel: '&',
         onSubmit: '&'
      }
   })
   class SystemicCaseClosureInformationForm {
      static $inject = ['common', 'datacontext'];
      constructor(
         private common: core.ICommonService,
         private datacontext: data.IDataContextService) {
         'ngInject';
      }
      public onSubmit: (args: { result: ISystemicCaseClosureInformation }) => void;
      public data: ISystemicCaseClosureInformation;
      public isLoading: boolean = false;
      public isSubmitting: boolean = false;
      public caseId: string;

      public $onInit() {
         this.getData();
      }

      public getData() {
         this.isLoading = true;
         this.datacontext.systemicCases
            .getClosureInformation(this.caseId)
            .then(result => this.data = result)
            .finally(() => this.isLoading = false);
      }

      public submit() {
         this.isSubmitting = true;

         if (!this.data.monetarySettlement) {
            this.data.monetarySettlementAmount = null;
         }

         this.datacontext.systemicCases
            .updateClosureInformation(this.data)
            .then(result => {
               if (result) {
                  this.common.$mdToast.showSimple('Remember to Complete Survey');
                  this.onSubmit({ result });
               }
            }).finally(() => this.isSubmitting = false);
      }
   }
}