namespace app.funding {
   @Component('app.funding', 'ceEditPrimaryFunding', {
      templateUrl: 'app/funding/enrollments/edit/edit.html',
      bindings: {
         enrollment: '<'
      }
   })
   class EditPrimaryFunding {
      static $inject = ['common', 'datacontext'];
      constructor(
         private common: core.ICommonService,
         private datacontext: data.IDataContextService) {
         'ngInject';
      }

      public fundingPrograms: IEnrollmentEligibilityView[];
      public enrollment: IEnrollment;
      public isLoading: boolean = true;
      public maxDate: Date = moment().toDate();

      public $onInit = () => {
         this.getFundingSources();
      }

      private getFundingSources = () => {
         this.isLoading = true;
         this.datacontext.funding
            .getEnrollmentEligibilityView(this.enrollment.involvementId)
            .then(result => {
               this.fundingPrograms = result;
               this.isLoading = false;
            }
            );
      }

      public submit() {
         if (typeof this.enrollment.date !== 'string') {
            this.enrollment.date = this.enrollment.date.toDateString();
         }

         this.datacontext.funding.updateEnrollment(this.enrollment)
            .then(result => {
               this.common.$state.go('^.');
            });
      }
   }
}