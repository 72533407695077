namespace app.filters {
   @Component('app.filters', 'ceDateFilter', {
      templateUrl: 'app/filters/date/date-filter.html',
      bindings: {
         onChange: '&',
         enabled: '<?',
         fromDate: '<',
         toDate: '<'
      }
   })
   class DateFilter {
      static $inject = ['common', 'datacontext'];
      constructor(
         private common: core.ICommonService,
         private datacontext: data.IDataContextService) {
         'ngInject';
      }

      private fromDate: Date;
      private toDate: Date;
      private onChange: ({ }) => {};
      private enabled: boolean;

      private $onInit() {
         this.dateChanged();
         if (this.enabled === undefined) {
            this.enabled = false;
         }
      }

      private toggleState() {
         this.enabled = !this.enabled;

         if (this.enabled) {
            angular.element('#fromDate').focus();
         }

         this.dateChanged();
      }

      public dateChanged() {
         if (this.enabled) {
            this.onChange({ dates: { fromDate: this.fromDate, toDate: this.toDate } });
         }
         else {
            this.onChange({ dates: { fromDate: null, toDate: null } });
         }
      }
   }
}