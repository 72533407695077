namespace app.time {
   @Controller('app.time', 'TimeslipDialogController')
   class TimeslipDialogController {
      static $inject = ['$scope', '$mdDialog', 'common', 'datacontext', 'id', 'hours', 'source', 'sourceId', 'sourceName', 'involvementId', 'fundingProgramId', 'date', 'sourceName2'];
      constructor(
         private $scope: angular.IScope,
         private $mdDialog: angular.material.IDialogService,
         private common: core.ICommonService,
         private datacontext: data.IDataContextService,
         private id: string,
         public hours: number,
         private source: string,
         private sourceId: string,
         private sourceName: string,
         private involvementId: string,
         private fundingProgramId: string,
         private date: Date,
         private sourceName2: string = '') {
         'ngInject';
      }

      public timeslip = {} as ITimeslip;
      public fundingPrograms: funding.IFundingProgram[];
      public isSubmitting: boolean;
      public maxDate: Date;
      public minDate?: Date;
      public taskSearchText: string;
      public keepOpen: boolean;
      public keepHoursTaskDescription: boolean;
      public keepSource: boolean;
      public sourceProvided: boolean;
      public selectedTask: ITask;
      public selectedSource: common.ISource;
      public sourceDescription: string;
      public defaultFundingProgramId: string;
      public originalFundingProgramId: string;
      public currentTotal: number;

      private $onInit() {
         this.timeslip.date = new Date(this.date.getFullYear(), this.date.getMonth(), this.date.getDate()) || moment().startOf('day').toDate();
         this.getCurrentTotal(this.timeslip.date);
         this.timeslip.hours = this.hours;
         this.defaultFundingProgramId = this.fundingProgramId;
         this.maxDate = moment().add(1, 'years').toDate();

         if (!this.common.auth.checkPermissions('timeslips.unlock')) {
            this.getLastLock();
         }

         if (this.id) {
            this.loadTimeslip();
         }
         else if (this.source) {
            this.sourceProvided = true;
            this.sourceDescription = this.sourceName + ' ' + this.sourceName2;
            this.setSource({
               source: this.source,
               sourceId: this.sourceId,
               involvementId: this.involvementId,
               sourceDescription: this.sourceDescription
            });
         } else {
            this.getFundingPrograms();
         }

         setTimeout(this.setDefaultField, 100);
      }

      private onSourceSelected(source: common.ISource) {
         this.selectedSource = source;
         this.getFundingPrograms();
      }

      private setDefaultField = () => {
         let hours = angular.element(document).find('#hours');
         if (!this.hours) {
            hours.focus();
         }
      }

      private getLastLock() {
         this.datacontext.time
            .getLastLock()
            .then(result => {
               if (result.lockDate) {
                  this.minDate = moment(result.lockDate).add(1, 'days').toDate();
               }
            });
      }

      public loadTimeslip() {
         this.datacontext.time.getTimeslip(this.id)
            .then((timeslip) => {
               this.timeslip = timeslip;
               this.originalFundingProgramId = timeslip.fundingProgramId;
               this.setSourceFromTimeslip(timeslip);
               this.setTask({ id: timeslip.taskId, name: timeslip.taskName });
            });
      }

      public cancel() {
         this.$mdDialog.cancel();
      }

      private getCurrentTotal(date: Date | string) {
         if (typeof date !== 'string') date = date.toDateString();
         this.datacontext.time.getCurrentTotal(this.common.auth.user.id, date)
            .then((currentTotal) => {
               this.currentTotal = currentTotal.hours;
            });
      }

      private getFundingPrograms() {
         this.fundingPrograms = [];
         // we are setting this to null because this fundingProgramId may no longer be valid
         // users could have deleted the fundingprogram from the Source, so we need to force them to pick a new one
         this.timeslip.fundingProgramId = null;

         if (!this.selectedSource) {
            return;
         }

         this.datacontext.funding
            .getFundingProgramsBySource(this.timeslip.date, this.selectedSource.source, this.selectedSource.sourceId, this.selectedSource.involvementId)
            .then(results => {
               if (results && results.length > 0) {
                  this.fundingPrograms = [...results];
                  let validFundingProgramFound: boolean;

                  //  does the timeslip have a valid fundingprogramid, if so then set it
                  let validTimeslipFundingProgram = _.find(this.fundingPrograms, { 'fundingProgramId': this.originalFundingProgramId });
                  if (validTimeslipFundingProgram) {
                     this.timeslip.fundingProgramId = validTimeslipFundingProgram.fundingProgramId;
                     validFundingProgramFound = true;
                  }

                  // if the timeslip did not have a valid fundingprogramid, see if a valid default funding program was passed in
                  let validDefaultFundingProgram = _.find(this.fundingPrograms, { 'fundingProgramId': this.defaultFundingProgramId });
                  if (this.timeslip.fundingProgramId === null && validDefaultFundingProgram) {
                     this.timeslip.fundingProgramId = validDefaultFundingProgram.fundingProgramId;
                     validFundingProgramFound = true;
                  }

                  if (this.source === 'Request') {
                     // if the source is a request set it to null so the user has to make a selection
                     this.timeslip.fundingProgramId = null;
                  } else if (!validFundingProgramFound) {
                     // if we haven't been passed in any valid funding programs, then just set it to the first value in the list
                     this.timeslip.fundingProgramId = this.fundingPrograms[0].fundingProgramId;
                  }
               }
            }).then(() => {
               if (this.selectedSource && this.selectedSource.source === 'Project' && !this.defaultFundingProgramId) {
                  this.datacontext.projects.getProject(this.selectedSource.sourceId).then(
                     project => {
                        this.timeslip.fundingProgramId = project.fundingProgramId;
                     }
                  );
               }
            });
      }

      public dateChanged() {
         this.getFundingPrograms();
         this.getCurrentTotal(this.timeslip.date as Date | string);
      }

      public setSourceFromTimeslip(slip: ITimeslip) {
         this.source = slip.source;
         this.sourceId = slip.sourceId;
         this.involvementId = slip.involvementId;
         this.sourceDescription = `${slip.sourceName} ${slip.sourceName2 || ''}`;
         this.setSource({ source: this.source, sourceId: this.sourceId, involvementId: this.involvementId });
      }

      public setSource(source: common.ISource) {
         this.selectedSource = source;
         this.getFundingPrograms();
      }

      public taskSearch(searchText: string) {
         return this.datacontext.time
            .getTasks(searchText);
      }

      public setTask(task: app.time.ITask) {
         this.selectedTask = task;
      }

      public submit() {
         this.isSubmitting = true;
         angular.extend(this.timeslip, { source: this.selectedSource.source, sourceId: this.selectedSource.sourceId, involvementId: this.selectedSource.involvementId });
         angular.extend(this.timeslip, { taskId: this.selectedTask.id });

         if (typeof this.timeslip.date !== 'string') {
            this.timeslip.date = this.timeslip.date.toDateString();
         }

         let timePromise = this.id ?
            this.datacontext.time.updateTimeslip(this.timeslip) :
            this.datacontext.time.createTimeslip(this.timeslip);

         timePromise.then(timeslipId => {
            if (timeslipId) {
               this.common.$mdToast.showSimple('Timeslip ' + (this.id ? 'updated' : 'created'));
               this.submitted();
            }
         })
            .finally(() => this.isSubmitting = false);
      }

      public toggleKeepOpen() {
         if (this.keepOpen === false) {
            this.keepHoursTaskDescription = false;
            this.keepSource = false;
         }
      }

      public toggleKeepOpenChild() {
         if (this.keepHoursTaskDescription === true || this.keepSource === true) {
            this.keepOpen = true;
         }
      }

      public reset() {
         this.timeslip.id = null;

         if (this.keepHoursTaskDescription !== true) {
            this.timeslip.hours = null;
            this.selectedTask = null;
            this.timeslip.description = null;
            angular.element(document).find('#hours').focus();
         } else {
            angular.element(document).find('#sourceSearch').focus();
         }


         if (!this.sourceProvided && !this.keepSource) {
            this.selectedSource = null;
            this.$scope.$broadcast('ResetSourceSearch');
         }
      }

      public submitted() {
         this.getCurrentTotal(this.timeslip.date as Date | string);

         if (!this.keepOpen) {
            this.$mdDialog.hide(this.timeslip);
         }
         else {
            this.reset();
         }
      }
   }
}