namespace app.analysis {
   const routes: core.routing.IStateExtended[] = [
      {
         name: 'app.analysis',
         url: '/analysis',
         template: '<ui-view layout flex></ui-view>',
         params: { route: null },
         redirectTo: 'app.analysis.routing',
      },
      {
         name: 'app.analysis.routing',
         url: '/routing',
         controller: 'AnalysisRouteController'
      },
      {
         name: 'app.analysis.dashboard',
         url: '/dashboard',
         template: '<aims-analysis-dashboard layout="column" flex dashboard="$resolve.dashboard"><aims-analysis-dashboard>',
         data: { title: 'Analysis', permissions: ['analysis.view'], },
         resolve: {
            dashboard: dashboardResolver
         }
      },
      {
         name: 'app.analysis.queries',
         url: '/queries',
         template: '<aims-analysis-query-list layout flex></aims-analysis-query-list>',
         data: {
            title: 'Queries (Analysis)',
            permissions: ['analysis.view']
         }
      },
      {
         name: 'app.analysis.reports',
         url: '/reports',
         template: '<ce-report-listing layout=column flex></ce-report-listing>',
         data: {
            title: 'Reports',
            permissions: ['reports.view']
         },
      },
      {
         name: 'app.analysis.manageQuery',
         url: '/queries/manage',
         template: '<ce-analysis-manage-query layout flex data-data-sources="$resolve.dataSources" query-id="$resolve.queryId"></ce-analysis-manage-query>',
         data: {
            title: 'Manage Query (Analysis)',
            permissions: ['queries.edit']
         },
         params: { queryId: null },
         resolve: {
            dataSources: dataSourcesResolver,
            queryId: ['$stateParams', p => p.queryId]
         }
      },
      {
         name: 'app.analysis.query',
         url: '/queries/{queryId}',
         template: '<aims-analysis-query-details layout="column" flex query="$resolve.query"></aims-analysis-query-details>',
         redirectTo: 'app.analysis.query.data',
         resolve: {
            query: queryResolver
         }
      },
      {
         name: 'app.analysis.query.data',
         url: '',
         template: '<aims-analysis-query-data layout="column" flex query="$resolve.query"></aims-analysis-query-data>',
         data: {
            title: 'Query Data (Analysis)',
            permissions: ['analysis.view']
         },
      },
      {
         name: 'app.analysis.query.charts',
         url: '/charts',
         template: '<aims-analysis-query-charts layout="column" flex query="$resolve.query" charts="$resolve.charts"></aims-analysis-query-charts>',
         data: {
            title: 'Query Charts (Analysis)',
            permissions: ['analysis.view']
         },
         resolve: {
            charts: chartsResolver
         }
      },
      {
         name: 'app.analysis.createChart',
         url: '/queries/{queryId}/charts/create',
         template: '<aims-analysis-create-chart layout flex query="$resolve.query"></aims-analysis-create-chart>',
         data: {
            title: 'Create Chart (Analysis)',
            permissions: ['queries.edit']
         },
         resolve: {
            query: queryResolver
         }
      }
   ];

   const runner = (routingHelper: core.routing.IRoutingHelperService) => {
      'ngInject';
      routingHelper.configureRoutes(routes);
   };

   angular
      .module('app.analysis')
      .run(runner);
}