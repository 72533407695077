namespace app.entities.summary {
   @Component('app.entities.summary', 'ceEntityContactSummary', {
      templateUrl: 'app/entities/summary/contact-info/contact-info.html',
      bindings: {
         entity: '<'
      }
   })
   class PersonContact {
      static $inject = ['datacontext'];
      constructor(
         private datacontext: data.IDataContextService) {
         'ngInject';
      }

      public entity: IEntityBasicInfo;
      public data: IEntityContactInfo;

      public $onInit() {
         this.datacontext.entities.getContactInfo(this.entity.id)
            .then((result) => this.data = result);
      }
   }
}