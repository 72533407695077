namespace app.projects.overview {
   @Component('app.projects.overview', 'ceProjectOverview', {
      templateUrl: 'app/projects/overview/overview.html',
      bindings: {
         project: '<'
      }
   })
   class ProjectDetails {
      static $inject = ['$mdDialog', 'common', 'datacontext'];
      constructor(
         private $mdDialog: angular.material.IDialogService,
         private common: core.ICommonService,
         private datacontext: data.IDataContextService) {
         'ngInject';
      }

      public $onInit() {
      }

      private project: IProjectView;
   }
}