namespace app.mail {
   @Component('app.mail', 'ceEmailView', {
      templateUrl: 'app/mail/email/email-view.html',
      bindings: {
         messageId: '<'
      }
   })
   class EmailView {
      static $inject = ['datacontext', 'common', 'blobHelper'];
      constructor(
         private datacontext: data.IDataContextService,
         private common: core.ICommonService,
         private blobHelper: utils.IBlobHelperService) {
         'ngInject';
      }

      public messageId: string;
      public message: IMessage;
      public isSubmitting = false;

      private $onInit() {
         this.datacontext.mail
            .getMessage(this.messageId)
            .then((result) => this.message = result);
      }

      public onDownload(attachment: IAttachment) {
         this.datacontext.mail
            .downloadAttachment(this.message.id, attachment.id)
            .then(result => this.blobHelper.open(result));
      }

      public onCopy(attachment: IAttachment) {
         this.isSubmitting = true;

         const data = {
            attachmentIds: [attachment.id],
            involvementId: this.message.involvementId,
            sourceId: this.message.sourceId,
            source: this.message.source
         } as IImportAttachmentsData;

         this.datacontext.mail
            .importAttachments(data)
            .then(result => {
               if (result) {
                  attachment.documentId = attachment.id;
                  this.common.$mdToast.showSimple('Attachment copied');
               }
            }).finally(() => this.isSubmitting = false);
      }
   }
}