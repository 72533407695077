namespace app.requests.details {
   @Component('app.requests.details', 'ceRequestInformationForm', {
      templateUrl: 'app/requests/details/request-information/request-information-form.html',
      bindings: {
         requestId: '<',
         onCancel: '&',
         onSubmit: '&'
      }
   })
   class RequestInformationForm {
      static $inject = ['datacontext'];
      constructor(private datacontext: data.IDataContextService) {
         'ngInject';
      }
      public onSubmit: (args: { result: IRequestInformation }) => void;
      public data: IRequestInformation;
      public isLoading: boolean = false;
      public isSubmitting: boolean = false;
      public requestId: string;
      public legalTeams: app.config.ILegalTeam[];

      public $onInit() {
         this.datacontext.config.legalTeams.getLegalTeams(true).then(
            result => this.legalTeams = result
         );
         this.getData();
      }

      public getData() {
         this.isLoading = true;
         this.datacontext.requests
            .getRequestInformation(this.requestId)
            .then(result => this.data = result)
            .finally(() => this.isLoading = false);
      }

      public submit() {
         this.isSubmitting = true;
         this.datacontext.requests
            .updateRequestInformation(this.data)
            .then(result => {
               if (result) this.onSubmit({ result });
            }).finally(() => this.isSubmitting = false);
      }
   }
}