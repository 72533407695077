namespace app.mail {
   const routes: core.routing.IStateExtended[] = [
      {
         name: 'app.mail',
         url: '/mail',
         template: '<ui-view layout="column" flex></ui-view>',
         redirectTo: 'app.mail.inbox'
      },
      {
         name: 'app.mail.inbox',
         url: '',
         template: '<ce-inbox-view layout="column" flex></ce-inbox-view>',
         data: {
            title: 'Inbox',
            permissions: ['mail.link']
         }
      },
      // {
      //   name: 'app.mail.importAttachments',
      //   url: '/import?messageIds',
      //   template: '<ce-import-attachments-view layout="column" flex message-ids="$resolve.messageIds"></ce-import-attachments-view>',
      //   data: { title: 'Import Attachments',
      //   resolve: {
      //      messageIds: ['$stateParams', p => p.messageIds]
      //   }
      // },
      {
         name: 'app.mail.linkMessages',
         url: '/link?messageIds',
         template: '<ce-link-messages-view layout="column" flex message-ids="$resolve.messageIds"></ce-link-messages-view>',
         data: {
            title: 'Link Messages',
            permissions: ['mail.link']
         },
         resolve: {
            messageIds: ['$stateParams', p => p.messageIds]
         }
      },
      {
         name: 'app.mail.message',
         url: '/{messageId}',
         template: '<ce-email-view layout="column" flex message-id="$resolve.messageId"></ce-email-view>',
         data: {
            title: 'Message',
            permissions: ['mail.view']
         },
         resolve: {
            messageId: ['$stateParams', p => p.messageId]
         }
      }
   ];

   const runner = (routingHelper: core.routing.IRoutingHelperService) => {
      'ngInject';
      routingHelper.configureRoutes(routes);
   };

   angular
      .module('app.mail')
      .run(runner);
}