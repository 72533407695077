namespace app.filters {
   @Component('app.filters', 'ceAmountFilter', {
      templateUrl: 'app/filters/amount/amount-filter.html',
      bindings: {
         onChange: '&',
         enabled: '<?',
         fromAmount: '<',
         toAmount: '<'
      }
   })
   class AmountFilter {
      static $inject = ['common', 'datacontext'];
      constructor(
         private common: core.ICommonService,
         private datacontext: data.IDataContextService) {
         'ngInject';
      }

      private fromAmount: number;
      private toAmount: number;
      private onChange: ({ }) => {};
      private enabled: boolean;

      private $onInit() {
         this.amountChanged();
         if (this.enabled === undefined) {
            this.enabled = false;
         }
      }

      private toggleState() {
         this.enabled = !this.enabled;
         this.amountChanged();
      }

      public amountChanged() {
         if (this.enabled) {
            this.onChange({ amounts: { fromAmount: this.fromAmount, toAmount: this.toAmount } });
         }
         else {
            this.onChange({ amounts: { fromAmount: null, toAmount: null } });
         }
      }
   }
}