namespace app.entities {
   @Component('app.entities', 'ceCaseListByEntity', {
      templateUrl: 'app/entities/case-listing/case-list.html',
      bindings: {
         cases: '<',
         refresh: '&',
         paging: '<',
         promise: '<'
      }
   })
   class CaseList {
   }
}