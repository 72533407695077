namespace app.core.exception {
   'use strict';

   export interface IExceptionService {
      catcher(message?: string): (reason: any) => void;
   }

   class ExceptionService implements IExceptionService {
      constructor(private logger: logging.ILogService) {
         'ngInject';
      }

      public catcher(message?: string) {
         return (error: any) => this.logger.error(message, error);
      }
   }

   angular
      .module('app.core.exception')
      .service('exception', ExceptionService);
}