namespace app.funding {
   @Component('app.funding', 'ceFundingSummary', {
      templateUrl: 'app/funding/summary/summary.html',
      bindings: {
         involvementId: '<'
      }
   })
   class FundingSummary {
      static $inject = ['$mdDialog', 'common', 'datacontext'];
      constructor(
         private $mdDialog: angular.material.IDialogService,
         private common: core.ICommonService,
         private datacontext: data.IDataContextService) {
         'ngInject';
      }

      public involvementId: string;
      public fundingSummary: IFundingSummaryView;

      private $onInit() {
         this.datacontext.funding.getFundingSummaryView(this.involvementId).
            then(result => this.fundingSummary = result);
      }
   }
}