namespace app.mail {
   @Controller('app.mail', 'CopyMessageDialogController')
   class CopyMessageDialogController {
      static $inject = ['$mdDialog', 'datacontext', 'message'];
      constructor(
         private $mdDialog: angular.material.IDialogService,
         private datacontext: data.IDataContextService,
         private message: IMessageFolder) {
         'ngInject';
      }

      public data = {} as ICopyMessageData;
      public isSubmitting = false;

      public submit() {
         this.isSubmitting = true;
         this.datacontext.mail
            .copyMessage(this.data)
            .then(() => this.$mdDialog.hide())
            .finally(() => this.isSubmitting = false);
      }

      private $onInit() {
         this.data.messageId = this.message.id;
      }

      public setSource(source: app.common.ISource) {
         this.data.source = source.source;
         this.data.sourceId = source.sourceId;
         this.data.involvementId = source.involvementId;
      }

      public cancel() {
         this.$mdDialog.cancel();
      }
   }
}