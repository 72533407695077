namespace app.training.training {
   @Component('app.training.training', 'ceTrainingOverview', {
      templateUrl: 'app/training/training/overview/training-overview.html',
      bindings: {
         data: '<'
      }
   })
   class TrainingOverview {
      static $inject = ['datacontext'];
      constructor(private datacontext: data.IDataContextService) {
         'ngInject';
      }

      public data: ITraining;

      public GetTrainingInformation() {
         this.datacontext.training.getTrainingInformation(this.data.id).then(
            result => this.data = result
         );
      }
   }
}