namespace app.grievance {
   'use strict';

   export interface IGrievanceRepository {
      getGrievanceListing(params: IGetGrievancesParams): data.IPagedListPromise<IGrievance>;
      createGrievance(grievance: ISaveGrievanceData): angular.IPromise<IGrievance>;
      getCases(entityId: string): angular.IPromise<ICase[]>;
      deleteGrievance(id: string): angular.IPromise<boolean>;
      getGrievance(id: string): angular.IPromise<IGrievance>;
      updateGrievance(id: string, data: ISaveGrievanceData): angular.IPromise<IGrievance>;
   }

   const baseUrl = 'api/grievances';

   class GrievanceRepository implements IGrievanceRepository {
      static $inject = ['$http', 'exception'];
      constructor(
         private $http: angular.IHttpService,
         private exception: core.exception.IExceptionService,
      ) {
      }

      @ApiExecutor()
      public getGrievanceListing(params: IGetGrievancesParams) {
         return this.$http.get<data.IPagedList<IGrievance>>(baseUrl, { params: params }) as any;
      }

      @ApiExecutor()
      public createGrievance(grievance: ISaveGrievanceData) {
         return this.$http.post<IGrievance>(baseUrl, grievance) as any;
      }

      @ApiExecutor()
      public getCases(entityId: string) {
         return this.$http.get<ICase[]>(`${baseUrl}/cases`, { params: { entityId: entityId } }) as any;
      }

      @ApiExecutor()
      public deleteGrievance(id: string) {
         return this.$http.delete<boolean>(`${baseUrl}/${id}`) as any;
      }

      @ApiExecutor()
      public getGrievance(id: string) {
         return this.$http.get<IGrievance>(`${baseUrl}/${id}`) as any;
      }

      @ApiExecutor()
      public updateGrievance(id: string, data: ISaveGrievanceData) {
         return this.$http.put<IGrievance>(`${baseUrl}/${id}`, data) as any;
      }
   }

   angular
      .module('app.grievance')
      .service('grievance.repository', GrievanceRepository);
}