namespace app.utils {
   export interface IBlob {
      buffer: any;
      contentType: string;
      fileName: string;
   }

   export interface IBlobHelperService {
      open(blob: IBlob);
   }

   class BlobHelperService implements IBlobHelperService {
      public open(blob: IBlob) {
         const { buffer, contentType, fileName } = blob;
         const blobObject = new Blob([buffer], { type: contentType });

         const a = document.createElement('a');
         a.style.display = 'none';

         const url = window.URL.createObjectURL(blobObject);
         a.href = url;
         a.download = blob.fileName;
         document.body.appendChild(a);
         a.click();
         setTimeout(() => {
            document.body.removeChild(a);
            window.URL.revokeObjectURL(url);
         }, 100);
      }
   }

   angular
      .module('app.utils')
      .service('blobHelper', BlobHelperService);
}