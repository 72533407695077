namespace app.search {
   @Component('app.search', 'ceAdvancedSearchForm', {
      templateUrl: 'app/search/advanced-search/advanced-search-form.html',
      bindings: {
         query: '=',
         onSubmit: '&'
      }
   })
   class AdvancedSearchForm {
      public query: persons.IGetPersonsParams;
      public isSubmitting = false;
      public onSubmit: () => angular.IPromise<boolean>;

      public submit() {
         this.isSubmitting = true;
         this.onSubmit().finally(() => this.isSubmitting = false);
      }
   }
}