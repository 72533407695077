namespace app.entities.details {
   @Component('app.entities.details', 'ceEntityPhoneNumberForm', {
      templateUrl: 'app/entities/details/phone-number/phone-number-form.html',
      bindings: {
         phoneNumber: '<',
         onCancel: '&',
         onSubmit: '&'
      }
   })
   @Inject('common')
   class PhoneNumberForm {
      static $inject = ['common'];
      constructor(
         private common: core.ICommonService) {
      }

      public data: IEntityPhoneNumber;
      public phoneNumber: IEntityPhoneNumber;

      private phoneChanged(phoneNumber: IEntityPhoneNumber) {
         this.data = angular.extend(this.data, phoneNumber);
      }

      private $onChanges() {
         this.data = angular.extend({}, this.phoneNumber);
      }
   }
}