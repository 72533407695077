namespace app.mail {
   @Component('app.mail', 'ceEmailListing', {
      templateUrl: 'app/mail/email-listing/email-listing.html',
      bindings: {
         involvementId: '<',
         source: '@',
         sourceId: '<'
      }
   })
   class EmailListing {
      static $inject = ['$mdDialog', 'common', 'datacontext', 'blobHelper'];
      constructor(
         private $mdDialog: angular.material.IDialogService,
         private common: core.ICommonService,
         private datacontext: data.IDataContextService,
         private blobHelper: utils.IBlobHelperService) {
         'ngInject';
      }

      private involvementId: string;
      private source: string;
      private sourceId: string;

      public messages: data.IPagedList<IMessageFolder>;
      public showFilters: boolean = false;
      public dateFilterInitialized: boolean;
      public fromDate: Date;
      public toDate: Date;
      public showInvolvementEmails: boolean;
      public subjectFilter: string;
      private folderId: string;
      public folderName: string;
      public promise: angular.IPromise<any>;
      public paging: data.IPagedListParams = {
         page: 1,
         pageSize: 100,
         order: '-receivedAt'
      };

      private $onInit() {
         this.dateFilterInitialized = true;
         this.getMessages();
      }

      public dateFilterChanged(dates: filters.IDateFilterFromTo) {
         if (!dates) {
            return;
         }

         this.dateFilterInitialized = true;
         this.fromDate = dates.fromDate;
         this.toDate = dates.toDate;
         this.getMessages();
      }

      public toggleFilters() {
         this.showFilters = !this.showFilters;
      }

      private getMessages = () => {
         if (!this.dateFilterInitialized) {
            return;
         }

         const params: IGetFoldersParams = {
            involvementId: this.involvementId,
            source: this.showInvolvementEmails ? null : this.source,
            sourceId: this.showInvolvementEmails ? null : this.sourceId,
            folderId: this.folderId,
            q: this.subjectFilter,
            ...this.paging
         };

         this.promise = this.datacontext.mail
            .getFolders(params)
            .then(result => {
               this.messages = result;
            });
      }

      public getInvolvementEmails() {
         this.showInvolvementEmails = !this.showInvolvementEmails;
         this.getMessages();
      }

      public download(message: IMessage, attachment: IAttachment) {
         this.datacontext.mail
            .downloadAttachment(message.id, attachment.id)
            .then(result => this.blobHelper.open(result));
      }

      public show(message: IMessageFolder) {
         if (message.type === 'Folder') {
            this.showFolder(message);
         } else {
            this.common.$state.go('^.message', { messageId: message.id });
         }
      }

      public showFolder(message: IMessageFolder) {
         if (message) {
            this.folderId = message.id;
            this.folderName = message.subject;
         } else {
            this.folderId = null;
            this.folderName = null;
         }

         this.getMessages();
      }

      public createFolder(targetEvent: MouseEvent) {
         this.openFolderDialog(targetEvent, null);
      }

      public rename(targetEvent: MouseEvent, message: IMessageFolder) {
         if (message.type === 'Folder') {
            this.renameFolder(targetEvent, message);
         }
      }

      public renameFolder(targetEvent: MouseEvent, folder: IMessageFolder) {
         this.openFolderDialog(targetEvent, folder);
      }

      private openFolderDialog(targetEvent: MouseEvent, folder: IMessageFolder) {
         const parent = angular.element(document.body);
         return this.$mdDialog.show({
            parent,
            targetEvent,
            fullscreen: true,
            templateUrl: 'app/mail/folder-dialog/folder-dialog.html',
            controller: 'MailFolderDialogController',
            controllerAs: '$ctrl',
            locals: {
               folder: folder,
               involvementId: this.involvementId,
               source: this.source,
               sourceId: this.sourceId
            }
         })
            .then(() => { this.getMessages(); })
            .catch((error) => { if (error) throw error; });
      }

      public delete(message: IMessageFolder) {
         if (message.type === 'Folder') {
            this.deleteFolder(message);
         } else {
            this.deleteMessage(message);
         }
      }

      public deleteFolder(folder: IMessageFolder) {
         this.common.confirmation.show({
            title: 'Delete Folder',
            message: 'Are you sure you want to delete this folder?',
            ok: 'Delete',
            cancel: 'Keep'
         }).then(() => {
            this.datacontext.mail
               .deleteFolder(folder.id)
               .then(result => {
                  if (result) {
                     this.getMessages();
                  }
               });
         }).catch(angular.noop);
      }

      public deleteMessage(message: IMessageFolder) {
         this.common.confirmation.show({
            title: 'Delete Message',
            message: 'Are you sure you want to delete this message?',
            ok: 'Delete',
            cancel: 'Keep'
         }).then(() => {
            this.datacontext.mail
               .deleteMessage(message.id)
               .then(result => {
                  if (result) {
                     _.remove(this.messages.list, message);
                     this.messages.count--;
                  }
               });
         }).catch(angular.noop);
      }

      public change(targetEvent: MouseEvent, message: IMessageFolder) {
         if (message.type === 'Message') {
            this.changeMessageFolder(targetEvent, message);
         }
      }

      public move(targetEvent: MouseEvent, message: IMessageFolder) {
         if (message.type === 'Message') {
            this.moveMessage(targetEvent, message);
         }
      }

      public copy(targetEvent: MouseEvent, message: IMessageFolder) {
         if (message.type === 'Message') {
            this.copyMessage(targetEvent, message);
         }
      }

      public changeMessageFolder(targetEvent: MouseEvent, message: IMessageFolder) {
         const parent = angular.element(document.body);
         return this.$mdDialog.show({
            parent,
            targetEvent,
            fullscreen: true,
            templateUrl: 'app/mail/change-message-folder-dialog/change-message-folder-dialog.html',
            controller: 'ChangeMessageFolderDialogController',
            controllerAs: '$ctrl',
            locals: {
               message: message
            }
         })
            .then(() => { this.getMessages(); })
            .catch((error) => { if (error) throw error; });
      }

      public moveMessage(targetEvent: MouseEvent, message: IMessageFolder) {
         const parent = angular.element(document.body);
         return this.$mdDialog.show({
            parent,
            targetEvent,
            fullscreen: true,
            templateUrl: 'app/mail/move-message-dialog/move-message-dialog.html',
            controller: 'MoveMessageDialogController',
            controllerAs: '$ctrl',
            locals: {
               message: message
            }
         })
            .then(() => { this.getMessages(); })
            .catch((error) => { if (error) throw error; });
      }

      public copyMessage(targetEvent: MouseEvent, message: IMessageFolder) {
         const parent = angular.element(document.body);
         return this.$mdDialog.show({
            parent,
            targetEvent,
            fullscreen: true,
            templateUrl: 'app/mail/copy-message-dialog/copy-message-dialog.html',
            controller: 'CopyMessageDialogController',
            controllerAs: '$ctrl',
            locals: {
               message: message
            }
         })
            .then(() => { this.getMessages(); })
            .catch(angular.noop);
      }

      public showReportDialog(message: IMessageFolder) {
         let report = {
            category: 'Other',
            label: 'Single Email Report',
            name: 'Single Email',
            description: 'Prints Single Email',
            permission: 'reports.view',
            parameters: [
               { label: 'MessageId', name: 'MessageId', dataType: 'hidden', value: message.id },
            ]
         } as app.reports.IReport;

         return this.$mdDialog.show({
            fullscreen: true,
            templateUrl: 'app/reports/report-dialog/report-dialog.html',
            controller: 'ReportDialogController',
            controllerAs: '$ctrl',
            focusOnOpen: false,
            locals: {
               report: report
            }
         })
            .catch((error) => { if (error) throw error; })
            .finally(() => angular.noop);
      }
   }
}