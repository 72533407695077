namespace app.monitoring.activities {
   interface IActivityViewModel {
      icon: string;
      text: string;
      sref: string;
      activityType?: string;
      name?: string;
      filter?: (monitoring: IMonitoringBasicInfoView) => boolean;
   }

   const activities: IActivityViewModel[] = [
      {
         icon: 'group',
         text: 'Meeting',
         sref: '^.meeting',
         filter: i => i.isActive
      },
      {
         icon: 'how_to_reg',
         text: 'Contact / Visit',
         sref: '^.contact',
         filter: i => i.isActive
      },
      {
         icon: 'report_problem',
         text: 'Issue',
         sref: '^.issue',
         filter: i => i.isActive
      },
      {
         icon: 'playlist_add_check',
         text: 'Case Review',
         sref: '^.caseReview',
         filter: i => i.isActive
      },
      {
         icon: 'comment',
         text: 'Note',
         sref: '^.note',
         filter: i => true
      },
      {
         icon: 'folder_open',
         text: 'Reopen Case',
         sref: '^.reopen',
         filter: i => !i.isActive
      },
      {
         icon: 'highlight_off',
         text: 'Close Case',
         sref: '^.close',
         filter: i => i.isActive
      },
      {
         icon: 'approval',
         text: 'Status Change',
         sref: '^.statusChange',
         filter: i => i.isActive
      },
   ];

   @Controller('app.monitoring.activities', 'NewMonitoringActivityDialogController')
   class NewMonitoringActivityDialogController {
      static $inject = ['$mdDialog', 'common', 'monitoringview'];
      constructor(
         private $mdDialog: angular.material.IDialogService,
         private common: core.ICommonService,
         private monitoringview: IMonitoringBasicInfoView) {
         'ngInject';
      }

      public filteredActivities: IActivityViewModel[];

      private $onInit() {
         this.filteredActivities = activities.filter(this.filterActivity);
      }

      private filterActivity = (activity: IActivityViewModel) => {
         return activity.filter(this.monitoringview);
      }

      public selectActivity(item: IActivityViewModel) {
         this.common.$state.go(item.sref, { involvementId: this.monitoringview.involvementId, sourceId: this.monitoringview.id });
         this.$mdDialog.hide();
      }
   }
}