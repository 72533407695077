namespace app.time {
   'use strict';

   export interface ITimeRepository {
      getCurrentTotal(userId: string, date: Date | string): angular.IPromise<ICurrentTotal>;
      getTimeslip(id: string): angular.IPromise<ITimeslip>;
      getTimeslipCalendarItems(params: IGetTimeslipsParams): angular.IPromise<ITimeslipListingView[]>;
      getTimeslipListing(params: IGetTimeslipsParams): data.IPagedListPromise<ITimeslipListingView>;
      getTimeslipListingDownload(params: IGetTimeslipsParams): angular.IPromise<app.analysis.IDownloadDataResult>;
      createTimeslip(timeslip: ITimeslip): angular.IPromise<string>;
      deleteTimeslip(id: string): angular.IPromise<string>;
      updateTimeslip(timeslip: ITimeslip): angular.IPromise<string>;
      getTasks(q: string): angular.IPromise<ITask[]>;
      getSources(q: string): angular.IPromise<ITimeslipSource[]>;
      lockTimeslips(data: IUpdateLockData): angular.IPromise<boolean>;
      getLastLock(): angular.IPromise<ILastLock>;
      getTimeslipsPerPayPeriod(userIds: string[]): angular.IPromise<any>;
      getTimeslipsPerWorkWeek(userIds: string[]): angular.IPromise<any>;
   }

   const baseUrl = 'api/time';

   class TimeRepository implements ITimeRepository {
      static $inject = ['$http', 'exception'];
      constructor(
         private $http: angular.IHttpService,
         private exception: core.exception.IExceptionService,
      ) {
      }

      @ApiExecutor()
      public getCurrentTotal(userId: string, date: Date | string) {
         return this.$http.get<ICurrentTotal>(`${baseUrl}/currentTotal`, { params: { userId: userId, date: date } }) as any;
      }

      @ApiExecutor()
      public getTimeslip(id: string) {
         return this.$http.get<ITimeslip>(`${baseUrl}/slips/${id}`) as any;
      }

      @ApiExecutor()
      public getSources(q: string) {
         return this.$http.get<ITimeslipSource>(`${baseUrl}/sources`, { params: { q: q } }) as any;
      }

      @ApiExecutor()
      public getTimeslipCalendarItems(params: IGetTimeslipsParams) {
         let result = this.$http.post<ITimeslipListingView>(`${baseUrl}/calendaritems`, params) as any;
         return result;
      }

      @ApiExecutor()
      public getTimeslipListing(params: IGetTimeslipsParams) {
         let result = this.$http.get<data.IPagedList<ITimeslipListingView>>(`${baseUrl}/slips`, { params: params }) as any;
         return result;
      }

      public getTimeslipListingDownload(params: IGetTimeslipsParams) {
         return this.$http.get<any>(`${baseUrl}/csv`, { params, responseType: 'arraybuffer' }).then(
            (response) => {
               return {
                  buffer: response.data,
                  contentType: response.headers()['content-type'],
                  fileName: response.headers()['content-disposition'].split(';')[1].split('=')[1].replace(/"/g, '')
               };
            }).catch(error => this.exception.catcher('XHR for getCsvData failed')(error)) as any;
      }

      @ApiExecutor()
      public createTimeslip(timeslip: ITimeslip) {
         return this.$http.post<ITimeslip>(`${baseUrl}/slip`, timeslip) as any;
      }

      @ApiExecutor()
      public updateTimeslip(timeslip: ITimeslip) {
         return this.$http.put<ITimeslip>(`${baseUrl}/slips/${timeslip.id}`, timeslip) as any;
      }

      @ApiExecutor()
      public deleteTimeslip(id: string) {
         return this.$http.delete(`${baseUrl}/slips/${id}`) as any;
      }

      @ApiExecutor()
      public getTasks(q: string) {
         return this.$http.get<ITask[]>(`${baseUrl}/tasks`, { params: { q: q, activeOnly: true } }) as any;
      }

      @ApiExecutor()
      public lockTimeslips(data: IUpdateLockData) {
         return this.$http.post<boolean>(`${baseUrl}/slips/lock`, data) as any;
      }

      @ApiExecutor()
      public getLastLock() {
         return this.$http.get<ILastLock>(`${baseUrl}/slips/last-lock`) as any;
      }

      @ApiExecutor()
      public getTimeslipsPerPayPeriod(userIds: string[]) {
         return this.$http.get<any>(`${baseUrl}/slipsPerPayPeriod`, { params: { userIds: userIds } }) as any;
      }

      @ApiExecutor()
      public getTimeslipsPerWorkWeek(userIds: string[]) {
         return this.$http.get<any>(`${baseUrl}/slipsPerWorkWeek`, { params: { userIds: userIds } }) as any;
      }
   }

   angular
      .module('app.time')
      .service('time.repository', TimeRepository);
}