namespace app.core {
   export interface ICommonService {
      $broadcast(...args): any;
      $mdToast: angular.material.IToastService;
      $q: angular.IQService;
      $rootScope: IRootScopeService;
      $state: angular.ui.IStateService;
      $stateParams: angular.ui.IStateParamsService;
      $timeout: angular.ITimeoutService;
      $window: angular.IWindowService;
      auth: security.IAuthorizationService;
      configHelper: config.IConfigHelperService;
      confirmation: utils.IConfirmationService;
      logger: logging.ILogService;
      moment: moment.MomentStatic;
      routingHelper: routing.IRoutingHelperService;
      setTitle(title: string);
   }

   class CommonService implements ICommonService {
      constructor(
         public $mdToast: angular.material.IToastService,
         public $q: angular.IQService,
         public $rootScope: IRootScopeService,
         public $state: angular.ui.IStateService,
         public $stateParams: angular.ui.IStateParamsService,
         public $timeout: angular.ITimeoutService,
         public $window: angular.IWindowService,
         public auth: security.IAuthorizationService,
         public configHelper: config.IConfigHelperService,
         public confirmation: utils.IConfirmationService,
         public logger: logging.ILogService,
         public moment: moment.MomentStatic,
         public routingHelper: routing.IRoutingHelperService
      ) {
         'ngInject';
      }

      public $broadcast() {
         return this.$rootScope.$broadcast.apply(this.$rootScope, arguments);
      }

      public setTitle(title: string) {
         this.$rootScope.title = `${title} - PANDA`;
      }
   }

   angular
      .module('app.core')
      .service('common', CommonService);
}