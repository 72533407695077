namespace app.projects {
   'use strict';

   const routes: core.routing.IStateExtended[] = [
      {
         name: 'app.createProject',
         url: '/projects/create',
         template: '<ce-create-project layout="column" funding-programs="$resolve.fundingPrograms" flex></ce-create-project>',
         data: {
            title: 'Create Project',
            permissions: ['projects.edit']
         },
         resolve: {
            fundingPrograms: fundingProgramsResolver
         }
      },
      {
         name: 'app.projects',
         url: '/projects/{projectId}',
         template: '<ce-project-view layout flex project="$resolve.project"></ce-project-view>',
         redirectTo: 'app.projects.overview',
         data: { permissions: ['projects.view'] },
         resolve: { project: projectResolver }
      },
      {
         name: 'app.projects.overview',
         url: '/overview',
         template: '<ce-project-overview project="$resolve.project" layout="column" flex></ce-project-overview>',
         data: {
            title: 'Project Overview',
            permissions: ['projects.view']
         }
      },
      {
         name: 'app.projects.details',
         url: '/details',
         template: '<ce-project-details data="$resolve.project" layout="column" flex funding-programs="$resolve.fundingPrograms"></ce-project-details>',
         data: {
            title: 'Project Details',
            permissions: ['projects.view']
         },
         resolve: {
            fundingPrograms: fundingProgramsResolver
         }
      },
      {
         name: 'app.projects.timeslipListing',
         url: '/timeslips',
         template: '<ce-timeslip-listing source="Project" source-id="$resolve.project.id" source-name="$resolve.project.name" funding-program-id="$resolve.project.fundingProgramId" enable-date-filter="false" layout="column" flex></ce-timeslip-listing>',
         data: {
            title: 'Project Timeslips',
            permissions: ['timeslips.view']
         }
      },
      {
         name: 'app.projects.timeslipCalendar',
         url: '/timeslips-calendar',
         template: '<ce-timeslip-calendar-frame source="Project" source-id="$resolve.project.id" source-name="$resolve.project.name" funding-program-id="$resolve.project.fundingProgramId" layout="column" flex></ce-timeslip-calendar-frame>',
         data: {
            title: 'Project Timeslips',
            permissions: ['timeslips.view']
         }
      },
      {
         name: 'app.projects.documents',
         url: '/documents',
         template: '<ce-documents-list source="Project" source-id="$resolve.project.id" layout="column" flex></ce-documents-list>',
         data: {
            title: 'Project Documents',
            permissions: ['documents.view']
         }
      },
      {
         name: 'app.projects.uploadDocuments',
         url: '/upload',
         params: {
            folderId: null
         },
         resolve: {
            folderId: ['$stateParams', p => p.folderId],
         },
         template: '<ce-upload-documents-activity layout flex folder-id="$resolve.folderId" source="Project" source-id="$resolve.project.id"></ce-upload-documents-activity>',
         data: { permissions: ['documents.edit'] }
      },
      {
         name: 'app.projects.emails',
         url: '/emails',
         template: '<ui-view flex layout="column"></ui-view>',
         redirectTo: 'app.projects.emails.listing',
         data: {
            title: 'Project Emails',
            permissions: ['mail.view']
         }
      },
      {
         name: 'app.projects.emails.listing',
         url: '',
         template: '<ce-email-listing source="Project" source-id="$resolve.project.id" layout="column" flex></ce-email-listing>',
         data: {
            title: 'Project Emails',
            permissions: ['mail.view']
         }
      },
      {
         name: 'app.projects.emails.message',
         url: '/{messageId}',
         template: '<ce-email-view layout="column" flex message-id="$resolve.messageId"></ce-email-view>',
         data: {
            title: 'Message',
            permissions: ['mail.view']
         },
         resolve: {
            messageId: ['$stateParams', p => p.messageId]
         }
      },
      {
         name: 'app.projects.emails.link',
         url: '/link',
         template: '<ce-link-emails layout="column" flex source="Project" source-id="$resolve.project.id"></ce-link-emails>',
         data: {
            title: 'Inbox Messages',
            permissions: ['mail.link']
         }
      },
      {
         name: 'app.projects.calendar',
         url: '/calendar',
         template: '<ce-projects-calendar source="Project" source-id="$resolve.project.id" layout="column" flex></ce-projects-calendar>',
         data: {
            title: 'Calendar',
         }
      },
      {
         name: 'app.projects.eventSchedule',
         url: '/eventSchedule',
         template: `<ce-calendar-event-schedule
                     event-schedule-id="$resolve.eventScheduleId"
                     target-date="$resolve.targetDate"
                     source="Project"
                     source-name="$resolve.project.name"
                     source-id="$resolve.project.id"></ce-calendar-event-schedule>`,
         params: {
            eventScheduleId: null,
            targetDate: null
         },
         resolve: {
            eventScheduleId: ['$stateParams', p => p.eventScheduleId],
            targetDate: ['$stateParams', p => p.targetDate],
         },
         data: { title: 'Event Schedule', permissions: ['events.view'], }
      },
      {
         name: 'app.projects.event',
         url: '/event',
         template: '<ce-calendar-event event="$resolve.event"></ce-calendar-event>',
         params: {
            event: null
         },
         resolve: {
            event: ['$stateParams', e => e.event]
         },
         data: {
            title: 'Event',
            permissions: ['events.view']
         }
      },
   ];

   const runner = (routingHelper: core.routing.IRoutingHelperService) => {
      'ngInject';
      routingHelper.configureRoutes(routes);
   };

   angular
      .module('app.projects')
      .run(runner);
}