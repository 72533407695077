namespace app.funding {
   @Component('app.funding', 'ceNdbedpIntake', {
      templateUrl: 'app/funding/enrollments/ndbedp/ndbedp-intake.html',
      bindings: {
         enrollmentId: '<'
      }
   })
   class NDBEDPIntake {
      static $inject = ['common', 'datacontext'];
      constructor(
         private common: core.ICommonService,
         private datacontext: data.IDataContextService) {
         'ngInject';
      }
      public enrollmentId: string;
      public intake: INDBEDPIntake;
      public editing: boolean;

      public $onInit() {
         this.getIntake();
      }

      public getIntake() {
         return this.datacontext.funding.getNDBEDPIntake(this.enrollmentId)
            .then(intake => { if (intake) this.intake = intake; });
      }

      public edit() {
         this.getIntake().then(() => {
            this.editing = true;
         });
      }

      public updateIntake() {
         this.datacontext.funding.updateNDBEDPIntake(this.intake)
            .then(() => {
               this.editing = false;
            });
      }

      public cancel() {
         this.getIntake().then(() => {
            this.editing = false;
         });
      }

      public done() {
         this.common.$state.go('^.');
      }
   }
}