namespace app.funding {
   @Component('app.funding', 'cePaddIntake', {
      templateUrl: 'app/funding/enrollments/padd/padd-intake.html',
      bindings: {
         enrollmentId: '<'
      }
   })
   class PADDIntake {
      static $inject = ['common', 'datacontext'];
      constructor(
         private common: core.ICommonService,
         private datacontext: data.IDataContextService) {
         'ngInject';
      }
      public enrollmentId: string;
      public intake: IPADDIntake;
      public editing: boolean;

      public $onInit() {
         this.getIntake();
      }

      public getIntake() {
         return this.datacontext.funding.getPADDIntake(this.enrollmentId)
            .then(intake => { if (intake) this.intake = intake; });
      }

      public edit() {
         this.getIntake().then(() => {
            this.editing = true;
         });
      }

      public updateIntake() {

         this.datacontext.funding.updatePADDIntake(this.intake).then(
            () => {
               this.editing = false;
            }
         );
      }

      public cancel() {
         this.getIntake().then(() => {
            this.editing = false;
         });
      }

      public done() {
         this.common.$state.go('^.');
      }
   }
}