namespace app.identity {
   @Component('app.identity', 'ceEditProfilePhotoActions', {
      templateUrl: 'app/identity/edit-profile/edit-profile-photo-actions.html',
      bindings: {
         onToggleInputMethod: '&',
         useUploadMethod: '='
      }
   })
   class EditProfilePhotoActions {
   }
}