namespace app.clientCases.activities {
   @Component('app.clientCases.activities', 'ceClientCasesActivitiesReopen', {
      templateUrl: 'app/client-cases/activities/reopen/reopen.html',
      bindings: {
         case: '<'
      }
   })
   class Reopen {
      static $inject = ['common', 'datacontext'];
      constructor(
         private common: core.ICommonService,
         private datacontext: data.IDataContextService) {
         'ngInject';
      }

      public case: IClientCaseBasicInfoView;
      public isSubmitting: boolean = false;
      public activity = {} as ICreateClientCaseActivity;

      private $onInit() {
         this.activity.caseId = this.case.id;
         this.activity.involvementId = this.case.involvementId;
         this.activity.activityDate = moment().toDate();
      }

      public submit() {
         this.isSubmitting = true;

         if (typeof this.activity.activityDate !== 'string') {
            this.activity.activityDate = this.activity.activityDate.toDateString();
         }

         this.datacontext.clientCases
            .reopen(this.activity)
            .then(cc => {
               if (cc) {
                  this.common.$mdToast.showSimple('Case Reopened');
                  this.common.$rootScope.$broadcast('refreshBasicInfo');
                  this.case.isActive = true;
                  this.common.$state.go('^.list', { caseId: this.case.id, involvementId: this.case.involvementId });
               }
            })
            .finally(() => this.isSubmitting = false);
      }
   }
}