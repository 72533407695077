namespace app.admin.configuration {
   @Component('app.admin.configuration', 'ceAdminLawCategoryDetails', {
      templateUrl: 'app/admin/modules/configuration/views/law-category-details/law-category-details.html',
      bindings: {
         lawCategory: '<'
      }
   })
   class LawCategoryDetails {
      static $inject = ['common', 'datacontext'];
      constructor(
         private common: core.ICommonService,
         private datacontext: data.IDataContextService) {
         'ngInject';
      }

      public isSubmitting = false;
      public lawCategory: ILawCategory;

      public addSubcategory() {
         this.lawCategory.subcategories.unshift({ isActive: true, isEditMode: true } as ILawSubcategory);
      }

      public cancelSubcategory(index: number) {
         let subcategory = this.lawCategory.subcategories[index];
         subcategory.isEditMode = false;

         if (!subcategory.id) {
            this.lawCategory.subcategories.splice(index, 1);
         }
      }

      public saveSubcategory(subcategory: ILawSubcategory) {
         this.isSubmitting = true;

         const promise = subcategory.id
            ? this.datacontext.admin.configuration.updateLawSubcategory(this.lawCategory.id, subcategory.id, subcategory)
            : this.datacontext.admin.configuration.createLawSubcategory(this.lawCategory.id, subcategory);

         promise
            .then((result) => {
               subcategory.isEditMode = false;
               this.common.$mdToast.showSimple('Law Subcategory ' + (subcategory.id ? 'updated' : 'created'));

               if (result) {
                  subcategory.id = result.id;
               }
            })
            .finally(() => this.isSubmitting = false);
      }

      public submit() {
         this.isSubmitting = true;
         this.datacontext.admin.configuration
            .updateLawCategory(this.lawCategory.id, this.lawCategory)
            .then(result => {
               if (result) {
                  this.common.$mdToast.showSimple('Law Category updated');
               }
            }).finally(() => this.isSubmitting = false);
      }

      private $onChanges() {
         this.common.setTitle(`${this.lawCategory.name} (Law Category)`);
      }
   }
}