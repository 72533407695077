namespace app.utils {
   const focusIf = ($timeout) => {
      'ngInject';

      const link = ($scope: angular.IScope, $element: angular.IAugmentedJQuery, $attrs: any) => {
         const focus = (condition) => {
            if (condition) {
               $timeout(() => {
                  dom.focus();
               }, $scope.$eval($attrs.focusDelay) || 0);
            }
         };

         const dom = $element[0];
         if ($attrs.focusIf) {
            $scope.$watch($attrs.focusIf, focus);
         } else {
            focus(true);
         }
      };

      return {
         restrict: 'A',
         link: link
      };
   };

   angular
      .module('app.utils')
      .directive('focusIf', focusIf);
}