namespace app.monitoring {
   @Component('app.monitoring', 'ceMonitoringView', {
      templateUrl: 'app/monitoring/monitoring/monitoring-view.html',
      bindings: {
         monitoring: '<'
      }
   })
   class MonitoringView {
      static $inject = ['$mdDialog', 'common', 'confirmation', 'datacontext', 'timerService'];
      constructor(
         private $mdDialog: angular.material.IDialogService,
         private common: core.ICommonService,
         private confirmation: IConfirmationService,
         private datacontext: data.IDataContextService,
         private timerService: time.ITimerService) {
         'ngInject';
         this.$state = common.$state;
      }

      public isLoading: boolean = false;
      public monitoring: IMonitoringBasicInfoView;
      public $state: angular.ui.IStateService;
      private unsubscribe: any;

      private $onInit() {
         this.unsubscribe = this.common.$rootScope.$on('refreshBasicInfo', this.refresh);
      }

      public showReportDialog() {
         let report = {
            category: 'Monitoring Report',
            label: 'Monitoring Activities Report',
            name: 'Monitoring Activities',
            description: 'Monitoring Activities',
            permission: 'reports.view',
            parameters: [
               { label: 'From Date', name: 'FromDate', dataType: 'Date', value: null },
               { label: 'To Date', name: 'ToDate', dataType: 'Date', value: null },
               { label: 'Activity Type - Leave blank for All', name: 'ActivityType', value: '', dataType: 'Select', options: ['', 'Monitoring Description', 'Note', 'Issue', 'Meeting', 'Contact / Visit', 'Case Review', 'Closure Summary'] },
               { label: 'MonitoringId', name: 'MonitoringId', dataType: 'hidden', value: this.monitoring.id },
               { label: 'Involvement', name: 'InvolvementId', dataType: 'hidden', value: this.monitoring.involvementId }
            ]
         } as app.reports.IReport;

         return this.$mdDialog.show({
            fullscreen: true,
            templateUrl: 'app/reports/report-dialog/report-dialog.html',
            controller: 'ReportDialogController',
            controllerAs: '$ctrl',
            focusOnOpen: false,
            locals: {
               report: report
            }
         })
            .catch((error) => { if (error) throw error; })
            .finally(() => angular.noop);
      }

      private $onDestroy() {
         this.unsubscribe();
      }

      private refresh = () => {
         this.datacontext.monitoring.getMonitoringBasicInfoView(this.monitoring.id).then(
            monitoring => this.monitoring = monitoring
         );
      }

      private showDeleteDialog(id: string) {
         this.confirmation.show({
            message: 'Are you sure you want to delete this Monitoring? This will delete all data attached to this Monitoring.',
            ok: 'DELETE',
            cancel: 'KEEP'
         }).then(() => { this.deleteMonitoring(id); });
      }

      private deleteMonitoring(id: string) {
         this.datacontext.monitoring.deleteMonitoring(id).then((result) => {
            if (result) {
               this.timerService.killTimer();
               this.common.$state.go('app.monitoringList');
            }
         });
      }
   }
}