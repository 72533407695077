namespace app.persons {
   @Component('app.persons', 'cePersonView', {
      templateUrl: 'app/persons/person/person-view.html',
      bindings: {
         person: '<'
      }
   })
   class PersonView {
      static $inject = ['$mdDialog', 'ageFilter', 'dateFilter', 'common'];
      constructor(
         private $mdDialog: angular.material.IDialogService,
         private ageFilter: app.utils.IAgeFilter,
         private dateFilter: angular.IFilterDate,
         private common: core.ICommonService) {
         'ngInject';
      }

      public person: IPerson;
      public dob: string;
      $onInit() {
         if (this.person && this.person.dateOfBirth) {
            this.dob = 'DOB ' + this.dateFilter(this.person.dateOfBirth, 'MM/dd/yy') + ' (' + this.ageFilter(this.person.dateOfBirth) + ')';
         }
         else {
            this.dob = 'DOB unknown';
         }
      }
   }
}