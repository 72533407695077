namespace app.entities {
   @Component('app.entities', 'ceRelatedEntities', {
      templateUrl: 'app/entities/related-entities/related-entities.html',
      bindings: {
         entityId: '<'
      }
   })
   class RelatedEntities {
      static $inject = ['$mdDialog', 'common', 'datacontext'];
      constructor(
         private $mdDialog: angular.material.IDialogService,
         private common: core.ICommonService,
         private datacontext: data.IDataContextService) {
         'ngInject';
      }

      public entities: IAssociationView[];
      public entityId: string;

      private $onInit = () => {
         this.getEntities();
      }

      private getEntities = () => {
         return this.datacontext.entities
            .getRelatedEntities(this.entityId)
            .then(result => {
               this.entities = result;
            });
      };

      public deleteAssociation(association: IAssociationView) {
         this.common.confirmation.show({
            title: 'Delete Relationship',
            message: 'Are you sure you want to delete this entity relationship?',
            ok: 'Delete',
            cancel: 'Keep'
         }).then(() => {
            this.datacontext.entities
               .deleteAssociation(association.entityId, association.relatedEntityId)
               .then(() => {
                  this.getEntities();
               });
         }).catch(angular.noop);
      }
   }
}