namespace app.admin.identityManagement {
   @Component('app.admin.identityManagement', 'ceAdminUserList', {
      templateUrl: 'app/admin/modules/identityManagement/views/user-list/user-list.html',
      bindings: {
      }
   })
   class UserList {
      static $inject = ['datacontext'];
      constructor(
         private datacontext: data.IDataContextService) {
         'ngInject';
      }

      public users: identityManagement.IUser[];

      public nameFilter: string;
      public showActiveOnly: boolean = true;

      private $onInit() {
         this.getUsers();
      }

      public getUsers() {
         this.datacontext.admin.identityManagement
            .getUsers({ q: this.nameFilter, activeOnly: this.showActiveOnly })
            .then(result => this.users = result);
      }

      public filterActiveOnly() {
         this.showActiveOnly = !this.showActiveOnly;
         this.getUsers();
      }
   }
}