namespace app.utils {
   @Component('app.utils', 'ceBadgedButton', {
      bindings: {
         badge: '=',
         icon: '@',
         tooltip: '@',
         onClick: '&'
      },
      template: `<a ng-click="$ctrl.onClick({$event})">
   <md-button aria-label="{{$ctrl.tooltip}}"
              class="md-icon-button">
      <md-tooltip>{{$ctrl.tooltip}}</md-tooltip>
      <md-icon>{{$ctrl.icon}}</md-icon>
   </md-button>
   <i ng-show="$ctrl.badge">{{$ctrl.badge}}</i>
</a>`
   })
   class BadgedButtonComponent {
   }
}