namespace app.funding {
   'use strict';

   export interface IFundingRepository {
      getFundingPrograms(activeOnly: boolean, source: string): angular.IPromise<IFundingProgram[]>;
      getFundingProgramsBySource(date: Date | string, source: string, sourceId: string, involvementId: string): angular.IPromise<IFundingProgram[]>;
      getTrainingFundingPrograms(trainingId: string): angular.IPromise<IFundingProgram[]>;
      setTrainingFundingPrograms(trainingId: string, fundingProgramIds: string[]);
      getEligibilityView(involvementId: string): angular.IPromise<IEligibilityView[]>;
      getEnrollmentEligibilityView(involvementId: string): angular.IPromise<IEnrollmentEligibilityView[]>;
      createEnrollment(enrollment: IEnrollment): angular.IPromise<string>;
      deleteEnrollment(enrollmentId: string): angular.IPromise<boolean>;
      updateEnrollment(enrollment: IEnrollment): angular.IPromise<boolean>;
      setPrimaryFundingSource(enrollment: IEnrollment): angular.IPromise<string>;
      forceSetPrimaryFundingSource(enrollment: IEnrollment): angular.IPromise<string>;
      setSecondaryFundingSource(enrollment: IEnrollment): angular.IPromise<string>;
      deleteSecondaryFundingSource(involvementId: string): angular.IPromise<boolean>;
      getFundingSummaryView(involvementId: string): angular.IPromise<IFundingSummaryView>;
      getEnrollmentView(involvementId: string): angular.IPromise<IEnrollmentView[]>;
      createEligibility(eligibility: IEligibility): angular.IPromise<string>;
      createCAPEligibility(eligibility: ICAPEligibility): angular.IPromise<string>;
      createPAATEligibility(eligibility: IPAATEligibility): angular.IPromise<string>;
      createPABSSEligibility(eligibility: IPABSSEligibility): angular.IPromise<string>;
      createPADDEligibility(eligibility: IPADDEligibility): angular.IPromise<string>;
      createPAIMIEligibility(eligibility: IPAIMIEligibility): angular.IPromise<string>;
      createPAIREligibility(eligibility: IPAIREligibility): angular.IPromise<string>;
      createPATBIEligibility(eligibility: IPATBIEligibility): angular.IPromise<string>;
      getPADDIntake(id: string): angular.IPromise<IPADDIntake>;
      getPADDOutcome(id: string): angular.IPromise<IPADDOutcome>;
      updatePADDIntake(intake: IPADDIntake): angular.IPromise<string>;
      updatePADDOutcome(outcome: IPADDOutcome): angular.IPromise<string>;
      getPADDSystemicIntake(id: string): angular.IPromise<IPADDSystemicIntake>;
      getPADDSystemicOutcome(id: string): angular.IPromise<IPADDSystemicOutcome>;
      updatePADDSystemicIntake(intake: IPADDSystemicIntake): angular.IPromise<string>;
      updatePADDSystemicOutcome(outcome: IPADDSystemicOutcome): angular.IPromise<string>;
      getPABSSIntake(id: string): angular.IPromise<IPABSSIntake>;
      getPABSSOutcome(id: string): angular.IPromise<IPABSSOutcome>;
      updatePABSSIntake(intake: IPABSSIntake): angular.IPromise<string>;
      updatePABSSOutcome(outcome: IPABSSOutcome): angular.IPromise<string>;
      getPAATIntake(id: string): angular.IPromise<IPAATIntake>;
      getPAATOutcome(id: string): angular.IPromise<IPAATOutcome>;
      updatePAATIntake(intake: IPAATIntake): angular.IPromise<string>;
      updatePAATOutcome(outcome: IPAATOutcome): angular.IPromise<string>;
      getPAIRIntake(id: string): angular.IPromise<IPAIRIntake>;
      getPAIROutcome(id: string): angular.IPromise<IPAIROutcome>;
      updatePAIRIntake(intake: IPAIRIntake): angular.IPromise<string>;
      updatePAIROutcome(outcome: IPAIROutcome): angular.IPromise<string>;
      getPATBIIntake(id: string): angular.IPromise<IPATBIIntake>;
      getPATBIOutcome(id: string): angular.IPromise<IPATBIOutcome>;
      updatePATBIIntake(intake: IPATBIIntake): angular.IPromise<string>;
      updatePATBIOutcome(outcome: IPATBIOutcome): angular.IPromise<string>;
      getPAIMIIntake(id: string): angular.IPromise<IPAIMIIntake>;
      getPAIMIOutcome(id: string): angular.IPromise<IPAIMIOutcome>;
      updatePAIMIIntake(intake: IPAIMIIntake): angular.IPromise<string>;
      updatePAIMIOutcome(outcome: IPAIMIOutcome): angular.IPromise<string>;
      getPAIMIProblemAndInterventionOptions(): angular.IPromise<IPAIMIProblemAndInterventionOption[]>;
      getPAIMICaseOutcomeOptions(): angular.IPromise<IPAIMIOption[]>;
      getPAIMISystemicOutcomeOptions(): angular.IPromise<IPAIMIOption[]>;

      getPADDIAndROutcome(id: string): angular.IPromise<IPADDIAndROutcome>;
      updatePADDIAndROutcome(outcome: IPADDIAndROutcome): angular.IPromise<string>;

      getPAIMISystemicIntake(id: string): angular.IPromise<IPAIMISystemicIntake>;
      getPAIMISystemicOutcome(id: string): angular.IPromise<IPAIMISystemicOutcome>;
      updatePAIMISystemicIntake(intake: IPAIMISystemicIntake): angular.IPromise<string>;
      updatePAIMISystemicOutcome(outcome: IPAIMISystemicOutcome): angular.IPromise<string>;

      getPAATSystemicIntake(id: string): angular.IPromise<IPAATSystemicIntake>;
      getPAATSystemicOutcome(id: string): angular.IPromise<IPAATSystemicOutcome>;
      updatePAATSystemicIntake(intake: IPAATSystemicIntake): angular.IPromise<string>;
      updatePAATSystemicOutcome(outcome: IPAATSystemicOutcome): angular.IPromise<string>;

      getPAATIAndROutcome(id: string): angular.IPromise<IPAATIAndROutcome>;
      updatePAATIAndROutcome(outcome: IPAATIAndROutcome): angular.IPromise<string>;

      getPATBISystemicIntake(id: string): angular.IPromise<IPATBISystemicIntake>;
      getPATBISystemicOutcome(id: string): angular.IPromise<IPATBISystemicOutcome>;
      updatePATBISystemicIntake(intake: IPATBISystemicIntake): angular.IPromise<string>;
      updatePATBISystemicOutcome(outcome: IPATBISystemicOutcome): angular.IPromise<string>;

      getPATBIIAndROutcome(id: string): angular.IPromise<IPATBIIAndROutcome>;
      updatePATBIIAndROutcome(outcome: IPATBIIAndROutcome): angular.IPromise<string>;

      getPAATLivingArrangementOptions(): angular.IPromise<ILivingArrangement[]>;
      getPADDLivingArrangementOptions(): angular.IPromise<ILivingArrangement[]>;
      getPATBILivingArrangementOptions(): angular.IPromise<ILivingArrangement[]>;
      getPAATProblemAreaOptions(): angular.IPromise<IProblemArea[]>;
      getPADDProblemAreaOptions(): angular.IPromise<IProblemArea[]>;
      getPATBIProblemAreaOptions(): angular.IPromise<IProblemArea[]>;

      getPAVAIntake(id: string): angular.IPromise<IPAVAIntake>;
      getPAVAOutcome(id: string): angular.IPromise<IPAVAOutcome>;
      updatePAVAIntake(intake: IPAVAIntake): angular.IPromise<string>;
      updatePAVAOutcome(outcome: IPAVAOutcome): angular.IPromise<string>;
      getPAVASystemicIntake(id: string): angular.IPromise<IPAVASystemicIntake>;
      getPAVASystemicOutcome(id: string): angular.IPromise<IPAVASystemicOutcome>;
      updatePAVASystemicIntake(intake: IPAVASystemicIntake): angular.IPromise<string>;
      updatePAVASystemicOutcome(outcome: IPAVASystemicOutcome): angular.IPromise<string>;
      getPAVALivingArrangementOptions(): angular.IPromise<ILivingArrangement[]>;
      getPAVAProblemAreaOptions(): angular.IPromise<IProblemArea[]>;

      getPAVAIAndROutcome(id: string): angular.IPromise<IPAVAIAndROutcome>;
      updatePAVAIAndROutcome(outcome: IPAVAIAndROutcome): angular.IPromise<string>;

      getCAPIntake(id: string): angular.IPromise<ICAPIntake>;
      getCAPOutcome(id: string): angular.IPromise<ICAPOutcome>;
      updateCAPIntake(intake: ICAPIntake): angular.IPromise<string>;
      updateCAPOutcome(outcome: ICAPOutcome): angular.IPromise<string>;
      getCAPSystemicIntake(id: string): angular.IPromise<ICAPSystemicIntake>;
      getCAPSystemicOutcome(id: string): angular.IPromise<ICAPSystemicOutcome>;
      updateCAPSystemicIntake(intake: ICAPSystemicIntake): angular.IPromise<string>;
      updateCAPSystemicOutcome(outcome: ICAPSystemicOutcome): angular.IPromise<string>;
      getCAPLivingArrangementOptions(): angular.IPromise<ILivingArrangement[]>;
      getCAPProblemAreaOptions(): angular.IPromise<IProblemArea[]>;

      getCAPIAndROutcome(id: string): angular.IPromise<ICAPIAndROutcome>;
      updateCAPIAndROutcome(outcome: ICAPIAndROutcome): angular.IPromise<string>;

      getTEPIntake(id: string): angular.IPromise<ITEPIntake>;
      getTEPOutcome(id: string): angular.IPromise<ITEPOutcome>;
      updateTEPIntake(intake: ITEPIntake): angular.IPromise<string>;
      updateTEPOutcome(outcome: ITEPOutcome): angular.IPromise<string>;

      getNDBEDPIntake(id: string): angular.IPromise<INDBEDPIntake>;
      getNDBEDPOutcome(id: string): angular.IPromise<INDBEDPOutcome>;
      updateNDBEDPIntake(intake: INDBEDPIntake): angular.IPromise<string>;
      updateNDBEDPOutcome(outcome: INDBEDPOutcome): angular.IPromise<string>;

      getTEPSystemicIntake(id: string): angular.IPromise<ITEPIntake>;
      getTEPSystemicOutcome(id: string): angular.IPromise<ITEPOutcome>;
      updateTEPSystemicIntake(intake: ITEPIntake): angular.IPromise<string>;
      updateTEPSystemicOutcome(outcome: ITEPOutcome): angular.IPromise<string>;

      getNDBEDPSystemicIntake(id: string): angular.IPromise<INDBEDPIntake>;
      getNDBEDPSystemicOutcome(id: string): angular.IPromise<INDBEDPOutcome>;
      updateNDBEDPSystemicIntake(intake: INDBEDPIntake): angular.IPromise<string>;
      updateNDBEDPSystemicOutcome(outcome: INDBEDPOutcome): angular.IPromise<string>;
   }

   const baseUrl = 'api/funding';

   class FundingRepository implements IFundingRepository {
      static $inject = ['$http', 'exception'];
      constructor(
         private $http: angular.IHttpService,
         private exception: core.exception.IExceptionService,
      ) {
      }

      @ApiExecutor()
      public getFundingPrograms(activeOnly: boolean, source: string) {
         return this.$http.get<IFundingProgram[]>(`${baseUrl}/programs`, { params: { activeOnly: activeOnly, source: source } }) as any;
      }
      @ApiExecutor()
      public getFundingProgramsBySource(date: Date, source: string, sourceId: string, involvementId: string) {
         return this.$http.get<IFundingProgram[]>(`${baseUrl}/programsbysource`, { params: { date: date, source: source, sourceId: sourceId, involvementId: involvementId } }) as any;
      }
      @ApiExecutor()
      public getTrainingFundingPrograms(trainingId: string) {
         return this.$http.get<IFundingProgram[]>(`${baseUrl}/trainingPrograms`, { params: { trainingId: trainingId } }) as any;
      }
      @ApiExecutor()
      public setTrainingFundingPrograms(trainingId: string, fundingProgramIds: string[]) {
         return this.$http.post(`${baseUrl}/trainingPrograms`, { trainingId, fundingProgramIds }) as any;
      }
      @ApiExecutor()
      public getEligibilityView(involvementId: string) {
         return this.$http.get<IEligibilityView[]>(`${baseUrl}/eligibility`, { params: { involvementId: involvementId } }) as any;
      }
      @ApiExecutor()
      public getEnrollmentView(involvementId: string) {
         return this.$http.get<IEnrollmentView[]>(`${baseUrl}/enrollments`, { params: { involvementId: involvementId } }) as any;
      }

      public createEnrollment(enrollment: IEnrollment) {
         return this.$http.post(`${baseUrl}/enrollments`, enrollment).catch(error => this.exception.catcher('There can only be one Funding Program per Date')(error)) as any;
      }

      public updateEnrollment(enrollment: IEnrollment) {
         return this.$http.put(`${baseUrl}/enrollments/${enrollment.id}`, enrollment).catch(error => this.exception.catcher('There can only be one Funding Program per Date')(error)) as any;
      }
      @ApiExecutor()
      public deleteEnrollment(enrollmentId: string) {
         return this.$http.delete(`${baseUrl}/enrollments/${enrollmentId}`) as any;
      }
      @ApiExecutor()
      public forceSetPrimaryFundingSource(enrollment: IEnrollment) {
         return this.$http.post(`${baseUrl}/setprimary`, enrollment) as any;
      }
      @ApiExecutor()
      public setPrimaryFundingSource(enrollment: IEnrollment) {
         return this.$http.post(`${baseUrl}/primary`, enrollment) as any;
      }
      @ApiExecutor()
      public setSecondaryFundingSource(enrollment: IEnrollment) {
         return this.$http.post(`${baseUrl}/secondary`, enrollment) as any;
      }
      @ApiExecutor()
      public deleteSecondaryFundingSource(involvementId: string) {
         return this.$http.delete(`${baseUrl}/secondary/${involvementId}`) as any;
      }
      @ApiExecutor()
      public getFundingSummaryView(involvementId: string) {
         return this.$http.get(`${baseUrl}/summary?involvementId=${involvementId}`) as any;
      }
      @ApiExecutor()
      public getEnrollmentEligibilityView(involvementId: string) {
         return this.$http.get<IEnrollmentEligibilityView[]>(`${baseUrl}/enrollments/eligibility`, { params: { involvementId: involvementId } }) as any;
      }
      @ApiExecutor()
      public createEligibility(eligibility: IEligibility) {
         return this.$http.post(`${baseUrl}/eligibility/`, eligibility) as any;
      }
      @ApiExecutor()
      public createCAPEligibility(eligibility: ICAPEligibility) {
         return this.$http.post(`${baseUrl}/eligibility/cap`, eligibility) as any;
      }
      @ApiExecutor()
      public createPAATEligibility(eligibility: IPAATEligibility) {
         return this.$http.post(`${baseUrl}/eligibility/paat`, eligibility) as any;
      }
      @ApiExecutor()
      public createPABSSEligibility(eligibility: IPABSSEligibility) {
         return this.$http.post(`${baseUrl}/eligibility/pabss`, eligibility) as any;
      }
      @ApiExecutor()
      public createPADDEligibility(eligibility: IPADDEligibility) {
         return this.$http.post(`${baseUrl}/eligibility/padd`, eligibility) as any;
      }
      @ApiExecutor()
      public createPAIMIEligibility(eligibility: IPAIMIEligibility) {
         return this.$http.post(`${baseUrl}/eligibility/paimi`, eligibility) as any;
      }
      @ApiExecutor()
      public createPAIREligibility(eligibility: IPAIREligibility) {
         return this.$http.post(`${baseUrl}/eligibility/pair`, eligibility) as any;
      }
      @ApiExecutor()
      public createPATBIEligibility(eligibility: IPATBIEligibility) {
         return this.$http.post(`${baseUrl}/eligibility/patbi`, eligibility) as any;
      }
      @ApiExecutor()
      public getPADDIntake(id: string) {
         return this.$http.get(`${baseUrl}/enrollments/${id}/padd/intake`) as any;
      }
      @ApiExecutor()
      public getPADDOutcome(id: string) {
         return this.$http.get(`${baseUrl}/enrollments/${id}/padd/outcome`) as any;
      }
      @ApiExecutor()
      public updatePADDIntake(intake: IPADDIntake) {
         return this.$http.post(`${baseUrl}/enrollments/${intake.id}/padd/intake`, intake) as any;
      }
      @ApiExecutor()
      public updatePADDOutcome(outcome: IPADDOutcome) {
         return this.$http.post(`${baseUrl}/enrollments/${outcome.id}/padd/outcome`, outcome) as any;
      }
      @ApiExecutor()
      public getPADDSystemicIntake(id: string) {
         return this.$http.get(`${baseUrl}/enrollments/${id}/paddsystemic/intake`) as any;
      }
      @ApiExecutor()
      public getPADDSystemicOutcome(id: string) {
         return this.$http.get(`${baseUrl}/enrollments/${id}/paddsystemic/outcome`) as any;
      }
      @ApiExecutor()
      public updatePADDSystemicIntake(intake: IPADDSystemicIntake) {
         return this.$http.post(`${baseUrl}/enrollments/${intake.id}/paddsystemic/intake`, intake) as any;
      }
      @ApiExecutor()
      public updatePADDSystemicOutcome(outcome: IPADDSystemicOutcome) {
         return this.$http.post(`${baseUrl}/enrollments/${outcome.id}/paddsystemic/outcome`, outcome) as any;
      }
      @ApiExecutor()
      public getPABSSIntake(id: string) {
         return this.$http.get(`${baseUrl}/enrollments/${id}/pabss/intake`) as any;
      }
      @ApiExecutor()
      public getPABSSOutcome(id: string) {
         return this.$http.get(`${baseUrl}/enrollments/${id}/pabss/outcome`) as any;
      }
      @ApiExecutor()
      public updatePABSSIntake(intake: IPABSSIntake) {
         return this.$http.post(`${baseUrl}/enrollments/${intake.id}/pabss/intake`, intake) as any;
      }
      @ApiExecutor()
      public updatePABSSOutcome(outcome: IPABSSOutcome) {
         return this.$http.post(`${baseUrl}/enrollments/${outcome.id}/pabss/outcome`, outcome) as any;
      }
      @ApiExecutor()
      public getPAATIntake(id: string) {
         return this.$http.get(`${baseUrl}/enrollments/${id}/paat/intake`) as any;
      }
      @ApiExecutor()
      public getPAATOutcome(id: string) {
         return this.$http.get(`${baseUrl}/enrollments/${id}/paat/outcome`) as any;
      }
      @ApiExecutor()
      public updatePAATIntake(intake: IPAATIntake) {
         return this.$http.post(`${baseUrl}/enrollments/${intake.id}/paat/intake`, intake) as any;
      }
      @ApiExecutor()
      public updatePAATOutcome(outcome: IPAATOutcome) {
         return this.$http.post(`${baseUrl}/enrollments/${outcome.id}/paat/outcome`, outcome) as any;
      }
      @ApiExecutor()
      public getPAIRIntake(id: string) {
         return this.$http.get(`${baseUrl}/enrollments/${id}/pair/intake`) as any;
      }
      @ApiExecutor()
      public getPAIROutcome(id: string) {
         return this.$http.get(`${baseUrl}/enrollments/${id}/pair/outcome`) as any;
      }
      @ApiExecutor()
      public updatePAIRIntake(intake: IPAIRIntake) {
         return this.$http.post(`${baseUrl}/enrollments/${intake.id}/pair/intake`, intake) as any;
      }
      @ApiExecutor()
      public updatePAIROutcome(outcome: IPAIROutcome) {
         return this.$http.post(`${baseUrl}/enrollments/${outcome.id}/pair/outcome`, outcome) as any;
      }
      @ApiExecutor()
      public getPATBIIntake(id: string) {
         return this.$http.get(`${baseUrl}/enrollments/${id}/patbi/intake`) as any;
      }
      @ApiExecutor()
      public getPATBIOutcome(id: string) {
         return this.$http.get(`${baseUrl}/enrollments/${id}/patbi/outcome`) as any;
      }
      @ApiExecutor()
      public updatePATBIIntake(intake: IPATBIIntake) {
         return this.$http.post(`${baseUrl}/enrollments/${intake.id}/patbi/intake`, intake) as any;
      }
      @ApiExecutor()
      public updatePATBIOutcome(outcome: IPATBIOutcome) {
         return this.$http.post(`${baseUrl}/enrollments/${outcome.id}/patbi/outcome`, outcome) as any;
      }
      @ApiExecutor()
      public getPAIMIIntake(id: string) {
         return this.$http.get(`${baseUrl}/enrollments/${id}/paimi/intake`) as any;
      }
      @ApiExecutor()
      public getPAIMIOutcome(id: string) {
         return this.$http.get(`${baseUrl}/enrollments/${id}/paimi/outcome`) as any;
      }
      @ApiExecutor()
      public updatePAIMIIntake(intake: IPAIMIIntake) {
         return this.$http.post(`${baseUrl}/enrollments/${intake.id}/paimi/intake`, intake) as any;
      }
      @ApiExecutor()
      public updatePAIMIOutcome(outcome: IPAIMIOutcome) {
         return this.$http.post(`${baseUrl}/enrollments/${outcome.id}/paimi/outcome`, outcome) as any;
      }
      @ApiExecutor()
      public getPAIMIProblemAndInterventionOptions() {
         return this.$http.get(`${baseUrl}/enrollments/paimi/problemandinterventionoptions`) as any;
      }
      @ApiExecutor()
      public getPAIMICaseOutcomeOptions() {
         return this.$http.get(`${baseUrl}/enrollments/paimi/caseoutcomeoptions`) as any;
      }
      @ApiExecutor()
      public getPAIMISystemicOutcomeOptions() {
         return this.$http.get(`${baseUrl}/enrollments/paimi/systemicoutcomeoptionsview`) as any;
      }
      @ApiExecutor()
      public getPAIMISystemicIntake(id: string) {
         return this.$http.get(`${baseUrl}/enrollments/${id}/paimisystemic/intake`) as any;
      }
      @ApiExecutor()
      public getPAIMISystemicOutcome(id: string) {
         return this.$http.get(`${baseUrl}/enrollments/${id}/paimisystemic/outcome`) as any;
      }
      @ApiExecutor()
      public updatePAIMISystemicIntake(intake: IPAIMISystemicIntake) {
         return this.$http.post(`${baseUrl}/enrollments/${intake.id}/paimisystemic/intake`, intake) as any;
      }
      @ApiExecutor()
      public updatePAIMISystemicOutcome(outcome: IPAIMISystemicOutcome) {
         return this.$http.post(`${baseUrl}/enrollments/${outcome.id}/paimisystemic/outcome`, outcome) as any;
      }

      @ApiExecutor()
      public getPAATSystemicIntake(id: string) {
         return this.$http.get(`${baseUrl}/enrollments/${id}/paatsystemic/intake`) as any;
      }
      @ApiExecutor()
      public getPAATSystemicOutcome(id: string) {
         return this.$http.get(`${baseUrl}/enrollments/${id}/paatsystemic/outcome`) as any;
      }
      @ApiExecutor()
      public updatePAATSystemicIntake(intake: IPAATSystemicIntake) {
         return this.$http.post(`${baseUrl}/enrollments/${intake.id}/paatsystemic/intake`, intake) as any;
      }
      @ApiExecutor()
      public updatePAATSystemicOutcome(outcome: IPAATSystemicOutcome) {
         return this.$http.post(`${baseUrl}/enrollments/${outcome.id}/paatsystemic/outcome`, outcome) as any;
      }

      @ApiExecutor()
      public getPATBISystemicIntake(id: string) {
         return this.$http.get(`${baseUrl}/enrollments/${id}/patbisystemic/intake`) as any;
      }
      @ApiExecutor()
      public getPATBISystemicOutcome(id: string) {
         return this.$http.get(`${baseUrl}/enrollments/${id}/patbisystemic/outcome`) as any;
      }
      @ApiExecutor()
      public updatePATBISystemicIntake(intake: IPATBISystemicIntake) {
         return this.$http.post(`${baseUrl}/enrollments/${intake.id}/patbisystemic/intake`, intake) as any;
      }
      @ApiExecutor()
      public updatePATBISystemicOutcome(outcome: IPATBISystemicOutcome) {
         return this.$http.post(`${baseUrl}/enrollments/${outcome.id}/patbisystemic/outcome`, outcome) as any;
      }

      @ApiExecutor()
      public getPAATLivingArrangementOptions() {
         return this.$http.get(`${baseUrl}/enrollments/paatlivingarrangements`) as any;
      }
      @ApiExecutor()
      public getPADDLivingArrangementOptions() {
         return this.$http.get(`${baseUrl}/enrollments/paddlivingarrangements`) as any;
      }
      @ApiExecutor()
      public getPATBILivingArrangementOptions() {
         return this.$http.get(`${baseUrl}/enrollments/patbilivingarrangements`) as any;
      }
      @ApiExecutor()
      public getPAATProblemAreaOptions() {
         return this.$http.get(`${baseUrl}/enrollments/paatproblemareas`) as any;
      }
      @ApiExecutor()
      public getPADDProblemAreaOptions() {
         return this.$http.get(`${baseUrl}/enrollments/paddproblemareas`) as any;
      }
      @ApiExecutor()
      public getPATBIProblemAreaOptions() {
         return this.$http.get(`${baseUrl}/enrollments/patbiproblemareas`) as any;
      }

      @ApiExecutor()
      public getPAVAIntake(id: string) {
         return this.$http.get(`${baseUrl}/enrollments/${id}/pava/intake`) as any;
      }
      @ApiExecutor()
      public getPAVAOutcome(id: string) {
         return this.$http.get(`${baseUrl}/enrollments/${id}/pava/outcome`) as any;
      }
      @ApiExecutor()
      public updatePAVAIntake(intake: IPAVAIntake) {
         return this.$http.post(`${baseUrl}/enrollments/${intake.id}/pava/intake`, intake) as any;
      }
      @ApiExecutor()
      public updatePAVAOutcome(outcome: IPAVAOutcome) {
         return this.$http.post(`${baseUrl}/enrollments/${outcome.id}/pava/outcome`, outcome) as any;
      }

      @ApiExecutor()
      public getPAVASystemicIntake(id: string) {
         return this.$http.get(`${baseUrl}/enrollments/${id}/pavasystemic/intake`) as any;
      }
      @ApiExecutor()
      public getPAVASystemicOutcome(id: string) {
         return this.$http.get(`${baseUrl}/enrollments/${id}/pavasystemic/outcome`) as any;
      }
      @ApiExecutor()
      public updatePAVASystemicIntake(intake: IPAVASystemicIntake) {
         return this.$http.post(`${baseUrl}/enrollments/${intake.id}/pavasystemic/intake`, intake) as any;
      }
      @ApiExecutor()
      public updatePAVASystemicOutcome(outcome: IPAVASystemicOutcome) {
         return this.$http.post(`${baseUrl}/enrollments/${outcome.id}/pavasystemic/outcome`, outcome) as any;
      }
      @ApiExecutor()
      public getPAVALivingArrangementOptions() {
         return this.$http.get(`${baseUrl}/enrollments/pavalivingarrangements`) as any;
      }
      @ApiExecutor()
      public getPAVAProblemAreaOptions() {
         return this.$http.get(`${baseUrl}/enrollments/pavaproblemareas`) as any;
      }

      @ApiExecutor()
      public getPAATIAndROutcome(id: string) {
         return this.$http.get(`${baseUrl}/enrollments/${id}/paatiandr/outcome`) as any;
      }
      @ApiExecutor()
      public updatePAATIAndROutcome(outcome: IPAATIAndROutcome) {
         return this.$http.post(`${baseUrl}/enrollments/${outcome.id}/paatiandr/outcome`, outcome) as any;
      }
      @ApiExecutor()
      public getPADDIAndROutcome(id: string) {
         return this.$http.get(`${baseUrl}/enrollments/${id}/paddiandr/outcome`) as any;
      }
      @ApiExecutor()
      public updatePADDIAndROutcome(outcome: IPADDIAndROutcome) {
         return this.$http.post(`${baseUrl}/enrollments/${outcome.id}/paddiandr/outcome`, outcome) as any;
      }
      @ApiExecutor()
      public getPATBIIAndROutcome(id: string) {
         return this.$http.get(`${baseUrl}/enrollments/${id}/patbiiandr/outcome`) as any;
      }
      @ApiExecutor()
      public updatePATBIIAndROutcome(outcome: IPATBIIAndROutcome) {
         return this.$http.post(`${baseUrl}/enrollments/${outcome.id}/patbiiandr/outcome`, outcome) as any;
      }
      @ApiExecutor()
      public getPAVAIAndROutcome(id: string) {
         return this.$http.get(`${baseUrl}/enrollments/${id}/pavaiandr/outcome`) as any;
      }
      @ApiExecutor()
      public updatePAVAIAndROutcome(outcome: IPAATIAndROutcome) {
         return this.$http.post(`${baseUrl}/enrollments/${outcome.id}/pavaiandr/outcome`, outcome) as any;
      }
      @ApiExecutor()
      public getCAPIntake(id: string) {
         return this.$http.get(`${baseUrl}/enrollments/${id}/cap/intake`) as any;
      }
      @ApiExecutor()
      public getCAPOutcome(id: string) {
         return this.$http.get(`${baseUrl}/enrollments/${id}/cap/outcome`) as any;
      }
      @ApiExecutor()
      public updateCAPIntake(intake: ICAPIntake) {
         return this.$http.post(`${baseUrl}/enrollments/${intake.id}/cap/intake`, intake) as any;
      }
      @ApiExecutor()
      public updateCAPOutcome(outcome: ICAPOutcome) {
         return this.$http.post(`${baseUrl}/enrollments/${outcome.id}/cap/outcome`, outcome) as any;
      }

      @ApiExecutor()
      public getCAPSystemicIntake(id: string) {
         return this.$http.get(`${baseUrl}/enrollments/${id}/capsystemic/intake`) as any;
      }
      @ApiExecutor()
      public getCAPSystemicOutcome(id: string) {
         return this.$http.get(`${baseUrl}/enrollments/${id}/capsystemic/outcome`) as any;
      }
      @ApiExecutor()
      public updateCAPSystemicIntake(intake: ICAPSystemicIntake) {
         return this.$http.post(`${baseUrl}/enrollments/${intake.id}/capsystemic/intake`, intake) as any;
      }
      @ApiExecutor()
      public updateCAPSystemicOutcome(outcome: ICAPSystemicOutcome) {
         return this.$http.post(`${baseUrl}/enrollments/${outcome.id}/capsystemic/outcome`, outcome) as any;
      }
      @ApiExecutor()
      public getCAPLivingArrangementOptions() {
         return this.$http.get(`${baseUrl}/enrollments/caplivingarrangements`) as any;
      }
      @ApiExecutor()
      public getCAPProblemAreaOptions() {
         return this.$http.get(`${baseUrl}/enrollments/capproblemareas`) as any;
      }
      @ApiExecutor()
      public getCAPIAndROutcome(id: string) {
         return this.$http.get(`${baseUrl}/enrollments/${id}/capiandr/outcome`) as any;
      }
      @ApiExecutor()
      public updateCAPIAndROutcome(outcome: ICAPIAndROutcome) {
         return this.$http.post(`${baseUrl}/enrollments/${outcome.id}/capiandr/outcome`, outcome) as any;
      }

      @ApiExecutor()
      public getTEPIntake(id: string) {
         return this.$http.get(`${baseUrl}/enrollments/${id}/tep/intake`) as any;
      }
      @ApiExecutor()
      public getTEPOutcome(id: string) {
         return this.$http.get(`${baseUrl}/enrollments/${id}/tep/outcome`) as any;
      }
      @ApiExecutor()
      public updateTEPIntake(intake: ITEPIntake) {
         return this.$http.post(`${baseUrl}/enrollments/${intake.id}/tep/intake`, intake) as any;
      }
      @ApiExecutor()
      public updateTEPOutcome(outcome: ITEPOutcome) {
         return this.$http.post(`${baseUrl}/enrollments/${outcome.id}/tep/outcome`, outcome) as any;
      }

      @ApiExecutor()
      public getNDBEDPIntake(id: string) {
         return this.$http.get(`${baseUrl}/enrollments/${id}/ndbedp/intake`) as any;
      }
      @ApiExecutor()
      public getNDBEDPOutcome(id: string) {
         return this.$http.get(`${baseUrl}/enrollments/${id}/ndbedp/outcome`) as any;
      }
      @ApiExecutor()
      public updateNDBEDPIntake(intake: INDBEDPIntake) {
         return this.$http.post(`${baseUrl}/enrollments/${intake.id}/ndbedp/intake`, intake) as any;
      }
      @ApiExecutor()
      public updateNDBEDPOutcome(outcome: INDBEDPOutcome) {
         return this.$http.post(`${baseUrl}/enrollments/${outcome.id}/ndbedp/outcome`, outcome) as any;
      }
      @ApiExecutor()
      public getTEPSystemicIntake(id: string) {
         return this.$http.get(`${baseUrl}/enrollments/${id}/tep/systemic/intake`) as any;
      }
      @ApiExecutor()
      public getTEPSystemicOutcome(id: string) {
         return this.$http.get(`${baseUrl}/enrollments/${id}/tep/systemic/outcome`) as any;
      }
      @ApiExecutor()
      public updateTEPSystemicIntake(intake: ITEPIntake) {
         return this.$http.post(`${baseUrl}/enrollments/${intake.id}/tep/systemic/intake`, intake) as any;
      }
      @ApiExecutor()
      public updateTEPSystemicOutcome(outcome: ITEPOutcome) {
         return this.$http.post(`${baseUrl}/enrollments/${outcome.id}/tep/systemic/outcome`, outcome) as any;
      }

      @ApiExecutor()
      public getNDBEDPSystemicIntake(id: string) {
         return this.$http.get(`${baseUrl}/enrollments/${id}/ndbedp/systemic/intake`) as any;
      }
      @ApiExecutor()
      public getNDBEDPSystemicOutcome(id: string) {
         return this.$http.get(`${baseUrl}/enrollments/${id}/ndbedp/systemic/outcome`) as any;
      }
      @ApiExecutor()
      public updateNDBEDPSystemicIntake(intake: INDBEDPIntake) {
         return this.$http.post(`${baseUrl}/enrollments/${intake.id}/ndbedp/systemic/intake`, intake) as any;
      }
      @ApiExecutor()
      public updateNDBEDPSystemicOutcome(outcome: INDBEDPOutcome) {
         return this.$http.post(`${baseUrl}/enrollments/${outcome.id}/ndbedp/systemic/outcome`, outcome) as any;
      }
   }

   angular
      .module('app.funding')
      .service('funding.repository', FundingRepository);
}