namespace app.funding {
   @Component('app.funding', 'cePaimiIntake', {
      templateUrl: 'app/funding/enrollments/paimi/paimi-intake.html',
      bindings: {
         enrollmentId: '<'
      }
   })
   class PAIMIIntake {
      static $inject = ['common', 'datacontext'];
      constructor(
         private common: core.ICommonService,
         private datacontext: data.IDataContextService) {
         'ngInject';
      }
      public enrollmentId: string;
      public intake: IPAIMIIntake;
      public editing: boolean;

      public $onInit() {
         this.getIntake();
      }

      public getIntake() {
         return this.datacontext.funding.getPAIMIIntake(this.enrollmentId)
            .then(intake => { if (intake) this.intake = intake; });
      }

      public edit() {
         this.getIntake().then(() => {
            this.editing = true;
         });
      }

      public updateIntake() {
         if (this.intake.livingArrangement && this.intake.livingArrangement.indexOf('Other') === -1) {
            this.intake.livingArrangementOther = null;
         }
         if (this.intake.race && this.intake.race.indexOf('More') === -1) {
            this.intake.raceOther = null;
         }

         if (this.intake.determinationDate && typeof this.intake.determinationDate !== 'string') {
            this.intake.determinationDate = this.intake.determinationDate.toDateString();
         }

         if (this.intake.documentationRequestedDate && typeof this.intake.documentationRequestedDate !== 'string') {
            this.intake.documentationRequestedDate = this.intake.documentationRequestedDate.toDateString();
         }

         if (this.intake.documentationReceivedDate && typeof this.intake.documentationReceivedDate !== 'string') {
            this.intake.documentationReceivedDate = this.intake.documentationReceivedDate.toDateString();
         }

         if (this.intake.deathReporter && this.intake.deathReporter.indexOf('Other') === -1) {
            this.intake.deathReporterOther = null;
         }

         this.datacontext.funding.updatePAIMIIntake(this.intake).then(
            () => {
               this.editing = false;
               this.getIntake();
            }
         );
      }

      public cancel() {
         this.getIntake().then(() => {
            this.editing = false;
         });
      }

      public done() {
         this.common.$state.go('^.');
      }
   }
}