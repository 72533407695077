namespace app.funding {
   @Component('app.funding', 'ceFunding', {
      templateUrl: 'app/funding/funding-view/funding-view.html',
      bindings: {
         involvementId: '<'
      }
   })
   class FundingView {
      static $inject = ['$mdDialog', 'common', 'datacontext'];
      constructor(
         private $mdDialog: angular.material.IDialogService,
         private common: core.ICommonService,
         private datacontext: data.IDataContextService) {
         'ngInject';
      }

      public isLoading: boolean = false;
      public involvementId: string;
      public currentTab: string;

      private $onInit() {
         this.currentTab = this.common.$state.current.name.split('.')[3];
      }
   }
}