namespace app.systemicCases.funding {
   const routes: core.routing.IStateExtended[] = [
      {
         name: 'app.systemicCases.funding',
         url: '/funding',
         template: '<ce-funding involvement-id="$resolve.case.involvementId" layout="column" flex></ce-funding>',
         redirectTo: 'app.systemicCases.funding.summary',
         data: { title: 'Systemic Case Funding Summary' }
      },
      {
         name: 'app.systemicCases.funding.summary',
         url: '/summary',
         template: '<ce-funding-summary involvement-id="$resolve.case.involvementId" layout="column" flex></ce-funding-summary>',
         data: { title: 'Systemic Case Funding' }
      },
      {
         name: 'app.systemicCases.funding.eligibility',
         url: '/eligibility',
         template: '<ui-view layout flex></ui-view>',
         redirectTo: 'app.systemicCases.funding.eligibility.list',
         data: { title: 'Systemic Case Funding' }
      },
      {
         name: 'app.systemicCases.funding.eligibility.list',
         url: '/list',
         template: `<am-funding-eligibility-view
                    [involvement-id]="$resolve.case.involvementId"
                    source-type="systemicCases"
                    layout="column" flex></am-funding-eligibility-view>`,
         data: { title: 'Systemic Cases Funding' }
      },
      {
         name: 'app.systemicCases.funding.eligibility.create',
         url: '/create',
         template: '<ce-funding-eligibility-create involvement-id="$resolve.case.involvementId" source="Systemic Case" layout="column" flex></ce-funding-eligibility-create>',
         data: { title: 'Create Eligibility' }
      },
      {
         name: 'app.systemicCases.funding.eligibility.cap',
         url: '/cap',
         params: { fundingProgramId: null },
         resolve: { fundingProgramId: ['$stateParams', p => p.fundingProgramId] },
         template: '<ce-cap-eligibility involvement-id="$resolve.case.involvementId" funding-program-id="$resolve.fundingProgramId" layout="column" flex></ce-cap-eligibility>',
         data: { title: 'CAP Eligibility' }
      },
      {
         name: 'app.systemicCases.funding.eligibility.paat',
         url: '/paat',
         params: { fundingProgramId: null },
         resolve: { fundingProgramId: ['$stateParams', p => p.fundingProgramId] },
         template: '<ce-paat-eligibility involvement-id="$resolve.case.involvementId" funding-program-id="$resolve.fundingProgramId" layout="column" flex></ce-paat-eligibility>',
         data: { title: 'PAAT Eligibility' }
      },
      {
         name: 'app.systemicCases.funding.eligibility.pabss',
         url: '/pabss',
         params: { fundingProgramId: null },
         resolve: { fundingProgramId: ['$stateParams', p => p.fundingProgramId] },
         template: '<ce-pabss-eligibility involvement-id="$resolve.case.involvementId" funding-program-id="$resolve.fundingProgramId" layout="column" flex></ce-pabss-eligibility>',
         data: { title: 'PABSS Eligibility' }
      },
      {
         name: 'app.systemicCases.funding.eligibility.padd',
         url: '/padd',
         params: { fundingProgramId: null },
         resolve: { fundingProgramId: ['$stateParams', p => p.fundingProgramId] },
         template: '<ce-padd-eligibility involvement-id="$resolve.case.involvementId" funding-program-id="$resolve.fundingProgramId" layout="column" flex></ce-padd-eligibility>',
         data: { title: 'PADD Eligibility' }
      },
      {
         name: 'app.systemicCases.funding.eligibility.paimi',
         url: '/paimi',
         params: { fundingProgramId: null },
         resolve: { fundingProgramId: ['$stateParams', p => p.fundingProgramId] },
         template: '<ce-paimi-eligibility involvement-id="$resolve.case.involvementId" funding-program-id="$resolve.fundingProgramId" layout="column" flex></ce-paimi-eligibility>',
         data: { title: 'PAIMI Eligibility' }
      },
      {
         name: 'app.systemicCases.funding.eligibility.pair',
         url: '/pair',
         params: { fundingProgramId: null },
         resolve: { fundingProgramId: ['$stateParams', p => p.fundingProgramId] },
         template: '<ce-pair-eligibility involvement-id="$resolve.case.involvementId" funding-program-id="$resolve.fundingProgramId" layout="column" flex></ce-pair-eligibility>',
         data: { title: 'PAIR Eligibility' }
      },
      {
         name: 'app.systemicCases.funding.eligibility.patbi',
         url: '/patbi',
         params: { fundingProgramId: null },
         resolve: { fundingProgramId: ['$stateParams', p => p.fundingProgramId] },
         template: '<ce-patbi-eligibility involvement-id="$resolve.case.involvementId" funding-program-id="$resolve.fundingProgramId" layout="column" flex></ce-patbi-eligibility>',
         data: { title: 'PATBI Eligibility' }
      },
      {
         name: 'app.systemicCases.funding.enrollment',
         url: '/primary',
         template: '<ui-view layout flex></ui-view>',
         redirectTo: 'app.systemicCases.funding.enrollment.list'
      },
      {
         name: 'app.systemicCases.funding.enrollment.list',
         url: '/list',
         template: '<ce-primary-funding-source-listing involvement-id="$resolve.case.involvementId" layout="column" source="Systemic Case" flex></ce-primary-funding-source-listing>',
         data: { title: 'Primary List' }
      },
      {
         name: 'app.systemicCases.funding.enrollment.primary',
         url: '/primary',
         template: '<ce-create-primary-funding involvement-id="$resolve.case.involvementId" layout="column" flex></ce-create-primary-funding>',
         data: { title: 'Primary Funding' }
      },
      {
         name: 'app.systemicCases.funding.enrollment.updatePrimary',
         url: '/updateprimary',
         params: { enrollment: null },
         resolve: { enrollment: ['$stateParams', p => p.enrollment] },
         template: '<ce-edit-primary-funding enrollment="$resolve.enrollment" layout="column" flex></ce-edit-primary-funding>',
         data: { title: 'Update Funding' }
      },
      {
         name: 'app.systemicCases.funding.enrollment.pavaIntake',
         url: '/pava-systemic-intake/{enrollmentId}',
         template: '<ce-pava-systemic-intake enrollment-id="$resolve.enrollmentId" layout="column" flex></ce-pava-systemic-intake>',
         params: { enrollmentId: null },
         resolve: { enrollmentId: ['$stateParams', p => p.enrollmentId] },
         data: { title: 'PAVA Intake' }
      },
      {
         name: 'app.systemicCases.funding.enrollment.pavaOutcome',
         url: '/pava-systemic-outcome/{enrollmentId}',
         template: '<ce-pava-systemic-outcome enrollment-id="$resolve.enrollmentId" layout="column" flex></ce-pava-systemic-outcome>',
         params: { enrollmentId: null },
         resolve: { enrollmentId: ['$stateParams', p => p.enrollmentId] },
         data: { title: 'PAVA Outcome' }
      },
      {
         name: 'app.systemicCases.funding.enrollment.paddIntake',
         url: '/padd-systemic-intake/{enrollmentId}',
         template: '<ce-padd-systemic-intake enrollment-id="$resolve.enrollmentId" layout="column" flex></ce-padd-systemic-intake>',
         params: { enrollmentId: null },
         resolve: { enrollmentId: ['$stateParams', p => p.enrollmentId] },
         data: { title: 'PADD Intake' }
      },
      {
         name: 'app.systemicCases.funding.enrollment.paddOutcome',
         url: '/padd-systemic-outcome/{enrollmentId}',
         template: '<ce-padd-systemic-outcome enrollment-id="$resolve.enrollmentId" layout="column" flex></ce-padd-systemic-outcome>',
         params: { enrollmentId: null },
         resolve: { enrollmentId: ['$stateParams', p => p.enrollmentId] },
         data: { title: 'PADD Outcome' }
      },
      {
         name: 'app.systemicCases.funding.enrollment.pabssIntake',
         url: '/pabss-intake/{enrollmentId}',
         template: '<ce-pabss-intake enrollment-id="$resolve.enrollmentId" layout="column" flex></ce-pabss-intake>',
         params: { enrollmentId: null },
         resolve: { enrollmentId: ['$stateParams', p => p.enrollmentId] },
         data: { title: 'PABSS Intake' }
      },
      {
         name: 'app.systemicCases.funding.enrollment.pabssOutcome',
         url: '/pabss-outcome/{enrollmentId}',
         template: '<ce-pabss-outcome enrollment-id="$resolve.enrollmentId" layout="column" flex></ce-pabss-outcome>',
         params: { enrollmentId: null },
         resolve: { enrollmentId: ['$stateParams', p => p.enrollmentId] },
         data: { title: 'PABSS Outcome' }
      },
      {
         name: 'app.systemicCases.funding.enrollment.capIntake',
         url: '/cap-systemic-intake/{enrollmentId}',
         template: '<ce-cap-systemic-intake enrollment-id="$resolve.enrollmentId" layout="column" flex></ce-cap-systemic-intake>',
         params: { enrollmentId: null },
         resolve: { enrollmentId: ['$stateParams', p => p.enrollmentId] },
         data: { title: 'CAP Intake' }
      },
      {
         name: 'app.systemicCases.funding.enrollment.capOutcome',
         url: '/cap-systemic-outcome/{enrollmentId}',
         template: '<ce-cap-systemic-outcome enrollment-id="$resolve.enrollmentId" layout="column" flex></ce-cap-systemic-outcome>',
         params: { enrollmentId: null },
         resolve: { enrollmentId: ['$stateParams', p => p.enrollmentId] },
         data: { title: 'CAP Outcome' }
      },
      {
         name: 'app.systemicCases.funding.enrollment.paatIntake',
         url: '/paat-systemic-intake/{enrollmentId}',
         template: '<ce-paat-systemic-intake enrollment-id="$resolve.enrollmentId" layout="column" flex></ce-paat-systemic-intake>',
         params: { enrollmentId: null },
         resolve: { enrollmentId: ['$stateParams', p => p.enrollmentId] },
         data: { title: 'PAAT Intake' }
      },
      {
         name: 'app.systemicCases.funding.enrollment.paatOutcome',
         url: '/paat-systemic-outcome/{enrollmentId}',
         template: '<ce-paat-systemic-outcome enrollment-id="$resolve.enrollmentId" layout="column" flex></ce-paat-systemic-outcome>',
         params: { enrollmentId: null },
         resolve: { enrollmentId: ['$stateParams', p => p.enrollmentId] },
         data: { title: 'PAAT Outcome' }
      },
      {
         name: 'app.systemicCases.funding.enrollment.pairIntake',
         url: '/pair-intake/{enrollmentId}',
         template: '<ce-pair-intake enrollment-id="$resolve.enrollmentId" layout="column" flex></ce-pair-intake>',
         params: { enrollmentId: null },
         resolve: { enrollmentId: ['$stateParams', p => p.enrollmentId] },
         data: { title: 'PAIR Intake' }
      },
      {
         name: 'app.systemicCases.funding.enrollment.pairOutcome',
         url: '/pair-outcome/{enrollmentId}',
         template: '<ce-pair-outcome enrollment-id="$resolve.enrollmentId" layout="column" flex></ce-pair-outcome>',
         params: { enrollmentId: null },
         resolve: { enrollmentId: ['$stateParams', p => p.enrollmentId] },
         data: { title: 'PAIR Outcome' }
      },
      {
         name: 'app.systemicCases.funding.enrollment.patbiIntake',
         url: '/patbi-systemic-intake/{enrollmentId}',
         template: '<ce-patbi-systemic-intake enrollment-id="$resolve.enrollmentId" layout="column" flex></ce-patbi-systemic-intake>',
         params: { enrollmentId: null },
         resolve: { enrollmentId: ['$stateParams', p => p.enrollmentId] },
         data: { title: 'PATBI Intake' }
      },
      {
         name: 'app.systemicCases.funding.enrollment.patbiOutcome',
         url: '/patbi-systemic-outcome/{enrollmentId}',
         template: '<ce-patbi-systemic-outcome enrollment-id="$resolve.enrollmentId" layout="column" flex></ce-patbi-systemic-outcome>',
         params: { enrollmentId: null },
         resolve: { enrollmentId: ['$stateParams', p => p.enrollmentId] },
         data: { title: 'PATBI Outcome' }
      },
      {
         name: 'app.systemicCases.funding.enrollment.paimiIntake',
         url: '/paimi-intake/{enrollmentId}',
         template: '<ce-paimi-systemic-intake enrollment-id="$resolve.enrollmentId" layout="column" flex></ce-paimi-systemic-intake>',
         params: { enrollmentId: null },
         resolve: { enrollmentId: ['$stateParams', p => p.enrollmentId] },
         data: { title: 'PAIMI Intake' }
      },
      {
         name: 'app.systemicCases.funding.enrollment.paimiOutcome',
         url: '/paimi-outcome/{enrollmentId}',
         template: '<ce-paimi-systemic-outcome enrollment-id="$resolve.enrollmentId" layout="column" flex></ce-paimi-systemic-outcome>',
         params: { enrollmentId: null },
         resolve: { enrollmentId: ['$stateParams', p => p.enrollmentId] },
         data: { title: 'PAIMI Outcome' }
      },
      {
         name: 'app.systemicCases.funding.secondary',
         url: '/secondary',
         template: '<ui-view flex></ui-view>',
         redirectTo: 'app.systemicCases.funding.secondary.view',
         data: { title: 'Secondary Funding' }
      },
      {
         name: 'app.systemicCases.funding.secondary.edit',
         url: '/edit',
         params: { fundingProgramId: null },
         resolve: { fundingProgramId: ['$stateParams', p => p.fundingProgramId] },
         template: '<ce-edit-secondary-funding involvement-id="$resolve.case.involvementId" funding-program-id="$resolve.fundingProgramId" layout="column" flex></ce-edit-secondary-funding>',
         data: { title: 'Secondary Funding' }
      },
      {
         name: 'app.systemicCases.funding.secondary.view',
         url: '/views',
         template: '<ce-secondary-funding-view involvement-id="$resolve.case.involvementId" layout="column" flex></ce-secondary-funding-view>',
         data: { title: 'Secondary Funding' }
      },
      {
         name: 'app.systemicCases.funding.enrollment.deaf services (tep)Intake',
         url: '/deaf services (tep)Intake/{enrollmentId}',
         template: '<ce-tep-systemic-intake enrollment-id="$resolve.enrollmentId" layout="column" flex></ce-tep-systemic-intake>',
         params: { enrollmentId: null },
         resolve: { enrollmentId: ['$stateParams', p => p.enrollmentId] },
         data: { title: 'TEP Intake' }
      },
      {
         name: 'app.systemicCases.funding.enrollment.deaf services (tep)Outcome',
         url: '/deaf services (tep)Outcome/{enrollmentId}',
         template: '<ce-tep-systemic-outcome enrollment-id="$resolve.enrollmentId" layout="column" flex></ce-tep-systemic-outcome>',
         params: { enrollmentId: null },
         resolve: { enrollmentId: ['$stateParams', p => p.enrollmentId] },
         data: { title: 'TEP Outcome' }
      },
      {
         name: 'app.systemicCases.funding.enrollment.deaf services (ndbedp)Intake',
         url: '/deaf services (ndbedp)Intake/{enrollmentId}',
         template: '<ce-ndbedp-systemic-intake enrollment-id="$resolve.enrollmentId" layout="column" flex></ce-ndbedp-systemic-intake>',
         params: { enrollmentId: null },
         resolve: { enrollmentId: ['$stateParams', p => p.enrollmentId] },
         data: { title: 'NDBEDP Intake' }
      },
      {
         name: 'app.systemicCases.funding.enrollment.deaf services (ndbedp)Outcome',
         url: '/deaf services (ndbedp)Outcome/{enrollmentId}',
         template: '<ce-ndbedp-systemic-outcome enrollment-id="$resolve.enrollmentId" layout="column" flex></ce-ndbedp-systemic-outcome>',
         params: { enrollmentId: null },
         resolve: { enrollmentId: ['$stateParams', p => p.enrollmentId] },
         data: { title: 'NDBEDP Outcome' }
      }
   ];

   const runner = (routingHelper: core.routing.IRoutingHelperService) => {
      'ngInject';
      routingHelper.configureRoutes(routes);
   };

   angular
      .module('app.systemicCases.funding')
      .run(runner);
}