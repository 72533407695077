namespace app.analysis {
   @Component('app.analysis', 'aimsAnalysisQueryData', {
      templateUrl: 'app/analysis/views/query-details/query-data.html',
      bindings: {
         query: '<'
      }
   })
   class QueryData {
      static $inject = ['$mdDialog', 'datacontext', 'blobHelper'];
      constructor(
         private $mdDialog: angular.material.IDialogService,
         private datacontext: data.IDataContextService,
         private blobHelper: utils.IBlobHelperService) {
         'ngInject';
      }

      public dataParams: IGetTabularDataParams = {
         page: 1,
         pageSize: 10
      };
      public dataPromise: angular.IPromise<any>;
      public dataSource: IDataSource;
      public query: IQuery;
      public tabularData: ITabularDataResult;
      public visibleFields: IFieldMetadata[];

      public export() {
         this.datacontext.analysis
            .downloadData(this.query.id)
            .then(result => {
               if (result) {
                  this.blobHelper.open({
                     buffer: result.buffer,
                     fileName: result.fileName,
                     contentType: result.contentType
                  });
               }
            });
      }

      public openColumnSettings(targetEvent: MouseEvent) {
         const parent = angular.element(document.body);
         this.$mdDialog.show({
            parent,
            targetEvent,
            clickOutsideToClose: false,
            fullscreen: true,
            templateUrl: 'app/analysis/views/query-details/column-settings.html',
            controller: 'AnalysisColumnSettingsController',
            controllerAs: '$ctrl',
            locals: {
               queryId: this.query.id,
               columns: this.dataSource.metadata.fields,
               selectedColumns: this.query.metadata.columns
            }
         }).then(result => {
            this.query.metadata.columns = result;
            this.initVisibleFields();
         }).catch(angular.noop);
      }

      public refresh = () => {
         this.dataPromise = this.datacontext.analysis
            .getTabularData(this.query.id, this.dataParams)
            .then(result => this.tabularData = result);
         return this.dataPromise;
      };

      private $onChanges() {
         this.refresh();
         this.datacontext.analysis
            .getDataSource(this.query.dataSourceId)
            .then(result => {
               this.dataSource = result;
               this.initVisibleFields();
            });
      }

      private initVisibleFields = () => {
         const { fields } = this.dataSource.metadata;
         const { columns } = this.query.metadata;

         this.visibleFields = _(fields)
            .filter(f => _.some(columns, c => c === f.name))
            .value();
      };
   }
}