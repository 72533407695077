namespace app.persons.overview {
   @Component('app.persons.overview', 'cePersonSummary', {
      templateUrl: 'app/persons/summary/summary.html',
      bindings: {
         person: '<'
      }
   })
   class PersonSummary {
      static $inject = ['$mdDialog', 'common', 'datacontext'];
      constructor(
         private $mdDialog: angular.material.IDialogService,
         private common: core.ICommonService,
         private datacontext: data.IDataContextService) {
         'ngInject';
      }
      public person: IPerson;
      public data = {} as IDemographics;
      public $onInit() {
         this.getData();
      }

      public getData() {
         return this.datacontext.persons
            .getDemographics(this.person.id)
            .then(result => {
               this.data = result;
            });
      }
   }
}