namespace app.grievance {
   @Component('app.grievance', 'amEditGrievanceButton', {
      template: `
      <md-button style="font-weight:normal;text-transform:none;" ng-click="$ctrl.openGrievanceDialog()" permission="['grievances.edit']">
        <md-icon aria-hidden="true">edit</md-icon>
        <span class="pl-lg">Edit Grievance</span>
      </md-button>`,
      bindings: {
         grievanceId: '<',
         refresh: '&'
      }
   })
   class EditGrievanceButtonComponent {
      static $inject = ['$mdDialog', '$scope'];
      constructor(
         private $mdDialog: angular.material.IDialogService,
         private $scope: angular.IScope) {
         'ngInject';
      }

      private refresh: () => {};
      private grievanceId: string;
      private openGrievanceDialog() {
         const parent = angular.element(document.body);
         // not adding a .catch to dialogs causes possibly unhandled rejection error
         return this.$mdDialog.show({
            parent,
            fullscreen: true,
            templateUrl: 'app/grievance/grievance-dialog/grievance-dialog.html',
            controller: 'GrievanceDialogController',
            controllerAs: '$ctrl',
            focusOnOpen: false,
            locals: {
               grievanceId: this.grievanceId,
               aCase: null
            }
         })
            .catch((error) => { if (error) throw error; })
            .finally(() => { this.refresh(); });
      }
   }
}
