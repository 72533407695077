namespace app.config {
   export interface IConfigRepository {
      lookups: ILookupRepository;
      legalTeams: ILegalTeamRepository;
      getLawCategories(): angular.IPromise<ILawCategory[]>;
      getLawSubcategories(): angular.IPromise<ILawSubcategory[]>;
      getConfigurationParameter<T>(parameterName: string, defaultValue: T): angular.IPromise<T>;
   }

   const baseUrl = 'api/configuration';

   @Inject('config.lookups.repository', 'config.legalTeams.repository', '$http', 'common')
   class ConfigRepository implements IConfigRepository {
      constructor(
         public lookups: ILookupRepository,
         public legalTeams: ILegalTeamRepository,
         private $http: angular.IHttpService,
         private common: core.ICommonService) { }

      @ApiExecutor()
      public getLawCategories() {
         return this.$http.get<ILawCategory[]>(`${baseUrl}/law-categories?activeOnly=true`) as any;
      }

      public getLawSubcategories() {
         return this.$http.get<ILawSubcategory[]>(`${baseUrl}/lawsubcategories?activeOnly=true`) as any;
      }

      public getConfigurationParameter<T>(parameterName: string, defaultValue: T): angular.IPromise<T> {
         const name = encodeURIComponent(parameterName);

         return this.$http.get<T>(`${baseUrl}/parameters?name=${name}`).then(result => {
            let returnValue: T = null;
            if (!result || result.data === null) {
               returnValue = defaultValue;
            } else {
               returnValue = result.data;
            }
            return returnValue as T;
          });
      }
   }

   angular
      .module('app.config')
      .service('config.repository', ConfigRepository);
}