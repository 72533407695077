namespace app.entities {
   'use strict';

   export interface IEntityRepository {
      getCaseListing(id: string, paging: data.IPagedListParams): data.IPagedListPromise<ICaseListing>;
      getEntityBasicInfo(id: string): angular.IPromise<IEntityBasicInfo>;
      getEntityNames(id: string): angular.IPromise<IEntityName[]>;
      getContactInfo(id: string): angular.IPromise<IEntityContactInfo>;
      createEntityName(name: IEntityName): angular.IPromise<IEntityName>;
      updateEntityName(name: IEntityName): angular.IPromise<IEntityName>;
      deleteEntityName(name: IEntityName): angular.IPromise<IEntityName>;
      getEntityPhoneNumbers(id: string): angular.IPromise<IEntityPhoneNumber[]>;
      createEntityPhoneNumber(phone: IEntityPhoneNumber): angular.IPromise<IEntityPhoneNumber>;
      updateEntityPhoneNumber(phone: IEntityPhoneNumber): angular.IPromise<IEntityPhoneNumber>;
      deleteEntityPhoneNumber(phone: IEntityPhoneNumber): angular.IPromise<IEntityPhoneNumber>;
      getEntityAddresses(id: string): angular.IPromise<IEntityAddress[]>;
      createEntityAddress(name: IEntityAddress): angular.IPromise<IEntityAddress>;
      updateEntityAddress(name: IEntityAddress): angular.IPromise<IEntityAddress>;
      deleteEntityAddress(name: IEntityAddress): angular.IPromise<IEntityAddress>;
      getEntityEmailAddresses(id: string): angular.IPromise<IEntityEmailAddress[]>;
      createEntityEmailAddress(name: IEntityEmailAddress): angular.IPromise<IEntityEmailAddress>;
      updateEntityEmailAddress(name: IEntityEmailAddress): angular.IPromise<IEntityEmailAddress>;
      deleteEntityEmailAddress(name: IEntityEmailAddress): angular.IPromise<IEntityEmailAddress>;
      searchPhoneNumbers(params: ISearchPhoneNumbers): data.IPagedListPromise<IPhoneNumberSearchView>;
      searchEmailAddresses(params: ISearchEmailAddresses): data.IPagedListPromise<IEmailAddressSearchView>;
      getRelatedEntities(id: string): angular.IPromise<IAssociationView[]>;
      getAssociation(entityId: string, relatedEntityId: string): angular.IPromise<IAssociationView>;
      createAssociation(data: ISaveAssociationData): angular.IPromise<IAssociationView>;
      updateAssociation(data: ISaveAssociationData): angular.IPromise<IAssociationView>;
      deleteAssociation(entityId: string, relatedEntityId: string): angular.IPromise<boolean>;
   }

   const baseUrl = 'api/entities';

   class EntityRepository implements IEntityRepository {
      static $inject = ['$http', 'exception'];
      constructor(
         private $http: angular.IHttpService,
         private exception: core.exception.IExceptionService,
      ) {
      }

      @ApiExecutor()
      public getCaseListing(id: string, paging: data.IPagedListParams) {
         return this.$http.get<data.IPagedListPromise<ICaseListing>>(`${baseUrl}/${id}/cases`, { params: paging }) as any;
      }

      @ApiExecutor()
      public getEntityBasicInfo(id: string) {
         return this.$http.get<IEntityBasicInfo[]>(`${baseUrl}/${id}`) as any;
      }

      @ApiExecutor()
      public getEntityNames(id: string) {
         return this.$http.get<IEntityName[]>(`${baseUrl}/${id}/names`) as any;
      }

      @ApiExecutor()
      public createEntityName(entityName: IEntityName) {
         return this.$http.post<IEntityName>(`${baseUrl}/${entityName.entityId}/names`, entityName) as any;
      }

      @ApiExecutor()
      public updateEntityName(entityName: IEntityName) {
         return this.$http.put<IEntityName>(`${baseUrl}/${entityName.entityId}/names/${entityName.id}`, entityName) as any;
      }

      @ApiExecutor()
      public deleteEntityName(entityName: IEntityName) {
         return this.$http.delete<boolean>(`${baseUrl}/${entityName.entityId}/names/${entityName.id}`) as any;
      }

      @ApiExecutor()
      public getEntityAddresses(id: string) {
         return this.$http.get<IEntityAddress[]>(`${baseUrl}/${id}/addresses`) as any;
      }

      @ApiExecutor()
      public createEntityAddress(entityAddress: IEntityAddress) {
         return this.$http.post<IEntityAddress>(`${baseUrl}/${entityAddress.entityId}/addresses`, entityAddress) as any;
      }

      @ApiExecutor()
      public updateEntityAddress(entityAddress: IEntityAddress) {
         return this.$http.put<IEntityAddress>(`${baseUrl}/${entityAddress.entityId}/addresses/${entityAddress.id}`, entityAddress) as any;
      }

      @ApiExecutor()
      public deleteEntityAddress(entityAddress: IEntityAddress) {
         return this.$http.delete<boolean>(`${baseUrl}/${entityAddress.entityId}/addresses/${entityAddress.id}`) as any;
      }

      @ApiExecutor()
      public getEntityPhoneNumbers(id: string) {
         return this.$http.get<IEntityPhoneNumber[]>(`${baseUrl}/${id}/phoneNumbers`) as any;
      }

      @ApiExecutor()
      public createEntityPhoneNumber(entityPhoneNumber: IEntityPhoneNumber) {
         return this.$http.post<IEntityPhoneNumber>(`${baseUrl}/${entityPhoneNumber.entityId}/phoneNumbers`, entityPhoneNumber) as any;
      }

      @ApiExecutor()
      public updateEntityPhoneNumber(entityPhoneNumber: IEntityPhoneNumber) {
         return this.$http.put<IEntityPhoneNumber>(`${baseUrl}/${entityPhoneNumber.entityId}/phoneNumbers/${entityPhoneNumber.id}`, entityPhoneNumber) as any;
      }

      @ApiExecutor()
      public deleteEntityPhoneNumber(entityPhoneNumber: IEntityPhoneNumber) {
         return this.$http.delete<boolean>(`${baseUrl}/${entityPhoneNumber.entityId}/phoneNumbers/${entityPhoneNumber.id}`) as any;
      }

      @ApiExecutor()
      public getEntityEmailAddresses(id: string) {
         return this.$http.get<IEntityEmailAddress[]>(`${baseUrl}/${id}/emailAddresses`) as any;
      }

      @ApiExecutor()
      public createEntityEmailAddress(entityEmailAddress: IEntityEmailAddress) {
         return this.$http.post<IEntityEmailAddress>(`${baseUrl}/${entityEmailAddress.entityId}/emailAddresses`, entityEmailAddress) as any;
      }

      @ApiExecutor()
      public updateEntityEmailAddress(entityEmailAddress: IEntityEmailAddress) {
         return this.$http.put<IEntityEmailAddress>(`${baseUrl}/${entityEmailAddress.entityId}/emailAddresses/${entityEmailAddress.id}`, entityEmailAddress) as any;
      }

      @ApiExecutor()
      public deleteEntityEmailAddress(entityEmailAddress: IEntityEmailAddress) {
         return this.$http.delete<boolean>(`${baseUrl}/${entityEmailAddress.entityId}/emailAddresses/${entityEmailAddress.id}`) as any;
      }

      @ApiExecutor()
      public getContactInfo(id: string) {
         return this.$http.get<IEntityContactInfo[]>(`${baseUrl}/${id}/contactInfo`) as any;
      }

      @ApiExecutor()
      public searchPhoneNumbers(params: ISearchPhoneNumbers) {
         return this.$http.get<IPhoneNumberSearchView[]>(`${baseUrl}/searchphonenumbers`, { params }) as any;
      }

      @ApiExecutor()
      public searchEmailAddresses(params: ISearchEmailAddresses) {
         return this.$http.get<IEmailAddressSearchView[]>(`${baseUrl}/searchemailaddresses`, { params }) as any;
      }

      @ApiExecutor()
      public getRelatedEntities(id: string) {
         return this.$http.get<IAssociationView[]>(`${baseUrl}/${id}/entities`) as any;
      }

      @ApiExecutor()
      public getAssociation(entityId: string, relatedEntityId: string) {
         return this.$http.get<IAssociationView>(`${baseUrl}/${entityId}/entities/${relatedEntityId}`) as any;
      }

      @ApiExecutor()
      public createAssociation(data: ISaveAssociationData) {
         return this.$http.post<IAssociationView>(`${baseUrl}/${data.entityId}/entities`, data) as any;
      }

      @ApiExecutor()
      public updateAssociation(data: ISaveAssociationData) {
         return this.$http.put<IAssociationView>(`${baseUrl}/${data.entityId}/entities/${data.relatedEntityId}`, data) as any;
      }

      @ApiExecutor()
      public deleteAssociation(entityId: string, relatedEntityId: string) {
         return this.$http.delete<boolean>(`${baseUrl}/${entityId}/entities/${relatedEntityId}`) as any;
      }
   }

   angular
      .module('app.entities')
      .service('entities.repository', EntityRepository);
}