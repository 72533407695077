namespace app.funding {
   @Component('app.funding', 'ceTepSystemicIntakeForm', {
      templateUrl: 'app/funding/enrollments/tep/systemic/tep-systemic-intake-form.html',
      bindings: {
         intake: '<',
         onCancel: '&',
         onSubmit: '&'
      }
   })
   class TEPSystemicIntakeForm {
      static $inject = ['common', 'datacontext'];
      constructor(
         private common: core.ICommonService,
         private datacontext: data.IDataContextService) {
         'ngInject';
      }
   }
}