namespace app.clientCases.activities {
   interface IActivityViewModel {
      icon: string;
      text: string;
      sref: string;
      activityType?: string;
      name?: string;
      filter?: (clientCase: IClientCaseBasicInfoView, vaccineFeatureFlag: boolean) => boolean;
   }

   const activities: IActivityViewModel[] = [
      {
         icon: 'history',
         text: 'Conflict Check',
         sref: '^.conflictCheck',
         filter: i => i.isActive
      },
      {
         icon: 'info',
         text: 'Convert to I&R',
         sref: '^.createInformationAndReferral',
         name: 'Create I&R',
         filter: i => i.isActive
      },
      {
         icon: 'playlist_add_check',
         text: 'Case Review',
         sref: '^.caseReview',
         filter: i => i.isActive
      },
      {
         icon: 'folder',
         text: 'Convert to Systemic Case',
         sref: '^.createSystemicCase',
         name: 'Create Systemic Case',
         filter: i => i.isActive
      },
      {
         icon: 'comment',
         text: 'Note',
         sref: '^.note',
         filter: i => true
      },

      {
         icon: 'folder_open',
         text: 'Reopen Case',
         sref: '^.reopen',
         filter: i => !i.isActive
      },
      {
         icon: 'highlight_off',
         text: 'Close Case',
         sref: '^.close',
         filter: i => i.isActive
      },
      {
         icon: 'group_work',
         text: 'Case Assessment Meeting',
         sref: '^.caseAssessmentMeeting',
         filter: i => i.isActive
      },
      {
         icon: 'supervised_user_circle',
         text: 'Representation',
         sref: '^.representation',
         filter: i => i.isActive
      },
      {
         icon: 'vaccines',
         text: 'Covid Vaccine',
         sref: '^.covidVaccine',
         filter: (i, v) => i.isActive && v
      },
      {
         icon: 'approval',
         text: 'Status Change',
         sref: '^.statusChange',
         filter: i => i.isActive
      },
   ];

   @Controller('app.clientCases.activities', 'NewClientCaseActivityDialogController')
   class NewClientCaseActivityDialogController {
      static $inject = ['$mdDialog', 'common', 'caseview', 'vaccineFeatureFlag'];
      constructor(
         private $mdDialog: angular.material.IDialogService,
         private common: core.ICommonService,
         private caseview: IClientCaseBasicInfoView,
         private vaccineFeatureFlag: boolean) {
         'ngInject';
      }

      public filteredActivities: IActivityViewModel[];

      private $onInit() {
         this.filteredActivities = activities.filter(this.filterActivity);
      }

      private filterActivity = (activity: IActivityViewModel) => {
         return activity.filter(this.caseview, this.vaccineFeatureFlag);
      }

      public selectActivity(item: IActivityViewModel) {
         this.common.$state.go(item.sref, { involvementId: this.caseview.involvementId, sourceId: this.caseview.id });
         this.$mdDialog.hide();
      }
   }
}