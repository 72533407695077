namespace app.monitoring.details {
   @Component('app.monitoring.details', 'ceMonitoringInformation', {
      templateUrl: 'app/monitoring/details/monitoring-information/monitoring-information.html',
      bindings: {
         data: '<',
         bypassCensus: '<',
         onEdit: '&'
      }
   })
   class MonitoringInformation {

   }
}