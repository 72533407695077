namespace app.monitoring {
   'use strict';

   export interface IMonitoringRepository {
      getMonitoringBasicInfoView(id: string): angular.IPromise<IMonitoringBasicInfoView>;
      getMonitoringSummaryView(id: string): angular.IHttpPromise<IMonitoringSummaryView>;
      getMonitoringActivities(monitoringId: string): angular.IPromise<IMonitoringActivityModel[]>;
      getMonitoringInformation(monitoringId: string): angular.IPromise<IMonitoringInformation>;
      getMonitoringStatus(id: string): angular.IHttpPromise<IMonitoringStatus>;
      getClosureInformation(id: string): angular.IPromise<IMonitoringClosureInformation>;
      close(activity: ICreateMonitoringActivity): angular.IHttpPromise<ICreateMonitoringActivity>;
      reopen(activity: ICreateMonitoringActivity): angular.IHttpPromise<ICreateMonitoringActivity>;
      createNote(activity: ICreateMonitoringActivity): angular.IHttpPromise<ICreateMonitoringActivity>;
      updateNote(activity: ICreateMonitoringActivity): angular.IHttpPromise<ICreateMonitoringActivity>;
      updateMonitoringInformation(info: IMonitoringInformation): angular.IPromise<IMonitoringInformation>;
      updateClosureInformation(closure: IMonitoringClosureInformation): angular.IPromise<IMonitoringClosureInformation>;
      deleteMonitoring(id: string): angular.IPromise<string>;
      createCaseReview(review: involvements.ICaseReview): angular.IPromise<involvements.ICaseReview>;
      updateCaseReview(review: involvements.ICaseReview): angular.IPromise<involvements.ICaseReview>;
      getMonitoringList(params: any): angular.IPromise<data.IPagedList<IMonitoringListingView>>;
   }

   const baseUrl = 'api/monitoring';

   class MonitoringRepository implements IMonitoringRepository {
      static $inject = ['$http', 'exception'];
      constructor(
         private $http: angular.IHttpService,
         private exception: core.exception.IExceptionService,
      ) {
      }


      @ApiExecutor()
      public getMonitoringActivities(monitoringId: string) {
         return this.$http.get<IMonitoringActivityModel[]>(`${baseUrl}/${monitoringId}/activities`) as any;
      }

      @ApiExecutor()
      public close(activity: ICreateMonitoringActivity) {
         return this.$http.post<ICreateMonitoringActivity>(`${baseUrl}/${activity.monitoringId}/close`, activity) as any;
      }

      @ApiExecutor()
      public reopen(activity: ICreateMonitoringActivity) {
         return this.$http.post<ICreateMonitoringActivity>(`${baseUrl}/${activity.monitoringId}/reopen`, activity) as any;
      }

      @ApiExecutor()
      public createNote(activity: ICreateMonitoringActivity) {
         return this.$http.post<ICreateMonitoringActivity>(`${baseUrl}/${activity.monitoringId}/notes`, activity) as any;
      }

      @ApiExecutor()
      public updateNote(activity: ICreateMonitoringActivity) {
         return this.$http.put<ICreateMonitoringActivity>(`${baseUrl}/${activity.monitoringId}/notes/${activity.id}`, activity) as any;
      }

      @ApiExecutor()
      public getMonitoringStatus(id: string) {
         return this.$http.get<IMonitoringStatus>(`${baseUrl}/${id}/status`) as any;
      }

      @ApiExecutor()
      public getMonitoringBasicInfoView(id: string) {
         return this.$http.get<IMonitoringBasicInfoView>(`${baseUrl}/${id}/basicinfo`) as any;
      }

      @ApiExecutor()
      public getMonitoringSummaryView(id: string) {
         return this.$http.get<IMonitoringSummaryView>(`${baseUrl}/${id}/summary`) as any;
      }

      @ApiExecutor()
      public getMonitoringInformation(id: string) {
         return this.$http.get<IMonitoringInformation>(`${baseUrl}/${id}/information`) as any;
      }

      @ApiExecutor()
      public updateMonitoringInformation(info: IMonitoringInformation) {
         return this.$http.post<IMonitoringInformation>(`${baseUrl}/${info.id}/information`, info) as any;
      }

      @ApiExecutor()
      public getClosureInformation(id: string) {
         return this.$http.get<IMonitoringClosureInformation>(`${baseUrl}/${id}/closure`) as any;
      }
      @ApiExecutor()
      public updateClosureInformation(closure: IMonitoringClosureInformation) {
         return this.$http.post<IMonitoringClosureInformation>(`${baseUrl}/${closure.id}/closure`, closure) as any;
      }

      public deleteMonitoring(id: string) {
         return this.$http.delete(`${baseUrl}/${id}`).catch(error => this.exception.catcher('Cannot Delete Case')(error)) as any;
      }
      @ApiExecutor()
      public createCaseReview(review: involvements.ICaseReview) {
         return this.$http.post<involvements.ICaseReview>(`${baseUrl}/${review.caseId}/caseReview`, review) as any;
      }

      @ApiExecutor()
      public updateCaseReview(review: involvements.ICaseReview) {
         return this.$http.put<involvements.ICaseReview>(`${baseUrl}/${review.caseId}/caseReview/${review.id}`, review) as any;
      }

      @ApiExecutor()
      public getMonitoringList(data: any) {
         const url = `${baseUrl}/list`;
         return this.$http.post<data.IPagedList<IMonitoringListingView>>(url, data) as any;
      }
   }

   angular
      .module('app.monitoring')
      .service('monitoring.repository', MonitoringRepository);
}