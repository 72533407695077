namespace app.persons.overview {
   @Component('app.persons.overview', 'cePersonOverviewView', {
      templateUrl: 'app/persons/overview/overview-view.html',
      bindings: {
         person: '<'
      }
   })
   class PersonOverviewView {
      public person: IPerson;
   }
}