namespace app.monitoring {
   'use strict';

   export const monitoringBasicInfoResolver = (datacontext: data.IDataContextService, $stateParams: angular.ui.IStateParamsService) => {
      'ngInject';
      const { monitoringId } = $stateParams;
      return datacontext.monitoring.getMonitoringBasicInfoView(monitoringId);
   };

   export const legalTeamsResolver = (datacontext: data.IDataContextService) => {
      'ngInject';
      return datacontext.config.legalTeams.getLegalTeams(false);
   };

   export const bypassCensusInfoResolver = (datacontext: data.IDataContextService) => {
      'ngInject';
      const bypassCensusInfo = datacontext.config.getConfigurationParameter<boolean>('Monitoring.BypassCensusInfo', false);
      return bypassCensusInfo.then(result => {
         return result.toString() === 'true';
      });
   };
}