namespace app.time {
   @Controller('app.time', 'TimeslipViewDialogController')
   class TimeslipViewDialogController {
      static $inject = ['$mdDialog', 'timeslip'];
      constructor(
         private $mdDialog: angular.material.IDialogService,
         private timeslip: ITimeslipListingView) {
         'ngInject';
      }

      public cancel() {
         this.$mdDialog.cancel();
      }
   }
}