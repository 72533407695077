namespace app.mail {
   @Component('app.mail', 'ceLinkMessagesView', {
      templateUrl: 'app/mail/link-messages/link-messages-view.html',
      bindings: {
         messageIds: '<'
      }
   })
   class LinkMessagesView {
      static $inject = ['common', 'datacontext'];
      constructor(
         private common: core.ICommonService,
         private datacontext: data.IDataContextService) {
         'ngInject';
      }

      private messageIds: string;
      public messages: IMessage[] = [];
      public data = { messageIds: [] } as ILinkMessagesData;
      public items: {
         sourceId?: string,
         source?: string;
         involvementId?: string;
         selectedSource?: IMailSource
      }[] = [{}];

      public isSubmitting = false;

      public addItem() {
         this.items.push({});
      }

      public removeItem(index: number) {
         this.items.splice(index, 1);
      }

      public submit() {
         this.isSubmitting = true;

         this.data.sources = _.map(this.items, i => ({
            involvementId: i.selectedSource.involvementId,
            sourceId: i.selectedSource.sourceId,
            source: i.selectedSource.source
         }));

         this.datacontext.mail
            .linkMessages(this.data)
            .then(result => {
               if (result) {
                  this.common.$mdToast.showSimple('Messages linked');
                  this.common.$state.go('^');
               }
            }).finally(() => this.isSubmitting = false);
      }

      private $onChanges = () => {
         this.getMessages();
      };

      private getMessages() {
         this.datacontext.mail
            .getMessages({ messageIds: this.messageIds })
            .then(result => {
               this.messages = result.list;
               this.data.messageIds = _.map(this.messages, m => m.id);
            });
      }

      public setSource(source: IMailSource, index: number) {
         this.items[index].selectedSource = source;
      }

   }
}