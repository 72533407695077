namespace app.training {
   @Component('app.training.entities', 'ceEditTrainingEntityRoles', {
      templateUrl: 'app/training/entities/roles/edit-roles.html',
      bindings: {
         entity: '<',
         trainingId: '<',
         roleLookupTypePrefix: '@'
      }
   })
   class EntityRoles {
      static $inject = ['common', 'datacontext'];
      constructor(
         private common: core.ICommonService,
         private datacontext: data.IDataContextService) {
         'ngInject';
      }

      public roles: config.ILookup[];
      public selectedRoles: config.ILookup[] = [];
      public isSubmitting: boolean;
      public entity: app.entities.IEntityBasicInfo;
      public trainingId: string;
      public trainingEntity = {} as ITrainingEntity;
      public roleLookupTypePrefix: string;

      public $onInit = () => {
         this.getRoles().then(() => this.getExistingRoles());
      }

      private transformChip(chip) {
         if (angular.isObject(chip)) {
            return chip;
         }
         return { name: chip, type: 'new' };
      }

      private querySearch(query) {
         let results = query ? _.filter(this.roles, (r: any) => {
            return query === '*' || r.value.toLowerCase().indexOf(query) > -1;
         }) : [];
         return results;
      }

      private getRoles = () => {
         let lookupTypeId: string = '';

         if (this.roleLookupTypePrefix === 'training-request') {
            lookupTypeId = `${this.roleLookupTypePrefix}-entity-role`;
         } else {
            if (this.entity.isUser) {
               lookupTypeId = `${this.roleLookupTypePrefix}-user-role`;
            } else {
               lookupTypeId = `${this.roleLookupTypePrefix}-${this.entity.entityType}-role`;
            }
         }

         return this.datacontext.config.lookups.getLookupType(lookupTypeId).then(
            result => {
               this.roles = result.lookups;
            });
      }

      private getExistingRoles = () => {
         return this.datacontext.training.getEntity(this.trainingId, this.entity.entityId).then(
            (result) => {
               this.trainingEntity = result;
               _.forEach(this.trainingEntity.roles, (ieRole) => {
                  this.selectedRoles.push(_.find(this.roles, (role) => {
                     return role.value === ieRole;
                  }));
               });
            });
      }

      private onSubmit = () => {
         this.isSubmitting = true;
         let roles: string[] = _.map(this.selectedRoles, 'value');

         angular.extend(this.trainingEntity, {
            roles: roles
         });

         let promise: angular.IPromise<ITrainingEntity>;
         promise = this.datacontext.training.updateEntity(this.trainingEntity);

         promise.finally(() => {
            this.isSubmitting = false;
            this.common.$mdToast.showSimple('Entity Updated');
            this.common.$state.go('^.listing');
         });
      }
   }
}