namespace app.projects {
   'use strict';

   export const projectResolver = (datacontext: data.IDataContextService, $stateParams: angular.ui.IStateParamsService) => {
      'ngInject';
      const { projectId } = $stateParams;
      return datacontext.projects.getProject(projectId);
   };

   export const fundingProgramsResolver = (datacontext: data.IDataContextService) => {
      'ngInject';
      return datacontext.funding.getFundingPrograms(true, 'Project');
   };
}