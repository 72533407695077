namespace app.training.training {
   @Component('app.training.training', 'ceTrainingCalendar', {
      templateUrl: 'app/training/training/calendar/calendar.html',
      bindings: {
         source: '@',
         sourceId: '<'
      }
   })
   class TrainingCalendar {
      static $inject = ['$scope', '$mdPanel', '$mdSidenav', 'common', 'trainingCalendarData', 'trainingCalendarState', '$mdDialog'];
      constructor(
         private $scope: angular.IScope,
         private $mdPanel: angular.material.IPanelService,
         private $mdSidenav: angular.material.ISidenavService,
         private common: core.ICommonService,
         private trainingCalendarData: ITrainingCalendarDataService,
         private trainingCalendarState: ITrainingCalendarStateService,
         private $mdDialog: angular.material.IDialogService) {
         'ngInject';
      }

      private $calendar: app.calendars.fc.ICalendar;
      private state = this.trainingCalendarState.state;
      private trainingId: string;
      private source: string;
      private sourceId: string;

      public calendarOptions: app.calendars.fc.ICalendarOptions = {
         aspectRatio: 0,
         scrollTime: '06:00',
         editable: false,
         eventLimit: true,
         header: false,
         selectable: false,
         selectHelper: false,
         dayClick: (targetDate: moment.Moment, targetEvent: MouseEvent, calendarView: app.calendars.fc.IView) => {
            this.createEvent(targetDate);
         },
         eventClick: (event: app.calendars.IEvent) => {
            this.eventClick(event);
         },
         views: {
            highPriority: {
               type: 'list',
               duration: { months: 2 },
               noEventsMessage: 'No High Priority events to display'
            }
         },
         eventRender: (event, element) => { element.attr('aria-label', event.startTime.toLocaleDateString() + ' ' + element[0].innerText); }
      };
      public eventSources: app.calendars.IEventSource[];
      public selectedRange: moment.Range;
      public title: string;
      public visibleRange: moment.Range;

      private createEvent(targetDate: moment.Moment) {
         this.common.$state.go('^.eventSchedule', {
            targetDate: targetDate,
            source: this.source,
            sourceId: this.sourceId
         });
      }

      private eventClick(event: app.calendars.IEvent) {
         const parent = angular.element(document.body);

         return this.$mdDialog.show({
            parent,
            fullscreen: false,
            templateUrl: 'app/calendars/events/summary-dialog.html',
            controller: 'EventSummaryDialogController',
            controllerAs: '$ctrl',
            locals: {
               event: event,
               eventScheduleId: event.eventScheduleId
            }
         })
            .then((edit: string) => {
               if (edit === 'eventSchedule') {
                  this.common.$state.go('^.eventSchedule', { eventScheduleId: event.eventScheduleId });
               }
               else {
                  this.common.$state.go('^.event', { event });
               }
            })
            .catch((error) => { if (error) throw error; });
      }

      public today() {
         this.$calendar.today();
      }

      public next() {
         this.$calendar.next();
      }

      public prev() {
         this.$calendar.prev();
      }

      public changeView(view: string) {
         this.$calendar.changeView(view);
      }

      public refresh = () => {
         this.trainingCalendarData.refresh(this.source, this.sourceId);
      };

      public showFilters() {
         this.$mdSidenav('calendarFilters').toggle();
      }

      private $onInit() {
         this.eventSources = this.trainingCalendarData.eventSources;
         this.calendarOptions.viewRender = this.onViewRender;
         this.loadState();

         const cancelToken = this.$scope.$on(app.calendars.EVENT_ADDED, this.refresh);
         this.$scope.$on('$destroy', cancelToken);

         this.common.$timeout(this.setContentHeight, 100);
         angular.element(this.common.$window).bind('resize', this.setContentHeight);
      }

      private onViewRender = (view: app.calendars.fc.IView) => {
         this.$calendar = view.calendar;
         this.title = view.title;

         let reload = true;
         if (this.state.dateRange) {
            reload = !(this.state.dateRange.contains(view.start) && this.state.dateRange.contains(view.end));
         }

         let viewDate = view.start.clone();
         if (view.type === 'month' && view.start.date() > 1) {
            viewDate = viewDate.startOf('month').add(1, 'month');
         }
         this.trainingCalendarState.update({
            viewType: view.type,
            viewDate: viewDate
         });

         const start = view.start.local();
         const end = view.end.local();

         this.visibleRange = moment.range(start, end);
         this.selectedRange = moment.range(start, start);

         if (reload) {
            this.state.dateRange = this.visibleRange;
            this.common.$timeout(this.refresh);
         }
      };

      private loadState = () => {
         this.calendarOptions.defaultView = this.state.viewType || 'month';
         this.calendarOptions.defaultDate = this.state.viewDate;
      };

      private setContentHeight = () => {
         this.$calendar.option('contentHeight', angular.element('#calendar').height());
      };
   }
}