namespace app.config {
   @Component('app.config', 'pamLookupPicker', {
      templateUrl: 'app/config/components/lookup-picker/lookup-picker.html',
      bindings: {
         autoSelect: '<',
         includeNone: '<?',
         includeBlank: '<?',
         inputName: '@',
         label: '@',
         lookupType: '@',
         placeholder: '@',
         required: '<',
         value: '=',
         valueChanged: '&?',
         autoFocus: '<'
      },
      require: {
         form: '^^form'
      }
   })
   class SelectLookup {
      static $inject = ['common'];
      constructor(private common: core.ICommonService) {
         'ngInject';
      }

      public autoSelect: boolean;
      public intialized = false;
      public lookups: string[];
      public lookupType: string;
      public value: string;
      public autoFocus: boolean;

      public valueChanged(param: { value: string }) {
      }

      public changeValue() {
         this.valueChanged({ value: this.value });
      }

      private $onChanges() {
         if (this.lookupType && !this.intialized) {
            this.intialized = true;
            this.common.configHelper
               .getLookupValues(this.lookupType)
               .then(lookups => {
                  this.lookups = lookups;
                  if (this.autoSelect && this.lookups.length === 1) {
                     this.value = this.lookups[0];
                  }
               });
         }
      }
   }
}