namespace app.activities {
   @Component('app.activities', 'ceActivityCard', {
      templateUrl: 'app/activities/activity-list/activity-card.html',
      bindings: {
         activity: '<',
         onClose: '&',
         onPostComment: '&'
      }
   })
   class GenericActivityCardComponent {
      static $inject = ['common'];
      constructor(private common: core.ICommonService) {
         'ngInject';
      }

      public activity: IActivity;
      public href: string;

      private $onChanges() {
         switch (this.activity.activityType) {
            default:
               this.href = this.common.$state.href('^.activity', { activityId: this.activity.id });
         }
      }
   }
}