namespace app.analysis {
   const labelFormatter = (label: string, series: any) => {
      return `<div style="font-size:10pt; text-align: center; padding: 2px; color: white;">${label}<br/>${series.data[0][1]} (${Math.round(series.percent)}%)</div>`;
   };

   @Component('app.analysis', 'aimsAnalysisPieChart', {
      template: '<flot dataset="$ctrl.dataset" options="$ctrl.options" ng-if="$ctrl.dataset"></flot>',
      bindings: {
         chartData: '<'
      }
   })
   class PieChartComponent {
      static $inject = ['analysisConstants', 'analysisData'];
      constructor(
         private analysisConstants: IAnalysisConstants,
         private analysisData: IAnalysisDataService) {
         'ngInject';
      }

      public chartData: IChartData[];
      public dataset: any[];
      public dataSeries: Series;
      public percentSeries: Series;
      public options = {
         series: {
            pie: {
               show: true,
               radius: 1,
               label: {
                  show: true,
                  formatter: labelFormatter,
                  radius: 3 / 4,
                  background: {
                     opacity: 0.5,
                     color: '#000'
                  }
               }
            }
         },
         legend: { show: false }
      };
      public showPercentages: boolean;

      private $onChanges() {
         if (!this.chartData) return;

         this.dataset = this.chartData.map((d, i) => {
            return {
               label: d.group0,
               data: d.data,
               color: this.analysisConstants.colorPalette[i % this.analysisConstants.colorPalette.length]
            };
         });
      }
   }
}