namespace app.projects {
   @Component('app.projects', 'ceProjectEntityListing', {
      templateUrl: 'app/projects/entities/entity-listing/entity-listing.html',
      bindings: {
         projectId: '<'
      }
   })
   class ProjectEntityListing {
      static $inject = ['$mdDialog', 'common', 'datacontext'];
      constructor(
         private $mdDialog: angular.material.IDialogService,
         private common: core.ICommonService,
         private datacontext: data.IDataContextService) {
         'ngInject';
      }

      public entities: IEntityListing[];
      public entity: IEntityBasicInfo;
      public projectId: string;

      private $onInit = () => {
         this.getEntities();
      }

      private getEntities = () => {
         return this.datacontext.projects
            .getEntityListing(this.projectId)
            .then(result => {
               this.entities = result;
            });
      };

      public deleteEntity(entity: IEntityListing) {
         this.common.confirmation.show({
            title: 'Delete Relationship',
            message: 'Are you sure you want to delete this entity relationship?',
            ok: 'Delete',
            cancel: 'Keep'
         }).then(() => {
            this.datacontext.projects
               .deleteEntity(entity.id)
               .then(() => {
                  this.getEntities();
               });
         }).catch(angular.noop);
      }
   }
}