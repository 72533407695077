namespace app.utils {
   'use strict';

   export interface IInflectorFilter {
      (value: string, inflector?: string): string;
   }

   const inflector = () => {
      const tokenize = (text: string) => {
         text = text.replace(/([A-Z])|([\-|\_])/g, (_, $1) => ' ' + ($1 || ''));
         return text.replace(/\s\s+/g, ' ').trim().toLowerCase().split(' ');
      };

      const capitalizeTokens = (tokens: string[]) => {
         const result = [];
         angular.forEach(tokens, (token) => {
            result.push(token.charAt(0).toUpperCase() + token.substr(1));
         });
         return result;
      };

      const camelCase = (tokens: string[]) => {
         const result = [];
         angular.forEach(tokens, (token) => {
            if (tokens.indexOf(token) === 0) {
               result.push(token);
            } else {
               result.push(token.charAt(0).toUpperCase() + token.substr(1));
            }
         });
         return result;
      };

      const inflectors = {
         humanize: (value: string) => capitalizeTokens(tokenize(value)).join(' '),
         underscore: (value: string) => tokenize(value).join('_'),
         variable: (value: string) => {
            const tokens = tokenize(value);
            value = value[0] + capitalizeTokens(tokens.slice(1)).join('');
            return value;
         },
         camelCase: (value: string) => camelCase(tokenize(value)).join('')
      };

      return (text: string, inflector: string | boolean) => {
         if (inflector !== false && angular.isString(text)) {
            inflector = inflector || 'humanize';
            return inflectors[inflector as string](text);
         } else {
            return text;
         }
      };
   };

   angular
      .module('app.utils')
      .filter('inflector', inflector);
}