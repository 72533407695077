namespace app.identity {
    @Component('app.identity', 'aimsUserLookup', {
        templateUrl: 'app/identity/components/user-lookup/user-lookup.html',
        bindings: {
            label: '@',
            inputName: '@',
            required: '=',
            value: '='
        },
        require: {
            form: '^'
        }
    })
    class UserLookup {
        static $inject = ['datacontext'];
        constructor(private datacontext: data.IDataContextService) {
            'ngInject';
        }

        public getUsers(q: string) {
            return this.datacontext.identity.getUsers({ q });
        }
    }
}