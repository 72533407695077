namespace app.activities {
   @Component('app.activities', 'ceActivityCommentForm', {
      templateUrl: 'app/activities/activity-list/comment-form.html',
      bindings: {
         onPost: '&'
      }
   })
   class ActivityCommentFormComponent {
      static $inject = ['common'];
      constructor(private common: core.ICommonService) {
         'ngInject';
      }

      public onPost: (args: { value: string }) => angular.IPromise<boolean>;
      public value: string;
      public showActions = false;
      public isPosting = false;
      public photoId: string;

      public handleKeypress(event: KeyboardEvent) {
         if (event.keyCode === 10 && event.ctrlKey) {
            this.submit();
         }
      }

      public submit() {
         this.isPosting = true;
         const { value } = this;
         this.onPost({ value }).then(result => {
            if (result) this.reset();
         }).finally(() => this.isPosting = false);
      }

      private $onInit = () => {
         this.photoId = this.common.auth.user.photoId;
      };

      private reset = () => {
         delete this.value;
         this.showActions = false;
      };
   }
}