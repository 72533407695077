namespace app.persons.views {
   @Component('app.persons.views', 'ceCallerInformation', {
      templateUrl: 'app/persons/views/caller-information/caller-information.html',
      bindings: {
         data: '<',
         onEdit: '&'
      }
   })
   class CallerInformation {
   }
}