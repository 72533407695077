namespace app.reports {
   @Component('app.reports', 'ceReportListing', {
      templateUrl: 'app/reports/listing/report-list.html',
      bindings: {
      }
   })
   class ReportList {
      static $inject = ['$mdDialog', 'datacontext'];
      constructor(
         private $mdDialog: angular.material.IDialogService,
         private datacontext: data.IDataContextService) {
         'ngInject';
      }

      public reports = [] as IReport[];
      public categorizedReports: IDictionary<IReport[]>;

      private $onInit() {
         this.getReportListing();
      }

      private getReportListing() {
         this.reports.push(
            {
               category: 'Data Audit Reports',
               label: 'Client Case Closure Data',
               name: 'ClientCaseClosureMissingDataByPrimaryStaff',
               description: 'Lists Closed Client Cases with Missing Data',
               permission: 'reports.viewmanagerial',
               parameters: [
                  { label: 'From Date', name: 'FromDate', dataType: 'Date', value: null, required: true },
                  { label: 'To Date', name: 'ToDate', dataType: 'Date', value: null, required: true },
               ]
            },
            {
               category: 'Data Audit Reports',
               label: 'Client Case Intake Data',
               name: 'ClientCaseMissingDataByIntakeStaff',
               description: 'Lists Client Cases with Missing Data',
               permission: 'reports.viewmanagerial',
               parameters: []
            },
            {
               category: 'Data Audit Reports',
               label: 'Client Cases Ready for Closure',
               name: 'Client Case Ready For Closure',
               description: 'Lists Client Cases with Ready for Closure Status',
               permission: 'reports.viewmanagerial',
               parameters: []
            },
            {
               category: 'Timekeeping Reports',
               label: 'Employee Leave',
               name: 'Employee Leave',
               description: 'Lists all Leave time for a Date Range',
               permission: 'reports.viewmanagerial',
               parameters: [
                  { label: 'From Date', name: 'FromDate', dataType: 'Date', value: null, required: true },
                  { label: 'To Date', name: 'ToDate', dataType: 'Date', value: null, required: true },
                  { label: 'Employee Type', name: 'EmployeeType', dataType: 'Select', value: null, options: [null, 'Exempt', 'Non-Exempt'] }
               ]
            },
            {
               category: 'Timekeeping Reports',
               label: 'Individual Employee Leave and Overtime',
               name: 'Employee Leave And Overtime',
               description: 'Summarizes Leave and Overtime for up to 4 weeks for an Employee',
               permission: 'reports.view',
               parameters: [
                  { label: 'UserName', name: 'UserName', dataType: 'TimeslipUserSearch', value: null, required: true },
                  { label: 'UserId', name: 'UserId', dataType: 'hidden', value: null, required: true },
                  { label: 'From Date', name: 'FromDate', dataType: 'Date', value: null, required: true },
                  { label: 'To Date', name: 'ToDate', dataType: 'Date', value: null, required: true },
               ]
            },
            {
               category: 'Timekeeping Reports',
               label: 'Employee Timesheet',
               name: 'Employee Timesheet',
               description: 'Timesheet for an Employee',
               permission: 'reports.view',
               parameters: [
                  { label: 'UserName', name: 'UserName', dataType: 'TimeslipUserSearch', value: null, required: true },
                  { label: 'UserId', name: 'UserId', dataType: 'hidden', value: null, required: true },
                  { label: 'From Date', name: 'FromDate', dataType: 'Date', value: null, required: true },
                  { label: 'To Date', name: 'ToDate', dataType: 'Date', value: null, required: true },
               ]
            },
            {
               category: 'Timekeeping Reports',
               label: 'Employee Timesheet By Day',
               name: 'Employee Timesheet By Day',
               description: 'Timesheet for an Employee By Day - 16 day maximum',
               permission: 'reports.view',
               parameters: [
                  { label: 'UserName', name: 'UserName', dataType: 'TimeslipUserSearch', value: null, required: true },
                  { label: 'UserId', name: 'UserId', dataType: 'hidden', value: null, required: true },
                  { label: 'From Date', name: 'FromDate', dataType: 'Date', value: null, required: true },
                  { label: 'To Date', name: 'ToDate', dataType: 'Date', value: null, required: true },
               ]
            },
            {
               category: 'Other Reports',
               label: 'I&R Docket',
               name: 'IandR Docket',
               description: 'Lists all I&Rs within a date range',
               permission: 'reports.view',
               parameters: [
                  { label: 'From Date', name: 'FromDate', dataType: 'Date', value: moment().startOf('month').format('MM/DD/YYYY'), required: true },
                  { label: 'To Date', name: 'ToDate', dataType: 'Date', value: moment().endOf('month').format('MM/DD/YYYY'), required: true },
               ]
            },
            {
               category: 'Other Reports',
               label: 'Closure Summary',
               name: 'ClosureSummary',
               description: 'Lists Closure Summaries for Cases',
               permission: 'reports.view',
               parameters: [
                  { label: 'From Date', name: 'FromDate', dataType: 'Date', value: moment().startOf('month').format('MM/DD/YYYY'), required: true },
                  { label: 'To Date', name: 'ToDate', dataType: 'Date', value: moment().endOf('month').format('MM/DD/YYYY'), required: true },
                  { label: 'Case Type', name: 'CaseType', dataType: 'Select', value: '', options: ['Client Cases', 'Systemic Cases', 'Monitoring'] },
                  { label: '\'Yes\' for', name: 'FieldName', dataType: 'Select', value: '', options: ['Secured Judicial Processes', 'Self Advocacy', 'Success Story', 'Systemic Impact'] },
                  { label: 'Region', name: 'Region', dataType: 'Lookup', value: '' },
                  { label: 'Legal Team', name: 'LegalTeamId', dataType: 'DictionarySelect' },
                  { label: 'LegalTeamName', name: 'LegalTeamName', dataType: 'hidden', value: null },
                  { label: 'Funding', name: 'FundingProgramId', dataType: 'DictionarySelect' },
                  { label: 'FundingProgramName', name: 'FundingProgramName', dataType: 'hidden', value: null },
               ]
            },
            {
               category: 'Data Audit Reports',
               label: 'I&R Data',
               name: 'IandR Missing Data By Primary Staff',
               description: 'Lists all I&Rs within a date range that are missing data',
               permission: 'reports.viewmanagerial',
               parameters: [
                  { label: 'From Date', name: 'FromDate', dataType: 'Date', value: moment().startOf('month').format('MM/DD/YYYY'), required: true },
                  { label: 'To Date', name: 'ToDate', dataType: 'Date', value: moment().endOf('month').format('MM/DD/YYYY'), required: true },
               ]
            },
            {
               category: 'Funder Reports',
               label: 'LTF/CBF',
               name: 'LTF CBF',
               description: 'Runs the LTF CBF Report',
               permission: 'reports.view',
               parameters: [
                  { label: 'From Date', name: 'FromDate', dataType: 'Date', value: moment().startOf('month').format('MM/DD/YYYY'), required: true },
                  { label: 'To Date', name: 'ToDate', dataType: 'Date', value: moment().endOf('month').format('MM/DD/YYYY'), required: true },
                  { label: 'Case Type', name: 'Type', dataType: 'Select', value: 'Type', options: ['Closed', 'Handled', 'Opened'], required: true },
                  { label: 'Region', name: 'Region', dataType: 'Lookup', value: '' },
                  { label: 'Show Detail', name: 'ShowDetail', dataType: 'Boolean', value: 'False', required: true },
               ]
            },
            {
               category: 'Funder Reports',
               label: 'IDHS Monitoring',
               name: 'Monitoring',
               description: 'Runs the IDHS Monitoring Report',
               permission: 'reports.view',
               parameters: [
                  { label: 'From Date', name: 'FromDate', dataType: 'Date', value: moment().startOf('month').format('MM/DD/YYYY'), required: true },
                  { label: 'To Date', name: 'ToDate', dataType: 'Date', value: moment().endOf('month').format('MM/DD/YYYY'), required: true }
               ]
            },
            {
               category: 'Data Audit Reports',
               label: 'Missing Adverse Party',
               name: 'Missing Adverse Party',
               description: 'Lists all active cases missing an Adverse Party',
               permission: 'reports.viewmanagerial',
               parameters: []
            },
            {
               category: 'Data Audit Reports',
               label: 'Systemic Case Closure Data',
               name: 'Systemic Case Closure Missing Data By Primary Staff',
               description: 'Lists Closed Systemic Cases with Missing Data',
               permission: 'reports.viewmanagerial',
               parameters: [
                  { label: 'From Date', name: 'FromDate', dataType: 'Date', value: null, required: true },
                  { label: 'To Date', name: 'ToDate', dataType: 'Date', value: null, required: true },
               ]
            },

            {
               category: 'Data Audit Reports',
               label: 'Systemic Case Data',
               name: 'Systemic Case Missing Data By Primary Staff',
               description: 'Lists Systemic Cases with Missing Data',
               permission: 'reports.viewmanagerial',
               parameters: []
            },
            {
               category: 'Timekeeping Reports',
               label: 'Timeslips by Employee',
               name: 'Timeslips By Employee',
               description: 'Lists all Timeslips grouped by Employee then by Funding Source',
               permission: 'reports.viewmanagerial',
               parameters: [
                  { label: 'From Date', name: 'FromDate', dataType: 'Date', value: moment().startOf('month').format('MM/DD/YYYY'), required: true },
                  { label: 'To Date', name: 'ToDate', dataType: 'Date', value: moment().endOf('month').format('MM/DD/YYYY'), required: true },
                  { label: 'Include Leave', name: 'IncludeLeave', dataType: 'Boolean', value: 'True', required: true },
                  { label: 'Include Volunteer', name: 'IncludeVolunteer', dataType: 'Boolean', value: 'False', required: true },
                  { label: 'Sort By', name: 'SortBy', dataType: 'Select', value: 'Hours', options: ['Employee then Hours', 'Employee then Program'] }
               ]
            },
            {
               category: 'Timekeeping Reports',
               label: 'Timeslips By Employee By Category',
               name: 'Timeslips By Employee By Category',
               description: 'Lists all Timeslips grouped by Employee then by Category',
               permission: 'reports.viewmanagerial',
               parameters: [
                  { label: 'From Date', name: 'FromDate', dataType: 'Date', value: moment().startOf('month').format('MM/DD/YYYY'), required: true },
                  { label: 'To Date', name: 'ToDate', dataType: 'Date', value: moment().endOf('month').format('MM/DD/YYYY'), required: true },
                  { label: 'Include Leave', name: 'IncludeLeave', dataType: 'Boolean', value: 'True', required: true },
                  { label: 'Include Volunteer', name: 'IncludeVolunteer', dataType: 'Boolean', value: 'False', required: true }
               ]
            },
            {
               category: 'Timekeeping Reports',
               label: 'Timeslips By Employee By Funding Program',
               name: 'Timeslips By Employee By Funding Program',
               description: 'Lists all Timeslips grouped by Employee then by Funding Program - Choose Report Format of Excel',
               permission: 'reports.viewmanagerial',
               parameters: [
                  { label: 'From Date', name: 'FromDate', dataType: 'Date', value: null, required: true },
                  { label: 'To Date', name: 'ToDate', dataType: 'Date', value: null, required: true },
               ]
            },
            {
               category: 'Timekeeping Reports',
               label: 'Timeslips By Source',
               name: 'Timeslips By Source',
               description: 'Timeslips for a Case, Project, or Training',
               permission: 'reports.view',
               parameters: [
                  { label: 'SourceType', name: 'SourceType', dataType: 'SourceSearch', value: null, required: true },
                  { label: 'SourceId', name: 'SourceId', dataType: 'hidden', value: null, required: true },
                  { label: 'SourceName', name: 'SourceName', dataType: 'hidden', value: null, required: true },
                  { label: 'From Date', name: 'FromDate', dataType: 'Date', value: null, required: false },
                  { label: 'To Date', name: 'ToDate', dataType: 'Date', value: null, required: false },
                  { label: 'Include Volunteer', name: 'IncludeVolunteer', dataType: 'Boolean', value: 'False', required: true }
               ]
            },
            {
               category: 'Data Audit Reports',
               label: 'Training Data',
               name: 'Training Missing Data By Primary Trainer',
               description: 'Lists all Trainings within a date range that are missing data',
               permission: 'reports.viewmanagerial',
               parameters: [
                  { label: 'From Date', name: 'FromDate', dataType: 'Date', value: moment().startOf('month').format('MM/DD/YYYY'), required: true },
                  { label: 'To Date', name: 'ToDate', dataType: 'Date', value: moment().endOf('month').format('MM/DD/YYYY'), required: true },
               ]
            },
            {
               category: 'Other Reports',
               label: 'Weekly CAM Report',
               name: 'Weekly CAM Report',
               description: 'Lists Information for Weekly CAM Report',
               permission: 'reports.view',
               parameters: [
                  { label: 'Legal Team', name: 'LegalTeamId', dataType: 'DictionarySelect', required: true }
               ]
            },
            {
               category: 'Funder Reports',
               label: 'PAIR PPR',
               name: 'FY2019 PAIR',
               description: 'PAIR Program Performance Report',
               permission: 'reports.view',
               parameters: [
                  { label: 'From Date', name: 'FromDate', dataType: 'Date', value: null, required: false },
                  { label: 'To Date', name: 'ToDate', dataType: 'Date', value: null, required: false },
               ]
            },
            {
               category: 'Funder Reports',
               label: 'PAIMI PPR',
               name: 'FY2019 PAIMI',
               description: 'PAIMI Program Performance Report',
               permission: 'reports.view',
               parameters: [
                  { label: 'From Date', name: 'FromDate', dataType: 'Date', value: null, required: false },
                  { label: 'To Date', name: 'ToDate', dataType: 'Date', value: null, required: false },
               ]
            },
            {
               category: 'Funder Reports',
               label: 'PABSS PPR',
               name: 'FY2019 PABSS',
               description: 'PABSS Program Performance Report',
               permission: 'reports.view',
               parameters: [
                  { label: 'From Date', name: 'FromDate', dataType: 'Date', value: null, required: false },
                  { label: 'To Date', name: 'ToDate', dataType: 'Date', value: null, required: false },
               ]
            },
            {
               category: 'Funder Reports',
               label: 'CAP PPR - Individuals',
               name: 'FY2019CAPIndividual',
               description: 'CAP Program Performance Report',
               permission: 'reports.view',
               parameters: [
                  { label: 'From Date', name: 'FromDate', dataType: 'Date', value: null, required: false },
                  { label: 'To Date', name: 'ToDate', dataType: 'Date', value: null, required: false },
               ]
            },
            {
               category: 'Funder Reports',
               label: 'CAP PPR - Groups',
               name: 'FY2019CAPGroups',
               description: 'CAP Program Performance Report',
               permission: 'reports.view',
               parameters: [
                  { label: 'From Date', name: 'FromDate', dataType: 'Date', value: null, required: false },
                  { label: 'To Date', name: 'ToDate', dataType: 'Date', value: null, required: false },
               ]
            },
            {
               category: 'Funder Reports',
               label: 'PAAT PPR - Individuals',
               name: 'FY2019ONEPAATIndividual',
               description: 'PAAT ONE Program Performance Report - Individuals',
               permission: 'reports.view',
               parameters: [
                  { label: 'From Date', name: 'FromDate', dataType: 'Date', value: null, required: false },
                  { label: 'To Date', name: 'ToDate', dataType: 'Date', value: null, required: false },
               ]
            },
            {
               category: 'Funder Reports',
               label: 'PAAT PPR - Groups',
               name: 'FY2019ONEPAATGroup',
               description: 'PAAT ONE Program Performance Report - Groups',
               permission: 'reports.view',
               parameters: [
                  { label: 'From Date', name: 'FromDate', dataType: 'Date', value: null, required: false },
                  { label: 'To Date', name: 'ToDate', dataType: 'Date', value: null, required: false },
               ]
            },
            {
               category: 'Funder Reports',
               label: 'PAAT PPR - Results',
               name: 'FY2019ONEPAATResults',
               description: 'PAAT ONE Program Performance Report - Results',
               permission: 'reports.view',
               parameters: [
                  { label: 'From Date', name: 'FromDate', dataType: 'Date', value: null, required: false },
                  { label: 'To Date', name: 'ToDate', dataType: 'Date', value: null, required: false },
               ]
            },
            {
               category: 'Funder Reports',
               label: 'PADD PPR - Individuals',
               name: 'FY2019ONEPADDIndividual',
               description: 'PADD ONE Program Performance Report - Individuals',
               permission: 'reports.view',
               parameters: [
                  { label: 'From Date', name: 'FromDate', dataType: 'Date', value: null, required: false },
                  { label: 'To Date', name: 'ToDate', dataType: 'Date', value: null, required: false },
               ]
            },
            {
               category: 'Funder Reports',
               label: 'PADD PPR - Groups',
               name: 'FY2019ONEPADDGroup',
               description: 'PADD ONE Program Performance Report - Groups',
               permission: 'reports.view',
               parameters: [
                  { label: 'From Date', name: 'FromDate', dataType: 'Date', value: null, required: false },
                  { label: 'To Date', name: 'ToDate', dataType: 'Date', value: null, required: false },
               ]
            },
            {
               category: 'Funder Reports',
               label: 'PADD PPR - Results',
               name: 'FY2019ONEPADDResults',
               description: 'PADD ONE Program Performance Report - Results',
               permission: 'reports.view',
               parameters: [
                  { label: 'From Date', name: 'FromDate', dataType: 'Date', value: null, required: false },
                  { label: 'To Date', name: 'ToDate', dataType: 'Date', value: null, required: false },
               ]
            },
            {
               category: 'Funder Reports',
               label: 'PATBI PPR - Individuals',
               name: 'FY2019ONEPATBIIndividual',
               description: 'PATBI ONE Program Performance Report - Individuals',
               permission: 'reports.view',
               parameters: [
                  { label: 'From Date', name: 'FromDate', dataType: 'Date', value: null, required: false },
                  { label: 'To Date', name: 'ToDate', dataType: 'Date', value: null, required: false },
               ]
            },
            {
               category: 'Funder Reports',
               label: 'PATBI PPR - Groups',
               name: 'FY2019ONEPATBIGroup',
               description: 'PATBI ONE Program Performance Report - Groups',
               permission: 'reports.view',
               parameters: [
                  { label: 'From Date', name: 'FromDate', dataType: 'Date', value: null, required: false },
                  { label: 'To Date', name: 'ToDate', dataType: 'Date', value: null, required: false },
               ]
            },
            {
               category: 'Funder Reports',
               label: 'PATBI PPR - Results',
               name: 'FY2019ONEPATBIResults',
               description: 'PATBI ONE Program Performance Report - Results',
               permission: 'reports.view',
               parameters: [
                  { label: 'From Date', name: 'FromDate', dataType: 'Date', value: null, required: false },
                  { label: 'To Date', name: 'ToDate', dataType: 'Date', value: null, required: false },
               ]
            },
            {
               category: 'Funder Reports',
               label: 'PAVA PPR - Individuals',
               name: 'FY2019ONEPAVAIndividual',
               description: 'PAVA ONE Program Performance Report - Individuals',
               permission: 'reports.view',
               parameters: [
                  { label: 'From Date', name: 'FromDate', dataType: 'Date', value: null, required: false },
                  { label: 'To Date', name: 'ToDate', dataType: 'Date', value: null, required: false },
               ]
            },
            {
               category: 'Funder Reports',
               label: 'PAVA PPR - Groups',
               name: 'FY2019ONEPAVAGroup',
               description: 'PAVA ONE Program Performance Report - Groups',
               permission: 'reports.view',
               parameters: [
                  { label: 'From Date', name: 'FromDate', dataType: 'Date', value: null, required: false },
                  { label: 'To Date', name: 'ToDate', dataType: 'Date', value: null, required: false },
               ]
            },
            {
               category: 'Funder Reports',
               label: 'PAVA PPR - Results',
               name: 'FY2019ONEPAVAResults',
               description: 'PAVA ONE Program Performance Report - Results',
               permission: 'reports.view',
               parameters: [
                  { label: 'From Date', name: 'FromDate', dataType: 'Date', value: null, required: false },
                  { label: 'To Date', name: 'ToDate', dataType: 'Date', value: null, required: false },
               ]
            },
            {
               category: 'Other Reports',
               label: 'Statutes of Limitations',
               name: 'StatutesOfLimitations',
               description: 'Lists Statutes of Limitations',
               permission: 'reports.view',
               parameters: [
                  { label: 'From Date', name: 'FromDate', dataType: 'Date', value: null, required: true },
                  { label: 'To Date', name: 'ToDate', dataType: 'Date', value: null, required: true },
                  { label: 'UserName', name: 'UserName', dataType: 'UserSearch', value: null },
                  { label: 'UserId', name: 'UserId', dataType: 'hidden', value: null },
                  { label: 'Legal Team', name: 'LegalTeamId', dataType: 'DictionarySelect' },
                  { label: 'LegalTeamName', name: 'LegalTeamName', dataType: 'hidden', value: null },
               ]
            },
            {
               category: 'Other Reports',
               label: 'Race Counts',
               name: 'RaceCounts',
               description: 'Runs the Race Count Report',
               permission: 'reports.view',
               parameters: [
                  { label: 'From Date', name: 'FromDate', dataType: 'Date', value: moment().startOf('month').format('MM/DD/YYYY'), required: true },
                  { label: 'To Date', name: 'ToDate', dataType: 'Date', value: moment().endOf('month').format('MM/DD/YYYY'), required: true },
                  { label: 'Case Type', name: 'Type', dataType: 'Select', value: 'Type', options: ['Closed', 'Handled', 'Opened'], required: true },
                  { label: 'Legal Team', name: 'LegalTeamId', dataType: 'DictionarySelect' },
                  { label: 'LegalTeamName', name: 'LegalTeamName', dataType: 'hidden', value: null }
               ]
            },
            {
               category: 'Other Reports',
               label: 'Case Review',
               name: 'CaseReview',
               description: 'Lists Information for Case Reviews',
               permission: 'reports.view',
               parameters: [
                  { label: 'Opened From', name: 'OpenFrom', dataType: 'Date', value: moment().startOf('month').format('MM/DD/YYYY'), required: true },
                  { label: 'Opened To', name: 'OpenTo', dataType: 'Date', value: moment().endOf('month').format('MM/DD/YYYY'), required: true },
                  { label: 'Reviewed From', name: 'ReviewedFrom', dataType: 'Date', value: null, required: false },
                  { label: 'Reviewed To', name: 'ReviewedTo', dataType: 'Date', value: null, required: false },
                  { label: 'Case Type', name: 'CaseType', dataType: 'Select', value: '', options: ['Client Cases', 'Systemic Cases', 'Monitoring'], required: false },
                  { label: 'LegalTeamName', name: 'LegalTeamName', dataType: 'hidden', value: null },
                  { label: 'Legal Team', name: 'LegalTeamId', dataType: 'DictionarySelect', required: false },
                  { label: 'UserName', name: 'UserName', dataType: 'UserSearch', value: null },
                  { label: 'UserId', name: 'UserId', dataType: 'hidden', value: null },
               ]
            },
            {
               category: 'Other Reports',
               label: 'Representation',
               name: 'Representation',
               description: 'Runs the Representation Report',
               permission: 'reports.view',
               parameters: [
                  { label: 'From Date', name: 'FromDate', dataType: 'Date', value: moment().startOf('month').format('MM/DD/YYYY'), required: true },
                  { label: 'To Date', name: 'ToDate', dataType: 'Date', value: moment().endOf('month').format('MM/DD/YYYY'), required: true },
               ]
            },
            {
               category: 'Other Reports',
               label: 'At Poverty Level',
               name: 'AtPovertyLevel',
               description: 'Lists all clients at Poverty Level',
               permission: 'reports.view',
               parameters: [
                  { label: 'From Date', name: 'FromDate', dataType: 'Date', value: moment().startOf('month').format('MM/DD/YYYY'), required: true },
                  { label: 'To Date', name: 'ToDate', dataType: 'Date', value: moment().endOf('month').format('MM/DD/YYYY'), required: true },
               ]
            },
            {
               category: 'Other Reports',
               label: 'Diversity',
               name: 'Diversity',
               description: 'Lists all race and ethnicity percentages for all cases',
               permission: 'reports.view',
               parameters: [
                  { label: 'From Date', name: 'FromDate', dataType: 'Date', value: moment().startOf('month').format('MM/DD/YYYY'), required: true },
                  { label: 'To Date', name: 'ToDate', dataType: 'Date', value: moment().endOf('month').format('MM/DD/YYYY'), required: true },
                  { label: 'Case Type', name: 'CaseType', dataType: 'Select', value: 'All', options: ['All', 'Client Case', 'I&R Case', 'Request'], required: true },
               ]
            },
         );
         this.categorizeReports();
      }

      public categorizeReports() {
         this.categorizedReports = _.chain(this.reports)
            .sortBy(r => { return r.category.replace('Other', 'z'); })
            .groupBy('category')
            .value();
      }

      public showReportDialog(report: IReport) {
         report.parameters = this.processParameters(report);

         return this.$mdDialog.show({
            fullscreen: true,
            templateUrl: 'app/reports/report-dialog/report-dialog.html',
            controller: 'ReportDialogController',
            controllerAs: '$ctrl',
            focusOnOpen: false,
            locals: {
               report: report
            }
         })
            .catch((error) => { if (error) throw error; })
            .finally(() => angular.noop);
      }

      private processParameters(report: IReport) {
         let parameters = report.parameters;
         _.forEach(parameters, p => {
            switch (p.name) {
               case 'LegalTeamId': {
                  let dictionaryOptions = {} as IDictionary<string>[];
                  this.getLegalTeams().then(legalTeams => {
                     _.forEach(legalTeams, lt => dictionaryOptions[lt.id] = lt.name);
                  });
                  p.dictionaryOptions = dictionaryOptions;
                  break;
               }
               case 'FundingProgramId': {
                  let dictionaryOptions = {} as IDictionary<string>[];
                  this.getFundingPrograms().then(programs => {
                     _.forEach(programs, p => dictionaryOptions[p.fundingProgramId] = p.name);
                  });
                  p.dictionaryOptions = dictionaryOptions;
                  break;
               }

            }
         });
         return parameters;
      }

      private getLegalTeams() {
         return this.datacontext.config.legalTeams.getLegalTeams(false);
      }

      private getFundingPrograms() {
         return this.datacontext.funding.getFundingPrograms(true, 'Systemic Case');
      }
   }
}