namespace app.mail {
   @Component('app.mail', 'ceInboxView', {
      templateUrl: 'app/mail/inbox/inbox-view.html'
   })
   class InboxComponent {
      static $inject = ['common', 'datacontext', 'blobHelper'];
      constructor(
         private common: core.ICommonService,
         private datacontext: data.IDataContextService,
         private blobHelper: utils.IBlobHelperService) {
         'ngInject';
      }

      public messages: data.IPagedList<IMessage>;
      public messagesSelected: IMessage[] = [];
      public promise: angular.IPromise<any>;
      public paging: data.IPagedListParams = {
         page: 1,
         pageSize: 100,
         order: '-receivedAt'
      };
      private myMessagesOnly: boolean = true;
      private q: string;

      public deleteSelected() {
      }

      public delete(message: IMessage) {
         this.common.confirmation.show({
            title: 'Delete Message',
            message: 'Are you sure you want to delete this message?',
            ok: 'Delete',
            cancel: 'Keep'
         }).then(() => {
            this.datacontext.mail
               .deleteMessage(message.id)
               .then(result => {
                  if (result) {
                     _.remove(this.messages.list, message);
                     _.remove(this.messagesSelected, message);
                     this.messages.count--;
                  }
               });
         }).catch(angular.noop);
      }

      public download(message: IMessage, attachment: IAttachment) {
         this.datacontext.mail
            .downloadAttachment(message.id, attachment.id)
            .then(result => this.blobHelper.open(result));
      }

      public fetch() {
         this.datacontext.mail.fetchNewMessages()
            .then(this.getMessages);
      }

      public linkMessages() {
         const messageIds = _.map(this.messagesSelected, 'id').join(',');
         this.common.$state.go('^.linkMessages', { messageIds });
      }

      private $onInit() {
         this.getMessages();
      }

      public filterMessages = (myMessagesOnly: boolean, q: string) => {
         this.myMessagesOnly = myMessagesOnly;
         this.q = q;
         this.getMessages();
      }

      private getMessages = () => {
         const params: IGetMessagesParams = {
            myMessagesOnly: this.myMessagesOnly,
            q: this.q,
            ...this.paging
         };

         this.promise = this.datacontext.mail
            .getMessages(params)
            .then(result => {
               this.messages = result;
            });
      };
   }
}