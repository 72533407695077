namespace app.clientCases {
   'use strict';

   const routes: core.routing.IStateExtended[] = [
      {
         name: 'app.clientCaseNoTimeslips',
         url: '/notimeslips',
         template: '<ce-client-case-no-timeslips-listing layout="column" flex bucket="$resolve.bucket" user-ids="$resolve.userIds" ></ce-client-case-no-timeslips-listing>',
         params: {
            bucket: null,
            userIds: null
         },
         resolve: {
            bucket: ['$stateParams', p => p.bucket],
            userIds: ['$stateParams', p => p.userIds]
         },
         data: {
            title: 'No Timeslips',
            permissions: ['client-cases.view']
         }
      },
      {
         name: 'app.createClientCase',
         url: '/client-cases/create',
         template: '<ce-create-client-case layout="column" flex legalTeams="$resolve.legalTeams" ></ce-create-client-case>',
         data: {
            title: 'Create Client Case',
            permissions: ['client-cases.edit']
         }
      },
      {
         name: 'app.clientCaseListing',
         url: '/client-cases',
         template: '<ce-client-case-listing layout="column" flex users="$resolve.users"></ce-client-case-listing > ',
         data: {
            title: 'Client Case Listing',
            permissions: ['client-cases.view']
         },
         params: { users: null },
         resolve: {
            users: ['$stateParams', p => p.users]
         }
      },
      {
         name: 'app.clientCases',
         url: '/client-cases/{clientCaseId}',
         template: '<ce-client-case-view case="$resolve.case" layout="column" flex></ce-client-case-view>',
         redirectTo: 'app.clientCases.overview',
         data: {
            title: 'Client Case View',
            permissions: ['client-cases.view']
         },
         resolve: { case: caseBasicInfoResolver }
      },
      {
         name: 'app.clientCases.overview',
         url: '/overview',
         template: '<ce-client-case-overview case="$resolve.case" layout="column" flex></ce-client-case-overview>',
         data: {
            title: 'Client Case Overview',
            permissions: ['client-cases.view']
         }
      },
      {
         name: 'app.clientCases.details',
         url: '/details',
         template: `<ce-client-case-details layout="column" flex
                   case="$resolve.case"
                   param-case-name="$resolve.paramCaseName"
                   param-poverty-level="$resolve.paramPovertyLevel"
                   param-at-poverty="$resolve.paramAtPoverty"
                   param-race-Sub-Category="$resolve.paramRaceSubCategory"></ce-client-case-details>`,
         data: {
            title: 'Client Case Details',
            permissions: ['client-cases.view']
         },
         resolve: {
            paramCaseName: showClientCaseNameResolver,
            paramPovertyLevel: showPovertyLevelResolver,
            paramAtPoverty: showAtPovertyLevelResolver,
            paramRaceSubCategory: showRaceSubCategoryResolver
         }
      },
      {
         name: 'app.clientCases.involvement',
         url: '/involvement',
         template: '<ce-involvement-case-listing involvement-id="$resolve.case.involvementId" flex></ce-involvement-case-listing>',
         data: { title: 'Client Case Involvement' }
      },
      {
         name: 'app.clientCases.timeslipListing',
         url: '/timeslips',
         template: '<ce-timeslip-listing source="Client Case" source-name="$resolve.case.idNumber" source-name2="$resolve.case.clientFullName" source-id="$resolve.case.id" involvement-id="$resolve.case.involvementId" enable-filters="false" flex layout="column"></ce-timeslip-listing>',
         data: {
            title: 'Client Case Timeslips',
            permissions: ['timeslips.view']
         }
      },
      {
         name: 'app.clientCases.timeslipCalendar',
         url: '/timeslips-calendar',
         template: '<ce-timeslip-calendar-frame source="Client Case" source-name="$resolve.case.idNumber" source-name2="$resolve.case.clientFullName" source-id="$resolve.case.id" involvement-id="$resolve.case.involvementId" enable-filters="false" flex layout="column"></ce-timeslip-calendar-frame>',
         data: {
            title: 'Client Case Timeslips',
            permissions: ['timeslips.view']
         }
      },
      {
         name: 'app.clientCases.documents',
         url: '/documents',
         template: '<ce-documents-list involvement-id="$resolve.case.involvementId" source="Client Case" source-id="$resolve.case.id" layout="column" flex></ce-document-list>',
         data: {
            title: 'Client Case Documents',
            permissions: ['documents.view']
         }
      },
      {
         name: 'app.clientCases.uploadDocuments',
         url: '/upload',
         params: {
            folderId: null
         },
         resolve: {
            folderId: ['$stateParams', p => p.folderId],
         },
         template: '<ce-upload-documents-activity layout flex folder-id="$resolve.folderId" involvement-id="$resolve.case.involvementId" source="Client Case" source-id="$resolve.case.id"></ce-upload-documents-activity>',
         data: { permissions: ['documents.edit'] }
      },
      {
         name: 'app.clientCases.emails',
         url: '/emails',
         template: '<ui-view flex layout="column"></ui-view>',
         redirectTo: 'app.clientCases.emails.listing',
         data: {
            title: 'Client Case Emails',
            permissions: ['mail.view']
         }
      },
      {
         name: 'app.clientCases.emails.listing',
         url: '',
         template: '<ce-email-listing involvement-id="$resolve.case.involvementId" source="Client Case" source-id="$resolve.case.id" layout="column" flex></ce-email-listing>',
         data: {
            title: 'Client Case Emails',
            permissions: ['mail.view']
         }
      },
      {
         name: 'app.clientCases.emails.message',
         url: '/{messageId}',
         template: '<ce-email-view layout="column" flex message-id="$resolve.messageId"></ce-email-view>',
         data: {
            title: 'Message',
            permissions: ['mail.view']
         },
         resolve: {
            messageId: ['$stateParams', p => p.messageId]
         }
      },
      {
         name: 'app.clientCases.emails.link',
         url: '/link',
         template: '<ce-link-emails layout="column" flex involvement-id="$resolve.case.involvementId" source="Client Case" source-id="$resolve.case.id"></ce-link-emails>',
         data: {
            title: 'Inbox Messages',
            permissions: ['mail.link']
         }
      },
      {
         name: 'app.clientCases.closure',
         url: '/closure',
         template: '<ce-client-case-closure case="$resolve.case" layout="column" flex></ce-client-case-closure>',
         data: {
            title: 'Client Case Closure',
            permissions: ['client-cases.view']
         }
      },
      {
         name: 'app.clientCases.grievances',
         url: '/grievances',
         template: '<ce-grievance-listing case="$resolve.case" case-type="Client Case" flex></ce-grievance-listing>',
         data: {
            title: 'Client Case Grievances',
            permissions: ['grievances.view']
         }
      },
      {
         name: 'app.clientCases.calendar',
         url: '/calendar',
         template: '<ce-involvements-calendar source="Client Case" source-id="$resolve.case.id" involvement-id="$resolve.case.involvementId" layout="column" flex></ce-involvements-calendar>',
         data: {
            title: 'Calendar',
         }
      },
      {
         name: 'app.clientCases.eventSchedule',
         url: '/eventSchedule',
         template: `<ce-calendar-event-schedule
                     event-schedule-id="$resolve.eventScheduleId"
                     target-date="$resolve.targetDate"
                     source="Client Case"
                     source-name="$resolve.case.idNumber"
                     source-id="$resolve.case.id"
                     involvement-id="$resolve.case.involvementId"></ce-calendar-event-schedule>`,
         params: {
            eventScheduleId: null,
            targetDate: null
         },
         resolve: {
            eventScheduleId: ['$stateParams', p => p.eventScheduleId],
            targetDate: ['$stateParams', p => p.targetDate],
         },
         data: { title: 'Event Schedule', permissions: ['events.view'], }
      },
      {
         name: 'app.clientCases.event',
         url: '/event',
         template: '<ce-calendar-event event="$resolve.event"></ce-calendar-event>',
         params: {
            event: null
         },
         resolve: {
            event: ['$stateParams', e => e.event]
         },
         data: {
            title: 'Event',
            permissions: ['events.view']
         }
      }
   ];

   const runner = (routingHelper: core.routing.IRoutingHelperService) => {
      'ngInject';
      routingHelper.configureRoutes(routes);
   };

   angular
      .module('app.clientCases')
      .run(runner);
}