namespace app.alerts {
   const runner = (routingHelper: core.routing.IRoutingHelperService) => {
      'ngInject';

      // TODO: Use component instead of template when 1.0 is released
      routingHelper.configureRoutes([
         {
            name: 'app.notifications',
            url: '/alerts',
            template: '<ce-notifications-list layout="column" flex notifications="$resolve.notifications"></ce-notifications-list>',
            resolve: {
               notifications: notificationsResolver
            },
            data: { title: 'Notifications' }
         }
      ]);
   };

   angular
      .module('app.alerts')
      .run(runner);
}