namespace app.entities.details {
   @Component('app.entities.details', 'ceEntityAddresses', {
      templateUrl: 'app/entities/details/address/addresses.html',
      bindings: {
         entityId: '<',
         defaultAddressType: '@'
      }
   })
   class Addresses {
      static $inject = ['common', 'datacontext'];
      constructor(
         private common: core.ICommonService,
         private datacontext: data.IDataContextService) {
         'ngInject';
      }

      public editMode = false;
      public mouseOver: boolean = false;
      public entityId: string;
      public addresses: IEntityAddress[];
      public selectedAddress: IEntityAddress;
      public defaultAddressType: string;

      public deleteAddress(address: IEntityAddress) {
         this.selectedAddress = address;
         this.common.confirmation.show({
            title: 'Delete Address',
            message: 'Are you sure you want to delete this address?',
            ok: 'Delete',
            cancel: 'Keep'
         }).then(this.deleteAddressCore);
      }

      public cancelEdit() {
         this.editMode = false;
         this.selectedAddress = null;
      }

      public addAddress() {
         let address = {} as IEntityAddress;
         address.addressType = this.defaultAddressType;
         this.editAddress(address);
      }

      public editAddress(address: IEntityAddress) {
         this.selectedAddress = address;
         this.editMode = true;
      }

      public saveAddress(data: IEntityAddress) {
         data.entityId = this.entityId;

         if (data.startDate && typeof data.startDate !== 'string') {
            data.startDate = data.startDate.toDateString();
         }

         if (data.endDate && typeof data.endDate !== 'string') {
            data.endDate = data.endDate.toDateString();
         }

         const promise = this.selectedAddress.id
            ? this.datacontext.entities.updateEntityAddress(data)
            : this.datacontext.entities.createEntityAddress(data);

         if (data.isPrimary) {
            this.addresses.forEach(a => a.isPrimary = false);
         }

         promise.then(result => {
            if (result) {
               if (!this.selectedAddress.id) {
                  this.addresses.push(result);
               } else {
                  angular.extend(this.selectedAddress, result);
               }

               this.cancelEdit();
            }
         });
      }

      private $onInit() {
         // if (this.common.auth.checkPermission('person.addresses')) {
         this.getAddressses();
         // }
      }

      private deleteAddressCore = () => {
         this.datacontext.entities.deleteEntityAddress(this.selectedAddress)
            .then(() => {
               const index = this.addresses.indexOf(this.selectedAddress);
               this.addresses.splice(index, 1);
               this.cancelEdit();
            });
      };

      private getAddressses = () => {
         this.datacontext.entities
            .getEntityAddresses(this.entityId)
            .then(result => this.addresses = result);
      };
   }
}