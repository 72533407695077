namespace app.projects.details {
   @Component('app.projects.details', 'ceProjectInformation', {
      templateUrl: 'app/projects/details/project-information.html',
      bindings: {
         data: '<',
         onEdit: '&'
      }
   })
   class ProjectInformation {
      constructor() {
         'ngInject';
      }

      private project: IProjectView;
   }
}