namespace app.utils {
   export interface IYesNoFilter {
      (value: boolean): string;
   }

   const yesNoFilter = ($filter: IFilterService) => {
      'ngInject';
      return (value: boolean) => {
         let returnValue = null;
         switch (value) {
            case true:
               returnValue = 'Yes';
               break;
            case false:
               returnValue = 'No';
               break;
            default:
               returnValue = '';
               break;
         }
         return returnValue;
      };
   };

   angular
      .module('app.utils')
      .filter('yesNo', yesNoFilter);
}