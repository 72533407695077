namespace app.monitoring.activities {
   const routes: core.routing.IStateExtended[] = [
      {
         name: 'app.monitoring.activities',
         url: '/activities',
         template: '<ui-view layout="column" flex></ui-view>',
         redirectTo: 'app.monitoring.activities.list'
      },
      {
         name: 'app.monitoring.activities.list',
         url: '',
         template: `<ce-monitoring-activity-list
         monitoring="$resolve.monitoring"
                        layout="column" flex>
                    </ce-monitoring-activity-list>`,
         data: {
            title: 'Monitoring Activities',
            permissions: ['access_activities']
         }
      },
      {
         name: 'app.monitoring.activities.note',
         url: '/note',
         template: `<ce-monitoring-activities-note layout="column" flex
                        involvement-id="$resolve.monitoring.involvementId"
                        monitoring-Id="$resolve.monitoring.id"
                        activity-Type="MonitoringNote"
                        name="Note"
                        activity="$resolve.activity"
                        case-open-date="$resolve.monitoring.openDate"
                        return-sref="app.monitoring.activities.list">
                    </ce-monitoring-activities-note>`,
         params: { activity: null },
         resolve: { activity: ['$stateParams', p => p.activity] },
         data: { title: 'Note' }
      },
      {
         name: 'app.monitoring.activities.conflictCheck',
         url: '/conflictCheck',
         template: `<ce-conflict-check layout="column" flex
                     involvement-id="$resolve.monitoring.involvementId"
                     source="Monitoring"
                     source-Id="$resolve.monitoring.id"
                     case-open-date="$resolve.monitoring.openDate">
                    </ce-conflict-check>`,
         data: { title: 'Conflict Check' }
      },
      {
         name: 'app.monitoring.activities.close',
         url: '/close',
         template: `<ce-monitoring-activities-close
         monitoring="$resolve.monitoring" layout="column" flex>
                    </ce-monitoring-activities-close>`,
         data: { title: 'Close' }
      },
      {
         name: 'app.monitoring.activities.reopen',
         url: '/reopen',
         template: `<ce-monitoring-activities-reopen
         monitoring="$resolve.monitoring" flex>
                    </ce-monitoring-activities-reopen>`,
         data: { title: 'Reopen' }
      },
      {
         name: 'app.monitoring.activities.caseReview',
         url: '/review',
         template: `<ce-monitoring-review
                     involvement-id="$resolve.monitoring.involvementId"
                     monitoring-id="$resolve.monitoring.id"
                     activity="$resolve.activity"
                     case-open-date="$resolve.monitoring.openDate"
                     flex>
                    </ce-monitoring-review>`,
         params: { activity: null },
         resolve: { activity: ['$stateParams', p => p.activity] },
         data: { title: 'Review' }
      },
      {
         name: 'app.monitoring.activities.issue',
         url: '/issue',
         template: `<am-monitoring-issue layout="column" flex
                        [involvement-id]="$resolve.monitoring.involvementId"
                        [source-id]="$resolve.monitoring.id"
                        [activity-id]="$resolve.activityId">
                    </am-monitoring-issue>`,
         params: { activityId: null },
         resolve: { activityId: ['$stateParams', p => p.activityId] },
         data: {
            title: 'Contact',
            permissions: ['monitoring.edit']
         }
      },
      {
         name: 'app.monitoring.activities.contact',
         url: '/contact',
         template: `<am-monitoring-contact layout="column" flex
                        [involvement-id]="$resolve.monitoring.involvementId"
                        [source-id]="$resolve.monitoring.id"
                        [activity-id]="$resolve.activityId">
                    </am-monitoring-contact>`,
         params: { activityId: null },
         resolve: { activityId: ['$stateParams', p => p.activityId] },
         data: {
            title: 'Contact',
            permissions: ['monitoring.edit']
         }
      },
      {
         name: 'app.monitoring.activities.meeting',
         url: '/meeting',
         template: `<am-monitoring-meeting layout="column" flex
                        [involvement-id]="$resolve.monitoring.involvementId"
                        [source-id]="$resolve.monitoring.id"
                        [activity-id]="$resolve.activityId">
                    </am-monitoring-meeting>`,
         params: { activityId: null },
         resolve: { activityId: ['$stateParams', p => p.activityId] },
         data: {
            title: 'Meeting',
            permissions: ['monitoring.edit']
         }
      },
      {
         name: 'app.monitoring.activities.statusChange',
         url: '/statusChange',
         template: `<am-activities-status-change layout="column" flex
                        [involvement-id]="$resolve.monitoring.involvementId"
                        [source-id]="$resolve.monitoring.id"
                        source="monitoring"
                        [activity-id]="$resolve.activityId">
                    </am-activities-status-change>`,
         params: { activityId: null },
         resolve: { activityId: ['$stateParams', p => p.activityId] },
         data: {
            title: 'Status Change',
            permissions: ['monitoring.edit']
         }
      }
   ];

   const runner = (routingHelper: core.routing.IRoutingHelperService) => {
      'ngInject';
      routingHelper.configureRoutes(routes);
   };

   angular
      .module('app.monitoring.activities')
      .run(runner);
}