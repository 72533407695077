namespace app.involvements {
   @Component('app.involvements', 'ceConflictCheck', {
      templateUrl: 'app/involvements/conflict-check/conflict-check.html',
      bindings: {
         source: '@',
         sourceId: '<',
         involvementId: '<',
         returnSref: '<',
         caseOpenDate: '<'
      }
   })
   class ConflictCheck {
      static $inject = ['common', 'datacontext'];
      constructor(
         private common: core.ICommonService,
         private datacontext: data.IDataContextService) {
         'ngInject';
      }

      public entities: involvements.IEntityBasicInfo[] = [];
      public sourceId: string;
      public source: string;
      public involvementId: string;
      public activity = {} as app.activities.ICreateActivity;
      public isSubmitting: boolean;
      public maxDate: Date;

      private $onInit() {
         this.activity.source = this.source;
         this.activity.sourceId = this.sourceId;
         this.activity.involvementId = this.involvementId;
         this.activity.activityDate = moment().toDate();
         this.maxDate = this.activity.activityDate;
         this.getEntities();
      }

      public getEntities() {
         this.datacontext.involvements.getEntitiesBasic(this.involvementId)
            .then(entities => {
               this.entities = entities;
            });
      }

      public submit() {
         this.isSubmitting = true;

         if (typeof this.activity.activityDate !== 'string') {
            this.activity.activityDate = this.activity.activityDate.toDateString();
         }

         this.datacontext.involvements
            .conflictCheck(this.activity)
            .then(request => {
               if (request) {
                  this.common.$mdToast.showSimple('Conflict Check Created');
                  this.common.$state.go('^.list', { sourceId: this.sourceId });
               }
            })
            .finally(() => this.isSubmitting = false);
      }
   }
}