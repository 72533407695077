namespace app.clientCases.closure {
   @Component('app.clientCases.closure', 'ceClosureSpecialEducation', {
      templateUrl: 'app/client-cases/closure/closure-special-education/closure-special-education.html',
      bindings: {
         data: '<',
         onEdit: '&'
      }
   })
   class ClosureSpecialEducation {
   }
}