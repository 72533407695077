namespace app.calendars {
   @Controller('app.calendars', 'EventSummaryDialogController')
   class EventSummaryDialogController {
      static $inject = ['$mdDialog', 'common', 'datacontext', 'event', 'eventScheduleId'];
      constructor(
         private $mdDialog: angular.material.IDialogService,
         private common: core.ICommonService,
         private datacontext: data.IDataContextService,
         private event: IEventSummary,
         private eventScheduleId: string
      ) {
         'ngInject';
      }

      public $onInit() {
         switch (this.event.type) {
            case 'event':
               this.datacontext.calendars.getEventSummary(this.event)
                  .then(summary =>
                     angular.extend(this.event, summary));

               this.datacontext.calendars.getEventScheduleStaff(this.eventScheduleId)
                  .then(staff =>
                     this.event.staff = staff);

               this.datacontext.calendars.getEventScheduleSources(this.eventScheduleId)
                  .then(sources =>
                     this.event.sources = sources);
               break;
            case 'sol':
               this.datacontext.involvements.getBasicInfo(this.event.involvementId)
                  .then(involvement => {
                     this.event.staff = [];
                     this.event.staff[0] = {
                        userId: involvement.primaryStaffUserId,
                        fullName: involvement.primaryStaffFullName
                     };

                     this.event.sources = [];
                     this.event.sources[0] = {
                        sourceId: involvement.caseId,
                        source: involvement.caseType,
                        sourceName: involvement.caseName,
                        idNumber: involvement.idNumber,
                        involvementId: involvement.involvementId,
                     };
                  });
               break;
         }
      }

      public editEvent() {
         this.$mdDialog.hide(event);
      }

      public editEventSchedule() {
         this.$mdDialog.hide('eventSchedule');
      }

      public cancel() {
         this.$mdDialog.cancel();
      }
   }
}