namespace app.admin.identityManagement {
   @Component('app.admin.identityManagement', 'ceAdminCreateUser', {
      templateUrl: 'app/admin/modules/identityManagement/views/create-user/create-user.html',
      bindings: {
         person: '<',
         roles: '<'
      }
   })
   class CreateUser {
      static $inject = ['common', 'datacontext'];
      constructor(
         private common: core.ICommonService,
         private datacontext: data.IDataContextService) {
         'ngInject';
      }

      public person: persons.IPersonBasicInfo;
      public roles: IRole[];

      public data = {} as ICreateUserData;
      public isSubmitting = false;

      private $onInit() {
         this.data.roleIds = [];
         this.data.isActive = true;
         this.data.timeslipTimerEnabled = true;

         if (this.person) {
            this.data.firstName = this.person.firstName;
            this.data.lastName = this.person.lastName;
            this.data.id = this.person.id;
            this.data.existingPerson = true;
         }
      }

      public submit() {
         this.isSubmitting = true;
         this.datacontext.admin.identityManagement
            .createUser(this.data)
            .then(result => {
               if (result) {
                  this.common.$mdToast.showSimple('User created');
                  this.common.$state.go('^.^');
               }
            })
            .finally(() => this.isSubmitting = false);
      }

      public hasRole(roleId: string) {
         return this.data.roleIds.indexOf(roleId) > -1;
      }

      public toggleRole(roleId: string) {
         const index = this.data.roleIds.indexOf(roleId);
         if (index > -1) {
            this.data.roleIds.splice(index, 1);
         }
         else {
            this.data.roleIds.push(roleId);
         }
      }
   }
}