namespace app.training.cle {
   @Component('app.training.cle', 'ceCLEOverview', {
      templateUrl: 'app/training/continuing-legal-education/overview/cle-overview.html',
      bindings: {
         data: '<'
      }
   })
   class CLEOverview {
      static $inject = ['datacontext'];
      constructor(private datacontext: data.IDataContextService) {
         'ngInject';
      }

      public data: ICLECourse;

      public GetCLEInformation() {
         this.datacontext.training.getCLEInformation(this.data.id).then(
            result => this.data = result
         );
      }
   }
}