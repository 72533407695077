namespace app.agencies {
   export interface IAgencyRepository {
      createAgency(data: ICreateAgency): angular.IPromise<IAgency>;
      getAgency(id: string): angular.IPromise<IAgency>;
      getAgencyBasicInfo(id: string): angular.IPromise<IAgencyBasicInfo>;
      getAgencies(params: IGetAgenciesParams): data.IPagedListPromise<IAgency>;
      createAgencyPerson(data: ICreateAgencyPerson): angular.IPromise<IAgencyPerson>;
      getAgencyPersons(params: IGetAgencyPersonsParams): data.IPagedListPromise<IAgencyPerson>;
      getImportantInformation(id: string): angular.IPromise<IAgencyImportantInformation>;
      getOtherInfo(id: string): angular.IPromise<IAgencyOtherInfo>;
      updateOtherInfo(otherInfo: IAgencyOtherInfo): angular.IPromise<IAgencyOtherInfo>;
      merge(winnerId: string, loserId: string): angular.IPromise<boolean>;
   }

   const baseUrl = 'api/agencies';

   class AgencyRepository implements IAgencyRepository {
      constructor(
         private $http: angular.IHttpService,
         private exception: core.exception.IExceptionService) {
         'ngInject';
      }

      @ApiExecutor()
      public createAgency(data: ICreateAgency) {
         return this.$http.post<IAgency>(baseUrl, data) as any;
      }

      @ApiExecutor()
      public createAgencyPerson(data: ICreateAgencyPerson) {
         return this.$http.post<IAgencyPerson>(`${baseUrl}/${data.agencyId}/contact`, data) as any;
      }

      @ApiExecutor()
      public getAgency(id: string) {
         return this.$http.get<IAgency>(`${baseUrl}/${id}`) as any;
      }

      @ApiExecutor()
      public getAgencies(params: IGetAgenciesParams) {
         return this.$http.get<data.IPagedList<IAgency>>(baseUrl, { params }) as any;
      }

      @ApiExecutor()
      public getAgencyPersons(params: IGetAgencyPersonsParams) {
         return this.$http.get<data.IPagedList<IAgencyPerson>>(`${baseUrl}/${params.agencyId}/contacts`, { params }) as any;
      }

      @ApiExecutor()
      public getAgencyBasicInfo(id: string) {
         return this.$http.get<IAgencyBasicInfo>(`${baseUrl}/${id}/basicinfo`) as any;
      }
      @ApiExecutor()
      public getImportantInformation(agencyId: string) {
         return this.$http.get<IAgencyImportantInformation>(`${baseUrl}/${agencyId}/importantinformation`) as any;
      }
      @ApiExecutor()
      public getOtherInfo(agencyId: string) {
         return this.$http.get<IAgencyOtherInfo>(`${baseUrl}/${agencyId}/otherinfo`) as any;
      }
      @ApiExecutor()
      public updateOtherInfo(otherInfo: IAgencyOtherInfo) {
         return this.$http.put<IAgencyOtherInfo>(`${baseUrl}/${otherInfo.id}/otherinfo`, otherInfo) as any;
      }
      @ApiExecutor()
      public merge(winnerId: string, loserId: string) {
         return this.$http.post(`${baseUrl}/merge`, { winnerId, loserId }) as any;
      }
   }

   angular
      .module('app.agencies')
      .service('agencies.repository', AgencyRepository);
}