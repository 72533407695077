namespace app.utils {
   angular
      .module('app.utils')
      .filter('duration', duration);

   duration.$inject = ['$filter'];

   function duration($filter: angular.IFilterService) {
      return function (input, compareTo) {
         if (!input) return;

         const starts = input;
         const ends = compareTo || new Date();

         const diff = moment(ends).diff(starts, 'days');
         const duration = moment.duration(diff, 'days');

         let years = Math.floor(duration.asYears());
         let months = Math.round(duration.asMonths());

         if (months === 12) {
            years++;
            months = 0;
         }

         if (years > 0) {
            return `${years} years, ${Math.floor(months % 12)} months`;
         }
         else {
            return `${months} months`;
         }
      };
   }
}