namespace app.admin.tools {
   export interface IPersonMergeData extends persons.IPerson {
      cases: entities.ICaseListing[];
   }

   @Component('app.admin.tools', 'ceAdminPersonMerge', {
      templateUrl: 'app/admin/modules/tools/entity-merge/views/person-merge/person-merge.html',
   })
   class AdminPersonMerge {
      static $inject = ['common', 'datacontext'];
      constructor(
         private common: core.ICommonService,
         private datacontext: data.IDataContextService) {
         'ngInject';
      }

      public winner: IPersonMergeData;
      public loser: IPersonMergeData;
      public isSubmitting: boolean;

      public personSearch(searchText: string) {
         const params: persons.IGetPersonsParams = { q: searchText };

         return this.datacontext.persons
            .getPersons(params)
            .then(results => {
               return results.list;
            });
      }

      private onWinnerChange(winner: persons.IPerson) {
         this.setWinner(winner);
      }

      public setWinner(winner: persons.IPerson) {
         this.winner = winner as IPersonMergeData;
         this.getCases(this.winner);
      }

      private onLoserChange(loser: persons.IPerson) {
         this.setLoser(loser);
      }

      public setLoser(loser: persons.IPerson) {
         this.loser = loser as IPersonMergeData;
         this.getCases(this.loser);
      }

      private getCases = (person: IPersonMergeData) => {
         if (!person) {
            return;
         }

         this.datacontext.entities
            .getCaseListing(person.id, { pageSize: 50 })
            .then(result => person.cases = result.list);
      }

      private onSubmit() {
         this.isSubmitting = true;

         this.datacontext.persons
            .merge(this.winner.id, this.loser.id)
            .then((result) => {
               if (!result) {
                  this.isSubmitting = false;
                  return;
               }

               this.common.$mdToast.showSimple('Merge completed');
               this.common.$state.go('^.select');
            });
      }
   }
}