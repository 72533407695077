namespace app.data {
   'use strict';

   export interface ICreateResult<T> {
      id: T;
   }

   export interface ICreateResultPromise<T> extends angular.IPromise<ICreateResult<T>> {
   }

   export interface IDataContextService {
      activities: activities.IActivityRepository;
      admin: admin.IAdminRepository;
      alerts: alerts.IAlertRepository;
      analysis: analysis.IAnalysisRepository;
      calendars: calendars.ICalendarRepository;
      config: config.IConfigRepository;
      documentAssembly: documentManagement.IDocumentAssemblyRepository;
      documentManagement: documentManagement.IDocumentManagementRepository;
      identity: identity.IIdentityRepository;
      involvements: involvements.IInvolvementRepository;
      persons: persons.IPersonRepository;
      preferences: preferences.IPreferenceRepository;
      reports: reports.IReportRepository;
      mail: mail.IMailRepository;
      requests: requests.IRequestRepository;
      users: users.IUserRepository;
      clientCases: clientCases.IClientCaseRepository;
      systemicCases: systemicCases.ISystemicCaseRepository;
      informationAndReferrals: informationAndReferrals.IInformationAndReferralRepository;
      time: time.ITimeRepository;
      fiscal: fiscal.IFiscalRepository;
      projects: projects.IProjectRepository;
      training: training.ITrainingRepository;
      funding: funding.IFundingRepository;
      agencies: agencies.IAgencyRepository;
      entities: entities.IEntityRepository;
      grievance: grievance.IGrievanceRepository;
      monitoring: monitoring.IMonitoringRepository;
   }

   class DataContextService {
      private repoNames = [
         'activities',
         'admin',
         'alerts',
         'analysis',
         'calendars',
         'config',
         'documentAssembly',
         'documentManagement',
         'identity',
         'involvements',
         'persons',
         'preferences',
         'reports',
         'mail',
         'persons',
         'requests',
         'users',
         'clientCases',
         'systemicCases',
         'informationAndReferrals',
         'time',
         'fiscal',
         'projects',
         'training',
         'funding',
         'agencies',
         'entities',
         'grievance',
         'monitoring'
      ];

      constructor(private $injector: angular.auto.IInjectorService) {
         'ngInject';
         this.defineLazyLoadedRepos();
      }

      private defineLazyLoadedRepos = () => {
         const self = this;
         angular.forEach(this.repoNames, (name: string) => {
            Object.defineProperty(self, name, {
               configurable: true, // will redefine this property once
               get: () => {
                  // The 1st time the repo is request via this property,
                  // we ask the repositories for it (which will inject it).
                  const repo = self.getRepo(name);
                  // Rewrite this property to always return this repo;
                  // no longer redefinable
                  Object.defineProperty(self, name, {
                     value: repo,
                     configurable: false,
                     enumerable: true
                  });
                  return repo;
               }
            });
         });
      };

      private getRepo = (repoName: string) => {
         const fullRepoName = `${repoName}.repository`;
         const factory = this.$injector.get(fullRepoName);
         return factory;
      };
   }

   angular
      .module('app.data')
      .service('datacontext', DataContextService);
}