namespace app.informationAndReferrals.details {
   @Component('app.informationAndReferrals.details', 'ceInformationAndReferralInformationForm', {
      templateUrl: 'app/information-and-referrals/details/case-information/case-information-form.html',
      bindings: {
         caseId: '<',
         onCancel: '&',
         onSubmit: '&'
      }
   })
   class InformationAndReferralInformationForm {
      static $inject = ['datacontext'];
      constructor(private datacontext: data.IDataContextService) {
         'ngInject';
      }
      public onSubmit: (args: { result: IInformationAndReferralInformation }) => void;
      public data: IInformationAndReferralInformation;
      public isLoading: boolean = false;
      public isSubmitting: boolean = false;
      public caseId: string;
      public legalTeams: app.config.ILegalTeam[];
      public lawCategories: app.config.ILawCategory[];
      public lawSubcategories: app.config.ILawSubcategory[];
      public validLawSubcategories: app.config.ILawSubcategory[];

      public $onInit() {
         this.datacontext.config.legalTeams.getLegalTeams(true).then(
            result => this.legalTeams = result
         );
         this.getLawCategoriesAndSubcategories().then(() => this.getData());
      }

      public lawCategoryChanged() {
         this.filterLawSubcategories();
      }

      public getLawCategoriesAndSubcategories() {
         return this.datacontext.config.getLawCategories()
            .then((lawCategories) => {
               this.lawCategories = lawCategories;
               return this.datacontext.config.getLawSubcategories().then((lawSubcategories: any) => {
                  this.lawSubcategories = lawSubcategories.data;
               });
            });
      }
      public getData() {
         this.isLoading = true;
         this.datacontext.informationAndReferrals
            .getInformationAndReferralInformation(this.caseId)
            .then(result => this.data = result)
            .finally(() => this.isLoading = false);
      }

      public filterLawSubcategories() {
         this.validLawSubcategories = _.filter(this.lawSubcategories, ct => {
            return ct.lawCategoryId === this.data.lawCategoryId;
         });
      }

      public submit() {
         this.isSubmitting = true;
         this.datacontext.informationAndReferrals
            .updateInformationAndReferralInformation(this.data)
            .then(result => {
               if (result) this.onSubmit({ result });
            }).finally(() => this.isSubmitting = false);
      }
   }
}