namespace app.preferences.favorites {
   @Component('app.preferences.favorites', 'ceFavoritesSidebar', {
      templateUrl: 'app/preferences/favorites/favorites-sidebar/favorites-sidebar.html'
   })
   class FavoritesSidebarComponent {
      static $inject = ['$mdSidenav', 'common', 'datacontext', 'preferenceService'];
      constructor(
         private $mdSidenav: angular.material.ISidenavService,
         private common: core.ICommonService,
         private datacontext: data.IDataContextService,
         private preferenceService: IPreferenceService) {
         'ngInject';
      }

      private cancelToken: Function;
      public loading = false;
      public favoritesList: IFavorite[];

      public removeFavorite(favorite: IFavorite) {
         const index = _.findIndex(this.preferenceService.favorites, f => { return f.type === favorite.type && f.id === favorite.id; });
         this.preferenceService.favorites.splice(index, 1);
         this.preferenceService.save();
         _.remove(this.favoritesList, favorite);
      }

      public click(favorite: IFavorite) {
         this.common.$state.go(favorite.state, { [favorite.param]: favorite.id });
         this.$mdSidenav('favorites').close();
      }

      $onInit() {
         this.getFavorites();
      }

      public refresh() {
         this.getFavorites();
      }

      private getFavorites = () => {
         this.preferenceService.loadPreferences().then(() => this.favoritesList = this.preferenceService.favorites);
      };
   }
}