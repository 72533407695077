namespace app.core {
   const config = ($mdThemingProvider: angular.material.IThemingProvider) => {
      'ngInject';

      $mdThemingProvider.theme('default')
         .primaryPalette('blue')
         .accentPalette('blue');
   };

   angular
      .module('app.core')
      .config(config);
}