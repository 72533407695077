namespace app.admin.configuration {
   @Component('app.admin.configuration', 'ceAdminCreateTaskCategory', {
      templateUrl: 'app/admin/modules/configuration/views/create-task-category/create-task-category.html',
      bindings: {
      }
   })
   class CreateTaskCategory {
      static $inject = ['common', 'datacontext'];
      constructor(
         private common: core.ICommonService,
         private datacontext: data.IDataContextService) {
         'ngInject';
      }

      public isSubmitting = false;
      public data = { isActive: true } as IUpdateTaskCategoryData;

      public submit() {
         this.isSubmitting = true;
         this.datacontext.admin.configuration
            .createTaskCategory(this.data)
            .then(result => {
               if (result) {
                  this.common.$mdToast.showSimple('Task Category created');
                  this.common.$state.go('^.taskCategoryDetails', { taskCategoryId: result.id });
               }
            }).finally(() => this.isSubmitting = false);
      }
   }
}