namespace app.systemicCases.activities {
   interface IActivityViewModel {
      icon: string;
      text: string;
      sref: string;
      activityType?: string;
      name?: string;
      filter?: (request: ISystemicCaseBasicInfoView) => boolean;
   }

   const activities: IActivityViewModel[] = [
      {
         icon: 'history',
         text: 'Conflict Check',
         sref: '^.conflictCheck',
         filter: i => i.isActive
      },
      {
         icon: 'info',
         text: 'Convert to I&R',
         sref: '^.createInformationAndReferral',
         name: 'Create I&R',
         filter: i => i.isActive
      },
      {
         icon: 'assignment_turned_in',
         text: 'Case Review',
         sref: '^.caseReview',
         filter: i => i.isActive
      },
      {
         icon: 'folder_shared',
         text: 'Convert to Client Case',
         sref: '^.createClientCase',
         name: 'Create Client Case',
         filter: i => i.isActive
      },
      {
         icon: 'comment',
         text: 'Note',
         sref: '^.note',
         filter: i => true
      },
      {
         icon: 'highlight_off',
         text: 'Close Case',
         sref: '^.close',
         filter: i => i.isActive
      },
      {
         icon: 'folder_open',
         text: 'Reopen Case',
         sref: '^.reopen',
         filter: i => !i.isActive
      },
      {
         icon: 'group_work',
         text: 'Case Assessment Meeting',
         sref: '^.caseAssessmentMeeting',
         filter: i => i.isActive
      },
      {
         icon: 'approval',
         text: 'Status Change',
         sref: '^.statusChange',
         filter: i => i.isActive
      },
   ];

   @Controller('app.systemicCases.activities', 'NewSystemicCaseActivityDialogController')
   class NewSystemicCaseActivityDialogController {
      static $inject = ['$mdDialog', 'common', 'caseview'];
      constructor(
         private $mdDialog: angular.material.IDialogService,
         private common: core.ICommonService,
         private caseview: ISystemicCaseBasicInfoView) {
         'ngInject';
      }

      public filteredActivities: IActivityViewModel[];

      private $onInit() {
         this.filteredActivities = activities.filter(this.filterActivity);
      }

      private filterActivity = (activity: IActivityViewModel) => {
         return activity.filter(this.caseview);
      }

      public selectActivity(item: IActivityViewModel) {
         this.common.$state.go(item.sref, { involvementId: this.caseview.involvementId, sourceId: this.caseview.id });
         this.$mdDialog.hide();
      }
   }
}