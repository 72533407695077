namespace app.persons.details {
   @Component('app.persons.details', 'cePersonSpecialConsiderationsForm', {
      templateUrl: 'app/persons/details/special-considerations/special-considerations-form.html',
      bindings: {
         specialConsiderations: '<',
         onCancel: '&',
         onSubmit: '&'
      }
   })
   class PersonSpecialConsiderationsForm {
      static $inject = ['common', 'datacontext'];
      constructor(
         private common: core.ICommonService,
         private datacontext: data.IDataContextService) {
         'ngInject';
      }
      public isLoading: boolean = false;
      public specialConsiderations: ISpecialConsiderations;
      public data = {} as ISpecialConsiderations;

      public $onInit() {
      }

      private $onChanges() {
         this.data = angular.extend({}, this.specialConsiderations);
      }

   }
}