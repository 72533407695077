namespace app.admin.identityManagement {
   export interface IIdentityManagementRepository {
      createRole(data: ICreateRoleData): angular.IPromise<IRole>;
      createUser(data: ICreateUserData): angular.IPromise<IUser>;
      deleteRole(roleId: string): angular.IPromise<boolean>;
      getPermissions(): angular.IPromise<IPermission[]>;
      getRole(roleId: string): angular.IPromise<IRole>;
      getRoles(): angular.IPromise<IRole[]>;
      getUser(userId: string): angular.IPromise<IUser>;
      getUsers(params?: IGetUserParams): angular.IPromise<IUser[]>;
      updateRole(roleId: string, data: IUpdateRoleData): angular.IPromise<IRole>;
      updateUser(userId: string, data: IUpdateUserData): angular.IPromise<IUser>;
   }

   const baseUrl = 'api/identity-management';

   @Service('app.admin.identityManagement', 'admin.identityManagement.repository')
   class IdentityManagementRepository implements IIdentityManagementRepository {
      static $inject = ['$http'];
      constructor(private $http: angular.IHttpService) {
         'ngInject';
      }

      @ApiExecutor()
      public createRole(data: ICreateRoleData) {
         return this.$http.post<IRole>(`${baseUrl}/roles`, data) as any;
      }

      @ApiExecutor()
      public createUser(data: ICreateUserData) {
         return this.$http.post<IUser>(`${baseUrl}/users`, data) as any;
      }

      @ApiExecutor({ returnValue: true })
      public deleteRole(roleId: string) {
         return this.$http.delete<boolean>(`${baseUrl}/roles/${roleId}`) as any;
      }

      @ApiExecutor()
      public getPermissions() {
         return this.$http.get<IPermission[]>(`${baseUrl}/permissions`) as any;
      }

      @ApiExecutor()
      public getRole(roleId: string) {
         return this.$http.get<IRole>(`${baseUrl}/roles/${roleId}`) as any;
      }

      @ApiExecutor()
      public getRoles() {
         return this.$http.get<IRole[]>(`${baseUrl}/roles`) as any;
      }

      @ApiExecutor()
      public getUser(userId: string) {
         return this.$http.get<IUser>(`${baseUrl}/users/${userId}`) as any;
      }

      @ApiExecutor()
      public getUsers(params?: IGetUserParams) {
         return this.$http.get<IUser[]>(`${baseUrl}/users`, { params }) as any;
      }

      @ApiExecutor()
      public updateRole(roleId: string, data: IUpdateRoleData) {
         return this.$http.put<IRole>(`${baseUrl}/roles/${roleId}`, data) as any;
      }

      @ApiExecutor()
      public updateUser(userId: string, data: IUpdateUserData) {
         return this.$http.put<IUser>(`${baseUrl}/users/${userId}`, data) as any;
      }
   }
}