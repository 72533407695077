namespace app.documentManagement {
   @Controller('app.documentManagement', 'NewDocumentDialogController')
   class NewDocumentDialogController {
      static $inject = ['common', '$mdDialog', 'datacontext', 'blobHelper', 'source', 'sourceId', 'involvementId', 'folderId'];
      constructor(
         private common: core.ICommonService,
         private $mdDialog: angular.material.IDialogService,
         private datacontext: data.IDataContextService,
         private blobHelper: utils.IBlobHelperService,
         public source: string,
         public sourceId: string,
         public involvementId: string,
         public folderId: string
      ) {
         'ngInject';
      }

      public data = { parameters: {} } as ICreateDocumentData;
      public isSubmitting = false;
      public template: ITemplate;
      public templates: ITemplate[];

      public submit() {
         this.isSubmitting = true;
         this.datacontext.documentManagement
            .createDocument(this.data)
            .then(buffer => {
               if (buffer) {
                  this.blobHelper.open({
                     buffer,
                     contentType: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
                     fileName: `${this.template.title}.docx`
                  });
                  this.$mdDialog.hide();
               }
            })
            .finally(() => this.isSubmitting = false);
      }

      private $onInit() {
         this.getTemplates();
         this.setData();
      }

      private setData() {
         this.data.involvementId = this.involvementId;
         this.data.source = this.source;
         this.data.sourceId = this.sourceId;
         this.data.folderId = this.folderId;
         const datasetName = this.source.toLowerCase().replace(/ /g, '-'); // replaces empty space with '-' to match on DocumentAssembly.Dataset.Id
         this.data.parameters[datasetName] = this.data.sourceId;
         this.data.parameters['involvement'] = this.involvementId;
         this.data.parameters['pwd'] = this.involvementId;
         this.data.parameters['caller'] = this.involvementId;
         this.data.parameters['client'] = this.involvementId;
         this.data.parameters['primary-staff'] = this.involvementId;
         this.data.parameters['adverse-party'] = this.involvementId;
      }

      private getTemplates = () => {
         return this.datacontext.documentAssembly
            .getTemplates(this.source)
            .then(result => this.templates = result);
      };

      public cancel() {
         this.$mdDialog.cancel();
      }
   }
}