namespace app.layout {
   @Component('app.layout', 'ceApp', {
      templateUrl: 'app/layout/app.html'
   })
   class AppComponent {
      static $inject = ['$mdMedia', '$mdSidenav', 'common'];
      constructor(
         private $mdMedia: angular.material.IMedia,
         private $mdSidenav: angular.material.ISidenavService,
         private common: core.ICommonService) {
         'ngInject';
         this.$state = this.common.$state;
      }

      public $state: angular.ui.IStateService;
      public theme = 'default';
      public isSidenavLocked = true;

      public toggleSidenav() {
         this.isSidenavLocked = !this.isSidenavLocked;
      }

      private $onInit() {
         this.isSidenavLocked = this.$mdMedia('gt-md');
         // this.common.$rootScope.$on('$stateChangeSuccess', this.setTheme);
         // this.setTheme();
      }

      // private setTheme = () => {
      //   if (this.common.$state.includes('app.admin') || this.common.$state.includes('app.mail')) {
      //      this.theme = 'blueGrey';
      //   } else {
      //      this.theme = 'default';
      //   }
      // };
   }
}