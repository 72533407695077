namespace app.informationAndReferrals {
   'use strict';

   export interface IInformationAndReferralRepository {
      getInformationAndReferralBasicInfoView(id: string): angular.IPromise<IInformationAndReferralBasicInfoView>;
      getInformationAndReferralSummaryView(id: string): angular.IHttpPromise<IInformationAndReferralSummaryView>;
      getInformationAndReferralActivities(caseId: string): angular.IPromise<IInformationAndReferralActivityModel[]>;
      getInformationAndReferralListing(params: IGetInformationAndReferralsParams): angular.IPromise<data.IPagedList<IInformationAndReferralListingView>>;
      getInformationAndReferralListingDownload(params: IGetInformationAndReferralsParams): angular.IPromise<app.analysis.IDownloadDataResult>;
      getInformationAndReferralStatus(id: string): angular.IHttpPromise<IInformationAndReferralStatus>;
      getInformationAndReferralToDo(id: string): angular.IPromise<common.IToDoItem[]>;
      getInformationAndReferralInformation(id: string): angular.IPromise<IInformationAndReferralInformation>;
      updateInformationAndReferralInformation(informationAndReferral: IInformationAndReferralInformation): angular.IPromise<IInformationAndReferralInformation>;
      createInformationAndReferral(informationAndReferral: ICreateInformationAndReferral): angular.IPromise<string>;
      close(request: ICreateInformationAndReferralActivity): angular.IHttpPromise<ICreateInformationAndReferralActivity>;
      reopen(request: ICreateInformationAndReferralActivity): angular.IHttpPromise<ICreateInformationAndReferralActivity>;
      conflictCheck(requestNote: ICreateInformationAndReferralActivity): angular.IHttpPromise<ICreateInformationAndReferralActivity>;
      followUp(requestNote: ICreateInformationAndReferralActivity): angular.IHttpPromise<ICreateInformationAndReferralActivity>;
      review(requestNote: ICreateInformationAndReferralActivity): angular.IHttpPromise<ICreateInformationAndReferralActivity>;
      createNote(requestNote: ICreateInformationAndReferralActivity): angular.IHttpPromise<ICreateInformationAndReferralActivity>;
      getActiveCaseCount(userIds: string[]): angular.IPromise<number>;
      updateNote(requestNote: ICreateInformationAndReferralActivity): angular.IHttpPromise<ICreateInformationAndReferralActivity>;
      deleteInformationAndReferral(id: string): angular.IPromise<string>;
   }

   const baseUrl = 'api/informationAndReferrals';

   class InformationAndReferralRepository implements IInformationAndReferralRepository {
      static $inject = ['$http', 'exception'];
      constructor(
         private $http: angular.IHttpService,
         private exception: core.exception.IExceptionService,
      ) {
      }

      @ApiExecutor()
      public getInformationAndReferralListing(params: IGetInformationAndReferralsParams) {
         return this.$http.get<data.IPagedList<IInformationAndReferralListingView>>(baseUrl, { params }) as any;
      }

      public getInformationAndReferralListingDownload(params: IGetInformationAndReferralsParams) {
         return this.$http.get<any>(`${baseUrl}/csv`, { params, responseType: 'arraybuffer' }).then(
            (response) => {
               return {
                  buffer: response.data,
                  contentType: response.headers()['content-type'],
                  fileName: response.headers()['content-disposition'].split(';')[1].split('=')[1].replace(/"/g, '')
               };
            }).catch(error => this.exception.catcher('XHR for getCsvData failed')(error)) as any;
      }


      @ApiExecutor()
      public getInformationAndReferralActivities(caseId: string) {
         return this.$http.get<IInformationAndReferralActivityModel[]>(`${baseUrl}/${caseId}/activities`) as any;
      }

      @ApiExecutor()
      public createInformationAndReferral(informationAndReferral: ICreateInformationAndReferral) {
         return this.$http.post<ICreateInformationAndReferral>(baseUrl, informationAndReferral) as any;
      }

      @ApiExecutor()
      public close(activity: ICreateInformationAndReferralActivity) {
         return this.$http.post<ICreateInformationAndReferralActivity>(`${baseUrl}/${activity.caseId}/close`, activity) as any;
      }

      @ApiExecutor()
      public reopen(activity: ICreateInformationAndReferralActivity) {
         return this.$http.post<ICreateInformationAndReferralActivity>(`${baseUrl}/${activity.caseId}/reopen`, activity) as any;
      }

      @ApiExecutor()
      public conflictCheck(activity: ICreateInformationAndReferralActivity) {
         return this.$http.post<ICreateInformationAndReferralActivity>(`${baseUrl}/${activity.caseId}/conflictCheck`, activity) as any;
      }

      @ApiExecutor()
      public followUp(activity: ICreateInformationAndReferralActivity) {
         return this.$http.post<ICreateInformationAndReferralActivity>(`${baseUrl}/${activity.caseId}/followUp`, activity) as any;
      }

      @ApiExecutor()
      public review(activity: ICreateInformationAndReferralActivity) {
         return this.$http.post<ICreateInformationAndReferralActivity>(`${baseUrl}/${activity.caseId}/review`, activity) as any;
      }

      @ApiExecutor()
      public createNote(activity: ICreateInformationAndReferralActivity) {
         return this.$http.post<ICreateInformationAndReferralActivity>(`${baseUrl}/activities/${activity.caseId}/notes`, activity) as any;
      }

      @ApiExecutor()
      public updateNote(activity: ICreateInformationAndReferralActivity) {
         return this.$http.put<ICreateInformationAndReferralActivity>(`${baseUrl}/activities/${activity.caseId}/notes/${activity.id}`, activity) as any;
      }

      @ApiExecutor()
      public getInformationAndReferralBasicInfoView(id: string) {
         return this.$http.get<IInformationAndReferralBasicInfoView>(`${baseUrl}/${id}/basicinfo`) as any;
      }

      @ApiExecutor()
      public getInformationAndReferralSummaryView(id: string) {
         return this.$http.get<IInformationAndReferralSummaryView>(`${baseUrl}/${id}/summary`) as any;
      }

      @ApiExecutor()
      public getInformationAndReferralStatus(id: string) {
         return this.$http.get<IInformationAndReferralStatus>(`${baseUrl}/${id}/status`) as any;
      }

      @ApiExecutor()
      public getInformationAndReferralToDo(id: string) {
         return this.$http.get<common.IToDoItem[]>(`${baseUrl}/${id}/todo`) as any;
      }

      @ApiExecutor()
      public getInformationAndReferralInformation(id: string) {
         return this.$http.get<IInformationAndReferralInformation>(`${baseUrl}/${id}/information`) as any;
      }

      @ApiExecutor()
      public updateInformationAndReferralInformation(info: IInformationAndReferralInformation) {
         return this.$http.post<IInformationAndReferralInformation>(`${baseUrl}/${info.id}/information`, info) as any;
      }

      @ApiExecutor()
      public getActiveCaseCount(userIds: string[]) {
         return this.$http.get<number>(`${baseUrl}/activeCaseCount`, { params: { userIds: userIds } }) as any;
      }

      public deleteInformationAndReferral(id: string) {
         return this.$http.delete(`${baseUrl}/${id}`).catch(error => this.exception.catcher('Cannot Delete I&R')(error)) as any;
      }
   }

   angular
      .module('app.informationAndReferrals')
      .service('informationAndReferrals.repository', InformationAndReferralRepository);
}