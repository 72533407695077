namespace app.admin.tools {
   @Component('app.admin.tools', 'ceAdminTimeslipsLock', {
      templateUrl: 'app/admin/modules/tools/timeslip-lock/views/lock-timeslips/lock-timeslips.html',
   })
   class AdminTimeslipsLock {
      static $inject = ['common', 'datacontext'];
      constructor(
         private common: core.ICommonService,
         private datacontext: data.IDataContextService) {
         'ngInject';
      }

      public isSubmitting: boolean;
      public maxDate: Date;
      public minDate?: Date;
      public newLockDate: Date;
      public lastLock: time.ILastLock;

      private $onInit() {
         this.maxDate = moment().toDate();
         this.getLastLock();
      }

      private onSubmit() {
         this.isSubmitting = true;

         const data: time.IUpdateLockData = {
            lockDate: this.newLockDate.toDateString()
         };

         this.datacontext.time
            .lockTimeslips(data)
            .then((result) => {
               this.getLastLock();
               this.common.$mdToast.showSimple('Timeslips locked');
            })
            .finally(() => this.isSubmitting = false);
      }

      private getLastLock() {
         this.datacontext.time
            .getLastLock()
            .then(result => {
               this.lastLock = result;
               if (this.lastLock.lockDate) {
                  this.minDate = moment(this.lastLock.lockDate).add(1, 'days').toDate();
               }
            });
      }
   }
}