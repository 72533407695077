namespace app.monitoring.closure {
   @Component('app.monitoring.closure', 'ceMonitoringClosureInformationForm', {
      templateUrl: 'app/monitoring/closure-information/closure-information-form.html',
      bindings: {
         monitoringId: '<',
         onCancel: '&',
         onSubmit: '&'
      }
   })
   class MonitoringClosureInformationForm {
      static $inject = ['common', 'datacontext'];
      constructor(
         private common: core.ICommonService,
         private datacontext: data.IDataContextService) {
         'ngInject';
      }
      public onSubmit: (args: { result: IMonitoringClosureInformation }) => void;
      public data: IMonitoringClosureInformation;
      public isLoading: boolean = false;
      public isSubmitting: boolean = false;
      public monitoringId: string;

      public $onInit() {
         this.getData();
      }

      public getData() {
         this.isLoading = true;
         this.datacontext.monitoring
            .getClosureInformation(this.monitoringId)
            .then(result => this.data = result)
            .finally(() => this.isLoading = false);
      }

      public submit() {
         this.isSubmitting = true;

         if (!this.data.monetarySettlement) {
            this.data.monetarySettlementAmount = null;
         }

         this.datacontext.monitoring
            .updateClosureInformation(this.data)
            .then(result => {
               if (result) {
                  this.common.$mdToast.showSimple('Remember to Complete Survey');
                  this.onSubmit({ result });
               }
            }).finally(() => this.isSubmitting = false);
      }
   }
}