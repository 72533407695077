namespace app.funding {
   @Component('app.funding', 'ceMultipleChoiceFundingView', {
      templateUrl: 'app/funding/multiple-choice/view/view.html',
      bindings: {
         fundingPrograms: '<',
         onEdit: '&'
      }
   })
   class MultipleChoiceFundingView {

   }
}