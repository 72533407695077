namespace app.analysis {
   export interface IAnalysisConstants {
      ADD_CHART_SIDENAV_ID: string;
      ADD_CHART_SIDENAV_OPENED: string;
      chartTypes: IChartType[];
      colorPalette: string[];
   }

   const analysisConstants: IAnalysisConstants = {
      ADD_CHART_SIDENAV_ID: 'add-chart-sidenav',
      ADD_CHART_SIDENAV_OPENED: 'CHART_SIDENAV_OPENED',
      chartTypes: [
         { name: 'Pie', groupByCount: 1 },
         { name: 'Bar', groupByCount: 1, percentages: true, totals: true },
         { name: 'Column', groupByCount: 1, percentages: true, totals: true },
         { name: 'StackedBar', groupByCount: 2 },
         { name: 'StackedColumn', groupByCount: 2 },
         { name: 'PivotTable', groupByCount: 2 },
         { name: 'Line', groupByCount: 1, trending: true }
      ],
      colorPalette: [
         '#2196F3',
         '#009688',
         '#EF6C00',
         '#9C27B0',
         '#F44336',
         '#3F51B5',
         '#4CAF50',
         '#009688',
         '#689F38',
         '#FF5722',
         '#795548',
         '#757575',
         '#78909C'
      ]
   };

   angular
      .module('app.analysis')
      .constant('analysisConstants', analysisConstants);
}