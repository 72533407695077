namespace app.training.cle {
   export interface ICLECalendarState {
      dateRange: moment.Range;
      viewType: string;
      viewDate: moment.Moment;
      showCompleted: boolean;
      calendars: ICalendar[];
   }

   export interface ICLECalendarStateService {
      state: ICLECalendarState;
      save();
      update(state: any);
   }

   class CLECalendarStateService implements ICLECalendarStateService {
      constructor(private $localStorage: angular.storage.IStorageService) {
         'ngInject';

         if ($localStorage.calendarState) {
            delete $localStorage.calendarState.dateRange;
            delete $localStorage.calendarState.viewType;
            delete $localStorage.calendarState.viewDate;
         }

         this.loadState();
      }

      public state = {} as ICLECalendarState;

      public save() {
         this.$localStorage['cleCalendarState'] = this.state;
      }

      public update(state) {
         angular.extend(this.state, state);
         angular.extend(this.$localStorage['cleCalendarState'], state);
      }

      private loadState = () => {
         if (this.$localStorage['cleCalendarState']) {
            angular.extend(this.state, this.$localStorage['cleCalendarState']);
         } else {
            this.$localStorage['cleCalendarState'] = this.state;
         }

         if (this.state.dateRange) {
            this.state.dateRange = moment.range(this.state.dateRange.start, this.state.dateRange.end);
         }
      }
   }

   angular
      .module('app.training.cle')
      .service('cleCalendarState', CLECalendarStateService);
}