namespace app.data {
   const interceptorName = 'jsonDateInterceptor';

   const interceptor = ($q: angular.IQService) => {
      const dateRegExMs = /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2})Z$/;
      const dateRegEx = /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2})$/;
      const dateRegExShort = /^(\d{4})-(\d{2})-(\d{2})$/;

      const tryMatchDate = (dateString: string): (RegExpMatchArray | boolean) => {
         if (dateString.length === 19) {
            return dateString.match(dateRegEx);
         }
         if (dateString.length === 10) {
            return dateString.match(dateRegExShort);
         }
         if (dateString.length === 20) {
            return dateString.match(dateRegExMs);
         }
         return false;
      };

      const convertDateStringsToDates = (input: any) => {
         // Ignore things that aren't objects.
         if (typeof input !== 'object') {
            return input;
         }

         for (let key in input) {
            if (!input.hasOwnProperty(key)) {
               continue;
            }

            const value = input[key];
            let match;

            // Check for string properties which look like dates.
            if (typeof value === 'string' && (match = tryMatchDate(value))) {
               let date: Date;
               if (value.length === 10) {
                  date = moment(value, 'YYYY-MM-DD').toDate();
               }
               else if (value.length === 20) {
                  date = moment(value, 'YYYY-MM-DDTHH:mm:ssZ').toDate();
                    }
               else {
                  date = moment(value).toDate();
                    }

               input[key] = date;
            } else if (typeof value === 'object') {
               // Recurse into object
               convertDateStringsToDates(value);
            }
         }
         return null;
      };

      return {
         'response': (response: any) => {
            convertDateStringsToDates(response);
            return response;
         }
      };
   };
   interceptor.$inject = ['$q'];

   const configure = ($httpProvider: angular.IHttpProvider) => {
      $httpProvider.interceptors.push(interceptorName);
   };
   configure.$inject = ['$httpProvider'];

   angular
      .module('app.data')
      .factory(interceptorName, interceptor)
      .config(configure);
}