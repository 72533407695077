namespace app.admin.tools {
   const routes: core.routing.IStateExtended[] = [
      {
         name: 'app.admin.tools',
         url: '/tools',
         template: '<ui-view layout="column" flex></ui-view>',
         data: { roles: ['Administrator'] },
         redirectTo: 'app.admin.tools.entityMerge'
      },
      {
         name: 'app.admin.tools.entityMerge',
         url: '/entity-merge',
         template: '<ui-view layout="column" flex></ui-view>',
         data: { roles: ['Administrator'] },
         redirectTo: 'app.admin.tools.entityMerge.select'
      },
      {
         name: 'app.admin.tools.entityMerge.select',
         url: '',
         template: '<ce-admin-entity-merge layout="column" flex></ce-admin-entity-merge>',
         data: {
            title: 'Entity Merge',
            roles: ['Administrator']
         }
      },
      {
         name: 'app.admin.tools.entityMerge.persons',
         url: '/persons',
         template: '<ce-admin-person-merge layout="column" flex></ce-admin-person-merge>',
         data: {
            title: 'Person Merge',
            roles: ['Administrator']
         }
      },
      {
         name: 'app.admin.tools.entityMerge.agencies',
         url: '/agencies',
         template: '<ce-admin-agency-merge layout="column" flex></ce-admin-agency-merge>',
         data: {
            title: 'Agency Merge',
            roles: ['Administrator']
         }
      },
      {
         name: 'app.admin.tools.timeslipLock',
         url: '/timeslip-lock',
         template: '<ce-admin-timeslips-lock layout="column" flex></ce-admin-timeslips-lock>',
         data: {
            title: 'Lock Timeslips',
            roles: ['Administrator']
         }
      },
      {
         name: 'app.admin.tools.documents',
         url: '/documents',
         template: '<ce-documents-list layout="column" flex></ce-document-list>',
         data: {
            title: 'Documents',
            permissions: ['Administrator']
         }
      },
   ];

   const runner = (routingHelper: core.routing.IRoutingHelperService) => {
      'ngInject';
      routingHelper.configureRoutes(routes);
   };

   angular
      .module('app.admin.tools')
      .run(runner);
}