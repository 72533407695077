namespace app.funding {

   export interface IPAATSystemicIntakeModel extends IPAATSystemicIntake {
      livingArrangementsDisplayValue?: string[];
      problemAreasDisplayValue?: string[];
   }

   @Component('app.funding', 'cePaatSystemicIntakeInfo', {
      templateUrl: 'app/funding/enrollments/paat/systemic/paat-intake-info.html',
      bindings: {
         intake: '<',
         onEdit: '&',
         livingArrangements: '<',
         problemAreas: '<'
      }
   })
   class PAATSystemicIntakeInfo {
      public intake: IPAATSystemicIntakeModel;
      public livingArrangements: ILivingArrangement[];
      public problemAreas: IProblemArea[];

      constructor() {
         'ngInject';
      }

      $onChanges(changes) {
         if (this.intake.livingArrangements && this.livingArrangements) {
            this.setLivingArrangementsDisplayValue();
         }
         if (this.intake.problemAreas && this.problemAreas) {
            this.setProblemAreasDisplayValue();
         }
      }

      private setProblemAreasDisplayValue() {
         this.intake.problemAreasDisplayValue = [];
         this.intake.problemAreas.forEach(paId => {
            this.problemAreas.forEach(pa => {
               if (paId === pa.id) {
                  this.intake.problemAreasDisplayValue.push(pa.value);
               }
            });
         });
      }

      private setLivingArrangementsDisplayValue() {
         this.intake.livingArrangementsDisplayValue = [];
         this.intake.livingArrangements.forEach(laId => {
            this.livingArrangements.forEach(la => {
               if (laId === la.id) {
                  this.intake.livingArrangementsDisplayValue.push(la.value);
               }
            });
         });
      }

   }
}