namespace app.funding {
   const routes: core.routing.IStateExtended[] = [
      {
         name: 'app.funding',
         url: '/funding',
         template: '<ce-funding layout flex></ce-funding>',
         redirectTo: '^.summary'
      },
      {
         name: 'app.funding.summary',
         url: '',
         template: '<ce-funding-summary layout flex></ce-funding-summary>'
      },
      {
         name: 'app.funding.intake',
         url: '',
         template: '<ce-funding-intake layout flex></ce-funding-intake>'
      }
   ];

   const runner = (routingHelper: core.routing.IRoutingHelperService) => {
      'ngInject';
      routingHelper.configureRoutes(routes);
   };

   angular
      .module('app.funding')
      .run(runner);
}