namespace app.calendars {
   const routes: core.routing.IStateExtended[] = [
      {
         name: 'app.calendar',
         url: '/calendar',
         template: '<ui-view layout="column" flex></ui-view>',
         // TODO: (Security) permissions: ['events.view'],
         redirectTo: 'app.calendar.calendar'
      },
      {
         name: 'app.calendar.calendar',
         url: '',
         template: '<ce-calendar layout="column" flex></ce-calendar>',
         data: { title: 'Calendar' },
         // TODO: (Security) permissions: ['events.view']
      },
      {
         name: 'app.calendar.eventSchedule',
         url: '/eventSchedule',
         template: '<ce-calendar-event-schedule event-schedule-id="$resolve.eventScheduleId" target-date="$resolve.targetDate"></ce-calendar-event-schedule>',
         params: {
            eventScheduleId: null,
            targetDate: null
         },
         resolve: {
            eventScheduleId: ['$stateParams', p => p.eventScheduleId],
            targetDate: ['$stateParams', p => p.targetDate],
         },
         data: { title: 'Event Schedule', permissions: ['events.view'], }
      },
      {
         name: 'app.calendar.event',
         url: '/event',
         template: '<ce-calendar-event event="$resolve.event"></ce-calendar-event>',
         params: {
            event: null
         },
         resolve: {
            event: ['$stateParams', e => e.event]
         },
         data: {
            title: 'Event',
            permissions: ['events.view']
         },
      }
   ];

   const runner = (routingHelper: core.routing.IRoutingHelperService) => {
      'ngInject';
      routingHelper.configureRoutes(routes);
   };

   angular
      .module('app.calendars')
      .run(runner);
}