namespace app.training.funding {
   @Component('app.training.funding', 'ceTrainingFunding', {
      templateUrl: 'app/training/funding/funding.html',
      bindings: {
         trainingId: '<',
         source: '@'
      }
   })
   class TrainingFunding {
      static $inject = ['datacontext'];
      constructor(
         private datacontext: data.IDataContextService) {
         'ngInject';
      }

      private fundingPrograms = [] as app.funding.IFundingProgram[];
      private editFundingPrograms: boolean = false;
      private trainingId: string;
      private source: string;

      public $onInit() {
         this.getFundingPrograms();
      }

      public getFundingPrograms() {
         this.datacontext.funding.getTrainingFundingPrograms(this.trainingId)
            .then(result => {
               this.fundingPrograms = result;
            });
      }

      public updateFundingPrograms(result: app.funding.IFundingProgram[]) {
         this.fundingPrograms = result;
         const fundingProgramIds: string[] = _.map(this.fundingPrograms, 'fundingProgramId');
         this.datacontext.funding.setTrainingFundingPrograms(this.trainingId, fundingProgramIds)
            .then(response => {
               this.editFundingPrograms = false;
            });
      }
   }
}