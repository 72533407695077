namespace app.utils {
   const timesList: string[] = [];

   (function () {
      let meridiem: string;
      let hour: number;

      for (let i = 8; i < 18; i++) {
         hour = i % 12;
         if (hour === 0) {
            hour = 12;
         }

         meridiem = i < 12 ? 'am' : 'pm';

         timesList.push(hour + ':00' + meridiem);
         timesList.push(hour + ':15' + meridiem);
         timesList.push(hour + ':30' + meridiem);
         timesList.push(hour + ':45' + meridiem);
      }
   })();

   @Component('app.utils', 'aimsTimePicker', {
      templateUrl: 'app/utils/time-picker/time-picker.component.html',
      bindings: {
         hideIcon: '<',
         inputName: '@',
         label: '@',
         required: '<',
         value: '=',
      },
      require: {
         form: '^^form'
      }
   })
   class TimePicker {
      public timesList = timesList;

      private startsWithFilter(actualTime, startsWithTime) {
         return actualTime.toLowerCase().indexOf(startsWithTime.toLowerCase()) === 0;
      }
   }
}