namespace app.utils {
   export interface IAgeFilter {
      (value: Date): number;
   }

   const ageFilter = ($filter: IFilterService) => {
      'ngInject';
      return (value: Date) => {
         return $filter('abs')($filter('amDifference')(value, null, 'years'));
      };
   };

   angular
      .module('app.utils')
      .filter('age', ageFilter);
}