namespace app.mail {
   @Component('app.mail', 'ceCreateEmail', {
      templateUrl: 'app/mail/create-email/create-email.html',
      bindings: {
         involvementId: '<',
         sourceId: '<',
         source: '@',
         sourceNumber: '<',
         sourceName: '<'
      }
   })
   class CreateEmail {
      static $inject = ['datacontext', 'common', 'blobHelper'];
      constructor(
         private datacontext: data.IDataContextService,
         private common: core.ICommonService,
         private blobHelper: utils.IBlobHelperService) {
         'ngInject';
      }

      public involvementId: string;
      public sourceId: string;
      public source: string;
      public sourceNumber: string;
      public sourceName: string;
      public data = {} as ICreateMessageData;
      public selectedDocuments: documentManagement.IDocument[] = [];
      public isSubmitting = false;

      private $onInit() {
         this.data.involvementId = this.involvementId;
         this.data.sourceId = this.sourceId;
         this.data.source = this.source;

         if (this.sourceNumber) {
            this.data.subject = `${this.source} ${this.sourceNumber}: ${this.sourceName}`;
         } else {
            this.data.subject = `${this.source}: ${this.sourceName}`;
         }
      }

      private transformChip(chip) {
         if (angular.isObject(chip)) {
            return chip;
         }
         return { name: chip, type: 'new' };
      }

      private querySearch(query) {
         return this.datacontext.documentManagement
            .getDocuments({ involvementId: this.involvementId, source: this.source, sourceId: this.sourceId, q: query })
            .then(result => result.list);
      }

      public submit() {
         this.isSubmitting = true;

         this.data.documentIds = _.map(this.selectedDocuments, x => x.id);

         this.datacontext.mail
            .createMessage(this.data)
            .then(result => {
               if (result) {
                  this.common.$mdToast.showSimple('Email sent');
                  this.common.$state.go('^');
               }
            }).finally(() => this.isSubmitting = false);
      }
   }
}