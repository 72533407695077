namespace app.training.cle {
   @Component('app.training.cle', 'ceCLECreate', {
      templateUrl: 'app/training/continuing-legal-education/create/create.html',
      bindings: {
      }
   })
   class CLECreate {
      static $inject = ['datacontext', 'common'];
      constructor(
         private datacontext: data.IDataContextService,
         private common: core.ICommonService) {
         'ngInject';
      }
      public onSubmit: (args: { result: ICLECourse }) => void;
      public data: ICLECourse = {} as ICLECourse;
      public isLoading: boolean = false;
      public isSubmitting: boolean = false;
      public courseForums: app.config.ILookup[];

      public $onInit() {
         this.getData();
         this.data.forum = [];
         this.data.trainingMaterials = [];
         this.data.difficultyLevel = [];
      }

      public forumToggle(value: string) {
         if (this.forumExists(value)) {
            _.pull(this.data.forum, value);
         }
         else {
            this.data.forum.push(value);
         }
      }
      public forumExists(value: string) {
         return _.includes(this.data.forum, value);
      }

      public materialToggle(value: string) {
         if (this.materialExists(value)) {
            _.pull(this.data.trainingMaterials, value);
         }
         else {
            this.data.trainingMaterials.push(value);
         }
      }
      public materialExists(value: string) {
         return _.includes(this.data.trainingMaterials, value);
      }

      public difficultyLevelToggle(value: string) {
         if (this.difficultyLevelExists(value)) {
            _.pull(this.data.difficultyLevel, value);
         }
         else {
            this.data.difficultyLevel.push(value);
         }
      }
      public difficultyLevelExists(value: string) {
         return _.includes(this.data.difficultyLevel, value);
      }

      getData() {
         this.datacontext.config.lookups.getLookupType('course-forum').then(
            result => this.courseForums = result.lookups
         );
      }

      public submit() {
         this.data.endTime.setMonth(this.data.startTime.getMonth());
         this.data.endTime.setDate(this.data.startTime.getDate());
         this.data.endTime.setFullYear(this.data.startTime.getFullYear());
         this.data.status = 'Scheduled';

         this.isSubmitting = true;
         this.datacontext.training
            .createCLE(this.data)
            .then(result => {
               if (result) {
                  this.common.$state.go('^.cle', { cleId: result });
               }
            }).finally(() => this.isSubmitting = false);
      }
   }
}