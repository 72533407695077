namespace app.requests.activities {
   const routes: core.routing.IStateExtended[] = [
      {
         name: 'app.requests.activities',
         url: '/activities',
         template: '<ui-view layout flex></ui-view>',
         redirectTo: 'app.requests.activities.list'
      },
      {
         name: 'app.requests.activities.list',
         url: '',
         template: '<ce-request-activity-list request="$resolve.request" layout="column" flex></ce-request-activity-list>',
         data: {
            title: 'Request Activities',
            permissions: ['access_activities']
         },
      },
      {
         name: 'app.requests.activities.note',
         url: '/note',
         template: `<ce-requests-activities-note layout="column" flex
                        involvement-id="$resolve.request.involvementId"
                        request-Id="$resolve.request.id"
                        activity-Type="RequestNote"
                        name="Note"
                        activity="$resolve.activity"
                        case-open-date="$resolve.request.requestedAt"
                        return-sref="app.requests.activities.list">
                    </ce-requests-activities-note>`,
         params: { activity: null },
         resolve: { activity: ['$stateParams', p => p.activity] },
         data: { title: 'Note' }
      },
      {
         name: 'app.requests.activities.followUp',
         url: '/follow-up',
         template: `<ce-requests-activities-follow-up layout="column" flex
                        involvement-id="$resolve.request.involvementId"
                        request-Id="$resolve.request.id"
                        activity="$resolve.activity"
                        case-open-date="$resolve.request.requestedAt">
                    </ce-requests-activities-follow-up>`,
         params: { activity: null },
         resolve: { activity: ['$stateParams', p => p.activity] },
         data: { title: 'Follow-Up' }
      },
      {
         name: 'app.requests.activities.review',
         url: '/review',
         template: `<ce-requests-activities-review layout="column" flex
                       involvement-id="$resolve.request.involvementId"
                       request-Id="$resolve.request.id"
                       activity="$resolve.activity"
                       case-open-date="$resolve.request.requestedAt">
                    </ce-requests-activities-review>`,
         params: { activity: null },
         resolve: { activity: ['$stateParams', p => p.activity] },
         data: { title: 'Review' }
      },
      {
         name: 'app.requests.activities.conflictCheck',
         url: '/conflictCheck',
         template: `<ce-conflict-check layout="column" flex
                     involvement-id="$resolve.request.involvementId"
                     source="Request"
                     source-Id="$resolve.request.id"
                     case-open-date="$resolve.request.requestedAt">
                    </ce-conflict-check>`,
         data: { title: 'Conflict Check' }
      },
      {
         name: 'app.requests.activities.close',
         url: '/close',
         template: `<ce-requests-activities-close layout="column" flex
                        request="$resolve.request">
                     </ce-requests-activities-close>`,
         data: { title: 'Close' }
      },
      {
         name: 'app.requests.activities.reopen',
         url: '/reopen',
         template: `<ce-requests-activities-reopen layout="column" flex
                        request="$resolve.request">
                     </ce-requests-activities-reopen>`,
         data: { title: 'Reopen' }
      },
      {
         name: 'app.requests.activities.createClientCase',
         url: '/createclientcase',
         template: `<ce-create-client-case layout="column" flex
                        involvement-id="$resolve.request.involvementId"
                        request-id="$resolve.request.id"
                        case-open-date="$resolve.request.requestedAt">
                    </ce-create-client-case>`,
         data: {
            title: 'Create Client Case',
            permissions: ['client-cases.edit']
         }
      },
      {
         name: 'app.requests.activities.createInformationAndReferral',
         url: '/createInformationAndReferral',
         template: `<ce-create-information-and-referral layout="column" flex
                        involvement-id="$resolve.request.involvementId"
                        request-id="$resolve.request.id"
                        case-open-date="$resolve.request.requestedAt">
                    </ce-create-information-and-referral>`,
         data: {
            title: 'Create I&R',
            permissions: ['i-and-rs.edit']
         }
      },
      {
         name: 'app.requests.activities.createSystemicCase',
         url: '/createSystemicCase',
         template: `<ce-create-systemic-case layout="column" flex
                        involvement-id="$resolve.request.involvementId"
                        request-id="$resolve.request.id"
                        case-open-date="$resolve.request.requestedAt">
                    </ce-create-systemic-case>`,
         data: {
            title: 'Create Systemic Case',
            permissions: ['systemic-cases.edit']
         }
      },
      {
         name: 'app.requests.activities.caseAssessmentMeeting',
         url: '/cam',
         template: `<ce-request-case-assessment-meeting
                     involvement-id="$resolve.request.involvementId"
                     source="Request"
                     source-id="$resolve.request.id"
                     show-communication-date="true"
                     return-sref="app.requests.activities.list"
                     activity="$resolve.activity"
                     case-open-date="$resolve.request.requestedAt"
                     flex>
                    </ce-request-assessment-meeting>`,
         params: { activity: null },
         resolve: { activity: ['$stateParams', p => p.activity] },
         data: { title: 'CAM' }
      },
      {
         name: 'app.requests.activities.statusChange',
         url: '/statusChange',
         template: `<am-activities-status-change layout="column" flex
                        [involvement-id]="$resolve.request.involvementId"
                        [source-id]="$resolve.request.id"
                        source="requests"
                        [activity-id]="$resolve.activityId">
                    </am-activities-status-change>`,
         params: { activityId: null },
         resolve: { activityId: ['$stateParams', p => p.activityId] },
         data: {
            title: 'Status Change',
            permissions: ['requests.edit']
         }
      }
   ];

   const runner = (routingHelper: core.routing.IRoutingHelperService) => {
      'ngInject';
      routingHelper.configureRoutes(routes);
   };

   angular
      .module('app.requests.activities')
      .run(runner);
}