namespace app.documentManagement {
   @Component('app.documentManagement', 'pamNewDocument', {
      templateUrl: 'app/documentManagement/new-document/new-document.html',
      bindings: {
         source: '<',
         sourceId: '<',
         involvementId: '<'
      }
   })
   class NewDocumentView {
      static $inject = ['common', 'datacontext', 'blobHelper'];
      constructor(
         private common: core.ICommonService,
         private datacontext: data.IDataContextService,
         private blobHelper: utils.IBlobHelperService) {
         'ngInject';
      }

      public data = { parameters: {} } as ICreateDocumentData;
      public involvementId: string;
      public source: string;
      public sourceId: string;
      public isSubmitting = false;
      public template: ITemplate;
      public templates: ITemplate[];

      public submit() {
         this.isSubmitting = true;
         this.datacontext.documentManagement
            .createDocument(this.data)
            .then(buffer => {
               if (buffer) {
                  this.blobHelper.open({
                     buffer,
                     contentType: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
                     fileName: `${this.template.title}.docx`
                  });
                  this.common.$state.go('^');
               }
            })
            .finally(() => this.isSubmitting = false);
      }

      private $onInit() {
         this.getTemplates();
      }

      private $onChanges() {
         this.data.involvementId = this.involvementId;
         this.data.source = this.source;
         this.data.sourceId = this.sourceId;
         this.data.parameters[this.data.source] = this.data.sourceId;
         this.data.parameters['involvementId'] = this.involvementId;
      }

      private getTemplates = () => {
         return this.datacontext.documentAssembly
            .getTemplates(this.source)
            .then(result => this.templates = result);
      };
   }
}