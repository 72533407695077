namespace app.activities {
   interface IActivityViewModel {
      icon: string;
      text: string;
      sref: string;
   }

   const activities: IActivityViewModel[] = [
   ];

   @Controller('app.activities', 'NewActivityDialogController')
   class NewActivityDialogController {
      static $inject = ['$mdDialog', 'common', 'datacontext', 'personId'];
      constructor(
         private $mdDialog: angular.material.IDialogService,
         private common: core.ICommonService,
         private datacontext: data.IDataContextService,
         private personId: string) {
         'ngInject';
         this.activate();
      }

      public filteredActivities: IActivityViewModel[];
      public involvements: involvements.IInvolvement[];
      public involvementSelectorVisible = false;
      public selectedInvolvement: involvements.IInvolvement;

      public selectInvolvement(involvement: involvements.IInvolvement) {
         this.selectedInvolvement = involvement;
         // this.filteredActivities = _.filter(activities, a => a.filter(involvement));
         // this.involvementSelectorVisible = false;
      }

      public selectActivity(item: IActivityViewModel) {
         this.common.$state.go(item.sref, { involvementId: this.selectedInvolvement.id });
         this.$mdDialog.hide();
      }

      private activate = () => {
         this.getInvolvements();
      };

      private getInvolvements = () => {
         this.datacontext.involvements
            .getInvolvements({ personId: this.personId, fields: 'id,idNumber,type,opeDate,isActive' })
            .then(result => {
               this.involvements = result;
               if (this.involvements.length) {
                  this.selectInvolvement(this.involvements[0]);
               }
            });
      };
   }
}