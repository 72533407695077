namespace app.identity {
   @Component('app.identity', 'ceEditProfilePhotoTab', {
      templateUrl: 'app/identity/edit-profile/edit-profile-photo.html',
      bindings: {
         photoId: '=',
         onDiscardPhoto: '&',
         useUploadMethod: '<'
      }
   })
   class EditProfilePhotoTab {
      static $inject = ['common', 'fileUploader', '$scope'];
      constructor(
         private common: core.ICommonService,
         private fileUploader: utils.IFileUploaderService,
         private $scope: angular.IScope) {
         'ngInject';
         this.activate();
      }

      public isUploading: boolean = false;
      public onDiscardPhoto: () => void;
      public photoId: string;
      public useUploadMethod: boolean;

      public discard = () => {
         delete this.$scope['file'];
         this.onDiscardPhoto();
      };

      private activate = () => {
         this.$scope.$watch('file', this.uploadFile);
      };

      private uploadFile = (file: any) => {
         if (!file) return;

         this.isUploading = true;
         this.fileUploader.upload({ file })
            .success((result: any) => this.photoId = result.id)
            .error(() => this.common.$mdToast.showSimple('Failed to upload file.'))
            .finally(() => this.isUploading = false);
      };
   }
}