namespace app.calendars {
   @Controller('app.calendars', 'FrequencyDialogController')
   class FrequencyDialogController {
      static $inject = ['$mdDialog', 'common', 'datacontext', 'schedule'];
      constructor(
         private $mdDialog: angular.material.IDialogService,
         private common: core.ICommonService,
         private datacontext: data.IDataContextService,
         public schedule: ISchedule
      ) {
         'ngInject';
         this.schedule.weekdays = this.schedule.weekdays || [];
         this.minDate = moment().toDate();
      }
      public minDate: Date;

      public cancel() {
         this.$mdDialog.cancel();
      }

      public hasWeekday(day: DayOfWeek) {
         return this.schedule.weekdays.indexOf(day) > -1;
      }

      public toggleWeekday(day: DayOfWeek) {
         const index = this.schedule.weekdays.indexOf(day);
         if (index > -1) {
            this.schedule.weekdays.splice(index, 1);
         } else {
            this.schedule.weekdays.push(day);
         }

         this.schedule.weekdays = _.sortBy(this.schedule.weekdays,
            d => {
               return _.findIndex(this.daysOfWeek,
                  dow => { return d === dow; });
            });
      }

      public selectDayOfMonth() {
         this.schedule.repeatBy = 'DayOfMonth';
         delete this.schedule.dayOfWeek;
         delete this.schedule.weekNumber;
      }

      public selectDayOfWeek() {
         this.schedule.repeatBy = 'DayOfWeek';
         delete this.schedule.dayOfMonth;
      }

      public submit() {
         this.buildDescription();
         this.$mdDialog.hide(this.schedule);
      }

      public buildDescription() {
         let d = `Every ${this.schedule.interval > 1 ? this.schedule.interval : ''}`;

         if (this.schedule.frequency === 'Weekly') {
            d += ` week${this.schedule.interval > 1 ? 's' : ''} on `;
            d += this.schedule.weekdays.join(', ');
         }
         else {
            d += ` month${this.schedule.interval > 1 ? 's' : ''} on `;
            if (this.schedule.repeatBy === 'DayOfWeek') {
               if (this.schedule.weekNumber === 5) {
                  d += ' the last';
               }
               else {
                  d += ` the ${this.numberToString[this.schedule.weekNumber - 1].value}`;
               }
               d += ` ${this.schedule.dayOfWeek}`;
            }
            else {
               d += ` the ${this.numberToString[this.schedule.dayOfMonth - 1].value} `;
            }
         }

         if (this.schedule.endDate) {
            d += ` until ${this.schedule.endDate.toLocaleDateString('en-US')}`;
         }

         this.schedule.summary = d.replace(/  /g, ' ');
      }

      private numberToString: any[] = [
         { key: 1, value: '1st' },
         { key: 2, value: '2nd' },
         { key: 3, value: '3rd' },
         { key: 4, value: '4th' },
         { key: 5, value: '5th' },
         { key: 6, value: '6th' },
         { key: 7, value: '7th' },
         { key: 8, value: '8th' },
         { key: 9, value: '9th' },
         { key: 10, value: '10th' },
         { key: 11, value: '11th' },
         { key: 12, value: '12th' },
         { key: 13, value: '13th' },
         { key: 14, value: '14th' },
         { key: 15, value: '15th' },
         { key: 16, value: '16th' },
         { key: 17, value: '17th' },
         { key: 18, value: '18th' },
         { key: 19, value: '19th' },
         { key: 20, value: '20th' },
         { key: 21, value: '21st' },
         { key: 22, value: '22nd' },
         { key: 23, value: '23rd' },
         { key: 24, value: '24th' },
         { key: 25, value: '25th' },
         { key: 26, value: '26th' },
         { key: 27, value: '27th' },
         { key: 28, value: '28th' },
         { key: 29, value: '29th' },
         { key: 30, value: '30th' },
         { key: 31, value: '31st' }

      ];

      private daysOfWeek: string[] = [
         'Sunday',
         'Monday',
         'Tuesday',
         'Wednesday',
         'Thursday',
         'Friday',
         'Saturday'
      ];
   }
}