namespace app.involvements {
   export interface IInvolvementRepository {
      getInvolvements(params: IGetInvolvementsParams): angular.IPromise<IInvolvement[]>;
      getEntitiesBasic(involvementId: string): angular.IPromise<involvements.IEntityBasicInfo[]>;
      getEntity(involvementId: string, entityId: string): angular.IPromise<IEntityView>;
      getEntitySummary(entityType: string, entityId: string): angular.IPromise<IEntityView>;
      getEntityListing(involvementId: string): angular.IPromise<IEntityListing[]>;
      getPartySummary(involvementId: string): angular.IPromise<IEntityListing[]>;
      getCaseListing(involvementId: string): angular.IHttpPromise<ICaseListing[]>;
      searchCases(params: ISearchCasesParams): data.IPagedListPromise<ICaseSearchView>;
      createEntity(entity: IInvolvementEntity): angular.IPromise<IEntityView>;
      updateEntity(entity: IInvolvementEntity): angular.IPromise<IEntityView>;
      deleteEntity(id: string): angular.IPromise<boolean>;
      conflictCheck(activity: ICreateActivity): angular.IHttpPromise<ICreateActivity>;
      createCaseAssessmentMeeting(meeting: ICaseAssessmentMeeting): angular.IPromise<ICaseAssessmentMeeting>;
      updateCaseAssessmentMeeting(meeting: ICaseAssessmentMeeting): angular.IPromise<ICaseAssessmentMeeting>;
      getCAM(involvementId: string, id: string): angular.IPromise<ICaseAssessmentMeeting>;
      getEvents(params: IGetEventsParams): angular.IPromise<IEvent[]>;
      getBasicInfo(involvementId: string): angular.IPromise<IInvolvementBasicInfo>;
   }

   const baseUrl = 'api/involvements';

   class InvolvementRepository implements IInvolvementRepository {
      constructor(private $http: angular.IHttpService) {
         'ngInject';
      }

      @ApiExecutor()
      public getEvents(params: IGetEventsParams) {
         return this.$http.get<IEvent[]>(`api/calendars/involvementevents`, { params }) as any;
      }

      @ApiExecutor()
      public getInvolvements(params: IGetInvolvementsParams) {
         return this.$http.get<IInvolvement[]>(baseUrl, { params }) as any;
      }

      @ApiExecutor()
      public getEntity(involvementId: string, entityId: string) {
         return this.$http.get<IEntityView>(`${baseUrl}/${involvementId}/entities/${entityId}`) as any;
      }

      @ApiExecutor()
      public getEntityListing(involvementId: string) {
         return this.$http.get<IEntityView[]>(`${baseUrl}/${involvementId}/entities`) as any;
      }

      @ApiExecutor()
      public getCaseListing(involvementId: string) {
         return this.$http.get<ICaseListing[]>(`${baseUrl}/${involvementId}/cases`) as any;
      }

      @ApiExecutor()
      public getEntitySummary(entityType: string, entityId: string) {
         return this.$http.get<IEntityView[]>(`${baseUrl}/entitySummary?entityType=${entityType}&entityId=${entityId}`) as any;
      }

      @ApiExecutor()
      public getPartySummary(involvementId: string) {
         return this.$http.get<IEntityView[]>(`${baseUrl}/${involvementId}/parties`) as any;
      }

      @ApiExecutor()
      public getEntitiesBasic(involvementId: string) {
         return this.$http.get<involvements.IEntityBasicInfo[]>(`${baseUrl}/${involvementId}/entitiesbasic`) as any;
      }

      @ApiExecutor()
      public searchCases(params: ISearchCasesParams) {
         return this.$http.get<ICaseSearchView[]>(`${baseUrl}/searchcases`, { params }) as any;
      }

      @ApiExecutor()
      public createEntity(entity: IInvolvementEntity) {
         return this.$http.post<IInvolvementEntity>(`${baseUrl}/entities`, entity) as any;
      }

      @ApiExecutor()
      public updateEntity(entity: IInvolvementEntity) {
         return this.$http.put<IInvolvementEntity>(`${baseUrl}/entities`, entity) as any;
      }

      @ApiExecutor()
      public deleteEntity(id: string) {
         return this.$http.delete<boolean>(`${baseUrl}/entities/${id}`) as any;
      }

      @ApiExecutor()
      public conflictCheck(activity: ICreateActivity) {
         return this.$http.post<ICreateActivity>(`${baseUrl}/${activity.involvementId}/conflictCheck`, activity) as any;
      }

      @ApiExecutor()
      public createCaseAssessmentMeeting(meeting: ICaseAssessmentMeeting) {
         return this.$http.post<ICaseAssessmentMeeting>(`${baseUrl}/${meeting.involvementId}/caseAssessmentMeeting`, meeting) as any;
      }

      @ApiExecutor()
      public updateCaseAssessmentMeeting(meeting: ICaseAssessmentMeeting) {
         return this.$http.put<ICaseAssessmentMeeting>(`${baseUrl}/${meeting.involvementId}/caseAssessmentMeeting/${meeting.id}`, meeting) as any;
      }

      @ApiExecutor()
      public getCAM(involvementId: string, id: string) {
         return this.$http.get<ICaseAssessmentMeeting>(`${baseUrl}/${involvementId}/caseAssessmentMeeting/${id}`) as any;
      }

      @ApiExecutor()
      public getBasicInfo(involvementId: string) {
         return this.$http.get<IInvolvementBasicInfo>(`${baseUrl}/${involvementId}/basicInfo`) as any;
      }
   }

   angular
      .module('app.involvements')
      .service('involvements.repository', InvolvementRepository);
}