namespace app.config {
   @Component('app.config', 'pamLookupsPicker', {
      templateUrl: 'app/config/components/lookups-picker/lookups-picker.html',
      bindings: {
         inputName: '@',
         label: '@',
         lookupType: '@',
         required: '@',
         value: '='
      },
      require: {
         form: '^^form'
      }
   })
   class SelectLookup {
      static $inject = ['common'];
      constructor(private common: core.ICommonService) {
         'ngInject';
      }

      public intialized = false;
      public lookups: string[];
      public lookupType: string;
      public value: string[];

      private $onChanges() {
         if (this.lookupType && !this.intialized) {
            this.intialized = true;
            this.common.configHelper
               .getLookupValues(this.lookupType)
               .then(lookups => {
                  this.lookups = lookups;
               });
         }
      }
   }
}