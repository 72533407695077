namespace app.alerts {
   const POLLING_INTERVAL = 180000; // 3 MINUTES

   @Component('app.alerts', 'ceNotificationsButton', {
      template: '<ce-badged-button badge="$ctrl.badge" icon="notifications" tooltip="Notifications" on-click="$ctrl.open($event)"></ce-badged-button>'
   })
   class NotificationsButton {
      static $inject = ['$mdSidenav', 'common', 'datacontext'];
      constructor(
         private $mdSidenav: angular.material.ISidenavService,
         private common: core.ICommonService,
         private datacontext: data.IDataContextService) {
         'ngInject';
      }

      private intervalId: number;
      badge: number | string;
      count: number;

      $onInit() {
         this.getNotificationsCount();
         this.intervalId = setInterval(() => this.getNotificationsCount(), POLLING_INTERVAL);
      }

      $onDestroy() {
         clearInterval(this.intervalId);
      }

      public open() {
         const sidenav = this.$mdSidenav('notifications');

         if (!sidenav.isOpen()) {
            sidenav.open();
            this.common.$broadcast(NOTIFICATIONS_SIDENAV_OPENED);
         }
      }

      private getNotificationsCount() {
         return this.datacontext.alerts.getNotificationsCount().then(result => {
            if (result) {
               this.setCount(result.count);
            }
         });
      }

      private setCount(count: number) {
         if (isNaN(count)) return;

         if (this.count !== count) {
            this.count = +count;
            this.badge = this.count > 99 ? '99+' : this.count;
         }
      }
   }
}