namespace app.training.training {
   @Component('app.training.training', 'ceTrainingDetails', {
      templateUrl: 'app/training/training/details/details.html',
      bindings: {
         data: '<',
         paramNumberOfBrochures: '<'
      }
   })
   class Training {
      static $inject = ['datacontext'];
      constructor(private datacontext: data.IDataContextService) {
         'ngInject';
      }

      public data: ITraining;
      public editTraining: boolean = false;

      public updateTraining(result: ITraining) {
         angular.extend(this.data, result);
         this.editTraining = false;
      }
   }
}