namespace app.analysis {
   @Component('app.analysis', 'aimsAnalysisChart', {
      templateUrl: 'app/analysis/components/chart/chart.html',
      bindings: {
         data: '<',
         metadata: '<'
      }
   })
   class ChartComponent {
   }
}