namespace app.entities {
   @Component('app.entities', 'ceEditEntityRelationships', {
      templateUrl: 'app/entities/roles/edit-roles.html',
      bindings: {
         association: '<'
      }
   })
   class EntityRelationships {
      static $inject = ['common', 'datacontext'];
      constructor(
         private common: core.ICommonService,
         private datacontext: data.IDataContextService) {
         'ngInject';
      }

      public roles: config.ILookup[];
      public selectedRoles: config.ILookup[] = [];
      public isSubmitting: boolean;
      public association: IAssociationView;

      public $onInit = () => {
         this.getRoles().then(() => this.getExistingRoles());
      }

      private transformChip(chip) {
         if (angular.isObject(chip)) {
            return chip;
         }
         return { name: chip, type: 'new' };
      }

      private querySearch(query) {
         let results = query ? _.filter(this.roles, (r: any) => {
            return query === '*' || r.value.toLowerCase().indexOf(query) > -1;
         }) : [];
         return results;
      }

      private getRoles = () => {
         let lookupTypeId: string = '';
         let promise: angular.IPromise<config.ILookupType>;

         // Supervised Staff management was moved to User configuration screen

         // if (this.association.relatedIsUser) {
         //   lookupTypeId = 'user-user-relationship';

         //   const lookupType: config.ILookupType = {
         //      id: lookupTypeId,
         //      description: 'User-User Relationships',
         //      lookups: [
         //         { value: 'Staff', isActive: true, lookupTypeId: lookupTypeId },
         //         { value: 'Supervisor', isActive: true, lookupTypeId: lookupTypeId }
         //      ]
         //   };

         //   const deferred = this.common.$q.defer<config.ILookupType>();
         //   deferred.resolve(lookupType);

         //   promise = deferred.promise;
         // } else {
         lookupTypeId = `${this.association.entityType}-${this.association.relatedEntityType}-relationship`;

         promise = this.datacontext.config.lookups.getLookupType(lookupTypeId);
         // }

         return promise.then(
            result => {
               this.roles = result.lookups;
            });
      }

      private getExistingRoles = () => {
         _.forEach(this.association.roles, (ieRole) => {
            this.selectedRoles.push(_.find(this.roles, (role) => {
               return role.value === ieRole;
            }));
         });
      }

      private onSubmit = () => {
         this.isSubmitting = true;
         let roles: string[] = _.map(this.selectedRoles, 'value');

         angular.extend(this.association, {
            roles: roles
         });

         let promise: angular.IPromise<IAssociationView>;
         promise = this.datacontext.entities.updateAssociation(this.association);

         promise.finally(() => {
            this.isSubmitting = false;
            this.common.$mdToast.showSimple('Entity Updated');
            this.common.$state.go('^.listing');
         });
      }
   }
}