namespace app.entities {
   @Component('app.entities', 'ceEntityRelationships', {
      templateUrl: 'app/entities/roles/roles.html',
      bindings: {
         agency: '<',
         person: '<',
         relatedEntity: '<'
      }
   })
   class EntityRelationships {
      static $inject = ['common', 'datacontext'];
      constructor(
         private common: core.ICommonService,
         private datacontext: data.IDataContextService) {
         'ngInject';
      }

      public roles: config.ILookup[];
      public selectedRoles: config.ILookup[] = [];
      public isSubmitting: boolean;
      public agency: agencies.IAgencyBasicInfo;
      public person: persons.IPersonBasicInfo;
      public entity = {} as IEntityBasicInfo;
      public relatedEntity: IEntityBasicInfo;
      public association = {} as ISaveAssociationData;

      public $onInit = () => {
         if (this.person) {
            this.entity.entityId = this.person.id;
            this.entity.entityType = 'Person';
            this.entity.isUser = this.person.isUser;
         } else {
            this.entity.entityId = this.agency.id;
            this.entity.entityType = 'Agency';
            this.entity.isUser = false;
         }

         this.getRoles().then(() => this.getExistingRoles());
      }

      private transformChip(chip) {
         if (angular.isObject(chip)) {
            return chip;
         }
         return { name: chip, type: 'new' };
      }

      private querySearch(query) {
         let results = query ? _.filter(this.roles, (r: any) => {
            return query === '*' || r.value.toLowerCase().indexOf(query) > -1;
         }) : [];
         return results;
      }

      private getRoles = () => {
         let lookupTypeId: string = '';
         let promise: angular.IPromise<config.ILookupType>;

         // Supervised Staff management was moved to User configuration screen

         // if (this.relatedEntity.isUser) {
         //   lookupTypeId = 'user-user-relationship';

         //   const lookupType: config.ILookupType = {
         //      id: lookupTypeId,
         //      description: 'User-User Relationships',
         //      lookups: [
         //         { value: 'Staff', isActive: true, lookupTypeId: lookupTypeId },
         //         { value: 'Supervisor', isActive: true, lookupTypeId: lookupTypeId }
         //      ]
         //   };

         //   const deferred = this.common.$q.defer<config.ILookupType>();
         //   deferred.resolve(lookupType);

         //   promise = deferred.promise;
         // } else {
         lookupTypeId = `${this.entity.entityType}-${this.relatedEntity.entityType}-relationship`;

         promise = this.datacontext.config.lookups.getLookupType(lookupTypeId);
         // }

         return promise.then(
            result => {
               this.roles = result.lookups;
            });
      }

      private getExistingRoles = () => {
         return this.datacontext.entities.getAssociation(this.entity.entityId, this.relatedEntity.entityId).then(
            (result) => {
               angular.extend(this.association, result);
               _.forEach(this.association.roles, (ieRole) => {
                  this.selectedRoles.push(_.find(this.roles, (role) => {
                     return role.value === ieRole;
                  }));
               });
            });
      }

      private onSubmit = () => {
         this.isSubmitting = true;
         let roles: string[] = _.map(this.selectedRoles, 'value');

         // if (this.relatedEntity.isUser && roles.indexOf('Staff') > -1) {
         //   angular.extend(this.association, {
         //      entityId: this.relatedEntity.entityId,
         //      relatedEntityId: this.entity.entityId,
         //      roles: ['Supervisor']
         //   });
         // } else {
         angular.extend(this.association, {
            entityId: this.entity.entityId,
            relatedEntityId: this.relatedEntity.entityId,
            roles: roles
         });
         // }

         let promise: angular.IPromise<IAssociationView>;

         if (this.association.id) {
            promise = this.datacontext.entities.updateAssociation(this.association);
         }
         else {
            promise = this.datacontext.entities.createAssociation(this.association);
         }

         promise.finally(() => {
            this.isSubmitting = false;
            this.common.$mdToast.showSimple('Entity Updated');
            this.common.$state.go('^.^.^');
         });
      }
   }
}