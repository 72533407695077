namespace app.funding {
   @Component('app.funding', 'ceSingleChoiceFundingView', {
      templateUrl: 'app/funding/single-choice/view/view.html',
      bindings: {
         fundingProgram: '<',
         onEdit: '&'
      }
   })
   class SingleChoiceFundingView {
      static $inject = ['common', 'datacontext'];
      constructor(
         private common: core.ICommonService,
         private datacontext: data.IDataContextService) {
         'ngInject';
      }

      public fundingProgram: IFundingProgram;

      public $onChange = () => {
      }
   }
}