namespace app.common {
   @Component('app.common', 'cePhone', {
      templateUrl: 'app/common/phone/phone.html',
      bindings: {
         phoneNumber: '<',
         onChange: '&',
         showVoiceMail: '@'
      }
   })
   class CePhone {
      private onChange: ({ }) => {};
      private phone: string;
      private extension: string;
      private allowsVoiceMail: boolean;
      private mask: string;
      private showVoiceMail: boolean;
      private phoneNumber: common.IPhone;
      private data: common.IPhone;
      private doNotAllowVoicemail: boolean;

      private $onInit() {
         this.data = this.phoneNumber || {} as common.IPhone;
         if (this.data.allowsVoiceMail === undefined) {
            this.data.allowsVoiceMail = true;
         }
         this.doNotAllowVoicemail = !this.data.allowsVoiceMail;

         if (this.data.phone) {
            this.data.phone = this.data.phone.substr(-10);
            this.setMask();
         }
      }

      public setMask = () => {
         this.mask = '(999) 999-9999';
      };

      public removeMask = () => {
         if (!this.data.phone) {
            this.mask = '';
         } else if (!this.data.phone.match(/^[0-9]{10}$/g)) {
            this.mask = '';
            this.data.phone = null;
         }
      };

      public changed() {
         this.onChange({ phone: { phone: this.data.phone, extension: this.data.extension, allowsVoiceMail: !this.doNotAllowVoicemail } });
      }
   }
}