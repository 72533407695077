namespace app.projects.activities {
   const routes: core.routing.IStateExtended[] = [
      {
         name: 'app.projects.activities',
         url: '/activities',
         template: '<ui-view layout="column" flex></ui-view>',
         redirectTo: 'app.projects.activities.list'
      },
      {
         name: 'app.projects.activities.list',
         url: '',
         template: `<ce-project-activity-list
                        project-id="$resolve.project.id"
                        layout="column" flex>
                    </ce-project-activity-list>`,
         data: {
            title: 'Project Activities',
            permissions: ['access_activities']
         }
      },
      {
         name: 'app.projects.activities.note',
         url: '/note',
         template: `<ce-projects-activities-note layout="column" flex
                        project-Id="$resolve.project.id"
                        activity-Type="ProjectNote"
                        name="Note"
                        activity="$resolve.activity"
                        case-open-date="$resolve.project.openDate"
                        return-sref="app.projects.activities.list">
                    </ce-projects-activities-note>`,
         params: { activity: null },
         resolve: { activity: ['$stateParams', p => p.activity] },
         data: { title: 'Note' }
      }
   ];

   const runner = (routingHelper: core.routing.IRoutingHelperService) => {
      'ngInject';
      routingHelper.configureRoutes(routes);
   };

   angular
      .module('app.projects.activities')
      .run(runner);
}