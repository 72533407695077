namespace app.funding {
   @Component('app.funding', 'cePairIntake', {
      templateUrl: 'app/funding/enrollments/pair/pair-intake.html',
      bindings: {
         enrollmentId: '<'
      }
   })
   class PAIRIntake {
      static $inject = ['common', 'datacontext'];
      constructor(
         private common: core.ICommonService,
         private datacontext: data.IDataContextService) {
         'ngInject';
      }
      public enrollmentId: string;
      public intake: IPAIRIntake;
      public editing: boolean;

      public $onInit() {
         this.getIntake();
      }

      public getIntake() {
         return this.datacontext.funding.getPAIRIntake(this.enrollmentId)
            .then(intake => { if (intake) this.intake = intake; });
      }

      public edit() {
         this.getIntake().then(() => {
            this.editing = true;
         });
      }

      public updateIntake() {
         if (this.intake.disability && this.intake.disability.indexOf('Other') === -1) {
            this.intake.disabilityOther = null;
         }
         if (this.intake.livingArrangement && this.intake.livingArrangement.indexOf('Other') === -1) {
            this.intake.livingArrangementOther = null;
         }
         if (this.intake.problemAreas && this.intake.problemAreas.indexOf('Other') === -1) {
            this.intake.problemAreasOther = null;
         }

         this.datacontext.funding.updatePAIRIntake(this.intake).then(
            () => {
               this.editing = false;
            }
         );
      }

      public cancel() {
         this.getIntake().then(() => {
            this.editing = false;
         });
      }

      public done() {
         this.common.$state.go('^.');
      }
   }
}