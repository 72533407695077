namespace app.fiscal {
   @Controller('app.fiscal', 'CheckSummaryDialogController')
   class CheckSummaryDialogController {
      static $inject = ['$scope', '$mdDialog', 'check'];
      constructor(
         private $scope: angular.IScope,
         private $mdDialog: angular.material.IDialogService,
         private check: ICheck) {
         'ngInject';
      }

      public cancel() {
         this.$mdDialog.cancel();
      }
   }
}