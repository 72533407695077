namespace app.activities {
   @Component('app.activities', 'ceActivityListView', {
      templateUrl: 'app/activities/activity-list/activity-list-view.html',
      bindings: {
         entityId: '<'
      }
   })
   class ActivityListViewComponent {
      static $inject = ['$mdDialog', '$mdSidenav', 'datacontext', 'blobHelper'];
      constructor(
         private $mdDialog: angular.material.IDialogService,
         private $mdSidenav: angular.material.ISidenavService,
         private datacontext: data.IDataContextService,
         private blobHelper: utils.IBlobHelperService) {
         'ngInject';
      }

      public activities: IActivity[] = [];
      public canShowMore = false;
      public isFiltered = false;
      public isLoading = false;
      public params = {
         page: 1,
         activityTypes: []
      } as ISearchActivitiesData;
      public entityId: string;

      public filter() {
         this.$mdSidenav('activity-filters').open();
      }

      public newActivity(targetEvent: MouseEvent) {
         const parent = angular.element(document.body);
         this.$mdDialog.show({
            parent,
            targetEvent,
            clickOutsideToClose: true,
            templateUrl: 'app/activities/new-activity/new-activity.html',
            controller: 'NewActivityDialogController',
            controllerAs: '$ctrl',
            locals: {
               entityId: this.entityId
            }
         });
      }

      public onActivityDeleted(activity: IActivity) {
         const index = this.activities.indexOf(activity);
         this.activities.splice(index, 1);
      }

      public onPostComment(activity: IActivity, value: string) {
         return this.datacontext.activities
            .postComment(activity.id, { value })
            .then(result => {
               if (result) {
                  activity.events.push(result);
               }
               return !!result;
            });
      }

      public onShowMore() {
         this.params.page++;
         this.getActivities();
      }

      public print() {
         const data: reports.IRunReportData = {
            name: 'ActivityList',
            parameters: [
               { name: 'PersonId', value: this.entityId },
               { name: 'ActivityIds', values: _.map(this.activities, a => a.id) }
            ]
         };

         this.datacontext.reports
            .runReport(data)
            .then(buffer => {
               if (buffer) {
                  this.blobHelper.open({
                     buffer,
                     contentType: 'application/pdf',
                     fileName: 'Activity List'
                  });
               }
            });
      }

      public refresh() {
         this.isFiltered = this.params.activityTypes.length > 0
            || !!this.params.from
            || !!this.params.to;

         this.activities.splice(0);
         this.getActivities();
      }

      private $onChanges() {
         this.params.entityId = this.entityId;
         this.getActivities();
      }

      private getActivities = () => {
         this.isLoading = true;
         return this.datacontext.activities
            .searchActivities(this.params)
            .then(result => {
               this.canShowMore = result.list.length >= result.pageSize;
               this.activities = _.uniqBy([...this.activities, ...result.list], a => a.id);
            })
            .finally(() => this.isLoading = false);
      };
   }
}