namespace app.persons.views {
   @Component('app.persons.views', 'ceClientInformationForm', {
      templateUrl: 'app/persons/views/client-information/client-information-form.html',
      bindings: {
         personId: '<',
         paramPovertyLevel: '<',
         paramAtPoverty: '<',
         paramRaceSubCategory: '<',
         onCancel: '&',
         onSubmit: '&'
      }
   })
   class ClientInformationForm {
      static $inject = ['common', 'datacontext', '$scope'];
      constructor(
         private common: core.ICommonService,
         private datacontext: data.IDataContextService,
         private $scope: angular.IScope) {
         'ngInject';
      }
      public isLoading: boolean = false;
      public personId: string;
      public data = {} as IDemographics;
      public incomeSources: app.config.ILookup[];
      public isSubmitting: boolean = false;
      public paramPovertyLevel: boolean;
      public paramAtPoverty: boolean;
      public paramRaceSubCategory: boolean;
      public raceSubcategories: app.config.IRaceSubcategory[] = [];
      public validRaceSubcategories: app.config.IRaceSubcategory[] = [];

      public onSubmit: (args: { result: IDemographics }) => void;

      public $onInit() {
         this.getData();
         this.getSubCategory();
         this.$scope.$watch(() => this.data.race, this.getValidRaceSubcategories);
      }

      public toggle(value: string) {
         if (!this.data.incomeSources) {
            this.data.incomeSources = [];
         }

         if (this.exists(value)) {
            _.pull(this.data.incomeSources, value);
         }
         else {
            this.data.incomeSources.push(value);
         }
      }

      public exists(value: string) {
         return _.includes(this.data.incomeSources, value);
      }

      public getData() {
         this.isLoading = true;

         const getLookups =
            this.datacontext.config.lookups.getLookupType('income-sources').then(
               result => this.incomeSources = result.lookups
            );

         const getDemographicData =
            this.datacontext.persons
               .getDemographics(this.personId)
               .then(result => this.data = result);

         this.common.$q.all([getLookups, getDemographicData]).finally(() => this.isLoading = false);
      }

      public getSubCategory() {
         return this.datacontext.admin.configuration.getRaceSubcategories().then((raceSubcategories: any) => {
            this.raceSubcategories = raceSubcategories;
            this.getValidRaceSubcategories();
         });
      }

      public raceChanged(test: any) {
         this.validRaceSubcategories = null;
      }

      public getValidRaceSubcategories = () => {
         this.validRaceSubcategories = this.raceSubcategories.filter(sc => { return sc.raceName === this.data.race; });
      }

      public submit() {
         this.isSubmitting = true;

         if (this.data.highestUnmetLegalNeed !== 'Other') {
            this.data.highestUnmetLegalNeedOther = null;
         }
         if (this.data.communicationPreference !== 'Other') {
            this.data.communicationPreferenceOther = null;
         }


         const updateClientInformation = this.datacontext.persons
            .updateDemographics(this.personId, this.data).then((result) => {
               if (result) this.onSubmit({ result });
            }).finally(() => this.isSubmitting = false);
      }
   }
}