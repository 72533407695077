namespace app.alerts {
   @Component('app.alerts', 'ceNotificationsSidebar', {
      templateUrl: 'app/alerts/components/notifications-sidebar/notifications-sidebar.html'
   })
   class NotificationsSidebar {
      static $inject = ['$mdDialog', '$mdSidenav', 'common', 'datacontext'];
      constructor(
         private $mdDialog: angular.material.IDialogService,
         private $mdSidenav: angular.material.ISidenavService,
         private common: core.ICommonService,
         private datacontext: data.IDataContextService) {
         'ngInject';
      }

      private cancelToken: Function;
      public loading = false;
      public notifications: INotification[];

      public clearNotification(notification: INotification) {
         this.datacontext.alerts.clearNotification(notification.id).then(result => {
            if (result) {
               _.remove(this.notifications, notification);
            }
         });
      }

      public openPreferences(targetEvent: MouseEvent) {
         const parent = angular.element(document.body);

         this.$mdDialog.show({
            parent,
            targetEvent,
            template: '<ce-notification-preferences aria-label="Notification preferences"></ce-notification-preferences>'
         });
      }

      public refresh() {
         this.getNotifications();
      }

      public showAll() {
         this.common.$state.go('app.notifications');
         this.$mdSidenav('notifications').close();
      }

      private $onInit = () => {
         this.cancelToken = this.common.$rootScope.$on(NOTIFICATIONS_SIDENAV_OPENED, this.getNotifications);
      };

      private $onDestroy = () => {
         this.cancelToken();
      };

      private getNotifications = () => {
         this.loading = true;
         return this.datacontext.alerts
            .getNotifications({ unclearedOnly: true, pageSize: 7 })
            .then(result => this.notifications = result ? result.list : [])
            .finally(() => this.loading = false);
      };
   }
}