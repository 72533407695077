namespace app.analysis {
   @Component('app.analysis', 'aimsAnalysisQueryChart', {
      templateUrl: 'app/analysis/views/query-details/query-chart.html',
      bindings: {
         chart: '<',
         onDelete: '&'
      }
   })
   class DashboardChartComponent {
      static $inject = ['common', 'datacontext'];
      constructor(
         private common: core.ICommonService,
         private datacontext: data.IDataContextService) {
         'ngInject';
      }

      public chart: IChart;
      public data: IChartData[];
      public onDelete: () => void;

      public deleteChart() {
         this.common.confirmation.show({
            title: 'Delete Chart?',
            message: 'Are you sure you want to delete this chart?',
            ok: 'Delete',
            cancel: 'Keep'
         }).then(this.deleteChartCore);
      }

      private $onInit() {
         this.getData();
      }

      private getData = () => {
         return this.datacontext.analysis
            .getChartData(this.chart.id)
            .then(result => this.data = result);
      };

      private deleteChartCore = () => {
         this.datacontext.analysis
            .deleteChart(this.chart.id)
            .then(result => {
               if (result) {
                  this.onDelete();
               }
            });
      };
   }
}