namespace app.grievance {
   @Component('app.grievance', 'amViewGrievanceButton', {
      template: `
      <md-button ng-click="$ctrl.openGrievanceDialog()"  style="font-weight:normal;text-transform:none;" permission="['grievances.view']">
         <md-icon aria-hidden="true">feedback</md-icon>
         <span class="pl-lg">View Grievance</span>
      </md-button>`,
      bindings: {
         grievanceId: '<',
      }
   })
   class ViewGrievanceButtonComponent {
      static $inject = ['$mdDialog', '$scope'];
      constructor(
         private $mdDialog: angular.material.IDialogService,
         private $scope: angular.IScope) {
         'ngInject';
      }

      private grievanceId: string;
      private openGrievanceDialog() {
         const parent = angular.element(document.body);
         return this.$mdDialog.show({
            parent,
            fullscreen: true,
            templateUrl: 'app/grievance/grievance-dialog/grievance-view-dialog.html',
            controller: 'GrievanceDialogController',
            controllerAs: '$ctrl',
            focusOnOpen: false,
            locals: {
               grievanceId: this.grievanceId,
               aCase: null
            }
         })
            .catch((error) => { if (error) throw error; });
      }
   }
}
