namespace app.entities.details {
   @Component('app.entities.details', 'ceAgencyNameForm', {
      templateUrl: 'app/entities/details/name/agency-name-form.html',
      bindings: {
         name: '<',
         onCancel: '&',
         onSubmit: '&'
      }
   })
   @Inject('common')
   class NameForm {
      static $inject = ['common'];
      constructor(
         private common: core.ICommonService) {
      }

      public data: IEntityName;
      public name: IEntityName;

      private $onChanges() {
         this.data = angular.extend({}, this.name);
      }
   }
}