namespace app.funding {
   interface IPAIRProblem {
      key: string;
      value: string;
   }

   @Component('app.funding', 'cePairIntakeForm', {
      templateUrl: 'app/funding/enrollments/pair/pair-intake-form.html',
      bindings: {
         intake: '<',
         onCancel: '&',
         onSubmit: '&'
      }
   })
   class PAIRIntakeForm {
      static $inject = ['common', 'datacontext'];
      constructor(
         private common: core.ICommonService,
         private datacontext: data.IDataContextService) {
         'ngInject';
      }

      public intake: IPAIRIntake;
      public problems: IPAIRProblem[] = [];

      public $onInit() {
         this.loadProblems();
      }

      // public toggle(problemKey: string) {
      //   if (this.exists(problemKey)) {
      //      _.pull(this.intake.problemAreas, problemKey);
      //   }
      //   else {
      //      if (this.intake.problemAreas == null) {
      //         this.intake.problemAreas = [];
      //      }
      //      this.intake.problemAreas.push(problemKey);
      //   }
      // }

      // public exists(problemKey: string) {
      //   return _.includes(this.intake.problemAreas, problemKey);
      // }

      public loadProblems() {
         this.problems.push({ key: 'Abuse', value: 'Involves such issues as inappropriate or excessive physical restraint, isolation or seclusion; inappropriate or excessive physical restraint, isolation or seclusion; inappropriate or excessive medication; involuntary sterilization; failure to provide appropriatemedical treatment; physical or sexual assault; threats of retaliation by facility staff; coercion; suspicious death, etc. ' });
         this.problems.push({ key: 'Access To Records', value: 'Individuals who were denied access to their records for some reason related to their disabilities.' });
         this.problems.push({ key: 'Architectural Accessibility', value: 'Complaints related to architectural barriers or other building accessibility problems. These complaints could relate to any aspect of accessibility to the building or structure, including the adjoining parking lot or garage, building entrance, interior rooms, signage, etc.  Include complaints for both public and private buildings/structures.' });
         this.problems.push({ key: 'Assistive Technology', value: 'Issues that generally involve the individual\'s access to needed assistive technology, such as augmentative communication devices, durable medical equipment, vehicle modifications, etc.' });
         this.problems.push({ key: 'Education', value: 'Issues such as IEP planning, development or implementation; inclusive settings in the schools; availability of assistive technology and interpreter services and devices in the schools; discipline, suspension or expulsion; and other education-related issues.  DO NOT INCLUDE complaints relating to building accessibility or program access of the school program.' });
         this.problems.push({ key: 'Employment', value: 'Issues such as hiring or termination, reasonable accommodations, supported employment, or employment benefits.' });
         this.problems.push({ key: 'Neglect', value: 'Involves issues such as the failure to provide appropriate diagnostic evaluations, personal care, written habilitation plan, adequate discharge planning, etc.' });
         this.problems.push({ key: 'Government Benefits/Services', value: 'Include individuals who had difficulty securing equal access to a government service or who have problems with government financial benefits programs, welfare-to-work programs, and other government programs.' });
         this.problems.push({ key: 'Health Care', value: 'Includes issues such as Medicaid problems; access to or denial of coverage for medical insurance; access to medical or treatment services or managed care, etc.' });
         this.problems.push({ key: 'Housing', value: 'Issues involving contracts/property ownership, zoning or restrictive covenants, reasonable accommodations, landlord/tenant, and subsidized or section 8 housing.' });
         this.problems.push({ key: 'Insurance', value: 'Include denial of private problemAreas or life insurance coverage due to the individual\'s problemAreas.DO NOT INCLUDE issues related to medical insurance because those complaints should be included under Health Care.' });
         this.problems.push({ key: 'Privacy Rights', value: 'These issues involve some invasion of the individual\'s rights of privacy, such as the release of medical information without the individual\'s consent.' });
         this.problems.push({ key: 'Program Access', value: 'Individuals who were denied equal access to a program due to their disabilities.  An example may include an individual who is not allowed to participate in a YMCA program due to a particular problemAreas.  DO NOT INCLUDE complaints that relate to the accessibility of the building in which the program is housed.' });
         this.problems.push({ key: 'Non-Government Services', value: 'Involves inability to receive non-government services, such as problems with obtaining interpreter services for medical visits; access to menus in alternative formats; obtaining needed personal assistance services, etc. DO NOT INCLUDE any issues involving services paid for with government (federal, state or local) funds.' });
         this.problems.push({ key: 'Transportation', value: 'Issues such as the accessibility or availability of accessible public transportation.' });
         this.problems.push({ key: 'Voting', value: 'Include voting issues, such as the accessibility of the voting progress for individuals with disabilities.' });
         this.problems.push({ key: 'Other', value: 'Include assistance for complaints not otherwise covered by the list of problem areas provided below.  Examples may include criminal actions, commitment to institutions, guardianship/conservatorship, wills, etc.  ' });
      }
   }
}