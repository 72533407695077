namespace app.funding {
   @Component('app.funding', 'ceSingleChoiceFundingForm', {
      templateUrl: 'app/funding/single-choice/edit/edit.html',
      bindings: {
         fundingProgram: '<',
         onCancel: '&',
         onSubmit: '&',
         source: '@'
      }
   })
   class SingleChoiceFundingForm {
      static $inject = ['common', 'datacontext'];
      constructor(
         private common: core.ICommonService,
         private datacontext: data.IDataContextService) {
         'ngInject';
      }
      public onSubmit: (args: { result: IFundingProgram }) => void;
      public fundingPrograms: IFundingProgram[];
      public selectedFundingProgram: IFundingProgram;
      public fundingProgramId: string;
      public source: string;

      public $onInit() {
         this.getFundingSources();
      }

      private getFundingSources = () => {
         return this.datacontext.funding
            .getFundingPrograms(true, this.source)
            .then(programs => this.fundingPrograms = programs);
      }

      public submit() {
         this.onSubmit({ result: this.selectedFundingProgram });
      }
   }
}