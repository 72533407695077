namespace app.training.cle {
   @Component('app.training.cle', 'ceCLEInformation', {
      templateUrl: 'app/training/continuing-legal-education/details/cle-information.html',
      bindings: {
         data: '<',
         onEdit: '&'
      }
   })
   class CLEInformation {
   }
}