namespace app.core.routing {
   const setupHandler = ($transitions: any, $window: angular.IWindowService) => {
      'ngInject';
      $transitions.onSuccess({
         to: state => state.data && state.data.title
      }, transition => {

         let { title } = transition.to().data;

         if (title instanceof Array) {
            const args: any[] = [];
            for (let i = 0; i < title.length - 1; i++) {
               args.push(transition.injector().get(title[i]));
            }

            title = title[title.length - 1].call(transition.to(), ...args);
         }

         $window.document.title = title + ' | PANDA';
      });
   };

   angular
      .module('app.core.routing')
      .run(setupHandler);
}