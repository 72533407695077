namespace app.persons {
   @Component('app.persons', 'cePersonSearch', {
      templateUrl: 'app/persons/find-create-person/person-search.html',
      bindings: {
      }
   })
   class FindPerson {
      static $inject = ['$scope', '$mdDialog', 'common', 'datacontext'];
      constructor(
         private $scope: angular.IScope,
         private $mdDialog: angular.material.IDialogService,
         private common: core.ICommonService,
         private datacontext: data.IDataContextService) {
         'ngInject';
      }

      public searchResults: data.IPagedList<IPerson>;
      public selectedPerson: IPerson;
      public totalResults: number;
      public params = {
         page: 1,
         pageSize: 5
      } as IGetPersonsParams;
      public isLoading: boolean = true;
      public promise: angular.IPromise<any>;
      public searched: boolean = false;

      private navigate(person: IPersonBasicInfo) {
         // todo pass this in
         let entity = {} as app.entities.IEntityBasicInfo;
         entity.entityId = person.id;
         entity.fullName = person.fullName;
         entity.entityType = 'Person';
         entity.isUser = person.isUser;
         this.common.$state.go('^.roles', { entity: entity });
      }

      public refresh = () => {
         this.search();
      }

      private search = () => {
         this.searched = true;
         if (!this.params.q) {
            this.searchResults = null;
            return;
         }

         return this.datacontext.persons
            .getPersons(this.params)
            .then(result => {
               this.searchResults = result;
            });
      }

      public submit() {
      }
   }
}