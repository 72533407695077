namespace app.clientCases.overview {
   @Component('app.clientCases.overview', 'ceClientCaseToDo', {
      templateUrl: 'app/client-cases/to-do-list/to-do-list.html',
      bindings: {
         case: '<'
      }
   })
   class ClientCaseToDo {
      static $inject = ['$mdDialog', 'common', 'datacontext'];
      constructor(
         private $mdDialog: angular.material.IDialogService,
         private common: core.ICommonService,
         private datacontext: data.IDataContextService) {
         'ngInject';
      }

      public case: IClientCaseBasicInfoView;
      public items: common.IToDoItem[];

      public $onInit() {
         this.datacontext.clientCases.getClientCaseToDo(this.case.id)
            .then(items => {
               this.items = items;
            }).then(() => angular.element('#todolistcard').attr('aria-hidden', 'false'));
      }
   }
}