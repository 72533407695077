namespace app.entities.details {
   @Component('app.entities.details', 'ceEntityNames', {
      templateUrl: 'app/entities/details/name/names.html',
      bindings: {
         entity: '<',
         entityType: '@'
      }
   })
   class Names {
      static $inject = ['common', 'datacontext'];
      constructor(
         private common: core.ICommonService,
         private datacontext: data.IDataContextService) {
         'ngInject';
      }

      public editMode = false;
      public mouseOver: boolean = false;
      public entity: IEntityBasicInfo;
      public entityType: string;
      public names: IEntityName[];
      public selectedName: IEntityName;
      public defaultNameType: string;

      public deleteName(name: IEntityName) {
         this.selectedName = name;
         this.common.confirmation.show({
            title: 'Delete Name',
            message: 'Are you sure you want to delete this name?',
            ok: 'Delete',
            cancel: 'Keep'
         }).then(this.deleteNameCore);
      }

      public cancelEdit() {
         this.editMode = false;
         this.selectedName = null;
      }

      public addName() {
         let name = {} as IEntityName;
         name.nameType = this.defaultNameType;
         this.editName(name);
      }

      public editName(name: IEntityName) {
         this.selectedName = name;
         this.editMode = true;
      }

      public saveName(data: IEntityName) {
         data.entityId = this.entity.id;

         const promise = this.selectedName.id
            ? this.datacontext.entities.updateEntityName(data)
            : this.datacontext.entities.createEntityName(data);

         if (data.isPrimary) {
            this.names.forEach(a => a.isPrimary = false);
         }

         promise.then(result => {
            if (result) {
               if (!this.selectedName.id) {
                  this.names.push(result);
               } else {
                  angular.extend(this.selectedName, result);
               }

               if (result.isPrimary) {
                  this.entity.fullName = result.fullName;
               }

               this.cancelEdit();
            }
         });
      }

      private $onInit() {
         // if (this.common.auth.checkPermission('person.names')) {
         this.getNames();
         // }
      }

      private deleteNameCore = () => {
         this.datacontext.entities.deleteEntityName(this.selectedName)
            .then(() => {
               const index = this.names.indexOf(this.selectedName);
               this.names.splice(index, 1);
               this.cancelEdit();
            });
      };

      private getNames = () => {
         this.datacontext.entities
            .getEntityNames(this.entity.id)
            .then(result => this.names = result);
      };
   }
}