namespace app.training.cle {
   @Component('app.training.cle', 'ceCLEInformationForm', {
      templateUrl: 'app/training/continuing-legal-education/details/cle-information-form.html',
      bindings: {
         cleId: '<',
         onCancel: '&',
         onSubmit: '&'
      }
   })
   class CLEInformationForm {
      static $inject = ['datacontext'];
      constructor(private datacontext: data.IDataContextService) {
         'ngInject';
      }
      public onSubmit: (args: { result: ICLECourse }) => void;
      public data: ICLECourse;
      public isLoading: boolean = false;
      public isSubmitting: boolean = false;
      public cleId: string;
      public courseForums: app.config.ILookup[];

      public $onInit() {
         this.getData();
      }

      public forumToggle(value: string) {
         if (this.forumExists(value)) {
            _.pull(this.data.forum, value);
         }
         else {
            this.data.forum.push(value);
         }
      }

      public forumExists(value: string) {
         if (this.isLoading) {
            return;
         }
         return _.includes(this.data.forum, value);
      }
      public materialToggle(value: string) {
         if (this.materialExists(value)) {
            _.pull(this.data.trainingMaterials, value);
         }
         else {
            this.data.trainingMaterials.push(value);
         }
      }

      public materialExists(value: string) {
         if (this.isLoading) {
            return;
         }
         return _.includes(this.data.trainingMaterials, value);
      }
      public difficultyLevelToggle(value: string) {
         if (this.difficultyLevelExists(value)) {
            _.pull(this.data.difficultyLevel, value);
         }
         else {
            this.data.difficultyLevel.push(value);
         }
      }
      public difficultyLevelExists(value: string) {
         if (this.isLoading) {
            return;
         }
         return _.includes(this.data.difficultyLevel, value);
      }

      public getData() {
         this.isLoading = true;
         this.datacontext.training
            .getCLEInformation(this.cleId)
            .then(result => this.data = result)
            .finally(() => this.isLoading = false);
         this.datacontext.config.lookups.getLookupType('course-forum').then(
            result => this.courseForums = result.lookups
         );
      }

      public submit() {
         this.isSubmitting = true;
         this.datacontext.training
            .updateCLEInformation(this.data)
            .then(result => {
               if (result) {
                  this.onSubmit({ result });
               }
            }).finally(() => this.isSubmitting = false);
      }
   }
}