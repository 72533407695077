namespace app.training.request {
   @Component('app.training.request', 'ceTrainingRequestInformationForm', {
      templateUrl: 'app/training/request/details/request-information-form.html',
      bindings: {
         requestId: '<',
         onCancel: '&',
         onSubmit: '&'
      }
   })
   class RequestInformationForm {
      static $inject = ['datacontext'];
      constructor(private datacontext: data.IDataContextService) {
         'ngInject';
      }
      public onSubmit: (args: { result: IRequest }) => void;
      public data: IRequest;
      public isLoading: boolean = false;
      public isSubmitting: boolean = false;
      public requestId: string;

      public $onInit() {
         this.getData();
      }

      public getData() {
         this.isLoading = true;
         this.datacontext.training
            .getRequestInformation(this.requestId)
            .then(result => this.data = result)
            .finally(() => this.isLoading = false);
      }

      public submit() {
         this.isSubmitting = true;
         this.datacontext.training
            .updateRequestInformation(this.data)
            .then(result => {
               if (result) {
                  this.onSubmit({ result });
               }
            }).finally(() => this.isSubmitting = false);
      }
   }
}