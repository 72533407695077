namespace app.monitoring.details {
   @Component('app.monitoring.details', 'ceMonitoringInformationForm', {
      templateUrl: 'app/monitoring/details/monitoring-information/monitoring-information-form.html',
      bindings: {
         monitoringId: '<',
         bypassCensus: '<',
         onCancel: '&',
         onSubmit: '&'
      }
   })
   class MonitoringInformationForm {
      static $inject = ['datacontext'];
      constructor(private datacontext: data.IDataContextService) {
         'ngInject';
      }
      public onSubmit: (args: { result: IMonitoringInformation }) => void;
      public data: IMonitoringInformation;
      public bypassCensus: boolean;
      public isLoading: boolean = false;
      public isSubmitting: boolean = false;
      public monitoringId: string;
      public legalTeams: app.config.ILegalTeam[];
      public lawCategories: app.config.ILawCategory[];
      public lawSubcategories: app.config.ILawSubcategory[];
      public validLawSubcategories: app.config.ILawSubcategory[];
      public disabilities: string[];

      public $onInit() {
         this.isLoading = true;
         this.getDisabilities();
         this.getLegalTeams();
         this.getLawCategoriesAndSubcategories().then(() => this.getData());
      }

      public getDisabilities() {
         this.datacontext.config.lookups.getLookupType('monitoring-disabilities').then(
            result => this.disabilities = result.lookups.map(l => { return l.value; })
         );
      }

      public getLegalTeams() {
         this.datacontext.config.legalTeams.getLegalTeams(true).then(
            result => this.legalTeams = result
         );
      }

      public lawCategoryChanged() {
         this.filterLawSubcategories();
      }

      public getLawCategoriesAndSubcategories() {
         return this.datacontext.config.getLawCategories()
            .then((lawCategories) => {
               this.lawCategories = lawCategories;
               return this.datacontext.config.getLawSubcategories().then((lawSubcategories: any) => {
                  this.lawSubcategories = lawSubcategories.data;
               });
            });
      }

      public getData() {
         this.isLoading = true;
         this.datacontext.monitoring
            .getMonitoringInformation(this.monitoringId)
            .then(result => {
               this.data = result;
               this.filterLawSubcategories();
               this.isLoading = false;
            })
            .finally(() => this.isLoading = false);
      }

      public filterLawSubcategories() {
         this.validLawSubcategories = _.filter(this.lawSubcategories, ct => {
            return ct.lawCategoryId === this.data.lawCategoryId;
         });
      }

      public submit() {
         this.isSubmitting = true;
         this.datacontext.monitoring
            .updateMonitoringInformation(this.data)
            .then(result => {
               if (result) this.onSubmit({ result });
            }).finally(() => this.isSubmitting = false);
      }
      public disabilityToggle(value: string) {
         if (value === '') {
            return;
         }

         if (this.disabilityExists(value)) {
            _.pull(this.data.disabilities, value);
         }
         else {
            this.data.disabilities.push(value);
         }
      }

      public disabilityExists(value: string) {
         if (this.isLoading) {
            return;
         }
         return _.includes(this.data.disabilities, value);
      }
   }
}