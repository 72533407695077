namespace app.time {
   @Component('app.time', 'ceTimeslipCalendar', {
      template: `<div layout>
      <div class="md-toolbar-tools" layout="row">
        <md-button class="md-icon-button" ng-click="$ctrl.prev()">
          <md-tooltip>Previous</md-tooltip>
          <md-icon>chevron_left</md-icon>
        </md-button>
        <div class="ce-title mb0 text-center" style="width:200px;">
          {{$ctrl.title}}
        </div>
        <md-button class="md-icon-button" ng-click="$ctrl.next()">
          <md-tooltip>Next</md-tooltip>
          <md-icon>chevron_right</md-icon>
        </md-button>
        <span flex></span>
        <md-button class="md-icon-button" ng-click="$ctrl.today()">
          <md-tooltip>Today</md-tooltip>
          <md-icon>today</md-icon>
        </md-button>
        <md-button class="md-icon-button" ng-click="$ctrl.changeView('agendaWeek')">
          <md-tooltip>Week</md-tooltip>
          <md-icon>view_week</md-icon>
        </md-button>
        <md-button class="md-icon-button" ng-click="$ctrl.changeView('month')">
          <md-tooltip>Month</md-tooltip>
          <md-icon>view_module</md-icon>
        </md-button>
      </div>
    </div>
    <md-divider></md-divider>
    <md-content id="calendar" style="max-height:700px;" ui-calendar="$ctrl.calendarOptions" class="calendar bg-white" ng-model="$ctrl.eventSources" calendar="calendar"></md-content>
    <md-button aria-label="menu" class="md-fab md-fab-bottom-right" ng-click="$ctrl.openTimeslipDialog()" permission="['timeslips.edit']">
      <md-icon>add</md-icon>
    </md-button>`,
      bindings: {
         users: '<',
         involvementId: '<',
         source: '<',
         sourceId: '<',
         sourceName: '<',
         sourceName2: '<'
      }
   })
   class Calendar {
      static $inject = ['$scope', '$mdPanel', '$mdSidenav', 'common', '$mdDialog', 'timeslipCalendarData', 'timeslipCalendarState'];
      constructor(
         private $scope: angular.IScope,
         private $mdPanel: angular.material.IPanelService,
         private $mdSidenav: angular.material.ISidenavService,
         private common: core.ICommonService,
         private $mdDialog: angular.material.IDialogService,
         private timeslipCalendarData: ICalendarDataService,
         private timeslipCalendarState: ICalendarStateService) {
         'ngInject';
      }

      private $calendar: app.calendars.fc.ICalendar;
      private state = this.timeslipCalendarState.state;
      private events: IEvent[];
      private canUnlockTimeslips: boolean;
      private minDate: moment.Moment;

      public involvementId: string;
      public source: string;
      public sourceId: string;
      public sourceName: string;
      public sourceName2: string;
      public users: users.IUser[];

      public calendarOptions: app.calendars.fc.ICalendarOptions = {
         aspectRatio: 0,
         scrollTime: '06:00',
         editable: false,
         eventLimit: true,
         header: false,
         selectable: false,
         selectHelper: false,
         dayClick: (targetDate: moment.Moment, targetEvent: MouseEvent, calendarView: app.calendars.fc.IView) => {
            this.createTimeslip(targetDate);
         },
         eventClick: (timeslipSummary: IEvent) => {
            this.showSummaries(timeslipSummary);
         }
      };
      public eventSources = [] as app.calendars.fc.IEventSource[];
      public selectedRange: moment.Range;
      public title: string;
      public visibleRange: moment.Range;
      public initialized: boolean;

      private createTimeslip(targetDate: moment.Moment) {
         if (!this.minDate || (targetDate.isAfter(this.minDate) || this.canUnlockTimeslips)) {
            this.openTimeslipDialog(targetDate, null);
         }
      }

      private $onChanges() {
         this.state.userIds = _.map(this.users, 'id');
         this.refresh();
      }

      private refresh = () => {
         if (this.initialized) {
            this.timeslipCalendarData.refresh(this.source, this.sourceId, this.involvementId);
         }
      }

      private showSummaries = (timeslipSummary: IEvent) => {
         const parent = angular.element(document.body);

         return this.$mdDialog.show({
            parent,
            fullscreen: true,
            templateUrl: 'app/time/timeslip-summary/timeslip-summary.html',
            controller: 'TimeslipSummaryDialogController',
            controllerAs: '$ctrl',
            locals: {
               timeslips: _.filter(this.timeslipCalendarData.timeslips, (e: IEvent) => { return e.date.toString() === timeslipSummary.date.toString() && e.userId === timeslipSummary.userId; })
            }
         }).then((id: string) => {
            if (id) {
               this.openTimeslipDialog(null, id);
            }
         })
            .catch((error) => { if (error) throw error; });
         // TODO: Could potentially launch into edit here if they click on a timeslip
         // .finally(() => { this.refresh(); });
      }

      private openTimeslipDialog = (targetDate: moment.Moment, id: string) => {
         const parent = angular.element(document.body);
         targetDate = targetDate || moment();
         // not adding a .catch to dialogs causes possibly unhandled rejection error

         return this.$mdDialog.show({
            parent,
            fullscreen: true,
            templateUrl: 'app/time/timeslip-dialog/timeslip-dialog.html',
            controller: 'TimeslipDialogController',
            controllerAs: '$ctrl',
            focusOnOpen: false,
            locals: {
               id: id,
               hours: null,
               involvementId: this.involvementId,
               source: this.source,
               sourceId: this.sourceId,
               sourceName: this.sourceName,
               sourceName2: this.sourceName2,
               date: new Date(targetDate.year(), targetDate.month(), targetDate.date()),
               fundingProgramId: null
            }
         })
            .catch((error) => { if (error) throw error; })
            .finally(() => { this.refresh(); });
      }

      public today() {
         this.$calendar.today();
      }

      public next() {
         this.$calendar.next();
      }

      public prev() {
         this.$calendar.prev();
      }

      public changeView(view: string) {
         this.$calendar.changeView(view);
      }

      public showFilters() {
         this.$mdSidenav('calendarFilters').toggle();
      }

      private $onInit() {
         this.canUnlockTimeslips = this.common.auth.checkPermissions('timeslips.unlock');
         this.timeslipCalendarData.getLastLock()
            .then(result => {
               if (result.lockDate) {
                  this.minDate = moment(result.lockDate).add(1, 'days');
               }
            });

         this.calendarOptions.viewRender = this.onViewRender;
         this.eventSources = this.timeslipCalendarData.eventSources;
         const cancelToken = this.$scope.$on('calendarEventAdded', this.refresh);
         this.$scope.$on('$destroy', cancelToken);

         this.common.$timeout(this.setContentHeight, 100);
         angular.element(this.common.$window).bind('resize', this.setContentHeight);
         this.initialized = true;
      }

      private onViewRender = (view: app.calendars.fc.IView) => {
         this.$calendar = view.calendar;
         this.title = view.title;

         let reload = true;
         if (this.state.dateRange) {
            reload = !(this.state.dateRange.contains(view.start) && this.state.dateRange.contains(view.end));
         }

         let viewDate = view.start.clone();
         if (view.type === 'month' && view.start.date() > 1) {
            viewDate = viewDate.startOf('month').add(1, 'month');
         }
         this.timeslipCalendarState.update({
            viewType: view.type,
            viewDate: viewDate
         });

         const start = view.start.local();
         const end = view.end.local();

         this.visibleRange = moment.range(start, end);
         this.selectedRange = moment.range(start, start);

         if (reload) {
            this.state.dateRange = this.visibleRange;
            this.common.$timeout(this.refresh);
         }
      };

      private setContentHeight = () => {
         this.$calendar.option('contentHeight', angular.element('#calendar').height());
      };
   }
}