namespace app.admin {
   export interface IAdminRepository {
      configuration: configuration.IConfigurationRespository;
      identityManagement: identityManagement.IIdentityManagementRepository;
   }

   @Inject(
      'admin.configuration.repository',
      'admin.identityManagement.repository')
   @Service('app.admin', 'admin.repository')
   class AdminRepository implements IAdminRepository {
      static $inject = ['configuration', 'identityManagement'];
      constructor(
         public configuration: configuration.IConfigurationRespository,
         public identityManagement: identityManagement.IIdentityManagementRepository
      ) {
      }
   }
}