namespace app.projects.activities {
   interface IActivityViewModel {
      icon: string;
      text: string;
      sref: string;
      activityType?: string;
      name?: string;
   }

   const activities: IActivityViewModel[] = [
      {
         icon: 'comment',
         text: 'Note',
         sref: '^.note',
      }
   ];

   @Controller('app.projects.activities', 'NewProjectActivityDialogController')
   class NewProjectActivityDialogController {
      static $inject = ['$mdDialog', 'common', 'projectId'];
      constructor(
         private $mdDialog: angular.material.IDialogService,
         private common: core.ICommonService,
         private projectId: string) {
         'ngInject';
      }

      public filteredActivities: IActivityViewModel[];

      private $onInit() {
         this.filteredActivities = activities;
      }

      public selectActivity(item: IActivityViewModel) {
         this.common.$state.go(item.sref, { projectId: this.projectId });
         this.$mdDialog.hide();
      }
   }
}