namespace app.time {
   'use strict';

   const routes: core.routing.IStateExtended[] = [
      {
         name: 'app.timeslipCalendar',
         url: '/timeslips-calendar',
         template: '<ce-timeslip-calendar-frame layout="column" flex></ce-timeslip-calendar-frame>',
         data: {
            title: 'Timeslip Calendar',
            permissions: ['timeslips.view']
         }
      }
   ];

   const runner = (routingHelper: core.routing.IRoutingHelperService) => {
      'ngInject';
      routingHelper.configureRoutes(routes);
   };

   angular
      .module('app.time')
      .run(runner);
}