namespace app.activities {
   @Component('app.activities', 'ceActivityCardSelector', {
      template: `<div flex ng-switch="$ctrl.activity.activityType">
                   <ce-document-activity-card flex
                                              ng-switch-when="Document"
                                              activity="$ctrl.activity"
                                              on-close="$ctrl.onClose()"
                                              on-delete="$ctrl.onDelete()"
                                              on-post-comment="$ctrl.onPostComment({value})">
                   </ce-document-activity-card>
                   <ce-activity-card flex
                                     ng-switch-default
                                     activity="$ctrl.activity"
                                     on-close="$ctrl.onClose()"
                                     on-post-comment="$ctrl.onPostComment({value})">
                   </ce-activity-card>
                 </div>`,
      bindings: {
         activity: '<',
         onClose: '&',
         onDelete: '&',
         onPostComment: '&'
      }
   })
   class ActivityCardSelectorComponent {
   }
}