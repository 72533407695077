namespace app.utils {
   @Component('app.utils', 'ceBackButton', {
      template: `<md-button class="md-icon-button" ng-click="$ctrl.back()">
                  <md-icon>arrow_back</md-icon>
                 </md-button>`,
      bindings: {
         isDirty: '<',
         fallbackUrl: '<'
      }
   })
   class BackButtonComponent {
      static $inject = ['common'];
      constructor(private common: core.ICommonService) {
         'ngInject';
      }

      public isDirty: boolean;
      public confirmation: string;
      public fallbackUrl: string;

      public back() {
         if (this.isDirty) {
            this.common.confirmation
               .show({
                  message: this.confirmation || 'Your changes will not be saved. Are you sure you want to discard your changes?',
                  title: 'Discard Changes?',
                  ok: 'Discard',
                  cancel: 'Cancel'
               })
               .then(() => this.common.routingHelper.back(this.fallbackUrl));
         } else {
            this.common.routingHelper.back(this.fallbackUrl);
         }
      }
   }
}