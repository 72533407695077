namespace app.fiscal {
   'use strict';

   export interface IFiscalRepository {
      getCheck(id: string): angular.IPromise<ICheckView>;
      deleteCheck(id: string): angular.IPromise<void>;
      getCheckListing(params: IGetChecksParams): data.IPagedListPromise<ICheckView>;
      getCheckListingDownload(params: IGetChecksParams): angular.IPromise<app.analysis.IDownloadDataResult>;
      createCheck(timeslip: ICheck): angular.IPromise<ICheckView>;
      updateCheck(timeslip: ICheck): angular.IPromise<ICheckView>;
   }

   const baseUrl = 'api/fiscal';

   class FiscalRepository implements IFiscalRepository {
      static $inject = ['$http', 'exception'];
      constructor(
         private $http: angular.IHttpService,
         private exception: core.exception.IExceptionService,
      ) {
      }

      @ApiExecutor()
      public getCheck(id: string) {
         return this.$http.get<ICheckView>(`${baseUrl}/checks/${id}`) as any;
      }

      public deleteCheck(id: string) {
         return this.$http.delete(`${baseUrl}/checks/${id}`) as any;
      }

      @ApiExecutor()
      public getCheckListing(params: IGetChecksParams) {
         return this.$http.get<data.IPagedListPromise<ICheckView>>(`${baseUrl}/checks`, { params }) as any;
      }

      public getCheckListingDownload(params: IGetChecksParams) {
         return this.$http.get<any>(`${baseUrl}/csv`, { params, responseType: 'arraybuffer' }).then(
            (response) => {
               return {
                  buffer: response.data,
                  contentType: response.headers()['content-type'],
                  fileName: response.headers()['content-disposition'].split(';')[1].split('=')[1].replace(/"/g, '')
               };
            }).catch(error => this.exception.catcher('XHR for getCsvData failed')(error)) as any;
      }

      @ApiExecutor()
      public updateCheck(check: ICheck) {
         return this.$http.put<ICheckView>(`${baseUrl}/checks/${check.id}`, check) as any;
      }

      @ApiExecutor()
      public createCheck(check: ICheck) {
         return this.$http.post<ICheckView>(`${baseUrl}/checks`, check) as any;
      }
   }

   angular
      .module('app.fiscal')
      .service('fiscal.repository', FiscalRepository);
}