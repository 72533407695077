namespace app.utils {
   export interface IRadioCheck {
      isChecked: boolean;
      value: any;
      onClick: (option: IRadioCheck) => void;
   }
   export interface IRadioCheckGroup {
      addCheck(option: IRadioCheck): void;
   }

   @Component('app.utils', 'ceRadioCheckGroup', {
      bindings: {
         label: '@',
         inputName: '@',
         required: '<?',
         value: '='
      },
      require: {
         form: '^^form'
      },
      transclude: true,
      template: `
        <div ng-transclude></div>
        <div class="hide">
          <input name="{{$ctrl.inputName}}" type="radio" ng-repeat="opt in $ctrl.options" ng-value="opt.value" ng-required="$ctrl.required" ng-model="$ctrl.value" />
        </div>
        <div ng-messages="$ctrl.form[$ctrl.inputName].$error" class="field-error" ng-if="$ctrl.form.$submitted">
          <div ng-message="required">{{$ctrl.label}} is required.</div>
        </div>`
   })
   class RadioCheckGroup implements IRadioCheckGroup {
      private options: IRadioCheck[] = [];
      private selectedOption: IRadioCheck;

      public value: any;

      public addCheck(option: IRadioCheck) {
         option.onClick = this.handleClick;
         option.isChecked = (option.value === this.value);

         this.options.push(option);
      }

      private handleClick = (option: IRadioCheck) => {
         if (this.selectedOption) {
            this.selectedOption.isChecked = false;
         }

         option.isChecked = true;
         this.value = option.value;
         this.selectedOption = option;
      };
   }

   @Component('app.utils', 'ceRadioCheck', {
      template: '<md-checkbox ng-checked="$ctrl.isChecked" ng-click="$ctrl.onClick($ctrl)">{{$ctrl.label}}</md-checkbox>',
      bindings: {
         label: '@',
         value: '<'
      },
      require: {
         group: '^ceRadioCheckGroup'
      }
   })
   class RadioCheck implements IRadioCheck {
      private group: IRadioCheckGroup;

      public isChecked = false;
      public value: any;
      public onClick: (option: IRadioCheck) => void;

      private $onInit() {
         this.group.addCheck(this);
      }
   }
}