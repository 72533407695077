namespace app.utils {
   const filter = () => {
      const units = [
         'bytes',
         'KB',
         'MB',
         'GB',
         'TB',
         'PB'
      ];

      return (bytes: number, precision: number) => {
         if (isNaN(bytes) || !isFinite(bytes)) {
            return '?';
         }

         let unit = 0;

         while (bytes >= 1024) {
            bytes /= 1024;
            unit++;
         }

         return bytes.toFixed(precision) + ' ' + units[unit];
      };
   };

   angular
      .module('app.utils')
      .filter('filesize', filter);
}