namespace app.grievance {
    @Component('app.grievance', 'ceGrievanceListing', {
        templateUrl: 'app/grievance/grievance-listing/grievance-listing.html',
        bindings: {
            case: '<',
            caseType: '@',
            personId: '<'
        }
    })
    class GrievanceListing {
        static $inject = ['$mdDialog', 'common', 'datacontext'];
        constructor(
            private $mdDialog: angular.material.IDialogService,
            private common: core.ICommonService,
            private datacontext: data.IDataContextService) {
            'ngInject';
        }

        public grievances: data.IPagedList<IGrievance>;
        public showFilters: boolean = false;
        private case: any;
        private caseType: string;
        private involvementId: string;
        public personId: string;
        public promise: angular.IPromise<any>;
        public paging: data.IPagedListParams = {
            page: 1,
            pageSize: 100
        };

        public isActive: boolean = true;
        public activeFilterInitialized: boolean;
        public fromDate: Date;
        public toDate: Date;
        public dateFilterInitialized: boolean;

        private $onInit() {
            if (this.case) {
                this.involvementId = this.case.involvementId;
            }
        }

        public activeFilterChanged(isActive: number) {
            this.activeFilterInitialized = true;
            switch (isActive) {
                case 0:
                    this.isActive = false;
                    break;
                case 1:
                    this.isActive = true;
                    break;
                case 2:
                    this.isActive = null;
                    break;
                default:
                    this.isActive = true;
                    break;
            }
            this.getGrievances();
        }

        public dateFilterChanged(dates: filters.IDateFilterFromTo) {
            this.dateFilterInitialized = true;
            this.fromDate = dates.fromDate;
            this.toDate = dates.toDate;
            this.getGrievances();
        }

        public toggleFilters() {
            this.showFilters = !this.showFilters;
        }

        public createGrievance(targetEvent: MouseEvent) {
            this.openGrievanceDialog(targetEvent, null);
        }

        public editGrievance(targetEvent: MouseEvent, grievance: IGrievance) {
            this.openGrievanceDialog(targetEvent, grievance.id);
        }

        private openGrievanceDialog(targetEvent: MouseEvent, grievanceId: string) {
            const parent = angular.element(document.body);
            let aCase: ICase;

            if (this.case) {
                aCase = {
                    id: this.case.id,
                    caseIdNumber: this.case.idNumber,
                    involvementId: this.involvementId,
                    caseType: this.caseType
                };
            }

            return this.$mdDialog.show({
                parent,
                targetEvent,
                fullscreen: true,
                templateUrl: 'app/grievance/grievance-dialog/grievance-dialog.html',
                controller: 'GrievanceDialogController',
                controllerAs: '$ctrl',
                locals: {
                    grievanceId: grievanceId,
                    aCase: aCase
                }
            }).then(() => {
                this.getGrievances();
            }).catch((error) => {
                if (error) throw error;
            });
        }

        private filtersInitialized() {
            return this.activeFilterInitialized &&
                this.dateFilterInitialized;
        }

        private getGrievances = () => {
            if (!this.dateFilterInitialized) {
                return;
            }

            const params: IGetGrievancesParams = {
                involvementId: this.involvementId,
                personId: this.personId,
                fromDate: this.fromDate,
                toDate: this.toDate,
                isActive: this.isActive,
                ...this.paging
            };

            this.promise = this.datacontext.grievance
                .getGrievanceListing(params)
                .then(result => {
                    this.grievances = result;
                });
        }

        public deleteGrievance(grievance: IGrievance) {
            this.common.confirmation.show({
                title: 'Delete Grievance',
                message: 'Are you sure you want to delete this grievance?',
                ok: 'Delete',
                cancel: 'Keep'
            }).then(() => {
                this.datacontext.grievance
                    .deleteGrievance(grievance.id)
                    .then(() => {
                        this.getGrievances();
                    });
            }).catch(angular.noop);
        }
    }
}