namespace app.mail {
   @Component('app.mail', 'ceEmailList', {
      templateUrl: 'app/mail/email-listing/email-list.html',
      bindings: {
         messages: '<',
         onShow: '&',
         onRename: '&',
         onDelete: '&',
         onChange: '&',
         onMove: '&',
         onCopy: '&',
         showReportDialog: '&',
         refresh: '&',
         paging: '<',
         promise: '<'
      }
   })
   class EmailList {
      static $inject = ['datacontext'];
      constructor(
         private datacontext: data.IDataContextService) {
         'ngInject';
      }

      private currentUserName: string;

      private $onInit() {
         this.datacontext.users.getCurrentUser().then(user => this.currentUserName = user.userName);
      }
   }
}