namespace app.mail {
   export interface IMailRepository {
      fetchNewMessages(): angular.IPromise<boolean>;
      getMessages(params?: IGetMessagesParams): data.IPagedListPromise<IMessage>;
      importAttachments(data: IImportAttachmentsData): angular.IPromise<boolean>;
      downloadAttachment(messageId: string, attachmentId: string): angular.IPromise<utils.IBlob>;
      linkMessages(data: ILinkMessagesData): angular.IPromise<boolean>;
      getMessage(id: string): angular.IPromise<IMessage>;
      createMessage(data: ICreateMessageData): angular.IPromise<IMessage>;
      changeMessageFolder(data: IChangeMessageFolderData): angular.IPromise<any>;
      moveMessage(data: IMoveMessageData): angular.IPromise<any>;
      copyMessage(data: ICopyMessageData): angular.IPromise<any>;
      getFolders(params: IGetFoldersParams): data.IPagedListPromise<IMessageFolder>;
      createFolder(data: ISaveFolderData): angular.IPromise<IFolder>;
      updateFolder(data: ISaveFolderData): angular.IPromise<IFolder>;
      deleteFolder(folderId: string): angular.IPromise<boolean>;
      getSources(params: IGetMailSourcesParams): angular.IPromise<IMailSource[]>;
      deleteMessage(messageId: string): angular.IPromise<boolean>;
   }

   const baseUrl = 'api/mail';

   class MailRepository implements IMailRepository {
      constructor(
         private $http: angular.IHttpService,
         private exception: core.exception.IExceptionService) {
         'ngInject';
      }

      @ApiExecutor({ returnValue: true })
      public fetchNewMessages() {
         return this.$http.post<boolean>(`${baseUrl}/messages/fetch`, null) as any;
      }

      @ApiExecutor()
      public getMessages(params?: IGetMessagesParams) {
         return this.$http.get<data.IPagedList<IMessage>>(`${baseUrl}/messages`, { params }) as any;
      }

      @ApiExecutor()
      public getMessage(id: string) {
         return this.$http.get<IMessage>(`${baseUrl}/messages/${id}`) as any;
      }

      @ApiExecutor({ returnValue: true })
      public importAttachments(data: IImportAttachmentsData) {
         return this.$http.post<boolean>(`${baseUrl}/attachments/import`, data) as any;
      }

      public downloadAttachment(messageId: string, attachmentId: string) {
         const config = { responseType: 'arraybuffer' };

         return this.$http.get<any>(`${baseUrl}/messages/${messageId}/attachments/${attachmentId}/download`, config).then(
            (response) => {
               const contentType = response.headers()['content-type'];
               let fileName = response.headers()['content-disposition'];

               if (fileName) {
                  fileName = fileName.split(';')[1].split('=')[1].replace(/"/g, '');
               }

               return {
                  buffer: response.data,
                  contentType,
                  fileName
               };
            }).catch(error => this.exception.catcher('downloadAttachment failed')(error)) as any;
      }

      @ApiExecutor({ returnValue: true })
      public linkMessages(data: ILinkMessagesData) {
         return this.$http.post<boolean>(`${baseUrl}/messages/link`, data) as any;
      }

      @ApiExecutor()
      public createMessage(data: ICreateMessageData) {
         return this.$http.post<IMessage>(`${baseUrl}/messages`, data) as any;
      }

      @ApiExecutor()
      public changeMessageFolder(data: IChangeMessageFolderData) {
         return this.$http.put<any>(`${baseUrl}/messages/${data.messageId}/change`, data) as any;
      }

      @ApiExecutor()
      public moveMessage(data: IMoveMessageData) {
         return this.$http.put<any>(`${baseUrl}/messages/${data.messageId}/move`, data) as any;
      }

      @ApiExecutor()
      public copyMessage(data: ICopyMessageData) {
         return this.$http.put<any>(`${baseUrl}/messages/${data.messageId}/copy`, data) as any;
      }

      @ApiExecutor()
      public getFolders(params: IGetFoldersParams) {
         return this.$http.get<data.IPagedListPromise<IMessageFolder>>(`${baseUrl}/folders`, { params }) as any;
      }

      @ApiExecutor()
      public createFolder(data: ISaveFolderData) {
         return this.$http.post<IFolder>(`${baseUrl}/folders`, data) as any;
      }

      @ApiExecutor()
      public updateFolder(data: ISaveFolderData) {
         return this.$http.put<IFolder>(`${baseUrl}/folders/${data.id}`, data) as any;
      }

      @ApiExecutor({ returnValue: true })
      public deleteFolder(folderId: string) {
         return this.$http.delete<boolean>(`${baseUrl}/folders/${folderId}`) as any;
      }

      @ApiExecutor()
      public getSources(params: IGetMailSourcesParams) {
         return this.$http.get<IMailSource>(`${baseUrl}/sources`, { params }) as any;
      }

      @ApiExecutor({ returnValue: true })
      public deleteMessage(messageId: string) {
         return this.$http.delete<boolean>(`${baseUrl}/messages/${messageId}`) as any;
      }
   }

   angular
      .module('app.mail')
      .service('mail.repository', MailRepository);
}