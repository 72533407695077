namespace app.funding {
   @Component('app.funding', 'cePatbiIntake', {
      templateUrl: 'app/funding/enrollments/patbi/patbi-intake.html',
      bindings: {
         enrollmentId: '<'
      }
   })
   class PATBIIntake {
      static $inject = ['common', 'datacontext'];
      constructor(
         private common: core.ICommonService,
         private datacontext: data.IDataContextService) {
         'ngInject';
      }
      public enrollmentId: string;
      public intake: IPATBIIntake;
      public editing: boolean;

      public $onInit() {
         this.getIntake();
      }

      public getIntake() {
         return this.datacontext.funding.getPATBIIntake(this.enrollmentId)
            .then(intake => { if (intake) this.intake = intake; });
      }

      public edit() {
         this.getIntake().then(() => {
            this.editing = true;
         });
      }

      public updateIntake() {
         this.datacontext.funding.updatePATBIIntake(this.intake).then(
            () => {
               this.editing = false;
            }
         );
      }

      public cancel() {
         this.getIntake().then(() => {
            this.editing = false;
         });
      }

      public done() {
         this.common.$state.go('^.');
      }
   }
}