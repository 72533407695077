namespace app.clientCases {
   @Component('app.clientCases', 'ceClientCaseAssessmentMeeting', {
      templateUrl: 'app/client-Cases/activities/case-assessment-meeting/case-assessment-meeting.html',
      bindings: {
         involvementId: '<',
         source: '@',
         sourceId: '<',
         returnSref: '@',
         showCommunicationDate: '@',
         activity: '<',
         caseOpenDate: '<'
      }
   })
   class CaseAssessmentMeeting {
      static $inject = ['common', 'datacontext'];
      constructor(
         private common: core.ICommonService,
         private datacontext: data.IDataContextService) {
         'ngInject';
      }

      public involvementId: string;
      public source: string;
      public sourceId: string;
      public returnSref: string;
      public showCommunicationDate: boolean;
      public activity: app.activities.IActivity;

      public isSubmitting: boolean = false;
      public meeting = {} as involvements.ICaseAssessmentMeeting;
      public selectedStatus: string;
      public maxDate: Date;

      private $onInit() {
         this.maxDate = moment().toDate();
         if (this.activity) {
            this.getCAM();
         } else {
            this.meeting.involvementId = this.involvementId;
            this.meeting.source = this.source;
            this.meeting.sourceId = this.sourceId;
            this.meeting.meetingDate = moment().toDate();
         }
         this.getStatus();
         this.returnSref = this.returnSref || '^.list';
      }

      private getCAM() {
         this.datacontext.involvements
            .getCAM(this.involvementId, this.activity.id)
            .then(result => this.meeting = result);
      }

      private getStatus() {
         return this.datacontext.clientCases.getClientCaseStatus(this.sourceId).then(
            result => {
               let data: any = result;
               if (data.status) {
                  this.selectedStatus = data.status;
                  this.meeting.statusDescription = data.statusDescription;
               }
            });
      }

      public submit() {
         this.isSubmitting = true;

         if (typeof this.meeting.meetingDate !== 'string') {
            this.meeting.meetingDate = this.meeting.meetingDate.toDateString();
         }

         if (this.meeting.communicationDate && typeof this.meeting.communicationDate !== 'string') {
            this.meeting.communicationDate = this.meeting.communicationDate.toDateString();
         }

         this.meeting.status = this.selectedStatus;
         if (this.selectedStatus && this.selectedStatus.indexOf('Other') === -1) {
            this.meeting.statusDescription = null;
         }

         const promise = this.meeting.id
            ? this.datacontext.involvements.updateCaseAssessmentMeeting(this.meeting)
            : this.datacontext.involvements.createCaseAssessmentMeeting(this.meeting);

         promise
            .then(request => {
               if (request) {
                  this.common.$mdToast.showSimple('Case Assessment Meeting ' + (this.meeting.id ? 'updated' : 'created'));
                  this.common.$timeout(() => { this.common.$rootScope.$broadcast('refreshBasicInfo'); }, 2000);
                  this.common.$state.go(this.returnSref, { involvementId: this.involvementId, sourceId: this.sourceId });
               }
            })
            .finally(() => this.isSubmitting = false);
      }
   }
}