namespace app.mail {
   @Controller('app.mail', 'ChangeMessageFolderDialogController')
   class ChangeMessageFolderDialogController {
      static $inject = ['common', '$mdDialog', 'datacontext', 'message'];
      constructor(
         private common: core.ICommonService,
         private $mdDialog: angular.material.IDialogService,
         private datacontext: data.IDataContextService,
         private message: IMessageFolder) {
         'ngInject';
      }

      public data = {} as IChangeMessageFolderData;
      public isSubmitting = false;
      public folders: IMessageFolder[];

      public submit() {
         this.isSubmitting = true;
         this.datacontext.mail
            .changeMessageFolder(this.data)
            .then(() => this.$mdDialog.hide())
            .finally(() => this.isSubmitting = false);
      }

      private $onInit() {
         this.getFolders();
         this.setData();
      }

      private setData() {
         this.data.messageId = this.message.id;
      }

      private getFolders = () => {
         return this.datacontext.mail
            .getFolders({ involvementId: this.message.involvementId, source: this.message.source, sourceId: this.message.sourceId, type: 'Folder', pageSize: 100 })
            .then(result => this.folders = result.list);
      };

      public cancel() {
         this.$mdDialog.cancel();
      }
   }
}