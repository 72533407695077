namespace app.utils {
   export interface IHideMidnightFilter {
      (value: string): string;
   }

   const hideMidnightFilter = ($filter: IFilterService) => {
      'ngInject';
      return (value: string) => {
         return value.replace(' 12:00 am', '');
      };
   };

   angular
      .module('app.utils')
      .filter('hideMidnightFilter', hideMidnightFilter);
}