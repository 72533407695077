namespace app.utils {
   export interface ITimeHelperService {
      times: string[];
   }

   class TimeHelperService {
      constructor() {
         for (let i = 8; i < 17; i++) {
            let hour = i % 12;
            if (hour === 0) hour = 12;

            const meridiem = i < 12 ? 'am' : 'pm';

            this.times.push(hour + ':00' + meridiem);
            this.times.push(hour + ':15' + meridiem);
            this.times.push(hour + ':30' + meridiem);
            this.times.push(hour + ':45' + meridiem);
         }
      }

      public times: string[] = [];

      public query(query: string) {
         return _.filter(this.times, t => t.indexOf(query) === 0);
      }
   }

   angular
      .module('app.utils')
      .service('timehelper', TimeHelperService);
}