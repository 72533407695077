namespace app.informationAndReferrals.overview {
   @Component('app.informationAndReferrals.overview', 'ceInformationAndReferralOverview', {
      templateUrl: 'app/information-and-referrals/overview/overview.html',
      bindings: {
         case: '<'
      }
   })
   class InformationAndReferralOverview {
      static $inject = ['$mdDialog', 'common', 'datacontext'];
      constructor(
         private $mdDialog: angular.material.IDialogService,
         private common: core.ICommonService,
         private datacontext: data.IDataContextService) {
         'ngInject';
      }

      public $onInit() {
      }

      private case: IInformationAndReferralBasicInfoView;
   }
}