namespace app.admin.identityManagement {
   @Component('app.admin.identityManagement', 'ceAdminRoleDetails', {
      templateUrl: 'app/admin/modules/identityManagement/views/role-details/role-details.html',
      bindings: {
         role: '<',
         permissions: '<'
      }
   })
   class Role {
      static $inject = ['common', 'datacontext'];
      constructor(
         private common: core.ICommonService,
         private datacontext: data.IDataContextService) {
         'ngInject';
      }

      public data: IUpdateRoleData;
      public isSubmitting = false;
      public role: IRole;
      public permissions: IPermission[];
      public permissionsByCategory: IDictionary<IPermission[]>;

      public deleteRole() {
         this.common.confirmation.show({
            message: `Delete ${this.role.id} role?`,
            ok: 'Delete',
            cancel: 'Keep'
         }).then(this.deleteRoleCore);
      }

      public hasPermission(id: string) {
         return _.some(this.data.permissions, p => p.permission === id);
      }

      public togglePermission(id: string) {
         if (!this.hasPermission(id)) {
            this.addPermssion(id);
         } else {
            this.removePermission(id);
         }
      }

      public submit() {
         this.datacontext.admin.identityManagement
            .updateRole(this.role.id, this.data)
            .then(result => {
               if (result) {
                  this.common.$mdToast.showSimple('Role updated');
                  this.common.$state.go('^');
               }
            }).finally(() => this.isSubmitting = false);
      }

      private $onInit() {
         this.common.setTitle(`${this.role.id} (Role)`);

         const { description } = this.role;
         this.data = {
            description,
            permissions: _.map(this.role.permissions, p => ({ permission: p.permission }))
         };

         this.permissionsByCategory = _(this.permissions)
            .orderBy(p => p.category)
            .groupBy(p => p.category || 'No Category')
            .value();
      }

      private addPermssion = (id: string) => {
         if (!_.some(this.data.permissions, p => p.permission === id)) {
            this.data.permissions.push({ permission: id });
         }

         const permission = _.find(this.permissions, p => p.id === id);
         _.forEach(permission.dependencies, this.addPermssion);
      };

      private deleteRoleCore = () => {
         return this.datacontext.admin.identityManagement
            .deleteRole(this.role.id)
            .then(result => {
               if (result) {
                  this.common.$mdToast.showSimple('Role deleted');
                  this.common.$state.go('^');
               }
            });
      };

      private removePermission = (id: string) => {
         _.remove(this.data.permissions, p => p.permission === id);

         _(this.permissions)
            .filter(p => p.dependencies && p.dependencies.indexOf(id) > -1)
            .map(p => p.id)
            .forEach(this.removePermission);
      };
   }
}