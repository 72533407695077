namespace app.utils {
   angular
      .module('app.utils')
      .directive('validSubmit', validSubmit);

   interface IFormController extends angular.IFormController {
      submit(): void;
   }

   function validSubmit($parse, $timeout) {
      'ngInject';
      return {
         require: 'form',
         link: (scope: angular.IScope, element: angular.IAugmentedJQuery, attrs: any, form: IFormController) => {
            form.$submitted = false;
            const fn = $parse(attrs.validSubmit);

            form.submit = () => {
               form.$submitted = true;
               if (form.$valid) {
                  fn(scope);
                  form.$submitted = false;
               }
            };

            element.on('submit', (event: JQueryEventObject) => {
               scope.$apply(() => {
                  form.$submitted = true;
                  if (form.$valid) {
                     fn(scope, { $event: event });
                     form.$setPristine();
                     form.$setUntouched();
                  }
               });
            });
         }
      };
   }
}