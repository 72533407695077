namespace app.persons.details {
   @Component('app.persons.details', 'cePersonContactInfo', {
      templateUrl: 'app/persons/details/contact-info/contact-info.html',
      bindings: {
         person: '<',
      }
   })
   class PersonContactInfo {
      public person: IPersonBasicInfo;
   }
}