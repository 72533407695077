namespace app.agencies {
   @Component('app.agencies', 'ceCreateAgency', {
      templateUrl: 'app/agencies/find-create-agency/create-agency.html',
      bindings: {
      }
   })
   class CreateAgency {
      static $inject = ['common', 'datacontext', 'addressHelper'];
      constructor(
         private common: core.ICommonService,
         private datacontext: data.IDataContextService,
         private addressHelper: utils.IAddressHelperService) {
         'ngInject';
      }

      public agency = {} as ICreateAgency;
      public address = {} as app.entities.IEntityAddress;
      public isSubmitting: boolean = false;

      public phoneChanged(phone: common.IPhone) {
         this.agency.phoneNumber = phone;
      }

      public onPlaceSelected(place: any) {
         this.common.$timeout(() => {
            angular.extend(this.address, this.addressHelper.mapFromGooglePlaceResult(place));
         });
      }

      public submit() {
         this.isSubmitting = true;

         let agencyPromise = this.datacontext.agencies
            .createAgency(this.agency)
            .then(agency => {
               if (agency) {
                  angular.extend(this.agency, agency);
                  this.common.$mdToast.showSimple('Agency created');
               }
            });

         if (this.address.line1) {
            agencyPromise.then(() => {
               this.address.entityId = this.agency.id;
               this.address.addressType = 'Commercial';
               this.address.isPrimary = true;
               this.datacontext.entities.createEntityAddress(this.address);
            });
         }

         agencyPromise.then(() => {
            this.isSubmitting = false;
            let entity = {} as app.entities.IEntityBasicInfo;
            entity.entityId = this.agency.id;
            entity.fullName = this.agency.name;
            entity.entityType = 'Agency';
            entity.isUser = false;
            this.common.$state.go('^.roles', { entity: entity });
         });
      }
   }
}