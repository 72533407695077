namespace app.systemicCases {
   @Component('app.systemicCases', 'ceCreateSystemicCase', {
      templateUrl: 'app/systemic-cases/create-systemic-case/create-systemic-case.html',
      bindings: {
         requestId: '<',
         informationAndReferralId: '<',
         clientCaseId: '<',
         involvementId: '<',
         caseOpenDate: '<'
      }
   })
   class CreateSystemicCase {
      static $inject = ['common', 'datacontext'];
      constructor(
         private common: core.ICommonService,
         private datacontext: data.IDataContextService) {
         'ngInject';
      }

      public requestId: string;
      public informationAndReferralId: string;
      public clientCaseId: string;
      public involvementId: string;
      public isSubmitting: boolean = false;
      public case = {} as ICreateSystemicCase;
      public maxDate: Date;

      private $onInit() {
         this.maxDate = moment().toDate();
         this.case.involvementId = this.involvementId;
         this.case.requestId = this.requestId;
         this.case.informationAndReferralId = this.informationAndReferralId;
         this.case.clientCaseId = this.clientCaseId;
         this.case.openDate = moment().toDate();
      }

      public submit() {
         this.isSubmitting = true;

         if (typeof this.case.openDate !== 'string') {
            this.case.openDate = this.case.openDate.toDateString();
         }

         this.datacontext.systemicCases
            .createSystemicCase(this.case)
            .then(id => {
               if (id) {
                  this.common.$mdToast.showSimple('Systemic Case Created');
                  this.common.$state.go('app.systemicCases', { systemicCaseId: id });
               }
            })
            .finally(() => this.isSubmitting = false);
      }
   }
}