namespace app.grievance {
    @Component('app.grievance', 'ceGrievanceList', {
        templateUrl: 'app/grievance/grievance-listing/grievance-list.html',
        bindings: {
            grievances: '<',
            onEdit: '&',
            onDelete: '&',
            refresh: '&',
            paging: '<',
            promise: '<'
        }
    })
    class GrievanceList {
        static $inject = ['$mdDialog'];
        constructor(
            private $mdDialog: angular.material.IDialogService) {
            'ngInject';
        }

        public viewGrievance(grievance: IGrievance) {
            return this.$mdDialog.show({
                fullscreen: true,
                templateUrl: 'app/grievance/grievance-dialog/grievance-view-dialog.html',
                controller: 'GrievanceViewDialogController',
                controllerAs: '$ctrl',
                locals: { grievance: grievance }
            });
        }
    }
}