namespace app.documentManagement {
   export interface IDocumentAssemblyRepository {
      assembleDocument(data: IAssembleDocumentData): angular.IPromise<any>;
      getTemplates(source: string): angular.IPromise<ITemplate[]>;
   }

   const baseUrl = 'api/document-assembly';

   class DocumentAssemblyRepository implements IDocumentAssemblyRepository {
      constructor(private $http: angular.IHttpService) {
         'ngInject';
      }

      @ApiExecutor()
      public assembleDocument(data: IAssembleDocumentData) {
         return this.$http.post<any>(`${baseUrl}/documents`, data, {
            responseType: 'arraybuffer'
         }) as any;
      }

      @ApiExecutor()
      public getTemplates(source: string) {
         return this.$http.get<ITemplate[]>(`${baseUrl}/templates`, { params: { appliesTo: source } }) as any;
      }
   }

   angular
      .module('app.documentManagement')
      .service('documentAssembly.repository', DocumentAssemblyRepository);
}