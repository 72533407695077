var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
var app;
(function (app) {
    var activities;
    (function (activities) {
        angular.module('app.activities', []);
    })(activities = app.activities || (app.activities = {}));
})(app || (app = {}));
var app;
(function (app) {
    var admin;
    (function (admin) {
        angular.module('app.admin', [
            'app.admin.configuration',
            'app.admin.identityManagement',
            'app.admin.tools',
            'app.admin.tenancy'
        ]);
    })(admin = app.admin || (app.admin = {}));
})(app || (app = {}));
var app;
(function (app) {
    var agencies;
    (function (agencies) {
        angular.module('app.agencies', [
            'app.agencies.overview',
            'app.agencies.details'
        ]);
    })(agencies = app.agencies || (app.agencies = {}));
})(app || (app = {}));
var app;
(function (app) {
    var analysis;
    (function (analysis) {
        angular.module('app.analysis', []);
    })(analysis = app.analysis || (app.analysis = {}));
})(app || (app = {}));
var app;
(function (app) {
    var alerts;
    (function (alerts) {
        angular.module('app.alerts', []);
    })(alerts = app.alerts || (app.alerts = {}));
})(app || (app = {}));
var app;
(function (app) {
    var calendars;
    (function (calendars) {
        angular.module('app.calendars', ['ui.calendar']);
    })(calendars = app.calendars || (app.calendars = {}));
})(app || (app = {}));
var app;
(function (app) {
    var cases;
    (function (cases) {
        angular.module('app.cases', []);
    })(cases = app.cases || (app.cases = {}));
})(app || (app = {}));
var app;
(function (app) {
    var clientCases;
    (function (clientCases) {
        angular.module('app.clientCases', [
            'app.clientCases.overview',
            'app.clientCases.details',
            'app.clientCases.activities',
            'app.clientCases.funding',
            'app.clientCases.closure',
            'app.clientCases.entities'
        ]);
    })(clientCases = app.clientCases || (app.clientCases = {}));
})(app || (app = {}));
var app;
(function (app) {
    var common;
    (function (common) {
        angular.module('app.common', []);
    })(common = app.common || (app.common = {}));
})(app || (app = {}));
var app;
(function (app) {
    var config;
    (function (config) {
        angular.module('app.config', []);
    })(config = app.config || (app.config = {}));
})(app || (app = {}));
var app;
(function (app) {
    var data;
    (function (data) {
        'use strict';
        angular.module('app.data', ['app.core.exception']);
    })(data = app.data || (app.data = {}));
})(app || (app = {}));
var app;
(function (app) {
    var core;
    (function (core) {
        angular.module('app.core', [
            'ngMaterial',
            'ngMessages',
            'ngSanitize',
            'angularMoment',
            'ngFileUpload',
            'ngStorage',
            'md.data.table',
            'fixed.table.header',
            'mdSteppers',
            'ui.mask',
            'ui.validate',
            'luegg.directives',
            'easypiechart',
            'angular.filter',
            'angular-flot',
            'btford.markdown',
            'ngScrollbars',
            'ngAria',
            'app.core.logging',
            'app.core.exception',
            'app.core.routing',
            'app.core.security'
        ]);
    })(core = app.core || (app.core = {}));
})(app || (app = {}));
var app;
(function (app) {
    var documentManagement;
    (function (documentManagement) {
        angular.module('app.documentManagement', []);
    })(documentManagement = app.documentManagement || (app.documentManagement = {}));
})(app || (app = {}));
var app;
(function (app) {
    var entities;
    (function (entities) {
        angular.module('app.entities', [
            'app.entities.activities',
            'app.entities.details',
            'app.entities.summary'
        ]);
    })(entities = app.entities || (app.entities = {}));
})(app || (app = {}));
var app;
(function (app) {
    var fiscal;
    (function (fiscal) {
        angular.module('app.fiscal', []);
    })(fiscal = app.fiscal || (app.fiscal = {}));
})(app || (app = {}));
var app;
(function (app) {
    var filters;
    (function (filters) {
        angular.module('app.filters', []);
    })(filters = app.filters || (app.filters = {}));
})(app || (app = {}));
var app;
(function (app) {
    var funding;
    (function (funding) {
        angular.module('app.funding', []);
    })(funding = app.funding || (app.funding = {}));
})(app || (app = {}));
var app;
(function (app) {
    var grievance;
    (function (grievance) {
        angular.module('app.grievance', []);
    })(grievance = app.grievance || (app.grievance = {}));
})(app || (app = {}));
var app;
(function (app) {
    var identity;
    (function (identity) {
        angular.module('app.identity', []);
    })(identity = app.identity || (app.identity = {}));
})(app || (app = {}));
var app;
(function (app) {
    var informationAndReferrals;
    (function (informationAndReferrals) {
        angular.module('app.informationAndReferrals', [
            'app.informationAndReferrals.overview',
            'app.informationAndReferrals.details',
            'app.informationAndReferrals.activities',
            'app.informationAndReferrals.entities',
            'app.informationAndReferrals.funding'
        ]);
    })(informationAndReferrals = app.informationAndReferrals || (app.informationAndReferrals = {}));
})(app || (app = {}));
var app;
(function (app) {
    var involvements;
    (function (involvements) {
        angular.module('app.involvements', [
            'app.involvements.entities'
        ]);
    })(involvements = app.involvements || (app.involvements = {}));
})(app || (app = {}));
var app;
(function (app) {
    var layout;
    (function (layout) {
        angular.module('app.layout', []);
    })(layout = app.layout || (app.layout = {}));
})(app || (app = {}));
var app;
(function (app) {
    var mail;
    (function (mail) {
        angular.module('app.mail', []);
    })(mail = app.mail || (app.mail = {}));
})(app || (app = {}));
var app;
(function (app) {
    var persons;
    (function (persons) {
        angular.module('app.persons', [
            'app.persons.overview',
            'app.persons.views',
            'app.persons.details'
        ]);
    })(persons = app.persons || (app.persons = {}));
})(app || (app = {}));
var app;
(function (app) {
    var monitoring;
    (function (monitoring) {
        angular.module('app.monitoring', [
            'app.monitoring.overview',
            'app.monitoring.details',
            'app.monitoring.activities',
            'app.monitoring.funding',
            'app.monitoring.entities',
            'app.monitoring.closure'
        ]);
    })(monitoring = app.monitoring || (app.monitoring = {}));
})(app || (app = {}));
var app;
(function (app) {
    var preferences;
    (function (preferences) {
        angular.module('app.preferences', ['app.preferences.favorites']);
    })(preferences = app.preferences || (app.preferences = {}));
})(app || (app = {}));
var app;
(function (app) {
    var projects;
    (function (projects) {
        angular.module('app.projects', [
            'app.projects.overview',
            'app.projects.details',
            'app.projects.entities',
            'app.projects.activities'
        ]);
    })(projects = app.projects || (app.projects = {}));
})(app || (app = {}));
var app;
(function (app) {
    var reports;
    (function (reports) {
        angular.module('app.reports', []);
    })(reports = app.reports || (app.reports = {}));
})(app || (app = {}));
var app;
(function (app) {
    var requests;
    (function (requests) {
        angular.module('app.requests', [
            'app.requests.overview',
            'app.requests.details',
            'app.requests.activities',
            'app.requests.entities',
        ]);
    })(requests = app.requests || (app.requests = {}));
})(app || (app = {}));
var app;
(function (app) {
    var search;
    (function (search) {
        angular.module('app.search', []);
    })(search = app.search || (app.search = {}));
})(app || (app = {}));
var app;
(function (app) {
    var systemicCases;
    (function (systemicCases) {
        angular.module('app.systemicCases', [
            'app.systemicCases.overview',
            'app.systemicCases.details',
            'app.systemicCases.activities',
            'app.systemicCases.closure',
            'app.systemicCases.entities',
            'app.systemicCases.funding'
        ]);
    })(systemicCases = app.systemicCases || (app.systemicCases = {}));
})(app || (app = {}));
var app;
(function (app) {
    var time;
    (function (time) {
        angular.module('app.time', []);
    })(time = app.time || (app.time = {}));
})(app || (app = {}));
var app;
(function (app) {
    var training;
    (function (training) {
        angular.module('app.training', [
            'app.training.request',
            'app.training.cle',
            'app.training.training',
            'app.training.entities',
            'app.training.funding'
        ]);
    })(training = app.training || (app.training = {}));
})(app || (app = {}));
var app;
(function (app) {
    var users;
    (function (users) {
        angular.module('app.users', []);
    })(users = app.users || (app.users = {}));
})(app || (app = {}));
var app;
(function (app) {
    var utils;
    (function (utils) {
        angular.module('app.utils', []);
    })(utils = app.utils || (app.utils = {}));
})(app || (app = {}));
var app;
(function (app) {
    var agencies;
    (function (agencies) {
        var details;
        (function (details) {
            angular.module('app.agencies.details', []);
        })(details = agencies.details || (agencies.details = {}));
    })(agencies = app.agencies || (app.agencies = {}));
})(app || (app = {}));
var app;
(function (app) {
    var agencies;
    (function (agencies) {
        var overview;
        (function (overview) {
            angular.module('app.agencies.overview', []);
        })(overview = agencies.overview || (agencies.overview = {}));
    })(agencies = app.agencies || (app.agencies = {}));
})(app || (app = {}));
var app;
(function (app) {
    var clientCases;
    (function (clientCases) {
        var activities;
        (function (activities) {
            angular.module('app.clientCases.activities', []);
        })(activities = clientCases.activities || (clientCases.activities = {}));
    })(clientCases = app.clientCases || (app.clientCases = {}));
})(app || (app = {}));
var app;
(function (app) {
    var clientCases;
    (function (clientCases) {
        var closure;
        (function (closure) {
            angular.module('app.clientCases.closure', []);
        })(closure = clientCases.closure || (clientCases.closure = {}));
    })(clientCases = app.clientCases || (app.clientCases = {}));
})(app || (app = {}));
var app;
(function (app) {
    var clientCases;
    (function (clientCases) {
        var details;
        (function (details) {
            angular.module('app.clientCases.details', []);
        })(details = clientCases.details || (clientCases.details = {}));
    })(clientCases = app.clientCases || (app.clientCases = {}));
})(app || (app = {}));
var app;
(function (app) {
    var clientCases;
    (function (clientCases) {
        var entities;
        (function (entities) {
            angular.module('app.clientCases.entities', []);
        })(entities = clientCases.entities || (clientCases.entities = {}));
    })(clientCases = app.clientCases || (app.clientCases = {}));
})(app || (app = {}));
var app;
(function (app) {
    var clientCases;
    (function (clientCases) {
        var funding;
        (function (funding) {
            angular.module('app.clientCases.funding', []);
        })(funding = clientCases.funding || (clientCases.funding = {}));
    })(clientCases = app.clientCases || (app.clientCases = {}));
})(app || (app = {}));
var app;
(function (app) {
    var clientCases;
    (function (clientCases) {
        var overview;
        (function (overview) {
            angular.module('app.clientCases.overview', []);
        })(overview = clientCases.overview || (clientCases.overview = {}));
    })(clientCases = app.clientCases || (app.clientCases = {}));
})(app || (app = {}));
var app;
(function (app) {
    var core;
    (function (core) {
        var exception;
        (function (exception) {
            angular.module('app.core.exception', ['app.core.logging']);
        })(exception = core.exception || (core.exception = {}));
    })(core = app.core || (app.core = {}));
})(app || (app = {}));
var app;
(function (app) {
    var core;
    (function (core) {
        var logging;
        (function (logging) {
            angular.module('app.core.logging', []);
        })(logging = core.logging || (core.logging = {}));
    })(core = app.core || (app.core = {}));
})(app || (app = {}));
var app;
(function (app) {
    var core;
    (function (core) {
        var routing;
        (function (routing) {
            angular.module('app.core.routing', ['ui.router', 'ui.router.upgrade']);
        })(routing = core.routing || (core.routing = {}));
    })(core = app.core || (app.core = {}));
})(app || (app = {}));
var app;
(function (app) {
    var core;
    (function (core) {
        var security;
        (function (security) {
            angular.module('app.core.security', []);
        })(security = core.security || (core.security = {}));
    })(core = app.core || (app.core = {}));
})(app || (app = {}));
var app;
(function (app) {
    var entities;
    (function (entities) {
        var activities;
        (function (activities) {
            angular.module('app.entities.activities', []);
        })(activities = entities.activities || (entities.activities = {}));
    })(entities = app.entities || (app.entities = {}));
})(app || (app = {}));
var app;
(function (app) {
    var entities;
    (function (entities) {
        var details;
        (function (details) {
            angular.module('app.entities.details', []);
        })(details = entities.details || (entities.details = {}));
    })(entities = app.entities || (app.entities = {}));
})(app || (app = {}));
var app;
(function (app) {
    var entities;
    (function (entities) {
        var summary;
        (function (summary) {
            angular.module('app.entities.summary', []);
        })(summary = entities.summary || (entities.summary = {}));
    })(entities = app.entities || (app.entities = {}));
})(app || (app = {}));
var app;
(function (app) {
    var informationAndReferrals;
    (function (informationAndReferrals) {
        var activities;
        (function (activities) {
            angular.module('app.informationAndReferrals.activities', []);
        })(activities = informationAndReferrals.activities || (informationAndReferrals.activities = {}));
    })(informationAndReferrals = app.informationAndReferrals || (app.informationAndReferrals = {}));
})(app || (app = {}));
var app;
(function (app) {
    var informationAndReferrals;
    (function (informationAndReferrals) {
        var details;
        (function (details) {
            angular.module('app.informationAndReferrals.details', []);
        })(details = informationAndReferrals.details || (informationAndReferrals.details = {}));
    })(informationAndReferrals = app.informationAndReferrals || (app.informationAndReferrals = {}));
})(app || (app = {}));
var app;
(function (app) {
    var informationAndReferrals;
    (function (informationAndReferrals) {
        var entities;
        (function (entities) {
            angular.module('app.informationAndReferrals.entities', []);
        })(entities = informationAndReferrals.entities || (informationAndReferrals.entities = {}));
    })(informationAndReferrals = app.informationAndReferrals || (app.informationAndReferrals = {}));
})(app || (app = {}));
var app;
(function (app) {
    var informationAndReferrals;
    (function (informationAndReferrals) {
        var funding;
        (function (funding) {
            angular.module('app.informationAndReferrals.funding', []);
        })(funding = informationAndReferrals.funding || (informationAndReferrals.funding = {}));
    })(informationAndReferrals = app.informationAndReferrals || (app.informationAndReferrals = {}));
})(app || (app = {}));
var app;
(function (app) {
    var informationAndReferrals;
    (function (informationAndReferrals) {
        var overview;
        (function (overview) {
            angular.module('app.informationAndReferrals.overview', []);
        })(overview = informationAndReferrals.overview || (informationAndReferrals.overview = {}));
    })(informationAndReferrals = app.informationAndReferrals || (app.informationAndReferrals = {}));
})(app || (app = {}));
var app;
(function (app) {
    var involvements;
    (function (involvements) {
        var entities;
        (function (entities) {
            angular.module('app.involvements.entities', []);
        })(entities = involvements.entities || (involvements.entities = {}));
    })(involvements = app.involvements || (app.involvements = {}));
})(app || (app = {}));
var app;
(function (app) {
    var persons;
    (function (persons) {
        var details;
        (function (details) {
            angular.module('app.persons.details', []);
        })(details = persons.details || (persons.details = {}));
    })(persons = app.persons || (app.persons = {}));
})(app || (app = {}));
var app;
(function (app) {
    var persons;
    (function (persons) {
        var overview;
        (function (overview) {
            angular.module('app.persons.overview', []);
        })(overview = persons.overview || (persons.overview = {}));
    })(persons = app.persons || (app.persons = {}));
})(app || (app = {}));
var app;
(function (app) {
    var persons;
    (function (persons) {
        var views;
        (function (views) {
            angular.module('app.persons.views', []);
        })(views = persons.views || (persons.views = {}));
    })(persons = app.persons || (app.persons = {}));
})(app || (app = {}));
var app;
(function (app) {
    var monitoring;
    (function (monitoring) {
        var activities;
        (function (activities) {
            angular.module('app.monitoring.activities', []);
        })(activities = monitoring.activities || (monitoring.activities = {}));
    })(monitoring = app.monitoring || (app.monitoring = {}));
})(app || (app = {}));
var app;
(function (app) {
    var monitoring;
    (function (monitoring) {
        var closure;
        (function (closure) {
            angular.module('app.monitoring.closure', []);
        })(closure = monitoring.closure || (monitoring.closure = {}));
    })(monitoring = app.monitoring || (app.monitoring = {}));
})(app || (app = {}));
var app;
(function (app) {
    var monitoring;
    (function (monitoring) {
        var entities;
        (function (entities) {
            angular.module('app.monitoring.entities', []);
        })(entities = monitoring.entities || (monitoring.entities = {}));
    })(monitoring = app.monitoring || (app.monitoring = {}));
})(app || (app = {}));
var app;
(function (app) {
    var monitoring;
    (function (monitoring) {
        var details;
        (function (details) {
            angular.module('app.monitoring.details', []);
        })(details = monitoring.details || (monitoring.details = {}));
    })(monitoring = app.monitoring || (app.monitoring = {}));
})(app || (app = {}));
var app;
(function (app) {
    var monitoring;
    (function (monitoring) {
        var funding;
        (function (funding) {
            angular.module('app.monitoring.funding', []);
        })(funding = monitoring.funding || (monitoring.funding = {}));
    })(monitoring = app.monitoring || (app.monitoring = {}));
})(app || (app = {}));
var app;
(function (app) {
    var monitoring;
    (function (monitoring) {
        var overview;
        (function (overview) {
            angular.module('app.monitoring.overview', []);
        })(overview = monitoring.overview || (monitoring.overview = {}));
    })(monitoring = app.monitoring || (app.monitoring = {}));
})(app || (app = {}));
var app;
(function (app) {
    var preferences;
    (function (preferences) {
        var favorites;
        (function (favorites) {
            angular.module('app.preferences.favorites', []);
        })(favorites = preferences.favorites || (preferences.favorites = {}));
    })(preferences = app.preferences || (app.preferences = {}));
})(app || (app = {}));
var app;
(function (app) {
    var projects;
    (function (projects) {
        var activities;
        (function (activities) {
            angular.module('app.projects.activities', []);
        })(activities = projects.activities || (projects.activities = {}));
    })(projects = app.projects || (app.projects = {}));
})(app || (app = {}));
var app;
(function (app) {
    var projects;
    (function (projects) {
        var details;
        (function (details) {
            angular.module('app.projects.details', []);
        })(details = projects.details || (projects.details = {}));
    })(projects = app.projects || (app.projects = {}));
})(app || (app = {}));
var app;
(function (app) {
    var projects;
    (function (projects) {
        var entities;
        (function (entities) {
            angular.module('app.projects.entities', []);
        })(entities = projects.entities || (projects.entities = {}));
    })(projects = app.projects || (app.projects = {}));
})(app || (app = {}));
var app;
(function (app) {
    var projects;
    (function (projects) {
        var overview;
        (function (overview) {
            angular.module('app.projects.overview', []);
        })(overview = projects.overview || (projects.overview = {}));
    })(projects = app.projects || (app.projects = {}));
})(app || (app = {}));
var app;
(function (app) {
    var requests;
    (function (requests) {
        var activities;
        (function (activities) {
            angular.module('app.requests.activities', []);
        })(activities = requests.activities || (requests.activities = {}));
    })(requests = app.requests || (app.requests = {}));
})(app || (app = {}));
var app;
(function (app) {
    var requests;
    (function (requests) {
        var details;
        (function (details) {
            angular.module('app.requests.details', []);
        })(details = requests.details || (requests.details = {}));
    })(requests = app.requests || (app.requests = {}));
})(app || (app = {}));
var app;
(function (app) {
    var requests;
    (function (requests) {
        var entities;
        (function (entities) {
            angular.module('app.requests.entities', []);
        })(entities = requests.entities || (requests.entities = {}));
    })(requests = app.requests || (app.requests = {}));
})(app || (app = {}));
var app;
(function (app) {
    var requests;
    (function (requests) {
        var overview;
        (function (overview) {
            angular.module('app.requests.overview', []);
        })(overview = requests.overview || (requests.overview = {}));
    })(requests = app.requests || (app.requests = {}));
})(app || (app = {}));
var app;
(function (app) {
    var systemicCases;
    (function (systemicCases) {
        var activities;
        (function (activities) {
            angular.module('app.systemicCases.activities', []);
        })(activities = systemicCases.activities || (systemicCases.activities = {}));
    })(systemicCases = app.systemicCases || (app.systemicCases = {}));
})(app || (app = {}));
var app;
(function (app) {
    var systemicCases;
    (function (systemicCases) {
        var closure;
        (function (closure) {
            angular.module('app.systemicCases.closure', []);
        })(closure = systemicCases.closure || (systemicCases.closure = {}));
    })(systemicCases = app.systemicCases || (app.systemicCases = {}));
})(app || (app = {}));
var app;
(function (app) {
    var systemicCases;
    (function (systemicCases) {
        var details;
        (function (details) {
            angular.module('app.systemicCases.details', []);
        })(details = systemicCases.details || (systemicCases.details = {}));
    })(systemicCases = app.systemicCases || (app.systemicCases = {}));
})(app || (app = {}));
var app;
(function (app) {
    var systemicCases;
    (function (systemicCases) {
        var entities;
        (function (entities) {
            angular.module('app.systemicCases.entities', []);
        })(entities = systemicCases.entities || (systemicCases.entities = {}));
    })(systemicCases = app.systemicCases || (app.systemicCases = {}));
})(app || (app = {}));
var app;
(function (app) {
    var systemicCases;
    (function (systemicCases) {
        var funding;
        (function (funding) {
            angular.module('app.systemicCases.funding', []);
        })(funding = systemicCases.funding || (systemicCases.funding = {}));
    })(systemicCases = app.systemicCases || (app.systemicCases = {}));
})(app || (app = {}));
var app;
(function (app) {
    var systemicCases;
    (function (systemicCases) {
        var overview;
        (function (overview) {
            angular.module('app.systemicCases.overview', []);
        })(overview = systemicCases.overview || (systemicCases.overview = {}));
    })(systemicCases = app.systemicCases || (app.systemicCases = {}));
})(app || (app = {}));
var app;
(function (app) {
    var training;
    (function (training) {
        var cle;
        (function (cle) {
            angular.module('app.training.cle', [
                'app.training.cle.entities',
                'app.training.cle.activities'
            ]);
        })(cle = training.cle || (training.cle = {}));
    })(training = app.training || (app.training = {}));
})(app || (app = {}));
var app;
(function (app) {
    var training;
    (function (training) {
        var funding;
        (function (funding) {
            angular.module('app.training.funding', []);
        })(funding = training.funding || (training.funding = {}));
    })(training = app.training || (app.training = {}));
})(app || (app = {}));
var app;
(function (app) {
    var training;
    (function (training) {
        var entities;
        (function (entities) {
            angular.module('app.training.entities', []);
        })(entities = training.entities || (training.entities = {}));
    })(training = app.training || (app.training = {}));
})(app || (app = {}));
var app;
(function (app) {
    var training;
    (function (training) {
        var request;
        (function (request) {
            angular.module('app.training.request', [
                'app.training.request.entities'
            ]);
        })(request = training.request || (training.request = {}));
    })(training = app.training || (app.training = {}));
})(app || (app = {}));
var app;
(function (app) {
    var training;
    (function (training_1) {
        var training;
        (function (training) {
            angular.module('app.training.training', [
                'app.training.training.entities',
                'app.training.training.activities'
            ]);
        })(training = training_1.training || (training_1.training = {}));
    })(training = app.training || (app.training = {}));
})(app || (app = {}));
var app;
(function (app) {
    var admin;
    (function (admin) {
        var configuration;
        (function (configuration) {
            angular.module('app.admin.configuration', []);
        })(configuration = admin.configuration || (admin.configuration = {}));
    })(admin = app.admin || (app.admin = {}));
})(app || (app = {}));
var app;
(function (app) {
    var admin;
    (function (admin) {
        var identityManagement;
        (function (identityManagement) {
            angular.module('app.admin.identityManagement', []);
        })(identityManagement = admin.identityManagement || (admin.identityManagement = {}));
    })(admin = app.admin || (app.admin = {}));
})(app || (app = {}));
var app;
(function (app) {
    var admin;
    (function (admin) {
        var tenancy;
        (function (tenancy) {
            angular.module('app.admin.tenancy', []);
        })(tenancy = admin.tenancy || (admin.tenancy = {}));
    })(admin = app.admin || (app.admin = {}));
})(app || (app = {}));
var app;
(function (app) {
    var admin;
    (function (admin) {
        var tools;
        (function (tools) {
            angular.module('app.admin.tools', []);
        })(tools = admin.tools || (admin.tools = {}));
    })(admin = app.admin || (app.admin = {}));
})(app || (app = {}));
var app;
(function (app) {
    var training;
    (function (training) {
        var cle;
        (function (cle) {
            var activities;
            (function (activities) {
                angular.module('app.training.cle.activities', []);
            })(activities = cle.activities || (cle.activities = {}));
        })(cle = training.cle || (training.cle = {}));
    })(training = app.training || (app.training = {}));
})(app || (app = {}));
var app;
(function (app) {
    var training;
    (function (training) {
        var cle;
        (function (cle) {
            var entities;
            (function (entities) {
                angular.module('app.training.cle.entities', []);
            })(entities = cle.entities || (cle.entities = {}));
        })(cle = training.cle || (training.cle = {}));
    })(training = app.training || (app.training = {}));
})(app || (app = {}));
var app;
(function (app) {
    var training;
    (function (training) {
        var request;
        (function (request) {
            var entities;
            (function (entities) {
                angular.module('app.training.request.entities', []);
            })(entities = request.entities || (request.entities = {}));
        })(request = training.request || (training.request = {}));
    })(training = app.training || (app.training = {}));
})(app || (app = {}));
var app;
(function (app) {
    var training;
    (function (training_2) {
        var training;
        (function (training) {
            var activities;
            (function (activities) {
                angular.module('app.training.training.activities', []);
            })(activities = training.activities || (training.activities = {}));
        })(training = training_2.training || (training_2.training = {}));
    })(training = app.training || (app.training = {}));
})(app || (app = {}));
var app;
(function (app) {
    var training;
    (function (training_3) {
        var training;
        (function (training) {
            var entities;
            (function (entities) {
                angular.module('app.training.training.entities', []);
            })(entities = training.entities || (training.entities = {}));
        })(training = training_3.training || (training_3.training = {}));
    })(training = app.training || (app.training = {}));
})(app || (app = {}));
var app;
(function (app) {
    function ApiExecutor(options) {
        return function (target, propertyKey, descriptor) {
            var originalMethod = descriptor.value;
            descriptor.value = function () {
                var args = [];
                for (var _i = 0; _i < arguments.length; _i++) {
                    args[_i] = arguments[_i];
                }
                return originalMethod.apply(this, args)
                    .then(function (result) {
                    if (options && angular.isDefined(options.returnValue)) {
                        return options.returnValue;
                    }
                    else if (options && options.keyBy) {
                        return _.keyBy(result.data, options.keyBy);
                    }
                    else {
                        return result.data;
                    }
                })
                    .catch(function (error) {
                    if (error.status === 401 || error.status === 404)
                        return;
                    var exception = angular.element(document.body).injector().get('exception');
                    var message;
                    if (error.data) {
                        message = error.data.exceptionMessage || error.data.message;
                    }
                    else if (error.status === 403) {
                        message = 'Access denied';
                    }
                    if (!message) {
                        message = error.statusText || "XHR for ".concat(propertyKey, " failed");
                    }
                    exception.catcher(message)(error);
                });
            };
            return descriptor;
        };
    }
    app.ApiExecutor = ApiExecutor;
    function Component(moduleName, selector, options) {
        return function (controller) {
            angular.module(moduleName)
                .component(selector, __assign(__assign({}, options), { controller: controller }));
        };
    }
    app.Component = Component;
    function Controller(moduleName, controllerName) {
        return function (controller) {
            angular
                .module(moduleName)
                .controller(controllerName, controller);
        };
    }
    app.Controller = Controller;
    function Inject() {
        var dependencies = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            dependencies[_i] = arguments[_i];
        }
        return function (target, decoratedPropertyName) {
            var normalizedDependencies = [];
            for (var _i = 0, dependencies_1 = dependencies; _i < dependencies_1.length; _i++) {
                var dep = dependencies_1[_i];
                normalizedDependencies = normalizedDependencies.concat(dep.replace(/\s+/g, '').split(','));
            }
            var $inject = target.$inject = [];
            $inject = $inject.concat(normalizedDependencies);
            target.prototype.$inject = $inject;
            target.$inject = $inject;
        };
    }
    app.Inject = Inject;
    function Service(moduleName, serviceName) {
        return function (service) {
            angular
                .module(moduleName)
                .service(serviceName, service);
        };
    }
    app.Service = Service;
})(app || (app = {}));
if (!String.prototype.endsWith) {
    String.prototype.endsWith = function (suffix) {
        var str = this;
        return str && str.indexOf(suffix, str.length - suffix.length) !== -1;
    };
}
var app;
(function (app) {
    var activities;
    (function (activities) {
        var baseUrl = 'api/activities';
        var ActivityRepository = (function () {
            function ActivityRepository($http) {
                this.$http = $http;
                'ngInject';
                'ngInject';
            }
            ActivityRepository.prototype.createActivity = function (data) {
                return this.$http.post("".concat(baseUrl, "/activity"), data);
            };
            ActivityRepository.prototype.getActivity = function (activityId) {
                return this.$http.get("".concat(baseUrl, "/").concat(activityId));
            };
            ActivityRepository.prototype.postComment = function (activityId, data) {
                return this.$http.post("".concat(baseUrl, "/").concat(activityId, "/events/comments"), data);
            };
            ActivityRepository.prototype.searchActivities = function (data) {
                return this.$http.post("".concat(baseUrl, "/search"), data);
            };
            __decorate([
                app.ApiExecutor()
            ], ActivityRepository.prototype, "createActivity", null);
            __decorate([
                app.ApiExecutor()
            ], ActivityRepository.prototype, "getActivity", null);
            __decorate([
                app.ApiExecutor()
            ], ActivityRepository.prototype, "postComment", null);
            __decorate([
                app.ApiExecutor()
            ], ActivityRepository.prototype, "searchActivities", null);
            return ActivityRepository;
        }());
        angular
            .module('app.activities')
            .service('activities.repository', ActivityRepository);
    })(activities = app.activities || (app.activities = {}));
})(app || (app = {}));
var app;
(function (app) {
    var activities;
    (function (activities) {
        activities.activityResolver = function (datacontext, $stateParams) {
            'ngInject';
            var activityId = $stateParams.activityId;
            return datacontext.activities.getActivity(activityId);
        };
    })(activities = app.activities || (app.activities = {}));
})(app || (app = {}));
var app;
(function (app) {
    var activities;
    (function (activities) {
        var routes = [
            {
                name: 'app.activities',
                url: '/activities',
                template: '<ui-view layout flex></ui-view>',
                redirectTo: 'app.person.activities.list'
            },
            {
                name: 'app.activities.list',
                url: '',
                template: '<ce-activity-list-view layout flex person-id="$resolve.person.id"></ce-activity-list-view>'
            },
            {
                name: 'app.activities.activity',
                url: '/activities/activity?involvementId',
                template: '<ce-activities-activity layout flex involvement-id="$resolve.involvementId"></ce-activities-activity>',
                resolve: {
                    involvementId: ['$stateParams', function (p) { return p.involvementId; }],
                    name: ['$stateParams', function (p) { return p.name; }]
                }
            }
        ];
        var runner = function (routingHelper) {
            'ngInject';
            routingHelper.configureRoutes(routes);
        };
        angular
            .module('app.activities')
            .run(runner);
    })(activities = app.activities || (app.activities = {}));
})(app || (app = {}));
var app;
(function (app) {
    var activities;
    (function (activities) {
        activities.ActivityConfig = [
            {
                activityType: 'CaseAssessmentMeeting',
                reportName: 'SingleNote',
                canEdit: true
            },
            {
                activityType: 'CaseReview',
                reportName: 'SingleNote',
                canEdit: true
            },
            {
                activityType: 'FollowUp',
                reportName: 'SingleNote',
                canEdit: true
            },
            {
                activityType: 'Note',
                reportName: 'SingleNote',
                canEdit: true
            },
            {
                activityType: 'MonitoringIssue',
                reportName: null,
                canEdit: true
            },
            {
                activityType: 'MonitoringMeeting',
                reportName: null,
                canEdit: true
            },
            {
                activityType: 'MonitoringContact',
                reportName: null,
                canEdit: true
            },
            {
                activityType: 'Representation',
                reportName: null,
                canEdit: true
            },
            {
                activityType: 'CovidVaccine',
                reportName: null,
                canEdit: true
            },
            {
                activityType: 'StatusChange',
                reportName: null,
                canEdit: false
            },
        ];
    })(activities = app.activities || (app.activities = {}));
})(app || (app = {}));
var app;
(function (app) {
    var admin;
    (function (admin) {
        var routes = [
            {
                name: 'app.admin',
                url: '/admin',
                template: '<ce-admin layout="column" flex></ce-admin>',
                redirectTo: 'app.admin.identityManagement',
                data: {
                    title: 'System Administration',
                    roles: ['Administrator']
                },
            }
        ];
        var runner = function (routingHelper) {
            routingHelper.configureRoutes(routes);
        };
        angular
            .module('app.admin')
            .run(runner);
    })(admin = app.admin || (app.admin = {}));
})(app || (app = {}));
var app;
(function (app) {
    var agencies;
    (function (agencies) {
        var baseUrl = 'api/agencies';
        var AgencyRepository = (function () {
            function AgencyRepository($http, exception) {
                this.$http = $http;
                this.exception = exception;
                'ngInject';
                'ngInject';
            }
            AgencyRepository.prototype.createAgency = function (data) {
                return this.$http.post(baseUrl, data);
            };
            AgencyRepository.prototype.createAgencyPerson = function (data) {
                return this.$http.post("".concat(baseUrl, "/").concat(data.agencyId, "/contact"), data);
            };
            AgencyRepository.prototype.getAgency = function (id) {
                return this.$http.get("".concat(baseUrl, "/").concat(id));
            };
            AgencyRepository.prototype.getAgencies = function (params) {
                return this.$http.get(baseUrl, { params: params });
            };
            AgencyRepository.prototype.getAgencyPersons = function (params) {
                return this.$http.get("".concat(baseUrl, "/").concat(params.agencyId, "/contacts"), { params: params });
            };
            AgencyRepository.prototype.getAgencyBasicInfo = function (id) {
                return this.$http.get("".concat(baseUrl, "/").concat(id, "/basicinfo"));
            };
            AgencyRepository.prototype.getImportantInformation = function (agencyId) {
                return this.$http.get("".concat(baseUrl, "/").concat(agencyId, "/importantinformation"));
            };
            AgencyRepository.prototype.getOtherInfo = function (agencyId) {
                return this.$http.get("".concat(baseUrl, "/").concat(agencyId, "/otherinfo"));
            };
            AgencyRepository.prototype.updateOtherInfo = function (otherInfo) {
                return this.$http.put("".concat(baseUrl, "/").concat(otherInfo.id, "/otherinfo"), otherInfo);
            };
            AgencyRepository.prototype.merge = function (winnerId, loserId) {
                return this.$http.post("".concat(baseUrl, "/merge"), { winnerId: winnerId, loserId: loserId });
            };
            __decorate([
                app.ApiExecutor()
            ], AgencyRepository.prototype, "createAgency", null);
            __decorate([
                app.ApiExecutor()
            ], AgencyRepository.prototype, "createAgencyPerson", null);
            __decorate([
                app.ApiExecutor()
            ], AgencyRepository.prototype, "getAgency", null);
            __decorate([
                app.ApiExecutor()
            ], AgencyRepository.prototype, "getAgencies", null);
            __decorate([
                app.ApiExecutor()
            ], AgencyRepository.prototype, "getAgencyPersons", null);
            __decorate([
                app.ApiExecutor()
            ], AgencyRepository.prototype, "getAgencyBasicInfo", null);
            __decorate([
                app.ApiExecutor()
            ], AgencyRepository.prototype, "getImportantInformation", null);
            __decorate([
                app.ApiExecutor()
            ], AgencyRepository.prototype, "getOtherInfo", null);
            __decorate([
                app.ApiExecutor()
            ], AgencyRepository.prototype, "updateOtherInfo", null);
            __decorate([
                app.ApiExecutor()
            ], AgencyRepository.prototype, "merge", null);
            return AgencyRepository;
        }());
        angular
            .module('app.agencies')
            .service('agencies.repository', AgencyRepository);
    })(agencies = app.agencies || (app.agencies = {}));
})(app || (app = {}));
var app;
(function (app) {
    var agencies;
    (function (agencies) {
        agencies.agencyResolver = function (datacontext, $stateParams) {
            'ngInject';
            var agencyId = $stateParams.agencyId;
            return datacontext.agencies.getAgencyBasicInfo(agencyId);
        };
    })(agencies = app.agencies || (app.agencies = {}));
})(app || (app = {}));
var app;
(function (app) {
    var agencies;
    (function (agencies) {
        var routes = [
            {
                name: 'app.agency',
                url: '/agencies/{agencyId}',
                template: '<ce-agency-view layout flex agency="$resolve.agency"></ce-agency-view>',
                redirectTo: 'app.agency.overview',
                resolve: {
                    agency: agencies.agencyResolver
                }
            },
            {
                name: 'app.agency.overview',
                url: '/overview',
                template: '<ce-agency-overview-view agency="$resolve.agency" layout="column" flex></ce-agency-overview-view>',
                data: { title: 'Agency Overview' }
            },
            {
                name: 'app.agency.cases',
                url: '/cases',
                template: '<ce-entity-case-listing entity-id="$resolve.agency.id" layout="column" flex></ce-entity-case-listing>',
                data: { title: 'Agency Involvements' }
            },
            {
                name: 'app.agency.activities',
                url: '/activities',
                template: '<ce-entity-activities entity-id="$resolve.agency.id" layout="column" flex></ce-entity-activities>',
                data: {
                    title: 'Agency Activities',
                    permissions: ['access_activities']
                }
            },
            {
                name: 'app.agency.details',
                url: '/details',
                template: '<ce-agency-details-view agency="$resolve.agency" layout="column" flex></ce-agency-details-view>',
                redirectTo: 'app.agency.details.contactInfo'
            },
            {
                name: 'app.agency.details.contactInfo',
                url: '/contact-info',
                template: '<ce-agency-contact-info agency="$resolve.agency" layout="column" flex></ce-agency-contact-info>',
                data: { title: 'Contact Info' }
            },
            {
                name: 'app.agency.details.otherInfo',
                url: '/other',
                template: '<ce-agency-other-info agency="$resolve.agency" layout="column" flex></ce-agency-other-info>',
                data: { title: 'Other Info' }
            },
            {
                name: 'app.agency.entities',
                url: '/entities',
                template: '<ui-view flex></ui-view>',
                redirectTo: 'app.agency.entities.listing',
                data: { title: 'Entities' }
            },
            {
                name: 'app.agency.entities.listing',
                url: '',
                template: '<ce-related-entities entity-id="$resolve.agency.id" layout="column" flex></ce-related-entities>',
                data: { title: 'Entities' }
            },
            {
                name: 'app.agency.entities.add',
                url: '/add',
                template: '<ce-select-entity-type flex></ce-select-entity-type>',
                redirectTo: 'app.agency.entities.add.type',
                data: { title: 'Add' }
            },
            {
                name: 'app.agency.entities.add.type',
                url: '/types',
                template: '<ce-entity-types flex></ce-entity-types>'
            },
            {
                name: 'app.agency.entities.add.person',
                url: '/person',
                template: '<ui-view layout flex></ui-view>',
                redirectTo: 'app.agency.entities.add.person.search',
            },
            {
                name: 'app.agency.entities.add.person.search',
                url: '/search',
                template: '<ce-person-search flex></ce-person-search>',
                data: { title: 'Find Person' }
            },
            {
                name: 'app.agency.entities.add.person.create',
                url: '/create',
                template: '<ce-create-person flex></ce-create-person>',
                data: { title: 'Create Person' }
            },
            {
                name: 'app.agency.entities.add.person.roles',
                url: '/roles',
                params: { entity: null },
                resolve: { entity: ['$stateParams', function (p) { return p.entity; }] },
                template: '<ce-entity-relationships agency="$resolve.agency" related-entity="$resolve.entity" layout="column" flex></ce-entity-relationships>',
                data: { title: 'Select Relationships' }
            },
            {
                name: 'app.agency.entities.add.agency',
                url: '/agency',
                template: '<ui-view layout flex></ui-view>',
                redirectTo: 'app.agency.entities.add.agency.search',
            },
            {
                name: 'app.agency.entities.add.agency.search',
                url: '/search',
                template: '<ce-agency-search flex></ce-agenyc-search>',
                data: { title: 'Find Agency' }
            },
            {
                name: 'app.agency.entities.add.agency.create',
                url: '/create',
                template: '<ce-create-agency flex></ce-create-agency>',
                data: { title: 'Create Agency' }
            },
            {
                name: 'app.agency.entities.add.agency.roles',
                url: '/roles',
                params: { entity: null },
                resolve: { entity: ['$stateParams', function (p) { return p.entity; }] },
                template: '<ce-entity-relationships agency="$resolve.agency" related-entity="$resolve.entity" layout="column" flex></ce-entity-relationships>',
                data: { title: 'Select Relationships' }
            },
            {
                name: 'app.agency.entities.edit',
                url: '/edit',
                params: { association: null },
                resolve: { association: ['$stateParams', function (p) { return p.association; }] },
                template: '<ce-edit-entity-relationships association="$resolve.association" layout="column" flex></ce-edit-entity-relationships>',
                data: { title: 'Edit' }
            },
        ];
        var runner = function (routingHelper) {
            'ngInject';
            routingHelper.configureRoutes(routes);
        };
        angular
            .module('app.agencies')
            .run(runner);
    })(agencies = app.agencies || (app.agencies = {}));
})(app || (app = {}));
var app;
(function (app) {
    var analysis;
    (function (analysis) {
        var analysisConstants = {
            ADD_CHART_SIDENAV_ID: 'add-chart-sidenav',
            ADD_CHART_SIDENAV_OPENED: 'CHART_SIDENAV_OPENED',
            chartTypes: [
                { name: 'Pie', groupByCount: 1 },
                { name: 'Bar', groupByCount: 1, percentages: true, totals: true },
                { name: 'Column', groupByCount: 1, percentages: true, totals: true },
                { name: 'StackedBar', groupByCount: 2 },
                { name: 'StackedColumn', groupByCount: 2 },
                { name: 'PivotTable', groupByCount: 2 },
                { name: 'Line', groupByCount: 1, trending: true }
            ],
            colorPalette: [
                '#2196F3',
                '#009688',
                '#EF6C00',
                '#9C27B0',
                '#F44336',
                '#3F51B5',
                '#4CAF50',
                '#009688',
                '#689F38',
                '#FF5722',
                '#795548',
                '#757575',
                '#78909C'
            ]
        };
        angular
            .module('app.analysis')
            .constant('analysisConstants', analysisConstants);
    })(analysis = app.analysis || (app.analysis = {}));
})(app || (app = {}));
var app;
(function (app) {
    var analysis;
    (function (analysis) {
        function dashboardResolver(datacontext) {
            'ngInject';
            return datacontext.analysis.getMyDashboard();
        }
        analysis.dashboardResolver = dashboardResolver;
        function dataSourcesResolver(datacontext) {
            'ngInject';
            return datacontext.analysis.getDataSources();
        }
        analysis.dataSourcesResolver = dataSourcesResolver;
        function chartsResolver(datacontext, $stateParams) {
            'ngInject';
            var queryId = $stateParams.queryId;
            return datacontext.analysis.getChartListByQueryId(queryId);
        }
        analysis.chartsResolver = chartsResolver;
        function queryResolver(datacontext, $stateParams) {
            'ngInject';
            var queryId = $stateParams.queryId;
            return datacontext.analysis.getQuery(queryId);
        }
        analysis.queryResolver = queryResolver;
    })(analysis = app.analysis || (app.analysis = {}));
})(app || (app = {}));
var app;
(function (app) {
    var analysis;
    (function (analysis) {
        var routes = [
            {
                name: 'app.analysis',
                url: '/analysis',
                template: '<ui-view layout flex></ui-view>',
                params: { route: null },
                redirectTo: 'app.analysis.routing',
            },
            {
                name: 'app.analysis.routing',
                url: '/routing',
                controller: 'AnalysisRouteController'
            },
            {
                name: 'app.analysis.dashboard',
                url: '/dashboard',
                template: '<aims-analysis-dashboard layout="column" flex dashboard="$resolve.dashboard"><aims-analysis-dashboard>',
                data: { title: 'Analysis', permissions: ['analysis.view'], },
                resolve: {
                    dashboard: analysis.dashboardResolver
                }
            },
            {
                name: 'app.analysis.queries',
                url: '/queries',
                template: '<aims-analysis-query-list layout flex></aims-analysis-query-list>',
                data: {
                    title: 'Queries (Analysis)',
                    permissions: ['analysis.view']
                }
            },
            {
                name: 'app.analysis.reports',
                url: '/reports',
                template: '<ce-report-listing layout=column flex></ce-report-listing>',
                data: {
                    title: 'Reports',
                    permissions: ['reports.view']
                },
            },
            {
                name: 'app.analysis.manageQuery',
                url: '/queries/manage',
                template: '<ce-analysis-manage-query layout flex data-data-sources="$resolve.dataSources" query-id="$resolve.queryId"></ce-analysis-manage-query>',
                data: {
                    title: 'Manage Query (Analysis)',
                    permissions: ['queries.edit']
                },
                params: { queryId: null },
                resolve: {
                    dataSources: analysis.dataSourcesResolver,
                    queryId: ['$stateParams', function (p) { return p.queryId; }]
                }
            },
            {
                name: 'app.analysis.query',
                url: '/queries/{queryId}',
                template: '<aims-analysis-query-details layout="column" flex query="$resolve.query"></aims-analysis-query-details>',
                redirectTo: 'app.analysis.query.data',
                resolve: {
                    query: analysis.queryResolver
                }
            },
            {
                name: 'app.analysis.query.data',
                url: '',
                template: '<aims-analysis-query-data layout="column" flex query="$resolve.query"></aims-analysis-query-data>',
                data: {
                    title: 'Query Data (Analysis)',
                    permissions: ['analysis.view']
                },
            },
            {
                name: 'app.analysis.query.charts',
                url: '/charts',
                template: '<aims-analysis-query-charts layout="column" flex query="$resolve.query" charts="$resolve.charts"></aims-analysis-query-charts>',
                data: {
                    title: 'Query Charts (Analysis)',
                    permissions: ['analysis.view']
                },
                resolve: {
                    charts: analysis.chartsResolver
                }
            },
            {
                name: 'app.analysis.createChart',
                url: '/queries/{queryId}/charts/create',
                template: '<aims-analysis-create-chart layout flex query="$resolve.query"></aims-analysis-create-chart>',
                data: {
                    title: 'Create Chart (Analysis)',
                    permissions: ['queries.edit']
                },
                resolve: {
                    query: analysis.queryResolver
                }
            }
        ];
        var runner = function (routingHelper) {
            'ngInject';
            routingHelper.configureRoutes(routes);
        };
        angular
            .module('app.analysis')
            .run(runner);
    })(analysis = app.analysis || (app.analysis = {}));
})(app || (app = {}));
var app;
(function (app) {
    var analysis;
    (function (analysis) {
        var AnalysisRouteController = (function () {
            function AnalysisRouteController($scope, common) {
                this.$scope = $scope;
                this.common = common;
                'ngInject';
                'ngInject';
            }
            AnalysisRouteController.prototype.$onInit = function () {
                var route = this.common.$stateParams['route'];
                switch (route) {
                    case 'reports':
                        this.common.$state.go('app.analysis.reports');
                        break;
                    case 'queries':
                        this.common.$state.go('app.analysis.queries');
                        break;
                    default:
                        this.common.$state.go('app.analysis.dashboard');
                        break;
                }
            };
            AnalysisRouteController.$inject = ['$scope', 'common'];
            AnalysisRouteController = __decorate([
                app.Controller('app.analysis', 'AnalysisRouteController')
            ], AnalysisRouteController);
            return AnalysisRouteController;
        }());
    })(analysis = app.analysis || (app.analysis = {}));
})(app || (app = {}));
var app;
(function (app) {
    var alerts;
    (function (alerts) {
        alerts.NOTIFICATIONS_SIDENAV_OPENED = 'NOTIFICATIONS_SIDENAV_OPENED';
    })(alerts = app.alerts || (app.alerts = {}));
})(app || (app = {}));
var app;
(function (app) {
    var alerts;
    (function (alerts) {
        alerts.notificationsResolver = function (datacontext) {
            'ngInject';
            return datacontext.alerts.getNotifications({
                unclearedOnly: true,
                pageSize: 25
            });
        };
    })(alerts = app.alerts || (app.alerts = {}));
})(app || (app = {}));
var app;
(function (app) {
    var alerts;
    (function (alerts) {
        var runner = function (routingHelper) {
            'ngInject';
            routingHelper.configureRoutes([
                {
                    name: 'app.notifications',
                    url: '/alerts',
                    template: '<ce-notifications-list layout="column" flex notifications="$resolve.notifications"></ce-notifications-list>',
                    resolve: {
                        notifications: alerts.notificationsResolver
                    },
                    data: { title: 'Notifications' }
                }
            ]);
        };
        angular
            .module('app.alerts')
            .run(runner);
    })(alerts = app.alerts || (app.alerts = {}));
})(app || (app = {}));
var app;
(function (app) {
    var calendars;
    (function (calendars) {
        calendars.EVENT_ADDED = 'calendarEventAdded';
    })(calendars = app.calendars || (app.calendars = {}));
})(app || (app = {}));
var app;
(function (app) {
    var calendars;
    (function (calendars) {
        calendars.CalendarId = {
            Case: 'case',
            FrontDesk: 'frontdesk',
            Intake: 'intake',
            Meeting: 'meeting',
            Personal: 'personal',
            Training: 'training'
        };
    })(calendars = app.calendars || (app.calendars = {}));
})(app || (app = {}));
var app;
(function (app) {
    var calendars;
    (function (calendars) {
        calendars.calendarsResolver = function (datacontext) {
            'ngInject';
            return datacontext.calendars.getCalendars();
        };
    })(calendars = app.calendars || (app.calendars = {}));
})(app || (app = {}));
var app;
(function (app) {
    var calendars;
    (function (calendars) {
        var routes = [
            {
                name: 'app.calendar',
                url: '/calendar',
                template: '<ui-view layout="column" flex></ui-view>',
                redirectTo: 'app.calendar.calendar'
            },
            {
                name: 'app.calendar.calendar',
                url: '',
                template: '<ce-calendar layout="column" flex></ce-calendar>',
                data: { title: 'Calendar' },
            },
            {
                name: 'app.calendar.eventSchedule',
                url: '/eventSchedule',
                template: '<ce-calendar-event-schedule event-schedule-id="$resolve.eventScheduleId" target-date="$resolve.targetDate"></ce-calendar-event-schedule>',
                params: {
                    eventScheduleId: null,
                    targetDate: null
                },
                resolve: {
                    eventScheduleId: ['$stateParams', function (p) { return p.eventScheduleId; }],
                    targetDate: ['$stateParams', function (p) { return p.targetDate; }],
                },
                data: { title: 'Event Schedule', permissions: ['events.view'], }
            },
            {
                name: 'app.calendar.event',
                url: '/event',
                template: '<ce-calendar-event event="$resolve.event"></ce-calendar-event>',
                params: {
                    event: null
                },
                resolve: {
                    event: ['$stateParams', function (e) { return e.event; }]
                },
                data: {
                    title: 'Event',
                    permissions: ['events.view']
                },
            }
        ];
        var runner = function (routingHelper) {
            'ngInject';
            routingHelper.configureRoutes(routes);
        };
        angular
            .module('app.calendars')
            .run(runner);
    })(calendars = app.calendars || (app.calendars = {}));
})(app || (app = {}));
var app;
(function (app) {
    var cases;
    (function (cases) {
        var CaseRoutingController = (function () {
            function CaseRoutingController($scope, $mdDialog, common, datacontext) {
                this.$scope = $scope;
                this.$mdDialog = $mdDialog;
                this.common = common;
                this.datacontext = datacontext;
                'ngInject';
                'ngInject';
                this.init();
            }
            CaseRoutingController.prototype.init = function () {
                var caseType = this.common.$stateParams['caseType'];
                var caseId = this.common.$stateParams['caseId'];
                switch (caseType) {
                    case 'Request':
                        this.common.$state.go('app.requests', { requestId: caseId });
                        break;
                    case 'Client Case':
                        this.common.$state.go('app.clientCases', { clientCaseId: caseId });
                        break;
                    case 'I&R':
                        this.common.$state.go('app.informationAndReferrals', { informationAndReferralId: caseId });
                        break;
                    case 'Systemic Case':
                        this.common.$state.go('app.systemicCases', { systemicCaseId: caseId });
                        break;
                    case 'Monitoring':
                        this.common.$state.go('app.monitoring', { monitoringId: caseId });
                        break;
                }
            };
            CaseRoutingController.$inject = ['$scope', '$mdDialog', 'common', 'datacontext'];
            CaseRoutingController = __decorate([
                app.Controller('app.cases', 'CaseRoutingController')
            ], CaseRoutingController);
            return CaseRoutingController;
        }());
    })(cases = app.cases || (app.cases = {}));
})(app || (app = {}));
var app;
(function (app) {
    var cases;
    (function (cases) {
        var routes = [
            {
                name: 'app.cases',
                url: '/cases?caseId&caseType',
                controller: 'CaseRoutingController'
            }
        ];
        var runner = function (routingHelper) {
            'ngInject';
            routingHelper.configureRoutes(routes);
        };
        angular
            .module('app.cases')
            .run(runner);
    })(cases = app.cases || (app.cases = {}));
})(app || (app = {}));
var app;
(function (app) {
    var clientCases;
    (function (clientCases) {
        'use strict';
        var baseUrl = 'api/clientCases';
        var ClientCaseRepository = (function () {
            function ClientCaseRepository($http, exception) {
                this.$http = $http;
                this.exception = exception;
            }
            ClientCaseRepository.prototype.getClientCaseListing = function (params) {
                return this.$http.get(baseUrl, { params: params });
            };
            ClientCaseRepository.prototype.getClientCaseListingDownload = function (params) {
                var _this = this;
                return this.$http.get("".concat(baseUrl, "/csv"), { params: params, responseType: 'arraybuffer' }).then(function (response) {
                    return {
                        buffer: response.data,
                        contentType: response.headers()['content-type'],
                        fileName: response.headers()['content-disposition'].split(';')[1].split('=')[1].replace(/"/g, '')
                    };
                }).catch(function (error) { return _this.exception.catcher('XHR for getCsvData failed')(error); });
            };
            ClientCaseRepository.prototype.getClientCaseNoTimeslipsListing = function (params) {
                return this.$http.get("".concat(baseUrl, "/notimeslips"), { params: params });
            };
            ClientCaseRepository.prototype.getClientCaseActivities = function (caseId) {
                return this.$http.get("".concat(baseUrl, "/").concat(caseId, "/activities"));
            };
            ClientCaseRepository.prototype.makeClientCase = function (source, sourceId, involvementId) {
                return this.$http.post("".concat(baseUrl, "/make"), { params: { source: source, sourceId: sourceId, involvementId: involvementId } });
            };
            ClientCaseRepository.prototype.createClientCase = function (clientCase) {
                return this.$http.post(baseUrl, clientCase);
            };
            ClientCaseRepository.prototype.close = function (activity) {
                return this.$http.post("".concat(baseUrl, "/activities/").concat(activity.caseId, "/close"), activity);
            };
            ClientCaseRepository.prototype.reopen = function (activity) {
                return this.$http.post("".concat(baseUrl, "/activities/").concat(activity.caseId, "/reopen"), activity);
            };
            ClientCaseRepository.prototype.followUp = function (activity) {
                return this.$http.post("".concat(baseUrl, "/").concat(activity.caseId, "/followUp"), activity);
            };
            ClientCaseRepository.prototype.createNote = function (activity) {
                return this.$http.post("".concat(baseUrl, "/activities/").concat(activity.caseId, "/notes"), activity);
            };
            ClientCaseRepository.prototype.updateNote = function (activity) {
                return this.$http.put("".concat(baseUrl, "/activities/").concat(activity.caseId, "/notes/").concat(activity.id), activity);
            };
            ClientCaseRepository.prototype.getClientCaseStatus = function (id) {
                return this.$http.get("".concat(baseUrl, "/").concat(id, "/status"));
            };
            ClientCaseRepository.prototype.getClientCaseBasicInfoView = function (id) {
                return this.$http.get("".concat(baseUrl, "/").concat(id, "/basicinfo"));
            };
            ClientCaseRepository.prototype.getClientCaseSummaryView = function (id) {
                return this.$http.get("".concat(baseUrl, "/").concat(id, "/summary"));
            };
            ClientCaseRepository.prototype.getClientCaseToDo = function (id, stage) {
                return this.$http.get("".concat(baseUrl, "/").concat(id, "/todo/").concat(stage || ''));
            };
            ClientCaseRepository.prototype.getClientCaseInformation = function (id) {
                return this.$http.get("".concat(baseUrl, "/").concat(id, "/information"));
            };
            ClientCaseRepository.prototype.updateClientCaseInformation = function (info) {
                return this.$http.post("".concat(baseUrl, "/").concat(info.id, "/information"), info);
            };
            ClientCaseRepository.prototype.getClosureInformation = function (id) {
                return this.$http.get("".concat(baseUrl, "/").concat(id, "/closure"));
            };
            ClientCaseRepository.prototype.updateClosureInformation = function (closure) {
                return this.$http.post("".concat(baseUrl, "/").concat(closure.id, "/closure"), closure);
            };
            ClientCaseRepository.prototype.getSurveyInformation = function (id) {
                return this.$http.get("".concat(baseUrl, "/").concat(id, "/survey"));
            };
            ClientCaseRepository.prototype.updateSpecialEducation = function (closureSpecialEducation) {
                return this.$http.post("".concat(baseUrl, "/").concat(closureSpecialEducation.id, "/closure/specialeducation"), closureSpecialEducation);
            };
            ClientCaseRepository.prototype.getSpecialEducation = function (id) {
                return this.$http.get("".concat(baseUrl, "/").concat(id, "/closure/specialeducation"));
            };
            ClientCaseRepository.prototype.getClosureSpecialEducationOptions = function () {
                return this.$http.get("".concat(baseUrl, "/specialeducation/options"));
            };
            ClientCaseRepository.prototype.createCaseReview = function (review) {
                return this.$http.post("".concat(baseUrl, "/activities/").concat(review.caseId, "/caseReview"), review);
            };
            ClientCaseRepository.prototype.updateCaseReview = function (review) {
                return this.$http.put("".concat(baseUrl, "/activities/").concat(review.caseId, "/caseReview/").concat(review.id), review);
            };
            ClientCaseRepository.prototype.updateSurveyInformation = function (survey) {
                return this.$http.post("".concat(baseUrl, "/").concat(survey.id, "/survey"), survey);
            };
            ClientCaseRepository.prototype.getActiveCaseCount = function (userIds) {
                return this.$http.get("".concat(baseUrl, "/activeCaseCount"), { params: { userIds: userIds } });
            };
            ClientCaseRepository.prototype.getCasesWithNoTimeslips = function (userIds) {
                return this.$http.get("".concat(baseUrl, "/casesWithNoTimeslipsCounts"), { params: { userIds: userIds } });
            };
            ClientCaseRepository.prototype.deleteClientCase = function (id) {
                var _this = this;
                return this.$http.delete("".concat(baseUrl, "/").concat(id)).catch(function (error) { return _this.exception.catcher('Cannot Delete Case')(error); });
            };
            ClientCaseRepository.$inject = ['$http', 'exception'];
            __decorate([
                app.ApiExecutor()
            ], ClientCaseRepository.prototype, "getClientCaseListing", null);
            __decorate([
                app.ApiExecutor()
            ], ClientCaseRepository.prototype, "getClientCaseNoTimeslipsListing", null);
            __decorate([
                app.ApiExecutor()
            ], ClientCaseRepository.prototype, "getClientCaseActivities", null);
            __decorate([
                app.ApiExecutor()
            ], ClientCaseRepository.prototype, "makeClientCase", null);
            __decorate([
                app.ApiExecutor()
            ], ClientCaseRepository.prototype, "createClientCase", null);
            __decorate([
                app.ApiExecutor()
            ], ClientCaseRepository.prototype, "close", null);
            __decorate([
                app.ApiExecutor()
            ], ClientCaseRepository.prototype, "reopen", null);
            __decorate([
                app.ApiExecutor()
            ], ClientCaseRepository.prototype, "followUp", null);
            __decorate([
                app.ApiExecutor()
            ], ClientCaseRepository.prototype, "createNote", null);
            __decorate([
                app.ApiExecutor()
            ], ClientCaseRepository.prototype, "updateNote", null);
            __decorate([
                app.ApiExecutor()
            ], ClientCaseRepository.prototype, "getClientCaseStatus", null);
            __decorate([
                app.ApiExecutor()
            ], ClientCaseRepository.prototype, "getClientCaseBasicInfoView", null);
            __decorate([
                app.ApiExecutor()
            ], ClientCaseRepository.prototype, "getClientCaseSummaryView", null);
            __decorate([
                app.ApiExecutor()
            ], ClientCaseRepository.prototype, "getClientCaseToDo", null);
            __decorate([
                app.ApiExecutor()
            ], ClientCaseRepository.prototype, "getClientCaseInformation", null);
            __decorate([
                app.ApiExecutor()
            ], ClientCaseRepository.prototype, "updateClientCaseInformation", null);
            __decorate([
                app.ApiExecutor()
            ], ClientCaseRepository.prototype, "getClosureInformation", null);
            __decorate([
                app.ApiExecutor()
            ], ClientCaseRepository.prototype, "updateClosureInformation", null);
            __decorate([
                app.ApiExecutor()
            ], ClientCaseRepository.prototype, "getSurveyInformation", null);
            __decorate([
                app.ApiExecutor()
            ], ClientCaseRepository.prototype, "updateSpecialEducation", null);
            __decorate([
                app.ApiExecutor()
            ], ClientCaseRepository.prototype, "getSpecialEducation", null);
            __decorate([
                app.ApiExecutor()
            ], ClientCaseRepository.prototype, "getClosureSpecialEducationOptions", null);
            __decorate([
                app.ApiExecutor()
            ], ClientCaseRepository.prototype, "createCaseReview", null);
            __decorate([
                app.ApiExecutor()
            ], ClientCaseRepository.prototype, "updateCaseReview", null);
            __decorate([
                app.ApiExecutor()
            ], ClientCaseRepository.prototype, "updateSurveyInformation", null);
            __decorate([
                app.ApiExecutor()
            ], ClientCaseRepository.prototype, "getActiveCaseCount", null);
            __decorate([
                app.ApiExecutor()
            ], ClientCaseRepository.prototype, "getCasesWithNoTimeslips", null);
            return ClientCaseRepository;
        }());
        angular
            .module('app.clientCases')
            .service('clientCases.repository', ClientCaseRepository);
    })(clientCases = app.clientCases || (app.clientCases = {}));
})(app || (app = {}));
var app;
(function (app) {
    var clientCases;
    (function (clientCases) {
        'use strict';
        clientCases.caseBasicInfoResolver = function (datacontext, $stateParams) {
            'ngInject';
            var clientCaseId = $stateParams.clientCaseId;
            return datacontext.clientCases.getClientCaseBasicInfoView(clientCaseId);
        };
        clientCases.legalTeamsResolver = function (datacontext) {
            'ngInject';
            return datacontext.config.legalTeams.getLegalTeams(false);
        };
        clientCases.showClientCaseNameResolver = function (datacontext) {
            'ngInject';
            var bypassReasonsPromise = datacontext.config.getConfigurationParameter('ClientCase.ShowCaseName', false);
            return bypassReasonsPromise.then(function (result) {
                return result.toString() === 'true';
            });
        };
        clientCases.showPovertyLevelResolver = function (datacontext) {
            'ngInject';
            var bypassReasonsPromise = datacontext.config.getConfigurationParameter('Entities.PovertyLevel', false);
            return bypassReasonsPromise.then(function (result) {
                return result.toString() === 'true';
            });
        };
        clientCases.showAtPovertyLevelResolver = function (datacontext) {
            'ngInject';
            var bypassReasonsPromise = datacontext.config.getConfigurationParameter('Entities.AtPovertyLevel', false);
            return bypassReasonsPromise.then(function (result) {
                return result.toString() === 'true';
            });
        };
        clientCases.showRaceSubCategoryResolver = function (datacontext) {
            'ngInject';
            var bypassReasonsPromise = datacontext.config.getConfigurationParameter('Entities.RaceSubCategory', false);
            return bypassReasonsPromise.then(function (result) {
                return result.toString() === 'true';
            });
        };
    })(clientCases = app.clientCases || (app.clientCases = {}));
})(app || (app = {}));
var app;
(function (app) {
    var clientCases;
    (function (clientCases) {
        'use strict';
        var routes = [
            {
                name: 'app.clientCaseNoTimeslips',
                url: '/notimeslips',
                template: '<ce-client-case-no-timeslips-listing layout="column" flex bucket="$resolve.bucket" user-ids="$resolve.userIds" ></ce-client-case-no-timeslips-listing>',
                params: {
                    bucket: null,
                    userIds: null
                },
                resolve: {
                    bucket: ['$stateParams', function (p) { return p.bucket; }],
                    userIds: ['$stateParams', function (p) { return p.userIds; }]
                },
                data: {
                    title: 'No Timeslips',
                    permissions: ['client-cases.view']
                }
            },
            {
                name: 'app.createClientCase',
                url: '/client-cases/create',
                template: '<ce-create-client-case layout="column" flex legalTeams="$resolve.legalTeams" ></ce-create-client-case>',
                data: {
                    title: 'Create Client Case',
                    permissions: ['client-cases.edit']
                }
            },
            {
                name: 'app.clientCaseListing',
                url: '/client-cases',
                template: '<ce-client-case-listing layout="column" flex users="$resolve.users"></ce-client-case-listing > ',
                data: {
                    title: 'Client Case Listing',
                    permissions: ['client-cases.view']
                },
                params: { users: null },
                resolve: {
                    users: ['$stateParams', function (p) { return p.users; }]
                }
            },
            {
                name: 'app.clientCases',
                url: '/client-cases/{clientCaseId}',
                template: '<ce-client-case-view case="$resolve.case" layout="column" flex></ce-client-case-view>',
                redirectTo: 'app.clientCases.overview',
                data: {
                    title: 'Client Case View',
                    permissions: ['client-cases.view']
                },
                resolve: { case: clientCases.caseBasicInfoResolver }
            },
            {
                name: 'app.clientCases.overview',
                url: '/overview',
                template: '<ce-client-case-overview case="$resolve.case" layout="column" flex></ce-client-case-overview>',
                data: {
                    title: 'Client Case Overview',
                    permissions: ['client-cases.view']
                }
            },
            {
                name: 'app.clientCases.details',
                url: '/details',
                template: "<ce-client-case-details layout=\"column\" flex\n                   case=\"$resolve.case\"\n                   param-case-name=\"$resolve.paramCaseName\"\n                   param-poverty-level=\"$resolve.paramPovertyLevel\"\n                   param-at-poverty=\"$resolve.paramAtPoverty\"\n                   param-race-Sub-Category=\"$resolve.paramRaceSubCategory\"></ce-client-case-details>",
                data: {
                    title: 'Client Case Details',
                    permissions: ['client-cases.view']
                },
                resolve: {
                    paramCaseName: clientCases.showClientCaseNameResolver,
                    paramPovertyLevel: clientCases.showPovertyLevelResolver,
                    paramAtPoverty: clientCases.showAtPovertyLevelResolver,
                    paramRaceSubCategory: clientCases.showRaceSubCategoryResolver
                }
            },
            {
                name: 'app.clientCases.involvement',
                url: '/involvement',
                template: '<ce-involvement-case-listing involvement-id="$resolve.case.involvementId" flex></ce-involvement-case-listing>',
                data: { title: 'Client Case Involvement' }
            },
            {
                name: 'app.clientCases.timeslipListing',
                url: '/timeslips',
                template: '<ce-timeslip-listing source="Client Case" source-name="$resolve.case.idNumber" source-name2="$resolve.case.clientFullName" source-id="$resolve.case.id" involvement-id="$resolve.case.involvementId" enable-filters="false" flex layout="column"></ce-timeslip-listing>',
                data: {
                    title: 'Client Case Timeslips',
                    permissions: ['timeslips.view']
                }
            },
            {
                name: 'app.clientCases.timeslipCalendar',
                url: '/timeslips-calendar',
                template: '<ce-timeslip-calendar-frame source="Client Case" source-name="$resolve.case.idNumber" source-name2="$resolve.case.clientFullName" source-id="$resolve.case.id" involvement-id="$resolve.case.involvementId" enable-filters="false" flex layout="column"></ce-timeslip-calendar-frame>',
                data: {
                    title: 'Client Case Timeslips',
                    permissions: ['timeslips.view']
                }
            },
            {
                name: 'app.clientCases.documents',
                url: '/documents',
                template: '<ce-documents-list involvement-id="$resolve.case.involvementId" source="Client Case" source-id="$resolve.case.id" layout="column" flex></ce-document-list>',
                data: {
                    title: 'Client Case Documents',
                    permissions: ['documents.view']
                }
            },
            {
                name: 'app.clientCases.uploadDocuments',
                url: '/upload',
                params: {
                    folderId: null
                },
                resolve: {
                    folderId: ['$stateParams', function (p) { return p.folderId; }],
                },
                template: '<ce-upload-documents-activity layout flex folder-id="$resolve.folderId" involvement-id="$resolve.case.involvementId" source="Client Case" source-id="$resolve.case.id"></ce-upload-documents-activity>',
                data: { permissions: ['documents.edit'] }
            },
            {
                name: 'app.clientCases.emails',
                url: '/emails',
                template: '<ui-view flex layout="column"></ui-view>',
                redirectTo: 'app.clientCases.emails.listing',
                data: {
                    title: 'Client Case Emails',
                    permissions: ['mail.view']
                }
            },
            {
                name: 'app.clientCases.emails.listing',
                url: '',
                template: '<ce-email-listing involvement-id="$resolve.case.involvementId" source="Client Case" source-id="$resolve.case.id" layout="column" flex></ce-email-listing>',
                data: {
                    title: 'Client Case Emails',
                    permissions: ['mail.view']
                }
            },
            {
                name: 'app.clientCases.emails.message',
                url: '/{messageId}',
                template: '<ce-email-view layout="column" flex message-id="$resolve.messageId"></ce-email-view>',
                data: {
                    title: 'Message',
                    permissions: ['mail.view']
                },
                resolve: {
                    messageId: ['$stateParams', function (p) { return p.messageId; }]
                }
            },
            {
                name: 'app.clientCases.emails.link',
                url: '/link',
                template: '<ce-link-emails layout="column" flex involvement-id="$resolve.case.involvementId" source="Client Case" source-id="$resolve.case.id"></ce-link-emails>',
                data: {
                    title: 'Inbox Messages',
                    permissions: ['mail.link']
                }
            },
            {
                name: 'app.clientCases.closure',
                url: '/closure',
                template: '<ce-client-case-closure case="$resolve.case" layout="column" flex></ce-client-case-closure>',
                data: {
                    title: 'Client Case Closure',
                    permissions: ['client-cases.view']
                }
            },
            {
                name: 'app.clientCases.grievances',
                url: '/grievances',
                template: '<ce-grievance-listing case="$resolve.case" case-type="Client Case" flex></ce-grievance-listing>',
                data: {
                    title: 'Client Case Grievances',
                    permissions: ['grievances.view']
                }
            },
            {
                name: 'app.clientCases.calendar',
                url: '/calendar',
                template: '<ce-involvements-calendar source="Client Case" source-id="$resolve.case.id" involvement-id="$resolve.case.involvementId" layout="column" flex></ce-involvements-calendar>',
                data: {
                    title: 'Calendar',
                }
            },
            {
                name: 'app.clientCases.eventSchedule',
                url: '/eventSchedule',
                template: "<ce-calendar-event-schedule\n                     event-schedule-id=\"$resolve.eventScheduleId\"\n                     target-date=\"$resolve.targetDate\"\n                     source=\"Client Case\"\n                     source-name=\"$resolve.case.idNumber\"\n                     source-id=\"$resolve.case.id\"\n                     involvement-id=\"$resolve.case.involvementId\"></ce-calendar-event-schedule>",
                params: {
                    eventScheduleId: null,
                    targetDate: null
                },
                resolve: {
                    eventScheduleId: ['$stateParams', function (p) { return p.eventScheduleId; }],
                    targetDate: ['$stateParams', function (p) { return p.targetDate; }],
                },
                data: { title: 'Event Schedule', permissions: ['events.view'], }
            },
            {
                name: 'app.clientCases.event',
                url: '/event',
                template: '<ce-calendar-event event="$resolve.event"></ce-calendar-event>',
                params: {
                    event: null
                },
                resolve: {
                    event: ['$stateParams', function (e) { return e.event; }]
                },
                data: {
                    title: 'Event',
                    permissions: ['events.view']
                }
            }
        ];
        var runner = function (routingHelper) {
            'ngInject';
            routingHelper.configureRoutes(routes);
        };
        angular
            .module('app.clientCases')
            .run(runner);
    })(clientCases = app.clientCases || (app.clientCases = {}));
})(app || (app = {}));
var app;
(function (app) {
    var data;
    (function (data) {
        var interceptorName = 'apiBaseUrlInterceptor';
        var interceptor = function (configurationProvider) {
            'ngInject';
            return {
                'request': function (request) {
                    if (request.url.match(/^api\//i)) {
                        request.url = configurationProvider.config.apiRootUrl + '/' + request.url.replace(/^api\//i, '');
                    }
                    return request;
                }
            };
        };
        var configure = function ($httpProvider) {
            'ngInject';
            $httpProvider.interceptors.push(interceptorName);
        };
        angular
            .module('app.data')
            .factory(interceptorName, interceptor)
            .config(configure);
    })(data = app.data || (app.data = {}));
})(app || (app = {}));
var app;
(function (app) {
    var data;
    (function (data) {
        'use strict';
        var DataContextService = (function () {
            function DataContextService($injector) {
                var _this = this;
                this.$injector = $injector;
                this.repoNames = [
                    'activities',
                    'admin',
                    'alerts',
                    'analysis',
                    'calendars',
                    'config',
                    'documentAssembly',
                    'documentManagement',
                    'identity',
                    'involvements',
                    'persons',
                    'preferences',
                    'reports',
                    'mail',
                    'persons',
                    'requests',
                    'users',
                    'clientCases',
                    'systemicCases',
                    'informationAndReferrals',
                    'time',
                    'fiscal',
                    'projects',
                    'training',
                    'funding',
                    'agencies',
                    'entities',
                    'grievance',
                    'monitoring'
                ];
                this.defineLazyLoadedRepos = function () {
                    var self = _this;
                    angular.forEach(_this.repoNames, function (name) {
                        Object.defineProperty(self, name, {
                            configurable: true,
                            get: function () {
                                var repo = self.getRepo(name);
                                Object.defineProperty(self, name, {
                                    value: repo,
                                    configurable: false,
                                    enumerable: true
                                });
                                return repo;
                            }
                        });
                    });
                };
                this.getRepo = function (repoName) {
                    var fullRepoName = "".concat(repoName, ".repository");
                    var factory = _this.$injector.get(fullRepoName);
                    return factory;
                };
                'ngInject';
                'ngInject';
                this.defineLazyLoadedRepos();
            }
            return DataContextService;
        }());
        angular
            .module('app.data')
            .service('datacontext', DataContextService);
    })(data = app.data || (app.data = {}));
})(app || (app = {}));
var app;
(function (app) {
    var data;
    (function (data) {
        var interceptorName = 'fingerprintInterceptor';
        var interceptor = function ($q, configurationProvider) {
            'ngInject';
            return {
                'request': function (request) {
                    if (request.url.match(/\app\/.*\.html$/i)) {
                        request.url += '?v=' + configurationProvider.config.version;
                    }
                    return request;
                }
            };
        };
        var configure = function ($httpProvider) {
            'ngInject';
            $httpProvider.interceptors.push(interceptorName);
        };
        angular
            .module('app.data')
            .factory(interceptorName, interceptor)
            .config(configure);
    })(data = app.data || (app.data = {}));
})(app || (app = {}));
var app;
(function (app) {
    var data;
    (function (data) {
        var interceptorName = 'jsonDateInterceptor';
        var interceptor = function ($q) {
            var dateRegExMs = /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2})Z$/;
            var dateRegEx = /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2})$/;
            var dateRegExShort = /^(\d{4})-(\d{2})-(\d{2})$/;
            var tryMatchDate = function (dateString) {
                if (dateString.length === 19) {
                    return dateString.match(dateRegEx);
                }
                if (dateString.length === 10) {
                    return dateString.match(dateRegExShort);
                }
                if (dateString.length === 20) {
                    return dateString.match(dateRegExMs);
                }
                return false;
            };
            var convertDateStringsToDates = function (input) {
                if (typeof input !== 'object') {
                    return input;
                }
                for (var key in input) {
                    if (!input.hasOwnProperty(key)) {
                        continue;
                    }
                    var value = input[key];
                    var match = void 0;
                    if (typeof value === 'string' && (match = tryMatchDate(value))) {
                        var date = void 0;
                        if (value.length === 10) {
                            date = moment(value, 'YYYY-MM-DD').toDate();
                        }
                        else if (value.length === 20) {
                            date = moment(value, 'YYYY-MM-DDTHH:mm:ssZ').toDate();
                        }
                        else {
                            date = moment(value).toDate();
                        }
                        input[key] = date;
                    }
                    else if (typeof value === 'object') {
                        convertDateStringsToDates(value);
                    }
                }
                return null;
            };
            return {
                'response': function (response) {
                    convertDateStringsToDates(response);
                    return response;
                }
            };
        };
        interceptor.$inject = ['$q'];
        var configure = function ($httpProvider) {
            $httpProvider.interceptors.push(interceptorName);
        };
        configure.$inject = ['$httpProvider'];
        angular
            .module('app.data')
            .factory(interceptorName, interceptor)
            .config(configure);
    })(data = app.data || (app.data = {}));
})(app || (app = {}));
var app;
(function (app) {
    var core;
    (function (core) {
        var config = function ($mdThemingProvider) {
            'ngInject';
            $mdThemingProvider.theme('default')
                .primaryPalette('blue')
                .accentPalette('blue');
        };
        angular
            .module('app.core')
            .config(config);
    })(core = app.core || (app.core = {}));
})(app || (app = {}));
var app;
(function (app) {
    var core;
    (function (core) {
        function runner($rootScope) {
            'ngInject';
            var ua = window.navigator.userAgent;
            var ieVersion;
            var msie = ua.indexOf('MSIE ');
            if (msie > 0) {
                ieVersion = parseInt(ua.substring(msie + 5, ua.indexOf('.', msie)), 10);
            }
            var trident = ua.indexOf('Trident/');
            if (trident > 0) {
                var rv = ua.indexOf('rv:');
                ieVersion = parseInt(ua.substring(rv + 3, ua.indexOf('.', rv)), 10);
            }
            $rootScope.ieVersion = ieVersion;
        }
        angular.module('app.core').run(runner);
    })(core = app.core || (app.core = {}));
})(app || (app = {}));
var app;
(function (app) {
    var core;
    (function (core) {
        var interceptorName = 'loadingInterceptor';
        var interceptor = function ($q, $rootScope) {
            'ngInject';
            var loadingCount = 0;
            return {
                request: function (config) {
                    if (!config.workingDisabled) {
                        loadingCount++;
                        $rootScope.isWorking = true;
                    }
                    return config || $q.when(config);
                },
                response: function (response) {
                    if (loadingCount > 0)
                        loadingCount--;
                    if (!loadingCount) {
                        $rootScope.isWorking = false;
                    }
                    return response || $q.when(response);
                },
                responseError: function (response) {
                    if (loadingCount > 0)
                        loadingCount--;
                    if (!loadingCount) {
                        $rootScope.isWorking = false;
                    }
                    return $q.reject(response);
                }
            };
        };
        var config = function ($httpProvider) {
            'ngInject';
            $httpProvider.interceptors.push(interceptorName);
        };
        angular
            .module('app.core')
            .factory(interceptorName, interceptor)
            .config(config);
    })(core = app.core || (app.core = {}));
})(app || (app = {}));
var app;
(function (app) {
    var entities;
    (function (entities) {
        'use strict';
        var baseUrl = 'api/entities';
        var EntityRepository = (function () {
            function EntityRepository($http, exception) {
                this.$http = $http;
                this.exception = exception;
            }
            EntityRepository.prototype.getCaseListing = function (id, paging) {
                return this.$http.get("".concat(baseUrl, "/").concat(id, "/cases"), { params: paging });
            };
            EntityRepository.prototype.getEntityBasicInfo = function (id) {
                return this.$http.get("".concat(baseUrl, "/").concat(id));
            };
            EntityRepository.prototype.getEntityNames = function (id) {
                return this.$http.get("".concat(baseUrl, "/").concat(id, "/names"));
            };
            EntityRepository.prototype.createEntityName = function (entityName) {
                return this.$http.post("".concat(baseUrl, "/").concat(entityName.entityId, "/names"), entityName);
            };
            EntityRepository.prototype.updateEntityName = function (entityName) {
                return this.$http.put("".concat(baseUrl, "/").concat(entityName.entityId, "/names/").concat(entityName.id), entityName);
            };
            EntityRepository.prototype.deleteEntityName = function (entityName) {
                return this.$http.delete("".concat(baseUrl, "/").concat(entityName.entityId, "/names/").concat(entityName.id));
            };
            EntityRepository.prototype.getEntityAddresses = function (id) {
                return this.$http.get("".concat(baseUrl, "/").concat(id, "/addresses"));
            };
            EntityRepository.prototype.createEntityAddress = function (entityAddress) {
                return this.$http.post("".concat(baseUrl, "/").concat(entityAddress.entityId, "/addresses"), entityAddress);
            };
            EntityRepository.prototype.updateEntityAddress = function (entityAddress) {
                return this.$http.put("".concat(baseUrl, "/").concat(entityAddress.entityId, "/addresses/").concat(entityAddress.id), entityAddress);
            };
            EntityRepository.prototype.deleteEntityAddress = function (entityAddress) {
                return this.$http.delete("".concat(baseUrl, "/").concat(entityAddress.entityId, "/addresses/").concat(entityAddress.id));
            };
            EntityRepository.prototype.getEntityPhoneNumbers = function (id) {
                return this.$http.get("".concat(baseUrl, "/").concat(id, "/phoneNumbers"));
            };
            EntityRepository.prototype.createEntityPhoneNumber = function (entityPhoneNumber) {
                return this.$http.post("".concat(baseUrl, "/").concat(entityPhoneNumber.entityId, "/phoneNumbers"), entityPhoneNumber);
            };
            EntityRepository.prototype.updateEntityPhoneNumber = function (entityPhoneNumber) {
                return this.$http.put("".concat(baseUrl, "/").concat(entityPhoneNumber.entityId, "/phoneNumbers/").concat(entityPhoneNumber.id), entityPhoneNumber);
            };
            EntityRepository.prototype.deleteEntityPhoneNumber = function (entityPhoneNumber) {
                return this.$http.delete("".concat(baseUrl, "/").concat(entityPhoneNumber.entityId, "/phoneNumbers/").concat(entityPhoneNumber.id));
            };
            EntityRepository.prototype.getEntityEmailAddresses = function (id) {
                return this.$http.get("".concat(baseUrl, "/").concat(id, "/emailAddresses"));
            };
            EntityRepository.prototype.createEntityEmailAddress = function (entityEmailAddress) {
                return this.$http.post("".concat(baseUrl, "/").concat(entityEmailAddress.entityId, "/emailAddresses"), entityEmailAddress);
            };
            EntityRepository.prototype.updateEntityEmailAddress = function (entityEmailAddress) {
                return this.$http.put("".concat(baseUrl, "/").concat(entityEmailAddress.entityId, "/emailAddresses/").concat(entityEmailAddress.id), entityEmailAddress);
            };
            EntityRepository.prototype.deleteEntityEmailAddress = function (entityEmailAddress) {
                return this.$http.delete("".concat(baseUrl, "/").concat(entityEmailAddress.entityId, "/emailAddresses/").concat(entityEmailAddress.id));
            };
            EntityRepository.prototype.getContactInfo = function (id) {
                return this.$http.get("".concat(baseUrl, "/").concat(id, "/contactInfo"));
            };
            EntityRepository.prototype.searchPhoneNumbers = function (params) {
                return this.$http.get("".concat(baseUrl, "/searchphonenumbers"), { params: params });
            };
            EntityRepository.prototype.searchEmailAddresses = function (params) {
                return this.$http.get("".concat(baseUrl, "/searchemailaddresses"), { params: params });
            };
            EntityRepository.prototype.getRelatedEntities = function (id) {
                return this.$http.get("".concat(baseUrl, "/").concat(id, "/entities"));
            };
            EntityRepository.prototype.getAssociation = function (entityId, relatedEntityId) {
                return this.$http.get("".concat(baseUrl, "/").concat(entityId, "/entities/").concat(relatedEntityId));
            };
            EntityRepository.prototype.createAssociation = function (data) {
                return this.$http.post("".concat(baseUrl, "/").concat(data.entityId, "/entities"), data);
            };
            EntityRepository.prototype.updateAssociation = function (data) {
                return this.$http.put("".concat(baseUrl, "/").concat(data.entityId, "/entities/").concat(data.relatedEntityId), data);
            };
            EntityRepository.prototype.deleteAssociation = function (entityId, relatedEntityId) {
                return this.$http.delete("".concat(baseUrl, "/").concat(entityId, "/entities/").concat(relatedEntityId));
            };
            EntityRepository.$inject = ['$http', 'exception'];
            __decorate([
                app.ApiExecutor()
            ], EntityRepository.prototype, "getCaseListing", null);
            __decorate([
                app.ApiExecutor()
            ], EntityRepository.prototype, "getEntityBasicInfo", null);
            __decorate([
                app.ApiExecutor()
            ], EntityRepository.prototype, "getEntityNames", null);
            __decorate([
                app.ApiExecutor()
            ], EntityRepository.prototype, "createEntityName", null);
            __decorate([
                app.ApiExecutor()
            ], EntityRepository.prototype, "updateEntityName", null);
            __decorate([
                app.ApiExecutor()
            ], EntityRepository.prototype, "deleteEntityName", null);
            __decorate([
                app.ApiExecutor()
            ], EntityRepository.prototype, "getEntityAddresses", null);
            __decorate([
                app.ApiExecutor()
            ], EntityRepository.prototype, "createEntityAddress", null);
            __decorate([
                app.ApiExecutor()
            ], EntityRepository.prototype, "updateEntityAddress", null);
            __decorate([
                app.ApiExecutor()
            ], EntityRepository.prototype, "deleteEntityAddress", null);
            __decorate([
                app.ApiExecutor()
            ], EntityRepository.prototype, "getEntityPhoneNumbers", null);
            __decorate([
                app.ApiExecutor()
            ], EntityRepository.prototype, "createEntityPhoneNumber", null);
            __decorate([
                app.ApiExecutor()
            ], EntityRepository.prototype, "updateEntityPhoneNumber", null);
            __decorate([
                app.ApiExecutor()
            ], EntityRepository.prototype, "deleteEntityPhoneNumber", null);
            __decorate([
                app.ApiExecutor()
            ], EntityRepository.prototype, "getEntityEmailAddresses", null);
            __decorate([
                app.ApiExecutor()
            ], EntityRepository.prototype, "createEntityEmailAddress", null);
            __decorate([
                app.ApiExecutor()
            ], EntityRepository.prototype, "updateEntityEmailAddress", null);
            __decorate([
                app.ApiExecutor()
            ], EntityRepository.prototype, "deleteEntityEmailAddress", null);
            __decorate([
                app.ApiExecutor()
            ], EntityRepository.prototype, "getContactInfo", null);
            __decorate([
                app.ApiExecutor()
            ], EntityRepository.prototype, "searchPhoneNumbers", null);
            __decorate([
                app.ApiExecutor()
            ], EntityRepository.prototype, "searchEmailAddresses", null);
            __decorate([
                app.ApiExecutor()
            ], EntityRepository.prototype, "getRelatedEntities", null);
            __decorate([
                app.ApiExecutor()
            ], EntityRepository.prototype, "getAssociation", null);
            __decorate([
                app.ApiExecutor()
            ], EntityRepository.prototype, "createAssociation", null);
            __decorate([
                app.ApiExecutor()
            ], EntityRepository.prototype, "updateAssociation", null);
            __decorate([
                app.ApiExecutor()
            ], EntityRepository.prototype, "deleteAssociation", null);
            return EntityRepository;
        }());
        angular
            .module('app.entities')
            .service('entities.repository', EntityRepository);
    })(entities = app.entities || (app.entities = {}));
})(app || (app = {}));
var app;
(function (app) {
    var entities;
    (function (entities) {
        'use strict';
        entities.entityBasicInfoResolver = function (datacontext, $stateParams) {
            'ngInject';
            var entityId = $stateParams.entityId;
            return datacontext.entities.getEntityBasicInfo(entityId);
        };
    })(entities = app.entities || (app.entities = {}));
})(app || (app = {}));
var app;
(function (app) {
    var entities;
    (function (entities) {
        var routes = [
            {
                name: 'app.entities',
                url: '/entities?entityId&entityType',
                controller: 'EntityRoutingController'
            },
            {
                name: 'app.entities.details',
                url: '/details',
                template: '<ce-entity-details-view case="$resolve.entity" layout="column" flex></ce-entity-details-view>',
                redirectTo: 'app.entities.details.names',
                data: { title: 'Details' }
            },
            {
                name: 'app.entities.details.names',
                url: '/names',
                template: '<ce-entity-names entity="$resolve.entity" layout="column" flex></ce-entity-names>',
                data: { title: 'Names' }
            },
        ];
        var runner = function (routingHelper) {
            'ngInject';
            routingHelper.configureRoutes(routes);
        };
        angular
            .module('app.entities')
            .run(runner);
    })(entities = app.entities || (app.entities = {}));
})(app || (app = {}));
var app;
(function (app) {
    var entities;
    (function (entities) {
        var EntityRoutingController = (function () {
            function EntityRoutingController(common) {
                this.common = common;
                'ngInject';
                'ngInject';
                this.init();
            }
            EntityRoutingController.prototype.init = function () {
                var entityType = this.common.$stateParams['entityType'];
                var entityId = this.common.$stateParams['entityId'];
                switch (entityType) {
                    case 'Person':
                        this.common.$state.go('app.person', { personId: entityId });
                        break;
                    case 'Agency':
                        this.common.$state.go('app.agency', { agencyId: entityId });
                        break;
                }
            };
            EntityRoutingController.$inject = ['common'];
            EntityRoutingController = __decorate([
                app.Controller('app.entities', 'EntityRoutingController')
            ], EntityRoutingController);
            return EntityRoutingController;
        }());
    })(entities = app.entities || (app.entities = {}));
})(app || (app = {}));
var app;
(function (app) {
    var fiscal;
    (function (fiscal) {
        'use strict';
        var baseUrl = 'api/fiscal';
        var FiscalRepository = (function () {
            function FiscalRepository($http, exception) {
                this.$http = $http;
                this.exception = exception;
            }
            FiscalRepository.prototype.getCheck = function (id) {
                return this.$http.get("".concat(baseUrl, "/checks/").concat(id));
            };
            FiscalRepository.prototype.deleteCheck = function (id) {
                return this.$http.delete("".concat(baseUrl, "/checks/").concat(id));
            };
            FiscalRepository.prototype.getCheckListing = function (params) {
                return this.$http.get("".concat(baseUrl, "/checks"), { params: params });
            };
            FiscalRepository.prototype.getCheckListingDownload = function (params) {
                var _this = this;
                return this.$http.get("".concat(baseUrl, "/csv"), { params: params, responseType: 'arraybuffer' }).then(function (response) {
                    return {
                        buffer: response.data,
                        contentType: response.headers()['content-type'],
                        fileName: response.headers()['content-disposition'].split(';')[1].split('=')[1].replace(/"/g, '')
                    };
                }).catch(function (error) { return _this.exception.catcher('XHR for getCsvData failed')(error); });
            };
            FiscalRepository.prototype.updateCheck = function (check) {
                return this.$http.put("".concat(baseUrl, "/checks/").concat(check.id), check);
            };
            FiscalRepository.prototype.createCheck = function (check) {
                return this.$http.post("".concat(baseUrl, "/checks"), check);
            };
            FiscalRepository.$inject = ['$http', 'exception'];
            __decorate([
                app.ApiExecutor()
            ], FiscalRepository.prototype, "getCheck", null);
            __decorate([
                app.ApiExecutor()
            ], FiscalRepository.prototype, "getCheckListing", null);
            __decorate([
                app.ApiExecutor()
            ], FiscalRepository.prototype, "updateCheck", null);
            __decorate([
                app.ApiExecutor()
            ], FiscalRepository.prototype, "createCheck", null);
            return FiscalRepository;
        }());
        angular
            .module('app.fiscal')
            .service('fiscal.repository', FiscalRepository);
    })(fiscal = app.fiscal || (app.fiscal = {}));
})(app || (app = {}));
var app;
(function (app) {
    var funding;
    (function (funding) {
        'use strict';
        var baseUrl = 'api/funding';
        var FundingRepository = (function () {
            function FundingRepository($http, exception) {
                this.$http = $http;
                this.exception = exception;
            }
            FundingRepository.prototype.getFundingPrograms = function (activeOnly, source) {
                return this.$http.get("".concat(baseUrl, "/programs"), { params: { activeOnly: activeOnly, source: source } });
            };
            FundingRepository.prototype.getFundingProgramsBySource = function (date, source, sourceId, involvementId) {
                return this.$http.get("".concat(baseUrl, "/programsbysource"), { params: { date: date, source: source, sourceId: sourceId, involvementId: involvementId } });
            };
            FundingRepository.prototype.getTrainingFundingPrograms = function (trainingId) {
                return this.$http.get("".concat(baseUrl, "/trainingPrograms"), { params: { trainingId: trainingId } });
            };
            FundingRepository.prototype.setTrainingFundingPrograms = function (trainingId, fundingProgramIds) {
                return this.$http.post("".concat(baseUrl, "/trainingPrograms"), { trainingId: trainingId, fundingProgramIds: fundingProgramIds });
            };
            FundingRepository.prototype.getEligibilityView = function (involvementId) {
                return this.$http.get("".concat(baseUrl, "/eligibility"), { params: { involvementId: involvementId } });
            };
            FundingRepository.prototype.getEnrollmentView = function (involvementId) {
                return this.$http.get("".concat(baseUrl, "/enrollments"), { params: { involvementId: involvementId } });
            };
            FundingRepository.prototype.createEnrollment = function (enrollment) {
                var _this = this;
                return this.$http.post("".concat(baseUrl, "/enrollments"), enrollment).catch(function (error) { return _this.exception.catcher('There can only be one Funding Program per Date')(error); });
            };
            FundingRepository.prototype.updateEnrollment = function (enrollment) {
                var _this = this;
                return this.$http.put("".concat(baseUrl, "/enrollments/").concat(enrollment.id), enrollment).catch(function (error) { return _this.exception.catcher('There can only be one Funding Program per Date')(error); });
            };
            FundingRepository.prototype.deleteEnrollment = function (enrollmentId) {
                return this.$http.delete("".concat(baseUrl, "/enrollments/").concat(enrollmentId));
            };
            FundingRepository.prototype.forceSetPrimaryFundingSource = function (enrollment) {
                return this.$http.post("".concat(baseUrl, "/setprimary"), enrollment);
            };
            FundingRepository.prototype.setPrimaryFundingSource = function (enrollment) {
                return this.$http.post("".concat(baseUrl, "/primary"), enrollment);
            };
            FundingRepository.prototype.setSecondaryFundingSource = function (enrollment) {
                return this.$http.post("".concat(baseUrl, "/secondary"), enrollment);
            };
            FundingRepository.prototype.deleteSecondaryFundingSource = function (involvementId) {
                return this.$http.delete("".concat(baseUrl, "/secondary/").concat(involvementId));
            };
            FundingRepository.prototype.getFundingSummaryView = function (involvementId) {
                return this.$http.get("".concat(baseUrl, "/summary?involvementId=").concat(involvementId));
            };
            FundingRepository.prototype.getEnrollmentEligibilityView = function (involvementId) {
                return this.$http.get("".concat(baseUrl, "/enrollments/eligibility"), { params: { involvementId: involvementId } });
            };
            FundingRepository.prototype.createEligibility = function (eligibility) {
                return this.$http.post("".concat(baseUrl, "/eligibility/"), eligibility);
            };
            FundingRepository.prototype.createCAPEligibility = function (eligibility) {
                return this.$http.post("".concat(baseUrl, "/eligibility/cap"), eligibility);
            };
            FundingRepository.prototype.createPAATEligibility = function (eligibility) {
                return this.$http.post("".concat(baseUrl, "/eligibility/paat"), eligibility);
            };
            FundingRepository.prototype.createPABSSEligibility = function (eligibility) {
                return this.$http.post("".concat(baseUrl, "/eligibility/pabss"), eligibility);
            };
            FundingRepository.prototype.createPADDEligibility = function (eligibility) {
                return this.$http.post("".concat(baseUrl, "/eligibility/padd"), eligibility);
            };
            FundingRepository.prototype.createPAIMIEligibility = function (eligibility) {
                return this.$http.post("".concat(baseUrl, "/eligibility/paimi"), eligibility);
            };
            FundingRepository.prototype.createPAIREligibility = function (eligibility) {
                return this.$http.post("".concat(baseUrl, "/eligibility/pair"), eligibility);
            };
            FundingRepository.prototype.createPATBIEligibility = function (eligibility) {
                return this.$http.post("".concat(baseUrl, "/eligibility/patbi"), eligibility);
            };
            FundingRepository.prototype.getPADDIntake = function (id) {
                return this.$http.get("".concat(baseUrl, "/enrollments/").concat(id, "/padd/intake"));
            };
            FundingRepository.prototype.getPADDOutcome = function (id) {
                return this.$http.get("".concat(baseUrl, "/enrollments/").concat(id, "/padd/outcome"));
            };
            FundingRepository.prototype.updatePADDIntake = function (intake) {
                return this.$http.post("".concat(baseUrl, "/enrollments/").concat(intake.id, "/padd/intake"), intake);
            };
            FundingRepository.prototype.updatePADDOutcome = function (outcome) {
                return this.$http.post("".concat(baseUrl, "/enrollments/").concat(outcome.id, "/padd/outcome"), outcome);
            };
            FundingRepository.prototype.getPADDSystemicIntake = function (id) {
                return this.$http.get("".concat(baseUrl, "/enrollments/").concat(id, "/paddsystemic/intake"));
            };
            FundingRepository.prototype.getPADDSystemicOutcome = function (id) {
                return this.$http.get("".concat(baseUrl, "/enrollments/").concat(id, "/paddsystemic/outcome"));
            };
            FundingRepository.prototype.updatePADDSystemicIntake = function (intake) {
                return this.$http.post("".concat(baseUrl, "/enrollments/").concat(intake.id, "/paddsystemic/intake"), intake);
            };
            FundingRepository.prototype.updatePADDSystemicOutcome = function (outcome) {
                return this.$http.post("".concat(baseUrl, "/enrollments/").concat(outcome.id, "/paddsystemic/outcome"), outcome);
            };
            FundingRepository.prototype.getPABSSIntake = function (id) {
                return this.$http.get("".concat(baseUrl, "/enrollments/").concat(id, "/pabss/intake"));
            };
            FundingRepository.prototype.getPABSSOutcome = function (id) {
                return this.$http.get("".concat(baseUrl, "/enrollments/").concat(id, "/pabss/outcome"));
            };
            FundingRepository.prototype.updatePABSSIntake = function (intake) {
                return this.$http.post("".concat(baseUrl, "/enrollments/").concat(intake.id, "/pabss/intake"), intake);
            };
            FundingRepository.prototype.updatePABSSOutcome = function (outcome) {
                return this.$http.post("".concat(baseUrl, "/enrollments/").concat(outcome.id, "/pabss/outcome"), outcome);
            };
            FundingRepository.prototype.getPAATIntake = function (id) {
                return this.$http.get("".concat(baseUrl, "/enrollments/").concat(id, "/paat/intake"));
            };
            FundingRepository.prototype.getPAATOutcome = function (id) {
                return this.$http.get("".concat(baseUrl, "/enrollments/").concat(id, "/paat/outcome"));
            };
            FundingRepository.prototype.updatePAATIntake = function (intake) {
                return this.$http.post("".concat(baseUrl, "/enrollments/").concat(intake.id, "/paat/intake"), intake);
            };
            FundingRepository.prototype.updatePAATOutcome = function (outcome) {
                return this.$http.post("".concat(baseUrl, "/enrollments/").concat(outcome.id, "/paat/outcome"), outcome);
            };
            FundingRepository.prototype.getPAIRIntake = function (id) {
                return this.$http.get("".concat(baseUrl, "/enrollments/").concat(id, "/pair/intake"));
            };
            FundingRepository.prototype.getPAIROutcome = function (id) {
                return this.$http.get("".concat(baseUrl, "/enrollments/").concat(id, "/pair/outcome"));
            };
            FundingRepository.prototype.updatePAIRIntake = function (intake) {
                return this.$http.post("".concat(baseUrl, "/enrollments/").concat(intake.id, "/pair/intake"), intake);
            };
            FundingRepository.prototype.updatePAIROutcome = function (outcome) {
                return this.$http.post("".concat(baseUrl, "/enrollments/").concat(outcome.id, "/pair/outcome"), outcome);
            };
            FundingRepository.prototype.getPATBIIntake = function (id) {
                return this.$http.get("".concat(baseUrl, "/enrollments/").concat(id, "/patbi/intake"));
            };
            FundingRepository.prototype.getPATBIOutcome = function (id) {
                return this.$http.get("".concat(baseUrl, "/enrollments/").concat(id, "/patbi/outcome"));
            };
            FundingRepository.prototype.updatePATBIIntake = function (intake) {
                return this.$http.post("".concat(baseUrl, "/enrollments/").concat(intake.id, "/patbi/intake"), intake);
            };
            FundingRepository.prototype.updatePATBIOutcome = function (outcome) {
                return this.$http.post("".concat(baseUrl, "/enrollments/").concat(outcome.id, "/patbi/outcome"), outcome);
            };
            FundingRepository.prototype.getPAIMIIntake = function (id) {
                return this.$http.get("".concat(baseUrl, "/enrollments/").concat(id, "/paimi/intake"));
            };
            FundingRepository.prototype.getPAIMIOutcome = function (id) {
                return this.$http.get("".concat(baseUrl, "/enrollments/").concat(id, "/paimi/outcome"));
            };
            FundingRepository.prototype.updatePAIMIIntake = function (intake) {
                return this.$http.post("".concat(baseUrl, "/enrollments/").concat(intake.id, "/paimi/intake"), intake);
            };
            FundingRepository.prototype.updatePAIMIOutcome = function (outcome) {
                return this.$http.post("".concat(baseUrl, "/enrollments/").concat(outcome.id, "/paimi/outcome"), outcome);
            };
            FundingRepository.prototype.getPAIMIProblemAndInterventionOptions = function () {
                return this.$http.get("".concat(baseUrl, "/enrollments/paimi/problemandinterventionoptions"));
            };
            FundingRepository.prototype.getPAIMICaseOutcomeOptions = function () {
                return this.$http.get("".concat(baseUrl, "/enrollments/paimi/caseoutcomeoptions"));
            };
            FundingRepository.prototype.getPAIMISystemicOutcomeOptions = function () {
                return this.$http.get("".concat(baseUrl, "/enrollments/paimi/systemicoutcomeoptionsview"));
            };
            FundingRepository.prototype.getPAIMISystemicIntake = function (id) {
                return this.$http.get("".concat(baseUrl, "/enrollments/").concat(id, "/paimisystemic/intake"));
            };
            FundingRepository.prototype.getPAIMISystemicOutcome = function (id) {
                return this.$http.get("".concat(baseUrl, "/enrollments/").concat(id, "/paimisystemic/outcome"));
            };
            FundingRepository.prototype.updatePAIMISystemicIntake = function (intake) {
                return this.$http.post("".concat(baseUrl, "/enrollments/").concat(intake.id, "/paimisystemic/intake"), intake);
            };
            FundingRepository.prototype.updatePAIMISystemicOutcome = function (outcome) {
                return this.$http.post("".concat(baseUrl, "/enrollments/").concat(outcome.id, "/paimisystemic/outcome"), outcome);
            };
            FundingRepository.prototype.getPAATSystemicIntake = function (id) {
                return this.$http.get("".concat(baseUrl, "/enrollments/").concat(id, "/paatsystemic/intake"));
            };
            FundingRepository.prototype.getPAATSystemicOutcome = function (id) {
                return this.$http.get("".concat(baseUrl, "/enrollments/").concat(id, "/paatsystemic/outcome"));
            };
            FundingRepository.prototype.updatePAATSystemicIntake = function (intake) {
                return this.$http.post("".concat(baseUrl, "/enrollments/").concat(intake.id, "/paatsystemic/intake"), intake);
            };
            FundingRepository.prototype.updatePAATSystemicOutcome = function (outcome) {
                return this.$http.post("".concat(baseUrl, "/enrollments/").concat(outcome.id, "/paatsystemic/outcome"), outcome);
            };
            FundingRepository.prototype.getPATBISystemicIntake = function (id) {
                return this.$http.get("".concat(baseUrl, "/enrollments/").concat(id, "/patbisystemic/intake"));
            };
            FundingRepository.prototype.getPATBISystemicOutcome = function (id) {
                return this.$http.get("".concat(baseUrl, "/enrollments/").concat(id, "/patbisystemic/outcome"));
            };
            FundingRepository.prototype.updatePATBISystemicIntake = function (intake) {
                return this.$http.post("".concat(baseUrl, "/enrollments/").concat(intake.id, "/patbisystemic/intake"), intake);
            };
            FundingRepository.prototype.updatePATBISystemicOutcome = function (outcome) {
                return this.$http.post("".concat(baseUrl, "/enrollments/").concat(outcome.id, "/patbisystemic/outcome"), outcome);
            };
            FundingRepository.prototype.getPAATLivingArrangementOptions = function () {
                return this.$http.get("".concat(baseUrl, "/enrollments/paatlivingarrangements"));
            };
            FundingRepository.prototype.getPADDLivingArrangementOptions = function () {
                return this.$http.get("".concat(baseUrl, "/enrollments/paddlivingarrangements"));
            };
            FundingRepository.prototype.getPATBILivingArrangementOptions = function () {
                return this.$http.get("".concat(baseUrl, "/enrollments/patbilivingarrangements"));
            };
            FundingRepository.prototype.getPAATProblemAreaOptions = function () {
                return this.$http.get("".concat(baseUrl, "/enrollments/paatproblemareas"));
            };
            FundingRepository.prototype.getPADDProblemAreaOptions = function () {
                return this.$http.get("".concat(baseUrl, "/enrollments/paddproblemareas"));
            };
            FundingRepository.prototype.getPATBIProblemAreaOptions = function () {
                return this.$http.get("".concat(baseUrl, "/enrollments/patbiproblemareas"));
            };
            FundingRepository.prototype.getPAVAIntake = function (id) {
                return this.$http.get("".concat(baseUrl, "/enrollments/").concat(id, "/pava/intake"));
            };
            FundingRepository.prototype.getPAVAOutcome = function (id) {
                return this.$http.get("".concat(baseUrl, "/enrollments/").concat(id, "/pava/outcome"));
            };
            FundingRepository.prototype.updatePAVAIntake = function (intake) {
                return this.$http.post("".concat(baseUrl, "/enrollments/").concat(intake.id, "/pava/intake"), intake);
            };
            FundingRepository.prototype.updatePAVAOutcome = function (outcome) {
                return this.$http.post("".concat(baseUrl, "/enrollments/").concat(outcome.id, "/pava/outcome"), outcome);
            };
            FundingRepository.prototype.getPAVASystemicIntake = function (id) {
                return this.$http.get("".concat(baseUrl, "/enrollments/").concat(id, "/pavasystemic/intake"));
            };
            FundingRepository.prototype.getPAVASystemicOutcome = function (id) {
                return this.$http.get("".concat(baseUrl, "/enrollments/").concat(id, "/pavasystemic/outcome"));
            };
            FundingRepository.prototype.updatePAVASystemicIntake = function (intake) {
                return this.$http.post("".concat(baseUrl, "/enrollments/").concat(intake.id, "/pavasystemic/intake"), intake);
            };
            FundingRepository.prototype.updatePAVASystemicOutcome = function (outcome) {
                return this.$http.post("".concat(baseUrl, "/enrollments/").concat(outcome.id, "/pavasystemic/outcome"), outcome);
            };
            FundingRepository.prototype.getPAVALivingArrangementOptions = function () {
                return this.$http.get("".concat(baseUrl, "/enrollments/pavalivingarrangements"));
            };
            FundingRepository.prototype.getPAVAProblemAreaOptions = function () {
                return this.$http.get("".concat(baseUrl, "/enrollments/pavaproblemareas"));
            };
            FundingRepository.prototype.getPAATIAndROutcome = function (id) {
                return this.$http.get("".concat(baseUrl, "/enrollments/").concat(id, "/paatiandr/outcome"));
            };
            FundingRepository.prototype.updatePAATIAndROutcome = function (outcome) {
                return this.$http.post("".concat(baseUrl, "/enrollments/").concat(outcome.id, "/paatiandr/outcome"), outcome);
            };
            FundingRepository.prototype.getPADDIAndROutcome = function (id) {
                return this.$http.get("".concat(baseUrl, "/enrollments/").concat(id, "/paddiandr/outcome"));
            };
            FundingRepository.prototype.updatePADDIAndROutcome = function (outcome) {
                return this.$http.post("".concat(baseUrl, "/enrollments/").concat(outcome.id, "/paddiandr/outcome"), outcome);
            };
            FundingRepository.prototype.getPATBIIAndROutcome = function (id) {
                return this.$http.get("".concat(baseUrl, "/enrollments/").concat(id, "/patbiiandr/outcome"));
            };
            FundingRepository.prototype.updatePATBIIAndROutcome = function (outcome) {
                return this.$http.post("".concat(baseUrl, "/enrollments/").concat(outcome.id, "/patbiiandr/outcome"), outcome);
            };
            FundingRepository.prototype.getPAVAIAndROutcome = function (id) {
                return this.$http.get("".concat(baseUrl, "/enrollments/").concat(id, "/pavaiandr/outcome"));
            };
            FundingRepository.prototype.updatePAVAIAndROutcome = function (outcome) {
                return this.$http.post("".concat(baseUrl, "/enrollments/").concat(outcome.id, "/pavaiandr/outcome"), outcome);
            };
            FundingRepository.prototype.getCAPIntake = function (id) {
                return this.$http.get("".concat(baseUrl, "/enrollments/").concat(id, "/cap/intake"));
            };
            FundingRepository.prototype.getCAPOutcome = function (id) {
                return this.$http.get("".concat(baseUrl, "/enrollments/").concat(id, "/cap/outcome"));
            };
            FundingRepository.prototype.updateCAPIntake = function (intake) {
                return this.$http.post("".concat(baseUrl, "/enrollments/").concat(intake.id, "/cap/intake"), intake);
            };
            FundingRepository.prototype.updateCAPOutcome = function (outcome) {
                return this.$http.post("".concat(baseUrl, "/enrollments/").concat(outcome.id, "/cap/outcome"), outcome);
            };
            FundingRepository.prototype.getCAPSystemicIntake = function (id) {
                return this.$http.get("".concat(baseUrl, "/enrollments/").concat(id, "/capsystemic/intake"));
            };
            FundingRepository.prototype.getCAPSystemicOutcome = function (id) {
                return this.$http.get("".concat(baseUrl, "/enrollments/").concat(id, "/capsystemic/outcome"));
            };
            FundingRepository.prototype.updateCAPSystemicIntake = function (intake) {
                return this.$http.post("".concat(baseUrl, "/enrollments/").concat(intake.id, "/capsystemic/intake"), intake);
            };
            FundingRepository.prototype.updateCAPSystemicOutcome = function (outcome) {
                return this.$http.post("".concat(baseUrl, "/enrollments/").concat(outcome.id, "/capsystemic/outcome"), outcome);
            };
            FundingRepository.prototype.getCAPLivingArrangementOptions = function () {
                return this.$http.get("".concat(baseUrl, "/enrollments/caplivingarrangements"));
            };
            FundingRepository.prototype.getCAPProblemAreaOptions = function () {
                return this.$http.get("".concat(baseUrl, "/enrollments/capproblemareas"));
            };
            FundingRepository.prototype.getCAPIAndROutcome = function (id) {
                return this.$http.get("".concat(baseUrl, "/enrollments/").concat(id, "/capiandr/outcome"));
            };
            FundingRepository.prototype.updateCAPIAndROutcome = function (outcome) {
                return this.$http.post("".concat(baseUrl, "/enrollments/").concat(outcome.id, "/capiandr/outcome"), outcome);
            };
            FundingRepository.prototype.getTEPIntake = function (id) {
                return this.$http.get("".concat(baseUrl, "/enrollments/").concat(id, "/tep/intake"));
            };
            FundingRepository.prototype.getTEPOutcome = function (id) {
                return this.$http.get("".concat(baseUrl, "/enrollments/").concat(id, "/tep/outcome"));
            };
            FundingRepository.prototype.updateTEPIntake = function (intake) {
                return this.$http.post("".concat(baseUrl, "/enrollments/").concat(intake.id, "/tep/intake"), intake);
            };
            FundingRepository.prototype.updateTEPOutcome = function (outcome) {
                return this.$http.post("".concat(baseUrl, "/enrollments/").concat(outcome.id, "/tep/outcome"), outcome);
            };
            FundingRepository.prototype.getNDBEDPIntake = function (id) {
                return this.$http.get("".concat(baseUrl, "/enrollments/").concat(id, "/ndbedp/intake"));
            };
            FundingRepository.prototype.getNDBEDPOutcome = function (id) {
                return this.$http.get("".concat(baseUrl, "/enrollments/").concat(id, "/ndbedp/outcome"));
            };
            FundingRepository.prototype.updateNDBEDPIntake = function (intake) {
                return this.$http.post("".concat(baseUrl, "/enrollments/").concat(intake.id, "/ndbedp/intake"), intake);
            };
            FundingRepository.prototype.updateNDBEDPOutcome = function (outcome) {
                return this.$http.post("".concat(baseUrl, "/enrollments/").concat(outcome.id, "/ndbedp/outcome"), outcome);
            };
            FundingRepository.prototype.getTEPSystemicIntake = function (id) {
                return this.$http.get("".concat(baseUrl, "/enrollments/").concat(id, "/tep/systemic/intake"));
            };
            FundingRepository.prototype.getTEPSystemicOutcome = function (id) {
                return this.$http.get("".concat(baseUrl, "/enrollments/").concat(id, "/tep/systemic/outcome"));
            };
            FundingRepository.prototype.updateTEPSystemicIntake = function (intake) {
                return this.$http.post("".concat(baseUrl, "/enrollments/").concat(intake.id, "/tep/systemic/intake"), intake);
            };
            FundingRepository.prototype.updateTEPSystemicOutcome = function (outcome) {
                return this.$http.post("".concat(baseUrl, "/enrollments/").concat(outcome.id, "/tep/systemic/outcome"), outcome);
            };
            FundingRepository.prototype.getNDBEDPSystemicIntake = function (id) {
                return this.$http.get("".concat(baseUrl, "/enrollments/").concat(id, "/ndbedp/systemic/intake"));
            };
            FundingRepository.prototype.getNDBEDPSystemicOutcome = function (id) {
                return this.$http.get("".concat(baseUrl, "/enrollments/").concat(id, "/ndbedp/systemic/outcome"));
            };
            FundingRepository.prototype.updateNDBEDPSystemicIntake = function (intake) {
                return this.$http.post("".concat(baseUrl, "/enrollments/").concat(intake.id, "/ndbedp/systemic/intake"), intake);
            };
            FundingRepository.prototype.updateNDBEDPSystemicOutcome = function (outcome) {
                return this.$http.post("".concat(baseUrl, "/enrollments/").concat(outcome.id, "/ndbedp/systemic/outcome"), outcome);
            };
            FundingRepository.$inject = ['$http', 'exception'];
            __decorate([
                app.ApiExecutor()
            ], FundingRepository.prototype, "getFundingPrograms", null);
            __decorate([
                app.ApiExecutor()
            ], FundingRepository.prototype, "getFundingProgramsBySource", null);
            __decorate([
                app.ApiExecutor()
            ], FundingRepository.prototype, "getTrainingFundingPrograms", null);
            __decorate([
                app.ApiExecutor()
            ], FundingRepository.prototype, "setTrainingFundingPrograms", null);
            __decorate([
                app.ApiExecutor()
            ], FundingRepository.prototype, "getEligibilityView", null);
            __decorate([
                app.ApiExecutor()
            ], FundingRepository.prototype, "getEnrollmentView", null);
            __decorate([
                app.ApiExecutor()
            ], FundingRepository.prototype, "deleteEnrollment", null);
            __decorate([
                app.ApiExecutor()
            ], FundingRepository.prototype, "forceSetPrimaryFundingSource", null);
            __decorate([
                app.ApiExecutor()
            ], FundingRepository.prototype, "setPrimaryFundingSource", null);
            __decorate([
                app.ApiExecutor()
            ], FundingRepository.prototype, "setSecondaryFundingSource", null);
            __decorate([
                app.ApiExecutor()
            ], FundingRepository.prototype, "deleteSecondaryFundingSource", null);
            __decorate([
                app.ApiExecutor()
            ], FundingRepository.prototype, "getFundingSummaryView", null);
            __decorate([
                app.ApiExecutor()
            ], FundingRepository.prototype, "getEnrollmentEligibilityView", null);
            __decorate([
                app.ApiExecutor()
            ], FundingRepository.prototype, "createEligibility", null);
            __decorate([
                app.ApiExecutor()
            ], FundingRepository.prototype, "createCAPEligibility", null);
            __decorate([
                app.ApiExecutor()
            ], FundingRepository.prototype, "createPAATEligibility", null);
            __decorate([
                app.ApiExecutor()
            ], FundingRepository.prototype, "createPABSSEligibility", null);
            __decorate([
                app.ApiExecutor()
            ], FundingRepository.prototype, "createPADDEligibility", null);
            __decorate([
                app.ApiExecutor()
            ], FundingRepository.prototype, "createPAIMIEligibility", null);
            __decorate([
                app.ApiExecutor()
            ], FundingRepository.prototype, "createPAIREligibility", null);
            __decorate([
                app.ApiExecutor()
            ], FundingRepository.prototype, "createPATBIEligibility", null);
            __decorate([
                app.ApiExecutor()
            ], FundingRepository.prototype, "getPADDIntake", null);
            __decorate([
                app.ApiExecutor()
            ], FundingRepository.prototype, "getPADDOutcome", null);
            __decorate([
                app.ApiExecutor()
            ], FundingRepository.prototype, "updatePADDIntake", null);
            __decorate([
                app.ApiExecutor()
            ], FundingRepository.prototype, "updatePADDOutcome", null);
            __decorate([
                app.ApiExecutor()
            ], FundingRepository.prototype, "getPADDSystemicIntake", null);
            __decorate([
                app.ApiExecutor()
            ], FundingRepository.prototype, "getPADDSystemicOutcome", null);
            __decorate([
                app.ApiExecutor()
            ], FundingRepository.prototype, "updatePADDSystemicIntake", null);
            __decorate([
                app.ApiExecutor()
            ], FundingRepository.prototype, "updatePADDSystemicOutcome", null);
            __decorate([
                app.ApiExecutor()
            ], FundingRepository.prototype, "getPABSSIntake", null);
            __decorate([
                app.ApiExecutor()
            ], FundingRepository.prototype, "getPABSSOutcome", null);
            __decorate([
                app.ApiExecutor()
            ], FundingRepository.prototype, "updatePABSSIntake", null);
            __decorate([
                app.ApiExecutor()
            ], FundingRepository.prototype, "updatePABSSOutcome", null);
            __decorate([
                app.ApiExecutor()
            ], FundingRepository.prototype, "getPAATIntake", null);
            __decorate([
                app.ApiExecutor()
            ], FundingRepository.prototype, "getPAATOutcome", null);
            __decorate([
                app.ApiExecutor()
            ], FundingRepository.prototype, "updatePAATIntake", null);
            __decorate([
                app.ApiExecutor()
            ], FundingRepository.prototype, "updatePAATOutcome", null);
            __decorate([
                app.ApiExecutor()
            ], FundingRepository.prototype, "getPAIRIntake", null);
            __decorate([
                app.ApiExecutor()
            ], FundingRepository.prototype, "getPAIROutcome", null);
            __decorate([
                app.ApiExecutor()
            ], FundingRepository.prototype, "updatePAIRIntake", null);
            __decorate([
                app.ApiExecutor()
            ], FundingRepository.prototype, "updatePAIROutcome", null);
            __decorate([
                app.ApiExecutor()
            ], FundingRepository.prototype, "getPATBIIntake", null);
            __decorate([
                app.ApiExecutor()
            ], FundingRepository.prototype, "getPATBIOutcome", null);
            __decorate([
                app.ApiExecutor()
            ], FundingRepository.prototype, "updatePATBIIntake", null);
            __decorate([
                app.ApiExecutor()
            ], FundingRepository.prototype, "updatePATBIOutcome", null);
            __decorate([
                app.ApiExecutor()
            ], FundingRepository.prototype, "getPAIMIIntake", null);
            __decorate([
                app.ApiExecutor()
            ], FundingRepository.prototype, "getPAIMIOutcome", null);
            __decorate([
                app.ApiExecutor()
            ], FundingRepository.prototype, "updatePAIMIIntake", null);
            __decorate([
                app.ApiExecutor()
            ], FundingRepository.prototype, "updatePAIMIOutcome", null);
            __decorate([
                app.ApiExecutor()
            ], FundingRepository.prototype, "getPAIMIProblemAndInterventionOptions", null);
            __decorate([
                app.ApiExecutor()
            ], FundingRepository.prototype, "getPAIMICaseOutcomeOptions", null);
            __decorate([
                app.ApiExecutor()
            ], FundingRepository.prototype, "getPAIMISystemicOutcomeOptions", null);
            __decorate([
                app.ApiExecutor()
            ], FundingRepository.prototype, "getPAIMISystemicIntake", null);
            __decorate([
                app.ApiExecutor()
            ], FundingRepository.prototype, "getPAIMISystemicOutcome", null);
            __decorate([
                app.ApiExecutor()
            ], FundingRepository.prototype, "updatePAIMISystemicIntake", null);
            __decorate([
                app.ApiExecutor()
            ], FundingRepository.prototype, "updatePAIMISystemicOutcome", null);
            __decorate([
                app.ApiExecutor()
            ], FundingRepository.prototype, "getPAATSystemicIntake", null);
            __decorate([
                app.ApiExecutor()
            ], FundingRepository.prototype, "getPAATSystemicOutcome", null);
            __decorate([
                app.ApiExecutor()
            ], FundingRepository.prototype, "updatePAATSystemicIntake", null);
            __decorate([
                app.ApiExecutor()
            ], FundingRepository.prototype, "updatePAATSystemicOutcome", null);
            __decorate([
                app.ApiExecutor()
            ], FundingRepository.prototype, "getPATBISystemicIntake", null);
            __decorate([
                app.ApiExecutor()
            ], FundingRepository.prototype, "getPATBISystemicOutcome", null);
            __decorate([
                app.ApiExecutor()
            ], FundingRepository.prototype, "updatePATBISystemicIntake", null);
            __decorate([
                app.ApiExecutor()
            ], FundingRepository.prototype, "updatePATBISystemicOutcome", null);
            __decorate([
                app.ApiExecutor()
            ], FundingRepository.prototype, "getPAATLivingArrangementOptions", null);
            __decorate([
                app.ApiExecutor()
            ], FundingRepository.prototype, "getPADDLivingArrangementOptions", null);
            __decorate([
                app.ApiExecutor()
            ], FundingRepository.prototype, "getPATBILivingArrangementOptions", null);
            __decorate([
                app.ApiExecutor()
            ], FundingRepository.prototype, "getPAATProblemAreaOptions", null);
            __decorate([
                app.ApiExecutor()
            ], FundingRepository.prototype, "getPADDProblemAreaOptions", null);
            __decorate([
                app.ApiExecutor()
            ], FundingRepository.prototype, "getPATBIProblemAreaOptions", null);
            __decorate([
                app.ApiExecutor()
            ], FundingRepository.prototype, "getPAVAIntake", null);
            __decorate([
                app.ApiExecutor()
            ], FundingRepository.prototype, "getPAVAOutcome", null);
            __decorate([
                app.ApiExecutor()
            ], FundingRepository.prototype, "updatePAVAIntake", null);
            __decorate([
                app.ApiExecutor()
            ], FundingRepository.prototype, "updatePAVAOutcome", null);
            __decorate([
                app.ApiExecutor()
            ], FundingRepository.prototype, "getPAVASystemicIntake", null);
            __decorate([
                app.ApiExecutor()
            ], FundingRepository.prototype, "getPAVASystemicOutcome", null);
            __decorate([
                app.ApiExecutor()
            ], FundingRepository.prototype, "updatePAVASystemicIntake", null);
            __decorate([
                app.ApiExecutor()
            ], FundingRepository.prototype, "updatePAVASystemicOutcome", null);
            __decorate([
                app.ApiExecutor()
            ], FundingRepository.prototype, "getPAVALivingArrangementOptions", null);
            __decorate([
                app.ApiExecutor()
            ], FundingRepository.prototype, "getPAVAProblemAreaOptions", null);
            __decorate([
                app.ApiExecutor()
            ], FundingRepository.prototype, "getPAATIAndROutcome", null);
            __decorate([
                app.ApiExecutor()
            ], FundingRepository.prototype, "updatePAATIAndROutcome", null);
            __decorate([
                app.ApiExecutor()
            ], FundingRepository.prototype, "getPADDIAndROutcome", null);
            __decorate([
                app.ApiExecutor()
            ], FundingRepository.prototype, "updatePADDIAndROutcome", null);
            __decorate([
                app.ApiExecutor()
            ], FundingRepository.prototype, "getPATBIIAndROutcome", null);
            __decorate([
                app.ApiExecutor()
            ], FundingRepository.prototype, "updatePATBIIAndROutcome", null);
            __decorate([
                app.ApiExecutor()
            ], FundingRepository.prototype, "getPAVAIAndROutcome", null);
            __decorate([
                app.ApiExecutor()
            ], FundingRepository.prototype, "updatePAVAIAndROutcome", null);
            __decorate([
                app.ApiExecutor()
            ], FundingRepository.prototype, "getCAPIntake", null);
            __decorate([
                app.ApiExecutor()
            ], FundingRepository.prototype, "getCAPOutcome", null);
            __decorate([
                app.ApiExecutor()
            ], FundingRepository.prototype, "updateCAPIntake", null);
            __decorate([
                app.ApiExecutor()
            ], FundingRepository.prototype, "updateCAPOutcome", null);
            __decorate([
                app.ApiExecutor()
            ], FundingRepository.prototype, "getCAPSystemicIntake", null);
            __decorate([
                app.ApiExecutor()
            ], FundingRepository.prototype, "getCAPSystemicOutcome", null);
            __decorate([
                app.ApiExecutor()
            ], FundingRepository.prototype, "updateCAPSystemicIntake", null);
            __decorate([
                app.ApiExecutor()
            ], FundingRepository.prototype, "updateCAPSystemicOutcome", null);
            __decorate([
                app.ApiExecutor()
            ], FundingRepository.prototype, "getCAPLivingArrangementOptions", null);
            __decorate([
                app.ApiExecutor()
            ], FundingRepository.prototype, "getCAPProblemAreaOptions", null);
            __decorate([
                app.ApiExecutor()
            ], FundingRepository.prototype, "getCAPIAndROutcome", null);
            __decorate([
                app.ApiExecutor()
            ], FundingRepository.prototype, "updateCAPIAndROutcome", null);
            __decorate([
                app.ApiExecutor()
            ], FundingRepository.prototype, "getTEPIntake", null);
            __decorate([
                app.ApiExecutor()
            ], FundingRepository.prototype, "getTEPOutcome", null);
            __decorate([
                app.ApiExecutor()
            ], FundingRepository.prototype, "updateTEPIntake", null);
            __decorate([
                app.ApiExecutor()
            ], FundingRepository.prototype, "updateTEPOutcome", null);
            __decorate([
                app.ApiExecutor()
            ], FundingRepository.prototype, "getNDBEDPIntake", null);
            __decorate([
                app.ApiExecutor()
            ], FundingRepository.prototype, "getNDBEDPOutcome", null);
            __decorate([
                app.ApiExecutor()
            ], FundingRepository.prototype, "updateNDBEDPIntake", null);
            __decorate([
                app.ApiExecutor()
            ], FundingRepository.prototype, "updateNDBEDPOutcome", null);
            __decorate([
                app.ApiExecutor()
            ], FundingRepository.prototype, "getTEPSystemicIntake", null);
            __decorate([
                app.ApiExecutor()
            ], FundingRepository.prototype, "getTEPSystemicOutcome", null);
            __decorate([
                app.ApiExecutor()
            ], FundingRepository.prototype, "updateTEPSystemicIntake", null);
            __decorate([
                app.ApiExecutor()
            ], FundingRepository.prototype, "updateTEPSystemicOutcome", null);
            __decorate([
                app.ApiExecutor()
            ], FundingRepository.prototype, "getNDBEDPSystemicIntake", null);
            __decorate([
                app.ApiExecutor()
            ], FundingRepository.prototype, "getNDBEDPSystemicOutcome", null);
            __decorate([
                app.ApiExecutor()
            ], FundingRepository.prototype, "updateNDBEDPSystemicIntake", null);
            __decorate([
                app.ApiExecutor()
            ], FundingRepository.prototype, "updateNDBEDPSystemicOutcome", null);
            return FundingRepository;
        }());
        angular
            .module('app.funding')
            .service('funding.repository', FundingRepository);
    })(funding = app.funding || (app.funding = {}));
})(app || (app = {}));
var app;
(function (app) {
    var funding;
    (function (funding) {
        var routes = [
            {
                name: 'app.funding',
                url: '/funding',
                template: '<ce-funding layout flex></ce-funding>',
                redirectTo: '^.summary'
            },
            {
                name: 'app.funding.summary',
                url: '',
                template: '<ce-funding-summary layout flex></ce-funding-summary>'
            },
            {
                name: 'app.funding.intake',
                url: '',
                template: '<ce-funding-intake layout flex></ce-funding-intake>'
            }
        ];
        var runner = function (routingHelper) {
            'ngInject';
            routingHelper.configureRoutes(routes);
        };
        angular
            .module('app.funding')
            .run(runner);
    })(funding = app.funding || (app.funding = {}));
})(app || (app = {}));
var app;
(function (app) {
    var grievance;
    (function (grievance_1) {
        'use strict';
        var baseUrl = 'api/grievances';
        var GrievanceRepository = (function () {
            function GrievanceRepository($http, exception) {
                this.$http = $http;
                this.exception = exception;
            }
            GrievanceRepository.prototype.getGrievanceListing = function (params) {
                return this.$http.get(baseUrl, { params: params });
            };
            GrievanceRepository.prototype.createGrievance = function (grievance) {
                return this.$http.post(baseUrl, grievance);
            };
            GrievanceRepository.prototype.getCases = function (entityId) {
                return this.$http.get("".concat(baseUrl, "/cases"), { params: { entityId: entityId } });
            };
            GrievanceRepository.prototype.deleteGrievance = function (id) {
                return this.$http.delete("".concat(baseUrl, "/").concat(id));
            };
            GrievanceRepository.prototype.getGrievance = function (id) {
                return this.$http.get("".concat(baseUrl, "/").concat(id));
            };
            GrievanceRepository.prototype.updateGrievance = function (id, data) {
                return this.$http.put("".concat(baseUrl, "/").concat(id), data);
            };
            GrievanceRepository.$inject = ['$http', 'exception'];
            __decorate([
                app.ApiExecutor()
            ], GrievanceRepository.prototype, "getGrievanceListing", null);
            __decorate([
                app.ApiExecutor()
            ], GrievanceRepository.prototype, "createGrievance", null);
            __decorate([
                app.ApiExecutor()
            ], GrievanceRepository.prototype, "getCases", null);
            __decorate([
                app.ApiExecutor()
            ], GrievanceRepository.prototype, "deleteGrievance", null);
            __decorate([
                app.ApiExecutor()
            ], GrievanceRepository.prototype, "getGrievance", null);
            __decorate([
                app.ApiExecutor()
            ], GrievanceRepository.prototype, "updateGrievance", null);
            return GrievanceRepository;
        }());
        angular
            .module('app.grievance')
            .service('grievance.repository', GrievanceRepository);
    })(grievance = app.grievance || (app.grievance = {}));
})(app || (app = {}));
var app;
(function (app) {
    var identity;
    (function (identity) {
        var baseUrl = 'api/identity-management';
        var IdentityRepository = (function () {
            function IdentityRepository($http, auth) {
                this.$http = $http;
                this.auth = auth;
                'ngInject';
                'ngInject';
            }
            IdentityRepository.prototype.getCurrentTenants = function () {
                return this.$http.get("".concat(baseUrl, "/users/current/tenants"));
            };
            IdentityRepository.prototype.getCurrentUser = function () {
                return this.$http.get("".concat(baseUrl, "/users/current"));
            };
            IdentityRepository.prototype.getUsers = function (params) {
                return this.$http.get("".concat(baseUrl, "/users"), { params: params });
            };
            IdentityRepository.prototype.switchTenant = function (data) {
                return this.$http.put("".concat(baseUrl, "/users/current/tenants/switch"), data);
            };
            IdentityRepository.prototype.updateCurrentUser = function (data) {
                var id = this.auth.user.id;
                return this.$http.put("".concat(baseUrl, "/users/").concat(id), data);
            };
            __decorate([
                app.ApiExecutor()
            ], IdentityRepository.prototype, "getCurrentTenants", null);
            __decorate([
                app.ApiExecutor()
            ], IdentityRepository.prototype, "getCurrentUser", null);
            __decorate([
                app.ApiExecutor()
            ], IdentityRepository.prototype, "getUsers", null);
            __decorate([
                app.ApiExecutor()
            ], IdentityRepository.prototype, "switchTenant", null);
            __decorate([
                app.ApiExecutor()
            ], IdentityRepository.prototype, "updateCurrentUser", null);
            return IdentityRepository;
        }());
        angular
            .module('app.identity')
            .service('identity.repository', IdentityRepository);
    })(identity = app.identity || (app.identity = {}));
})(app || (app = {}));
var app;
(function (app) {
    var informationAndReferrals;
    (function (informationAndReferrals) {
        'use strict';
        var baseUrl = 'api/informationAndReferrals';
        var InformationAndReferralRepository = (function () {
            function InformationAndReferralRepository($http, exception) {
                this.$http = $http;
                this.exception = exception;
            }
            InformationAndReferralRepository.prototype.getInformationAndReferralListing = function (params) {
                return this.$http.get(baseUrl, { params: params });
            };
            InformationAndReferralRepository.prototype.getInformationAndReferralListingDownload = function (params) {
                var _this = this;
                return this.$http.get("".concat(baseUrl, "/csv"), { params: params, responseType: 'arraybuffer' }).then(function (response) {
                    return {
                        buffer: response.data,
                        contentType: response.headers()['content-type'],
                        fileName: response.headers()['content-disposition'].split(';')[1].split('=')[1].replace(/"/g, '')
                    };
                }).catch(function (error) { return _this.exception.catcher('XHR for getCsvData failed')(error); });
            };
            InformationAndReferralRepository.prototype.getInformationAndReferralActivities = function (caseId) {
                return this.$http.get("".concat(baseUrl, "/").concat(caseId, "/activities"));
            };
            InformationAndReferralRepository.prototype.createInformationAndReferral = function (informationAndReferral) {
                return this.$http.post(baseUrl, informationAndReferral);
            };
            InformationAndReferralRepository.prototype.close = function (activity) {
                return this.$http.post("".concat(baseUrl, "/").concat(activity.caseId, "/close"), activity);
            };
            InformationAndReferralRepository.prototype.reopen = function (activity) {
                return this.$http.post("".concat(baseUrl, "/").concat(activity.caseId, "/reopen"), activity);
            };
            InformationAndReferralRepository.prototype.conflictCheck = function (activity) {
                return this.$http.post("".concat(baseUrl, "/").concat(activity.caseId, "/conflictCheck"), activity);
            };
            InformationAndReferralRepository.prototype.followUp = function (activity) {
                return this.$http.post("".concat(baseUrl, "/").concat(activity.caseId, "/followUp"), activity);
            };
            InformationAndReferralRepository.prototype.review = function (activity) {
                return this.$http.post("".concat(baseUrl, "/").concat(activity.caseId, "/review"), activity);
            };
            InformationAndReferralRepository.prototype.createNote = function (activity) {
                return this.$http.post("".concat(baseUrl, "/activities/").concat(activity.caseId, "/notes"), activity);
            };
            InformationAndReferralRepository.prototype.updateNote = function (activity) {
                return this.$http.put("".concat(baseUrl, "/activities/").concat(activity.caseId, "/notes/").concat(activity.id), activity);
            };
            InformationAndReferralRepository.prototype.getInformationAndReferralBasicInfoView = function (id) {
                return this.$http.get("".concat(baseUrl, "/").concat(id, "/basicinfo"));
            };
            InformationAndReferralRepository.prototype.getInformationAndReferralSummaryView = function (id) {
                return this.$http.get("".concat(baseUrl, "/").concat(id, "/summary"));
            };
            InformationAndReferralRepository.prototype.getInformationAndReferralStatus = function (id) {
                return this.$http.get("".concat(baseUrl, "/").concat(id, "/status"));
            };
            InformationAndReferralRepository.prototype.getInformationAndReferralToDo = function (id) {
                return this.$http.get("".concat(baseUrl, "/").concat(id, "/todo"));
            };
            InformationAndReferralRepository.prototype.getInformationAndReferralInformation = function (id) {
                return this.$http.get("".concat(baseUrl, "/").concat(id, "/information"));
            };
            InformationAndReferralRepository.prototype.updateInformationAndReferralInformation = function (info) {
                return this.$http.post("".concat(baseUrl, "/").concat(info.id, "/information"), info);
            };
            InformationAndReferralRepository.prototype.getActiveCaseCount = function (userIds) {
                return this.$http.get("".concat(baseUrl, "/activeCaseCount"), { params: { userIds: userIds } });
            };
            InformationAndReferralRepository.prototype.deleteInformationAndReferral = function (id) {
                var _this = this;
                return this.$http.delete("".concat(baseUrl, "/").concat(id)).catch(function (error) { return _this.exception.catcher('Cannot Delete I&R')(error); });
            };
            InformationAndReferralRepository.$inject = ['$http', 'exception'];
            __decorate([
                app.ApiExecutor()
            ], InformationAndReferralRepository.prototype, "getInformationAndReferralListing", null);
            __decorate([
                app.ApiExecutor()
            ], InformationAndReferralRepository.prototype, "getInformationAndReferralActivities", null);
            __decorate([
                app.ApiExecutor()
            ], InformationAndReferralRepository.prototype, "createInformationAndReferral", null);
            __decorate([
                app.ApiExecutor()
            ], InformationAndReferralRepository.prototype, "close", null);
            __decorate([
                app.ApiExecutor()
            ], InformationAndReferralRepository.prototype, "reopen", null);
            __decorate([
                app.ApiExecutor()
            ], InformationAndReferralRepository.prototype, "conflictCheck", null);
            __decorate([
                app.ApiExecutor()
            ], InformationAndReferralRepository.prototype, "followUp", null);
            __decorate([
                app.ApiExecutor()
            ], InformationAndReferralRepository.prototype, "review", null);
            __decorate([
                app.ApiExecutor()
            ], InformationAndReferralRepository.prototype, "createNote", null);
            __decorate([
                app.ApiExecutor()
            ], InformationAndReferralRepository.prototype, "updateNote", null);
            __decorate([
                app.ApiExecutor()
            ], InformationAndReferralRepository.prototype, "getInformationAndReferralBasicInfoView", null);
            __decorate([
                app.ApiExecutor()
            ], InformationAndReferralRepository.prototype, "getInformationAndReferralSummaryView", null);
            __decorate([
                app.ApiExecutor()
            ], InformationAndReferralRepository.prototype, "getInformationAndReferralStatus", null);
            __decorate([
                app.ApiExecutor()
            ], InformationAndReferralRepository.prototype, "getInformationAndReferralToDo", null);
            __decorate([
                app.ApiExecutor()
            ], InformationAndReferralRepository.prototype, "getInformationAndReferralInformation", null);
            __decorate([
                app.ApiExecutor()
            ], InformationAndReferralRepository.prototype, "updateInformationAndReferralInformation", null);
            __decorate([
                app.ApiExecutor()
            ], InformationAndReferralRepository.prototype, "getActiveCaseCount", null);
            return InformationAndReferralRepository;
        }());
        angular
            .module('app.informationAndReferrals')
            .service('informationAndReferrals.repository', InformationAndReferralRepository);
    })(informationAndReferrals = app.informationAndReferrals || (app.informationAndReferrals = {}));
})(app || (app = {}));
var app;
(function (app) {
    var informationAndReferrals;
    (function (informationAndReferrals) {
        'use strict';
        informationAndReferrals.caseBasicInfoResolver = function (datacontext, $stateParams) {
            'ngInject';
            var informationAndReferralId = $stateParams.informationAndReferralId;
            return datacontext.informationAndReferrals.getInformationAndReferralBasicInfoView(informationAndReferralId);
        };
        informationAndReferrals.legalTeamsResolver = function (datacontext) {
            'ngInject';
            return datacontext.config.legalTeams.getLegalTeams(false);
        };
    })(informationAndReferrals = app.informationAndReferrals || (app.informationAndReferrals = {}));
})(app || (app = {}));
var app;
(function (app) {
    var informationAndReferrals;
    (function (informationAndReferrals) {
        'use strict';
        var routes = [
            {
                name: 'app.createInformationAndReferral',
                url: '/information-and-referrals/create',
                template: '<ce-create-information-and-referral layout="column" flex></ce-create-information-and-referral>',
                data: {
                    title: 'Create I&R',
                    permissions: ['i-and-rs.edit']
                }
            },
            {
                name: 'app.informationAndReferrals',
                url: '/information-and-referrals/{informationAndReferralId}',
                template: '<ce-information-and-referral-view case="$resolve.case" layout="column" flex></ce-information-and-referral-view>',
                redirectTo: 'app.informationAndReferrals.overview',
                data: {
                    title: 'I&R View',
                    permissions: ['i-and-rs.view']
                },
                resolve: { case: informationAndReferrals.caseBasicInfoResolver }
            },
            {
                name: 'app.informationAndReferrals.overview',
                url: '/overview',
                template: '<ce-information-and-referral-overview case="$resolve.case" layout="column" flex></ce-information-and-referral-overview>',
                data: {
                    title: 'I&R Overview',
                    permissions: ['i-and-rs.view']
                }
            },
            {
                name: 'app.informationAndReferrals.details',
                url: '/details',
                template: '<ce-information-and-referral-details case="$resolve.case" layout="column" flex></ce-information-and-referral-details>',
                data: {
                    title: 'I&R Details',
                    permissions: ['i-and-rs.view']
                }
            },
            {
                name: 'app.informationAndReferrals.documents',
                url: '/documents',
                template: '<ce-documents-list involvement-id="$resolve.case.involvementId" source="I&R" source-id="$resolve.case.id" layout="column" flex></ce-document-list>',
                data: {
                    title: 'I&R Documents',
                    permissions: ['documents.view']
                }
            },
            {
                name: 'app.informationAndReferrals.uploadDocuments',
                url: '/upload',
                params: {
                    folderId: null
                },
                resolve: {
                    folderId: ['$stateParams', function (p) { return p.folderId; }],
                },
                template: '<ce-upload-documents-activity layout flex folder-id="$resolve.folderId" involvement-id="$resolve.case.involvementId" source="I&R" source-id="$resolve.case.id"></ce-upload-documents-activity>',
                data: { permissions: ['documents.edit'] }
            },
            {
                name: 'app.informationAndReferrals.emails',
                url: '/emails',
                template: '<ui-view flex layout="column"></ui-view>',
                redirectTo: 'app.informationAndReferrals.emails.listing',
                data: {
                    title: 'I&R Emails',
                    permissions: ['mail.view']
                }
            },
            {
                name: 'app.informationAndReferrals.emails.listing',
                url: '',
                template: '<ce-email-listing involvement-id="$resolve.case.involvementId" source="I&R" source-id="$resolve.case.id" layout="column" flex></ce-email-listing>',
                data: {
                    title: 'I&R Emails',
                    permissions: ['mail.view']
                }
            },
            {
                name: 'app.informationAndReferrals.emails.message',
                url: '/{messageId}',
                template: '<ce-email-view layout="column" flex message-id="$resolve.messageId"></ce-email-view>',
                data: {
                    title: 'Message',
                    permissions: ['mail.view']
                },
                resolve: {
                    messageId: ['$stateParams', function (p) { return p.messageId; }]
                }
            },
            {
                name: 'app.informationAndReferrals.emails.link',
                url: '/link',
                template: '<ce-link-emails layout="column" flex involvement-id="$resolve.case.involvementId" source="I&R" source-id="$resolve.case.id"></ce-link-emails>',
                data: {
                    title: 'Inbox Messages',
                    permissions: ['mail.link']
                }
            },
            {
                name: 'app.informationAndReferrals.involvement',
                url: '/involvement',
                template: '<ce-involvement-case-listing involvement-id="$resolve.case.involvementId" flex></ce-involvement-case-listing>',
                data: { title: 'I&R Involvement' }
            },
            {
                name: 'app.informationAndReferrals.timeslipListing',
                url: '/timeslips',
                template: '<ce-timeslip-listing source="I&R" source-name="$resolve.case.idNumber" source-name2="$resolve.case.requesterFullName" source-id="$resolve.case.id" involvement-id="$resolve.case.involvementId" enable-filters="false" flex layout="column"></ce-timeslip-listing>',
                data: {
                    title: 'I&R Timeslips',
                    permissions: ['timeslips.view']
                }
            },
            {
                name: 'app.informationAndReferrals.timeslipCalendar',
                url: '/timeslips-calendar',
                template: '<ce-timeslip-calendar-frame source="I&R" source-name="$resolve.case.idNumber" source-name2="$resolve.case.requesterFullName" source-id="$resolve.case.id" involvement-id="$resolve.case.involvementId" enable-filters="false" flex layout="column"></ce-timeslip-calendar-frame>',
                data: {
                    title: 'I&R Timeslips',
                    permissions: ['timeslips.view']
                }
            },
            {
                name: 'app.informationAndReferrals.grievances',
                url: '/grievances',
                template: '<ce-grievance-listing case="$resolve.case" case-type="I&R" flex></ce-grievance-listing>',
                data: {
                    title: 'I&R Grievances',
                    permissions: ['grievances.view']
                }
            }
        ];
        var runner = function (routingHelper) {
            'ngInject';
            routingHelper.configureRoutes(routes);
        };
        angular
            .module('app.informationAndReferrals')
            .run(runner);
    })(informationAndReferrals = app.informationAndReferrals || (app.informationAndReferrals = {}));
})(app || (app = {}));
var app;
(function (app) {
    var involvements;
    (function (involvements) {
        var baseUrl = 'api/involvements';
        var InvolvementRepository = (function () {
            function InvolvementRepository($http) {
                this.$http = $http;
                'ngInject';
                'ngInject';
            }
            InvolvementRepository.prototype.getEvents = function (params) {
                return this.$http.get("api/calendars/involvementevents", { params: params });
            };
            InvolvementRepository.prototype.getInvolvements = function (params) {
                return this.$http.get(baseUrl, { params: params });
            };
            InvolvementRepository.prototype.getEntity = function (involvementId, entityId) {
                return this.$http.get("".concat(baseUrl, "/").concat(involvementId, "/entities/").concat(entityId));
            };
            InvolvementRepository.prototype.getEntityListing = function (involvementId) {
                return this.$http.get("".concat(baseUrl, "/").concat(involvementId, "/entities"));
            };
            InvolvementRepository.prototype.getCaseListing = function (involvementId) {
                return this.$http.get("".concat(baseUrl, "/").concat(involvementId, "/cases"));
            };
            InvolvementRepository.prototype.getEntitySummary = function (entityType, entityId) {
                return this.$http.get("".concat(baseUrl, "/entitySummary?entityType=").concat(entityType, "&entityId=").concat(entityId));
            };
            InvolvementRepository.prototype.getPartySummary = function (involvementId) {
                return this.$http.get("".concat(baseUrl, "/").concat(involvementId, "/parties"));
            };
            InvolvementRepository.prototype.getEntitiesBasic = function (involvementId) {
                return this.$http.get("".concat(baseUrl, "/").concat(involvementId, "/entitiesbasic"));
            };
            InvolvementRepository.prototype.searchCases = function (params) {
                return this.$http.get("".concat(baseUrl, "/searchcases"), { params: params });
            };
            InvolvementRepository.prototype.createEntity = function (entity) {
                return this.$http.post("".concat(baseUrl, "/entities"), entity);
            };
            InvolvementRepository.prototype.updateEntity = function (entity) {
                return this.$http.put("".concat(baseUrl, "/entities"), entity);
            };
            InvolvementRepository.prototype.deleteEntity = function (id) {
                return this.$http.delete("".concat(baseUrl, "/entities/").concat(id));
            };
            InvolvementRepository.prototype.conflictCheck = function (activity) {
                return this.$http.post("".concat(baseUrl, "/").concat(activity.involvementId, "/conflictCheck"), activity);
            };
            InvolvementRepository.prototype.createCaseAssessmentMeeting = function (meeting) {
                return this.$http.post("".concat(baseUrl, "/").concat(meeting.involvementId, "/caseAssessmentMeeting"), meeting);
            };
            InvolvementRepository.prototype.updateCaseAssessmentMeeting = function (meeting) {
                return this.$http.put("".concat(baseUrl, "/").concat(meeting.involvementId, "/caseAssessmentMeeting/").concat(meeting.id), meeting);
            };
            InvolvementRepository.prototype.getCAM = function (involvementId, id) {
                return this.$http.get("".concat(baseUrl, "/").concat(involvementId, "/caseAssessmentMeeting/").concat(id));
            };
            InvolvementRepository.prototype.getBasicInfo = function (involvementId) {
                return this.$http.get("".concat(baseUrl, "/").concat(involvementId, "/basicInfo"));
            };
            __decorate([
                app.ApiExecutor()
            ], InvolvementRepository.prototype, "getEvents", null);
            __decorate([
                app.ApiExecutor()
            ], InvolvementRepository.prototype, "getInvolvements", null);
            __decorate([
                app.ApiExecutor()
            ], InvolvementRepository.prototype, "getEntity", null);
            __decorate([
                app.ApiExecutor()
            ], InvolvementRepository.prototype, "getEntityListing", null);
            __decorate([
                app.ApiExecutor()
            ], InvolvementRepository.prototype, "getCaseListing", null);
            __decorate([
                app.ApiExecutor()
            ], InvolvementRepository.prototype, "getEntitySummary", null);
            __decorate([
                app.ApiExecutor()
            ], InvolvementRepository.prototype, "getPartySummary", null);
            __decorate([
                app.ApiExecutor()
            ], InvolvementRepository.prototype, "getEntitiesBasic", null);
            __decorate([
                app.ApiExecutor()
            ], InvolvementRepository.prototype, "searchCases", null);
            __decorate([
                app.ApiExecutor()
            ], InvolvementRepository.prototype, "createEntity", null);
            __decorate([
                app.ApiExecutor()
            ], InvolvementRepository.prototype, "updateEntity", null);
            __decorate([
                app.ApiExecutor()
            ], InvolvementRepository.prototype, "deleteEntity", null);
            __decorate([
                app.ApiExecutor()
            ], InvolvementRepository.prototype, "conflictCheck", null);
            __decorate([
                app.ApiExecutor()
            ], InvolvementRepository.prototype, "createCaseAssessmentMeeting", null);
            __decorate([
                app.ApiExecutor()
            ], InvolvementRepository.prototype, "updateCaseAssessmentMeeting", null);
            __decorate([
                app.ApiExecutor()
            ], InvolvementRepository.prototype, "getCAM", null);
            __decorate([
                app.ApiExecutor()
            ], InvolvementRepository.prototype, "getBasicInfo", null);
            return InvolvementRepository;
        }());
        angular
            .module('app.involvements')
            .service('involvements.repository', InvolvementRepository);
    })(involvements = app.involvements || (app.involvements = {}));
})(app || (app = {}));
var app;
(function (app) {
    var layout;
    (function (layout) {
        var AppComponent = (function () {
            function AppComponent($mdMedia, $mdSidenav, common) {
                this.$mdMedia = $mdMedia;
                this.$mdSidenav = $mdSidenav;
                this.common = common;
                this.theme = 'default';
                this.isSidenavLocked = true;
                'ngInject';
                'ngInject';
                this.$state = this.common.$state;
            }
            AppComponent.prototype.toggleSidenav = function () {
                this.isSidenavLocked = !this.isSidenavLocked;
            };
            AppComponent.prototype.$onInit = function () {
                this.isSidenavLocked = this.$mdMedia('gt-md');
            };
            AppComponent.$inject = ['$mdMedia', '$mdSidenav', 'common'];
            AppComponent = __decorate([
                app.Component('app.layout', 'ceApp', {
                    templateUrl: 'app/layout/app.html'
                })
            ], AppComponent);
            return AppComponent;
        }());
    })(layout = app.layout || (app.layout = {}));
})(app || (app = {}));
var app;
(function (app) {
    var mail;
    (function (mail) {
        var baseUrl = 'api/mail';
        var MailRepository = (function () {
            function MailRepository($http, exception) {
                this.$http = $http;
                this.exception = exception;
                'ngInject';
                'ngInject';
            }
            MailRepository.prototype.fetchNewMessages = function () {
                return this.$http.post("".concat(baseUrl, "/messages/fetch"), null);
            };
            MailRepository.prototype.getMessages = function (params) {
                return this.$http.get("".concat(baseUrl, "/messages"), { params: params });
            };
            MailRepository.prototype.getMessage = function (id) {
                return this.$http.get("".concat(baseUrl, "/messages/").concat(id));
            };
            MailRepository.prototype.importAttachments = function (data) {
                return this.$http.post("".concat(baseUrl, "/attachments/import"), data);
            };
            MailRepository.prototype.downloadAttachment = function (messageId, attachmentId) {
                var _this = this;
                var config = { responseType: 'arraybuffer' };
                return this.$http.get("".concat(baseUrl, "/messages/").concat(messageId, "/attachments/").concat(attachmentId, "/download"), config).then(function (response) {
                    var contentType = response.headers()['content-type'];
                    var fileName = response.headers()['content-disposition'];
                    if (fileName) {
                        fileName = fileName.split(';')[1].split('=')[1].replace(/"/g, '');
                    }
                    return {
                        buffer: response.data,
                        contentType: contentType,
                        fileName: fileName
                    };
                }).catch(function (error) { return _this.exception.catcher('downloadAttachment failed')(error); });
            };
            MailRepository.prototype.linkMessages = function (data) {
                return this.$http.post("".concat(baseUrl, "/messages/link"), data);
            };
            MailRepository.prototype.createMessage = function (data) {
                return this.$http.post("".concat(baseUrl, "/messages"), data);
            };
            MailRepository.prototype.changeMessageFolder = function (data) {
                return this.$http.put("".concat(baseUrl, "/messages/").concat(data.messageId, "/change"), data);
            };
            MailRepository.prototype.moveMessage = function (data) {
                return this.$http.put("".concat(baseUrl, "/messages/").concat(data.messageId, "/move"), data);
            };
            MailRepository.prototype.copyMessage = function (data) {
                return this.$http.put("".concat(baseUrl, "/messages/").concat(data.messageId, "/copy"), data);
            };
            MailRepository.prototype.getFolders = function (params) {
                return this.$http.get("".concat(baseUrl, "/folders"), { params: params });
            };
            MailRepository.prototype.createFolder = function (data) {
                return this.$http.post("".concat(baseUrl, "/folders"), data);
            };
            MailRepository.prototype.updateFolder = function (data) {
                return this.$http.put("".concat(baseUrl, "/folders/").concat(data.id), data);
            };
            MailRepository.prototype.deleteFolder = function (folderId) {
                return this.$http.delete("".concat(baseUrl, "/folders/").concat(folderId));
            };
            MailRepository.prototype.getSources = function (params) {
                return this.$http.get("".concat(baseUrl, "/sources"), { params: params });
            };
            MailRepository.prototype.deleteMessage = function (messageId) {
                return this.$http.delete("".concat(baseUrl, "/messages/").concat(messageId));
            };
            __decorate([
                app.ApiExecutor({ returnValue: true })
            ], MailRepository.prototype, "fetchNewMessages", null);
            __decorate([
                app.ApiExecutor()
            ], MailRepository.prototype, "getMessages", null);
            __decorate([
                app.ApiExecutor()
            ], MailRepository.prototype, "getMessage", null);
            __decorate([
                app.ApiExecutor({ returnValue: true })
            ], MailRepository.prototype, "importAttachments", null);
            __decorate([
                app.ApiExecutor({ returnValue: true })
            ], MailRepository.prototype, "linkMessages", null);
            __decorate([
                app.ApiExecutor()
            ], MailRepository.prototype, "createMessage", null);
            __decorate([
                app.ApiExecutor()
            ], MailRepository.prototype, "changeMessageFolder", null);
            __decorate([
                app.ApiExecutor()
            ], MailRepository.prototype, "moveMessage", null);
            __decorate([
                app.ApiExecutor()
            ], MailRepository.prototype, "copyMessage", null);
            __decorate([
                app.ApiExecutor()
            ], MailRepository.prototype, "getFolders", null);
            __decorate([
                app.ApiExecutor()
            ], MailRepository.prototype, "createFolder", null);
            __decorate([
                app.ApiExecutor()
            ], MailRepository.prototype, "updateFolder", null);
            __decorate([
                app.ApiExecutor({ returnValue: true })
            ], MailRepository.prototype, "deleteFolder", null);
            __decorate([
                app.ApiExecutor()
            ], MailRepository.prototype, "getSources", null);
            __decorate([
                app.ApiExecutor({ returnValue: true })
            ], MailRepository.prototype, "deleteMessage", null);
            return MailRepository;
        }());
        angular
            .module('app.mail')
            .service('mail.repository', MailRepository);
    })(mail = app.mail || (app.mail = {}));
})(app || (app = {}));
var app;
(function (app) {
    var mail;
    (function (mail) {
        var routes = [
            {
                name: 'app.mail',
                url: '/mail',
                template: '<ui-view layout="column" flex></ui-view>',
                redirectTo: 'app.mail.inbox'
            },
            {
                name: 'app.mail.inbox',
                url: '',
                template: '<ce-inbox-view layout="column" flex></ce-inbox-view>',
                data: {
                    title: 'Inbox',
                    permissions: ['mail.link']
                }
            },
            {
                name: 'app.mail.linkMessages',
                url: '/link?messageIds',
                template: '<ce-link-messages-view layout="column" flex message-ids="$resolve.messageIds"></ce-link-messages-view>',
                data: {
                    title: 'Link Messages',
                    permissions: ['mail.link']
                },
                resolve: {
                    messageIds: ['$stateParams', function (p) { return p.messageIds; }]
                }
            },
            {
                name: 'app.mail.message',
                url: '/{messageId}',
                template: '<ce-email-view layout="column" flex message-id="$resolve.messageId"></ce-email-view>',
                data: {
                    title: 'Message',
                    permissions: ['mail.view']
                },
                resolve: {
                    messageId: ['$stateParams', function (p) { return p.messageId; }]
                }
            }
        ];
        var runner = function (routingHelper) {
            'ngInject';
            routingHelper.configureRoutes(routes);
        };
        angular
            .module('app.mail')
            .run(runner);
    })(mail = app.mail || (app.mail = {}));
})(app || (app = {}));
var app;
(function (app) {
    var persons;
    (function (persons) {
        var baseUrl = 'api/persons';
        var PersonRepository = (function () {
            function PersonRepository($http, exception) {
                this.$http = $http;
                this.exception = exception;
                'ngInject';
                'ngInject';
            }
            PersonRepository.prototype.createPerson = function (data) {
                return this.$http.post(baseUrl, data);
            };
            PersonRepository.prototype.deletePhoneNumber = function (id, phoneNumberId) {
                return this.$http.delete("".concat(baseUrl, "/").concat(id, "/phone-numbers/").concat(phoneNumberId));
            };
            PersonRepository.prototype.deletePhoto = function (id) {
                return this.$http.delete("".concat(baseUrl, "/").concat(id, "/photo"));
            };
            PersonRepository.prototype.getDemographics = function (personId) {
                return this.$http.get("".concat(baseUrl, "/").concat(personId, "/demographics"));
            };
            PersonRepository.prototype.getSpecialConsiderations = function (personId) {
                return this.$http.get("".concat(baseUrl, "/").concat(personId, "/specialconsiderations"));
            };
            PersonRepository.prototype.getPerson = function (id) {
                return this.$http.get("".concat(baseUrl, "/").concat(id));
            };
            PersonRepository.prototype.getPersons = function (params) {
                return this.$http.get('api/persons', { params: params });
            };
            PersonRepository.prototype.getUpcomingDates = function (personId) {
                return this.$http.get("".concat(baseUrl, "/").concat(personId, "/upcoming-dates"));
            };
            PersonRepository.prototype.getImportantInformation = function (personId) {
                return this.$http.get("".concat(baseUrl, "/").concat(personId, "/importantinformation"));
            };
            PersonRepository.prototype.linkPhoto = function (id, photoId) {
                return this.$http.put("".concat(baseUrl, "/").concat(id, "/photo"), { photoId: photoId });
            };
            PersonRepository.prototype.updateDemographics = function (personId, data) {
                return this.$http.put("".concat(baseUrl, "/").concat(personId, "/demographics"), data);
            };
            PersonRepository.prototype.updateBaseDemographics = function (personId, data) {
                return this.$http.put("".concat(baseUrl, "/").concat(personId, "/demographics"), data);
            };
            PersonRepository.prototype.updateSpecialConsiderations = function (personId, data) {
                return this.$http.put("".concat(baseUrl, "/").concat(personId, "/specialconsiderations"), data);
            };
            PersonRepository.prototype.merge = function (winnerId, loserId) {
                return this.$http.post("".concat(baseUrl, "/merge"), { winnerId: winnerId, loserId: loserId });
            };
            __decorate([
                app.ApiExecutor()
            ], PersonRepository.prototype, "createPerson", null);
            __decorate([
                app.ApiExecutor({ returnValue: true })
            ], PersonRepository.prototype, "deletePhoneNumber", null);
            __decorate([
                app.ApiExecutor({ returnValue: true })
            ], PersonRepository.prototype, "deletePhoto", null);
            __decorate([
                app.ApiExecutor()
            ], PersonRepository.prototype, "getDemographics", null);
            __decorate([
                app.ApiExecutor()
            ], PersonRepository.prototype, "getSpecialConsiderations", null);
            __decorate([
                app.ApiExecutor()
            ], PersonRepository.prototype, "getPerson", null);
            __decorate([
                app.ApiExecutor()
            ], PersonRepository.prototype, "getPersons", null);
            __decorate([
                app.ApiExecutor()
            ], PersonRepository.prototype, "getUpcomingDates", null);
            __decorate([
                app.ApiExecutor()
            ], PersonRepository.prototype, "getImportantInformation", null);
            __decorate([
                app.ApiExecutor({ returnValue: true })
            ], PersonRepository.prototype, "linkPhoto", null);
            __decorate([
                app.ApiExecutor()
            ], PersonRepository.prototype, "updateDemographics", null);
            __decorate([
                app.ApiExecutor()
            ], PersonRepository.prototype, "updateBaseDemographics", null);
            __decorate([
                app.ApiExecutor()
            ], PersonRepository.prototype, "updateSpecialConsiderations", null);
            __decorate([
                app.ApiExecutor()
            ], PersonRepository.prototype, "merge", null);
            return PersonRepository;
        }());
        angular
            .module('app.persons')
            .service('persons.repository', PersonRepository);
    })(persons = app.persons || (app.persons = {}));
})(app || (app = {}));
var app;
(function (app) {
    var persons;
    (function (persons) {
        persons.personResolver = function (datacontext, $stateParams) {
            'ngInject';
            var personId = $stateParams.personId;
            return datacontext.persons.getPerson(personId);
        };
        persons.showAtPovertyLevelResolver = function (datacontext) {
            'ngInject';
            var bypassReasonsPromise = datacontext.config.getConfigurationParameter('Entities.AtPovertyLevel', false);
            return bypassReasonsPromise.then(function (result) {
                return result.toString() === 'true';
            });
        };
        persons.showPovertyLevelResolver = function (datacontext) {
            'ngInject';
            var bypassReasonsPromise = datacontext.config.getConfigurationParameter('Entities.PovertyLevel', false);
            return bypassReasonsPromise.then(function (result) {
                return result.toString() === 'true';
            });
        };
        persons.showRaceSubCategoryResolver = function (datacontext) {
            'ngInject';
            var bypassReasonsPromise = datacontext.config.getConfigurationParameter('Entities.RaceSubCategory', false);
            return bypassReasonsPromise.then(function (result) {
                return result.toString() === 'true';
            });
        };
    })(persons = app.persons || (app.persons = {}));
})(app || (app = {}));
var app;
(function (app) {
    var persons;
    (function (persons) {
        var routes = [
            {
                name: 'app.person',
                url: '/persons/{personId}',
                template: '<ce-person-view layout="column" flex person="$resolve.person"></ce-person-view>',
                redirectTo: 'app.person.overview',
                resolve: {
                    person: persons.personResolver
                }
            },
            {
                name: 'app.person.overview',
                url: '/overview',
                template: '<ce-person-overview-view person="$resolve.person" layout="column" flex></ce-person-overview-view>',
                data: { title: 'Person Overview' }
            },
            {
                name: 'app.person.cases',
                url: '/cases',
                template: '<ce-entity-case-listing entity-id="$resolve.person.id" layout="column" flex></ce-entity-case-listing>',
                data: { title: 'Person Involvements' }
            },
            {
                name: 'app.person.activities',
                url: '/activities',
                template: '<ui-view layout flex></ui-view>',
                redirectTo: 'app.person.activities.list'
            },
            {
                name: 'app.person.activities.list',
                url: '',
                template: '<ce-entity-activities entity-id="$resolve.person.id" layout="column" flex></ce-entity-activities>',
                data: {
                    title: 'Person Activities',
                    permissions: ['access_activities']
                }
            },
            {
                name: 'app.person.activities.createRequest',
                url: '/createRequest',
                template: "<ce-requests-create-request layout=\"column\" flex\n                        entity=\"$resolve.person\"\n                        entity-type=\"Person\">\n                    </ce-requests-create-request>",
                data: {
                    title: 'Create Request',
                    permissions: ['requests.edit']
                }
            },
            {
                name: 'app.person.activities.createInformationAndReferral',
                url: '/createInformationAndReferral',
                template: "<ce-create-information-and-referral layout=\"column\" flex\n                        entity=\"$resolve.person\"\n                        entity-type=\"Person\">\n                    </ce-create-information-and-referral>",
                data: {
                    title: 'Create I&R',
                    permissions: ['i-and-rs.edit']
                }
            },
            {
                name: 'app.person.activities.createClientCase',
                url: '/createclientcase',
                template: "<ce-create-client-case layout=\"column\" flex\n                        entity=\"$resolve.person\"\n                        entity-type=\"Person\">\n                    </ce-create-client-case>",
                data: {
                    title: 'Create Client Case',
                    permissions: ['client-cases.edit']
                }
            },
            {
                name: 'app.person.details',
                url: '/details',
                template: '<ce-person-details-view person="$resolve.person" layout="column" flex></ce-person-details-view>',
                redirectTo: 'app.person.details.contactInfo'
            },
            {
                name: 'app.person.details.contactInfo',
                url: '/contact-info',
                template: '<ce-person-contact-info person="$resolve.person" layout="column" flex></ce-person-contact-info>',
                data: { title: 'Contact Info' }
            },
            {
                name: 'app.person.details.demographics',
                url: '/demographics',
                template: "<ce-person-demographics layout=\"column\" flex\n                   person=\"$resolve.person\"\n                   param-poverty-level=\"$resolve.paramPovertyLevel\"\n                   param-at-poverty=\"$resolve.paramAtPoverty\"\n                   param-race-Sub-Category=\"$resolve.paramRaceSubCategory\"></ce-person-demographics>",
                data: { title: 'Demographics' },
                resolve: {
                    paramPovertyLevel: persons.showPovertyLevelResolver,
                    paramAtPoverty: persons.showAtPovertyLevelResolver,
                    paramRaceSubCategory: persons.showRaceSubCategoryResolver
                }
            },
            {
                name: 'app.person.details.specialConsiderations',
                url: '/specialConsiderations',
                template: '<ce-person-special-considerations person="$resolve.person" layout="column" flex></ce-person-special-considerations>',
                data: { title: 'Special Considerations' }
            },
            {
                name: 'app.person.entities',
                url: '/entities',
                template: '<ui-view flex></ui-view>',
                redirectTo: 'app.person.entities.listing',
                data: { title: 'Entities' }
            },
            {
                name: 'app.person.entities.listing',
                url: '',
                template: '<ce-related-entities entity-id="$resolve.person.id" layout="column" flex></ce-related-entities>',
                data: { title: 'Entities' }
            },
            {
                name: 'app.person.entities.add',
                url: '/add',
                template: '<ce-select-entity-type flex></ce-select-entity-type>',
                redirectTo: 'app.person.entities.add.type',
                data: { title: 'Add' }
            },
            {
                name: 'app.person.entities.add.type',
                url: '/types',
                template: '<ce-entity-types flex></ce-entity-types>'
            },
            {
                name: 'app.person.entities.add.person',
                url: '/person',
                template: '<ui-view layout flex></ui-view>',
                redirectTo: 'app.person.entities.add.person.search',
            },
            {
                name: 'app.person.entities.add.person.search',
                url: '/search',
                template: '<ce-person-search flex></ce-person-search>',
                data: { title: 'Find Person' }
            },
            {
                name: 'app.person.entities.add.person.create',
                url: '/create',
                template: '<ce-create-person flex></ce-create-person>',
                data: { title: 'Create Person' }
            },
            {
                name: 'app.person.entities.add.person.roles',
                url: '/roles',
                params: { entity: null },
                resolve: { entity: ['$stateParams', function (p) { return p.entity; }] },
                template: '<ce-entity-relationships person="$resolve.person" related-entity="$resolve.entity" layout="column" flex></ce-entity-relationships>',
                data: { title: 'Select Relationships' }
            },
            {
                name: 'app.person.entities.add.agency',
                url: '/agency',
                template: '<ui-view layout flex></ui-view>',
                redirectTo: 'app.person.entities.add.agency.search',
            },
            {
                name: 'app.person.entities.add.agency.search',
                url: '/search',
                template: '<ce-agency-search flex></ce-agenyc-search>',
                data: { title: 'Find Agency' }
            },
            {
                name: 'app.person.entities.add.agency.create',
                url: '/create',
                template: '<ce-create-agency flex></ce-create-agency>',
                data: { title: 'Create Agency' }
            },
            {
                name: 'app.person.entities.add.agency.roles',
                url: '/roles',
                params: { entity: null },
                resolve: { entity: ['$stateParams', function (p) { return p.entity; }] },
                template: '<ce-entity-relationships person="$resolve.person" related-entity="$resolve.entity" layout="column" flex></ce-entity-relationships>',
                data: { title: 'Select Relationships' }
            },
            {
                name: 'app.person.entities.edit',
                url: '/edit',
                params: { association: null },
                resolve: { association: ['$stateParams', function (p) { return p.association; }] },
                template: '<ce-edit-entity-relationships association="$resolve.association" layout="column" flex></ce-edit-entity-relationships>',
                data: { title: 'Edit' }
            },
            {
                name: 'app.person.grievances',
                url: '/grievances',
                template: '<ce-grievance-listing person-id="$resolve.person.id" flex></ce-grievance-listing>',
                data: {
                    title: 'Person Grievances',
                    permissions: ['grievances.view']
                }
            }
        ];
        var runner = function (routingHelper) {
            'ngInject';
            routingHelper.configureRoutes(routes);
        };
        angular
            .module('app.persons')
            .run(runner);
    })(persons = app.persons || (app.persons = {}));
})(app || (app = {}));
var app;
(function (app) {
    var persons;
    (function (persons) {
        persons.AddressTypes = [
            'Home',
            'Mailing',
            'Work',
            'Homeless',
            'Other'
        ];
        persons.States = ('AL AK AZ AR CA CO CT DE FL GA HI ID IL IN IA KS KY LA ME MD MA MI MN MS ' +
            'MO MT NE NV NH NJ NM NY NC ND OH OK OR PA RI SC SD TN TX UT VT VA WA WV WI ' +
            'WY').split(' ').map(function (state) {
            return { abbrev: state };
        });
    })(persons = app.persons || (app.persons = {}));
})(app || (app = {}));
var app;
(function (app) {
    var monitoring;
    (function (monitoring) {
        'use strict';
        var baseUrl = 'api/monitoring';
        var MonitoringRepository = (function () {
            function MonitoringRepository($http, exception) {
                this.$http = $http;
                this.exception = exception;
            }
            MonitoringRepository.prototype.getMonitoringActivities = function (monitoringId) {
                return this.$http.get("".concat(baseUrl, "/").concat(monitoringId, "/activities"));
            };
            MonitoringRepository.prototype.close = function (activity) {
                return this.$http.post("".concat(baseUrl, "/").concat(activity.monitoringId, "/close"), activity);
            };
            MonitoringRepository.prototype.reopen = function (activity) {
                return this.$http.post("".concat(baseUrl, "/").concat(activity.monitoringId, "/reopen"), activity);
            };
            MonitoringRepository.prototype.createNote = function (activity) {
                return this.$http.post("".concat(baseUrl, "/").concat(activity.monitoringId, "/notes"), activity);
            };
            MonitoringRepository.prototype.updateNote = function (activity) {
                return this.$http.put("".concat(baseUrl, "/").concat(activity.monitoringId, "/notes/").concat(activity.id), activity);
            };
            MonitoringRepository.prototype.getMonitoringStatus = function (id) {
                return this.$http.get("".concat(baseUrl, "/").concat(id, "/status"));
            };
            MonitoringRepository.prototype.getMonitoringBasicInfoView = function (id) {
                return this.$http.get("".concat(baseUrl, "/").concat(id, "/basicinfo"));
            };
            MonitoringRepository.prototype.getMonitoringSummaryView = function (id) {
                return this.$http.get("".concat(baseUrl, "/").concat(id, "/summary"));
            };
            MonitoringRepository.prototype.getMonitoringInformation = function (id) {
                return this.$http.get("".concat(baseUrl, "/").concat(id, "/information"));
            };
            MonitoringRepository.prototype.updateMonitoringInformation = function (info) {
                return this.$http.post("".concat(baseUrl, "/").concat(info.id, "/information"), info);
            };
            MonitoringRepository.prototype.getClosureInformation = function (id) {
                return this.$http.get("".concat(baseUrl, "/").concat(id, "/closure"));
            };
            MonitoringRepository.prototype.updateClosureInformation = function (closure) {
                return this.$http.post("".concat(baseUrl, "/").concat(closure.id, "/closure"), closure);
            };
            MonitoringRepository.prototype.deleteMonitoring = function (id) {
                var _this = this;
                return this.$http.delete("".concat(baseUrl, "/").concat(id)).catch(function (error) { return _this.exception.catcher('Cannot Delete Case')(error); });
            };
            MonitoringRepository.prototype.createCaseReview = function (review) {
                return this.$http.post("".concat(baseUrl, "/").concat(review.caseId, "/caseReview"), review);
            };
            MonitoringRepository.prototype.updateCaseReview = function (review) {
                return this.$http.put("".concat(baseUrl, "/").concat(review.caseId, "/caseReview/").concat(review.id), review);
            };
            MonitoringRepository.prototype.getMonitoringList = function (data) {
                var url = "".concat(baseUrl, "/list");
                return this.$http.post(url, data);
            };
            MonitoringRepository.$inject = ['$http', 'exception'];
            __decorate([
                app.ApiExecutor()
            ], MonitoringRepository.prototype, "getMonitoringActivities", null);
            __decorate([
                app.ApiExecutor()
            ], MonitoringRepository.prototype, "close", null);
            __decorate([
                app.ApiExecutor()
            ], MonitoringRepository.prototype, "reopen", null);
            __decorate([
                app.ApiExecutor()
            ], MonitoringRepository.prototype, "createNote", null);
            __decorate([
                app.ApiExecutor()
            ], MonitoringRepository.prototype, "updateNote", null);
            __decorate([
                app.ApiExecutor()
            ], MonitoringRepository.prototype, "getMonitoringStatus", null);
            __decorate([
                app.ApiExecutor()
            ], MonitoringRepository.prototype, "getMonitoringBasicInfoView", null);
            __decorate([
                app.ApiExecutor()
            ], MonitoringRepository.prototype, "getMonitoringSummaryView", null);
            __decorate([
                app.ApiExecutor()
            ], MonitoringRepository.prototype, "getMonitoringInformation", null);
            __decorate([
                app.ApiExecutor()
            ], MonitoringRepository.prototype, "updateMonitoringInformation", null);
            __decorate([
                app.ApiExecutor()
            ], MonitoringRepository.prototype, "getClosureInformation", null);
            __decorate([
                app.ApiExecutor()
            ], MonitoringRepository.prototype, "updateClosureInformation", null);
            __decorate([
                app.ApiExecutor()
            ], MonitoringRepository.prototype, "createCaseReview", null);
            __decorate([
                app.ApiExecutor()
            ], MonitoringRepository.prototype, "updateCaseReview", null);
            __decorate([
                app.ApiExecutor()
            ], MonitoringRepository.prototype, "getMonitoringList", null);
            return MonitoringRepository;
        }());
        angular
            .module('app.monitoring')
            .service('monitoring.repository', MonitoringRepository);
    })(monitoring = app.monitoring || (app.monitoring = {}));
})(app || (app = {}));
var app;
(function (app) {
    var monitoring;
    (function (monitoring) {
        'use strict';
        monitoring.monitoringBasicInfoResolver = function (datacontext, $stateParams) {
            'ngInject';
            var monitoringId = $stateParams.monitoringId;
            return datacontext.monitoring.getMonitoringBasicInfoView(monitoringId);
        };
        monitoring.legalTeamsResolver = function (datacontext) {
            'ngInject';
            return datacontext.config.legalTeams.getLegalTeams(false);
        };
        monitoring.bypassCensusInfoResolver = function (datacontext) {
            'ngInject';
            var bypassCensusInfo = datacontext.config.getConfigurationParameter('Monitoring.BypassCensusInfo', false);
            return bypassCensusInfo.then(function (result) {
                return result.toString() === 'true';
            });
        };
    })(monitoring = app.monitoring || (app.monitoring = {}));
})(app || (app = {}));
var app;
(function (app) {
    var monitoring;
    (function (monitoring) {
        'use strict';
        var routes = [
            {
                name: 'app.monitoring',
                url: '/monitoring/{monitoringId}',
                template: '<ce-monitoring-view monitoring="$resolve.monitoring" layout="column" flex></ce-monitoring-view>',
                redirectTo: 'app.monitoring.overview',
                data: {
                    title: 'Monitoring View',
                    permissions: ['monitoring.view']
                },
                resolve: { monitoring: monitoring.monitoringBasicInfoResolver }
            },
            {
                name: 'app.monitoring.overview',
                url: '/overview',
                template: '<ce-monitoring-overview monitoring="$resolve.monitoring" layout="column" flex></ce-monitoring-overview>',
                data: {
                    title: 'Monitoring Overview',
                    permissions: ['monitoring.view']
                }
            },
            {
                name: 'app.monitoring.details',
                url: '/details',
                template: '<ce-monitoring-details monitoring="$resolve.monitoring" bypass-census="$resolve.bypassCensus" layout="column" flex></ce-monitoring-details>',
                data: {
                    title: 'Monitoring Details',
                    permissions: ['monitoring.view']
                },
                resolve: { bypassCensus: monitoring.bypassCensusInfoResolver }
            },
            {
                name: 'app.monitoring.involvement',
                url: '/involvement',
                template: '<ce-involvement-case-listing involvement-id="$resolve.monitoring.involvementId" flex></ce-involvement-case-listing>',
                data: { title: 'Monitoring Involvement' }
            },
            {
                name: 'app.monitoring.timeslipListing',
                url: '/timeslips',
                template: '<ce-timeslip-listing source="Monitoring" source-name="$resolve.monitoring.idNumber" source-name2="$resolve.monitoring.clientFullName" source-id="$resolve.monitoring.id" involvement-id="$resolve.monitoring.involvementId" enable-filters="false" flex layout="column"></ce-timeslip-listing>',
                data: {
                    title: 'Monitoring Timeslips',
                    permissions: ['timeslips.view']
                }
            },
            {
                name: 'app.monitoring.timeslipCalendar',
                url: '/timeslips-calendar',
                template: '<ce-timeslip-calendar-frame source="Monitoring" source-name="$resolve.monitoring.idNumber" source-name2="$resolve.monitoring.clientFullName" source-id="$resolve.monitoring.id" involvement-id="$resolve.monitoring.involvementId" enable-filters="false" flex layout="column"></ce-timeslip-calendar-frame>',
                data: {
                    title: 'Monitoring Timeslips',
                    permissions: ['timeslips.view']
                }
            },
            {
                name: 'app.monitoring.documents',
                url: '/documents',
                template: '<ce-documents-list involvement-id="$resolve.monitoring.involvementId" source="Monitoring" source-id="$resolve.monitoring.id" layout="column" flex></ce-document-list>',
                data: {
                    title: 'Monitoring Documents',
                    permissions: ['documents.view']
                }
            },
            {
                name: 'app.monitoring.uploadDocuments',
                url: '/upload',
                params: {
                    folderId: null
                },
                resolve: {
                    folderId: ['$stateParams', function (p) { return p.folderId; }],
                },
                template: '<ce-upload-documents-activity layout flex folder-id="$resolve.folderId" involvement-id="$resolve.monitoring.involvementId" source="Monitoring" source-id="$resolve.monitoring.id"></ce-upload-documents-activity>',
                data: { permissions: ['documents.edit'] }
            },
            {
                name: 'app.monitoring.emails',
                url: '/emails',
                template: '<ui-view flex layout="column"></ui-view>',
                redirectTo: 'app.monitoring.emails.listing',
                data: {
                    title: 'Monitoring Emails',
                    permissions: ['mail.view']
                }
            },
            {
                name: 'app.monitoring.emails.listing',
                url: '',
                template: '<ce-email-listing involvement-id="$resolve.monitoring.involvementId" source="Monitoring" source-id="$resolve.monitoring.id" layout="column" flex></ce-email-listing>',
                data: {
                    title: 'Monitoring Emails',
                    permissions: ['mail.view']
                }
            },
            {
                name: 'app.monitoring.emails.message',
                url: '/{messageId}',
                template: '<ce-email-view layout="column" flex message-id="$resolve.messageId"></ce-email-view>',
                data: {
                    title: 'Message',
                    permissions: ['mail.view']
                },
                resolve: {
                    messageId: ['$stateParams', function (p) { return p.messageId; }]
                }
            },
            {
                name: 'app.monitoring.emails.link',
                url: '/link',
                template: '<ce-link-emails layout="column" flex involvement-id="$resolve.monitoring.involvementId" source="Monitoring" source-id="$resolve.monitoring.id"></ce-link-emails>',
                data: {
                    title: 'Inbox Messages',
                    permissions: ['mail.link']
                }
            },
            {
                name: 'app.monitoring.calendar',
                url: '/calendar',
                template: '<ce-involvements-calendar source="Monitoring" source-id="$resolve.monitoring.id" involvement-id="$resolve.monitoring.involvementId" layout="column" flex></ce-involvements-calendar>',
                data: {
                    title: 'Calendar',
                }
            },
            {
                name: 'app.monitoring.eventSchedule',
                url: '/eventSchedule',
                template: "<ce-calendar-event-schedule\n                     event-schedule-id=\"$resolve.eventScheduleId\"\n                     target-date=\"$resolve.targetDate\"\n                     source=\"Monitoring\"\n                     source-name=\"$resolve.monitoring.idNumber\"\n                     source-id=\"$resolve.monitoring.id\"\n                     involvement-id=\"$resolve.monitoring.involvementId\"></ce-calendar-event-schedule>",
                params: {
                    eventScheduleId: null,
                    targetDate: null
                },
                resolve: {
                    eventScheduleId: ['$stateParams', function (p) { return p.eventScheduleId; }],
                    targetDate: ['$stateParams', function (p) { return p.targetDate; }],
                },
                data: { title: 'Event Schedule', permissions: ['events.view'], }
            },
            {
                name: 'app.monitoring.event',
                url: '/event',
                template: '<ce-calendar-event event="$resolve.event"></ce-calendar-event>',
                params: {
                    event: null
                },
                resolve: {
                    event: ['$stateParams', function (e) { return e.event; }]
                },
                data: {
                    title: 'Event',
                    permissions: ['events.view']
                }
            },
            {
                name: 'app.monitoring.closure',
                url: '/closure',
                template: '<ce-monitoring-closure monitoring="$resolve.monitoring" layout="column" flex></ce-monitoring-closure>',
                data: {
                    title: 'Monitoring Closure',
                    permissions: ['monitoring.view']
                }
            }
        ];
        var runner = function (routingHelper) {
            'ngInject';
            routingHelper.configureRoutes(routes);
        };
        angular
            .module('app.monitoring')
            .run(runner);
    })(monitoring = app.monitoring || (app.monitoring = {}));
})(app || (app = {}));
var app;
(function (app) {
    var preferences;
    (function (preferences) {
        var baseUrl = 'api/preferences';
        var PreferenceRepository = (function () {
            function PreferenceRepository($http) {
                this.$http = $http;
                'ngInject';
                'ngInject';
            }
            PreferenceRepository.prototype.getPreferences = function () {
                return this.$http.get(baseUrl);
            };
            PreferenceRepository.prototype.savePreferences = function (data) {
                return this.$http.put(baseUrl, data);
            };
            __decorate([
                app.ApiExecutor()
            ], PreferenceRepository.prototype, "getPreferences", null);
            __decorate([
                app.ApiExecutor({ returnValue: true })
            ], PreferenceRepository.prototype, "savePreferences", null);
            return PreferenceRepository;
        }());
        angular
            .module('app.preferences')
            .service('preferences.repository', PreferenceRepository);
    })(preferences = app.preferences || (app.preferences = {}));
})(app || (app = {}));
var app;
(function (app) {
    var preferences;
    (function (preferences) {
        var defaultState = {
            favorites: []
        };
        var PreferenceService = (function () {
            function PreferenceService(datacontext) {
                this.datacontext = datacontext;
                'ngInject';
                'ngInject';
                this.initialize();
            }
            Object.defineProperty(PreferenceService.prototype, "favorites", {
                get: function () {
                    return this.state.favorites;
                },
                enumerable: false,
                configurable: true
            });
            PreferenceService.prototype.initialize = function () {
                this.state = defaultState;
                this.loadPreferences();
            };
            PreferenceService.prototype.loadPreferences = function () {
                var _this = this;
                return this.datacontext.preferences
                    .getPreferences()
                    .then(function (result) {
                    if (result) {
                        _this.state = angular.extend({}, defaultState, result);
                    }
                    else {
                        _this.state = defaultState;
                    }
                });
            };
            PreferenceService.prototype.save = function () {
                this.datacontext.preferences
                    .savePreferences(this.state);
            };
            PreferenceService.$inject = ['datacontext'];
            PreferenceService = __decorate([
                app.Service('app.preferences', 'preferenceService')
            ], PreferenceService);
            return PreferenceService;
        }());
    })(preferences = app.preferences || (app.preferences = {}));
})(app || (app = {}));
var app;
(function (app) {
    var projects;
    (function (projects) {
        'use strict';
        var baseUrl = 'api/projects';
        var ProjectRepository = (function () {
            function ProjectRepository($http, exception) {
                this.$http = $http;
                this.exception = exception;
            }
            ProjectRepository.prototype.getEvents = function (params) {
                return this.$http.get("api/calendars/projectevents", { params: params });
            };
            ProjectRepository.prototype.getProjects = function () {
                return this.$http.get(baseUrl);
            };
            ProjectRepository.prototype.getProject = function (id) {
                return this.$http.get("".concat(baseUrl, "/").concat(id));
            };
            ProjectRepository.prototype.getProjectListing = function (params) {
                return this.$http.get(baseUrl, { params: params });
            };
            ProjectRepository.prototype.getProjectListingDownload = function (params) {
                var _this = this;
                return this.$http.get("".concat(baseUrl, "/csv"), { params: params, responseType: 'arraybuffer' }).then(function (response) {
                    return {
                        buffer: response.data,
                        contentType: response.headers()['content-type'],
                        fileName: response.headers()['content-disposition'].split(';')[1].split('=')[1].replace(/"/g, '')
                    };
                }).catch(function (error) { return _this.exception.catcher('XHR for getCsvData failed')(error); });
            };
            ProjectRepository.prototype.createProject = function (project) {
                return this.$http.post(baseUrl, project);
            };
            ProjectRepository.prototype.updateProject = function (project) {
                return this.$http.put("".concat(baseUrl, "/").concat(project.id), project);
            };
            ProjectRepository.prototype.getEntity = function (projectId, entityId) {
                return this.$http.get("".concat(baseUrl, "/").concat(projectId, "/entities/").concat(entityId));
            };
            ProjectRepository.prototype.getEntityListing = function (projectId) {
                return this.$http.get("".concat(baseUrl, "/").concat(projectId, "/entities"));
            };
            ProjectRepository.prototype.createEntity = function (entity) {
                return this.$http.post("".concat(baseUrl, "/entities"), entity);
            };
            ProjectRepository.prototype.updateEntity = function (entity) {
                return this.$http.put("".concat(baseUrl, "/entities"), entity);
            };
            ProjectRepository.prototype.deleteEntity = function (id) {
                return this.$http.delete("".concat(baseUrl, "/entities/").concat(id));
            };
            ProjectRepository.prototype.deleteProject = function (id) {
                var _this = this;
                return this.$http.delete("".concat(baseUrl, "/").concat(id)).catch(function (error) { return _this.exception.catcher('Cannot Delete Project')(error); });
            };
            ProjectRepository.prototype.createNote = function (activity) {
                return this.$http.post("".concat(baseUrl, "/").concat(activity.projectId, "/notes"), activity);
            };
            ProjectRepository.prototype.updateNote = function (activity) {
                return this.$http.put("".concat(baseUrl, "/").concat(activity.projectId, "/notes/").concat(activity.id), activity);
            };
            ProjectRepository.$inject = ['$http', 'exception'];
            __decorate([
                app.ApiExecutor()
            ], ProjectRepository.prototype, "getEvents", null);
            __decorate([
                app.ApiExecutor()
            ], ProjectRepository.prototype, "getProjects", null);
            __decorate([
                app.ApiExecutor()
            ], ProjectRepository.prototype, "getProject", null);
            __decorate([
                app.ApiExecutor()
            ], ProjectRepository.prototype, "getProjectListing", null);
            __decorate([
                app.ApiExecutor()
            ], ProjectRepository.prototype, "createProject", null);
            __decorate([
                app.ApiExecutor()
            ], ProjectRepository.prototype, "updateProject", null);
            __decorate([
                app.ApiExecutor()
            ], ProjectRepository.prototype, "getEntity", null);
            __decorate([
                app.ApiExecutor()
            ], ProjectRepository.prototype, "getEntityListing", null);
            __decorate([
                app.ApiExecutor()
            ], ProjectRepository.prototype, "createEntity", null);
            __decorate([
                app.ApiExecutor()
            ], ProjectRepository.prototype, "updateEntity", null);
            __decorate([
                app.ApiExecutor()
            ], ProjectRepository.prototype, "deleteEntity", null);
            __decorate([
                app.ApiExecutor()
            ], ProjectRepository.prototype, "createNote", null);
            __decorate([
                app.ApiExecutor()
            ], ProjectRepository.prototype, "updateNote", null);
            return ProjectRepository;
        }());
        angular
            .module('app.projects')
            .service('projects.repository', ProjectRepository);
    })(projects = app.projects || (app.projects = {}));
})(app || (app = {}));
var app;
(function (app) {
    var projects;
    (function (projects) {
        'use strict';
        projects.projectResolver = function (datacontext, $stateParams) {
            'ngInject';
            var projectId = $stateParams.projectId;
            return datacontext.projects.getProject(projectId);
        };
        projects.fundingProgramsResolver = function (datacontext) {
            'ngInject';
            return datacontext.funding.getFundingPrograms(true, 'Project');
        };
    })(projects = app.projects || (app.projects = {}));
})(app || (app = {}));
var app;
(function (app) {
    var projects;
    (function (projects) {
        'use strict';
        var routes = [
            {
                name: 'app.createProject',
                url: '/projects/create',
                template: '<ce-create-project layout="column" funding-programs="$resolve.fundingPrograms" flex></ce-create-project>',
                data: {
                    title: 'Create Project',
                    permissions: ['projects.edit']
                },
                resolve: {
                    fundingPrograms: projects.fundingProgramsResolver
                }
            },
            {
                name: 'app.projects',
                url: '/projects/{projectId}',
                template: '<ce-project-view layout flex project="$resolve.project"></ce-project-view>',
                redirectTo: 'app.projects.overview',
                data: { permissions: ['projects.view'] },
                resolve: { project: projects.projectResolver }
            },
            {
                name: 'app.projects.overview',
                url: '/overview',
                template: '<ce-project-overview project="$resolve.project" layout="column" flex></ce-project-overview>',
                data: {
                    title: 'Project Overview',
                    permissions: ['projects.view']
                }
            },
            {
                name: 'app.projects.details',
                url: '/details',
                template: '<ce-project-details data="$resolve.project" layout="column" flex funding-programs="$resolve.fundingPrograms"></ce-project-details>',
                data: {
                    title: 'Project Details',
                    permissions: ['projects.view']
                },
                resolve: {
                    fundingPrograms: projects.fundingProgramsResolver
                }
            },
            {
                name: 'app.projects.timeslipListing',
                url: '/timeslips',
                template: '<ce-timeslip-listing source="Project" source-id="$resolve.project.id" source-name="$resolve.project.name" funding-program-id="$resolve.project.fundingProgramId" enable-date-filter="false" layout="column" flex></ce-timeslip-listing>',
                data: {
                    title: 'Project Timeslips',
                    permissions: ['timeslips.view']
                }
            },
            {
                name: 'app.projects.timeslipCalendar',
                url: '/timeslips-calendar',
                template: '<ce-timeslip-calendar-frame source="Project" source-id="$resolve.project.id" source-name="$resolve.project.name" funding-program-id="$resolve.project.fundingProgramId" layout="column" flex></ce-timeslip-calendar-frame>',
                data: {
                    title: 'Project Timeslips',
                    permissions: ['timeslips.view']
                }
            },
            {
                name: 'app.projects.documents',
                url: '/documents',
                template: '<ce-documents-list source="Project" source-id="$resolve.project.id" layout="column" flex></ce-documents-list>',
                data: {
                    title: 'Project Documents',
                    permissions: ['documents.view']
                }
            },
            {
                name: 'app.projects.uploadDocuments',
                url: '/upload',
                params: {
                    folderId: null
                },
                resolve: {
                    folderId: ['$stateParams', function (p) { return p.folderId; }],
                },
                template: '<ce-upload-documents-activity layout flex folder-id="$resolve.folderId" source="Project" source-id="$resolve.project.id"></ce-upload-documents-activity>',
                data: { permissions: ['documents.edit'] }
            },
            {
                name: 'app.projects.emails',
                url: '/emails',
                template: '<ui-view flex layout="column"></ui-view>',
                redirectTo: 'app.projects.emails.listing',
                data: {
                    title: 'Project Emails',
                    permissions: ['mail.view']
                }
            },
            {
                name: 'app.projects.emails.listing',
                url: '',
                template: '<ce-email-listing source="Project" source-id="$resolve.project.id" layout="column" flex></ce-email-listing>',
                data: {
                    title: 'Project Emails',
                    permissions: ['mail.view']
                }
            },
            {
                name: 'app.projects.emails.message',
                url: '/{messageId}',
                template: '<ce-email-view layout="column" flex message-id="$resolve.messageId"></ce-email-view>',
                data: {
                    title: 'Message',
                    permissions: ['mail.view']
                },
                resolve: {
                    messageId: ['$stateParams', function (p) { return p.messageId; }]
                }
            },
            {
                name: 'app.projects.emails.link',
                url: '/link',
                template: '<ce-link-emails layout="column" flex source="Project" source-id="$resolve.project.id"></ce-link-emails>',
                data: {
                    title: 'Inbox Messages',
                    permissions: ['mail.link']
                }
            },
            {
                name: 'app.projects.calendar',
                url: '/calendar',
                template: '<ce-projects-calendar source="Project" source-id="$resolve.project.id" layout="column" flex></ce-projects-calendar>',
                data: {
                    title: 'Calendar',
                }
            },
            {
                name: 'app.projects.eventSchedule',
                url: '/eventSchedule',
                template: "<ce-calendar-event-schedule\n                     event-schedule-id=\"$resolve.eventScheduleId\"\n                     target-date=\"$resolve.targetDate\"\n                     source=\"Project\"\n                     source-name=\"$resolve.project.name\"\n                     source-id=\"$resolve.project.id\"></ce-calendar-event-schedule>",
                params: {
                    eventScheduleId: null,
                    targetDate: null
                },
                resolve: {
                    eventScheduleId: ['$stateParams', function (p) { return p.eventScheduleId; }],
                    targetDate: ['$stateParams', function (p) { return p.targetDate; }],
                },
                data: { title: 'Event Schedule', permissions: ['events.view'], }
            },
            {
                name: 'app.projects.event',
                url: '/event',
                template: '<ce-calendar-event event="$resolve.event"></ce-calendar-event>',
                params: {
                    event: null
                },
                resolve: {
                    event: ['$stateParams', function (e) { return e.event; }]
                },
                data: {
                    title: 'Event',
                    permissions: ['events.view']
                }
            },
        ];
        var runner = function (routingHelper) {
            'ngInject';
            routingHelper.configureRoutes(routes);
        };
        angular
            .module('app.projects')
            .run(runner);
    })(projects = app.projects || (app.projects = {}));
})(app || (app = {}));
var app;
(function (app) {
    var reports;
    (function (reports) {
        var baseUrl = 'api/reports';
        var ReportRepository = (function () {
            function ReportRepository($http) {
                this.$http = $http;
                'ngInject';
                'ngInject';
            }
            ReportRepository.prototype.runReport = function (data) {
                return this.$http.post(baseUrl, data, {
                    responseType: 'arraybuffer'
                });
            };
            __decorate([
                app.ApiExecutor()
            ], ReportRepository.prototype, "runReport", null);
            return ReportRepository;
        }());
        angular
            .module('app.reports')
            .service('reports.repository', ReportRepository);
    })(reports = app.reports || (app.reports = {}));
})(app || (app = {}));
var app;
(function (app) {
    var requests;
    (function (requests) {
        'use strict';
        var baseUrl = 'api/requests';
        var RequestRepository = (function () {
            function RequestRepository($http, exception) {
                this.$http = $http;
                this.exception = exception;
            }
            RequestRepository.prototype.getRequestBasicInfo = function (id) {
                return this.$http.get("".concat(baseUrl, "/").concat(id, "/basicinfo"));
            };
            RequestRepository.prototype.getActiveRequestCount = function (userIds) {
                return this.$http.get("".concat(baseUrl, "/activeRequestCount"), { params: { userIds: userIds } });
            };
            RequestRepository.prototype.getRequestSummary = function (id) {
                return this.$http.get("".concat(baseUrl, "/").concat(id, "/summary"));
            };
            RequestRepository.prototype.getRequestInformation = function (id) {
                return this.$http.get("".concat(baseUrl, "/").concat(id, "/information"));
            };
            RequestRepository.prototype.getRequestListing = function (params) {
                return this.$http.get(baseUrl, { params: params });
            };
            RequestRepository.prototype.getRequestListingDownload = function (params) {
                var _this = this;
                return this.$http.get("".concat(baseUrl, "/csv"), { params: params, responseType: 'arraybuffer' }).then(function (response) {
                    return {
                        buffer: response.data,
                        contentType: response.headers()['content-type'],
                        fileName: response.headers()['content-disposition'].split(';')[1].split('=')[1].replace(/"/g, '')
                    };
                }).catch(function (error) { return _this.exception.catcher('XHR for getCsvData failed')(error); });
            };
            RequestRepository.prototype.getRequestActivities = function (requestId) {
                return this.$http.get("".concat(baseUrl, "/").concat(requestId, "/activities"));
            };
            RequestRepository.prototype.getRequestToDo = function (id) {
                return this.$http.get("".concat(baseUrl, "/").concat(id, "/todo"));
            };
            RequestRepository.prototype.createRequest = function (request) {
                return this.$http.post(baseUrl, request);
            };
            RequestRepository.prototype.close = function (activity) {
                return this.$http.post("".concat(baseUrl, "/").concat(activity.requestId, "/close"), activity);
            };
            RequestRepository.prototype.reopen = function (activity) {
                return this.$http.post("".concat(baseUrl, "/").concat(activity.requestId, "/reopen"), activity);
            };
            RequestRepository.prototype.conflictCheck = function (activity) {
                return this.$http.post("".concat(baseUrl, "/").concat(activity.requestId, "/conflictCheck"), activity);
            };
            RequestRepository.prototype.createFollowUp = function (activity) {
                return this.$http.post("".concat(baseUrl, "/").concat(activity.requestId, "/followUp"), activity);
            };
            RequestRepository.prototype.updateFollowUp = function (activity) {
                return this.$http.put("".concat(baseUrl, "/").concat(activity.requestId, "/followUp/").concat(activity.id), activity);
            };
            RequestRepository.prototype.createReview = function (activity) {
                return this.$http.post("".concat(baseUrl, "/").concat(activity.requestId, "/review"), activity);
            };
            RequestRepository.prototype.updateReview = function (activity) {
                return this.$http.put("".concat(baseUrl, "/").concat(activity.requestId, "/review/").concat(activity.id), activity);
            };
            RequestRepository.prototype.createNote = function (activity) {
                return this.$http.post("".concat(baseUrl, "/").concat(activity.requestId, "/notes"), activity);
            };
            RequestRepository.prototype.updateNote = function (activity) {
                return this.$http.put("".concat(baseUrl, "/").concat(activity.requestId, "/notes/").concat(activity.id), activity);
            };
            RequestRepository.prototype.getRequestStatus = function (id) {
                return this.$http.get("".concat(baseUrl, "/").concat(id, "/status"));
            };
            RequestRepository.prototype.updateRequestInformation = function (info) {
                return this.$http.post("".concat(baseUrl, "/").concat(info.id, "/information"), info);
            };
            RequestRepository.prototype.deleteRequest = function (id) {
                var _this = this;
                return this.$http.delete("".concat(baseUrl, "/").concat(id)).catch(function (error) { return _this.exception.catcher('Cannot Delete Request')(error); });
            };
            RequestRepository.$inject = ['$http', 'exception'];
            __decorate([
                app.ApiExecutor()
            ], RequestRepository.prototype, "getRequestBasicInfo", null);
            __decorate([
                app.ApiExecutor()
            ], RequestRepository.prototype, "getActiveRequestCount", null);
            __decorate([
                app.ApiExecutor()
            ], RequestRepository.prototype, "getRequestSummary", null);
            __decorate([
                app.ApiExecutor()
            ], RequestRepository.prototype, "getRequestInformation", null);
            __decorate([
                app.ApiExecutor()
            ], RequestRepository.prototype, "getRequestListing", null);
            __decorate([
                app.ApiExecutor()
            ], RequestRepository.prototype, "getRequestActivities", null);
            __decorate([
                app.ApiExecutor()
            ], RequestRepository.prototype, "getRequestToDo", null);
            __decorate([
                app.ApiExecutor()
            ], RequestRepository.prototype, "createRequest", null);
            __decorate([
                app.ApiExecutor()
            ], RequestRepository.prototype, "close", null);
            __decorate([
                app.ApiExecutor()
            ], RequestRepository.prototype, "reopen", null);
            __decorate([
                app.ApiExecutor()
            ], RequestRepository.prototype, "conflictCheck", null);
            __decorate([
                app.ApiExecutor()
            ], RequestRepository.prototype, "createFollowUp", null);
            __decorate([
                app.ApiExecutor()
            ], RequestRepository.prototype, "updateFollowUp", null);
            __decorate([
                app.ApiExecutor()
            ], RequestRepository.prototype, "createReview", null);
            __decorate([
                app.ApiExecutor()
            ], RequestRepository.prototype, "updateReview", null);
            __decorate([
                app.ApiExecutor()
            ], RequestRepository.prototype, "createNote", null);
            __decorate([
                app.ApiExecutor()
            ], RequestRepository.prototype, "updateNote", null);
            __decorate([
                app.ApiExecutor()
            ], RequestRepository.prototype, "getRequestStatus", null);
            __decorate([
                app.ApiExecutor()
            ], RequestRepository.prototype, "updateRequestInformation", null);
            return RequestRepository;
        }());
        angular
            .module('app.requests')
            .service('requests.repository', RequestRepository);
    })(requests = app.requests || (app.requests = {}));
})(app || (app = {}));
var app;
(function (app) {
    var requests;
    (function (requests) {
        'use strict';
        requests.requestBasicInfoResolver = function (datacontext, $stateParams) {
            'ngInject';
            var requestId = $stateParams.requestId;
            return datacontext.requests.getRequestBasicInfo(requestId);
        };
    })(requests = app.requests || (app.requests = {}));
})(app || (app = {}));
var app;
(function (app) {
    var requests;
    (function (requests) {
        'use strict';
        var routes = [
            {
                name: 'app.createRequest',
                url: '/requests/create',
                template: '<ce-requests-create-request layout="column" flex></ce-requests-create-request>',
                data: {
                    title: 'Create Request',
                    permissions: ['requests.edit']
                }
            },
            {
                name: 'app.requests',
                url: '/requests/{requestId}',
                template: '<ce-request-view request="$resolve.request" layout="column" flex></ce-request-view>',
                redirectTo: 'app.requests.overview',
                data: {
                    title: 'Request View',
                    permissions: ['requests.view']
                },
                resolve: { request: requests.requestBasicInfoResolver }
            },
            {
                name: 'app.requests.overview',
                url: '/overview',
                template: '<ce-request-overview request="$resolve.request" layout="column" flex></ce-request-overview>',
                data: { title: 'Request Overview', permissions: ['requests.view'], }
            },
            {
                name: 'app.requests.details',
                url: '/details',
                template: '<ce-request-details request="$resolve.request" layout="column" flex></ce-request-details>',
                data: {
                    title: 'Request Details', permissions: ['requests.view']
                },
            },
            {
                name: 'app.requests.timeslipListing',
                url: '/timeslips',
                template: '<ce-timeslip-listing involvement-id="$resolve.request.involvementId" source="Request" source-id="$resolve.request.id" source-name="$resolve.request.idNumber" source-name2="$resolve.request.requesterFullName"  enable-filters="false" layout="column" flex></ce-timeslip-listing>',
                data: {
                    title: 'Request Timeslips',
                    permissions: ['timeslips.view']
                }
            },
            {
                name: 'app.requests.timeslipCalendar',
                url: '/timeslips-calendar',
                template: '<ce-timeslip-calendar-frame involvement-id="$resolve.request.involvementId" source="Request" source-id="$resolve.request.id" source-name="$resolve.request.idNumber" source-name2="$resolve.request.requesterFullName"  enable-filters="false" layout="column" flex></ce-timeslip-calendar-frame>',
                data: {
                    title: 'Request Timeslips',
                    permissions: ['timeslips.view']
                }
            },
            {
                name: 'app.requests.documents',
                url: '/documents',
                template: '<ce-documents-list involvement-id="$resolve.request.involvementId" source="Request" source-id="$resolve.request.id" layout="column" flex></ce-document-list>',
                data: {
                    title: 'Request Documents',
                    permissions: ['documents.view']
                }
            },
            {
                name: 'app.requests.uploadDocuments',
                url: '/upload',
                params: {
                    folderId: null
                },
                resolve: {
                    folderId: ['$stateParams', function (p) { return p.folderId; }],
                },
                template: '<ce-upload-documents-activity layout flex folder-id="$resolve.folderId" involvement-id="$resolve.request.involvementId" source="Request" source-id="$resolve.request.id"></ce-upload-documents-activity>',
                data: { permissions: ['documents.edit'] }
            },
            {
                name: 'app.requests.emails',
                url: '/emails',
                template: '<ui-view flex layout="column"></ui-view>',
                redirectTo: 'app.requests.emails.listing',
                data: {
                    title: 'Requests Emails',
                    permissions: ['mail.view']
                },
            },
            {
                name: 'app.requests.emails.listing',
                url: '',
                template: '<ce-email-listing involvement-id="$resolve.request.involvementId" source="Request" source-id="$resolve.request.id" layout="column" flex></ce-email-listing>',
                data: {
                    title: 'Request Emails',
                    permissions: ['mail.view']
                }
            },
            {
                name: 'app.requests.emails.message',
                url: '/{messageId}',
                template: '<ce-email-view layout="column" flex message-id="$resolve.messageId"></ce-email-view>',
                data: {
                    title: 'Message',
                    permissions: ['mail.view']
                },
                resolve: {
                    messageId: ['$stateParams', function (p) { return p.messageId; }]
                }
            },
            {
                name: 'app.requests.emails.link',
                url: '/link',
                template: '<ce-link-emails layout="column" flex involvement-id="$resolve.request.involvementId" source="Request" source-id="$resolve.request.id"></ce-link-emails>',
                data: {
                    title: 'Inbox Messages',
                    permissions: ['mail.link']
                }
            },
            {
                name: 'app.requests.involvement',
                url: '/involvement',
                template: '<ce-involvement-case-listing involvement-id="$resolve.request.involvementId" flex></ce-involvement-case-listing>',
                data: { title: 'Request Involvement' }
            },
            {
                name: 'app.requestReviewListing',
                url: '/requestReviewListing',
                template: '<ce-request-review-listing layout="column" flex></ce-request-review-listing>',
                data: { title: 'Request Listing' }
            },
            {
                name: 'app.requestReviewCompletedListing',
                url: '/requestListing',
                template: '<ce-request-review-completed-listing layout="column" flex></ce-request-review-completed-listing>',
                data: { title: 'Request Listing' }
            }
        ];
        var runner = function (routingHelper) {
            'ngInject';
            routingHelper.configureRoutes(routes);
        };
        angular
            .module('app.requests')
            .run(runner);
    })(requests = app.requests || (app.requests = {}));
})(app || (app = {}));
var app;
(function (app) {
    var search;
    (function (search) {
        var routes = [
            {
                name: 'app.search',
                url: '/search',
                template: '<ce-advanced-search layout="column" flex></ce-advanced-search>',
                data: { title: 'Advanced Search' }
            }
        ];
        var runner = function (routingHelper) {
            'ngInject';
            routingHelper.configureRoutes(routes);
        };
        angular
            .module('app.search')
            .run(runner);
    })(search = app.search || (app.search = {}));
})(app || (app = {}));
var app;
(function (app) {
    var systemicCases;
    (function (systemicCases) {
        'use strict';
        var baseUrl = 'api/systemicCases';
        var SystemicCaseRepository = (function () {
            function SystemicCaseRepository($http, exception) {
                this.$http = $http;
                this.exception = exception;
            }
            SystemicCaseRepository.prototype.getSystemicCaseListing = function (params) {
                return this.$http.get(baseUrl, { params: params });
            };
            SystemicCaseRepository.prototype.getSystemicCaseListingDownload = function (params) {
                var _this = this;
                return this.$http.get("".concat(baseUrl, "/csv"), { params: params, responseType: 'arraybuffer' }).then(function (response) {
                    return {
                        buffer: response.data,
                        contentType: response.headers()['content-type'],
                        fileName: response.headers()['content-disposition'].split(';')[1].split('=')[1].replace(/"/g, '')
                    };
                }).catch(function (error) { return _this.exception.catcher('XHR for getCsvData failed')(error); });
            };
            SystemicCaseRepository.prototype.getSystemicCaseActivities = function (caseId) {
                return this.$http.get("".concat(baseUrl, "/").concat(caseId, "/activities"));
            };
            SystemicCaseRepository.prototype.createSystemicCase = function (request) {
                return this.$http.post(baseUrl, request);
            };
            SystemicCaseRepository.prototype.close = function (activity) {
                return this.$http.post("".concat(baseUrl, "/").concat(activity.caseId, "/close"), activity);
            };
            SystemicCaseRepository.prototype.reopen = function (activity) {
                return this.$http.post("".concat(baseUrl, "/").concat(activity.caseId, "/reopen"), activity);
            };
            SystemicCaseRepository.prototype.conflictCheck = function (activity) {
                return this.$http.post("".concat(baseUrl, "/").concat(activity.caseId, "/conflictCheck"), activity);
            };
            SystemicCaseRepository.prototype.followUp = function (activity) {
                return this.$http.post("".concat(baseUrl, "/").concat(activity.caseId, "/followUp"), activity);
            };
            SystemicCaseRepository.prototype.getSystemicCaseBasicInfoView = function (id) {
                return this.$http.get("".concat(baseUrl, "/").concat(id, "/basicinfo"));
            };
            SystemicCaseRepository.prototype.createNote = function (activity) {
                return this.$http.post("".concat(baseUrl, "/").concat(activity.caseId, "/notes"), activity);
            };
            SystemicCaseRepository.prototype.updateNote = function (activity) {
                return this.$http.put("".concat(baseUrl, "/").concat(activity.caseId, "/notes/").concat(activity.id), activity);
            };
            SystemicCaseRepository.prototype.getSystemicCaseStatus = function (id) {
                return this.$http.get("".concat(baseUrl, "/").concat(id, "/status"));
            };
            SystemicCaseRepository.prototype.getSystemicCaseSummaryView = function (id) {
                return this.$http.get("".concat(baseUrl, "/").concat(id, "/summary"));
            };
            SystemicCaseRepository.prototype.getSystemicCaseToDo = function (id, stage) {
                return this.$http.get("".concat(baseUrl, "/").concat(id, "/todo/").concat(stage || ''));
            };
            SystemicCaseRepository.prototype.getSystemicCaseInformation = function (id) {
                return this.$http.get("".concat(baseUrl, "/").concat(id, "/information"));
            };
            SystemicCaseRepository.prototype.updateSystemicCaseInformation = function (info) {
                return this.$http.post("".concat(baseUrl, "/").concat(info.id, "/information"), info);
            };
            SystemicCaseRepository.prototype.getClosureInformation = function (id) {
                return this.$http.get("".concat(baseUrl, "/").concat(id, "/closure"));
            };
            SystemicCaseRepository.prototype.updateClosureInformation = function (closure) {
                return this.$http.post("".concat(baseUrl, "/").concat(closure.id, "/closure"), closure);
            };
            SystemicCaseRepository.prototype.createCaseReview = function (review) {
                return this.$http.post("".concat(baseUrl, "/").concat(review.caseId, "/caseReview"), review);
            };
            SystemicCaseRepository.prototype.updateCaseReview = function (review) {
                return this.$http.put("".concat(baseUrl, "/").concat(review.caseId, "/caseReview/").concat(review.id), review);
            };
            SystemicCaseRepository.prototype.getActiveCaseCount = function (userIds) {
                return this.$http.get("".concat(baseUrl, "/activeCaseCount"), { params: { userIds: userIds } });
            };
            SystemicCaseRepository.prototype.deleteSystemicCase = function (id) {
                var _this = this;
                return this.$http.delete("".concat(baseUrl, "/").concat(id)).catch(function (error) { return _this.exception.catcher('Cannot Delete Case')(error); });
            };
            SystemicCaseRepository.$inject = ['$http', 'exception'];
            __decorate([
                app.ApiExecutor()
            ], SystemicCaseRepository.prototype, "getSystemicCaseListing", null);
            __decorate([
                app.ApiExecutor()
            ], SystemicCaseRepository.prototype, "getSystemicCaseActivities", null);
            __decorate([
                app.ApiExecutor()
            ], SystemicCaseRepository.prototype, "createSystemicCase", null);
            __decorate([
                app.ApiExecutor()
            ], SystemicCaseRepository.prototype, "close", null);
            __decorate([
                app.ApiExecutor()
            ], SystemicCaseRepository.prototype, "reopen", null);
            __decorate([
                app.ApiExecutor()
            ], SystemicCaseRepository.prototype, "conflictCheck", null);
            __decorate([
                app.ApiExecutor()
            ], SystemicCaseRepository.prototype, "followUp", null);
            __decorate([
                app.ApiExecutor()
            ], SystemicCaseRepository.prototype, "getSystemicCaseBasicInfoView", null);
            __decorate([
                app.ApiExecutor()
            ], SystemicCaseRepository.prototype, "createNote", null);
            __decorate([
                app.ApiExecutor()
            ], SystemicCaseRepository.prototype, "updateNote", null);
            __decorate([
                app.ApiExecutor()
            ], SystemicCaseRepository.prototype, "getSystemicCaseStatus", null);
            __decorate([
                app.ApiExecutor()
            ], SystemicCaseRepository.prototype, "getSystemicCaseSummaryView", null);
            __decorate([
                app.ApiExecutor()
            ], SystemicCaseRepository.prototype, "getSystemicCaseToDo", null);
            __decorate([
                app.ApiExecutor()
            ], SystemicCaseRepository.prototype, "getSystemicCaseInformation", null);
            __decorate([
                app.ApiExecutor()
            ], SystemicCaseRepository.prototype, "updateSystemicCaseInformation", null);
            __decorate([
                app.ApiExecutor()
            ], SystemicCaseRepository.prototype, "getClosureInformation", null);
            __decorate([
                app.ApiExecutor()
            ], SystemicCaseRepository.prototype, "updateClosureInformation", null);
            __decorate([
                app.ApiExecutor()
            ], SystemicCaseRepository.prototype, "createCaseReview", null);
            __decorate([
                app.ApiExecutor()
            ], SystemicCaseRepository.prototype, "updateCaseReview", null);
            __decorate([
                app.ApiExecutor()
            ], SystemicCaseRepository.prototype, "getActiveCaseCount", null);
            return SystemicCaseRepository;
        }());
        angular
            .module('app.systemicCases')
            .service('systemicCases.repository', SystemicCaseRepository);
    })(systemicCases = app.systemicCases || (app.systemicCases = {}));
})(app || (app = {}));
var app;
(function (app) {
    var systemicCases;
    (function (systemicCases) {
        'use strict';
        systemicCases.caseBasicInfoResolver = function (datacontext, $stateParams) {
            'ngInject';
            var systemicCaseId = $stateParams.systemicCaseId;
            return datacontext.systemicCases.getSystemicCaseBasicInfoView(systemicCaseId);
        };
        systemicCases.legalTeamsResolver = function (datacontext) {
            'ngInject';
            return datacontext.config.legalTeams.getLegalTeams(false);
        };
    })(systemicCases = app.systemicCases || (app.systemicCases = {}));
})(app || (app = {}));
var app;
(function (app) {
    var systemicCases;
    (function (systemicCases) {
        'use strict';
        var routes = [
            {
                name: 'app.createSystemicCase',
                url: '/systemic-cases/create',
                template: '<ce-create-systemic-case layout="column" flex legalTeams="$resolve.legalTeams"></ce-create-systemic-case>',
                data: {
                    title: 'Create Systemic Case',
                    permissions: ['systemic-cases.edit']
                }
            },
            {
                name: 'app.systemicCaseListing',
                url: '/systemic-cases',
                template: '<ce-systemic-case-listing layout="column" flex users="$resolve.users"></ce-systemic-case-listing > ',
                data: {
                    title: 'Systemic Case Listing',
                    permissions: ['systemic-cases.view']
                },
                params: { users: null },
                resolve: {
                    users: ['$stateParams', function (p) { return p.users; }]
                }
            },
            {
                name: 'app.systemicCases',
                url: '/systemic-cases/{systemicCaseId}',
                template: '<ce-systemic-case-view case="$resolve.case" layout="column" flex></ce-systemic-case-view>',
                redirectTo: 'app.systemicCases.overview',
                data: {
                    title: 'Systemic Case View',
                    permissions: ['systemic-cases.view']
                },
                resolve: { case: systemicCases.caseBasicInfoResolver }
            },
            {
                name: 'app.systemicCases.overview',
                url: '/overview',
                template: '<ce-systemic-case-overview case="$resolve.case" layout="column" flex></ce-systemic-case-overview>',
                data: {
                    title: 'Systemic Case Overview',
                    permissions: ['systemic-cases.view']
                }
            },
            {
                name: 'app.systemicCases.details',
                url: '/details',
                template: '<ce-systemic-case-details case="$resolve.case" layout="column" flex></ce-systemic-case-details>',
                data: {
                    title: 'Systemic Case Overview',
                    permissions: ['systemic-cases.view']
                }
            },
            {
                name: 'app.systemicCases.involvement',
                url: '/involvement',
                template: '<ce-involvement-case-listing involvement-id="$resolve.case.involvementId" flex></ce-involvement-case-listing>',
                data: { title: 'Systemic Case Involvement' }
            },
            {
                name: 'app.systemicCases.documents',
                url: '/documents',
                template: '<ce-documents-list involvement-id="$resolve.case.involvementId" source="Systemic Case" source-id="$resolve.case.id" layout="column" flex></ce-document-list>',
                data: {
                    title: 'Systemic Case Documents',
                    permissions: ['documents.view']
                }
            },
            {
                name: 'app.systemicCases.uploadDocuments',
                url: '/upload',
                params: {
                    folderId: null
                },
                resolve: {
                    folderId: ['$stateParams', function (p) { return p.folderId; }],
                },
                template: '<ce-upload-documents-activity layout flex folder-id="$resolve.folderId" involvement-id="$resolve.case.involvementId" source="Systemic Case" source-id="$resolve.case.id"></ce-upload-documents-activity>',
                data: { permissions: ['documents.edit'] }
            },
            {
                name: 'app.systemicCases.emails',
                url: '/emails',
                template: '<ui-view flex layout="column"></ui-view>',
                redirectTo: 'app.systemicCases.emails.listing',
                data: {
                    title: 'Systemic Case Emails',
                    permissions: ['mail.view']
                }
            },
            {
                name: 'app.systemicCases.emails.listing',
                url: '',
                template: '<ce-email-listing involvement-id="$resolve.case.involvementId" source="Systemic Case" source-id="$resolve.case.id" layout="column" flex></ce-email-listing>',
                data: {
                    title: 'Systemic Case Emails',
                    permissions: ['mail.view']
                }
            },
            {
                name: 'app.systemicCases.emails.message',
                url: '/{messageId}',
                template: '<ce-email-view layout="column" flex message-id="$resolve.messageId"></ce-email-view>',
                data: {
                    title: 'Message',
                    permissions: ['mail.view']
                },
                resolve: {
                    messageId: ['$stateParams', function (p) { return p.messageId; }]
                }
            },
            {
                name: 'app.systemicCases.emails.link',
                url: '/link',
                template: '<ce-link-emails layout="column" flex involvement-id="$resolve.case.involvementId" source="Systemic Case" source-id="$resolve.case.id"></ce-link-emails>',
                data: {
                    title: 'Inbox Messages',
                    permissions: ['mail.link']
                }
            },
            {
                name: 'app.systemicCases.timeslipListing',
                url: '/timeslips',
                template: '<ce-timeslip-listing source="Systemic Case" source-name="$resolve.case.idNumber" source-name2="$resolve.case.name" source-id="$resolve.case.id" involvement-id="$resolve.case.involvementId" enable-filters="false" flex layout="column"></ce-timeslip-listing>',
                data: {
                    title: 'Systemic Case Timeslips',
                    permissions: ['timeslips.view']
                }
            },
            {
                name: 'app.systemicCases.timeslipCalendar',
                url: '/timeslips-calendar',
                template: '<ce-timeslip-calendar-frame source="Systemic Case" source-name="$resolve.case.idNumber" source-name2="$resolve.case.name" source-id="$resolve.case.id" involvement-id="$resolve.case.involvementId" enable-filters="false" flex layout="column"></ce-timeslip-calendar-frame>',
                data: {
                    title: 'Systemic Case Timeslips',
                    permissions: ['timeslips.view']
                }
            },
            {
                name: 'app.systemicCases.closure',
                url: '/closure',
                template: '<ce-systemic-case-closure case="$resolve.case" layout="column" flex></ce-systemic-case-closure>',
                data: {
                    title: 'Systemic Case Closure',
                    permissions: ['systemic-cases.view']
                }
            },
            {
                name: 'app.systemicCases.grievances',
                url: '/grievances',
                template: '<ce-grievance-listing case="$resolve.case" case-type="Systemic Case" flex></ce-grievance-listing>',
                data: {
                    title: 'Systemic Case Grievances',
                    permissions: ['grievances.view']
                }
            },
            {
                name: 'app.systemicCases.calendar',
                url: '/calendar',
                template: '<ce-involvements-calendar source="Systemic Case" source-id="$resolve.case.id" involvement-id="$resolve.case.involvementId" layout="column" flex></ce-involvements-calendar>',
                data: {
                    title: 'Calendar',
                }
            },
            {
                name: 'app.systemicCases.eventSchedule',
                url: '/eventSchedule',
                template: "<ce-calendar-event-schedule\n                     event-schedule-id=\"$resolve.eventScheduleId\"\n                     target-date=\"$resolve.targetDate\"\n                     source=\"Systemic Case\"\n                     source-name=\"$resolve.case.idNumber\"\n                     source-id=\"$resolve.case.id\"\n                     involvement-id=\"$resolve.case.involvementId\"></ce-calendar-event-schedule>",
                params: {
                    eventScheduleId: null,
                    targetDate: null
                },
                resolve: {
                    eventScheduleId: ['$stateParams', function (p) { return p.eventScheduleId; }],
                    targetDate: ['$stateParams', function (p) { return p.targetDate; }],
                },
                data: { title: 'Event Schedule', permissions: ['events.view'], }
            },
            {
                name: 'app.systemicCases.event',
                url: '/event',
                template: '<ce-calendar-event event="$resolve.event"></ce-calendar-event>',
                params: {
                    event: null
                },
                resolve: {
                    event: ['$stateParams', function (e) { return e.event; }]
                },
                data: {
                    title: 'Event',
                    permissions: ['events.view']
                }
            }
        ];
        var runner = function (routingHelper) {
            'ngInject';
            routingHelper.configureRoutes(routes);
        };
        angular
            .module('app.systemicCases')
            .run(runner);
    })(systemicCases = app.systemicCases || (app.systemicCases = {}));
})(app || (app = {}));
var app;
(function (app) {
    var time;
    (function (time) {
        'use strict';
        var baseUrl = 'api/time';
        var TimeRepository = (function () {
            function TimeRepository($http, exception) {
                this.$http = $http;
                this.exception = exception;
            }
            TimeRepository.prototype.getCurrentTotal = function (userId, date) {
                return this.$http.get("".concat(baseUrl, "/currentTotal"), { params: { userId: userId, date: date } });
            };
            TimeRepository.prototype.getTimeslip = function (id) {
                return this.$http.get("".concat(baseUrl, "/slips/").concat(id));
            };
            TimeRepository.prototype.getSources = function (q) {
                return this.$http.get("".concat(baseUrl, "/sources"), { params: { q: q } });
            };
            TimeRepository.prototype.getTimeslipCalendarItems = function (params) {
                var result = this.$http.post("".concat(baseUrl, "/calendaritems"), params);
                return result;
            };
            TimeRepository.prototype.getTimeslipListing = function (params) {
                var result = this.$http.get("".concat(baseUrl, "/slips"), { params: params });
                return result;
            };
            TimeRepository.prototype.getTimeslipListingDownload = function (params) {
                var _this = this;
                return this.$http.get("".concat(baseUrl, "/csv"), { params: params, responseType: 'arraybuffer' }).then(function (response) {
                    return {
                        buffer: response.data,
                        contentType: response.headers()['content-type'],
                        fileName: response.headers()['content-disposition'].split(';')[1].split('=')[1].replace(/"/g, '')
                    };
                }).catch(function (error) { return _this.exception.catcher('XHR for getCsvData failed')(error); });
            };
            TimeRepository.prototype.createTimeslip = function (timeslip) {
                return this.$http.post("".concat(baseUrl, "/slip"), timeslip);
            };
            TimeRepository.prototype.updateTimeslip = function (timeslip) {
                return this.$http.put("".concat(baseUrl, "/slips/").concat(timeslip.id), timeslip);
            };
            TimeRepository.prototype.deleteTimeslip = function (id) {
                return this.$http.delete("".concat(baseUrl, "/slips/").concat(id));
            };
            TimeRepository.prototype.getTasks = function (q) {
                return this.$http.get("".concat(baseUrl, "/tasks"), { params: { q: q, activeOnly: true } });
            };
            TimeRepository.prototype.lockTimeslips = function (data) {
                return this.$http.post("".concat(baseUrl, "/slips/lock"), data);
            };
            TimeRepository.prototype.getLastLock = function () {
                return this.$http.get("".concat(baseUrl, "/slips/last-lock"));
            };
            TimeRepository.prototype.getTimeslipsPerPayPeriod = function (userIds) {
                return this.$http.get("".concat(baseUrl, "/slipsPerPayPeriod"), { params: { userIds: userIds } });
            };
            TimeRepository.prototype.getTimeslipsPerWorkWeek = function (userIds) {
                return this.$http.get("".concat(baseUrl, "/slipsPerWorkWeek"), { params: { userIds: userIds } });
            };
            TimeRepository.$inject = ['$http', 'exception'];
            __decorate([
                app.ApiExecutor()
            ], TimeRepository.prototype, "getCurrentTotal", null);
            __decorate([
                app.ApiExecutor()
            ], TimeRepository.prototype, "getTimeslip", null);
            __decorate([
                app.ApiExecutor()
            ], TimeRepository.prototype, "getSources", null);
            __decorate([
                app.ApiExecutor()
            ], TimeRepository.prototype, "getTimeslipCalendarItems", null);
            __decorate([
                app.ApiExecutor()
            ], TimeRepository.prototype, "getTimeslipListing", null);
            __decorate([
                app.ApiExecutor()
            ], TimeRepository.prototype, "createTimeslip", null);
            __decorate([
                app.ApiExecutor()
            ], TimeRepository.prototype, "updateTimeslip", null);
            __decorate([
                app.ApiExecutor()
            ], TimeRepository.prototype, "deleteTimeslip", null);
            __decorate([
                app.ApiExecutor()
            ], TimeRepository.prototype, "getTasks", null);
            __decorate([
                app.ApiExecutor()
            ], TimeRepository.prototype, "lockTimeslips", null);
            __decorate([
                app.ApiExecutor()
            ], TimeRepository.prototype, "getLastLock", null);
            __decorate([
                app.ApiExecutor()
            ], TimeRepository.prototype, "getTimeslipsPerPayPeriod", null);
            __decorate([
                app.ApiExecutor()
            ], TimeRepository.prototype, "getTimeslipsPerWorkWeek", null);
            return TimeRepository;
        }());
        angular
            .module('app.time')
            .service('time.repository', TimeRepository);
    })(time = app.time || (app.time = {}));
})(app || (app = {}));
var app;
(function (app) {
    var time;
    (function (time) {
        'use strict';
        var routes = [
            {
                name: 'app.timeslipCalendar',
                url: '/timeslips-calendar',
                template: '<ce-timeslip-calendar-frame layout="column" flex></ce-timeslip-calendar-frame>',
                data: {
                    title: 'Timeslip Calendar',
                    permissions: ['timeslips.view']
                }
            }
        ];
        var runner = function (routingHelper) {
            'ngInject';
            routingHelper.configureRoutes(routes);
        };
        angular
            .module('app.time')
            .run(runner);
    })(time = app.time || (app.time = {}));
})(app || (app = {}));
var app;
(function (app) {
    var training;
    (function (training_4) {
        'use strict';
        var baseUrl = 'api/trainings';
        var TrainingRepository = (function () {
            function TrainingRepository($http, exception) {
                this.$http = $http;
                this.exception = exception;
            }
            TrainingRepository.prototype.getCLEEvents = function (params) {
                return this.$http.get("api/calendars/cleevents", { params: params });
            };
            TrainingRepository.prototype.getTrainingEvents = function (params) {
                return this.$http.get("api/calendars/trainingevents", { params: params });
            };
            TrainingRepository.prototype.getCLEListing = function (params) {
                return this.$http.get("".concat(baseUrl, "/cle"), { params: params });
            };
            TrainingRepository.prototype.getCLEListingDownload = function (params) {
                var _this = this;
                return this.$http.get("".concat(baseUrl, "/cle/csv"), { params: params, responseType: 'arraybuffer' }).then(function (response) {
                    return {
                        buffer: response.data,
                        contentType: response.headers()['content-type'],
                        fileName: response.headers()['content-disposition'].split(';')[1].split('=')[1].replace(/"/g, '')
                    };
                }).catch(function (error) { return _this.exception.catcher('XHR for getCsvData failed')(error); });
            };
            TrainingRepository.prototype.getCLEInformation = function (id) {
                return this.$http.get("".concat(baseUrl, "/cle/").concat(id));
            };
            TrainingRepository.prototype.updateCLEInformation = function (cle) {
                return this.$http.put("".concat(baseUrl, "/cle/").concat(cle.id), cle);
            };
            TrainingRepository.prototype.createCLE = function (cle) {
                return this.$http.post("".concat(baseUrl, "/cle"), cle);
            };
            TrainingRepository.prototype.getRequestListing = function (params) {
                return this.$http.get("".concat(baseUrl, "/request"), { params: params });
            };
            TrainingRepository.prototype.getRequestListingDownload = function (params) {
                var _this = this;
                return this.$http.get("".concat(baseUrl, "/request/csv"), { params: params, responseType: 'arraybuffer' }).then(function (response) {
                    return {
                        buffer: response.data,
                        contentType: response.headers()['content-type'],
                        fileName: response.headers()['content-disposition'].split(';')[1].split('=')[1].replace(/"/g, '')
                    };
                }).catch(function (error) { return _this.exception.catcher('XHR for getCsvData failed')(error); });
            };
            TrainingRepository.prototype.getRequestInformation = function (id) {
                return this.$http.get("".concat(baseUrl, "/request/").concat(id));
            };
            TrainingRepository.prototype.updateRequestInformation = function (request) {
                return this.$http.put("".concat(baseUrl, "/request/").concat(request.id), request);
            };
            TrainingRepository.prototype.createRequest = function (request) {
                return this.$http.post("".concat(baseUrl, "/request"), request);
            };
            TrainingRepository.prototype.getTrainingListing = function (params) {
                return this.$http.get("".concat(baseUrl, "/training"), { params: params });
            };
            TrainingRepository.prototype.getTrainingListingDownload = function (params) {
                var _this = this;
                return this.$http.get("".concat(baseUrl, "/training/csv"), { params: params, responseType: 'arraybuffer' }).then(function (response) {
                    return {
                        buffer: response.data,
                        contentType: response.headers()['content-type'],
                        fileName: response.headers()['content-disposition'].split(';')[1].split('=')[1].replace(/"/g, '')
                    };
                }).catch(function (error) { return _this.exception.catcher('XHR for getCsvData failed')(error); });
            };
            TrainingRepository.prototype.getTrainingInformation = function (id) {
                return this.$http.get("".concat(baseUrl, "/training/").concat(id));
            };
            TrainingRepository.prototype.updateTrainingInformation = function (training) {
                return this.$http.put("".concat(baseUrl, "/training/").concat(training.id), training);
            };
            TrainingRepository.prototype.createTraining = function (training) {
                return this.$http.post("".concat(baseUrl, "/training"), training);
            };
            TrainingRepository.prototype.getEntity = function (trainingId, entityId) {
                return this.$http.get("".concat(baseUrl, "/").concat(trainingId, "/entities/").concat(entityId));
            };
            TrainingRepository.prototype.getEntityListing = function (trainingId) {
                return this.$http.get("".concat(baseUrl, "/").concat(trainingId, "/entities"));
            };
            TrainingRepository.prototype.getEntitySummary = function (entityType, entityId) {
                return this.$http.get("".concat(baseUrl, "/entitySummary?entityType=").concat(entityType, "&entityId=").concat(entityId));
            };
            TrainingRepository.prototype.getEntitiesBasic = function (trainingId) {
                return this.$http.get("".concat(baseUrl, "/").concat(trainingId, "/entitiesbasic"));
            };
            TrainingRepository.prototype.createEntity = function (entity) {
                return this.$http.post("".concat(baseUrl, "/entities"), entity);
            };
            TrainingRepository.prototype.updateEntity = function (entity) {
                return this.$http.put("".concat(baseUrl, "/entities"), entity);
            };
            TrainingRepository.prototype.deleteEntity = function (id) {
                return this.$http.delete("".concat(baseUrl, "/entities/").concat(id));
            };
            TrainingRepository.prototype.deleteCLE = function (id) {
                var _this = this;
                return this.$http.delete("".concat(baseUrl, "/cle/").concat(id)).catch(function (error) { return _this.exception.catcher('Cannot Delete CLE')(error); });
            };
            TrainingRepository.prototype.deleteTraining = function (id) {
                var _this = this;
                return this.$http.delete("".concat(baseUrl, "/training/").concat(id)).catch(function (error) { return _this.exception.catcher('Cannot Delete Training')(error); });
            };
            TrainingRepository.prototype.deleteTrainingRequest = function (id) {
                var _this = this;
                return this.$http.delete("".concat(baseUrl, "/request/").concat(id)).catch(function (error) { return _this.exception.catcher('Cannot Delete Training Request')(error); });
            };
            TrainingRepository.prototype.createCLENote = function (activity) {
                return this.$http.post("".concat(baseUrl, "/cle/").concat(activity.cleId, "/notes"), activity);
            };
            TrainingRepository.prototype.updateCLENote = function (activity) {
                return this.$http.put("".concat(baseUrl, "/cle/").concat(activity.cleId, "/notes/").concat(activity.id), activity);
            };
            TrainingRepository.prototype.createTrainingNote = function (activity) {
                return this.$http.post("".concat(baseUrl, "/training/").concat(activity.trainingId, "/notes"), activity);
            };
            TrainingRepository.prototype.updateTrainingNote = function (activity) {
                return this.$http.put("".concat(baseUrl, "/training/").concat(activity.trainingId, "/notes/").concat(activity.id), activity);
            };
            TrainingRepository.$inject = ['$http', 'exception'];
            __decorate([
                app.ApiExecutor()
            ], TrainingRepository.prototype, "getCLEEvents", null);
            __decorate([
                app.ApiExecutor()
            ], TrainingRepository.prototype, "getTrainingEvents", null);
            __decorate([
                app.ApiExecutor()
            ], TrainingRepository.prototype, "getCLEListing", null);
            __decorate([
                app.ApiExecutor()
            ], TrainingRepository.prototype, "getCLEInformation", null);
            __decorate([
                app.ApiExecutor()
            ], TrainingRepository.prototype, "updateCLEInformation", null);
            __decorate([
                app.ApiExecutor()
            ], TrainingRepository.prototype, "createCLE", null);
            __decorate([
                app.ApiExecutor()
            ], TrainingRepository.prototype, "getRequestListing", null);
            __decorate([
                app.ApiExecutor()
            ], TrainingRepository.prototype, "getRequestInformation", null);
            __decorate([
                app.ApiExecutor()
            ], TrainingRepository.prototype, "updateRequestInformation", null);
            __decorate([
                app.ApiExecutor()
            ], TrainingRepository.prototype, "createRequest", null);
            __decorate([
                app.ApiExecutor()
            ], TrainingRepository.prototype, "getTrainingListing", null);
            __decorate([
                app.ApiExecutor()
            ], TrainingRepository.prototype, "getTrainingInformation", null);
            __decorate([
                app.ApiExecutor()
            ], TrainingRepository.prototype, "updateTrainingInformation", null);
            __decorate([
                app.ApiExecutor()
            ], TrainingRepository.prototype, "createTraining", null);
            __decorate([
                app.ApiExecutor()
            ], TrainingRepository.prototype, "getEntity", null);
            __decorate([
                app.ApiExecutor()
            ], TrainingRepository.prototype, "getEntityListing", null);
            __decorate([
                app.ApiExecutor()
            ], TrainingRepository.prototype, "getEntitySummary", null);
            __decorate([
                app.ApiExecutor()
            ], TrainingRepository.prototype, "getEntitiesBasic", null);
            __decorate([
                app.ApiExecutor()
            ], TrainingRepository.prototype, "createEntity", null);
            __decorate([
                app.ApiExecutor()
            ], TrainingRepository.prototype, "updateEntity", null);
            __decorate([
                app.ApiExecutor()
            ], TrainingRepository.prototype, "deleteEntity", null);
            __decorate([
                app.ApiExecutor()
            ], TrainingRepository.prototype, "createCLENote", null);
            __decorate([
                app.ApiExecutor()
            ], TrainingRepository.prototype, "updateCLENote", null);
            __decorate([
                app.ApiExecutor()
            ], TrainingRepository.prototype, "createTrainingNote", null);
            __decorate([
                app.ApiExecutor()
            ], TrainingRepository.prototype, "updateTrainingNote", null);
            return TrainingRepository;
        }());
        angular
            .module('app.training')
            .service('training.repository', TrainingRepository);
    })(training = app.training || (app.training = {}));
})(app || (app = {}));
var app;
(function (app) {
    var users;
    (function (users) {
        'use strict';
        var baseUrl = 'api/identity-management/users';
        var UserRepository = (function () {
            function UserRepository($http, exception) {
                this.$http = $http;
                this.exception = exception;
            }
            UserRepository.prototype.getCurrentUser = function () {
                return this.$http.get("".concat(baseUrl, "/current"));
            };
            UserRepository.prototype.getCurrentUserBasic = function () {
                return this.$http.get("".concat(baseUrl, "/current/basic"));
            };
            UserRepository.prototype.getSupervisedUsers = function (q) {
                return this.$http.get("".concat(baseUrl, "/current/supervisedUsers"), { params: { q: q } });
            };
            UserRepository.prototype.getTimeslipUsers = function (q) {
                return this.$http.get("".concat(baseUrl, "/current/timeslipUsers"), { params: { q: q } });
            };
            UserRepository.prototype.getUsers = function (q) {
                return this.$http.get(baseUrl, { params: { q: q } });
            };
            UserRepository.$inject = ['$http', 'exception'];
            __decorate([
                app.ApiExecutor()
            ], UserRepository.prototype, "getCurrentUser", null);
            __decorate([
                app.ApiExecutor()
            ], UserRepository.prototype, "getCurrentUserBasic", null);
            __decorate([
                app.ApiExecutor()
            ], UserRepository.prototype, "getSupervisedUsers", null);
            __decorate([
                app.ApiExecutor()
            ], UserRepository.prototype, "getTimeslipUsers", null);
            __decorate([
                app.ApiExecutor()
            ], UserRepository.prototype, "getUsers", null);
            return UserRepository;
        }());
        angular
            .module('app.users')
            .service('users.repository', UserRepository);
    })(users = app.users || (app.users = {}));
})(app || (app = {}));
var app;
(function (app) {
    var users;
    (function (users_1) {
        'use strict';
        var UserService = (function () {
            function UserService(datacontext) {
                this.datacontext = datacontext;
                'ngInject';
                'ngInject';
            }
            UserService.prototype.getCurrentUser = function () {
                return this.datacontext.users.getCurrentUser().then(function (u) {
                    var user = {
                        id: u.id,
                        firstName: u.firstName,
                        lastName: u.lastName,
                        fullName: u.fullName
                    };
                    return user;
                });
            };
            UserService.prototype.getSupervisedUsers = function (q) {
                return this.datacontext.users.getSupervisedUsers(q).then(function (users) {
                    return _.map(users, function (u) {
                        return {
                            id: u.id,
                            firstName: u.firstName,
                            lastName: u.lastName,
                            fullName: u.fullName
                        };
                    });
                });
            };
            return UserService;
        }());
        angular
            .module('app.users')
            .service('userService', UserService);
    })(users = app.users || (app.users = {}));
})(app || (app = {}));
var app;
(function (app) {
    var utils;
    (function (utils) {
        angular
            .module('app.utils')
            .filter('abs', function () { return function (num) { return Math.abs(num); }; });
    })(utils = app.utils || (app.utils = {}));
})(app || (app = {}));
var app;
(function (app) {
    var utils;
    (function (utils) {
        var AddressHelperService = (function () {
            function AddressHelperService() {
            }
            AddressHelperService.prototype.mapFromGooglePlaceResult = function (place) {
                var address = {};
                var components = place.address_components;
                for (var i = 0, component = void 0; component = components[i]; i++) {
                    if (!(component.types && component.types.length)) {
                        continue;
                    }
                    if (component.types[0] === 'street_number') {
                        address.line1 = component['long_name'];
                    }
                    if (component.types[0] === 'route') {
                        address.line1 += ' ' + component['long_name'];
                    }
                    if (component.types[0] === 'locality') {
                        address.city = component['long_name'];
                    }
                    if (component.types[0] === 'administrative_area_level_1') {
                        address.state = component['short_name'];
                    }
                    if (component.types[0] === 'administrative_area_level_2') {
                        address.county = component['short_name'].replace(' County', '');
                    }
                    if (component.types[0] === 'postal_code') {
                        address.postalCode = component['long_name'];
                    }
                }
                address.fullAddress = place.formatted_address;
                address.geoLatitude = place.geometry.location.lat();
                address.geoLongitude = place.geometry.location.lng();
                return address;
            };
            AddressHelperService = __decorate([
                app.Service('app.utils', 'addressHelper')
            ], AddressHelperService);
            return AddressHelperService;
        }());
    })(utils = app.utils || (app.utils = {}));
})(app || (app = {}));
var app;
(function (app) {
    var utils;
    (function (utils) {
        var ageFilter = function ($filter) {
            'ngInject';
            return function (value) {
                return $filter('abs')($filter('amDifference')(value, null, 'years'));
            };
        };
        angular
            .module('app.utils')
            .filter('age', ageFilter);
    })(utils = app.utils || (app.utils = {}));
})(app || (app = {}));
var app;
(function (app) {
    var utils;
    (function (utils) {
        var autofocus = function ($timeout) {
            'ngInject';
            return {
                link: function (scope, element, attrs) {
                    $timeout(function () { return element.focus(); });
                }
            };
        };
        angular
            .module('app.utils')
            .directive('autofocus', autofocus);
    })(utils = app.utils || (app.utils = {}));
})(app || (app = {}));
var app;
(function (app) {
    var utils;
    (function (utils) {
        var BackButtonComponent = (function () {
            function BackButtonComponent(common) {
                this.common = common;
                'ngInject';
                'ngInject';
            }
            BackButtonComponent.prototype.back = function () {
                var _this = this;
                if (this.isDirty) {
                    this.common.confirmation
                        .show({
                        message: this.confirmation || 'Your changes will not be saved. Are you sure you want to discard your changes?',
                        title: 'Discard Changes?',
                        ok: 'Discard',
                        cancel: 'Cancel'
                    })
                        .then(function () { return _this.common.routingHelper.back(_this.fallbackUrl); });
                }
                else {
                    this.common.routingHelper.back(this.fallbackUrl);
                }
            };
            BackButtonComponent.$inject = ['common'];
            BackButtonComponent = __decorate([
                app.Component('app.utils', 'ceBackButton', {
                    template: "<md-button class=\"md-icon-button\" ng-click=\"$ctrl.back()\">\n                  <md-icon>arrow_back</md-icon>\n                 </md-button>",
                    bindings: {
                        isDirty: '<',
                        fallbackUrl: '<'
                    }
                })
            ], BackButtonComponent);
            return BackButtonComponent;
        }());
    })(utils = app.utils || (app.utils = {}));
})(app || (app = {}));
var app;
(function (app) {
    var utils;
    (function (utils) {
        var BadgedButtonComponent = (function () {
            function BadgedButtonComponent() {
            }
            BadgedButtonComponent = __decorate([
                app.Component('app.utils', 'ceBadgedButton', {
                    bindings: {
                        badge: '=',
                        icon: '@',
                        tooltip: '@',
                        onClick: '&'
                    },
                    template: "<a ng-click=\"$ctrl.onClick({$event})\">\n   <md-button aria-label=\"{{$ctrl.tooltip}}\"\n              class=\"md-icon-button\">\n      <md-tooltip>{{$ctrl.tooltip}}</md-tooltip>\n      <md-icon>{{$ctrl.icon}}</md-icon>\n   </md-button>\n   <i ng-show=\"$ctrl.badge\">{{$ctrl.badge}}</i>\n</a>"
                })
            ], BadgedButtonComponent);
            return BadgedButtonComponent;
        }());
    })(utils = app.utils || (app.utils = {}));
})(app || (app = {}));
var app;
(function (app) {
    var utils;
    (function (utils) {
        var BlobHelperService = (function () {
            function BlobHelperService() {
            }
            BlobHelperService.prototype.open = function (blob) {
                var buffer = blob.buffer, contentType = blob.contentType, fileName = blob.fileName;
                var blobObject = new Blob([buffer], { type: contentType });
                var a = document.createElement('a');
                a.style.display = 'none';
                var url = window.URL.createObjectURL(blobObject);
                a.href = url;
                a.download = blob.fileName;
                document.body.appendChild(a);
                a.click();
                setTimeout(function () {
                    document.body.removeChild(a);
                    window.URL.revokeObjectURL(url);
                }, 100);
            };
            return BlobHelperService;
        }());
        angular
            .module('app.utils')
            .service('blobHelper', BlobHelperService);
    })(utils = app.utils || (app.utils = {}));
})(app || (app = {}));
var app;
(function (app) {
    var utils;
    (function (utils) {
        var ConfirmationService = (function () {
            function ConfirmationService($mdDialog) {
                this.$mdDialog = $mdDialog;
                'ngInject';
                'ngInject';
            }
            ConfirmationService.prototype.show = function (options) {
                var message = options.message, title = options.title, ok = options.ok, cancel = options.cancel;
                var confirm = this.$mdDialog.confirm()
                    .textContent(message)
                    .ok(ok || 'Yes')
                    .cancel(cancel || 'No');
                if (title) {
                    confirm = confirm.title(title);
                }
                return this.$mdDialog.show(confirm);
            };
            return ConfirmationService;
        }());
        angular
            .module('app.utils')
            .service('confirmation', ConfirmationService);
    })(utils = app.utils || (app.utils = {}));
})(app || (app = {}));
var app;
(function (app) {
    var utils;
    (function (utils) {
        angular
            .module('app.utils')
            .filter('duration', duration);
        duration.$inject = ['$filter'];
        function duration($filter) {
            return function (input, compareTo) {
                if (!input)
                    return;
                var starts = input;
                var ends = compareTo || new Date();
                var diff = moment(ends).diff(starts, 'days');
                var duration = moment.duration(diff, 'days');
                var years = Math.floor(duration.asYears());
                var months = Math.round(duration.asMonths());
                if (months === 12) {
                    years++;
                    months = 0;
                }
                if (years > 0) {
                    return "".concat(years, " years, ").concat(Math.floor(months % 12), " months");
                }
                else {
                    return "".concat(months, " months");
                }
            };
        }
    })(utils = app.utils || (app.utils = {}));
})(app || (app = {}));
var app;
(function (app) {
    var utils;
    (function (utils) {
        angular
            .module('app.utils')
            .directive('eatClick', function () {
            return function (scope, element) {
                element.on('click', function ($event) { return $event.stopPropagation(); });
            };
        });
    })(utils = app.utils || (app.utils = {}));
})(app || (app = {}));
var app;
(function (app) {
    var utils;
    (function (utils) {
        var errSrc = function () {
            return {
                link: function (scope, element, attrs) {
                    scope.$watch(function () { return attrs['ngSrc']; }, function (value) {
                        if (!value) {
                            element.attr('src', attrs.errSrc);
                        }
                    });
                    element.bind('error', function () { return element.attr('src', attrs.errSrc); });
                }
            };
        };
        angular
            .module('app.utils')
            .directive('errSrc', errSrc);
    })(utils = app.utils || (app.utils = {}));
})(app || (app = {}));
var app;
(function (app) {
    var utils;
    (function (utils) {
        var FileUploaderService = (function () {
            function FileUploaderService(Upload) {
                this.Upload = Upload;
                'ngInject';
                'ngInject';
            }
            FileUploaderService.prototype.upload = function (request) {
                var url = request.url, file = request.file;
                var data = {
                    url: url || 'api/photos',
                    method: 'POST',
                    data: { file: file }
                };
                return this.Upload.upload(data);
            };
            return FileUploaderService;
        }());
        angular
            .module('app.utils')
            .service('fileUploader', FileUploaderService);
    })(utils = app.utils || (app.utils = {}));
})(app || (app = {}));
var app;
(function (app) {
    var utils;
    (function (utils) {
        var filter = function () {
            var units = [
                'bytes',
                'KB',
                'MB',
                'GB',
                'TB',
                'PB'
            ];
            return function (bytes, precision) {
                if (isNaN(bytes) || !isFinite(bytes)) {
                    return '?';
                }
                var unit = 0;
                while (bytes >= 1024) {
                    bytes /= 1024;
                    unit++;
                }
                return bytes.toFixed(precision) + ' ' + units[unit];
            };
        };
        angular
            .module('app.utils')
            .filter('filesize', filter);
    })(utils = app.utils || (app.utils = {}));
})(app || (app = {}));
var app;
(function (app) {
    var utils;
    (function (utils) {
        var focusIf = function ($timeout) {
            'ngInject';
            var link = function ($scope, $element, $attrs) {
                var focus = function (condition) {
                    if (condition) {
                        $timeout(function () {
                            dom.focus();
                        }, $scope.$eval($attrs.focusDelay) || 0);
                    }
                };
                var dom = $element[0];
                if ($attrs.focusIf) {
                    $scope.$watch($attrs.focusIf, focus);
                }
                else {
                    focus(true);
                }
            };
            return {
                restrict: 'A',
                link: link
            };
        };
        angular
            .module('app.utils')
            .directive('focusIf', focusIf);
    })(utils = app.utils || (app.utils = {}));
})(app || (app = {}));
var app;
(function (app) {
    var utils;
    (function (utils) {
        angular
            .module('app.utils')
            .directive('geoAutocomplete', geoAutocomplete);
        function geoAutocomplete(common) {
            'ngInject';
            return {
                restrict: 'A',
                require: 'ngModel',
                scope: {
                    placeType: '@placesAutocomplete',
                    onSelect: '&',
                    placeholderText: '='
                },
                link: linkFn
            };
            function linkFn(scope, element, attrs, model) {
                var types = [];
                if (scope.placeType) {
                    types.push(scope.placeType);
                }
                common.$timeout(function () {
                    var autocomplete = new google.maps.places.Autocomplete(element.get(0), { types: types });
                    google.maps.event.addListener(autocomplete, 'place_changed', function () {
                        var place = autocomplete.getPlace();
                        if (scope.onSelect) {
                            scope.onSelect({ place: place });
                        }
                        else {
                            model.$setViewValue(place.formatted_address);
                        }
                    });
                    if (navigator.geolocation) {
                        navigator.geolocation.getCurrentPosition(function (position) {
                            var geolocation = new google.maps.LatLng(position.coords.latitude, position.coords.longitude);
                            autocomplete.setBounds(new google.maps.LatLngBounds(geolocation, geolocation));
                        });
                    }
                });
            }
        }
    })(utils = app.utils || (app.utils = {}));
})(app || (app = {}));
var app;
(function (app) {
    var utils;
    (function (utils) {
        angular
            .module('app.utils')
            .filter('height', function () { return function (value) {
            var totalInches = +value;
            if (isNaN(totalInches))
                return;
            var feet = Math.floor(totalInches / 12);
            var inches = totalInches % 12;
            return "".concat(feet, "' ").concat(inches, "\"");
        }; });
    })(utils = app.utils || (app.utils = {}));
})(app || (app = {}));
var app;
(function (app) {
    var utils;
    (function (utils) {
        var hideMidnightFilter = function ($filter) {
            'ngInject';
            return function (value) {
                return value.replace(' 12:00 am', '');
            };
        };
        angular
            .module('app.utils')
            .filter('hideMidnightFilter', hideMidnightFilter);
    })(utils = app.utils || (app.utils = {}));
})(app || (app = {}));
var app;
(function (app) {
    var utils;
    (function (utils) {
        var inArrayFilter = function ($filter) {
            'ngInject';
            return function (list, arrayFilter, element) {
                return $filter('filter')(list, function (listItem) {
                    return arrayFilter && arrayFilter.indexOf(listItem[element]) !== -1;
                });
            };
        };
        angular
            .module('app.utils')
            .filter('inArray', inArrayFilter);
    })(utils = app.utils || (app.utils = {}));
})(app || (app = {}));
var app;
(function (app) {
    var utils;
    (function (utils) {
        'use strict';
        var inflector = function () {
            var tokenize = function (text) {
                text = text.replace(/([A-Z])|([\-|\_])/g, function (_, $1) { return ' ' + ($1 || ''); });
                return text.replace(/\s\s+/g, ' ').trim().toLowerCase().split(' ');
            };
            var capitalizeTokens = function (tokens) {
                var result = [];
                angular.forEach(tokens, function (token) {
                    result.push(token.charAt(0).toUpperCase() + token.substr(1));
                });
                return result;
            };
            var camelCase = function (tokens) {
                var result = [];
                angular.forEach(tokens, function (token) {
                    if (tokens.indexOf(token) === 0) {
                        result.push(token);
                    }
                    else {
                        result.push(token.charAt(0).toUpperCase() + token.substr(1));
                    }
                });
                return result;
            };
            var inflectors = {
                humanize: function (value) { return capitalizeTokens(tokenize(value)).join(' '); },
                underscore: function (value) { return tokenize(value).join('_'); },
                variable: function (value) {
                    var tokens = tokenize(value);
                    value = value[0] + capitalizeTokens(tokens.slice(1)).join('');
                    return value;
                },
                camelCase: function (value) { return camelCase(tokenize(value)).join(''); }
            };
            return function (text, inflector) {
                if (inflector !== false && angular.isString(text)) {
                    inflector = inflector || 'humanize';
                    return inflectors[inflector](text);
                }
                else {
                    return text;
                }
            };
        };
        angular
            .module('app.utils')
            .filter('inflector', inflector);
    })(utils = app.utils || (app.utils = {}));
})(app || (app = {}));
var app;
(function (app) {
    var utils;
    (function (utils) {
        angular
            .module('app.utils')
            .filter('isEmpty', function () {
            var prop;
            return function (obj) {
                for (prop in obj) {
                    if (obj.hasOwnProperty(prop)) {
                        return false;
                    }
                }
                return true;
            };
        });
    })(utils = app.utils || (app.utils = {}));
})(app || (app = {}));
var app;
(function (app) {
    var utils;
    (function (utils) {
        angular
            .module('app.utils')
            .directive('noDirtyCheck', function () {
            return {
                restrict: 'A',
                require: 'ngModel',
                link: function (scope, elm, attrs, ctrl) {
                    ctrl.$pristine = false;
                }
            };
        });
    })(utils = app.utils || (app.utils = {}));
})(app || (app = {}));
var app;
(function (app) {
    var utils;
    (function (utils) {
        angular
            .module('app.utils')
            .filter('nth', function () { return function (num) {
            var postscript;
            if (num === 1) {
                postscript = 'st';
            }
            else if (num === 2) {
                postscript = 'nd';
            }
            else if (num === 3) {
                postscript = 'rd';
            }
            else {
                postscript = 'tn';
            }
            return "".concat(num).concat(postscript);
        }; });
    })(utils = app.utils || (app.utils = {}));
})(app || (app = {}));
var app;
(function (app) {
    var utils;
    (function (utils) {
        var MASK = '(999) 999-9999';
        var PhoneInput = (function () {
            function PhoneInput() {
            }
            PhoneInput.prototype.enableMask = function () {
                this.mask = MASK;
            };
            PhoneInput.prototype.disableMask = function () {
                this.mask = '';
                this.inputValue = null;
            };
            PhoneInput.prototype.handleChange = function () {
                if (this.inputValue && this.inputValue.match(/^\d{10}$/g)) {
                    this.value = this.inputValue ? '+1' + this.inputValue : null;
                }
                else {
                    this.value = null;
                    this.disableMask();
                }
            };
            PhoneInput.prototype.$onChanges = function () {
                if (this.value) {
                    this.inputValue = this.value.replace(/^\+1/, '');
                }
            };
            PhoneInput = __decorate([
                app.Component('app.utils', 'cePhoneInput', {
                    template: "\n<input name=\"$ctrl.inputName\"\n       ng-change=\"$ctrl.handleChange()\"\n       ng-model=\"$ctrl.inputValue\"\n       ng-model-options=\"{updateOn: 'blur'}\"\n       ui-mask=\"{{$ctrl.mask}}\"\n       ng-focus=\"$ctrl.enableMask()\"\n       ng-required=\"$ctrl.required\" />",
                    bindings: {
                        inputName: '@',
                        required: '@',
                        value: '='
                    }
                })
            ], PhoneInput);
            return PhoneInput;
        }());
    })(utils = app.utils || (app.utils = {}));
})(app || (app = {}));
var app;
(function (app) {
    var utils;
    (function (utils) {
        var filter = function () {
            return function (phone) {
                if (phone) {
                    var value = phone.replace(/^\+1/, '');
                    return "(".concat(value.substr(0, 3), ") ").concat(value.substr(3, 3), "-").concat(value.substr(6, 4));
                }
            };
        };
        angular
            .module('app.utils')
            .filter('phone', filter);
    })(utils = app.utils || (app.utils = {}));
})(app || (app = {}));
var app;
(function (app) {
    var utils;
    (function (utils) {
        angular
            .module('app.utils')
            .filter('ssn', function () { return function (ssn) {
            if (ssn && ssn.length === 9) {
                return "".concat(ssn.substr(0, 3), "-").concat(ssn.substr(3, 2), "-").concat(ssn.substr(5, 4));
            }
        }; });
    })(utils = app.utils || (app.utils = {}));
})(app || (app = {}));
var app;
(function (app) {
    var utils;
    (function (utils) {
        var TimeHelperService = (function () {
            function TimeHelperService() {
                this.times = [];
                for (var i = 8; i < 17; i++) {
                    var hour = i % 12;
                    if (hour === 0)
                        hour = 12;
                    var meridiem = i < 12 ? 'am' : 'pm';
                    this.times.push(hour + ':00' + meridiem);
                    this.times.push(hour + ':15' + meridiem);
                    this.times.push(hour + ':30' + meridiem);
                    this.times.push(hour + ':45' + meridiem);
                }
            }
            TimeHelperService.prototype.query = function (query) {
                return _.filter(this.times, function (t) { return t.indexOf(query) === 0; });
            };
            return TimeHelperService;
        }());
        angular
            .module('app.utils')
            .service('timehelper', TimeHelperService);
    })(utils = app.utils || (app.utils = {}));
})(app || (app = {}));
var app;
(function (app) {
    var utils;
    (function (utils) {
        angular
            .module('app.utils')
            .directive('validSubmit', validSubmit);
        function validSubmit($parse, $timeout) {
            'ngInject';
            return {
                require: 'form',
                link: function (scope, element, attrs, form) {
                    form.$submitted = false;
                    var fn = $parse(attrs.validSubmit);
                    form.submit = function () {
                        form.$submitted = true;
                        if (form.$valid) {
                            fn(scope);
                            form.$submitted = false;
                        }
                    };
                    element.on('submit', function (event) {
                        scope.$apply(function () {
                            form.$submitted = true;
                            if (form.$valid) {
                                fn(scope, { $event: event });
                                form.$setPristine();
                                form.$setUntouched();
                            }
                        });
                    });
                }
            };
        }
    })(utils = app.utils || (app.utils = {}));
})(app || (app = {}));
var app;
(function (app) {
    var utils;
    (function (utils) {
        var yesNoFilter = function ($filter) {
            'ngInject';
            return function (value) {
                var returnValue = null;
                switch (value) {
                    case true:
                        returnValue = 'Yes';
                        break;
                    case false:
                        returnValue = 'No';
                        break;
                    default:
                        returnValue = '';
                        break;
                }
                return returnValue;
            };
        };
        angular
            .module('app.utils')
            .filter('yesNo', yesNoFilter);
    })(utils = app.utils || (app.utils = {}));
})(app || (app = {}));
var app;
(function (app) {
    var activities;
    (function (activities) {
        var Activity = (function () {
            function Activity(common, datacontext) {
                this.common = common;
                this.datacontext = datacontext;
                this.isSubmitting = false;
                this.activity = {};
                this.returnSref = '^.list';
                'ngInject';
                'ngInject';
            }
            Activity.prototype.$onInit = function () {
                this.activity.involvementId = this.involvementId;
                this.activity.source = this.source;
                this.activity.sourceId = this.sourceId;
                this.activity.activityDate = moment().toDate();
                this.activity.activityType = this.activityType;
                this.activity.name = this.name;
                this.returnSref = this.returnSref || '^.list';
            };
            Activity.prototype.submit = function () {
                var _this = this;
                this.isSubmitting = true;
                if (typeof this.activity.activityDate !== 'string') {
                    this.activity.activityDate = this.activity.activityDate.toDateString();
                }
                this.datacontext.activities
                    .createActivity(this.activity)
                    .then(function (request) {
                    if (request) {
                        _this.common.$mdToast.showSimple(_this.name + ' created');
                        _this.common.$state.go(_this.returnSref, { involvementId: _this.involvementId, sourceId: _this.sourceId });
                    }
                })
                    .finally(function () { return _this.isSubmitting = false; });
            };
            Activity.$inject = ['common', 'datacontext'];
            Activity = __decorate([
                app.Component('app.activities', 'ceActivitiesActivity', {
                    templateUrl: 'app/activities/activity/activity.html',
                    bindings: {
                        involvementId: '<',
                        source: '@',
                        sourceId: '<',
                        activityType: '@',
                        name: '@',
                        returnSref: '@'
                    }
                })
            ], Activity);
            return Activity;
        }());
    })(activities = app.activities || (app.activities = {}));
})(app || (app = {}));
var app;
(function (app) {
    var activities;
    (function (activities) {
        var ActivityCardSelectorComponent = (function () {
            function ActivityCardSelectorComponent() {
            }
            ActivityCardSelectorComponent = __decorate([
                app.Component('app.activities', 'ceActivityCardSelector', {
                    template: "<div flex ng-switch=\"$ctrl.activity.activityType\">\n                   <ce-document-activity-card flex\n                                              ng-switch-when=\"Document\"\n                                              activity=\"$ctrl.activity\"\n                                              on-close=\"$ctrl.onClose()\"\n                                              on-delete=\"$ctrl.onDelete()\"\n                                              on-post-comment=\"$ctrl.onPostComment({value})\">\n                   </ce-document-activity-card>\n                   <ce-activity-card flex\n                                     ng-switch-default\n                                     activity=\"$ctrl.activity\"\n                                     on-close=\"$ctrl.onClose()\"\n                                     on-post-comment=\"$ctrl.onPostComment({value})\">\n                   </ce-activity-card>\n                 </div>",
                    bindings: {
                        activity: '<',
                        onClose: '&',
                        onDelete: '&',
                        onPostComment: '&'
                    }
                })
            ], ActivityCardSelectorComponent);
            return ActivityCardSelectorComponent;
        }());
    })(activities = app.activities || (app.activities = {}));
})(app || (app = {}));
var app;
(function (app) {
    var activities;
    (function (activities) {
        var GenericActivityCardComponent = (function () {
            function GenericActivityCardComponent(common) {
                this.common = common;
                'ngInject';
                'ngInject';
            }
            GenericActivityCardComponent.prototype.$onChanges = function () {
                switch (this.activity.activityType) {
                    default:
                        this.href = this.common.$state.href('^.activity', { activityId: this.activity.id });
                }
            };
            GenericActivityCardComponent.$inject = ['common'];
            GenericActivityCardComponent = __decorate([
                app.Component('app.activities', 'ceActivityCard', {
                    templateUrl: 'app/activities/activity-list/activity-card.html',
                    bindings: {
                        activity: '<',
                        onClose: '&',
                        onPostComment: '&'
                    }
                })
            ], GenericActivityCardComponent);
            return GenericActivityCardComponent;
        }());
    })(activities = app.activities || (app.activities = {}));
})(app || (app = {}));
var app;
(function (app) {
    var activities;
    (function (activities) {
        var ActivitiesFilterComponent = (function () {
            function ActivitiesFilterComponent($mdSidenav, datacontext) {
                var _this = this;
                this.$mdSidenav = $mdSidenav;
                this.datacontext = datacontext;
                this.activityTypes = [];
                this.getInvolvements = function () {
                    return _this.datacontext.involvements.getInvolvements({
                        personId: _this.personId,
                        fields: 'id,idNumber'
                    }).then(function (result) { return _this.involvements = result.map(function (item) { return ({ item: item }); }); });
                };
                'ngInject';
                'ngInject';
            }
            ActivitiesFilterComponent.prototype.close = function () {
                this.$mdSidenav('activity-filters').close();
            };
            ActivitiesFilterComponent.prototype.submit = function () {
                var involvementIds = _(this.involvements).filter(function (x) { return x.isChecked; }).map(function (x) { return x.item.id; }).value();
                var activityTypes = _(this.activityTypes).filter(function (x) { return x.isChecked; }).map(function (x) { return x.item; }).value();
                angular.extend(this.params, __assign(__assign({}, this.data), { involvementIds: involvementIds, activityTypes: activityTypes }));
                this.onSubmit();
                this.close();
            };
            ActivitiesFilterComponent.prototype.$onChanges = function () {
                this.data = angular.copy(this.params);
                this.getInvolvements();
            };
            ActivitiesFilterComponent.$inject = ['$mdSidenav', 'datacontext'];
            ActivitiesFilterComponent = __decorate([
                app.Component('app.activities', 'ceActivityFilters', {
                    templateUrl: 'app/activities/activity-list/activity-filters.html',
                    bindings: {
                        onSubmit: '&',
                        personId: '<',
                        params: '<'
                    }
                })
            ], ActivitiesFilterComponent);
            return ActivitiesFilterComponent;
        }());
    })(activities = app.activities || (app.activities = {}));
})(app || (app = {}));
var app;
(function (app) {
    var activities;
    (function (activities) {
        var ActivityListItemComponent = (function () {
            function ActivityListItemComponent() {
            }
            ActivityListItemComponent = __decorate([
                app.Component('app.activities', 'ceActivityListItem', {
                    templateUrl: 'app/activities/activity-list/activity-list-item.html',
                    bindings: {
                        activity: '<',
                        onClick: '&'
                    }
                })
            ], ActivityListItemComponent);
            return ActivityListItemComponent;
        }());
    })(activities = app.activities || (app.activities = {}));
})(app || (app = {}));
var app;
(function (app) {
    var activities;
    (function (activities) {
        var ActivityListViewComponent = (function () {
            function ActivityListViewComponent($mdDialog, $mdSidenav, datacontext, blobHelper) {
                var _this = this;
                this.$mdDialog = $mdDialog;
                this.$mdSidenav = $mdSidenav;
                this.datacontext = datacontext;
                this.blobHelper = blobHelper;
                this.activities = [];
                this.canShowMore = false;
                this.isFiltered = false;
                this.isLoading = false;
                this.params = {
                    page: 1,
                    activityTypes: []
                };
                this.getActivities = function () {
                    _this.isLoading = true;
                    return _this.datacontext.activities
                        .searchActivities(_this.params)
                        .then(function (result) {
                        _this.canShowMore = result.list.length >= result.pageSize;
                        _this.activities = _.uniqBy(__spreadArray(__spreadArray([], _this.activities, true), result.list, true), function (a) { return a.id; });
                    })
                        .finally(function () { return _this.isLoading = false; });
                };
                'ngInject';
                'ngInject';
            }
            ActivityListViewComponent.prototype.filter = function () {
                this.$mdSidenav('activity-filters').open();
            };
            ActivityListViewComponent.prototype.newActivity = function (targetEvent) {
                var parent = angular.element(document.body);
                this.$mdDialog.show({
                    parent: parent,
                    targetEvent: targetEvent,
                    clickOutsideToClose: true,
                    templateUrl: 'app/activities/new-activity/new-activity.html',
                    controller: 'NewActivityDialogController',
                    controllerAs: '$ctrl',
                    locals: {
                        entityId: this.entityId
                    }
                });
            };
            ActivityListViewComponent.prototype.onActivityDeleted = function (activity) {
                var index = this.activities.indexOf(activity);
                this.activities.splice(index, 1);
            };
            ActivityListViewComponent.prototype.onPostComment = function (activity, value) {
                return this.datacontext.activities
                    .postComment(activity.id, { value: value })
                    .then(function (result) {
                    if (result) {
                        activity.events.push(result);
                    }
                    return !!result;
                });
            };
            ActivityListViewComponent.prototype.onShowMore = function () {
                this.params.page++;
                this.getActivities();
            };
            ActivityListViewComponent.prototype.print = function () {
                var _this = this;
                var data = {
                    name: 'ActivityList',
                    parameters: [
                        { name: 'PersonId', value: this.entityId },
                        { name: 'ActivityIds', values: _.map(this.activities, function (a) { return a.id; }) }
                    ]
                };
                this.datacontext.reports
                    .runReport(data)
                    .then(function (buffer) {
                    if (buffer) {
                        _this.blobHelper.open({
                            buffer: buffer,
                            contentType: 'application/pdf',
                            fileName: 'Activity List'
                        });
                    }
                });
            };
            ActivityListViewComponent.prototype.refresh = function () {
                this.isFiltered = this.params.activityTypes.length > 0
                    || !!this.params.from
                    || !!this.params.to;
                this.activities.splice(0);
                this.getActivities();
            };
            ActivityListViewComponent.prototype.$onChanges = function () {
                this.params.entityId = this.entityId;
                this.getActivities();
            };
            ActivityListViewComponent.$inject = ['$mdDialog', '$mdSidenav', 'datacontext', 'blobHelper'];
            ActivityListViewComponent = __decorate([
                app.Component('app.activities', 'ceActivityListView', {
                    templateUrl: 'app/activities/activity-list/activity-list-view.html',
                    bindings: {
                        entityId: '<'
                    }
                })
            ], ActivityListViewComponent);
            return ActivityListViewComponent;
        }());
    })(activities = app.activities || (app.activities = {}));
})(app || (app = {}));
var app;
(function (app) {
    var activities;
    (function (activities) {
        var ActivityListComponent = (function () {
            function ActivityListComponent($mdDialog) {
                this.$mdDialog = $mdDialog;
                'ngInject';
                'ngInject';
            }
            ActivityListComponent.prototype.$onChanges = function () {
                var _this = this;
                if (this.activities && this.activities.list.length > 0) {
                    this.activities.list = _.forEach(this.activities.list, function (activity) { activity.showReportIcon = _this.showReportIcon(activity); });
                    this.activities.list = _.forEach(this.activities.list, function (activity) { activity.canEdit = _this.canEdit(activity); });
                }
            };
            ActivityListComponent.prototype.canEdit = function (activity) {
                if (this.showInvolvementActivities === true) {
                    return false;
                }
                var a = _.find(app.activities.ActivityConfig, function (a) { return a.activityType === activity.activityType; });
                return a && a.canEdit === true;
            };
            ActivityListComponent.prototype.showReportIcon = function (activity) {
                return this.getReportName(activity) != null;
            };
            ActivityListComponent.prototype.getReportName = function (activity) {
                var reportName = null;
                var a = _.find(app.activities.ActivityConfig, function (a) { return a.activityType === activity.activityType; });
                if (a && a.reportName) {
                    reportName = a.reportName;
                }
                return reportName;
            };
            ActivityListComponent.prototype.showReportDialog = function (activity) {
                var report = {
                    category: 'Activity Report',
                    label: 'Single Note',
                    name: 'Single Note',
                    description: 'Single Note Report',
                    permission: 'reports.view',
                    parameters: [
                        { label: 'Note Type', name: 'NoteType', value: activity.name, dataType: 'hidden' },
                        { label: 'ActivityId', name: 'ActivityId', dataType: 'hidden', value: activity.id },
                    ]
                };
                return this.$mdDialog.show({
                    fullscreen: true,
                    templateUrl: 'app/reports/report-dialog/report-dialog.html',
                    controller: 'ReportDialogController',
                    controllerAs: '$ctrl',
                    focusOnOpen: false,
                    locals: {
                        report: report
                    }
                })
                    .catch(function (error) { if (error)
                    throw error; })
                    .finally(function () { return angular.noop; });
            };
            ActivityListComponent.$inject = ['$mdDialog'];
            ActivityListComponent = __decorate([
                app.Component('app.activities', 'ceActivityList', {
                    templateUrl: 'app/activities/activity-list/activity-list.html',
                    bindings: {
                        activities: '<',
                        showInvolvementActivities: '<',
                        refresh: '&',
                        paging: '<',
                        promise: '<',
                        onEdit: '&',
                    }
                })
            ], ActivityListComponent);
            return ActivityListComponent;
        }());
    })(activities = app.activities || (app.activities = {}));
})(app || (app = {}));
var app;
(function (app) {
    var activities;
    (function (activities) {
        var ActivityCommentFormComponent = (function () {
            function ActivityCommentFormComponent(common) {
                var _this = this;
                this.common = common;
                this.showActions = false;
                this.isPosting = false;
                this.$onInit = function () {
                    _this.photoId = _this.common.auth.user.photoId;
                };
                this.reset = function () {
                    delete _this.value;
                    _this.showActions = false;
                };
                'ngInject';
                'ngInject';
            }
            ActivityCommentFormComponent.prototype.handleKeypress = function (event) {
                if (event.keyCode === 10 && event.ctrlKey) {
                    this.submit();
                }
            };
            ActivityCommentFormComponent.prototype.submit = function () {
                var _this = this;
                this.isPosting = true;
                var value = this.value;
                this.onPost({ value: value }).then(function (result) {
                    if (result)
                        _this.reset();
                }).finally(function () { return _this.isPosting = false; });
            };
            ActivityCommentFormComponent.$inject = ['common'];
            ActivityCommentFormComponent = __decorate([
                app.Component('app.activities', 'ceActivityCommentForm', {
                    templateUrl: 'app/activities/activity-list/comment-form.html',
                    bindings: {
                        onPost: '&'
                    }
                })
            ], ActivityCommentFormComponent);
            return ActivityCommentFormComponent;
        }());
    })(activities = app.activities || (app.activities = {}));
})(app || (app = {}));
var app;
(function (app) {
    var activities;
    (function (activities) {
        var EventListComponent = (function () {
            function EventListComponent() {
            }
            EventListComponent = __decorate([
                app.Component('app.activities', 'ceActivityEventList', {
                    templateUrl: 'app/activities/activity-list/event-list.html',
                    bindings: {
                        events: '<'
                    }
                })
            ], EventListComponent);
            return EventListComponent;
        }());
    })(activities = app.activities || (app.activities = {}));
})(app || (app = {}));
var app;
(function (app) {
    var activities;
    (function (activities) {
        var InvolvementSelector = (function () {
            function InvolvementSelector() {
            }
            InvolvementSelector = __decorate([
                app.Component('app.activities', 'ceNewActivityInvolvementSelector', {
                    template: "\n    <md-button class=\"mt\" ng-click=\"$ctrl.onBack()\">\n      <md-icon>arrow_back</md-icon>\n      Back\n    </md-button>\n    <md-list>\n      <md-list-item ng-repeat=\"involvement in $ctrl.involvements\" ng-click=\"$ctrl.onInvolvementSelected({involvement})\">\n        <span>{{involvement.idNumber}}</span>\n        <md-icon class=\"md-secondary\" ng-if=\"$ctrl.selectedInvolvement === involvement\">check</md-icon>\n      </md-list-item>\n    </md-list>",
                    bindings: {
                        involvements: '<',
                        selectedInvolvement: '<',
                        onBack: '&',
                        onInvolvementSelected: '&'
                    }
                })
            ], InvolvementSelector);
            return InvolvementSelector;
        }());
    })(activities = app.activities || (app.activities = {}));
})(app || (app = {}));
var app;
(function (app) {
    var activities;
    (function (activities) {
        var NewActivitySelector = (function () {
            function NewActivitySelector() {
            }
            NewActivitySelector = __decorate([
                app.Component('app.activities', 'ceNewActivitySelector', {
                    templateUrl: 'app/activities/new-activity/new-activity-selector.html',
                    bindings: {
                        activities: '<',
                        involvement: '<',
                        showInvolvementSelector: '<',
                        onActivitySelected: '&',
                        onChangeInvolvement: '&'
                    }
                })
            ], NewActivitySelector);
            return NewActivitySelector;
        }());
    })(activities = app.activities || (app.activities = {}));
})(app || (app = {}));
var app;
(function (app) {
    var activities;
    (function (activities_1) {
        var activities = [];
        var NewActivityDialogController = (function () {
            function NewActivityDialogController($mdDialog, common, datacontext, personId) {
                var _this = this;
                this.$mdDialog = $mdDialog;
                this.common = common;
                this.datacontext = datacontext;
                this.personId = personId;
                this.involvementSelectorVisible = false;
                this.activate = function () {
                    _this.getInvolvements();
                };
                this.getInvolvements = function () {
                    _this.datacontext.involvements
                        .getInvolvements({ personId: _this.personId, fields: 'id,idNumber,type,opeDate,isActive' })
                        .then(function (result) {
                        _this.involvements = result;
                        if (_this.involvements.length) {
                            _this.selectInvolvement(_this.involvements[0]);
                        }
                    });
                };
                'ngInject';
                'ngInject';
                this.activate();
            }
            NewActivityDialogController.prototype.selectInvolvement = function (involvement) {
                this.selectedInvolvement = involvement;
            };
            NewActivityDialogController.prototype.selectActivity = function (item) {
                this.common.$state.go(item.sref, { involvementId: this.selectedInvolvement.id });
                this.$mdDialog.hide();
            };
            NewActivityDialogController.$inject = ['$mdDialog', 'common', 'datacontext', 'personId'];
            NewActivityDialogController = __decorate([
                app.Controller('app.activities', 'NewActivityDialogController')
            ], NewActivityDialogController);
            return NewActivityDialogController;
        }());
    })(activities = app.activities || (app.activities = {}));
})(app || (app = {}));
var app;
(function (app) {
    var admin;
    (function (admin) {
        var AdminRepository = (function () {
            function AdminRepository(configuration, identityManagement) {
                this.configuration = configuration;
                this.identityManagement = identityManagement;
            }
            AdminRepository.$inject = ['configuration', 'identityManagement'];
            AdminRepository = __decorate([
                app.Inject('admin.configuration.repository', 'admin.identityManagement.repository'),
                app.Service('app.admin', 'admin.repository')
            ], AdminRepository);
            return AdminRepository;
        }());
    })(admin = app.admin || (app.admin = {}));
})(app || (app = {}));
var app;
(function (app) {
    var agencies;
    (function (agencies) {
        var AgencyView = (function () {
            function AgencyView($mdDialog, common) {
                this.$mdDialog = $mdDialog;
                this.common = common;
                'ngInject';
                'ngInject';
            }
            AgencyView.$inject = ['$mdDialog', 'common'];
            AgencyView = __decorate([
                app.Component('app.agencies', 'ceAgencyView', {
                    templateUrl: 'app/agencies/agency/agency-view.html',
                    bindings: {
                        agency: '<'
                    }
                })
            ], AgencyView);
            return AgencyView;
        }());
    })(agencies = app.agencies || (app.agencies = {}));
})(app || (app = {}));
var app;
(function (app) {
    var agencies;
    (function (agencies) {
        var details;
        (function (details) {
            var EntityDetailsView = (function () {
                function EntityDetailsView($mdDialog, common, datacontext) {
                    this.$mdDialog = $mdDialog;
                    this.common = common;
                    this.datacontext = datacontext;
                    this.isLoading = false;
                    'ngInject';
                    'ngInject';
                }
                EntityDetailsView.prototype.$onInit = function () {
                    this.currentTab = this.common.$state.current.name.split('.')[3];
                };
                EntityDetailsView.$inject = ['$mdDialog', 'common', 'datacontext'];
                EntityDetailsView = __decorate([
                    app.Component('app.agencies.details', 'ceAgencyDetailsView', {
                        templateUrl: 'app/agencies/details/details-view.html',
                        bindings: {
                            agency: '<'
                        }
                    })
                ], EntityDetailsView);
                return EntityDetailsView;
            }());
        })(details = agencies.details || (agencies.details = {}));
    })(agencies = app.agencies || (app.agencies = {}));
})(app || (app = {}));
var app;
(function (app) {
    var agencies;
    (function (agencies) {
        var FindAgency = (function () {
            function FindAgency(common, datacontext) {
                var _this = this;
                this.common = common;
                this.datacontext = datacontext;
                this.params = {
                    page: 1,
                    pageSize: 5
                };
                this.searched = false;
                this.refresh = function () {
                    _this.search();
                };
                this.search = function () {
                    _this.searched = true;
                    if (!_this.params.q) {
                        _this.searchResults = null;
                        return;
                    }
                    return _this.datacontext.agencies
                        .getAgencies(_this.params)
                        .then(function (result) {
                        _this.searchResults = result;
                    });
                };
                'ngInject';
                'ngInject';
            }
            FindAgency.prototype.navigate = function (agency) {
                var entity = {};
                entity.entityId = agency.id;
                entity.fullName = agency.fullName;
                entity.entityType = 'Agency';
                entity.isUser = false;
                this.common.$state.go('^.roles', { entity: entity });
            };
            FindAgency.$inject = ['common', 'datacontext'];
            FindAgency = __decorate([
                app.Component('app.agencies', 'ceAgencySearch', {
                    templateUrl: 'app/agencies/find-create-agency/agency-search.html',
                    bindings: {}
                })
            ], FindAgency);
            return FindAgency;
        }());
    })(agencies = app.agencies || (app.agencies = {}));
})(app || (app = {}));
var app;
(function (app) {
    var agencies;
    (function (agencies) {
        var CreateAgency = (function () {
            function CreateAgency(common, datacontext, addressHelper) {
                this.common = common;
                this.datacontext = datacontext;
                this.addressHelper = addressHelper;
                this.agency = {};
                this.address = {};
                this.isSubmitting = false;
                'ngInject';
                'ngInject';
            }
            CreateAgency.prototype.phoneChanged = function (phone) {
                this.agency.phoneNumber = phone;
            };
            CreateAgency.prototype.onPlaceSelected = function (place) {
                var _this = this;
                this.common.$timeout(function () {
                    angular.extend(_this.address, _this.addressHelper.mapFromGooglePlaceResult(place));
                });
            };
            CreateAgency.prototype.submit = function () {
                var _this = this;
                this.isSubmitting = true;
                var agencyPromise = this.datacontext.agencies
                    .createAgency(this.agency)
                    .then(function (agency) {
                    if (agency) {
                        angular.extend(_this.agency, agency);
                        _this.common.$mdToast.showSimple('Agency created');
                    }
                });
                if (this.address.line1) {
                    agencyPromise.then(function () {
                        _this.address.entityId = _this.agency.id;
                        _this.address.addressType = 'Commercial';
                        _this.address.isPrimary = true;
                        _this.datacontext.entities.createEntityAddress(_this.address);
                    });
                }
                agencyPromise.then(function () {
                    _this.isSubmitting = false;
                    var entity = {};
                    entity.entityId = _this.agency.id;
                    entity.fullName = _this.agency.name;
                    entity.entityType = 'Agency';
                    entity.isUser = false;
                    _this.common.$state.go('^.roles', { entity: entity });
                });
            };
            CreateAgency.$inject = ['common', 'datacontext', 'addressHelper'];
            CreateAgency = __decorate([
                app.Component('app.agencies', 'ceCreateAgency', {
                    templateUrl: 'app/agencies/find-create-agency/create-agency.html',
                    bindings: {}
                })
            ], CreateAgency);
            return CreateAgency;
        }());
    })(agencies = app.agencies || (app.agencies = {}));
})(app || (app = {}));
var app;
(function (app) {
    var agencies;
    (function (agencies) {
        var overview;
        (function (overview) {
            var AgencyImportantInformation = (function () {
                function AgencyImportantInformation($mdDialog, common, datacontext) {
                    this.$mdDialog = $mdDialog;
                    this.common = common;
                    this.datacontext = datacontext;
                    'ngInject';
                    'ngInject';
                }
                AgencyImportantInformation.prototype.$onInit = function () {
                    var _this = this;
                    this.datacontext.agencies.getImportantInformation(this.agency.id)
                        .then(function (result) { return _this.data = result; });
                };
                AgencyImportantInformation.$inject = ['$mdDialog', 'common', 'datacontext'];
                AgencyImportantInformation = __decorate([
                    app.Component('app.agencies.overview', 'ceAgencyImportantInformation', {
                        templateUrl: 'app/agencies/important/important.html',
                        bindings: {
                            agency: '<'
                        }
                    })
                ], AgencyImportantInformation);
                return AgencyImportantInformation;
            }());
        })(overview = agencies.overview || (agencies.overview = {}));
    })(agencies = app.agencies || (app.agencies = {}));
})(app || (app = {}));
var app;
(function (app) {
    var agencies;
    (function (agencies) {
        var overview;
        (function (overview) {
            var AgencyOverviewView = (function () {
                function AgencyOverviewView() {
                }
                AgencyOverviewView = __decorate([
                    app.Component('app.agencies.overview', 'ceAgencyOverviewView', {
                        templateUrl: 'app/agencies/overview/overview-view.html',
                        bindings: {
                            agency: '<'
                        }
                    })
                ], AgencyOverviewView);
                return AgencyOverviewView;
            }());
        })(overview = agencies.overview || (agencies.overview = {}));
    })(agencies = app.agencies || (app.agencies = {}));
})(app || (app = {}));
var app;
(function (app) {
    var agencies;
    (function (agencies) {
        var overview;
        (function (overview) {
            var AgencySummary = (function () {
                function AgencySummary(datacontext) {
                    this.datacontext = datacontext;
                    'ngInject';
                    'ngInject';
                }
                AgencySummary.prototype.$onInit = function () {
                };
                AgencySummary.$inject = ['datacontext'];
                AgencySummary = __decorate([
                    app.Component('app.agencies.overview', 'ceAgencySummary', {
                        templateUrl: 'app/agencies/summary/summary.html',
                        bindings: {
                            agency: '<'
                        }
                    })
                ], AgencySummary);
                return AgencySummary;
            }());
        })(overview = agencies.overview || (agencies.overview = {}));
    })(agencies = app.agencies || (app.agencies = {}));
})(app || (app = {}));
var app;
(function (app) {
    var analysis;
    (function (analysis) {
        var AnalysisDataService = (function () {
            function AnalysisDataService() {
            }
            AnalysisDataService.prototype.barSeries = function (chartData) {
                return _.chain(chartData)
                    .reverse()
                    .map(function (d, i) { return [d.data, i]; })
                    .value();
            };
            AnalysisDataService.prototype.barPercentageSeries = function (data) {
                var total = this.sum(data);
                return _.chain(data)
                    .map(function (x, i) { return [Math.round(x.data / total * 100), i]; })
                    .value();
            };
            AnalysisDataService.prototype.columnSeries = function (chartData) {
                return _.chain(chartData)
                    .map(function (d, i) { return [i, d.data]; })
                    .value();
            };
            AnalysisDataService.prototype.columnPercentageSeries = function (data) {
                var total = this.sum(data);
                return _.chain(data)
                    .map(function (x, i) { return [i, Math.round(x.data / total * 100)]; })
                    .value();
            };
            AnalysisDataService.prototype.sum = function (data) {
                return _.reduce(data, function (sum, x) { return sum + x.data; }, 0);
            };
            AnalysisDataService.prototype.ticks = function (chartData) {
                return _.chain(chartData)
                    .map(function (x) { return x.group0; })
                    .uniq()
                    .map(function (t, i) { return [i, t]; })
                    .value();
            };
            AnalysisDataService = __decorate([
                app.Service('app.analysis', 'analysisData')
            ], AnalysisDataService);
            return AnalysisDataService;
        }());
    })(analysis = app.analysis || (app.analysis = {}));
})(app || (app = {}));
var app;
(function (app) {
    var analysis;
    (function (analysis) {
        var baseUrl = 'api/analysis';
        var AnalysisRepository = (function () {
            function AnalysisRepository($http, exception) {
                this.$http = $http;
                this.exception = exception;
                'ngInject';
                'ngInject';
            }
            AnalysisRepository.prototype.addChart = function (dashboardId, data) {
                return this.$http.post("".concat(baseUrl, "/dashboards/").concat(dashboardId, "/charts"), data);
            };
            AnalysisRepository.prototype.createChart = function (data) {
                return this.$http.post("".concat(baseUrl, "/charts"), data);
            };
            AnalysisRepository.prototype.createQuery = function (data) {
                return this.$http.post("".concat(baseUrl, "/queries"), data);
            };
            AnalysisRepository.prototype.updateQuery = function (data) {
                return this.$http.put("".concat(baseUrl, "/queries/").concat(data.id), data);
            };
            AnalysisRepository.prototype.deleteChart = function (chartId) {
                return this.$http.delete("".concat(baseUrl, "/charts/").concat(chartId));
            };
            AnalysisRepository.prototype.deleteQuery = function (queryId) {
                return this.$http.delete("".concat(baseUrl, "/queries/").concat(queryId));
            };
            AnalysisRepository.prototype.downloadData = function (queryId) {
                var _this = this;
                return this.$http.get("".concat(baseUrl, "/queries/").concat(queryId, "/data/csv"), { responseType: 'arraybuffer' }).then(function (response) {
                    return {
                        buffer: response.data,
                        contentType: response.headers()['content-type'],
                        fileName: response.headers()['content-disposition'].split(';')[1].split('=')[1].replace(/"/g, '')
                    };
                }).catch(function (error) { return _this.exception.catcher('XHR for getCsvData failed')(error); });
            };
            AnalysisRepository.prototype.getChartData = function (chartId) {
                return this.$http.get("".concat(baseUrl, "/charts/").concat(chartId, "/data"));
            };
            AnalysisRepository.prototype.getChartList = function () {
                return this.$http.get("".concat(baseUrl, "/charts"));
            };
            AnalysisRepository.prototype.getChartListByQueryId = function (queryId) {
                return this.$http.get("".concat(baseUrl, "/queries/").concat(queryId, "/charts"));
            };
            AnalysisRepository.prototype.getDataSource = function (dataSourceId) {
                return this.$http.get("".concat(baseUrl, "/data-sources/").concat(dataSourceId));
            };
            AnalysisRepository.prototype.getDataSources = function () {
                return this.$http.get("".concat(baseUrl, "/data-sources"));
            };
            AnalysisRepository.prototype.getMyDashboard = function () {
                return this.$http.get("".concat(baseUrl, "/dashboards/my"));
            };
            AnalysisRepository.prototype.getQuery = function (queryId) {
                return this.$http.get("".concat(baseUrl, "/queries/").concat(queryId));
            };
            AnalysisRepository.prototype.getQueryList = function (params) {
                return this.$http.get("".concat(baseUrl, "/queries"), { params: params });
            };
            AnalysisRepository.prototype.getTabularData = function (queryId, params) {
                return this.$http.get("".concat(baseUrl, "/queries/").concat(queryId, "/data/tabular"), { params: params });
            };
            AnalysisRepository.prototype.previewChartData = function (queryId, data) {
                return this.$http.post("".concat(baseUrl, "/queries/").concat(queryId, "/data/chart"), data);
            };
            AnalysisRepository.prototype.previewTabularData = function (data) {
                return this.$http.post("".concat(baseUrl, "/queries/data/tabular"), data);
            };
            AnalysisRepository.prototype.removeChart = function (dashboardId, chartId) {
                return this.$http.delete("".concat(baseUrl, "/dashboards/").concat(dashboardId, "/charts/").concat(chartId));
            };
            AnalysisRepository.prototype.updateChartLayout = function (dashboardId, chartId, layout) {
                return this.$http.put("".concat(baseUrl, "/dashboards/").concat(dashboardId, "/charts/").concat(chartId, "/layout"), { layout: layout });
            };
            AnalysisRepository.prototype.updateQueryColumns = function (queryId, columns) {
                return this.$http.put("".concat(baseUrl, "/queries/").concat(queryId, "/metadata/columns"), { columns: columns });
            };
            AnalysisRepository.$inject = ['$http', 'exception'];
            __decorate([
                app.ApiExecutor()
            ], AnalysisRepository.prototype, "addChart", null);
            __decorate([
                app.ApiExecutor()
            ], AnalysisRepository.prototype, "createChart", null);
            __decorate([
                app.ApiExecutor()
            ], AnalysisRepository.prototype, "createQuery", null);
            __decorate([
                app.ApiExecutor()
            ], AnalysisRepository.prototype, "updateQuery", null);
            __decorate([
                app.ApiExecutor({ returnValue: true })
            ], AnalysisRepository.prototype, "deleteChart", null);
            __decorate([
                app.ApiExecutor({ returnValue: true })
            ], AnalysisRepository.prototype, "deleteQuery", null);
            __decorate([
                app.ApiExecutor()
            ], AnalysisRepository.prototype, "getChartData", null);
            __decorate([
                app.ApiExecutor()
            ], AnalysisRepository.prototype, "getChartList", null);
            __decorate([
                app.ApiExecutor()
            ], AnalysisRepository.prototype, "getChartListByQueryId", null);
            __decorate([
                app.ApiExecutor()
            ], AnalysisRepository.prototype, "getDataSource", null);
            __decorate([
                app.ApiExecutor()
            ], AnalysisRepository.prototype, "getDataSources", null);
            __decorate([
                app.ApiExecutor()
            ], AnalysisRepository.prototype, "getMyDashboard", null);
            __decorate([
                app.ApiExecutor()
            ], AnalysisRepository.prototype, "getQuery", null);
            __decorate([
                app.ApiExecutor()
            ], AnalysisRepository.prototype, "getQueryList", null);
            __decorate([
                app.ApiExecutor()
            ], AnalysisRepository.prototype, "getTabularData", null);
            __decorate([
                app.ApiExecutor()
            ], AnalysisRepository.prototype, "previewChartData", null);
            __decorate([
                app.ApiExecutor()
            ], AnalysisRepository.prototype, "previewTabularData", null);
            __decorate([
                app.ApiExecutor({ returnValue: true })
            ], AnalysisRepository.prototype, "removeChart", null);
            __decorate([
                app.ApiExecutor({ returnValue: true })
            ], AnalysisRepository.prototype, "updateChartLayout", null);
            __decorate([
                app.ApiExecutor({ returnValue: true })
            ], AnalysisRepository.prototype, "updateQueryColumns", null);
            AnalysisRepository = __decorate([
                app.Service('app.analysis', 'analysis.repository')
            ], AnalysisRepository);
            return AnalysisRepository;
        }());
    })(analysis = app.analysis || (app.analysis = {}));
})(app || (app = {}));
var app;
(function (app) {
    var alerts;
    (function (alerts) {
        var baseUrl = 'api/alerts';
        var AlertRepository = (function () {
            function AlertRepository($http) {
                this.$http = $http;
                'ngInject';
                'ngInject';
            }
            AlertRepository.prototype.clearNotification = function (notificationId) {
                return this.$http.put("".concat(baseUrl, "/notifications/").concat(notificationId, "/clear"), null);
            };
            AlertRepository.prototype.getNotificationPreferences = function () {
                return this.$http.get("".concat(baseUrl, "/notification-preferences"));
            };
            AlertRepository.prototype.getNotifications = function (params) {
                return this.$http.get("".concat(baseUrl, "/notifications"), { params: params });
            };
            AlertRepository.prototype.getNotificationsCount = function () {
                return this.$http.get("".concat(baseUrl, "/notifications/$count"));
            };
            AlertRepository.prototype.savePreferences = function (data) {
                return this.$http.post("".concat(baseUrl, "/notification-preferences"), data);
            };
            __decorate([
                app.ApiExecutor({ returnValue: true })
            ], AlertRepository.prototype, "clearNotification", null);
            __decorate([
                app.ApiExecutor()
            ], AlertRepository.prototype, "getNotificationPreferences", null);
            __decorate([
                app.ApiExecutor()
            ], AlertRepository.prototype, "getNotifications", null);
            __decorate([
                app.ApiExecutor()
            ], AlertRepository.prototype, "getNotificationsCount", null);
            __decorate([
                app.ApiExecutor({ returnValue: true })
            ], AlertRepository.prototype, "savePreferences", null);
            return AlertRepository;
        }());
        angular
            .module('app.alerts')
            .service('alerts.repository', AlertRepository);
    })(alerts = app.alerts || (app.alerts = {}));
})(app || (app = {}));
var app;
(function (app) {
    var calendars;
    (function (calendars) {
        var CalendarEventSchedule = (function () {
            function CalendarEventSchedule(common, datacontext, $mdDialog) {
                var _this = this;
                this.common = common;
                this.datacontext = datacontext;
                this.$mdDialog = $mdDialog;
                this.eventSchedule = {};
                this.scheduleData = {};
                this.deleteEventSchedule = function () {
                    _this.isSubmitting = true;
                    _this.datacontext.calendars.deleteEventSchedule(_this.eventSchedule.id)
                        .then(function () {
                        _this.common.$mdToast.showSimple("Event Series Deleted");
                        _this.$mdDialog.hide();
                        _this.common.$state.go('^.calendar');
                    })
                        .finally(function () { return _this.isSubmitting = false; });
                };
                'ngInject';
                'ngInject';
            }
            CalendarEventSchedule.prototype.$onInit = function () {
                var _this = this;
                this.eventSchedule.scheduleData = this.scheduleData;
                this.users = this.users || [];
                this.sources = this.sources || [];
                this.processTargetDate();
                this.eventSchedule.scheduleData.startDate = this.startDate;
                this.eventSchedule.scheduleData.endDate = this.endDate;
                this.eventSchedule.scheduleData.startTime = this.startTime;
                this.eventSchedule.scheduleData.endTime = this.endTime;
                if (this.eventScheduleId) {
                    this.action = 'Update';
                    this.loadEventSchedule();
                }
                else {
                    this.action = 'Create';
                    this.datacontext.users.getCurrentUserBasic()
                        .then(function (result) { return _this.users.push({ userId: result.id, fullName: result.fullName }); });
                    if (this.sourceId) {
                        this.sources.push({ involvementId: this.involvementId, source: this.source, sourceDescription: this.sourceName, sourceId: this.sourceId });
                    }
                }
                this.datacontext.config.lookups.getLookupType('calendar-source-types')
                    .then(function (result) {
                    _this.calendarSourceTypes = result.lookups.map(function (l) { return l.value; });
                });
            };
            CalendarEventSchedule.prototype.transformChip = function (chip) {
                if (angular.isObject(chip)) {
                    var index = _.findIndex(this.users, function (user) { return user.id === chip.id; });
                    if (index > -1) {
                        return null;
                    }
                    else {
                        return chip;
                    }
                }
            };
            CalendarEventSchedule.prototype.loadEventSchedule = function () {
                var _this = this;
                this.datacontext.calendars
                    .getEventSchedule(this.eventScheduleId)
                    .then(function (result) {
                    angular.extend(_this.eventSchedule, result);
                });
                this.datacontext.calendars
                    .getEventScheduleStaff(this.eventScheduleId)
                    .then(function (result) {
                    angular.extend(_this.users, result);
                });
                this.datacontext.calendars
                    .getEventScheduleSources(this.eventScheduleId)
                    .then(function (result) {
                    _.forEach(result, function (r) {
                        _this.sources.push({
                            involvementId: r.involvementId,
                            source: r.source,
                            sourceDescription: (r.idNumber ? r.idNumber + ' ' : '') + r.sourceName,
                            sourceId: r.sourceId
                        });
                    });
                });
            };
            CalendarEventSchedule.prototype.onFrequencyChange = function () {
                this.resetSchedule();
                if (this.eventSchedule.scheduleData.frequency !== 'Single') {
                    this.openFrequencyDialog();
                }
            };
            CalendarEventSchedule.prototype.resetSchedule = function () {
                this.eventSchedule.scheduleData.interval = null;
                this.eventSchedule.scheduleData.skipInterval = null;
                this.eventSchedule.scheduleData.repeatBy = null;
                this.eventSchedule.scheduleData.dayOfMonth = null;
                this.eventSchedule.scheduleData.weekNumber = null;
                this.eventSchedule.scheduleData.dayOfWeek = null;
                this.eventSchedule.scheduleData.weekdays = null;
                this.eventSchedule.scheduleData.endDate = null;
                this.eventSchedule.scheduleData.summary = null;
            };
            CalendarEventSchedule.prototype.openFrequencyDialog = function () {
                var _this = this;
                var parent = angular.element(document.body);
                return this.$mdDialog.show({
                    parent: parent,
                    fullscreen: false,
                    templateUrl: 'app/calendars/event-schedule/frequency-dialog.html',
                    controller: 'FrequencyDialogController',
                    controllerAs: '$ctrl',
                    locals: {
                        schedule: this.eventSchedule.scheduleData
                    }
                })
                    .then(function (schedule) {
                    return _this.frequencyChanged(schedule);
                })
                    .catch(function (error) { if (error)
                    throw error; });
            };
            CalendarEventSchedule.prototype.frequencyChanged = function (schedule) {
                this.eventSchedule.scheduleData = schedule;
            };
            CalendarEventSchedule.prototype.userSearch = function (searchText) {
                return this.datacontext.users
                    .getUsers(searchText);
            };
            CalendarEventSchedule.prototype.addSource = function (sourceView) {
                if (!sourceView)
                    return;
                var exists = _.find(this.sources, function (c) { return c.sourceId === sourceView.sourceId; });
                if (!exists) {
                    this.sources.push(sourceView);
                }
            };
            CalendarEventSchedule.prototype.removeSource = function (sourceView) {
                _.remove(this.sources, function (s) { return s.sourceId === sourceView.sourceId; });
            };
            CalendarEventSchedule.prototype.processTargetDate = function () {
                var t;
                if (this.targetDate) {
                    t = angular.copy(this.targetDate);
                    if (t.hours() === 0) {
                        t.add(moment().hours() + 1, 'hours');
                    }
                }
                else {
                    t = moment().hours(moment().hours() + 1).minutes(0);
                }
                this.startDate = new Date(t.year(), t.month(), t.date());
                this.endDate = new Date(t.year(), t.month(), t.date());
                this.startTime = new Date(t.year(), t.month(), t.date(), t.hours(), t.minutes());
                this.endTime = new Date(t.year(), t.month(), t.date(), t.hours() + 1, t.minutes());
            };
            CalendarEventSchedule.prototype.cancel = function () {
                this.common.$state.go('^.calendar');
            };
            CalendarEventSchedule.prototype.delete = function () {
                this.common.confirmation.show({
                    title: 'Delete Event Series',
                    message: 'Are you sure you want to delete All Events in this Series?',
                    ok: 'Delete',
                    cancel: 'Keep'
                }).then(this.deleteEventSchedule);
            };
            CalendarEventSchedule.prototype.isValid = function () {
                if ((this.users.length > 0) &&
                    (this.eventSchedule.scheduleData.frequency === 'Single' && (!this.eventSchedule.scheduleData.summary || this.eventSchedule.scheduleData.summary.substr(0, 11) === 'Occurs once')) ||
                    (this.eventSchedule.scheduleData.frequency !== 'Single' && this.eventSchedule.scheduleData.summary)) {
                    return true;
                }
                else {
                    return false;
                }
            };
            CalendarEventSchedule.prototype.submit = function () {
                var _this = this;
                this.isSubmitting = true;
                if (this.eventSchedule.scheduleData.allDay) {
                    this.eventSchedule.scheduleData.startTime = new Date(this.eventSchedule.scheduleData.startDate.getFullYear(), this.eventSchedule.scheduleData.startDate.getMonth(), this.eventSchedule.scheduleData.startDate.getDate());
                    this.eventSchedule.scheduleData.endTime = null;
                }
                else {
                    this.eventSchedule.scheduleData.startTime = new Date(this.eventSchedule.scheduleData.startDate.getFullYear(), this.eventSchedule.scheduleData.startDate.getMonth(), this.eventSchedule.scheduleData.startDate.getDate(), this.eventSchedule.scheduleData.startTime.getHours(), this.eventSchedule.scheduleData.startTime.getMinutes());
                    this.eventSchedule.scheduleData.endTime = new Date(this.eventSchedule.scheduleData.startDate.getFullYear(), this.eventSchedule.scheduleData.startDate.getMonth(), this.eventSchedule.scheduleData.startDate.getDate(), this.eventSchedule.scheduleData.endTime.getHours(), this.eventSchedule.scheduleData.endTime.getMinutes());
                }
                var eventPromise = this.action === 'Create'
                    ? this.datacontext.calendars.createEventSchedule(this.eventSchedule)
                    : this.datacontext.calendars.updateEventSchedule(this.eventSchedule);
                var staffPromise = eventPromise.then(function (eventSchedule) {
                    return _this.datacontext.calendars.updateEventScheduleStaff(eventSchedule.id, _.map(_this.users, function (u) { return u.userId || u.id; }));
                });
                var casePromise = eventPromise.then(function (eventSchedule) {
                    return _this.datacontext.calendars.updateEventScheduleSources(eventSchedule.id, _this.sources);
                });
                this.common.$q.all([eventPromise, staffPromise, casePromise])
                    .then(function () {
                    _this.common.$mdToast.showSimple("Event ".concat(_this.action, "d"));
                    _this.$mdDialog.hide();
                    _this.common.$state.go('^.calendar');
                })
                    .finally(function () { return _this.isSubmitting = false; });
            };
            CalendarEventSchedule.$inject = ['common', 'datacontext', '$mdDialog'];
            CalendarEventSchedule = __decorate([
                app.Component('app.calendars', 'ceCalendarEventSchedule', {
                    templateUrl: 'app/calendars/event-schedule/event-schedule.html',
                    bindings: {
                        eventScheduleId: '<',
                        targetDate: '<',
                        involvementId: '<',
                        source: '@',
                        sourceId: '<',
                        sourceName: '<'
                    }
                })
            ], CalendarEventSchedule);
            return CalendarEventSchedule;
        }());
    })(calendars = app.calendars || (app.calendars = {}));
})(app || (app = {}));
var app;
(function (app) {
    var calendars;
    (function (calendars) {
        var FrequencyDialogController = (function () {
            function FrequencyDialogController($mdDialog, common, datacontext, schedule) {
                this.$mdDialog = $mdDialog;
                this.common = common;
                this.datacontext = datacontext;
                this.schedule = schedule;
                this.numberToString = [
                    { key: 1, value: '1st' },
                    { key: 2, value: '2nd' },
                    { key: 3, value: '3rd' },
                    { key: 4, value: '4th' },
                    { key: 5, value: '5th' },
                    { key: 6, value: '6th' },
                    { key: 7, value: '7th' },
                    { key: 8, value: '8th' },
                    { key: 9, value: '9th' },
                    { key: 10, value: '10th' },
                    { key: 11, value: '11th' },
                    { key: 12, value: '12th' },
                    { key: 13, value: '13th' },
                    { key: 14, value: '14th' },
                    { key: 15, value: '15th' },
                    { key: 16, value: '16th' },
                    { key: 17, value: '17th' },
                    { key: 18, value: '18th' },
                    { key: 19, value: '19th' },
                    { key: 20, value: '20th' },
                    { key: 21, value: '21st' },
                    { key: 22, value: '22nd' },
                    { key: 23, value: '23rd' },
                    { key: 24, value: '24th' },
                    { key: 25, value: '25th' },
                    { key: 26, value: '26th' },
                    { key: 27, value: '27th' },
                    { key: 28, value: '28th' },
                    { key: 29, value: '29th' },
                    { key: 30, value: '30th' },
                    { key: 31, value: '31st' }
                ];
                this.daysOfWeek = [
                    'Sunday',
                    'Monday',
                    'Tuesday',
                    'Wednesday',
                    'Thursday',
                    'Friday',
                    'Saturday'
                ];
                'ngInject';
                'ngInject';
                this.schedule.weekdays = this.schedule.weekdays || [];
                this.minDate = moment().toDate();
            }
            FrequencyDialogController.prototype.cancel = function () {
                this.$mdDialog.cancel();
            };
            FrequencyDialogController.prototype.hasWeekday = function (day) {
                return this.schedule.weekdays.indexOf(day) > -1;
            };
            FrequencyDialogController.prototype.toggleWeekday = function (day) {
                var _this = this;
                var index = this.schedule.weekdays.indexOf(day);
                if (index > -1) {
                    this.schedule.weekdays.splice(index, 1);
                }
                else {
                    this.schedule.weekdays.push(day);
                }
                this.schedule.weekdays = _.sortBy(this.schedule.weekdays, function (d) {
                    return _.findIndex(_this.daysOfWeek, function (dow) { return d === dow; });
                });
            };
            FrequencyDialogController.prototype.selectDayOfMonth = function () {
                this.schedule.repeatBy = 'DayOfMonth';
                delete this.schedule.dayOfWeek;
                delete this.schedule.weekNumber;
            };
            FrequencyDialogController.prototype.selectDayOfWeek = function () {
                this.schedule.repeatBy = 'DayOfWeek';
                delete this.schedule.dayOfMonth;
            };
            FrequencyDialogController.prototype.submit = function () {
                this.buildDescription();
                this.$mdDialog.hide(this.schedule);
            };
            FrequencyDialogController.prototype.buildDescription = function () {
                var d = "Every ".concat(this.schedule.interval > 1 ? this.schedule.interval : '');
                if (this.schedule.frequency === 'Weekly') {
                    d += " week".concat(this.schedule.interval > 1 ? 's' : '', " on ");
                    d += this.schedule.weekdays.join(', ');
                }
                else {
                    d += " month".concat(this.schedule.interval > 1 ? 's' : '', " on ");
                    if (this.schedule.repeatBy === 'DayOfWeek') {
                        if (this.schedule.weekNumber === 5) {
                            d += ' the last';
                        }
                        else {
                            d += " the ".concat(this.numberToString[this.schedule.weekNumber - 1].value);
                        }
                        d += " ".concat(this.schedule.dayOfWeek);
                    }
                    else {
                        d += " the ".concat(this.numberToString[this.schedule.dayOfMonth - 1].value, " ");
                    }
                }
                if (this.schedule.endDate) {
                    d += " until ".concat(this.schedule.endDate.toLocaleDateString('en-US'));
                }
                this.schedule.summary = d.replace(/  /g, ' ');
            };
            FrequencyDialogController.$inject = ['$mdDialog', 'common', 'datacontext', 'schedule'];
            FrequencyDialogController = __decorate([
                app.Controller('app.calendars', 'FrequencyDialogController')
            ], FrequencyDialogController);
            return FrequencyDialogController;
        }());
    })(calendars = app.calendars || (app.calendars = {}));
})(app || (app = {}));
var app;
(function (app) {
    var calendars;
    (function (calendars) {
        var CalendarEventSchedule = (function () {
            function CalendarEventSchedule(common, datacontext, $mdDialog) {
                var _this = this;
                this.common = common;
                this.datacontext = datacontext;
                this.$mdDialog = $mdDialog;
                this.deleteEvent = function () {
                    _this.isSubmitting = true;
                    var event = {
                        id: _this.event.id,
                        eventScheduleId: _this.event.eventScheduleId,
                        startTime: _this.event.startTime,
                        endTime: _this.event.endTime,
                    };
                    _this.datacontext.calendars.deleteEvent(event)
                        .then(function () {
                        _this.common.$mdToast.showSimple("Event Deleted");
                        _this.$mdDialog.hide();
                        _this.common.$state.go('^.calendar');
                    })
                        .finally(function () { return _this.isSubmitting = false; });
                };
                'ngInject';
                'ngInject';
            }
            CalendarEventSchedule.prototype.$onInit = function () {
                this.event.rescheduledStartTime = this.event.rescheduledStartTime || this.event.startTime;
                this.event.rescheduledEndTime = this.event.rescheduledEndTime || this.event.endTime;
            };
            CalendarEventSchedule.prototype.cancel = function () {
                this.common.$state.go('^.calendar');
            };
            CalendarEventSchedule.prototype.delete = function () {
                this.common.confirmation.show({
                    title: 'Delete Event',
                    message: 'Are you sure you want to delete this Event?',
                    ok: 'Delete',
                    cancel: 'Keep'
                }).then(this.deleteEvent);
            };
            CalendarEventSchedule.prototype.submit = function () {
                var _this = this;
                this.isSubmitting = true;
                if (this.event.allDay) {
                    this.event.rescheduledStartTime = new Date(this.event.rescheduledStartTime.getFullYear(), this.event.rescheduledStartTime.getMonth(), this.event.rescheduledStartTime.getDate());
                    this.event.rescheduledEndTime = null;
                }
                else {
                    this.event.rescheduledEndTime = new Date(this.event.rescheduledStartTime.getFullYear(), this.event.rescheduledStartTime.getMonth(), this.event.rescheduledStartTime.getDate(), this.event.rescheduledEndTime.getHours(), this.event.rescheduledEndTime.getMinutes());
                }
                var event = {
                    id: this.event.id,
                    eventScheduleId: this.event.eventScheduleId,
                    rescheduledStartTime: this.event.rescheduledStartTime,
                    rescheduledEndTime: this.event.rescheduledEndTime,
                    startTime: this.event.startTime,
                    endTime: this.event.endTime,
                };
                this.datacontext.calendars.rescheduleEvent(event)
                    .then(function () {
                    _this.common.$mdToast.showSimple("Event Rescheduled");
                    _this.$mdDialog.hide();
                    _this.common.$state.go('^.calendar');
                })
                    .finally(function () { return _this.isSubmitting = false; });
            };
            CalendarEventSchedule.$inject = ['common', 'datacontext', '$mdDialog'];
            CalendarEventSchedule = __decorate([
                app.Component('app.calendars', 'ceCalendarEvent', {
                    templateUrl: 'app/calendars/events/event.html',
                    bindings: {
                        event: '<'
                    }
                })
            ], CalendarEventSchedule);
            return CalendarEventSchedule;
        }());
    })(calendars = app.calendars || (app.calendars = {}));
})(app || (app = {}));
var app;
(function (app) {
    var calendars;
    (function (calendars) {
        var EventSummaryDialogController = (function () {
            function EventSummaryDialogController($mdDialog, common, datacontext, event, eventScheduleId) {
                this.$mdDialog = $mdDialog;
                this.common = common;
                this.datacontext = datacontext;
                this.event = event;
                this.eventScheduleId = eventScheduleId;
                'ngInject';
                'ngInject';
            }
            EventSummaryDialogController.prototype.$onInit = function () {
                var _this = this;
                switch (this.event.type) {
                    case 'event':
                        this.datacontext.calendars.getEventSummary(this.event)
                            .then(function (summary) {
                            return angular.extend(_this.event, summary);
                        });
                        this.datacontext.calendars.getEventScheduleStaff(this.eventScheduleId)
                            .then(function (staff) {
                            return _this.event.staff = staff;
                        });
                        this.datacontext.calendars.getEventScheduleSources(this.eventScheduleId)
                            .then(function (sources) {
                            return _this.event.sources = sources;
                        });
                        break;
                    case 'sol':
                        this.datacontext.involvements.getBasicInfo(this.event.involvementId)
                            .then(function (involvement) {
                            _this.event.staff = [];
                            _this.event.staff[0] = {
                                userId: involvement.primaryStaffUserId,
                                fullName: involvement.primaryStaffFullName
                            };
                            _this.event.sources = [];
                            _this.event.sources[0] = {
                                sourceId: involvement.caseId,
                                source: involvement.caseType,
                                sourceName: involvement.caseName,
                                idNumber: involvement.idNumber,
                                involvementId: involvement.involvementId,
                            };
                        });
                        break;
                }
            };
            EventSummaryDialogController.prototype.editEvent = function () {
                this.$mdDialog.hide(event);
            };
            EventSummaryDialogController.prototype.editEventSchedule = function () {
                this.$mdDialog.hide('eventSchedule');
            };
            EventSummaryDialogController.prototype.cancel = function () {
                this.$mdDialog.cancel();
            };
            EventSummaryDialogController.$inject = ['$mdDialog', 'common', 'datacontext', 'event', 'eventScheduleId'];
            EventSummaryDialogController = __decorate([
                app.Controller('app.calendars', 'EventSummaryDialogController')
            ], EventSummaryDialogController);
            return EventSummaryDialogController;
        }());
    })(calendars = app.calendars || (app.calendars = {}));
})(app || (app = {}));
var app;
(function (app) {
    var calendars;
    (function (calendars) {
        var CalendarStateService = (function () {
            function CalendarStateService($localStorage) {
                var _this = this;
                this.$localStorage = $localStorage;
                this.state = { calendarIds: [], calendars: [], userIds: [], users: [] };
                this.loadState = function () {
                    if (_this.$localStorage['calendarState']) {
                        angular.extend(_this.state, _this.$localStorage['calendarState']);
                    }
                    else {
                        _this.$localStorage['calendarState'] = _this.state;
                    }
                    if (_this.state.dateRange) {
                        _this.state.dateRange = moment.range(_this.state.dateRange.start, _this.state.dateRange.end);
                    }
                };
                'ngInject';
                'ngInject';
                if ($localStorage.calendarState) {
                    delete $localStorage.calendarState.dateRange;
                    delete $localStorage.calendarState.viewType;
                    delete $localStorage.calendarState.viewDate;
                }
                this.loadState();
            }
            CalendarStateService.prototype.save = function () {
                this.$localStorage['calendarState'] = this.state;
            };
            CalendarStateService.prototype.update = function (state) {
                angular.extend(this.state, state);
                angular.extend(this.$localStorage['calendarState'], state);
            };
            return CalendarStateService;
        }());
        angular
            .module('app.calendars')
            .service('calendarState', CalendarStateService);
    })(calendars = app.calendars || (app.calendars = {}));
})(app || (app = {}));
var app;
(function (app) {
    var calendars;
    (function (calendars) {
        var CalendarDataService = (function () {
            function CalendarDataService(common, datacontext, calendarState) {
                var _this = this;
                this.common = common;
                this.datacontext = datacontext;
                this.calendarState = calendarState;
                this.state = this.calendarState.state;
                this.refresh = function () {
                    var params = {
                        calendars: _this.state.calendarIds,
                        userIds: _this.state.userIds,
                        from: _this.state.dateRange.start.toDate(),
                        to: _this.state.dateRange.end.toDate(),
                        highPriorityOnly: _this.state.viewType === 'highPriority'
                    };
                    _this.eventSources.splice(0);
                    _this.getEvents(params);
                };
                this.activate = function () {
                    return _this.getCalendars();
                };
                this.getCalendars = function () {
                    return _this.datacontext.calendars.getCalendars().then(function (result) {
                        _this.calendarState.update({ calendars: result });
                    }).finally(function () {
                        var calendarIds = [];
                        angular.forEach(_this.state.calendars, function (c) { return calendarIds.push(c.id); });
                        _this.calendarState.update({ calendarIds: calendarIds });
                    });
                };
                this.getEvents = function (params) {
                    _this.datacontext.calendars
                        .getEvents(params)
                        .then(function (result) {
                        if (result) {
                            var results = _(result);
                            var events = results.map(function (e) { return _this.mapEvent(e); }).value();
                            _this.eventSources.push({ events: events });
                        }
                    });
                };
                this.mapEvent = function (e) {
                    var start = moment(e.rescheduledStartTime || e.startTime);
                    var end;
                    if (e.allDay) {
                        start = moment(e.eventDate).startOf('day');
                        end = moment(e.eventDate).endOf('day');
                    }
                    else {
                        end = moment(e.rescheduledEndTime || e.endTime);
                    }
                    var calendar = _.find(_this.state.calendars, function (c) { return c.id === e.calendar; });
                    var color;
                    var isPast = end.diff(moment()) < 0;
                    if (isPast) {
                        color = calendar.completedColor;
                    }
                    else {
                        color = calendar.color;
                    }
                    return __assign(__assign({}, e), { title: "".concat(e.title), color: color, start: start, end: end });
                };
                'ngInject';
                'ngInject';
                this.eventSources = [];
                this.activate();
            }
            return CalendarDataService;
        }());
        angular
            .module('app.calendars')
            .service('calendarData', CalendarDataService);
    })(calendars = app.calendars || (app.calendars = {}));
})(app || (app = {}));
var app;
(function (app) {
    var calendars;
    (function (calendars) {
        var baseUrl = 'api/calendars';
        var CalendarRepository = (function () {
            function CalendarRepository($http, exception) {
                this.$http = $http;
                this.exception = exception;
                'ngInject';
                'ngInject';
            }
            CalendarRepository.prototype.getCalendars = function () {
                return this.$http.get(baseUrl);
            };
            CalendarRepository.prototype.getEvents = function (params) {
                return this.$http.get("".concat(baseUrl, "/events"), { params: params });
            };
            CalendarRepository.prototype.getEventSchedule = function (id) {
                return this.$http.get("".concat(baseUrl, "/eventschedules/").concat(id));
            };
            CalendarRepository.prototype.getEventSummary = function (event) {
                var startTime = moment(event.startTime).format();
                var endTime = event.allDay ? null : moment(event.endTime).format();
                return this.$http.get("".concat(baseUrl, "/events/").concat(event.id, "/summary?startTime=").concat(startTime, "&endTime=").concat(endTime, "&eventScheduleId=").concat(event.eventScheduleId));
            };
            CalendarRepository.prototype.getEventScheduleStaff = function (eventScheduleId) {
                return this.$http.get("".concat(baseUrl, "/eventschedules/").concat(eventScheduleId, "/staff"));
            };
            CalendarRepository.prototype.getEventScheduleSources = function (eventScheduleId) {
                return this.$http.get("".concat(baseUrl, "/eventschedules/").concat(eventScheduleId, "/sources"));
            };
            CalendarRepository.prototype.rescheduleEvent = function (event) {
                return this.$http.put("".concat(baseUrl, "/events/").concat(event.id, "/reschedule"), event);
            };
            CalendarRepository.prototype.createEventSchedule = function (eventSchedule) {
                return this.$http.post("".concat(baseUrl, "/eventschedules"), eventSchedule);
            };
            CalendarRepository.prototype.updateEventSchedule = function (eventSchedule) {
                return this.$http.put("".concat(baseUrl, "/eventschedules/").concat(eventSchedule.id), eventSchedule);
            };
            CalendarRepository.prototype.updateEventScheduleStaff = function (eventScheduleId, staffIds) {
                return this.$http.put("".concat(baseUrl, "/eventschedules/").concat(eventScheduleId, "/staff"), { eventScheduleId: eventScheduleId, staffIds: staffIds });
            };
            CalendarRepository.prototype.updateEventScheduleSources = function (eventScheduleId, sources) {
                return this.$http.put("".concat(baseUrl, "/eventschedules/").concat(eventScheduleId, "/sources"), { eventScheduleId: eventScheduleId, sources: sources });
            };
            CalendarRepository.prototype.deleteEvent = function (event) {
                return this.$http.put("".concat(baseUrl, "/events/").concat(event.id, "/delete"), event);
            };
            CalendarRepository.prototype.deleteEventSchedule = function (eventScheduleId) {
                return this.$http.delete("".concat(baseUrl, "/eventschedules/").concat(eventScheduleId));
            };
            CalendarRepository.prototype.getNext10Events = function (userIds) {
                return this.$http.get("".concat(baseUrl, "/events/next10Events"), { params: { userIds: userIds } });
            };
            __decorate([
                app.ApiExecutor()
            ], CalendarRepository.prototype, "getCalendars", null);
            __decorate([
                app.ApiExecutor()
            ], CalendarRepository.prototype, "getEvents", null);
            __decorate([
                app.ApiExecutor()
            ], CalendarRepository.prototype, "getEventSchedule", null);
            __decorate([
                app.ApiExecutor()
            ], CalendarRepository.prototype, "getEventSummary", null);
            __decorate([
                app.ApiExecutor()
            ], CalendarRepository.prototype, "getEventScheduleStaff", null);
            __decorate([
                app.ApiExecutor()
            ], CalendarRepository.prototype, "getEventScheduleSources", null);
            __decorate([
                app.ApiExecutor()
            ], CalendarRepository.prototype, "rescheduleEvent", null);
            __decorate([
                app.ApiExecutor()
            ], CalendarRepository.prototype, "createEventSchedule", null);
            __decorate([
                app.ApiExecutor()
            ], CalendarRepository.prototype, "updateEventSchedule", null);
            __decorate([
                app.ApiExecutor()
            ], CalendarRepository.prototype, "updateEventScheduleStaff", null);
            __decorate([
                app.ApiExecutor()
            ], CalendarRepository.prototype, "updateEventScheduleSources", null);
            __decorate([
                app.ApiExecutor()
            ], CalendarRepository.prototype, "deleteEvent", null);
            __decorate([
                app.ApiExecutor()
            ], CalendarRepository.prototype, "deleteEventSchedule", null);
            __decorate([
                app.ApiExecutor()
            ], CalendarRepository.prototype, "getNext10Events", null);
            return CalendarRepository;
        }());
        angular
            .module('app.calendars')
            .service('calendars.repository', CalendarRepository);
    })(calendars = app.calendars || (app.calendars = {}));
})(app || (app = {}));
var app;
(function (app) {
    var clientCases;
    (function (clientCases) {
        var activities;
        (function (activities) {
            activities.showCovidVaccine = function (datacontext) {
                'ngInject';
                var showCovidVaccinePromise = datacontext.config.getConfigurationParameter('Activity.CovidVaccine', false);
                return showCovidVaccinePromise.then(function (result) {
                    return result.toString() === 'true';
                });
            };
        })(activities = clientCases.activities || (clientCases.activities = {}));
    })(clientCases = app.clientCases || (app.clientCases = {}));
})(app || (app = {}));
var app;
(function (app) {
    var clientCases;
    (function (clientCases) {
        var activities;
        (function (activities) {
            var routes = [
                {
                    name: 'app.clientCases.activities',
                    url: '/activities',
                    template: '<ui-view layout="column" flex></ui-view>',
                    redirectTo: 'app.clientCases.activities.list'
                },
                {
                    name: 'app.clientCases.activities.list',
                    url: '',
                    template: "<ce-client-case-activity-list\n                        case=\"$resolve.case\"\n                        show-Covid-Vaccine=\"$resolve.flag\"\n                        layout=\"column\" flex>\n                    </ce-client-case-activity-list>",
                    data: {
                        title: 'Client Case Activities',
                        permissions: ['access_activities']
                    },
                    resolve: { flag: activities.showCovidVaccine }
                },
                {
                    name: 'app.clientCases.activities.note',
                    url: '/note',
                    template: "<ce-client-cases-activities-note layout=\"column\" flex\n                        involvement-id=\"$resolve.case.involvementId\"\n                        case-Id=\"$resolve.case.id\"\n                        activity-Type=\"ClientCaseNote\"\n                        name=\"Note\"\n                        activity=\"$resolve.activity\"\n                        case-open-date=\"$resolve.case.openDate\"\n                        return-sref=\"app.clientCases.activities.list\">\n                    </ce-client-cases-activities-note>",
                    params: { activity: null },
                    resolve: { activity: ['$stateParams', function (p) { return p.activity; }] },
                    data: { title: 'Note' }
                },
                {
                    name: 'app.clientCases.activities.conflictCheck',
                    url: '/conflictCheck',
                    template: "<ce-conflict-check layout=\"column\" flex\n                     involvement-id=\"$resolve.case.involvementId\"\n                     source=\"Client Case\"\n                     source-Id=\"$resolve.case.id\"\n                     case-open-date=\"$resolve.case.openDate\">\n                    </ce-conflict-check>",
                    data: { title: 'Conflict Check' }
                },
                {
                    name: 'app.clientCases.activities.close',
                    url: '/close',
                    template: "<ce-client-cases-activities-close\n                     case=\"$resolve.case\" layout=\"column\" flex>\n                    </ce-client-cases-activities-close>",
                    data: { title: 'Close' }
                },
                {
                    name: 'app.clientCases.activities.reopen',
                    url: '/reopen',
                    template: "<ce-client-cases-activities-reopen\n                     case=\"$resolve.case\" flex>\n                    </ce-client-cases-activities-reopen>",
                    data: { title: 'Reopen' }
                },
                {
                    name: 'app.clientCases.activities.caseAssessmentMeeting',
                    url: '/cam',
                    template: "<ce-client-case-assessment-meeting\n                     involvement-id=\"$resolve.case.involvementId\"\n                     source=\"Client Case\"\n                     source-id=\"$resolve.case.id\"\n                     show-communication-date=\"true\"\n                     return-sref=\"app.clientCases.activities.list\"\n                     activity=\"$resolve.activity\"\n                     case-open-date=\"$resolve.case.openDate\"\n                     flex>\n                    </ce-client-case-assessment-meeting>",
                    params: { activity: null },
                    resolve: { activity: ['$stateParams', function (p) { return p.activity; }] },
                    data: { title: 'CAM' }
                },
                {
                    name: 'app.clientCases.activities.caseReview',
                    url: '/review',
                    template: "<ce-client-case-review\n                     involvement-id=\"$resolve.case.involvementId\"\n                     case-id=\"$resolve.case.id\"\n                     activity=\"$resolve.activity\"\n                     case-open-date=\"$resolve.case.openDate\"\n                     flex>\n                    </ce-client-case-review>",
                    params: { activity: null },
                    resolve: { activity: ['$stateParams', function (p) { return p.activity; }] },
                    data: { title: 'Review' }
                },
                {
                    name: 'app.clientCases.activities.createInformationAndReferral',
                    url: '/createInformationAndReferral',
                    template: "<ce-create-information-and-referral layout=\"column\" flex\n                        involvement-id=\"$resolve.case.involvementId\"\n                        client-case-id=\"$resolve.case.id\"\n                        case-open-date=\"$resolve.case.openDate\">\n                    </ce-create-information-and-referral>",
                    data: {
                        title: 'Create I&R',
                        permissions: ['i-and-rs.edit']
                    }
                },
                {
                    name: 'app.clientCases.activities.createSystemicCase',
                    url: '/createSystemicCase',
                    template: "<ce-create-systemic-case layout=\"column\" flex\n                        involvement-id=\"$resolve.case.involvementId\"\n                        client-case-id=\"$resolve.case.id\"\n                        case-open-date=\"$resolve.case.openDate\">\n                    </ce-create-systemic-case>",
                    data: {
                        title: 'Create Systemic Case',
                        permissions: ['systemic-cases.edit']
                    }
                },
                {
                    name: 'app.clientCases.activities.representation',
                    url: '/representation',
                    template: "<am-client-case-representation layout=\"column\" flex\n                        [involvement-id]=\"$resolve.case.involvementId\"\n                        [source-id]=\"$resolve.case.id\"\n                        [activity-id]=\"$resolve.activityId\">\n                    </am-client-case-representation>",
                    params: { activityId: null },
                    resolve: { activityId: ['$stateParams', function (p) { return p.activityId; }] },
                    data: {
                        title: 'Representation',
                        permissions: ['client-cases.edit']
                    }
                },
                {
                    name: 'app.clientCases.activities.covidVaccine',
                    url: '/covidVaccine',
                    template: "<am-client-case-covid-vaccine layout=\"column\" flex\n                        [involvement-id]=\"$resolve.case.involvementId\"\n                        [source-id]=\"$resolve.case.id\"\n                        [activity-id]=\"$resolve.activityId\">\n                    </am-client-case-covid-vaccine>",
                    params: { activityId: null },
                    resolve: { activityId: ['$stateParams', function (p) { return p.activityId; }] },
                    data: {
                        title: 'Covid Vaccine',
                        permissions: ['client-cases.edit']
                    }
                },
                {
                    name: 'app.clientCases.activities.statusChange',
                    url: '/statusChange',
                    template: "<am-activities-status-change layout=\"column\" flex\n                        [involvement-id]=\"$resolve.case.involvementId\"\n                        [source-id]=\"$resolve.case.id\"\n                        source=\"clientcases\"\n                        [activity-id]=\"$resolve.activityId\">\n                    </am-activities-status-change>",
                    params: { activityId: null },
                    resolve: { activityId: ['$stateParams', function (p) { return p.activityId; }] },
                    data: {
                        title: 'Status Change',
                        permissions: ['client-cases.edit']
                    }
                }
            ];
            var runner = function (routingHelper) {
                'ngInject';
                routingHelper.configureRoutes(routes);
            };
            angular
                .module('app.clientCases.activities')
                .run(runner);
        })(activities = clientCases.activities || (clientCases.activities = {}));
    })(clientCases = app.clientCases || (app.clientCases = {}));
})(app || (app = {}));
var app;
(function (app) {
    var clientCases;
    (function (clientCases) {
        var CaseReview = (function () {
            function CaseReview(common, datacontext) {
                this.common = common;
                this.datacontext = datacontext;
                this.isSubmitting = false;
                this.review = {};
                'ngInject';
                'ngInject';
            }
            CaseReview.prototype.$onInit = function () {
                this.maxDate = moment().toDate();
                if (this.activity) {
                    this.review.id = this.activity.id;
                    this.review.caseId = this.activity.sourceId;
                    this.review.involvementId = this.activity.involvementId;
                    this.review.note = this.activity.description;
                    this.review.reviewDate = this.activity.activityDate;
                }
                else {
                    this.getCaseStatus();
                    this.review.involvementId = this.involvementId;
                    this.review.caseId = this.caseId;
                    this.review.reviewDate = moment().toDate();
                }
                this.returnSref = this.returnSref || '^.list';
            };
            CaseReview.prototype.getCaseStatus = function () {
                var _this = this;
                return this.datacontext.clientCases.getClientCaseStatus(this.caseId).then(function (result) {
                    var data = result;
                    if (data.status) {
                        _this.review.status = data.status;
                        _this.review.statusDescription = data.statusDescription;
                    }
                });
            };
            CaseReview.prototype.submit = function () {
                var _this = this;
                this.isSubmitting = true;
                if (typeof this.review.reviewDate !== 'string') {
                    this.review.reviewDate = this.review.reviewDate.toDateString();
                }
                if (this.review.status !== 'Other') {
                    this.review.statusDescription = null;
                }
                var promise = this.review.id
                    ? this.datacontext.clientCases.updateCaseReview(this.review)
                    : this.datacontext.clientCases.createCaseReview(this.review);
                promise
                    .then(function (request) {
                    if (request) {
                        _this.common.$mdToast.showSimple('Case Review ' + (_this.review.id ? 'updated' : 'created'));
                        _this.common.$rootScope.$broadcast('refreshBasicInfo');
                        _this.common.$state.go(_this.returnSref, { involvementId: _this.involvementId, sourceId: _this.caseId });
                    }
                })
                    .finally(function () { return _this.isSubmitting = false; });
            };
            CaseReview.$inject = ['common', 'datacontext'];
            CaseReview = __decorate([
                app.Component('app.clientCases', 'ceClientCaseReview', {
                    templateUrl: 'app/client-cases/case-review/case-review.html',
                    bindings: {
                        involvementId: '<',
                        caseId: '<',
                        returnSref: '@',
                        activity: '<',
                        caseOpenDate: '<'
                    }
                })
            ], CaseReview);
            return CaseReview;
        }());
    })(clientCases = app.clientCases || (app.clientCases = {}));
})(app || (app = {}));
var app;
(function (app) {
    var clientCases;
    (function (clientCases) {
        var ClientCaseView = (function () {
            function ClientCaseView($mdDialog, common, confirmation, datacontext, timerService) {
                var _this = this;
                this.$mdDialog = $mdDialog;
                this.common = common;
                this.confirmation = confirmation;
                this.datacontext = datacontext;
                this.timerService = timerService;
                this.isLoading = false;
                this.refresh = function () {
                    _this.datacontext.clientCases.getClientCaseBasicInfoView(_this.case.id).then(function (clientCase) { return _this.case = clientCase; });
                };
                'ngInject';
                'ngInject';
                this.$state = common.$state;
            }
            ClientCaseView.prototype.$onInit = function () {
                this.unsubscribe = this.common.$rootScope.$on('refreshBasicInfo', this.refresh);
            };
            ClientCaseView.prototype.showReportDialog = function () {
                var report = {
                    category: 'Case Report',
                    label: 'Notes Report',
                    name: 'Notes Report',
                    description: 'Notes Report',
                    permission: 'reports.view',
                    parameters: [
                        { label: 'From Date', name: 'FromDate', dataType: 'Date', value: null },
                        { label: 'To Date', name: 'ToDate', dataType: 'Date', value: null },
                        { label: 'Note Type - Leave blank for All', name: 'NoteType', value: '', dataType: 'Select', options: ['', 'Case Description', 'CAM Decision', 'Case Note', 'Case Review', 'Closure Summary'] },
                        { label: 'Case Type', name: 'CaseType', dataType: 'hidden', value: 'Client Case' },
                        { label: 'CaseId', name: 'CaseId', dataType: 'hidden', value: this.case.id },
                        { label: 'Involvement', name: 'InvolvementId', dataType: 'hidden', value: this.case.involvementId }
                    ]
                };
                return this.$mdDialog.show({
                    fullscreen: true,
                    templateUrl: 'app/reports/report-dialog/report-dialog.html',
                    controller: 'ReportDialogController',
                    controllerAs: '$ctrl',
                    focusOnOpen: false,
                    locals: {
                        report: report
                    }
                })
                    .catch(function (error) { if (error)
                    throw error; })
                    .finally(function () { return angular.noop; });
            };
            ClientCaseView.prototype.$onDestroy = function () {
                this.unsubscribe();
            };
            ClientCaseView.prototype.showDeleteDialog = function (id) {
                var _this = this;
                this.confirmation.show({
                    message: 'Are you sure you want to delete this Case? This will delete all data attached to this Case.',
                    ok: 'DELETE',
                    cancel: 'KEEP'
                }).then(function () { _this.deleteCase(id); });
            };
            ClientCaseView.prototype.deleteCase = function (id) {
                var _this = this;
                this.datacontext.clientCases.deleteClientCase(id).then(function (result) {
                    if (result) {
                        _this.timerService.killTimer();
                        _this.common.$state.go('app.clientCaseList');
                    }
                });
            };
            ClientCaseView.$inject = ['$mdDialog', 'common', 'confirmation', 'datacontext', 'timerService'];
            ClientCaseView = __decorate([
                app.Component('app.clientCases', 'ceClientCaseView', {
                    templateUrl: 'app/client-cases/client-case/client-case-view.html',
                    bindings: {
                        case: '<'
                    }
                })
            ], ClientCaseView);
            return ClientCaseView;
        }());
    })(clientCases = app.clientCases || (app.clientCases = {}));
})(app || (app = {}));
var app;
(function (app) {
    var clientCases;
    (function (clientCases) {
        var ClientCaseList = (function () {
            function ClientCaseList() {
            }
            ClientCaseList = __decorate([
                app.Component('app.clientCases', 'ceClientCaseList', {
                    templateUrl: 'app/client-cases/client-case-listing/client-case-list.html',
                    bindings: {
                        cases: '<',
                        refresh: '&',
                        paging: '<',
                        promise: '<',
                        showDeleteDialog: '&'
                    }
                })
            ], ClientCaseList);
            return ClientCaseList;
        }());
    })(clientCases = app.clientCases || (app.clientCases = {}));
})(app || (app = {}));
var app;
(function (app) {
    var clientCases;
    (function (clientCases) {
        var closure;
        (function (closure) {
            var ClientCaseClosure = (function () {
                function ClientCaseClosure($mdDialog, common, datacontext) {
                    this.$mdDialog = $mdDialog;
                    this.common = common;
                    this.datacontext = datacontext;
                    this.editClosureInformation = false;
                    this.editSurveyInformation = false;
                    this.editSpecialEducation = false;
                    'ngInject';
                    'ngInject';
                }
                ClientCaseClosure.prototype.$onInit = function () {
                    this.GetClosureInformation();
                    this.GetSurveyInformation();
                    this.GetSpecialEducationInformation();
                };
                ClientCaseClosure.prototype.updateClosureInformation = function (result) {
                    this.closureInformation = result;
                    this.editClosureInformation = false;
                };
                ClientCaseClosure.prototype.GetClosureInformation = function () {
                    var _this = this;
                    this.datacontext.clientCases.getClosureInformation(this.case.id).then(function (result) { return _this.closureInformation = result; });
                };
                ClientCaseClosure.prototype.updateSurveyInformation = function (result) {
                    this.surveyInformation = result;
                    this.editSurveyInformation = false;
                };
                ClientCaseClosure.prototype.GetSurveyInformation = function () {
                    var _this = this;
                    this.datacontext.clientCases.getSurveyInformation(this.case.id).then(function (result) { return _this.surveyInformation = result; });
                };
                ClientCaseClosure.prototype.updateClosureSpecialEducation = function (result) {
                    this.specialEducation = result;
                    this.editSpecialEducation = false;
                };
                ClientCaseClosure.prototype.GetSpecialEducationInformation = function () {
                    var _this = this;
                    this.datacontext.clientCases.getSpecialEducation(this.case.id).then(function (result) { return _this.specialEducation = result; });
                };
                ClientCaseClosure.$inject = ['$mdDialog', 'common', 'datacontext'];
                ClientCaseClosure = __decorate([
                    app.Component('app.clientCases.closure', 'ceClientCaseClosure', {
                        templateUrl: 'app/client-cases/closure/closure.html',
                        bindings: {
                            case: '<'
                        }
                    })
                ], ClientCaseClosure);
                return ClientCaseClosure;
            }());
        })(closure = clientCases.closure || (clientCases.closure = {}));
    })(clientCases = app.clientCases || (app.clientCases = {}));
})(app || (app = {}));
var app;
(function (app) {
    var clientCases;
    (function (clientCases) {
        var CreateClientCase = (function () {
            function CreateClientCase(common, datacontext) {
                var _this = this;
                this.common = common;
                this.datacontext = datacontext;
                this.selectedRoles = [];
                this.isSubmitting = false;
                this.case = {};
                this.getRoles = function () {
                    var lookupTypeId = "involvement-".concat(_this.entityType, "-role");
                    return _this.datacontext.config.lookups.getLookupType(lookupTypeId).then(function (result) {
                        _this.roles = result.lookups;
                    });
                };
                'ngInject';
                'ngInject';
            }
            CreateClientCase.prototype.$onInit = function () {
                this.maxDate = moment().toDate();
                this.case.involvementId = this.involvementId;
                this.case.requestId = this.requestId;
                this.case.informationAndReferralId = this.informationAndReferralId;
                this.case.systemicCaseId = this.systemicCaseId;
                this.case.openDate = moment().toDate();
                if (this.entity) {
                    this.getRoles();
                }
            };
            CreateClientCase.prototype.transformChip = function (chip) {
                if (angular.isObject(chip)) {
                    return chip;
                }
                return { name: chip, type: 'new' };
            };
            CreateClientCase.prototype.querySearch = function (query) {
                var results = query ? _.filter(this.roles, function (r) {
                    return query === '*' || r.value.toLowerCase().indexOf(query) > -1;
                }) : [];
                return results;
            };
            CreateClientCase.prototype.submit = function () {
                var _this = this;
                this.isSubmitting = true;
                if (typeof this.case.openDate !== 'string') {
                    this.case.openDate = this.case.openDate.toDateString();
                }
                this.datacontext.clientCases
                    .createClientCase(this.case)
                    .then(function (id) {
                    if (id) {
                        if (_this.entity) {
                            var roles = _.map(_this.selectedRoles, 'value');
                            var data_1 = {
                                entityId: _this.entity.id,
                                involvementId: id,
                                roles: roles
                            };
                            _this.datacontext.involvements
                                .createEntity(data_1)
                                .then(function () {
                                _this.common.$mdToast.showSimple('Client Case Created');
                                _this.common.$state.go('app.clientCases', { clientCaseId: id });
                            });
                        }
                        else {
                            _this.common.$mdToast.showSimple('Client Case Created');
                            _this.common.$state.go('app.clientCases', { clientCaseId: id });
                        }
                    }
                })
                    .finally(function () { return _this.isSubmitting = false; });
            };
            CreateClientCase.$inject = ['common', 'datacontext'];
            CreateClientCase = __decorate([
                app.Component('app.clientCases', 'ceCreateClientCase', {
                    templateUrl: 'app/client-cases/create-client-case/create-client-case.html',
                    bindings: {
                        requestId: '<',
                        informationAndReferralId: '<',
                        systemicCaseId: '<',
                        involvementId: '<',
                        entity: '<',
                        entityType: '@',
                        caseOpenDate: '<'
                    }
                })
            ], CreateClientCase);
            return CreateClientCase;
        }());
    })(clientCases = app.clientCases || (app.clientCases = {}));
})(app || (app = {}));
var app;
(function (app) {
    var clientCases;
    (function (clientCases) {
        var details;
        (function (details) {
            var ClientCaseDetails = (function () {
                function ClientCaseDetails($mdDialog, common, datacontext) {
                    this.$mdDialog = $mdDialog;
                    this.common = common;
                    this.datacontext = datacontext;
                    this.editCaseInformation = false;
                    this.editSpecialEducationInformation = false;
                    this.editClientInformation = false;
                    this.editCallerInformation = false;
                    'ngInject';
                    'ngInject';
                }
                ClientCaseDetails.prototype.$onInit = function () {
                    var _this = this;
                    var promise = null;
                    promise = this.getCase();
                    promise.then(function () {
                        _this.getCaseInformation();
                        _this.getClientInformation();
                        _this.getCallerInformation();
                    });
                };
                ClientCaseDetails.prototype.getCase = function () {
                    var _this = this;
                    return this.datacontext.clientCases.getClientCaseBasicInfoView(this.case.id)
                        .then(function (cc) { return _this.case = cc; });
                };
                ClientCaseDetails.prototype.updateCaseInformation = function (result) {
                    this.caseInformation = result;
                    this.editCaseInformation = false;
                    this.editSpecialEducationInformation = false;
                };
                ClientCaseDetails.prototype.updateClientInformation = function (result) {
                    this.clientInformation = result;
                    this.editClientInformation = false;
                };
                ClientCaseDetails.prototype.updateCallerInformation = function (result) {
                    this.callerInformation = result;
                    this.editCallerInformation = false;
                };
                ClientCaseDetails.prototype.getCaseInformation = function () {
                    var _this = this;
                    this.datacontext.clientCases.getClientCaseInformation(this.case.id).then(function (result) { return _this.caseInformation = result; });
                };
                ClientCaseDetails.prototype.getClientInformation = function () {
                    var _this = this;
                    if (this.case.clientPersonId) {
                        this.datacontext.persons.getDemographics(this.case.clientPersonId).then(function (result) { return _this.clientInformation = result; });
                    }
                };
                ClientCaseDetails.prototype.getCallerInformation = function () {
                    var _this = this;
                    if (this.case.requesterPersonId && this.case.clientPersonId !== this.case.requesterPersonId) {
                        this.datacontext.persons.getDemographics(this.case.requesterPersonId).then(function (result) { return _this.callerInformation = result; });
                    }
                };
                ClientCaseDetails.$inject = ['$mdDialog', 'common', 'datacontext'];
                ClientCaseDetails = __decorate([
                    app.Component('app.clientCases.details', 'ceClientCaseDetails', {
                        templateUrl: 'app/client-cases/details/details.html',
                        bindings: {
                            case: '<',
                            paramCaseName: '<',
                            paramPovertyLevel: '<',
                            paramAtPoverty: '<',
                            paramRaceSubCategory: '<'
                        }
                    })
                ], ClientCaseDetails);
                return ClientCaseDetails;
            }());
        })(details = clientCases.details || (clientCases.details = {}));
    })(clientCases = app.clientCases || (app.clientCases = {}));
})(app || (app = {}));
var app;
(function (app) {
    var clientCases;
    (function (clientCases) {
        var entities;
        (function (entities) {
            var routes = [
                {
                    name: 'app.clientCases.entities',
                    url: '/entities',
                    template: '<ui-view flex layout="column"></ui-view>',
                    redirectTo: 'app.clientCases.entities.listing',
                    data: { title: 'Client Case Entities' }
                },
                {
                    name: 'app.clientCases.entities.listing',
                    url: '/listing',
                    template: '<ce-involvement-entity-listing involvement-id="$resolve.case.involvementId" layout="column" flex></ce-involvement-entity-listing>',
                    data: { title: 'Client Case Entities' }
                },
                {
                    name: 'app.clientCases.entities.add',
                    url: '/add',
                    template: '<ce-select-entity-type flex></ce-select-entity-type>',
                    redirectTo: 'app.clientCases.entities.add.type',
                    data: { title: 'Add' }
                },
                {
                    name: 'app.clientCases.entities.add.type',
                    url: '/types',
                    template: '<ce-entity-types flex></ce-entity-types>'
                },
                {
                    name: 'app.clientCases.entities.edit',
                    url: '/edit',
                    params: { entity: null },
                    resolve: { entity: ['$stateParams', function (p) { return p.entity; }] },
                    template: '<ce-edit-entity-roles involvement-id="$resolve.case.involvementId" entity="$resolve.entity" layout="column" flex></ce-edit-entity-roles>',
                    data: { title: 'Edit' }
                },
                {
                    name: 'app.clientCases.entities.add.person',
                    url: '/person',
                    template: '<ui-view layout flex></ui-view>',
                    redirectTo: 'app.clientCases.entities.add.person.search',
                },
                {
                    name: 'app.clientCases.entities.add.person.search',
                    url: '/search',
                    template: '<ce-person-search flex></ce-person-search>',
                    data: { title: 'Find Person' }
                },
                {
                    name: 'app.clientCases.entities.add.person.create',
                    url: '/create',
                    template: '<ce-create-person flex></ce-create-person>',
                    data: { title: 'Create Person' }
                },
                {
                    name: 'app.clientCases.entities.add.person.roles',
                    url: '/roles',
                    params: { entity: null },
                    resolve: { entity: ['$stateParams', function (p) { return p.entity; }] },
                    template: '<ce-entity-roles involvement-id="$resolve.case.involvementId" entity="$resolve.entity" layout="column" flex></ce-entity-roles>',
                    data: { title: 'Select Roles' }
                },
                {
                    name: 'app.clientCases.entities.add.agency',
                    url: '/agency',
                    template: '<ui-view layout flex></ui-view>',
                    redirectTo: 'app.clientCases.entities.add.agency.search',
                },
                {
                    name: 'app.clientCases.entities.add.agency.search',
                    url: '/search',
                    template: '<ce-agency-search flex></ce-agenyc-search>',
                    data: { title: 'Find Agency' }
                },
                {
                    name: 'app.clientCases.entities.add.agency.create',
                    url: '/create',
                    template: '<ce-create-agency flex></ce-create-agency>',
                    data: { title: 'Create Agency' }
                },
                {
                    name: 'app.clientCases.entities.add.agency.roles',
                    url: '/roles',
                    params: { entity: null },
                    resolve: { entity: ['$stateParams', function (p) { return p.entity; }] },
                    template: '<ce-entity-roles involvement-id="$resolve.case.involvementId" entity="$resolve.entity" layout="column" flex></ce-entity-roles>',
                    data: { title: 'Select Roles' }
                }
            ];
            var runner = function (routingHelper) {
                'ngInject';
                routingHelper.configureRoutes(routes);
            };
            angular
                .module('app.clientCases.entities')
                .run(runner);
        })(entities = clientCases.entities || (clientCases.entities = {}));
    })(clientCases = app.clientCases || (app.clientCases = {}));
})(app || (app = {}));
var app;
(function (app) {
    var clientCases;
    (function (clientCases) {
        var funding;
        (function (funding) {
            var routes = [
                {
                    name: 'app.clientCases.funding',
                    url: '/funding',
                    template: '<ce-funding involvement-id="$resolve.case.involvementId" layout="column" flex></ce-funding>',
                    redirectTo: 'app.clientCases.funding.summary',
                    data: { title: 'Client Case Funding Summary' }
                },
                {
                    name: 'app.clientCases.funding.summary',
                    url: '/summary',
                    template: '<ce-funding-summary involvement-id="$resolve.case.involvementId" layout="column" flex></ce-funding-summary>',
                    data: { title: 'Client Case Funding' }
                },
                {
                    name: 'app.clientCases.funding.eligibility',
                    url: '/eligibility',
                    template: '<ui-view layout flex></ui-view>',
                    redirectTo: 'app.clientCases.funding.eligibility.list',
                    data: { title: 'Client Case Funding' }
                },
                {
                    name: 'app.clientCases.funding.eligibility.list',
                    url: '/list',
                    template: "<am-funding-eligibility-view\n                    [involvement-id]=\"$resolve.case.involvementId\"\n                    source-type=\"clientCases\"\n                    layout=\"column\" flex></am-funding-eligibility-view>",
                    data: { title: 'Client Case Funding' }
                },
                {
                    name: 'app.clientCases.funding.eligibility.form',
                    url: '/form',
                    template: '<am-funding-form-eligibility involvement-id="$resolve.case.involvementId" source="Client Case" layout="column" flex></am-funding-form-eligibility>',
                    data: { title: 'Eligibility Form' }
                },
                {
                    name: 'app.clientCases.funding.eligibility.cap',
                    url: '/cap',
                    params: { fundingProgramId: null },
                    resolve: { fundingProgramId: ['$stateParams', function (p) { return p.fundingProgramId; }] },
                    template: '<ce-cap-eligibility involvement-id="$resolve.case.involvementId" funding-program-id="$resolve.fundingProgramId" layout="column" flex></ce-cap-eligibility>',
                    data: { title: 'CAP Eligibility' }
                },
                {
                    name: 'app.clientCases.funding.eligibility.paat',
                    url: '/paat',
                    params: { fundingProgramId: null },
                    resolve: { fundingProgramId: ['$stateParams', function (p) { return p.fundingProgramId; }] },
                    template: '<ce-paat-eligibility involvement-id="$resolve.case.involvementId" funding-program-id="$resolve.fundingProgramId" layout="column" flex></ce-paat-eligibility>',
                    data: { title: 'PAAT Eligibility' }
                },
                {
                    name: 'app.clientCases.funding.eligibility.pabss',
                    url: '/pabss',
                    params: { fundingProgramId: null },
                    resolve: { fundingProgramId: ['$stateParams', function (p) { return p.fundingProgramId; }] },
                    template: '<ce-pabss-eligibility involvement-id="$resolve.case.involvementId" funding-program-id="$resolve.fundingProgramId" layout="column" flex></ce-pabss-eligibility>',
                    data: { title: 'PABSS Eligibility' }
                },
                {
                    name: 'app.clientCases.funding.eligibility.padd',
                    url: '/padd',
                    params: { fundingProgramId: null },
                    resolve: { fundingProgramId: ['$stateParams', function (p) { return p.fundingProgramId; }] },
                    template: '<ce-padd-eligibility involvement-id="$resolve.case.involvementId" funding-program-id="$resolve.fundingProgramId" layout="column" flex></ce-padd-eligibility>',
                    data: { title: 'PADD Eligibility' }
                },
                {
                    name: 'app.clientCases.funding.eligibility.paimi',
                    url: '/paimi',
                    params: { fundingProgramId: null },
                    resolve: { fundingProgramId: ['$stateParams', function (p) { return p.fundingProgramId; }] },
                    template: '<ce-paimi-eligibility involvement-id="$resolve.case.involvementId" funding-program-id="$resolve.fundingProgramId" layout="column" flex></ce-paimi-eligibility>',
                    data: { title: 'PAIMI Eligibility' }
                },
                {
                    name: 'app.clientCases.funding.eligibility.pair',
                    url: '/pair',
                    params: { fundingProgramId: null },
                    resolve: { fundingProgramId: ['$stateParams', function (p) { return p.fundingProgramId; }] },
                    template: '<ce-pair-eligibility involvement-id="$resolve.case.involvementId" funding-program-id="$resolve.fundingProgramId" layout="column" flex></ce-pair-eligibility>',
                    data: { title: 'PAIR Eligibility' }
                },
                {
                    name: 'app.clientCases.funding.eligibility.patbi',
                    url: '/patbi',
                    params: { fundingProgramId: null },
                    resolve: { fundingProgramId: ['$stateParams', function (p) { return p.fundingProgramId; }] },
                    template: '<ce-patbi-eligibility involvement-id="$resolve.case.involvementId" funding-program-id="$resolve.fundingProgramId" layout="column" flex></ce-patbi-eligibility>',
                    data: { title: 'PATBI Eligibility' }
                },
                {
                    name: 'app.clientCases.funding.enrollment',
                    url: '/primary',
                    template: '<ui-view layout flex></ui-view>',
                    redirectTo: 'app.clientCases.funding.enrollment.list'
                },
                {
                    name: 'app.clientCases.funding.enrollment.list',
                    url: '/list',
                    template: '<ce-primary-funding-source-listing involvement-id="$resolve.case.involvementId" layout="column" source="Client Case" flex></ce-primary-funding-source-listing>',
                    data: { title: 'Primary List' }
                },
                {
                    name: 'app.clientCases.funding.enrollment.primary',
                    url: '/primary',
                    template: '<ce-create-primary-funding involvement-id="$resolve.case.involvementId" layout="column" flex></ce-create-primary-funding>',
                    data: { title: 'Primary Funding' }
                },
                {
                    name: 'app.clientCases.funding.enrollment.updatePrimary',
                    url: '/updateprimary',
                    params: { enrollment: null },
                    resolve: { enrollment: ['$stateParams', function (p) { return p.enrollment; }] },
                    template: '<ce-edit-primary-funding enrollment="$resolve.enrollment" layout="column" flex></ce-edit-primary-funding>',
                    data: { title: 'Update Funding' }
                },
                {
                    name: 'app.clientCases.funding.enrollment.pavaIntake',
                    url: '/pava-intake/{enrollmentId}',
                    template: '<ce-pava-intake enrollment-id="$resolve.enrollmentId" layout="column" flex></ce-pava-intake>',
                    params: { enrollmentId: null },
                    resolve: { enrollmentId: ['$stateParams', function (p) { return p.enrollmentId; }] },
                    data: { title: 'PAVA Intake' }
                },
                {
                    name: 'app.clientCases.funding.enrollment.pavaOutcome',
                    url: '/pava-outcome/{enrollmentId}',
                    template: '<ce-pava-outcome enrollment-id="$resolve.enrollmentId" layout="column" flex></ce-pava-outcome>',
                    params: { enrollmentId: null },
                    resolve: { enrollmentId: ['$stateParams', function (p) { return p.enrollmentId; }] },
                    data: { title: 'PAVA Outcome' }
                },
                {
                    name: 'app.clientCases.funding.enrollment.paddIntake',
                    url: '/padd-intake/{enrollmentId}',
                    template: '<ce-padd-intake enrollment-id="$resolve.enrollmentId" layout="column" flex></ce-padd-intake>',
                    params: { enrollmentId: null },
                    resolve: { enrollmentId: ['$stateParams', function (p) { return p.enrollmentId; }] },
                    data: { title: 'PADD Intake' }
                },
                {
                    name: 'app.clientCases.funding.enrollment.paddOutcome',
                    url: '/padd-outcome/{enrollmentId}',
                    template: '<ce-padd-outcome enrollment-id="$resolve.enrollmentId" layout="column" flex></ce-padd-outcome>',
                    params: { enrollmentId: null },
                    resolve: { enrollmentId: ['$stateParams', function (p) { return p.enrollmentId; }] },
                    data: { title: 'PADD Outcome' }
                },
                {
                    name: 'app.clientCases.funding.enrollment.pabssIntake',
                    url: '/pabss-intake/{enrollmentId}',
                    template: '<ce-pabss-intake enrollment-id="$resolve.enrollmentId" layout="column" flex></ce-pabss-intake>',
                    params: { enrollmentId: null },
                    resolve: { enrollmentId: ['$stateParams', function (p) { return p.enrollmentId; }] },
                    data: { title: 'PABSS Intake' }
                },
                {
                    name: 'app.clientCases.funding.enrollment.pabssOutcome',
                    url: '/pabss-outcome/{enrollmentId}',
                    template: '<ce-pabss-outcome enrollment-id="$resolve.enrollmentId" layout="column" flex></ce-pabss-outcome>',
                    params: { enrollmentId: null },
                    resolve: { enrollmentId: ['$stateParams', function (p) { return p.enrollmentId; }] },
                    data: { title: 'PABSS Outcome' }
                },
                {
                    name: 'app.clientCases.funding.enrollment.capIntake',
                    url: '/cap-intake/{enrollmentId}',
                    template: '<ce-cap-intake enrollment-id="$resolve.enrollmentId" layout="column" flex></ce-cap-intake>',
                    params: { enrollmentId: null },
                    resolve: { enrollmentId: ['$stateParams', function (p) { return p.enrollmentId; }] },
                    data: { title: 'CAP Intake' }
                },
                {
                    name: 'app.clientCases.funding.enrollment.capOutcome',
                    url: '/cap-outcome/{enrollmentId}',
                    template: '<ce-cap-outcome enrollment-id="$resolve.enrollmentId" layout="column" flex></ce-cap-outcome>',
                    params: { enrollmentId: null },
                    resolve: { enrollmentId: ['$stateParams', function (p) { return p.enrollmentId; }] },
                    data: { title: 'CAP Outcome' }
                },
                {
                    name: 'app.clientCases.funding.enrollment.paatIntake',
                    url: '/paat-intake/{enrollmentId}',
                    template: '<ce-paat-intake enrollment-id="$resolve.enrollmentId" layout="column" flex></ce-paat-intake>',
                    params: { enrollmentId: null },
                    resolve: { enrollmentId: ['$stateParams', function (p) { return p.enrollmentId; }] },
                    data: { title: 'PAAT Intake' }
                },
                {
                    name: 'app.clientCases.funding.enrollment.paatOutcome',
                    url: '/paat-outcome/{enrollmentId}',
                    template: '<ce-paat-outcome enrollment-id="$resolve.enrollmentId" layout="column" flex></ce-paat-outcome>',
                    params: { enrollmentId: null },
                    resolve: { enrollmentId: ['$stateParams', function (p) { return p.enrollmentId; }] },
                    data: { title: 'PAAT Outcome' }
                },
                {
                    name: 'app.clientCases.funding.enrollment.pairIntake',
                    url: '/pair-intake/{enrollmentId}',
                    template: '<ce-pair-intake enrollment-id="$resolve.enrollmentId" layout="column" flex></ce-pair-intake>',
                    params: { enrollmentId: null },
                    resolve: { enrollmentId: ['$stateParams', function (p) { return p.enrollmentId; }] },
                    data: { title: 'PAIR Intake' }
                },
                {
                    name: 'app.clientCases.funding.enrollment.pairOutcome',
                    url: '/pair-outcome/{enrollmentId}',
                    template: '<ce-pair-outcome enrollment-id="$resolve.enrollmentId" layout="column" flex></ce-pair-outcome>',
                    params: { enrollmentId: null },
                    resolve: { enrollmentId: ['$stateParams', function (p) { return p.enrollmentId; }] },
                    data: { title: 'PAIR Outcome' }
                },
                {
                    name: 'app.clientCases.funding.enrollment.patbiIntake',
                    url: '/patbi-intake/{enrollmentId}',
                    template: '<ce-patbi-intake enrollment-id="$resolve.enrollmentId" layout="column" flex></ce-patbi-intake>',
                    params: { enrollmentId: null },
                    resolve: { enrollmentId: ['$stateParams', function (p) { return p.enrollmentId; }] },
                    data: { title: 'PATBI Intake' }
                },
                {
                    name: 'app.clientCases.funding.enrollment.patbiOutcome',
                    url: '/patbi-outcome/{enrollmentId}',
                    template: '<ce-patbi-outcome enrollment-id="$resolve.enrollmentId" layout="column" flex></ce-patbi-outcome>',
                    params: { enrollmentId: null },
                    resolve: { enrollmentId: ['$stateParams', function (p) { return p.enrollmentId; }] },
                    data: { title: 'PATBI Outcome' }
                },
                {
                    name: 'app.clientCases.funding.enrollment.paimiIntake',
                    url: '/paimi-intake/{enrollmentId}',
                    template: '<ce-paimi-intake enrollment-id="$resolve.enrollmentId" layout="column" flex></ce-paimi-intake>',
                    params: { enrollmentId: null },
                    resolve: { enrollmentId: ['$stateParams', function (p) { return p.enrollmentId; }] },
                    data: { title: 'PAIMI Intake' }
                },
                {
                    name: 'app.clientCases.funding.enrollment.paimiOutcome',
                    url: '/paimi-outcome/{enrollmentId}',
                    template: '<ce-paimi-outcome enrollment-id="$resolve.enrollmentId" layout="column" flex></ce-paimi-outcome>',
                    params: { enrollmentId: null },
                    resolve: { enrollmentId: ['$stateParams', function (p) { return p.enrollmentId; }] },
                    data: { title: 'PAIMI Outcome' }
                },
                {
                    name: 'app.clientCases.funding.secondary',
                    url: '/secondary',
                    template: '<ui-view flex></ui-view>',
                    redirectTo: 'app.clientCases.funding.secondary.view',
                    data: { title: 'Secondary Funding' }
                },
                {
                    name: 'app.clientCases.funding.secondary.edit',
                    url: '/edit',
                    params: { fundingProgramId: null },
                    resolve: { fundingProgramId: ['$stateParams', function (p) { return p.fundingProgramId; }] },
                    template: '<ce-edit-secondary-funding involvement-id="$resolve.case.involvementId" funding-program-id="$resolve.fundingProgramId" layout="column" flex></ce-edit-secondary-funding>',
                    data: { title: 'Secondary Funding' }
                },
                {
                    name: 'app.clientCases.funding.secondary.view',
                    url: '/views',
                    template: '<ce-secondary-funding-view involvement-id="$resolve.case.involvementId" layout="column" flex></ce-secondary-funding-view>',
                    data: { title: 'Secondary Funding' }
                },
                {
                    name: 'app.clientCases.funding.enrollment.deaf services (tep)Intake',
                    url: '/deaf services (tep)Intake/{enrollmentId}',
                    template: '<ce-tep-intake enrollment-id="$resolve.enrollmentId" layout="column" flex></ce-tep-intake>',
                    params: { enrollmentId: null },
                    resolve: { enrollmentId: ['$stateParams', function (p) { return p.enrollmentId; }] },
                    data: { title: 'TEP Intake' }
                },
                {
                    name: 'app.clientCases.funding.enrollment.deaf services (tep)Outcome',
                    url: '/deaf services (tep)Outcome/{enrollmentId}',
                    template: '<ce-tep-outcome enrollment-id="$resolve.enrollmentId" layout="column" flex></ce-tep-outcome>',
                    params: { enrollmentId: null },
                    resolve: { enrollmentId: ['$stateParams', function (p) { return p.enrollmentId; }] },
                    data: { title: 'TEP Outcome' }
                },
                {
                    name: 'app.clientCases.funding.enrollment.deaf services (ndbedp)Intake',
                    url: '/deaf services (ndbedp)Intake/{enrollmentId}',
                    template: '<ce-ndbedp-intake enrollment-id="$resolve.enrollmentId" layout="column" flex></ce-ndbedp-intake>',
                    params: { enrollmentId: null },
                    resolve: { enrollmentId: ['$stateParams', function (p) { return p.enrollmentId; }] },
                    data: { title: 'NDBEDP Intake' }
                },
                {
                    name: 'app.clientCases.funding.enrollment.deaf services (ndbedp)Outcome',
                    url: '/deaf services (ndbedp)Outcome/{enrollmentId}',
                    template: '<ce-ndbedp-outcome enrollment-id="$resolve.enrollmentId" layout="column" flex></ce-ndbedp-outcome>',
                    params: { enrollmentId: null },
                    resolve: { enrollmentId: ['$stateParams', function (p) { return p.enrollmentId; }] },
                    data: { title: 'NDBEDP Outcome' }
                }
            ];
            var runner = function (routingHelper) {
                'ngInject';
                routingHelper.configureRoutes(routes);
            };
            angular
                .module('app.clientCases.funding')
                .run(runner);
        })(funding = clientCases.funding || (clientCases.funding = {}));
    })(clientCases = app.clientCases || (app.clientCases = {}));
})(app || (app = {}));
var app;
(function (app) {
    var clientCases;
    (function (clientCases) {
        var ClientCaseNoTimeslipsListing = (function () {
            function ClientCaseNoTimeslipsListing(datacontext) {
                var _this = this;
                this.datacontext = datacontext;
                this.paging = {
                    page: 1,
                    pageSize: 100
                };
                this.getCases = function () {
                    var params = __assign({ bucket: _this.bucket, userIds: _this.userIds }, _this.paging);
                    _this.promise = _this.datacontext.clientCases.getClientCaseNoTimeslipsListing(params)
                        .then(function (result) {
                        _this.cases = result;
                    });
                };
                'ngInject';
                'ngInject';
            }
            ClientCaseNoTimeslipsListing.prototype.$onInit = function () {
                this.getCases();
            };
            ClientCaseNoTimeslipsListing.$inject = ['datacontext'];
            ClientCaseNoTimeslipsListing = __decorate([
                app.Component('app.clientCases', 'ceClientCaseNoTimeslipsListing', {
                    templateUrl: 'app/client-cases/no-timeslips/no-timeslips-listing.html',
                    bindings: {
                        bucket: '<',
                        userIds: '<'
                    }
                })
            ], ClientCaseNoTimeslipsListing);
            return ClientCaseNoTimeslipsListing;
        }());
    })(clientCases = app.clientCases || (app.clientCases = {}));
})(app || (app = {}));
var app;
(function (app) {
    var clientCases;
    (function (clientCases) {
        var overview;
        (function (overview) {
            var ClientCaseOverview = (function () {
                function ClientCaseOverview($mdDialog, common, datacontext) {
                    this.$mdDialog = $mdDialog;
                    this.common = common;
                    this.datacontext = datacontext;
                    'ngInject';
                    'ngInject';
                }
                ClientCaseOverview.prototype.$onInit = function () {
                };
                ClientCaseOverview.$inject = ['$mdDialog', 'common', 'datacontext'];
                ClientCaseOverview = __decorate([
                    app.Component('app.clientCases.overview', 'ceClientCaseOverview', {
                        templateUrl: 'app/client-cases/overview/overview.html',
                        bindings: {
                            case: '<'
                        }
                    })
                ], ClientCaseOverview);
                return ClientCaseOverview;
            }());
        })(overview = clientCases.overview || (clientCases.overview = {}));
    })(clientCases = app.clientCases || (app.clientCases = {}));
})(app || (app = {}));
var app;
(function (app) {
    var clientCases;
    (function (clientCases) {
        var overview;
        (function (overview) {
            var ClientCaseSummary = (function () {
                function ClientCaseSummary($mdDialog, common, datacontext) {
                    this.$mdDialog = $mdDialog;
                    this.common = common;
                    this.datacontext = datacontext;
                    'ngInject';
                    'ngInject';
                }
                ClientCaseSummary.prototype.$onInit = function () {
                    var _this = this;
                    this.datacontext.clientCases.getClientCaseSummaryView(this.case.id)
                        .then(function (result) {
                        var data = result;
                        _this.caseSummary = data;
                    }).then(function () { return angular.element('#summarycard').attr('aria-hidden', 'false'); });
                };
                ClientCaseSummary.$inject = ['$mdDialog', 'common', 'datacontext'];
                ClientCaseSummary = __decorate([
                    app.Component('app.clientCases.overview', 'ceClientCaseSummary', {
                        templateUrl: 'app/client-cases/summary/summary.html',
                        bindings: {
                            case: '<'
                        }
                    })
                ], ClientCaseSummary);
                return ClientCaseSummary;
            }());
        })(overview = clientCases.overview || (clientCases.overview = {}));
    })(clientCases = app.clientCases || (app.clientCases = {}));
})(app || (app = {}));
var app;
(function (app) {
    var clientCases;
    (function (clientCases) {
        var overview;
        (function (overview) {
            var ClientCaseToDo = (function () {
                function ClientCaseToDo($mdDialog, common, datacontext) {
                    this.$mdDialog = $mdDialog;
                    this.common = common;
                    this.datacontext = datacontext;
                    'ngInject';
                    'ngInject';
                }
                ClientCaseToDo.prototype.$onInit = function () {
                    var _this = this;
                    this.datacontext.clientCases.getClientCaseToDo(this.case.id)
                        .then(function (items) {
                        _this.items = items;
                    }).then(function () { return angular.element('#todolistcard').attr('aria-hidden', 'false'); });
                };
                ClientCaseToDo.$inject = ['$mdDialog', 'common', 'datacontext'];
                ClientCaseToDo = __decorate([
                    app.Component('app.clientCases.overview', 'ceClientCaseToDo', {
                        templateUrl: 'app/client-cases/to-do-list/to-do-list.html',
                        bindings: {
                            case: '<'
                        }
                    })
                ], ClientCaseToDo);
                return ClientCaseToDo;
            }());
        })(overview = clientCases.overview || (clientCases.overview = {}));
    })(clientCases = app.clientCases || (app.clientCases = {}));
})(app || (app = {}));
var app;
(function (app) {
    var common;
    (function (common) {
        var CePhone = (function () {
            function CePhone() {
                var _this = this;
                this.setMask = function () {
                    _this.mask = '(999) 999-9999';
                };
                this.removeMask = function () {
                    if (!_this.data.phone) {
                        _this.mask = '';
                    }
                    else if (!_this.data.phone.match(/^[0-9]{10}$/g)) {
                        _this.mask = '';
                        _this.data.phone = null;
                    }
                };
            }
            CePhone.prototype.$onInit = function () {
                this.data = this.phoneNumber || {};
                if (this.data.allowsVoiceMail === undefined) {
                    this.data.allowsVoiceMail = true;
                }
                this.doNotAllowVoicemail = !this.data.allowsVoiceMail;
                if (this.data.phone) {
                    this.data.phone = this.data.phone.substr(-10);
                    this.setMask();
                }
            };
            CePhone.prototype.changed = function () {
                this.onChange({ phone: { phone: this.data.phone, extension: this.data.extension, allowsVoiceMail: !this.doNotAllowVoicemail } });
            };
            CePhone = __decorate([
                app.Component('app.common', 'cePhone', {
                    templateUrl: 'app/common/phone/phone.html',
                    bindings: {
                        phoneNumber: '<',
                        onChange: '&',
                        showVoiceMail: '@'
                    }
                })
            ], CePhone);
            return CePhone;
        }());
    })(common = app.common || (app.common = {}));
})(app || (app = {}));
var app;
(function (app) {
    var config;
    (function (config) {
        var baseUrl = 'api/configuration';
        var ConfigRepository = (function () {
            function ConfigRepository(lookups, legalTeams, $http, common) {
                this.lookups = lookups;
                this.legalTeams = legalTeams;
                this.$http = $http;
                this.common = common;
            }
            ConfigRepository.prototype.getLawCategories = function () {
                return this.$http.get("".concat(baseUrl, "/law-categories?activeOnly=true"));
            };
            ConfigRepository.prototype.getLawSubcategories = function () {
                return this.$http.get("".concat(baseUrl, "/lawsubcategories?activeOnly=true"));
            };
            ConfigRepository.prototype.getConfigurationParameter = function (parameterName, defaultValue) {
                var name = encodeURIComponent(parameterName);
                return this.$http.get("".concat(baseUrl, "/parameters?name=").concat(name)).then(function (result) {
                    var returnValue = null;
                    if (!result || result.data === null) {
                        returnValue = defaultValue;
                    }
                    else {
                        returnValue = result.data;
                    }
                    return returnValue;
                });
            };
            __decorate([
                app.ApiExecutor()
            ], ConfigRepository.prototype, "getLawCategories", null);
            ConfigRepository = __decorate([
                app.Inject('config.lookups.repository', 'config.legalTeams.repository', '$http', 'common')
            ], ConfigRepository);
            return ConfigRepository;
        }());
        angular
            .module('app.config')
            .service('config.repository', ConfigRepository);
    })(config = app.config || (app.config = {}));
})(app || (app = {}));
var app;
(function (app) {
    var config;
    (function (config) {
        var ConfigHelperService = (function () {
            function ConfigHelperService(datacontext) {
                this.datacontext = datacontext;
                'ngInject';
                'ngInject';
            }
            ConfigHelperService.prototype.getLookupValues = function (lookupTypeId, activeOnly) {
                if (activeOnly === void 0) { activeOnly = true; }
                return this.datacontext.config.lookups
                    .getLookupType(lookupTypeId)
                    .then(function (result) {
                    var query = _(result.lookups);
                    if (activeOnly) {
                        query = query.filter(function (l) { return l.isActive; });
                    }
                    return query.map('value').value();
                });
            };
            return ConfigHelperService;
        }());
        angular
            .module('app.config')
            .service('configHelper', ConfigHelperService);
    })(config = app.config || (app.config = {}));
})(app || (app = {}));
var app;
(function (app) {
    var config;
    (function (config) {
        var baseUrl = 'api/configuration/legal-teams';
        var LegalTeamRepository = (function () {
            function LegalTeamRepository($http) {
                this.$http = $http;
                'ngInject';
                'ngInject';
            }
            LegalTeamRepository.prototype.getLegalTeams = function (activeOnly) {
                return this.$http.get("".concat(baseUrl, "?activeOnly=").concat(activeOnly));
            };
            __decorate([
                app.ApiExecutor()
            ], LegalTeamRepository.prototype, "getLegalTeams", null);
            return LegalTeamRepository;
        }());
        angular
            .module('app.config')
            .service('config.legalTeams.repository', LegalTeamRepository);
    })(config = app.config || (app.config = {}));
})(app || (app = {}));
var app;
(function (app) {
    var config;
    (function (config) {
        var baseUrl = 'api/configuration/lookup-types';
        var LookupRepository = (function () {
            function LookupRepository($http) {
                this.$http = $http;
                'ngInject';
                'ngInject';
            }
            LookupRepository.prototype.getLookupType = function (id) {
                return this.$http.get("".concat(baseUrl, "/").concat(id));
            };
            __decorate([
                app.ApiExecutor()
            ], LookupRepository.prototype, "getLookupType", null);
            return LookupRepository;
        }());
        angular
            .module('app.config')
            .service('config.lookups.repository', LookupRepository);
    })(config = app.config || (app.config = {}));
})(app || (app = {}));
var app;
(function (app) {
    var core;
    (function (core) {
        var exception;
        (function (exception_1) {
            'use strict';
            var ExceptionConfig = (function () {
                function ExceptionConfig() {
                    this.config = {};
                }
                ExceptionConfig.prototype.$get = function () {
                    return this.config;
                };
                return ExceptionConfig;
            }());
            angular
                .module('app.core.exception')
                .provider('exceptionConfig', ExceptionConfig)
                .config(configure);
            function configure($provide) {
                'ngInject';
                $provide.decorator('$exceptionHandler', extendExceptionHandler);
            }
            function extendExceptionHandler($delegate, exceptionConfig, $injector) {
                'ngInject';
                return function (exception, cause) {
                    $delegate(exception, cause);
                    if (exception && exception.suppressToast)
                        return;
                    var errorData = { exception: exception, cause: cause };
                    var msg = exception.message;
                    var logger = $injector.get('logger');
                    logger.error(msg, errorData);
                };
            }
        })(exception = core.exception || (core.exception = {}));
    })(core = app.core || (app.core = {}));
})(app || (app = {}));
var app;
(function (app) {
    var core;
    (function (core) {
        var exception;
        (function (exception) {
            'use strict';
            var ExceptionService = (function () {
                function ExceptionService(logger) {
                    this.logger = logger;
                    'ngInject';
                    'ngInject';
                }
                ExceptionService.prototype.catcher = function (message) {
                    var _this = this;
                    return function (error) { return _this.logger.error(message, error); };
                };
                return ExceptionService;
            }());
            angular
                .module('app.core.exception')
                .service('exception', ExceptionService);
        })(exception = core.exception || (core.exception = {}));
    })(core = app.core || (app.core = {}));
})(app || (app = {}));
var app;
(function (app) {
    var core;
    (function (core) {
        var logging;
        (function (logging) {
            var LogService = (function () {
                function LogService($log, $mdToast) {
                    this.$log = $log;
                    this.$mdToast = $mdToast;
                    this.showToasts = true;
                    'ngInject';
                    'ngInject';
                }
                LogService.prototype.error = function (message, data) {
                    if (!message) {
                        return;
                    }
                    var toast = this.$mdToast.simple().textContent('Error: ' + message).hideDelay(0).action('CLOSE');
                    this.$mdToast.show(toast);
                    this.$log.error('Error: ' + message, data);
                };
                LogService.prototype.info = function (message, data) {
                    this.$mdToast.showSimple(message);
                    this.$log.info('Info: ' + message, data);
                };
                LogService.prototype.success = function (message, data) {
                    this.$mdToast.showSimple(message);
                    this.$log.info('Success: ' + message, data);
                };
                LogService.prototype.warning = function (message, data) {
                    this.$mdToast.showSimple(message);
                    this.$log.warn('Warning: ' + message, data);
                };
                return LogService;
            }());
            angular
                .module('app.core.logging')
                .service('logger', LogService);
        })(logging = core.logging || (core.logging = {}));
    })(core = app.core || (app.core = {}));
})(app || (app = {}));
var app;
(function (app) {
    var core;
    (function (core) {
        var routing;
        (function (routing) {
            angular
                .module('app.core.routing')
                .directive('formInterrupt', formInterrupt);
            function formInterrupt(interrupt) {
                'ngInject';
                return {
                    require: 'form',
                    link: function (scope, element, attrs, form) {
                        scope.$watch(function () { return form.$pristine; }, function (value) {
                            if (value) {
                                interrupt.allowNavigation();
                            }
                            else {
                                interrupt.preventNavigation();
                            }
                        });
                        scope.$on('$destroy', function () { return interrupt.allowNavigation(); });
                    }
                };
            }
        })(routing = core.routing || (core.routing = {}));
    })(core = app.core || (app.core = {}));
})(app || (app = {}));
var app;
(function (app) {
    var core;
    (function (core) {
        var routing;
        (function (routing) {
            function redirectHandler(transition) {
                var $state = transition.injector().get('$state');
                var preferences = transition.injector().get('preferences');
                var data = transition.to().data;
                var result = preferences.getPreference(data.redirectPreference);
                if (!$state.href(result, transition.params())) {
                    result = undefined;
                }
                return result || data.redirectFallback;
            }
            routing.redirectHandler = redirectHandler;
            var getRedirectPreferenceFromState = function (state) {
                for (var i = state.path.length - 2; i >= 0; i--) {
                    if (state.data && state.data.redirectPreference) {
                        return state.data.redirectPreference;
                    }
                }
            };
            var setupExitHandler = function ($transitions, preferences) {
                $transitions.onSuccess({
                    to: function (state) { return !!getRedirectPreferenceFromState(state); }
                }, function (transition) {
                    var to = transition.$to();
                    var preferenceName = getRedirectPreferenceFromState(to);
                    preferences.setPreference(preferenceName, to.name);
                });
            };
            angular
                .module('app.core.routing')
                .run(setupExitHandler);
        })(routing = core.routing || (core.routing = {}));
    })(core = app.core || (app.core = {}));
})(app || (app = {}));
var app;
(function (app) {
    var core;
    (function (core) {
        var routing;
        (function (routing) {
            var RoutingHelperService = (function () {
                function RoutingHelperService($rootScope, $location, $state, routingConfig) {
                    this.$rootScope = $rootScope;
                    this.$location = $location;
                    this.$state = $state;
                    this.routingConfig = routingConfig;
                    'ngInject';
                    'ngInject';
                }
                RoutingHelperService.prototype.back = function (fallbackUrl) {
                    var previousState = this.$rootScope.previousState;
                    if (previousState.name) {
                        this.$state.go(previousState.name, previousState.params);
                    }
                    else if (fallbackUrl) {
                        this.$location.path(fallbackUrl.replace(/^\#/, ''));
                    }
                    else {
                        throw new Error('fallbackUrl not provided');
                    }
                };
                RoutingHelperService.prototype.configureRoutes = function (routes) {
                    var _this = this;
                    angular.forEach(routes, function (route) {
                        var data = route.data || {};
                        route.data = data;
                        if (data.redirectPreference) {
                            data.redirectFallback = route.redirectTo;
                            route.redirectTo = routing.redirectHandler;
                        }
                        _this.routingConfig.$stateProvider.state(route);
                    });
                };
                return RoutingHelperService;
            }());
            angular
                .module('app.core.routing')
                .service('routingHelper', RoutingHelperService);
        })(routing = core.routing || (core.routing = {}));
    })(core = app.core || (app.core = {}));
})(app || (app = {}));
var app;
(function (app) {
    var core;
    (function (core) {
        var routing;
        (function (routing) {
            var RoutingConfig = (function () {
                function RoutingConfig() {
                    this.config = {};
                }
                RoutingConfig.prototype.$get = function () {
                    return this.config;
                };
                return RoutingConfig;
            }());
            angular
                .module('app.core.routing')
                .provider('routingConfig', RoutingConfig)
                .config(configure);
            function configure($stateProvider, $urlRouterProvider, routingConfigProvider) {
                'ngInject';
                var config = routingConfigProvider.config;
                config.$stateProvider = $stateProvider;
                $urlRouterProvider.otherwise('/');
                $stateProvider.state({
                    name: 'app',
                    url: '',
                    template: '<ce-app layout flex></ce-app>'
                });
            }
        })(routing = core.routing || (core.routing = {}));
    })(core = app.core || (app.core = {}));
})(app || (app = {}));
var app;
(function (app) {
    var core;
    (function (core) {
        var routing;
        (function (routing) {
            var setupHandler = function ($transitions, $rootScope) {
                'ngInject';
                $rootScope.previousState = {};
                $transitions.onSuccess({}, function (transition) {
                    var fromState = transition.$from();
                    var fromParams = transition.params('from');
                    $rootScope.previousState.name = fromState.name;
                    $rootScope.previousState.params = fromParams;
                });
            };
            angular
                .module('app.core.routing')
                .run(setupHandler);
        })(routing = core.routing || (core.routing = {}));
    })(core = app.core || (app.core = {}));
})(app || (app = {}));
var app;
(function (app) {
    var core;
    (function (core) {
        var routing;
        (function (routing) {
            var setupHandler = function ($transitions, $window) {
                'ngInject';
                $transitions.onSuccess({
                    to: function (state) { return state.data && state.data.title; }
                }, function (transition) {
                    var _a;
                    var title = transition.to().data.title;
                    if (title instanceof Array) {
                        var args = [];
                        for (var i = 0; i < title.length - 1; i++) {
                            args.push(transition.injector().get(title[i]));
                        }
                        title = (_a = title[title.length - 1]).call.apply(_a, __spreadArray([transition.to()], args, false));
                    }
                    $window.document.title = title + ' | PANDA';
                });
            };
            angular
                .module('app.core.routing')
                .run(setupHandler);
        })(routing = core.routing || (core.routing = {}));
    })(core = app.core || (app.core = {}));
})(app || (app = {}));
var app;
(function (app) {
    var core;
    (function (core) {
        var security;
        (function (security) {
            function featureDirective(auth) {
                'ngInject';
                return {
                    restrict: 'A',
                    link: function (scope, elem, attrs) {
                        if (!auth.checkFeature(attrs.amFeature)) {
                            elem.hide();
                        }
                    }
                };
            }
            angular
                .module('app.core.security')
                .directive('amFeature', featureDirective);
        })(security = core.security || (core.security = {}));
    })(core = app.core || (app.core = {}));
})(app || (app = {}));
var app;
(function (app) {
    var core;
    (function (core) {
        var security;
        (function (security) {
            var interceptorName = 'authInterceptor';
            function interceptor($q, $window, configurationProvider, auth) {
                'ngInject';
                return {
                    'request': function (config) {
                        return auth.loginSilent().then(function () {
                            config.headers = config.headers || {};
                            config.headers.Authorization = 'Bearer ' + auth.accessToken;
                            return config;
                        });
                    },
                    'responseError': function (response) {
                        if (response.status === 401) {
                            $window.location.replace('/');
                        }
                        var error = Object.create(Error.prototype);
                        if (response && response.data) {
                            error.message = response.data.message;
                        }
                        else if (typeof (response) === 'string') {
                            error.message = response;
                        }
                        else {
                            error.message = 'Error';
                        }
                        error.name = 'XHR';
                        error.status = response.status;
                        error.statusText = response.statusText;
                        error.xhrStatus = response.xhrStatus;
                        if (response.suppressToast) {
                            error.suppressToast = response.suppressToast;
                        }
                        return $q.reject(error);
                    }
                };
            }
            function configure($httpProvider) {
                'ngInject';
                $httpProvider.interceptors.push(interceptorName);
            }
            angular
                .module('app.data')
                .factory(interceptorName, interceptor)
                .config(configure);
        })(security = core.security || (core.security = {}));
    })(core = app.core || (app.core = {}));
})(app || (app = {}));
var app;
(function (app) {
    var core;
    (function (core) {
        var security;
        (function (security) {
            var directive = function (auth) {
                'ngInject';
                var applyPermissions = function (elem, permission, enforceAll) {
                    if (enforceAll === void 0) { enforceAll = true; }
                    if (permission) {
                        var success = enforceAll
                            ? auth.checkPermissions.apply(auth, permission) : auth.checkPermissionsAny.apply(auth, permission);
                        if (!success) {
                            elem.hide();
                        }
                    }
                };
                return {
                    restrict: 'A',
                    link: function (scope, elem, attrs) {
                        var permissions = scope.$eval(attrs.permission);
                        if (permissions.constructor === Array) {
                            applyPermissions(elem, permissions);
                        }
                        else {
                            applyPermissions(elem, permissions.permissions, permissions.enforceAll);
                        }
                    }
                };
            };
            angular
                .module('app.core.security')
                .directive('permission', directive);
        })(security = core.security || (core.security = {}));
    })(core = app.core || (app.core = {}));
})(app || (app = {}));
var app;
(function (app) {
    var core;
    (function (core) {
        var security;
        (function (security) {
            var directive = function (auth, $rootScope) {
                'ngInject';
                return {
                    restrict: 'A',
                    scope: {
                        roles: '=amRoles'
                    },
                    link: function (scope, elem) {
                        if (auth.checkRoles.apply(auth, scope.roles)) {
                            elem.show();
                        }
                        else {
                            elem.hide();
                        }
                    }
                };
            };
            angular
                .module('app.core.security')
                .directive('amRoles', directive);
        })(security = core.security || (core.security = {}));
    })(core = app.core || (app.core = {}));
})(app || (app = {}));
var app;
(function (app) {
    var core;
    (function (core) {
        var security;
        (function (security) {
            var routes = [
                {
                    name: '403',
                    url: '/403',
                    templateUrl: 'app/core/security/403/403.html',
                    data: {
                        bypassAuthentication: true
                    }
                }
            ];
            var runner = function (routingHelper) {
                'ngInject';
                routingHelper.configureRoutes(routes);
            };
            angular
                .module('app.core.security')
                .run(runner);
        })(security = core.security || (core.security = {}));
    })(core = app.core || (app.core = {}));
})(app || (app = {}));
var app;
(function (app) {
    var core;
    (function (core) {
        var security;
        (function (security) {
            var runner = function (auth) {
                'ngInject';
                auth.login();
            };
            angular
                .module('app.core.security')
                .run(runner);
        })(security = core.security || (core.security = {}));
    })(core = app.core || (app.core = {}));
})(app || (app = {}));
var app;
(function (app) {
    var core;
    (function (core) {
        var CommonService = (function () {
            function CommonService($mdToast, $q, $rootScope, $state, $stateParams, $timeout, $window, auth, configHelper, confirmation, logger, moment, routingHelper) {
                this.$mdToast = $mdToast;
                this.$q = $q;
                this.$rootScope = $rootScope;
                this.$state = $state;
                this.$stateParams = $stateParams;
                this.$timeout = $timeout;
                this.$window = $window;
                this.auth = auth;
                this.configHelper = configHelper;
                this.confirmation = confirmation;
                this.logger = logger;
                this.moment = moment;
                this.routingHelper = routingHelper;
                'ngInject';
                'ngInject';
            }
            CommonService.prototype.$broadcast = function () {
                return this.$rootScope.$broadcast.apply(this.$rootScope, arguments);
            };
            CommonService.prototype.setTitle = function (title) {
                this.$rootScope.title = "".concat(title, " - PANDA");
            };
            return CommonService;
        }());
        angular
            .module('app.core')
            .service('common', CommonService);
    })(core = app.core || (app.core = {}));
})(app || (app = {}));
var app;
(function (app) {
    var documentManagement;
    (function (documentManagement) {
        var NewDocumentDialogController = (function () {
            function NewDocumentDialogController(common, $mdDialog, datacontext, blobHelper, source, sourceId, involvementId, folderId) {
                var _this = this;
                this.common = common;
                this.$mdDialog = $mdDialog;
                this.datacontext = datacontext;
                this.blobHelper = blobHelper;
                this.source = source;
                this.sourceId = sourceId;
                this.involvementId = involvementId;
                this.folderId = folderId;
                this.data = { parameters: {} };
                this.isSubmitting = false;
                this.getTemplates = function () {
                    return _this.datacontext.documentAssembly
                        .getTemplates(_this.source)
                        .then(function (result) { return _this.templates = result; });
                };
                'ngInject';
                'ngInject';
            }
            NewDocumentDialogController.prototype.submit = function () {
                var _this = this;
                this.isSubmitting = true;
                this.datacontext.documentManagement
                    .createDocument(this.data)
                    .then(function (buffer) {
                    if (buffer) {
                        _this.blobHelper.open({
                            buffer: buffer,
                            contentType: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
                            fileName: "".concat(_this.template.title, ".docx")
                        });
                        _this.$mdDialog.hide();
                    }
                })
                    .finally(function () { return _this.isSubmitting = false; });
            };
            NewDocumentDialogController.prototype.$onInit = function () {
                this.getTemplates();
                this.setData();
            };
            NewDocumentDialogController.prototype.setData = function () {
                this.data.involvementId = this.involvementId;
                this.data.source = this.source;
                this.data.sourceId = this.sourceId;
                this.data.folderId = this.folderId;
                var datasetName = this.source.toLowerCase().replace(/ /g, '-');
                this.data.parameters[datasetName] = this.data.sourceId;
                this.data.parameters['involvement'] = this.involvementId;
                this.data.parameters['pwd'] = this.involvementId;
                this.data.parameters['caller'] = this.involvementId;
                this.data.parameters['client'] = this.involvementId;
                this.data.parameters['primary-staff'] = this.involvementId;
                this.data.parameters['adverse-party'] = this.involvementId;
            };
            NewDocumentDialogController.prototype.cancel = function () {
                this.$mdDialog.cancel();
            };
            NewDocumentDialogController.$inject = ['common', '$mdDialog', 'datacontext', 'blobHelper', 'source', 'sourceId', 'involvementId', 'folderId'];
            NewDocumentDialogController = __decorate([
                app.Controller('app.documentManagement', 'NewDocumentDialogController')
            ], NewDocumentDialogController);
            return NewDocumentDialogController;
        }());
    })(documentManagement = app.documentManagement || (app.documentManagement = {}));
})(app || (app = {}));
var app;
(function (app) {
    var documentManagement;
    (function (documentManagement) {
        var NewDocumentView = (function () {
            function NewDocumentView(common, datacontext, blobHelper) {
                var _this = this;
                this.common = common;
                this.datacontext = datacontext;
                this.blobHelper = blobHelper;
                this.data = { parameters: {} };
                this.isSubmitting = false;
                this.getTemplates = function () {
                    return _this.datacontext.documentAssembly
                        .getTemplates(_this.source)
                        .then(function (result) { return _this.templates = result; });
                };
                'ngInject';
                'ngInject';
            }
            NewDocumentView.prototype.submit = function () {
                var _this = this;
                this.isSubmitting = true;
                this.datacontext.documentManagement
                    .createDocument(this.data)
                    .then(function (buffer) {
                    if (buffer) {
                        _this.blobHelper.open({
                            buffer: buffer,
                            contentType: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
                            fileName: "".concat(_this.template.title, ".docx")
                        });
                        _this.common.$state.go('^');
                    }
                })
                    .finally(function () { return _this.isSubmitting = false; });
            };
            NewDocumentView.prototype.$onInit = function () {
                this.getTemplates();
            };
            NewDocumentView.prototype.$onChanges = function () {
                this.data.involvementId = this.involvementId;
                this.data.source = this.source;
                this.data.sourceId = this.sourceId;
                this.data.parameters[this.data.source] = this.data.sourceId;
                this.data.parameters['involvementId'] = this.involvementId;
            };
            NewDocumentView.$inject = ['common', 'datacontext', 'blobHelper'];
            NewDocumentView = __decorate([
                app.Component('app.documentManagement', 'pamNewDocument', {
                    templateUrl: 'app/documentManagement/new-document/new-document.html',
                    bindings: {
                        source: '<',
                        sourceId: '<',
                        involvementId: '<'
                    }
                })
            ], NewDocumentView);
            return NewDocumentView;
        }());
    })(documentManagement = app.documentManagement || (app.documentManagement = {}));
})(app || (app = {}));
var app;
(function (app) {
    var documentManagement;
    (function (documentManagement) {
        var baseUrl = 'api/document-assembly';
        var DocumentAssemblyRepository = (function () {
            function DocumentAssemblyRepository($http) {
                this.$http = $http;
                'ngInject';
                'ngInject';
            }
            DocumentAssemblyRepository.prototype.assembleDocument = function (data) {
                return this.$http.post("".concat(baseUrl, "/documents"), data, {
                    responseType: 'arraybuffer'
                });
            };
            DocumentAssemblyRepository.prototype.getTemplates = function (source) {
                return this.$http.get("".concat(baseUrl, "/templates"), { params: { appliesTo: source } });
            };
            __decorate([
                app.ApiExecutor()
            ], DocumentAssemblyRepository.prototype, "assembleDocument", null);
            __decorate([
                app.ApiExecutor()
            ], DocumentAssemblyRepository.prototype, "getTemplates", null);
            return DocumentAssemblyRepository;
        }());
        angular
            .module('app.documentManagement')
            .service('documentAssembly.repository', DocumentAssemblyRepository);
    })(documentManagement = app.documentManagement || (app.documentManagement = {}));
})(app || (app = {}));
var app;
(function (app) {
    var documentManagement;
    (function (documentManagement) {
        var baseUrl = 'api/document-management';
        var DocumentManagementRepository = (function () {
            function DocumentManagementRepository($http, exception) {
                this.$http = $http;
                this.exception = exception;
                'ngInject';
                'ngInject';
            }
            DocumentManagementRepository.prototype.createDocument = function (data) {
                return this.$http.post("".concat(baseUrl, "/documents"), data, {
                    responseType: 'arraybuffer'
                });
            };
            DocumentManagementRepository.prototype.changeDocumentFolder = function (data) {
                return this.$http.put("".concat(baseUrl, "/documents/").concat(data.documentId, "/change"), data);
            };
            DocumentManagementRepository.prototype.moveDocument = function (data) {
                return this.$http.put("".concat(baseUrl, "/documents/").concat(data.documentId, "/move"), data);
            };
            DocumentManagementRepository.prototype.copyDocument = function (data) {
                return this.$http.put("".concat(baseUrl, "/documents/").concat(data.documentId, "/copy"), data);
            };
            DocumentManagementRepository.prototype.renameDocument = function (data) {
                return this.$http.put("".concat(baseUrl, "/documents/").concat(data.documentId, "/rename"), data);
            };
            DocumentManagementRepository.prototype.deleteDocument = function (documentId) {
                return this.$http.delete("".concat(baseUrl, "/documents/").concat(documentId));
            };
            DocumentManagementRepository.prototype.downloadDocument = function (documentId) {
                var _this = this;
                var config = { responseType: 'arraybuffer' };
                return this.$http.get("".concat(baseUrl, "/documents/").concat(documentId, "/download"), config).then(function (response) {
                    var contentType = response.headers()['content-type'];
                    var fileName = response.headers()['content-disposition'];
                    if (fileName) {
                        fileName = fileName.split(';')[1].split('=')[1].replace(/"/g, '');
                    }
                    return {
                        buffer: response.data,
                        contentType: contentType,
                        fileName: fileName
                    };
                }).catch(function (error) { return _this.exception.catcher('XHR for downloadDocument failed')(error); });
            };
            DocumentManagementRepository.prototype.getDocuments = function (params) {
                return this.$http.get("".concat(baseUrl, "/documents"), { params: params });
            };
            DocumentManagementRepository.prototype.getFolders = function (params) {
                return this.$http.get("".concat(baseUrl, "/folders"), { params: params });
            };
            DocumentManagementRepository.prototype.createFolder = function (data) {
                return this.$http.post("".concat(baseUrl, "/folders"), data);
            };
            DocumentManagementRepository.prototype.updateFolder = function (data) {
                return this.$http.put("".concat(baseUrl, "/folders/").concat(data.id), data);
            };
            DocumentManagementRepository.prototype.deleteFolder = function (folderId) {
                return this.$http.delete("".concat(baseUrl, "/folders/").concat(folderId));
            };
            __decorate([
                app.ApiExecutor()
            ], DocumentManagementRepository.prototype, "createDocument", null);
            __decorate([
                app.ApiExecutor()
            ], DocumentManagementRepository.prototype, "changeDocumentFolder", null);
            __decorate([
                app.ApiExecutor()
            ], DocumentManagementRepository.prototype, "moveDocument", null);
            __decorate([
                app.ApiExecutor()
            ], DocumentManagementRepository.prototype, "copyDocument", null);
            __decorate([
                app.ApiExecutor()
            ], DocumentManagementRepository.prototype, "renameDocument", null);
            __decorate([
                app.ApiExecutor({ returnValue: true })
            ], DocumentManagementRepository.prototype, "deleteDocument", null);
            __decorate([
                app.ApiExecutor()
            ], DocumentManagementRepository.prototype, "getDocuments", null);
            __decorate([
                app.ApiExecutor()
            ], DocumentManagementRepository.prototype, "getFolders", null);
            __decorate([
                app.ApiExecutor()
            ], DocumentManagementRepository.prototype, "createFolder", null);
            __decorate([
                app.ApiExecutor()
            ], DocumentManagementRepository.prototype, "updateFolder", null);
            __decorate([
                app.ApiExecutor({ returnValue: true })
            ], DocumentManagementRepository.prototype, "deleteFolder", null);
            return DocumentManagementRepository;
        }());
        angular
            .module('app.documentManagement')
            .service('documentManagement.repository', DocumentManagementRepository);
    })(documentManagement = app.documentManagement || (app.documentManagement = {}));
})(app || (app = {}));
var app;
(function (app) {
    var entities;
    (function (entities) {
        var CaseList = (function () {
            function CaseList() {
            }
            CaseList = __decorate([
                app.Component('app.entities', 'ceCaseListByEntity', {
                    templateUrl: 'app/entities/case-listing/case-list.html',
                    bindings: {
                        cases: '<',
                        refresh: '&',
                        paging: '<',
                        promise: '<'
                    }
                })
            ], CaseList);
            return CaseList;
        }());
    })(entities = app.entities || (app.entities = {}));
})(app || (app = {}));
var app;
(function (app) {
    var entities;
    (function (entities) {
        var CaseListingByEntity = (function () {
            function CaseListingByEntity($mdDialog, common, datacontext) {
                var _this = this;
                this.$mdDialog = $mdDialog;
                this.common = common;
                this.datacontext = datacontext;
                this.paging = {
                    page: 1,
                    pageSize: 100
                };
                this.getCases = function () {
                    if (!_this.entityId) {
                        return;
                    }
                    _this.promise = _this.datacontext.entities
                        .getCaseListing(_this.entityId, _this.paging)
                        .then(function (result) { return _this.cases = result; });
                };
                'ngInject';
                'ngInject';
            }
            CaseListingByEntity.prototype.$onChanges = function () {
                this.getCases();
            };
            CaseListingByEntity.$inject = ['$mdDialog', 'common', 'datacontext'];
            CaseListingByEntity = __decorate([
                app.Component('app.entities', 'ceEntityCaseListing', {
                    templateUrl: 'app/entities/case-listing/case-listing.html',
                    bindings: {
                        entityId: '<'
                    }
                })
            ], CaseListingByEntity);
            return CaseListingByEntity;
        }());
    })(entities = app.entities || (app.entities = {}));
})(app || (app = {}));
var app;
(function (app) {
    var entities;
    (function (entities) {
        var RelatedEntities = (function () {
            function RelatedEntities($mdDialog, common, datacontext) {
                var _this = this;
                this.$mdDialog = $mdDialog;
                this.common = common;
                this.datacontext = datacontext;
                this.$onInit = function () {
                    _this.getEntities();
                };
                this.getEntities = function () {
                    return _this.datacontext.entities
                        .getRelatedEntities(_this.entityId)
                        .then(function (result) {
                        _this.entities = result;
                    });
                };
                'ngInject';
                'ngInject';
            }
            RelatedEntities.prototype.deleteAssociation = function (association) {
                var _this = this;
                this.common.confirmation.show({
                    title: 'Delete Relationship',
                    message: 'Are you sure you want to delete this entity relationship?',
                    ok: 'Delete',
                    cancel: 'Keep'
                }).then(function () {
                    _this.datacontext.entities
                        .deleteAssociation(association.entityId, association.relatedEntityId)
                        .then(function () {
                        _this.getEntities();
                    });
                }).catch(angular.noop);
            };
            RelatedEntities.$inject = ['$mdDialog', 'common', 'datacontext'];
            RelatedEntities = __decorate([
                app.Component('app.entities', 'ceRelatedEntities', {
                    templateUrl: 'app/entities/related-entities/related-entities.html',
                    bindings: {
                        entityId: '<'
                    }
                })
            ], RelatedEntities);
            return RelatedEntities;
        }());
    })(entities = app.entities || (app.entities = {}));
})(app || (app = {}));
var app;
(function (app) {
    var entities;
    (function (entities) {
        var RelatedEntityList = (function () {
            function RelatedEntityList(common) {
                this.common = common;
                'ngInject';
                'ngInject';
            }
            RelatedEntityList.prototype.hasSupervisorRole = function (association) {
                return association.roles.some(function (r) { return r === 'Supervisor'; });
            };
            RelatedEntityList.prototype.editRoles = function (association) {
                this.common.$state.go('^.edit', { association: association });
            };
            RelatedEntityList.$inject = ['common'];
            RelatedEntityList = __decorate([
                app.Component('app.entities', 'ceRelatedEntityList', {
                    templateUrl: 'app/entities/related-entities/related-entity-list.html',
                    bindings: {
                        entities: '<',
                        entityId: '<',
                        onDelete: '&'
                    }
                })
            ], RelatedEntityList);
            return RelatedEntityList;
        }());
    })(entities = app.entities || (app.entities = {}));
})(app || (app = {}));
var app;
(function (app) {
    var entities;
    (function (entities) {
        var EntityRelationships = (function () {
            function EntityRelationships(common, datacontext) {
                var _this = this;
                this.common = common;
                this.datacontext = datacontext;
                this.selectedRoles = [];
                this.$onInit = function () {
                    _this.getRoles().then(function () { return _this.getExistingRoles(); });
                };
                this.getRoles = function () {
                    var lookupTypeId = '';
                    var promise;
                    lookupTypeId = "".concat(_this.association.entityType, "-").concat(_this.association.relatedEntityType, "-relationship");
                    promise = _this.datacontext.config.lookups.getLookupType(lookupTypeId);
                    return promise.then(function (result) {
                        _this.roles = result.lookups;
                    });
                };
                this.getExistingRoles = function () {
                    _.forEach(_this.association.roles, function (ieRole) {
                        _this.selectedRoles.push(_.find(_this.roles, function (role) {
                            return role.value === ieRole;
                        }));
                    });
                };
                this.onSubmit = function () {
                    _this.isSubmitting = true;
                    var roles = _.map(_this.selectedRoles, 'value');
                    angular.extend(_this.association, {
                        roles: roles
                    });
                    var promise;
                    promise = _this.datacontext.entities.updateAssociation(_this.association);
                    promise.finally(function () {
                        _this.isSubmitting = false;
                        _this.common.$mdToast.showSimple('Entity Updated');
                        _this.common.$state.go('^.listing');
                    });
                };
                'ngInject';
                'ngInject';
            }
            EntityRelationships.prototype.transformChip = function (chip) {
                if (angular.isObject(chip)) {
                    return chip;
                }
                return { name: chip, type: 'new' };
            };
            EntityRelationships.prototype.querySearch = function (query) {
                var results = query ? _.filter(this.roles, function (r) {
                    return query === '*' || r.value.toLowerCase().indexOf(query) > -1;
                }) : [];
                return results;
            };
            EntityRelationships.$inject = ['common', 'datacontext'];
            EntityRelationships = __decorate([
                app.Component('app.entities', 'ceEditEntityRelationships', {
                    templateUrl: 'app/entities/roles/edit-roles.html',
                    bindings: {
                        association: '<'
                    }
                })
            ], EntityRelationships);
            return EntityRelationships;
        }());
    })(entities = app.entities || (app.entities = {}));
})(app || (app = {}));
var app;
(function (app) {
    var entities;
    (function (entities) {
        var EntityRelationships = (function () {
            function EntityRelationships(common, datacontext) {
                var _this = this;
                this.common = common;
                this.datacontext = datacontext;
                this.selectedRoles = [];
                this.entity = {};
                this.association = {};
                this.$onInit = function () {
                    if (_this.person) {
                        _this.entity.entityId = _this.person.id;
                        _this.entity.entityType = 'Person';
                        _this.entity.isUser = _this.person.isUser;
                    }
                    else {
                        _this.entity.entityId = _this.agency.id;
                        _this.entity.entityType = 'Agency';
                        _this.entity.isUser = false;
                    }
                    _this.getRoles().then(function () { return _this.getExistingRoles(); });
                };
                this.getRoles = function () {
                    var lookupTypeId = '';
                    var promise;
                    lookupTypeId = "".concat(_this.entity.entityType, "-").concat(_this.relatedEntity.entityType, "-relationship");
                    promise = _this.datacontext.config.lookups.getLookupType(lookupTypeId);
                    return promise.then(function (result) {
                        _this.roles = result.lookups;
                    });
                };
                this.getExistingRoles = function () {
                    return _this.datacontext.entities.getAssociation(_this.entity.entityId, _this.relatedEntity.entityId).then(function (result) {
                        angular.extend(_this.association, result);
                        _.forEach(_this.association.roles, function (ieRole) {
                            _this.selectedRoles.push(_.find(_this.roles, function (role) {
                                return role.value === ieRole;
                            }));
                        });
                    });
                };
                this.onSubmit = function () {
                    _this.isSubmitting = true;
                    var roles = _.map(_this.selectedRoles, 'value');
                    angular.extend(_this.association, {
                        entityId: _this.entity.entityId,
                        relatedEntityId: _this.relatedEntity.entityId,
                        roles: roles
                    });
                    var promise;
                    if (_this.association.id) {
                        promise = _this.datacontext.entities.updateAssociation(_this.association);
                    }
                    else {
                        promise = _this.datacontext.entities.createAssociation(_this.association);
                    }
                    promise.finally(function () {
                        _this.isSubmitting = false;
                        _this.common.$mdToast.showSimple('Entity Updated');
                        _this.common.$state.go('^.^.^');
                    });
                };
                'ngInject';
                'ngInject';
            }
            EntityRelationships.prototype.transformChip = function (chip) {
                if (angular.isObject(chip)) {
                    return chip;
                }
                return { name: chip, type: 'new' };
            };
            EntityRelationships.prototype.querySearch = function (query) {
                var results = query ? _.filter(this.roles, function (r) {
                    return query === '*' || r.value.toLowerCase().indexOf(query) > -1;
                }) : [];
                return results;
            };
            EntityRelationships.$inject = ['common', 'datacontext'];
            EntityRelationships = __decorate([
                app.Component('app.entities', 'ceEntityRelationships', {
                    templateUrl: 'app/entities/roles/roles.html',
                    bindings: {
                        agency: '<',
                        person: '<',
                        relatedEntity: '<'
                    }
                })
            ], EntityRelationships);
            return EntityRelationships;
        }());
    })(entities = app.entities || (app.entities = {}));
})(app || (app = {}));
var app;
(function (app) {
    var entities;
    (function (entities) {
        var SelectEntityType = (function () {
            function SelectEntityType(common) {
                this.common = common;
                'ngInject';
                'ngInject';
            }
            SelectEntityType.$inject = ['common'];
            SelectEntityType = __decorate([
                app.Component('app.entities', 'ceEntityTypes', {
                    templateUrl: 'app/entities/select-type/entity-types.html',
                })
            ], SelectEntityType);
            return SelectEntityType;
        }());
    })(entities = app.entities || (app.entities = {}));
})(app || (app = {}));
var app;
(function (app) {
    var entities;
    (function (entities) {
        var SelectEntityType = (function () {
            function SelectEntityType(common, datacontext) {
                this.common = common;
                this.datacontext = datacontext;
                'ngInject';
                'ngInject';
            }
            SelectEntityType.$inject = ['common', 'datacontext'];
            SelectEntityType = __decorate([
                app.Component('app.entities', 'ceSelectEntityType', {
                    templateUrl: 'app/entities/select-type/select-type.html',
                    bindings: {}
                })
            ], SelectEntityType);
            return SelectEntityType;
        }());
    })(entities = app.entities || (app.entities = {}));
})(app || (app = {}));
var app;
(function (app) {
    var fiscal;
    (function (fiscal) {
        var CreateCheckButtonComponent = (function () {
            function CreateCheckButtonComponent($mdDialog, $scope) {
                this.$mdDialog = $mdDialog;
                this.$scope = $scope;
                'ngInject';
                'ngInject';
            }
            CreateCheckButtonComponent.prototype.openCheckDialog = function () {
                var _this = this;
                var parent = angular.element(document.body);
                return this.$mdDialog.show({
                    parent: parent,
                    fullscreen: true,
                    templateUrl: 'app/fiscal/check/check-dialog.html',
                    controller: 'CheckDialogController',
                    controllerAs: '$ctrl',
                    focusOnOpen: false,
                    locals: {
                        check: null
                    }
                })
                    .catch(function (error) { if (error)
                    throw error; })
                    .finally(function () { _this.refresh(); });
            };
            CreateCheckButtonComponent.$inject = ['$mdDialog', '$scope'];
            CreateCheckButtonComponent = __decorate([
                app.Component('app.fiscal', 'amCreateCheckButton', {
                    template: "\n      <md-button ng-click=\"$ctrl.openCheckDialog()\" permission=\"['checks.edit']\">\n        <md-tooltip>Add Check</md-tooltip>\n        <md-icon aria-hidden=\"true\">add</md-icon>\n      </md-button>",
                    bindings: {
                        refresh: '&'
                    }
                })
            ], CreateCheckButtonComponent);
            return CreateCheckButtonComponent;
        }());
    })(fiscal = app.fiscal || (app.fiscal = {}));
})(app || (app = {}));
var app;
(function (app) {
    var fiscal;
    (function (fiscal) {
        var EditCheckButtonComponent = (function () {
            function EditCheckButtonComponent($mdDialog, $scope) {
                this.$mdDialog = $mdDialog;
                this.$scope = $scope;
                'ngInject';
                'ngInject';
            }
            EditCheckButtonComponent.prototype.openCheckDialog = function () {
                var _this = this;
                var parent = angular.element(document.body);
                return this.$mdDialog.show({
                    parent: parent,
                    fullscreen: true,
                    templateUrl: 'app/fiscal/check/check-dialog.html',
                    controller: 'CheckDialogController',
                    controllerAs: '$ctrl',
                    focusOnOpen: false,
                    locals: {
                        check: this.check
                    }
                })
                    .catch(function (error) { if (error)
                    throw error; })
                    .finally(function () { _this.refresh(); });
            };
            EditCheckButtonComponent.$inject = ['$mdDialog', '$scope'];
            EditCheckButtonComponent = __decorate([
                app.Component('app.fiscal', 'amEditCheckButton', {
                    template: "\n      <md-button class=\"\" style=\"font-weight:normal;text-transform:none;\" ng-click=\"$ctrl.openCheckDialog()\" permission=\"['checks.edit']\">\n        <md-icon aria-hidden=\"true\">edit</md-icon>\n        <span class=\"pl-lg\">Edit Check</span>\n      </md-button>",
                    bindings: {
                        check: '<',
                        refresh: '&'
                    }
                })
            ], EditCheckButtonComponent);
            return EditCheckButtonComponent;
        }());
    })(fiscal = app.fiscal || (app.fiscal = {}));
})(app || (app = {}));
var app;
(function (app) {
    var fiscal;
    (function (fiscal) {
        var ViewCheckButtonComponent = (function () {
            function ViewCheckButtonComponent($mdDialog, $scope) {
                this.$mdDialog = $mdDialog;
                this.$scope = $scope;
                'ngInject';
                'ngInject';
            }
            ViewCheckButtonComponent.prototype.openCheckDialog = function () {
                var parent = angular.element(document.body);
                return this.$mdDialog.show({
                    parent: parent,
                    fullscreen: true,
                    templateUrl: 'app/fiscal/check/check-summary-dialog.html',
                    controller: 'CheckSummaryDialogController',
                    controllerAs: '$ctrl',
                    focusOnOpen: false,
                    locals: {
                        check: this.check
                    }
                })
                    .catch(function (error) { if (error)
                    throw error; });
            };
            ViewCheckButtonComponent.$inject = ['$mdDialog', '$scope'];
            ViewCheckButtonComponent = __decorate([
                app.Component('app.fiscal', 'amViewCheckButton', {
                    template: "\n      <md-button ng-click=\"$ctrl.openCheckDialog()\" style=\"text-align:left;min-width:50px;\" permission=['checks.view']>\n      <md-tooltip>View Check Summary</md-tooltip>\n         {{$ctrl.check.checkNumber}}\n      </md-button>",
                    bindings: {
                        check: '<',
                    }
                })
            ], ViewCheckButtonComponent);
            return ViewCheckButtonComponent;
        }());
    })(fiscal = app.fiscal || (app.fiscal = {}));
})(app || (app = {}));
var app;
(function (app) {
    var fiscal;
    (function (fiscal) {
        var CheckDialogController = (function () {
            function CheckDialogController($scope, $mdDialog, common, datacontext, check) {
                this.$scope = $scope;
                this.$mdDialog = $mdDialog;
                this.common = common;
                this.datacontext = datacontext;
                this.check = check;
                'ngInject';
                'ngInject';
            }
            CheckDialogController.prototype.$onInit = function () {
                this.check = this.check || {};
                if (this.check.id) {
                    this.getCheck();
                }
                else {
                    this.check.receivedDate = moment().startOf('day').toDate();
                }
                this.maxDate = moment().endOf('day').toDate();
            };
            CheckDialogController.prototype.getCheck = function () {
                var _this = this;
                this.datacontext.fiscal
                    .getCheck(this.check.id)
                    .then(function (result) {
                    _this.check = result;
                });
            };
            CheckDialogController.prototype.cancel = function () {
                this.$mdDialog.cancel();
            };
            CheckDialogController.prototype.submit = function () {
                var _this = this;
                this.isSubmitting = true;
                if (typeof this.check.checkDate !== 'string') {
                    this.check.checkDate = this.check.checkDate.toDateString();
                }
                if (typeof this.check.receivedDate !== 'string') {
                    this.check.receivedDate = this.check.receivedDate.toDateString();
                }
                if (this.check.processedDate && typeof this.check.processedDate !== 'string') {
                    this.check.processedDate = this.check.processedDate.toDateString();
                }
                if (this.check.depositedDate && typeof this.check.depositedDate !== 'string') {
                    this.check.depositedDate = this.check.depositedDate.toDateString();
                }
                var checkPromise = this.check.id ?
                    this.datacontext.fiscal.updateCheck(this.check) :
                    this.datacontext.fiscal.createCheck(this.check);
                checkPromise
                    .then(function (check) {
                    if (check) {
                        _this.common.$mdToast.showSimple('Check Saved');
                        _this.submitted();
                    }
                })
                    .finally(function () { return _this.isSubmitting = false; });
            };
            CheckDialogController.prototype.reset = function () {
                this.check.processedDate = null;
                this.check.depositedDate = null;
                this.check.checkDate = null;
                this.check.checkNumber = null;
                this.check.amount = null;
                this.check.purpose = null;
                this.check.payorName = null;
                this.check.payorAddress = null;
                this.check.contactName = null;
                this.check.contactAddress = null;
                this.check.notes = null;
                this.check.reviewed = false;
                angular.element(document).find('#checkNumber').focus();
            };
            CheckDialogController.prototype.submitted = function () {
                if (!this.keepOpen) {
                    this.$mdDialog.hide(this.check);
                }
                else {
                    this.reset();
                }
            };
            CheckDialogController.$inject = ['$scope', '$mdDialog', 'common', 'datacontext', 'check'];
            CheckDialogController = __decorate([
                app.Controller('app.fiscal', 'CheckDialogController')
            ], CheckDialogController);
            return CheckDialogController;
        }());
    })(fiscal = app.fiscal || (app.fiscal = {}));
})(app || (app = {}));
var app;
(function (app) {
    var fiscal;
    (function (fiscal) {
        var CheckSummaryDialogController = (function () {
            function CheckSummaryDialogController($scope, $mdDialog, check) {
                this.$scope = $scope;
                this.$mdDialog = $mdDialog;
                this.check = check;
                'ngInject';
                'ngInject';
            }
            CheckSummaryDialogController.prototype.cancel = function () {
                this.$mdDialog.cancel();
            };
            CheckSummaryDialogController.$inject = ['$scope', '$mdDialog', 'check'];
            CheckSummaryDialogController = __decorate([
                app.Controller('app.fiscal', 'CheckSummaryDialogController')
            ], CheckSummaryDialogController);
            return CheckSummaryDialogController;
        }());
    })(fiscal = app.fiscal || (app.fiscal = {}));
})(app || (app = {}));
var app;
(function (app) {
    var filters;
    (function (filters) {
        var DateFilter = (function () {
            function DateFilter() {
                'ngInject';
            }
            DateFilter.prototype.$onInit = function () {
                this.open = true;
                this.closed = false;
                this.changed();
            };
            DateFilter.prototype.toggleState = function () {
                this.enabled = !this.enabled;
                if (this.enabled) {
                    angular.element('#showOpenCheckbox').focus();
                }
                this.changed();
            };
            DateFilter.prototype.toggleOpen = function () {
                this.open = !this.open;
                this.changed();
            };
            DateFilter.prototype.toggleClosed = function () {
                this.closed = !this.closed;
                this.changed();
            };
            DateFilter.prototype.changed = function () {
                if (this.enabled) {
                    switch (true) {
                        case (this.open && this.closed): {
                            this.onChange({ isActive: 2 });
                            break;
                        }
                        case (this.open): {
                            this.onChange({ isActive: 1 });
                            break;
                        }
                        case (this.closed): {
                            this.onChange({ isActive: 0 });
                            break;
                        }
                        default: {
                            this.onChange({ isActive: null });
                            break;
                        }
                    }
                }
                else {
                    this.onChange({ isActive: null });
                }
            };
            DateFilter = __decorate([
                app.Component('app.filters', 'ceActiveFilter', {
                    templateUrl: 'app/filters/active/active-filter.html',
                    bindings: {
                        onChange: '&',
                        enabled: '@',
                    }
                })
            ], DateFilter);
            return DateFilter;
        }());
    })(filters = app.filters || (app.filters = {}));
})(app || (app = {}));
var app;
(function (app) {
    var filters;
    (function (filters) {
        var ActivityTypeFilter = (function () {
            function ActivityTypeFilter(common, datacontext) {
                this.common = common;
                this.datacontext = datacontext;
                this.activityTypes = [
                    { item: 'CaseAssessmentMeeting' },
                    { item: 'CaseClosed' },
                    { item: 'CaseCreated' },
                    { item: 'CaseReopened' },
                    { item: 'CaseReview' },
                    { item: 'ConflictCheck' },
                    { item: 'FollowUp' },
                    { item: 'Note' },
                ];
                'ngInject';
                'ngInject';
            }
            ActivityTypeFilter.prototype.$onInit = function () {
                this.changed();
            };
            ActivityTypeFilter.prototype.toggleState = function () {
                this.enabled = !this.enabled;
                if (this.enabled) {
                    angular.element('#activityType0').focus();
                }
                this.changed();
            };
            ActivityTypeFilter.prototype.toggleType = function (activityType) {
                activityType.isChecked = !activityType.isChecked;
                this.changed();
            };
            ActivityTypeFilter.prototype.changed = function () {
                if (this.enabled) {
                    var activityTypes = _(this.activityTypes).filter(function (x) { return x.isChecked; }).map(function (x) { return x.item; }).value();
                    this.onChange({ activityTypes: activityTypes });
                }
                else {
                    this.onChange({ activityTypes: [] });
                }
            };
            ActivityTypeFilter.$inject = ['common', 'datacontext'];
            ActivityTypeFilter = __decorate([
                app.Component('app.filters', 'ceActivityTypeFilter', {
                    templateUrl: 'app/filters/activityType/activityType-filter.html',
                    bindings: {
                        onChange: '&'
                    }
                })
            ], ActivityTypeFilter);
            return ActivityTypeFilter;
        }());
    })(filters = app.filters || (app.filters = {}));
})(app || (app = {}));
var app;
(function (app) {
    var filters;
    (function (filters) {
        var AmountFilter = (function () {
            function AmountFilter(common, datacontext) {
                this.common = common;
                this.datacontext = datacontext;
                'ngInject';
                'ngInject';
            }
            AmountFilter.prototype.$onInit = function () {
                this.amountChanged();
                if (this.enabled === undefined) {
                    this.enabled = false;
                }
            };
            AmountFilter.prototype.toggleState = function () {
                this.enabled = !this.enabled;
                this.amountChanged();
            };
            AmountFilter.prototype.amountChanged = function () {
                if (this.enabled) {
                    this.onChange({ amounts: { fromAmount: this.fromAmount, toAmount: this.toAmount } });
                }
                else {
                    this.onChange({ amounts: { fromAmount: null, toAmount: null } });
                }
            };
            AmountFilter.$inject = ['common', 'datacontext'];
            AmountFilter = __decorate([
                app.Component('app.filters', 'ceAmountFilter', {
                    templateUrl: 'app/filters/amount/amount-filter.html',
                    bindings: {
                        onChange: '&',
                        enabled: '<?',
                        fromAmount: '<',
                        toAmount: '<'
                    }
                })
            ], AmountFilter);
            return AmountFilter;
        }());
    })(filters = app.filters || (app.filters = {}));
})(app || (app = {}));
var app;
(function (app) {
    var filters;
    (function (filters) {
        var DateFilter = (function () {
            function DateFilter(common, datacontext) {
                this.common = common;
                this.datacontext = datacontext;
                'ngInject';
                'ngInject';
            }
            DateFilter.prototype.$onInit = function () {
                this.dateChanged();
                if (this.enabled === undefined) {
                    this.enabled = false;
                }
            };
            DateFilter.prototype.toggleState = function () {
                this.enabled = !this.enabled;
                if (this.enabled) {
                    angular.element('#fromDate').focus();
                }
                this.dateChanged();
            };
            DateFilter.prototype.dateChanged = function () {
                if (this.enabled) {
                    this.onChange({ dates: { fromDate: this.fromDate, toDate: this.toDate } });
                }
                else {
                    this.onChange({ dates: { fromDate: null, toDate: null } });
                }
            };
            DateFilter.$inject = ['common', 'datacontext'];
            DateFilter = __decorate([
                app.Component('app.filters', 'ceDateFilter', {
                    templateUrl: 'app/filters/date/date-filter.html',
                    bindings: {
                        onChange: '&',
                        enabled: '<?',
                        fromDate: '<',
                        toDate: '<'
                    }
                })
            ], DateFilter);
            return DateFilter;
        }());
    })(filters = app.filters || (app.filters = {}));
})(app || (app = {}));
var app;
(function (app) {
    var filters;
    (function (filters) {
        var LegalTeamFilter = (function () {
            function LegalTeamFilter(common, datacontext) {
                this.common = common;
                this.datacontext = datacontext;
                'ngInject';
                'ngInject';
            }
            LegalTeamFilter.prototype.$onInit = function () {
                this.legalTeams = this.legalTeams || [];
                this.selectedLegalTeams = this.selectedLegalTeams || [];
                this.getLegalTeams();
            };
            LegalTeamFilter.prototype.toggleState = function () {
                this.enabled = !this.enabled;
                if (this.enabled) {
                    angular.element('#legalTeam0').focus();
                }
                this.changed();
            };
            LegalTeamFilter.prototype.getLegalTeams = function () {
                var _this = this;
                return this.datacontext.config.legalTeams.getLegalTeams(false).then(function (legalTeams) {
                    var _a;
                    (_a = _this.legalTeams).push.apply(_a, legalTeams);
                    _this.legalTeams = _.sortBy(_this.legalTeams, ['name']);
                    _this.changed();
                });
            };
            LegalTeamFilter.prototype.changed = function () {
                if (this.enabled) {
                    this.onChange({ legalTeams: this.selectedLegalTeams });
                }
                else {
                    this.onChange({ legalTeams: [] });
                }
            };
            LegalTeamFilter.prototype.isLegalTeamToggled = function (legalTeam) {
                return this.selectedLegalTeams.indexOf(legalTeam) > -1;
            };
            LegalTeamFilter.prototype.toggleLegalTeam = function (legalTeam) {
                var exists = _.find(this.selectedLegalTeams, function (lt) { return lt.id === legalTeam.id; });
                if (exists) {
                    _.remove(this.selectedLegalTeams, function (lt) { return lt.id === legalTeam.id; });
                }
                else {
                    this.selectedLegalTeams.push(legalTeam);
                }
                this.changed();
            };
            LegalTeamFilter.$inject = ['common', 'datacontext'];
            LegalTeamFilter = __decorate([
                app.Component('app.filters', 'ceLegalTeamFilter', {
                    templateUrl: 'app/filters/legalTeam/legalTeam-filter.html',
                    bindings: {
                        onChange: '&',
                        legalTeams: '<',
                        selectedLegalTeams: '<',
                    }
                })
            ], LegalTeamFilter);
            return LegalTeamFilter;
        }());
    })(filters = app.filters || (app.filters = {}));
})(app || (app = {}));
var app;
(function (app) {
    var filters;
    (function (filters) {
        var TimeslipUsersFilter = (function () {
            function TimeslipUsersFilter(common, datacontext) {
                this.common = common;
                this.datacontext = datacontext;
                'ngInject';
                'ngInject';
            }
            TimeslipUsersFilter.prototype.$onInit = function () {
                this.name = this.name || 'STAFF';
                this.users = this.users || [];
                this.selectedUsers = this.selectedUsers || [];
                if (this.users.length > 0) {
                    this.changed();
                }
                else {
                    this.addCurrentUser();
                }
            };
            TimeslipUsersFilter.prototype.addCurrentUser = function () {
                var _this = this;
                return this.datacontext.users.getCurrentUser().then(function (u) {
                    var user = {
                        id: u.id,
                        firstName: u.firstName,
                        lastName: u.lastName,
                        fullName: u.fullName
                    };
                    var index = _.findIndex(_this.users, function (user) { return user.id === u.id; });
                    if (index === -1) {
                        _this.users.push(user);
                        _this.addSelectedUser(user);
                    }
                    return user;
                });
            };
            TimeslipUsersFilter.prototype.toggleState = function () {
                this.enabled = !this.enabled;
                if (this.enabled) {
                    angular.element('#user0').focus();
                    if (this.users.length === 0) {
                        this.addCurrentUser();
                    }
                }
                this.changed();
            };
            TimeslipUsersFilter.prototype.changed = function () {
                this.users = _.sortBy(this.users, ['lastName', 'firstName']);
                if (this.enabled) {
                    this.onChange({ users: this.selectedUsers });
                }
                else {
                    this.onChange({ users: [] });
                }
            };
            TimeslipUsersFilter.prototype.addSelectedUser = function (user) {
                if (!user)
                    return;
                var changed = false;
                this.searchText = null;
                var exists = _.find(this.users, function (u) { return u.id === user.id; });
                if (!exists) {
                    this.users.push(user);
                    changed = true;
                }
                exists = _.find(this.selectedUsers, function (u) { return u.id === user.id; });
                if (!exists) {
                    this.selectedUsers.push(user);
                    changed = true;
                }
                if (changed) {
                    this.changed();
                }
            };
            TimeslipUsersFilter.prototype.isUserToggled = function (user) {
                return this.selectedUsers.indexOf(user) > -1;
            };
            TimeslipUsersFilter.prototype.toggleUser = function (user) {
                var exists = _.find(this.selectedUsers, function (u) { return u.id === user.id; });
                if (exists) {
                    _.remove(this.selectedUsers, function (u) { return u.id === user.id; });
                }
                else {
                    this.selectedUsers.push(user);
                }
                this.changed();
            };
            TimeslipUsersFilter.prototype.removeSelectedUser = function (user) {
                _.remove(this.users, function (u) { return u.id === user.id; });
                _.remove(this.selectedUsers, function (u) { return u.id === user.id; });
                this.changed();
            };
            TimeslipUsersFilter.prototype.search = function (searchText) {
                return this.datacontext.users.getTimeslipUsers(this.searchText);
            };
            TimeslipUsersFilter.$inject = ['common', 'datacontext'];
            TimeslipUsersFilter = __decorate([
                app.Component('app.filters', 'ceTimeslipUsersFilter', {
                    templateUrl: 'app/filters/timeslip-user/user-filter.html',
                    bindings: {
                        name: '@',
                        onChange: '&',
                        users: '<',
                        selectedUsers: '<',
                        enabled: '<',
                        supervisedOnly: '<'
                    }
                })
            ], TimeslipUsersFilter);
            return TimeslipUsersFilter;
        }());
    })(filters = app.filters || (app.filters = {}));
})(app || (app = {}));
var app;
(function (app) {
    var filters;
    (function (filters) {
        var UsersFilter = (function () {
            function UsersFilter(common, datacontext) {
                this.common = common;
                this.datacontext = datacontext;
                'ngInject';
                'ngInject';
            }
            UsersFilter.prototype.$onInit = function () {
                this.placeholder = this.placeholder || 'Search for more...';
                this.name = this.name || 'STAFF';
                this.users = this.users || [];
                this.selectedUsers = this.selectedUsers || [];
                if (this.users.length > 0) {
                    this.changed();
                }
                else {
                    this.addCurrentUser();
                }
            };
            UsersFilter.prototype.addCurrentUser = function () {
                var _this = this;
                return this.datacontext.users.getCurrentUser().then(function (u) {
                    var user = {
                        id: u.id,
                        firstName: u.firstName,
                        lastName: u.lastName,
                        fullName: u.fullName
                    };
                    var index = _.findIndex(_this.users, function (user) { return user.id === u.id; });
                    if (index === -1) {
                        _this.users.push(user);
                        _this.addSelectedUser(user);
                    }
                    return user;
                });
            };
            UsersFilter.prototype.toggleState = function () {
                this.enabled = !this.enabled;
                if (this.enabled) {
                    angular.element('#staff0').focus();
                    if (this.users.length === 0) {
                        this.addCurrentUser();
                    }
                }
                this.changed();
            };
            UsersFilter.prototype.changed = function () {
                this.users = _.sortBy(this.users, ['lastName', 'firstName']);
                if (this.enabled) {
                    this.onChange({ users: this.selectedUsers });
                }
                else {
                    this.onChange({ users: [] });
                }
            };
            UsersFilter.prototype.addSelectedUser = function (user) {
                if (!user)
                    return;
                var changed = false;
                this.searchText = null;
                var exists = _.find(this.users, function (u) { return u.id === user.id; });
                if (!exists) {
                    this.users.push(user);
                    changed = true;
                }
                exists = _.find(this.selectedUsers, function (u) { return u.id === user.id; });
                if (!exists) {
                    this.selectedUsers.push(user);
                    changed = true;
                }
                if (changed) {
                    this.changed();
                }
            };
            UsersFilter.prototype.isUserToggled = function (user) {
                return this.selectedUsers.indexOf(user) > -1;
            };
            UsersFilter.prototype.toggleUser = function (user) {
                var exists = _.find(this.selectedUsers, function (u) { return u.id === user.id; });
                if (exists) {
                    _.remove(this.selectedUsers, function (u) { return u.id === user.id; });
                }
                else {
                    this.selectedUsers.push(user);
                }
                this.changed();
            };
            UsersFilter.prototype.removeSelectedUser = function (user) {
                _.remove(this.users, function (u) { return u.id === user.id; });
                _.remove(this.selectedUsers, function (u) { return u.id === user.id; });
                this.changed();
            };
            UsersFilter.prototype.search = function (searchText) {
                if (this.supervisedOnly) {
                    return this.datacontext.users.getSupervisedUsers(this.searchText);
                }
                else {
                    return this.datacontext.users.getUsers(this.searchText);
                }
            };
            UsersFilter.$inject = ['common', 'datacontext'];
            UsersFilter = __decorate([
                app.Component('app.filters', 'ceUsersFilter', {
                    templateUrl: 'app/filters/user/user-filter.html',
                    bindings: {
                        name: '@',
                        onChange: '&',
                        users: '<',
                        selectedUsers: '<',
                        enabled: '<',
                        supervisedOnly: '<',
                        placeholder: '@'
                    }
                })
            ], UsersFilter);
            return UsersFilter;
        }());
    })(filters = app.filters || (app.filters = {}));
})(app || (app = {}));
var app;
(function (app) {
    var funding;
    (function (funding) {
        var FundingView = (function () {
            function FundingView($mdDialog, common, datacontext) {
                this.$mdDialog = $mdDialog;
                this.common = common;
                this.datacontext = datacontext;
                this.isLoading = false;
                'ngInject';
                'ngInject';
            }
            FundingView.prototype.$onInit = function () {
                this.currentTab = this.common.$state.current.name.split('.')[3];
            };
            FundingView.$inject = ['$mdDialog', 'common', 'datacontext'];
            FundingView = __decorate([
                app.Component('app.funding', 'ceFunding', {
                    templateUrl: 'app/funding/funding-view/funding-view.html',
                    bindings: {
                        involvementId: '<'
                    }
                })
            ], FundingView);
            return FundingView;
        }());
    })(funding = app.funding || (app.funding = {}));
})(app || (app = {}));
var app;
(function (app) {
    var funding;
    (function (funding) {
        var FundingSummary = (function () {
            function FundingSummary($mdDialog, common, datacontext) {
                this.$mdDialog = $mdDialog;
                this.common = common;
                this.datacontext = datacontext;
                'ngInject';
                'ngInject';
            }
            FundingSummary.prototype.$onInit = function () {
                var _this = this;
                this.datacontext.funding.getFundingSummaryView(this.involvementId).
                    then(function (result) { return _this.fundingSummary = result; });
            };
            FundingSummary.$inject = ['$mdDialog', 'common', 'datacontext'];
            FundingSummary = __decorate([
                app.Component('app.funding', 'ceFundingSummary', {
                    templateUrl: 'app/funding/summary/summary.html',
                    bindings: {
                        involvementId: '<'
                    }
                })
            ], FundingSummary);
            return FundingSummary;
        }());
    })(funding = app.funding || (app.funding = {}));
})(app || (app = {}));
var app;
(function (app) {
    var grievance;
    (function (grievance) {
        var CreateGrievanceButtonComponent = (function () {
            function CreateGrievanceButtonComponent($mdDialog, $scope) {
                this.$mdDialog = $mdDialog;
                this.$scope = $scope;
                'ngInject';
                'ngInject';
            }
            CreateGrievanceButtonComponent.prototype.openGrievanceDialog = function () {
                var _this = this;
                var parent = angular.element(document.body);
                return this.$mdDialog.show({
                    parent: parent,
                    fullscreen: true,
                    templateUrl: 'app/grievance/grievance-dialog/grievance-dialog.html',
                    controller: 'GrievanceDialogController',
                    controllerAs: '$ctrl',
                    focusOnOpen: false,
                    locals: {
                        grievanceId: null,
                        aCase: null
                    }
                })
                    .catch(function (error) { if (error)
                    throw error; })
                    .finally(function () { _this.refresh(); });
            };
            CreateGrievanceButtonComponent.$inject = ['$mdDialog', '$scope'];
            CreateGrievanceButtonComponent = __decorate([
                app.Component('app.grievance', 'amCreateGrievanceButton', {
                    template: "\n      <md-button ng-click=\"$ctrl.openGrievanceDialog()\" permission=\"['grievances.edit']\">\n        <md-tooltip>Add Grievance</md-tooltip>\n        <md-icon aria-hidden=\"true\">add</md-icon>\n      </md-button>",
                    bindings: {
                        refresh: '&'
                    }
                })
            ], CreateGrievanceButtonComponent);
            return CreateGrievanceButtonComponent;
        }());
    })(grievance = app.grievance || (app.grievance = {}));
})(app || (app = {}));
var app;
(function (app) {
    var grievance;
    (function (grievance) {
        var EditGrievanceButtonComponent = (function () {
            function EditGrievanceButtonComponent($mdDialog, $scope) {
                this.$mdDialog = $mdDialog;
                this.$scope = $scope;
                'ngInject';
                'ngInject';
            }
            EditGrievanceButtonComponent.prototype.openGrievanceDialog = function () {
                var _this = this;
                var parent = angular.element(document.body);
                return this.$mdDialog.show({
                    parent: parent,
                    fullscreen: true,
                    templateUrl: 'app/grievance/grievance-dialog/grievance-dialog.html',
                    controller: 'GrievanceDialogController',
                    controllerAs: '$ctrl',
                    focusOnOpen: false,
                    locals: {
                        grievanceId: this.grievanceId,
                        aCase: null
                    }
                })
                    .catch(function (error) { if (error)
                    throw error; })
                    .finally(function () { _this.refresh(); });
            };
            EditGrievanceButtonComponent.$inject = ['$mdDialog', '$scope'];
            EditGrievanceButtonComponent = __decorate([
                app.Component('app.grievance', 'amEditGrievanceButton', {
                    template: "\n      <md-button style=\"font-weight:normal;text-transform:none;\" ng-click=\"$ctrl.openGrievanceDialog()\" permission=\"['grievances.edit']\">\n        <md-icon aria-hidden=\"true\">edit</md-icon>\n        <span class=\"pl-lg\">Edit Grievance</span>\n      </md-button>",
                    bindings: {
                        grievanceId: '<',
                        refresh: '&'
                    }
                })
            ], EditGrievanceButtonComponent);
            return EditGrievanceButtonComponent;
        }());
    })(grievance = app.grievance || (app.grievance = {}));
})(app || (app = {}));
var app;
(function (app) {
    var grievance;
    (function (grievance) {
        var ViewGrievanceButtonComponent = (function () {
            function ViewGrievanceButtonComponent($mdDialog, $scope) {
                this.$mdDialog = $mdDialog;
                this.$scope = $scope;
                'ngInject';
                'ngInject';
            }
            ViewGrievanceButtonComponent.prototype.openGrievanceDialog = function () {
                var parent = angular.element(document.body);
                return this.$mdDialog.show({
                    parent: parent,
                    fullscreen: true,
                    templateUrl: 'app/grievance/grievance-dialog/grievance-view-dialog.html',
                    controller: 'GrievanceDialogController',
                    controllerAs: '$ctrl',
                    focusOnOpen: false,
                    locals: {
                        grievanceId: this.grievanceId,
                        aCase: null
                    }
                })
                    .catch(function (error) { if (error)
                    throw error; });
            };
            ViewGrievanceButtonComponent.$inject = ['$mdDialog', '$scope'];
            ViewGrievanceButtonComponent = __decorate([
                app.Component('app.grievance', 'amViewGrievanceButton', {
                    template: "\n      <md-button ng-click=\"$ctrl.openGrievanceDialog()\"  style=\"font-weight:normal;text-transform:none;\" permission=\"['grievances.view']\">\n         <md-icon aria-hidden=\"true\">feedback</md-icon>\n         <span class=\"pl-lg\">View Grievance</span>\n      </md-button>",
                    bindings: {
                        grievanceId: '<',
                    }
                })
            ], ViewGrievanceButtonComponent);
            return ViewGrievanceButtonComponent;
        }());
    })(grievance = app.grievance || (app.grievance = {}));
})(app || (app = {}));
var app;
(function (app) {
    var grievance;
    (function (grievance) {
        var GrievanceDialogController = (function () {
            function GrievanceDialogController($mdDialog, common, datacontext, grievanceId, aCase) {
                this.$mdDialog = $mdDialog;
                this.common = common;
                this.datacontext = datacontext;
                this.grievanceId = grievanceId;
                this.aCase = aCase;
                this.grievance = {};
                'ngInject';
                'ngInject';
            }
            GrievanceDialogController.prototype.$onInit = function () {
                var _this = this;
                this.maxDate = moment().toDate();
                if (this.aCase) {
                    this.selectedCase = this.aCase;
                    this.getGrievants();
                    this.getFundingPrograms();
                }
                if (this.grievanceId) {
                    this.getGrievance()
                        .then(function () {
                        if (_this.grievance) {
                            _this.selectedCase = {
                                id: _this.grievance.caseId,
                                caseType: _this.grievance.caseType,
                                involvementId: _this.grievance.involvementId,
                                caseIdNumber: _this.grievance.caseIdNumber
                            };
                            _this.setGrievant({
                                id: _this.grievance.personId,
                                fullName: _this.grievance.grievantFullName
                            });
                        }
                    });
                }
                else {
                    this.grievance.fileDate = moment().startOf('day').toDate();
                    this.grievance.status = 'Pending';
                }
            };
            GrievanceDialogController.prototype.getGrievance = function () {
                var _this = this;
                return this.datacontext.grievance
                    .getGrievance(this.grievanceId)
                    .then(function (result) { return _this.grievance = result; });
            };
            GrievanceDialogController.prototype.cancel = function () {
                this.$mdDialog.cancel();
            };
            GrievanceDialogController.prototype.getCases = function () {
                var _this = this;
                this.cases = [];
                if (!this.selectedGrievant) {
                    return;
                }
                this.datacontext.grievance
                    .getCases(this.selectedGrievant.id)
                    .then(function (results) { return _this.cases = results; });
            };
            GrievanceDialogController.prototype.onCaseChange = function () {
                if (this.selectedCase && this.selectedCase.id !== this.grievance.caseId) {
                    this.grievance.fundingProgramId = null;
                }
                this.getFundingPrograms();
            };
            GrievanceDialogController.prototype.getFundingPrograms = function () {
                var _this = this;
                this.fundingPrograms = [];
                if (!this.selectedCase) {
                    return;
                }
                this.datacontext.funding
                    .getFundingProgramsBySource(moment().toDate().toDateString(), this.selectedCase.caseType, null, this.selectedCase.involvementId)
                    .then(function (results) { return _this.fundingPrograms = results; });
            };
            GrievanceDialogController.prototype.grievantSearch = function (searchText) {
                var params = { q: searchText };
                return this.datacontext.persons
                    .getPersons(params)
                    .then(function (results) {
                    return results.list;
                });
            };
            GrievanceDialogController.prototype.onGrievantChange = function (grievant) {
                if (this.grievanceId && this.selectedGrievant && grievant && this.selectedGrievant.id === grievant.id) {
                    return;
                }
                this.selectedCase = null;
                this.grievance.fundingProgramId = null;
                this.setGrievant(grievant);
            };
            GrievanceDialogController.prototype.getGrievants = function () {
                var _this = this;
                this.grievants = [];
                this.datacontext.involvements
                    .getEntityListing(this.selectedCase.involvementId)
                    .then(function (results) {
                    results = results.filter(function (r) { return r.entityType === 'Person' && !r.isUser; });
                    _this.grievants = results.map(function (r) { return { id: r.entityId, fullName: r.fullName }; });
                });
            };
            GrievanceDialogController.prototype.setGrievant = function (grievant) {
                this.selectedGrievant = grievant;
                if (!this.aCase) {
                    this.getCases();
                    this.getFundingPrograms();
                }
            };
            GrievanceDialogController.prototype.submit = function () {
                var _this = this;
                this.isSubmitting = true;
                var data = __assign(__assign({}, this.grievance), { fileDate: this.grievance.fileDate.toDateString(), escalationDate: this.grievance.escalationDate ? this.grievance.escalationDate.toDateString() : null, replyDueDate: this.grievance.replyDueDate ? this.grievance.replyDueDate.toDateString() : null, actualReplyDate: this.grievance.actualReplyDate ? this.grievance.actualReplyDate.toDateString() : null, personId: this.selectedGrievant.id, involvementId: this.selectedCase.involvementId, caseId: this.selectedCase.id, caseType: this.selectedCase.caseType, status: this.selectedStatus });
                var promise = this.grievanceId
                    ? this.datacontext.grievance.updateGrievance(this.grievanceId, data)
                    : this.datacontext.grievance.createGrievance(data);
                promise
                    .then(function () {
                    _this.common.$mdToast.showSimple('Grievance ' + (_this.grievanceId ? 'updated' : 'created'));
                    _this.$mdDialog.hide();
                })
                    .finally(function () { return _this.isSubmitting = false; });
            };
            GrievanceDialogController.$inject = ['$mdDialog', 'common', 'datacontext', 'grievanceId', 'aCase'];
            GrievanceDialogController = __decorate([
                app.Controller('app.grievance', 'GrievanceDialogController')
            ], GrievanceDialogController);
            return GrievanceDialogController;
        }());
    })(grievance = app.grievance || (app.grievance = {}));
})(app || (app = {}));
var app;
(function (app) {
    var grievance;
    (function (grievance_2) {
        var GrievanceDialogController = (function () {
            function GrievanceDialogController($mdDialog, grievance) {
                this.$mdDialog = $mdDialog;
                this.grievance = grievance;
                'ngInject';
                'ngInject';
            }
            GrievanceDialogController.prototype.cancel = function () {
                this.$mdDialog.cancel();
            };
            GrievanceDialogController.$inject = ['$mdDialog', 'grievance'];
            GrievanceDialogController = __decorate([
                app.Controller('app.grievance', 'GrievanceViewDialogController')
            ], GrievanceDialogController);
            return GrievanceDialogController;
        }());
    })(grievance = app.grievance || (app.grievance = {}));
})(app || (app = {}));
var app;
(function (app) {
    var grievance;
    (function (grievance_3) {
        var GrievanceList = (function () {
            function GrievanceList($mdDialog) {
                this.$mdDialog = $mdDialog;
                'ngInject';
                'ngInject';
            }
            GrievanceList.prototype.viewGrievance = function (grievance) {
                return this.$mdDialog.show({
                    fullscreen: true,
                    templateUrl: 'app/grievance/grievance-dialog/grievance-view-dialog.html',
                    controller: 'GrievanceViewDialogController',
                    controllerAs: '$ctrl',
                    locals: { grievance: grievance }
                });
            };
            GrievanceList.$inject = ['$mdDialog'];
            GrievanceList = __decorate([
                app.Component('app.grievance', 'ceGrievanceList', {
                    templateUrl: 'app/grievance/grievance-listing/grievance-list.html',
                    bindings: {
                        grievances: '<',
                        onEdit: '&',
                        onDelete: '&',
                        refresh: '&',
                        paging: '<',
                        promise: '<'
                    }
                })
            ], GrievanceList);
            return GrievanceList;
        }());
    })(grievance = app.grievance || (app.grievance = {}));
})(app || (app = {}));
var app;
(function (app) {
    var grievance;
    (function (grievance_4) {
        var GrievanceListing = (function () {
            function GrievanceListing($mdDialog, common, datacontext) {
                var _this = this;
                this.$mdDialog = $mdDialog;
                this.common = common;
                this.datacontext = datacontext;
                this.showFilters = false;
                this.paging = {
                    page: 1,
                    pageSize: 100
                };
                this.isActive = true;
                this.getGrievances = function () {
                    if (!_this.dateFilterInitialized) {
                        return;
                    }
                    var params = __assign({ involvementId: _this.involvementId, personId: _this.personId, fromDate: _this.fromDate, toDate: _this.toDate, isActive: _this.isActive }, _this.paging);
                    _this.promise = _this.datacontext.grievance
                        .getGrievanceListing(params)
                        .then(function (result) {
                        _this.grievances = result;
                    });
                };
                'ngInject';
                'ngInject';
            }
            GrievanceListing.prototype.$onInit = function () {
                if (this.case) {
                    this.involvementId = this.case.involvementId;
                }
            };
            GrievanceListing.prototype.activeFilterChanged = function (isActive) {
                this.activeFilterInitialized = true;
                switch (isActive) {
                    case 0:
                        this.isActive = false;
                        break;
                    case 1:
                        this.isActive = true;
                        break;
                    case 2:
                        this.isActive = null;
                        break;
                    default:
                        this.isActive = true;
                        break;
                }
                this.getGrievances();
            };
            GrievanceListing.prototype.dateFilterChanged = function (dates) {
                this.dateFilterInitialized = true;
                this.fromDate = dates.fromDate;
                this.toDate = dates.toDate;
                this.getGrievances();
            };
            GrievanceListing.prototype.toggleFilters = function () {
                this.showFilters = !this.showFilters;
            };
            GrievanceListing.prototype.createGrievance = function (targetEvent) {
                this.openGrievanceDialog(targetEvent, null);
            };
            GrievanceListing.prototype.editGrievance = function (targetEvent, grievance) {
                this.openGrievanceDialog(targetEvent, grievance.id);
            };
            GrievanceListing.prototype.openGrievanceDialog = function (targetEvent, grievanceId) {
                var _this = this;
                var parent = angular.element(document.body);
                var aCase;
                if (this.case) {
                    aCase = {
                        id: this.case.id,
                        caseIdNumber: this.case.idNumber,
                        involvementId: this.involvementId,
                        caseType: this.caseType
                    };
                }
                return this.$mdDialog.show({
                    parent: parent,
                    targetEvent: targetEvent,
                    fullscreen: true,
                    templateUrl: 'app/grievance/grievance-dialog/grievance-dialog.html',
                    controller: 'GrievanceDialogController',
                    controllerAs: '$ctrl',
                    locals: {
                        grievanceId: grievanceId,
                        aCase: aCase
                    }
                }).then(function () {
                    _this.getGrievances();
                }).catch(function (error) {
                    if (error)
                        throw error;
                });
            };
            GrievanceListing.prototype.filtersInitialized = function () {
                return this.activeFilterInitialized &&
                    this.dateFilterInitialized;
            };
            GrievanceListing.prototype.deleteGrievance = function (grievance) {
                var _this = this;
                this.common.confirmation.show({
                    title: 'Delete Grievance',
                    message: 'Are you sure you want to delete this grievance?',
                    ok: 'Delete',
                    cancel: 'Keep'
                }).then(function () {
                    _this.datacontext.grievance
                        .deleteGrievance(grievance.id)
                        .then(function () {
                        _this.getGrievances();
                    });
                }).catch(angular.noop);
            };
            GrievanceListing.$inject = ['$mdDialog', 'common', 'datacontext'];
            GrievanceListing = __decorate([
                app.Component('app.grievance', 'ceGrievanceListing', {
                    templateUrl: 'app/grievance/grievance-listing/grievance-listing.html',
                    bindings: {
                        case: '<',
                        caseType: '@',
                        personId: '<'
                    }
                })
            ], GrievanceListing);
            return GrievanceListing;
        }());
    })(grievance = app.grievance || (app.grievance = {}));
})(app || (app = {}));
var app;
(function (app) {
    var identity;
    (function (identity) {
        var EditProfileInfoTab = (function () {
            function EditProfileInfoTab() {
            }
            EditProfileInfoTab = __decorate([
                app.Component('app.identity', 'ceEditProfileInfoTab', {
                    templateUrl: 'app/identity/edit-profile/edit-profile-info.html',
                    bindings: {
                        data: '<'
                    }
                })
            ], EditProfileInfoTab);
            return EditProfileInfoTab;
        }());
    })(identity = app.identity || (app.identity = {}));
})(app || (app = {}));
var app;
(function (app) {
    var identity;
    (function (identity) {
        var EditProfilePhotoActions = (function () {
            function EditProfilePhotoActions() {
            }
            EditProfilePhotoActions = __decorate([
                app.Component('app.identity', 'ceEditProfilePhotoActions', {
                    templateUrl: 'app/identity/edit-profile/edit-profile-photo-actions.html',
                    bindings: {
                        onToggleInputMethod: '&',
                        useUploadMethod: '='
                    }
                })
            ], EditProfilePhotoActions);
            return EditProfilePhotoActions;
        }());
    })(identity = app.identity || (app.identity = {}));
})(app || (app = {}));
var app;
(function (app) {
    var identity;
    (function (identity) {
        var EditProfilePhotoTab = (function () {
            function EditProfilePhotoTab(common, fileUploader, $scope) {
                var _this = this;
                this.common = common;
                this.fileUploader = fileUploader;
                this.$scope = $scope;
                this.isUploading = false;
                this.discard = function () {
                    delete _this.$scope['file'];
                    _this.onDiscardPhoto();
                };
                this.activate = function () {
                    _this.$scope.$watch('file', _this.uploadFile);
                };
                this.uploadFile = function (file) {
                    if (!file)
                        return;
                    _this.isUploading = true;
                    _this.fileUploader.upload({ file: file })
                        .success(function (result) { return _this.photoId = result.id; })
                        .error(function () { return _this.common.$mdToast.showSimple('Failed to upload file.'); })
                        .finally(function () { return _this.isUploading = false; });
                };
                'ngInject';
                'ngInject';
                this.activate();
            }
            EditProfilePhotoTab.$inject = ['common', 'fileUploader', '$scope'];
            EditProfilePhotoTab = __decorate([
                app.Component('app.identity', 'ceEditProfilePhotoTab', {
                    templateUrl: 'app/identity/edit-profile/edit-profile-photo.html',
                    bindings: {
                        photoId: '=',
                        onDiscardPhoto: '&',
                        useUploadMethod: '<'
                    }
                })
            ], EditProfilePhotoTab);
            return EditProfilePhotoTab;
        }());
    })(identity = app.identity || (app.identity = {}));
})(app || (app = {}));
var app;
(function (app) {
    var identity;
    (function (identity) {
        var EditUserProfileController = (function () {
            function EditUserProfileController($mdDialog, datacontext, common) {
                var _this = this;
                this.$mdDialog = $mdDialog;
                this.datacontext = datacontext;
                this.common = common;
                this.isSubmitting = false;
                this.photoButton = { icon: 'camera_alt', toolTip: 'Take Photo' };
                this.photoInputMethod = 'upload';
                this.selectedTab = 'info';
                this.useUploadMethod = true;
                this.activate = function () {
                    _this.getUser().then(function () {
                        var _a = _this.user, firstName = _a.firstName, lastName = _a.lastName;
                        _this.data = {
                            firstName: firstName,
                            lastName: lastName
                        };
                    });
                };
                this.getUser = function () {
                    return _this.datacontext.identity
                        .getCurrentUser()
                        .then(function (result) { return _this.user = result; });
                };
                'ngInject';
                'ngInject';
                this.activate();
            }
            EditUserProfileController.prototype.cancel = function () {
                this.discardPhoto();
                this.$mdDialog.cancel();
            };
            EditUserProfileController.prototype.discardPhoto = function () {
            };
            EditUserProfileController.prototype.submit = function () {
                var _this = this;
                this.isSubmitting = true;
                this.datacontext.identity
                    .updateCurrentUser(this.data)
                    .then(function (result) {
                    if (result)
                        _this.$mdDialog.hide(result);
                })
                    .finally(function () { return _this.isSubmitting = false; });
            };
            EditUserProfileController.prototype.tabSelected = function (tab) {
                this.selectedTab = tab;
            };
            EditUserProfileController.$inject = ['$mdDialog', 'datacontext', 'common'];
            EditUserProfileController = __decorate([
                app.Controller('app.identity', 'EditUserProfileController')
            ], EditUserProfileController);
            return EditUserProfileController;
        }());
    })(identity = app.identity || (app.identity = {}));
})(app || (app = {}));
var app;
(function (app) {
    var identity;
    (function (identity) {
        var SwitchTenantController = (function () {
            function SwitchTenantController($window, $location, $mdDialog, auth, datacontext, user, tenants) {
                var _this = this;
                this.$window = $window;
                this.$location = $location;
                this.$mdDialog = $mdDialog;
                this.auth = auth;
                this.datacontext = datacontext;
                this.user = user;
                this.tenants = tenants;
                this.isSubmitting = false;
                this.activate = function () {
                    _this.data = {
                        tenantId: _this.user.tenantId
                    };
                };
                'ngInject';
                'ngInject';
                this.activate();
            }
            SwitchTenantController.prototype.cancel = function () {
                this.$mdDialog.cancel();
            };
            SwitchTenantController.prototype.submit = function () {
                var _this = this;
                this.isSubmitting = true;
                this.datacontext.identity
                    .switchTenant(this.data)
                    .then(function (result) {
                    _this.$location.path('/#!/homepage');
                    _this.$window.location.reload();
                }).finally(function () { return _this.isSubmitting = false; });
            };
            SwitchTenantController.$inject = ['$window', '$location', '$mdDialog', 'auth', 'datacontext', 'user', 'tenants'];
            SwitchTenantController = __decorate([
                app.Controller('app.identity', 'SwitchTenantController')
            ], SwitchTenantController);
            return SwitchTenantController;
        }());
    })(identity = app.identity || (app.identity = {}));
})(app || (app = {}));
var app;
(function (app) {
    var identity;
    (function (identity) {
        var UserMenuComponent = (function () {
            function UserMenuComponent($mdDialog, auth, datacontext, configurationProvider) {
                var _this = this;
                this.$mdDialog = $mdDialog;
                this.auth = auth;
                this.datacontext = datacontext;
                this.configurationProvider = configurationProvider;
                this.activate = function () {
                    _this.getUser();
                    _this.getTenants();
                    _this.apiDocsUrl = _this.configurationProvider.config.apiRootUrl.replace('/v1/', '/') + 'swagger';
                    _this.wordAddinUrl = _this.configurationProvider.config.wordAddinUrl;
                    _this.supportUrl = _this.configurationProvider.config.supportUrl;
                };
                this.getUser = function () {
                    return _this.datacontext.identity
                        .getCurrentUser()
                        .then(function (result) { return _this.user = result; });
                };
                this.getTenants = function () {
                    _this.datacontext.identity
                        .getCurrentTenants()
                        .then(function (result) { return _this.tenants = result; });
                };
                'ngInject';
                'ngInject';
                this.activate();
            }
            UserMenuComponent.prototype.switchTenant = function (targetEvent) {
                var parent = angular.element(document.body);
                this.$mdDialog.show({
                    parent: parent,
                    targetEvent: targetEvent,
                    templateUrl: 'app/identity/switch-tenant/switch-tenant.html',
                    controller: 'SwitchTenantController',
                    controllerAs: '$ctrl',
                    focusOnOpen: true,
                    fullscreen: true,
                    locals: {
                        tenants: this.tenants,
                        user: this.user
                    }
                });
            };
            UserMenuComponent.prototype.editProfile = function (targetEvent) {
                var parent = angular.element(document.body);
                this.$mdDialog.show({
                    parent: parent,
                    targetEvent: targetEvent,
                    templateUrl: 'app/identity/edit-profile/edit-profile.html',
                    controller: 'EditUserProfileController',
                    controllerAs: '$ctrl',
                    focusOnOpen: true,
                    fullscreen: true
                }).then(this.getUser);
            };
            UserMenuComponent.prototype.signout = function () {
                this.auth.logout();
            };
            UserMenuComponent.$inject = ['$mdDialog', 'auth', 'datacontext', 'configurationProvider'];
            UserMenuComponent = __decorate([
                app.Component('app.identity', 'ceUserMenu', {
                    templateUrl: 'app/identity/user-menu/user-menu.html'
                })
            ], UserMenuComponent);
            return UserMenuComponent;
        }());
    })(identity = app.identity || (app.identity = {}));
})(app || (app = {}));
var app;
(function (app) {
    var informationAndReferrals;
    (function (informationAndReferrals) {
        var activities;
        (function (activities) {
            activities.bypassClosureReasons = function (datacontext) {
                'ngInject';
                var bypassReasonsPromise = datacontext.config.getConfigurationParameter('I&R.BypassClosureReasons', true);
                return bypassReasonsPromise.then(function (result) {
                    return result.toString() === 'true';
                });
            };
            activities.showCovidVaccine = function (datacontext) {
                'ngInject';
                var showCovidVaccinePromise = datacontext.config.getConfigurationParameter('Activity.CovidVaccine', false);
                return showCovidVaccinePromise.then(function (result) {
                    return result.toString() === 'true';
                });
            };
        })(activities = informationAndReferrals.activities || (informationAndReferrals.activities = {}));
    })(informationAndReferrals = app.informationAndReferrals || (app.informationAndReferrals = {}));
})(app || (app = {}));
var app;
(function (app) {
    var informationAndReferrals;
    (function (informationAndReferrals) {
        var activities;
        (function (activities) {
            var routes = [
                {
                    name: 'app.informationAndReferrals.activities',
                    url: '/activities',
                    template: '<ui-view flex layout="column"></ui-view>',
                    redirectTo: 'app.informationAndReferrals.activities.list'
                },
                {
                    name: 'app.informationAndReferrals.activities.list',
                    url: '',
                    template: "<ce-information-and-referral-activity-list\n                        case=\"$resolve.case\"\n                        show-Covid-Vaccine=\"$resolve.flag\"\n                        layout=\"column\" flex>\n                     </ce-information-and-referral-activity-list>",
                    data: {
                        title: 'I&R Activities',
                        permissions: ['access_activities']
                    },
                    resolve: { flag: activities.showCovidVaccine }
                },
                {
                    name: 'app.informationAndReferrals.activities.note',
                    url: '/note',
                    template: "<ce-information-and-referrals-activities-note layout=\"column\" flex\n                        involvement-id=\"$resolve.case.involvementId\"\n                        case-Id=\"$resolve.case.id\"\n                        activity-Type=\"InformationAndReferralNote\"\n                        name=\"Note\"\n                        activity=\"$resolve.activity\"\n                        case-open-date=\"$resolve.case.openDate\"\n                        return-sref=\"app.informationAndReferrals.activities.list\">\n                    </ce-information-and-referrals-activities-note>",
                    params: { activity: null },
                    resolve: { activity: ['$stateParams', function (p) { return p.activity; }] },
                    data: { title: 'Note' }
                },
                {
                    name: 'app.informationAndReferrals.activities.review',
                    url: '/review',
                    template: "<ce-activities-activity layout=\"column\" flex\n                       involvement-id=\"$resolve.case.involvementId\"\n                       source=\"I&R\"\n                       source-Id=\"$resolve.case.id\"\n                       activity-Type=\"InformationAndReferralReview\"\n                       name=\"Review\"\n                       return-sref=\"app.informationAndReferrals.activities.list\">\n                    </ce-activities-activity>",
                    data: { title: 'Review' }
                },
                {
                    name: 'app.informationAndReferrals.activities.conflictCheck',
                    url: '/conflictCheck',
                    template: "<ce-conflict-check layout=\"column\" flex\n                     involvement-id=\"$resolve.case.involvementId\"\n                     source=\"I&R\"\n                     source-Id=\"$resolve.case.id\"\n                     case-open-date=\"$resolve.case.openDate\">\n                    </ce-conflict-check>",
                    data: { title: 'Conflict Check' }
                },
                {
                    name: 'app.informationAndReferrals.activities.close',
                    url: '/close',
                    template: "<ce-information-and-referral-activities-close\n                     source=\"I&R\"\n                     bypass-Closure-Reasons=\"$resolve.bypass\"\n                     case=\"$resolve.case\" flex>\n                    </ce-information-and-referral-activities-close>",
                    data: { title: 'Close' },
                    resolve: { bypass: activities.bypassClosureReasons }
                },
                {
                    name: 'app.informationAndReferrals.activities.reopen',
                    url: '/reopen',
                    template: "<ce-information-and-referral-activities-reopen\n                     case=\"$resolve.case\" flex>\n                    </ce-information-and-referral-activities-reopen>",
                    data: { title: 'Reopen' }
                },
                {
                    name: 'app.informationAndReferrals.activities.createClientCase',
                    url: '/createclientcase',
                    template: "<ce-create-client-case layout=\"column\" flex\n                        involvement-id=\"$resolve.case.involvementId\"\n                        information-and-referral-id=\"$resolve.case.id\"\n                        case-open-date=\"$resolve.case.openDate\">\n                    </ce-create-client-case>",
                    data: {
                        title: 'Create Client Case',
                        permissions: ['client-cases.edit']
                    }
                },
                {
                    name: 'app.informationAndReferrals.activities.createSystemicCase',
                    url: '/createSystemicCase',
                    template: "<ce-create-systemic-case layout=\"column\" flex\n                        involvement-id=\"$resolve.case.involvementId\"\n                        information-and-referral-id=\"$resolve.case.id\"\n                        case-open-date=\"$resolve.case.openDate\">\n                    </ce-create-systemic-case>",
                    data: {
                        title: 'Create Systemic Case',
                        permissions: ['systemic-cases.edit']
                    }
                },
                {
                    name: 'app.informationAndReferrals.activities.covidVaccine',
                    url: '/covidVaccine',
                    template: "<am-information-and-referrals-covid-vaccine layout=\"column\" flex\n                        [involvement-id]=\"$resolve.case.involvementId\"\n                        [source-id]=\"$resolve.case.id\"\n                        [activity-id]=\"$resolve.activityId\">\n                    </am-information-and-referrals-covid-vaccine>",
                    params: { activityId: null },
                    resolve: { activityId: ['$stateParams', function (p) { return p.activityId; }] },
                    data: {
                        title: 'Covid Vaccine'
                    }
                },
                {
                    name: 'app.informationAndReferrals.activities.statusChange',
                    url: '/statusChange',
                    template: "<am-activities-status-change layout=\"column\" flex\n                        [involvement-id]=\"$resolve.case.involvementId\"\n                        [source-id]=\"$resolve.case.id\"\n                        source=\"informationandreferrals\"\n                        [activity-id]=\"$resolve.activityId\">\n                    </am-activities-status-change>",
                    params: { activityId: null },
                    resolve: { activityId: ['$stateParams', function (p) { return p.activityId; }] },
                    data: {
                        title: 'Status Change'
                    }
                }
            ];
            var runner = function (routingHelper) {
                'ngInject';
                routingHelper.configureRoutes(routes);
            };
            angular
                .module('app.informationAndReferrals.activities')
                .run(runner);
        })(activities = informationAndReferrals.activities || (informationAndReferrals.activities = {}));
    })(informationAndReferrals = app.informationAndReferrals || (app.informationAndReferrals = {}));
})(app || (app = {}));
var app;
(function (app) {
    var informationAndReferrals;
    (function (informationAndReferrals) {
        var CreateInformationAndReferral = (function () {
            function CreateInformationAndReferral(common, datacontext) {
                var _this = this;
                this.common = common;
                this.datacontext = datacontext;
                this.selectedRoles = [];
                this.isSubmitting = false;
                this.case = {};
                this.getRoles = function () {
                    var lookupTypeId = "involvement-".concat(_this.entityType, "-role");
                    return _this.datacontext.config.lookups.getLookupType(lookupTypeId).then(function (result) {
                        _this.roles = result.lookups;
                    });
                };
                'ngInject';
                'ngInject';
            }
            CreateInformationAndReferral.prototype.$onInit = function () {
                this.maxDate = moment().toDate();
                this.case.involvementId = this.involvementId;
                this.case.requestId = this.requestId;
                this.case.clientCaseId = this.clientCaseId;
                this.case.systemicCaseId = this.systemicCaseId;
                this.case.openDate = moment().toDate();
                if (this.entity) {
                    this.getRoles();
                }
            };
            CreateInformationAndReferral.prototype.transformChip = function (chip) {
                if (angular.isObject(chip)) {
                    return chip;
                }
                return { name: chip, type: 'new' };
            };
            CreateInformationAndReferral.prototype.querySearch = function (query) {
                var results = query ? _.filter(this.roles, function (r) {
                    return query === '*' || r.value.toLowerCase().indexOf(query) > -1;
                }) : [];
                return results;
            };
            CreateInformationAndReferral.prototype.submit = function () {
                var _this = this;
                this.isSubmitting = true;
                if (typeof this.case.openDate !== 'string') {
                    this.case.openDate = this.case.openDate.toDateString();
                }
                this.datacontext.informationAndReferrals
                    .createInformationAndReferral(this.case)
                    .then(function (id) {
                    if (id) {
                        if (_this.entity) {
                            var roles = _.map(_this.selectedRoles, 'value');
                            var data_2 = {
                                entityId: _this.entity.id,
                                involvementId: id,
                                roles: roles
                            };
                            _this.datacontext.involvements
                                .createEntity(data_2)
                                .then(function () {
                                _this.common.$mdToast.showSimple('I&R Created');
                                _this.common.$state.go('app.informationAndReferrals', { informationAndReferralId: id });
                            });
                        }
                        else {
                            _this.common.$mdToast.showSimple('I&R Created');
                            _this.common.$state.go('app.informationAndReferrals', { informationAndReferralId: id });
                        }
                    }
                })
                    .finally(function () { return _this.isSubmitting = false; });
            };
            CreateInformationAndReferral.$inject = ['common', 'datacontext'];
            CreateInformationAndReferral = __decorate([
                app.Component('app.informationAndReferrals', 'ceCreateInformationAndReferral', {
                    templateUrl: 'app/information-and-referrals/create-information-and-referral/create-information-and-referral.html',
                    bindings: {
                        requestId: '<',
                        clientCaseId: '<',
                        systemicCaseId: '<',
                        involvementId: '<',
                        entity: '<',
                        entityType: '@',
                        caseOpenDate: '<'
                    }
                })
            ], CreateInformationAndReferral);
            return CreateInformationAndReferral;
        }());
    })(informationAndReferrals = app.informationAndReferrals || (app.informationAndReferrals = {}));
})(app || (app = {}));
var app;
(function (app) {
    var informationAndReferrals;
    (function (informationAndReferrals) {
        var details;
        (function (details) {
            var InformationAndReferralDetails = (function () {
                function InformationAndReferralDetails($mdDialog, common, datacontext) {
                    this.$mdDialog = $mdDialog;
                    this.common = common;
                    this.datacontext = datacontext;
                    this.editCaseInformation = false;
                    this.editPWDInformation = false;
                    this.editCallerInformation = false;
                    'ngInject';
                    'ngInject';
                }
                InformationAndReferralDetails.prototype.$onInit = function () {
                    var _this = this;
                    var promise = null;
                    promise = this.getCase();
                    promise.then(function () {
                        _this.GetCaseInformation();
                        _this.GetPWDInformation();
                        _this.GetCallerInformation();
                    });
                };
                InformationAndReferralDetails.prototype.getCase = function () {
                    var _this = this;
                    return this.datacontext.informationAndReferrals.getInformationAndReferralBasicInfoView(this.case.id)
                        .then(function (iandr) { return _this.case = iandr; });
                };
                InformationAndReferralDetails.prototype.updateCaseInformation = function (result) {
                    this.caseInformation = result;
                    this.editCaseInformation = false;
                };
                InformationAndReferralDetails.prototype.updatePWDInformation = function (result) {
                    this.pwdInformation = result;
                    this.editPWDInformation = false;
                };
                InformationAndReferralDetails.prototype.updateCallerInformation = function (result) {
                    this.callerInformation = result;
                    this.editCallerInformation = false;
                };
                InformationAndReferralDetails.prototype.GetCaseInformation = function () {
                    var _this = this;
                    this.datacontext.informationAndReferrals.getInformationAndReferralInformation(this.case.id).then(function (result) { return _this.caseInformation = result; });
                };
                InformationAndReferralDetails.prototype.GetPWDInformation = function () {
                    var _this = this;
                    if (this.case.pwdPersonId) {
                        this.datacontext.persons.getDemographics(this.case.pwdPersonId).then(function (result) { return _this.pwdInformation = result; });
                    }
                };
                InformationAndReferralDetails.prototype.GetCallerInformation = function () {
                    var _this = this;
                    if (this.case.requesterPersonId && this.case.pwdPersonId !== this.case.requesterPersonId) {
                        this.datacontext.persons.getDemographics(this.case.requesterPersonId).then(function (result) { return _this.callerInformation = result; });
                    }
                };
                InformationAndReferralDetails.$inject = ['$mdDialog', 'common', 'datacontext'];
                InformationAndReferralDetails = __decorate([
                    app.Component('app.informationAndReferrals.details', 'ceInformationAndReferralDetails', {
                        templateUrl: 'app/information-and-referrals/details/details.html',
                        bindings: {
                            case: '<'
                        }
                    })
                ], InformationAndReferralDetails);
                return InformationAndReferralDetails;
            }());
        })(details = informationAndReferrals.details || (informationAndReferrals.details = {}));
    })(informationAndReferrals = app.informationAndReferrals || (app.informationAndReferrals = {}));
})(app || (app = {}));
var app;
(function (app) {
    var informationAndReferrals;
    (function (informationAndReferrals) {
        var entities;
        (function (entities) {
            var routes = [
                {
                    name: 'app.informationAndReferrals.entities',
                    url: '/entities',
                    template: '<ui-view flex layout="column"></ui-view>',
                    redirectTo: 'app.informationAndReferrals.entities.listing',
                    data: { title: 'Request Entities' }
                },
                {
                    name: 'app.informationAndReferrals.entities.listing',
                    url: '/listing',
                    template: '<ce-involvement-entity-listing involvement-id="$resolve.case.involvementId" layout="column" flex></ce-involvement-entity-listing>',
                    data: { title: 'Request Entities' }
                },
                {
                    name: 'app.informationAndReferrals.entities.add',
                    url: '/add',
                    template: '<ce-select-entity-type flex></ce-select-entity-type>',
                    redirectTo: 'app.informationAndReferrals.entities.add.type',
                    data: { title: 'Add' }
                },
                {
                    name: 'app.informationAndReferrals.entities.add.type',
                    url: '/types',
                    template: '<ce-entity-types flex></ce-entity-types>'
                },
                {
                    name: 'app.informationAndReferrals.entities.edit',
                    url: '/edit',
                    params: { entity: null },
                    resolve: { entity: ['$stateParams', function (p) { return p.entity; }] },
                    template: '<ce-edit-entity-roles involvement-id="$resolve.case.involvementId" entity="$resolve.entity" layout="column" flex></ce-edit-entity-roles>',
                    data: { title: 'Edit' }
                },
                {
                    name: 'app.informationAndReferrals.entities.add.person',
                    url: '/person',
                    template: '<ui-view layout flex></ui-view>',
                    redirectTo: 'app.informationAndReferrals.entities.add.person.search',
                },
                {
                    name: 'app.informationAndReferrals.entities.add.person.search',
                    url: '/search',
                    template: '<ce-person-search flex></ce-person-search>',
                    data: { title: 'Find Person' }
                },
                {
                    name: 'app.informationAndReferrals.entities.add.person.create',
                    url: '/create',
                    template: '<ce-create-person flex></ce-create-person>',
                    data: { title: 'Create Person' }
                },
                {
                    name: 'app.informationAndReferrals.entities.add.person.roles',
                    url: '/roles',
                    params: { entity: null },
                    resolve: { entity: ['$stateParams', function (p) { return p.entity; }] },
                    template: '<ce-entity-roles involvement-id="$resolve.case.involvementId" entity="$resolve.entity" layout="column" flex></ce-entity-roles>',
                    data: { title: 'Select Roles' }
                },
                {
                    name: 'app.informationAndReferrals.entities.add.agency',
                    url: '/agency',
                    template: '<ui-view layout flex></ui-view>',
                    redirectTo: 'app.informationAndReferrals.entities.add.agency.search',
                },
                {
                    name: 'app.informationAndReferrals.entities.add.agency.search',
                    url: '/search',
                    template: '<ce-agency-search flex></ce-agenyc-search>',
                    data: { title: 'Find Agency' }
                },
                {
                    name: 'app.informationAndReferrals.entities.add.agency.create',
                    url: '/create',
                    template: '<ce-create-agency flex></ce-create-agency>',
                    data: { title: 'Create Agency' }
                },
                {
                    name: 'app.informationAndReferrals.entities.add.agency.roles',
                    url: '/roles',
                    params: { entity: null },
                    resolve: { entity: ['$stateParams', function (p) { return p.entity; }] },
                    template: '<ce-entity-roles involvement-id="$resolve.case.involvementId" entity="$resolve.entity" layout="column" flex></ce-entity-roles>',
                    data: { title: 'Select Roles' }
                }
            ];
            var runner = function (routingHelper) {
                'ngInject';
                routingHelper.configureRoutes(routes);
            };
            angular
                .module('app.informationAndReferrals.entities')
                .run(runner);
        })(entities = informationAndReferrals.entities || (informationAndReferrals.entities = {}));
    })(informationAndReferrals = app.informationAndReferrals || (app.informationAndReferrals = {}));
})(app || (app = {}));
var app;
(function (app) {
    var informationAndReferrals;
    (function (informationAndReferrals) {
        var funding;
        (function (funding) {
            funding.bypassEligibilityResolver = function (datacontext, $stateParams, common) {
                'ngInject';
                var informationAndReferralId = $stateParams.informationAndReferralId;
                var iandrPromise = datacontext.informationAndReferrals.getInformationAndReferralBasicInfoView(informationAndReferralId);
                var forceBypassPromise = datacontext.config.getConfigurationParameter('I&R.ForceBypassEligibility', true);
                return common.$q.all([iandrPromise, forceBypassPromise])
                    .then(function (result) {
                    var iandr = result[0];
                    var forceBypass = result[1].toString() === 'true';
                    var noClient = !iandr.clientPersonId;
                    var noPWD = !iandr.pwdPersonId;
                    return forceBypass || (noClient && noPWD);
                });
            };
        })(funding = informationAndReferrals.funding || (informationAndReferrals.funding = {}));
    })(informationAndReferrals = app.informationAndReferrals || (app.informationAndReferrals = {}));
})(app || (app = {}));
var app;
(function (app) {
    var informationAndReferrals;
    (function (informationAndReferrals) {
        var funding;
        (function (funding) {
            var routes = [
                {
                    name: 'app.informationAndReferrals.funding',
                    url: '/funding',
                    template: '<ce-funding involvement-id="$resolve.case.involvementId" layout="column" flex></ce-funding>',
                    redirectTo: 'app.informationAndReferrals.funding.summary',
                    data: { title: 'I&R Funding Summary' }
                },
                {
                    name: 'app.informationAndReferrals.funding.summary',
                    url: '/summary',
                    template: '<ce-funding-summary involvement-id="$resolve.case.involvementId" layout="column" flex></ce-funding-summary>',
                    data: { title: 'I&R Funding' }
                },
                {
                    name: 'app.informationAndReferrals.funding.eligibility',
                    url: '/eligibility',
                    template: '<ui-view layout flex></ui-view>',
                    redirectTo: 'app.informationAndReferrals.funding.eligibility.list',
                    data: { title: 'I&R Funding' }
                },
                {
                    name: 'app.informationAndReferrals.funding.eligibility.list',
                    url: '/list',
                    template: "<am-funding-eligibility-view\n                    [involvement-id]=\"$resolve.case.involvementId\"\n                    source-type=\"informationAndReferrals\"\n                    layout=\"column\" flex></am-funding-eligibility-view>",
                    data: { title: 'I&R Funding' }
                },
                {
                    name: 'app.informationAndReferrals.funding.eligibility.create',
                    url: '/create',
                    template: '<ce-funding-eligibility-create involvement-id="$resolve.case.involvementId" source="I&R" bypass-eligibility-criteria="$resolve.bypass" layout="column" flex></ce-funding-eligibility-create>',
                    data: { title: 'Create Eligibility' },
                    resolve: { bypass: funding.bypassEligibilityResolver }
                },
                {
                    name: 'app.informationAndReferrals.funding.eligibility.cap',
                    url: '/cap',
                    params: { fundingProgramId: null },
                    resolve: { fundingProgramId: ['$stateParams', function (p) { return p.fundingProgramId; }] },
                    template: '<ce-cap-eligibility involvement-id="$resolve.case.involvementId" funding-program-id="$resolve.fundingProgramId" layout="column" flex></ce-cap-eligibility>',
                    data: { title: 'CAP Eligibility' }
                },
                {
                    name: 'app.informationAndReferrals.funding.eligibility.paat',
                    url: '/paat',
                    params: { fundingProgramId: null },
                    resolve: { fundingProgramId: ['$stateParams', function (p) { return p.fundingProgramId; }] },
                    template: '<ce-paat-eligibility involvement-id="$resolve.case.involvementId" funding-program-id="$resolve.fundingProgramId" layout="column" flex></ce-paat-eligibility>',
                    data: { title: 'PAAT Eligibility' }
                },
                {
                    name: 'app.informationAndReferrals.funding.eligibility.pabss',
                    url: '/pabss',
                    params: { fundingProgramId: null },
                    resolve: { fundingProgramId: ['$stateParams', function (p) { return p.fundingProgramId; }] },
                    template: '<ce-pabss-eligibility involvement-id="$resolve.case.involvementId" funding-program-id="$resolve.fundingProgramId" layout="column" flex></ce-pabss-eligibility>',
                    data: { title: 'PABSS Eligibility' }
                },
                {
                    name: 'app.informationAndReferrals.funding.eligibility.padd',
                    url: '/padd',
                    params: { fundingProgramId: null },
                    resolve: { fundingProgramId: ['$stateParams', function (p) { return p.fundingProgramId; }] },
                    template: '<ce-padd-eligibility involvement-id="$resolve.case.involvementId" funding-program-id="$resolve.fundingProgramId" layout="column" flex></ce-padd-eligibility>',
                    data: { title: 'PADD Eligibility' }
                },
                {
                    name: 'app.informationAndReferrals.funding.eligibility.paimi',
                    url: '/paimi',
                    params: { fundingProgramId: null },
                    resolve: { fundingProgramId: ['$stateParams', function (p) { return p.fundingProgramId; }] },
                    template: '<ce-paimi-eligibility involvement-id="$resolve.case.involvementId" funding-program-id="$resolve.fundingProgramId" layout="column" flex></ce-paimi-eligibility>',
                    data: { title: 'PAIMI Eligibility' }
                },
                {
                    name: 'app.informationAndReferrals.funding.eligibility.pair',
                    url: '/pair',
                    params: { fundingProgramId: null },
                    resolve: { fundingProgramId: ['$stateParams', function (p) { return p.fundingProgramId; }] },
                    template: '<ce-pair-eligibility involvement-id="$resolve.case.involvementId" funding-program-id="$resolve.fundingProgramId" layout="column" flex></ce-pair-eligibility>',
                    data: { title: 'PAIR Eligibility' }
                },
                {
                    name: 'app.informationAndReferrals.funding.eligibility.patbi',
                    url: '/patbi',
                    params: { fundingProgramId: null },
                    resolve: { fundingProgramId: ['$stateParams', function (p) { return p.fundingProgramId; }] },
                    template: '<ce-patbi-eligibility involvement-id="$resolve.case.involvementId" funding-program-id="$resolve.fundingProgramId" layout="column" flex></ce-patbi-eligibility>',
                    data: { title: 'PATBI Eligibility' }
                },
                {
                    name: 'app.informationAndReferrals.funding.enrollment',
                    url: '/primary',
                    template: '<ui-view layout flex></ui-view>',
                    redirectTo: 'app.informationAndReferrals.funding.enrollment.list'
                },
                {
                    name: 'app.informationAndReferrals.funding.enrollment.list',
                    url: '/list',
                    template: '<ce-primary-funding-source-listing involvement-id="$resolve.case.involvementId" layout="column" source="I&R" flex></ce-primary-funding-source-listing>',
                    data: { title: 'Primary List' }
                },
                {
                    name: 'app.informationAndReferrals.funding.enrollment.primary',
                    url: '/primary',
                    template: '<ce-create-primary-funding involvement-id="$resolve.case.involvementId" layout="column" flex></ce-create-primary-funding>',
                    data: { title: 'Primary Funding' }
                },
                {
                    name: 'app.informationAndReferrals.funding.enrollment.updatePrimary',
                    url: '/updateprimary',
                    params: { enrollment: null },
                    resolve: { enrollment: ['$stateParams', function (p) { return p.enrollment; }] },
                    template: '<ce-edit-primary-funding enrollment="$resolve.enrollment" layout="column" flex></ce-edit-primary-funding>',
                    data: { title: 'Update Funding' }
                },
                {
                    name: 'app.informationAndReferrals.funding.enrollment.pavaOutcome',
                    url: '/pava-i-and-r-outcome/{enrollmentId}',
                    template: '<ce-pava-i-and-r-outcome enrollment-id="$resolve.enrollmentId" layout="column" flex></ce-pava-i-and-r-outcome>',
                    params: { enrollmentId: null },
                    resolve: { enrollmentId: ['$stateParams', function (p) { return p.enrollmentId; }] },
                    data: { title: 'PAVA Outcome' }
                },
                {
                    name: 'app.informationAndReferrals.funding.enrollment.paddOutcome',
                    url: '/padd-i-and-r-outcome/{enrollmentId}',
                    template: '<ce-padd-i-and-r-outcome enrollment-id="$resolve.enrollmentId" layout="column" flex></ce-padd-i-and-r-outcome>',
                    params: { enrollmentId: null },
                    resolve: { enrollmentId: ['$stateParams', function (p) { return p.enrollmentId; }] },
                    data: { title: 'PADD Outcome' }
                },
                {
                    name: 'app.informationAndReferrals.funding.enrollment.capOutcome',
                    url: '/cap-i-and-r-outcome/{enrollmentId}',
                    template: '<ce-cap-i-and-r-outcome enrollment-id="$resolve.enrollmentId" layout="column" flex></ce-cap-i-and-r-outcome>',
                    params: { enrollmentId: null },
                    resolve: { enrollmentId: ['$stateParams', function (p) { return p.enrollmentId; }] },
                    data: { title: 'CAP Outcome' }
                },
                {
                    name: 'app.informationAndReferrals.funding.enrollment.paatOutcome',
                    url: '/paat-i-and-r-outcome/{enrollmentId}',
                    template: '<ce-paat-i-and-r-outcome enrollment-id="$resolve.enrollmentId" layout="column" flex></ce-paat-i-and-r-outcome>',
                    params: { enrollmentId: null },
                    resolve: { enrollmentId: ['$stateParams', function (p) { return p.enrollmentId; }] },
                    data: { title: 'PAAT Outcome' }
                },
                {
                    name: 'app.informationAndReferrals.funding.enrollment.patbiOutcome',
                    url: '/patbi-i-and-r-outcome/{enrollmentId}',
                    template: '<ce-patbi-i-and-r-outcome enrollment-id="$resolve.enrollmentId" layout="column" flex></ce-patbi-i-and-r-outcome>',
                    params: { enrollmentId: null },
                    resolve: { enrollmentId: ['$stateParams', function (p) { return p.enrollmentId; }] },
                    data: { title: 'PATBI Outcome' }
                },
                {
                    name: 'app.informationAndReferrals.funding.secondary',
                    url: '/secondary',
                    template: '<ui-view flex></ui-view>',
                    redirectTo: 'app.informationAndReferrals.funding.secondary.view',
                    data: { title: 'Secondary Funding' }
                },
                {
                    name: 'app.informationAndReferrals.funding.secondary.edit',
                    url: '/edit',
                    params: { fundingProgramId: null },
                    resolve: { fundingProgramId: ['$stateParams', function (p) { return p.fundingProgramId; }] },
                    template: '<ce-edit-secondary-funding involvement-id="$resolve.case.involvementId" funding-program-id="$resolve.fundingProgramId" layout="column" flex></ce-edit-secondary-funding>',
                    data: { title: 'Secondary Funding' }
                },
                {
                    name: 'app.informationAndReferrals.funding.secondary.view',
                    url: '/views',
                    template: '<ce-secondary-funding-view involvement-id="$resolve.case.involvementId" layout="column" flex></ce-secondary-funding-view>',
                    data: { title: 'Secondary Funding' }
                }
            ];
            var runner = function (routingHelper) {
                'ngInject';
                routingHelper.configureRoutes(routes);
            };
            angular
                .module('app.informationAndReferrals.funding')
                .run(runner);
        })(funding = informationAndReferrals.funding || (informationAndReferrals.funding = {}));
    })(informationAndReferrals = app.informationAndReferrals || (app.informationAndReferrals = {}));
})(app || (app = {}));
var app;
(function (app) {
    var informationAndReferrals;
    (function (informationAndReferrals) {
        var InformationAndReferralView = (function () {
            function InformationAndReferralView($mdDialog, common, confirmation, timerService, datacontext) {
                var _this = this;
                this.$mdDialog = $mdDialog;
                this.common = common;
                this.confirmation = confirmation;
                this.timerService = timerService;
                this.datacontext = datacontext;
                this.isLoading = false;
                this.refresh = function () {
                    _this.datacontext.informationAndReferrals.getInformationAndReferralBasicInfoView(_this.case.id).then(function (clientCase) { return _this.case = clientCase; });
                };
                'ngInject';
                'ngInject';
                this.$state = common.$state;
            }
            InformationAndReferralView.prototype.$onInit = function () {
                this.unsubscribe = this.common.$rootScope.$on('refreshBasicInfo', this.refresh);
            };
            InformationAndReferralView.prototype.$onDestroy = function () {
                this.unsubscribe();
            };
            InformationAndReferralView.prototype.showDeleteDialog = function (id) {
                var _this = this;
                this.confirmation.show({
                    message: 'Are you sure you want to delete this I&R? This will delete all data attached to this I&R.',
                    ok: 'DELETE',
                    cancel: 'KEEP'
                }).then(function () { _this.deleteIandR(id); });
            };
            InformationAndReferralView.prototype.deleteIandR = function (id) {
                var _this = this;
                this.datacontext.informationAndReferrals.deleteInformationAndReferral(id).then(function (result) {
                    if (result) {
                        _this.timerService.killTimer();
                        _this.common.$state.go('app.iAndRList');
                    }
                });
            };
            InformationAndReferralView.$inject = ['$mdDialog', 'common', 'confirmation', 'timerService', 'datacontext'];
            InformationAndReferralView = __decorate([
                app.Component('app.informationAndReferrals', 'ceInformationAndReferralView', {
                    templateUrl: 'app/information-and-referrals/information-and-referral/information-and-referral-view.html',
                    bindings: {
                        case: '<'
                    }
                })
            ], InformationAndReferralView);
            return InformationAndReferralView;
        }());
    })(informationAndReferrals = app.informationAndReferrals || (app.informationAndReferrals = {}));
})(app || (app = {}));
var app;
(function (app) {
    var informationAndReferrals;
    (function (informationAndReferrals) {
        var overview;
        (function (overview) {
            var InformationAndReferralOverview = (function () {
                function InformationAndReferralOverview($mdDialog, common, datacontext) {
                    this.$mdDialog = $mdDialog;
                    this.common = common;
                    this.datacontext = datacontext;
                    'ngInject';
                    'ngInject';
                }
                InformationAndReferralOverview.prototype.$onInit = function () {
                };
                InformationAndReferralOverview.$inject = ['$mdDialog', 'common', 'datacontext'];
                InformationAndReferralOverview = __decorate([
                    app.Component('app.informationAndReferrals.overview', 'ceInformationAndReferralOverview', {
                        templateUrl: 'app/information-and-referrals/overview/overview.html',
                        bindings: {
                            case: '<'
                        }
                    })
                ], InformationAndReferralOverview);
                return InformationAndReferralOverview;
            }());
        })(overview = informationAndReferrals.overview || (informationAndReferrals.overview = {}));
    })(informationAndReferrals = app.informationAndReferrals || (app.informationAndReferrals = {}));
})(app || (app = {}));
var app;
(function (app) {
    var informationAndReferrals;
    (function (informationAndReferrals) {
        var overview;
        (function (overview) {
            var InformationAndReferralSummary = (function () {
                function InformationAndReferralSummary($mdDialog, common, datacontext) {
                    this.$mdDialog = $mdDialog;
                    this.common = common;
                    this.datacontext = datacontext;
                    'ngInject';
                    'ngInject';
                }
                InformationAndReferralSummary.prototype.$onInit = function () {
                    var _this = this;
                    this.datacontext.informationAndReferrals.getInformationAndReferralSummaryView(this.case.id)
                        .then(function (result) {
                        var data = result;
                        _this.caseSummary = data;
                    });
                };
                InformationAndReferralSummary.prototype.init = function () {
                };
                InformationAndReferralSummary.$inject = ['$mdDialog', 'common', 'datacontext'];
                InformationAndReferralSummary = __decorate([
                    app.Component('app.informationAndReferrals.overview', 'ceInformationAndReferralSummary', {
                        templateUrl: 'app/information-and-referrals/summary/summary.html',
                        bindings: {
                            case: '<'
                        }
                    })
                ], InformationAndReferralSummary);
                return InformationAndReferralSummary;
            }());
        })(overview = informationAndReferrals.overview || (informationAndReferrals.overview = {}));
    })(informationAndReferrals = app.informationAndReferrals || (app.informationAndReferrals = {}));
})(app || (app = {}));
var app;
(function (app) {
    var informationAndReferrals;
    (function (informationAndReferrals) {
        var overview;
        (function (overview) {
            var InformationAndReferralToDo = (function () {
                function InformationAndReferralToDo($mdDialog, common, datacontext) {
                    this.$mdDialog = $mdDialog;
                    this.common = common;
                    this.datacontext = datacontext;
                    'ngInject';
                    'ngInject';
                }
                InformationAndReferralToDo.prototype.$onInit = function () {
                    var _this = this;
                    this.datacontext.informationAndReferrals.getInformationAndReferralToDo(this.case.id)
                        .then(function (items) {
                        _this.items = items;
                    });
                };
                InformationAndReferralToDo.$inject = ['$mdDialog', 'common', 'datacontext'];
                InformationAndReferralToDo = __decorate([
                    app.Component('app.informationAndReferrals.overview', 'ceInformationAndReferralToDo', {
                        templateUrl: 'app/client-cases/to-do-list/to-do-list.html',
                        bindings: {
                            case: '<'
                        }
                    })
                ], InformationAndReferralToDo);
                return InformationAndReferralToDo;
            }());
        })(overview = informationAndReferrals.overview || (informationAndReferrals.overview = {}));
    })(informationAndReferrals = app.informationAndReferrals || (app.informationAndReferrals = {}));
})(app || (app = {}));
var app;
(function (app) {
    var involvements;
    (function (involvements) {
        var InvolvementCalendarStateService = (function () {
            function InvolvementCalendarStateService($localStorage) {
                var _this = this;
                this.$localStorage = $localStorage;
                this.state = {};
                this.loadState = function () {
                    if (_this.$localStorage['involvementCalendarState']) {
                        angular.extend(_this.state, _this.$localStorage['involvementCalendarState']);
                    }
                    else {
                        _this.$localStorage['involvementCalendarState'] = _this.state;
                    }
                    if (_this.state.dateRange) {
                        _this.state.dateRange = moment.range(_this.state.dateRange.start, _this.state.dateRange.end);
                    }
                };
                'ngInject';
                'ngInject';
                if ($localStorage.calendarState) {
                    delete $localStorage.calendarState.dateRange;
                    delete $localStorage.calendarState.viewType;
                    delete $localStorage.calendarState.viewDate;
                }
                this.loadState();
            }
            InvolvementCalendarStateService.prototype.save = function () {
                this.$localStorage['involvementCalendarState'] = this.state;
            };
            InvolvementCalendarStateService.prototype.update = function (state) {
                angular.extend(this.state, state);
                angular.extend(this.$localStorage['involvementCalendarState'], state);
            };
            return InvolvementCalendarStateService;
        }());
        angular
            .module('app.involvements')
            .service('involvementCalendarState', InvolvementCalendarStateService);
    })(involvements = app.involvements || (app.involvements = {}));
})(app || (app = {}));
var app;
(function (app) {
    var involvements;
    (function (involvements) {
        var InvolvementCalendar = (function () {
            function InvolvementCalendar($scope, $mdPanel, $mdSidenav, common, involvementCalendarData, involvementCalendarState, $mdDialog) {
                var _this = this;
                this.$scope = $scope;
                this.$mdPanel = $mdPanel;
                this.$mdSidenav = $mdSidenav;
                this.common = common;
                this.involvementCalendarData = involvementCalendarData;
                this.involvementCalendarState = involvementCalendarState;
                this.$mdDialog = $mdDialog;
                this.state = this.involvementCalendarState.state;
                this.calendarOptions = {
                    aspectRatio: 0,
                    scrollTime: '06:00',
                    editable: false,
                    eventLimit: true,
                    header: false,
                    selectable: false,
                    selectHelper: false,
                    dayClick: function (targetDate, targetEvent, calendarView) {
                        _this.createEvent(targetDate);
                    },
                    eventClick: function (event) {
                        _this.eventClick(event);
                    },
                    views: {
                        highPriority: {
                            type: 'list',
                            duration: { months: 2 },
                            noEventsMessage: 'No High Priority events to display'
                        }
                    },
                    eventRender: function (event, element) { element.attr('aria-label', event.startTime.toLocaleDateString() + ' ' + element[0].innerText); }
                };
                this.refresh = function () {
                    _this.involvementCalendarData.refresh(_this.involvementId, _this.source, _this.sourceId);
                };
                this.onViewRender = function (view) {
                    _this.$calendar = view.calendar;
                    _this.title = view.title;
                    var reload = true;
                    if (_this.state.dateRange) {
                        reload = !(_this.state.dateRange.contains(view.start) && _this.state.dateRange.contains(view.end));
                    }
                    var viewDate = view.start.clone();
                    if (view.type === 'month' && view.start.date() > 1) {
                        viewDate = viewDate.startOf('month').add(1, 'month');
                    }
                    _this.involvementCalendarState.update({
                        viewType: view.type,
                        viewDate: viewDate
                    });
                    var start = view.start.local();
                    var end = view.end.local();
                    _this.visibleRange = moment.range(start, end);
                    _this.selectedRange = moment.range(start, start);
                    if (reload) {
                        _this.state.dateRange = _this.visibleRange;
                        _this.common.$timeout(_this.refresh);
                    }
                };
                this.loadState = function () {
                    _this.calendarOptions.defaultView = _this.state.viewType || 'month';
                    _this.calendarOptions.defaultDate = _this.state.viewDate;
                };
                this.setContentHeight = function () {
                    _this.$calendar.option('contentHeight', angular.element('#calendar').height());
                };
                'ngInject';
                'ngInject';
            }
            InvolvementCalendar.prototype.createEvent = function (targetDate) {
                this.common.$state.go('^.eventSchedule', {
                    targetDate: targetDate,
                    involvementId: this.involvementId,
                    source: this.source,
                    sourceId: this.sourceId
                });
            };
            InvolvementCalendar.prototype.eventClick = function (event) {
                var _this = this;
                var parent = angular.element(document.body);
                return this.$mdDialog.show({
                    parent: parent,
                    fullscreen: false,
                    templateUrl: 'app/calendars/events/summary-dialog.html',
                    controller: 'EventSummaryDialogController',
                    controllerAs: '$ctrl',
                    locals: {
                        event: event,
                        eventScheduleId: event.eventScheduleId
                    }
                })
                    .then(function (edit) {
                    if (edit === 'eventSchedule') {
                        _this.common.$state.go('^.eventSchedule', { eventScheduleId: event.eventScheduleId });
                    }
                    else {
                        _this.common.$state.go('^.event', { event: event });
                    }
                })
                    .catch(function (error) { if (error)
                    throw error; });
            };
            InvolvementCalendar.prototype.today = function () {
                this.$calendar.today();
            };
            InvolvementCalendar.prototype.next = function () {
                this.$calendar.next();
            };
            InvolvementCalendar.prototype.prev = function () {
                this.$calendar.prev();
            };
            InvolvementCalendar.prototype.changeView = function (view) {
                this.$calendar.changeView(view);
            };
            InvolvementCalendar.prototype.showFilters = function () {
                this.$mdSidenav('calendarFilters').toggle();
            };
            InvolvementCalendar.prototype.$onInit = function () {
                this.eventSources = this.involvementCalendarData.eventSources;
                this.calendarOptions.viewRender = this.onViewRender;
                this.loadState();
                var cancelToken = this.$scope.$on(app.calendars.EVENT_ADDED, this.refresh);
                this.$scope.$on('$destroy', cancelToken);
                this.common.$timeout(this.setContentHeight, 100);
                angular.element(this.common.$window).bind('resize', this.setContentHeight);
            };
            InvolvementCalendar.$inject = ['$scope', '$mdPanel', '$mdSidenav', 'common', 'involvementCalendarData', 'involvementCalendarState', '$mdDialog'];
            InvolvementCalendar = __decorate([
                app.Component('app.involvements', 'ceInvolvementsCalendar', {
                    templateUrl: 'app/involvements/calendar/calendar.html',
                    bindings: {
                        involvementId: '<',
                        source: '@',
                        sourceId: '<'
                    }
                })
            ], InvolvementCalendar);
            return InvolvementCalendar;
        }());
    })(involvements = app.involvements || (app.involvements = {}));
})(app || (app = {}));
var app;
(function (app) {
    var involvements;
    (function (involvements) {
        var InvolvementCalendarDataService = (function () {
            function InvolvementCalendarDataService(common, datacontext, involvementCalendarState) {
                var _this = this;
                this.common = common;
                this.datacontext = datacontext;
                this.involvementCalendarState = involvementCalendarState;
                this.state = this.involvementCalendarState.state;
                this.refresh = function (involvementId, source, sourceId) {
                    var params = {
                        involvementId: involvementId,
                        source: source,
                        sourceId: sourceId,
                        from: _this.state.dateRange.start.toDate(),
                        to: _this.state.dateRange.end.toDate(),
                        highPriorityOnly: _this.state.viewType === 'highPriority'
                    };
                    _this.eventSources.splice(0);
                    _this.getEvents(params);
                };
                this.activate = function () {
                    return _this.getCalendars();
                };
                this.getCalendars = function () {
                    return _this.datacontext.calendars.getCalendars().then(function (result) {
                        _this.involvementCalendarState.update({ calendars: result });
                    }).finally(function () {
                        var calendarIds = [];
                        angular.forEach(_this.state.calendars, function (c) { return calendarIds.push(c.id); });
                        _this.involvementCalendarState.update({ calendarIds: calendarIds });
                    });
                };
                this.getEvents = function (params) {
                    _this.datacontext.involvements
                        .getEvents(params)
                        .then(function (result) {
                        if (result) {
                            var results = _(result);
                            var events = results.map(function (e) { return _this.mapEvent(e); }).value();
                            _this.eventSources.push({ events: events });
                        }
                    });
                };
                this.mapEvent = function (e) {
                    var start = moment(e.rescheduledStartTime || e.startTime);
                    var end;
                    if (e.allDay) {
                        start = moment(e.eventDate).startOf('day');
                        end = moment(e.eventDate).endOf('day');
                    }
                    else {
                        end = moment(e.rescheduledEndTime || e.endTime);
                    }
                    var calendar = _.find(_this.state.calendars, function (c) { return c.id === e.calendar; });
                    var color;
                    var isPast = end.diff(moment()) < 0;
                    if (isPast) {
                        color = calendar.completedColor;
                    }
                    else {
                        color = calendar.color;
                    }
                    return __assign(__assign({}, e), { title: "".concat(e.title), color: color, start: start, end: end });
                };
                'ngInject';
                'ngInject';
                this.eventSources = [];
                this.activate();
            }
            return InvolvementCalendarDataService;
        }());
        angular
            .module('app.involvements')
            .service('involvementCalendarData', InvolvementCalendarDataService);
    })(involvements = app.involvements || (app.involvements = {}));
})(app || (app = {}));
var app;
(function (app) {
    var involvements;
    (function (involvements) {
        var CaseList = (function () {
            function CaseList() {
            }
            CaseList = __decorate([
                app.Component('app.involvements', 'ceCaseList', {
                    templateUrl: 'app/involvements/case-listing/case-list.html',
                    bindings: {
                        cases: '<',
                        totalCases: '<'
                    }
                })
            ], CaseList);
            return CaseList;
        }());
    })(involvements = app.involvements || (app.involvements = {}));
})(app || (app = {}));
var app;
(function (app) {
    var involvements;
    (function (involvements) {
        var CaseListing = (function () {
            function CaseListing($mdDialog, common, datacontext) {
                var _this = this;
                this.$mdDialog = $mdDialog;
                this.common = common;
                this.datacontext = datacontext;
                this.$onInit = function () {
                    _this.getCases();
                };
                this.getCases = function () {
                    return _this.datacontext.involvements
                        .getCaseListing(_this.involvementId)
                        .then(function (result) {
                        var cases = result;
                        _this.cases = cases;
                    });
                };
                'ngInject';
                'ngInject';
            }
            CaseListing.$inject = ['$mdDialog', 'common', 'datacontext'];
            CaseListing = __decorate([
                app.Component('app.involvements', 'ceInvolvementCaseListing', {
                    templateUrl: 'app/involvements/case-listing/case-listing.html',
                    bindings: {
                        involvementId: '<'
                    }
                })
            ], CaseListing);
            return CaseListing;
        }());
    })(involvements = app.involvements || (app.involvements = {}));
})(app || (app = {}));
var app;
(function (app) {
    var involvements;
    (function (involvements) {
        var ConflictCheck = (function () {
            function ConflictCheck(common, datacontext) {
                this.common = common;
                this.datacontext = datacontext;
                this.entities = [];
                this.activity = {};
                'ngInject';
                'ngInject';
            }
            ConflictCheck.prototype.$onInit = function () {
                this.activity.source = this.source;
                this.activity.sourceId = this.sourceId;
                this.activity.involvementId = this.involvementId;
                this.activity.activityDate = moment().toDate();
                this.maxDate = this.activity.activityDate;
                this.getEntities();
            };
            ConflictCheck.prototype.getEntities = function () {
                var _this = this;
                this.datacontext.involvements.getEntitiesBasic(this.involvementId)
                    .then(function (entities) {
                    _this.entities = entities;
                });
            };
            ConflictCheck.prototype.submit = function () {
                var _this = this;
                this.isSubmitting = true;
                if (typeof this.activity.activityDate !== 'string') {
                    this.activity.activityDate = this.activity.activityDate.toDateString();
                }
                this.datacontext.involvements
                    .conflictCheck(this.activity)
                    .then(function (request) {
                    if (request) {
                        _this.common.$mdToast.showSimple('Conflict Check Created');
                        _this.common.$state.go('^.list', { sourceId: _this.sourceId });
                    }
                })
                    .finally(function () { return _this.isSubmitting = false; });
            };
            ConflictCheck.$inject = ['common', 'datacontext'];
            ConflictCheck = __decorate([
                app.Component('app.involvements', 'ceConflictCheck', {
                    templateUrl: 'app/involvements/conflict-check/conflict-check.html',
                    bindings: {
                        source: '@',
                        sourceId: '<',
                        involvementId: '<',
                        returnSref: '<',
                        caseOpenDate: '<'
                    }
                })
            ], ConflictCheck);
            return ConflictCheck;
        }());
    })(involvements = app.involvements || (app.involvements = {}));
})(app || (app = {}));
var app;
(function (app) {
    var involvements;
    (function (involvements) {
        var PartySummary = (function () {
            function PartySummary($mdDialog, common, datacontext) {
                this.$mdDialog = $mdDialog;
                this.common = common;
                this.datacontext = datacontext;
                'ngInject';
                'ngInject';
            }
            PartySummary.prototype.getParties = function () {
                var _this = this;
                this.datacontext.involvements.getPartySummary(this.involvementId)
                    .then(function (parties) {
                    _this.parties = parties;
                    _this.sortParties();
                }).then(function () { return angular.element('#partysummarycard').attr('aria-hidden', 'false'); });
            };
            PartySummary.prototype.$onInit = function () {
                this.getParties();
            };
            PartySummary.prototype.sortParties = function () {
                var sortedParties = [];
                sortedParties = _.map(this.parties, function (p) {
                    var s = p;
                    if (_.includes(s.roles, 'PWD')) {
                        s.sequence = 0;
                        return s;
                    }
                    if (_.includes(s.roles, 'Client')) {
                        s.sequence = 1;
                        return s;
                    }
                    if (_.includes(s.roles, 'Caller')) {
                        s.sequence = 2;
                        return s;
                    }
                    if (_.includes(s.roles, 'Adverse Party')) {
                        s.sequence = 3;
                        return s;
                    }
                });
                this.parties = _.sortBy(sortedParties, ['sequence', 'sortName']);
            };
            PartySummary.$inject = ['$mdDialog', 'common', 'datacontext'];
            PartySummary = __decorate([
                app.Component('app.involvements', 'cePartySummary', {
                    templateUrl: 'app/involvements/parties/party-summary.html',
                    bindings: {
                        involvementId: '<'
                    }
                })
            ], PartySummary);
            return PartySummary;
        }());
    })(involvements = app.involvements || (app.involvements = {}));
})(app || (app = {}));
var app;
(function (app) {
    var layout;
    (function (layout) {
        var TopBarComponent = (function () {
            function TopBarComponent($rootScope) {
                var _this = this;
                this.$rootScope = $rootScope;
                'ngInject';
                'ngInject';
                this.$rootScope.$watch(function () { return _this.$rootScope.isWorking; }, function (value) { return _this.isWorking = value; });
            }
            TopBarComponent.$inject = ['$rootScope'];
            TopBarComponent = __decorate([
                app.Component('app.layout', 'ceTopBar', {
                    templateUrl: 'app/layout/top-bar/top-bar.html',
                    bindings: {
                        onMenuClick: '&'
                    }
                })
            ], TopBarComponent);
            return TopBarComponent;
        }());
    })(layout = app.layout || (app.layout = {}));
})(app || (app = {}));
var app;
(function (app) {
    var mail;
    (function (mail) {
        var ChangeMessageFolderDialogController = (function () {
            function ChangeMessageFolderDialogController(common, $mdDialog, datacontext, message) {
                var _this = this;
                this.common = common;
                this.$mdDialog = $mdDialog;
                this.datacontext = datacontext;
                this.message = message;
                this.data = {};
                this.isSubmitting = false;
                this.getFolders = function () {
                    return _this.datacontext.mail
                        .getFolders({ involvementId: _this.message.involvementId, source: _this.message.source, sourceId: _this.message.sourceId, type: 'Folder', pageSize: 100 })
                        .then(function (result) { return _this.folders = result.list; });
                };
                'ngInject';
                'ngInject';
            }
            ChangeMessageFolderDialogController.prototype.submit = function () {
                var _this = this;
                this.isSubmitting = true;
                this.datacontext.mail
                    .changeMessageFolder(this.data)
                    .then(function () { return _this.$mdDialog.hide(); })
                    .finally(function () { return _this.isSubmitting = false; });
            };
            ChangeMessageFolderDialogController.prototype.$onInit = function () {
                this.getFolders();
                this.setData();
            };
            ChangeMessageFolderDialogController.prototype.setData = function () {
                this.data.messageId = this.message.id;
            };
            ChangeMessageFolderDialogController.prototype.cancel = function () {
                this.$mdDialog.cancel();
            };
            ChangeMessageFolderDialogController.$inject = ['common', '$mdDialog', 'datacontext', 'message'];
            ChangeMessageFolderDialogController = __decorate([
                app.Controller('app.mail', 'ChangeMessageFolderDialogController')
            ], ChangeMessageFolderDialogController);
            return ChangeMessageFolderDialogController;
        }());
    })(mail = app.mail || (app.mail = {}));
})(app || (app = {}));
var app;
(function (app) {
    var layout;
    (function (layout) {
        var SideNav = (function () {
            function SideNav(common) {
                this.common = common;
            }
            SideNav.$inject = ['common'];
            SideNav = __decorate([
                app.Component('app.layout', 'ceSideNav', {
                    templateUrl: 'app/layout/side-nav/side-nav.html'
                }),
                app.Inject('common')
            ], SideNav);
            return SideNav;
        }());
    })(layout = app.layout || (app.layout = {}));
})(app || (app = {}));
var app;
(function (app) {
    var mail;
    (function (mail) {
        var CopyMessageDialogController = (function () {
            function CopyMessageDialogController($mdDialog, datacontext, message) {
                this.$mdDialog = $mdDialog;
                this.datacontext = datacontext;
                this.message = message;
                this.data = {};
                this.isSubmitting = false;
                'ngInject';
                'ngInject';
            }
            CopyMessageDialogController.prototype.submit = function () {
                var _this = this;
                this.isSubmitting = true;
                this.datacontext.mail
                    .copyMessage(this.data)
                    .then(function () { return _this.$mdDialog.hide(); })
                    .finally(function () { return _this.isSubmitting = false; });
            };
            CopyMessageDialogController.prototype.$onInit = function () {
                this.data.messageId = this.message.id;
            };
            CopyMessageDialogController.prototype.setSource = function (source) {
                this.data.source = source.source;
                this.data.sourceId = source.sourceId;
                this.data.involvementId = source.involvementId;
            };
            CopyMessageDialogController.prototype.cancel = function () {
                this.$mdDialog.cancel();
            };
            CopyMessageDialogController.$inject = ['$mdDialog', 'datacontext', 'message'];
            CopyMessageDialogController = __decorate([
                app.Controller('app.mail', 'CopyMessageDialogController')
            ], CopyMessageDialogController);
            return CopyMessageDialogController;
        }());
    })(mail = app.mail || (app.mail = {}));
})(app || (app = {}));
var app;
(function (app) {
    var mail;
    (function (mail) {
        var EmailView = (function () {
            function EmailView(datacontext, common, blobHelper) {
                this.datacontext = datacontext;
                this.common = common;
                this.blobHelper = blobHelper;
                this.isSubmitting = false;
                'ngInject';
                'ngInject';
            }
            EmailView.prototype.$onInit = function () {
                var _this = this;
                this.datacontext.mail
                    .getMessage(this.messageId)
                    .then(function (result) { return _this.message = result; });
            };
            EmailView.prototype.onDownload = function (attachment) {
                var _this = this;
                this.datacontext.mail
                    .downloadAttachment(this.message.id, attachment.id)
                    .then(function (result) { return _this.blobHelper.open(result); });
            };
            EmailView.prototype.onCopy = function (attachment) {
                var _this = this;
                this.isSubmitting = true;
                var data = {
                    attachmentIds: [attachment.id],
                    involvementId: this.message.involvementId,
                    sourceId: this.message.sourceId,
                    source: this.message.source
                };
                this.datacontext.mail
                    .importAttachments(data)
                    .then(function (result) {
                    if (result) {
                        attachment.documentId = attachment.id;
                        _this.common.$mdToast.showSimple('Attachment copied');
                    }
                }).finally(function () { return _this.isSubmitting = false; });
            };
            EmailView.$inject = ['datacontext', 'common', 'blobHelper'];
            EmailView = __decorate([
                app.Component('app.mail', 'ceEmailView', {
                    templateUrl: 'app/mail/email/email-view.html',
                    bindings: {
                        messageId: '<'
                    }
                })
            ], EmailView);
            return EmailView;
        }());
    })(mail = app.mail || (app.mail = {}));
})(app || (app = {}));
var app;
(function (app) {
    var mail;
    (function (mail) {
        var CreateEmail = (function () {
            function CreateEmail(datacontext, common, blobHelper) {
                this.datacontext = datacontext;
                this.common = common;
                this.blobHelper = blobHelper;
                this.data = {};
                this.selectedDocuments = [];
                this.isSubmitting = false;
                'ngInject';
                'ngInject';
            }
            CreateEmail.prototype.$onInit = function () {
                this.data.involvementId = this.involvementId;
                this.data.sourceId = this.sourceId;
                this.data.source = this.source;
                if (this.sourceNumber) {
                    this.data.subject = "".concat(this.source, " ").concat(this.sourceNumber, ": ").concat(this.sourceName);
                }
                else {
                    this.data.subject = "".concat(this.source, ": ").concat(this.sourceName);
                }
            };
            CreateEmail.prototype.transformChip = function (chip) {
                if (angular.isObject(chip)) {
                    return chip;
                }
                return { name: chip, type: 'new' };
            };
            CreateEmail.prototype.querySearch = function (query) {
                return this.datacontext.documentManagement
                    .getDocuments({ involvementId: this.involvementId, source: this.source, sourceId: this.sourceId, q: query })
                    .then(function (result) { return result.list; });
            };
            CreateEmail.prototype.submit = function () {
                var _this = this;
                this.isSubmitting = true;
                this.data.documentIds = _.map(this.selectedDocuments, function (x) { return x.id; });
                this.datacontext.mail
                    .createMessage(this.data)
                    .then(function (result) {
                    if (result) {
                        _this.common.$mdToast.showSimple('Email sent');
                        _this.common.$state.go('^');
                    }
                }).finally(function () { return _this.isSubmitting = false; });
            };
            CreateEmail.$inject = ['datacontext', 'common', 'blobHelper'];
            CreateEmail = __decorate([
                app.Component('app.mail', 'ceCreateEmail', {
                    templateUrl: 'app/mail/create-email/create-email.html',
                    bindings: {
                        involvementId: '<',
                        sourceId: '<',
                        source: '@',
                        sourceNumber: '<',
                        sourceName: '<'
                    }
                })
            ], CreateEmail);
            return CreateEmail;
        }());
    })(mail = app.mail || (app.mail = {}));
})(app || (app = {}));
var app;
(function (app) {
    var mail;
    (function (mail) {
        var EmailList = (function () {
            function EmailList(datacontext) {
                this.datacontext = datacontext;
                'ngInject';
                'ngInject';
            }
            EmailList.prototype.$onInit = function () {
                var _this = this;
                this.datacontext.users.getCurrentUser().then(function (user) { return _this.currentUserName = user.userName; });
            };
            EmailList.$inject = ['datacontext'];
            EmailList = __decorate([
                app.Component('app.mail', 'ceEmailList', {
                    templateUrl: 'app/mail/email-listing/email-list.html',
                    bindings: {
                        messages: '<',
                        onShow: '&',
                        onRename: '&',
                        onDelete: '&',
                        onChange: '&',
                        onMove: '&',
                        onCopy: '&',
                        showReportDialog: '&',
                        refresh: '&',
                        paging: '<',
                        promise: '<'
                    }
                })
            ], EmailList);
            return EmailList;
        }());
    })(mail = app.mail || (app.mail = {}));
})(app || (app = {}));
var app;
(function (app) {
    var mail;
    (function (mail) {
        var EmailListing = (function () {
            function EmailListing($mdDialog, common, datacontext, blobHelper) {
                var _this = this;
                this.$mdDialog = $mdDialog;
                this.common = common;
                this.datacontext = datacontext;
                this.blobHelper = blobHelper;
                this.showFilters = false;
                this.paging = {
                    page: 1,
                    pageSize: 100,
                    order: '-receivedAt'
                };
                this.getMessages = function () {
                    if (!_this.dateFilterInitialized) {
                        return;
                    }
                    var params = __assign({ involvementId: _this.involvementId, source: _this.showInvolvementEmails ? null : _this.source, sourceId: _this.showInvolvementEmails ? null : _this.sourceId, folderId: _this.folderId, q: _this.subjectFilter }, _this.paging);
                    _this.promise = _this.datacontext.mail
                        .getFolders(params)
                        .then(function (result) {
                        _this.messages = result;
                    });
                };
                'ngInject';
                'ngInject';
            }
            EmailListing.prototype.$onInit = function () {
                this.dateFilterInitialized = true;
                this.getMessages();
            };
            EmailListing.prototype.dateFilterChanged = function (dates) {
                if (!dates) {
                    return;
                }
                this.dateFilterInitialized = true;
                this.fromDate = dates.fromDate;
                this.toDate = dates.toDate;
                this.getMessages();
            };
            EmailListing.prototype.toggleFilters = function () {
                this.showFilters = !this.showFilters;
            };
            EmailListing.prototype.getInvolvementEmails = function () {
                this.showInvolvementEmails = !this.showInvolvementEmails;
                this.getMessages();
            };
            EmailListing.prototype.download = function (message, attachment) {
                var _this = this;
                this.datacontext.mail
                    .downloadAttachment(message.id, attachment.id)
                    .then(function (result) { return _this.blobHelper.open(result); });
            };
            EmailListing.prototype.show = function (message) {
                if (message.type === 'Folder') {
                    this.showFolder(message);
                }
                else {
                    this.common.$state.go('^.message', { messageId: message.id });
                }
            };
            EmailListing.prototype.showFolder = function (message) {
                if (message) {
                    this.folderId = message.id;
                    this.folderName = message.subject;
                }
                else {
                    this.folderId = null;
                    this.folderName = null;
                }
                this.getMessages();
            };
            EmailListing.prototype.createFolder = function (targetEvent) {
                this.openFolderDialog(targetEvent, null);
            };
            EmailListing.prototype.rename = function (targetEvent, message) {
                if (message.type === 'Folder') {
                    this.renameFolder(targetEvent, message);
                }
            };
            EmailListing.prototype.renameFolder = function (targetEvent, folder) {
                this.openFolderDialog(targetEvent, folder);
            };
            EmailListing.prototype.openFolderDialog = function (targetEvent, folder) {
                var _this = this;
                var parent = angular.element(document.body);
                return this.$mdDialog.show({
                    parent: parent,
                    targetEvent: targetEvent,
                    fullscreen: true,
                    templateUrl: 'app/mail/folder-dialog/folder-dialog.html',
                    controller: 'MailFolderDialogController',
                    controllerAs: '$ctrl',
                    locals: {
                        folder: folder,
                        involvementId: this.involvementId,
                        source: this.source,
                        sourceId: this.sourceId
                    }
                })
                    .then(function () { _this.getMessages(); })
                    .catch(function (error) { if (error)
                    throw error; });
            };
            EmailListing.prototype.delete = function (message) {
                if (message.type === 'Folder') {
                    this.deleteFolder(message);
                }
                else {
                    this.deleteMessage(message);
                }
            };
            EmailListing.prototype.deleteFolder = function (folder) {
                var _this = this;
                this.common.confirmation.show({
                    title: 'Delete Folder',
                    message: 'Are you sure you want to delete this folder?',
                    ok: 'Delete',
                    cancel: 'Keep'
                }).then(function () {
                    _this.datacontext.mail
                        .deleteFolder(folder.id)
                        .then(function (result) {
                        if (result) {
                            _this.getMessages();
                        }
                    });
                }).catch(angular.noop);
            };
            EmailListing.prototype.deleteMessage = function (message) {
                var _this = this;
                this.common.confirmation.show({
                    title: 'Delete Message',
                    message: 'Are you sure you want to delete this message?',
                    ok: 'Delete',
                    cancel: 'Keep'
                }).then(function () {
                    _this.datacontext.mail
                        .deleteMessage(message.id)
                        .then(function (result) {
                        if (result) {
                            _.remove(_this.messages.list, message);
                            _this.messages.count--;
                        }
                    });
                }).catch(angular.noop);
            };
            EmailListing.prototype.change = function (targetEvent, message) {
                if (message.type === 'Message') {
                    this.changeMessageFolder(targetEvent, message);
                }
            };
            EmailListing.prototype.move = function (targetEvent, message) {
                if (message.type === 'Message') {
                    this.moveMessage(targetEvent, message);
                }
            };
            EmailListing.prototype.copy = function (targetEvent, message) {
                if (message.type === 'Message') {
                    this.copyMessage(targetEvent, message);
                }
            };
            EmailListing.prototype.changeMessageFolder = function (targetEvent, message) {
                var _this = this;
                var parent = angular.element(document.body);
                return this.$mdDialog.show({
                    parent: parent,
                    targetEvent: targetEvent,
                    fullscreen: true,
                    templateUrl: 'app/mail/change-message-folder-dialog/change-message-folder-dialog.html',
                    controller: 'ChangeMessageFolderDialogController',
                    controllerAs: '$ctrl',
                    locals: {
                        message: message
                    }
                })
                    .then(function () { _this.getMessages(); })
                    .catch(function (error) { if (error)
                    throw error; });
            };
            EmailListing.prototype.moveMessage = function (targetEvent, message) {
                var _this = this;
                var parent = angular.element(document.body);
                return this.$mdDialog.show({
                    parent: parent,
                    targetEvent: targetEvent,
                    fullscreen: true,
                    templateUrl: 'app/mail/move-message-dialog/move-message-dialog.html',
                    controller: 'MoveMessageDialogController',
                    controllerAs: '$ctrl',
                    locals: {
                        message: message
                    }
                })
                    .then(function () { _this.getMessages(); })
                    .catch(function (error) { if (error)
                    throw error; });
            };
            EmailListing.prototype.copyMessage = function (targetEvent, message) {
                var _this = this;
                var parent = angular.element(document.body);
                return this.$mdDialog.show({
                    parent: parent,
                    targetEvent: targetEvent,
                    fullscreen: true,
                    templateUrl: 'app/mail/copy-message-dialog/copy-message-dialog.html',
                    controller: 'CopyMessageDialogController',
                    controllerAs: '$ctrl',
                    locals: {
                        message: message
                    }
                })
                    .then(function () { _this.getMessages(); })
                    .catch(angular.noop);
            };
            EmailListing.prototype.showReportDialog = function (message) {
                var report = {
                    category: 'Other',
                    label: 'Single Email Report',
                    name: 'Single Email',
                    description: 'Prints Single Email',
                    permission: 'reports.view',
                    parameters: [
                        { label: 'MessageId', name: 'MessageId', dataType: 'hidden', value: message.id },
                    ]
                };
                return this.$mdDialog.show({
                    fullscreen: true,
                    templateUrl: 'app/reports/report-dialog/report-dialog.html',
                    controller: 'ReportDialogController',
                    controllerAs: '$ctrl',
                    focusOnOpen: false,
                    locals: {
                        report: report
                    }
                })
                    .catch(function (error) { if (error)
                    throw error; })
                    .finally(function () { return angular.noop; });
            };
            EmailListing.$inject = ['$mdDialog', 'common', 'datacontext', 'blobHelper'];
            EmailListing = __decorate([
                app.Component('app.mail', 'ceEmailListing', {
                    templateUrl: 'app/mail/email-listing/email-listing.html',
                    bindings: {
                        involvementId: '<',
                        source: '@',
                        sourceId: '<'
                    }
                })
            ], EmailListing);
            return EmailListing;
        }());
    })(mail = app.mail || (app.mail = {}));
})(app || (app = {}));
var app;
(function (app) {
    var mail;
    (function (mail) {
        var FolderDialogController = (function () {
            function FolderDialogController(common, $mdDialog, datacontext, source, sourceId, involvementId, folder) {
                this.common = common;
                this.$mdDialog = $mdDialog;
                this.datacontext = datacontext;
                this.source = source;
                this.sourceId = sourceId;
                this.involvementId = involvementId;
                this.folder = folder;
                this.data = {};
                this.isSubmitting = false;
                'ngInject';
                'ngInject';
            }
            FolderDialogController.prototype.submit = function () {
                var _this = this;
                this.isSubmitting = true;
                var promise = this.data.id
                    ? this.datacontext.mail.updateFolder(this.data)
                    : this.datacontext.mail.createFolder(this.data);
                promise
                    .then(function () { return _this.$mdDialog.hide(); })
                    .finally(function () { return _this.isSubmitting = false; });
            };
            FolderDialogController.prototype.$onInit = function () {
                this.setData();
            };
            FolderDialogController.prototype.setData = function () {
                this.data.involvementId = this.involvementId;
                this.data.source = this.source;
                this.data.sourceId = this.sourceId;
                if (this.folder) {
                    this.data.id = this.folder.id;
                    this.data.folderName = this.folder.subject;
                }
            };
            FolderDialogController.prototype.cancel = function () {
                this.$mdDialog.cancel();
            };
            FolderDialogController.$inject = ['common', '$mdDialog', 'datacontext', 'source', 'sourceId', 'involvementId', 'folder'];
            FolderDialogController = __decorate([
                app.Controller('app.mail', 'MailFolderDialogController')
            ], FolderDialogController);
            return FolderDialogController;
        }());
    })(mail = app.mail || (app.mail = {}));
})(app || (app = {}));
var app;
(function (app) {
    var mail;
    (function (mail) {
        var ImportAttachmentsView = (function () {
            function ImportAttachmentsView(common, datacontext) {
                var _this = this;
                this.common = common;
                this.datacontext = datacontext;
                this.attachments = [];
                this.data = { attachmentIds: [] };
                this.isSubmitting = false;
                this.$onChanges = function () {
                    _this.getMessages();
                };
                this.getCases = function (personId) {
                    _this.datacontext.entities
                        .getCaseListing(personId, { pageSize: 50 })
                        .then(function (result) { return _this.cases = result.list; });
                };
                this.getMessages = function () {
                    _this.datacontext.mail
                        .getMessages({ messageIds: _this.messageIds })
                        .then(function (result) {
                        angular.forEach(result.list, function (message) {
                            angular.forEach(message.attachments, function (attachment) { return _this.attachments.push({ message: message, attachment: attachment }); });
                        });
                        _this.data.attachmentIds = _.map(_this.attachments, function (x) { return x.attachment.id; });
                    });
                };
                'ngInject';
                'ngInject';
            }
            ImportAttachmentsView.prototype.getPersons = function (q) {
                return this.datacontext.persons
                    .getPersons({ q: q })
                    .then(function (result) { return result.list; });
            };
            ImportAttachmentsView.prototype.onPersonChange = function (person) {
                if (!person) {
                    this.selectedCase = null;
                }
                else {
                    this.getCases(person.id);
                }
            };
            ImportAttachmentsView.prototype.submit = function () {
                var _this = this;
                this.isSubmitting = true;
                this.data.involvementId = this.selectedCase.involvementId;
                this.data.sourceId = this.selectedCase.id;
                this.data.source = this.selectedCase.caseType;
                this.datacontext.mail
                    .importAttachments(this.data)
                    .then(function (result) {
                    if (result) {
                        _this.common.$mdToast.showSimple('Attachments imported');
                        _this.common.$state.go('^');
                    }
                }).finally(function () { return _this.isSubmitting = false; });
            };
            ImportAttachmentsView.$inject = ['common', 'datacontext'];
            ImportAttachmentsView = __decorate([
                app.Component('app.mail', 'ceImportAttachmentsView', {
                    templateUrl: 'app/mail/import-attachments/import-attachments-view.html',
                    bindings: {
                        messageIds: '<'
                    }
                })
            ], ImportAttachmentsView);
            return ImportAttachmentsView;
        }());
    })(mail = app.mail || (app.mail = {}));
})(app || (app = {}));
var app;
(function (app) {
    var mail;
    (function (mail) {
        var InboxComponent = (function () {
            function InboxComponent(common, datacontext, blobHelper) {
                var _this = this;
                this.common = common;
                this.datacontext = datacontext;
                this.blobHelper = blobHelper;
                this.messagesSelected = [];
                this.paging = {
                    page: 1,
                    pageSize: 100,
                    order: '-receivedAt'
                };
                this.myMessagesOnly = true;
                this.filterMessages = function (myMessagesOnly, q) {
                    _this.myMessagesOnly = myMessagesOnly;
                    _this.q = q;
                    _this.getMessages();
                };
                this.getMessages = function () {
                    var params = __assign({ myMessagesOnly: _this.myMessagesOnly, q: _this.q }, _this.paging);
                    _this.promise = _this.datacontext.mail
                        .getMessages(params)
                        .then(function (result) {
                        _this.messages = result;
                    });
                };
                'ngInject';
                'ngInject';
            }
            InboxComponent.prototype.deleteSelected = function () {
            };
            InboxComponent.prototype.delete = function (message) {
                var _this = this;
                this.common.confirmation.show({
                    title: 'Delete Message',
                    message: 'Are you sure you want to delete this message?',
                    ok: 'Delete',
                    cancel: 'Keep'
                }).then(function () {
                    _this.datacontext.mail
                        .deleteMessage(message.id)
                        .then(function (result) {
                        if (result) {
                            _.remove(_this.messages.list, message);
                            _.remove(_this.messagesSelected, message);
                            _this.messages.count--;
                        }
                    });
                }).catch(angular.noop);
            };
            InboxComponent.prototype.download = function (message, attachment) {
                var _this = this;
                this.datacontext.mail
                    .downloadAttachment(message.id, attachment.id)
                    .then(function (result) { return _this.blobHelper.open(result); });
            };
            InboxComponent.prototype.fetch = function () {
                this.datacontext.mail.fetchNewMessages()
                    .then(this.getMessages);
            };
            InboxComponent.prototype.linkMessages = function () {
                var messageIds = _.map(this.messagesSelected, 'id').join(',');
                this.common.$state.go('^.linkMessages', { messageIds: messageIds });
            };
            InboxComponent.prototype.$onInit = function () {
                this.getMessages();
            };
            InboxComponent.$inject = ['common', 'datacontext', 'blobHelper'];
            InboxComponent = __decorate([
                app.Component('app.mail', 'ceInboxView', {
                    templateUrl: 'app/mail/inbox/inbox-view.html'
                })
            ], InboxComponent);
            return InboxComponent;
        }());
    })(mail = app.mail || (app.mail = {}));
})(app || (app = {}));
var app;
(function (app) {
    var mail;
    (function (mail) {
        var MessageListToolbar = (function () {
            function MessageListToolbar() {
                this.showMyMessagesOnly = true;
            }
            MessageListToolbar.prototype.clearSelected = function () {
                this.messagesSelected.splice(0);
            };
            MessageListToolbar.prototype.filterMyMessagesOnly = function () {
                this.showMyMessagesOnly = !this.showMyMessagesOnly;
                this.filterMessages();
            };
            MessageListToolbar.prototype.filterMessages = function () {
                this.onFilter({ myMessagesOnly: this.showMyMessagesOnly, q: this.subjectFilter });
            };
            MessageListToolbar = __decorate([
                app.Component('app.mail', 'ceMailMessageListToolbar', {
                    templateUrl: 'app/mail/inbox/message-list-toolbar.html',
                    bindings: {
                        messagesSelected: '<',
                        onDelete: '&',
                        onFetch: '&',
                        onLink: '&',
                        onFilter: '&'
                    }
                })
            ], MessageListToolbar);
            return MessageListToolbar;
        }());
    })(mail = app.mail || (app.mail = {}));
})(app || (app = {}));
var app;
(function (app) {
    var mail;
    (function (mail) {
        var MessageList = (function () {
            function MessageList(datacontext) {
                this.datacontext = datacontext;
                'ngInject';
                'ngInject';
            }
            MessageList.prototype.$onInit = function () {
                var _this = this;
                this.datacontext.users.getCurrentUser().then(function (user) { return _this.currentUserName = user.userName; });
            };
            MessageList.$inject = ['datacontext'];
            MessageList = __decorate([
                app.Component('app.mail', 'ceMailMessageList', {
                    templateUrl: 'app/mail/inbox/message-list.html',
                    bindings: {
                        messages: '<',
                        messagesSelected: '<',
                        onDownload: '&',
                        onDelete: '&',
                        refresh: '&',
                        paging: '<',
                        promise: '<'
                    }
                })
            ], MessageList);
            return MessageList;
        }());
    })(mail = app.mail || (app.mail = {}));
})(app || (app = {}));
var app;
(function (app) {
    var mail;
    (function (mail) {
        var LinkMessagesFromSource = (function () {
            function LinkMessagesFromSource(common, datacontext) {
                var _this = this;
                this.common = common;
                this.datacontext = datacontext;
                this.data = { messageIds: [], sources: [] };
                this.isSubmitting = false;
                this.showMyMessagesOnly = true;
                this.paging = {
                    page: 1,
                    pageSize: 100,
                    order: '-receivedAt'
                };
                this.refresh = function () {
                    _this.getMessages();
                };
                this.getMessages = function () {
                    var params = __assign({ myMessagesOnly: _this.showMyMessagesOnly, q: _this.subjectFilter }, _this.paging);
                    _this.promise = _this.datacontext.mail
                        .getMessages(params)
                        .then(function (result) { return _this.messages = result; });
                };
                'ngInject';
                'ngInject';
            }
            LinkMessagesFromSource.prototype.$onInit = function () {
                this.data.sources.push({
                    involvementId: this.involvementId,
                    sourceId: this.sourceId,
                    source: this.source
                });
                this.getMessages();
            };
            LinkMessagesFromSource.prototype.clearSelected = function () {
                this.data.messageIds.splice(0);
            };
            LinkMessagesFromSource.prototype.fetch = function () {
                this.datacontext.mail.fetchNewMessages()
                    .then(this.getMessages);
            };
            LinkMessagesFromSource.prototype.link = function () {
                var _this = this;
                this.isSubmitting = true;
                this.datacontext.mail
                    .linkMessages(this.data)
                    .then(function (result) {
                    if (result) {
                        _this.common.$mdToast.showSimple('Messages linked');
                        _this.common.$state.go('^');
                    }
                }).finally(function () { return _this.isSubmitting = false; });
            };
            LinkMessagesFromSource.prototype.filterMyMessagesOnly = function () {
                this.showMyMessagesOnly = !this.showMyMessagesOnly;
                this.getMessages();
            };
            LinkMessagesFromSource.$inject = ['common', 'datacontext'];
            LinkMessagesFromSource = __decorate([
                app.Component('app.mail', 'ceLinkEmails', {
                    templateUrl: 'app/mail/link-messages-from-source/link-messages.html',
                    bindings: {
                        involvementId: '<',
                        source: '@',
                        sourceId: '<'
                    }
                })
            ], LinkMessagesFromSource);
            return LinkMessagesFromSource;
        }());
    })(mail = app.mail || (app.mail = {}));
})(app || (app = {}));
var app;
(function (app) {
    var mail;
    (function (mail) {
        var LinkMessagesView = (function () {
            function LinkMessagesView(common, datacontext) {
                var _this = this;
                this.common = common;
                this.datacontext = datacontext;
                this.messages = [];
                this.data = { messageIds: [] };
                this.items = [{}];
                this.isSubmitting = false;
                this.$onChanges = function () {
                    _this.getMessages();
                };
                'ngInject';
                'ngInject';
            }
            LinkMessagesView.prototype.addItem = function () {
                this.items.push({});
            };
            LinkMessagesView.prototype.removeItem = function (index) {
                this.items.splice(index, 1);
            };
            LinkMessagesView.prototype.submit = function () {
                var _this = this;
                this.isSubmitting = true;
                this.data.sources = _.map(this.items, function (i) { return ({
                    involvementId: i.selectedSource.involvementId,
                    sourceId: i.selectedSource.sourceId,
                    source: i.selectedSource.source
                }); });
                this.datacontext.mail
                    .linkMessages(this.data)
                    .then(function (result) {
                    if (result) {
                        _this.common.$mdToast.showSimple('Messages linked');
                        _this.common.$state.go('^');
                    }
                }).finally(function () { return _this.isSubmitting = false; });
            };
            LinkMessagesView.prototype.getMessages = function () {
                var _this = this;
                this.datacontext.mail
                    .getMessages({ messageIds: this.messageIds })
                    .then(function (result) {
                    _this.messages = result.list;
                    _this.data.messageIds = _.map(_this.messages, function (m) { return m.id; });
                });
            };
            LinkMessagesView.prototype.setSource = function (source, index) {
                this.items[index].selectedSource = source;
            };
            LinkMessagesView.$inject = ['common', 'datacontext'];
            LinkMessagesView = __decorate([
                app.Component('app.mail', 'ceLinkMessagesView', {
                    templateUrl: 'app/mail/link-messages/link-messages-view.html',
                    bindings: {
                        messageIds: '<'
                    }
                })
            ], LinkMessagesView);
            return LinkMessagesView;
        }());
    })(mail = app.mail || (app.mail = {}));
})(app || (app = {}));
var app;
(function (app) {
    var mail;
    (function (mail) {
        var MoveMessageDialogController = (function () {
            function MoveMessageDialogController($mdDialog, datacontext, message) {
                this.$mdDialog = $mdDialog;
                this.datacontext = datacontext;
                this.message = message;
                this.data = {};
                this.isSubmitting = false;
                'ngInject';
                'ngInject';
            }
            MoveMessageDialogController.prototype.submit = function () {
                var _this = this;
                this.isSubmitting = true;
                this.datacontext.mail
                    .moveMessage(this.data)
                    .then(function () { return _this.$mdDialog.hide(); })
                    .finally(function () { return _this.isSubmitting = false; });
            };
            MoveMessageDialogController.prototype.$onInit = function () {
                this.data.messageId = this.message.id;
            };
            MoveMessageDialogController.prototype.setSource = function (source) {
                this.data.source = source.source;
                this.data.sourceId = source.sourceId;
                this.data.involvementId = source.involvementId;
            };
            MoveMessageDialogController.prototype.cancel = function () {
                this.$mdDialog.cancel();
            };
            MoveMessageDialogController.$inject = ['$mdDialog', 'datacontext', 'message'];
            MoveMessageDialogController = __decorate([
                app.Controller('app.mail', 'MoveMessageDialogController')
            ], MoveMessageDialogController);
            return MoveMessageDialogController;
        }());
    })(mail = app.mail || (app.mail = {}));
})(app || (app = {}));
var app;
(function (app) {
    var persons;
    (function (persons) {
        var details;
        (function (details) {
            var EntityDetailsView = (function () {
                function EntityDetailsView($mdDialog, common, datacontext) {
                    this.$mdDialog = $mdDialog;
                    this.common = common;
                    this.datacontext = datacontext;
                    this.isLoading = false;
                    'ngInject';
                    'ngInject';
                }
                EntityDetailsView.prototype.$onInit = function () {
                    this.currentTab = this.common.$state.current.name.split('.')[3];
                };
                EntityDetailsView.$inject = ['$mdDialog', 'common', 'datacontext'];
                EntityDetailsView = __decorate([
                    app.Component('app.persons.details', 'cePersonDetailsView', {
                        templateUrl: 'app/persons/details/details-view.html',
                        bindings: {
                            person: '<',
                            paramPovertyLevel: '<',
                            paramAtPoverty: '<'
                        }
                    })
                ], EntityDetailsView);
                return EntityDetailsView;
            }());
        })(details = persons.details || (persons.details = {}));
    })(persons = app.persons || (app.persons = {}));
})(app || (app = {}));
var app;
(function (app) {
    var persons;
    (function (persons) {
        var CreatePerson = (function () {
            function CreatePerson($scope, $mdDialog, common, datacontext, addressHelper) {
                this.$scope = $scope;
                this.$mdDialog = $mdDialog;
                this.common = common;
                this.datacontext = datacontext;
                this.addressHelper = addressHelper;
                this.person = {};
                this.address = {};
                this.isSubmitting = false;
                this.isLoading = true;
                this.refresh = function () {
                };
                'ngInject';
                'ngInject';
                this.init();
            }
            CreatePerson.prototype.init = function () {
            };
            CreatePerson.prototype.cancel = function () {
            };
            CreatePerson.prototype.createNewPerson = function () {
            };
            CreatePerson.prototype.phoneChanged = function (phone) {
                this.person.phoneNumber = phone;
            };
            CreatePerson.prototype.onPlaceSelected = function (place) {
                var _this = this;
                this.common.$timeout(function () {
                    angular.extend(_this.address, _this.addressHelper.mapFromGooglePlaceResult(place));
                });
            };
            CreatePerson.prototype.submit = function () {
                var _this = this;
                this.isSubmitting = true;
                var personPromise = this.datacontext.persons
                    .createPerson(this.person)
                    .then(function (person) {
                    if (person) {
                        angular.extend(_this.person, person);
                        _this.common.$mdToast.showSimple('Person created');
                    }
                });
                if (this.person.dateOfBirth) {
                    personPromise.then(function () {
                        _this.datacontext.persons.updateBaseDemographics(_this.person.id, { id: _this.person.id, dateOfBirth: _this.person.dateOfBirth });
                    });
                }
                if (this.address.line1) {
                    personPromise.then(function () {
                        _this.address.entityId = _this.person.id;
                        _this.address.addressType = 'Residential';
                        _this.address.isPrimary = true;
                        _this.datacontext.entities.createEntityAddress(_this.address);
                    });
                }
                personPromise.then(function () {
                    _this.isSubmitting = false;
                    var entity = {};
                    entity.entityId = _this.person.id;
                    entity.fullName = "".concat(_this.person.firstName, " ").concat(_this.person.lastName);
                    entity.entityType = 'Person';
                    entity.isUser = false;
                    _this.common.$state.go('^.roles', { entity: entity });
                });
            };
            CreatePerson.$inject = ['$scope', '$mdDialog', 'common', 'datacontext', 'addressHelper'];
            CreatePerson = __decorate([
                app.Component('app.persons', 'ceCreatePerson', {
                    templateUrl: 'app/persons/find-create-person/create-person.html',
                    bindings: {}
                })
            ], CreatePerson);
            return CreatePerson;
        }());
    })(persons = app.persons || (app.persons = {}));
})(app || (app = {}));
var app;
(function (app) {
    var persons;
    (function (persons) {
        var FindPerson = (function () {
            function FindPerson($scope, $mdDialog, common, datacontext) {
                var _this = this;
                this.$scope = $scope;
                this.$mdDialog = $mdDialog;
                this.common = common;
                this.datacontext = datacontext;
                this.params = {
                    page: 1,
                    pageSize: 5
                };
                this.isLoading = true;
                this.searched = false;
                this.refresh = function () {
                    _this.search();
                };
                this.search = function () {
                    _this.searched = true;
                    if (!_this.params.q) {
                        _this.searchResults = null;
                        return;
                    }
                    return _this.datacontext.persons
                        .getPersons(_this.params)
                        .then(function (result) {
                        _this.searchResults = result;
                    });
                };
                'ngInject';
                'ngInject';
            }
            FindPerson.prototype.navigate = function (person) {
                var entity = {};
                entity.entityId = person.id;
                entity.fullName = person.fullName;
                entity.entityType = 'Person';
                entity.isUser = person.isUser;
                this.common.$state.go('^.roles', { entity: entity });
            };
            FindPerson.prototype.submit = function () {
            };
            FindPerson.$inject = ['$scope', '$mdDialog', 'common', 'datacontext'];
            FindPerson = __decorate([
                app.Component('app.persons', 'cePersonSearch', {
                    templateUrl: 'app/persons/find-create-person/person-search.html',
                    bindings: {}
                })
            ], FindPerson);
            return FindPerson;
        }());
    })(persons = app.persons || (app.persons = {}));
})(app || (app = {}));
var app;
(function (app) {
    var persons;
    (function (persons) {
        var overview;
        (function (overview) {
            var PersonImportantInformation = (function () {
                function PersonImportantInformation($mdDialog, common, datacontext) {
                    this.$mdDialog = $mdDialog;
                    this.common = common;
                    this.datacontext = datacontext;
                    'ngInject';
                    'ngInject';
                }
                PersonImportantInformation.prototype.$onInit = function () {
                    var _this = this;
                    this.datacontext.persons.getImportantInformation(this.person.id)
                        .then(function (result) { return _this.data = result; });
                };
                PersonImportantInformation.$inject = ['$mdDialog', 'common', 'datacontext'];
                PersonImportantInformation = __decorate([
                    app.Component('app.persons.overview', 'cePersonImportantInformation', {
                        templateUrl: 'app/persons/important/important.html',
                        bindings: {
                            person: '<'
                        }
                    })
                ], PersonImportantInformation);
                return PersonImportantInformation;
            }());
        })(overview = persons.overview || (persons.overview = {}));
    })(persons = app.persons || (app.persons = {}));
})(app || (app = {}));
var app;
(function (app) {
    var persons;
    (function (persons) {
        var overview;
        (function (overview) {
            var PersonOverviewView = (function () {
                function PersonOverviewView() {
                }
                PersonOverviewView = __decorate([
                    app.Component('app.persons.overview', 'cePersonOverviewView', {
                        templateUrl: 'app/persons/overview/overview-view.html',
                        bindings: {
                            person: '<'
                        }
                    })
                ], PersonOverviewView);
                return PersonOverviewView;
            }());
        })(overview = persons.overview || (persons.overview = {}));
    })(persons = app.persons || (app.persons = {}));
})(app || (app = {}));
var app;
(function (app) {
    var persons;
    (function (persons) {
        var PersonView = (function () {
            function PersonView($mdDialog, ageFilter, dateFilter, common) {
                this.$mdDialog = $mdDialog;
                this.ageFilter = ageFilter;
                this.dateFilter = dateFilter;
                this.common = common;
                'ngInject';
                'ngInject';
            }
            PersonView.prototype.$onInit = function () {
                if (this.person && this.person.dateOfBirth) {
                    this.dob = 'DOB ' + this.dateFilter(this.person.dateOfBirth, 'MM/dd/yy') + ' (' + this.ageFilter(this.person.dateOfBirth) + ')';
                }
                else {
                    this.dob = 'DOB unknown';
                }
            };
            PersonView.$inject = ['$mdDialog', 'ageFilter', 'dateFilter', 'common'];
            PersonView = __decorate([
                app.Component('app.persons', 'cePersonView', {
                    templateUrl: 'app/persons/person/person-view.html',
                    bindings: {
                        person: '<'
                    }
                })
            ], PersonView);
            return PersonView;
        }());
    })(persons = app.persons || (app.persons = {}));
})(app || (app = {}));
var app;
(function (app) {
    var persons;
    (function (persons) {
        var overview;
        (function (overview) {
            var PersonSummary = (function () {
                function PersonSummary($mdDialog, common, datacontext) {
                    this.$mdDialog = $mdDialog;
                    this.common = common;
                    this.datacontext = datacontext;
                    this.data = {};
                    'ngInject';
                    'ngInject';
                }
                PersonSummary.prototype.$onInit = function () {
                    this.getData();
                };
                PersonSummary.prototype.getData = function () {
                    var _this = this;
                    return this.datacontext.persons
                        .getDemographics(this.person.id)
                        .then(function (result) {
                        _this.data = result;
                    });
                };
                PersonSummary.$inject = ['$mdDialog', 'common', 'datacontext'];
                PersonSummary = __decorate([
                    app.Component('app.persons.overview', 'cePersonSummary', {
                        templateUrl: 'app/persons/summary/summary.html',
                        bindings: {
                            person: '<'
                        }
                    })
                ], PersonSummary);
                return PersonSummary;
            }());
        })(overview = persons.overview || (persons.overview = {}));
    })(persons = app.persons || (app.persons = {}));
})(app || (app = {}));
var app;
(function (app) {
    var monitoring;
    (function (monitoring) {
        var activities;
        (function (activities) {
            var routes = [
                {
                    name: 'app.monitoring.activities',
                    url: '/activities',
                    template: '<ui-view layout="column" flex></ui-view>',
                    redirectTo: 'app.monitoring.activities.list'
                },
                {
                    name: 'app.monitoring.activities.list',
                    url: '',
                    template: "<ce-monitoring-activity-list\n         monitoring=\"$resolve.monitoring\"\n                        layout=\"column\" flex>\n                    </ce-monitoring-activity-list>",
                    data: {
                        title: 'Monitoring Activities',
                        permissions: ['access_activities']
                    }
                },
                {
                    name: 'app.monitoring.activities.note',
                    url: '/note',
                    template: "<ce-monitoring-activities-note layout=\"column\" flex\n                        involvement-id=\"$resolve.monitoring.involvementId\"\n                        monitoring-Id=\"$resolve.monitoring.id\"\n                        activity-Type=\"MonitoringNote\"\n                        name=\"Note\"\n                        activity=\"$resolve.activity\"\n                        case-open-date=\"$resolve.monitoring.openDate\"\n                        return-sref=\"app.monitoring.activities.list\">\n                    </ce-monitoring-activities-note>",
                    params: { activity: null },
                    resolve: { activity: ['$stateParams', function (p) { return p.activity; }] },
                    data: { title: 'Note' }
                },
                {
                    name: 'app.monitoring.activities.conflictCheck',
                    url: '/conflictCheck',
                    template: "<ce-conflict-check layout=\"column\" flex\n                     involvement-id=\"$resolve.monitoring.involvementId\"\n                     source=\"Monitoring\"\n                     source-Id=\"$resolve.monitoring.id\"\n                     case-open-date=\"$resolve.monitoring.openDate\">\n                    </ce-conflict-check>",
                    data: { title: 'Conflict Check' }
                },
                {
                    name: 'app.monitoring.activities.close',
                    url: '/close',
                    template: "<ce-monitoring-activities-close\n         monitoring=\"$resolve.monitoring\" layout=\"column\" flex>\n                    </ce-monitoring-activities-close>",
                    data: { title: 'Close' }
                },
                {
                    name: 'app.monitoring.activities.reopen',
                    url: '/reopen',
                    template: "<ce-monitoring-activities-reopen\n         monitoring=\"$resolve.monitoring\" flex>\n                    </ce-monitoring-activities-reopen>",
                    data: { title: 'Reopen' }
                },
                {
                    name: 'app.monitoring.activities.caseReview',
                    url: '/review',
                    template: "<ce-monitoring-review\n                     involvement-id=\"$resolve.monitoring.involvementId\"\n                     monitoring-id=\"$resolve.monitoring.id\"\n                     activity=\"$resolve.activity\"\n                     case-open-date=\"$resolve.monitoring.openDate\"\n                     flex>\n                    </ce-monitoring-review>",
                    params: { activity: null },
                    resolve: { activity: ['$stateParams', function (p) { return p.activity; }] },
                    data: { title: 'Review' }
                },
                {
                    name: 'app.monitoring.activities.issue',
                    url: '/issue',
                    template: "<am-monitoring-issue layout=\"column\" flex\n                        [involvement-id]=\"$resolve.monitoring.involvementId\"\n                        [source-id]=\"$resolve.monitoring.id\"\n                        [activity-id]=\"$resolve.activityId\">\n                    </am-monitoring-issue>",
                    params: { activityId: null },
                    resolve: { activityId: ['$stateParams', function (p) { return p.activityId; }] },
                    data: {
                        title: 'Contact',
                        permissions: ['monitoring.edit']
                    }
                },
                {
                    name: 'app.monitoring.activities.contact',
                    url: '/contact',
                    template: "<am-monitoring-contact layout=\"column\" flex\n                        [involvement-id]=\"$resolve.monitoring.involvementId\"\n                        [source-id]=\"$resolve.monitoring.id\"\n                        [activity-id]=\"$resolve.activityId\">\n                    </am-monitoring-contact>",
                    params: { activityId: null },
                    resolve: { activityId: ['$stateParams', function (p) { return p.activityId; }] },
                    data: {
                        title: 'Contact',
                        permissions: ['monitoring.edit']
                    }
                },
                {
                    name: 'app.monitoring.activities.meeting',
                    url: '/meeting',
                    template: "<am-monitoring-meeting layout=\"column\" flex\n                        [involvement-id]=\"$resolve.monitoring.involvementId\"\n                        [source-id]=\"$resolve.monitoring.id\"\n                        [activity-id]=\"$resolve.activityId\">\n                    </am-monitoring-meeting>",
                    params: { activityId: null },
                    resolve: { activityId: ['$stateParams', function (p) { return p.activityId; }] },
                    data: {
                        title: 'Meeting',
                        permissions: ['monitoring.edit']
                    }
                },
                {
                    name: 'app.monitoring.activities.statusChange',
                    url: '/statusChange',
                    template: "<am-activities-status-change layout=\"column\" flex\n                        [involvement-id]=\"$resolve.monitoring.involvementId\"\n                        [source-id]=\"$resolve.monitoring.id\"\n                        source=\"monitoring\"\n                        [activity-id]=\"$resolve.activityId\">\n                    </am-activities-status-change>",
                    params: { activityId: null },
                    resolve: { activityId: ['$stateParams', function (p) { return p.activityId; }] },
                    data: {
                        title: 'Status Change',
                        permissions: ['monitoring.edit']
                    }
                }
            ];
            var runner = function (routingHelper) {
                'ngInject';
                routingHelper.configureRoutes(routes);
            };
            angular
                .module('app.monitoring.activities')
                .run(runner);
        })(activities = monitoring.activities || (monitoring.activities = {}));
    })(monitoring = app.monitoring || (app.monitoring = {}));
})(app || (app = {}));
var app;
(function (app) {
    var monitoring;
    (function (monitoring) {
        var closure;
        (function (closure) {
            var MonitoringClosureInformationForm = (function () {
                function MonitoringClosureInformationForm(common, datacontext) {
                    this.common = common;
                    this.datacontext = datacontext;
                    this.isLoading = false;
                    this.isSubmitting = false;
                    'ngInject';
                    'ngInject';
                }
                MonitoringClosureInformationForm.prototype.$onInit = function () {
                    this.getData();
                };
                MonitoringClosureInformationForm.prototype.getData = function () {
                    var _this = this;
                    this.isLoading = true;
                    this.datacontext.monitoring
                        .getClosureInformation(this.monitoringId)
                        .then(function (result) { return _this.data = result; })
                        .finally(function () { return _this.isLoading = false; });
                };
                MonitoringClosureInformationForm.prototype.submit = function () {
                    var _this = this;
                    this.isSubmitting = true;
                    if (!this.data.monetarySettlement) {
                        this.data.monetarySettlementAmount = null;
                    }
                    this.datacontext.monitoring
                        .updateClosureInformation(this.data)
                        .then(function (result) {
                        if (result) {
                            _this.common.$mdToast.showSimple('Remember to Complete Survey');
                            _this.onSubmit({ result: result });
                        }
                    }).finally(function () { return _this.isSubmitting = false; });
                };
                MonitoringClosureInformationForm.$inject = ['common', 'datacontext'];
                MonitoringClosureInformationForm = __decorate([
                    app.Component('app.monitoring.closure', 'ceMonitoringClosureInformationForm', {
                        templateUrl: 'app/monitoring/closure-information/closure-information-form.html',
                        bindings: {
                            monitoringId: '<',
                            onCancel: '&',
                            onSubmit: '&'
                        }
                    })
                ], MonitoringClosureInformationForm);
                return MonitoringClosureInformationForm;
            }());
        })(closure = monitoring.closure || (monitoring.closure = {}));
    })(monitoring = app.monitoring || (app.monitoring = {}));
})(app || (app = {}));
var app;
(function (app) {
    var monitoring;
    (function (monitoring) {
        var closure;
        (function (closure) {
            var MonitoringClosureInformation = (function () {
                function MonitoringClosureInformation() {
                }
                MonitoringClosureInformation = __decorate([
                    app.Component('app.monitoring.closure', 'ceMonitoringClosureInformation', {
                        templateUrl: 'app/monitoring/closure-information/closure-information.html',
                        bindings: {
                            data: '<',
                            onEdit: '&'
                        }
                    })
                ], MonitoringClosureInformation);
                return MonitoringClosureInformation;
            }());
        })(closure = monitoring.closure || (monitoring.closure = {}));
    })(monitoring = app.monitoring || (app.monitoring = {}));
})(app || (app = {}));
var app;
(function (app) {
    var monitoring;
    (function (monitoring) {
        var closure;
        (function (closure) {
            var MonitoringClosure = (function () {
                function MonitoringClosure($mdDialog, common, datacontext) {
                    this.$mdDialog = $mdDialog;
                    this.common = common;
                    this.datacontext = datacontext;
                    this.editClosureInformation = false;
                    'ngInject';
                    'ngInject';
                }
                MonitoringClosure.prototype.$onInit = function () {
                    this.GetClosureInformation();
                };
                MonitoringClosure.prototype.updateClosureInformation = function (result) {
                    this.closureInformation = result;
                    this.editClosureInformation = false;
                };
                MonitoringClosure.prototype.GetClosureInformation = function () {
                    var _this = this;
                    this.datacontext.monitoring.getClosureInformation(this.monitoring.id).then(function (result) { return _this.closureInformation = result; });
                };
                MonitoringClosure.$inject = ['$mdDialog', 'common', 'datacontext'];
                MonitoringClosure = __decorate([
                    app.Component('app.monitoring.closure', 'ceMonitoringClosure', {
                        templateUrl: 'app/monitoring/closure-information/closure.html',
                        bindings: {
                            monitoring: '<'
                        }
                    })
                ], MonitoringClosure);
                return MonitoringClosure;
            }());
        })(closure = monitoring.closure || (monitoring.closure = {}));
    })(monitoring = app.monitoring || (app.monitoring = {}));
})(app || (app = {}));
var app;
(function (app) {
    var monitoring;
    (function (monitoring) {
        var entities;
        (function (entities) {
            var routes = [
                {
                    name: 'app.monitoring.entities',
                    url: '/entities',
                    template: '<ui-view flex layout="column"></ui-view>',
                    redirectTo: 'app.monitoring.entities.listing',
                    data: { title: 'Monitoring Entities' }
                },
                {
                    name: 'app.monitoring.entities.listing',
                    url: '/listing',
                    template: '<ce-involvement-entity-listing involvement-id="$resolve.monitoring.involvementId" layout="column" flex></ce-involvement-entity-listing>',
                    data: { title: 'Monitoring Entities' }
                },
                {
                    name: 'app.monitoring.entities.add',
                    url: '/add',
                    template: '<ce-select-entity-type flex></ce-select-entity-type>',
                    redirectTo: 'app.monitoring.entities.add.type',
                    data: { title: 'Add' }
                },
                {
                    name: 'app.monitoring.entities.add.type',
                    url: '/types',
                    template: '<ce-entity-types flex></ce-entity-types>'
                },
                {
                    name: 'app.monitoring.entities.edit',
                    url: '/edit',
                    params: { entity: null },
                    resolve: { entity: ['$stateParams', function (p) { return p.entity; }] },
                    template: '<ce-edit-entity-roles involvement-id="$resolve.monitoring.involvementId" entity="$resolve.entity" layout="column" flex></ce-edit-entity-roles>',
                    data: { title: 'Edit' }
                },
                {
                    name: 'app.monitoring.entities.add.person',
                    url: '/person',
                    template: '<ui-view layout flex></ui-view>',
                    redirectTo: 'app.monitoring.entities.add.person.search',
                },
                {
                    name: 'app.monitoring.entities.add.person.search',
                    url: '/search',
                    template: '<ce-person-search flex></ce-person-search>',
                    data: { title: 'Find Person' }
                },
                {
                    name: 'app.monitoring.entities.add.person.create',
                    url: '/create',
                    template: '<ce-create-person flex></ce-create-person>',
                    data: { title: 'Create Person' }
                },
                {
                    name: 'app.monitoring.entities.add.person.roles',
                    url: '/roles',
                    params: { entity: null },
                    resolve: { entity: ['$stateParams', function (p) { return p.entity; }] },
                    template: '<ce-entity-roles involvement-id="$resolve.monitoring.involvementId" entity="$resolve.entity" layout="column" flex></ce-entity-roles>',
                    data: { title: 'Select Roles' }
                },
                {
                    name: 'app.monitoring.entities.add.agency',
                    url: '/agency',
                    template: '<ui-view layout flex></ui-view>',
                    redirectTo: 'app.monitoring.entities.add.agency.search',
                },
                {
                    name: 'app.monitoring.entities.add.agency.search',
                    url: '/search',
                    template: '<ce-agency-search flex></ce-agenyc-search>',
                    data: { title: 'Find Agency' }
                },
                {
                    name: 'app.monitoring.entities.add.agency.create',
                    url: '/create',
                    template: '<ce-create-agency flex></ce-create-agency>',
                    data: { title: 'Create Agency' }
                },
                {
                    name: 'app.monitoring.entities.add.agency.roles',
                    url: '/roles',
                    params: { entity: null },
                    resolve: { entity: ['$stateParams', function (p) { return p.entity; }] },
                    template: '<ce-entity-roles involvement-id="$resolve.monitoring.involvementId" entity="$resolve.entity" layout="column" flex></ce-entity-roles>',
                    data: { title: 'Select Roles' }
                }
            ];
            var runner = function (routingHelper) {
                'ngInject';
                routingHelper.configureRoutes(routes);
            };
            angular
                .module('app.monitoring.entities')
                .run(runner);
        })(entities = monitoring.entities || (monitoring.entities = {}));
    })(monitoring = app.monitoring || (app.monitoring = {}));
})(app || (app = {}));
var app;
(function (app) {
    var monitoring;
    (function (monitoring) {
        var details;
        (function (details) {
            var MonitoringDetails = (function () {
                function MonitoringDetails($mdDialog, common, datacontext) {
                    this.$mdDialog = $mdDialog;
                    this.common = common;
                    this.datacontext = datacontext;
                    this.editMonitoringInformation = false;
                    this.editClientInformation = false;
                    'ngInject';
                    'ngInject';
                }
                MonitoringDetails.prototype.$onInit = function () {
                    var _this = this;
                    var promise = null;
                    promise = this.getMonitoring();
                    promise.then(function () {
                        _this.GetMonitoringInformation();
                        _this.GetClientInformation();
                    });
                };
                MonitoringDetails.prototype.getMonitoring = function () {
                    var _this = this;
                    return this.datacontext.monitoring.getMonitoringBasicInfoView(this.monitoring.id)
                        .then(function (cc) { return _this.monitoring = cc; });
                };
                MonitoringDetails.prototype.updateMonitoringInformation = function (result) {
                    this.monitoringInformation = result;
                    this.editMonitoringInformation = false;
                };
                MonitoringDetails.prototype.updateClientInformation = function (result) {
                    this.clientInformation = result;
                    this.editClientInformation = false;
                };
                MonitoringDetails.prototype.GetMonitoringInformation = function () {
                    var _this = this;
                    this.datacontext.monitoring.getMonitoringInformation(this.monitoring.id).then(function (result) { return _this.monitoringInformation = result; });
                };
                MonitoringDetails.prototype.GetClientInformation = function () {
                    var _this = this;
                    if (this.monitoring.clientPersonId) {
                        this.datacontext.persons.getDemographics(this.monitoring.clientPersonId).then(function (result) { return _this.clientInformation = result; });
                    }
                };
                MonitoringDetails.$inject = ['$mdDialog', 'common', 'datacontext'];
                MonitoringDetails = __decorate([
                    app.Component('app.monitoring.details', 'ceMonitoringDetails', {
                        templateUrl: 'app/monitoring/details/details.html',
                        bindings: {
                            monitoring: '<',
                            bypassCensus: '<'
                        }
                    })
                ], MonitoringDetails);
                return MonitoringDetails;
            }());
        })(details = monitoring.details || (monitoring.details = {}));
    })(monitoring = app.monitoring || (app.monitoring = {}));
})(app || (app = {}));
var app;
(function (app) {
    var monitoring;
    (function (monitoring) {
        var funding;
        (function (funding) {
            var routes = [
                {
                    name: 'app.monitoring.funding',
                    url: '/funding',
                    template: '<ce-funding involvement-id="$resolve.monitoring.involvementId" layout="column" flex></ce-funding>',
                    redirectTo: 'app.monitoring.funding.summary',
                    data: { title: 'Monitoring Funding Summary' }
                },
                {
                    name: 'app.monitoring.funding.summary',
                    url: '/summary',
                    template: '<ce-funding-summary involvement-id="$resolve.monitoring.involvementId" layout="column" flex></ce-funding-summary>',
                    data: { title: 'Monitoring Funding' }
                },
                {
                    name: 'app.monitoring.funding.eligibility',
                    url: '/eligibility',
                    template: '<ui-view layout flex></ui-view>',
                    redirectTo: 'app.monitoring.funding.eligibility.list',
                    data: { title: 'Monitoring Funding' }
                },
                {
                    name: 'app.monitoring.funding.eligibility.list',
                    url: '/list',
                    template: "<am-funding-eligibility-view\n                    [involvement-id]=\"$resolve.monitoring.involvementId\"\n                    source-type=\"monitoring\"\n                    layout=\"column\" flex></am-funding-eligibility-view>",
                    data: { title: 'Monitoring Funding' }
                },
                {
                    name: 'app.monitoring.funding.eligibility.create',
                    url: '/create',
                    template: '<ce-funding-eligibility-create involvement-id="$resolve.monitoring.involvementId" source="Monitoring" layout="column" flex></ce-funding-eligibility-create>',
                    data: { title: 'Create Eligibility' }
                },
                {
                    name: 'app.monitoring.funding.eligibility.cap',
                    url: '/cap',
                    params: { fundingProgramId: null },
                    resolve: { fundingProgramId: ['$stateParams', function (p) { return p.fundingProgramId; }] },
                    template: '<ce-cap-eligibility involvement-id="$resolve.monitoring.involvementId" funding-program-id="$resolve.fundingProgramId" layout="column" flex></ce-cap-eligibility>',
                    data: { title: 'CAP Eligibility' }
                },
                {
                    name: 'app.monitoring.funding.eligibility.paat',
                    url: '/paat',
                    params: { fundingProgramId: null },
                    resolve: { fundingProgramId: ['$stateParams', function (p) { return p.fundingProgramId; }] },
                    template: '<ce-paat-eligibility involvement-id="$resolve.monitoring.involvementId" funding-program-id="$resolve.fundingProgramId" layout="column" flex></ce-paat-eligibility>',
                    data: { title: 'PAAT Eligibility' }
                },
                {
                    name: 'app.monitoring.funding.eligibility.pabss',
                    url: '/pabss',
                    params: { fundingProgramId: null },
                    resolve: { fundingProgramId: ['$stateParams', function (p) { return p.fundingProgramId; }] },
                    template: '<ce-pabss-eligibility involvement-id="$resolve.monitoring.involvementId" funding-program-id="$resolve.fundingProgramId" layout="column" flex></ce-pabss-eligibility>',
                    data: { title: 'PABSS Eligibility' }
                },
                {
                    name: 'app.monitoring.funding.eligibility.padd',
                    url: '/padd',
                    params: { fundingProgramId: null },
                    resolve: { fundingProgramId: ['$stateParams', function (p) { return p.fundingProgramId; }] },
                    template: '<ce-padd-eligibility involvement-id="$resolve.monitoring.involvementId" funding-program-id="$resolve.fundingProgramId" layout="column" flex></ce-padd-eligibility>',
                    data: { title: 'PADD Eligibility' }
                },
                {
                    name: 'app.monitoring.funding.eligibility.paimi',
                    url: '/paimi',
                    params: { fundingProgramId: null },
                    resolve: { fundingProgramId: ['$stateParams', function (p) { return p.fundingProgramId; }] },
                    template: '<ce-paimi-eligibility involvement-id="$resolve.monitoring.involvementId" funding-program-id="$resolve.fundingProgramId" layout="column" flex></ce-paimi-eligibility>',
                    data: { title: 'PAIMI Eligibility' }
                },
                {
                    name: 'app.monitoring.funding.eligibility.pair',
                    url: '/pair',
                    params: { fundingProgramId: null },
                    resolve: { fundingProgramId: ['$stateParams', function (p) { return p.fundingProgramId; }] },
                    template: '<ce-pair-eligibility involvement-id="$resolve.monitoring.involvementId" funding-program-id="$resolve.fundingProgramId" layout="column" flex></ce-pair-eligibility>',
                    data: { title: 'PAIR Eligibility' }
                },
                {
                    name: 'app.monitoring.funding.eligibility.patbi',
                    url: '/patbi',
                    params: { fundingProgramId: null },
                    resolve: { fundingProgramId: ['$stateParams', function (p) { return p.fundingProgramId; }] },
                    template: '<ce-patbi-eligibility involvement-id="$resolve.monitoring.involvementId" funding-program-id="$resolve.fundingProgramId" layout="column" flex></ce-patbi-eligibility>',
                    data: { title: 'PATBI Eligibility' }
                },
                {
                    name: 'app.monitoring.funding.enrollment',
                    url: '/primary',
                    template: '<ui-view layout flex></ui-view>',
                    redirectTo: 'app.monitoring.funding.enrollment.list'
                },
                {
                    name: 'app.monitoring.funding.enrollment.list',
                    url: '/list',
                    template: '<ce-primary-funding-source-listing involvement-id="$resolve.monitoring.involvementId" layout="column" source="Monitoring" flex></ce-primary-funding-source-listing>',
                    data: { title: 'Primary List' }
                },
                {
                    name: 'app.monitoring.funding.enrollment.primary',
                    url: '/primary',
                    template: '<ce-create-primary-funding involvement-id="$resolve.monitoring.involvementId" layout="column" flex></ce-create-primary-funding>',
                    data: { title: 'Primary Funding' }
                },
                {
                    name: 'app.monitoring.funding.enrollment.updatePrimary',
                    url: '/updateprimary',
                    params: { enrollment: null },
                    resolve: { enrollment: ['$stateParams', function (p) { return p.enrollment; }] },
                    template: '<ce-edit-primary-funding enrollment="$resolve.enrollment" layout="column" flex></ce-edit-primary-funding>',
                    data: { title: 'Update Funding' }
                },
                {
                    name: 'app.monitoring.funding.enrollment.paddIntake',
                    url: '/padd-intake/{enrollmentId}',
                    template: '<ce-padd-intake enrollment-id="$resolve.enrollmentId" layout="column" flex></ce-padd-intake>',
                    params: { enrollmentId: null },
                    resolve: { enrollmentId: ['$stateParams', function (p) { return p.enrollmentId; }] },
                    data: { title: 'PADD Intake' }
                },
                {
                    name: 'app.monitoring.funding.enrollment.paddOutcome',
                    url: '/padd-outcome/{enrollmentId}',
                    template: '<ce-padd-outcome enrollment-id="$resolve.enrollmentId" layout="column" flex></ce-padd-outcome>',
                    params: { enrollmentId: null },
                    resolve: { enrollmentId: ['$stateParams', function (p) { return p.enrollmentId; }] },
                    data: { title: 'PADD Outcome' }
                },
                {
                    name: 'app.monitoring.funding.enrollment.pabssIntake',
                    url: '/pabss-intake/{enrollmentId}',
                    template: '<ce-pabss-intake enrollment-id="$resolve.enrollmentId" layout="column" flex></ce-pabss-intake>',
                    params: { enrollmentId: null },
                    resolve: { enrollmentId: ['$stateParams', function (p) { return p.enrollmentId; }] },
                    data: { title: 'PABSS Intake' }
                },
                {
                    name: 'app.monitoring.funding.enrollment.pabssOutcome',
                    url: '/pabss-outcome/{enrollmentId}',
                    template: '<ce-pabss-outcome enrollment-id="$resolve.enrollmentId" layout="column" flex></ce-pabss-outcome>',
                    params: { enrollmentId: null },
                    resolve: { enrollmentId: ['$stateParams', function (p) { return p.enrollmentId; }] },
                    data: { title: 'PABSS Outcome' }
                },
                {
                    name: 'app.monitoring.funding.enrollment.capIntake',
                    url: '/cap-intake/{enrollmentId}',
                    template: '<ce-cap-intake enrollment-id="$resolve.enrollmentId" layout="column" flex></ce-cap-intake>',
                    params: { enrollmentId: null },
                    resolve: { enrollmentId: ['$stateParams', function (p) { return p.enrollmentId; }] },
                    data: { title: 'CAP Intake' }
                },
                {
                    name: 'app.monitoring.funding.enrollment.paatIntake',
                    url: '/paat-intake/{enrollmentId}',
                    template: '<ce-paat-intake enrollment-id="$resolve.enrollmentId" layout="column" flex></ce-paat-intake>',
                    params: { enrollmentId: null },
                    resolve: { enrollmentId: ['$stateParams', function (p) { return p.enrollmentId; }] },
                    data: { title: 'PAAT Intake' }
                },
                {
                    name: 'app.monitoring.funding.enrollment.paatOutcome',
                    url: '/paat-outcome/{enrollmentId}',
                    template: '<ce-paat-outcome enrollment-id="$resolve.enrollmentId" layout="column" flex></ce-paat-outcome>',
                    params: { enrollmentId: null },
                    resolve: { enrollmentId: ['$stateParams', function (p) { return p.enrollmentId; }] },
                    data: { title: 'PAAT Outcome' }
                },
                {
                    name: 'app.monitoring.funding.enrollment.pairIntake',
                    url: '/pair-intake/{enrollmentId}',
                    template: '<ce-pair-intake enrollment-id="$resolve.enrollmentId" layout="column" flex></ce-pair-intake>',
                    params: { enrollmentId: null },
                    resolve: { enrollmentId: ['$stateParams', function (p) { return p.enrollmentId; }] },
                    data: { title: 'PAIR Intake' }
                },
                {
                    name: 'app.monitoring.funding.enrollment.pairOutcome',
                    url: '/pair-outcome/{enrollmentId}',
                    template: '<ce-pair-outcome enrollment-id="$resolve.enrollmentId" layout="column" flex></ce-pair-outcome>',
                    params: { enrollmentId: null },
                    resolve: { enrollmentId: ['$stateParams', function (p) { return p.enrollmentId; }] },
                    data: { title: 'PAIR Outcome' }
                },
                {
                    name: 'app.monitoring.funding.enrollment.patbiIntake',
                    url: '/patbi-intake/{enrollmentId}',
                    template: '<ce-patbi-intake enrollment-id="$resolve.enrollmentId" layout="column" flex></ce-patbi-intake>',
                    params: { enrollmentId: null },
                    resolve: { enrollmentId: ['$stateParams', function (p) { return p.enrollmentId; }] },
                    data: { title: 'PATBI Intake' }
                },
                {
                    name: 'app.monitoring.funding.enrollment.patbiOutcome',
                    url: '/patbi-outcome/{enrollmentId}',
                    template: '<ce-patbi-outcome enrollment-id="$resolve.enrollmentId" layout="column" flex></ce-patbi-outcome>',
                    params: { enrollmentId: null },
                    resolve: { enrollmentId: ['$stateParams', function (p) { return p.enrollmentId; }] },
                    data: { title: 'PATBI Outcome' }
                },
                {
                    name: 'app.monitoring.funding.enrollment.paimiIntake',
                    url: '/paimi-intake/{enrollmentId}',
                    template: '<ce-paimi-intake enrollment-id="$resolve.enrollmentId" layout="column" flex></ce-paimi-intake>',
                    params: { enrollmentId: null },
                    resolve: { enrollmentId: ['$stateParams', function (p) { return p.enrollmentId; }] },
                    data: { title: 'PAIMI Intake' }
                },
                {
                    name: 'app.monitoring.funding.enrollment.paimiOutcome',
                    url: '/paimi-outcome/{enrollmentId}',
                    template: '<ce-paimi-outcome enrollment-id="$resolve.enrollmentId" layout="column" flex></ce-paimi-outcome>',
                    params: { enrollmentId: null },
                    resolve: { enrollmentId: ['$stateParams', function (p) { return p.enrollmentId; }] },
                    data: { title: 'PAIMI Outcome' }
                },
                {
                    name: 'app.monitoring.funding.secondary',
                    url: '/secondary',
                    template: '<ui-view flex></ui-view>',
                    redirectTo: 'app.monitoring.funding.secondary.view',
                    data: { title: 'Secondary Funding' }
                },
                {
                    name: 'app.monitoring.funding.secondary.edit',
                    url: '/edit',
                    params: { fundingProgramId: null },
                    resolve: { fundingProgramId: ['$stateParams', function (p) { return p.fundingProgramId; }] },
                    template: '<ce-edit-secondary-funding involvement-id="$resolve.monitoring.involvementId" funding-program-id="$resolve.fundingProgramId" layout="column" flex></ce-edit-secondary-funding>',
                    data: { title: 'Secondary Funding' }
                },
                {
                    name: 'app.monitoring.funding.secondary.view',
                    url: '/views',
                    template: '<ce-secondary-funding-view involvement-id="$resolve.monitoring.involvementId" layout="column" flex></ce-secondary-funding-view>',
                    data: { title: 'Secondary Funding' }
                }
            ];
            var runner = function (routingHelper) {
                'ngInject';
                routingHelper.configureRoutes(routes);
            };
            angular
                .module('app.monitoring.funding')
                .run(runner);
        })(funding = monitoring.funding || (monitoring.funding = {}));
    })(monitoring = app.monitoring || (app.monitoring = {}));
})(app || (app = {}));
var app;
(function (app) {
    var monitoring;
    (function (monitoring_1) {
        var MonitoringView = (function () {
            function MonitoringView($mdDialog, common, confirmation, datacontext, timerService) {
                var _this = this;
                this.$mdDialog = $mdDialog;
                this.common = common;
                this.confirmation = confirmation;
                this.datacontext = datacontext;
                this.timerService = timerService;
                this.isLoading = false;
                this.refresh = function () {
                    _this.datacontext.monitoring.getMonitoringBasicInfoView(_this.monitoring.id).then(function (monitoring) { return _this.monitoring = monitoring; });
                };
                'ngInject';
                'ngInject';
                this.$state = common.$state;
            }
            MonitoringView.prototype.$onInit = function () {
                this.unsubscribe = this.common.$rootScope.$on('refreshBasicInfo', this.refresh);
            };
            MonitoringView.prototype.showReportDialog = function () {
                var report = {
                    category: 'Monitoring Report',
                    label: 'Monitoring Activities Report',
                    name: 'Monitoring Activities',
                    description: 'Monitoring Activities',
                    permission: 'reports.view',
                    parameters: [
                        { label: 'From Date', name: 'FromDate', dataType: 'Date', value: null },
                        { label: 'To Date', name: 'ToDate', dataType: 'Date', value: null },
                        { label: 'Activity Type - Leave blank for All', name: 'ActivityType', value: '', dataType: 'Select', options: ['', 'Monitoring Description', 'Note', 'Issue', 'Meeting', 'Contact / Visit', 'Case Review', 'Closure Summary'] },
                        { label: 'MonitoringId', name: 'MonitoringId', dataType: 'hidden', value: this.monitoring.id },
                        { label: 'Involvement', name: 'InvolvementId', dataType: 'hidden', value: this.monitoring.involvementId }
                    ]
                };
                return this.$mdDialog.show({
                    fullscreen: true,
                    templateUrl: 'app/reports/report-dialog/report-dialog.html',
                    controller: 'ReportDialogController',
                    controllerAs: '$ctrl',
                    focusOnOpen: false,
                    locals: {
                        report: report
                    }
                })
                    .catch(function (error) { if (error)
                    throw error; })
                    .finally(function () { return angular.noop; });
            };
            MonitoringView.prototype.$onDestroy = function () {
                this.unsubscribe();
            };
            MonitoringView.prototype.showDeleteDialog = function (id) {
                var _this = this;
                this.confirmation.show({
                    message: 'Are you sure you want to delete this Monitoring? This will delete all data attached to this Monitoring.',
                    ok: 'DELETE',
                    cancel: 'KEEP'
                }).then(function () { _this.deleteMonitoring(id); });
            };
            MonitoringView.prototype.deleteMonitoring = function (id) {
                var _this = this;
                this.datacontext.monitoring.deleteMonitoring(id).then(function (result) {
                    if (result) {
                        _this.timerService.killTimer();
                        _this.common.$state.go('app.monitoringList');
                    }
                });
            };
            MonitoringView.$inject = ['$mdDialog', 'common', 'confirmation', 'datacontext', 'timerService'];
            MonitoringView = __decorate([
                app.Component('app.monitoring', 'ceMonitoringView', {
                    templateUrl: 'app/monitoring/monitoring/monitoring-view.html',
                    bindings: {
                        monitoring: '<'
                    }
                })
            ], MonitoringView);
            return MonitoringView;
        }());
    })(monitoring = app.monitoring || (app.monitoring = {}));
})(app || (app = {}));
var app;
(function (app) {
    var monitoring;
    (function (monitoring) {
        var overview;
        (function (overview) {
            var MonitoringOverview = (function () {
                function MonitoringOverview($mdDialog, common, datacontext) {
                    this.$mdDialog = $mdDialog;
                    this.common = common;
                    this.datacontext = datacontext;
                    'ngInject';
                    'ngInject';
                }
                MonitoringOverview.prototype.$onInit = function () {
                };
                MonitoringOverview.$inject = ['$mdDialog', 'common', 'datacontext'];
                MonitoringOverview = __decorate([
                    app.Component('app.monitoring.overview', 'ceMonitoringOverview', {
                        templateUrl: 'app/monitoring/overview/overview.html',
                        bindings: {
                            monitoring: '<'
                        }
                    })
                ], MonitoringOverview);
                return MonitoringOverview;
            }());
        })(overview = monitoring.overview || (monitoring.overview = {}));
    })(monitoring = app.monitoring || (app.monitoring = {}));
})(app || (app = {}));
var app;
(function (app) {
    var monitoring;
    (function (monitoring) {
        var overview;
        (function (overview) {
            var MonitoringSummary = (function () {
                function MonitoringSummary($mdDialog, common, datacontext) {
                    this.$mdDialog = $mdDialog;
                    this.common = common;
                    this.datacontext = datacontext;
                    'ngInject';
                    'ngInject';
                }
                MonitoringSummary.prototype.$onInit = function () {
                    var _this = this;
                    this.datacontext.monitoring.getMonitoringSummaryView(this.monitoring.id)
                        .then(function (result) {
                        var data = result;
                        _this.monitoringSummary = data;
                    }).then(function () { return angular.element('#summarycard').attr('aria-hidden', 'false'); });
                };
                MonitoringSummary.$inject = ['$mdDialog', 'common', 'datacontext'];
                MonitoringSummary = __decorate([
                    app.Component('app.monitoring.overview', 'ceMonitoringSummary', {
                        templateUrl: 'app/monitoring/summary/summary.html',
                        bindings: {
                            monitoring: '<'
                        }
                    })
                ], MonitoringSummary);
                return MonitoringSummary;
            }());
        })(overview = monitoring.overview || (monitoring.overview = {}));
    })(monitoring = app.monitoring || (app.monitoring = {}));
})(app || (app = {}));
var app;
(function (app) {
    var projects;
    (function (projects) {
        var activities;
        (function (activities) {
            var routes = [
                {
                    name: 'app.projects.activities',
                    url: '/activities',
                    template: '<ui-view layout="column" flex></ui-view>',
                    redirectTo: 'app.projects.activities.list'
                },
                {
                    name: 'app.projects.activities.list',
                    url: '',
                    template: "<ce-project-activity-list\n                        project-id=\"$resolve.project.id\"\n                        layout=\"column\" flex>\n                    </ce-project-activity-list>",
                    data: {
                        title: 'Project Activities',
                        permissions: ['access_activities']
                    }
                },
                {
                    name: 'app.projects.activities.note',
                    url: '/note',
                    template: "<ce-projects-activities-note layout=\"column\" flex\n                        project-Id=\"$resolve.project.id\"\n                        activity-Type=\"ProjectNote\"\n                        name=\"Note\"\n                        activity=\"$resolve.activity\"\n                        case-open-date=\"$resolve.project.openDate\"\n                        return-sref=\"app.projects.activities.list\">\n                    </ce-projects-activities-note>",
                    params: { activity: null },
                    resolve: { activity: ['$stateParams', function (p) { return p.activity; }] },
                    data: { title: 'Note' }
                }
            ];
            var runner = function (routingHelper) {
                'ngInject';
                routingHelper.configureRoutes(routes);
            };
            angular
                .module('app.projects.activities')
                .run(runner);
        })(activities = projects.activities || (projects.activities = {}));
    })(projects = app.projects || (app.projects = {}));
})(app || (app = {}));
var app;
(function (app) {
    var projects;
    (function (projects) {
        var ProjectCalendarStateService = (function () {
            function ProjectCalendarStateService($localStorage) {
                var _this = this;
                this.$localStorage = $localStorage;
                this.state = {};
                this.loadState = function () {
                    if (_this.$localStorage['projectCalendarState']) {
                        angular.extend(_this.state, _this.$localStorage['projectCalendarState']);
                    }
                    else {
                        _this.$localStorage['projectCalendarState'] = _this.state;
                    }
                    if (_this.state.dateRange) {
                        _this.state.dateRange = moment.range(_this.state.dateRange.start, _this.state.dateRange.end);
                    }
                };
                'ngInject';
                'ngInject';
                if ($localStorage.calendarState) {
                    delete $localStorage.calendarState.dateRange;
                    delete $localStorage.calendarState.viewType;
                    delete $localStorage.calendarState.viewDate;
                }
                this.loadState();
            }
            ProjectCalendarStateService.prototype.save = function () {
                this.$localStorage['projectCalendarState'] = this.state;
            };
            ProjectCalendarStateService.prototype.update = function (state) {
                angular.extend(this.state, state);
                angular.extend(this.$localStorage['projectCalendarState'], state);
            };
            return ProjectCalendarStateService;
        }());
        angular
            .module('app.projects')
            .service('projectCalendarState', ProjectCalendarStateService);
    })(projects = app.projects || (app.projects = {}));
})(app || (app = {}));
var app;
(function (app) {
    var projects;
    (function (projects) {
        var ProjectCalendar = (function () {
            function ProjectCalendar($scope, $mdPanel, $mdSidenav, common, projectCalendarData, projectCalendarState, $mdDialog) {
                var _this = this;
                this.$scope = $scope;
                this.$mdPanel = $mdPanel;
                this.$mdSidenav = $mdSidenav;
                this.common = common;
                this.projectCalendarData = projectCalendarData;
                this.projectCalendarState = projectCalendarState;
                this.$mdDialog = $mdDialog;
                this.state = this.projectCalendarState.state;
                this.calendarOptions = {
                    aspectRatio: 0,
                    scrollTime: '06:00',
                    editable: false,
                    eventLimit: true,
                    header: false,
                    selectable: false,
                    selectHelper: false,
                    dayClick: function (targetDate, targetEvent, calendarView) {
                        _this.createEvent(targetDate);
                    },
                    eventClick: function (event) {
                        _this.eventClick(event);
                    },
                    views: {
                        highPriority: {
                            type: 'list',
                            duration: { months: 2 },
                            noEventsMessage: 'No High Priority events to display'
                        }
                    },
                    eventRender: function (event, element) { element.attr('aria-label', event.startTime.toLocaleDateString() + ' ' + element[0].innerText); }
                };
                this.refresh = function () {
                    _this.projectCalendarData.refresh(_this.source, _this.sourceId);
                };
                this.onViewRender = function (view) {
                    _this.$calendar = view.calendar;
                    _this.title = view.title;
                    var reload = true;
                    if (_this.state.dateRange) {
                        reload = !(_this.state.dateRange.contains(view.start) && _this.state.dateRange.contains(view.end));
                    }
                    var viewDate = view.start.clone();
                    if (view.type === 'month' && view.start.date() > 1) {
                        viewDate = viewDate.startOf('month').add(1, 'month');
                    }
                    _this.projectCalendarState.update({
                        viewType: view.type,
                        viewDate: viewDate
                    });
                    var start = view.start.local();
                    var end = view.end.local();
                    _this.visibleRange = moment.range(start, end);
                    _this.selectedRange = moment.range(start, start);
                    if (reload) {
                        _this.state.dateRange = _this.visibleRange;
                        _this.common.$timeout(_this.refresh);
                    }
                };
                this.loadState = function () {
                    _this.calendarOptions.defaultView = _this.state.viewType || 'month';
                    _this.calendarOptions.defaultDate = _this.state.viewDate;
                };
                this.setContentHeight = function () {
                    _this.$calendar.option('contentHeight', angular.element('#calendar').height());
                };
                'ngInject';
                'ngInject';
            }
            ProjectCalendar.prototype.createEvent = function (targetDate) {
                this.common.$state.go('^.eventSchedule', {
                    targetDate: targetDate,
                    source: this.source,
                    sourceId: this.sourceId
                });
            };
            ProjectCalendar.prototype.eventClick = function (event) {
                var _this = this;
                var parent = angular.element(document.body);
                return this.$mdDialog.show({
                    parent: parent,
                    fullscreen: false,
                    templateUrl: 'app/calendars/events/summary-dialog.html',
                    controller: 'EventSummaryDialogController',
                    controllerAs: '$ctrl',
                    locals: {
                        event: event,
                        eventScheduleId: event.eventScheduleId
                    }
                })
                    .then(function (edit) {
                    if (edit === 'eventSchedule') {
                        _this.common.$state.go('^.eventSchedule', { eventScheduleId: event.eventScheduleId });
                    }
                    else {
                        _this.common.$state.go('^.event', { event: event });
                    }
                })
                    .catch(function (error) { if (error)
                    throw error; });
            };
            ProjectCalendar.prototype.today = function () {
                this.$calendar.today();
            };
            ProjectCalendar.prototype.next = function () {
                this.$calendar.next();
            };
            ProjectCalendar.prototype.prev = function () {
                this.$calendar.prev();
            };
            ProjectCalendar.prototype.changeView = function (view) {
                this.$calendar.changeView(view);
            };
            ProjectCalendar.prototype.showFilters = function () {
                this.$mdSidenav('calendarFilters').toggle();
            };
            ProjectCalendar.prototype.$onInit = function () {
                this.eventSources = this.projectCalendarData.eventSources;
                this.calendarOptions.viewRender = this.onViewRender;
                this.loadState();
                var cancelToken = this.$scope.$on(app.calendars.EVENT_ADDED, this.refresh);
                this.$scope.$on('$destroy', cancelToken);
                this.common.$timeout(this.setContentHeight, 100);
                angular.element(this.common.$window).bind('resize', this.setContentHeight);
            };
            ProjectCalendar.$inject = ['$scope', '$mdPanel', '$mdSidenav', 'common', 'projectCalendarData', 'projectCalendarState', '$mdDialog'];
            ProjectCalendar = __decorate([
                app.Component('app.projects', 'ceProjectsCalendar', {
                    templateUrl: 'app/projects/calendar/calendar.html',
                    bindings: {
                        source: '@',
                        sourceId: '<'
                    }
                })
            ], ProjectCalendar);
            return ProjectCalendar;
        }());
    })(projects = app.projects || (app.projects = {}));
})(app || (app = {}));
var app;
(function (app) {
    var projects;
    (function (projects) {
        var ProjectCalendarDataService = (function () {
            function ProjectCalendarDataService(common, datacontext, projectCalendarState) {
                var _this = this;
                this.common = common;
                this.datacontext = datacontext;
                this.projectCalendarState = projectCalendarState;
                this.state = this.projectCalendarState.state;
                this.refresh = function (source, sourceId) {
                    var params = {
                        source: source,
                        sourceId: sourceId,
                        from: _this.state.dateRange.start.toDate(),
                        to: _this.state.dateRange.end.toDate(),
                        highPriorityOnly: _this.state.viewType === 'highPriority'
                    };
                    _this.eventSources.splice(0);
                    _this.getEvents(params);
                };
                this.activate = function () {
                    return _this.getCalendars();
                };
                this.getCalendars = function () {
                    return _this.datacontext.calendars.getCalendars().then(function (result) {
                        _this.projectCalendarState.update({ calendars: result });
                    }).finally(function () {
                        var calendarIds = [];
                        angular.forEach(_this.state.calendars, function (c) { return calendarIds.push(c.id); });
                        _this.projectCalendarState.update({ calendarIds: calendarIds });
                    });
                };
                this.getEvents = function (params) {
                    _this.datacontext.projects
                        .getEvents(params)
                        .then(function (result) {
                        if (result) {
                            var results = _(result);
                            var events = results.map(function (e) { return _this.mapEvent(e); }).value();
                            _this.eventSources.push({ events: events });
                        }
                    });
                };
                this.mapEvent = function (e) {
                    var start = moment(e.rescheduledStartTime || e.startTime);
                    var end;
                    if (e.allDay) {
                        start = moment(e.eventDate).startOf('day');
                        end = moment(e.eventDate).endOf('day');
                    }
                    else {
                        end = moment(e.rescheduledEndTime || e.endTime);
                    }
                    var calendar = _.find(_this.state.calendars, function (c) { return c.id === e.calendar; });
                    var color;
                    var isPast = end.diff(moment()) < 0;
                    if (isPast) {
                        color = calendar.completedColor;
                    }
                    else {
                        color = calendar.color;
                    }
                    return __assign(__assign({}, e), { title: "".concat(e.title), color: color, start: start, end: end });
                };
                'ngInject';
                'ngInject';
                this.eventSources = [];
                this.activate();
            }
            return ProjectCalendarDataService;
        }());
        angular
            .module('app.projects')
            .service('projectCalendarData', ProjectCalendarDataService);
    })(projects = app.projects || (app.projects = {}));
})(app || (app = {}));
var app;
(function (app) {
    var projects;
    (function (projects) {
        var CreateProject = (function () {
            function CreateProject(common, datacontext) {
                this.common = common;
                this.datacontext = datacontext;
                this.project = {};
                'ngInject';
                'ngInject';
            }
            CreateProject.prototype.$onInit = function () {
                this.maxDate = moment().toDate();
                this.project.openDate = moment().toDate();
            };
            CreateProject.prototype.submit = function () {
                var _this = this;
                this.isSubmitting = true;
                if (typeof this.project.openDate !== 'string') {
                    this.project.openDate = this.project.openDate.toDateString();
                }
                this.datacontext.projects
                    .createProject(this.project)
                    .then(function (project) {
                    if (project) {
                        _this.common.$mdToast.showSimple('Project created');
                        _this.common.$state.go('app.projects.overview', { projectId: project });
                    }
                })
                    .finally(function () { return _this.isSubmitting = false; });
            };
            CreateProject.$inject = ['common', 'datacontext'];
            CreateProject = __decorate([
                app.Component('app.projects', 'ceCreateProject', {
                    templateUrl: 'app/projects/create-project/create-project.html',
                    bindings: {
                        fundingPrograms: '<'
                    }
                })
            ], CreateProject);
            return CreateProject;
        }());
    })(projects = app.projects || (app.projects = {}));
})(app || (app = {}));
var app;
(function (app) {
    var projects;
    (function (projects) {
        var details;
        (function (details) {
            var ProjectDetails = (function () {
                function ProjectDetails(datacontext) {
                    this.datacontext = datacontext;
                    this.editProject = false;
                    'ngInject';
                    'ngInject';
                }
                ProjectDetails.prototype.$onInit = function () {
                };
                ProjectDetails.prototype.updateProject = function (result) {
                    angular.extend(this.data, result);
                    this.editProject = false;
                };
                ProjectDetails.$inject = ['datacontext'];
                ProjectDetails = __decorate([
                    app.Component('app.projects.details', 'ceProjectDetails', {
                        templateUrl: 'app/projects/details/details.html',
                        bindings: {
                            data: '<',
                            fundingPrograms: '<'
                        }
                    })
                ], ProjectDetails);
                return ProjectDetails;
            }());
        })(details = projects.details || (projects.details = {}));
    })(projects = app.projects || (app.projects = {}));
})(app || (app = {}));
var app;
(function (app) {
    var projects;
    (function (projects) {
        var details;
        (function (details) {
            var ProjectInformationForm = (function () {
                function ProjectInformationForm(common, datacontext) {
                    this.common = common;
                    this.datacontext = datacontext;
                    this.data = {};
                    'ngInject';
                    'ngInject';
                }
                ProjectInformationForm.prototype.$onInit = function () {
                    this.data.openDate = moment().toDate();
                    this.getData();
                };
                ProjectInformationForm.prototype.getData = function () {
                    var _this = this;
                    this.isLoading = true;
                    this.datacontext.projects
                        .getProject(this.projectId)
                        .then(function (result) { return _this.data = result; })
                        .finally(function () { return _this.isLoading = false; });
                };
                ProjectInformationForm.prototype.submit = function () {
                    var _this = this;
                    this.isSubmitting = true;
                    if (typeof this.data.openDate !== 'string') {
                        this.data.openDate = this.data.openDate.toDateString();
                    }
                    if (this.data.closeDate && typeof this.data.closeDate !== 'string') {
                        this.data.closeDate = this.data.closeDate.toDateString();
                    }
                    this.datacontext.projects
                        .updateProject(this.data)
                        .then(function (result) {
                        if (result)
                            _this.onSubmit({ result: result });
                    }).finally(function () { return _this.isSubmitting = false; });
                };
                ProjectInformationForm.$inject = ['common', 'datacontext'];
                ProjectInformationForm = __decorate([
                    app.Component('app.projects.details', 'ceProjectInformationForm', {
                        templateUrl: 'app/projects/details/project-information-form.html',
                        bindings: {
                            projectId: '<',
                            onCancel: '&',
                            onSubmit: '&',
                            fundingPrograms: '<'
                        }
                    })
                ], ProjectInformationForm);
                return ProjectInformationForm;
            }());
        })(details = projects.details || (projects.details = {}));
    })(projects = app.projects || (app.projects = {}));
})(app || (app = {}));
var app;
(function (app) {
    var projects;
    (function (projects) {
        var details;
        (function (details) {
            var ProjectInformation = (function () {
                function ProjectInformation() {
                    'ngInject';
                }
                ProjectInformation = __decorate([
                    app.Component('app.projects.details', 'ceProjectInformation', {
                        templateUrl: 'app/projects/details/project-information.html',
                        bindings: {
                            data: '<',
                            onEdit: '&'
                        }
                    })
                ], ProjectInformation);
                return ProjectInformation;
            }());
        })(details = projects.details || (projects.details = {}));
    })(projects = app.projects || (app.projects = {}));
})(app || (app = {}));
var app;
(function (app) {
    var projects;
    (function (projects) {
        var entities;
        (function (entities) {
            var routes = [
                {
                    name: 'app.projects.entities',
                    url: '/entities',
                    template: '<ui-view flex></ui-view>',
                    redirectTo: 'app.projects.entities.listing',
                    data: { title: 'Project Entities' }
                },
                {
                    name: 'app.projects.entities.listing',
                    url: '/listing',
                    template: '<ce-project-entity-listing project-id="$resolve.project.id" layout="column" flex></ce-project-entity-listing>',
                    data: { title: 'Project Entities' }
                },
                {
                    name: 'app.projects.entities.add',
                    url: '/add',
                    template: '<ce-select-entity-type flex></ce-select-entity-type>',
                    redirectTo: 'app.projects.entities.add.type',
                    data: { title: 'Add' }
                },
                {
                    name: 'app.projects.entities.add.type',
                    url: '/types',
                    template: '<ce-entity-types flex></ce-entity-types>'
                },
                {
                    name: 'app.projects.entities.edit',
                    url: '/edit',
                    params: { entity: null },
                    resolve: { entity: ['$stateParams', function (p) { return p.entity; }] },
                    template: '<ce-edit-project-entity-roles project-id="$resolve.project.id" entity="$resolve.entity" layout="column" flex></ce-edit-project-entity-roles>',
                    data: { title: 'Edit' }
                },
                {
                    name: 'app.projects.entities.add.person',
                    url: '/person',
                    template: '<ui-view layout flex></ui-view>',
                    redirectTo: 'app.projects.entities.add.person.search',
                },
                {
                    name: 'app.projects.entities.add.person.search',
                    url: '/search',
                    template: '<ce-person-search flex></ce-person-search>',
                    data: { title: 'Find Person' }
                },
                {
                    name: 'app.projects.entities.add.person.create',
                    url: '/create',
                    template: '<ce-create-person flex></ce-create-person>',
                    data: { title: 'Create Person' }
                },
                {
                    name: 'app.projects.entities.add.person.roles',
                    url: '/roles',
                    params: { entity: null },
                    resolve: { entity: ['$stateParams', function (p) { return p.entity; }] },
                    template: '<ce-project-entity-roles project-id="$resolve.project.id" entity="$resolve.entity" layout="column" flex></ce-project-entity-roles>',
                    data: { title: 'Select Roles' }
                },
                {
                    name: 'app.projects.entities.add.agency',
                    url: '/agency',
                    template: '<ui-view layout flex></ui-view>',
                    redirectTo: 'app.projects.entities.add.agency.search',
                },
                {
                    name: 'app.projects.entities.add.agency.search',
                    url: '/search',
                    template: '<ce-agency-search flex></ce-agenyc-search>',
                    data: { title: 'Find Agency' }
                },
                {
                    name: 'app.projects.entities.add.agency.create',
                    url: '/create',
                    template: '<ce-create-agency flex></ce-create-agency>',
                    data: { title: 'Create Agency' }
                },
                {
                    name: 'app.projects.entities.add.agency.roles',
                    url: '/roles',
                    params: { entity: null },
                    resolve: { entity: ['$stateParams', function (p) { return p.entity; }] },
                    template: '<ce-project-entity-roles project-id="$resolve.project.id" entity="$resolve.entity" layout="column" flex></ce-project-entity-roles>',
                    data: { title: 'Select Roles' }
                }
            ];
            var runner = function (routingHelper) {
                'ngInject';
                routingHelper.configureRoutes(routes);
            };
            angular
                .module('app.projects.entities')
                .run(runner);
        })(entities = projects.entities || (projects.entities = {}));
    })(projects = app.projects || (app.projects = {}));
})(app || (app = {}));
var app;
(function (app) {
    var projects;
    (function (projects) {
        var overview;
        (function (overview) {
            var ProjectDetails = (function () {
                function ProjectDetails($mdDialog, common, datacontext) {
                    this.$mdDialog = $mdDialog;
                    this.common = common;
                    this.datacontext = datacontext;
                    'ngInject';
                    'ngInject';
                }
                ProjectDetails.prototype.$onInit = function () {
                };
                ProjectDetails.$inject = ['$mdDialog', 'common', 'datacontext'];
                ProjectDetails = __decorate([
                    app.Component('app.projects.overview', 'ceProjectOverview', {
                        templateUrl: 'app/projects/overview/overview.html',
                        bindings: {
                            project: '<'
                        }
                    })
                ], ProjectDetails);
                return ProjectDetails;
            }());
        })(overview = projects.overview || (projects.overview = {}));
    })(projects = app.projects || (app.projects = {}));
})(app || (app = {}));
var app;
(function (app) {
    var projects;
    (function (projects) {
        var ProjectView = (function () {
            function ProjectView(common, confirmation, datacontext, timerService) {
                this.common = common;
                this.confirmation = confirmation;
                this.datacontext = datacontext;
                this.timerService = timerService;
                this.isLoading = false;
                'ngInject';
                'ngInject';
                this.$state = common.$state;
            }
            ProjectView.prototype.$onInit = function () {
            };
            ProjectView.prototype.showDeleteDialog = function (id) {
                var _this = this;
                this.confirmation.show({
                    message: 'Are you sure you want to delete this Project? This will delete all data attached to this Project.',
                    ok: 'DELETE',
                    cancel: 'KEEP'
                }).then(function () { _this.deleteProject(id); });
            };
            ProjectView.prototype.deleteProject = function (id) {
                var _this = this;
                this.datacontext.projects.deleteProject(id).then(function (result) {
                    if (result) {
                        _this.timerService.killTimer();
                        _this.common.$state.go('app.projectList');
                    }
                });
            };
            ProjectView.$inject = ['common', 'confirmation', 'datacontext', 'timerService'];
            ProjectView = __decorate([
                app.Component('app.projects', 'ceProjectView', {
                    templateUrl: 'app/projects/project/project-view.html',
                    bindings: {
                        project: '<'
                    }
                })
            ], ProjectView);
            return ProjectView;
        }());
    })(projects = app.projects || (app.projects = {}));
})(app || (app = {}));
var app;
(function (app) {
    var reports;
    (function (reports) {
        var ReportList = (function () {
            function ReportList($mdDialog, datacontext) {
                this.$mdDialog = $mdDialog;
                this.datacontext = datacontext;
                this.reports = [];
                'ngInject';
                'ngInject';
            }
            ReportList.prototype.$onInit = function () {
                this.getReportListing();
            };
            ReportList.prototype.getReportListing = function () {
                this.reports.push({
                    category: 'Data Audit Reports',
                    label: 'Client Case Closure Data',
                    name: 'ClientCaseClosureMissingDataByPrimaryStaff',
                    description: 'Lists Closed Client Cases with Missing Data',
                    permission: 'reports.viewmanagerial',
                    parameters: [
                        { label: 'From Date', name: 'FromDate', dataType: 'Date', value: null, required: true },
                        { label: 'To Date', name: 'ToDate', dataType: 'Date', value: null, required: true },
                    ]
                }, {
                    category: 'Data Audit Reports',
                    label: 'Client Case Intake Data',
                    name: 'ClientCaseMissingDataByIntakeStaff',
                    description: 'Lists Client Cases with Missing Data',
                    permission: 'reports.viewmanagerial',
                    parameters: []
                }, {
                    category: 'Data Audit Reports',
                    label: 'Client Cases Ready for Closure',
                    name: 'Client Case Ready For Closure',
                    description: 'Lists Client Cases with Ready for Closure Status',
                    permission: 'reports.viewmanagerial',
                    parameters: []
                }, {
                    category: 'Timekeeping Reports',
                    label: 'Employee Leave',
                    name: 'Employee Leave',
                    description: 'Lists all Leave time for a Date Range',
                    permission: 'reports.viewmanagerial',
                    parameters: [
                        { label: 'From Date', name: 'FromDate', dataType: 'Date', value: null, required: true },
                        { label: 'To Date', name: 'ToDate', dataType: 'Date', value: null, required: true },
                        { label: 'Employee Type', name: 'EmployeeType', dataType: 'Select', value: null, options: [null, 'Exempt', 'Non-Exempt'] }
                    ]
                }, {
                    category: 'Timekeeping Reports',
                    label: 'Individual Employee Leave and Overtime',
                    name: 'Employee Leave And Overtime',
                    description: 'Summarizes Leave and Overtime for up to 4 weeks for an Employee',
                    permission: 'reports.view',
                    parameters: [
                        { label: 'UserName', name: 'UserName', dataType: 'TimeslipUserSearch', value: null, required: true },
                        { label: 'UserId', name: 'UserId', dataType: 'hidden', value: null, required: true },
                        { label: 'From Date', name: 'FromDate', dataType: 'Date', value: null, required: true },
                        { label: 'To Date', name: 'ToDate', dataType: 'Date', value: null, required: true },
                    ]
                }, {
                    category: 'Timekeeping Reports',
                    label: 'Employee Timesheet',
                    name: 'Employee Timesheet',
                    description: 'Timesheet for an Employee',
                    permission: 'reports.view',
                    parameters: [
                        { label: 'UserName', name: 'UserName', dataType: 'TimeslipUserSearch', value: null, required: true },
                        { label: 'UserId', name: 'UserId', dataType: 'hidden', value: null, required: true },
                        { label: 'From Date', name: 'FromDate', dataType: 'Date', value: null, required: true },
                        { label: 'To Date', name: 'ToDate', dataType: 'Date', value: null, required: true },
                    ]
                }, {
                    category: 'Timekeeping Reports',
                    label: 'Employee Timesheet By Day',
                    name: 'Employee Timesheet By Day',
                    description: 'Timesheet for an Employee By Day - 16 day maximum',
                    permission: 'reports.view',
                    parameters: [
                        { label: 'UserName', name: 'UserName', dataType: 'TimeslipUserSearch', value: null, required: true },
                        { label: 'UserId', name: 'UserId', dataType: 'hidden', value: null, required: true },
                        { label: 'From Date', name: 'FromDate', dataType: 'Date', value: null, required: true },
                        { label: 'To Date', name: 'ToDate', dataType: 'Date', value: null, required: true },
                    ]
                }, {
                    category: 'Other Reports',
                    label: 'I&R Docket',
                    name: 'IandR Docket',
                    description: 'Lists all I&Rs within a date range',
                    permission: 'reports.view',
                    parameters: [
                        { label: 'From Date', name: 'FromDate', dataType: 'Date', value: moment().startOf('month').format('MM/DD/YYYY'), required: true },
                        { label: 'To Date', name: 'ToDate', dataType: 'Date', value: moment().endOf('month').format('MM/DD/YYYY'), required: true },
                    ]
                }, {
                    category: 'Other Reports',
                    label: 'Closure Summary',
                    name: 'ClosureSummary',
                    description: 'Lists Closure Summaries for Cases',
                    permission: 'reports.view',
                    parameters: [
                        { label: 'From Date', name: 'FromDate', dataType: 'Date', value: moment().startOf('month').format('MM/DD/YYYY'), required: true },
                        { label: 'To Date', name: 'ToDate', dataType: 'Date', value: moment().endOf('month').format('MM/DD/YYYY'), required: true },
                        { label: 'Case Type', name: 'CaseType', dataType: 'Select', value: '', options: ['Client Cases', 'Systemic Cases', 'Monitoring'] },
                        { label: '\'Yes\' for', name: 'FieldName', dataType: 'Select', value: '', options: ['Secured Judicial Processes', 'Self Advocacy', 'Success Story', 'Systemic Impact'] },
                        { label: 'Region', name: 'Region', dataType: 'Lookup', value: '' },
                        { label: 'Legal Team', name: 'LegalTeamId', dataType: 'DictionarySelect' },
                        { label: 'LegalTeamName', name: 'LegalTeamName', dataType: 'hidden', value: null },
                        { label: 'Funding', name: 'FundingProgramId', dataType: 'DictionarySelect' },
                        { label: 'FundingProgramName', name: 'FundingProgramName', dataType: 'hidden', value: null },
                    ]
                }, {
                    category: 'Data Audit Reports',
                    label: 'I&R Data',
                    name: 'IandR Missing Data By Primary Staff',
                    description: 'Lists all I&Rs within a date range that are missing data',
                    permission: 'reports.viewmanagerial',
                    parameters: [
                        { label: 'From Date', name: 'FromDate', dataType: 'Date', value: moment().startOf('month').format('MM/DD/YYYY'), required: true },
                        { label: 'To Date', name: 'ToDate', dataType: 'Date', value: moment().endOf('month').format('MM/DD/YYYY'), required: true },
                    ]
                }, {
                    category: 'Funder Reports',
                    label: 'LTF/CBF',
                    name: 'LTF CBF',
                    description: 'Runs the LTF CBF Report',
                    permission: 'reports.view',
                    parameters: [
                        { label: 'From Date', name: 'FromDate', dataType: 'Date', value: moment().startOf('month').format('MM/DD/YYYY'), required: true },
                        { label: 'To Date', name: 'ToDate', dataType: 'Date', value: moment().endOf('month').format('MM/DD/YYYY'), required: true },
                        { label: 'Case Type', name: 'Type', dataType: 'Select', value: 'Type', options: ['Closed', 'Handled', 'Opened'], required: true },
                        { label: 'Region', name: 'Region', dataType: 'Lookup', value: '' },
                        { label: 'Show Detail', name: 'ShowDetail', dataType: 'Boolean', value: 'False', required: true },
                    ]
                }, {
                    category: 'Funder Reports',
                    label: 'IDHS Monitoring',
                    name: 'Monitoring',
                    description: 'Runs the IDHS Monitoring Report',
                    permission: 'reports.view',
                    parameters: [
                        { label: 'From Date', name: 'FromDate', dataType: 'Date', value: moment().startOf('month').format('MM/DD/YYYY'), required: true },
                        { label: 'To Date', name: 'ToDate', dataType: 'Date', value: moment().endOf('month').format('MM/DD/YYYY'), required: true }
                    ]
                }, {
                    category: 'Data Audit Reports',
                    label: 'Missing Adverse Party',
                    name: 'Missing Adverse Party',
                    description: 'Lists all active cases missing an Adverse Party',
                    permission: 'reports.viewmanagerial',
                    parameters: []
                }, {
                    category: 'Data Audit Reports',
                    label: 'Systemic Case Closure Data',
                    name: 'Systemic Case Closure Missing Data By Primary Staff',
                    description: 'Lists Closed Systemic Cases with Missing Data',
                    permission: 'reports.viewmanagerial',
                    parameters: [
                        { label: 'From Date', name: 'FromDate', dataType: 'Date', value: null, required: true },
                        { label: 'To Date', name: 'ToDate', dataType: 'Date', value: null, required: true },
                    ]
                }, {
                    category: 'Data Audit Reports',
                    label: 'Systemic Case Data',
                    name: 'Systemic Case Missing Data By Primary Staff',
                    description: 'Lists Systemic Cases with Missing Data',
                    permission: 'reports.viewmanagerial',
                    parameters: []
                }, {
                    category: 'Timekeeping Reports',
                    label: 'Timeslips by Employee',
                    name: 'Timeslips By Employee',
                    description: 'Lists all Timeslips grouped by Employee then by Funding Source',
                    permission: 'reports.viewmanagerial',
                    parameters: [
                        { label: 'From Date', name: 'FromDate', dataType: 'Date', value: moment().startOf('month').format('MM/DD/YYYY'), required: true },
                        { label: 'To Date', name: 'ToDate', dataType: 'Date', value: moment().endOf('month').format('MM/DD/YYYY'), required: true },
                        { label: 'Include Leave', name: 'IncludeLeave', dataType: 'Boolean', value: 'True', required: true },
                        { label: 'Include Volunteer', name: 'IncludeVolunteer', dataType: 'Boolean', value: 'False', required: true },
                        { label: 'Sort By', name: 'SortBy', dataType: 'Select', value: 'Hours', options: ['Employee then Hours', 'Employee then Program'] }
                    ]
                }, {
                    category: 'Timekeeping Reports',
                    label: 'Timeslips By Employee By Category',
                    name: 'Timeslips By Employee By Category',
                    description: 'Lists all Timeslips grouped by Employee then by Category',
                    permission: 'reports.viewmanagerial',
                    parameters: [
                        { label: 'From Date', name: 'FromDate', dataType: 'Date', value: moment().startOf('month').format('MM/DD/YYYY'), required: true },
                        { label: 'To Date', name: 'ToDate', dataType: 'Date', value: moment().endOf('month').format('MM/DD/YYYY'), required: true },
                        { label: 'Include Leave', name: 'IncludeLeave', dataType: 'Boolean', value: 'True', required: true },
                        { label: 'Include Volunteer', name: 'IncludeVolunteer', dataType: 'Boolean', value: 'False', required: true }
                    ]
                }, {
                    category: 'Timekeeping Reports',
                    label: 'Timeslips By Employee By Funding Program',
                    name: 'Timeslips By Employee By Funding Program',
                    description: 'Lists all Timeslips grouped by Employee then by Funding Program - Choose Report Format of Excel',
                    permission: 'reports.viewmanagerial',
                    parameters: [
                        { label: 'From Date', name: 'FromDate', dataType: 'Date', value: null, required: true },
                        { label: 'To Date', name: 'ToDate', dataType: 'Date', value: null, required: true },
                    ]
                }, {
                    category: 'Timekeeping Reports',
                    label: 'Timeslips By Source',
                    name: 'Timeslips By Source',
                    description: 'Timeslips for a Case, Project, or Training',
                    permission: 'reports.view',
                    parameters: [
                        { label: 'SourceType', name: 'SourceType', dataType: 'SourceSearch', value: null, required: true },
                        { label: 'SourceId', name: 'SourceId', dataType: 'hidden', value: null, required: true },
                        { label: 'SourceName', name: 'SourceName', dataType: 'hidden', value: null, required: true },
                        { label: 'From Date', name: 'FromDate', dataType: 'Date', value: null, required: false },
                        { label: 'To Date', name: 'ToDate', dataType: 'Date', value: null, required: false },
                        { label: 'Include Volunteer', name: 'IncludeVolunteer', dataType: 'Boolean', value: 'False', required: true }
                    ]
                }, {
                    category: 'Data Audit Reports',
                    label: 'Training Data',
                    name: 'Training Missing Data By Primary Trainer',
                    description: 'Lists all Trainings within a date range that are missing data',
                    permission: 'reports.viewmanagerial',
                    parameters: [
                        { label: 'From Date', name: 'FromDate', dataType: 'Date', value: moment().startOf('month').format('MM/DD/YYYY'), required: true },
                        { label: 'To Date', name: 'ToDate', dataType: 'Date', value: moment().endOf('month').format('MM/DD/YYYY'), required: true },
                    ]
                }, {
                    category: 'Other Reports',
                    label: 'Weekly CAM Report',
                    name: 'Weekly CAM Report',
                    description: 'Lists Information for Weekly CAM Report',
                    permission: 'reports.view',
                    parameters: [
                        { label: 'Legal Team', name: 'LegalTeamId', dataType: 'DictionarySelect', required: true }
                    ]
                }, {
                    category: 'Funder Reports',
                    label: 'PAIR PPR',
                    name: 'FY2019 PAIR',
                    description: 'PAIR Program Performance Report',
                    permission: 'reports.view',
                    parameters: [
                        { label: 'From Date', name: 'FromDate', dataType: 'Date', value: null, required: false },
                        { label: 'To Date', name: 'ToDate', dataType: 'Date', value: null, required: false },
                    ]
                }, {
                    category: 'Funder Reports',
                    label: 'PAIMI PPR',
                    name: 'FY2019 PAIMI',
                    description: 'PAIMI Program Performance Report',
                    permission: 'reports.view',
                    parameters: [
                        { label: 'From Date', name: 'FromDate', dataType: 'Date', value: null, required: false },
                        { label: 'To Date', name: 'ToDate', dataType: 'Date', value: null, required: false },
                    ]
                }, {
                    category: 'Funder Reports',
                    label: 'PABSS PPR',
                    name: 'FY2019 PABSS',
                    description: 'PABSS Program Performance Report',
                    permission: 'reports.view',
                    parameters: [
                        { label: 'From Date', name: 'FromDate', dataType: 'Date', value: null, required: false },
                        { label: 'To Date', name: 'ToDate', dataType: 'Date', value: null, required: false },
                    ]
                }, {
                    category: 'Funder Reports',
                    label: 'CAP PPR - Individuals',
                    name: 'FY2019CAPIndividual',
                    description: 'CAP Program Performance Report',
                    permission: 'reports.view',
                    parameters: [
                        { label: 'From Date', name: 'FromDate', dataType: 'Date', value: null, required: false },
                        { label: 'To Date', name: 'ToDate', dataType: 'Date', value: null, required: false },
                    ]
                }, {
                    category: 'Funder Reports',
                    label: 'CAP PPR - Groups',
                    name: 'FY2019CAPGroups',
                    description: 'CAP Program Performance Report',
                    permission: 'reports.view',
                    parameters: [
                        { label: 'From Date', name: 'FromDate', dataType: 'Date', value: null, required: false },
                        { label: 'To Date', name: 'ToDate', dataType: 'Date', value: null, required: false },
                    ]
                }, {
                    category: 'Funder Reports',
                    label: 'PAAT PPR - Individuals',
                    name: 'FY2019ONEPAATIndividual',
                    description: 'PAAT ONE Program Performance Report - Individuals',
                    permission: 'reports.view',
                    parameters: [
                        { label: 'From Date', name: 'FromDate', dataType: 'Date', value: null, required: false },
                        { label: 'To Date', name: 'ToDate', dataType: 'Date', value: null, required: false },
                    ]
                }, {
                    category: 'Funder Reports',
                    label: 'PAAT PPR - Groups',
                    name: 'FY2019ONEPAATGroup',
                    description: 'PAAT ONE Program Performance Report - Groups',
                    permission: 'reports.view',
                    parameters: [
                        { label: 'From Date', name: 'FromDate', dataType: 'Date', value: null, required: false },
                        { label: 'To Date', name: 'ToDate', dataType: 'Date', value: null, required: false },
                    ]
                }, {
                    category: 'Funder Reports',
                    label: 'PAAT PPR - Results',
                    name: 'FY2019ONEPAATResults',
                    description: 'PAAT ONE Program Performance Report - Results',
                    permission: 'reports.view',
                    parameters: [
                        { label: 'From Date', name: 'FromDate', dataType: 'Date', value: null, required: false },
                        { label: 'To Date', name: 'ToDate', dataType: 'Date', value: null, required: false },
                    ]
                }, {
                    category: 'Funder Reports',
                    label: 'PADD PPR - Individuals',
                    name: 'FY2019ONEPADDIndividual',
                    description: 'PADD ONE Program Performance Report - Individuals',
                    permission: 'reports.view',
                    parameters: [
                        { label: 'From Date', name: 'FromDate', dataType: 'Date', value: null, required: false },
                        { label: 'To Date', name: 'ToDate', dataType: 'Date', value: null, required: false },
                    ]
                }, {
                    category: 'Funder Reports',
                    label: 'PADD PPR - Groups',
                    name: 'FY2019ONEPADDGroup',
                    description: 'PADD ONE Program Performance Report - Groups',
                    permission: 'reports.view',
                    parameters: [
                        { label: 'From Date', name: 'FromDate', dataType: 'Date', value: null, required: false },
                        { label: 'To Date', name: 'ToDate', dataType: 'Date', value: null, required: false },
                    ]
                }, {
                    category: 'Funder Reports',
                    label: 'PADD PPR - Results',
                    name: 'FY2019ONEPADDResults',
                    description: 'PADD ONE Program Performance Report - Results',
                    permission: 'reports.view',
                    parameters: [
                        { label: 'From Date', name: 'FromDate', dataType: 'Date', value: null, required: false },
                        { label: 'To Date', name: 'ToDate', dataType: 'Date', value: null, required: false },
                    ]
                }, {
                    category: 'Funder Reports',
                    label: 'PATBI PPR - Individuals',
                    name: 'FY2019ONEPATBIIndividual',
                    description: 'PATBI ONE Program Performance Report - Individuals',
                    permission: 'reports.view',
                    parameters: [
                        { label: 'From Date', name: 'FromDate', dataType: 'Date', value: null, required: false },
                        { label: 'To Date', name: 'ToDate', dataType: 'Date', value: null, required: false },
                    ]
                }, {
                    category: 'Funder Reports',
                    label: 'PATBI PPR - Groups',
                    name: 'FY2019ONEPATBIGroup',
                    description: 'PATBI ONE Program Performance Report - Groups',
                    permission: 'reports.view',
                    parameters: [
                        { label: 'From Date', name: 'FromDate', dataType: 'Date', value: null, required: false },
                        { label: 'To Date', name: 'ToDate', dataType: 'Date', value: null, required: false },
                    ]
                }, {
                    category: 'Funder Reports',
                    label: 'PATBI PPR - Results',
                    name: 'FY2019ONEPATBIResults',
                    description: 'PATBI ONE Program Performance Report - Results',
                    permission: 'reports.view',
                    parameters: [
                        { label: 'From Date', name: 'FromDate', dataType: 'Date', value: null, required: false },
                        { label: 'To Date', name: 'ToDate', dataType: 'Date', value: null, required: false },
                    ]
                }, {
                    category: 'Funder Reports',
                    label: 'PAVA PPR - Individuals',
                    name: 'FY2019ONEPAVAIndividual',
                    description: 'PAVA ONE Program Performance Report - Individuals',
                    permission: 'reports.view',
                    parameters: [
                        { label: 'From Date', name: 'FromDate', dataType: 'Date', value: null, required: false },
                        { label: 'To Date', name: 'ToDate', dataType: 'Date', value: null, required: false },
                    ]
                }, {
                    category: 'Funder Reports',
                    label: 'PAVA PPR - Groups',
                    name: 'FY2019ONEPAVAGroup',
                    description: 'PAVA ONE Program Performance Report - Groups',
                    permission: 'reports.view',
                    parameters: [
                        { label: 'From Date', name: 'FromDate', dataType: 'Date', value: null, required: false },
                        { label: 'To Date', name: 'ToDate', dataType: 'Date', value: null, required: false },
                    ]
                }, {
                    category: 'Funder Reports',
                    label: 'PAVA PPR - Results',
                    name: 'FY2019ONEPAVAResults',
                    description: 'PAVA ONE Program Performance Report - Results',
                    permission: 'reports.view',
                    parameters: [
                        { label: 'From Date', name: 'FromDate', dataType: 'Date', value: null, required: false },
                        { label: 'To Date', name: 'ToDate', dataType: 'Date', value: null, required: false },
                    ]
                }, {
                    category: 'Other Reports',
                    label: 'Statutes of Limitations',
                    name: 'StatutesOfLimitations',
                    description: 'Lists Statutes of Limitations',
                    permission: 'reports.view',
                    parameters: [
                        { label: 'From Date', name: 'FromDate', dataType: 'Date', value: null, required: true },
                        { label: 'To Date', name: 'ToDate', dataType: 'Date', value: null, required: true },
                        { label: 'UserName', name: 'UserName', dataType: 'UserSearch', value: null },
                        { label: 'UserId', name: 'UserId', dataType: 'hidden', value: null },
                        { label: 'Legal Team', name: 'LegalTeamId', dataType: 'DictionarySelect' },
                        { label: 'LegalTeamName', name: 'LegalTeamName', dataType: 'hidden', value: null },
                    ]
                }, {
                    category: 'Other Reports',
                    label: 'Race Counts',
                    name: 'RaceCounts',
                    description: 'Runs the Race Count Report',
                    permission: 'reports.view',
                    parameters: [
                        { label: 'From Date', name: 'FromDate', dataType: 'Date', value: moment().startOf('month').format('MM/DD/YYYY'), required: true },
                        { label: 'To Date', name: 'ToDate', dataType: 'Date', value: moment().endOf('month').format('MM/DD/YYYY'), required: true },
                        { label: 'Case Type', name: 'Type', dataType: 'Select', value: 'Type', options: ['Closed', 'Handled', 'Opened'], required: true },
                        { label: 'Legal Team', name: 'LegalTeamId', dataType: 'DictionarySelect' },
                        { label: 'LegalTeamName', name: 'LegalTeamName', dataType: 'hidden', value: null }
                    ]
                }, {
                    category: 'Other Reports',
                    label: 'Case Review',
                    name: 'CaseReview',
                    description: 'Lists Information for Case Reviews',
                    permission: 'reports.view',
                    parameters: [
                        { label: 'Opened From', name: 'OpenFrom', dataType: 'Date', value: moment().startOf('month').format('MM/DD/YYYY'), required: true },
                        { label: 'Opened To', name: 'OpenTo', dataType: 'Date', value: moment().endOf('month').format('MM/DD/YYYY'), required: true },
                        { label: 'Reviewed From', name: 'ReviewedFrom', dataType: 'Date', value: null, required: false },
                        { label: 'Reviewed To', name: 'ReviewedTo', dataType: 'Date', value: null, required: false },
                        { label: 'Case Type', name: 'CaseType', dataType: 'Select', value: '', options: ['Client Cases', 'Systemic Cases', 'Monitoring'], required: false },
                        { label: 'LegalTeamName', name: 'LegalTeamName', dataType: 'hidden', value: null },
                        { label: 'Legal Team', name: 'LegalTeamId', dataType: 'DictionarySelect', required: false },
                        { label: 'UserName', name: 'UserName', dataType: 'UserSearch', value: null },
                        { label: 'UserId', name: 'UserId', dataType: 'hidden', value: null },
                    ]
                }, {
                    category: 'Other Reports',
                    label: 'Representation',
                    name: 'Representation',
                    description: 'Runs the Representation Report',
                    permission: 'reports.view',
                    parameters: [
                        { label: 'From Date', name: 'FromDate', dataType: 'Date', value: moment().startOf('month').format('MM/DD/YYYY'), required: true },
                        { label: 'To Date', name: 'ToDate', dataType: 'Date', value: moment().endOf('month').format('MM/DD/YYYY'), required: true },
                    ]
                }, {
                    category: 'Other Reports',
                    label: 'At Poverty Level',
                    name: 'AtPovertyLevel',
                    description: 'Lists all clients at Poverty Level',
                    permission: 'reports.view',
                    parameters: [
                        { label: 'From Date', name: 'FromDate', dataType: 'Date', value: moment().startOf('month').format('MM/DD/YYYY'), required: true },
                        { label: 'To Date', name: 'ToDate', dataType: 'Date', value: moment().endOf('month').format('MM/DD/YYYY'), required: true },
                    ]
                }, {
                    category: 'Other Reports',
                    label: 'Diversity',
                    name: 'Diversity',
                    description: 'Lists all race and ethnicity percentages for all cases',
                    permission: 'reports.view',
                    parameters: [
                        { label: 'From Date', name: 'FromDate', dataType: 'Date', value: moment().startOf('month').format('MM/DD/YYYY'), required: true },
                        { label: 'To Date', name: 'ToDate', dataType: 'Date', value: moment().endOf('month').format('MM/DD/YYYY'), required: true },
                        { label: 'Case Type', name: 'CaseType', dataType: 'Select', value: 'All', options: ['All', 'Client Case', 'I&R Case', 'Request'], required: true },
                    ]
                });
                this.categorizeReports();
            };
            ReportList.prototype.categorizeReports = function () {
                this.categorizedReports = _.chain(this.reports)
                    .sortBy(function (r) { return r.category.replace('Other', 'z'); })
                    .groupBy('category')
                    .value();
            };
            ReportList.prototype.showReportDialog = function (report) {
                report.parameters = this.processParameters(report);
                return this.$mdDialog.show({
                    fullscreen: true,
                    templateUrl: 'app/reports/report-dialog/report-dialog.html',
                    controller: 'ReportDialogController',
                    controllerAs: '$ctrl',
                    focusOnOpen: false,
                    locals: {
                        report: report
                    }
                })
                    .catch(function (error) { if (error)
                    throw error; })
                    .finally(function () { return angular.noop; });
            };
            ReportList.prototype.processParameters = function (report) {
                var _this = this;
                var parameters = report.parameters;
                _.forEach(parameters, function (p) {
                    switch (p.name) {
                        case 'LegalTeamId': {
                            var dictionaryOptions_1 = {};
                            _this.getLegalTeams().then(function (legalTeams) {
                                _.forEach(legalTeams, function (lt) { return dictionaryOptions_1[lt.id] = lt.name; });
                            });
                            p.dictionaryOptions = dictionaryOptions_1;
                            break;
                        }
                        case 'FundingProgramId': {
                            var dictionaryOptions_2 = {};
                            _this.getFundingPrograms().then(function (programs) {
                                _.forEach(programs, function (p) { return dictionaryOptions_2[p.fundingProgramId] = p.name; });
                            });
                            p.dictionaryOptions = dictionaryOptions_2;
                            break;
                        }
                    }
                });
                return parameters;
            };
            ReportList.prototype.getLegalTeams = function () {
                return this.datacontext.config.legalTeams.getLegalTeams(false);
            };
            ReportList.prototype.getFundingPrograms = function () {
                return this.datacontext.funding.getFundingPrograms(true, 'Systemic Case');
            };
            ReportList.$inject = ['$mdDialog', 'datacontext'];
            ReportList = __decorate([
                app.Component('app.reports', 'ceReportListing', {
                    templateUrl: 'app/reports/listing/report-list.html',
                    bindings: {}
                })
            ], ReportList);
            return ReportList;
        }());
    })(reports = app.reports || (app.reports = {}));
})(app || (app = {}));
var app;
(function (app) {
    var reports;
    (function (reports) {
        var ReportDialogController = (function () {
            function ReportDialogController(blobHelper, $mdDialog, common, datacontext, report) {
                this.blobHelper = blobHelper;
                this.$mdDialog = $mdDialog;
                this.common = common;
                this.datacontext = datacontext;
                this.report = report;
                'ngInject';
                'ngInject';
            }
            ReportDialogController.prototype.$onInit = function () {
            };
            ReportDialogController.prototype.sourceSearch = function (searchText) {
                return this.datacontext.time
                    .getSources(searchText)
                    .then(function (results) { return _.map(results, function (r) {
                    if (!r) {
                        return;
                    }
                    return r;
                }); });
            };
            ReportDialogController.prototype.setSource = function (source) {
                this.selectedSource = source;
                var sourceTypeParam = _.find(this.report.parameters, function (p) { return p.name === 'SourceType'; });
                sourceTypeParam.value = source.source;
                var sourceIdParam = _.find(this.report.parameters, function (p) { return p.name === 'SourceId'; });
                sourceIdParam.value = source.sourceId;
                var sourceNameParam = _.find(this.report.parameters, function (p) { return p.name === 'SourceName'; });
                sourceNameParam.value = source.sourceDescription;
            };
            ReportDialogController.prototype.timeslipUserSearch = function (searchText) {
                return this.datacontext.users
                    .getTimeslipUsers(searchText);
            };
            ReportDialogController.prototype.userSearch = function (searchText) {
                return this.datacontext.users
                    .getUsers(searchText);
            };
            ReportDialogController.prototype.setUser = function (user) {
                this.selectedUser = user;
                var userIdParam = _.find(this.report.parameters, function (p) { return p.name === 'UserId'; });
                userIdParam.value = user.id;
                var userNameParam = _.find(this.report.parameters, function (p) { return p.name === 'UserName'; });
                userNameParam.value = user.fullName;
            };
            ReportDialogController.prototype.cancel = function () {
                this.$mdDialog.cancel();
            };
            ReportDialogController.prototype.submit = function () {
                var _this = this;
                var contentType;
                this.processParameters();
                this.isSubmitting = true;
                var data = {
                    name: this.report.name.replace(/ /g, ''),
                    format: this.report.format,
                    parameters: this.report.parameters
                };
                switch (this.report.format) {
                    case ('xlsx'): {
                        contentType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
                        break;
                    }
                    case ('docx'): {
                        contentType = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
                        break;
                    }
                    default: {
                        contentType = 'application/pdf';
                        break;
                    }
                }
                this.datacontext.reports
                    .runReport(data)
                    .then(function (buffer) {
                    if (buffer) {
                        _this.blobHelper.open({
                            buffer: buffer,
                            contentType: contentType,
                            fileName: "".concat(_this.report.name, ".").concat(_this.report.format)
                        });
                    }
                }).then(function () { return _this.$mdDialog.hide(); });
            };
            ReportDialogController.prototype.processParameters = function () {
                var _this = this;
                _.forEach(this.report.parameters, function (p) {
                    if (p.dataType === 'DictionarySelect') {
                        var name_1 = p.name.replace('Id', 'Name');
                        var nameParameter = _.find(_this.report.parameters, function (p) { return p.name === name_1; });
                        if (!nameParameter) {
                            _this.report.parameters.push({
                                name: p.name.replace('Id', 'Name'),
                                value: p.dictionaryOptions[p.value]
                            });
                        }
                        else {
                            nameParameter.value = p.dictionaryOptions[p.value];
                        }
                    }
                });
            };
            ReportDialogController.$inject = ['blobHelper', '$mdDialog', 'common', 'datacontext', 'report'];
            ReportDialogController = __decorate([
                app.Controller('app.reports', 'ReportDialogController')
            ], ReportDialogController);
            return ReportDialogController;
        }());
    })(reports = app.reports || (app.reports = {}));
})(app || (app = {}));
var app;
(function (app) {
    var requests;
    (function (requests) {
        var activities;
        (function (activities) {
            var routes = [
                {
                    name: 'app.requests.activities',
                    url: '/activities',
                    template: '<ui-view layout flex></ui-view>',
                    redirectTo: 'app.requests.activities.list'
                },
                {
                    name: 'app.requests.activities.list',
                    url: '',
                    template: '<ce-request-activity-list request="$resolve.request" layout="column" flex></ce-request-activity-list>',
                    data: {
                        title: 'Request Activities',
                        permissions: ['access_activities']
                    },
                },
                {
                    name: 'app.requests.activities.note',
                    url: '/note',
                    template: "<ce-requests-activities-note layout=\"column\" flex\n                        involvement-id=\"$resolve.request.involvementId\"\n                        request-Id=\"$resolve.request.id\"\n                        activity-Type=\"RequestNote\"\n                        name=\"Note\"\n                        activity=\"$resolve.activity\"\n                        case-open-date=\"$resolve.request.requestedAt\"\n                        return-sref=\"app.requests.activities.list\">\n                    </ce-requests-activities-note>",
                    params: { activity: null },
                    resolve: { activity: ['$stateParams', function (p) { return p.activity; }] },
                    data: { title: 'Note' }
                },
                {
                    name: 'app.requests.activities.followUp',
                    url: '/follow-up',
                    template: "<ce-requests-activities-follow-up layout=\"column\" flex\n                        involvement-id=\"$resolve.request.involvementId\"\n                        request-Id=\"$resolve.request.id\"\n                        activity=\"$resolve.activity\"\n                        case-open-date=\"$resolve.request.requestedAt\">\n                    </ce-requests-activities-follow-up>",
                    params: { activity: null },
                    resolve: { activity: ['$stateParams', function (p) { return p.activity; }] },
                    data: { title: 'Follow-Up' }
                },
                {
                    name: 'app.requests.activities.review',
                    url: '/review',
                    template: "<ce-requests-activities-review layout=\"column\" flex\n                       involvement-id=\"$resolve.request.involvementId\"\n                       request-Id=\"$resolve.request.id\"\n                       activity=\"$resolve.activity\"\n                       case-open-date=\"$resolve.request.requestedAt\">\n                    </ce-requests-activities-review>",
                    params: { activity: null },
                    resolve: { activity: ['$stateParams', function (p) { return p.activity; }] },
                    data: { title: 'Review' }
                },
                {
                    name: 'app.requests.activities.conflictCheck',
                    url: '/conflictCheck',
                    template: "<ce-conflict-check layout=\"column\" flex\n                     involvement-id=\"$resolve.request.involvementId\"\n                     source=\"Request\"\n                     source-Id=\"$resolve.request.id\"\n                     case-open-date=\"$resolve.request.requestedAt\">\n                    </ce-conflict-check>",
                    data: { title: 'Conflict Check' }
                },
                {
                    name: 'app.requests.activities.close',
                    url: '/close',
                    template: "<ce-requests-activities-close layout=\"column\" flex\n                        request=\"$resolve.request\">\n                     </ce-requests-activities-close>",
                    data: { title: 'Close' }
                },
                {
                    name: 'app.requests.activities.reopen',
                    url: '/reopen',
                    template: "<ce-requests-activities-reopen layout=\"column\" flex\n                        request=\"$resolve.request\">\n                     </ce-requests-activities-reopen>",
                    data: { title: 'Reopen' }
                },
                {
                    name: 'app.requests.activities.createClientCase',
                    url: '/createclientcase',
                    template: "<ce-create-client-case layout=\"column\" flex\n                        involvement-id=\"$resolve.request.involvementId\"\n                        request-id=\"$resolve.request.id\"\n                        case-open-date=\"$resolve.request.requestedAt\">\n                    </ce-create-client-case>",
                    data: {
                        title: 'Create Client Case',
                        permissions: ['client-cases.edit']
                    }
                },
                {
                    name: 'app.requests.activities.createInformationAndReferral',
                    url: '/createInformationAndReferral',
                    template: "<ce-create-information-and-referral layout=\"column\" flex\n                        involvement-id=\"$resolve.request.involvementId\"\n                        request-id=\"$resolve.request.id\"\n                        case-open-date=\"$resolve.request.requestedAt\">\n                    </ce-create-information-and-referral>",
                    data: {
                        title: 'Create I&R',
                        permissions: ['i-and-rs.edit']
                    }
                },
                {
                    name: 'app.requests.activities.createSystemicCase',
                    url: '/createSystemicCase',
                    template: "<ce-create-systemic-case layout=\"column\" flex\n                        involvement-id=\"$resolve.request.involvementId\"\n                        request-id=\"$resolve.request.id\"\n                        case-open-date=\"$resolve.request.requestedAt\">\n                    </ce-create-systemic-case>",
                    data: {
                        title: 'Create Systemic Case',
                        permissions: ['systemic-cases.edit']
                    }
                },
                {
                    name: 'app.requests.activities.caseAssessmentMeeting',
                    url: '/cam',
                    template: "<ce-request-case-assessment-meeting\n                     involvement-id=\"$resolve.request.involvementId\"\n                     source=\"Request\"\n                     source-id=\"$resolve.request.id\"\n                     show-communication-date=\"true\"\n                     return-sref=\"app.requests.activities.list\"\n                     activity=\"$resolve.activity\"\n                     case-open-date=\"$resolve.request.requestedAt\"\n                     flex>\n                    </ce-request-assessment-meeting>",
                    params: { activity: null },
                    resolve: { activity: ['$stateParams', function (p) { return p.activity; }] },
                    data: { title: 'CAM' }
                },
                {
                    name: 'app.requests.activities.statusChange',
                    url: '/statusChange',
                    template: "<am-activities-status-change layout=\"column\" flex\n                        [involvement-id]=\"$resolve.request.involvementId\"\n                        [source-id]=\"$resolve.request.id\"\n                        source=\"requests\"\n                        [activity-id]=\"$resolve.activityId\">\n                    </am-activities-status-change>",
                    params: { activityId: null },
                    resolve: { activityId: ['$stateParams', function (p) { return p.activityId; }] },
                    data: {
                        title: 'Status Change',
                        permissions: ['requests.edit']
                    }
                }
            ];
            var runner = function (routingHelper) {
                'ngInject';
                routingHelper.configureRoutes(routes);
            };
            angular
                .module('app.requests.activities')
                .run(runner);
        })(activities = requests.activities || (requests.activities = {}));
    })(requests = app.requests || (app.requests = {}));
})(app || (app = {}));
var app;
(function (app) {
    var requests;
    (function (requests) {
        var CreateRequest = (function () {
            function CreateRequest(common, datacontext) {
                var _this = this;
                this.common = common;
                this.datacontext = datacontext;
                this.selectedRoles = [];
                this.isSubmitting = false;
                this.request = {};
                this.getRoles = function () {
                    var lookupTypeId = "involvement-".concat(_this.entityType, "-role");
                    return _this.datacontext.config.lookups.getLookupType(lookupTypeId).then(function (result) {
                        _this.roles = result.lookups;
                    });
                };
                'ngInject';
                'ngInject';
            }
            CreateRequest.prototype.$onInit = function () {
                this.maxDate = moment().toDate();
                this.request.requestedAt = moment().seconds(0).milliseconds(0).toDate();
                this.request.requestMethod = 'Voicemail';
                angular.element(document).ready(function () {
                    angular.element(document).find('#date')['0'].children[0].children[0];
                });
                this.getLegalTeams();
                if (this.entity) {
                    this.getRoles();
                }
            };
            CreateRequest.prototype.getLegalTeams = function () {
                var _this = this;
                this.datacontext.config.legalTeams
                    .getLegalTeams(true)
                    .then(function (result) { return _this.legalTeams = result; });
            };
            CreateRequest.prototype.transformChip = function (chip) {
                if (angular.isObject(chip)) {
                    return chip;
                }
                return { name: chip, type: 'new' };
            };
            CreateRequest.prototype.querySearch = function (query) {
                var results = query ? _.filter(this.roles, function (r) {
                    return query === '*' || r.value.toLowerCase().indexOf(query) > -1;
                }) : [];
                return results;
            };
            CreateRequest.prototype.dateKeypress = function (event) {
                if (event.keyCode === 32) {
                }
            };
            CreateRequest.prototype.submit = function () {
                var _this = this;
                this.isSubmitting = true;
                this.datacontext.requests
                    .createRequest(this.request)
                    .then(function (id) {
                    if (id) {
                        if (_this.entity) {
                            var roles = _.map(_this.selectedRoles, 'value');
                            var data_3 = {
                                entityId: _this.entity.id,
                                involvementId: id,
                                roles: roles
                            };
                            _this.datacontext.involvements
                                .createEntity(data_3)
                                .then(function () {
                                _this.common.$mdToast.showSimple('Request created');
                                _this.common.$state.go('app.requests', { requestId: id });
                            });
                        }
                        else {
                            _this.common.$mdToast.showSimple('Request created');
                            _this.common.$state.go('app.requests', { requestId: id });
                        }
                    }
                })
                    .finally(function () { return _this.isSubmitting = false; });
            };
            CreateRequest.$inject = ['common', 'datacontext'];
            CreateRequest = __decorate([
                app.Component('app.requests', 'ceRequestsCreateRequest', {
                    templateUrl: 'app/requests/create-request/create-request.html',
                    bindings: {
                        entity: '<',
                        entityType: '@'
                    }
                })
            ], CreateRequest);
            return CreateRequest;
        }());
    })(requests = app.requests || (app.requests = {}));
})(app || (app = {}));
var app;
(function (app) {
    var requests;
    (function (requests) {
        var details;
        (function (details) {
            var RequestDetails = (function () {
                function RequestDetails($mdDialog, common, datacontext) {
                    this.$mdDialog = $mdDialog;
                    this.common = common;
                    this.datacontext = datacontext;
                    this.editRequestInformation = false;
                    this.editPWDInformation = false;
                    this.editCallerInformation = false;
                    'ngInject';
                    'ngInject';
                }
                RequestDetails.prototype.$onInit = function () {
                    var _this = this;
                    var promise = null;
                    promise = this.getRequest();
                    promise.then(function () {
                        _this.GetRequestInformation();
                        _this.GetPWDInformation();
                        _this.GetCallerInformation();
                    });
                };
                RequestDetails.prototype.getRequest = function () {
                    var _this = this;
                    return this.datacontext.requests.getRequestBasicInfo(this.request.id)
                        .then(function (request) { return _this.request = request; });
                };
                RequestDetails.prototype.updateRequestInformation = function (result) {
                    this.common.$rootScope.$broadcast('refreshBasicInfo');
                    this.requestInformation = result;
                    this.editRequestInformation = false;
                };
                RequestDetails.prototype.updatePWDInformation = function (result) {
                    this.pwdInformation = result;
                    this.editPWDInformation = false;
                };
                RequestDetails.prototype.updateCallerInformation = function (result) {
                    this.callerInformation = result;
                    this.editCallerInformation = false;
                };
                RequestDetails.prototype.GetRequestInformation = function () {
                    var _this = this;
                    this.datacontext.requests.getRequestInformation(this.request.id).then(function (result) { return _this.requestInformation = result; });
                };
                RequestDetails.prototype.GetPWDInformation = function () {
                    var _this = this;
                    if (this.request.pwdPersonId) {
                        this.datacontext.persons.getDemographics(this.request.pwdPersonId).then(function (result) { return _this.pwdInformation = result; });
                    }
                };
                RequestDetails.prototype.GetCallerInformation = function () {
                    var _this = this;
                    if (this.request.requesterPersonId && this.request.pwdPersonId !== this.request.requesterPersonId) {
                        this.datacontext.persons.getDemographics(this.request.requesterPersonId).then(function (result) { return _this.callerInformation = result; });
                    }
                };
                RequestDetails.$inject = ['$mdDialog', 'common', 'datacontext'];
                RequestDetails = __decorate([
                    app.Component('app.requests.details', 'ceRequestDetails', {
                        templateUrl: 'app/requests/details/details.html',
                        bindings: {
                            request: '<'
                        }
                    })
                ], RequestDetails);
                return RequestDetails;
            }());
        })(details = requests.details || (requests.details = {}));
    })(requests = app.requests || (app.requests = {}));
})(app || (app = {}));
var app;
(function (app) {
    var requests;
    (function (requests) {
        var entities;
        (function (entities) {
            var routes = [
                {
                    name: 'app.requests.entities',
                    url: '/entities',
                    template: '<ui-view flex layout="column"></ui-view>',
                    redirectTo: 'app.requests.entities.listing',
                    data: { title: 'Request Entities' }
                },
                {
                    name: 'app.requests.entities.listing',
                    url: '/listing',
                    template: '<ce-involvement-entity-listing involvement-id="$resolve.request.involvementId" layout="column" flex></ce-involvement-entity-listing>',
                    data: { title: 'Request Entities' }
                },
                {
                    name: 'app.requests.entities.add',
                    url: '/add',
                    template: '<ce-select-entity-type flex></ce-select-entity-type>',
                    redirectTo: 'app.requests.entities.add.type',
                    data: { title: 'Add' }
                },
                {
                    name: 'app.requests.entities.add.type',
                    url: '/types',
                    template: '<ce-entity-types flex></ce-entity-types>'
                },
                {
                    name: 'app.requests.entities.add.person',
                    url: '/person',
                    template: '<ui-view layout flex></ui-view>',
                    redirectTo: 'app.requests.entities.add.person.search',
                },
                {
                    name: 'app.requests.entities.add.person.search',
                    url: '/search',
                    template: '<ce-person-search flex></ce-person-search>',
                    data: { title: 'Find Person' }
                },
                {
                    name: 'app.requests.entities.add.person.create',
                    url: '/create',
                    template: '<ce-create-person flex></ce-create-person>',
                    data: { title: 'Create Person' }
                },
                {
                    name: 'app.requests.entities.add.person.roles',
                    url: '/roles',
                    params: { entity: null },
                    resolve: { entity: ['$stateParams', function (p) { return p.entity; }] },
                    template: '<ce-entity-roles involvement-id="$resolve.request.involvementId" entity="$resolve.entity" layout="column" flex></ce-entity-roles>',
                    data: { title: 'Select Roles' }
                },
                {
                    name: 'app.requests.entities.edit',
                    url: '/edit',
                    params: { entity: null },
                    resolve: { entity: ['$stateParams', function (p) { return p.entity; }] },
                    template: '<ce-edit-entity-roles involvement-id="$resolve.request.involvementId" entity="$resolve.entity" layout="column" flex></ce-edit-entity-roles>',
                    data: { title: 'Edit' }
                },
                {
                    name: 'app.requests.entities.add.agency',
                    url: '/agency',
                    template: '<ui-view layout flex></ui-view>',
                    redirectTo: 'app.requests.entities.add.agency.search',
                },
                {
                    name: 'app.requests.entities.add.agency.search',
                    url: '/search',
                    template: '<ce-agency-search flex></ce-agenyc-search>',
                    data: { title: 'Find Agency' }
                },
                {
                    name: 'app.requests.entities.add.agency.create',
                    url: '/create',
                    template: '<ce-create-agency flex></ce-create-agency>',
                    data: { title: 'Create Agency' }
                },
                {
                    name: 'app.requests.entities.add.agency.roles',
                    url: '/roles',
                    params: { entity: null },
                    resolve: { entity: ['$stateParams', function (p) { return p.entity; }] },
                    template: '<ce-entity-roles involvement-id="$resolve.request.involvementId" entity="$resolve.entity" layout="column" flex></ce-entity-roles>',
                    data: { title: 'Select Roles' }
                }
            ];
            var runner = function (routingHelper) {
                'ngInject';
                routingHelper.configureRoutes(routes);
            };
            angular
                .module('app.requests.entities')
                .run(runner);
        })(entities = requests.entities || (requests.entities = {}));
    })(requests = app.requests || (app.requests = {}));
})(app || (app = {}));
var app;
(function (app) {
    var requests;
    (function (requests) {
        var overview;
        (function (overview) {
            var RequestDetails = (function () {
                function RequestDetails(common, datacontext) {
                    this.common = common;
                    this.datacontext = datacontext;
                    'ngInject';
                    'ngInject';
                }
                RequestDetails.prototype.$onInit = function () {
                };
                RequestDetails.$inject = ['common', 'datacontext'];
                RequestDetails = __decorate([
                    app.Component('app.requests.overview', 'ceRequestOverview', {
                        templateUrl: 'app/requests/overview/overview.html',
                        bindings: {
                            request: '<'
                        }
                    })
                ], RequestDetails);
                return RequestDetails;
            }());
        })(overview = requests.overview || (requests.overview = {}));
    })(requests = app.requests || (app.requests = {}));
})(app || (app = {}));
var app;
(function (app) {
    var requests;
    (function (requests) {
        var RequestView = (function () {
            function RequestView(common, datacontext, confirmation, timerService) {
                var _this = this;
                this.common = common;
                this.datacontext = datacontext;
                this.confirmation = confirmation;
                this.timerService = timerService;
                this.isLoading = false;
                this.refresh = function () {
                    _this.datacontext.requests.getRequestBasicInfo(_this.request.id).then(function (request) { return _this.request = request; });
                };
                'ngInject';
                'ngInject';
                this.$state = common.$state;
            }
            RequestView.prototype.$onInit = function () {
                this.unsubscribe = this.common.$rootScope.$on('refreshBasicInfo', this.refresh);
            };
            RequestView.prototype.$onDestroy = function () {
                this.unsubscribe();
            };
            RequestView.prototype.showDeleteDialog = function (id) {
                var _this = this;
                this.confirmation.show({
                    message: 'Are you sure you want to delete this Request? This will delete all data attached to this Request.',
                    ok: 'DELETE',
                    cancel: 'KEEP'
                }).then(function () { _this.deleteRequest(id); });
            };
            RequestView.prototype.deleteRequest = function (id) {
                var _this = this;
                this.datacontext.requests.deleteRequest(id).then(function (result) {
                    if (result) {
                        _this.timerService.killTimer();
                        _this.common.$state.go('app.requestListView');
                    }
                });
            };
            RequestView.$inject = ['common', 'datacontext', 'confirmation', 'timerService'];
            RequestView = __decorate([
                app.Component('app.requests', 'ceRequestView', {
                    templateUrl: 'app/requests/request/request-view.html',
                    bindings: {
                        request: '<'
                    }
                })
            ], RequestView);
            return RequestView;
        }());
    })(requests = app.requests || (app.requests = {}));
})(app || (app = {}));
var app;
(function (app) {
    var requests;
    (function (requests) {
        var overview;
        (function (overview) {
            var RequestToDo = (function () {
                function RequestToDo($mdDialog, common, datacontext) {
                    this.$mdDialog = $mdDialog;
                    this.common = common;
                    this.datacontext = datacontext;
                    'ngInject';
                    'ngInject';
                }
                RequestToDo.prototype.$onInit = function () {
                    var _this = this;
                    this.datacontext.requests.getRequestToDo(this.request.id)
                        .then(function (items) {
                        _this.items = items;
                    });
                };
                RequestToDo.$inject = ['$mdDialog', 'common', 'datacontext'];
                RequestToDo = __decorate([
                    app.Component('app.requests.overview', 'ceRequestToDo', {
                        templateUrl: 'app/requests/to-do-list/to-do-list.html',
                        bindings: {
                            request: '<'
                        }
                    })
                ], RequestToDo);
                return RequestToDo;
            }());
        })(overview = requests.overview || (requests.overview = {}));
    })(requests = app.requests || (app.requests = {}));
})(app || (app = {}));
var app;
(function (app) {
    var requests;
    (function (requests) {
        var overview;
        (function (overview) {
            var RequestSummary = (function () {
                function RequestSummary($mdDialog, common, datacontext) {
                    this.$mdDialog = $mdDialog;
                    this.common = common;
                    this.datacontext = datacontext;
                    'ngInject';
                    'ngInject';
                }
                RequestSummary.prototype.$onInit = function () {
                    var _this = this;
                    this.datacontext.requests.getRequestSummary(this.request.id)
                        .then(function (result) {
                        var data = result;
                        _this.requestSummary = data;
                    });
                };
                RequestSummary.prototype.init = function () {
                };
                RequestSummary.$inject = ['$mdDialog', 'common', 'datacontext'];
                RequestSummary = __decorate([
                    app.Component('app.requests.overview', 'ceRequestSummary', {
                        templateUrl: 'app/requests/summary/summary.html',
                        bindings: {
                            request: '<'
                        }
                    })
                ], RequestSummary);
                return RequestSummary;
            }());
        })(overview = requests.overview || (requests.overview = {}));
    })(requests = app.requests || (app.requests = {}));
})(app || (app = {}));
var app;
(function (app) {
    var search;
    (function (search) {
        var AdvancedSearch = (function () {
            function AdvancedSearch() {
                'ngInject';
            }
            AdvancedSearch = __decorate([
                app.Component('app.search', 'ceAdvancedSearch', {
                    templateUrl: 'app/search/advanced-search/advanced-search.html'
                })
            ], AdvancedSearch);
            return AdvancedSearch;
        }());
    })(search = app.search || (app.search = {}));
})(app || (app = {}));
var app;
(function (app) {
    var search;
    (function (search) {
        var SearchBar = (function () {
            function SearchBar(common, ageFilter, datacontext) {
                this.common = common;
                this.ageFilter = ageFilter;
                this.datacontext = datacontext;
                'ngInject';
                'ngInject';
            }
            SearchBar.prototype.advancedSearch = function () {
                this.common.$state.go('app.search');
            };
            SearchBar.prototype.handleSelectedItemChange = function (entity) {
                if (!entity)
                    return;
                if (entity.entityType === 'Person') {
                    this.common.$state.go('app.person', { personId: entity.id });
                }
                else {
                    this.common.$state.go('app.agency', { agencyId: entity.id });
                }
                this.searchText = null;
            };
            SearchBar.prototype.search = function (searchText) {
                var _this = this;
                var searchResults = [];
                var people = this.datacontext.persons
                    .getPersons({ q: this.searchText, pageSize: 10 });
                people.then(function (result) {
                    var results = result.list;
                    _.forEach(results, function (r) {
                        if (r.dateOfBirth) {
                            r.age = _this.ageFilter(r.dateOfBirth);
                            r.formattedDoB = moment(r.dateOfBirth).format('MM/DD/YYYY');
                        }
                        r.entityType = 'Person';
                        r.sortBy = r.lastName + ' ' + r.firstName;
                    });
                    searchResults.push.apply(searchResults, results);
                });
                var agencies = this.datacontext.agencies
                    .getAgencies({ q: this.searchText, pageSize: 10 });
                agencies.then(function (result) {
                    var results = result.list;
                    _.forEach(results, function (r) {
                        r.entityType = 'Agency';
                        r.sortBy = r.fullName;
                    });
                    searchResults.push.apply(searchResults, results);
                });
                return this.common.$q.all([people, agencies]).then(function () {
                    return _.sortBy(searchResults, 'sortBy');
                });
            };
            SearchBar.$inject = ['common', 'ageFilter', 'datacontext'];
            SearchBar = __decorate([
                app.Component('app.search', 'ceSearch', {
                    templateUrl: 'app/search/search-bar/search-bar.html'
                })
            ], SearchBar);
            return SearchBar;
        }());
    })(search = app.search || (app.search = {}));
})(app || (app = {}));
var app;
(function (app) {
    var search;
    (function (search) {
        var SourceSearchController = (function () {
            function SourceSearchController($scope, common, datacontext) {
                this.$scope = $scope;
                this.common = common;
                this.datacontext = datacontext;
                'ngInject';
                'ngInject';
            }
            SourceSearchController.prototype.$onInit = function () {
                var _this = this;
                if (!this.sourceTypes) {
                    this.datacontext.config.lookups.getLookupType('source-types')
                        .then(function (result) {
                        _this.sourceTypes = result.lookups.map(function (l) { return l.value; });
                    });
                }
                this.unsubscribe = this.$scope.$on('ResetSourceSearch', function () {
                    _this.selectedSource = null;
                });
            };
            SourceSearchController.prototype.$onDestroy = function () {
                this.unsubscribe();
            };
            SourceSearchController.prototype.handleSelectedItemChange = function (source) {
                this.onSourceSelected({ source: source });
                if (this.clearOnSelect) {
                    this.selectedSource = null;
                }
                else {
                    this.selectedSource = source;
                }
            };
            SourceSearchController.prototype.$onChanges = function () {
                this.setSource();
            };
            SourceSearchController.prototype.setSource = function () {
                if (!this.sourceDescription) {
                    return;
                }
                this.selectedSource = {
                    source: this.source,
                    sourceId: this.sourceId,
                    sourceDescription: this.sourceDescription,
                    involvementId: this.involvementId
                };
            };
            SourceSearchController.prototype.setType = function () {
                this.selectedSource = null;
                switch (this.source) {
                    case 'CLE': {
                        this.placeHolder = 'Search by Title';
                        break;
                    }
                    case 'Client Case': {
                        this.placeHolder = 'Search by Client Name';
                        break;
                    }
                    case 'Monitoring': {
                        this.placeHolder = 'Search by Case Name';
                        break;
                    }
                    case 'I&R': {
                        this.placeHolder = 'Search by Caller Name';
                        break;
                    }
                    case 'Project': {
                        this.placeHolder = 'Search by Project Name';
                        break;
                    }
                    case 'Request': {
                        this.placeHolder = 'Search by Caller Name';
                        break;
                    }
                    case 'Systemic Case': {
                        this.placeHolder = 'Search by Case Name';
                        break;
                    }
                    case 'Training': {
                        this.placeHolder = 'Search by Title';
                        break;
                    }
                    default: {
                        this.placeHolder = 'Select a Source type before searching';
                    }
                }
            };
            SourceSearchController.prototype.search = function () {
                if (!this.source) {
                    return [];
                }
                switch (this.source) {
                    case 'CLE':
                        return this.getCLEs();
                    case 'Client Case':
                        return this.getClientCases();
                    case 'I&R':
                        return this.getIandRs();
                    case 'Project':
                        return this.getProjects();
                    case 'Request':
                        return this.getRequests();
                    case 'Systemic Case':
                        return this.getSystemicCases();
                    case 'Training':
                        return this.getTrainings();
                    case 'Monitoring':
                        return this.getMonitorings();
                }
            };
            SourceSearchController.prototype.getRequests = function () {
                var searchResults = [];
                var requests = this.datacontext.requests.getRequestListing({ callerName: this.searchText, pageSize: 50 });
                return requests.then(function (result) {
                    var results = result.list;
                    _.forEach(results, function (r) {
                        r.source = 'Request';
                        r.sourceId = r.id;
                        r.sourceDescription = "".concat(r.idNumber, " ").concat(r.requesterFullName);
                        r.sortBy = r.requesterLastFirstName;
                    });
                    searchResults.push.apply(searchResults, results);
                    return _.sortBy(searchResults, 'sortBy');
                });
            };
            SourceSearchController.prototype.getIandRs = function () {
                var searchResults = [];
                var iandrs = this.datacontext.informationAndReferrals.getInformationAndReferralListing({ callerName: this.searchText, pageSize: 50 });
                return iandrs.then(function (result) {
                    var results = result.list;
                    _.forEach(results, function (r) {
                        r.source = 'I&R';
                        r.sourceId = r.id;
                        r.sourceDescription = "".concat(r.idNumber, " ").concat(r.requesterFullName);
                        r.sortBy = r.requesterLastFirstName;
                    });
                    searchResults.push.apply(searchResults, results);
                    return _.sortBy(searchResults, 'sortBy');
                });
            };
            SourceSearchController.prototype.getClientCases = function () {
                var searchResults = [];
                var cases = this.datacontext.clientCases.getClientCaseListing({ clientName: this.searchText, pageSize: 50 });
                return cases.then(function (result) {
                    var results = result.list;
                    _.forEach(results, function (r) {
                        r.source = 'Client Case';
                        r.sourceId = r.id;
                        r.sourceDescription = "".concat(r.idNumber, " ").concat(r.clientFullName);
                        r.sortBy = r.clientLastFirstName;
                    });
                    searchResults.push.apply(searchResults, results);
                    return _.sortBy(searchResults, 'sortBy');
                });
            };
            SourceSearchController.prototype.getSystemicCases = function () {
                var searchResults = [];
                var cases = this.datacontext.systemicCases.getSystemicCaseListing({ caseName: this.searchText, pageSize: 50 });
                return cases.then(function (result) {
                    var results = result.list;
                    _.forEach(results, function (r) {
                        r.source = 'Systemic Case';
                        r.sourceId = r.id;
                        r.sourceDescription = "".concat(r.idNumber, " ").concat(r.name);
                        r.sortBy = r.name;
                    });
                    searchResults.push.apply(searchResults, results);
                    return _.sortBy(searchResults, 'sortBy');
                });
            };
            SourceSearchController.prototype.getProjects = function () {
                var searchResults = [];
                var projects = this.datacontext.projects.getProjectListing({ projectName: this.searchText, pageSize: 50 });
                return projects.then(function (result) {
                    var results = result.list;
                    _.forEach(results, function (r) {
                        r.source = 'Project';
                        r.sourceId = r.id;
                        r.sourceDescription = "".concat(r.name);
                        r.sortBy = r.name;
                    });
                    searchResults.push.apply(searchResults, results);
                    return _.sortBy(searchResults, 'sortBy');
                });
            };
            SourceSearchController.prototype.getTrainings = function () {
                var searchResults = [];
                var trainings = this.datacontext.training.getTrainingListing({ q: this.searchText, pageSize: 50 });
                return trainings.then(function (result) {
                    var results = result.list;
                    _.forEach(results, function (r) {
                        r.source = 'Training';
                        r.sourceId = r.id;
                        r.sourceDescription = "".concat(r.title);
                        r.sortBy = r.title;
                    });
                    searchResults.push.apply(searchResults, results);
                    return _.sortBy(searchResults, 'sortBy');
                });
            };
            SourceSearchController.prototype.getCLEs = function () {
                var searchResults = [];
                var cles = this.datacontext.training.getCLEListing({ q: this.searchText, pageSize: 50 });
                return cles.then(function (result) {
                    var results = result.list;
                    _.forEach(results, function (r) {
                        r.source = 'CLE';
                        r.sourceId = r.id;
                        r.sourceDescription = "".concat(r.title);
                        r.sortBy = r.title;
                    });
                    searchResults.push.apply(searchResults, results);
                    return _.sortBy(searchResults, 'sortBy');
                });
            };
            SourceSearchController.prototype.getMonitorings = function () {
                var searchResults = [];
                var mons = this.datacontext.monitoring.getMonitoringList({ caseName: this.searchText, pageSize: 50 });
                return mons.then(function (result) {
                    var results = [];
                    if (result) {
                        results = result.list;
                    }
                    _.forEach(results, function (r) {
                        r.source = 'Monitoring';
                        r.sourceId = r.id;
                        r.sourceDescription = "".concat(r.idNumber, " ").concat(r.name);
                        r.sortBy = r.name;
                    });
                    searchResults.push.apply(searchResults, results);
                    return _.sortBy(searchResults, 'sortBy');
                });
            };
            SourceSearchController.$inject = ['$scope', 'common', 'datacontext'];
            SourceSearchController = __decorate([
                app.Component('app.search', 'ceSourceSearch', {
                    templateUrl: 'app/search/source/source.html',
                    bindings: {
                        sourceId: '<',
                        source: '<',
                        involvementId: '<',
                        sourceDescription: '<',
                        onSourceSelected: '&',
                        sourceRequired: '<',
                        sourceTypes: '<',
                        clearOnSelect: '<'
                    }
                })
            ], SourceSearchController);
            return SourceSearchController;
        }());
    })(search = app.search || (app.search = {}));
})(app || (app = {}));
var app;
(function (app) {
    var systemicCases;
    (function (systemicCases) {
        var activities;
        (function (activities) {
            var routes = [
                {
                    name: 'app.systemicCases.activities',
                    url: '/activities',
                    template: '<ui-view layout flex></ui-view>',
                    redirectTo: 'app.systemicCases.activities.list'
                },
                {
                    name: 'app.systemicCases.activities.list',
                    url: '',
                    template: '<ce-systemic-case-activity-list case="$resolve.case" layout="column" flex></ce-systemic-case-activity-list>',
                    data: {
                        title: 'Systemic Case Activities',
                        permissions: ['access_activities']
                    }
                },
                {
                    name: 'app.systemicCases.activities.note',
                    url: '/note',
                    template: "<ce-systemic-cases-activities-note layout=\"column\" flex\n                        involvement-id=\"$resolve.case.involvementId\"\n                        case-Id=\"$resolve.case.id\"\n                        activity-Type=\"SystemicCaseNote\"\n                        name=\"Note\"\n                        activity=\"$resolve.activity\"\n                        case-open-date=\"$resolve.case.openDate\"\n                        return-sref=\"app.systemicCases.activities.list\">\n                    </ce-systemic-cases-activities-note>",
                    params: { activity: null },
                    resolve: { activity: ['$stateParams', function (p) { return p.activity; }] },
                    data: { title: 'Note' }
                },
                {
                    name: 'app.systemicCases.activities.caseReview',
                    url: '/review',
                    template: "<ce-systemic-case-review\n                     involvement-id=\"$resolve.case.involvementId\"\n                     case-id=\"$resolve.case.id\"\n                     activity=\"$resolve.activity\"\n                     case-open-date=\"$resolve.case.openDate\"\n                     flex>\n                    </ce-systemic-case-review>",
                    params: { activity: null },
                    resolve: { activity: ['$stateParams', function (p) { return p.activity; }] },
                    data: { title: 'Review' }
                },
                {
                    name: 'app.systemicCases.activities.caseAssessmentMeeting',
                    url: '/cam',
                    template: "<ce-systemic-case-assessment-meeting\n                     involvement-id=\"$resolve.case.involvementId\"\n                     source=\"Systemic Case\"\n                     source-id=\"$resolve.case.id\"\n                     show-communication-date=\"true\"\n                     activity=\"$resolve.activity\"\n                     case-open-date=\"$resolve.case.openDate\"\n                     flex>\n                    </ce-systemic-case-assessment-meeting>",
                    params: { activity: null },
                    resolve: { activity: ['$stateParams', function (p) { return p.activity; }] },
                    data: { title: 'CAM' }
                },
                {
                    name: 'app.systemicCases.activities.conflictCheck',
                    url: '/conflictCheck',
                    template: "<ce-conflict-check layout=\"column\" flex\n                     involvement-id=\"$resolve.case.involvementId\"\n                     source=\"Systemic Case\"\n                     source-Id=\"$resolve.case.id\"\n                     case-open-date=\"$resolve.case.openDate\">\n                    </ce-conflict-check>",
                    data: { title: 'Conflict Check' }
                },
                {
                    name: 'app.systemicCases.activities.close',
                    url: '/close',
                    template: "<ce-systemic-cases-activities-close flex\n                     case=\"$resolve.case\">\n                    </ce-systemic-cases-activities-close>",
                    data: { title: 'Close' }
                },
                {
                    name: 'app.systemicCases.activities.reopen',
                    url: '/reopen',
                    template: "<ce-systemic-cases-activities-reopen flex\n                     case=\"$resolve.case\">\n                    </ce-systemic-cases-activities-reopen>",
                    data: { title: 'Reopen' }
                },
                {
                    name: 'app.systemicCases.activities.createClientCase',
                    url: '/createclientcase',
                    template: "<ce-create-client-case layout=\"column\" flex\n                        involvement-id=\"$resolve.case.involvementId\"\n                        systemic-case-id=\"$resolve.case.id\"\n                        case-open-date=\"$resolve.case.openDate\">\n                    </ce-create-client-case>",
                    data: {
                        title: 'Create Client Case',
                        permissions: ['client-cases.edit']
                    }
                },
                {
                    name: 'app.systemicCases.activities.createInformationAndReferral',
                    url: '/createInformationAndReferral',
                    template: "<ce-create-information-and-referral layout=\"column\" flex\n                        involvement-id=\"$resolve.case.involvementId\"\n                        systemic-case-id=\"$resolve.case.id\"\n                        case-open-date=\"$resolve.case.openDate\">\n                    </ce-create-information-and-referral>",
                    data: {
                        title: 'Create I&R',
                        permissions: ['i-and-rs.edit']
                    }
                },
                {
                    name: 'app.systemicCases.activities.statusChange',
                    url: '/statusChange',
                    template: "<am-activities-status-change layout=\"column\" flex\n                        [involvement-id]=\"$resolve.case.involvementId\"\n                        [source-id]=\"$resolve.case.id\"\n                        source=\"systemiccases\"\n                        [activity-id]=\"$resolve.activityId\">\n                    </am-activities-status-change>",
                    params: { activityId: null },
                    resolve: { activityId: ['$stateParams', function (p) { return p.activityId; }] },
                    data: {
                        title: 'Status Change',
                        permissions: ['systemic-cases.edit']
                    }
                }
            ];
            var runner = function (routingHelper) {
                'ngInject';
                routingHelper.configureRoutes(routes);
            };
            angular
                .module('app.systemicCases.activities')
                .run(runner);
        })(activities = systemicCases.activities || (systemicCases.activities = {}));
    })(systemicCases = app.systemicCases || (app.systemicCases = {}));
})(app || (app = {}));
var app;
(function (app) {
    var systemicCases;
    (function (systemicCases) {
        var SystemicCaseReview = (function () {
            function SystemicCaseReview(common, datacontext) {
                this.common = common;
                this.datacontext = datacontext;
                this.isSubmitting = false;
                this.review = {};
                'ngInject';
                'ngInject';
            }
            SystemicCaseReview.prototype.$onInit = function () {
                this.maxDate = moment().toDate();
                if (this.activity) {
                    this.review.id = this.activity.id;
                    this.review.caseId = this.activity.sourceId;
                    this.review.involvementId = this.activity.involvementId;
                    this.review.note = this.activity.description;
                    this.review.reviewDate = this.activity.activityDate;
                }
                else {
                    this.getCaseStatus();
                    this.review.involvementId = this.involvementId;
                    this.review.caseId = this.caseId;
                    this.review.reviewDate = moment().toDate();
                }
                this.returnSref = this.returnSref || '^.list';
            };
            SystemicCaseReview.prototype.getCaseStatus = function () {
                var _this = this;
                return this.datacontext.systemicCases.getSystemicCaseStatus(this.caseId).then(function (result) {
                    var data = result;
                    if (data.status) {
                        _this.review.status = data.status;
                        _this.review.statusDescription = data.statusDescription;
                    }
                });
            };
            SystemicCaseReview.prototype.submit = function () {
                var _this = this;
                this.isSubmitting = true;
                if (typeof this.review.reviewDate !== 'string') {
                    this.review.reviewDate = this.review.reviewDate.toDateString();
                }
                if (this.review.status !== 'Other') {
                    this.review.statusDescription = null;
                }
                var promise = this.review.id
                    ? this.datacontext.systemicCases.updateCaseReview(this.review)
                    : this.datacontext.systemicCases.createCaseReview(this.review);
                promise
                    .then(function (request) {
                    if (request) {
                        _this.common.$mdToast.showSimple('Case Review ' + (_this.review.id ? 'updated' : 'created'));
                        _this.common.$rootScope.$broadcast('refreshBasicInfo');
                        _this.common.$state.go(_this.returnSref, { involvementId: _this.involvementId, sourceId: _this.caseId });
                    }
                })
                    .finally(function () { return _this.isSubmitting = false; });
            };
            SystemicCaseReview.$inject = ['common', 'datacontext'];
            SystemicCaseReview = __decorate([
                app.Component('app.systemicCases', 'ceSystemicCaseReview', {
                    templateUrl: 'app/systemic-cases/case-review/case-review.html',
                    bindings: {
                        involvementId: '<',
                        caseId: '<',
                        returnSref: '@',
                        activity: '<',
                        caseOpenDate: '<'
                    }
                })
            ], SystemicCaseReview);
            return SystemicCaseReview;
        }());
    })(systemicCases = app.systemicCases || (app.systemicCases = {}));
})(app || (app = {}));
var app;
(function (app) {
    var systemicCases;
    (function (systemicCases) {
        var closure;
        (function (closure) {
            var SystemicCaseClosure = (function () {
                function SystemicCaseClosure($mdDialog, common, datacontext) {
                    this.$mdDialog = $mdDialog;
                    this.common = common;
                    this.datacontext = datacontext;
                    this.editClosureInformation = false;
                    'ngInject';
                    'ngInject';
                }
                SystemicCaseClosure.prototype.$onInit = function () {
                    this.GetClosureInformation();
                };
                SystemicCaseClosure.prototype.updateClosureInformation = function (result) {
                    this.closureInformation = result;
                    this.editClosureInformation = false;
                };
                SystemicCaseClosure.prototype.GetClosureInformation = function () {
                    var _this = this;
                    this.datacontext.systemicCases.getClosureInformation(this.case.id).then(function (result) { return _this.closureInformation = result; });
                };
                SystemicCaseClosure.$inject = ['$mdDialog', 'common', 'datacontext'];
                SystemicCaseClosure = __decorate([
                    app.Component('app.systemicCases.closure', 'ceSystemicCaseClosure', {
                        templateUrl: 'app/systemic-cases/closure/closure.html',
                        bindings: {
                            case: '<'
                        }
                    })
                ], SystemicCaseClosure);
                return SystemicCaseClosure;
            }());
        })(closure = systemicCases.closure || (systemicCases.closure = {}));
    })(systemicCases = app.systemicCases || (app.systemicCases = {}));
})(app || (app = {}));
var app;
(function (app) {
    var systemicCases;
    (function (systemicCases) {
        var CreateSystemicCase = (function () {
            function CreateSystemicCase(common, datacontext) {
                this.common = common;
                this.datacontext = datacontext;
                this.isSubmitting = false;
                this.case = {};
                'ngInject';
                'ngInject';
            }
            CreateSystemicCase.prototype.$onInit = function () {
                this.maxDate = moment().toDate();
                this.case.involvementId = this.involvementId;
                this.case.requestId = this.requestId;
                this.case.informationAndReferralId = this.informationAndReferralId;
                this.case.clientCaseId = this.clientCaseId;
                this.case.openDate = moment().toDate();
            };
            CreateSystemicCase.prototype.submit = function () {
                var _this = this;
                this.isSubmitting = true;
                if (typeof this.case.openDate !== 'string') {
                    this.case.openDate = this.case.openDate.toDateString();
                }
                this.datacontext.systemicCases
                    .createSystemicCase(this.case)
                    .then(function (id) {
                    if (id) {
                        _this.common.$mdToast.showSimple('Systemic Case Created');
                        _this.common.$state.go('app.systemicCases', { systemicCaseId: id });
                    }
                })
                    .finally(function () { return _this.isSubmitting = false; });
            };
            CreateSystemicCase.$inject = ['common', 'datacontext'];
            CreateSystemicCase = __decorate([
                app.Component('app.systemicCases', 'ceCreateSystemicCase', {
                    templateUrl: 'app/systemic-cases/create-systemic-case/create-systemic-case.html',
                    bindings: {
                        requestId: '<',
                        informationAndReferralId: '<',
                        clientCaseId: '<',
                        involvementId: '<',
                        caseOpenDate: '<'
                    }
                })
            ], CreateSystemicCase);
            return CreateSystemicCase;
        }());
    })(systemicCases = app.systemicCases || (app.systemicCases = {}));
})(app || (app = {}));
var app;
(function (app) {
    var systemicCases;
    (function (systemicCases) {
        var details;
        (function (details) {
            var SystemicCaseDetails = (function () {
                function SystemicCaseDetails($mdDialog, common, datacontext) {
                    this.$mdDialog = $mdDialog;
                    this.common = common;
                    this.datacontext = datacontext;
                    this.editCaseInformation = false;
                    'ngInject';
                    'ngInject';
                }
                SystemicCaseDetails.prototype.$onInit = function () {
                    this.GetCaseInformation();
                };
                SystemicCaseDetails.prototype.updateCaseInformation = function (result) {
                    this.caseInformation = result;
                    this.editCaseInformation = false;
                    angular.extend(this.case, this.caseInformation);
                };
                SystemicCaseDetails.prototype.GetCaseInformation = function () {
                    var _this = this;
                    this.datacontext.systemicCases.getSystemicCaseInformation(this.case.id).then(function (result) { return _this.caseInformation = result; });
                };
                SystemicCaseDetails.$inject = ['$mdDialog', 'common', 'datacontext'];
                SystemicCaseDetails = __decorate([
                    app.Component('app.systemicCases.details', 'ceSystemicCaseDetails', {
                        templateUrl: 'app/systemic-cases/details/details.html',
                        bindings: {
                            case: '<'
                        }
                    })
                ], SystemicCaseDetails);
                return SystemicCaseDetails;
            }());
        })(details = systemicCases.details || (systemicCases.details = {}));
    })(systemicCases = app.systemicCases || (app.systemicCases = {}));
})(app || (app = {}));
var app;
(function (app) {
    var systemicCases;
    (function (systemicCases) {
        var entities;
        (function (entities) {
            var routes = [
                {
                    name: 'app.systemicCases.entities',
                    url: '/entities',
                    template: '<ui-view flex layout="column"></ui-view>',
                    redirectTo: 'app.systemicCases.entities.listing',
                    data: { title: 'Request Entities' }
                },
                {
                    name: 'app.systemicCases.entities.listing',
                    url: '/listing',
                    template: '<ce-involvement-entity-listing involvement-id="$resolve.case.involvementId" layout="column" flex></ce-involvement-entity-listing>',
                    data: { title: 'Request Entities' }
                },
                {
                    name: 'app.systemicCases.entities.add',
                    url: '/add',
                    template: '<ce-select-entity-type flex></ce-select-entity-type>',
                    redirectTo: 'app.systemicCases.entities.add.type',
                    data: { title: 'Add' }
                },
                {
                    name: 'app.systemicCases.entities.add.type',
                    url: '/types',
                    template: '<ce-entity-types flex></ce-entity-types>'
                },
                {
                    name: 'app.systemicCases.entities.edit',
                    url: '/edit',
                    params: { entity: null },
                    resolve: { entity: ['$stateParams', function (p) { return p.entity; }] },
                    template: '<ce-edit-entity-roles involvement-id="$resolve.case.involvementId" entity="$resolve.entity" layout="column" flex></ce-edit-entity-roles>',
                    data: { title: 'Edit' }
                },
                {
                    name: 'app.systemicCases.entities.add.person',
                    url: '/person',
                    template: '<ui-view layout flex></ui-view>',
                    redirectTo: 'app.systemicCases.entities.add.person.search',
                },
                {
                    name: 'app.systemicCases.entities.add.person.search',
                    url: '/search',
                    template: '<ce-person-search flex></ce-person-search>',
                    data: { title: 'Find Person' }
                },
                {
                    name: 'app.systemicCases.entities.add.person.create',
                    url: '/create',
                    template: '<ce-create-person flex></ce-create-person>',
                    data: { title: 'Create Person' }
                },
                {
                    name: 'app.systemicCases.entities.add.person.roles',
                    url: '/roles',
                    params: { entity: null },
                    resolve: { entity: ['$stateParams', function (p) { return p.entity; }] },
                    template: '<ce-entity-roles involvement-id="$resolve.case.involvementId" entity="$resolve.entity" layout="column" flex></ce-entity-roles>',
                    data: { title: 'Select Roles' }
                },
                {
                    name: 'app.systemicCases.entities.add.agency',
                    url: '/agency',
                    template: '<ui-view layout flex></ui-view>',
                    redirectTo: 'app.systemicCases.entities.add.agency.search',
                },
                {
                    name: 'app.systemicCases.entities.add.agency.search',
                    url: '/search',
                    template: '<ce-agency-search flex></ce-agenyc-search>',
                    data: { title: 'Find Agency' }
                },
                {
                    name: 'app.systemicCases.entities.add.agency.create',
                    url: '/create',
                    template: '<ce-create-agency flex></ce-create-agency>',
                    data: { title: 'Create Agency' }
                },
                {
                    name: 'app.systemicCases.entities.add.agency.roles',
                    url: '/roles',
                    params: { entity: null },
                    resolve: { entity: ['$stateParams', function (p) { return p.entity; }] },
                    template: '<ce-entity-roles involvement-id="$resolve.case.involvementId" entity="$resolve.entity" layout="column" flex></ce-entity-roles>',
                    data: { title: 'Select Roles' }
                }
            ];
            var runner = function (routingHelper) {
                'ngInject';
                routingHelper.configureRoutes(routes);
            };
            angular
                .module('app.systemicCases.entities')
                .run(runner);
        })(entities = systemicCases.entities || (systemicCases.entities = {}));
    })(systemicCases = app.systemicCases || (app.systemicCases = {}));
})(app || (app = {}));
var app;
(function (app) {
    var systemicCases;
    (function (systemicCases) {
        var funding;
        (function (funding) {
            var routes = [
                {
                    name: 'app.systemicCases.funding',
                    url: '/funding',
                    template: '<ce-funding involvement-id="$resolve.case.involvementId" layout="column" flex></ce-funding>',
                    redirectTo: 'app.systemicCases.funding.summary',
                    data: { title: 'Systemic Case Funding Summary' }
                },
                {
                    name: 'app.systemicCases.funding.summary',
                    url: '/summary',
                    template: '<ce-funding-summary involvement-id="$resolve.case.involvementId" layout="column" flex></ce-funding-summary>',
                    data: { title: 'Systemic Case Funding' }
                },
                {
                    name: 'app.systemicCases.funding.eligibility',
                    url: '/eligibility',
                    template: '<ui-view layout flex></ui-view>',
                    redirectTo: 'app.systemicCases.funding.eligibility.list',
                    data: { title: 'Systemic Case Funding' }
                },
                {
                    name: 'app.systemicCases.funding.eligibility.list',
                    url: '/list',
                    template: "<am-funding-eligibility-view\n                    [involvement-id]=\"$resolve.case.involvementId\"\n                    source-type=\"systemicCases\"\n                    layout=\"column\" flex></am-funding-eligibility-view>",
                    data: { title: 'Systemic Cases Funding' }
                },
                {
                    name: 'app.systemicCases.funding.eligibility.create',
                    url: '/create',
                    template: '<ce-funding-eligibility-create involvement-id="$resolve.case.involvementId" source="Systemic Case" layout="column" flex></ce-funding-eligibility-create>',
                    data: { title: 'Create Eligibility' }
                },
                {
                    name: 'app.systemicCases.funding.eligibility.cap',
                    url: '/cap',
                    params: { fundingProgramId: null },
                    resolve: { fundingProgramId: ['$stateParams', function (p) { return p.fundingProgramId; }] },
                    template: '<ce-cap-eligibility involvement-id="$resolve.case.involvementId" funding-program-id="$resolve.fundingProgramId" layout="column" flex></ce-cap-eligibility>',
                    data: { title: 'CAP Eligibility' }
                },
                {
                    name: 'app.systemicCases.funding.eligibility.paat',
                    url: '/paat',
                    params: { fundingProgramId: null },
                    resolve: { fundingProgramId: ['$stateParams', function (p) { return p.fundingProgramId; }] },
                    template: '<ce-paat-eligibility involvement-id="$resolve.case.involvementId" funding-program-id="$resolve.fundingProgramId" layout="column" flex></ce-paat-eligibility>',
                    data: { title: 'PAAT Eligibility' }
                },
                {
                    name: 'app.systemicCases.funding.eligibility.pabss',
                    url: '/pabss',
                    params: { fundingProgramId: null },
                    resolve: { fundingProgramId: ['$stateParams', function (p) { return p.fundingProgramId; }] },
                    template: '<ce-pabss-eligibility involvement-id="$resolve.case.involvementId" funding-program-id="$resolve.fundingProgramId" layout="column" flex></ce-pabss-eligibility>',
                    data: { title: 'PABSS Eligibility' }
                },
                {
                    name: 'app.systemicCases.funding.eligibility.padd',
                    url: '/padd',
                    params: { fundingProgramId: null },
                    resolve: { fundingProgramId: ['$stateParams', function (p) { return p.fundingProgramId; }] },
                    template: '<ce-padd-eligibility involvement-id="$resolve.case.involvementId" funding-program-id="$resolve.fundingProgramId" layout="column" flex></ce-padd-eligibility>',
                    data: { title: 'PADD Eligibility' }
                },
                {
                    name: 'app.systemicCases.funding.eligibility.paimi',
                    url: '/paimi',
                    params: { fundingProgramId: null },
                    resolve: { fundingProgramId: ['$stateParams', function (p) { return p.fundingProgramId; }] },
                    template: '<ce-paimi-eligibility involvement-id="$resolve.case.involvementId" funding-program-id="$resolve.fundingProgramId" layout="column" flex></ce-paimi-eligibility>',
                    data: { title: 'PAIMI Eligibility' }
                },
                {
                    name: 'app.systemicCases.funding.eligibility.pair',
                    url: '/pair',
                    params: { fundingProgramId: null },
                    resolve: { fundingProgramId: ['$stateParams', function (p) { return p.fundingProgramId; }] },
                    template: '<ce-pair-eligibility involvement-id="$resolve.case.involvementId" funding-program-id="$resolve.fundingProgramId" layout="column" flex></ce-pair-eligibility>',
                    data: { title: 'PAIR Eligibility' }
                },
                {
                    name: 'app.systemicCases.funding.eligibility.patbi',
                    url: '/patbi',
                    params: { fundingProgramId: null },
                    resolve: { fundingProgramId: ['$stateParams', function (p) { return p.fundingProgramId; }] },
                    template: '<ce-patbi-eligibility involvement-id="$resolve.case.involvementId" funding-program-id="$resolve.fundingProgramId" layout="column" flex></ce-patbi-eligibility>',
                    data: { title: 'PATBI Eligibility' }
                },
                {
                    name: 'app.systemicCases.funding.enrollment',
                    url: '/primary',
                    template: '<ui-view layout flex></ui-view>',
                    redirectTo: 'app.systemicCases.funding.enrollment.list'
                },
                {
                    name: 'app.systemicCases.funding.enrollment.list',
                    url: '/list',
                    template: '<ce-primary-funding-source-listing involvement-id="$resolve.case.involvementId" layout="column" source="Systemic Case" flex></ce-primary-funding-source-listing>',
                    data: { title: 'Primary List' }
                },
                {
                    name: 'app.systemicCases.funding.enrollment.primary',
                    url: '/primary',
                    template: '<ce-create-primary-funding involvement-id="$resolve.case.involvementId" layout="column" flex></ce-create-primary-funding>',
                    data: { title: 'Primary Funding' }
                },
                {
                    name: 'app.systemicCases.funding.enrollment.updatePrimary',
                    url: '/updateprimary',
                    params: { enrollment: null },
                    resolve: { enrollment: ['$stateParams', function (p) { return p.enrollment; }] },
                    template: '<ce-edit-primary-funding enrollment="$resolve.enrollment" layout="column" flex></ce-edit-primary-funding>',
                    data: { title: 'Update Funding' }
                },
                {
                    name: 'app.systemicCases.funding.enrollment.pavaIntake',
                    url: '/pava-systemic-intake/{enrollmentId}',
                    template: '<ce-pava-systemic-intake enrollment-id="$resolve.enrollmentId" layout="column" flex></ce-pava-systemic-intake>',
                    params: { enrollmentId: null },
                    resolve: { enrollmentId: ['$stateParams', function (p) { return p.enrollmentId; }] },
                    data: { title: 'PAVA Intake' }
                },
                {
                    name: 'app.systemicCases.funding.enrollment.pavaOutcome',
                    url: '/pava-systemic-outcome/{enrollmentId}',
                    template: '<ce-pava-systemic-outcome enrollment-id="$resolve.enrollmentId" layout="column" flex></ce-pava-systemic-outcome>',
                    params: { enrollmentId: null },
                    resolve: { enrollmentId: ['$stateParams', function (p) { return p.enrollmentId; }] },
                    data: { title: 'PAVA Outcome' }
                },
                {
                    name: 'app.systemicCases.funding.enrollment.paddIntake',
                    url: '/padd-systemic-intake/{enrollmentId}',
                    template: '<ce-padd-systemic-intake enrollment-id="$resolve.enrollmentId" layout="column" flex></ce-padd-systemic-intake>',
                    params: { enrollmentId: null },
                    resolve: { enrollmentId: ['$stateParams', function (p) { return p.enrollmentId; }] },
                    data: { title: 'PADD Intake' }
                },
                {
                    name: 'app.systemicCases.funding.enrollment.paddOutcome',
                    url: '/padd-systemic-outcome/{enrollmentId}',
                    template: '<ce-padd-systemic-outcome enrollment-id="$resolve.enrollmentId" layout="column" flex></ce-padd-systemic-outcome>',
                    params: { enrollmentId: null },
                    resolve: { enrollmentId: ['$stateParams', function (p) { return p.enrollmentId; }] },
                    data: { title: 'PADD Outcome' }
                },
                {
                    name: 'app.systemicCases.funding.enrollment.pabssIntake',
                    url: '/pabss-intake/{enrollmentId}',
                    template: '<ce-pabss-intake enrollment-id="$resolve.enrollmentId" layout="column" flex></ce-pabss-intake>',
                    params: { enrollmentId: null },
                    resolve: { enrollmentId: ['$stateParams', function (p) { return p.enrollmentId; }] },
                    data: { title: 'PABSS Intake' }
                },
                {
                    name: 'app.systemicCases.funding.enrollment.pabssOutcome',
                    url: '/pabss-outcome/{enrollmentId}',
                    template: '<ce-pabss-outcome enrollment-id="$resolve.enrollmentId" layout="column" flex></ce-pabss-outcome>',
                    params: { enrollmentId: null },
                    resolve: { enrollmentId: ['$stateParams', function (p) { return p.enrollmentId; }] },
                    data: { title: 'PABSS Outcome' }
                },
                {
                    name: 'app.systemicCases.funding.enrollment.capIntake',
                    url: '/cap-systemic-intake/{enrollmentId}',
                    template: '<ce-cap-systemic-intake enrollment-id="$resolve.enrollmentId" layout="column" flex></ce-cap-systemic-intake>',
                    params: { enrollmentId: null },
                    resolve: { enrollmentId: ['$stateParams', function (p) { return p.enrollmentId; }] },
                    data: { title: 'CAP Intake' }
                },
                {
                    name: 'app.systemicCases.funding.enrollment.capOutcome',
                    url: '/cap-systemic-outcome/{enrollmentId}',
                    template: '<ce-cap-systemic-outcome enrollment-id="$resolve.enrollmentId" layout="column" flex></ce-cap-systemic-outcome>',
                    params: { enrollmentId: null },
                    resolve: { enrollmentId: ['$stateParams', function (p) { return p.enrollmentId; }] },
                    data: { title: 'CAP Outcome' }
                },
                {
                    name: 'app.systemicCases.funding.enrollment.paatIntake',
                    url: '/paat-systemic-intake/{enrollmentId}',
                    template: '<ce-paat-systemic-intake enrollment-id="$resolve.enrollmentId" layout="column" flex></ce-paat-systemic-intake>',
                    params: { enrollmentId: null },
                    resolve: { enrollmentId: ['$stateParams', function (p) { return p.enrollmentId; }] },
                    data: { title: 'PAAT Intake' }
                },
                {
                    name: 'app.systemicCases.funding.enrollment.paatOutcome',
                    url: '/paat-systemic-outcome/{enrollmentId}',
                    template: '<ce-paat-systemic-outcome enrollment-id="$resolve.enrollmentId" layout="column" flex></ce-paat-systemic-outcome>',
                    params: { enrollmentId: null },
                    resolve: { enrollmentId: ['$stateParams', function (p) { return p.enrollmentId; }] },
                    data: { title: 'PAAT Outcome' }
                },
                {
                    name: 'app.systemicCases.funding.enrollment.pairIntake',
                    url: '/pair-intake/{enrollmentId}',
                    template: '<ce-pair-intake enrollment-id="$resolve.enrollmentId" layout="column" flex></ce-pair-intake>',
                    params: { enrollmentId: null },
                    resolve: { enrollmentId: ['$stateParams', function (p) { return p.enrollmentId; }] },
                    data: { title: 'PAIR Intake' }
                },
                {
                    name: 'app.systemicCases.funding.enrollment.pairOutcome',
                    url: '/pair-outcome/{enrollmentId}',
                    template: '<ce-pair-outcome enrollment-id="$resolve.enrollmentId" layout="column" flex></ce-pair-outcome>',
                    params: { enrollmentId: null },
                    resolve: { enrollmentId: ['$stateParams', function (p) { return p.enrollmentId; }] },
                    data: { title: 'PAIR Outcome' }
                },
                {
                    name: 'app.systemicCases.funding.enrollment.patbiIntake',
                    url: '/patbi-systemic-intake/{enrollmentId}',
                    template: '<ce-patbi-systemic-intake enrollment-id="$resolve.enrollmentId" layout="column" flex></ce-patbi-systemic-intake>',
                    params: { enrollmentId: null },
                    resolve: { enrollmentId: ['$stateParams', function (p) { return p.enrollmentId; }] },
                    data: { title: 'PATBI Intake' }
                },
                {
                    name: 'app.systemicCases.funding.enrollment.patbiOutcome',
                    url: '/patbi-systemic-outcome/{enrollmentId}',
                    template: '<ce-patbi-systemic-outcome enrollment-id="$resolve.enrollmentId" layout="column" flex></ce-patbi-systemic-outcome>',
                    params: { enrollmentId: null },
                    resolve: { enrollmentId: ['$stateParams', function (p) { return p.enrollmentId; }] },
                    data: { title: 'PATBI Outcome' }
                },
                {
                    name: 'app.systemicCases.funding.enrollment.paimiIntake',
                    url: '/paimi-intake/{enrollmentId}',
                    template: '<ce-paimi-systemic-intake enrollment-id="$resolve.enrollmentId" layout="column" flex></ce-paimi-systemic-intake>',
                    params: { enrollmentId: null },
                    resolve: { enrollmentId: ['$stateParams', function (p) { return p.enrollmentId; }] },
                    data: { title: 'PAIMI Intake' }
                },
                {
                    name: 'app.systemicCases.funding.enrollment.paimiOutcome',
                    url: '/paimi-outcome/{enrollmentId}',
                    template: '<ce-paimi-systemic-outcome enrollment-id="$resolve.enrollmentId" layout="column" flex></ce-paimi-systemic-outcome>',
                    params: { enrollmentId: null },
                    resolve: { enrollmentId: ['$stateParams', function (p) { return p.enrollmentId; }] },
                    data: { title: 'PAIMI Outcome' }
                },
                {
                    name: 'app.systemicCases.funding.secondary',
                    url: '/secondary',
                    template: '<ui-view flex></ui-view>',
                    redirectTo: 'app.systemicCases.funding.secondary.view',
                    data: { title: 'Secondary Funding' }
                },
                {
                    name: 'app.systemicCases.funding.secondary.edit',
                    url: '/edit',
                    params: { fundingProgramId: null },
                    resolve: { fundingProgramId: ['$stateParams', function (p) { return p.fundingProgramId; }] },
                    template: '<ce-edit-secondary-funding involvement-id="$resolve.case.involvementId" funding-program-id="$resolve.fundingProgramId" layout="column" flex></ce-edit-secondary-funding>',
                    data: { title: 'Secondary Funding' }
                },
                {
                    name: 'app.systemicCases.funding.secondary.view',
                    url: '/views',
                    template: '<ce-secondary-funding-view involvement-id="$resolve.case.involvementId" layout="column" flex></ce-secondary-funding-view>',
                    data: { title: 'Secondary Funding' }
                },
                {
                    name: 'app.systemicCases.funding.enrollment.deaf services (tep)Intake',
                    url: '/deaf services (tep)Intake/{enrollmentId}',
                    template: '<ce-tep-systemic-intake enrollment-id="$resolve.enrollmentId" layout="column" flex></ce-tep-systemic-intake>',
                    params: { enrollmentId: null },
                    resolve: { enrollmentId: ['$stateParams', function (p) { return p.enrollmentId; }] },
                    data: { title: 'TEP Intake' }
                },
                {
                    name: 'app.systemicCases.funding.enrollment.deaf services (tep)Outcome',
                    url: '/deaf services (tep)Outcome/{enrollmentId}',
                    template: '<ce-tep-systemic-outcome enrollment-id="$resolve.enrollmentId" layout="column" flex></ce-tep-systemic-outcome>',
                    params: { enrollmentId: null },
                    resolve: { enrollmentId: ['$stateParams', function (p) { return p.enrollmentId; }] },
                    data: { title: 'TEP Outcome' }
                },
                {
                    name: 'app.systemicCases.funding.enrollment.deaf services (ndbedp)Intake',
                    url: '/deaf services (ndbedp)Intake/{enrollmentId}',
                    template: '<ce-ndbedp-systemic-intake enrollment-id="$resolve.enrollmentId" layout="column" flex></ce-ndbedp-systemic-intake>',
                    params: { enrollmentId: null },
                    resolve: { enrollmentId: ['$stateParams', function (p) { return p.enrollmentId; }] },
                    data: { title: 'NDBEDP Intake' }
                },
                {
                    name: 'app.systemicCases.funding.enrollment.deaf services (ndbedp)Outcome',
                    url: '/deaf services (ndbedp)Outcome/{enrollmentId}',
                    template: '<ce-ndbedp-systemic-outcome enrollment-id="$resolve.enrollmentId" layout="column" flex></ce-ndbedp-systemic-outcome>',
                    params: { enrollmentId: null },
                    resolve: { enrollmentId: ['$stateParams', function (p) { return p.enrollmentId; }] },
                    data: { title: 'NDBEDP Outcome' }
                }
            ];
            var runner = function (routingHelper) {
                'ngInject';
                routingHelper.configureRoutes(routes);
            };
            angular
                .module('app.systemicCases.funding')
                .run(runner);
        })(funding = systemicCases.funding || (systemicCases.funding = {}));
    })(systemicCases = app.systemicCases || (app.systemicCases = {}));
})(app || (app = {}));
var app;
(function (app) {
    var systemicCases;
    (function (systemicCases) {
        var overview;
        (function (overview) {
            var SystemicCaseOverview = (function () {
                function SystemicCaseOverview($mdDialog, common, datacontext) {
                    this.$mdDialog = $mdDialog;
                    this.common = common;
                    this.datacontext = datacontext;
                    'ngInject';
                    'ngInject';
                }
                SystemicCaseOverview.prototype.$onInit = function () {
                };
                SystemicCaseOverview.$inject = ['$mdDialog', 'common', 'datacontext'];
                SystemicCaseOverview = __decorate([
                    app.Component('app.systemicCases.overview', 'ceSystemicCaseOverview', {
                        templateUrl: 'app/systemic-cases/overview/overview.html',
                        bindings: {
                            case: '<'
                        }
                    })
                ], SystemicCaseOverview);
                return SystemicCaseOverview;
            }());
        })(overview = systemicCases.overview || (systemicCases.overview = {}));
    })(systemicCases = app.systemicCases || (app.systemicCases = {}));
})(app || (app = {}));
var app;
(function (app) {
    var systemicCases;
    (function (systemicCases) {
        var overview;
        (function (overview) {
            var SystemicCaseSummary = (function () {
                function SystemicCaseSummary($mdDialog, common, datacontext) {
                    this.$mdDialog = $mdDialog;
                    this.common = common;
                    this.datacontext = datacontext;
                    'ngInject';
                    'ngInject';
                }
                SystemicCaseSummary.prototype.$onInit = function () {
                    var _this = this;
                    this.datacontext.systemicCases.getSystemicCaseSummaryView(this.case.id)
                        .then(function (result) {
                        var data = result;
                        _this.caseSummary = data;
                    });
                };
                SystemicCaseSummary.prototype.init = function () {
                };
                SystemicCaseSummary.$inject = ['$mdDialog', 'common', 'datacontext'];
                SystemicCaseSummary = __decorate([
                    app.Component('app.systemicCases.overview', 'ceSystemicCaseSummary', {
                        templateUrl: 'app/systemic-cases/summary/summary.html',
                        bindings: {
                            case: '<'
                        }
                    })
                ], SystemicCaseSummary);
                return SystemicCaseSummary;
            }());
        })(overview = systemicCases.overview || (systemicCases.overview = {}));
    })(systemicCases = app.systemicCases || (app.systemicCases = {}));
})(app || (app = {}));
var app;
(function (app) {
    var systemicCases;
    (function (systemicCases) {
        var SystemicCaseView = (function () {
            function SystemicCaseView($mdDialog, common, confirmation, datacontext, timerService) {
                var _this = this;
                this.$mdDialog = $mdDialog;
                this.common = common;
                this.confirmation = confirmation;
                this.datacontext = datacontext;
                this.timerService = timerService;
                this.isLoading = false;
                this.refresh = function () {
                    _this.datacontext.systemicCases.getSystemicCaseBasicInfoView(_this.case.id).then(function (systemicCase) { return _this.case = systemicCase; });
                };
                'ngInject';
                'ngInject';
                this.$state = common.$state;
            }
            SystemicCaseView.prototype.$onInit = function () {
                this.unsubscribe = this.common.$rootScope.$on('refreshBasicInfo', this.refresh);
            };
            SystemicCaseView.prototype.$onDestroy = function () {
                this.unsubscribe();
            };
            SystemicCaseView.prototype.showReportDialog = function () {
                var report = {
                    category: 'Case Report',
                    label: 'Notes Report',
                    name: 'Notes Report',
                    description: 'Notes Report',
                    permission: 'reports.view',
                    parameters: [
                        { label: 'From Date', name: 'FromDate', dataType: 'Date', value: null },
                        { label: 'To Date', name: 'ToDate', dataType: 'Date', value: null },
                        { label: 'Note Type - Leave blank for All', name: 'NoteType', value: '', dataType: 'Select', options: ['', 'Request Summary', 'CAM Decision', 'Case Note', 'Case Review', 'Closure Summary'] },
                        { label: 'Case Type', name: 'CaseType', dataType: 'hidden', value: 'Systemic Case' },
                        { label: 'CaseId', name: 'CaseId', dataType: 'hidden', value: this.case.id },
                        { label: 'Involvment', name: 'InvolvementId', dataType: 'hidden', value: this.case.involvementId }
                    ]
                };
                return this.$mdDialog.show({
                    fullscreen: true,
                    templateUrl: 'app/reports/report-dialog/report-dialog.html',
                    controller: 'ReportDialogController',
                    controllerAs: '$ctrl',
                    focusOnOpen: false,
                    locals: {
                        report: report
                    }
                })
                    .catch(function (error) { if (error)
                    throw error; })
                    .finally(function () { return angular.noop; });
            };
            SystemicCaseView.prototype.showDeleteDialog = function (id) {
                var _this = this;
                this.confirmation.show({
                    message: 'Are you sure you want to delete this Case? This will delete all data attached to this Case.',
                    ok: 'DELETE',
                    cancel: 'KEEP'
                }).then(function () { _this.deleteCase(id); });
            };
            SystemicCaseView.prototype.deleteCase = function (id) {
                var _this = this;
                this.datacontext.systemicCases.deleteSystemicCase(id).then(function (result) {
                    if (result) {
                        _this.timerService.killTimer();
                        _this.common.$state.go('app.systemicCaseList');
                    }
                });
            };
            SystemicCaseView.$inject = ['$mdDialog', 'common', 'confirmation', 'datacontext', 'timerService'];
            SystemicCaseView = __decorate([
                app.Component('app.systemicCases', 'ceSystemicCaseView', {
                    templateUrl: 'app/systemic-cases/systemic-case/systemic-case-view.html',
                    bindings: {
                        case: '<'
                    }
                })
            ], SystemicCaseView);
            return SystemicCaseView;
        }());
    })(systemicCases = app.systemicCases || (app.systemicCases = {}));
})(app || (app = {}));
var app;
(function (app) {
    var systemicCases;
    (function (systemicCases) {
        var overview;
        (function (overview) {
            var SystemicCaseToDo = (function () {
                function SystemicCaseToDo($mdDialog, common, datacontext) {
                    this.$mdDialog = $mdDialog;
                    this.common = common;
                    this.datacontext = datacontext;
                    'ngInject';
                    'ngInject';
                }
                SystemicCaseToDo.prototype.$onInit = function () {
                    var _this = this;
                    this.datacontext.systemicCases.getSystemicCaseToDo(this.case.id)
                        .then(function (items) {
                        _this.items = items;
                    });
                };
                SystemicCaseToDo.$inject = ['$mdDialog', 'common', 'datacontext'];
                SystemicCaseToDo = __decorate([
                    app.Component('app.systemicCases.overview', 'ceSystemicCaseToDo', {
                        templateUrl: 'app/systemic-cases/to-do-list/to-do-list.html',
                        bindings: {
                            case: '<'
                        }
                    })
                ], SystemicCaseToDo);
                return SystemicCaseToDo;
            }());
        })(overview = systemicCases.overview || (systemicCases.overview = {}));
    })(systemicCases = app.systemicCases || (app.systemicCases = {}));
})(app || (app = {}));
var app;
(function (app) {
    var time;
    (function (time) {
        var CalendarStateService = (function () {
            function CalendarStateService($localStorage) {
                var _this = this;
                this.$localStorage = $localStorage;
                this.state = { userIds: [], users: [] };
                this.loadState = function () {
                    if (_this.$localStorage['timeslipCalendarState']) {
                        angular.extend(_this.state, _this.$localStorage['timeslipCalendarState']);
                    }
                    else {
                        _this.$localStorage['timeslipCalendarState'] = _this.state;
                    }
                    if (_this.state.dateRange) {
                        _this.state.dateRange = moment.range(_this.state.dateRange.start, _this.state.dateRange.end);
                    }
                };
                'ngInject';
                'ngInject';
                if ($localStorage.calendarState) {
                    delete $localStorage.calendarState.dateRange;
                    delete $localStorage.calendarState.viewType;
                    delete $localStorage.calendarState.viewDate;
                }
                this.loadState();
            }
            CalendarStateService.prototype.save = function () {
                this.$localStorage['timeslipCalendarState'] = this.state;
            };
            CalendarStateService.prototype.update = function (state) {
                angular.extend(this.state, state);
                angular.extend(this.$localStorage['timeslipCalendarState'], state);
            };
            return CalendarStateService;
        }());
        angular
            .module('app.time')
            .service('timeslipCalendarState', CalendarStateService);
    })(time = app.time || (app.time = {}));
})(app || (app = {}));
var app;
(function (app) {
    var time;
    (function (time) {
        var Calendar = (function () {
            function Calendar($scope, $mdPanel, $mdSidenav, common, $mdDialog, timeslipCalendarData, timeslipCalendarState) {
                var _this = this;
                this.$scope = $scope;
                this.$mdPanel = $mdPanel;
                this.$mdSidenav = $mdSidenav;
                this.common = common;
                this.$mdDialog = $mdDialog;
                this.timeslipCalendarData = timeslipCalendarData;
                this.timeslipCalendarState = timeslipCalendarState;
                this.state = this.timeslipCalendarState.state;
                this.calendarOptions = {
                    aspectRatio: 0,
                    scrollTime: '06:00',
                    editable: false,
                    eventLimit: true,
                    header: false,
                    selectable: false,
                    selectHelper: false,
                    dayClick: function (targetDate, targetEvent, calendarView) {
                        _this.createTimeslip(targetDate);
                    },
                    eventClick: function (timeslipSummary) {
                        _this.showSummaries(timeslipSummary);
                    }
                };
                this.eventSources = [];
                this.refresh = function () {
                    if (_this.initialized) {
                        _this.timeslipCalendarData.refresh(_this.source, _this.sourceId, _this.involvementId);
                    }
                };
                this.showSummaries = function (timeslipSummary) {
                    var parent = angular.element(document.body);
                    return _this.$mdDialog.show({
                        parent: parent,
                        fullscreen: true,
                        templateUrl: 'app/time/timeslip-summary/timeslip-summary.html',
                        controller: 'TimeslipSummaryDialogController',
                        controllerAs: '$ctrl',
                        locals: {
                            timeslips: _.filter(_this.timeslipCalendarData.timeslips, function (e) { return e.date.toString() === timeslipSummary.date.toString() && e.userId === timeslipSummary.userId; })
                        }
                    }).then(function (id) {
                        if (id) {
                            _this.openTimeslipDialog(null, id);
                        }
                    })
                        .catch(function (error) { if (error)
                        throw error; });
                };
                this.openTimeslipDialog = function (targetDate, id) {
                    var parent = angular.element(document.body);
                    targetDate = targetDate || moment();
                    return _this.$mdDialog.show({
                        parent: parent,
                        fullscreen: true,
                        templateUrl: 'app/time/timeslip-dialog/timeslip-dialog.html',
                        controller: 'TimeslipDialogController',
                        controllerAs: '$ctrl',
                        focusOnOpen: false,
                        locals: {
                            id: id,
                            hours: null,
                            involvementId: _this.involvementId,
                            source: _this.source,
                            sourceId: _this.sourceId,
                            sourceName: _this.sourceName,
                            sourceName2: _this.sourceName2,
                            date: new Date(targetDate.year(), targetDate.month(), targetDate.date()),
                            fundingProgramId: null
                        }
                    })
                        .catch(function (error) { if (error)
                        throw error; })
                        .finally(function () { _this.refresh(); });
                };
                this.onViewRender = function (view) {
                    _this.$calendar = view.calendar;
                    _this.title = view.title;
                    var reload = true;
                    if (_this.state.dateRange) {
                        reload = !(_this.state.dateRange.contains(view.start) && _this.state.dateRange.contains(view.end));
                    }
                    var viewDate = view.start.clone();
                    if (view.type === 'month' && view.start.date() > 1) {
                        viewDate = viewDate.startOf('month').add(1, 'month');
                    }
                    _this.timeslipCalendarState.update({
                        viewType: view.type,
                        viewDate: viewDate
                    });
                    var start = view.start.local();
                    var end = view.end.local();
                    _this.visibleRange = moment.range(start, end);
                    _this.selectedRange = moment.range(start, start);
                    if (reload) {
                        _this.state.dateRange = _this.visibleRange;
                        _this.common.$timeout(_this.refresh);
                    }
                };
                this.setContentHeight = function () {
                    _this.$calendar.option('contentHeight', angular.element('#calendar').height());
                };
                'ngInject';
                'ngInject';
            }
            Calendar.prototype.createTimeslip = function (targetDate) {
                if (!this.minDate || (targetDate.isAfter(this.minDate) || this.canUnlockTimeslips)) {
                    this.openTimeslipDialog(targetDate, null);
                }
            };
            Calendar.prototype.$onChanges = function () {
                this.state.userIds = _.map(this.users, 'id');
                this.refresh();
            };
            Calendar.prototype.today = function () {
                this.$calendar.today();
            };
            Calendar.prototype.next = function () {
                this.$calendar.next();
            };
            Calendar.prototype.prev = function () {
                this.$calendar.prev();
            };
            Calendar.prototype.changeView = function (view) {
                this.$calendar.changeView(view);
            };
            Calendar.prototype.showFilters = function () {
                this.$mdSidenav('calendarFilters').toggle();
            };
            Calendar.prototype.$onInit = function () {
                var _this = this;
                this.canUnlockTimeslips = this.common.auth.checkPermissions('timeslips.unlock');
                this.timeslipCalendarData.getLastLock()
                    .then(function (result) {
                    if (result.lockDate) {
                        _this.minDate = moment(result.lockDate).add(1, 'days');
                    }
                });
                this.calendarOptions.viewRender = this.onViewRender;
                this.eventSources = this.timeslipCalendarData.eventSources;
                var cancelToken = this.$scope.$on('calendarEventAdded', this.refresh);
                this.$scope.$on('$destroy', cancelToken);
                this.common.$timeout(this.setContentHeight, 100);
                angular.element(this.common.$window).bind('resize', this.setContentHeight);
                this.initialized = true;
            };
            Calendar.$inject = ['$scope', '$mdPanel', '$mdSidenav', 'common', '$mdDialog', 'timeslipCalendarData', 'timeslipCalendarState'];
            Calendar = __decorate([
                app.Component('app.time', 'ceTimeslipCalendar', {
                    template: "<div layout>\n      <div class=\"md-toolbar-tools\" layout=\"row\">\n        <md-button class=\"md-icon-button\" ng-click=\"$ctrl.prev()\">\n          <md-tooltip>Previous</md-tooltip>\n          <md-icon>chevron_left</md-icon>\n        </md-button>\n        <div class=\"ce-title mb0 text-center\" style=\"width:200px;\">\n          {{$ctrl.title}}\n        </div>\n        <md-button class=\"md-icon-button\" ng-click=\"$ctrl.next()\">\n          <md-tooltip>Next</md-tooltip>\n          <md-icon>chevron_right</md-icon>\n        </md-button>\n        <span flex></span>\n        <md-button class=\"md-icon-button\" ng-click=\"$ctrl.today()\">\n          <md-tooltip>Today</md-tooltip>\n          <md-icon>today</md-icon>\n        </md-button>\n        <md-button class=\"md-icon-button\" ng-click=\"$ctrl.changeView('agendaWeek')\">\n          <md-tooltip>Week</md-tooltip>\n          <md-icon>view_week</md-icon>\n        </md-button>\n        <md-button class=\"md-icon-button\" ng-click=\"$ctrl.changeView('month')\">\n          <md-tooltip>Month</md-tooltip>\n          <md-icon>view_module</md-icon>\n        </md-button>\n      </div>\n    </div>\n    <md-divider></md-divider>\n    <md-content id=\"calendar\" style=\"max-height:700px;\" ui-calendar=\"$ctrl.calendarOptions\" class=\"calendar bg-white\" ng-model=\"$ctrl.eventSources\" calendar=\"calendar\"></md-content>\n    <md-button aria-label=\"menu\" class=\"md-fab md-fab-bottom-right\" ng-click=\"$ctrl.openTimeslipDialog()\" permission=\"['timeslips.edit']\">\n      <md-icon>add</md-icon>\n    </md-button>",
                    bindings: {
                        users: '<',
                        involvementId: '<',
                        source: '<',
                        sourceId: '<',
                        sourceName: '<',
                        sourceName2: '<'
                    }
                })
            ], Calendar);
            return Calendar;
        }());
    })(time = app.time || (app.time = {}));
})(app || (app = {}));
var app;
(function (app) {
    var time;
    (function (time) {
        var CalendarDataService = (function () {
            function CalendarDataService(common, datacontext, timeslipCalendarState) {
                var _this = this;
                this.common = common;
                this.datacontext = datacontext;
                this.timeslipCalendarState = timeslipCalendarState;
                this.state = this.timeslipCalendarState.state;
                this.refresh = function (source, sourceId, involvementId) {
                    var params = {
                        userIds: _this.state.userIds,
                        fromDate: _this.state.dateRange.start,
                        toDate: _this.state.dateRange.end,
                        source: source,
                        sourceId: sourceId,
                        involvementId: involvementId
                    };
                    _this.eventSources.splice(0);
                    if (source === 'Project' && _this.state.userIds.length < 1) {
                        return;
                    }
                    if (sourceId || _this.state.userIds.length > 0) {
                        _this.getTimeslips(params);
                    }
                };
                this.getTimeslips = function (params) {
                    _this.datacontext.time
                        .getTimeslipCalendarItems(params)
                        .then(function (result) {
                        if (result) {
                            _this.timeslips = result;
                            var reducedEvents = _(_this.reduceEvents(_this.timeslips));
                            var events = reducedEvents.map(function (e) { return _this.mapTimeslipSummary(e); }).value();
                            _this.eventSources.splice(0);
                            _this.eventSources.push({ events: events });
                        }
                    });
                };
                this.mapTimeslipSummary = function (timeslip) {
                    var start = moment(timeslip.date).startOf('day');
                    var end = start.endOf('day');
                    var color = 'rgb(33, 150, 243)';
                    var lockedColor = 'rgb(33, 150, 243, .5)';
                    return __assign(__assign({}, timeslip), { allDay: true, title: "".concat(timeslip.userInitials, " ").concat(timeslip.hours.toFixed(1), "h"), color: color, start: start, end: end });
                };
                'ngInject';
                'ngInject';
                this.eventSources = [];
            }
            CalendarDataService.prototype.reduceEvents = function (results) {
                return _.reduce(results, function (result, value, key) {
                    if (result === null) {
                        result = [__assign({}, value)];
                    }
                    else {
                        var timeslipSummary = _.find(result, function (r) { return r.date.toString() === value.date.toString() && r.userId === value.userId; });
                        if (timeslipSummary == null) {
                            result.push(__assign({}, value));
                        }
                        else {
                            timeslipSummary.hours += value.hours;
                        }
                    }
                    return result;
                }, null);
            };
            CalendarDataService.prototype.getLastLock = function () {
                return this.datacontext.time.getLastLock();
            };
            return CalendarDataService;
        }());
        angular
            .module('app.time')
            .service('timeslipCalendarData', CalendarDataService);
    })(time = app.time || (app.time = {}));
})(app || (app = {}));
var app;
(function (app) {
    var time;
    (function (time) {
        var TimeslipCalendarFrame = (function () {
            function TimeslipCalendarFrame($mdDialog, common, datacontext) {
                this.$mdDialog = $mdDialog;
                this.common = common;
                this.datacontext = datacontext;
                this.selectedUsers = [];
                'ngInject';
                'ngInject';
            }
            TimeslipCalendarFrame.prototype.$onInit = function () {
                if (this.enableFilters === undefined) {
                    this.enableFilters = true;
                }
            };
            TimeslipCalendarFrame.prototype.usersFilterChanged = function (users) {
                this.selectedUsers = __spreadArray([], users, true);
            };
            TimeslipCalendarFrame.prototype.toggleFilters = function () {
                this.showFilters = !this.showFilters;
            };
            TimeslipCalendarFrame.$inject = ['$mdDialog', 'common', 'datacontext'];
            TimeslipCalendarFrame = __decorate([
                app.Component('app.time', 'ceTimeslipCalendarFrame', {
                    templateUrl: 'app/time/calendar/timeslip-calendar.html',
                    bindings: {
                        enableFilters: '<',
                        involvementId: '<',
                        source: '@',
                        sourceId: '<',
                        sourceName: '<',
                        sourceName2: '<?',
                    }
                })
            ], TimeslipCalendarFrame);
            return TimeslipCalendarFrame;
        }());
    })(time = app.time || (app.time = {}));
})(app || (app = {}));
var app;
(function (app) {
    var time;
    (function (time) {
        var TimerService = (function () {
            function TimerService() {
                var _this = this;
                this.tenthsOfHours = 1;
                this.increment = function (currentDate) {
                    _this.currentDate = +Date.now();
                    var count = (_this.currentDate - _this.pauseOffset - +_this.startDate);
                    var seconds = Math.floor((count / 1000)) % 60;
                    var minutes = Math.floor((count / 60000)) % 60;
                    var hours = Math.floor((count / 3600000)) % 60;
                    var hoursDecimal = hours + (Math.ceil(minutes / 6)) / 10;
                    _this.hours = hoursDecimal > .1 ? hoursDecimal : .1;
                    _this.seconds++;
                    _this.setTimeString(hours, minutes, seconds);
                    _this.startTimer();
                };
            }
            TimerService.prototype.init = function (source, sourceId, sourceName, sourceName2, involvementId) {
                this.source = source;
                this.sourceId = sourceId;
                this.sourceName = sourceName;
                this.sourceName2 = sourceName2;
                this.involvementId = involvementId;
                this.seconds = 0;
                this.time = '00:00:00';
                this.tenthsOfHours = 1;
                this.hours = .1;
                this.ariaLabel = 'Timer started click to Pause.';
                this.startDate = new Date();
                this.currentDate = +new Date();
                this.delay = 1000;
                this.pauseOffset = 0;
            };
            TimerService.prototype.startTimer = function () {
                this.timeout = setTimeout(this.increment.bind(this.currentDate), 1000);
            };
            TimerService.prototype.restartTimer = function () {
                this.seconds = 0;
                this.time = '00:00:00';
                this.tenthsOfHours = 1;
                this.hours = .1;
                clearTimeout(this.timeout);
                this.startTimer();
            };
            TimerService.prototype.killTimer = function () {
                this.paused = false;
                this.source = null;
                this.sourceId = null;
                this.sourceName = null;
                this.sourceName2 = null;
                this.involvementId = null;
                this.seconds = 0;
                this.time = '00:00:00';
                this.tenthsOfHours = 0;
                this.hours = 0;
                clearTimeout(this.timeout);
            };
            TimerService.prototype.pause = function () {
                clearTimeout(this.timeout);
                if (this.paused) {
                    this.endPause = new Date();
                    this.pauseOffset += +this.endPause - +this.startPause;
                    this.startTimer();
                    this.ariaLabel = 'Timer started at ' + this.time + ' click to pause';
                }
                else {
                    this.ariaLabel = 'Timer paused at ' + this.time + ' click to start';
                    this.startPause = new Date();
                }
                this.paused = !this.paused;
            };
            TimerService.prototype.setTimeString = function (hours, minutes, seconds) {
                this.time = this.padTime(hours) + ':' + this.padTime(minutes) + ':' + this.padTime(seconds);
            };
            TimerService.prototype.padTime = function (value) {
                switch (true) {
                    case (value === 0): {
                        return '00';
                    }
                    case (value < 10): {
                        return '0' + value;
                    }
                    default: {
                        return value.toString();
                    }
                }
            };
            TimerService = __decorate([
                app.Service('app.time', 'timerService')
            ], TimerService);
            return TimerService;
        }());
    })(time = app.time || (app.time = {}));
})(app || (app = {}));
var app;
(function (app) {
    var time;
    (function (time) {
        var setupTransitionHandler = function ($transitions, $mdDialog, $q) {
            'ngInject';
            $transitions.onStart({}, function (transition) {
                var $injector = transition.injector();
                var timerService = $injector.get('timerService');
                var targetState = transition.targetState();
                var timerPromise = $q.defer();
                if (timerService.hours > 0 && moduleChange(transition.$to().name, transition.$from().name)) {
                    askAboutTimeslip().then(function (buttonText) { processButtonText(buttonText); }, function () { resetTimerAndNavigate(); })
                        .catch(function (error) { if (error)
                        throw error; });
                    return timerPromise.promise.catch(function () { transition.abort(); });
                }
                function moduleChange(fromState, toState) {
                    var fromStateSplit = fromState.split('.');
                    var toStateSplit = toState.split('.');
                    if (fromStateSplit[1] !== toStateSplit[1]) {
                        return true;
                    }
                    else {
                        return false;
                    }
                }
                function askAboutTimeslip() {
                    return $mdDialog.show({
                        fullscreen: true,
                        templateUrl: 'app/utils/ternary-confirmation/ternary-confirmation.html',
                        controller: 'TernaryConfirmationDialogController',
                        controllerAs: '$ctrl',
                        locals: {
                            title: 'Create Timeslip?',
                            message: 'You are leaving this page with a timer running. Do you want to create a timeslip first?',
                            submitButtonText: 'Yes/Enter',
                            submitButtonTooltip: 'Hit Enter to Create a Timeslip then leave',
                            middleButtonText: 'No/Esc',
                            middleButtonTooltip: 'Hit Esc to skip creating a timeslip, continue to the page you clicked on',
                            leftButtonText: 'Cancel',
                            leftButtonTooltip: 'Don\'t leave, stay on this page. You must open links in a new tab to keep the timer running'
                        }
                    });
                }
                function processButtonText(buttonText) {
                    switch (buttonText) {
                        case 'Yes/Enter': {
                            showTimeslipDialog();
                            break;
                        }
                        case 'No/Esc': {
                            resetTimerAndNavigate();
                            break;
                        }
                        default: {
                            stayOnCurrentPage();
                            break;
                        }
                    }
                }
                function showTimeslipDialog() {
                    $mdDialog.show({
                        fullscreen: true,
                        templateUrl: 'app/time/timeslip-dialog/timeslip-dialog.html',
                        controller: 'TimeslipDialogController',
                        controllerAs: '$ctrl',
                        locals: {
                            id: null,
                            involvementId: timerService.involvementId,
                            source: timerService.source,
                            sourceId: timerService.sourceId,
                            sourceName: timerService.sourceName,
                            sourceName2: timerService.sourceName2,
                            hours: timerService.hours,
                            date: moment().toDate(),
                            fundingProgramId: null
                        }
                    }).then(function () { return resetTimerAndNavigate(); }, function () { return stayOnCurrentPage(); })
                        .catch(function (error) { if (error)
                        throw error; });
                }
                function stayOnCurrentPage() {
                    timerPromise.reject();
                }
                function resetTimerAndNavigate() {
                    timerService.killTimer();
                    timerPromise.resolve();
                }
            });
        };
        angular
            .module('app.time')
            .run(setupTransitionHandler);
    })(time = app.time || (app.time = {}));
})(app || (app = {}));
var app;
(function (app) {
    var time;
    (function (time) {
        var Timer = (function () {
            function Timer(timerService, auth) {
                this.timerService = timerService;
                this.auth = auth;
                this.timeslipTimerEnabled = false;
                'ngInject';
                'ngInject';
            }
            Timer.prototype.$onInit = function () {
                this.timeslipTimerEnabled = this.auth.user.timeslipTimerEnabled;
                if (this.timeslipTimerEnabled) {
                    this.timerService.init(this.source, this.sourceId, this.sourceName, this.sourceName2, this.involvementId);
                    this.timerService.startTimer();
                }
            };
            Timer.$inject = ['timerService', 'auth'];
            Timer = __decorate([
                app.Component('app.time', 'ceTimer', {
                    templateUrl: 'app/time/timer/timer.html',
                    bindings: {
                        source: '@',
                        sourceId: '<',
                        sourceName: '<',
                        sourceName2: '<',
                        involvementId: '<'
                    }
                })
            ], Timer);
            return Timer;
        }());
    })(time = app.time || (app.time = {}));
})(app || (app = {}));
var app;
(function (app) {
    var time;
    (function (time) {
        var TimeslipList = (function () {
            function TimeslipList($mdDialog, common, datacontext) {
                this.$mdDialog = $mdDialog;
                this.common = common;
                this.datacontext = datacontext;
                'ngInject';
                'ngInject';
            }
            TimeslipList.prototype.$onInit = function () {
                var _this = this;
                this.datacontext.users.getCurrentUser().then(function (user) { return _this.currentUserId = user.id; });
                this.canUnlockTimeslips = this.common.auth.checkPermissions('timeslips.unlock');
            };
            TimeslipList.prototype.$onChanges = function () {
                if (this.timeslips) {
                    this.hoursSum = _.sumBy(this.timeslips.list, 'hours').toFixed(1);
                }
            };
            TimeslipList.prototype.showTimeslip = function (timeslip) {
                var parent = angular.element(document.body);
                return this.$mdDialog.show({
                    fullscreen: true,
                    clickOutsideToClose: true,
                    templateUrl: 'app/time/timeslip-dialog/timeslip-view-dialog.html',
                    controller: 'TimeslipViewDialogController',
                    controllerAs: '$ctrl',
                    locals: { timeslip: timeslip }
                });
            };
            TimeslipList.$inject = ['$mdDialog', 'common', 'datacontext'];
            TimeslipList = __decorate([
                app.Component('app.time', 'ceTimeslipList', {
                    templateUrl: 'app/time/timeslip-listing/timeslip-list.html',
                    bindings: {
                        timeslips: '<',
                        editTimeslip: '&',
                        showDeleteDialog: '&',
                        refresh: '&',
                        paging: '<',
                        promise: '<'
                    }
                })
            ], TimeslipList);
            return TimeslipList;
        }());
    })(time = app.time || (app.time = {}));
})(app || (app = {}));
var app;
(function (app) {
    var time;
    (function (time) {
        var TimeslipListing = (function () {
            function TimeslipListing($mdDialog, confirmation, common, datacontext, blobHelper) {
                var _this = this;
                this.$mdDialog = $mdDialog;
                this.confirmation = confirmation;
                this.common = common;
                this.datacontext = datacontext;
                this.blobHelper = blobHelper;
                this.params = {};
                this.selectedUsers = {};
                this.today = new Date();
                this.paging = {
                    page: 1,
                    pageSize: 100
                };
                this.refresh = function () {
                    _this.getTimeslips();
                };
                this.export = function () {
                    var params = _this.getParams();
                    _this.promise = _this.datacontext.time
                        .getTimeslipListingDownload(params)
                        .then(function (result) {
                        if (result) {
                            _this.blobHelper.open({
                                buffer: result.buffer,
                                fileName: result.fileName,
                                contentType: result.contentType
                            });
                        }
                    });
                };
                'ngInject';
                'ngInject';
            }
            TimeslipListing.prototype.$onInit = function () {
                if (this.enableFilters === undefined) {
                    this.enableFilters = true;
                }
                if (this.enableDateFilter === undefined) {
                    this.enableDateFilter = true;
                }
                if (this.enableFilters && this.enableDateFilter) {
                    this.fromDate = new Date(this.today.getFullYear(), this.today.getMonth(), 1);
                    this.toDate = new Date(this.today.getFullYear(), this.today.getMonth(), this.today.getDate());
                }
                else {
                    this.getTimeslips();
                }
            };
            TimeslipListing.prototype.usersFilterChanged = function (users) {
                this.userFilterInitialized = true;
                this.selectedUsers = __assign({}, users);
                this.getTimeslips();
            };
            TimeslipListing.prototype.dateFilterChanged = function (dates) {
                this.dateFilterInitialized = true;
                this.fromDate = dates.fromDate;
                this.toDate = dates.toDate;
                this.getTimeslips();
            };
            TimeslipListing.prototype.toggleFilters = function () {
                this.showFilters = !this.showFilters;
            };
            TimeslipListing.prototype.filtersInitialized = function () {
                return this.userFilterInitialized && this.dateFilterInitialized;
            };
            TimeslipListing.prototype.createTimeslip = function () {
                this.openTimeslipDialog(null);
            };
            TimeslipListing.prototype.editTimeslip = function (id) {
                this.openTimeslipDialog(id);
            };
            TimeslipListing.prototype.showDeleteDialog = function (id) {
                var _this = this;
                this.confirmation.show({
                    message: 'Are you sure you want to delete this timeslip?',
                    ok: 'DELETE',
                    cancel: 'KEEP'
                }).then(function () { _this.deleteTimeslip(id); });
            };
            TimeslipListing.prototype.deleteTimeslip = function (id) {
                var _this = this;
                this.datacontext.time.deleteTimeslip(id).then(function () { return _this.refresh(); });
            };
            TimeslipListing.prototype.openTimeslipDialog = function (id) {
                var _this = this;
                var parent = angular.element(document.body);
                return this.$mdDialog.show({
                    parent: parent,
                    fullscreen: true,
                    templateUrl: 'app/time/timeslip-dialog/timeslip-dialog.html',
                    controller: 'TimeslipDialogController',
                    controllerAs: '$ctrl',
                    focusOnOpen: false,
                    locals: {
                        id: id,
                        involvementId: this.involvementId,
                        hours: null,
                        source: this.source,
                        sourceId: this.sourceId,
                        sourceName: this.sourceName,
                        date: moment().toDate(),
                        fundingProgramId: this.fundingProgramId,
                        sourceName2: this.sourceName2
                    }
                })
                    .catch(function (error) { if (error)
                    throw error; })
                    .finally(function () { _this.getTimeslips(); });
            };
            TimeslipListing.prototype.getParams = function () {
                var userIds = _.map(this.selectedUsers, 'id');
                if (userIds.length === 0 && this.enableFilters) {
                    this.timeslips = __assign({ count: 0, list: [] }, this.paging);
                    return;
                }
                var fromDate = null;
                if (this.fromDate && typeof this.fromDate !== 'string') {
                    fromDate = this.fromDate.toDateString();
                }
                var toDate = null;
                if (this.toDate && typeof this.toDate !== 'string') {
                    toDate = this.toDate.toDateString();
                }
                var params = __assign({ fromDate: fromDate, toDate: toDate, userIds: userIds, involvementId: this.involvementId, source: this.showInvolvementTimeslips ? null : this.source, sourceId: this.showInvolvementTimeslips ? null : this.sourceId }, this.paging);
                return params;
            };
            TimeslipListing.prototype.getTimeslips = function () {
                var _this = this;
                if (this.enableFilters) {
                    if (!this.filtersInitialized()) {
                        return;
                    }
                }
                var params = this.getParams();
                this.promise = this.datacontext.time
                    .getTimeslipListing(params)
                    .then(function (result) {
                    _this.timeslips = result;
                });
            };
            TimeslipListing.prototype.getInvolvementTimeslips = function () {
                this.showInvolvementTimeslips = !this.showInvolvementTimeslips;
                this.getTimeslips();
            };
            TimeslipListing.$inject = ['$mdDialog', 'confirmation', 'common', 'datacontext', 'blobHelper'];
            TimeslipListing = __decorate([
                app.Component('app.time', 'ceTimeslipListing', {
                    templateUrl: 'app/time/timeslip-listing/timeslip-listing.html',
                    bindings: {
                        involvementId: '<',
                        source: '@',
                        sourceId: '<',
                        sourceName: '<',
                        sourceName2: '<?',
                        enableFilters: '<?',
                        enableDateFilter: '<?',
                        showCalendarIcon: '<',
                        fundingProgramId: '<'
                    }
                })
            ], TimeslipListing);
            return TimeslipListing;
        }());
    })(time = app.time || (app.time = {}));
})(app || (app = {}));
var app;
(function (app) {
    var time;
    (function (time) {
        var TimeslipButtonComponent = (function () {
            function TimeslipButtonComponent($mdDialog, $scope) {
                this.$mdDialog = $mdDialog;
                this.$scope = $scope;
                'ngInject';
                'ngInject';
            }
            TimeslipButtonComponent.prototype.openTimeslipDialog = function () {
                var _this = this;
                var parent = angular.element(document.body);
                return this.$mdDialog.show({
                    parent: parent,
                    fullscreen: true,
                    templateUrl: 'app/time/timeslip-dialog/timeslip-dialog.html',
                    controller: 'TimeslipDialogController',
                    controllerAs: '$ctrl',
                    focusOnOpen: false,
                    locals: {
                        id: null,
                        involvementId: null,
                        hours: null,
                        source: null,
                        sourceId: null,
                        sourceName: null,
                        date: moment().toDate(),
                        fundingProgramId: null,
                        sourceName2: null
                    }
                })
                    .catch(function (error) { if (error)
                    throw error; })
                    .finally(function () { _this.refresh(); });
            };
            TimeslipButtonComponent.$inject = ['$mdDialog', '$scope'];
            TimeslipButtonComponent = __decorate([
                app.Component('app.time', 'amTimeslipButton', {
                    template: "\n      <md-button ng-click=\"$ctrl.openTimeslipDialog()\" permission=\"['timeslips.edit']\">\n        <md-tooltip>Add Timeslip</md-tooltip>\n        <md-icon aria-hidden=\"true\">add</md-icon>\n      </md-button>",
                    bindings: {
                        refresh: '&'
                    }
                })
            ], TimeslipButtonComponent);
            return TimeslipButtonComponent;
        }());
    })(time = app.time || (app.time = {}));
})(app || (app = {}));
var app;
(function (app) {
    var time;
    (function (time) {
        var EditTimeslipButtonComponent = (function () {
            function EditTimeslipButtonComponent($mdDialog, $scope) {
                this.$mdDialog = $mdDialog;
                this.$scope = $scope;
                'ngInject';
                'ngInject';
            }
            EditTimeslipButtonComponent.prototype.openTimeslipDialog = function (id) {
                var _this = this;
                var parent = angular.element(document.body);
                return this.$mdDialog.show({
                    parent: parent,
                    fullscreen: true,
                    templateUrl: 'app/time/timeslip-dialog/timeslip-dialog.html',
                    controller: 'TimeslipDialogController',
                    controllerAs: '$ctrl',
                    focusOnOpen: false,
                    locals: {
                        id: this.id,
                        involvementId: null,
                        hours: null,
                        source: null,
                        sourceId: null,
                        sourceName: null,
                        date: moment().toDate(),
                        fundingProgramId: null,
                        sourceName2: null
                    }
                })
                    .catch(function (error) { if (error)
                    throw error; })
                    .finally(function () { _this.refresh(); });
            };
            EditTimeslipButtonComponent.$inject = ['$mdDialog', '$scope'];
            EditTimeslipButtonComponent = __decorate([
                app.Component('app.time', 'amEditTimeslipButton', {
                    template: "\n      <md-button class=\"ml0 pl0\" style=\"font-weight:normal;text-transform:none;\" ng-click=\"$ctrl.openTimeslipDialog()\">\n        <md-icon aria-hidden=\"true\">edit</md-icon>\n        <span class=\"pl-lg\">Edit Timeslip</span>\n      </md-button>",
                    bindings: {
                        id: '<',
                        refresh: '&'
                    }
                })
            ], EditTimeslipButtonComponent);
            return EditTimeslipButtonComponent;
        }());
    })(time = app.time || (app.time = {}));
})(app || (app = {}));
var app;
(function (app) {
    var time;
    (function (time) {
        var TimeslipDialogController = (function () {
            function TimeslipDialogController($scope, $mdDialog, common, datacontext, id, hours, source, sourceId, sourceName, involvementId, fundingProgramId, date, sourceName2) {
                if (sourceName2 === void 0) { sourceName2 = ''; }
                var _this = this;
                this.$scope = $scope;
                this.$mdDialog = $mdDialog;
                this.common = common;
                this.datacontext = datacontext;
                this.id = id;
                this.hours = hours;
                this.source = source;
                this.sourceId = sourceId;
                this.sourceName = sourceName;
                this.involvementId = involvementId;
                this.fundingProgramId = fundingProgramId;
                this.date = date;
                this.sourceName2 = sourceName2;
                this.timeslip = {};
                this.setDefaultField = function () {
                    var hours = angular.element(document).find('#hours');
                    if (!_this.hours) {
                        hours.focus();
                    }
                };
                'ngInject';
                'ngInject';
            }
            TimeslipDialogController.prototype.$onInit = function () {
                this.timeslip.date = new Date(this.date.getFullYear(), this.date.getMonth(), this.date.getDate()) || moment().startOf('day').toDate();
                this.getCurrentTotal(this.timeslip.date);
                this.timeslip.hours = this.hours;
                this.defaultFundingProgramId = this.fundingProgramId;
                this.maxDate = moment().add(1, 'years').toDate();
                if (!this.common.auth.checkPermissions('timeslips.unlock')) {
                    this.getLastLock();
                }
                if (this.id) {
                    this.loadTimeslip();
                }
                else if (this.source) {
                    this.sourceProvided = true;
                    this.sourceDescription = this.sourceName + ' ' + this.sourceName2;
                    this.setSource({
                        source: this.source,
                        sourceId: this.sourceId,
                        involvementId: this.involvementId,
                        sourceDescription: this.sourceDescription
                    });
                }
                else {
                    this.getFundingPrograms();
                }
                setTimeout(this.setDefaultField, 100);
            };
            TimeslipDialogController.prototype.onSourceSelected = function (source) {
                this.selectedSource = source;
                this.getFundingPrograms();
            };
            TimeslipDialogController.prototype.getLastLock = function () {
                var _this = this;
                this.datacontext.time
                    .getLastLock()
                    .then(function (result) {
                    if (result.lockDate) {
                        _this.minDate = moment(result.lockDate).add(1, 'days').toDate();
                    }
                });
            };
            TimeslipDialogController.prototype.loadTimeslip = function () {
                var _this = this;
                this.datacontext.time.getTimeslip(this.id)
                    .then(function (timeslip) {
                    _this.timeslip = timeslip;
                    _this.originalFundingProgramId = timeslip.fundingProgramId;
                    _this.setSourceFromTimeslip(timeslip);
                    _this.setTask({ id: timeslip.taskId, name: timeslip.taskName });
                });
            };
            TimeslipDialogController.prototype.cancel = function () {
                this.$mdDialog.cancel();
            };
            TimeslipDialogController.prototype.getCurrentTotal = function (date) {
                var _this = this;
                if (typeof date !== 'string')
                    date = date.toDateString();
                this.datacontext.time.getCurrentTotal(this.common.auth.user.id, date)
                    .then(function (currentTotal) {
                    _this.currentTotal = currentTotal.hours;
                });
            };
            TimeslipDialogController.prototype.getFundingPrograms = function () {
                var _this = this;
                this.fundingPrograms = [];
                this.timeslip.fundingProgramId = null;
                if (!this.selectedSource) {
                    return;
                }
                this.datacontext.funding
                    .getFundingProgramsBySource(this.timeslip.date, this.selectedSource.source, this.selectedSource.sourceId, this.selectedSource.involvementId)
                    .then(function (results) {
                    if (results && results.length > 0) {
                        _this.fundingPrograms = __spreadArray([], results, true);
                        var validFundingProgramFound = void 0;
                        var validTimeslipFundingProgram = _.find(_this.fundingPrograms, { 'fundingProgramId': _this.originalFundingProgramId });
                        if (validTimeslipFundingProgram) {
                            _this.timeslip.fundingProgramId = validTimeslipFundingProgram.fundingProgramId;
                            validFundingProgramFound = true;
                        }
                        var validDefaultFundingProgram = _.find(_this.fundingPrograms, { 'fundingProgramId': _this.defaultFundingProgramId });
                        if (_this.timeslip.fundingProgramId === null && validDefaultFundingProgram) {
                            _this.timeslip.fundingProgramId = validDefaultFundingProgram.fundingProgramId;
                            validFundingProgramFound = true;
                        }
                        if (_this.source === 'Request') {
                            _this.timeslip.fundingProgramId = null;
                        }
                        else if (!validFundingProgramFound) {
                            _this.timeslip.fundingProgramId = _this.fundingPrograms[0].fundingProgramId;
                        }
                    }
                }).then(function () {
                    if (_this.selectedSource && _this.selectedSource.source === 'Project' && !_this.defaultFundingProgramId) {
                        _this.datacontext.projects.getProject(_this.selectedSource.sourceId).then(function (project) {
                            _this.timeslip.fundingProgramId = project.fundingProgramId;
                        });
                    }
                });
            };
            TimeslipDialogController.prototype.dateChanged = function () {
                this.getFundingPrograms();
                this.getCurrentTotal(this.timeslip.date);
            };
            TimeslipDialogController.prototype.setSourceFromTimeslip = function (slip) {
                this.source = slip.source;
                this.sourceId = slip.sourceId;
                this.involvementId = slip.involvementId;
                this.sourceDescription = "".concat(slip.sourceName, " ").concat(slip.sourceName2 || '');
                this.setSource({ source: this.source, sourceId: this.sourceId, involvementId: this.involvementId });
            };
            TimeslipDialogController.prototype.setSource = function (source) {
                this.selectedSource = source;
                this.getFundingPrograms();
            };
            TimeslipDialogController.prototype.taskSearch = function (searchText) {
                return this.datacontext.time
                    .getTasks(searchText);
            };
            TimeslipDialogController.prototype.setTask = function (task) {
                this.selectedTask = task;
            };
            TimeslipDialogController.prototype.submit = function () {
                var _this = this;
                this.isSubmitting = true;
                angular.extend(this.timeslip, { source: this.selectedSource.source, sourceId: this.selectedSource.sourceId, involvementId: this.selectedSource.involvementId });
                angular.extend(this.timeslip, { taskId: this.selectedTask.id });
                if (typeof this.timeslip.date !== 'string') {
                    this.timeslip.date = this.timeslip.date.toDateString();
                }
                var timePromise = this.id ?
                    this.datacontext.time.updateTimeslip(this.timeslip) :
                    this.datacontext.time.createTimeslip(this.timeslip);
                timePromise.then(function (timeslipId) {
                    if (timeslipId) {
                        _this.common.$mdToast.showSimple('Timeslip ' + (_this.id ? 'updated' : 'created'));
                        _this.submitted();
                    }
                })
                    .finally(function () { return _this.isSubmitting = false; });
            };
            TimeslipDialogController.prototype.toggleKeepOpen = function () {
                if (this.keepOpen === false) {
                    this.keepHoursTaskDescription = false;
                    this.keepSource = false;
                }
            };
            TimeslipDialogController.prototype.toggleKeepOpenChild = function () {
                if (this.keepHoursTaskDescription === true || this.keepSource === true) {
                    this.keepOpen = true;
                }
            };
            TimeslipDialogController.prototype.reset = function () {
                this.timeslip.id = null;
                if (this.keepHoursTaskDescription !== true) {
                    this.timeslip.hours = null;
                    this.selectedTask = null;
                    this.timeslip.description = null;
                    angular.element(document).find('#hours').focus();
                }
                else {
                    angular.element(document).find('#sourceSearch').focus();
                }
                if (!this.sourceProvided && !this.keepSource) {
                    this.selectedSource = null;
                    this.$scope.$broadcast('ResetSourceSearch');
                }
            };
            TimeslipDialogController.prototype.submitted = function () {
                this.getCurrentTotal(this.timeslip.date);
                if (!this.keepOpen) {
                    this.$mdDialog.hide(this.timeslip);
                }
                else {
                    this.reset();
                }
            };
            TimeslipDialogController.$inject = ['$scope', '$mdDialog', 'common', 'datacontext', 'id', 'hours', 'source', 'sourceId', 'sourceName', 'involvementId', 'fundingProgramId', 'date', 'sourceName2'];
            TimeslipDialogController = __decorate([
                app.Controller('app.time', 'TimeslipDialogController')
            ], TimeslipDialogController);
            return TimeslipDialogController;
        }());
    })(time = app.time || (app.time = {}));
})(app || (app = {}));
var app;
(function (app) {
    var time;
    (function (time) {
        var TimeslipViewDialogController = (function () {
            function TimeslipViewDialogController($mdDialog, timeslip) {
                this.$mdDialog = $mdDialog;
                this.timeslip = timeslip;
                'ngInject';
                'ngInject';
            }
            TimeslipViewDialogController.prototype.cancel = function () {
                this.$mdDialog.cancel();
            };
            TimeslipViewDialogController.$inject = ['$mdDialog', 'timeslip'];
            TimeslipViewDialogController = __decorate([
                app.Controller('app.time', 'TimeslipViewDialogController')
            ], TimeslipViewDialogController);
            return TimeslipViewDialogController;
        }());
    })(time = app.time || (app.time = {}));
})(app || (app = {}));
var app;
(function (app) {
    var time;
    (function (time) {
        var TimeslipSummaryDialogController = (function () {
            function TimeslipSummaryDialogController($scope, $mdDialog, common, datacontext, timeslips) {
                this.$scope = $scope;
                this.$mdDialog = $mdDialog;
                this.common = common;
                this.datacontext = datacontext;
                this.timeslips = timeslips;
                'ngInject';
                'ngInject';
            }
            TimeslipSummaryDialogController.prototype.$onInit = function () {
                this.userName = "".concat(this.timeslips[0].userFirstName, " ").concat(this.timeslips[0].userLastName);
                this.date = this.timeslips[0].date;
            };
            TimeslipSummaryDialogController.prototype.edit = function (id) {
                this.$mdDialog.hide(id);
            };
            TimeslipSummaryDialogController.prototype.cancel = function () {
                this.$mdDialog.cancel();
            };
            TimeslipSummaryDialogController.$inject = ['$scope', '$mdDialog', 'common', 'datacontext', 'timeslips'];
            TimeslipSummaryDialogController = __decorate([
                app.Controller('app.time', 'TimeslipSummaryDialogController')
            ], TimeslipSummaryDialogController);
            return TimeslipSummaryDialogController;
        }());
    })(time = app.time || (app.time = {}));
})(app || (app = {}));
var app;
(function (app) {
    var training;
    (function (training) {
        var cle;
        (function (cle) {
            'use strict';
            cle.cleBasicInfoResolver = function (datacontext, $stateParams) {
                'ngInject';
                var cleId = $stateParams.cleId;
                return datacontext.training.getCLEInformation(cleId);
            };
        })(cle = training.cle || (training.cle = {}));
    })(training = app.training || (app.training = {}));
})(app || (app = {}));
var app;
(function (app) {
    var training;
    (function (training) {
        var cle;
        (function (cle) {
            'use strict';
            var routes = [
                {
                    name: 'app.createCLE',
                    url: '/cle-courses/create',
                    template: '<ce-c-l-e-create layout="column" flex></ce-c-l-e-create>',
                    data: {
                        title: 'Create CLE',
                        permissions: ['cle-courses.edit']
                    }
                },
                {
                    name: 'app.cle',
                    url: '/cle-courses/{cleId}',
                    template: '<ce-c-l-e-view data="$resolve.cle" layout="column" flex></ce-c-l-e-view>',
                    redirectTo: 'app.cle.overview',
                    data: { permissions: ['cle-courses.view'] },
                    resolve: { cle: cle.cleBasicInfoResolver }
                },
                {
                    name: 'app.cle.overview',
                    url: '/overview',
                    template: '<ce-c-l-e-overview data="$resolve.cle" layout="column" flex></ce-c-l-e-overview>',
                    data: {
                        title: 'CLE Overview',
                        permissions: ['cle-courses.view']
                    }
                },
                {
                    name: 'app.cle.details',
                    url: '/details',
                    template: '<ce-c-l-e-details data="$resolve.cle" layout="column" flex></ce-c-l-e-details>',
                    data: {
                        title: 'CLE Details',
                        permissions: ['cle-courses.view']
                    }
                },
                {
                    name: 'app.cle.documents',
                    url: '/documents',
                    template: '<ce-documents-list layout="column" flex source="CLE" source-id="$resolve.cle.id"></ce-documents-list>',
                    data: {
                        title: 'CLE Documents',
                        permissions: ['documents.view']
                    }
                },
                {
                    name: 'app.cle.uploadDocuments',
                    url: '/upload',
                    params: {
                        folderId: null
                    },
                    resolve: {
                        folderId: ['$stateParams', function (p) { return p.folderId; }],
                    },
                    template: '<ce-upload-documents-activity layout flex folder-id="$resolve.folderId" source="CLE" source-id="$resolve.cle.id"></ce-upload-documents-activity>',
                    data: { permissions: ['documents.edit'] }
                },
                {
                    name: 'app.cle.emails',
                    url: '/emails',
                    template: '<ui-view flex layout="column"></ui-view>',
                    redirectTo: 'app.cle.emails.listing',
                    data: {
                        title: 'CLE Emails',
                        permissions: ['mail.view']
                    }
                },
                {
                    name: 'app.cle.emails.listing',
                    url: '',
                    template: '<ce-email-listing source="CLE" source-id="$resolve.cle.id" layout="column" flex></ce-email-listing>',
                    data: {
                        title: 'CLE Emails',
                        permissions: ['mail.view']
                    }
                },
                {
                    name: 'app.cle.emails.message',
                    url: '/{messageId}',
                    template: '<ce-email-view layout="column" flex message-id="$resolve.messageId"></ce-email-view>',
                    data: {
                        title: 'Message',
                        permissions: ['mail.view']
                    },
                    resolve: {
                        messageId: ['$stateParams', function (p) { return p.messageId; }]
                    }
                },
                {
                    name: 'app.cle.emails.link',
                    url: '/link',
                    template: '<ce-link-emails layout="column" flex source="CLE" source-id="$resolve.cle.id"></ce-link-emails>',
                    data: {
                        title: 'Inbox Messages',
                        permissions: ['mail.link']
                    }
                },
                {
                    name: 'app.cle.timeslipListing',
                    url: '/timeslips',
                    template: '<ce-timeslip-listing source="CLE" source-id="$resolve.cle.id" source-name="$resolve.cle.title" enable-filters="false" layout="column" flex></ce-timeslip-listing>',
                    data: {
                        title: 'CLE Timeslips',
                        permissions: ['timeslips.view']
                    }
                },
                {
                    name: 'app.cle.timeslipCalendar',
                    url: '/timeslips-calendar',
                    template: '<ce-timeslip-calendar-frame source="CLE" source-id="$resolve.cle.id" source-name="$resolve.cle.title" enable-filters="false" layout="column" flex></ce-timeslip-calendar-frame>',
                    data: {
                        title: 'CLE Timeslips',
                        permissions: ['timeslips.view']
                    }
                },
                {
                    name: 'app.cle.funding',
                    url: '/funding',
                    template: '<ce-training-funding training-id="$resolve.cle.id" source="CLE" layout="column" flex></ce-training-funding>',
                    data: { title: 'Training Funding' }
                },
                {
                    name: 'app.cle.calendar',
                    url: '/calendar',
                    template: '<ce-c-l-e-calendar source="CLE" source-id="$resolve.cle.id" layout="column" flex></ce-c-l-e-calendar>',
                    data: {
                        title: 'Calendar',
                    }
                },
                {
                    name: 'app.cle.eventSchedule',
                    url: '/eventSchedule',
                    template: "<ce-calendar-event-schedule\n                     event-schedule-id=\"$resolve.eventScheduleId\"\n                     target-date=\"$resolve.targetDate\"\n                     source=\"CLE\"\n                     source-name=\"$resolve.cle.title\"\n                     source-id=\"$resolve.cle.id\"></ce-calendar-event-schedule>",
                    params: {
                        eventScheduleId: null,
                        targetDate: null
                    },
                    resolve: {
                        eventScheduleId: ['$stateParams', function (p) { return p.eventScheduleId; }],
                        targetDate: ['$stateParams', function (p) { return p.targetDate; }],
                    },
                    data: { title: 'Event Schedule', permissions: ['events.view'], }
                },
                {
                    name: 'app.cle.event',
                    url: '/event',
                    template: '<ce-calendar-event event="$resolve.event"></ce-calendar-event>',
                    params: {
                        event: null
                    },
                    resolve: {
                        event: ['$stateParams', function (e) { return e.event; }]
                    },
                    data: {
                        title: 'Event',
                        permissions: ['events.view']
                    }
                },
            ];
            var runner = function (routingHelper) {
                'ngInject';
                routingHelper.configureRoutes(routes);
            };
            angular
                .module('app.training.cle')
                .run(runner);
        })(cle = training.cle || (training.cle = {}));
    })(training = app.training || (app.training = {}));
})(app || (app = {}));
var app;
(function (app) {
    var training;
    (function (training) {
        var funding;
        (function (funding) {
            var TrainingFunding = (function () {
                function TrainingFunding(datacontext) {
                    this.datacontext = datacontext;
                    this.fundingPrograms = [];
                    this.editFundingPrograms = false;
                    'ngInject';
                    'ngInject';
                }
                TrainingFunding.prototype.$onInit = function () {
                    this.getFundingPrograms();
                };
                TrainingFunding.prototype.getFundingPrograms = function () {
                    var _this = this;
                    this.datacontext.funding.getTrainingFundingPrograms(this.trainingId)
                        .then(function (result) {
                        _this.fundingPrograms = result;
                    });
                };
                TrainingFunding.prototype.updateFundingPrograms = function (result) {
                    var _this = this;
                    this.fundingPrograms = result;
                    var fundingProgramIds = _.map(this.fundingPrograms, 'fundingProgramId');
                    this.datacontext.funding.setTrainingFundingPrograms(this.trainingId, fundingProgramIds)
                        .then(function (response) {
                        _this.editFundingPrograms = false;
                    });
                };
                TrainingFunding.$inject = ['datacontext'];
                TrainingFunding = __decorate([
                    app.Component('app.training.funding', 'ceTrainingFunding', {
                        templateUrl: 'app/training/funding/funding.html',
                        bindings: {
                            trainingId: '<',
                            source: '@'
                        }
                    })
                ], TrainingFunding);
                return TrainingFunding;
            }());
        })(funding = training.funding || (training.funding = {}));
    })(training = app.training || (app.training = {}));
})(app || (app = {}));
var app;
(function (app) {
    var training;
    (function (training) {
        var request;
        (function (request) {
            'use strict';
            request.requestBasicInfoResolver = function (datacontext, $stateParams) {
                'ngInject';
                var requestId = $stateParams.requestId;
                return datacontext.training.getRequestInformation(requestId);
            };
        })(request = training.request || (training.request = {}));
    })(training = app.training || (app.training = {}));
})(app || (app = {}));
var app;
(function (app) {
    var training;
    (function (training) {
        var request;
        (function (request) {
            'use strict';
            var routes = [
                {
                    name: 'app.createTrainingRequest',
                    url: '/training-requests/create',
                    template: '<ce-training-request-create layout="column" flex></ce-training-request-create>',
                    data: {
                        title: 'Create Request',
                        permissions: ['training-requests.edit']
                    }
                },
                {
                    name: 'app.trainingRequest',
                    url: '/training-requests/{requestId}',
                    template: '<ce-training-request-view data="$resolve.request" layout="column" flex></ce-training-request-view>',
                    redirectTo: 'app.trainingRequest.overview',
                    data: { permissions: ['training-requests.view'] },
                    resolve: { request: request.requestBasicInfoResolver }
                },
                {
                    name: 'app.trainingRequest.overview',
                    url: '/overview',
                    template: '<ce-training-request-overview data="$resolve.request" layout="column" flex></ce-training-request-overview>',
                    data: {
                        title: 'Request Overview',
                        permissions: ['training-requests.view']
                    }
                },
                {
                    name: 'app.trainingRequest.details',
                    url: '/details',
                    template: '<ce-training-request-details data="$resolve.request" layout="column" flex></ce-training-request-details>',
                    data: {
                        title: 'Request Details',
                        permissions: ['training-requests.view']
                    }
                },
            ];
            var runner = function (routingHelper) {
                'ngInject';
                routingHelper.configureRoutes(routes);
            };
            angular
                .module('app.training.request')
                .run(runner);
        })(request = training.request || (training.request = {}));
    })(training = app.training || (app.training = {}));
})(app || (app = {}));
var app;
(function (app) {
    var training;
    (function (training_5) {
        var training;
        (function (training) {
            'use strict';
            training.trainingBasicInfoResolver = function (datacontext, $stateParams) {
                'ngInject';
                var trainingId = $stateParams.trainingId;
                return datacontext.training.getTrainingInformation(trainingId);
            };
            training.showNumberOfBrochuresResolver = function (datacontext) {
                'ngInject';
                var bypassReasonsPromise = datacontext.config.getConfigurationParameter('Training.NumberOfBrochures', false);
                return bypassReasonsPromise.then(function (result) {
                    return result.toString() === 'true';
                });
            };
        })(training = training_5.training || (training_5.training = {}));
    })(training = app.training || (app.training = {}));
})(app || (app = {}));
var app;
(function (app) {
    var training;
    (function (training_6) {
        var training;
        (function (training) {
            'use strict';
            var routes = [
                {
                    name: 'app.createTraining',
                    url: '/trainings/create',
                    template: '<ce-training-create layout="column" flex></ce-training-create>',
                    data: {
                        title: 'Create Training',
                        permissions: ['trainings.edit']
                    }
                },
                {
                    name: 'app.training',
                    url: '/trainings/{trainingId}',
                    template: '<ce-training-view data="$resolve.training" layout="column" flex></ce-training-view>',
                    data: { permissions: ['trainings.view'] },
                    redirectTo: 'app.training.overview',
                    resolve: { training: training.trainingBasicInfoResolver }
                },
                {
                    name: 'app.training.overview',
                    url: '/overview',
                    template: '<ce-training-overview data="$resolve.training" layout="column" flex></ce-training-overview>',
                    data: {
                        title: 'Training Overview',
                        permissions: ['trainings.view']
                    }
                },
                {
                    name: 'app.training.details',
                    url: '/details',
                    template: '<ce-training-details data="$resolve.training" layout="column" flex param-number-of-brochures="$resolve.paramNumberOfBrochures"></ce-training-details>',
                    data: {
                        title: 'Training Details',
                        permissions: ['trainings.view']
                    },
                    resolve: { paramNumberOfBrochures: training.showNumberOfBrochuresResolver }
                },
                {
                    name: 'app.training.documents',
                    url: '/documents',
                    template: '<ce-documents-list layout="column" flex source="Training" source-id="$resolve.training.id"></ce-documents-list>',
                    data: {
                        title: 'Training Documents',
                        permissions: ['documents.view']
                    }
                },
                {
                    name: 'app.training.uploadDocuments',
                    url: '/upload',
                    params: {
                        folderId: null
                    },
                    resolve: {
                        folderId: ['$stateParams', function (p) { return p.folderId; }],
                    },
                    template: '<ce-upload-documents-activity layout flex folder-id="$resolve.folderId" source="Training" source-id="$resolve.training.id"></ce-upload-documents-activity>',
                    data: { permissions: ['documents.edit'] }
                },
                {
                    name: 'app.training.emails',
                    url: '/emails',
                    template: '<ui-view flex layout="column"></ui-view>',
                    redirectTo: 'app.training.emails.listing',
                    data: {
                        title: 'Training Emails',
                        permissions: ['mail.view']
                    }
                },
                {
                    name: 'app.training.emails.listing',
                    url: '',
                    template: '<ce-email-listing source="Training" source-id="$resolve.training.id" layout="column" flex></ce-email-listing>',
                    data: {
                        title: 'Training Emails',
                        permissions: ['mail.view']
                    }
                },
                {
                    name: 'app.training.emails.message',
                    url: '/{messageId}',
                    template: '<ce-email-view layout="column" flex message-id="$resolve.messageId"></ce-email-view>',
                    data: {
                        title: 'Message',
                        permissions: ['mail.view']
                    },
                    resolve: {
                        messageId: ['$stateParams', function (p) { return p.messageId; }]
                    }
                },
                {
                    name: 'app.training.emails.link',
                    url: '/link',
                    template: '<ce-link-emails layout="column" flex source="Training" source-id="$resolve.training.id"></ce-link-emails>',
                    data: {
                        title: 'Inbox Messages',
                        permissions: ['mail.link']
                    }
                },
                {
                    name: 'app.training.timeslipListing',
                    url: '/timeslips',
                    template: '<ce-timeslip-listing source="Training" source-id="$resolve.training.id" source-name="$resolve.training.title" enable-filters="false" layout="column" flex></ce-timeslip-listing>',
                    data: {
                        title: 'Training Timeslips',
                        permissions: ['timeslips.view']
                    }
                },
                {
                    name: 'app.training.timeslipCalendar',
                    url: '/timeslips-calendar',
                    template: '<ce-timeslip-calendar-frame source="Training" source-id="$resolve.training.id" source-name="$resolve.training.title" enable-filters="false" layout="column" flex></ce-timeslip-calendar-frame>',
                    data: {
                        title: 'Training Timeslips',
                        permissions: ['timeslips.view']
                    }
                },
                {
                    name: 'app.training.funding',
                    url: '/funding',
                    template: '<ce-training-funding training-id="$resolve.training.id" source="Training" layout="column" flex></ce-training-funding>',
                    data: { title: 'Training Funding' }
                },
                {
                    name: 'app.training.calendar',
                    url: '/calendar',
                    template: '<ce-training-calendar source="Training" source-id="$resolve.training.id" layout="column" flex></ce-training-calendar>',
                    data: {
                        title: 'Calendar',
                    }
                },
                {
                    name: 'app.training.eventSchedule',
                    url: '/eventSchedule',
                    template: "<ce-calendar-event-schedule\n                     event-schedule-id=\"$resolve.eventScheduleId\"\n                     target-date=\"$resolve.targetDate\"\n                     source=\"Training\"\n                     source-name=\"$resolve.training.title\"\n                     source-id=\"$resolve.training.id\"></ce-calendar-event-schedule>",
                    params: {
                        eventScheduleId: null,
                        targetDate: null
                    },
                    resolve: {
                        eventScheduleId: ['$stateParams', function (p) { return p.eventScheduleId; }],
                        targetDate: ['$stateParams', function (p) { return p.targetDate; }],
                    },
                    data: { title: 'Event Schedule', permissions: ['events.view'], }
                },
                {
                    name: 'app.training.event',
                    url: '/event',
                    template: '<ce-calendar-event event="$resolve.event"></ce-calendar-event>',
                    params: {
                        event: null
                    },
                    resolve: {
                        event: ['$stateParams', function (e) { return e.event; }]
                    },
                    data: {
                        title: 'Event',
                        permissions: ['events.view']
                    }
                },
            ];
            var runner = function (routingHelper) {
                'ngInject';
                routingHelper.configureRoutes(routes);
            };
            angular
                .module('app.training.training')
                .run(runner);
        })(training = training_6.training || (training_6.training = {}));
    })(training = app.training || (app.training = {}));
})(app || (app = {}));
var app;
(function (app) {
    var utils;
    (function (utils) {
        var ProgressBar = (function () {
            function ProgressBar() {
            }
            ProgressBar = __decorate([
                app.Component('app.utils', 'aimsProgressBar', {
                    template: "\n        <div ng-style=\"::{width: $ctrl.value + '%'}\">\n          <div ng-transclude></div>\n        </div>",
                    bindings: {
                        value: '<'
                    },
                    transclude: true
                })
            ], ProgressBar);
            return ProgressBar;
        }());
    })(utils = app.utils || (app.utils = {}));
})(app || (app = {}));
var app;
(function (app) {
    var utils;
    (function (utils) {
        var RadioCheckGroup = (function () {
            function RadioCheckGroup() {
                var _this = this;
                this.options = [];
                this.handleClick = function (option) {
                    if (_this.selectedOption) {
                        _this.selectedOption.isChecked = false;
                    }
                    option.isChecked = true;
                    _this.value = option.value;
                    _this.selectedOption = option;
                };
            }
            RadioCheckGroup.prototype.addCheck = function (option) {
                option.onClick = this.handleClick;
                option.isChecked = (option.value === this.value);
                this.options.push(option);
            };
            RadioCheckGroup = __decorate([
                app.Component('app.utils', 'ceRadioCheckGroup', {
                    bindings: {
                        label: '@',
                        inputName: '@',
                        required: '<?',
                        value: '='
                    },
                    require: {
                        form: '^^form'
                    },
                    transclude: true,
                    template: "\n        <div ng-transclude></div>\n        <div class=\"hide\">\n          <input name=\"{{$ctrl.inputName}}\" type=\"radio\" ng-repeat=\"opt in $ctrl.options\" ng-value=\"opt.value\" ng-required=\"$ctrl.required\" ng-model=\"$ctrl.value\" />\n        </div>\n        <div ng-messages=\"$ctrl.form[$ctrl.inputName].$error\" class=\"field-error\" ng-if=\"$ctrl.form.$submitted\">\n          <div ng-message=\"required\">{{$ctrl.label}} is required.</div>\n        </div>"
                })
            ], RadioCheckGroup);
            return RadioCheckGroup;
        }());
        var RadioCheck = (function () {
            function RadioCheck() {
                this.isChecked = false;
            }
            RadioCheck.prototype.$onInit = function () {
                this.group.addCheck(this);
            };
            RadioCheck = __decorate([
                app.Component('app.utils', 'ceRadioCheck', {
                    template: '<md-checkbox ng-checked="$ctrl.isChecked" ng-click="$ctrl.onClick($ctrl)">{{$ctrl.label}}</md-checkbox>',
                    bindings: {
                        label: '@',
                        value: '<'
                    },
                    require: {
                        group: '^ceRadioCheckGroup'
                    }
                })
            ], RadioCheck);
            return RadioCheck;
        }());
    })(utils = app.utils || (app.utils = {}));
})(app || (app = {}));
var app;
(function (app) {
    var utils;
    (function (utils) {
        var ProgressCircle = (function () {
            function ProgressCircle() {
            }
            ProgressCircle.prototype.$onInit = function () {
                this.fractionSize = this.fractionSize || 0;
            };
            ProgressCircle = __decorate([
                app.Component('app.utils', 'aimsProgressCircle', {
                    bindings: {
                        value: '<',
                        size: '@',
                        fractionSize: '@'
                    },
                    template: "\n<div class=\"c100 center blue p{{$ctrl.value|number:0}}\" ng-class=\"$ctrl.size\">\n  <span>{{$ctrl.value|number:$ctrl.fractionSize}}%</span>\n  <div class=\"slice\">\n    <div class=\"bar\"></div>\n    <div class=\"fill\"></div>\n  </div>\n</div>"
                })
            ], ProgressCircle);
            return ProgressCircle;
        }());
    })(utils = app.utils || (app.utils = {}));
})(app || (app = {}));
var app;
(function (app) {
    var utils;
    (function (utils) {
        var Stepper = (function () {
            function Stepper($attrs, $mdComponentRegistry, common) {
                this.$attrs = $attrs;
                this.$mdComponentRegistry = $mdComponentRegistry;
                this.common = common;
                this.groups = [];
                'ngInject';
                'ngInject';
            }
            Stepper.prototype.addGroup = function (group) {
                return this.groups.push(group) - 1;
            };
            Stepper.prototype.isActive = function (group, step) {
                return angular.isDefined(step)
                    ? this.currentGroupIndex === group && this.currentStepIndex === step
                    : this.currentGroupIndex === group;
            };
            Stepper.prototype.isCompleted = function (group, step) {
                if (angular.isDefined(step)) {
                    if (this.currentGroupIndex > group)
                        return true;
                    else if (this.currentGroupIndex === group)
                        return this.currentStepIndex > step;
                    else
                        return false;
                }
                else {
                    return this.currentGroupIndex > group;
                }
            };
            Stepper.prototype.$onChanges = function () {
                this.currentGroupIndex = 0;
                this.currentStepIndex = 0;
                this.currentStep = '0.0';
            };
            Stepper.prototype.$postLink = function () {
                if (!this.$attrs['id']) {
                    this.common.logger.warning('You must set an id attribute on your stepper');
                }
                this.registeredStepper = this.$mdComponentRegistry.register(this, this.$attrs['id']);
            };
            Stepper.prototype.$onDestroy = function () {
                this.registeredStepper && this.registeredStepper();
            };
            Stepper.prototype.next = function () {
                var group = this.groups[this.currentGroupIndex];
                if (this.currentStepIndex < group.steps.length - 1) {
                    this.currentStepIndex++;
                }
                else if (this.currentGroupIndex < this.groups.length - 1) {
                    this.currentGroupIndex++;
                    this.currentStepIndex = 0;
                }
                this.currentStep = "".concat(this.currentGroupIndex, ".").concat(this.currentStepIndex);
            };
            Stepper.prototype.prev = function () {
                if (this.currentStepIndex > 0) {
                    this.currentStepIndex--;
                }
                else if (this.currentGroupIndex > 0) {
                    var group = this.groups[--this.currentGroupIndex];
                    this.currentStepIndex = group.steps.length - 1;
                }
                this.currentStep = "".concat(this.currentGroupIndex, ".").concat(this.currentStepIndex);
            };
            Stepper.$inject = ['$attrs', '$mdComponentRegistry', 'common'];
            Stepper = __decorate([
                app.Component('app.utils', 'ceStepper', {
                    templateUrl: 'app/utils/stepper/stepper.html',
                    transclude: true,
                    bindings: {
                        currentStep: '='
                    }
                })
            ], Stepper);
            return Stepper;
        }());
        var StepperGroup = (function () {
            function StepperGroup() {
            }
            StepperGroup.prototype.$onInit = function () {
                this.number = this.stepper.addGroup(this);
            };
            StepperGroup = __decorate([
                app.Component('app.utils', 'ceStepperGroup', {
                    templateUrl: 'app/utils/stepper/stepper-group.html',
                    bindings: {
                        label: '@',
                        steps: '<'
                    },
                    require: {
                        stepper: '^ceStepper'
                    }
                })
            ], StepperGroup);
            return StepperGroup;
        }());
        var StepperServiceFactor = function ($mdComponentRegistry) {
            'ngInject';
            return function (handle) {
                var instance = $mdComponentRegistry.get(handle);
                if (!instance) {
                    $mdComponentRegistry.notFoundError(handle);
                }
                return instance;
            };
        };
        angular.module('app.utils')
            .factory('ceStepper', StepperServiceFactor);
    })(utils = app.utils || (app.utils = {}));
})(app || (app = {}));
var app;
(function (app) {
    var utils;
    (function (utils) {
        var TernaryConfirmationDialogController = (function () {
            function TernaryConfirmationDialogController($mdDialog, title, message, submitButtonText, submitButtonTooltip, leftButtonText, leftButtonTooltip, middleButtonText, middleButtonTooltip) {
                this.$mdDialog = $mdDialog;
                this.title = title;
                this.message = message;
                this.submitButtonText = submitButtonText;
                this.submitButtonTooltip = submitButtonTooltip;
                this.leftButtonText = leftButtonText;
                this.leftButtonTooltip = leftButtonTooltip;
                this.middleButtonText = middleButtonText;
                this.middleButtonTooltip = middleButtonTooltip;
                'ngInject';
                'ngInject';
            }
            TernaryConfirmationDialogController.prototype.leftButtonClicked = function () {
                this.$mdDialog.hide(this.leftButtonText);
            };
            TernaryConfirmationDialogController.prototype.middleButtonClicked = function () {
                this.$mdDialog.hide(this.middleButtonText);
            };
            TernaryConfirmationDialogController.prototype.submit = function () {
                this.$mdDialog.hide(this.submitButtonText);
            };
            TernaryConfirmationDialogController.$inject = ['$mdDialog', 'title', 'message', 'submitButtonText', 'submitButtonTooltip', 'leftButtonText', 'leftButtonTooltip', 'middleButtonText', 'middleButtonTooltip'];
            TernaryConfirmationDialogController = __decorate([
                app.Controller('app.utils', 'TernaryConfirmationDialogController')
            ], TernaryConfirmationDialogController);
            return TernaryConfirmationDialogController;
        }());
    })(utils = app.utils || (app.utils = {}));
})(app || (app = {}));
var app;
(function (app) {
    var utils;
    (function (utils) {
        var timesList = [];
        (function () {
            var meridiem;
            var hour;
            for (var i = 8; i < 18; i++) {
                hour = i % 12;
                if (hour === 0) {
                    hour = 12;
                }
                meridiem = i < 12 ? 'am' : 'pm';
                timesList.push(hour + ':00' + meridiem);
                timesList.push(hour + ':15' + meridiem);
                timesList.push(hour + ':30' + meridiem);
                timesList.push(hour + ':45' + meridiem);
            }
        })();
        var TimePicker = (function () {
            function TimePicker() {
                this.timesList = timesList;
            }
            TimePicker.prototype.startsWithFilter = function (actualTime, startsWithTime) {
                return actualTime.toLowerCase().indexOf(startsWithTime.toLowerCase()) === 0;
            };
            TimePicker = __decorate([
                app.Component('app.utils', 'aimsTimePicker', {
                    templateUrl: 'app/utils/time-picker/time-picker.component.html',
                    bindings: {
                        hideIcon: '<',
                        inputName: '@',
                        label: '@',
                        required: '<',
                        value: '=',
                    },
                    require: {
                        form: '^^form'
                    }
                })
            ], TimePicker);
            return TimePicker;
        }());
    })(utils = app.utils || (app.utils = {}));
})(app || (app = {}));
var app;
(function (app) {
    var admin;
    (function (admin) {
        var configuration;
        (function (configuration) {
            function lookupTypeResolver($stateParams, datacontext) {
                'ngInject';
                var lookupTypeId = $stateParams.lookupTypeId;
                return datacontext.admin.configuration.getLookupType(lookupTypeId);
            }
            configuration.lookupTypeResolver = lookupTypeResolver;
            function lookupTypesResolver(datacontext) {
                'ngInject';
                return datacontext.admin.configuration.getLookupTypes();
            }
            configuration.lookupTypesResolver = lookupTypesResolver;
            function lawCategoriesResolver(datacontext) {
                'ngInject';
                return datacontext.admin.configuration.getLawCategories(false);
            }
            configuration.lawCategoriesResolver = lawCategoriesResolver;
            function lawCategoryResolver($stateParams, datacontext) {
                'ngInject';
                var lawCategoryId = $stateParams.lawCategoryId;
                return datacontext.admin.configuration.getLawCategory(lawCategoryId, false);
            }
            configuration.lawCategoryResolver = lawCategoryResolver;
            function legalTeamsResolver(datacontext) {
                'ngInject';
                return datacontext.admin.configuration.getLegalTeams(false);
            }
            configuration.legalTeamsResolver = legalTeamsResolver;
            function legalTeamResolver($stateParams, datacontext) {
                'ngInject';
                var legalTeamId = $stateParams.legalTeamId;
                return datacontext.admin.configuration.getLegalTeam(legalTeamId);
            }
            configuration.legalTeamResolver = legalTeamResolver;
            function taskCategoriesResolver(datacontext) {
                'ngInject';
                return datacontext.admin.configuration.getTaskCategories();
            }
            configuration.taskCategoriesResolver = taskCategoriesResolver;
            function taskCategoryResolver($stateParams, datacontext) {
                'ngInject';
                var taskCategoryId = $stateParams.taskCategoryId;
                return datacontext.admin.configuration.getTaskCategory(taskCategoryId);
            }
            configuration.taskCategoryResolver = taskCategoryResolver;
        })(configuration = admin.configuration || (admin.configuration = {}));
    })(admin = app.admin || (app.admin = {}));
})(app || (app = {}));
var app;
(function (app) {
    var admin;
    (function (admin) {
        var configuration;
        (function (configuration) {
            var routes = [
                {
                    name: 'app.admin.configuration',
                    url: '/configuration',
                    template: '<ui-view layout="column" flex></ui-view>',
                    data: { roles: ['Administrator'] },
                    redirectTo: 'app.admin.configuration.lookupTypeList'
                },
                {
                    name: 'app.admin.configuration.lookupTypeList',
                    url: '/lookup-types',
                    template: '<aims-admin-lookup-type-list layout="column" flex lookup-types="$resolve.lookupTypes"></aims-admin-lookup-type-list>',
                    data: {
                        title: 'Lookup Type List',
                        roles: ['Administrator']
                    },
                    resolve: {
                        lookupTypes: configuration.lookupTypesResolver
                    }
                },
                {
                    name: 'app.admin.configuration.lookupTypeDetails',
                    url: '/lookup-types/:lookupTypeId',
                    template: '<aims-admin-lookup-type-details layout="column" flex lookup-type="$resolve.lookupType"></aims-admin-lookup-type-details>',
                    data: { roles: ['Administrator'] },
                    resolve: {
                        lookupType: configuration.lookupTypeResolver
                    }
                },
                {
                    name: 'app.admin.configuration.lawCategoryList',
                    url: '/law-categories',
                    template: '<ce-admin-law-category-list layout="column" flex law-categories="$resolve.lawCategories"></ce-admin-law-category-list>',
                    data: {
                        title: 'Law Category List',
                        roles: ['Administrator']
                    },
                    resolve: {
                        lawCategories: configuration.lawCategoriesResolver
                    }
                },
                {
                    name: 'app.admin.configuration.createLawCategory',
                    url: '/law-categories/create',
                    template: '<ce-admin-create-law-category layout="column" flex></ce-admin-create-law-category>',
                    data: { roles: ['Administrator'] }
                },
                {
                    name: 'app.admin.configuration.lawCategoryDetails',
                    url: '/law-categories/:lawCategoryId',
                    template: '<ce-admin-law-category-details layout="column" flex law-category="$resolve.lawCategory"></ce-admin-law-category-details>',
                    data: { roles: ['Administrator'] },
                    resolve: {
                        lawCategory: configuration.lawCategoryResolver
                    }
                },
                {
                    name: 'app.admin.configuration.legalTeamList',
                    url: '/legal-teams',
                    template: '<ce-admin-legal-team-list layout="column" flex legal-teams="$resolve.legalTeams"></ce-admin-legal-team-list>',
                    data: {
                        title: 'Legal Team List',
                        roles: ['Administrator']
                    },
                    resolve: {
                        legalTeams: configuration.legalTeamsResolver
                    }
                },
                {
                    name: 'app.admin.configuration.createLegalTeam',
                    url: '/legal-teams/create',
                    template: '<ce-admin-legal-team-details layout="column" flex></ce-admin-legal-team-details>',
                    data: { roles: ['Administrator'] }
                },
                {
                    name: 'app.admin.configuration.legalTeamDetails',
                    url: '/legal-teams/:legalTeamId',
                    template: '<ce-admin-legal-team-details layout="column" flex legal-team="$resolve.legalTeam"></ce-admin-legal-team-details>',
                    data: { roles: ['Administrator'] },
                    resolve: {
                        legalTeam: configuration.legalTeamResolver
                    }
                },
                {
                    name: 'app.admin.configuration.taskCategoryList',
                    url: '/task-categories',
                    template: '<ce-admin-task-category-list layout="column" flex task-categories="$resolve.taskCategories"></ce-admin-task-category-list>',
                    data: {
                        title: 'Task Category List',
                        roles: ['Administrator']
                    },
                    resolve: {
                        taskCategories: configuration.taskCategoriesResolver
                    }
                },
                {
                    name: 'app.admin.configuration.createTaskCategory',
                    url: '/task-categories/create',
                    template: '<ce-admin-create-task-category layout="column" flex></ce-admin-create-task-category>',
                    data: { roles: ['Administrator'] }
                },
                {
                    name: 'app.admin.configuration.taskCategoryDetails',
                    url: '/task-categories/:taskCategoryId',
                    template: '<ce-admin-task-category-details layout="column" flex task-category="$resolve.taskCategory"></ce-admin-task-category-details>',
                    data: { roles: ['Administrator'] },
                    resolve: {
                        taskCategory: configuration.taskCategoryResolver
                    }
                },
            ];
            var runner = function (routingHelper) {
                'ngInject';
                routingHelper.configureRoutes(routes);
            };
            angular
                .module('app.admin.configuration')
                .run(runner);
        })(configuration = admin.configuration || (admin.configuration = {}));
    })(admin = app.admin || (app.admin = {}));
})(app || (app = {}));
var app;
(function (app) {
    var admin;
    (function (admin) {
        var identityManagement;
        (function (identityManagement) {
            identityManagement.permissionsRosolver = function (datacontext) {
                'ngInject';
                return datacontext.admin.identityManagement.getPermissions();
            };
            identityManagement.roleResolver = function (datacontext, $stateParams) {
                'ngInject';
                var roleId = $stateParams.roleId;
                return datacontext.admin.identityManagement.getRole(roleId);
            };
            identityManagement.rolesResolver = function (datacontext) {
                'ngInject';
                return datacontext.admin.identityManagement.getRoles();
            };
            identityManagement.userResolver = function (datacontext, $stateParams) {
                'ngInject';
                var userId = $stateParams.userId;
                return datacontext.admin.identityManagement.getUser(userId);
            };
        })(identityManagement = admin.identityManagement || (admin.identityManagement = {}));
    })(admin = app.admin || (app.admin = {}));
})(app || (app = {}));
var app;
(function (app) {
    var admin;
    (function (admin) {
        var identityManagement;
        (function (identityManagement) {
            var routes = [
                {
                    name: 'app.admin.identityManagement',
                    url: '/identity-management',
                    template: '<ui-view layout="column" flex></ui-view>',
                    redirectTo: 'app.admin.identityManagement.users',
                    data: { roles: ['Administrator'] }
                },
                {
                    name: 'app.admin.identityManagement.users',
                    url: '/users',
                    template: '<ui-view layout flex></ui-view>',
                    redirectTo: 'app.admin.identityManagement.users.list',
                    data: { roles: ['Administrator'] }
                },
                {
                    name: 'app.admin.identityManagement.users.list',
                    url: '',
                    template: '<ce-admin-user-list layout="column" flex></ce-admin-user-list>',
                    data: {
                        title: 'Users',
                        roles: ['Administrator']
                    }
                },
                {
                    name: 'app.admin.identityManagement.users.createUser',
                    url: '/create',
                    template: '<ui-view layout flex></ui-view>',
                    redirectTo: 'app.admin.identityManagement.users.createUser.find',
                    data: { roles: ['Administrator'] }
                },
                {
                    name: 'app.admin.identityManagement.users.createUser.find',
                    url: '/find',
                    template: '<ce-admin-find-person layout="column" flex></ce-admin-find-person>',
                    data: {
                        roles: ['Administrator'],
                        title: 'Create User'
                    }
                },
                {
                    name: 'app.admin.identityManagement.users.createUser.create',
                    url: '',
                    template: '<ce-admin-create-user layout="column" flex person="$resolve.person" roles="$resolve.roles"></ce-admin-create-user>',
                    data: {
                        roles: ['Administrator'],
                        title: 'Create User'
                    },
                    params: { person: null },
                    resolve: {
                        person: ['$stateParams', function (p) { return p.person; }],
                        roles: identityManagement.rolesResolver
                    }
                },
                {
                    name: 'app.admin.identityManagement.users.userDetails',
                    url: '/:userId',
                    template: '<ce-admin-user-details layout="column" flex user="$resolve.user" roles="$resolve.roles"></ce-admin-user-details>',
                    data: { roles: ['Administrator'] },
                    resolve: {
                        user: identityManagement.userResolver,
                        roles: identityManagement.rolesResolver
                    }
                },
                {
                    name: 'app.admin.identityManagement.roles',
                    url: '/roles',
                    template: '<ui-view layout flex></ui-view>',
                    data: { roles: ['Administrator'] },
                    redirectTo: 'app.admin.identityManagement.roles.list'
                },
                {
                    name: 'app.admin.identityManagement.roles.list',
                    url: '',
                    template: '<ce-admin-role-list layout="column" flex roles="$resolve.roles"></ce-admin-role-list>',
                    data: {
                        title: 'Roles & Permissions',
                        roles: ['Administrator']
                    },
                    resolve: {
                        roles: identityManagement.rolesResolver
                    }
                },
                {
                    name: 'app.admin.identityManagement.roles.createRole',
                    url: '/create',
                    template: '<ce-admin-create-role layout="column" flex permissions="$resolve.permissions"></ce-admin-create-role>',
                    data: { roles: ['Administrator'] },
                    resolve: {
                        permissions: identityManagement.permissionsRosolver
                    }
                },
                {
                    name: 'app.admin.identityManagement.roles.roleDetails',
                    url: '/:roleId',
                    template: '<ce-admin-role-details layout="column" flex role="$resolve.role" permissions="$resolve.permissions"></ce-admin-role-details>',
                    data: { roles: ['Administrator'] },
                    resolve: {
                        role: identityManagement.roleResolver,
                        permissions: identityManagement.permissionsRosolver
                    }
                },
            ];
            var runner = function (routingHelper) {
                'ngInject';
                routingHelper.configureRoutes(routes);
            };
            angular
                .module('app.admin.identityManagement')
                .run(runner);
        })(identityManagement = admin.identityManagement || (admin.identityManagement = {}));
    })(admin = app.admin || (app.admin = {}));
})(app || (app = {}));
var app;
(function (app) {
    var admin;
    (function (admin) {
        var tenancy;
        (function (tenancy) {
            var routes = [
                {
                    name: 'app.admin.tenancy',
                    url: '/tenancy',
                    template: '<ui-view layout="column" flex></ui-view>',
                    data: { roles: ['Administrator'] },
                    redirectTo: 'app.admin.tenancy.email'
                },
                {
                    name: 'app.admin.tenancy.email',
                    url: '/email',
                    template: '<am-tenancy-email></am-tenancy-email>',
                    data: { roles: ['Administrator'] }
                }
            ];
            var runner = function (routingHelper) {
                'ngInject';
                routingHelper.configureRoutes(routes);
            };
            angular
                .module('app.admin.tenancy')
                .run(runner);
        })(tenancy = admin.tenancy || (admin.tenancy = {}));
    })(admin = app.admin || (app.admin = {}));
})(app || (app = {}));
var app;
(function (app) {
    var admin;
    (function (admin) {
        var tools;
        (function (tools) {
            var routes = [
                {
                    name: 'app.admin.tools',
                    url: '/tools',
                    template: '<ui-view layout="column" flex></ui-view>',
                    data: { roles: ['Administrator'] },
                    redirectTo: 'app.admin.tools.entityMerge'
                },
                {
                    name: 'app.admin.tools.entityMerge',
                    url: '/entity-merge',
                    template: '<ui-view layout="column" flex></ui-view>',
                    data: { roles: ['Administrator'] },
                    redirectTo: 'app.admin.tools.entityMerge.select'
                },
                {
                    name: 'app.admin.tools.entityMerge.select',
                    url: '',
                    template: '<ce-admin-entity-merge layout="column" flex></ce-admin-entity-merge>',
                    data: {
                        title: 'Entity Merge',
                        roles: ['Administrator']
                    }
                },
                {
                    name: 'app.admin.tools.entityMerge.persons',
                    url: '/persons',
                    template: '<ce-admin-person-merge layout="column" flex></ce-admin-person-merge>',
                    data: {
                        title: 'Person Merge',
                        roles: ['Administrator']
                    }
                },
                {
                    name: 'app.admin.tools.entityMerge.agencies',
                    url: '/agencies',
                    template: '<ce-admin-agency-merge layout="column" flex></ce-admin-agency-merge>',
                    data: {
                        title: 'Agency Merge',
                        roles: ['Administrator']
                    }
                },
                {
                    name: 'app.admin.tools.timeslipLock',
                    url: '/timeslip-lock',
                    template: '<ce-admin-timeslips-lock layout="column" flex></ce-admin-timeslips-lock>',
                    data: {
                        title: 'Lock Timeslips',
                        roles: ['Administrator']
                    }
                },
                {
                    name: 'app.admin.tools.documents',
                    url: '/documents',
                    template: '<ce-documents-list layout="column" flex></ce-document-list>',
                    data: {
                        title: 'Documents',
                        permissions: ['Administrator']
                    }
                },
            ];
            var runner = function (routingHelper) {
                'ngInject';
                routingHelper.configureRoutes(routes);
            };
            angular
                .module('app.admin.tools')
                .run(runner);
        })(tools = admin.tools || (admin.tools = {}));
    })(admin = app.admin || (app.admin = {}));
})(app || (app = {}));
var app;
(function (app) {
    var admin;
    (function (admin) {
        var AdminLayout = (function () {
            function AdminLayout($state) {
                var _this = this;
                this.$state = $state;
                this.modules = [
                    {
                        title: 'Users & Permissions',
                        baseSref: 'app.admin.identityManagement',
                        links: [
                            { title: 'Users', icon: 'groups', sref: 'users' },
                            { title: 'Roles', icon: 'security', sref: 'roles' }
                        ]
                    },
                    {
                        title: 'Configuration Settings',
                        baseSref: 'app.admin.configuration',
                        links: [
                            { title: 'Lookups', icon: 'list', sref: 'lookupTypeList' },
                            { title: 'Law Categories', icon: 'list', sref: 'lawCategoryList' },
                            { title: 'Legal Teams', icon: 'list', sref: 'legalTeamList' },
                            { title: 'Timeslip Tasks', icon: 'list', sref: 'taskCategoryList' }
                        ]
                    },
                    {
                        title: 'Tenant Settings',
                        baseSref: 'app.admin.tenancy',
                        links: [
                            { title: 'Email', icon: 'mail', sref: 'email' }
                        ]
                    },
                    {
                        title: 'Tools',
                        baseSref: 'app.admin.tools',
                        links: [
                            { title: 'Merge Entities', icon: 'merge_type', sref: 'entityMerge' },
                            { title: 'Lock Timeslips', icon: 'lock', sref: 'timeslipLock' },
                            { title: 'Documents', icon: 'insert_drive_file', sref: 'documents' }
                        ]
                    }
                ];
                this.selectedModule = 'permissions';
                this.toggleModule = function (module) {
                    if (module.isExpanded) {
                        module.isExpanded = false;
                    }
                    else {
                        angular.forEach(_this.modules, function (x) { return x.isExpanded = false; });
                        module.isExpanded = true;
                        _this.$state.go("".concat(module.baseSref, ".").concat(module.links[0].sref));
                    }
                };
                'ngInject';
                'ngInject';
            }
            AdminLayout.prototype.$onInit = function () {
                var currentState = this.$state.current.name;
                for (var _i = 0, _a = this.modules; _i < _a.length; _i++) {
                    var module = _a[_i];
                    if (currentState.match("^".concat(module.baseSref))) {
                        module.isExpanded = true;
                        break;
                    }
                }
            };
            AdminLayout.$inject = ['$state'];
            AdminLayout = __decorate([
                app.Component('app.admin', 'ceAdmin', {
                    templateUrl: 'app/admin/views/layout/layout.html'
                })
            ], AdminLayout);
            return AdminLayout;
        }());
    })(admin = app.admin || (app.admin = {}));
})(app || (app = {}));
var app;
(function (app) {
    var agencies;
    (function (agencies) {
        var details;
        (function (details) {
            var AgencyContactInfo = (function () {
                function AgencyContactInfo() {
                }
                AgencyContactInfo = __decorate([
                    app.Component('app.agencies.details', 'ceAgencyContactInfo', {
                        templateUrl: 'app/agencies/details/contact-info/contact-info.html',
                        bindings: {
                            agency: '<',
                        }
                    })
                ], AgencyContactInfo);
                return AgencyContactInfo;
            }());
        })(details = agencies.details || (agencies.details = {}));
    })(agencies = app.agencies || (app.agencies = {}));
})(app || (app = {}));
var app;
(function (app) {
    var agencies;
    (function (agencies) {
        var details;
        (function (details) {
            var AgencyNames = (function () {
                function AgencyNames() {
                }
                AgencyNames = __decorate([
                    app.Component('app.agencies.details', 'ceAgencyNames', {
                        templateUrl: 'app/agencies/details/names/names.html',
                        bindings: {
                            agency: '<',
                        }
                    })
                ], AgencyNames);
                return AgencyNames;
            }());
        })(details = agencies.details || (agencies.details = {}));
    })(agencies = app.agencies || (app.agencies = {}));
})(app || (app = {}));
var app;
(function (app) {
    var agencies;
    (function (agencies) {
        var details;
        (function (details) {
            var AgencyOtherInfoForm = (function () {
                function AgencyOtherInfoForm(common, datacontext) {
                    this.common = common;
                    this.datacontext = datacontext;
                    this.isLoading = false;
                    this.data = {};
                    'ngInject';
                    'ngInject';
                }
                AgencyOtherInfoForm.prototype.$onChanges = function () {
                    this.data = angular.extend({}, this.otherInfo);
                };
                AgencyOtherInfoForm.$inject = ['common', 'datacontext'];
                AgencyOtherInfoForm = __decorate([
                    app.Component('app.agencies.details', 'ceAgencyOtherInfoForm', {
                        templateUrl: 'app/agencies/details/other-info/other-info-form.html',
                        bindings: {
                            otherInfo: '<',
                            onCancel: '&',
                            onSubmit: '&'
                        }
                    })
                ], AgencyOtherInfoForm);
                return AgencyOtherInfoForm;
            }());
        })(details = agencies.details || (agencies.details = {}));
    })(agencies = app.agencies || (app.agencies = {}));
})(app || (app = {}));
var app;
(function (app) {
    var agencies;
    (function (agencies) {
        var details;
        (function (details) {
            var AgencyOtherInfoView = (function () {
                function AgencyOtherInfoView() {
                }
                AgencyOtherInfoView = __decorate([
                    app.Component('app.agencies.details', 'ceAgencyOtherInfoView', {
                        templateUrl: 'app/agencies/details/other-info/other-info-view.html',
                        bindings: {
                            data: '<',
                            onEdit: '&'
                        }
                    })
                ], AgencyOtherInfoView);
                return AgencyOtherInfoView;
            }());
        })(details = agencies.details || (agencies.details = {}));
    })(agencies = app.agencies || (app.agencies = {}));
})(app || (app = {}));
var app;
(function (app) {
    var agencies;
    (function (agencies) {
        var details;
        (function (details) {
            var AgencyOtherInfo = (function () {
                function AgencyOtherInfo(common, datacontext) {
                    this.common = common;
                    this.datacontext = datacontext;
                    this.isLoading = false;
                    this.editMode = false;
                    this.data = {};
                    this.isSubmitting = false;
                    'ngInject';
                    'ngInject';
                }
                AgencyOtherInfo.prototype.$onInit = function () {
                    this.getData();
                };
                AgencyOtherInfo.prototype.cancelEdit = function () {
                    this.editMode = false;
                };
                AgencyOtherInfo.prototype.editData = function (data) {
                    this.editMode = true;
                };
                AgencyOtherInfo.prototype.getData = function () {
                    var _this = this;
                    this.isLoading = true;
                    return this.datacontext.agencies
                        .getOtherInfo(this.agency.id)
                        .then(function (result) {
                        _this.data = result;
                        _this.isLoading = false;
                    });
                };
                AgencyOtherInfo.prototype.saveData = function (data) {
                    var _this = this;
                    this.isSubmitting = true;
                    this.datacontext.agencies
                        .updateOtherInfo(data)
                        .then(function (result) {
                        _this.data = data;
                        angular.extend(_this.agency, result);
                        _this.isSubmitting = false;
                        _this.cancelEdit();
                    });
                };
                AgencyOtherInfo.$inject = ['common', 'datacontext'];
                AgencyOtherInfo = __decorate([
                    app.Component('app.agencies.details', 'ceAgencyOtherInfo', {
                        templateUrl: 'app/agencies/details/other-info/other-info.html',
                        bindings: {
                            agency: '<',
                            onEdit: '&',
                        }
                    })
                ], AgencyOtherInfo);
                return AgencyOtherInfo;
            }());
        })(details = agencies.details || (agencies.details = {}));
    })(agencies = app.agencies || (app.agencies = {}));
})(app || (app = {}));
var app;
(function (app) {
    var analysis;
    (function (analysis) {
        var BarChartComponent = (function () {
            function BarChartComponent(common, analysisData, analysisConstants) {
                var _this = this;
                this.common = common;
                this.analysisData = analysisData;
                this.analysisConstants = analysisConstants;
                this.options = {
                    series: {
                        bars: {
                            align: 'center',
                            barWidth: 0.6,
                            fill: 0.9,
                            horizontal: true,
                            lineWidth: 0,
                            show: true
                        }
                    },
                    grid: {
                        borderColor: '#eee',
                        borderWidth: 0,
                        hoverable: true,
                        backgroundColor: 'transparent',
                        labelMargin: 25,
                        margin: 10
                    },
                    tooltip: true,
                    tooltipOpts: {
                        content: function (label, x, y, item) {
                            var tooltip = "".concat(item.series.yaxis.ticks[item.series.yaxis.ticks.length - y - 1].label, " : ").concat(_this.dataSeries[y][0].toFixed(0));
                            if (_this.showPercentages) {
                                tooltip += ", ".concat(_this.percentSeries[y][0].toFixed(1), "%");
                            }
                            return tooltip;
                        },
                        zIndex: 2000
                    },
                    xaxis: {
                        autoscaleMargin: .1,
                        tickColor: '#fcfcfc',
                        tickDecimals: 0,
                        max: null
                    },
                    yaxis: {
                        tickColor: '#eee',
                        ticks: null
                    }
                };
                'ngInject';
                'ngInject';
            }
            BarChartComponent.prototype.$onChanges = function () {
                var _this = this;
                if (!this.chartData)
                    return;
                var color = this.analysisConstants.colorPalette[0];
                this.dataSeries = this.analysisData.barSeries(this.chartData);
                var ticks = _.reverse(this.analysisData.ticks(this.chartData));
                if (this.showPercentages) {
                    this.percentSeries = this.analysisData.barPercentageSeries(this.chartData);
                }
                if (this.showTotal) {
                    this.total = this.analysisData.sum(this.chartData);
                    this.dataSeries.push([this.total, this.dataSeries.length]);
                    ticks.push([ticks.length, 'Total']);
                    if (this.showPercentages) {
                        this.percentSeries.push([100, this.percentSeries.length]);
                    }
                }
                var dataset = [{ color: color, data: this.percentSeries || this.dataSeries }];
                this.common.$timeout(function () {
                    _this.dataset = dataset;
                    _this.options.yaxis.ticks = ticks;
                }, 50);
            };
            BarChartComponent.$inject = ['common', 'analysisData', 'analysisConstants'];
            BarChartComponent = __decorate([
                app.Component('app.analysis', 'aimsAnalysisBarChart', {
                    template: "<flot dataset=\"$ctrl.dataset\" options=\"$ctrl.options\"></flot>",
                    bindings: {
                        chartData: '<',
                        showPercentages: '<',
                        showTotal: '<'
                    }
                })
            ], BarChartComponent);
            return BarChartComponent;
        }());
    })(analysis = app.analysis || (app.analysis = {}));
})(app || (app = {}));
var app;
(function (app) {
    var analysis;
    (function (analysis) {
        var ChartComponent = (function () {
            function ChartComponent() {
            }
            ChartComponent = __decorate([
                app.Component('app.analysis', 'aimsAnalysisChart', {
                    templateUrl: 'app/analysis/components/chart/chart.html',
                    bindings: {
                        data: '<',
                        metadata: '<'
                    }
                })
            ], ChartComponent);
            return ChartComponent;
        }());
    })(analysis = app.analysis || (app.analysis = {}));
})(app || (app = {}));
var app;
(function (app) {
    var analysis;
    (function (analysis) {
        var ColumnChartComponent = (function () {
            function ColumnChartComponent(common, analysisConstants, analysisData) {
                var _this = this;
                this.common = common;
                this.analysisConstants = analysisConstants;
                this.analysisData = analysisData;
                this.options = {
                    series: {
                        bars: {
                            align: 'center',
                            barWidth: 0.6,
                            show: true,
                            lineWidth: 0,
                            fill: 0.9
                        }
                    },
                    grid: {
                        borderColor: '#eee',
                        borderWidth: 1,
                        hoverable: true,
                        backgroundColor: '#fcfcfc',
                        labelMargin: 25
                    },
                    tooltip: true,
                    tooltipOpts: {
                        content: function (label, x, y, item) {
                            var tooltip = "".concat(item.series.xaxis.ticks[x].label, " : ").concat(_this.dataSeries[x][1].toFixed(0));
                            if (_this.showPercentages) {
                                tooltip += ", ".concat(_this.percentSeries[x][1].toFixed(0), "%");
                            }
                            return tooltip;
                        },
                        zIndex: 2000
                    },
                    xaxis: {
                        tickColor: '#fcfcfc',
                        autoscaleMargin: .1,
                        ticks: null
                    },
                    yaxis: {
                        tickColor: '#eee',
                        tickDecimals: 0
                    }
                };
                'ngInject';
                'ngInject';
            }
            ColumnChartComponent.prototype.$onChanges = function () {
                var _this = this;
                if (!this.chartData)
                    return;
                var color = this.analysisConstants.colorPalette[0];
                this.dataSeries = this.analysisData.columnSeries(this.chartData);
                var ticks = this.analysisData.ticks(this.chartData);
                this.percentSeries = this.showPercentages
                    ? this.analysisData.columnPercentageSeries(this.chartData)
                    : null;
                if (this.showTotal) {
                    this.total = this.analysisData.sum(this.chartData);
                    this.dataSeries.push([this.dataSeries.length, this.total]);
                    ticks.push([ticks.length, 'Total']);
                    if (this.showPercentages) {
                        this.percentSeries.push([this.percentSeries.length, 100]);
                    }
                }
                var dataset = [{ color: color, data: this.percentSeries || this.dataSeries }];
                this.common.$timeout(function () {
                    _this.dataset = dataset;
                    _this.options.xaxis.ticks = ticks;
                }, 50);
            };
            ColumnChartComponent.$inject = ['common', 'analysisConstants', 'analysisData'];
            ColumnChartComponent = __decorate([
                app.Component('app.analysis', 'aimsAnalysisColumnChart', {
                    template: '<flot dataset="$ctrl.dataset" options="$ctrl.options"></flot>',
                    bindings: {
                        chartData: '<',
                        showPercentages: '<',
                        showTotal: '<'
                    }
                })
            ], ColumnChartComponent);
            return ColumnChartComponent;
        }());
    })(analysis = app.analysis || (app.analysis = {}));
})(app || (app = {}));
var app;
(function (app) {
    var analysis;
    (function (analysis) {
        var LineChartComponent = (function () {
            function LineChartComponent($element, common, analysisConstants) {
                this.$element = $element;
                this.common = common;
                this.analysisConstants = analysisConstants;
                this.options = {
                    grid: {
                        borderColor: '#eee',
                        borderWidth: 1,
                        hoverable: true,
                        backgroundColor: '#fcfcfc',
                        labelMargin: 25
                    },
                    legend: {
                        position: 'ne',
                        noColumns: 0
                    },
                    tooltip: true,
                    tooltipOpts: {
                        content: function (label, x, y, item) {
                            return "".concat(moment(x).format('M/D/YY'), " : ").concat(y);
                        },
                        zIndex: 2000
                    },
                    xaxis: {
                        mode: 'time',
                        tickColor: '#fcfcfc',
                        tickLength: 0,
                        tickSize: [1, 'day'],
                        autoscaleMargin: 0
                    },
                    yaxis: {
                        tickColor: '#eee',
                        tickDecimals: 0,
                        autoscaleMargin: 0.2
                    }
                };
                'ngInject';
                'ngInject';
            }
            LineChartComponent.prototype.$onChanges = function () {
                var _this = this;
                this.common.$timeout(function () {
                    if (!(_this.data && _this.data.length))
                        return;
                    _this.dataset = _(_this.data)
                        .groupBy(function (d) { return d.group0; })
                        .toPairs()
                        .map(function (x, i) {
                        return {
                            label: x[0],
                            color: _this.analysisConstants.colorPalette[i],
                            data: _.map(x[1], function (d) { return [d.trend.getTime(), d.data]; })
                        };
                    }).value();
                    var min = _.minBy(_this.data, function (d) { return d.trend; }).trend;
                    var max = _.maxBy(_this.data, function (d) { return d.trend; }).trend;
                    var scale = _this.scale || 'Day';
                    if (scale === 'Week')
                        scale = 'Day';
                    var delta = moment(max).diff(min, scale);
                    var maxTicks = Math.floor(_this.$element.width() / 50);
                    if (maxTicks === 0)
                        maxTicks = 7;
                    var tickSize = Math.floor(delta / maxTicks) + 1;
                    _this.options.xaxis.tickSize = [tickSize, scale.toLowerCase()];
                }, 50);
            };
            LineChartComponent.$inject = ['$element', 'common', 'analysisConstants'];
            LineChartComponent = __decorate([
                app.Component('app.analysis', 'aimsAnalysisLineChart', {
                    template: "<flot dataset=\"$ctrl.dataset\" options=\"$ctrl.options\"></flot>",
                    bindings: {
                        data: '<',
                        scale: '<'
                    }
                })
            ], LineChartComponent);
            return LineChartComponent;
        }());
    })(analysis = app.analysis || (app.analysis = {}));
})(app || (app = {}));
var app;
(function (app) {
    var analysis;
    (function (analysis) {
        var labelFormatter = function (label, series) {
            return "<div style=\"font-size:10pt; text-align: center; padding: 2px; color: white;\">".concat(label, "<br/>").concat(series.data[0][1], " (").concat(Math.round(series.percent), "%)</div>");
        };
        var PieChartComponent = (function () {
            function PieChartComponent(analysisConstants, analysisData) {
                this.analysisConstants = analysisConstants;
                this.analysisData = analysisData;
                this.options = {
                    series: {
                        pie: {
                            show: true,
                            radius: 1,
                            label: {
                                show: true,
                                formatter: labelFormatter,
                                radius: 3 / 4,
                                background: {
                                    opacity: 0.5,
                                    color: '#000'
                                }
                            }
                        }
                    },
                    legend: { show: false }
                };
                'ngInject';
                'ngInject';
            }
            PieChartComponent.prototype.$onChanges = function () {
                var _this = this;
                if (!this.chartData)
                    return;
                this.dataset = this.chartData.map(function (d, i) {
                    return {
                        label: d.group0,
                        data: d.data,
                        color: _this.analysisConstants.colorPalette[i % _this.analysisConstants.colorPalette.length]
                    };
                });
            };
            PieChartComponent.$inject = ['analysisConstants', 'analysisData'];
            PieChartComponent = __decorate([
                app.Component('app.analysis', 'aimsAnalysisPieChart', {
                    template: '<flot dataset="$ctrl.dataset" options="$ctrl.options" ng-if="$ctrl.dataset"></flot>',
                    bindings: {
                        chartData: '<'
                    }
                })
            ], PieChartComponent);
            return PieChartComponent;
        }());
    })(analysis = app.analysis || (app.analysis = {}));
})(app || (app = {}));
var app;
(function (app) {
    var analysis;
    (function (analysis) {
        var PivotTableChartComponent = (function () {
            function PivotTableChartComponent(common, analysisConstants) {
                var _this = this;
                this.common = common;
                this.analysisConstants = analysisConstants;
                this.$onChanges = function () {
                    _this.common.$timeout(function () {
                        var yaxis = _(_this.data).map('group0').uniq().value();
                        var xaxis = _(_this.data).map('group1').uniq().value();
                        _this.dataset = _.map(yaxis, function (y) {
                            return {
                                label: moment(y).isValid() ? moment(y).format('M/D/YY') : y,
                                total: _(_this.data).filter(function (d) { return d.group0 === y; }).map('data').sum(),
                                data: _.map(xaxis, function (x) {
                                    return {
                                        label: moment(x).isValid() ? moment(x).format('M/D/YY') : x,
                                        value: _(_this.data).filter(function (d) { return d.group0 === y && d.group1 === x; }).map('data').sum(),
                                        total: _(_this.data).filter(function (d) { return d.group1 === x; }).map('data').sum()
                                    };
                                })
                            };
                        });
                    });
                };
                'ngInject';
                'ngInject';
            }
            PivotTableChartComponent.prototype.grandTotal = function () {
                return _(this.dataset).map('total').sum();
            };
            PivotTableChartComponent.$inject = ['common', 'analysisConstants'];
            PivotTableChartComponent = __decorate([
                app.Component('app.analysis', 'aimsAnalysisPivotTableChart', {
                    templateUrl: 'app/analysis/components/chart/pivot-table-chart.html',
                    bindings: {
                        data: '<'
                    }
                })
            ], PivotTableChartComponent);
            return PivotTableChartComponent;
        }());
    })(analysis = app.analysis || (app.analysis = {}));
})(app || (app = {}));
var app;
(function (app) {
    var analysis;
    (function (analysis) {
        var StackedBarChartComponent = (function () {
            function StackedBarChartComponent(common, analysisConstants) {
                var _this = this;
                this.common = common;
                this.analysisConstants = analysisConstants;
                this.options = {
                    series: {
                        stack: true,
                        bars: {
                            align: 'center',
                            barWidth: 0.6,
                            fill: 0.9,
                            horizontal: true,
                            lineWidth: 0,
                            show: true
                        }
                    },
                    grid: {
                        borderColor: '#eee',
                        borderWidth: 1,
                        hoverable: true,
                        backgroundColor: '#fcfcfc',
                        labelMargin: 25
                    },
                    tooltip: true,
                    tooltipOpts: {
                        content: function (label, x, y, item) {
                            return "<div style=\"font-size:10pt; text-align: center; padding: 2px; color: white;\">".concat(item.series.yaxis.ticks[y].label, "<br />").concat(label, " : ").concat(x, "</div>");
                        },
                        zIndex: 2000
                    },
                    xaxis: {
                        tickColor: '#fcfcfc',
                        tickDecimals: 0,
                    },
                    yaxis: {
                        autoscaleMargin: .1,
                        tickColor: '#eee',
                        ticks: null
                    },
                    legend: {
                        position: 'ne',
                        noColumns: 0
                    }
                };
                this.$onChanges = function () {
                    _this.common.$timeout(function () {
                        var xaxis = _(_this.data).map('group0').uniq().sort().value();
                        var yaxis = _(_this.data).reverse().map('group1').uniq().value();
                        _this.dataset = _.map(xaxis, function (x, xi) {
                            return {
                                label: x,
                                color: _this.analysisConstants.colorPalette[xi % _this.analysisConstants.colorPalette.length],
                                data: _.map(yaxis, function (y, i) {
                                    return [_(_this.data).filter(function (d) { return d.group0 === x && d.group1 === y; }).map('data').sum(), i];
                                })
                            };
                        });
                        _this.options.yaxis.ticks = _(_this.data).map(function (d) { return d.group1; }).uniq().map(function (g, i) { return [i, g]; }).value();
                    });
                };
                'ngInject';
                'ngInject';
            }
            StackedBarChartComponent.$inject = ['common', 'analysisConstants'];
            StackedBarChartComponent = __decorate([
                app.Component('app.analysis', 'aimsAnalysisStackedBarChart', {
                    template: '<flot dataset="$ctrl.dataset" options="$ctrl.options" ></flot>',
                    bindings: {
                        data: '<'
                    }
                })
            ], StackedBarChartComponent);
            return StackedBarChartComponent;
        }());
    })(analysis = app.analysis || (app.analysis = {}));
})(app || (app = {}));
var app;
(function (app) {
    var analysis;
    (function (analysis) {
        var StackedColumnChartComponent = (function () {
            function StackedColumnChartComponent(common, analysisConstants) {
                var _this = this;
                this.common = common;
                this.analysisConstants = analysisConstants;
                this.options = {
                    series: {
                        stack: true,
                        bars: {
                            align: 'center',
                            barWidth: 0.6,
                            fill: 0.9,
                            lineWidth: 0,
                            show: true
                        }
                    },
                    grid: {
                        borderColor: '#eee',
                        borderWidth: 1,
                        hoverable: true,
                        backgroundColor: '#fcfcfc',
                        labelMargin: 25
                    },
                    tooltip: true,
                    tooltipOpts: {
                        content: function (label, x, y, item) {
                            return "<div style=\"font-size:10pt; text-align: center; padding: 2px; color: white;\">".concat(item.series.xaxis.ticks[x].label, "<br />").concat(label, " : ").concat(y, "</div>");
                        },
                        zIndex: 2000
                    },
                    xaxis: {
                        tickColor: '#fcfcfc',
                        autoscaleMargin: .1,
                        ticks: null
                    },
                    yaxis: {
                        tickColor: '#eee',
                        tickDecimals: 0
                    },
                    legend: {
                        position: 'ne',
                        noColumns: 0
                    }
                };
                this.$onChanges = function () {
                    _this.common.$timeout(function () {
                        var yaxis = _(_this.data).map('group0').uniq().value();
                        var xaxis = _(_this.data).map('group1').uniq().value();
                        _this.dataset = _.map(yaxis, function (y, yi) {
                            return {
                                label: y,
                                color: _this.analysisConstants.colorPalette[yi % _this.analysisConstants.colorPalette.length],
                                data: _.map(xaxis, function (x, i) {
                                    return [i, _(_this.data).filter(function (d) { return d.group0 === y && d.group1 === x; }).map('data').sum()];
                                })
                            };
                        });
                        _this.options.xaxis.ticks = _(_this.data).map(function (d) { return d.group1; }).uniq().map(function (g, i) { return [i, g]; }).value();
                    });
                };
                'ngInject';
                'ngInject';
            }
            StackedColumnChartComponent.$inject = ['common', 'analysisConstants'];
            StackedColumnChartComponent = __decorate([
                app.Component('app.analysis', 'aimsAnalysisStackedColumnChart', {
                    template: '<flot dataset="$ctrl.dataset" options="$ctrl.options" ></flot>',
                    bindings: {
                        data: '<'
                    }
                })
            ], StackedColumnChartComponent);
            return StackedColumnChartComponent;
        }());
    })(analysis = app.analysis || (app.analysis = {}));
})(app || (app = {}));
var app;
(function (app) {
    var analysis;
    (function (analysis) {
        var ChartEditorForm = (function () {
            function ChartEditorForm(analysisConstants) {
                this.analysisConstants = analysisConstants;
                'ngInject';
                'ngInject';
                this.chartTypes = analysisConstants.chartTypes;
            }
            ChartEditorForm.prototype.onChartTypeChanged = function () {
                this.chart.metadata.type = this.chartType.name;
                if (this.chartType.trending) {
                    this.chart.metadata.sortBy = 'Label';
                    this.chart.metadata.sortDirection = 'Asc';
                }
                else {
                    delete this.chart.metadata.trendField;
                    delete this.chart.metadata.trendScale;
                }
                if (!this.chartType.percentages) {
                    this.chart.metadata.showPercentages = false;
                }
                if (!this.chartType.totals) {
                    this.chart.metadata.showTotals = false;
                }
                this.chart.metadata.groupBy.splice(this.chartType.groupByCount);
            };
            ChartEditorForm.prototype.subGroupByPlaceholder = function () {
                if (!this.chart || !this.chart.metadata)
                    return;
                switch (this.chart.metadata.type) {
                    case 'StackedBar': return 'Y - Axis';
                    case 'StackedColumn': return 'X - Axis';
                    case 'PivotTable': return 'Columns';
                    default: return null;
                }
            };
            ChartEditorForm.$inject = ['analysisConstants'];
            ChartEditorForm = __decorate([
                app.Component('app.analysis', 'aimsAnalysisChartForm', {
                    templateUrl: 'app/analysis/views/create-chart/chart-form.html',
                    bindings: {
                        chart: '<',
                        dataSource: '<'
                    },
                    require: {
                        form: '^^form'
                    }
                })
            ], ChartEditorForm);
            return ChartEditorForm;
        }());
    })(analysis = app.analysis || (app.analysis = {}));
})(app || (app = {}));
var app;
(function (app) {
    var analysis;
    (function (analysis) {
        var NewChartViewComponent = (function () {
            function NewChartViewComponent($scope, common, datacontext) {
                var _this = this;
                this.$scope = $scope;
                this.common = common;
                this.datacontext = datacontext;
                this.data = {
                    addToDashboard: true,
                    metadata: {
                        aggregateBy: 'Count',
                        aggregateField: '*',
                        groupBy: [],
                        sortBy: 'Value',
                        sortDirection: 'Desc'
                    }
                };
                this.isSubmitting = false;
                this.previewChart = function () {
                    if (!_this.validateMetadata()) {
                        _this.chartData = null;
                        return;
                    }
                    return _this.datacontext.analysis
                        .previewChartData(_this.query.id, { metadata: _this.data.metadata })
                        .then(function (result) { return _this.chartData = result; });
                };
                this.validateMetadata = function () {
                    var metadata = _this.data.metadata;
                    if (!metadata.aggregateBy)
                        return false;
                    if (!metadata.aggregateField)
                        return false;
                    if (!metadata.groupBy.length)
                        return false;
                    if (!metadata.sortBy)
                        return false;
                    if (!metadata.sortDirection)
                        return false;
                    if (!metadata.type)
                        return false;
                    return true;
                };
                'ngInject';
                'ngInject';
            }
            NewChartViewComponent.prototype.submit = function () {
                var _this = this;
                this.isSubmitting = true;
                this.datacontext.analysis
                    .createChart(this.data)
                    .then(function (result) {
                    if (result) {
                        _this.common.$state.go('app.analysis.query.charts', { queryId: _this.query.id });
                    }
                }).finally(function () { return _this.isSubmitting = false; });
            };
            NewChartViewComponent.prototype.$onInit = function () {
                var _this = this;
                this.getDataSource();
                this.$scope.$watch(function () { return _this.data.metadata; }, this.previewChart, true);
            };
            NewChartViewComponent.prototype.$onChanges = function () {
                this.data.queryId = this.query.id;
            };
            NewChartViewComponent.prototype.getDataSource = function () {
                var _this = this;
                this.datacontext.analysis
                    .getDataSource(this.query.dataSourceId)
                    .then(function (result) { return _this.dataSource = result; });
            };
            NewChartViewComponent.$inject = ['$scope', 'common', 'datacontext'];
            NewChartViewComponent = __decorate([
                app.Component('app.analysis', 'aimsAnalysisCreateChart', {
                    templateUrl: 'app/analysis/views/create-chart/create-chart.html',
                    bindings: {
                        query: '<'
                    }
                })
            ], NewChartViewComponent);
            return NewChartViewComponent;
        }());
    })(analysis = app.analysis || (app.analysis = {}));
})(app || (app = {}));
var app;
(function (app) {
    var analysis;
    (function (analysis) {
        var NotificationsSidenavComponent = (function () {
            function NotificationsSidenavComponent($mdDialog, $mdSidenav, common, datacontext, analysisConstants) {
                var _this = this;
                this.$mdDialog = $mdDialog;
                this.$mdSidenav = $mdSidenav;
                this.common = common;
                this.datacontext = datacontext;
                this.analysisConstants = analysisConstants;
                this.$onInit = function () {
                    _this.cancelToken = _this.common.$rootScope.$on(_this.analysisConstants.ADD_CHART_SIDENAV_OPENED, _this.getCharts);
                };
                this.$onDestroy = function () {
                    _this.cancelToken();
                };
                this.getCharts = function () {
                    return _this.datacontext.analysis
                        .getChartList()
                        .then(function (result) { return _this.charts = _.filter(result, function (c) { return !_.some(_this.dashboard.charts, function (dc) { return dc.chart.id === c.id; }); }); });
                };
                'ngInject';
                'ngInject';
            }
            NotificationsSidenavComponent.prototype.addChart = function (chart, index) {
                var _this = this;
                var chartId = chart.id;
                this.datacontext.analysis
                    .addChart(this.dashboard.id, { chartId: chartId })
                    .then(function (result) {
                    if (result) {
                        _this.onChartAdded({ chart: result });
                        _this.charts.splice(index, 1);
                    }
                });
            };
            NotificationsSidenavComponent.prototype.close = function () {
                this.$mdSidenav(this.analysisConstants.ADD_CHART_SIDENAV_ID).close();
            };
            NotificationsSidenavComponent.$inject = ['$mdDialog', '$mdSidenav', 'common', 'datacontext', 'analysisConstants'];
            NotificationsSidenavComponent = __decorate([
                app.Component('app.analysis', 'aimsAnalysisAddChartSidenav', {
                    templateUrl: 'app/analysis/views/dashboard/add-chart-sidenav.html',
                    bindings: {
                        dashboard: '<',
                        onChartAdded: '&'
                    }
                })
            ], NotificationsSidenavComponent);
            return NotificationsSidenavComponent;
        }());
    })(analysis = app.analysis || (app.analysis = {}));
})(app || (app = {}));
var app;
(function (app) {
    var analysis;
    (function (analysis) {
        var DashboardChartComponent = (function () {
            function DashboardChartComponent(common, datacontext) {
                var _this = this;
                this.common = common;
                this.datacontext = datacontext;
                this.getData = function () {
                    return _this.datacontext.analysis
                        .getChartData(_this.chart.chart.id)
                        .then(function (result) { return _this.data = result; });
                };
                this.removeCore = function () {
                    _this.datacontext.analysis
                        .removeChart(_this.dashboardId, _this.chart.chart.id)
                        .then(function (result) {
                        if (result) {
                            _this.onRemove();
                        }
                    });
                };
                'ngInject';
                'ngInject';
            }
            DashboardChartComponent.prototype.remove = function () {
                this.common.confirmation.show({
                    title: 'Remove Chart?',
                    message: 'Are you sure you want to remove this chart?',
                    ok: 'Remove',
                    cancel: 'Keep'
                }).then(this.removeCore);
            };
            DashboardChartComponent.prototype.scale = function (colSpan, rowSpan) {
                this.chart.layout.colSpan = colSpan;
                this.chart.layout.rowSpan = rowSpan;
            };
            DashboardChartComponent.prototype.$onChanges = function () {
                this.getData();
            };
            DashboardChartComponent.$inject = ['common', 'datacontext'];
            DashboardChartComponent = __decorate([
                app.Component('app.analysis', 'aimsAnalysisDashboardChart', {
                    templateUrl: 'app/analysis/views/dashboard/dashboard-chart.html',
                    bindings: {
                        dashboardId: '<',
                        chart: '<',
                        isEditing: '<',
                        onRemove: '&'
                    }
                })
            ], DashboardChartComponent);
            return DashboardChartComponent;
        }());
    })(analysis = app.analysis || (app.analysis = {}));
})(app || (app = {}));
var app;
(function (app) {
    var analysis;
    (function (analysis) {
        var DashboardComponent = (function () {
            function DashboardComponent($mdSidenav, common, datacontext, analysisConstants) {
                var _this = this;
                this.$mdSidenav = $mdSidenav;
                this.common = common;
                this.datacontext = datacontext;
                this.analysisConstants = analysisConstants;
                this.isEditing = false;
                this.updateChartLayout = function (chart) {
                    return _this.datacontext.analysis
                        .updateChartLayout(_this.dashboard.id, chart.chart.id, chart.layout);
                };
                'ngInject';
                'ngInject';
            }
            DashboardComponent.prototype.addChart = function () {
                this.$mdSidenav(this.analysisConstants.ADD_CHART_SIDENAV_ID).open();
                this.common.$broadcast(this.analysisConstants.ADD_CHART_SIDENAV_OPENED);
            };
            DashboardComponent.prototype.editLayout = function () {
                var _this = this;
                var orderedIds = _(this.dashboard.charts)
                    .sortBy(function (c) { return c.layout.sequenceNo; })
                    .map(function (c) { return c.chart.id; })
                    .value();
                angular.forEach(orderedIds, function (id, i) {
                    _.find(_this.dashboard.charts, { chart: { id: id } }).layout.sequenceNo = i + 1;
                });
                this.isEditing = true;
            };
            DashboardComponent.prototype.onChartAdded = function (chart) {
                this.dashboard.charts.splice(0, 0, chart);
            };
            DashboardComponent.prototype.onChartRemoved = function (chart) {
                var index = this.dashboard.charts.indexOf(chart);
                this.dashboard.charts.splice(index, 1);
            };
            DashboardComponent.prototype.saveLayout = function () {
                var _this = this;
                var promises = angular.forEach(this.dashboard.charts, this.updateChartLayout);
                this.common.$q.all(promises)
                    .then(function () { return _this.isEditing = false; });
            };
            DashboardComponent.$inject = ['$mdSidenav', 'common', 'datacontext', 'analysisConstants'];
            DashboardComponent = __decorate([
                app.Component('app.analysis', 'aimsAnalysisDashboard', {
                    templateUrl: 'app/analysis/views/dashboard/dashboard.html',
                    bindings: {
                        dashboard: '<'
                    }
                })
            ], DashboardComponent);
            return DashboardComponent;
        }());
    })(analysis = app.analysis || (app.analysis = {}));
})(app || (app = {}));
var app;
(function (app) {
    var analysis;
    (function (analysis) {
        var operators = [
            { value: 'Equals', label: 'Equals', dataTypes: ['Boolean', 'Date', 'Number', 'String'], lookupSupport: true },
            { value: 'DoesNotEqual', label: 'Does not equal', dataTypes: ['Boolean', 'Date', 'Number', 'String'], lookupSupport: true },
            { value: 'Contains', label: 'Contains', dataTypes: ['String'] },
            { value: 'DoesNotContain', label: 'Does not contain', dataTypes: ['String'] },
            { value: 'Includes', label: 'Includes', dataTypes: ['String'], lookupSupport: true, multiple: true },
            { value: 'DoesNotInclude', label: 'Does not include', dataTypes: ['String'], lookupSupport: true, multiple: true },
            { value: 'StartsWith', label: 'Starts with', dataTypes: ['String'] },
            { value: 'DoesNotStartWith', label: 'Does not start with', dataTypes: ['String'] },
            { value: 'EndsWith', label: 'Ends with', dataTypes: ['String'] },
            { value: 'DoesNotEndWith', label: 'Does not end with', dataTypes: ['String'] },
            { value: 'GreaterThan', label: 'Greater than', dataTypes: ['Date', 'Number'] },
            { value: 'GreaterThanOrEqualTo', label: 'Greater than or equal to', dataTypes: ['Date', 'Number'] },
            { value: 'LessThan', label: 'Less than', dataTypes: ['Date', 'Number'] },
            { value: 'LessThanOrEqualTo', label: 'Less than or equal to', dataTypes: ['Date', 'Number'] }
        ];
        var ManageQuery = (function () {
            function ManageQuery($mdDialog, common, datacontext) {
                this.$mdDialog = $mdDialog;
                this.common = common;
                this.datacontext = datacontext;
                this.data = {};
                this.lookups = {};
                'ngInject';
                'ngInject';
            }
            ManageQuery.prototype.$onInit = function () {
                if (this.queryId) {
                    this.loadData();
                }
                else {
                    this.data = {
                        metadata: {
                            columns: [],
                            filters: [{ type: 'And', criteria: [] }]
                        }
                    };
                }
            };
            ManageQuery.prototype.loadData = function () {
                var _this = this;
                this.datacontext.analysis.getQuery(this.queryId)
                    .then(function (result) {
                    _this.data = result;
                    _this.dataSource = _.find(_this.dataSources, function (d) { return d.id === result.dataSourceId; });
                    _.forEach(_this.data.metadata.filters, function (f) {
                        f.field = _.find(_this.dataSource.metadata.fields, function (field) { return field.name === f.criteria[0].field; });
                        f.operator = _.find(operators, function (o) { return o.value === f.criteria[0].operator; });
                    });
                });
            };
            ManageQuery.prototype.addFilter = function (index) {
                var type;
                if (index === 0)
                    type = 'And';
                this.data.metadata.filters.splice(index, 0, { type: type, criteria: [] });
            };
            ManageQuery.prototype.deleteFilter = function (index) {
                this.data.metadata.filters.splice(index, 1);
            };
            ManageQuery.prototype.getLookups = function (type) {
                var _this = this;
                var deferred = this.common.$q.defer();
                if (this.lookups[type]) {
                    deferred.resolve(this.lookups[type]);
                }
                else {
                    this.datacontext.config.lookups
                        .getLookupType(type)
                        .then(function (result) {
                        _this.lookups[type] = _.map(result.lookups, function (l) { return l.value; });
                        deferred.resolve(_this.lookups[type]);
                    });
                }
                return deferred.promise;
            };
            ManageQuery.prototype.getOperators = function (field) {
                if (!field)
                    return;
                return _.filter(operators, function (o) { return o.dataTypes.indexOf(field.dataType) > -1; });
            };
            ManageQuery.prototype.getTabularData = function () {
                var _this = this;
                return this.datacontext.analysis
                    .previewTabularData(this.tabularDataParams)
                    .then(function (result) { return _this.tabularData = result; });
            };
            ManageQuery.prototype.onDataSourceSelected = function () {
                this.data.dataSourceId = this.dataSource.id;
                this.tabularFields = _.filter(this.dataSource.metadata.fields, function (f) { return f.visible; });
                this.data.metadata.columns = _.map(this.tabularFields, function (f) { return f.name; });
            };
            ManageQuery.prototype.onOperatorChanged = function (filter) {
                filter.criteria[0].operator = filter.operator.value;
                if (filter.operator.multiple) {
                    filter.criteria[0].value = null;
                    if (!filter.criteria[0].values) {
                        filter.criteria[0].values = [];
                    }
                }
                else {
                    filter.criteria[0].values = null;
                }
            };
            ManageQuery.prototype.runQuery = function (form) {
                form.$setPristine();
                form.$setSubmitted();
                if (form.$invalid) {
                    return;
                }
                this.tabularDataParams = {
                    dataSourceId: this.dataSource.id,
                    query: angular.copy(this.data.metadata),
                    page: 1,
                    pageSize: 10
                };
                this.getTabularData();
            };
            ManageQuery.prototype.saveQueryAs = function (targetEvent) {
                this.data.id = null;
                this.data.name += ' Copy';
                this.saveQuery(targetEvent);
            };
            ManageQuery.prototype.saveQuery = function (targetEvent) {
                var _this = this;
                var query = angular.copy(this.data);
                var filter;
                for (var _i = 0, _a = query.metadata.filters; _i < _a.length; _i++) {
                    filter = _a[_i];
                    delete filter.field;
                    delete filter.operator;
                }
                var parent = angular.element(document.body);
                this.$mdDialog.show({
                    parent: parent,
                    targetEvent: targetEvent,
                    clickOutsideToClose: false,
                    fullscreen: true,
                    templateUrl: 'app/analysis/views/manage-query/save-query.html',
                    controller: 'SaveAnalysisQueryController',
                    controllerAs: '$ctrl',
                    locals: { query: query }
                }).then(function (result) {
                    _this.common.$mdToast.showSimple('Query saved');
                    _this.common.$state.go('^.query', { queryId: result.id });
                }).catch(function (error) { if (error)
                    throw error; });
            };
            ManageQuery.$inject = ['$mdDialog', 'common', 'datacontext'];
            ManageQuery = __decorate([
                app.Component('app.analysis', 'ceAnalysisManageQuery', {
                    templateUrl: 'app/analysis/views/manage-query/manage-query.html',
                    bindings: {
                        dataSources: '<',
                        queryId: '<'
                    }
                })
            ], ManageQuery);
            return ManageQuery;
        }());
    })(analysis = app.analysis || (app.analysis = {}));
})(app || (app = {}));
var app;
(function (app) {
    var analysis;
    (function (analysis) {
        var SaveAnalysisQueryController = (function () {
            function SaveAnalysisQueryController($mdDialog, datacontext, query) {
                this.$mdDialog = $mdDialog;
                this.datacontext = datacontext;
                this.query = query;
                'ngInject';
                'ngInject';
            }
            SaveAnalysisQueryController.prototype.cancel = function () {
                this.$mdDialog.cancel();
            };
            SaveAnalysisQueryController.prototype.submit = function () {
                var _this = this;
                var queryPromise = this.query.id
                    ? this.datacontext.analysis.updateQuery(this.query)
                    : this.datacontext.analysis.createQuery(this.query);
                queryPromise.then(function (result) {
                    if (result) {
                        _this.$mdDialog.hide(result);
                    }
                });
            };
            SaveAnalysisQueryController.$inject = ['$mdDialog', 'datacontext', 'query'];
            SaveAnalysisQueryController = __decorate([
                app.Controller('app.analysis', 'SaveAnalysisQueryController')
            ], SaveAnalysisQueryController);
            return SaveAnalysisQueryController;
        }());
    })(analysis = app.analysis || (app.analysis = {}));
})(app || (app = {}));
var app;
(function (app) {
    var analysis;
    (function (analysis) {
        var ColumnSettingsController = (function () {
            function ColumnSettingsController($mdDialog, datacontext, queryId, columns, selectedColumns) {
                this.$mdDialog = $mdDialog;
                this.datacontext = datacontext;
                this.queryId = queryId;
                this.columns = columns;
                this.selectedColumns = selectedColumns;
                this.isSubmitting = false;
                'ngInject';
                'ngInject';
            }
            ColumnSettingsController.prototype.cancel = function () {
                this.$mdDialog.cancel();
            };
            ColumnSettingsController.prototype.isColumnSelected = function (name) {
                return this.selectedColumns.indexOf(name) > -1;
            };
            ColumnSettingsController.prototype.submit = function () {
                var _this = this;
                this.isSubmitting = true;
                this.datacontext.analysis
                    .updateQueryColumns(this.queryId, this.selectedColumns)
                    .then(function (result) {
                    if (result)
                        _this.$mdDialog.hide(_this.selectedColumns);
                }).finally(function () { return _this.isSubmitting = false; });
            };
            ColumnSettingsController.prototype.toggleColumn = function (name) {
                var index = this.selectedColumns.indexOf(name);
                if (index > -1) {
                    this.selectedColumns.splice(index, 1);
                }
                else {
                    this.selectedColumns.push(name);
                }
            };
            ColumnSettingsController.$inject = ['$mdDialog', 'datacontext', 'queryId', 'columns', 'selectedColumns'];
            ColumnSettingsController = __decorate([
                app.Controller('app.analysis', 'AnalysisColumnSettingsController')
            ], ColumnSettingsController);
            return ColumnSettingsController;
        }());
    })(analysis = app.analysis || (app.analysis = {}));
})(app || (app = {}));
var app;
(function (app) {
    var analysis;
    (function (analysis) {
        var DashboardChartComponent = (function () {
            function DashboardChartComponent(common, datacontext) {
                var _this = this;
                this.common = common;
                this.datacontext = datacontext;
                this.getData = function () {
                    return _this.datacontext.analysis
                        .getChartData(_this.chart.id)
                        .then(function (result) { return _this.data = result; });
                };
                this.deleteChartCore = function () {
                    _this.datacontext.analysis
                        .deleteChart(_this.chart.id)
                        .then(function (result) {
                        if (result) {
                            _this.onDelete();
                        }
                    });
                };
                'ngInject';
                'ngInject';
            }
            DashboardChartComponent.prototype.deleteChart = function () {
                this.common.confirmation.show({
                    title: 'Delete Chart?',
                    message: 'Are you sure you want to delete this chart?',
                    ok: 'Delete',
                    cancel: 'Keep'
                }).then(this.deleteChartCore);
            };
            DashboardChartComponent.prototype.$onInit = function () {
                this.getData();
            };
            DashboardChartComponent.$inject = ['common', 'datacontext'];
            DashboardChartComponent = __decorate([
                app.Component('app.analysis', 'aimsAnalysisQueryChart', {
                    templateUrl: 'app/analysis/views/query-details/query-chart.html',
                    bindings: {
                        chart: '<',
                        onDelete: '&'
                    }
                })
            ], DashboardChartComponent);
            return DashboardChartComponent;
        }());
    })(analysis = app.analysis || (app.analysis = {}));
})(app || (app = {}));
var app;
(function (app) {
    var analysis;
    (function (analysis) {
        var QueryCharts = (function () {
            function QueryCharts() {
            }
            QueryCharts.prototype.onDelete = function (chart) {
                var index = this.charts.indexOf(chart);
                this.charts.splice(index, 1);
            };
            QueryCharts = __decorate([
                app.Component('app.analysis', 'aimsAnalysisQueryCharts', {
                    templateUrl: 'app/analysis/views/query-details/query-charts.html',
                    bindings: {
                        query: '<',
                        charts: '<'
                    }
                })
            ], QueryCharts);
            return QueryCharts;
        }());
    })(analysis = app.analysis || (app.analysis = {}));
})(app || (app = {}));
var app;
(function (app) {
    var analysis;
    (function (analysis) {
        var QueryData = (function () {
            function QueryData($mdDialog, datacontext, blobHelper) {
                var _this = this;
                this.$mdDialog = $mdDialog;
                this.datacontext = datacontext;
                this.blobHelper = blobHelper;
                this.dataParams = {
                    page: 1,
                    pageSize: 10
                };
                this.refresh = function () {
                    _this.dataPromise = _this.datacontext.analysis
                        .getTabularData(_this.query.id, _this.dataParams)
                        .then(function (result) { return _this.tabularData = result; });
                    return _this.dataPromise;
                };
                this.initVisibleFields = function () {
                    var fields = _this.dataSource.metadata.fields;
                    var columns = _this.query.metadata.columns;
                    _this.visibleFields = _(fields)
                        .filter(function (f) { return _.some(columns, function (c) { return c === f.name; }); })
                        .value();
                };
                'ngInject';
                'ngInject';
            }
            QueryData.prototype.export = function () {
                var _this = this;
                this.datacontext.analysis
                    .downloadData(this.query.id)
                    .then(function (result) {
                    if (result) {
                        _this.blobHelper.open({
                            buffer: result.buffer,
                            fileName: result.fileName,
                            contentType: result.contentType
                        });
                    }
                });
            };
            QueryData.prototype.openColumnSettings = function (targetEvent) {
                var _this = this;
                var parent = angular.element(document.body);
                this.$mdDialog.show({
                    parent: parent,
                    targetEvent: targetEvent,
                    clickOutsideToClose: false,
                    fullscreen: true,
                    templateUrl: 'app/analysis/views/query-details/column-settings.html',
                    controller: 'AnalysisColumnSettingsController',
                    controllerAs: '$ctrl',
                    locals: {
                        queryId: this.query.id,
                        columns: this.dataSource.metadata.fields,
                        selectedColumns: this.query.metadata.columns
                    }
                }).then(function (result) {
                    _this.query.metadata.columns = result;
                    _this.initVisibleFields();
                }).catch(angular.noop);
            };
            QueryData.prototype.$onChanges = function () {
                var _this = this;
                this.refresh();
                this.datacontext.analysis
                    .getDataSource(this.query.dataSourceId)
                    .then(function (result) {
                    _this.dataSource = result;
                    _this.initVisibleFields();
                });
            };
            QueryData.$inject = ['$mdDialog', 'datacontext', 'blobHelper'];
            QueryData = __decorate([
                app.Component('app.analysis', 'aimsAnalysisQueryData', {
                    templateUrl: 'app/analysis/views/query-details/query-data.html',
                    bindings: {
                        query: '<'
                    }
                })
            ], QueryData);
            return QueryData;
        }());
    })(analysis = app.analysis || (app.analysis = {}));
})(app || (app = {}));
var app;
(function (app) {
    var analysis;
    (function (analysis) {
        var QueryDetails = (function () {
            function QueryDetails($state) {
                this.$state = $state;
                'ngInject';
                'ngInject';
            }
            QueryDetails.prototype.$onInit = function () {
                this.currentTab = this.$state.current.name.split('.')[3];
            };
            QueryDetails.$inject = ['$state'];
            QueryDetails = __decorate([
                app.Component('app.analysis', 'aimsAnalysisQueryDetails', {
                    templateUrl: 'app/analysis/views/query-details/query-details.html',
                    bindings: {
                        query: '<'
                    }
                })
            ], QueryDetails);
            return QueryDetails;
        }());
    })(analysis = app.analysis || (app.analysis = {}));
})(app || (app = {}));
var app;
(function (app) {
    var analysis;
    (function (analysis) {
        var TabularData = (function () {
            function TabularData() {
                var _this = this;
                this.refresh = function () {
                    _this.promise = _this.onRefresh();
                };
            }
            TabularData = __decorate([
                app.Component('app.analysis', 'aimsAnalysisTabularData', {
                    templateUrl: 'app/analysis/views/query-details/tabular-data.html',
                    bindings: {
                        onRefresh: '&',
                        params: '<',
                        promise: '<',
                        tabularData: '<',
                        visibleFields: '<'
                    }
                })
            ], TabularData);
            return TabularData;
        }());
    })(analysis = app.analysis || (app.analysis = {}));
})(app || (app = {}));
var app;
(function (app) {
    var analysis;
    (function (analysis) {
        var QueryList = (function () {
            function QueryList(confirmation, datacontext) {
                this.confirmation = confirmation;
                this.datacontext = datacontext;
                'ngInject';
                'ngInject';
            }
            QueryList.prototype.$onInit = function () {
                this.getQueries();
            };
            QueryList.prototype.getQueries = function () {
                var _this = this;
                this.datacontext.analysis
                    .getQueryList(this.search)
                    .then(function (result) { return _this.queries = result; });
            };
            QueryList.prototype.deleteQuery = function (id) {
                var _this = this;
                this.confirmation.show({
                    message: 'Are you sure you want to delete this Query and related Dashboard items?',
                    ok: 'DELETE',
                    cancel: 'KEEP'
                }).then(function () { return _this.deleteQueryCore(id); });
            };
            QueryList.prototype.deleteQueryCore = function (id) {
                var _this = this;
                this.datacontext.analysis
                    .deleteQuery(id)
                    .then(function () { return _this.getQueries(); });
            };
            QueryList.$inject = ['confirmation', 'datacontext'];
            QueryList = __decorate([
                app.Component('app.analysis', 'aimsAnalysisQueryList', {
                    templateUrl: 'app/analysis/views/query-list/query-list.html',
                    bindings: {}
                })
            ], QueryList);
            return QueryList;
        }());
    })(analysis = app.analysis || (app.analysis = {}));
})(app || (app = {}));
var app;
(function (app) {
    var alerts;
    (function (alerts) {
        var POLLING_INTERVAL = 180000;
        var NotificationsButton = (function () {
            function NotificationsButton($mdSidenav, common, datacontext) {
                this.$mdSidenav = $mdSidenav;
                this.common = common;
                this.datacontext = datacontext;
                'ngInject';
                'ngInject';
            }
            NotificationsButton.prototype.$onInit = function () {
                var _this = this;
                this.getNotificationsCount();
                this.intervalId = setInterval(function () { return _this.getNotificationsCount(); }, POLLING_INTERVAL);
            };
            NotificationsButton.prototype.$onDestroy = function () {
                clearInterval(this.intervalId);
            };
            NotificationsButton.prototype.open = function () {
                var sidenav = this.$mdSidenav('notifications');
                if (!sidenav.isOpen()) {
                    sidenav.open();
                    this.common.$broadcast(alerts.NOTIFICATIONS_SIDENAV_OPENED);
                }
            };
            NotificationsButton.prototype.getNotificationsCount = function () {
                var _this = this;
                return this.datacontext.alerts.getNotificationsCount().then(function (result) {
                    if (result) {
                        _this.setCount(result.count);
                    }
                });
            };
            NotificationsButton.prototype.setCount = function (count) {
                if (isNaN(count))
                    return;
                if (this.count !== count) {
                    this.count = +count;
                    this.badge = this.count > 99 ? '99+' : this.count;
                }
            };
            NotificationsButton.$inject = ['$mdSidenav', 'common', 'datacontext'];
            NotificationsButton = __decorate([
                app.Component('app.alerts', 'ceNotificationsButton', {
                    template: '<ce-badged-button badge="$ctrl.badge" icon="notifications" tooltip="Notifications" on-click="$ctrl.open($event)"></ce-badged-button>'
                })
            ], NotificationsButton);
            return NotificationsButton;
        }());
    })(alerts = app.alerts || (app.alerts = {}));
})(app || (app = {}));
var app;
(function (app) {
    var alerts;
    (function (alerts) {
        var NotificationsSidebar = (function () {
            function NotificationsSidebar($mdDialog, $mdSidenav, common, datacontext) {
                var _this = this;
                this.$mdDialog = $mdDialog;
                this.$mdSidenav = $mdSidenav;
                this.common = common;
                this.datacontext = datacontext;
                this.loading = false;
                this.$onInit = function () {
                    _this.cancelToken = _this.common.$rootScope.$on(alerts.NOTIFICATIONS_SIDENAV_OPENED, _this.getNotifications);
                };
                this.$onDestroy = function () {
                    _this.cancelToken();
                };
                this.getNotifications = function () {
                    _this.loading = true;
                    return _this.datacontext.alerts
                        .getNotifications({ unclearedOnly: true, pageSize: 7 })
                        .then(function (result) { return _this.notifications = result ? result.list : []; })
                        .finally(function () { return _this.loading = false; });
                };
                'ngInject';
                'ngInject';
            }
            NotificationsSidebar.prototype.clearNotification = function (notification) {
                var _this = this;
                this.datacontext.alerts.clearNotification(notification.id).then(function (result) {
                    if (result) {
                        _.remove(_this.notifications, notification);
                    }
                });
            };
            NotificationsSidebar.prototype.openPreferences = function (targetEvent) {
                var parent = angular.element(document.body);
                this.$mdDialog.show({
                    parent: parent,
                    targetEvent: targetEvent,
                    template: '<ce-notification-preferences aria-label="Notification preferences"></ce-notification-preferences>'
                });
            };
            NotificationsSidebar.prototype.refresh = function () {
                this.getNotifications();
            };
            NotificationsSidebar.prototype.showAll = function () {
                this.common.$state.go('app.notifications');
                this.$mdSidenav('notifications').close();
            };
            NotificationsSidebar.$inject = ['$mdDialog', '$mdSidenav', 'common', 'datacontext'];
            NotificationsSidebar = __decorate([
                app.Component('app.alerts', 'ceNotificationsSidebar', {
                    templateUrl: 'app/alerts/components/notifications-sidebar/notifications-sidebar.html'
                })
            ], NotificationsSidebar);
            return NotificationsSidebar;
        }());
    })(alerts = app.alerts || (app.alerts = {}));
})(app || (app = {}));
var app;
(function (app) {
    var alerts;
    (function (alerts) {
        var NotificationPreferences = (function () {
            function NotificationPreferences($scope, $mdDialog, common, datacontext) {
                var _this = this;
                this.$scope = $scope;
                this.$mdDialog = $mdDialog;
                this.common = common;
                this.datacontext = datacontext;
                this.isSubmitting = false;
                this.isUploading = false;
                this.alertTypes = ['CalendarEventScheduled', 'RequestAssigned', 'CaseAssigned', 'TrainingAssigned'];
                this.alertTypeSettings = 'any';
                this.notificationPreferencesSettings = [];
                this.selectedAlertTypes = {};
                this.activate = function () {
                    _this.datacontext.alerts.getNotificationPreferences().then(function (result) { return _this.notificationPreferencesSettings = result.settings || []; }).finally(function () {
                        if (_this.notificationPreferencesSettings.length >= 1) {
                            _this.alertTypeSettings = 'specify';
                            _.forEach(_this.notificationPreferencesSettings, function (t) { return _this.selectedAlertTypes[t.value] = true; });
                        }
                    });
                };
                this.close = function () {
                    _this.$mdDialog.hide(_this.photoId);
                };
                this.dismiss = function () {
                    _this.$mdDialog.cancel();
                };
                'ngInject';
                'ngInject';
                this.activate();
            }
            NotificationPreferences.prototype.cancel = function () {
                this.dismiss();
            };
            NotificationPreferences.prototype.clearAlertTypes = function (targetEvent) {
                this.selectedAlertTypes = {};
            };
            NotificationPreferences.prototype.submit = function (form) {
                var _this = this;
                form.$setSubmitted();
                this.isSubmitting = true;
                var data = {
                    alertTypes: []
                };
                if (this.alertTypeSettings === 'specify') {
                    data.alertTypes = _(this.selectedAlertTypes).keys().filter(function (x) { return _this.selectedAlertTypes[x]; }).value();
                }
                this.datacontext.alerts.savePreferences(data)
                    .then(function (result) {
                    if (result) {
                        _this.$mdDialog.hide(result);
                    }
                })
                    .finally(function () { return _this.isSubmitting = false; });
            };
            NotificationPreferences.$inject = ['$scope', '$mdDialog', 'common', 'datacontext'];
            NotificationPreferences = __decorate([
                app.Component('app.alerts', 'ceNotificationPreferences', {
                    bindings: {},
                    templateUrl: 'app/alerts/views/notification-preferences/notification-preferences.html'
                })
            ], NotificationPreferences);
            return NotificationPreferences;
        }());
    })(alerts = app.alerts || (app.alerts = {}));
})(app || (app = {}));
var app;
(function (app) {
    var alerts;
    (function (alerts) {
        var NotificationsList = (function () {
            function NotificationsList($scope, common, datacontext) {
                var _this = this;
                this.$scope = $scope;
                this.common = common;
                this.datacontext = datacontext;
                this.loading = false;
                this.pagination = { page: 1, limit: 25, order: '' };
                this.selectedNotifications = [];
                this.query = { unclearedOnly: true };
                this.clear = function (notification) {
                    return _this.datacontext.alerts.clearNotification(notification.id).then(function (result) {
                        if (result) {
                            _this.removeNotification(notification);
                        }
                    });
                };
                this.removeNotification = function (notification) {
                    var id = notification.id;
                    _.remove(_this.selectedNotifications, { id: id });
                    _.remove(_this.notifications.list, { id: id });
                    _this.notifications.count = _this.notifications.count - 1;
                };
                this.activate = function () {
                    _this.query.page = _this.notifications.page;
                    _this.query.pageSize = _this.notifications.pageSize;
                };
                this.getNotifications = function () {
                    _this.loading = true;
                    return _this.datacontext.alerts
                        .getNotifications(_this.query)
                        .then(function (result) {
                        _this.notifications = result;
                    })
                        .finally(function () { return _this.loading = false; });
                };
                'ngInject';
                'ngInject';
            }
            NotificationsList.prototype.cancelSelection = function () {
                this.selectedNotifications = [];
            };
            NotificationsList.prototype.clearNotifications = function (notifications) {
                var _this = this;
                var count = notifications.length;
                var promises = [];
                angular.forEach(notifications, function (n) { return promises.push(_this.clear(n)); });
                this.common.$q.all(promises).then(function () {
                    _this.common.$mdToast.showSimple("".concat(count, " notification(s) cleared"));
                });
            };
            NotificationsList.$inject = ['$scope', 'common', 'datacontext'];
            NotificationsList = __decorate([
                app.Component('app.alerts', 'ceNotificationsList', {
                    templateUrl: 'app/alerts/views/notifications-list/notifications-list.html',
                    bindings: {
                        notifications: '<'
                    }
                })
            ], NotificationsList);
            return NotificationsList;
        }());
    })(alerts = app.alerts || (app.alerts = {}));
})(app || (app = {}));
var app;
(function (app) {
    var calendars;
    (function (calendars) {
        var CalendarFilters = (function () {
            function CalendarFilters($mdSidenav, calendarData, calendarState) {
                this.$mdSidenav = $mdSidenav;
                this.calendarData = calendarData;
                this.calendarState = calendarState;
                this.users = {};
                'ngInject';
                'ngInject';
            }
            CalendarFilters.prototype.apply = function () {
                this.calendarState.save();
                this.calendarData.refresh();
                this.$mdSidenav('calendarFilters').close();
            };
            CalendarFilters.prototype.usersFilterChanged = function (users) {
                this.state.users = users;
                this.state.userIds = _.map(users, 'id');
            };
            CalendarFilters.prototype.isCalendarSelected = function (calendar) {
                return this.state.calendarIds.indexOf(calendar.id) >= 0;
            };
            CalendarFilters.prototype.getStyle = function (calendar) {
                return;
            };
            CalendarFilters.prototype.toggleCalendarSelected = function (calendar) {
                var index = this.state.calendarIds.indexOf(calendar.id);
                if (index >= 0) {
                    this.state.calendarIds.splice(index, 1);
                }
                else {
                    this.state.calendarIds.push(calendar.id);
                }
            };
            CalendarFilters.prototype.$onInit = function () {
                this.state = this.calendarState.state;
                this.users = this.state.users;
            };
            CalendarFilters.$inject = ['$mdSidenav', 'calendarData', 'calendarState'];
            CalendarFilters = __decorate([
                app.Component('app.calendars', 'ceCalendarFilters', {
                    templateUrl: 'app/calendars/views/calendar/calendar-filters.html'
                })
            ], CalendarFilters);
            return CalendarFilters;
        }());
    })(calendars = app.calendars || (app.calendars = {}));
})(app || (app = {}));
var app;
(function (app) {
    var calendars;
    (function (calendars) {
        var Calendar = (function () {
            function Calendar($scope, $mdPanel, $mdSidenav, common, calendarData, calendarState, $mdDialog) {
                var _this = this;
                this.$scope = $scope;
                this.$mdPanel = $mdPanel;
                this.$mdSidenav = $mdSidenav;
                this.common = common;
                this.calendarData = calendarData;
                this.calendarState = calendarState;
                this.$mdDialog = $mdDialog;
                this.state = this.calendarState.state;
                this.calendarOptions = {
                    aspectRatio: 0,
                    scrollTime: '06:00',
                    editable: false,
                    eventLimit: true,
                    header: false,
                    selectable: false,
                    selectHelper: false,
                    dayClick: function (targetDate, targetEvent, calendarView) {
                        _this.createEvent(targetDate);
                    },
                    eventClick: function (event) {
                        _this.eventClick(event);
                    },
                    views: {
                        highPriority: {
                            type: 'list',
                            duration: { months: 2 },
                            noEventsMessage: 'No High Priority events to display'
                        }
                    },
                    eventRender: function (event, element) { element.attr('aria-label', event.startTime.toLocaleDateString() + ' ' + element[0].innerText); }
                };
                this.refresh = function () {
                    _this.calendarData.refresh();
                };
                this.onViewRender = function (view) {
                    _this.$calendar = view.calendar;
                    _this.title = view.title;
                    var reload = true;
                    if (_this.state.dateRange) {
                        reload = !(_this.state.dateRange.contains(view.start) && _this.state.dateRange.contains(view.end));
                    }
                    var viewDate = view.start.clone();
                    if (view.type === 'month' && view.start.date() > 1) {
                        viewDate = viewDate.startOf('month').add(1, 'month');
                    }
                    _this.calendarState.update({
                        viewType: view.type,
                        viewDate: viewDate
                    });
                    var start = view.start.local();
                    var end = view.end.local();
                    _this.visibleRange = moment.range(start, end);
                    _this.selectedRange = moment.range(start, start);
                    if (reload) {
                        _this.state.dateRange = _this.visibleRange;
                        _this.common.$timeout(_this.refresh);
                    }
                };
                this.loadState = function () {
                    _this.calendarOptions.defaultView = _this.state.viewType || 'month';
                    _this.calendarOptions.defaultDate = _this.state.viewDate;
                };
                this.setContentHeight = function () {
                    _this.$calendar.option('contentHeight', angular.element('#calendar').height());
                };
                'ngInject';
                'ngInject';
            }
            Calendar.prototype.createEvent = function (targetDate) {
                this.common.$state.go('^.eventSchedule', { targetDate: targetDate });
            };
            Calendar.prototype.eventClick = function (event) {
                var _this = this;
                var parent = angular.element(document.body);
                return this.$mdDialog.show({
                    parent: parent,
                    fullscreen: false,
                    templateUrl: 'app/calendars/events/summary-dialog.html',
                    controller: 'EventSummaryDialogController',
                    controllerAs: '$ctrl',
                    locals: {
                        event: event,
                        eventScheduleId: event.eventScheduleId
                    }
                })
                    .then(function (edit) {
                    if (edit === 'eventSchedule') {
                        _this.common.$state.go('^.eventSchedule', { eventScheduleId: event.eventScheduleId });
                    }
                    else {
                        _this.common.$state.go('^.event', { event: event });
                    }
                })
                    .catch(function (error) { if (error)
                    throw error; });
            };
            Calendar.prototype.today = function () {
                this.$calendar.today();
            };
            Calendar.prototype.next = function () {
                this.$calendar.next();
            };
            Calendar.prototype.prev = function () {
                this.$calendar.prev();
            };
            Calendar.prototype.changeView = function (view) {
                this.$calendar.changeView(view);
            };
            Calendar.prototype.showFilters = function () {
                this.$mdSidenav('calendarFilters').toggle();
            };
            Calendar.prototype.$onInit = function () {
                this.eventSources = this.calendarData.eventSources;
                this.calendarOptions.viewRender = this.onViewRender;
                this.loadState();
                var cancelToken = this.$scope.$on(calendars.EVENT_ADDED, this.refresh);
                this.$scope.$on('$destroy', cancelToken);
                this.common.$timeout(this.setContentHeight, 100);
                angular.element(this.common.$window).bind('resize', this.setContentHeight);
            };
            Calendar.$inject = ['$scope', '$mdPanel', '$mdSidenav', 'common', 'calendarData', 'calendarState', '$mdDialog'];
            Calendar = __decorate([
                app.Component('app.calendars', 'ceCalendar', {
                    templateUrl: 'app/calendars/views/calendar/calendar.html'
                })
            ], Calendar);
            return Calendar;
        }());
    })(calendars = app.calendars || (app.calendars = {}));
})(app || (app = {}));
var app;
(function (app) {
    var clientCases;
    (function (clientCases) {
        var activities;
        (function (activities) {
            var ClientCaseActivityList = (function () {
                function ClientCaseActivityList($mdDialog, common, datacontext) {
                    var _this = this;
                    this.$mdDialog = $mdDialog;
                    this.common = common;
                    this.datacontext = datacontext;
                    this.showFilters = false;
                    this.selectedActivityTypes = [];
                    this.activityFilter = {};
                    this.paging = {
                        page: 1,
                        pageSize: 100
                    };
                    this.refresh = function () {
                        _this.getActivities();
                    };
                    'ngInject';
                    'ngInject';
                }
                ClientCaseActivityList.prototype.$onInit = function () {
                    this.showCovidVaccine = (this.showCovidVaccine || false).toString() === 'true';
                    this.activityFilter.involvementId = this.case.involvementId;
                    this.activityFilter.sourceId = this.case.id;
                    this.activityFilter.source = 'Client Case';
                    this.getActivities();
                };
                ClientCaseActivityList.prototype.getActivities = function () {
                    var _this = this;
                    if (!this.filtersInitialized()) {
                        return;
                    }
                    if (!this.showInvolvementActivities) {
                        this.activityFilter.sourceId = this.case.id;
                        this.activityFilter.source = 'Client Case';
                    }
                    else {
                        this.activityFilter.sourceId = null;
                        this.activityFilter.source = null;
                    }
                    this.activityFilter = __assign(__assign(__assign({}, this.activityFilter), this.paging), { activityTypes: this.selectedActivityTypes, q: this.noteFilter });
                    this.promise = this.datacontext.activities
                        .searchActivities(this.activityFilter)
                        .then(function (result) {
                        _this.activities = result;
                    });
                };
                ClientCaseActivityList.prototype.getInvolvementActivities = function () {
                    this.showInvolvementActivities = !this.showInvolvementActivities;
                    this.getActivities();
                };
                ClientCaseActivityList.prototype.newActivity = function (targetEvent) {
                    var parent = angular.element(document.body);
                    this.$mdDialog.show({
                        parent: parent,
                        targetEvent: targetEvent,
                        clickOutsideToClose: true,
                        templateUrl: 'app/client-cases/activities/new-activity/new-activity.html',
                        controller: 'NewClientCaseActivityDialogController',
                        controllerAs: '$ctrl',
                        locals: {
                            caseview: this.case,
                            vaccineFeatureFlag: this.showCovidVaccine
                        }
                    });
                };
                ClientCaseActivityList.prototype.toggleFilters = function () {
                    this.showFilters = !this.showFilters;
                };
                ClientCaseActivityList.prototype.filtersInitialized = function () {
                    return this.activityTypeFilterInitialized;
                };
                ClientCaseActivityList.prototype.activityTypeFilterChanged = function (activityTypes) {
                    this.selectedActivityTypes = activityTypes;
                    this.activityTypeFilterInitialized = true;
                    this.getActivities();
                };
                ClientCaseActivityList.prototype.onEdit = function (activity) {
                    switch (activity.activityType) {
                        case 'CaseAssessmentMeeting':
                            this.common.$state.go('^.caseAssessmentMeeting', { activity: activity });
                            break;
                        case 'CaseReview':
                            this.common.$state.go('^.caseReview', { activity: activity });
                            break;
                        case 'Note':
                            this.common.$state.go('^.note', { activity: activity });
                            break;
                        case 'Representation':
                            this.common.$state.go('^.representation', { activityId: activity.id });
                            break;
                        case 'CovidVaccine':
                            this.common.$state.go('^.covidVaccine', { activityId: activity.id });
                            break;
                        case 'StatusChange':
                            this.common.$state.go('^.statusChange', { activityId: activity.id });
                            break;
                    }
                };
                ClientCaseActivityList.$inject = ['$mdDialog', 'common', 'datacontext'];
                ClientCaseActivityList = __decorate([
                    app.Component('app.clientCases.activities', 'ceClientCaseActivityList', {
                        templateUrl: 'app/client-cases/activities/activity-list/activity-list.html',
                        bindings: {
                            case: '<',
                            showCovidVaccine: '<'
                        }
                    })
                ], ClientCaseActivityList);
                return ClientCaseActivityList;
            }());
        })(activities = clientCases.activities || (clientCases.activities = {}));
    })(clientCases = app.clientCases || (app.clientCases = {}));
})(app || (app = {}));
var app;
(function (app) {
    var clientCases;
    (function (clientCases) {
        var CaseAssessmentMeeting = (function () {
            function CaseAssessmentMeeting(common, datacontext) {
                this.common = common;
                this.datacontext = datacontext;
                this.isSubmitting = false;
                this.meeting = {};
                'ngInject';
                'ngInject';
            }
            CaseAssessmentMeeting.prototype.$onInit = function () {
                this.maxDate = moment().toDate();
                if (this.activity) {
                    this.getCAM();
                }
                else {
                    this.meeting.involvementId = this.involvementId;
                    this.meeting.source = this.source;
                    this.meeting.sourceId = this.sourceId;
                    this.meeting.meetingDate = moment().toDate();
                }
                this.getStatus();
                this.returnSref = this.returnSref || '^.list';
            };
            CaseAssessmentMeeting.prototype.getCAM = function () {
                var _this = this;
                this.datacontext.involvements
                    .getCAM(this.involvementId, this.activity.id)
                    .then(function (result) { return _this.meeting = result; });
            };
            CaseAssessmentMeeting.prototype.getStatus = function () {
                var _this = this;
                return this.datacontext.clientCases.getClientCaseStatus(this.sourceId).then(function (result) {
                    var data = result;
                    if (data.status) {
                        _this.selectedStatus = data.status;
                        _this.meeting.statusDescription = data.statusDescription;
                    }
                });
            };
            CaseAssessmentMeeting.prototype.submit = function () {
                var _this = this;
                this.isSubmitting = true;
                if (typeof this.meeting.meetingDate !== 'string') {
                    this.meeting.meetingDate = this.meeting.meetingDate.toDateString();
                }
                if (this.meeting.communicationDate && typeof this.meeting.communicationDate !== 'string') {
                    this.meeting.communicationDate = this.meeting.communicationDate.toDateString();
                }
                this.meeting.status = this.selectedStatus;
                if (this.selectedStatus && this.selectedStatus.indexOf('Other') === -1) {
                    this.meeting.statusDescription = null;
                }
                var promise = this.meeting.id
                    ? this.datacontext.involvements.updateCaseAssessmentMeeting(this.meeting)
                    : this.datacontext.involvements.createCaseAssessmentMeeting(this.meeting);
                promise
                    .then(function (request) {
                    if (request) {
                        _this.common.$mdToast.showSimple('Case Assessment Meeting ' + (_this.meeting.id ? 'updated' : 'created'));
                        _this.common.$timeout(function () { _this.common.$rootScope.$broadcast('refreshBasicInfo'); }, 2000);
                        _this.common.$state.go(_this.returnSref, { involvementId: _this.involvementId, sourceId: _this.sourceId });
                    }
                })
                    .finally(function () { return _this.isSubmitting = false; });
            };
            CaseAssessmentMeeting.$inject = ['common', 'datacontext'];
            CaseAssessmentMeeting = __decorate([
                app.Component('app.clientCases', 'ceClientCaseAssessmentMeeting', {
                    templateUrl: 'app/client-Cases/activities/case-assessment-meeting/case-assessment-meeting.html',
                    bindings: {
                        involvementId: '<',
                        source: '@',
                        sourceId: '<',
                        returnSref: '@',
                        showCommunicationDate: '@',
                        activity: '<',
                        caseOpenDate: '<'
                    }
                })
            ], CaseAssessmentMeeting);
            return CaseAssessmentMeeting;
        }());
    })(clientCases = app.clientCases || (app.clientCases = {}));
})(app || (app = {}));
var app;
(function (app) {
    var clientCases;
    (function (clientCases) {
        var activities;
        (function (activities) {
            var Close = (function () {
                function Close(common, datacontext) {
                    this.common = common;
                    this.datacontext = datacontext;
                    this.statuses = [];
                    this.isSubmitting = false;
                    this.activity = {};
                    'ngInject';
                    'ngInject';
                }
                Close.prototype.$onInit = function () {
                    var _this = this;
                    this.activity.involvementId = this.case.involvementId;
                    this.activity.caseId = this.case.id;
                    this.activity.activityDate = moment().toDate();
                    this.datacontext.clientCases.getClientCaseToDo(this.case.id, 'closing')
                        .then(function (items) {
                        _this.items = items;
                    });
                    this.maxDate = this.activity.activityDate;
                    this.getClientCaseStatus();
                };
                Close.prototype.getClientCaseStatus = function () {
                    var _this = this;
                    return this.datacontext.clientCases.getClientCaseStatus(this.case.id).then(function (result) {
                        var data = result;
                        if (data.status) {
                            _this.selectedStatus = data.status;
                            _this.activity.statusDescription = data.statusDescription;
                        }
                    });
                };
                Close.prototype.submit = function () {
                    var _this = this;
                    this.isSubmitting = true;
                    if (typeof this.activity.activityDate !== 'string') {
                        this.activity.activityDate = this.activity.activityDate.toDateString();
                    }
                    this.activity.status = this.selectedStatus;
                    if (this.selectedStatus.indexOf('Other') === -1) {
                        this.activity.statusDescription = null;
                    }
                    this.datacontext.clientCases
                        .close(this.activity)
                        .then(function (request) {
                        if (request) {
                            _this.common.$mdToast.showSimple('Case Closed');
                            _this.common.$rootScope.$broadcast('refreshBasicInfo');
                            _this.case.isActive = false;
                            _this.common.$state.go('^.list', { caseId: _this.case.id, involvementId: _this.case.involvementId });
                        }
                    })
                        .finally(function () { return _this.isSubmitting = false; });
                };
                Close.$inject = ['common', 'datacontext'];
                Close = __decorate([
                    app.Component('app.clientCases.activities', 'ceClientCasesActivitiesClose', {
                        templateUrl: 'app/client-cases/activities/close/close.html',
                        bindings: {
                            case: '<'
                        }
                    })
                ], Close);
                return Close;
            }());
        })(activities = clientCases.activities || (clientCases.activities = {}));
    })(clientCases = app.clientCases || (app.clientCases = {}));
})(app || (app = {}));
var app;
(function (app) {
    var clientCases;
    (function (clientCases) {
        var activities;
        (function (activities_2) {
            var activities = [
                {
                    icon: 'history',
                    text: 'Conflict Check',
                    sref: '^.conflictCheck',
                    filter: function (i) { return i.isActive; }
                },
                {
                    icon: 'info',
                    text: 'Convert to I&R',
                    sref: '^.createInformationAndReferral',
                    name: 'Create I&R',
                    filter: function (i) { return i.isActive; }
                },
                {
                    icon: 'playlist_add_check',
                    text: 'Case Review',
                    sref: '^.caseReview',
                    filter: function (i) { return i.isActive; }
                },
                {
                    icon: 'folder',
                    text: 'Convert to Systemic Case',
                    sref: '^.createSystemicCase',
                    name: 'Create Systemic Case',
                    filter: function (i) { return i.isActive; }
                },
                {
                    icon: 'comment',
                    text: 'Note',
                    sref: '^.note',
                    filter: function (i) { return true; }
                },
                {
                    icon: 'folder_open',
                    text: 'Reopen Case',
                    sref: '^.reopen',
                    filter: function (i) { return !i.isActive; }
                },
                {
                    icon: 'highlight_off',
                    text: 'Close Case',
                    sref: '^.close',
                    filter: function (i) { return i.isActive; }
                },
                {
                    icon: 'group_work',
                    text: 'Case Assessment Meeting',
                    sref: '^.caseAssessmentMeeting',
                    filter: function (i) { return i.isActive; }
                },
                {
                    icon: 'supervised_user_circle',
                    text: 'Representation',
                    sref: '^.representation',
                    filter: function (i) { return i.isActive; }
                },
                {
                    icon: 'vaccines',
                    text: 'Covid Vaccine',
                    sref: '^.covidVaccine',
                    filter: function (i, v) { return i.isActive && v; }
                },
                {
                    icon: 'approval',
                    text: 'Status Change',
                    sref: '^.statusChange',
                    filter: function (i) { return i.isActive; }
                },
            ];
            var NewClientCaseActivityDialogController = (function () {
                function NewClientCaseActivityDialogController($mdDialog, common, caseview, vaccineFeatureFlag) {
                    var _this = this;
                    this.$mdDialog = $mdDialog;
                    this.common = common;
                    this.caseview = caseview;
                    this.vaccineFeatureFlag = vaccineFeatureFlag;
                    this.filterActivity = function (activity) {
                        return activity.filter(_this.caseview, _this.vaccineFeatureFlag);
                    };
                    'ngInject';
                    'ngInject';
                }
                NewClientCaseActivityDialogController.prototype.$onInit = function () {
                    this.filteredActivities = activities.filter(this.filterActivity);
                };
                NewClientCaseActivityDialogController.prototype.selectActivity = function (item) {
                    this.common.$state.go(item.sref, { involvementId: this.caseview.involvementId, sourceId: this.caseview.id });
                    this.$mdDialog.hide();
                };
                NewClientCaseActivityDialogController.$inject = ['$mdDialog', 'common', 'caseview', 'vaccineFeatureFlag'];
                NewClientCaseActivityDialogController = __decorate([
                    app.Controller('app.clientCases.activities', 'NewClientCaseActivityDialogController')
                ], NewClientCaseActivityDialogController);
                return NewClientCaseActivityDialogController;
            }());
        })(activities = clientCases.activities || (clientCases.activities = {}));
    })(clientCases = app.clientCases || (app.clientCases = {}));
})(app || (app = {}));
var app;
(function (app) {
    var clientCases;
    (function (clientCases) {
        var activities;
        (function (activities) {
            var Note = (function () {
                function Note(common, datacontext, interrupt) {
                    this.common = common;
                    this.datacontext = datacontext;
                    this.interrupt = interrupt;
                    this.isSubmitting = false;
                    this.note = {};
                    'ngInject';
                    'ngInject';
                }
                Note.prototype.$onInit = function () {
                    this.maxDate = moment().toDate();
                    if (this.activity) {
                        this.note.id = this.activity.id;
                        this.note.caseId = this.activity.sourceId;
                        this.note.involvementId = this.activity.involvementId;
                        this.note.title = this.activity.title;
                        this.note.description = this.activity.description;
                        this.note.activityDate = this.activity.activityDate;
                    }
                    else {
                        this.note.involvementId = this.involvementId;
                        this.note.caseId = this.caseId;
                        this.note.activityDate = moment().toDate();
                        this.getStatus();
                    }
                };
                Note.prototype.getStatus = function () {
                    var _this = this;
                    return this.datacontext.clientCases.getClientCaseStatus(this.caseId).then(function (result) {
                        var data = result;
                        if (data.status) {
                            _this.selectedStatus = data.status;
                            _this.note.statusDescription = data.statusDescription;
                        }
                    });
                };
                Note.prototype.submit = function () {
                    var _this = this;
                    this.isSubmitting = true;
                    if (typeof this.note.activityDate !== 'string') {
                        this.note.activityDate = this.note.activityDate.toDateString();
                    }
                    this.note.status = this.selectedStatus;
                    if (this.selectedStatus !== 'Other') {
                        this.note.statusDescription = null;
                    }
                    var promise = this.note.id
                        ? this.datacontext.clientCases.updateNote(this.note)
                        : this.datacontext.clientCases.createNote(this.note);
                    promise
                        .then(function (note) {
                        if (note) {
                            _this.common.$mdToast.showSimple('Note ' + (_this.note.id ? 'updated' : 'created'));
                            _this.common.$rootScope.$broadcast('refreshBasicInfo');
                            _this.common.$state.go('^.list', { caseId: _this.caseId });
                        }
                    })
                        .finally(function () { return _this.isSubmitting = false; });
                };
                Note.$inject = ['common', 'datacontext', 'interrupt'];
                Note = __decorate([
                    app.Component('app.clientCases.activities', 'ceClientCasesActivitiesNote', {
                        templateUrl: 'app/client-cases/activities/note/note.html',
                        bindings: {
                            involvementId: '<',
                            caseId: '<',
                            activity: '<',
                            caseOpenDate: '<'
                        }
                    })
                ], Note);
                return Note;
            }());
        })(activities = clientCases.activities || (clientCases.activities = {}));
    })(clientCases = app.clientCases || (app.clientCases = {}));
})(app || (app = {}));
var app;
(function (app) {
    var clientCases;
    (function (clientCases) {
        var activities;
        (function (activities) {
            var Reopen = (function () {
                function Reopen(common, datacontext) {
                    this.common = common;
                    this.datacontext = datacontext;
                    this.isSubmitting = false;
                    this.activity = {};
                    'ngInject';
                    'ngInject';
                }
                Reopen.prototype.$onInit = function () {
                    this.activity.caseId = this.case.id;
                    this.activity.involvementId = this.case.involvementId;
                    this.activity.activityDate = moment().toDate();
                };
                Reopen.prototype.submit = function () {
                    var _this = this;
                    this.isSubmitting = true;
                    if (typeof this.activity.activityDate !== 'string') {
                        this.activity.activityDate = this.activity.activityDate.toDateString();
                    }
                    this.datacontext.clientCases
                        .reopen(this.activity)
                        .then(function (cc) {
                        if (cc) {
                            _this.common.$mdToast.showSimple('Case Reopened');
                            _this.common.$rootScope.$broadcast('refreshBasicInfo');
                            _this.case.isActive = true;
                            _this.common.$state.go('^.list', { caseId: _this.case.id, involvementId: _this.case.involvementId });
                        }
                    })
                        .finally(function () { return _this.isSubmitting = false; });
                };
                Reopen.$inject = ['common', 'datacontext'];
                Reopen = __decorate([
                    app.Component('app.clientCases.activities', 'ceClientCasesActivitiesReopen', {
                        templateUrl: 'app/client-cases/activities/reopen/reopen.html',
                        bindings: {
                            case: '<'
                        }
                    })
                ], Reopen);
                return Reopen;
            }());
        })(activities = clientCases.activities || (clientCases.activities = {}));
    })(clientCases = app.clientCases || (app.clientCases = {}));
})(app || (app = {}));
var app;
(function (app) {
    var clientCases;
    (function (clientCases) {
        var closure;
        (function (closure) {
            var ClientCaseClosureInformationForm = (function () {
                function ClientCaseClosureInformationForm(common, datacontext) {
                    this.common = common;
                    this.datacontext = datacontext;
                    this.isLoading = false;
                    this.isSubmitting = false;
                    'ngInject';
                    'ngInject';
                }
                ClientCaseClosureInformationForm.prototype.$onInit = function () {
                    this.getData();
                };
                ClientCaseClosureInformationForm.prototype.getData = function () {
                    var _this = this;
                    this.isLoading = true;
                    this.datacontext.clientCases
                        .getClosureInformation(this.caseId)
                        .then(function (result) { return _this.data = result; })
                        .finally(function () { return _this.isLoading = false; });
                };
                ClientCaseClosureInformationForm.prototype.submit = function () {
                    var _this = this;
                    this.isSubmitting = true;
                    if (!this.data.monetarySettlement) {
                        this.data.monetarySettlementAmount = null;
                    }
                    this.datacontext.clientCases
                        .updateClosureInformation(this.data)
                        .then(function (result) {
                        if (result) {
                            _this.common.$mdToast.showSimple('Remember to Complete Survey');
                            _this.onSubmit({ result: result });
                        }
                    }).finally(function () { return _this.isSubmitting = false; });
                };
                ClientCaseClosureInformationForm.$inject = ['common', 'datacontext'];
                ClientCaseClosureInformationForm = __decorate([
                    app.Component('app.clientCases.closure', 'ceClientCaseClosureInformationForm', {
                        templateUrl: 'app/client-cases/closure/closure-information/closure-information-form.html',
                        bindings: {
                            caseId: '<',
                            onCancel: '&',
                            onSubmit: '&'
                        }
                    })
                ], ClientCaseClosureInformationForm);
                return ClientCaseClosureInformationForm;
            }());
        })(closure = clientCases.closure || (clientCases.closure = {}));
    })(clientCases = app.clientCases || (app.clientCases = {}));
})(app || (app = {}));
var app;
(function (app) {
    var clientCases;
    (function (clientCases) {
        var closure;
        (function (closure) {
            var ClientCaseClosureInformation = (function () {
                function ClientCaseClosureInformation() {
                }
                ClientCaseClosureInformation = __decorate([
                    app.Component('app.clientCases.closure', 'ceClientCaseClosureInformation', {
                        templateUrl: 'app/client-cases/closure/closure-information/closure-information.html',
                        bindings: {
                            data: '<',
                            onEdit: '&'
                        }
                    })
                ], ClientCaseClosureInformation);
                return ClientCaseClosureInformation;
            }());
        })(closure = clientCases.closure || (clientCases.closure = {}));
    })(clientCases = app.clientCases || (app.clientCases = {}));
})(app || (app = {}));
var app;
(function (app) {
    var clientCases;
    (function (clientCases) {
        var closure;
        (function (closure) {
            var ClosureSpecialEducationForm = (function () {
                function ClosureSpecialEducationForm(datacontext, common) {
                    this.datacontext = datacontext;
                    this.common = common;
                    this.specialEducation = {};
                    this.isLoading = false;
                    this.isSubmitting = false;
                    'ngInject';
                    'ngInject';
                }
                ClosureSpecialEducationForm.prototype.$onInit = function () {
                    this.getSpecialEducation();
                    this.getClosureSpecialEducationOptions();
                };
                ClosureSpecialEducationForm.prototype.getSpecialEducation = function () {
                    var _this = this;
                    this.isLoading = true;
                    this.datacontext.clientCases
                        .getSpecialEducation(this.caseId)
                        .then(function (result) { return _this.specialEducation = result; })
                        .finally(function () { return _this.isLoading = false; });
                };
                ClosureSpecialEducationForm.prototype.getClosureSpecialEducationOptions = function () {
                    var _this = this;
                    this.datacontext.clientCases.getClosureSpecialEducationOptions()
                        .then(function (results) { return _this.closureSpecialEducationOptions = results; });
                };
                ClosureSpecialEducationForm.prototype.isEmpoweredChanged = function (empowermentId) {
                    if (!this.specialEducation) {
                        this.specialEducation = { empowermentId: 0, outcomes: [] };
                    }
                    var t = _.find(this.closureSpecialEducationOptions, function (o) {
                        return o.text === 'Parents WERE empowered as a result of P&A intervention';
                    });
                    if (empowermentId !== t.id) {
                        this.specialEducation.outcomes = [];
                        this.specialEducation.isEmpowered = false;
                    }
                    else {
                        this.specialEducation.isEmpowered = true,
                            this.specialEducation.empowermentId = empowermentId;
                    }
                };
                ClosureSpecialEducationForm.prototype.isEmpowered = function () {
                    var isEmpowered;
                    var t = _.find(this.closureSpecialEducationOptions, function (o) {
                        return o.text === 'Parents WERE empowered as a result of P&A intervention';
                    });
                    var m;
                    if (this.specialEducation) {
                        m = this.specialEducation;
                        if (m.empowermentId === t.id) {
                            isEmpowered = true;
                        }
                    }
                    else {
                        isEmpowered = false;
                    }
                    return isEmpowered;
                };
                ClosureSpecialEducationForm.prototype.outcomeSelected = function (outcomeText) {
                    var m = this.specialEducation;
                    return _.findIndex(m.outcomes, function (o) { return o === outcomeText; }) > -1;
                };
                ClosureSpecialEducationForm.prototype.outcomeChanged = function (outcome) {
                    var o = _.findIndex(this.specialEducation.outcomes, function (o) { return o === outcome.text; }) > -1;
                    if (!o) {
                        this.specialEducation.outcomes.push(outcome.text);
                    }
                    else {
                        _.pull(this.specialEducation.outcomes, outcome.text);
                    }
                };
                ClosureSpecialEducationForm.prototype.submit = function () {
                    var _this = this;
                    if (!this.specialEducation) {
                        this.specialEducation = { empowermentId: 0, outcomes: [] };
                    }
                    if (this.specialEducation.outcomes.length === 0 && this.isEmpowered() || this.specialEducation.empowermentId === 0) {
                        return;
                    }
                    this.isSubmitting = true;
                    this.specialEducation.id = this.caseId;
                    var promise = this.common.$q.when('');
                    promise.then(function () { return _this.datacontext.clientCases
                        .updateSpecialEducation(_this.specialEducation)
                        .then(function (result) {
                        if (result)
                            _this.onSubmit({ result: result });
                    }); }).finally(function () { return _this.isSubmitting = false; });
                };
                ClosureSpecialEducationForm.$inject = ['datacontext', 'common'];
                ClosureSpecialEducationForm = __decorate([
                    app.Component('app.clientCases.closure', 'ceClosureSpecialEducationForm', {
                        templateUrl: 'app/client-cases/closure/closure-special-education/closure-special-education-form.html',
                        bindings: {
                            caseId: '<',
                            involvementId: '<',
                            onCancel: '&',
                            onSubmit: '&'
                        }
                    })
                ], ClosureSpecialEducationForm);
                return ClosureSpecialEducationForm;
            }());
        })(closure = clientCases.closure || (clientCases.closure = {}));
    })(clientCases = app.clientCases || (app.clientCases = {}));
})(app || (app = {}));
var app;
(function (app) {
    var clientCases;
    (function (clientCases) {
        var closure;
        (function (closure) {
            var ClosureSpecialEducation = (function () {
                function ClosureSpecialEducation() {
                }
                ClosureSpecialEducation = __decorate([
                    app.Component('app.clientCases.closure', 'ceClosureSpecialEducation', {
                        templateUrl: 'app/client-cases/closure/closure-special-education/closure-special-education.html',
                        bindings: {
                            data: '<',
                            onEdit: '&'
                        }
                    })
                ], ClosureSpecialEducation);
                return ClosureSpecialEducation;
            }());
        })(closure = clientCases.closure || (clientCases.closure = {}));
    })(clientCases = app.clientCases || (app.clientCases = {}));
})(app || (app = {}));
var app;
(function (app) {
    var clientCases;
    (function (clientCases) {
        var closure;
        (function (closure) {
            var SurveyInformationForm = (function () {
                function SurveyInformationForm(datacontext, common) {
                    var _this = this;
                    this.datacontext = datacontext;
                    this.common = common;
                    this.data = {};
                    this.isLoading = false;
                    this.isSubmitting = false;
                    this.getPhoneNumbers = function () {
                        if (_this.data.entityId) {
                            _this.datacontext.entities
                                .getEntityPhoneNumbers(_this.data.entityId)
                                .then(function (result) { return _this.phoneNumbers = result; });
                        }
                    };
                    'ngInject';
                    'ngInject';
                }
                SurveyInformationForm.prototype.$onInit = function () {
                    var _this = this;
                    this.getSurvey()
                        .then(function () { return _this.getEntities(); })
                        .then(function () { return _this.getPhoneNumbers(); });
                };
                SurveyInformationForm.prototype.getEntities = function () {
                    var _this = this;
                    return this.datacontext.involvements.getEntitiesBasic(this.involvementId)
                        .then(function (entities) {
                        _this.entities = entities;
                    });
                };
                SurveyInformationForm.prototype.createPhoneNumber = function (phone) {
                    var phoneNumber = {
                        entityId: this.data.entityId,
                        phoneNumber: {
                            phone: this.phoneNumber.phone,
                            extension: this.phoneNumber.extension,
                            allowsVoiceMail: true
                        },
                        isPrimary: true
                    };
                    return this.datacontext.entities.createEntityPhoneNumber(phoneNumber);
                };
                SurveyInformationForm.prototype.loadPhoneNumbers = function () {
                    this.data.phoneNumberId = null;
                    this.getPhoneNumbers();
                };
                SurveyInformationForm.prototype.phoneChanged = function (phone) {
                    this.phoneNumber = phone;
                };
                SurveyInformationForm.prototype.getSurvey = function () {
                    var _this = this;
                    this.isLoading = true;
                    return this.datacontext.clientCases
                        .getSurveyInformation(this.caseId)
                        .then(function (result) { return _this.data = result; })
                        .finally(function () { return _this.isLoading = false; });
                };
                SurveyInformationForm.prototype.submit = function () {
                    var _this = this;
                    this.isSubmitting = true;
                    this.data.id = this.caseId;
                    if (this.data.shouldConduct === false) {
                        this.data.entityId = null;
                        this.data.phoneNumberId = null;
                        this.data.accommodations = null;
                    }
                    else {
                        this.data.comment = null;
                    }
                    var promise = this.common.$q.when('');
                    if (this.data.phoneNumberId === 'create') {
                        promise = this.createPhoneNumber(this.phoneNumber).then(function (phone) { return _this.data.phoneNumberId = phone.id; });
                    }
                    promise.then(function () { return _this.datacontext.clientCases
                        .updateSurveyInformation(_this.data)
                        .then(function (result) {
                        if (result)
                            _this.onSubmit({ result: result });
                    }); }).finally(function () { return _this.isSubmitting = false; });
                };
                SurveyInformationForm.$inject = ['datacontext', 'common'];
                SurveyInformationForm = __decorate([
                    app.Component('app.clientCases.closure', 'ceSurveyInformationForm', {
                        templateUrl: 'app/client-cases/closure/survey-information/survey-information-form.html',
                        bindings: {
                            caseId: '<',
                            involvementId: '<',
                            onCancel: '&',
                            onSubmit: '&'
                        }
                    })
                ], SurveyInformationForm);
                return SurveyInformationForm;
            }());
        })(closure = clientCases.closure || (clientCases.closure = {}));
    })(clientCases = app.clientCases || (app.clientCases = {}));
})(app || (app = {}));
var app;
(function (app) {
    var clientCases;
    (function (clientCases) {
        var closure;
        (function (closure) {
            var SurveyInformation = (function () {
                function SurveyInformation() {
                }
                SurveyInformation = __decorate([
                    app.Component('app.clientCases.closure', 'ceSurveyInformation', {
                        templateUrl: 'app/client-cases/closure/survey-information/survey-information.html',
                        bindings: {
                            data: '<',
                            onEdit: '&'
                        }
                    })
                ], SurveyInformation);
                return SurveyInformation;
            }());
        })(closure = clientCases.closure || (clientCases.closure = {}));
    })(clientCases = app.clientCases || (app.clientCases = {}));
})(app || (app = {}));
var app;
(function (app) {
    var clientCases;
    (function (clientCases) {
        var details;
        (function (details) {
            var ClientCaseInformationForm = (function () {
                function ClientCaseInformationForm(datacontext) {
                    this.datacontext = datacontext;
                    this.isLoading = false;
                    this.isSubmitting = false;
                    'ngInject';
                    'ngInject';
                }
                ClientCaseInformationForm.prototype.$onInit = function () {
                    var _this = this;
                    this.getLegalTeams();
                    this.getLawCategoriesAndSubcategories().then(function () { return _this.getData(); });
                };
                ClientCaseInformationForm.prototype.getLegalTeams = function () {
                    var _this = this;
                    this.datacontext.config.legalTeams.getLegalTeams(true).then(function (result) { return _this.legalTeams = result; });
                };
                ClientCaseInformationForm.prototype.lawCategoryChanged = function () {
                    this.filterLawSubcategories();
                };
                ClientCaseInformationForm.prototype.getLawCategoriesAndSubcategories = function () {
                    var _this = this;
                    return this.datacontext.config.getLawCategories()
                        .then(function (lawCategories) {
                        _this.lawCategories = lawCategories;
                        return _this.datacontext.config.getLawSubcategories().then(function (lawSubcategories) {
                            _this.lawSubcategories = lawSubcategories.data;
                        });
                    });
                };
                ClientCaseInformationForm.prototype.getData = function () {
                    var _this = this;
                    this.isLoading = true;
                    this.datacontext.clientCases
                        .getClientCaseInformation(this.caseId)
                        .then(function (result) {
                        _this.data = result;
                        _this.filterLawSubcategories();
                    })
                        .finally(function () { return _this.isLoading = false; });
                };
                ClientCaseInformationForm.prototype.filterLawSubcategories = function () {
                    var _this = this;
                    this.validLawSubcategories = _.filter(this.lawSubcategories, function (ct) {
                        return ct.lawCategoryId === _this.data.lawCategoryId;
                    });
                };
                ClientCaseInformationForm.prototype.submit = function () {
                    var _this = this;
                    this.isSubmitting = true;
                    this.datacontext.clientCases
                        .updateClientCaseInformation(this.data)
                        .then(function (result) {
                        if (result)
                            _this.onSubmit({ result: result });
                    }).finally(function () { return _this.isSubmitting = false; });
                };
                ClientCaseInformationForm.$inject = ['datacontext'];
                ClientCaseInformationForm = __decorate([
                    app.Component('app.clientCases.details', 'ceClientCaseInformationForm', {
                        templateUrl: 'app/client-cases/details/case-information/case-information-form.html',
                        bindings: {
                            caseId: '<',
                            paramCaseName: '<',
                            onCancel: '&',
                            onSubmit: '&'
                        }
                    })
                ], ClientCaseInformationForm);
                return ClientCaseInformationForm;
            }());
        })(details = clientCases.details || (clientCases.details = {}));
    })(clientCases = app.clientCases || (app.clientCases = {}));
})(app || (app = {}));
var app;
(function (app) {
    var clientCases;
    (function (clientCases) {
        var details;
        (function (details) {
            var ClientCaseInformation = (function () {
                function ClientCaseInformation() {
                }
                ClientCaseInformation = __decorate([
                    app.Component('app.clientCases.details', 'ceClientCaseInformation', {
                        templateUrl: 'app/client-cases/details/case-information/case-information.html',
                        bindings: {
                            data: '<',
                            paramCaseName: '<',
                            onEdit: '&'
                        }
                    })
                ], ClientCaseInformation);
                return ClientCaseInformation;
            }());
        })(details = clientCases.details || (clientCases.details = {}));
    })(clientCases = app.clientCases || (app.clientCases = {}));
})(app || (app = {}));
var app;
(function (app) {
    var clientCases;
    (function (clientCases) {
        var details;
        (function (details) {
            var SpecialEducationForm = (function () {
                function SpecialEducationForm(datacontext) {
                    this.datacontext = datacontext;
                    this.isLoading = false;
                    this.isSubmitting = false;
                    'ngInject';
                    'ngInject';
                }
                SpecialEducationForm.prototype.$onInit = function () {
                    this.getData();
                };
                SpecialEducationForm.prototype.getData = function () {
                    var _this = this;
                    this.isLoading = true;
                    this.datacontext.config
                        .getConfigurationParameter('ClientCase.SpecialEdSchoolDistrict', null)
                        .then(function (result) { return _this.schoolDistrict = result; });
                    this.datacontext.clientCases
                        .getClientCaseInformation(this.caseId)
                        .then(function (result) { return _this.data = result; })
                        .finally(function () { return _this.isLoading = false; });
                };
                SpecialEducationForm.prototype.submit = function () {
                    var _this = this;
                    this.isSubmitting = true;
                    this.datacontext.clientCases
                        .updateClientCaseInformation(this.data)
                        .then(function (result) {
                        if (result)
                            _this.onSubmit({ result: result });
                    }).finally(function () { return _this.isSubmitting = false; });
                };
                SpecialEducationForm.$inject = ['datacontext'];
                SpecialEducationForm = __decorate([
                    app.Component('app.clientCases.details', 'ceSpecialEducationForm', {
                        templateUrl: 'app/client-cases/details/special-education/special-education-form.html',
                        bindings: {
                            caseId: '<',
                            onCancel: '&',
                            onSubmit: '&'
                        }
                    })
                ], SpecialEducationForm);
                return SpecialEducationForm;
            }());
        })(details = clientCases.details || (clientCases.details = {}));
    })(clientCases = app.clientCases || (app.clientCases = {}));
})(app || (app = {}));
var app;
(function (app) {
    var clientCases;
    (function (clientCases) {
        var details;
        (function (details) {
            var SpecialEducation = (function () {
                function SpecialEducation() {
                }
                SpecialEducation = __decorate([
                    app.Component('app.clientCases.details', 'ceSpecialEducation', {
                        templateUrl: 'app/client-cases/details/special-education/special-education.html',
                        bindings: {
                            data: '<',
                            onEdit: '&'
                        }
                    })
                ], SpecialEducation);
                return SpecialEducation;
            }());
        })(details = clientCases.details || (clientCases.details = {}));
    })(clientCases = app.clientCases || (app.clientCases = {}));
})(app || (app = {}));
var app;
(function (app) {
    var config;
    (function (config) {
        var LookupAutocompleteComponent = (function () {
            function LookupAutocompleteComponent(datacontext) {
                var _this = this;
                this.datacontext = datacontext;
                this.getLookups = function () {
                    return _this.datacontext.config.lookups
                        .getLookupType(_this.lookupType)
                        .then(function (result) { return _this.lookups = _.map(result.lookups, function (r) { return r.value; }); });
                };
                'ngInject';
                'ngInject';
            }
            LookupAutocompleteComponent.prototype.$onInit = function () {
                this.getLookups();
            };
            LookupAutocompleteComponent.$inject = ['datacontext'];
            LookupAutocompleteComponent = __decorate([
                app.Component('app.config', 'pamLookupAutocomplete', {
                    templateUrl: 'app/config/components/lookup-autocomplete/lookup-autocomplete.html',
                    bindings: {
                        inputName: '@',
                        label: '@',
                        lookupType: '@',
                        placeholder: '@',
                        required: '<',
                        requireMatch: '<',
                        value: '=',
                    },
                    require: {
                        form: '^^form'
                    }
                })
            ], LookupAutocompleteComponent);
            return LookupAutocompleteComponent;
        }());
    })(config = app.config || (app.config = {}));
})(app || (app = {}));
var app;
(function (app) {
    var config;
    (function (config) {
        var SelectLookup = (function () {
            function SelectLookup(common) {
                this.common = common;
                this.intialized = false;
                'ngInject';
                'ngInject';
            }
            SelectLookup.prototype.valueChanged = function (param) {
            };
            SelectLookup.prototype.changeValue = function () {
                this.valueChanged({ value: this.value });
            };
            SelectLookup.prototype.$onChanges = function () {
                var _this = this;
                if (this.lookupType && !this.intialized) {
                    this.intialized = true;
                    this.common.configHelper
                        .getLookupValues(this.lookupType)
                        .then(function (lookups) {
                        _this.lookups = lookups;
                        if (_this.autoSelect && _this.lookups.length === 1) {
                            _this.value = _this.lookups[0];
                        }
                    });
                }
            };
            SelectLookup.$inject = ['common'];
            SelectLookup = __decorate([
                app.Component('app.config', 'pamLookupPicker', {
                    templateUrl: 'app/config/components/lookup-picker/lookup-picker.html',
                    bindings: {
                        autoSelect: '<',
                        includeNone: '<?',
                        includeBlank: '<?',
                        inputName: '@',
                        label: '@',
                        lookupType: '@',
                        placeholder: '@',
                        required: '<',
                        value: '=',
                        valueChanged: '&?',
                        autoFocus: '<'
                    },
                    require: {
                        form: '^^form'
                    }
                })
            ], SelectLookup);
            return SelectLookup;
        }());
    })(config = app.config || (app.config = {}));
})(app || (app = {}));
var app;
(function (app) {
    var config;
    (function (config) {
        var SelectLookup = (function () {
            function SelectLookup(common) {
                this.common = common;
                this.intialized = false;
                'ngInject';
                'ngInject';
            }
            SelectLookup.prototype.$onChanges = function () {
                var _this = this;
                if (this.lookupType && !this.intialized) {
                    this.intialized = true;
                    this.common.configHelper
                        .getLookupValues(this.lookupType)
                        .then(function (lookups) {
                        _this.lookups = lookups;
                    });
                }
            };
            SelectLookup.$inject = ['common'];
            SelectLookup = __decorate([
                app.Component('app.config', 'pamLookupsPicker', {
                    templateUrl: 'app/config/components/lookups-picker/lookups-picker.html',
                    bindings: {
                        inputName: '@',
                        label: '@',
                        lookupType: '@',
                        required: '@',
                        value: '='
                    },
                    require: {
                        form: '^^form'
                    }
                })
            ], SelectLookup);
            return SelectLookup;
        }());
    })(config = app.config || (app.config = {}));
})(app || (app = {}));
var app;
(function (app) {
    var documentManagement;
    (function (documentManagement) {
        var ChangeDocumentFolderDialogController = (function () {
            function ChangeDocumentFolderDialogController($mdDialog, datacontext, document) {
                var _this = this;
                this.$mdDialog = $mdDialog;
                this.datacontext = datacontext;
                this.document = document;
                this.data = {};
                this.isSubmitting = false;
                this.getFolders = function () {
                    return _this.datacontext.documentManagement
                        .getFolders({ involvementId: _this.document.involvementId, source: _this.document.source, sourceId: _this.document.sourceId, type: 'Folder', pageSize: 100 })
                        .then(function (result) { return _this.folders = result.list; });
                };
                'ngInject';
                'ngInject';
            }
            ChangeDocumentFolderDialogController.prototype.submit = function () {
                var _this = this;
                this.isSubmitting = true;
                this.datacontext.documentManagement
                    .changeDocumentFolder(this.data)
                    .then(function () { return _this.$mdDialog.hide(); })
                    .finally(function () { return _this.isSubmitting = false; });
            };
            ChangeDocumentFolderDialogController.prototype.$onInit = function () {
                this.getFolders();
                this.setData();
            };
            ChangeDocumentFolderDialogController.prototype.setData = function () {
                this.data.documentId = this.document.id;
            };
            ChangeDocumentFolderDialogController.prototype.cancel = function () {
                this.$mdDialog.cancel();
            };
            ChangeDocumentFolderDialogController.$inject = ['$mdDialog', 'datacontext', 'document'];
            ChangeDocumentFolderDialogController = __decorate([
                app.Controller('app.documentManagement', 'ChangeDocumentFolderDialogController')
            ], ChangeDocumentFolderDialogController);
            return ChangeDocumentFolderDialogController;
        }());
    })(documentManagement = app.documentManagement || (app.documentManagement = {}));
})(app || (app = {}));
var app;
(function (app) {
    var documentManagement;
    (function (documentManagement) {
        var CopyDocumentDialogController = (function () {
            function CopyDocumentDialogController($mdDialog, datacontext, document) {
                this.$mdDialog = $mdDialog;
                this.datacontext = datacontext;
                this.document = document;
                this.data = {};
                this.isSubmitting = false;
                'ngInject';
                'ngInject';
            }
            CopyDocumentDialogController.prototype.submit = function () {
                var _this = this;
                this.isSubmitting = true;
                this.datacontext.documentManagement
                    .copyDocument(this.data)
                    .then(function () { return _this.$mdDialog.hide(); })
                    .finally(function () { return _this.isSubmitting = false; });
            };
            CopyDocumentDialogController.prototype.$onInit = function () {
                this.data.documentId = this.document.id;
            };
            CopyDocumentDialogController.prototype.setSource = function (source) {
                this.data.source = source.source;
                this.data.sourceId = source.sourceId;
                this.data.involvementId = source.involvementId;
            };
            CopyDocumentDialogController.prototype.cancel = function () {
                this.$mdDialog.cancel();
            };
            CopyDocumentDialogController.$inject = ['$mdDialog', 'datacontext', 'document'];
            CopyDocumentDialogController = __decorate([
                app.Controller('app.documentManagement', 'CopyDocumentDialogController')
            ], CopyDocumentDialogController);
            return CopyDocumentDialogController;
        }());
    })(documentManagement = app.documentManagement || (app.documentManagement = {}));
})(app || (app = {}));
var app;
(function (app) {
    var documentManagement;
    (function (documentManagement) {
        var DocumentsList = (function () {
            function DocumentsList($mdDialog, common, datacontext, blobHelper) {
                var _this = this;
                this.$mdDialog = $mdDialog;
                this.common = common;
                this.datacontext = datacontext;
                this.blobHelper = blobHelper;
                this.paging = {
                    page: 1,
                    pageSize: 100
                };
                this.refresh = function () {
                    _this.getDocuments();
                };
                this.getDocuments = function () {
                    var params = __assign({ involvementId: _this.involvementId, source: _this.showInvolvementDocuments ? null : _this.source, sourceId: _this.showInvolvementDocuments ? null : _this.sourceId, folderId: _this.folderId, q: _this.nameFilter }, _this.paging);
                    _this.promise = _this.datacontext.documentManagement
                        .getFolders(params)
                        .then(function (result) { return _this.documents = result; });
                };
                'ngInject';
                'ngInject';
            }
            DocumentsList.prototype.openDocumentDialog = function (targetEvent) {
                var _this = this;
                var parent = angular.element(document.body);
                return this.$mdDialog.show({
                    parent: parent,
                    targetEvent: targetEvent,
                    fullscreen: true,
                    templateUrl: 'app/documentManagement/new-document/new-document-dialog.html',
                    controller: 'NewDocumentDialogController',
                    controllerAs: '$ctrl',
                    locals: {
                        involvementId: this.involvementId,
                        source: this.source,
                        sourceId: this.sourceId,
                        folderId: this.folderId
                    }
                })
                    .catch(function (error) { if (error)
                    throw error; })
                    .finally(function () { _this.getDocuments(); });
            };
            DocumentsList.prototype.download = function (documentId) {
                var _this = this;
                this.datacontext.documentManagement
                    .downloadDocument(documentId)
                    .then(function (result) { return _this.blobHelper.open(result); });
            };
            DocumentsList.prototype.$onInit = function () {
                this.getDocuments();
            };
            DocumentsList.prototype.deleteDocument = function (document) {
                var _this = this;
                this.common.confirmation.show({
                    title: 'Delete Document',
                    message: 'Are you sure you want to delete this document?',
                    ok: 'Delete',
                    cancel: 'Keep'
                }).then(function () {
                    _this.datacontext.documentManagement
                        .deleteDocument(document.id)
                        .then(function (result) {
                        if (result) {
                            _.remove(_this.documents.list, document);
                            _this.documents.count--;
                        }
                    });
                }).catch(angular.noop);
            };
            DocumentsList.prototype.getInvolvementDocuments = function () {
                this.showInvolvementDocuments = !this.showInvolvementDocuments;
                this.getDocuments();
            };
            DocumentsList.prototype.showFolder = function (document) {
                if (document) {
                    this.folderId = document.id;
                    this.folderName = document.name;
                }
                else {
                    this.folderId = null;
                    this.folderName = null;
                }
                this.getDocuments();
            };
            DocumentsList.prototype.createFolder = function (targetEvent) {
                this.openFolderDialog(targetEvent, null);
            };
            DocumentsList.prototype.renameFolder = function (targetEvent, folder) {
                this.openFolderDialog(targetEvent, folder);
            };
            DocumentsList.prototype.openFolderDialog = function (targetEvent, folder) {
                var _this = this;
                var parent = angular.element(document.body);
                return this.$mdDialog.show({
                    parent: parent,
                    targetEvent: targetEvent,
                    fullscreen: true,
                    templateUrl: 'app/documentManagement/views/folder-dialog/folder-dialog.html',
                    controller: 'DocumentFolderDialogController',
                    controllerAs: '$ctrl',
                    locals: {
                        folder: folder,
                        involvementId: this.involvementId,
                        source: this.source,
                        sourceId: this.sourceId
                    }
                })
                    .then(function () { _this.getDocuments(); })
                    .catch(function (error) { if (error)
                    throw error; });
            };
            DocumentsList.prototype.changeDocumentFolder = function (targetEvent, doc) {
                var _this = this;
                var parent = angular.element(document.body);
                return this.$mdDialog.show({
                    parent: parent,
                    targetEvent: targetEvent,
                    fullscreen: true,
                    templateUrl: 'app/documentManagement/views/change-document-folder-dialog/change-document-folder-dialog.html',
                    controller: 'ChangeDocumentFolderDialogController',
                    controllerAs: '$ctrl',
                    locals: {
                        document: doc
                    }
                })
                    .then(function () { _this.getDocuments(); })
                    .catch(function (error) { if (error)
                    throw error; });
            };
            DocumentsList.prototype.moveDocument = function (targetEvent, doc) {
                var _this = this;
                var parent = angular.element(document.body);
                return this.$mdDialog.show({
                    parent: parent,
                    targetEvent: targetEvent,
                    fullscreen: true,
                    templateUrl: 'app/documentManagement/views/move-document-dialog/move-document-dialog.html',
                    controller: 'MoveDocumentDialogController',
                    controllerAs: '$ctrl',
                    locals: {
                        document: doc
                    }
                })
                    .then(function () { _this.getDocuments(); })
                    .catch(function (error) { if (error)
                    throw error; });
            };
            DocumentsList.prototype.copyDocument = function (targetEvent, doc) {
                var _this = this;
                var parent = angular.element(document.body);
                return this.$mdDialog.show({
                    parent: parent,
                    targetEvent: targetEvent,
                    fullscreen: true,
                    templateUrl: 'app/documentManagement/views/copy-document-dialog/copy-document-dialog.html',
                    controller: 'CopyDocumentDialogController',
                    controllerAs: '$ctrl',
                    locals: {
                        document: doc
                    }
                })
                    .then(function () { _this.getDocuments(); })
                    .catch(function (error) { if (error)
                    throw error; });
            };
            DocumentsList.prototype.renameDocument = function (targetEvent, doc) {
                var _this = this;
                var parent = angular.element(document.body);
                return this.$mdDialog.show({
                    parent: parent,
                    targetEvent: targetEvent,
                    fullscreen: true,
                    templateUrl: 'app/documentManagement/views/rename-document-dialog/rename-document-dialog.html',
                    controller: 'RenameDocumentDialogController',
                    controllerAs: '$ctrl',
                    locals: {
                        document: doc
                    }
                })
                    .then(function () { _this.getDocuments(); })
                    .catch(function (error) { if (error)
                    throw error; });
            };
            DocumentsList.prototype.deleteFolder = function (folder) {
                var _this = this;
                this.common.confirmation.show({
                    title: 'Delete Folder',
                    message: 'Are you sure you want to delete this folder?',
                    ok: 'Delete',
                    cancel: 'Keep'
                }).then(function () {
                    _this.datacontext.documentManagement
                        .deleteFolder(folder.id)
                        .then(function (result) {
                        if (result) {
                            _this.refresh();
                        }
                    });
                }).catch(angular.noop);
            };
            DocumentsList.$inject = ['$mdDialog', 'common', 'datacontext', 'blobHelper'];
            DocumentsList = __decorate([
                app.Component('app.documentManagement', 'ceDocumentsList', {
                    templateUrl: 'app/documentManagement/views/documents-list/documents-list.html',
                    bindings: {
                        involvementId: '<',
                        source: '@',
                        sourceId: '<'
                    }
                })
            ], DocumentsList);
            return DocumentsList;
        }());
    })(documentManagement = app.documentManagement || (app.documentManagement = {}));
})(app || (app = {}));
var app;
(function (app) {
    var documentManagement;
    (function (documentManagement) {
        var FolderDialogController = (function () {
            function FolderDialogController(common, $mdDialog, datacontext, source, sourceId, involvementId, folder) {
                this.common = common;
                this.$mdDialog = $mdDialog;
                this.datacontext = datacontext;
                this.source = source;
                this.sourceId = sourceId;
                this.involvementId = involvementId;
                this.folder = folder;
                this.data = {};
                this.isSubmitting = false;
                'ngInject';
                'ngInject';
            }
            FolderDialogController.prototype.submit = function () {
                var _this = this;
                this.isSubmitting = true;
                var promise = this.data.id
                    ? this.datacontext.documentManagement.updateFolder(this.data)
                    : this.datacontext.documentManagement.createFolder(this.data);
                promise
                    .then(function () { return _this.$mdDialog.hide(); })
                    .finally(function () { return _this.isSubmitting = false; });
            };
            FolderDialogController.prototype.$onInit = function () {
                this.setData();
            };
            FolderDialogController.prototype.setData = function () {
                this.data.involvementId = this.involvementId;
                this.data.source = this.source;
                this.data.sourceId = this.sourceId;
                if (this.folder) {
                    this.data.id = this.folder.id;
                    this.data.folderName = this.folder.name;
                }
            };
            FolderDialogController.prototype.cancel = function () {
                this.$mdDialog.cancel();
            };
            FolderDialogController.$inject = ['common', '$mdDialog', 'datacontext', 'source', 'sourceId', 'involvementId', 'folder'];
            FolderDialogController = __decorate([
                app.Controller('app.documentManagement', 'DocumentFolderDialogController')
            ], FolderDialogController);
            return FolderDialogController;
        }());
    })(documentManagement = app.documentManagement || (app.documentManagement = {}));
})(app || (app = {}));
var app;
(function (app) {
    var documentManagement;
    (function (documentManagement) {
        var MoveDocumentDialogController = (function () {
            function MoveDocumentDialogController($mdDialog, datacontext, document) {
                this.$mdDialog = $mdDialog;
                this.datacontext = datacontext;
                this.document = document;
                this.data = {};
                this.isSubmitting = false;
                'ngInject';
                'ngInject';
            }
            MoveDocumentDialogController.prototype.submit = function () {
                var _this = this;
                this.isSubmitting = true;
                this.datacontext.documentManagement
                    .moveDocument(this.data)
                    .then(function () { return _this.$mdDialog.hide(); })
                    .finally(function () { return _this.isSubmitting = false; });
            };
            MoveDocumentDialogController.prototype.$onInit = function () {
                this.data.documentId = this.document.id;
            };
            MoveDocumentDialogController.prototype.setSource = function (source) {
                this.data.source = source.source;
                this.data.sourceId = source.sourceId;
                this.data.involvementId = source.involvementId;
            };
            MoveDocumentDialogController.prototype.cancel = function () {
                this.$mdDialog.cancel();
            };
            MoveDocumentDialogController.$inject = ['$mdDialog', 'datacontext', 'document'];
            MoveDocumentDialogController = __decorate([
                app.Controller('app.documentManagement', 'MoveDocumentDialogController')
            ], MoveDocumentDialogController);
            return MoveDocumentDialogController;
        }());
    })(documentManagement = app.documentManagement || (app.documentManagement = {}));
})(app || (app = {}));
var app;
(function (app) {
    var documentManagement;
    (function (documentManagement) {
        var RenameDocumentDialogController = (function () {
            function RenameDocumentDialogController(common, $mdDialog, datacontext, document) {
                this.common = common;
                this.$mdDialog = $mdDialog;
                this.datacontext = datacontext;
                this.document = document;
                this.data = {};
                this.isSubmitting = false;
                'ngInject';
                'ngInject';
            }
            RenameDocumentDialogController.prototype.submit = function () {
                var _this = this;
                this.isSubmitting = true;
                this.datacontext.documentManagement
                    .renameDocument(this.data)
                    .then(function () { return _this.$mdDialog.hide(); })
                    .finally(function () { return _this.isSubmitting = false; });
            };
            RenameDocumentDialogController.prototype.$onInit = function () {
                this.data.documentId = this.document.id;
                this.data.title = this.document.name;
            };
            RenameDocumentDialogController.prototype.cancel = function () {
                this.$mdDialog.cancel();
            };
            RenameDocumentDialogController.$inject = ['common', '$mdDialog', 'datacontext', 'document'];
            RenameDocumentDialogController = __decorate([
                app.Controller('app.documentManagement', 'RenameDocumentDialogController')
            ], RenameDocumentDialogController);
            return RenameDocumentDialogController;
        }());
    })(documentManagement = app.documentManagement || (app.documentManagement = {}));
})(app || (app = {}));
var app;
(function (app) {
    var documentManagement;
    (function (documentManagement) {
        var UploadDocumentsComponent = (function () {
            function UploadDocumentsComponent(fileUploader, datacontext) {
                this.fileUploader = fileUploader;
                this.datacontext = datacontext;
                this.documentIds = [];
                this.files = [];
                'ngInject';
                'ngInject';
            }
            UploadDocumentsComponent.prototype.deleteFile = function (index) {
                var documentId = this.files[index].documentId;
                this.datacontext.documentManagement.deleteDocument(documentId);
                this.files.splice(index, 1);
                this.documentIds.splice(index, 1);
            };
            UploadDocumentsComponent.prototype.uploadFiles = function (files) {
                var _this = this;
                if (!files.length)
                    return;
                var url = "api/document-management/documents/upload?sourceId=".concat(this.sourceId, "&source=").concat(encodeURIComponent(this.source));
                if (this.involvementId) {
                    url += "&involvementId=".concat(this.involvementId);
                }
                if (this.folderId) {
                    url += "&folderId=".concat(this.folderId);
                }
                var _loop_1 = function (file) {
                    this_1.files.push(file);
                    this_1.fileUploader.upload({ url: url, file: file })
                        .then(function (result) {
                        if (result.data) {
                            var document_1 = result.data;
                            file.documentId = document_1.id;
                            _this.documentIds.push(file.documentId);
                        }
                    }, function (error) { }, function (progress) { return file.progress = Math.floor(100.0 * progress.loaded / progress.total); });
                };
                var this_1 = this;
                for (var _i = 0, files_1 = files; _i < files_1.length; _i++) {
                    var file = files_1[_i];
                    _loop_1(file);
                }
            };
            UploadDocumentsComponent.$inject = ['fileUploader', 'datacontext'];
            UploadDocumentsComponent = __decorate([
                app.Component('app.documentManagement', 'ceUploadDocumentsActivity', {
                    templateUrl: 'app/documentManagement/views/upload-documents/upload-documents.html',
                    bindings: {
                        involvementId: '<',
                        sourceId: '<',
                        source: '@',
                        folderId: '<'
                    }
                })
            ], UploadDocumentsComponent);
            return UploadDocumentsComponent;
        }());
    })(documentManagement = app.documentManagement || (app.documentManagement = {}));
})(app || (app = {}));
var app;
(function (app) {
    var entities;
    (function (entities) {
        var activities;
        (function (activities) {
            var EntityActivities = (function () {
                function EntityActivities($mdDialog, common, datacontext) {
                    this.$mdDialog = $mdDialog;
                    this.common = common;
                    this.datacontext = datacontext;
                    'ngInject';
                    'ngInject';
                }
                EntityActivities.prototype.$onInit = function () {
                    var _this = this;
                    return this.datacontext.activities
                        .searchActivities({ entityId: this.entityId })
                        .then(function (result) {
                        _this.activities = result.list;
                    });
                };
                EntityActivities.prototype.newActivity = function (targetEvent) {
                    var parent = angular.element(document.body);
                    this.$mdDialog.show({
                        parent: parent,
                        targetEvent: targetEvent,
                        clickOutsideToClose: true,
                        templateUrl: 'app/entities/activities/new-activity/new-activity.html',
                        controller: 'NewEntityActivityDialogController',
                        controllerAs: '$ctrl',
                        locals: {
                            entityId: this.entityId
                        }
                    });
                };
                EntityActivities.$inject = ['$mdDialog', 'common', 'datacontext'];
                EntityActivities = __decorate([
                    app.Component('app.entities.activities', 'ceEntityActivities', {
                        templateUrl: 'app/entities/activities/activity-list/activity-list.html',
                        bindings: {
                            entityId: '<'
                        }
                    })
                ], EntityActivities);
                return EntityActivities;
            }());
        })(activities = entities.activities || (entities.activities = {}));
    })(entities = app.entities || (app.entities = {}));
})(app || (app = {}));
var app;
(function (app) {
    var entities;
    (function (entities) {
        var activities;
        (function (activities_3) {
            var activities = [
                {
                    icon: 'contact_phone',
                    text: 'Create Request',
                    sref: '^.createRequest',
                    name: 'Create Request'
                },
                {
                    icon: 'info',
                    text: 'Create I&R',
                    sref: '^.createInformationAndReferral',
                    name: 'Create I&R'
                },
                {
                    icon: 'folder_shared',
                    text: 'Create Client Case',
                    sref: '^.createClientCase',
                    name: 'Create Client Case'
                },
            ];
            var NewEntityActivityDialogController = (function () {
                function NewEntityActivityDialogController($mdDialog, common, entityId) {
                    this.$mdDialog = $mdDialog;
                    this.common = common;
                    this.entityId = entityId;
                    'ngInject';
                    'ngInject';
                }
                NewEntityActivityDialogController.prototype.$onInit = function () {
                    this.filteredActivities = activities;
                };
                NewEntityActivityDialogController.prototype.selectActivity = function (item) {
                    this.common.$state.go(item.sref, { entityId: this.entityId });
                    this.$mdDialog.hide();
                };
                NewEntityActivityDialogController.$inject = ['$mdDialog', 'common', 'entityId'];
                NewEntityActivityDialogController = __decorate([
                    app.Controller('app.entities.activities', 'NewEntityActivityDialogController')
                ], NewEntityActivityDialogController);
                return NewEntityActivityDialogController;
            }());
        })(activities = entities.activities || (entities.activities = {}));
    })(entities = app.entities || (app.entities = {}));
})(app || (app = {}));
var app;
(function (app) {
    var entities;
    (function (entities) {
        var details;
        (function (details) {
            var AddressForm = (function () {
                function AddressForm(common, addressHelper) {
                    this.common = common;
                    this.addressHelper = addressHelper;
                }
                AddressForm.prototype.onPlaceSelected = function (place) {
                    var _this = this;
                    this.common.$timeout(function () {
                        angular.extend(_this.data, _this.addressHelper.mapFromGooglePlaceResult(place));
                    });
                };
                AddressForm.prototype.$onChanges = function () {
                    this.data = angular.extend({}, this.address);
                };
                AddressForm.$inject = ['common', 'addressHelper'];
                AddressForm = __decorate([
                    app.Component('app.entities.details', 'ceEntityAddressForm', {
                        templateUrl: 'app/entities/details/address/address-form.html',
                        bindings: {
                            address: '<',
                            onCancel: '&',
                            onSubmit: '&'
                        }
                    }),
                    app.Inject('common', 'addressHelper')
                ], AddressForm);
                return AddressForm;
            }());
        })(details = entities.details || (entities.details = {}));
    })(entities = app.entities || (app.entities = {}));
})(app || (app = {}));
var app;
(function (app) {
    var entities;
    (function (entities) {
        var details;
        (function (details) {
            var AddressList = (function () {
                function AddressList() {
                }
                AddressList = __decorate([
                    app.Component('app.entities.details', 'ceEntityAddressList', {
                        templateUrl: 'app/entities/details/address/address-list.html',
                        bindings: {
                            addresses: '<',
                            onAdd: '&',
                            onEdit: '&',
                            onDelete: '&'
                        }
                    })
                ], AddressList);
                return AddressList;
            }());
        })(details = entities.details || (entities.details = {}));
    })(entities = app.entities || (app.entities = {}));
})(app || (app = {}));
var app;
(function (app) {
    var entities;
    (function (entities) {
        var details;
        (function (details) {
            var Addresses = (function () {
                function Addresses(common, datacontext) {
                    var _this = this;
                    this.common = common;
                    this.datacontext = datacontext;
                    this.editMode = false;
                    this.mouseOver = false;
                    this.deleteAddressCore = function () {
                        _this.datacontext.entities.deleteEntityAddress(_this.selectedAddress)
                            .then(function () {
                            var index = _this.addresses.indexOf(_this.selectedAddress);
                            _this.addresses.splice(index, 1);
                            _this.cancelEdit();
                        });
                    };
                    this.getAddressses = function () {
                        _this.datacontext.entities
                            .getEntityAddresses(_this.entityId)
                            .then(function (result) { return _this.addresses = result; });
                    };
                    'ngInject';
                    'ngInject';
                }
                Addresses.prototype.deleteAddress = function (address) {
                    this.selectedAddress = address;
                    this.common.confirmation.show({
                        title: 'Delete Address',
                        message: 'Are you sure you want to delete this address?',
                        ok: 'Delete',
                        cancel: 'Keep'
                    }).then(this.deleteAddressCore);
                };
                Addresses.prototype.cancelEdit = function () {
                    this.editMode = false;
                    this.selectedAddress = null;
                };
                Addresses.prototype.addAddress = function () {
                    var address = {};
                    address.addressType = this.defaultAddressType;
                    this.editAddress(address);
                };
                Addresses.prototype.editAddress = function (address) {
                    this.selectedAddress = address;
                    this.editMode = true;
                };
                Addresses.prototype.saveAddress = function (data) {
                    var _this = this;
                    data.entityId = this.entityId;
                    if (data.startDate && typeof data.startDate !== 'string') {
                        data.startDate = data.startDate.toDateString();
                    }
                    if (data.endDate && typeof data.endDate !== 'string') {
                        data.endDate = data.endDate.toDateString();
                    }
                    var promise = this.selectedAddress.id
                        ? this.datacontext.entities.updateEntityAddress(data)
                        : this.datacontext.entities.createEntityAddress(data);
                    if (data.isPrimary) {
                        this.addresses.forEach(function (a) { return a.isPrimary = false; });
                    }
                    promise.then(function (result) {
                        if (result) {
                            if (!_this.selectedAddress.id) {
                                _this.addresses.push(result);
                            }
                            else {
                                angular.extend(_this.selectedAddress, result);
                            }
                            _this.cancelEdit();
                        }
                    });
                };
                Addresses.prototype.$onInit = function () {
                    this.getAddressses();
                };
                Addresses.$inject = ['common', 'datacontext'];
                Addresses = __decorate([
                    app.Component('app.entities.details', 'ceEntityAddresses', {
                        templateUrl: 'app/entities/details/address/addresses.html',
                        bindings: {
                            entityId: '<',
                            defaultAddressType: '@'
                        }
                    })
                ], Addresses);
                return Addresses;
            }());
        })(details = entities.details || (entities.details = {}));
    })(entities = app.entities || (app.entities = {}));
})(app || (app = {}));
var app;
(function (app) {
    var entities;
    (function (entities) {
        var details;
        (function (details) {
            var EmailAddressForm = (function () {
                function EmailAddressForm(common) {
                    this.common = common;
                }
                EmailAddressForm.prototype.phoneChanged = function (emailAddress) {
                    this.data = angular.extend(this.data, emailAddress);
                };
                EmailAddressForm.prototype.$onChanges = function () {
                    this.data = angular.extend({}, this.emailAddress);
                };
                EmailAddressForm.$inject = ['common'];
                EmailAddressForm = __decorate([
                    app.Component('app.entities.details', 'ceEntityEmailAddressForm', {
                        templateUrl: 'app/entities/details/email-address/email-address-form.html',
                        bindings: {
                            emailAddress: '<',
                            onCancel: '&',
                            onSubmit: '&'
                        }
                    }),
                    app.Inject('common')
                ], EmailAddressForm);
                return EmailAddressForm;
            }());
        })(details = entities.details || (entities.details = {}));
    })(entities = app.entities || (app.entities = {}));
})(app || (app = {}));
var app;
(function (app) {
    var entities;
    (function (entities) {
        var details;
        (function (details) {
            var EmailAddressList = (function () {
                function EmailAddressList() {
                }
                EmailAddressList = __decorate([
                    app.Component('app.entities.details', 'ceEntityEmailAddressList', {
                        templateUrl: 'app/entities/details/email-address/email-address-list.html',
                        bindings: {
                            emailAddresses: '<',
                            onAdd: '&',
                            onEdit: '&',
                            onDelete: '&'
                        }
                    })
                ], EmailAddressList);
                return EmailAddressList;
            }());
        })(details = entities.details || (entities.details = {}));
    })(entities = app.entities || (app.entities = {}));
})(app || (app = {}));
var app;
(function (app) {
    var entities;
    (function (entities) {
        var details;
        (function (details) {
            var EmailAddresses = (function () {
                function EmailAddresses(common, datacontext) {
                    var _this = this;
                    this.common = common;
                    this.datacontext = datacontext;
                    this.editMode = false;
                    this.mouseOver = false;
                    this.deleteEmailAddressCore = function () {
                        _this.datacontext.entities.deleteEntityEmailAddress(_this.selectedEmailAddress)
                            .then(function () {
                            var index = _this.emailAddresses.indexOf(_this.selectedEmailAddress);
                            _this.emailAddresses.splice(index, 1);
                            _this.cancelEdit();
                        });
                    };
                    this.getEmailAddresses = function () {
                        _this.datacontext.entities
                            .getEntityEmailAddresses(_this.entityId)
                            .then(function (result) { return _this.emailAddresses = result; });
                    };
                    'ngInject';
                    'ngInject';
                }
                EmailAddresses.prototype.deleteEmailAddress = function (emailAddress) {
                    this.selectedEmailAddress = emailAddress;
                    this.common.confirmation.show({
                        title: 'Delete Phone Number',
                        message: 'Are you sure you want to delete this emailAddress number?',
                        ok: 'Delete',
                        cancel: 'Keep'
                    }).then(this.deleteEmailAddressCore);
                };
                EmailAddresses.prototype.cancelEdit = function () {
                    this.editMode = false;
                    this.selectedEmailAddress = null;
                };
                EmailAddresses.prototype.addEmailAddress = function () {
                    var emailAddress = {};
                    this.editEmailAddress(emailAddress);
                };
                EmailAddresses.prototype.editEmailAddress = function (emailAddress) {
                    this.selectedEmailAddress = emailAddress;
                    this.editMode = true;
                };
                EmailAddresses.prototype.saveEmailAddress = function (data) {
                    var _this = this;
                    data.entityId = this.entityId;
                    var promise = this.selectedEmailAddress.id
                        ? this.datacontext.entities.updateEntityEmailAddress(data)
                        : this.datacontext.entities.createEntityEmailAddress(data);
                    if (data.isPrimary) {
                        this.emailAddresses.forEach(function (a) { return a.isPrimary = false; });
                    }
                    promise.then(function (result) {
                        if (result) {
                            if (!_this.selectedEmailAddress.id) {
                                _this.emailAddresses.push(result);
                            }
                            else {
                                angular.extend(_this.selectedEmailAddress, result);
                            }
                            _this.cancelEdit();
                        }
                    });
                };
                EmailAddresses.prototype.$onInit = function () {
                    this.getEmailAddresses();
                };
                EmailAddresses.$inject = ['common', 'datacontext'];
                EmailAddresses = __decorate([
                    app.Component('app.entities.details', 'ceEntityEmailAddresses', {
                        templateUrl: 'app/entities/details/email-address/email-addresses.html',
                        bindings: {
                            entityId: '<'
                        }
                    })
                ], EmailAddresses);
                return EmailAddresses;
            }());
        })(details = entities.details || (entities.details = {}));
    })(entities = app.entities || (app.entities = {}));
})(app || (app = {}));
var app;
(function (app) {
    var entities;
    (function (entities) {
        var details;
        (function (details) {
            var NameForm = (function () {
                function NameForm(common) {
                    this.common = common;
                }
                NameForm.prototype.$onChanges = function () {
                    this.data = angular.extend({}, this.name);
                };
                NameForm.$inject = ['common'];
                NameForm = __decorate([
                    app.Component('app.entities.details', 'ceAgencyNameForm', {
                        templateUrl: 'app/entities/details/name/agency-name-form.html',
                        bindings: {
                            name: '<',
                            onCancel: '&',
                            onSubmit: '&'
                        }
                    }),
                    app.Inject('common')
                ], NameForm);
                return NameForm;
            }());
        })(details = entities.details || (entities.details = {}));
    })(entities = app.entities || (app.entities = {}));
})(app || (app = {}));
var app;
(function (app) {
    var entities;
    (function (entities) {
        var details;
        (function (details) {
            var NameList = (function () {
                function NameList() {
                }
                NameList = __decorate([
                    app.Component('app.entities.details', 'ceEntityNameList', {
                        templateUrl: 'app/entities/details/name/name-list.html',
                        bindings: {
                            names: '<',
                            onAdd: '&',
                            onEdit: '&',
                            onDelete: '&'
                        }
                    })
                ], NameList);
                return NameList;
            }());
        })(details = entities.details || (entities.details = {}));
    })(entities = app.entities || (app.entities = {}));
})(app || (app = {}));
var app;
(function (app) {
    var entities;
    (function (entities) {
        var details;
        (function (details) {
            var Names = (function () {
                function Names(common, datacontext) {
                    var _this = this;
                    this.common = common;
                    this.datacontext = datacontext;
                    this.editMode = false;
                    this.mouseOver = false;
                    this.deleteNameCore = function () {
                        _this.datacontext.entities.deleteEntityName(_this.selectedName)
                            .then(function () {
                            var index = _this.names.indexOf(_this.selectedName);
                            _this.names.splice(index, 1);
                            _this.cancelEdit();
                        });
                    };
                    this.getNames = function () {
                        _this.datacontext.entities
                            .getEntityNames(_this.entity.id)
                            .then(function (result) { return _this.names = result; });
                    };
                    'ngInject';
                    'ngInject';
                }
                Names.prototype.deleteName = function (name) {
                    this.selectedName = name;
                    this.common.confirmation.show({
                        title: 'Delete Name',
                        message: 'Are you sure you want to delete this name?',
                        ok: 'Delete',
                        cancel: 'Keep'
                    }).then(this.deleteNameCore);
                };
                Names.prototype.cancelEdit = function () {
                    this.editMode = false;
                    this.selectedName = null;
                };
                Names.prototype.addName = function () {
                    var name = {};
                    name.nameType = this.defaultNameType;
                    this.editName(name);
                };
                Names.prototype.editName = function (name) {
                    this.selectedName = name;
                    this.editMode = true;
                };
                Names.prototype.saveName = function (data) {
                    var _this = this;
                    data.entityId = this.entity.id;
                    var promise = this.selectedName.id
                        ? this.datacontext.entities.updateEntityName(data)
                        : this.datacontext.entities.createEntityName(data);
                    if (data.isPrimary) {
                        this.names.forEach(function (a) { return a.isPrimary = false; });
                    }
                    promise.then(function (result) {
                        if (result) {
                            if (!_this.selectedName.id) {
                                _this.names.push(result);
                            }
                            else {
                                angular.extend(_this.selectedName, result);
                            }
                            if (result.isPrimary) {
                                _this.entity.fullName = result.fullName;
                            }
                            _this.cancelEdit();
                        }
                    });
                };
                Names.prototype.$onInit = function () {
                    this.getNames();
                };
                Names.$inject = ['common', 'datacontext'];
                Names = __decorate([
                    app.Component('app.entities.details', 'ceEntityNames', {
                        templateUrl: 'app/entities/details/name/names.html',
                        bindings: {
                            entity: '<',
                            entityType: '@'
                        }
                    })
                ], Names);
                return Names;
            }());
        })(details = entities.details || (entities.details = {}));
    })(entities = app.entities || (app.entities = {}));
})(app || (app = {}));
var app;
(function (app) {
    var entities;
    (function (entities) {
        var details;
        (function (details) {
            var NameForm = (function () {
                function NameForm(common) {
                    this.common = common;
                }
                NameForm.prototype.$onChanges = function () {
                    this.data = angular.extend({}, this.name);
                };
                NameForm.$inject = ['common'];
                NameForm = __decorate([
                    app.Component('app.entities.details', 'cePersonNameForm', {
                        templateUrl: 'app/entities/details/name/person-name-form.html',
                        bindings: {
                            name: '<',
                            onCancel: '&',
                            onSubmit: '&'
                        }
                    }),
                    app.Inject('common')
                ], NameForm);
                return NameForm;
            }());
        })(details = entities.details || (entities.details = {}));
    })(entities = app.entities || (app.entities = {}));
})(app || (app = {}));
var app;
(function (app) {
    var entities;
    (function (entities) {
        var details;
        (function (details) {
            var PhoneNumberForm = (function () {
                function PhoneNumberForm(common) {
                    this.common = common;
                }
                PhoneNumberForm.prototype.phoneChanged = function (phoneNumber) {
                    this.data = angular.extend(this.data, phoneNumber);
                };
                PhoneNumberForm.prototype.$onChanges = function () {
                    this.data = angular.extend({}, this.phoneNumber);
                };
                PhoneNumberForm.$inject = ['common'];
                PhoneNumberForm = __decorate([
                    app.Component('app.entities.details', 'ceEntityPhoneNumberForm', {
                        templateUrl: 'app/entities/details/phone-number/phone-number-form.html',
                        bindings: {
                            phoneNumber: '<',
                            onCancel: '&',
                            onSubmit: '&'
                        }
                    }),
                    app.Inject('common')
                ], PhoneNumberForm);
                return PhoneNumberForm;
            }());
        })(details = entities.details || (entities.details = {}));
    })(entities = app.entities || (app.entities = {}));
})(app || (app = {}));
var app;
(function (app) {
    var entities;
    (function (entities) {
        var details;
        (function (details) {
            var PhoneNumberList = (function () {
                function PhoneNumberList() {
                }
                PhoneNumberList = __decorate([
                    app.Component('app.entities.details', 'ceEntityPhoneNumberList', {
                        templateUrl: 'app/entities/details/phone-number/phone-number-list.html',
                        bindings: {
                            phoneNumbers: '<',
                            onAdd: '&',
                            onEdit: '&',
                            onDelete: '&'
                        }
                    })
                ], PhoneNumberList);
                return PhoneNumberList;
            }());
        })(details = entities.details || (entities.details = {}));
    })(entities = app.entities || (app.entities = {}));
})(app || (app = {}));
var app;
(function (app) {
    var entities;
    (function (entities) {
        var details;
        (function (details) {
            var PhoneNumbers = (function () {
                function PhoneNumbers(common, datacontext) {
                    var _this = this;
                    this.common = common;
                    this.datacontext = datacontext;
                    this.editMode = false;
                    this.mouseOver = false;
                    this.deletePhoneNumberCore = function () {
                        _this.datacontext.entities.deleteEntityPhoneNumber(_this.selectedPhoneNumber)
                            .then(function () {
                            var index = _this.phoneNumbers.indexOf(_this.selectedPhoneNumber);
                            _this.phoneNumbers.splice(index, 1);
                            _this.cancelEdit();
                        });
                    };
                    this.getPhoneNumbers = function () {
                        _this.datacontext.entities
                            .getEntityPhoneNumbers(_this.entityId)
                            .then(function (result) { return _this.phoneNumbers = result; });
                    };
                    'ngInject';
                    'ngInject';
                }
                PhoneNumbers.prototype.deletePhoneNumber = function (phoneNumber) {
                    this.selectedPhoneNumber = phoneNumber;
                    this.common.confirmation.show({
                        title: 'Delete Phone Number',
                        message: 'Are you sure you want to delete this phone number?',
                        ok: 'Delete',
                        cancel: 'Keep'
                    }).then(this.deletePhoneNumberCore);
                };
                PhoneNumbers.prototype.cancelEdit = function () {
                    this.editMode = false;
                    this.selectedPhoneNumber = null;
                };
                PhoneNumbers.prototype.addPhoneNumber = function () {
                    var phoneNumber = {};
                    this.editPhoneNumber(phoneNumber);
                };
                PhoneNumbers.prototype.editPhoneNumber = function (phoneNumber) {
                    this.selectedPhoneNumber = phoneNumber;
                    this.editMode = true;
                };
                PhoneNumbers.prototype.savePhoneNumber = function (data) {
                    var _this = this;
                    var phoneNumber = {
                        phone: data.phone,
                        extension: data.extension,
                        formattedNumber: data.formattedPhoneNumber,
                        allowsVoiceMail: data.allowsVoiceMail
                    };
                    var phone = {
                        id: this.selectedPhoneNumber.id,
                        entityId: this.entityId,
                        phoneNumber: phoneNumber,
                        phoneNumberType: data.phoneNumberType,
                        isPrimary: data.isPrimary
                    };
                    var promise = this.selectedPhoneNumber.id
                        ? this.datacontext.entities.updateEntityPhoneNumber(phone)
                        : this.datacontext.entities.createEntityPhoneNumber(phone);
                    if (data.isPrimary) {
                        this.phoneNumbers.forEach(function (a) { return a.isPrimary = false; });
                    }
                    promise.then(function (result) {
                        if (result) {
                            if (!_this.selectedPhoneNumber.id) {
                                _this.phoneNumbers.push(result);
                            }
                            else {
                                angular.extend(_this.selectedPhoneNumber, result);
                            }
                            _this.cancelEdit();
                        }
                    });
                };
                PhoneNumbers.prototype.$onInit = function () {
                    this.getPhoneNumbers();
                };
                PhoneNumbers.$inject = ['common', 'datacontext'];
                PhoneNumbers = __decorate([
                    app.Component('app.entities.details', 'ceEntityPhoneNumbers', {
                        templateUrl: 'app/entities/details/phone-number/phone-numbers.html',
                        bindings: {
                            entityId: '<',
                            defaultPhoneType: '@'
                        }
                    })
                ], PhoneNumbers);
                return PhoneNumbers;
            }());
        })(details = entities.details || (entities.details = {}));
    })(entities = app.entities || (app.entities = {}));
})(app || (app = {}));
var app;
(function (app) {
    var entities;
    (function (entities) {
        var summary;
        (function (summary) {
            var PersonContact = (function () {
                function PersonContact(datacontext) {
                    this.datacontext = datacontext;
                    'ngInject';
                    'ngInject';
                }
                PersonContact.prototype.$onInit = function () {
                    var _this = this;
                    this.datacontext.entities.getContactInfo(this.entity.id)
                        .then(function (result) { return _this.data = result; });
                };
                PersonContact.$inject = ['datacontext'];
                PersonContact = __decorate([
                    app.Component('app.entities.summary', 'ceEntityContactSummary', {
                        templateUrl: 'app/entities/summary/contact-info/contact-info.html',
                        bindings: {
                            entity: '<'
                        }
                    })
                ], PersonContact);
                return PersonContact;
            }());
        })(summary = entities.summary || (entities.summary = {}));
    })(entities = app.entities || (app.entities = {}));
})(app || (app = {}));
var app;
(function (app) {
    var funding;
    (function (funding) {
        var CAPIntakeForm = (function () {
            function CAPIntakeForm(common, datacontext) {
                this.common = common;
                this.datacontext = datacontext;
                'ngInject';
                'ngInject';
            }
            CAPIntakeForm.$inject = ['common', 'datacontext'];
            CAPIntakeForm = __decorate([
                app.Component('app.funding', 'ceCapIntakeForm', {
                    templateUrl: 'app/funding/enrollments/cap/cap-intake-form.html',
                    bindings: {
                        intake: '<',
                        onCancel: '&',
                        onSubmit: '&'
                    }
                })
            ], CAPIntakeForm);
            return CAPIntakeForm;
        }());
    })(funding = app.funding || (app.funding = {}));
})(app || (app = {}));
var app;
(function (app) {
    var funding;
    (function (funding) {
        var CAPIntakeInfo = (function () {
            function CAPIntakeInfo(common, datacontext) {
                this.common = common;
                this.datacontext = datacontext;
                'ngInject';
                'ngInject';
            }
            CAPIntakeInfo.$inject = ['common', 'datacontext'];
            CAPIntakeInfo = __decorate([
                app.Component('app.funding', 'ceCapIntakeInfo', {
                    templateUrl: 'app/funding/enrollments/cap/cap-intake-info.html',
                    bindings: {
                        intake: '<',
                        onEdit: '&'
                    }
                })
            ], CAPIntakeInfo);
            return CAPIntakeInfo;
        }());
    })(funding = app.funding || (app.funding = {}));
})(app || (app = {}));
var app;
(function (app) {
    var funding;
    (function (funding) {
        var CAPIntake = (function () {
            function CAPIntake(common, datacontext) {
                this.common = common;
                this.datacontext = datacontext;
                'ngInject';
                'ngInject';
            }
            CAPIntake.prototype.$onInit = function () {
                this.getIntake();
            };
            CAPIntake.prototype.getIntake = function () {
                var _this = this;
                return this.datacontext.funding.getCAPIntake(this.enrollmentId)
                    .then(function (intake) { if (intake)
                    _this.intake = intake; });
            };
            CAPIntake.prototype.edit = function () {
                var _this = this;
                this.getIntake().then(function () {
                    _this.editing = true;
                });
            };
            CAPIntake.prototype.updateIntake = function () {
                var _this = this;
                this.datacontext.funding.updateCAPIntake(this.intake)
                    .then(function () {
                    _this.editing = false;
                });
            };
            CAPIntake.prototype.cancel = function () {
                var _this = this;
                this.getIntake().then(function () {
                    _this.editing = false;
                });
            };
            CAPIntake.prototype.done = function () {
                this.common.$state.go('^.');
            };
            CAPIntake.$inject = ['common', 'datacontext'];
            CAPIntake = __decorate([
                app.Component('app.funding', 'ceCapIntake', {
                    templateUrl: 'app/funding/enrollments/cap/cap-intake.html',
                    bindings: {
                        enrollmentId: '<'
                    }
                })
            ], CAPIntake);
            return CAPIntake;
        }());
    })(funding = app.funding || (app.funding = {}));
})(app || (app = {}));
var app;
(function (app) {
    var funding;
    (function (funding) {
        var CAPOutcomeForm = (function () {
            function CAPOutcomeForm(datacontext) {
                this.datacontext = datacontext;
                'ngInject';
                'ngInject';
            }
            CAPOutcomeForm.prototype.$onInit = function () {
                this.getInterventionStrategies();
                this.getindividualTypes();
            };
            CAPOutcomeForm.prototype.getInterventionStrategies = function () {
                var _this = this;
                return this.datacontext.config.lookups.getLookupType('cap-intervention-strategies')
                    .then(function (lookup) { _this.interventionStrategies = lookup.lookups; });
            };
            CAPOutcomeForm.prototype.getindividualTypes = function () {
                var _this = this;
                return this.datacontext.config.lookups.getLookupType('cap-individual-types')
                    .then(function (lookup) { _this.individualTypes = lookup.lookups; });
            };
            CAPOutcomeForm.prototype.otherDisabilityToggle = function () {
                this.outcome.OtherAchievement = !this.outcome.OtherAchievement;
            };
            CAPOutcomeForm.$inject = ['datacontext'];
            CAPOutcomeForm = __decorate([
                app.Component('app.funding', 'ceCapOutcomeForm', {
                    templateUrl: 'app/funding/enrollments/cap/cap-outcome-form.html',
                    bindings: {
                        outcome: '<',
                        onCancel: '&',
                        onSubmit: '&'
                    }
                })
            ], CAPOutcomeForm);
            return CAPOutcomeForm;
        }());
    })(funding = app.funding || (app.funding = {}));
})(app || (app = {}));
var app;
(function (app) {
    var funding;
    (function (funding) {
        var CAPOutcomeInfo = (function () {
            function CAPOutcomeInfo(common, datacontext) {
                this.common = common;
                this.datacontext = datacontext;
                'ngInject';
                'ngInject';
            }
            CAPOutcomeInfo.$inject = ['common', 'datacontext'];
            CAPOutcomeInfo = __decorate([
                app.Component('app.funding', 'ceCapOutcomeInfo', {
                    templateUrl: 'app/funding/enrollments/cap/cap-outcome-info.html',
                    bindings: {
                        outcome: '<',
                        onEdit: '&'
                    }
                })
            ], CAPOutcomeInfo);
            return CAPOutcomeInfo;
        }());
    })(funding = app.funding || (app.funding = {}));
})(app || (app = {}));
var app;
(function (app) {
    var funding;
    (function (funding) {
        var CAPOutcome = (function () {
            function CAPOutcome($mdDialog, common, datacontext) {
                this.$mdDialog = $mdDialog;
                this.common = common;
                this.datacontext = datacontext;
                this.outcome = {};
                'ngInject';
                'ngInject';
            }
            CAPOutcome.prototype.$onInit = function () {
                this.getOutcome();
            };
            CAPOutcome.prototype.getOutcome = function () {
                var _this = this;
                return this.datacontext.funding.getCAPOutcome(this.enrollmentId).then(function (result) {
                    if (result) {
                        _this.outcome = result;
                    }
                    else {
                        _this.outcome.id = _this.enrollmentId;
                    }
                });
            };
            CAPOutcome.prototype.edit = function () {
                var _this = this;
                this.getOutcome().then(function () { return _this.editing = true; });
            };
            CAPOutcome.prototype.updateOutcome = function () {
                var _this = this;
                this.datacontext.funding.updateCAPOutcome(this.outcome).then(function () {
                    _this.editing = false;
                });
            };
            CAPOutcome.prototype.cancel = function () {
                var _this = this;
                this.getOutcome().then(function () {
                    _this.editing = false;
                });
            };
            CAPOutcome.prototype.done = function () {
                this.common.$state.go('^.');
            };
            CAPOutcome.$inject = ['$mdDialog', 'common', 'datacontext'];
            CAPOutcome = __decorate([
                app.Component('app.funding', 'ceCapOutcome', {
                    templateUrl: 'app/funding/enrollments/cap/cap-outcome.html',
                    bindings: {
                        enrollmentId: '<'
                    }
                })
            ], CAPOutcome);
            return CAPOutcome;
        }());
    })(funding = app.funding || (app.funding = {}));
})(app || (app = {}));
var app;
(function (app) {
    var funding;
    (function (funding) {
        var CreatePrimaryFunding = (function () {
            function CreatePrimaryFunding(common, datacontext) {
                var _this = this;
                this.common = common;
                this.datacontext = datacontext;
                this.enrollment = {};
                this.isLoading = true;
                this.maxDate = moment().toDate();
                this.$onInit = function () {
                    _this.getFundingSources();
                    _this.enrollment.involvementId = _this.involvementId;
                    _this.enrollment.date = moment().toDate();
                };
                this.getFundingSources = function () {
                    _this.datacontext.funding.getFundingSummaryView(_this.involvementId)
                        .then(function (result) {
                        if (result) {
                            _this.enrollment.fundingProgramId = result.primaryFundingProgramId;
                        }
                        _this.datacontext.funding
                            .getEnrollmentEligibilityView(_this.involvementId)
                            .then(function (result) {
                            _this.fundingPrograms = result;
                            _this.isLoading = false;
                        });
                    });
                };
                'ngInject';
                'ngInject';
            }
            CreatePrimaryFunding.prototype.submit = function () {
                var _this = this;
                this.isSubmitting = true;
                if (typeof this.enrollment.date !== 'string') {
                    this.enrollment.date = this.enrollment.date.toDateString();
                }
                this.datacontext.funding.createEnrollment(this.enrollment)
                    .then(function (result) {
                    _this.common.$state.go('^.');
                });
            };
            CreatePrimaryFunding.$inject = ['common', 'datacontext'];
            CreatePrimaryFunding = __decorate([
                app.Component('app.funding', 'ceCreatePrimaryFunding', {
                    templateUrl: 'app/funding/enrollments/create/create.html',
                    bindings: {
                        involvementId: '<'
                    }
                })
            ], CreatePrimaryFunding);
            return CreatePrimaryFunding;
        }());
    })(funding = app.funding || (app.funding = {}));
})(app || (app = {}));
var app;
(function (app) {
    var funding;
    (function (funding) {
        var EditPrimaryFunding = (function () {
            function EditPrimaryFunding(common, datacontext) {
                var _this = this;
                this.common = common;
                this.datacontext = datacontext;
                this.isLoading = true;
                this.maxDate = moment().toDate();
                this.$onInit = function () {
                    _this.getFundingSources();
                };
                this.getFundingSources = function () {
                    _this.isLoading = true;
                    _this.datacontext.funding
                        .getEnrollmentEligibilityView(_this.enrollment.involvementId)
                        .then(function (result) {
                        _this.fundingPrograms = result;
                        _this.isLoading = false;
                    });
                };
                'ngInject';
                'ngInject';
            }
            EditPrimaryFunding.prototype.submit = function () {
                var _this = this;
                if (typeof this.enrollment.date !== 'string') {
                    this.enrollment.date = this.enrollment.date.toDateString();
                }
                this.datacontext.funding.updateEnrollment(this.enrollment)
                    .then(function (result) {
                    _this.common.$state.go('^.');
                });
            };
            EditPrimaryFunding.$inject = ['common', 'datacontext'];
            EditPrimaryFunding = __decorate([
                app.Component('app.funding', 'ceEditPrimaryFunding', {
                    templateUrl: 'app/funding/enrollments/edit/edit.html',
                    bindings: {
                        enrollment: '<'
                    }
                })
            ], EditPrimaryFunding);
            return EditPrimaryFunding;
        }());
    })(funding = app.funding || (app.funding = {}));
})(app || (app = {}));
var app;
(function (app) {
    var funding;
    (function (funding) {
        var PrimaryFundingSourceList = (function () {
            function PrimaryFundingSourceList(common, datacontext) {
                this.common = common;
                this.datacontext = datacontext;
                'ngInject';
                'ngInject';
            }
            PrimaryFundingSourceList.prototype.showDeleteDialog = function (id) {
                var _this = this;
                this.common.confirmation.show({
                    title: 'Delete Primary Funding Source?',
                    message: 'Are you sure you want to delete this Funding Source? The system will attempt to fix unlocked timeslips.',
                    ok: 'Delete',
                    cancel: 'Keep'
                }).then(function () { _this.deleteEnrollment(id); });
            };
            PrimaryFundingSourceList.prototype.deleteEnrollment = function (id) {
                var _this = this;
                return this.datacontext.funding.deleteEnrollment(id)
                    .then(function () { return _this.removePrimaryFundingSource(id); });
            };
            PrimaryFundingSourceList.prototype.removePrimaryFundingSource = function (id) {
                _.remove(this.primaryFundingSourceList, function (pfs) { return pfs.id === id; });
            };
            PrimaryFundingSourceList.prototype.editIntake = function (enrollment) {
                var _a;
                if (this.hasIntakeData(enrollment)) {
                    var fundingProgramName = (_a = enrollment.parentFundingProgramName) !== null && _a !== void 0 ? _a : enrollment.fundingProgramName;
                    var stateName = "^.".concat(fundingProgramName.toLowerCase(), "Intake");
                    this.common.$state.go(stateName, { enrollmentId: enrollment.id });
                }
            };
            PrimaryFundingSourceList.prototype.editOutcome = function (enrollment) {
                var _a;
                if (this.hasOutcomeData(enrollment)) {
                    var fundingProgramName = (_a = enrollment.parentFundingProgramName) !== null && _a !== void 0 ? _a : enrollment.fundingProgramName;
                    var stateName = "^.".concat(fundingProgramName.toLowerCase(), "Outcome");
                    this.common.$state.go(stateName, { enrollmentId: enrollment.id });
                }
            };
            PrimaryFundingSourceList.prototype.hasIntakeData = function (enrollment) {
                switch (this.source) {
                    case 'I&R':
                        return enrollment.hasIAndRIntakeData;
                    case 'Client Case':
                        return enrollment.hasClientCaseIntakeData;
                    case 'Systemic Case':
                        return enrollment.hasSystemicCaseIntakeData;
                    case 'Monitoring':
                        return enrollment.hasMonitoringIntakeData;
                }
            };
            PrimaryFundingSourceList.prototype.hasOutcomeData = function (enrollment) {
                switch (this.source) {
                    case 'I&R':
                        return enrollment.hasIAndROutcomeData;
                    case 'Client Case':
                        return enrollment.hasClientCaseOutcomeData;
                    case 'Systemic Case':
                        return enrollment.hasSystemicCaseOutcomeData;
                    case 'Monitoring':
                        return enrollment.hasMonitoringOutcomeData;
                }
            };
            PrimaryFundingSourceList.$inject = ['common', 'datacontext'];
            PrimaryFundingSourceList = __decorate([
                app.Component('app.funding', 'cePrimaryFundingSourceList', {
                    templateUrl: 'app/funding/enrollments/listing/enrollments-list.html',
                    bindings: {
                        primaryFundingSourceList: '<',
                        source: '<'
                    }
                })
            ], PrimaryFundingSourceList);
            return PrimaryFundingSourceList;
        }());
    })(funding = app.funding || (app.funding = {}));
})(app || (app = {}));
var app;
(function (app) {
    var funding;
    (function (funding) {
        var PrimaryFundingSourceListing = (function () {
            function PrimaryFundingSourceListing($mdDialog, common, datacontext) {
                var _this = this;
                this.$mdDialog = $mdDialog;
                this.common = common;
                this.datacontext = datacontext;
                this.$onInit = function () {
                    _this.getEnrollmentView();
                };
                this.getEnrollmentView = function () {
                    return _this.datacontext.funding
                        .getEnrollmentView(_this.involvementId)
                        .then(function (result) {
                        _this.primaryFundingSourceList = result;
                    });
                };
                'ngInject';
                'ngInject';
            }
            PrimaryFundingSourceListing.$inject = ['$mdDialog', 'common', 'datacontext'];
            PrimaryFundingSourceListing = __decorate([
                app.Component('app.funding', 'cePrimaryFundingSourceListing', {
                    templateUrl: 'app/funding/enrollments/listing/enrollments-listing.html',
                    bindings: {
                        involvementId: '<',
                        source: '@'
                    }
                })
            ], PrimaryFundingSourceListing);
            return PrimaryFundingSourceListing;
        }());
    })(funding = app.funding || (app.funding = {}));
})(app || (app = {}));
var app;
(function (app) {
    var funding;
    (function (funding) {
        var NDBEDPIntakeForm = (function () {
            function NDBEDPIntakeForm(common, datacontext) {
                this.common = common;
                this.datacontext = datacontext;
                'ngInject';
                'ngInject';
            }
            NDBEDPIntakeForm.$inject = ['common', 'datacontext'];
            NDBEDPIntakeForm = __decorate([
                app.Component('app.funding', 'ceNdbedpIntakeForm', {
                    templateUrl: 'app/funding/enrollments/ndbedp/ndbedp-intake-form.html',
                    bindings: {
                        intake: '<',
                        onCancel: '&',
                        onSubmit: '&'
                    }
                })
            ], NDBEDPIntakeForm);
            return NDBEDPIntakeForm;
        }());
    })(funding = app.funding || (app.funding = {}));
})(app || (app = {}));
var app;
(function (app) {
    var funding;
    (function (funding) {
        var NDBEDPIntakeInfo = (function () {
            function NDBEDPIntakeInfo(common, datacontext) {
                this.common = common;
                this.datacontext = datacontext;
                'ngInject';
                'ngInject';
            }
            NDBEDPIntakeInfo.$inject = ['common', 'datacontext'];
            NDBEDPIntakeInfo = __decorate([
                app.Component('app.funding', 'ceNdbedpIntakeInfo', {
                    templateUrl: 'app/funding/enrollments/ndbedp/ndbedp-intake-info.html',
                    bindings: {
                        intake: '<',
                        onEdit: '&'
                    }
                })
            ], NDBEDPIntakeInfo);
            return NDBEDPIntakeInfo;
        }());
    })(funding = app.funding || (app.funding = {}));
})(app || (app = {}));
var app;
(function (app) {
    var funding;
    (function (funding) {
        var NDBEDPIntake = (function () {
            function NDBEDPIntake(common, datacontext) {
                this.common = common;
                this.datacontext = datacontext;
                'ngInject';
                'ngInject';
            }
            NDBEDPIntake.prototype.$onInit = function () {
                this.getIntake();
            };
            NDBEDPIntake.prototype.getIntake = function () {
                var _this = this;
                return this.datacontext.funding.getNDBEDPIntake(this.enrollmentId)
                    .then(function (intake) { if (intake)
                    _this.intake = intake; });
            };
            NDBEDPIntake.prototype.edit = function () {
                var _this = this;
                this.getIntake().then(function () {
                    _this.editing = true;
                });
            };
            NDBEDPIntake.prototype.updateIntake = function () {
                var _this = this;
                this.datacontext.funding.updateNDBEDPIntake(this.intake)
                    .then(function () {
                    _this.editing = false;
                });
            };
            NDBEDPIntake.prototype.cancel = function () {
                var _this = this;
                this.getIntake().then(function () {
                    _this.editing = false;
                });
            };
            NDBEDPIntake.prototype.done = function () {
                this.common.$state.go('^.');
            };
            NDBEDPIntake.$inject = ['common', 'datacontext'];
            NDBEDPIntake = __decorate([
                app.Component('app.funding', 'ceNdbedpIntake', {
                    templateUrl: 'app/funding/enrollments/ndbedp/ndbedp-intake.html',
                    bindings: {
                        enrollmentId: '<'
                    }
                })
            ], NDBEDPIntake);
            return NDBEDPIntake;
        }());
    })(funding = app.funding || (app.funding = {}));
})(app || (app = {}));
var app;
(function (app) {
    var funding;
    (function (funding) {
        var NDBEDPOutcomeForm = (function () {
            function NDBEDPOutcomeForm(datacontext) {
                var _this = this;
                this.datacontext = datacontext;
                this.$onInit = function () {
                    _this.getServicesProvided();
                };
                'ngInject';
                'ngInject';
            }
            NDBEDPOutcomeForm.prototype.getServicesProvided = function () {
                var _this = this;
                return this.datacontext.config.lookups.getLookupType('ndbedp-services-provided')
                    .then(function (lookup) { _this.servicesProvided = lookup.lookups; });
            };
            NDBEDPOutcomeForm.$inject = ['datacontext'];
            NDBEDPOutcomeForm = __decorate([
                app.Component('app.funding', 'ceNdbedpOutcomeForm', {
                    templateUrl: 'app/funding/enrollments/ndbedp/ndbedp-outcome-form.html',
                    bindings: {
                        outcome: '<',
                        onCancel: '&',
                        onSubmit: '&'
                    }
                })
            ], NDBEDPOutcomeForm);
            return NDBEDPOutcomeForm;
        }());
    })(funding = app.funding || (app.funding = {}));
})(app || (app = {}));
var app;
(function (app) {
    var funding;
    (function (funding) {
        var TEPOutcomeInfo = (function () {
            function TEPOutcomeInfo(common, datacontext) {
                this.common = common;
                this.datacontext = datacontext;
                'ngInject';
                'ngInject';
            }
            TEPOutcomeInfo.$inject = ['common', 'datacontext'];
            TEPOutcomeInfo = __decorate([
                app.Component('app.funding', 'ceNdbedpOutcomeInfo', {
                    templateUrl: 'app/funding/enrollments/ndbedp/ndbedp-outcome-info.html',
                    bindings: {
                        outcome: '<',
                        onEdit: '&'
                    }
                })
            ], TEPOutcomeInfo);
            return TEPOutcomeInfo;
        }());
    })(funding = app.funding || (app.funding = {}));
})(app || (app = {}));
var app;
(function (app) {
    var funding;
    (function (funding) {
        var NDBEDPOutcome = (function () {
            function NDBEDPOutcome($mdDialog, common, datacontext) {
                this.$mdDialog = $mdDialog;
                this.common = common;
                this.datacontext = datacontext;
                this.outcome = {};
                'ngInject';
                'ngInject';
            }
            NDBEDPOutcome.prototype.$onInit = function () {
                this.getOutcome();
            };
            NDBEDPOutcome.prototype.getOutcome = function () {
                var _this = this;
                return this.datacontext.funding.getNDBEDPOutcome(this.enrollmentId).then(function (result) {
                    if (result) {
                        _this.outcome = result;
                    }
                    else {
                        _this.outcome.id = _this.enrollmentId;
                    }
                });
            };
            NDBEDPOutcome.prototype.edit = function () {
                var _this = this;
                this.getOutcome().then(function () { return _this.editing = true; });
            };
            NDBEDPOutcome.prototype.updateOutcome = function () {
                var _this = this;
                this.datacontext.funding.updateNDBEDPOutcome(this.outcome).then(function () {
                    _this.editing = false;
                });
            };
            NDBEDPOutcome.prototype.cancel = function () {
                var _this = this;
                this.getOutcome().then(function () {
                    _this.editing = false;
                });
            };
            NDBEDPOutcome.prototype.done = function () {
                this.common.$state.go('^.');
            };
            NDBEDPOutcome.$inject = ['$mdDialog', 'common', 'datacontext'];
            NDBEDPOutcome = __decorate([
                app.Component('app.funding', 'ceNdbedpOutcome', {
                    templateUrl: 'app/funding/enrollments/ndbedp/ndbedp-outcome.html',
                    bindings: {
                        enrollmentId: '<'
                    }
                })
            ], NDBEDPOutcome);
            return NDBEDPOutcome;
        }());
    })(funding = app.funding || (app.funding = {}));
})(app || (app = {}));
var app;
(function (app) {
    var funding;
    (function (funding) {
        var PAATIntakeForm = (function () {
            function PAATIntakeForm(common, datacontext) {
                this.common = common;
                this.datacontext = datacontext;
                'ngInject';
                'ngInject';
            }
            PAATIntakeForm.$inject = ['common', 'datacontext'];
            PAATIntakeForm = __decorate([
                app.Component('app.funding', 'cePaatIntakeForm', {
                    templateUrl: 'app/funding/enrollments/paat/paat-intake-form.html',
                    bindings: {
                        intake: '<',
                        onCancel: '&',
                        onSubmit: '&'
                    }
                })
            ], PAATIntakeForm);
            return PAATIntakeForm;
        }());
    })(funding = app.funding || (app.funding = {}));
})(app || (app = {}));
var app;
(function (app) {
    var funding;
    (function (funding) {
        var PABSSIntakeInfo = (function () {
            function PABSSIntakeInfo(common, datacontext) {
                this.common = common;
                this.datacontext = datacontext;
                'ngInject';
                'ngInject';
            }
            PABSSIntakeInfo.$inject = ['common', 'datacontext'];
            PABSSIntakeInfo = __decorate([
                app.Component('app.funding', 'cePaatIntakeInfo', {
                    templateUrl: 'app/funding/enrollments/paat/paat-intake-info.html',
                    bindings: {
                        intake: '<',
                        onEdit: '&'
                    }
                })
            ], PABSSIntakeInfo);
            return PABSSIntakeInfo;
        }());
    })(funding = app.funding || (app.funding = {}));
})(app || (app = {}));
var app;
(function (app) {
    var funding;
    (function (funding) {
        var PAATIntake = (function () {
            function PAATIntake(common, datacontext) {
                this.common = common;
                this.datacontext = datacontext;
                'ngInject';
                'ngInject';
            }
            PAATIntake.prototype.$onInit = function () {
                this.getIntake();
            };
            PAATIntake.prototype.getIntake = function () {
                var _this = this;
                return this.datacontext.funding.getPAATIntake(this.enrollmentId)
                    .then(function (intake) { if (intake)
                    _this.intake = intake; });
            };
            PAATIntake.prototype.edit = function () {
                var _this = this;
                this.getIntake().then(function () {
                    _this.editing = true;
                });
            };
            PAATIntake.prototype.updateIntake = function () {
                var _this = this;
                this.datacontext.funding.updatePAATIntake(this.intake)
                    .then(function () {
                    _this.editing = false;
                });
            };
            PAATIntake.prototype.cancel = function () {
                var _this = this;
                this.getIntake().then(function () {
                    _this.editing = false;
                });
            };
            PAATIntake.prototype.done = function () {
                this.common.$state.go('^.');
            };
            PAATIntake.$inject = ['common', 'datacontext'];
            PAATIntake = __decorate([
                app.Component('app.funding', 'cePaatIntake', {
                    templateUrl: 'app/funding/enrollments/paat/paat-intake.html',
                    bindings: {
                        enrollmentId: '<'
                    }
                })
            ], PAATIntake);
            return PAATIntake;
        }());
    })(funding = app.funding || (app.funding = {}));
})(app || (app = {}));
var app;
(function (app) {
    var funding;
    (function (funding) {
        var PAATOutcomeForm = (function () {
            function PAATOutcomeForm(datacontext) {
                this.datacontext = datacontext;
                'ngInject';
                'ngInject';
            }
            PAATOutcomeForm.prototype.$onInit = function () {
                this.getDevices();
                this.getServices();
                this.getInterventionStrategies();
            };
            PAATOutcomeForm.prototype.getDevices = function () {
                var _this = this;
                this.datacontext.config.lookups.getLookupType('paat-assistive-technology')
                    .then(function (assistiveTechnologies) { return _this.assistiveTechnologies = assistiveTechnologies.lookups; });
            };
            PAATOutcomeForm.prototype.getServices = function () {
                var _this = this;
                this.datacontext.config.lookups.getLookupType('paat-services')
                    .then(function (services) { return _this.services = services.lookups; });
            };
            PAATOutcomeForm.prototype.getInterventionStrategies = function () {
                var _this = this;
                return this.datacontext.config.lookups.getLookupType('paat-intervention-strategies')
                    .then(function (lookup) { _this.interventionStrategies = lookup.lookups; });
            };
            PAATOutcomeForm.$inject = ['datacontext'];
            PAATOutcomeForm = __decorate([
                app.Component('app.funding', 'cePaatOutcomeForm', {
                    templateUrl: 'app/funding/enrollments/paat/paat-outcome-form.html',
                    bindings: {
                        outcome: '<',
                        onCancel: '&',
                        onSubmit: '&'
                    }
                })
            ], PAATOutcomeForm);
            return PAATOutcomeForm;
        }());
    })(funding = app.funding || (app.funding = {}));
})(app || (app = {}));
var app;
(function (app) {
    var funding;
    (function (funding) {
        var PAATOutcomeInfo = (function () {
            function PAATOutcomeInfo(common, datacontext) {
                this.common = common;
                this.datacontext = datacontext;
                'ngInject';
                'ngInject';
            }
            PAATOutcomeInfo.$inject = ['common', 'datacontext'];
            PAATOutcomeInfo = __decorate([
                app.Component('app.funding', 'cePaatOutcomeInfo', {
                    templateUrl: 'app/funding/enrollments/paat/paat-outcome-info.html',
                    bindings: {
                        outcome: '<',
                        onEdit: '&'
                    }
                })
            ], PAATOutcomeInfo);
            return PAATOutcomeInfo;
        }());
    })(funding = app.funding || (app.funding = {}));
})(app || (app = {}));
var app;
(function (app) {
    var funding;
    (function (funding) {
        var PAATOutcome = (function () {
            function PAATOutcome(common, datacontext) {
                this.common = common;
                this.datacontext = datacontext;
                this.outcome = {};
                'ngInject';
                'ngInject';
            }
            PAATOutcome.prototype.$onInit = function () {
                this.getOutcome();
            };
            PAATOutcome.prototype.getOutcome = function () {
                var _this = this;
                return this.datacontext.funding.getPAATOutcome(this.enrollmentId).then(function (result) {
                    if (result) {
                        _this.outcome = result;
                    }
                    else {
                        _this.outcome.id = _this.enrollmentId;
                    }
                });
            };
            PAATOutcome.prototype.edit = function () {
                var _this = this;
                this.getOutcome().then(function () { return _this.editing = true; });
            };
            PAATOutcome.prototype.updateOutcome = function () {
                var _this = this;
                if (this.outcome.assistiveTechnology && this.outcome.assistiveTechnology.indexOf('Other') === -1) {
                    this.outcome.assistiveTechnologyOther = null;
                }
                this.datacontext.funding.updatePAATOutcome(this.outcome).then(function () {
                    _this.editing = false;
                });
            };
            PAATOutcome.prototype.cancel = function () {
                var _this = this;
                this.getOutcome().then(function () {
                    _this.editing = false;
                });
            };
            PAATOutcome.prototype.done = function () {
                this.common.$state.go('^.');
            };
            PAATOutcome.$inject = ['common', 'datacontext'];
            PAATOutcome = __decorate([
                app.Component('app.funding', 'cePaatOutcome', {
                    templateUrl: 'app/funding/enrollments/paat/paat-outcome.html',
                    bindings: {
                        enrollmentId: '<'
                    }
                })
            ], PAATOutcome);
            return PAATOutcome;
        }());
    })(funding = app.funding || (app.funding = {}));
})(app || (app = {}));
var app;
(function (app) {
    var funding;
    (function (funding) {
        var PABSSIntakeForm = (function () {
            function PABSSIntakeForm(common, datacontext) {
                this.common = common;
                this.datacontext = datacontext;
                'ngInject';
                'ngInject';
            }
            PABSSIntakeForm.$inject = ['common', 'datacontext'];
            PABSSIntakeForm = __decorate([
                app.Component('app.funding', 'cePabssIntakeForm', {
                    templateUrl: 'app/funding/enrollments/pabss/pabss-intake-form.html',
                    bindings: {
                        intake: '<',
                        onCancel: '&',
                        onSubmit: '&'
                    }
                })
            ], PABSSIntakeForm);
            return PABSSIntakeForm;
        }());
    })(funding = app.funding || (app.funding = {}));
})(app || (app = {}));
var app;
(function (app) {
    var funding;
    (function (funding) {
        var PABSSIntakeInfo = (function () {
            function PABSSIntakeInfo(common, datacontext) {
                this.common = common;
                this.datacontext = datacontext;
                'ngInject';
                'ngInject';
            }
            PABSSIntakeInfo.$inject = ['common', 'datacontext'];
            PABSSIntakeInfo = __decorate([
                app.Component('app.funding', 'cePabssIntakeInfo', {
                    templateUrl: 'app/funding/enrollments/pabss/pabss-intake-info.html',
                    bindings: {
                        intake: '<',
                        onEdit: '&'
                    }
                })
            ], PABSSIntakeInfo);
            return PABSSIntakeInfo;
        }());
    })(funding = app.funding || (app.funding = {}));
})(app || (app = {}));
var app;
(function (app) {
    var funding;
    (function (funding) {
        var PABSSIntake = (function () {
            function PABSSIntake(common, datacontext) {
                this.common = common;
                this.datacontext = datacontext;
                'ngInject';
                'ngInject';
            }
            PABSSIntake.prototype.$onInit = function () {
                this.getIntake();
            };
            PABSSIntake.prototype.getIntake = function () {
                var _this = this;
                return this.datacontext.funding.getPABSSIntake(this.enrollmentId)
                    .then(function (intake) { if (intake)
                    _this.intake = intake; });
            };
            PABSSIntake.prototype.edit = function () {
                var _this = this;
                this.getIntake().then(function () {
                    _this.editing = true;
                });
            };
            PABSSIntake.prototype.updateIntake = function () {
                var _this = this;
                if (this.intake.disability && this.intake.disability.indexOf('Other') === -1) {
                    this.intake.disabilityOther = null;
                }
                if (this.intake.livingArrangement && this.intake.livingArrangement.indexOf('Other') === -1) {
                    this.intake.livingArrangementOther = null;
                }
                if (this.intake.race && this.intake.race.indexOf('Other') === -1) {
                    this.intake.raceOther = null;
                }
                if (this.intake.majorConcern && this.intake.majorConcern.indexOf('Other') === -1) {
                    this.intake.majorConcernOther = null;
                }
                this.datacontext.funding.updatePABSSIntake(this.intake).then(function () {
                    _this.editing = false;
                });
            };
            PABSSIntake.prototype.cancel = function () {
                var _this = this;
                this.getIntake().then(function () {
                    _this.editing = false;
                });
            };
            PABSSIntake.prototype.done = function () {
                this.common.$state.go('^.');
            };
            PABSSIntake.$inject = ['common', 'datacontext'];
            PABSSIntake = __decorate([
                app.Component('app.funding', 'cePabssIntake', {
                    templateUrl: 'app/funding/enrollments/pabss/pabss-intake.html',
                    bindings: {
                        enrollmentId: '<'
                    }
                })
            ], PABSSIntake);
            return PABSSIntake;
        }());
    })(funding = app.funding || (app.funding = {}));
})(app || (app = {}));
var app;
(function (app) {
    var funding;
    (function (funding) {
        var PABSSOutcomeForm = (function () {
            function PABSSOutcomeForm(common, datacontext) {
                this.common = common;
                this.datacontext = datacontext;
                'ngInject';
                'ngInject';
            }
            PABSSOutcomeForm.$inject = ['common', 'datacontext'];
            PABSSOutcomeForm = __decorate([
                app.Component('app.funding', 'cePabssOutcomeForm', {
                    templateUrl: 'app/funding/enrollments/pabss/pabss-outcome-form.html',
                    bindings: {
                        outcome: '<',
                        onCancel: '&',
                        onSubmit: '&'
                    }
                })
            ], PABSSOutcomeForm);
            return PABSSOutcomeForm;
        }());
    })(funding = app.funding || (app.funding = {}));
})(app || (app = {}));
var app;
(function (app) {
    var funding;
    (function (funding) {
        var PABSSOutcomeInfo = (function () {
            function PABSSOutcomeInfo(common, datacontext) {
                this.common = common;
                this.datacontext = datacontext;
                'ngInject';
                'ngInject';
            }
            PABSSOutcomeInfo.$inject = ['common', 'datacontext'];
            PABSSOutcomeInfo = __decorate([
                app.Component('app.funding', 'cePabssOutcomeInfo', {
                    templateUrl: 'app/funding/enrollments/pabss/pabss-outcome-info.html',
                    bindings: {
                        outcome: '<',
                        onEdit: '&'
                    }
                })
            ], PABSSOutcomeInfo);
            return PABSSOutcomeInfo;
        }());
    })(funding = app.funding || (app.funding = {}));
})(app || (app = {}));
var app;
(function (app) {
    var funding;
    (function (funding) {
        var PABSSOutcome = (function () {
            function PABSSOutcome(common, datacontext) {
                this.common = common;
                this.datacontext = datacontext;
                this.outcome = {};
                'ngInject';
                'ngInject';
            }
            PABSSOutcome.prototype.$onInit = function () {
                this.getOutcome();
            };
            PABSSOutcome.prototype.getOutcome = function () {
                var _this = this;
                return this.datacontext.funding.getPABSSOutcome(this.enrollmentId).then(function (result) {
                    if (result) {
                        _this.outcome = result;
                    }
                    else {
                        _this.outcome.id = _this.enrollmentId;
                    }
                });
            };
            PABSSOutcome.prototype.edit = function () {
                var _this = this;
                this.getOutcome().then(function () { return _this.editing = true; });
            };
            PABSSOutcome.prototype.updateOutcome = function () {
                var _this = this;
                this.datacontext.funding.updatePABSSOutcome(this.outcome).then(function () {
                    _this.editing = false;
                });
            };
            PABSSOutcome.prototype.cancel = function () {
                var _this = this;
                this.getOutcome().then(function () {
                    _this.editing = false;
                });
            };
            PABSSOutcome.prototype.done = function () {
                this.common.$state.go('^.');
            };
            PABSSOutcome.$inject = ['common', 'datacontext'];
            PABSSOutcome = __decorate([
                app.Component('app.funding', 'cePabssOutcome', {
                    templateUrl: 'app/funding/enrollments/pabss/pabss-outcome.html',
                    bindings: {
                        enrollmentId: '<'
                    }
                })
            ], PABSSOutcome);
            return PABSSOutcome;
        }());
    })(funding = app.funding || (app.funding = {}));
})(app || (app = {}));
var app;
(function (app) {
    var funding;
    (function (funding) {
        var PADDIntakeForm = (function () {
            function PADDIntakeForm(common, datacontext) {
                this.common = common;
                this.datacontext = datacontext;
                'ngInject';
                'ngInject';
            }
            PADDIntakeForm.$inject = ['common', 'datacontext'];
            PADDIntakeForm = __decorate([
                app.Component('app.funding', 'cePaddIntakeForm', {
                    templateUrl: 'app/funding/enrollments/padd/padd-intake-form.html',
                    bindings: {
                        intake: '<',
                        onCancel: '&',
                        onSubmit: '&'
                    }
                })
            ], PADDIntakeForm);
            return PADDIntakeForm;
        }());
    })(funding = app.funding || (app.funding = {}));
})(app || (app = {}));
var app;
(function (app) {
    var funding;
    (function (funding) {
        var PADDIntakeInfo = (function () {
            function PADDIntakeInfo(common, datacontext) {
                this.common = common;
                this.datacontext = datacontext;
                'ngInject';
                'ngInject';
            }
            PADDIntakeInfo.$inject = ['common', 'datacontext'];
            PADDIntakeInfo = __decorate([
                app.Component('app.funding', 'cePaddIntakeInfo', {
                    templateUrl: 'app/funding/enrollments/padd/padd-intake-info.html',
                    bindings: {
                        intake: '<',
                        onEdit: '&'
                    }
                })
            ], PADDIntakeInfo);
            return PADDIntakeInfo;
        }());
    })(funding = app.funding || (app.funding = {}));
})(app || (app = {}));
var app;
(function (app) {
    var funding;
    (function (funding) {
        var PADDIntake = (function () {
            function PADDIntake(common, datacontext) {
                this.common = common;
                this.datacontext = datacontext;
                'ngInject';
                'ngInject';
            }
            PADDIntake.prototype.$onInit = function () {
                this.getIntake();
            };
            PADDIntake.prototype.getIntake = function () {
                var _this = this;
                return this.datacontext.funding.getPADDIntake(this.enrollmentId)
                    .then(function (intake) { if (intake)
                    _this.intake = intake; });
            };
            PADDIntake.prototype.edit = function () {
                var _this = this;
                this.getIntake().then(function () {
                    _this.editing = true;
                });
            };
            PADDIntake.prototype.updateIntake = function () {
                var _this = this;
                this.datacontext.funding.updatePADDIntake(this.intake).then(function () {
                    _this.editing = false;
                });
            };
            PADDIntake.prototype.cancel = function () {
                var _this = this;
                this.getIntake().then(function () {
                    _this.editing = false;
                });
            };
            PADDIntake.prototype.done = function () {
                this.common.$state.go('^.');
            };
            PADDIntake.$inject = ['common', 'datacontext'];
            PADDIntake = __decorate([
                app.Component('app.funding', 'cePaddIntake', {
                    templateUrl: 'app/funding/enrollments/padd/padd-intake.html',
                    bindings: {
                        enrollmentId: '<'
                    }
                })
            ], PADDIntake);
            return PADDIntake;
        }());
    })(funding = app.funding || (app.funding = {}));
})(app || (app = {}));
var app;
(function (app) {
    var funding;
    (function (funding) {
        var PADDOutcomeForm = (function () {
            function PADDOutcomeForm(common, datacontext) {
                this.common = common;
                this.datacontext = datacontext;
                'ngInject';
                'ngInject';
            }
            PADDOutcomeForm.prototype.$onInit = function () {
                this.getInterventionStrategies();
            };
            PADDOutcomeForm.prototype.getInterventionStrategies = function () {
                var _this = this;
                return this.datacontext.config.lookups.getLookupType('padd-intervention-strategies')
                    .then(function (lookup) { _this.interventionStrategies = lookup.lookups; });
            };
            PADDOutcomeForm.$inject = ['common', 'datacontext'];
            PADDOutcomeForm = __decorate([
                app.Component('app.funding', 'cePaddOutcomeForm', {
                    templateUrl: 'app/funding/enrollments/padd/padd-outcome-form.html',
                    bindings: {
                        outcome: '<',
                        onCancel: '&',
                        onSubmit: '&'
                    }
                })
            ], PADDOutcomeForm);
            return PADDOutcomeForm;
        }());
    })(funding = app.funding || (app.funding = {}));
})(app || (app = {}));
var app;
(function (app) {
    var funding;
    (function (funding) {
        var PADDOutcomeInfo = (function () {
            function PADDOutcomeInfo(common, datacontext) {
                this.common = common;
                this.datacontext = datacontext;
                'ngInject';
                'ngInject';
            }
            PADDOutcomeInfo.$inject = ['common', 'datacontext'];
            PADDOutcomeInfo = __decorate([
                app.Component('app.funding', 'cePaddOutcomeInfo', {
                    templateUrl: 'app/funding/enrollments/padd/padd-outcome-info.html',
                    bindings: {
                        outcome: '<',
                        onEdit: '&'
                    }
                })
            ], PADDOutcomeInfo);
            return PADDOutcomeInfo;
        }());
    })(funding = app.funding || (app.funding = {}));
})(app || (app = {}));
var app;
(function (app) {
    var funding;
    (function (funding) {
        var PADDOutcome = (function () {
            function PADDOutcome($mdDialog, common, datacontext) {
                this.$mdDialog = $mdDialog;
                this.common = common;
                this.datacontext = datacontext;
                this.outcome = {};
                'ngInject';
                'ngInject';
            }
            PADDOutcome.prototype.$onInit = function () {
                this.getOutcome();
            };
            PADDOutcome.prototype.getOutcome = function () {
                var _this = this;
                return this.datacontext.funding.getPADDOutcome(this.enrollmentId).then(function (result) {
                    if (result) {
                        _this.outcome = result;
                    }
                    else {
                        _this.outcome.id = _this.enrollmentId;
                    }
                });
            };
            PADDOutcome.prototype.edit = function () {
                var _this = this;
                this.getOutcome().then(function () { return _this.editing = true; });
            };
            PADDOutcome.prototype.updateOutcome = function () {
                var _this = this;
                this.datacontext.funding.updatePADDOutcome(this.outcome).then(function () {
                    _this.editing = false;
                });
            };
            PADDOutcome.prototype.cancel = function () {
                var _this = this;
                this.getOutcome().then(function () {
                    _this.editing = false;
                });
            };
            PADDOutcome.prototype.done = function () {
                this.common.$state.go('^.');
            };
            PADDOutcome.$inject = ['$mdDialog', 'common', 'datacontext'];
            PADDOutcome = __decorate([
                app.Component('app.funding', 'cePaddOutcome', {
                    templateUrl: 'app/funding/enrollments/padd/padd-outcome.html',
                    bindings: {
                        enrollmentId: '<'
                    }
                })
            ], PADDOutcome);
            return PADDOutcome;
        }());
    })(funding = app.funding || (app.funding = {}));
})(app || (app = {}));
var app;
(function (app) {
    var funding;
    (function (funding) {
        var PAIMIIntakeForm = (function () {
            function PAIMIIntakeForm(common, datacontext) {
                this.common = common;
                this.datacontext = datacontext;
                this.intake = {};
                this.illnesses = [];
                this.approvedIllnesses = [];
                'ngInject';
                'ngInject';
            }
            PAIMIIntakeForm.prototype.showDischargeStatus = function () {
                if (this.intake.livingArrangement === 'Parental, Guardian or other Family Home' || this.intake.livingArrangement === 'Independent') {
                    return true;
                }
                else {
                    return false;
                }
            };
            PAIMIIntakeForm.prototype.$onInit = function () {
                this.loadIllnesses();
            };
            PAIMIIntakeForm.prototype.approvedAttorneySearch = function (searchText) {
                return this.datacontext.users
                    .getUsers(searchText);
            };
            PAIMIIntakeForm.prototype.setApprovedAttorney = function (attorney) {
                this.intake.approvedBy = attorney.fullName;
            };
            PAIMIIntakeForm.prototype.mentalIllnessToggle = function (mentalIllness) {
                if (this.mentalIllnessExists(mentalIllness)) {
                    _.pull(this.intake.mentalIllnesses, mentalIllness);
                }
                else {
                    this.intake.mentalIllnesses.push(mentalIllness);
                }
            };
            PAIMIIntakeForm.prototype.mentalIllnessExists = function (mentalIllness) {
                return _.includes(this.intake.mentalIllnesses, mentalIllness);
            };
            PAIMIIntakeForm.prototype.approvedIllnessToggle = function (mentalIllness) {
                if (this.approvedIllnessExists(mentalIllness)) {
                    _.pull(this.intake.approvedMentalIllnesses, mentalIllness);
                }
                else {
                    this.intake.approvedMentalIllnesses.push(mentalIllness);
                }
            };
            PAIMIIntakeForm.prototype.approvedIllnessExists = function (mentalIllness) {
                return _.includes(this.intake.approvedMentalIllnesses, mentalIllness);
            };
            PAIMIIntakeForm.prototype.loadIllnesses = function () {
                this.illnesses.push({ key: 'Anxiety', value: 'Anxiety disorders' });
                this.illnesses.push({ key: 'Bipolar', value: 'Bipolar and related disorders' });
                this.illnesses.push({ key: 'Depressive', value: 'Depressive disorders' });
                this.illnesses.push({ key: 'Disruptive', value: 'Disruptive, impulse control, and conduct disorders' });
                this.illnesses.push({ key: 'Dissociative', value: 'Dissociative disorders' });
                this.illnesses.push({ key: 'Feeding', value: 'Feeding and eating disorders that are psychologically based' });
                this.illnesses.push({ key: 'Neurocognitive', value: 'Neurocognitive disorders (including Dementia/Alzheimer\'s)' });
                this.illnesses.push({ key: 'OCD', value: 'Obsessive-compulsive and related disorders' });
                this.illnesses.push({ key: 'Personality', value: 'Personality disorders' });
                this.illnesses.push({ key: 'Schizophrenia', value: 'Schizophrenia and related disorders' });
                this.illnesses.push({ key: 'Substance', value: 'Substance-related and addictive disorders (must be 18 or over)' });
                this.illnesses.push({ key: 'Trauma', value: 'Trauma and stressor-related disorders' });
                this.illnesses.push({ key: 'Other', value: 'Other mental disorders included in DSM (Somatic symptom and related disorders, elimination disorders, sleep-wake disorders, sexual dysfunctions, gender dysphoria, and paraphilic disorders)' });
                this.approvedIllnesses.push({ key: 'Neurodevelopmental', value: 'Neurodevelopmental disorders not covered by PADD or PAIR (this may included ADHD depending upon severity of behavior)' });
                this.approvedIllnesses.push({ key: 'Other', value: 'Other mental disorder (other specified or unspecified mental disorder due to another medical condition or other specified or unspecified mental disorder)(includes "emotional disturbance")' });
            };
            PAIMIIntakeForm.$inject = ['common', 'datacontext'];
            PAIMIIntakeForm = __decorate([
                app.Component('app.funding', 'cePaimiIntakeForm', {
                    templateUrl: 'app/funding/enrollments/paimi/paimi-intake-form.html',
                    bindings: {
                        intake: '<',
                        onCancel: '&',
                        onSubmit: '&'
                    }
                })
            ], PAIMIIntakeForm);
            return PAIMIIntakeForm;
        }());
    })(funding = app.funding || (app.funding = {}));
})(app || (app = {}));
var app;
(function (app) {
    var funding;
    (function (funding) {
        var PAIMIIntakeInfo = (function () {
            function PAIMIIntakeInfo(common, datacontext) {
                this.common = common;
                this.datacontext = datacontext;
                'ngInject';
                'ngInject';
            }
            PAIMIIntakeInfo.$inject = ['common', 'datacontext'];
            PAIMIIntakeInfo = __decorate([
                app.Component('app.funding', 'cePaimiIntakeInfo', {
                    templateUrl: 'app/funding/enrollments/paimi/paimi-intake-info.html',
                    bindings: {
                        intake: '<',
                        onEdit: '&'
                    }
                })
            ], PAIMIIntakeInfo);
            return PAIMIIntakeInfo;
        }());
    })(funding = app.funding || (app.funding = {}));
})(app || (app = {}));
var app;
(function (app) {
    var funding;
    (function (funding) {
        var PAIMIIntake = (function () {
            function PAIMIIntake(common, datacontext) {
                this.common = common;
                this.datacontext = datacontext;
                'ngInject';
                'ngInject';
            }
            PAIMIIntake.prototype.$onInit = function () {
                this.getIntake();
            };
            PAIMIIntake.prototype.getIntake = function () {
                var _this = this;
                return this.datacontext.funding.getPAIMIIntake(this.enrollmentId)
                    .then(function (intake) { if (intake)
                    _this.intake = intake; });
            };
            PAIMIIntake.prototype.edit = function () {
                var _this = this;
                this.getIntake().then(function () {
                    _this.editing = true;
                });
            };
            PAIMIIntake.prototype.updateIntake = function () {
                var _this = this;
                if (this.intake.livingArrangement && this.intake.livingArrangement.indexOf('Other') === -1) {
                    this.intake.livingArrangementOther = null;
                }
                if (this.intake.race && this.intake.race.indexOf('More') === -1) {
                    this.intake.raceOther = null;
                }
                if (this.intake.determinationDate && typeof this.intake.determinationDate !== 'string') {
                    this.intake.determinationDate = this.intake.determinationDate.toDateString();
                }
                if (this.intake.documentationRequestedDate && typeof this.intake.documentationRequestedDate !== 'string') {
                    this.intake.documentationRequestedDate = this.intake.documentationRequestedDate.toDateString();
                }
                if (this.intake.documentationReceivedDate && typeof this.intake.documentationReceivedDate !== 'string') {
                    this.intake.documentationReceivedDate = this.intake.documentationReceivedDate.toDateString();
                }
                if (this.intake.deathReporter && this.intake.deathReporter.indexOf('Other') === -1) {
                    this.intake.deathReporterOther = null;
                }
                this.datacontext.funding.updatePAIMIIntake(this.intake).then(function () {
                    _this.editing = false;
                    _this.getIntake();
                });
            };
            PAIMIIntake.prototype.cancel = function () {
                var _this = this;
                this.getIntake().then(function () {
                    _this.editing = false;
                });
            };
            PAIMIIntake.prototype.done = function () {
                this.common.$state.go('^.');
            };
            PAIMIIntake.$inject = ['common', 'datacontext'];
            PAIMIIntake = __decorate([
                app.Component('app.funding', 'cePaimiIntake', {
                    templateUrl: 'app/funding/enrollments/paimi/paimi-intake.html',
                    bindings: {
                        enrollmentId: '<'
                    }
                })
            ], PAIMIIntake);
            return PAIMIIntake;
        }());
    })(funding = app.funding || (app.funding = {}));
})(app || (app = {}));
var app;
(function (app) {
    var funding;
    (function (funding) {
        var PAIMIOutcomeForm = (function () {
            function PAIMIOutcomeForm(common, datacontext) {
                this.common = common;
                this.datacontext = datacontext;
                'ngInject';
                'ngInject';
            }
            PAIMIOutcomeForm.prototype.$onInit = function () {
                this.getPAIMIOptions();
            };
            PAIMIOutcomeForm.prototype.getPAIMIOptions = function () {
                var _this = this;
                this.datacontext.funding.getPAIMIProblemAndInterventionOptions()
                    .then(function (results) { return _this.problemAndInterventionOptions = results; });
                this.datacontext.funding.getPAIMICaseOutcomeOptions()
                    .then(function (results) { return _this.CaseOutcomeOptions = results; });
            };
            PAIMIOutcomeForm.prototype.problemAndInterventionChanged = function (problem) {
                var index = _.findIndex(this.outcome.problemsAndInterventions, function (p) { return p.problemId === problem.id; });
                if (index === -1) {
                    this.outcome.problemsAndInterventions.push({ problemId: problem.id, group: problem.group, problemText: problem.text, outcomeText: problem.text, outcomes: [] });
                }
                else {
                    _.remove(this.outcome.problemsAndInterventions, function (p) { return p.problemId === problem.id; });
                }
            };
            PAIMIOutcomeForm.prototype.problemAndInterventionSelected = function (problemId) {
                return _.findIndex(this.outcome.problemsAndInterventions, function (p) { return p.problemId === problemId; }) > -1;
            };
            PAIMIOutcomeForm.prototype.outcomeChanged = function (problemId, outcome) {
                var p = _.find(this.outcome.problemsAndInterventions, function (p) { return p.problemId === problemId; });
                var o = _.find(p.outcomes, function (o) { return o.outcomeId === outcome.id; });
                if (o == null) {
                    p.outcomes.push({ outcomeId: outcome.id, outcomeText: outcome.text });
                }
                else {
                    _.pull(p.outcomes, o);
                }
            };
            PAIMIOutcomeForm.prototype.outcomeSelected = function (problemId, outcomeId) {
                var p = _.find(this.outcome.problemsAndInterventions, function (p) { return p.problemId === problemId; });
                var o = _.findIndex(p.outcomes, function (o) { return o.outcomeId === outcomeId; });
                return o > -1;
            };
            PAIMIOutcomeForm.prototype.dispositionSelected = function (problemId, disposition) {
                var p = _.find(this.outcome.problemsAndInterventions, function (p) { return p.problemId === problemId; });
                p.dispositionId = disposition.id;
                p.dispositionText = disposition.text;
            };
            PAIMIOutcomeForm.prototype.getProblem = function (problemId) {
                var p = _.find(this.outcome.problemsAndInterventions, function (p) { return p.problemId === problemId; });
                return p;
            };
            PAIMIOutcomeForm.prototype.problemAreasOtherSelected = function () {
                var otherProblem = _.find(this.problemAndInterventionOptions, function (p) { return p.text === 'Other'; });
                var index = _.findIndex(this.outcome.problemsAndInterventions, function (p) { return p.problemId === otherProblem.id; });
                return index > -1;
            };
            PAIMIOutcomeForm.$inject = ['common', 'datacontext'];
            PAIMIOutcomeForm = __decorate([
                app.Component('app.funding', 'cePaimiOutcomeForm', {
                    templateUrl: 'app/funding/enrollments/paimi/paimi-outcome-form.html',
                    bindings: {
                        outcome: '<',
                        onCancel: '&',
                        onSubmit: '&'
                    }
                })
            ], PAIMIOutcomeForm);
            return PAIMIOutcomeForm;
        }());
    })(funding = app.funding || (app.funding = {}));
})(app || (app = {}));
var app;
(function (app) {
    var funding;
    (function (funding) {
        var PAIMIOutcomeInfo = (function () {
            function PAIMIOutcomeInfo(common, datacontext) {
                this.common = common;
                this.datacontext = datacontext;
                'ngInject';
                'ngInject';
            }
            PAIMIOutcomeInfo.$inject = ['common', 'datacontext'];
            PAIMIOutcomeInfo = __decorate([
                app.Component('app.funding', 'cePaimiOutcomeInfo', {
                    templateUrl: 'app/funding/enrollments/paimi/paimi-outcome-info.html',
                    bindings: {
                        outcome: '<',
                        onEdit: '&'
                    }
                })
            ], PAIMIOutcomeInfo);
            return PAIMIOutcomeInfo;
        }());
    })(funding = app.funding || (app.funding = {}));
})(app || (app = {}));
var app;
(function (app) {
    var funding;
    (function (funding) {
        var PAIMIOutcome = (function () {
            function PAIMIOutcome($mdDialog, common, datacontext) {
                this.$mdDialog = $mdDialog;
                this.common = common;
                this.datacontext = datacontext;
                this.outcome = {};
                'ngInject';
                'ngInject';
            }
            PAIMIOutcome.prototype.$onInit = function () {
                this.outcome.problemsAndInterventions = [];
                this.outcome.id = this.enrollmentId;
                this.getOutcome();
            };
            PAIMIOutcome.prototype.getOutcome = function () {
                var _this = this;
                return this.datacontext.funding.getPAIMIOutcome(this.enrollmentId).then(function (result) {
                    if (result) {
                        _this.outcome = result;
                    }
                });
            };
            PAIMIOutcome.prototype.edit = function () {
                var _this = this;
                this.getOutcome().then(function () { return _this.editing = true; });
            };
            PAIMIOutcome.prototype.updateOutcome = function () {
                var _this = this;
                this.datacontext.funding.updatePAIMIOutcome(this.outcome).then(function () {
                    _this.editing = false;
                });
            };
            PAIMIOutcome.prototype.cancel = function () {
                var _this = this;
                this.getOutcome().then(function () {
                    _this.editing = false;
                });
            };
            PAIMIOutcome.prototype.done = function () {
                this.common.$state.go('^.');
            };
            PAIMIOutcome.$inject = ['$mdDialog', 'common', 'datacontext'];
            PAIMIOutcome = __decorate([
                app.Component('app.funding', 'cePaimiOutcome', {
                    templateUrl: 'app/funding/enrollments/paimi/paimi-outcome.html',
                    bindings: {
                        enrollmentId: '<'
                    }
                })
            ], PAIMIOutcome);
            return PAIMIOutcome;
        }());
    })(funding = app.funding || (app.funding = {}));
})(app || (app = {}));
var app;
(function (app) {
    var funding;
    (function (funding) {
        var PATBIIntakeForm = (function () {
            function PATBIIntakeForm(common, datacontext) {
                this.common = common;
                this.datacontext = datacontext;
                'ngInject';
                'ngInject';
            }
            PATBIIntakeForm.$inject = ['common', 'datacontext'];
            PATBIIntakeForm = __decorate([
                app.Component('app.funding', 'cePatbiIntakeForm', {
                    templateUrl: 'app/funding/enrollments/patbi/patbi-intake-form.html',
                    bindings: {
                        intake: '<',
                        onCancel: '&',
                        onSubmit: '&'
                    }
                })
            ], PATBIIntakeForm);
            return PATBIIntakeForm;
        }());
    })(funding = app.funding || (app.funding = {}));
})(app || (app = {}));
var app;
(function (app) {
    var funding;
    (function (funding) {
        var PATBIIntakeInfo = (function () {
            function PATBIIntakeInfo(common, datacontext) {
                this.common = common;
                this.datacontext = datacontext;
                'ngInject';
                'ngInject';
            }
            PATBIIntakeInfo.$inject = ['common', 'datacontext'];
            PATBIIntakeInfo = __decorate([
                app.Component('app.funding', 'cePatbiIntakeInfo', {
                    templateUrl: 'app/funding/enrollments/patbi/patbi-intake-info.html',
                    bindings: {
                        intake: '<',
                        onEdit: '&'
                    }
                })
            ], PATBIIntakeInfo);
            return PATBIIntakeInfo;
        }());
    })(funding = app.funding || (app.funding = {}));
})(app || (app = {}));
var app;
(function (app) {
    var funding;
    (function (funding) {
        var PATBIIntake = (function () {
            function PATBIIntake(common, datacontext) {
                this.common = common;
                this.datacontext = datacontext;
                'ngInject';
                'ngInject';
            }
            PATBIIntake.prototype.$onInit = function () {
                this.getIntake();
            };
            PATBIIntake.prototype.getIntake = function () {
                var _this = this;
                return this.datacontext.funding.getPATBIIntake(this.enrollmentId)
                    .then(function (intake) { if (intake)
                    _this.intake = intake; });
            };
            PATBIIntake.prototype.edit = function () {
                var _this = this;
                this.getIntake().then(function () {
                    _this.editing = true;
                });
            };
            PATBIIntake.prototype.updateIntake = function () {
                var _this = this;
                this.datacontext.funding.updatePATBIIntake(this.intake).then(function () {
                    _this.editing = false;
                });
            };
            PATBIIntake.prototype.cancel = function () {
                var _this = this;
                this.getIntake().then(function () {
                    _this.editing = false;
                });
            };
            PATBIIntake.prototype.done = function () {
                this.common.$state.go('^.');
            };
            PATBIIntake.$inject = ['common', 'datacontext'];
            PATBIIntake = __decorate([
                app.Component('app.funding', 'cePatbiIntake', {
                    templateUrl: 'app/funding/enrollments/patbi/patbi-intake.html',
                    bindings: {
                        enrollmentId: '<'
                    }
                })
            ], PATBIIntake);
            return PATBIIntake;
        }());
    })(funding = app.funding || (app.funding = {}));
})(app || (app = {}));
var app;
(function (app) {
    var funding;
    (function (funding) {
        var PATBIOutcomeForm = (function () {
            function PATBIOutcomeForm(common, datacontext) {
                this.common = common;
                this.datacontext = datacontext;
                'ngInject';
                'ngInject';
            }
            PATBIOutcomeForm.prototype.$onInit = function () {
                this.getInterventionStrategies();
            };
            PATBIOutcomeForm.prototype.getInterventionStrategies = function () {
                var _this = this;
                return this.datacontext.config.lookups.getLookupType('patbi-intervention-strategies')
                    .then(function (lookup) { _this.interventionStrategies = lookup.lookups; });
            };
            PATBIOutcomeForm.$inject = ['common', 'datacontext'];
            PATBIOutcomeForm = __decorate([
                app.Component('app.funding', 'cePatbiOutcomeForm', {
                    templateUrl: 'app/funding/enrollments/patbi/patbi-outcome-form.html',
                    bindings: {
                        outcome: '<',
                        onCancel: '&',
                        onSubmit: '&'
                    }
                })
            ], PATBIOutcomeForm);
            return PATBIOutcomeForm;
        }());
    })(funding = app.funding || (app.funding = {}));
})(app || (app = {}));
var app;
(function (app) {
    var funding;
    (function (funding) {
        var PATBIOutcomeInfo = (function () {
            function PATBIOutcomeInfo(common, datacontext) {
                this.common = common;
                this.datacontext = datacontext;
                'ngInject';
                'ngInject';
            }
            PATBIOutcomeInfo.$inject = ['common', 'datacontext'];
            PATBIOutcomeInfo = __decorate([
                app.Component('app.funding', 'cePatbiOutcomeInfo', {
                    templateUrl: 'app/funding/enrollments/patbi/patbi-outcome-info.html',
                    bindings: {
                        outcome: '<',
                        onEdit: '&'
                    }
                })
            ], PATBIOutcomeInfo);
            return PATBIOutcomeInfo;
        }());
    })(funding = app.funding || (app.funding = {}));
})(app || (app = {}));
var app;
(function (app) {
    var funding;
    (function (funding) {
        var PATBIOutcome = (function () {
            function PATBIOutcome(common, datacontext) {
                this.common = common;
                this.datacontext = datacontext;
                this.outcome = {};
                'ngInject';
                'ngInject';
            }
            PATBIOutcome.prototype.$onInit = function () {
                this.getOutcome();
            };
            PATBIOutcome.prototype.getOutcome = function () {
                var _this = this;
                return this.datacontext.funding.getPATBIOutcome(this.enrollmentId).then(function (result) {
                    if (result) {
                        _this.outcome = result;
                    }
                    else {
                        _this.outcome.id = _this.enrollmentId;
                    }
                });
            };
            PATBIOutcome.prototype.edit = function () {
                var _this = this;
                this.getOutcome().then(function () { return _this.editing = true; });
            };
            PATBIOutcome.prototype.updateOutcome = function () {
                var _this = this;
                this.datacontext.funding.updatePATBIOutcome(this.outcome).then(function () {
                    _this.editing = false;
                });
            };
            PATBIOutcome.prototype.cancel = function () {
                var _this = this;
                this.getOutcome().then(function () {
                    _this.editing = false;
                });
            };
            PATBIOutcome.prototype.done = function () {
                this.common.$state.go('^.');
            };
            PATBIOutcome.$inject = ['common', 'datacontext'];
            PATBIOutcome = __decorate([
                app.Component('app.funding', 'cePatbiOutcome', {
                    templateUrl: 'app/funding/enrollments/patbi/patbi-outcome.html',
                    bindings: {
                        enrollmentId: '<'
                    }
                })
            ], PATBIOutcome);
            return PATBIOutcome;
        }());
    })(funding = app.funding || (app.funding = {}));
})(app || (app = {}));
var app;
(function (app) {
    var funding;
    (function (funding) {
        var PAIRIntakeForm = (function () {
            function PAIRIntakeForm(common, datacontext) {
                this.common = common;
                this.datacontext = datacontext;
                this.problems = [];
                'ngInject';
                'ngInject';
            }
            PAIRIntakeForm.prototype.$onInit = function () {
                this.loadProblems();
            };
            PAIRIntakeForm.prototype.loadProblems = function () {
                this.problems.push({ key: 'Abuse', value: 'Involves such issues as inappropriate or excessive physical restraint, isolation or seclusion; inappropriate or excessive physical restraint, isolation or seclusion; inappropriate or excessive medication; involuntary sterilization; failure to provide appropriatemedical treatment; physical or sexual assault; threats of retaliation by facility staff; coercion; suspicious death, etc. ' });
                this.problems.push({ key: 'Access To Records', value: 'Individuals who were denied access to their records for some reason related to their disabilities.' });
                this.problems.push({ key: 'Architectural Accessibility', value: 'Complaints related to architectural barriers or other building accessibility problems. These complaints could relate to any aspect of accessibility to the building or structure, including the adjoining parking lot or garage, building entrance, interior rooms, signage, etc.  Include complaints for both public and private buildings/structures.' });
                this.problems.push({ key: 'Assistive Technology', value: 'Issues that generally involve the individual\'s access to needed assistive technology, such as augmentative communication devices, durable medical equipment, vehicle modifications, etc.' });
                this.problems.push({ key: 'Education', value: 'Issues such as IEP planning, development or implementation; inclusive settings in the schools; availability of assistive technology and interpreter services and devices in the schools; discipline, suspension or expulsion; and other education-related issues.  DO NOT INCLUDE complaints relating to building accessibility or program access of the school program.' });
                this.problems.push({ key: 'Employment', value: 'Issues such as hiring or termination, reasonable accommodations, supported employment, or employment benefits.' });
                this.problems.push({ key: 'Neglect', value: 'Involves issues such as the failure to provide appropriate diagnostic evaluations, personal care, written habilitation plan, adequate discharge planning, etc.' });
                this.problems.push({ key: 'Government Benefits/Services', value: 'Include individuals who had difficulty securing equal access to a government service or who have problems with government financial benefits programs, welfare-to-work programs, and other government programs.' });
                this.problems.push({ key: 'Health Care', value: 'Includes issues such as Medicaid problems; access to or denial of coverage for medical insurance; access to medical or treatment services or managed care, etc.' });
                this.problems.push({ key: 'Housing', value: 'Issues involving contracts/property ownership, zoning or restrictive covenants, reasonable accommodations, landlord/tenant, and subsidized or section 8 housing.' });
                this.problems.push({ key: 'Insurance', value: 'Include denial of private problemAreas or life insurance coverage due to the individual\'s problemAreas.DO NOT INCLUDE issues related to medical insurance because those complaints should be included under Health Care.' });
                this.problems.push({ key: 'Privacy Rights', value: 'These issues involve some invasion of the individual\'s rights of privacy, such as the release of medical information without the individual\'s consent.' });
                this.problems.push({ key: 'Program Access', value: 'Individuals who were denied equal access to a program due to their disabilities.  An example may include an individual who is not allowed to participate in a YMCA program due to a particular problemAreas.  DO NOT INCLUDE complaints that relate to the accessibility of the building in which the program is housed.' });
                this.problems.push({ key: 'Non-Government Services', value: 'Involves inability to receive non-government services, such as problems with obtaining interpreter services for medical visits; access to menus in alternative formats; obtaining needed personal assistance services, etc. DO NOT INCLUDE any issues involving services paid for with government (federal, state or local) funds.' });
                this.problems.push({ key: 'Transportation', value: 'Issues such as the accessibility or availability of accessible public transportation.' });
                this.problems.push({ key: 'Voting', value: 'Include voting issues, such as the accessibility of the voting progress for individuals with disabilities.' });
                this.problems.push({ key: 'Other', value: 'Include assistance for complaints not otherwise covered by the list of problem areas provided below.  Examples may include criminal actions, commitment to institutions, guardianship/conservatorship, wills, etc.  ' });
            };
            PAIRIntakeForm.$inject = ['common', 'datacontext'];
            PAIRIntakeForm = __decorate([
                app.Component('app.funding', 'cePairIntakeForm', {
                    templateUrl: 'app/funding/enrollments/pair/pair-intake-form.html',
                    bindings: {
                        intake: '<',
                        onCancel: '&',
                        onSubmit: '&'
                    }
                })
            ], PAIRIntakeForm);
            return PAIRIntakeForm;
        }());
    })(funding = app.funding || (app.funding = {}));
})(app || (app = {}));
var app;
(function (app) {
    var funding;
    (function (funding) {
        var PAIRIntakeInfo = (function () {
            function PAIRIntakeInfo(common, datacontext) {
                this.common = common;
                this.datacontext = datacontext;
                'ngInject';
                'ngInject';
            }
            PAIRIntakeInfo.$inject = ['common', 'datacontext'];
            PAIRIntakeInfo = __decorate([
                app.Component('app.funding', 'cePairIntakeInfo', {
                    templateUrl: 'app/funding/enrollments/pair/pair-intake-info.html',
                    bindings: {
                        intake: '<',
                        onEdit: '&'
                    }
                })
            ], PAIRIntakeInfo);
            return PAIRIntakeInfo;
        }());
    })(funding = app.funding || (app.funding = {}));
})(app || (app = {}));
var app;
(function (app) {
    var funding;
    (function (funding) {
        var PAIRIntake = (function () {
            function PAIRIntake(common, datacontext) {
                this.common = common;
                this.datacontext = datacontext;
                'ngInject';
                'ngInject';
            }
            PAIRIntake.prototype.$onInit = function () {
                this.getIntake();
            };
            PAIRIntake.prototype.getIntake = function () {
                var _this = this;
                return this.datacontext.funding.getPAIRIntake(this.enrollmentId)
                    .then(function (intake) { if (intake)
                    _this.intake = intake; });
            };
            PAIRIntake.prototype.edit = function () {
                var _this = this;
                this.getIntake().then(function () {
                    _this.editing = true;
                });
            };
            PAIRIntake.prototype.updateIntake = function () {
                var _this = this;
                if (this.intake.disability && this.intake.disability.indexOf('Other') === -1) {
                    this.intake.disabilityOther = null;
                }
                if (this.intake.livingArrangement && this.intake.livingArrangement.indexOf('Other') === -1) {
                    this.intake.livingArrangementOther = null;
                }
                if (this.intake.problemAreas && this.intake.problemAreas.indexOf('Other') === -1) {
                    this.intake.problemAreasOther = null;
                }
                this.datacontext.funding.updatePAIRIntake(this.intake).then(function () {
                    _this.editing = false;
                });
            };
            PAIRIntake.prototype.cancel = function () {
                var _this = this;
                this.getIntake().then(function () {
                    _this.editing = false;
                });
            };
            PAIRIntake.prototype.done = function () {
                this.common.$state.go('^.');
            };
            PAIRIntake.$inject = ['common', 'datacontext'];
            PAIRIntake = __decorate([
                app.Component('app.funding', 'cePairIntake', {
                    templateUrl: 'app/funding/enrollments/pair/pair-intake.html',
                    bindings: {
                        enrollmentId: '<'
                    }
                })
            ], PAIRIntake);
            return PAIRIntake;
        }());
    })(funding = app.funding || (app.funding = {}));
})(app || (app = {}));
var app;
(function (app) {
    var funding;
    (function (funding) {
        var PAIROutcome = (function () {
            function PAIROutcome(common, datacontext) {
                this.common = common;
                this.datacontext = datacontext;
                'ngInject';
                'ngInject';
            }
            PAIROutcome.$inject = ['common', 'datacontext'];
            PAIROutcome = __decorate([
                app.Component('app.funding', 'cePairOutcomeForm', {
                    templateUrl: 'app/funding/enrollments/pair/pair-outcome-form.html',
                    bindings: {
                        outcome: '<',
                        onCancel: '&',
                        onSubmit: '&'
                    }
                })
            ], PAIROutcome);
            return PAIROutcome;
        }());
    })(funding = app.funding || (app.funding = {}));
})(app || (app = {}));
var app;
(function (app) {
    var funding;
    (function (funding) {
        var PAIROutcomeInfo = (function () {
            function PAIROutcomeInfo(common, datacontext) {
                this.common = common;
                this.datacontext = datacontext;
                'ngInject';
                'ngInject';
            }
            PAIROutcomeInfo.$inject = ['common', 'datacontext'];
            PAIROutcomeInfo = __decorate([
                app.Component('app.funding', 'cePairOutcomeInfo', {
                    templateUrl: 'app/funding/enrollments/pair/pair-outcome-info.html',
                    bindings: {
                        outcome: '<',
                        onEdit: '&'
                    }
                })
            ], PAIROutcomeInfo);
            return PAIROutcomeInfo;
        }());
    })(funding = app.funding || (app.funding = {}));
})(app || (app = {}));
var app;
(function (app) {
    var funding;
    (function (funding) {
        var PAIROutcome = (function () {
            function PAIROutcome($mdDialog, common, datacontext) {
                this.$mdDialog = $mdDialog;
                this.common = common;
                this.datacontext = datacontext;
                this.outcome = {};
                'ngInject';
                'ngInject';
            }
            PAIROutcome.prototype.$onInit = function () {
                this.getOutcome();
            };
            PAIROutcome.prototype.getOutcome = function () {
                var _this = this;
                return this.datacontext.funding.getPAIROutcome(this.enrollmentId).then(function (result) {
                    if (result) {
                        _this.outcome = result;
                    }
                    else {
                        _this.outcome.id = _this.enrollmentId;
                    }
                });
            };
            PAIROutcome.prototype.edit = function () {
                var _this = this;
                this.getOutcome().then(function () { return _this.editing = true; });
            };
            PAIROutcome.prototype.updateOutcome = function () {
                var _this = this;
                this.datacontext.funding.updatePAIROutcome(this.outcome).then(function () {
                    _this.editing = false;
                });
            };
            PAIROutcome.prototype.cancel = function () {
                var _this = this;
                this.getOutcome().then(function () {
                    _this.editing = false;
                });
            };
            PAIROutcome.prototype.done = function () {
                this.common.$state.go('^.');
            };
            PAIROutcome.$inject = ['$mdDialog', 'common', 'datacontext'];
            PAIROutcome = __decorate([
                app.Component('app.funding', 'cePairOutcome', {
                    templateUrl: 'app/funding/enrollments/pair/pair-outcome.html',
                    bindings: {
                        enrollmentId: '<'
                    }
                })
            ], PAIROutcome);
            return PAIROutcome;
        }());
    })(funding = app.funding || (app.funding = {}));
})(app || (app = {}));
var app;
(function (app) {
    var funding;
    (function (funding) {
        var PAVAIntakeForm = (function () {
            function PAVAIntakeForm(common, datacontext) {
                this.common = common;
                this.datacontext = datacontext;
                'ngInject';
                'ngInject';
            }
            PAVAIntakeForm.$inject = ['common', 'datacontext'];
            PAVAIntakeForm = __decorate([
                app.Component('app.funding', 'cePavaIntakeForm', {
                    templateUrl: 'app/funding/enrollments/pava/pava-intake-form.html',
                    bindings: {
                        intake: '<',
                        onCancel: '&',
                        onSubmit: '&'
                    }
                })
            ], PAVAIntakeForm);
            return PAVAIntakeForm;
        }());
    })(funding = app.funding || (app.funding = {}));
})(app || (app = {}));
var app;
(function (app) {
    var funding;
    (function (funding) {
        var PAVAIntakeInfo = (function () {
            function PAVAIntakeInfo(common, datacontext) {
                this.common = common;
                this.datacontext = datacontext;
                'ngInject';
                'ngInject';
            }
            PAVAIntakeInfo.$inject = ['common', 'datacontext'];
            PAVAIntakeInfo = __decorate([
                app.Component('app.funding', 'cePavaIntakeInfo', {
                    templateUrl: 'app/funding/enrollments/pava/pava-intake-info.html',
                    bindings: {
                        intake: '<',
                        onEdit: '&'
                    }
                })
            ], PAVAIntakeInfo);
            return PAVAIntakeInfo;
        }());
    })(funding = app.funding || (app.funding = {}));
})(app || (app = {}));
var app;
(function (app) {
    var funding;
    (function (funding) {
        var PAVAIntake = (function () {
            function PAVAIntake(common, datacontext) {
                this.common = common;
                this.datacontext = datacontext;
                'ngInject';
                'ngInject';
            }
            PAVAIntake.prototype.$onInit = function () {
                this.getIntake();
            };
            PAVAIntake.prototype.getIntake = function () {
                var _this = this;
                return this.datacontext.funding.getPAVAIntake(this.enrollmentId)
                    .then(function (intake) { if (intake)
                    _this.intake = intake; });
            };
            PAVAIntake.prototype.edit = function () {
                var _this = this;
                this.getIntake().then(function () {
                    _this.editing = true;
                });
            };
            PAVAIntake.prototype.updateIntake = function () {
                var _this = this;
                this.datacontext.funding.updatePAVAIntake(this.intake).then(function () {
                    _this.editing = false;
                });
            };
            PAVAIntake.prototype.cancel = function () {
                var _this = this;
                this.getIntake().then(function () {
                    _this.editing = false;
                });
            };
            PAVAIntake.prototype.done = function () {
                this.common.$state.go('^.');
            };
            PAVAIntake.$inject = ['common', 'datacontext'];
            PAVAIntake = __decorate([
                app.Component('app.funding', 'cePavaIntake', {
                    templateUrl: 'app/funding/enrollments/pava/pava-intake.html',
                    bindings: {
                        enrollmentId: '<'
                    }
                })
            ], PAVAIntake);
            return PAVAIntake;
        }());
    })(funding = app.funding || (app.funding = {}));
})(app || (app = {}));
var app;
(function (app) {
    var funding;
    (function (funding) {
        var PAVAOutcomeForm = (function () {
            function PAVAOutcomeForm(common, datacontext) {
                this.common = common;
                this.datacontext = datacontext;
                'ngInject';
                'ngInject';
            }
            PAVAOutcomeForm.prototype.$onInit = function () {
                this.getInterventionStrategies();
            };
            PAVAOutcomeForm.prototype.getInterventionStrategies = function () {
                var _this = this;
                return this.datacontext.config.lookups.getLookupType('pava-intervention-strategies')
                    .then(function (lookup) { _this.interventionStrategies = lookup.lookups; });
            };
            PAVAOutcomeForm.$inject = ['common', 'datacontext'];
            PAVAOutcomeForm = __decorate([
                app.Component('app.funding', 'cePavaOutcomeForm', {
                    templateUrl: 'app/funding/enrollments/pava/pava-outcome-form.html',
                    bindings: {
                        outcome: '<',
                        onCancel: '&',
                        onSubmit: '&'
                    }
                })
            ], PAVAOutcomeForm);
            return PAVAOutcomeForm;
        }());
    })(funding = app.funding || (app.funding = {}));
})(app || (app = {}));
var app;
(function (app) {
    var funding;
    (function (funding) {
        var PAVAOutcomeInfo = (function () {
            function PAVAOutcomeInfo(common, datacontext) {
                this.common = common;
                this.datacontext = datacontext;
                'ngInject';
                'ngInject';
            }
            PAVAOutcomeInfo.$inject = ['common', 'datacontext'];
            PAVAOutcomeInfo = __decorate([
                app.Component('app.funding', 'cePavaOutcomeInfo', {
                    templateUrl: 'app/funding/enrollments/pava/pava-outcome-info.html',
                    bindings: {
                        outcome: '<',
                        onEdit: '&'
                    }
                })
            ], PAVAOutcomeInfo);
            return PAVAOutcomeInfo;
        }());
    })(funding = app.funding || (app.funding = {}));
})(app || (app = {}));
var app;
(function (app) {
    var funding;
    (function (funding) {
        var PAVAOutcome = (function () {
            function PAVAOutcome($mdDialog, common, datacontext) {
                this.$mdDialog = $mdDialog;
                this.common = common;
                this.datacontext = datacontext;
                this.outcome = {};
                'ngInject';
                'ngInject';
            }
            PAVAOutcome.prototype.$onInit = function () {
                this.getOutcome();
            };
            PAVAOutcome.prototype.getOutcome = function () {
                var _this = this;
                return this.datacontext.funding.getPAVAOutcome(this.enrollmentId).then(function (result) {
                    if (result) {
                        _this.outcome = result;
                    }
                    else {
                        _this.outcome.id = _this.enrollmentId;
                    }
                });
            };
            PAVAOutcome.prototype.edit = function () {
                var _this = this;
                this.getOutcome().then(function () { return _this.editing = true; });
            };
            PAVAOutcome.prototype.updateOutcome = function () {
                var _this = this;
                this.outcome.caseType = 'Advocacy';
                this.datacontext.funding.updatePAVAOutcome(this.outcome).then(function () {
                    _this.editing = false;
                });
            };
            PAVAOutcome.prototype.cancel = function () {
                var _this = this;
                this.getOutcome().then(function () {
                    _this.editing = false;
                });
            };
            PAVAOutcome.prototype.done = function () {
                this.common.$state.go('^.');
            };
            PAVAOutcome.$inject = ['$mdDialog', 'common', 'datacontext'];
            PAVAOutcome = __decorate([
                app.Component('app.funding', 'cePavaOutcome', {
                    templateUrl: 'app/funding/enrollments/pava/pava-outcome.html',
                    bindings: {
                        enrollmentId: '<'
                    }
                })
            ], PAVAOutcome);
            return PAVAOutcome;
        }());
    })(funding = app.funding || (app.funding = {}));
})(app || (app = {}));
var app;
(function (app) {
    var funding;
    (function (funding) {
        var TEPIntakeForm = (function () {
            function TEPIntakeForm(common, datacontext) {
                this.common = common;
                this.datacontext = datacontext;
                'ngInject';
                'ngInject';
            }
            TEPIntakeForm.$inject = ['common', 'datacontext'];
            TEPIntakeForm = __decorate([
                app.Component('app.funding', 'ceTepIntakeForm', {
                    templateUrl: 'app/funding/enrollments/tep/tep-intake-form.html',
                    bindings: {
                        intake: '<',
                        onCancel: '&',
                        onSubmit: '&'
                    }
                })
            ], TEPIntakeForm);
            return TEPIntakeForm;
        }());
    })(funding = app.funding || (app.funding = {}));
})(app || (app = {}));
var app;
(function (app) {
    var funding;
    (function (funding) {
        var TEPIntakeInfo = (function () {
            function TEPIntakeInfo(common, datacontext) {
                this.common = common;
                this.datacontext = datacontext;
                'ngInject';
                'ngInject';
            }
            TEPIntakeInfo.$inject = ['common', 'datacontext'];
            TEPIntakeInfo = __decorate([
                app.Component('app.funding', 'ceTepIntakeInfo', {
                    templateUrl: 'app/funding/enrollments/tep/tep-intake-info.html',
                    bindings: {
                        intake: '<',
                        onEdit: '&'
                    }
                })
            ], TEPIntakeInfo);
            return TEPIntakeInfo;
        }());
    })(funding = app.funding || (app.funding = {}));
})(app || (app = {}));
var app;
(function (app) {
    var funding;
    (function (funding) {
        var TEPIntake = (function () {
            function TEPIntake(common, datacontext) {
                this.common = common;
                this.datacontext = datacontext;
                'ngInject';
                'ngInject';
            }
            TEPIntake.prototype.$onInit = function () {
                this.getIntake();
            };
            TEPIntake.prototype.getIntake = function () {
                var _this = this;
                return this.datacontext.funding.getTEPIntake(this.enrollmentId)
                    .then(function (intake) { if (intake)
                    _this.intake = intake; });
            };
            TEPIntake.prototype.edit = function () {
                var _this = this;
                this.getIntake().then(function () {
                    _this.editing = true;
                });
            };
            TEPIntake.prototype.updateIntake = function () {
                var _this = this;
                this.datacontext.funding.updateTEPIntake(this.intake)
                    .then(function () {
                    _this.editing = false;
                });
            };
            TEPIntake.prototype.cancel = function () {
                var _this = this;
                this.getIntake().then(function () {
                    _this.editing = false;
                });
            };
            TEPIntake.prototype.done = function () {
                this.common.$state.go('^.');
            };
            TEPIntake.$inject = ['common', 'datacontext'];
            TEPIntake = __decorate([
                app.Component('app.funding', 'ceTepIntake', {
                    templateUrl: 'app/funding/enrollments/tep/tep-intake.html',
                    bindings: {
                        enrollmentId: '<'
                    }
                })
            ], TEPIntake);
            return TEPIntake;
        }());
    })(funding = app.funding || (app.funding = {}));
})(app || (app = {}));
var app;
(function (app) {
    var funding;
    (function (funding) {
        var TEPOutcomeForm = (function () {
            function TEPOutcomeForm(datacontext) {
                var _this = this;
                this.datacontext = datacontext;
                this.$onInit = function () {
                    _this.getServicesProvided();
                };
                'ngInject';
                'ngInject';
            }
            TEPOutcomeForm.prototype.getServicesProvided = function () {
                var _this = this;
                return this.datacontext.config.lookups.getLookupType('tep-services-provided')
                    .then(function (lookup) { _this.servicesProvided = lookup.lookups; });
            };
            TEPOutcomeForm.$inject = ['datacontext'];
            TEPOutcomeForm = __decorate([
                app.Component('app.funding', 'ceTepOutcomeForm', {
                    templateUrl: 'app/funding/enrollments/tep/tep-outcome-form.html',
                    bindings: {
                        outcome: '<',
                        onCancel: '&',
                        onSubmit: '&'
                    }
                })
            ], TEPOutcomeForm);
            return TEPOutcomeForm;
        }());
    })(funding = app.funding || (app.funding = {}));
})(app || (app = {}));
var app;
(function (app) {
    var funding;
    (function (funding) {
        var TEPOutcomeInfo = (function () {
            function TEPOutcomeInfo(common, datacontext) {
                this.common = common;
                this.datacontext = datacontext;
                'ngInject';
                'ngInject';
            }
            TEPOutcomeInfo.$inject = ['common', 'datacontext'];
            TEPOutcomeInfo = __decorate([
                app.Component('app.funding', 'ceTepOutcomeInfo', {
                    templateUrl: 'app/funding/enrollments/tep/tep-outcome-info.html',
                    bindings: {
                        outcome: '<',
                        onEdit: '&'
                    }
                })
            ], TEPOutcomeInfo);
            return TEPOutcomeInfo;
        }());
    })(funding = app.funding || (app.funding = {}));
})(app || (app = {}));
var app;
(function (app) {
    var funding;
    (function (funding) {
        var TEPOutcome = (function () {
            function TEPOutcome($mdDialog, common, datacontext) {
                this.$mdDialog = $mdDialog;
                this.common = common;
                this.datacontext = datacontext;
                this.outcome = {};
                'ngInject';
                'ngInject';
            }
            TEPOutcome.prototype.$onInit = function () {
                this.getOutcome();
            };
            TEPOutcome.prototype.getOutcome = function () {
                var _this = this;
                return this.datacontext.funding.getTEPOutcome(this.enrollmentId).then(function (result) {
                    if (result) {
                        _this.outcome = result;
                    }
                    else {
                        _this.outcome.id = _this.enrollmentId;
                    }
                });
            };
            TEPOutcome.prototype.edit = function () {
                var _this = this;
                this.getOutcome().then(function () { return _this.editing = true; });
            };
            TEPOutcome.prototype.updateOutcome = function () {
                var _this = this;
                this.datacontext.funding.updateTEPOutcome(this.outcome).then(function () {
                    _this.editing = false;
                });
            };
            TEPOutcome.prototype.cancel = function () {
                var _this = this;
                this.getOutcome().then(function () {
                    _this.editing = false;
                });
            };
            TEPOutcome.prototype.done = function () {
                this.common.$state.go('^.');
            };
            TEPOutcome.$inject = ['$mdDialog', 'common', 'datacontext'];
            TEPOutcome = __decorate([
                app.Component('app.funding', 'ceTepOutcome', {
                    templateUrl: 'app/funding/enrollments/tep/tep-outcome.html',
                    bindings: {
                        enrollmentId: '<'
                    }
                })
            ], TEPOutcome);
            return TEPOutcome;
        }());
    })(funding = app.funding || (app.funding = {}));
})(app || (app = {}));
var app;
(function (app) {
    var funding;
    (function (funding) {
        var MultipleChoiceFundingForm = (function () {
            function MultipleChoiceFundingForm(common, datacontext) {
                var _this = this;
                this.common = common;
                this.datacontext = datacontext;
                this.getFundingSources = function () {
                    return _this.datacontext.funding
                        .getFundingPrograms(true, _this.source)
                        .then(function (programs) { return _this.fundingPrograms = programs; });
                };
                'ngInject';
                'ngInject';
            }
            MultipleChoiceFundingForm.prototype.$onInit = function () {
                this.getFundingSources();
            };
            MultipleChoiceFundingForm.prototype.toggle = function (fundingProgram) {
                var program = this.exists(fundingProgram);
                if (program) {
                    _.pull(this.selectedFundingPrograms, program);
                }
                else {
                    this.selectedFundingPrograms.push(fundingProgram);
                }
            };
            MultipleChoiceFundingForm.prototype.exists = function (fundingProgram) {
                var exists = _.find(this.selectedFundingPrograms, function (sfp) { return sfp.fundingProgramId === fundingProgram.fundingProgramId; });
                return exists;
            };
            MultipleChoiceFundingForm.prototype.submit = function () {
                this.onSubmit({ result: this.selectedFundingPrograms });
            };
            MultipleChoiceFundingForm.$inject = ['common', 'datacontext'];
            MultipleChoiceFundingForm = __decorate([
                app.Component('app.funding', 'ceMultipleChoiceFundingForm', {
                    templateUrl: 'app/funding/multiple-choice/edit/edit.html',
                    bindings: {
                        selectedFundingPrograms: '<',
                        onCancel: '&',
                        onSubmit: '&',
                        source: '<'
                    }
                })
            ], MultipleChoiceFundingForm);
            return MultipleChoiceFundingForm;
        }());
    })(funding = app.funding || (app.funding = {}));
})(app || (app = {}));
var app;
(function (app) {
    var funding;
    (function (funding) {
        var MultipleChoiceFundingView = (function () {
            function MultipleChoiceFundingView() {
            }
            MultipleChoiceFundingView = __decorate([
                app.Component('app.funding', 'ceMultipleChoiceFundingView', {
                    templateUrl: 'app/funding/multiple-choice/view/view.html',
                    bindings: {
                        fundingPrograms: '<',
                        onEdit: '&'
                    }
                })
            ], MultipleChoiceFundingView);
            return MultipleChoiceFundingView;
        }());
    })(funding = app.funding || (app.funding = {}));
})(app || (app = {}));
var app;
(function (app) {
    var funding;
    (function (funding) {
        var EditSecondaryFunding = (function () {
            function EditSecondaryFunding($mdDialog, common, datacontext) {
                var _this = this;
                this.$mdDialog = $mdDialog;
                this.common = common;
                this.datacontext = datacontext;
                this.enrollment = {};
                this.getEligibleFundingSources = function () {
                    return _this.datacontext.funding
                        .getEnrollmentEligibilityView(_this.involvementId)
                        .then(function (result) {
                        _this.fundingPrograms = _.filter(result, function (r) {
                            return _.includes(['PADD', 'PAIMI', 'PAIR'], r.fundingProgramName);
                        });
                    });
                };
                'ngInject';
                'ngInject';
            }
            EditSecondaryFunding.prototype.$onInit = function () {
                this.getEligibleFundingSources();
                this.enrollment.involvementId = this.involvementId;
            };
            EditSecondaryFunding.prototype.submit = function () {
                var _this = this;
                this.datacontext.funding.setSecondaryFundingSource(this.enrollment)
                    .then(function (result) { _this.common.$state.go('^.'); });
            };
            EditSecondaryFunding.$inject = ['$mdDialog', 'common', 'datacontext'];
            EditSecondaryFunding = __decorate([
                app.Component('app.funding', 'ceEditSecondaryFunding', {
                    templateUrl: 'app/funding/secondary/edit/edit.html',
                    bindings: {
                        involvementId: '<',
                        fundingProgramId: '<'
                    }
                })
            ], EditSecondaryFunding);
            return EditSecondaryFunding;
        }());
    })(funding = app.funding || (app.funding = {}));
})(app || (app = {}));
var app;
(function (app) {
    var funding;
    (function (funding) {
        var SecondaryFundingView = (function () {
            function SecondaryFundingView($mdDialog, common, datacontext) {
                var _this = this;
                this.$mdDialog = $mdDialog;
                this.common = common;
                this.datacontext = datacontext;
                this.$onInit = function () {
                    _this.getFundingSummaryView();
                };
                this.getFundingSummaryView = function () {
                    return _this.datacontext.funding
                        .getFundingSummaryView(_this.involvementId)
                        .then(function (result) {
                        _this.fundingSummary = result;
                    });
                };
                'ngInject';
                'ngInject';
            }
            SecondaryFundingView.prototype.showDeleteDialog = function () {
                var _this = this;
                this.common.confirmation.show({
                    title: 'Delete Secondary Funding Source?',
                    message: 'Are you sure you want to delete this Funding Source?',
                    ok: 'Delete',
                    cancel: 'Keep'
                }).then(function () { return _this.deleteSecondaryFunder(_this.involvementId); });
            };
            SecondaryFundingView.prototype.deleteSecondaryFunder = function (id) {
                var _this = this;
                return this.datacontext.funding.deleteSecondaryFundingSource(id)
                    .then(function () { return _this.common.$state.reload(); })
                    .then(function () { return _this.common.$state.go('^'); });
            };
            SecondaryFundingView.$inject = ['$mdDialog', 'common', 'datacontext'];
            SecondaryFundingView = __decorate([
                app.Component('app.funding', 'ceSecondaryFundingView', {
                    templateUrl: 'app/funding/secondary/view/view.html',
                    bindings: {
                        involvementId: '<'
                    }
                })
            ], SecondaryFundingView);
            return SecondaryFundingView;
        }());
    })(funding = app.funding || (app.funding = {}));
})(app || (app = {}));
var app;
(function (app) {
    var funding;
    (function (funding) {
        var SingleChoiceFundingForm = (function () {
            function SingleChoiceFundingForm(common, datacontext) {
                var _this = this;
                this.common = common;
                this.datacontext = datacontext;
                this.getFundingSources = function () {
                    return _this.datacontext.funding
                        .getFundingPrograms(true, _this.source)
                        .then(function (programs) { return _this.fundingPrograms = programs; });
                };
                'ngInject';
                'ngInject';
            }
            SingleChoiceFundingForm.prototype.$onInit = function () {
                this.getFundingSources();
            };
            SingleChoiceFundingForm.prototype.submit = function () {
                this.onSubmit({ result: this.selectedFundingProgram });
            };
            SingleChoiceFundingForm.$inject = ['common', 'datacontext'];
            SingleChoiceFundingForm = __decorate([
                app.Component('app.funding', 'ceSingleChoiceFundingForm', {
                    templateUrl: 'app/funding/single-choice/edit/edit.html',
                    bindings: {
                        fundingProgram: '<',
                        onCancel: '&',
                        onSubmit: '&',
                        source: '@'
                    }
                })
            ], SingleChoiceFundingForm);
            return SingleChoiceFundingForm;
        }());
    })(funding = app.funding || (app.funding = {}));
})(app || (app = {}));
var app;
(function (app) {
    var funding;
    (function (funding) {
        var SingleChoiceFundingView = (function () {
            function SingleChoiceFundingView(common, datacontext) {
                this.common = common;
                this.datacontext = datacontext;
                this.$onChange = function () {
                };
                'ngInject';
                'ngInject';
            }
            SingleChoiceFundingView.$inject = ['common', 'datacontext'];
            SingleChoiceFundingView = __decorate([
                app.Component('app.funding', 'ceSingleChoiceFundingView', {
                    templateUrl: 'app/funding/single-choice/view/view.html',
                    bindings: {
                        fundingProgram: '<',
                        onEdit: '&'
                    }
                })
            ], SingleChoiceFundingView);
            return SingleChoiceFundingView;
        }());
    })(funding = app.funding || (app.funding = {}));
})(app || (app = {}));
var app;
(function (app) {
    var identity;
    (function (identity) {
        var UserLookup = (function () {
            function UserLookup(datacontext) {
                this.datacontext = datacontext;
                'ngInject';
                'ngInject';
            }
            UserLookup.prototype.getUsers = function (q) {
                return this.datacontext.identity.getUsers({ q: q });
            };
            UserLookup.$inject = ['datacontext'];
            UserLookup = __decorate([
                app.Component('app.identity', 'aimsUserLookup', {
                    templateUrl: 'app/identity/components/user-lookup/user-lookup.html',
                    bindings: {
                        label: '@',
                        inputName: '@',
                        required: '=',
                        value: '='
                    },
                    require: {
                        form: '^'
                    }
                })
            ], UserLookup);
            return UserLookup;
        }());
    })(identity = app.identity || (app.identity = {}));
})(app || (app = {}));
var app;
(function (app) {
    var informationAndReferrals;
    (function (informationAndReferrals) {
        var activities;
        (function (activities) {
            var InformationAndReferralActivityList = (function () {
                function InformationAndReferralActivityList($mdDialog, common, datacontext) {
                    var _this = this;
                    this.$mdDialog = $mdDialog;
                    this.common = common;
                    this.datacontext = datacontext;
                    this.activityFilter = {};
                    this.showFilters = false;
                    this.selectedActivityTypes = [];
                    this.paging = {
                        page: 1,
                        pageSize: 100
                    };
                    this.refresh = function () {
                        _this.getActivities();
                    };
                    'ngInject';
                    'ngInject';
                }
                InformationAndReferralActivityList.prototype.$onInit = function () {
                    this.showCovidVaccine = (this.showCovidVaccine || false).toString() === 'true';
                    this.activityFilter.involvementId = this.case.involvementId;
                    this.activityFilter.sourceId = this.case.id;
                    this.activityFilter.source = 'I&R';
                    this.getActivities();
                };
                InformationAndReferralActivityList.prototype.getActivities = function () {
                    var _this = this;
                    if (!this.filtersInitialized()) {
                        return;
                    }
                    if (!this.showInvolvementActivities) {
                        this.activityFilter.sourceId = this.case.id;
                        this.activityFilter.source = 'I&R';
                    }
                    else {
                        this.activityFilter.sourceId = null;
                        this.activityFilter.source = null;
                    }
                    this.activityFilter = __assign(__assign(__assign({}, this.activityFilter), this.paging), { activityTypes: this.selectedActivityTypes, q: this.noteFilter });
                    this.promise = this.datacontext.activities
                        .searchActivities(this.activityFilter)
                        .then(function (result) {
                        _this.activities = result;
                    });
                };
                InformationAndReferralActivityList.prototype.getInvolvementActivities = function () {
                    this.showInvolvementActivities = !this.showInvolvementActivities;
                    this.getActivities();
                };
                InformationAndReferralActivityList.prototype.newActivity = function (targetEvent) {
                    var parent = angular.element(document.body);
                    this.$mdDialog.show({
                        parent: parent,
                        targetEvent: targetEvent,
                        clickOutsideToClose: true,
                        templateUrl: 'app/information-and-referrals/activities/new-activity/new-activity.html',
                        controller: 'NewInformationAndReferralActivityDialogController',
                        controllerAs: '$ctrl',
                        locals: {
                            caseview: this.case,
                            vaccineFeatureFlag: this.showCovidVaccine
                        }
                    });
                };
                InformationAndReferralActivityList.prototype.toggleFilters = function () {
                    this.showFilters = !this.showFilters;
                };
                InformationAndReferralActivityList.prototype.filtersInitialized = function () {
                    return this.activityTypeFilterInitialized;
                };
                InformationAndReferralActivityList.prototype.activityTypeFilterChanged = function (activityTypes) {
                    this.selectedActivityTypes = activityTypes;
                    this.activityTypeFilterInitialized = true;
                    this.getActivities();
                };
                InformationAndReferralActivityList.prototype.onEdit = function (activity) {
                    switch (activity.activityType) {
                        case 'Note':
                            this.common.$state.go('^.note', { activity: activity });
                            break;
                        case 'CovidVaccine':
                            this.common.$state.go('^.covidVaccine', { activityId: activity.id });
                            break;
                        case 'StatusChange':
                            this.common.$state.go('^.statusChange', { activityId: activity.id });
                            break;
                    }
                };
                InformationAndReferralActivityList.$inject = ['$mdDialog', 'common', 'datacontext'];
                InformationAndReferralActivityList = __decorate([
                    app.Component('app.informationAndReferrals.activities', 'ceInformationAndReferralActivityList', {
                        templateUrl: 'app/information-and-referrals/activities/activity-list/activity-list.html',
                        bindings: {
                            case: '<',
                            showCovidVaccine: '<'
                        }
                    })
                ], InformationAndReferralActivityList);
                return InformationAndReferralActivityList;
            }());
        })(activities = informationAndReferrals.activities || (informationAndReferrals.activities = {}));
    })(informationAndReferrals = app.informationAndReferrals || (app.informationAndReferrals = {}));
})(app || (app = {}));
var app;
(function (app) {
    var informationAndReferrals;
    (function (informationAndReferrals) {
        var activities;
        (function (activities) {
            var Close = (function () {
                function Close(common, datacontext) {
                    this.common = common;
                    this.datacontext = datacontext;
                    this.statuses = [];
                    this.isSubmitting = false;
                    this.activity = {};
                    'ngInject';
                    'ngInject';
                }
                Close.prototype.$onInit = function () {
                    this.activity.involvementId = this.case.involvementId;
                    this.activity.caseId = this.case.id;
                    this.activity.activityDate = moment().toDate();
                    this.bypassClosureReasons = (this.bypassClosureReasons || false).toString() === 'true';
                    this.maxDate = this.activity.activityDate;
                    this.getInformationAndReferralsCaseStatus();
                };
                Close.prototype.getInformationAndReferralsCaseStatus = function () {
                    var _this = this;
                    return this.datacontext.informationAndReferrals.getInformationAndReferralStatus(this.case.id).then(function (result) {
                        var data = result;
                        if (data.status) {
                            _this.selectedStatus = data.status;
                            _this.activity.statusDescription = data.statusDescription;
                        }
                    });
                };
                Close.prototype.submit = function () {
                    var _this = this;
                    this.isSubmitting = true;
                    if (typeof this.activity.activityDate !== 'string') {
                        this.activity.activityDate = this.activity.activityDate.toDateString();
                    }
                    if (this.bypassClosureReasons) {
                        this.selectedStatus = 'Closed';
                    }
                    this.activity.status = this.selectedStatus || 'Closed';
                    this.activity.statusDescription = null;
                    this.datacontext.informationAndReferrals
                        .close(this.activity)
                        .then(function (request) {
                        if (request) {
                            _this.common.$mdToast.showSimple('I&R Closed');
                            _this.common.$rootScope.$broadcast('refreshBasicInfo');
                            _this.case.isActive = false;
                            _this.common.$state.go('^.list', { caseId: _this.case.id, involvementId: _this.case.involvementId });
                        }
                    })
                        .finally(function () { return _this.isSubmitting = false; });
                };
                Close.$inject = ['common', 'datacontext'];
                Close = __decorate([
                    app.Component('app.informationAndReferrals.activities', 'ceInformationAndReferralActivitiesClose', {
                        templateUrl: 'app/information-and-referrals/activities/close/close.html',
                        bindings: {
                            case: '<',
                            bypassClosureReasons: '<'
                        }
                    })
                ], Close);
                return Close;
            }());
        })(activities = informationAndReferrals.activities || (informationAndReferrals.activities = {}));
    })(informationAndReferrals = app.informationAndReferrals || (app.informationAndReferrals = {}));
})(app || (app = {}));
var app;
(function (app) {
    var informationAndReferrals;
    (function (informationAndReferrals) {
        var activities;
        (function (activities_4) {
            var activities = [
                {
                    icon: 'history',
                    text: 'Conflict Check',
                    sref: '^.conflictCheck',
                    filter: function (i) { return i.isActive; }
                },
                {
                    icon: 'folder_shared',
                    text: 'Convert to Client Case',
                    sref: '^.createClientCase',
                    name: 'Create Client Case',
                    filter: function (i) { return i.isActive; }
                },
                {
                    icon: 'comment',
                    text: 'Note',
                    sref: '^.note',
                    filter: function (i) { return true; }
                },
                {
                    icon: 'folder',
                    text: 'Convert to Systemic Case',
                    sref: '^.createSystemicCase',
                    name: 'Create Systemic Case',
                    filter: function (i) { return i.isActive; }
                },
                {
                    icon: 'highlight_off',
                    text: 'Close I&R',
                    sref: '^.close',
                    filter: function (i) { return i.isActive; }
                },
                {
                    icon: 'folder_open',
                    text: 'Reopen I&R',
                    sref: '^.reopen',
                    filter: function (i) { return !i.isActive; }
                },
                {
                    icon: 'vaccines',
                    text: 'Covid Vaccine',
                    sref: '^.covidVaccine',
                    filter: function (i, v) { return i.isActive && v; }
                },
                {
                    icon: 'approval',
                    text: 'Status Change',
                    sref: '^.statusChange',
                    filter: function (i) { return i.isActive; }
                },
            ];
            var NewInformationAndReferralActivityDialogController = (function () {
                function NewInformationAndReferralActivityDialogController($mdDialog, common, caseview, vaccineFeatureFlag) {
                    var _this = this;
                    this.$mdDialog = $mdDialog;
                    this.common = common;
                    this.caseview = caseview;
                    this.vaccineFeatureFlag = vaccineFeatureFlag;
                    this.filterActivity = function (activity) {
                        return activity.filter(_this.caseview, _this.vaccineFeatureFlag);
                    };
                    'ngInject';
                    'ngInject';
                }
                NewInformationAndReferralActivityDialogController.prototype.$onInit = function () {
                    this.filteredActivities = activities.filter(this.filterActivity);
                };
                NewInformationAndReferralActivityDialogController.prototype.selectActivity = function (item) {
                    this.common.$state.go(item.sref, { involvementId: this.caseview.involvementId, sourceId: this.caseview.id });
                    this.$mdDialog.hide();
                };
                NewInformationAndReferralActivityDialogController.$inject = ['$mdDialog', 'common', 'caseview', 'vaccineFeatureFlag'];
                NewInformationAndReferralActivityDialogController = __decorate([
                    app.Controller('app.informationAndReferrals.activities', 'NewInformationAndReferralActivityDialogController')
                ], NewInformationAndReferralActivityDialogController);
                return NewInformationAndReferralActivityDialogController;
            }());
        })(activities = informationAndReferrals.activities || (informationAndReferrals.activities = {}));
    })(informationAndReferrals = app.informationAndReferrals || (app.informationAndReferrals = {}));
})(app || (app = {}));
var app;
(function (app) {
    var informationAndReferrals;
    (function (informationAndReferrals) {
        var activities;
        (function (activities) {
            var Reopen = (function () {
                function Reopen(common, datacontext) {
                    this.common = common;
                    this.datacontext = datacontext;
                    this.isSubmitting = false;
                    this.activity = {};
                    'ngInject';
                    'ngInject';
                }
                Reopen.prototype.$onInit = function () {
                    this.activity.caseId = this.case.id;
                    this.activity.involvementId = this.case.involvementId;
                    this.activity.activityDate = moment().toDate();
                };
                Reopen.prototype.submit = function () {
                    var _this = this;
                    this.isSubmitting = true;
                    if (typeof this.activity.activityDate !== 'string') {
                        this.activity.activityDate = this.activity.activityDate.toDateString();
                    }
                    this.datacontext.informationAndReferrals
                        .reopen(this.activity)
                        .then(function (ir) {
                        if (ir) {
                            _this.common.$mdToast.showSimple('I&R Reopened');
                            _this.case.isActive = true;
                            _this.common.$rootScope.$broadcast('refreshBasicInfo');
                            _this.common.$state.go('^.list', { caseId: _this.case.id, involvementId: _this.case.involvementId });
                        }
                    })
                        .finally(function () { return _this.isSubmitting = false; });
                };
                Reopen.$inject = ['common', 'datacontext'];
                Reopen = __decorate([
                    app.Component('app.informationAndReferrals.activities', 'ceInformationAndReferralActivitiesReopen', {
                        templateUrl: 'app/information-and-referrals/activities/reopen/reopen.html',
                        bindings: {
                            case: '<'
                        }
                    })
                ], Reopen);
                return Reopen;
            }());
        })(activities = informationAndReferrals.activities || (informationAndReferrals.activities = {}));
    })(informationAndReferrals = app.informationAndReferrals || (app.informationAndReferrals = {}));
})(app || (app = {}));
var app;
(function (app) {
    var informationAndReferrals;
    (function (informationAndReferrals) {
        var activities;
        (function (activities) {
            var Note = (function () {
                function Note(common, datacontext, interrupt) {
                    this.common = common;
                    this.datacontext = datacontext;
                    this.interrupt = interrupt;
                    this.isSubmitting = false;
                    this.note = {};
                    'ngInject';
                    'ngInject';
                }
                Note.prototype.$onInit = function () {
                    this.maxDate = moment().toDate();
                    if (this.activity) {
                        this.note.id = this.activity.id;
                        this.note.caseId = this.activity.sourceId;
                        this.note.involvementId = this.activity.involvementId;
                        this.note.title = this.activity.title;
                        this.note.description = this.activity.description;
                        this.note.activityDate = this.activity.activityDate;
                    }
                    else {
                        this.note.involvementId = this.involvementId;
                        this.note.caseId = this.caseId;
                        this.note.activityDate = moment().toDate();
                        this.getStatus();
                    }
                };
                Note.prototype.getStatus = function () {
                    var _this = this;
                    return this.datacontext.informationAndReferrals.getInformationAndReferralStatus(this.caseId).then(function (result) {
                        var data = result;
                        if (data.status) {
                            _this.selectedStatus = data.status;
                            _this.note.statusDescription = data.statusDescription;
                        }
                    });
                };
                Note.prototype.submit = function () {
                    var _this = this;
                    this.isSubmitting = true;
                    if (typeof this.note.activityDate !== 'string') {
                        this.note.activityDate = this.note.activityDate.toDateString();
                    }
                    this.note.status = this.selectedStatus;
                    if (this.selectedStatus !== 'Other') {
                        this.note.statusDescription = null;
                    }
                    var promise = this.note.id
                        ? this.datacontext.informationAndReferrals.updateNote(this.note)
                        : this.datacontext.informationAndReferrals.createNote(this.note);
                    promise
                        .then(function (note) {
                        if (note) {
                            _this.common.$mdToast.showSimple('Note ' + (_this.note.id ? 'updated' : 'created'));
                            _this.common.$rootScope.$broadcast('refreshBasicInfo');
                            _this.common.$state.go('^.list', { caseId: _this.caseId });
                        }
                    })
                        .finally(function () { return _this.isSubmitting = false; });
                };
                Note.$inject = ['common', 'datacontext', 'interrupt'];
                Note = __decorate([
                    app.Component('app.informationAndReferrals.activities', 'ceInformationAndReferralsActivitiesNote', {
                        templateUrl: 'app/information-and-referrals/activities/note/note.html',
                        bindings: {
                            involvementId: '<',
                            caseId: '<',
                            activity: '<',
                            caseOpenDate: '<'
                        }
                    })
                ], Note);
                return Note;
            }());
        })(activities = informationAndReferrals.activities || (informationAndReferrals.activities = {}));
    })(informationAndReferrals = app.informationAndReferrals || (app.informationAndReferrals = {}));
})(app || (app = {}));
var app;
(function (app) {
    var informationAndReferrals;
    (function (informationAndReferrals) {
        var details;
        (function (details) {
            var InformationAndReferralInformationForm = (function () {
                function InformationAndReferralInformationForm(datacontext) {
                    this.datacontext = datacontext;
                    this.isLoading = false;
                    this.isSubmitting = false;
                    'ngInject';
                    'ngInject';
                }
                InformationAndReferralInformationForm.prototype.$onInit = function () {
                    var _this = this;
                    this.datacontext.config.legalTeams.getLegalTeams(true).then(function (result) { return _this.legalTeams = result; });
                    this.getLawCategoriesAndSubcategories().then(function () { return _this.getData(); });
                };
                InformationAndReferralInformationForm.prototype.lawCategoryChanged = function () {
                    this.filterLawSubcategories();
                };
                InformationAndReferralInformationForm.prototype.getLawCategoriesAndSubcategories = function () {
                    var _this = this;
                    return this.datacontext.config.getLawCategories()
                        .then(function (lawCategories) {
                        _this.lawCategories = lawCategories;
                        return _this.datacontext.config.getLawSubcategories().then(function (lawSubcategories) {
                            _this.lawSubcategories = lawSubcategories.data;
                        });
                    });
                };
                InformationAndReferralInformationForm.prototype.getData = function () {
                    var _this = this;
                    this.isLoading = true;
                    this.datacontext.informationAndReferrals
                        .getInformationAndReferralInformation(this.caseId)
                        .then(function (result) { return _this.data = result; })
                        .finally(function () { return _this.isLoading = false; });
                };
                InformationAndReferralInformationForm.prototype.filterLawSubcategories = function () {
                    var _this = this;
                    this.validLawSubcategories = _.filter(this.lawSubcategories, function (ct) {
                        return ct.lawCategoryId === _this.data.lawCategoryId;
                    });
                };
                InformationAndReferralInformationForm.prototype.submit = function () {
                    var _this = this;
                    this.isSubmitting = true;
                    this.datacontext.informationAndReferrals
                        .updateInformationAndReferralInformation(this.data)
                        .then(function (result) {
                        if (result)
                            _this.onSubmit({ result: result });
                    }).finally(function () { return _this.isSubmitting = false; });
                };
                InformationAndReferralInformationForm.$inject = ['datacontext'];
                InformationAndReferralInformationForm = __decorate([
                    app.Component('app.informationAndReferrals.details', 'ceInformationAndReferralInformationForm', {
                        templateUrl: 'app/information-and-referrals/details/case-information/case-information-form.html',
                        bindings: {
                            caseId: '<',
                            onCancel: '&',
                            onSubmit: '&'
                        }
                    })
                ], InformationAndReferralInformationForm);
                return InformationAndReferralInformationForm;
            }());
        })(details = informationAndReferrals.details || (informationAndReferrals.details = {}));
    })(informationAndReferrals = app.informationAndReferrals || (app.informationAndReferrals = {}));
})(app || (app = {}));
var app;
(function (app) {
    var informationAndReferrals;
    (function (informationAndReferrals) {
        var details;
        (function (details) {
            var InformationAndReferralInformation = (function () {
                function InformationAndReferralInformation() {
                }
                InformationAndReferralInformation = __decorate([
                    app.Component('app.informationAndReferrals.details', 'ceInformationAndReferralInformation', {
                        templateUrl: 'app/information-and-referrals/details/case-information/case-information.html',
                        bindings: {
                            data: '<',
                            onEdit: '&'
                        }
                    })
                ], InformationAndReferralInformation);
                return InformationAndReferralInformation;
            }());
        })(details = informationAndReferrals.details || (informationAndReferrals.details = {}));
    })(informationAndReferrals = app.informationAndReferrals || (app.informationAndReferrals = {}));
})(app || (app = {}));
var app;
(function (app) {
    var involvements;
    (function (involvements) {
        var EntityList = (function () {
            function EntityList() {
            }
            EntityList = __decorate([
                app.Component('app.involvements', 'ceEntityList', {
                    templateUrl: 'app/involvements/entities/entity-listing/entity-list.html',
                    bindings: {
                        entities: '<',
                        totalEntities: '<',
                        onDelete: '&'
                    }
                })
            ], EntityList);
            return EntityList;
        }());
    })(involvements = app.involvements || (app.involvements = {}));
})(app || (app = {}));
var app;
(function (app) {
    var involvements;
    (function (involvements) {
        var EntityListing = (function () {
            function EntityListing($mdDialog, common, datacontext) {
                var _this = this;
                this.$mdDialog = $mdDialog;
                this.common = common;
                this.datacontext = datacontext;
                this.$onInit = function () {
                    _this.getEntities();
                };
                this.getEntities = function () {
                    return _this.datacontext.involvements
                        .getEntityListing(_this.involvementId)
                        .then(function (result) {
                        _this.entities = result;
                    });
                };
                'ngInject';
                'ngInject';
            }
            EntityListing.prototype.deleteEntity = function (entity) {
                var _this = this;
                this.common.confirmation.show({
                    title: 'Delete Relationship',
                    message: 'Are you sure you want to delete this entity relationship?',
                    ok: 'Delete',
                    cancel: 'Keep'
                }).then(function () {
                    _this.datacontext.involvements
                        .deleteEntity(entity.id)
                        .then(function () {
                        _this.getEntities();
                        _this.common.$rootScope.$broadcast('refreshBasicInfo');
                    });
                }).catch(angular.noop);
            };
            EntityListing.$inject = ['$mdDialog', 'common', 'datacontext'];
            EntityListing = __decorate([
                app.Component('app.involvements', 'ceInvolvementEntityListing', {
                    template: "<md-content>\n      <md-card>\n        <md-toolbar>\n          <div class=\"md-toolbar-tools\">\n            <span flex>Entity Listing</span>\n            <md-button class=\"md-icon-button\" ui-sref=\"^.add\">\n              <md-tooltip>Add Entity</md-tooltip>\n              <md-icon>add</md-icon>\n            </md-button>\n          </div>\n        </md-toolbar>\n        <md-divider></md-divider>\n        <md-card-content layout=\"row\" class=\"p0\">\n          <md-divider ng-show=\"$ctrl.showFilters\"></md-divider>\n          <ce-entity-list entities=\"$ctrl.entities\"\n                          involvement-id=\"$ctrl.involvementId\"\n                          on-delete=\"$ctrl.deleteEntity(entity)\"\n                          flex></ce-entity-list>\n        </md-card-content>\n      </md-card>\n      </md-content>",
                    bindings: {
                        involvementId: '<'
                    }
                })
            ], EntityListing);
            return EntityListing;
        }());
    })(involvements = app.involvements || (app.involvements = {}));
})(app || (app = {}));
var app;
(function (app) {
    var involvements;
    (function (involvements) {
        var EntityRoles = (function () {
            function EntityRoles(common, datacontext) {
                var _this = this;
                this.common = common;
                this.datacontext = datacontext;
                this.selectedRoles = [];
                this.involvementEntity = {};
                this.$onInit = function () {
                    _this.getRoles().then(function () { return _this.getExistingRoles(); });
                };
                this.getRoles = function () {
                    var lookupTypeId = '';
                    if (_this.entity.isUser) {
                        lookupTypeId = 'involvement-user-role';
                    }
                    else {
                        lookupTypeId = "involvement-".concat(_this.entity.entityType, "-role");
                    }
                    return _this.datacontext.config.lookups.getLookupType(lookupTypeId).then(function (result) {
                        _this.roles = result.lookups;
                    });
                };
                this.getExistingRoles = function () {
                    return _this.datacontext.involvements.getEntity(_this.involvementId, _this.entity.entityId).then(function (result) {
                        _this.involvementEntity = result;
                        _.forEach(_this.involvementEntity.roles, function (ieRole) {
                            var existingLookup = _.find(_this.roles, function (role) {
                                return role.value === ieRole;
                            });
                            if (existingLookup) {
                                _this.selectedRoles.push(existingLookup);
                            }
                            else {
                                _this.selectedRoles.push({ lookupTypeId: 'roles', isActive: false, value: ieRole });
                            }
                        });
                    });
                };
                this.onSubmit = function () {
                    _this.isSubmitting = true;
                    var roles = _.map(_this.selectedRoles, 'value');
                    angular.extend(_this.involvementEntity, {
                        roles: roles
                    });
                    var promise;
                    promise = _this.datacontext.involvements.updateEntity(_this.involvementEntity);
                    promise.finally(function () {
                        _this.isSubmitting = false;
                        _this.common.$mdToast.showSimple('Entity Updated');
                        _this.common.$rootScope.$broadcast('refreshBasicInfo');
                        _this.common.$state.go('^.listing');
                    });
                };
                'ngInject';
                'ngInject';
            }
            EntityRoles.prototype.transformChip = function (chip) {
                if (angular.isObject(chip)) {
                    return chip;
                }
                return { name: chip, type: 'new' };
            };
            EntityRoles.prototype.querySearch = function (query) {
                var results = query ? _.filter(this.roles, function (r) {
                    return query === '*' || r.value.toLowerCase().indexOf(query) > -1;
                }) : [];
                return results;
            };
            EntityRoles.$inject = ['common', 'datacontext'];
            EntityRoles = __decorate([
                app.Component('app.involvements.entities', 'ceEditEntityRoles', {
                    templateUrl: 'app/involvements/entities/roles/edit-roles.html',
                    bindings: {
                        entity: '<',
                        involvementId: '<'
                    }
                })
            ], EntityRoles);
            return EntityRoles;
        }());
    })(involvements = app.involvements || (app.involvements = {}));
})(app || (app = {}));
var app;
(function (app) {
    var involvements;
    (function (involvements) {
        var EntityRoles = (function () {
            function EntityRoles(common, datacontext) {
                var _this = this;
                this.common = common;
                this.datacontext = datacontext;
                this.selectedRoles = [];
                this.involvementEntity = {};
                this.$onInit = function () {
                    _this.getRoles().then(function () { return _this.getExistingRoles(); });
                };
                this.getRoles = function () {
                    var lookupTypeId = '';
                    if (_this.entity.isUser) {
                        lookupTypeId = 'involvement-user-role';
                    }
                    else {
                        lookupTypeId = "involvement-".concat(_this.entity.entityType, "-role");
                    }
                    return _this.datacontext.config.lookups.getLookupType(lookupTypeId).then(function (result) {
                        _this.roles = result.lookups;
                    });
                };
                this.getExistingRoles = function () {
                    return _this.datacontext.involvements.getEntity(_this.involvementId, _this.entity.entityId).then(function (result) {
                        angular.extend(_this.involvementEntity, result);
                        _.forEach(_this.involvementEntity.roles, function (ieRole) {
                            _this.selectedRoles.push(_.find(_this.roles, function (role) {
                                return role.value === ieRole;
                            }));
                        });
                    });
                };
                this.onSubmit = function () {
                    _this.isSubmitting = true;
                    var roles = _.map(_this.selectedRoles, 'value');
                    angular.extend(_this.involvementEntity, {
                        entityId: _this.entity.entityId,
                        involvementId: _this.involvementId,
                        roles: roles
                    });
                    var promise;
                    if (_this.involvementEntity.id) {
                        promise = _this.datacontext.involvements.updateEntity(_this.involvementEntity);
                    }
                    else {
                        promise = _this.datacontext.involvements.createEntity(_this.involvementEntity);
                    }
                    promise.finally(function () {
                        _this.isSubmitting = false;
                        _this.common.$mdToast.showSimple('Entity Updated');
                        _this.common.$rootScope.$broadcast('refreshBasicInfo');
                        _this.common.$state.go('^.^.^');
                    });
                };
                'ngInject';
                'ngInject';
            }
            EntityRoles.prototype.transformChip = function (chip) {
                if (angular.isObject(chip)) {
                    return chip;
                }
                return { name: chip, type: 'new' };
            };
            EntityRoles.prototype.querySearch = function (query) {
                var results = query ? _.filter(this.roles, function (r) {
                    return query === '*' || r.value.toLowerCase().indexOf(query) > -1;
                }) : [];
                return results;
            };
            EntityRoles.$inject = ['common', 'datacontext'];
            EntityRoles = __decorate([
                app.Component('app.involvements.entities', 'ceEntityRoles', {
                    templateUrl: 'app/involvements/entities/roles/roles.html',
                    bindings: {
                        entity: '<',
                        involvementId: '<'
                    }
                })
            ], EntityRoles);
            return EntityRoles;
        }());
    })(involvements = app.involvements || (app.involvements = {}));
})(app || (app = {}));
var app;
(function (app) {
    var persons;
    (function (persons) {
        var details;
        (function (details) {
            var PersonContactInfo = (function () {
                function PersonContactInfo() {
                }
                PersonContactInfo = __decorate([
                    app.Component('app.persons.details', 'cePersonContactInfo', {
                        templateUrl: 'app/persons/details/contact-info/contact-info.html',
                        bindings: {
                            person: '<',
                        }
                    })
                ], PersonContactInfo);
                return PersonContactInfo;
            }());
        })(details = persons.details || (persons.details = {}));
    })(persons = app.persons || (app.persons = {}));
})(app || (app = {}));
var app;
(function (app) {
    var persons;
    (function (persons) {
        var details;
        (function (details) {
            var PersonNames = (function () {
                function PersonNames() {
                }
                PersonNames = __decorate([
                    app.Component('app.persons.details', 'cePersonNames', {
                        templateUrl: 'app/persons/details/names/names.html',
                        bindings: {
                            person: '<',
                        }
                    })
                ], PersonNames);
                return PersonNames;
            }());
        })(details = persons.details || (persons.details = {}));
    })(persons = app.persons || (app.persons = {}));
})(app || (app = {}));
var app;
(function (app) {
    var persons;
    (function (persons) {
        var details;
        (function (details) {
            var PersonDemographicsForm = (function () {
                function PersonDemographicsForm(common, datacontext, $scope) {
                    var _this = this;
                    this.common = common;
                    this.datacontext = datacontext;
                    this.$scope = $scope;
                    this.isLoading = false;
                    this.data = {};
                    this.raceSubcategories = [];
                    this.validRaceSubcategories = [];
                    this.getValidRaceSubcategories = function () {
                        _this.validRaceSubcategories = _this.raceSubcategories.filter(function (sc) { return sc.raceName === _this.data.race; });
                    };
                    'ngInject';
                    'ngInject';
                }
                PersonDemographicsForm.prototype.$onInit = function () {
                    var _this = this;
                    this.getData();
                    this.getSubCategory();
                    this.$scope.$watch(function () { return _this.data.race; }, this.getValidRaceSubcategories);
                };
                PersonDemographicsForm.prototype.$onChanges = function () {
                    this.data = angular.extend({}, this.demographics);
                };
                PersonDemographicsForm.prototype.toggle = function (value) {
                    if (!this.data.incomeSources) {
                        this.data.incomeSources = [];
                    }
                    if (this.exists(value)) {
                        _.pull(this.data.incomeSources, value);
                    }
                    else {
                        this.data.incomeSources.push(value);
                    }
                };
                PersonDemographicsForm.prototype.exists = function (value) {
                    return _.includes(this.data.incomeSources, value);
                };
                PersonDemographicsForm.prototype.getData = function () {
                    var _this = this;
                    this.isLoading = true;
                    return this.datacontext.config.lookups.getLookupType('income-sources').then(function (result) {
                        _this.incomeSources = result.lookups;
                        _this.isLoading = false;
                    });
                };
                PersonDemographicsForm.prototype.getSubCategory = function () {
                    var _this = this;
                    return this.datacontext.admin.configuration.getRaceSubcategories().then(function (raceSubcategories) {
                        _this.raceSubcategories = raceSubcategories;
                        _this.getValidRaceSubcategories();
                    });
                };
                PersonDemographicsForm.prototype.raceChanged = function (test) {
                    this.validRaceSubcategories = null;
                };
                PersonDemographicsForm.$inject = ['common', 'datacontext', '$scope'];
                PersonDemographicsForm = __decorate([
                    app.Component('app.persons.details', 'cePersonDemographicsForm', {
                        templateUrl: 'app/persons/details/demographics/demographics-form.html',
                        bindings: {
                            demographics: '<',
                            paramPovertyLevel: '<',
                            paramAtPoverty: '<',
                            paramRaceSubCategory: '<',
                            onCancel: '&',
                            onSubmit: '&'
                        }
                    })
                ], PersonDemographicsForm);
                return PersonDemographicsForm;
            }());
        })(details = persons.details || (persons.details = {}));
    })(persons = app.persons || (app.persons = {}));
})(app || (app = {}));
var app;
(function (app) {
    var persons;
    (function (persons) {
        var details;
        (function (details) {
            var PersonDemographicsView = (function () {
                function PersonDemographicsView() {
                }
                PersonDemographicsView = __decorate([
                    app.Component('app.persons.details', 'cePersonDemographicsView', {
                        templateUrl: 'app/persons/details/demographics/demographics-view.html',
                        bindings: {
                            data: '<',
                            paramPovertyLevel: '<',
                            paramAtPoverty: '<',
                            paramRaceSubCategory: '<',
                            onEdit: '&'
                        }
                    })
                ], PersonDemographicsView);
                return PersonDemographicsView;
            }());
        })(details = persons.details || (persons.details = {}));
    })(persons = app.persons || (app.persons = {}));
})(app || (app = {}));
var app;
(function (app) {
    var persons;
    (function (persons) {
        var details;
        (function (details) {
            var PersonDemographics = (function () {
                function PersonDemographics(common, datacontext) {
                    this.common = common;
                    this.datacontext = datacontext;
                    this.isLoading = false;
                    this.editMode = false;
                    this.data = {};
                    this.isSubmitting = false;
                    'ngInject';
                    'ngInject';
                }
                PersonDemographics.prototype.$onInit = function () {
                    this.getData();
                };
                PersonDemographics.prototype.cancelEdit = function () {
                    this.editMode = false;
                };
                PersonDemographics.prototype.editData = function (data) {
                    this.editMode = true;
                };
                PersonDemographics.prototype.getData = function () {
                    var _this = this;
                    this.isLoading = true;
                    return this.datacontext.persons
                        .getDemographics(this.person.id)
                        .then(function (result) {
                        _this.data = result;
                        _this.isLoading = false;
                    });
                };
                PersonDemographics.prototype.saveData = function (data) {
                    var _this = this;
                    this.isSubmitting = true;
                    if (data.highestUnmetLegalNeed !== 'Other') {
                        data.highestUnmetLegalNeedOther = null;
                    }
                    if (data.communicationPreference !== 'Other') {
                        data.communicationPreferenceOther = null;
                    }
                    if (typeof data.dateOfBirth !== 'string' && data.dateOfBirth) {
                        data.dateOfBirth = data.dateOfBirth.toDateString();
                    }
                    if (typeof data.dateOfDeath !== 'string' && data.dateOfDeath) {
                        data.dateOfDeath = data.dateOfDeath.toDateString();
                    }
                    this.datacontext.persons
                        .updateDemographics(this.person.id, data)
                        .then(function (result) {
                        _this.data = result;
                        angular.extend(_this.person, result);
                        _this.isSubmitting = false;
                        _this.cancelEdit();
                    });
                };
                PersonDemographics.$inject = ['common', 'datacontext'];
                PersonDemographics = __decorate([
                    app.Component('app.persons.details', 'cePersonDemographics', {
                        templateUrl: 'app/persons/details/demographics/demographics.html',
                        bindings: {
                            person: '<',
                            paramPovertyLevel: '<',
                            paramAtPoverty: '<',
                            paramRaceSubCategory: '<',
                            onEdit: '&',
                        }
                    })
                ], PersonDemographics);
                return PersonDemographics;
            }());
        })(details = persons.details || (persons.details = {}));
    })(persons = app.persons || (app.persons = {}));
})(app || (app = {}));
var app;
(function (app) {
    var persons;
    (function (persons) {
        var details;
        (function (details) {
            var PersonSpecialConsiderationsForm = (function () {
                function PersonSpecialConsiderationsForm(common, datacontext) {
                    this.common = common;
                    this.datacontext = datacontext;
                    this.isLoading = false;
                    this.data = {};
                    'ngInject';
                    'ngInject';
                }
                PersonSpecialConsiderationsForm.prototype.$onInit = function () {
                };
                PersonSpecialConsiderationsForm.prototype.$onChanges = function () {
                    this.data = angular.extend({}, this.specialConsiderations);
                };
                PersonSpecialConsiderationsForm.$inject = ['common', 'datacontext'];
                PersonSpecialConsiderationsForm = __decorate([
                    app.Component('app.persons.details', 'cePersonSpecialConsiderationsForm', {
                        templateUrl: 'app/persons/details/special-considerations/special-considerations-form.html',
                        bindings: {
                            specialConsiderations: '<',
                            onCancel: '&',
                            onSubmit: '&'
                        }
                    })
                ], PersonSpecialConsiderationsForm);
                return PersonSpecialConsiderationsForm;
            }());
        })(details = persons.details || (persons.details = {}));
    })(persons = app.persons || (app.persons = {}));
})(app || (app = {}));
var app;
(function (app) {
    var persons;
    (function (persons) {
        var details;
        (function (details) {
            var PersonSpecialConsiderationsView = (function () {
                function PersonSpecialConsiderationsView() {
                }
                PersonSpecialConsiderationsView = __decorate([
                    app.Component('app.persons.details', 'cePersonSpecialConsiderationsView', {
                        templateUrl: 'app/persons/details/special-considerations/special-considerations-view.html',
                        bindings: {
                            data: '<',
                            onEdit: '&'
                        }
                    })
                ], PersonSpecialConsiderationsView);
                return PersonSpecialConsiderationsView;
            }());
        })(details = persons.details || (persons.details = {}));
    })(persons = app.persons || (app.persons = {}));
})(app || (app = {}));
var app;
(function (app) {
    var persons;
    (function (persons) {
        var details;
        (function (details) {
            var PersonSpecialConsiderations = (function () {
                function PersonSpecialConsiderations(common, datacontext) {
                    this.common = common;
                    this.datacontext = datacontext;
                    this.isLoading = false;
                    this.editMode = false;
                    this.data = {};
                    this.isSubmitting = false;
                    'ngInject';
                    'ngInject';
                }
                PersonSpecialConsiderations.prototype.$onInit = function () {
                    this.getData();
                };
                PersonSpecialConsiderations.prototype.cancelEdit = function () {
                    this.editMode = false;
                };
                PersonSpecialConsiderations.prototype.editData = function (data) {
                    this.editMode = true;
                };
                PersonSpecialConsiderations.prototype.getData = function () {
                    var _this = this;
                    this.isLoading = true;
                    return this.datacontext.persons
                        .getSpecialConsiderations(this.person.id)
                        .then(function (result) {
                        _this.data = result;
                        _this.isLoading = false;
                    });
                };
                PersonSpecialConsiderations.prototype.saveData = function (data) {
                    var _this = this;
                    this.isSubmitting = true;
                    this.datacontext.persons
                        .updateSpecialConsiderations(this.person.id, data)
                        .then(function (result) {
                        _this.data = result;
                        angular.extend(_this.person, result);
                        _this.isSubmitting = false;
                        _this.cancelEdit();
                    });
                };
                PersonSpecialConsiderations.$inject = ['common', 'datacontext'];
                PersonSpecialConsiderations = __decorate([
                    app.Component('app.persons.details', 'cePersonSpecialConsiderations', {
                        templateUrl: 'app/persons/details/special-considerations/special-considerations.html',
                        bindings: {
                            person: '<',
                            onEdit: '&',
                        }
                    })
                ], PersonSpecialConsiderations);
                return PersonSpecialConsiderations;
            }());
        })(details = persons.details || (persons.details = {}));
    })(persons = app.persons || (app.persons = {}));
})(app || (app = {}));
var app;
(function (app) {
    var persons;
    (function (persons) {
        var views;
        (function (views) {
            var CallerInformationForm = (function () {
                function CallerInformationForm(common, datacontext) {
                    this.common = common;
                    this.datacontext = datacontext;
                    this.isLoading = false;
                    this.data = {};
                    this.isSubmitting = false;
                    'ngInject';
                    'ngInject';
                }
                CallerInformationForm.prototype.$onInit = function () {
                    this.getData();
                };
                CallerInformationForm.prototype.getData = function () {
                    var _this = this;
                    this.isLoading = true;
                    var getClientData = this.datacontext.persons
                        .getDemographics(this.personId)
                        .then(function (result) { return _this.data = result; })
                        .finally(function () { return _this.isLoading = false; });
                };
                CallerInformationForm.prototype.submit = function () {
                    var _this = this;
                    this.isSubmitting = true;
                    if (this.data.highestUnmetLegalNeed !== 'Other') {
                        this.data.highestUnmetLegalNeedOther = null;
                    }
                    if (this.data.communicationPreference !== 'Other') {
                        this.data.communicationPreferenceOther = null;
                    }
                    var updateCallerInformation = this.datacontext.persons
                        .updateDemographics(this.personId, this.data).then(function (result) {
                        if (result)
                            _this.onSubmit({ result: result });
                    }).finally(function () { return _this.isSubmitting = false; });
                };
                CallerInformationForm.$inject = ['common', 'datacontext'];
                CallerInformationForm = __decorate([
                    app.Component('app.persons.views', 'ceCallerInformationForm', {
                        templateUrl: 'app/persons/views/caller-information/caller-information-form.html',
                        bindings: {
                            personId: '<',
                            onCancel: '&',
                            onSubmit: '&'
                        }
                    })
                ], CallerInformationForm);
                return CallerInformationForm;
            }());
        })(views = persons.views || (persons.views = {}));
    })(persons = app.persons || (app.persons = {}));
})(app || (app = {}));
var app;
(function (app) {
    var persons;
    (function (persons) {
        var views;
        (function (views) {
            var CallerInformation = (function () {
                function CallerInformation() {
                }
                CallerInformation = __decorate([
                    app.Component('app.persons.views', 'ceCallerInformation', {
                        templateUrl: 'app/persons/views/caller-information/caller-information.html',
                        bindings: {
                            data: '<',
                            onEdit: '&'
                        }
                    })
                ], CallerInformation);
                return CallerInformation;
            }());
        })(views = persons.views || (persons.views = {}));
    })(persons = app.persons || (app.persons = {}));
})(app || (app = {}));
var app;
(function (app) {
    var persons;
    (function (persons) {
        var views;
        (function (views) {
            var ClientInformationForm = (function () {
                function ClientInformationForm(common, datacontext, $scope) {
                    var _this = this;
                    this.common = common;
                    this.datacontext = datacontext;
                    this.$scope = $scope;
                    this.isLoading = false;
                    this.data = {};
                    this.isSubmitting = false;
                    this.raceSubcategories = [];
                    this.validRaceSubcategories = [];
                    this.getValidRaceSubcategories = function () {
                        _this.validRaceSubcategories = _this.raceSubcategories.filter(function (sc) { return sc.raceName === _this.data.race; });
                    };
                    'ngInject';
                    'ngInject';
                }
                ClientInformationForm.prototype.$onInit = function () {
                    var _this = this;
                    this.getData();
                    this.getSubCategory();
                    this.$scope.$watch(function () { return _this.data.race; }, this.getValidRaceSubcategories);
                };
                ClientInformationForm.prototype.toggle = function (value) {
                    if (!this.data.incomeSources) {
                        this.data.incomeSources = [];
                    }
                    if (this.exists(value)) {
                        _.pull(this.data.incomeSources, value);
                    }
                    else {
                        this.data.incomeSources.push(value);
                    }
                };
                ClientInformationForm.prototype.exists = function (value) {
                    return _.includes(this.data.incomeSources, value);
                };
                ClientInformationForm.prototype.getData = function () {
                    var _this = this;
                    this.isLoading = true;
                    var getLookups = this.datacontext.config.lookups.getLookupType('income-sources').then(function (result) { return _this.incomeSources = result.lookups; });
                    var getDemographicData = this.datacontext.persons
                        .getDemographics(this.personId)
                        .then(function (result) { return _this.data = result; });
                    this.common.$q.all([getLookups, getDemographicData]).finally(function () { return _this.isLoading = false; });
                };
                ClientInformationForm.prototype.getSubCategory = function () {
                    var _this = this;
                    return this.datacontext.admin.configuration.getRaceSubcategories().then(function (raceSubcategories) {
                        _this.raceSubcategories = raceSubcategories;
                        _this.getValidRaceSubcategories();
                    });
                };
                ClientInformationForm.prototype.raceChanged = function (test) {
                    this.validRaceSubcategories = null;
                };
                ClientInformationForm.prototype.submit = function () {
                    var _this = this;
                    this.isSubmitting = true;
                    if (this.data.highestUnmetLegalNeed !== 'Other') {
                        this.data.highestUnmetLegalNeedOther = null;
                    }
                    if (this.data.communicationPreference !== 'Other') {
                        this.data.communicationPreferenceOther = null;
                    }
                    var updateClientInformation = this.datacontext.persons
                        .updateDemographics(this.personId, this.data).then(function (result) {
                        if (result)
                            _this.onSubmit({ result: result });
                    }).finally(function () { return _this.isSubmitting = false; });
                };
                ClientInformationForm.$inject = ['common', 'datacontext', '$scope'];
                ClientInformationForm = __decorate([
                    app.Component('app.persons.views', 'ceClientInformationForm', {
                        templateUrl: 'app/persons/views/client-information/client-information-form.html',
                        bindings: {
                            personId: '<',
                            paramPovertyLevel: '<',
                            paramAtPoverty: '<',
                            paramRaceSubCategory: '<',
                            onCancel: '&',
                            onSubmit: '&'
                        }
                    })
                ], ClientInformationForm);
                return ClientInformationForm;
            }());
        })(views = persons.views || (persons.views = {}));
    })(persons = app.persons || (app.persons = {}));
})(app || (app = {}));
var app;
(function (app) {
    var persons;
    (function (persons) {
        var views;
        (function (views) {
            var ClientInformation = (function () {
                function ClientInformation() {
                }
                ClientInformation = __decorate([
                    app.Component('app.persons.views', 'ceClientInformation', {
                        templateUrl: 'app/persons/views/client-information/client-information.html',
                        bindings: {
                            data: '<',
                            paramPovertyLevel: '<',
                            paramAtPoverty: '<',
                            paramRaceSubCategory: '<',
                            onEdit: '&'
                        }
                    })
                ], ClientInformation);
                return ClientInformation;
            }());
        })(views = persons.views || (persons.views = {}));
    })(persons = app.persons || (app.persons = {}));
})(app || (app = {}));
var app;
(function (app) {
    var persons;
    (function (persons) {
        var views;
        (function (views) {
            var PWDInformationForm = (function () {
                function PWDInformationForm(common, datacontext) {
                    this.common = common;
                    this.datacontext = datacontext;
                    this.isLoading = false;
                    this.data = {};
                    this.isSubmitting = false;
                    'ngInject';
                    'ngInject';
                }
                PWDInformationForm.prototype.$onInit = function () {
                    this.getData();
                };
                PWDInformationForm.prototype.toggle = function (value) {
                    if (!this.data.incomeSources) {
                        this.data.incomeSources = [];
                    }
                    if (this.exists(value)) {
                        _.pull(this.data.incomeSources, value);
                    }
                    else {
                        this.data.incomeSources.push(value);
                    }
                };
                PWDInformationForm.prototype.exists = function (value) {
                    return _.includes(this.data.incomeSources, value);
                };
                PWDInformationForm.prototype.getData = function () {
                    var _this = this;
                    this.isLoading = true;
                    var getLookups = this.datacontext.config.lookups.getLookupType('income-sources').then(function (result) { return _this.incomeSources = result.lookups; });
                    var getDemographicData = this.datacontext.persons
                        .getDemographics(this.personId)
                        .then(function (result) { return _this.data = result; });
                    this.common.$q.all([getLookups, getDemographicData]).finally(function () { return _this.isLoading = false; });
                };
                PWDInformationForm.prototype.submit = function () {
                    var _this = this;
                    this.isSubmitting = true;
                    if (this.data.highestUnmetLegalNeed !== 'Other') {
                        this.data.highestUnmetLegalNeedOther = null;
                    }
                    if (this.data.communicationPreference !== 'Other') {
                        this.data.communicationPreferenceOther = null;
                    }
                    var updateClientInformation = this.datacontext.persons
                        .updateDemographics(this.personId, this.data).then(function (result) {
                        if (result)
                            _this.onSubmit({ result: result });
                    }).finally(function () { return _this.isSubmitting = false; });
                };
                PWDInformationForm.$inject = ['common', 'datacontext'];
                PWDInformationForm = __decorate([
                    app.Component('app.persons.views', 'cePWDInformationForm', {
                        templateUrl: 'app/persons/views/pwd-information/pwd-information-form.html',
                        bindings: {
                            personId: '<',
                            onCancel: '&',
                            onSubmit: '&'
                        }
                    })
                ], PWDInformationForm);
                return PWDInformationForm;
            }());
        })(views = persons.views || (persons.views = {}));
    })(persons = app.persons || (app.persons = {}));
})(app || (app = {}));
var app;
(function (app) {
    var persons;
    (function (persons) {
        var views;
        (function (views) {
            var PWDInformation = (function () {
                function PWDInformation() {
                }
                PWDInformation = __decorate([
                    app.Component('app.persons.views', 'cePWDInformation', {
                        templateUrl: 'app/persons/views/pwd-information/pwd-information.html',
                        bindings: {
                            data: '<',
                            onEdit: '&'
                        }
                    })
                ], PWDInformation);
                return PWDInformation;
            }());
        })(views = persons.views || (persons.views = {}));
    })(persons = app.persons || (app.persons = {}));
})(app || (app = {}));
var app;
(function (app) {
    var monitoring;
    (function (monitoring) {
        var activities;
        (function (activities) {
            var MonitoringActivityList = (function () {
                function MonitoringActivityList($mdDialog, common, datacontext) {
                    var _this = this;
                    this.$mdDialog = $mdDialog;
                    this.common = common;
                    this.datacontext = datacontext;
                    this.showFilters = false;
                    this.selectedActivityTypes = [];
                    this.activityFilter = {};
                    this.paging = {
                        page: 1,
                        pageSize: 100
                    };
                    this.refresh = function () {
                        _this.getActivities();
                    };
                    'ngInject';
                    'ngInject';
                }
                MonitoringActivityList.prototype.$onInit = function () {
                    this.activityFilter.involvementId = this.monitoring.involvementId;
                    this.activityFilter.sourceId = this.monitoring.id;
                    this.activityFilter.source = 'Monitoring';
                    this.getActivities();
                    this.common.$rootScope.$broadcast('refreshBasicInfo');
                };
                MonitoringActivityList.prototype.getActivities = function () {
                    var _this = this;
                    if (!this.filtersInitialized()) {
                        return;
                    }
                    if (!this.showInvolvementActivities) {
                        this.activityFilter.sourceId = this.monitoring.id;
                        this.activityFilter.source = 'Monitoring';
                    }
                    else {
                        this.activityFilter.sourceId = null;
                        this.activityFilter.source = null;
                    }
                    this.activityFilter = __assign(__assign(__assign({}, this.activityFilter), this.paging), { activityTypes: this.selectedActivityTypes, q: this.noteFilter });
                    this.promise = this.datacontext.activities
                        .searchActivities(this.activityFilter)
                        .then(function (result) {
                        _this.activities = result;
                    });
                };
                MonitoringActivityList.prototype.getInvolvementActivities = function () {
                    this.showInvolvementActivities = !this.showInvolvementActivities;
                    this.getActivities();
                };
                MonitoringActivityList.prototype.newActivity = function (targetEvent) {
                    var parent = angular.element(document.body);
                    this.$mdDialog.show({
                        parent: parent,
                        targetEvent: targetEvent,
                        clickOutsideToClose: true,
                        templateUrl: 'app/monitoring/activities/new-activity/new-activity.html',
                        controller: 'NewMonitoringActivityDialogController',
                        controllerAs: '$ctrl',
                        locals: {
                            monitoringview: this.monitoring
                        }
                    });
                };
                MonitoringActivityList.prototype.toggleFilters = function () {
                    this.showFilters = !this.showFilters;
                };
                MonitoringActivityList.prototype.filtersInitialized = function () {
                    return this.activityTypeFilterInitialized;
                };
                MonitoringActivityList.prototype.activityTypeFilterChanged = function (activityTypes) {
                    this.selectedActivityTypes = activityTypes;
                    this.activityTypeFilterInitialized = true;
                    this.getActivities();
                };
                MonitoringActivityList.prototype.onEdit = function (activity) {
                    switch (activity.activityType) {
                        case 'MonitoringIssue':
                            this.common.$state.go('^.issue', { activityId: activity.id });
                            break;
                        case 'MonitoringContact':
                            this.common.$state.go('^.contact', { activityId: activity.id });
                            break;
                        case 'MonitoringMeeting':
                            this.common.$state.go('^.meeting', { activityId: activity.id });
                            break;
                        case 'CaseReview':
                            this.common.$state.go('^.caseReview', { activity: activity });
                            break;
                        case 'Note':
                            this.common.$state.go('^.note', { activity: activity });
                            break;
                        case 'StatusChange':
                            this.common.$state.go('^.statusChange', { activityId: activity.id });
                            break;
                    }
                };
                MonitoringActivityList.$inject = ['$mdDialog', 'common', 'datacontext'];
                MonitoringActivityList = __decorate([
                    app.Component('app.monitoring.activities', 'ceMonitoringActivityList', {
                        templateUrl: 'app/monitoring/activities/activity-list/activity-list.html',
                        bindings: {
                            monitoring: '<'
                        }
                    })
                ], MonitoringActivityList);
                return MonitoringActivityList;
            }());
        })(activities = monitoring.activities || (monitoring.activities = {}));
    })(monitoring = app.monitoring || (app.monitoring = {}));
})(app || (app = {}));
var app;
(function (app) {
    var monitoring;
    (function (monitoring) {
        var CaseAssessmentMeeting = (function () {
            function CaseAssessmentMeeting(common, datacontext) {
                this.common = common;
                this.datacontext = datacontext;
                this.isSubmitting = false;
                this.meeting = {};
                'ngInject';
                'ngInject';
            }
            CaseAssessmentMeeting.prototype.$onInit = function () {
                this.maxDate = moment().toDate();
                if (this.activity) {
                    this.getCAM();
                }
                else {
                    this.meeting.involvementId = this.involvementId;
                    this.meeting.source = this.source;
                    this.meeting.sourceId = this.sourceId;
                    this.meeting.meetingDate = moment().toDate();
                }
                this.getStatus();
                this.returnSref = this.returnSref || '^.list';
            };
            CaseAssessmentMeeting.prototype.getCAM = function () {
                var _this = this;
                this.datacontext.involvements
                    .getCAM(this.involvementId, this.activity.id)
                    .then(function (result) { return _this.meeting = result; });
            };
            CaseAssessmentMeeting.prototype.getStatus = function () {
                var _this = this;
                return this.datacontext.monitoring.getMonitoringStatus(this.sourceId).then(function (result) {
                    var data = result;
                    if (data.status) {
                        _this.selectedStatus = data.status;
                        _this.meeting.statusDescription = data.statusDescription;
                    }
                });
            };
            CaseAssessmentMeeting.prototype.submit = function () {
                var _this = this;
                this.isSubmitting = true;
                if (typeof this.meeting.meetingDate !== 'string') {
                    this.meeting.meetingDate = this.meeting.meetingDate.toDateString();
                }
                if (this.meeting.communicationDate && typeof this.meeting.communicationDate !== 'string') {
                    this.meeting.communicationDate = this.meeting.communicationDate.toDateString();
                }
                this.meeting.status = this.selectedStatus;
                if (this.selectedStatus && this.selectedStatus.indexOf('Other') === -1) {
                    this.meeting.statusDescription = null;
                }
                var promise = this.meeting.id
                    ? this.datacontext.involvements.updateCaseAssessmentMeeting(this.meeting)
                    : this.datacontext.involvements.createCaseAssessmentMeeting(this.meeting);
                promise
                    .then(function (request) {
                    if (request) {
                        _this.common.$mdToast.showSimple('Case Assessment Meeting ' + (_this.meeting.id ? 'updated' : 'created'));
                        _this.common.$timeout(function () { _this.common.$rootScope.$broadcast('refreshBasicInfo'); }, 2000);
                        _this.common.$state.go(_this.returnSref, { involvementId: _this.involvementId, sourceId: _this.sourceId });
                    }
                })
                    .finally(function () { return _this.isSubmitting = false; });
            };
            CaseAssessmentMeeting.$inject = ['common', 'datacontext'];
            CaseAssessmentMeeting = __decorate([
                app.Component('app.monitoring', 'ceMonitoringCaseAssessmentMeeting', {
                    templateUrl: 'app/monitoring/activities/case-assessment-meeting/case-assessment-meeting.html',
                    bindings: {
                        involvementId: '<',
                        source: '@',
                        sourceId: '<',
                        returnSref: '@',
                        showCommunicationDate: '@',
                        activity: '<'
                    }
                })
            ], CaseAssessmentMeeting);
            return CaseAssessmentMeeting;
        }());
    })(monitoring = app.monitoring || (app.monitoring = {}));
})(app || (app = {}));
var app;
(function (app) {
    var monitoring;
    (function (monitoring) {
        var CaseReview = (function () {
            function CaseReview(common, datacontext) {
                this.common = common;
                this.datacontext = datacontext;
                this.isSubmitting = false;
                this.review = {};
                'ngInject';
                'ngInject';
            }
            CaseReview.prototype.$onInit = function () {
                this.maxDate = moment().toDate();
                if (this.activity) {
                    this.review.id = this.activity.id;
                    this.review.caseId = this.activity.sourceId;
                    this.review.involvementId = this.activity.involvementId;
                    this.review.note = this.activity.description;
                    this.review.reviewDate = this.activity.activityDate;
                }
                else {
                    this.getCaseStatus();
                    this.review.involvementId = this.involvementId;
                    this.review.caseId = this.monitoringId;
                    this.review.reviewDate = moment().toDate();
                }
                this.returnSref = this.returnSref || '^.list';
            };
            CaseReview.prototype.getCaseStatus = function () {
                var _this = this;
                return this.datacontext.monitoring.getMonitoringStatus(this.monitoringId).then(function (result) {
                    var data = result;
                    if (data.status) {
                        _this.review.status = data.status;
                    }
                });
            };
            CaseReview.prototype.submit = function () {
                var _this = this;
                this.isSubmitting = true;
                if (typeof this.review.reviewDate !== 'string') {
                    this.review.reviewDate = this.review.reviewDate.toDateString();
                }
                if (this.review.status !== 'Other') {
                    this.review.statusDescription = null;
                }
                var promise = this.review.id
                    ? this.datacontext.monitoring.updateCaseReview(this.review)
                    : this.datacontext.monitoring.createCaseReview(this.review);
                promise
                    .then(function (request) {
                    if (request) {
                        _this.common.$mdToast.showSimple('Monitoring Review ' + (_this.review.id ? 'updated' : 'created'));
                        _this.common.$rootScope.$broadcast('refreshBasicInfo');
                        _this.common.$state.go(_this.returnSref, { involvementId: _this.involvementId, sourceId: _this.monitoringId });
                    }
                })
                    .finally(function () { return _this.isSubmitting = false; });
            };
            CaseReview.$inject = ['common', 'datacontext'];
            CaseReview = __decorate([
                app.Component('app.monitoring', 'ceMonitoringReview', {
                    templateUrl: 'app/monitoring/activities/case-review/case-review.html',
                    bindings: {
                        involvementId: '<',
                        monitoringId: '<',
                        returnSref: '@',
                        activity: '<',
                        caseOpenDate: '<'
                    }
                })
            ], CaseReview);
            return CaseReview;
        }());
    })(monitoring = app.monitoring || (app.monitoring = {}));
})(app || (app = {}));
var app;
(function (app) {
    var monitoring;
    (function (monitoring) {
        var activities;
        (function (activities) {
            var Close = (function () {
                function Close(common, datacontext) {
                    this.common = common;
                    this.datacontext = datacontext;
                    this.statuses = [];
                    this.isSubmitting = false;
                    this.activity = {};
                    'ngInject';
                    'ngInject';
                }
                Close.prototype.$onInit = function () {
                    this.activity.involvementId = this.monitoring.involvementId;
                    this.activity.monitoringId = this.monitoring.id;
                    this.activity.activityDate = moment().toDate();
                    this.maxDate = this.activity.activityDate;
                    this.getMonitoringStatus();
                };
                Close.prototype.getMonitoringStatus = function () {
                    var _this = this;
                    return this.datacontext.monitoring.getMonitoringStatus(this.monitoring.id).then(function (result) {
                        var data = result;
                        if (data.status) {
                            _this.selectedStatus = data.status;
                            _this.activity.statusDescription = data.statusDescription;
                        }
                    });
                };
                Close.prototype.submit = function () {
                    var _this = this;
                    this.isSubmitting = true;
                    if (typeof this.activity.activityDate !== 'string') {
                        this.activity.activityDate = this.activity.activityDate.toDateString();
                    }
                    this.activity.status = this.selectedStatus;
                    if (this.selectedStatus.indexOf('Other') === -1) {
                        this.activity.statusDescription = null;
                    }
                    this.datacontext.monitoring
                        .close(this.activity)
                        .then(function (request) {
                        if (request) {
                            _this.common.$mdToast.showSimple('Monitoring Closed');
                            _this.common.$rootScope.$broadcast('refreshBasicInfo');
                            _this.monitoring.isActive = false;
                            _this.common.$state.go('^.list', { monitoringId: _this.monitoring.id, involvementId: _this.monitoring.involvementId });
                        }
                    })
                        .finally(function () { return _this.isSubmitting = false; });
                };
                Close.$inject = ['common', 'datacontext'];
                Close = __decorate([
                    app.Component('app.monitoring.activities', 'ceMonitoringActivitiesClose', {
                        templateUrl: 'app/monitoring/activities/close/close.html',
                        bindings: {
                            monitoring: '<'
                        }
                    })
                ], Close);
                return Close;
            }());
        })(activities = monitoring.activities || (monitoring.activities = {}));
    })(monitoring = app.monitoring || (app.monitoring = {}));
})(app || (app = {}));
var app;
(function (app) {
    var monitoring;
    (function (monitoring_2) {
        var activities;
        (function (activities_5) {
            var activities = [
                {
                    icon: 'group',
                    text: 'Meeting',
                    sref: '^.meeting',
                    filter: function (i) { return i.isActive; }
                },
                {
                    icon: 'how_to_reg',
                    text: 'Contact / Visit',
                    sref: '^.contact',
                    filter: function (i) { return i.isActive; }
                },
                {
                    icon: 'report_problem',
                    text: 'Issue',
                    sref: '^.issue',
                    filter: function (i) { return i.isActive; }
                },
                {
                    icon: 'playlist_add_check',
                    text: 'Case Review',
                    sref: '^.caseReview',
                    filter: function (i) { return i.isActive; }
                },
                {
                    icon: 'comment',
                    text: 'Note',
                    sref: '^.note',
                    filter: function (i) { return true; }
                },
                {
                    icon: 'folder_open',
                    text: 'Reopen Case',
                    sref: '^.reopen',
                    filter: function (i) { return !i.isActive; }
                },
                {
                    icon: 'highlight_off',
                    text: 'Close Case',
                    sref: '^.close',
                    filter: function (i) { return i.isActive; }
                },
                {
                    icon: 'approval',
                    text: 'Status Change',
                    sref: '^.statusChange',
                    filter: function (i) { return i.isActive; }
                },
            ];
            var NewMonitoringActivityDialogController = (function () {
                function NewMonitoringActivityDialogController($mdDialog, common, monitoringview) {
                    var _this = this;
                    this.$mdDialog = $mdDialog;
                    this.common = common;
                    this.monitoringview = monitoringview;
                    this.filterActivity = function (activity) {
                        return activity.filter(_this.monitoringview);
                    };
                    'ngInject';
                    'ngInject';
                }
                NewMonitoringActivityDialogController.prototype.$onInit = function () {
                    this.filteredActivities = activities.filter(this.filterActivity);
                };
                NewMonitoringActivityDialogController.prototype.selectActivity = function (item) {
                    this.common.$state.go(item.sref, { involvementId: this.monitoringview.involvementId, sourceId: this.monitoringview.id });
                    this.$mdDialog.hide();
                };
                NewMonitoringActivityDialogController.$inject = ['$mdDialog', 'common', 'monitoringview'];
                NewMonitoringActivityDialogController = __decorate([
                    app.Controller('app.monitoring.activities', 'NewMonitoringActivityDialogController')
                ], NewMonitoringActivityDialogController);
                return NewMonitoringActivityDialogController;
            }());
        })(activities = monitoring_2.activities || (monitoring_2.activities = {}));
    })(monitoring = app.monitoring || (app.monitoring = {}));
})(app || (app = {}));
var app;
(function (app) {
    var monitoring;
    (function (monitoring) {
        var activities;
        (function (activities) {
            var Note = (function () {
                function Note(common, datacontext, interrupt) {
                    this.common = common;
                    this.datacontext = datacontext;
                    this.interrupt = interrupt;
                    this.isSubmitting = false;
                    this.note = {};
                    'ngInject';
                    'ngInject';
                }
                Note.prototype.$onInit = function () {
                    this.maxDate = moment().toDate();
                    if (this.activity) {
                        this.note.id = this.activity.id;
                        this.note.monitoringId = this.activity.sourceId;
                        this.note.involvementId = this.activity.involvementId;
                        this.note.title = this.activity.title;
                        this.note.description = this.activity.description;
                        this.note.activityDate = this.activity.activityDate;
                    }
                    else {
                        this.note.involvementId = this.involvementId;
                        this.note.monitoringId = this.monitoringId;
                        this.note.activityDate = moment().toDate();
                        this.getStatus();
                    }
                };
                Note.prototype.getStatus = function () {
                    var _this = this;
                    return this.datacontext.monitoring.getMonitoringStatus(this.monitoringId).then(function (result) {
                        var data = result;
                        if (data.status) {
                            _this.selectedStatus = data.status;
                            _this.note.statusDescription = data.statusDescription;
                        }
                    });
                };
                Note.prototype.submit = function () {
                    var _this = this;
                    this.isSubmitting = true;
                    if (typeof this.note.activityDate !== 'string') {
                        this.note.activityDate = this.note.activityDate.toDateString();
                    }
                    this.note.status = this.selectedStatus;
                    if (this.selectedStatus !== 'Other') {
                        this.note.statusDescription = null;
                    }
                    var promise = this.note.id
                        ? this.datacontext.monitoring.updateNote(this.note)
                        : this.datacontext.monitoring.createNote(this.note);
                    promise
                        .then(function (note) {
                        if (note) {
                            _this.common.$mdToast.showSimple('Note ' + (_this.note.id ? 'updated' : 'created'));
                            _this.common.$rootScope.$broadcast('refreshBasicInfo');
                            _this.common.$state.go('^.list', { monitoringId: _this.monitoringId });
                        }
                    })
                        .finally(function () { return _this.isSubmitting = false; });
                };
                Note.$inject = ['common', 'datacontext', 'interrupt'];
                Note = __decorate([
                    app.Component('app.monitoring.activities', 'ceMonitoringActivitiesNote', {
                        templateUrl: 'app/monitoring/activities/note/note.html',
                        bindings: {
                            involvementId: '<',
                            monitoringId: '<',
                            activity: '<',
                            caseOpenDate: '<'
                        }
                    })
                ], Note);
                return Note;
            }());
        })(activities = monitoring.activities || (monitoring.activities = {}));
    })(monitoring = app.monitoring || (app.monitoring = {}));
})(app || (app = {}));
var app;
(function (app) {
    var monitoring;
    (function (monitoring) {
        var activities;
        (function (activities) {
            var Reopen = (function () {
                function Reopen(common, datacontext) {
                    this.common = common;
                    this.datacontext = datacontext;
                    this.isSubmitting = false;
                    this.activity = {};
                    'ngInject';
                    'ngInject';
                }
                Reopen.prototype.$onInit = function () {
                    this.activity.monitoringId = this.monitoring.id;
                    this.activity.involvementId = this.monitoring.involvementId;
                    this.activity.activityDate = moment().toDate();
                };
                Reopen.prototype.submit = function () {
                    var _this = this;
                    this.isSubmitting = true;
                    if (typeof this.activity.activityDate !== 'string') {
                        this.activity.activityDate = this.activity.activityDate.toDateString();
                    }
                    this.datacontext.monitoring
                        .reopen(this.activity)
                        .then(function (cc) {
                        if (cc) {
                            _this.common.$mdToast.showSimple('Monitoring Reopened');
                            _this.common.$rootScope.$broadcast('refreshBasicInfo');
                            _this.monitoring.isActive = true;
                            _this.common.$state.go('^.list', { monitoringId: _this.monitoring.id, involvementId: _this.monitoring.involvementId });
                        }
                    })
                        .finally(function () { return _this.isSubmitting = false; });
                };
                Reopen.$inject = ['common', 'datacontext'];
                Reopen = __decorate([
                    app.Component('app.monitoring.activities', 'ceMonitoringActivitiesReopen', {
                        templateUrl: 'app/monitoring/activities/reopen/reopen.html',
                        bindings: {
                            monitoring: '<'
                        }
                    })
                ], Reopen);
                return Reopen;
            }());
        })(activities = monitoring.activities || (monitoring.activities = {}));
    })(monitoring = app.monitoring || (app.monitoring = {}));
})(app || (app = {}));
var app;
(function (app) {
    var monitoring;
    (function (monitoring) {
        var details;
        (function (details) {
            var MonitoringInformationForm = (function () {
                function MonitoringInformationForm(datacontext) {
                    this.datacontext = datacontext;
                    this.isLoading = false;
                    this.isSubmitting = false;
                    'ngInject';
                    'ngInject';
                }
                MonitoringInformationForm.prototype.$onInit = function () {
                    var _this = this;
                    this.isLoading = true;
                    this.getDisabilities();
                    this.getLegalTeams();
                    this.getLawCategoriesAndSubcategories().then(function () { return _this.getData(); });
                };
                MonitoringInformationForm.prototype.getDisabilities = function () {
                    var _this = this;
                    this.datacontext.config.lookups.getLookupType('monitoring-disabilities').then(function (result) { return _this.disabilities = result.lookups.map(function (l) { return l.value; }); });
                };
                MonitoringInformationForm.prototype.getLegalTeams = function () {
                    var _this = this;
                    this.datacontext.config.legalTeams.getLegalTeams(true).then(function (result) { return _this.legalTeams = result; });
                };
                MonitoringInformationForm.prototype.lawCategoryChanged = function () {
                    this.filterLawSubcategories();
                };
                MonitoringInformationForm.prototype.getLawCategoriesAndSubcategories = function () {
                    var _this = this;
                    return this.datacontext.config.getLawCategories()
                        .then(function (lawCategories) {
                        _this.lawCategories = lawCategories;
                        return _this.datacontext.config.getLawSubcategories().then(function (lawSubcategories) {
                            _this.lawSubcategories = lawSubcategories.data;
                        });
                    });
                };
                MonitoringInformationForm.prototype.getData = function () {
                    var _this = this;
                    this.isLoading = true;
                    this.datacontext.monitoring
                        .getMonitoringInformation(this.monitoringId)
                        .then(function (result) {
                        _this.data = result;
                        _this.filterLawSubcategories();
                        _this.isLoading = false;
                    })
                        .finally(function () { return _this.isLoading = false; });
                };
                MonitoringInformationForm.prototype.filterLawSubcategories = function () {
                    var _this = this;
                    this.validLawSubcategories = _.filter(this.lawSubcategories, function (ct) {
                        return ct.lawCategoryId === _this.data.lawCategoryId;
                    });
                };
                MonitoringInformationForm.prototype.submit = function () {
                    var _this = this;
                    this.isSubmitting = true;
                    this.datacontext.monitoring
                        .updateMonitoringInformation(this.data)
                        .then(function (result) {
                        if (result)
                            _this.onSubmit({ result: result });
                    }).finally(function () { return _this.isSubmitting = false; });
                };
                MonitoringInformationForm.prototype.disabilityToggle = function (value) {
                    if (value === '') {
                        return;
                    }
                    if (this.disabilityExists(value)) {
                        _.pull(this.data.disabilities, value);
                    }
                    else {
                        this.data.disabilities.push(value);
                    }
                };
                MonitoringInformationForm.prototype.disabilityExists = function (value) {
                    if (this.isLoading) {
                        return;
                    }
                    return _.includes(this.data.disabilities, value);
                };
                MonitoringInformationForm.$inject = ['datacontext'];
                MonitoringInformationForm = __decorate([
                    app.Component('app.monitoring.details', 'ceMonitoringInformationForm', {
                        templateUrl: 'app/monitoring/details/monitoring-information/monitoring-information-form.html',
                        bindings: {
                            monitoringId: '<',
                            bypassCensus: '<',
                            onCancel: '&',
                            onSubmit: '&'
                        }
                    })
                ], MonitoringInformationForm);
                return MonitoringInformationForm;
            }());
        })(details = monitoring.details || (monitoring.details = {}));
    })(monitoring = app.monitoring || (app.monitoring = {}));
})(app || (app = {}));
var app;
(function (app) {
    var monitoring;
    (function (monitoring) {
        var details;
        (function (details) {
            var MonitoringInformation = (function () {
                function MonitoringInformation() {
                }
                MonitoringInformation = __decorate([
                    app.Component('app.monitoring.details', 'ceMonitoringInformation', {
                        templateUrl: 'app/monitoring/details/monitoring-information/monitoring-information.html',
                        bindings: {
                            data: '<',
                            bypassCensus: '<',
                            onEdit: '&'
                        }
                    })
                ], MonitoringInformation);
                return MonitoringInformation;
            }());
        })(details = monitoring.details || (monitoring.details = {}));
    })(monitoring = app.monitoring || (app.monitoring = {}));
})(app || (app = {}));
var app;
(function (app) {
    var preferences;
    (function (preferences) {
        var FavoriteToggleComponent = (function () {
            function FavoriteToggleComponent(preferenceService) {
                var _this = this;
                this.preferenceService = preferenceService;
                this.$onChanges = function () {
                    var index = _.findIndex(_this.preferenceService.favorites, function (f) { return f.type === _this.type && f.id === _this.id; });
                    _this.isFavorite = index > -1;
                };
                'ngInject';
                'ngInject';
            }
            FavoriteToggleComponent.prototype.toggle = function () {
                var _this = this;
                if (this.isFavorite) {
                    var index = _.findIndex(this.preferenceService.favorites, function (f) { return f.type === _this.type && f.id === _this.id; });
                    this.preferenceService.favorites.splice(index, 1);
                }
                else {
                    this.preferenceService.favorites.unshift({ type: this.type, state: this.state, param: this.param, id: this.id, title: this.maintitle, subtitle: this.subtitle });
                }
                this.preferenceService.save();
                this.isFavorite = !this.isFavorite;
            };
            FavoriteToggleComponent.$inject = ['preferenceService'];
            FavoriteToggleComponent = __decorate([
                app.Component('app.preferences', 'ceFavoriteToggle', {
                    bindings: {
                        type: '@',
                        state: '@',
                        param: '@',
                        id: '<',
                        maintitle: '@',
                        subtitle: '@'
                    },
                    template: "\n      <md-button class=\"md-icon-button\" ng-click=\"$ctrl.toggle()\" ng-if=\"!$ctrl.isFavorite\">\n        <md-tooltip>Add favorite</md-tooltip>\n        <md-icon aria-hidden=\"true\">star_border</md-icon>\n      </md-button>\n      <md-button class=\"md-icon-button\" ng-click=\"$ctrl.toggle()\" ng-if=\"$ctrl.isFavorite\">\n        <md-tooltip>Remove favorite</md-tooltip>\n        <md-icon aria-hidden=\"true\">star</md-icon>\n      </md-button>"
                })
            ], FavoriteToggleComponent);
            return FavoriteToggleComponent;
        }());
    })(preferences = app.preferences || (app.preferences = {}));
})(app || (app = {}));
var app;
(function (app) {
    var preferences;
    (function (preferences) {
        var favorites;
        (function (favorites) {
            var FavoritesButtonComponent = (function () {
                function FavoritesButtonComponent($mdSidenav, common) {
                    this.$mdSidenav = $mdSidenav;
                    this.common = common;
                    'ngInject';
                    'ngInject';
                }
                FavoritesButtonComponent.prototype.open = function () {
                    var sidenav = this.$mdSidenav('favorites');
                    if (sidenav.isOpen()) {
                        sidenav.close();
                    }
                    else {
                        sidenav.open();
                    }
                };
                FavoritesButtonComponent.$inject = ['$mdSidenav', 'common'];
                FavoritesButtonComponent = __decorate([
                    app.Component('app.preferences.favorites', 'ceFavoritesButton', {
                        template: "\n<md-button aria-label=\"Favorites\"\n           class=\"md-icon-button\"\n           ng-click=\"$ctrl.open($event)\">\n   <md-tooltip>Favorites</md-tooltip>\n   <md-icon>star</md-icon>\n</md-button>"
                    })
                ], FavoritesButtonComponent);
                return FavoritesButtonComponent;
            }());
        })(favorites = preferences.favorites || (preferences.favorites = {}));
    })(preferences = app.preferences || (app.preferences = {}));
})(app || (app = {}));
var app;
(function (app) {
    var preferences;
    (function (preferences) {
        var favorites;
        (function (favorites) {
            var FavoritesSidebarComponent = (function () {
                function FavoritesSidebarComponent($mdSidenav, common, datacontext, preferenceService) {
                    var _this = this;
                    this.$mdSidenav = $mdSidenav;
                    this.common = common;
                    this.datacontext = datacontext;
                    this.preferenceService = preferenceService;
                    this.loading = false;
                    this.getFavorites = function () {
                        _this.preferenceService.loadPreferences().then(function () { return _this.favoritesList = _this.preferenceService.favorites; });
                    };
                    'ngInject';
                    'ngInject';
                }
                FavoritesSidebarComponent.prototype.removeFavorite = function (favorite) {
                    var index = _.findIndex(this.preferenceService.favorites, function (f) { return f.type === favorite.type && f.id === favorite.id; });
                    this.preferenceService.favorites.splice(index, 1);
                    this.preferenceService.save();
                    _.remove(this.favoritesList, favorite);
                };
                FavoritesSidebarComponent.prototype.click = function (favorite) {
                    var _a;
                    this.common.$state.go(favorite.state, (_a = {}, _a[favorite.param] = favorite.id, _a));
                    this.$mdSidenav('favorites').close();
                };
                FavoritesSidebarComponent.prototype.$onInit = function () {
                    this.getFavorites();
                };
                FavoritesSidebarComponent.prototype.refresh = function () {
                    this.getFavorites();
                };
                FavoritesSidebarComponent.$inject = ['$mdSidenav', 'common', 'datacontext', 'preferenceService'];
                FavoritesSidebarComponent = __decorate([
                    app.Component('app.preferences.favorites', 'ceFavoritesSidebar', {
                        templateUrl: 'app/preferences/favorites/favorites-sidebar/favorites-sidebar.html'
                    })
                ], FavoritesSidebarComponent);
                return FavoritesSidebarComponent;
            }());
        })(favorites = preferences.favorites || (preferences.favorites = {}));
    })(preferences = app.preferences || (app.preferences = {}));
})(app || (app = {}));
var app;
(function (app) {
    var projects;
    (function (projects) {
        var activities;
        (function (activities_6) {
            var activities = [
                {
                    icon: 'comment',
                    text: 'Note',
                    sref: '^.note',
                }
            ];
            var NewProjectActivityDialogController = (function () {
                function NewProjectActivityDialogController($mdDialog, common, projectId) {
                    this.$mdDialog = $mdDialog;
                    this.common = common;
                    this.projectId = projectId;
                    'ngInject';
                    'ngInject';
                }
                NewProjectActivityDialogController.prototype.$onInit = function () {
                    this.filteredActivities = activities;
                };
                NewProjectActivityDialogController.prototype.selectActivity = function (item) {
                    this.common.$state.go(item.sref, { projectId: this.projectId });
                    this.$mdDialog.hide();
                };
                NewProjectActivityDialogController.$inject = ['$mdDialog', 'common', 'projectId'];
                NewProjectActivityDialogController = __decorate([
                    app.Controller('app.projects.activities', 'NewProjectActivityDialogController')
                ], NewProjectActivityDialogController);
                return NewProjectActivityDialogController;
            }());
        })(activities = projects.activities || (projects.activities = {}));
    })(projects = app.projects || (app.projects = {}));
})(app || (app = {}));
var app;
(function (app) {
    var projects;
    (function (projects) {
        var activities;
        (function (activities) {
            var ProjectActivityList = (function () {
                function ProjectActivityList($mdDialog, common, datacontext) {
                    var _this = this;
                    this.$mdDialog = $mdDialog;
                    this.common = common;
                    this.datacontext = datacontext;
                    this.showFilters = false;
                    this.selectedActivityTypes = [];
                    this.activityFilter = {};
                    this.paging = {
                        page: 1,
                        pageSize: 100
                    };
                    this.refresh = function () {
                        _this.getActivities();
                    };
                    'ngInject';
                    'ngInject';
                }
                ProjectActivityList.prototype.$onInit = function () {
                    this.activityFilter.sourceId = this.projectId;
                    this.activityFilter.source = 'Project';
                    this.getActivities();
                };
                ProjectActivityList.prototype.getActivities = function () {
                    var _this = this;
                    if (!this.filtersInitialized()) {
                        return;
                    }
                    this.activityFilter = __assign(__assign(__assign({}, this.activityFilter), this.paging), { activityTypes: this.selectedActivityTypes, q: this.noteFilter });
                    this.promise = this.datacontext.activities
                        .searchActivities(this.activityFilter)
                        .then(function (result) {
                        _this.activities = result;
                    });
                };
                ProjectActivityList.prototype.newActivity = function (targetEvent) {
                    var parent = angular.element(document.body);
                    this.$mdDialog.show({
                        parent: parent,
                        targetEvent: targetEvent,
                        clickOutsideToClose: true,
                        templateUrl: 'app/projects/activities/new-activity/new-activity.html',
                        controller: 'NewProjectActivityDialogController',
                        controllerAs: '$ctrl',
                        locals: {
                            projectId: this.projectId
                        }
                    });
                };
                ProjectActivityList.prototype.toggleFilters = function () {
                    this.showFilters = !this.showFilters;
                };
                ProjectActivityList.prototype.filtersInitialized = function () {
                    return this.activityTypeFilterInitialized;
                };
                ProjectActivityList.prototype.activityTypeFilterChanged = function (activityTypes) {
                    this.selectedActivityTypes = activityTypes;
                    this.activityTypeFilterInitialized = true;
                    this.getActivities();
                };
                ProjectActivityList.prototype.onEdit = function (activity) {
                    switch (activity.activityType) {
                        case 'Note':
                            this.common.$state.go('^.note', { activity: activity });
                            break;
                    }
                };
                ProjectActivityList.$inject = ['$mdDialog', 'common', 'datacontext'];
                ProjectActivityList = __decorate([
                    app.Component('app.projects.activities', 'ceProjectActivityList', {
                        templateUrl: 'app/projects/activities/activity-list/activity-list.html',
                        bindings: {
                            projectId: '<'
                        }
                    })
                ], ProjectActivityList);
                return ProjectActivityList;
            }());
        })(activities = projects.activities || (projects.activities = {}));
    })(projects = app.projects || (app.projects = {}));
})(app || (app = {}));
var app;
(function (app) {
    var projects;
    (function (projects) {
        var activities;
        (function (activities) {
            var Note = (function () {
                function Note(common, datacontext, interrupt) {
                    this.common = common;
                    this.datacontext = datacontext;
                    this.interrupt = interrupt;
                    this.isSubmitting = false;
                    this.note = {};
                    'ngInject';
                    'ngInject';
                }
                Note.prototype.$onInit = function () {
                    this.maxDate = moment().toDate();
                    if (this.activity) {
                        this.note.id = this.activity.id;
                        this.note.projectId = this.activity.sourceId;
                        this.note.title = this.activity.title;
                        this.note.description = this.activity.description;
                        this.note.activityDate = this.activity.activityDate;
                    }
                    else {
                        this.note.projectId = this.projectId;
                        this.note.activityDate = moment().toDate();
                    }
                };
                Note.prototype.submit = function () {
                    var _this = this;
                    this.isSubmitting = true;
                    if (typeof this.note.activityDate !== 'string') {
                        this.note.activityDate = this.note.activityDate.toDateString();
                    }
                    var promise = this.note.id
                        ? this.datacontext.projects.updateNote(this.note)
                        : this.datacontext.projects.createNote(this.note);
                    promise
                        .then(function (note) {
                        if (note) {
                            _this.common.$mdToast.showSimple('Note ' + (_this.note.id ? 'updated' : 'created'));
                            _this.common.$state.go('^.list', { projectId: _this.projectId });
                        }
                    })
                        .finally(function () { return _this.isSubmitting = false; });
                };
                Note.$inject = ['common', 'datacontext', 'interrupt'];
                Note = __decorate([
                    app.Component('app.projects.activities', 'ceProjectsActivitiesNote', {
                        templateUrl: 'app/projects/activities/note/note.html',
                        bindings: {
                            projectId: '<',
                            activity: '<',
                            caseOpenDate: '<'
                        }
                    })
                ], Note);
                return Note;
            }());
        })(activities = projects.activities || (projects.activities = {}));
    })(projects = app.projects || (app.projects = {}));
})(app || (app = {}));
var app;
(function (app) {
    var projects;
    (function (projects) {
        var ProjectEntityRoles = (function () {
            function ProjectEntityRoles(common, datacontext) {
                var _this = this;
                this.common = common;
                this.datacontext = datacontext;
                this.selectedRoles = [];
                this.projectEntity = {};
                this.$onInit = function () {
                    _this.getRoles().then(function () { return _this.getExistingRoles(); });
                };
                this.getRoles = function () {
                    var lookupTypeId = '';
                    if (_this.entity.isUser) {
                        lookupTypeId = 'project-user-role';
                    }
                    else {
                        lookupTypeId = "project-".concat(_this.entity.entityType, "-role");
                    }
                    return _this.datacontext.config.lookups.getLookupType(lookupTypeId).then(function (result) {
                        _this.roles = result.lookups;
                    });
                };
                this.getExistingRoles = function () {
                    return _this.datacontext.projects.getEntity(_this.projectId, _this.entity.entityId).then(function (result) {
                        _this.projectEntity = result;
                        _.forEach(_this.projectEntity.roles, function (ieRole) {
                            var existingLookup = _.find(_this.roles, function (role) {
                                return role.value === ieRole;
                            });
                            if (existingLookup) {
                                _this.selectedRoles.push(existingLookup);
                            }
                            else {
                                _this.selectedRoles.push({ lookupTypeId: 'roles', isActive: false, value: ieRole });
                            }
                        });
                    });
                };
                this.onSubmit = function () {
                    _this.isSubmitting = true;
                    var roles = _.map(_this.selectedRoles, 'value');
                    angular.extend(_this.projectEntity, {
                        roles: roles
                    });
                    var promise;
                    promise = _this.datacontext.projects.updateEntity(_this.projectEntity);
                    promise.finally(function () {
                        _this.isSubmitting = false;
                        _this.common.$mdToast.showSimple('Entity Updated');
                        _this.common.$state.go('^.listing');
                    });
                };
                'ngInject';
                'ngInject';
            }
            ProjectEntityRoles.prototype.transformChip = function (chip) {
                if (angular.isObject(chip)) {
                    return chip;
                }
                return { name: chip, type: 'new' };
            };
            ProjectEntityRoles.prototype.querySearch = function (query) {
                var results = query ? _.filter(this.roles, function (r) {
                    return query === '*' || r.value.toLowerCase().indexOf(query) > -1;
                }) : [];
                return results;
            };
            ProjectEntityRoles.$inject = ['common', 'datacontext'];
            ProjectEntityRoles = __decorate([
                app.Component('app.projects.entities', 'ceEditProjectEntityRoles', {
                    templateUrl: 'app/projects/entities/roles/edit-roles.html',
                    bindings: {
                        entity: '<',
                        projectId: '<'
                    }
                })
            ], ProjectEntityRoles);
            return ProjectEntityRoles;
        }());
    })(projects = app.projects || (app.projects = {}));
})(app || (app = {}));
var app;
(function (app) {
    var projects;
    (function (projects) {
        var ProjectEntityRoles = (function () {
            function ProjectEntityRoles(common, datacontext) {
                var _this = this;
                this.common = common;
                this.datacontext = datacontext;
                this.selectedRoles = [];
                this.projectEntity = {};
                this.$onInit = function () {
                    _this.getRoles().then(function () { return _this.getExistingRoles(); });
                };
                this.getRoles = function () {
                    var lookupTypeId = '';
                    if (_this.entity.isUser) {
                        lookupTypeId = 'project-user-role';
                    }
                    else {
                        lookupTypeId = "project-".concat(_this.entity.entityType, "-role");
                    }
                    return _this.datacontext.config.lookups.getLookupType(lookupTypeId).then(function (result) {
                        _this.roles = result.lookups;
                    });
                };
                this.getExistingRoles = function () {
                    return _this.datacontext.projects.getEntity(_this.projectId, _this.entity.entityId).then(function (result) {
                        angular.extend(_this.projectEntity, result);
                        _.forEach(_this.projectEntity.roles, function (ieRole) {
                            _this.selectedRoles.push(_.find(_this.roles, function (role) {
                                return role.value === ieRole;
                            }));
                        });
                    });
                };
                this.onSubmit = function () {
                    _this.isSubmitting = true;
                    var roles = _.map(_this.selectedRoles, 'value');
                    angular.extend(_this.projectEntity, {
                        entityId: _this.entity.entityId,
                        projectId: _this.projectId,
                        roles: roles
                    });
                    var promise;
                    if (_this.projectEntity.id) {
                        promise = _this.datacontext.projects.updateEntity(_this.projectEntity);
                    }
                    else {
                        promise = _this.datacontext.projects.createEntity(_this.projectEntity);
                    }
                    promise.finally(function () {
                        _this.isSubmitting = false;
                        _this.common.$mdToast.showSimple('Entity Updated');
                        _this.common.$state.go('^.^.^');
                    });
                };
                'ngInject';
                'ngInject';
            }
            ProjectEntityRoles.prototype.transformChip = function (chip) {
                if (angular.isObject(chip)) {
                    return chip;
                }
                return { name: chip, type: 'new' };
            };
            ProjectEntityRoles.prototype.querySearch = function (query) {
                var results = query ? _.filter(this.roles, function (r) {
                    return query === '*' || r.value.toLowerCase().indexOf(query) > -1;
                }) : [];
                return results;
            };
            ProjectEntityRoles.$inject = ['common', 'datacontext'];
            ProjectEntityRoles = __decorate([
                app.Component('app.projects.entities', 'ceProjectEntityRoles', {
                    templateUrl: 'app/projects/entities/roles/roles.html',
                    bindings: {
                        entity: '<',
                        projectId: '<'
                    }
                })
            ], ProjectEntityRoles);
            return ProjectEntityRoles;
        }());
    })(projects = app.projects || (app.projects = {}));
})(app || (app = {}));
var app;
(function (app) {
    var projects;
    (function (projects) {
        var ProjectEntityList = (function () {
            function ProjectEntityList() {
            }
            ProjectEntityList = __decorate([
                app.Component('app.projects', 'ceProjectEntityList', {
                    templateUrl: 'app/projects/entities/entity-listing/entity-list.html',
                    bindings: {
                        entities: '<',
                        totalEntities: '<',
                        projectId: '<',
                        onDelete: '&'
                    }
                })
            ], ProjectEntityList);
            return ProjectEntityList;
        }());
    })(projects = app.projects || (app.projects = {}));
})(app || (app = {}));
var app;
(function (app) {
    var projects;
    (function (projects) {
        var ProjectEntityListing = (function () {
            function ProjectEntityListing($mdDialog, common, datacontext) {
                var _this = this;
                this.$mdDialog = $mdDialog;
                this.common = common;
                this.datacontext = datacontext;
                this.$onInit = function () {
                    _this.getEntities();
                };
                this.getEntities = function () {
                    return _this.datacontext.projects
                        .getEntityListing(_this.projectId)
                        .then(function (result) {
                        _this.entities = result;
                    });
                };
                'ngInject';
                'ngInject';
            }
            ProjectEntityListing.prototype.deleteEntity = function (entity) {
                var _this = this;
                this.common.confirmation.show({
                    title: 'Delete Relationship',
                    message: 'Are you sure you want to delete this entity relationship?',
                    ok: 'Delete',
                    cancel: 'Keep'
                }).then(function () {
                    _this.datacontext.projects
                        .deleteEntity(entity.id)
                        .then(function () {
                        _this.getEntities();
                    });
                }).catch(angular.noop);
            };
            ProjectEntityListing.$inject = ['$mdDialog', 'common', 'datacontext'];
            ProjectEntityListing = __decorate([
                app.Component('app.projects', 'ceProjectEntityListing', {
                    templateUrl: 'app/projects/entities/entity-listing/entity-listing.html',
                    bindings: {
                        projectId: '<'
                    }
                })
            ], ProjectEntityListing);
            return ProjectEntityListing;
        }());
    })(projects = app.projects || (app.projects = {}));
})(app || (app = {}));
var app;
(function (app) {
    var requests;
    (function (requests) {
        var activities;
        (function (activities) {
            var RequestActivityList = (function () {
                function RequestActivityList($mdDialog, common, datacontext) {
                    var _this = this;
                    this.$mdDialog = $mdDialog;
                    this.common = common;
                    this.datacontext = datacontext;
                    this.activityFilter = {};
                    this.showFilters = false;
                    this.selectedActivityTypes = [];
                    this.paging = {
                        page: 1,
                        pageSize: 100
                    };
                    this.refresh = function () {
                        _this.getActivities();
                    };
                    'ngInject';
                    'ngInject';
                }
                RequestActivityList.prototype.$onInit = function () {
                    this.activityFilter.involvementId = this.request.involvementId;
                    this.activityFilter.source = 'Request';
                    this.activityFilter.sourceId = this.request.id;
                    this.getActivities();
                };
                RequestActivityList.prototype.getActivities = function () {
                    var _this = this;
                    if (!this.filtersInitialized()) {
                        return;
                    }
                    if (!this.showInvolvementActivities) {
                        this.activityFilter.sourceId = this.request.id;
                        this.activityFilter.source = 'Request';
                    }
                    else {
                        this.activityFilter.sourceId = null;
                        this.activityFilter.source = null;
                    }
                    this.activityFilter = __assign(__assign(__assign({}, this.activityFilter), this.paging), { activityTypes: this.selectedActivityTypes, q: this.noteFilter });
                    this.promise = this.datacontext.activities
                        .searchActivities(this.activityFilter)
                        .then(function (result) {
                        _this.activities = result;
                    });
                };
                RequestActivityList.prototype.getInvolvementActivities = function () {
                    this.showInvolvementActivities = !this.showInvolvementActivities;
                    this.getActivities();
                };
                RequestActivityList.prototype.newActivity = function (targetEvent) {
                    var parent = angular.element(document.body);
                    this.$mdDialog.show({
                        parent: parent,
                        targetEvent: targetEvent,
                        clickOutsideToClose: true,
                        templateUrl: 'app/requests/activities/new-activity/new-activity.html',
                        controller: 'NewRequestActivityDialogController',
                        controllerAs: '$ctrl',
                        locals: {
                            request: this.request
                        }
                    });
                };
                RequestActivityList.prototype.toggleFilters = function () {
                    this.showFilters = !this.showFilters;
                };
                RequestActivityList.prototype.filtersInitialized = function () {
                    return this.activityTypeFilterInitialized;
                };
                RequestActivityList.prototype.activityTypeFilterChanged = function (activityTypes) {
                    this.selectedActivityTypes = activityTypes;
                    this.activityTypeFilterInitialized = true;
                    this.getActivities();
                };
                RequestActivityList.prototype.onEdit = function (activity) {
                    switch (activity.activityType) {
                        case 'CaseAssessmentMeeting':
                            this.common.$state.go('^.caseAssessmentMeeting', { activity: activity });
                            break;
                        case 'CaseReview':
                            this.common.$state.go('^.review', { activity: activity });
                            break;
                        case 'FollowUp':
                            this.common.$state.go('^.followUp', { activity: activity });
                            break;
                        case 'Note':
                            this.common.$state.go('^.note', { activity: activity });
                            break;
                        case 'StatusChange':
                            this.common.$state.go('^.statusChange', { activityId: activity.id });
                            break;
                    }
                };
                RequestActivityList.$inject = ['$mdDialog', 'common', 'datacontext'];
                RequestActivityList = __decorate([
                    app.Component('app.requests.activities', 'ceRequestActivityList', {
                        templateUrl: 'app/requests/activities/activity-list/activity-list.html',
                        bindings: {
                            request: '<'
                        }
                    })
                ], RequestActivityList);
                return RequestActivityList;
            }());
        })(activities = requests.activities || (requests.activities = {}));
    })(requests = app.requests || (app.requests = {}));
})(app || (app = {}));
var app;
(function (app) {
    var requests;
    (function (requests) {
        var CaseAssessmentMeeting = (function () {
            function CaseAssessmentMeeting(common, datacontext) {
                this.common = common;
                this.datacontext = datacontext;
                this.isSubmitting = false;
                this.meeting = {};
                'ngInject';
                'ngInject';
            }
            CaseAssessmentMeeting.prototype.$onInit = function () {
                this.maxDate = moment().toDate();
                if (this.activity) {
                    this.getCAM();
                }
                else {
                    this.meeting.involvementId = this.involvementId;
                    this.meeting.source = this.source;
                    this.meeting.sourceId = this.sourceId;
                    this.meeting.meetingDate = moment().toDate();
                }
                this.getStatus();
                this.returnSref = this.returnSref || '^.list';
            };
            CaseAssessmentMeeting.prototype.getCAM = function () {
                var _this = this;
                this.datacontext.involvements
                    .getCAM(this.involvementId, this.activity.id)
                    .then(function (result) { return _this.meeting = result; });
            };
            CaseAssessmentMeeting.prototype.getStatus = function () {
                var _this = this;
                return this.datacontext.requests.getRequestStatus(this.sourceId).then(function (result) {
                    var data = result;
                    if (data.status) {
                        _this.selectedStatus = data.status;
                        _this.meeting.statusDescription = data.statusDescription;
                    }
                });
            };
            CaseAssessmentMeeting.prototype.submit = function () {
                var _this = this;
                this.isSubmitting = true;
                if (typeof this.meeting.meetingDate !== 'string') {
                    this.meeting.meetingDate = this.meeting.meetingDate.toDateString();
                }
                if (this.meeting.communicationDate && typeof this.meeting.communicationDate !== 'string') {
                    this.meeting.communicationDate = this.meeting.communicationDate.toDateString();
                }
                this.meeting.status = this.selectedStatus;
                if (this.selectedStatus !== 'Other') {
                    this.meeting.statusDescription = null;
                }
                var promise = this.meeting.id
                    ? this.datacontext.involvements.updateCaseAssessmentMeeting(this.meeting)
                    : this.datacontext.involvements.createCaseAssessmentMeeting(this.meeting);
                promise
                    .then(function (request) {
                    if (request) {
                        _this.common.$mdToast.showSimple('Case Assessment Meeting ' + (_this.meeting.id ? 'updated' : 'created'));
                        _this.common.$timeout(function () { _this.common.$rootScope.$broadcast('refreshBasicInfo'); }, 2000);
                        _this.common.$state.go(_this.returnSref, { involvementId: _this.involvementId, sourceId: _this.sourceId });
                    }
                })
                    .finally(function () { return _this.isSubmitting = false; });
            };
            CaseAssessmentMeeting.$inject = ['common', 'datacontext'];
            CaseAssessmentMeeting = __decorate([
                app.Component('app.requests', 'ceRequestCaseAssessmentMeeting', {
                    templateUrl: 'app/requests/activities/case-assessment-meeting/case-assessment-meeting.html',
                    bindings: {
                        involvementId: '<',
                        source: '@',
                        sourceId: '<',
                        returnSref: '@',
                        showCommunicationDate: '@',
                        activity: '<',
                        caseOpenDate: '<'
                    }
                })
            ], CaseAssessmentMeeting);
            return CaseAssessmentMeeting;
        }());
    })(requests = app.requests || (app.requests = {}));
})(app || (app = {}));
var app;
(function (app) {
    var requests;
    (function (requests) {
        var activities;
        (function (activities) {
            var FollowUp = (function () {
                function FollowUp(common, datacontext) {
                    this.common = common;
                    this.datacontext = datacontext;
                    this.isSubmitting = false;
                    this.followUp = {};
                    'ngInject';
                    'ngInject';
                }
                FollowUp.prototype.$onInit = function () {
                    this.maxDate = moment().toDate();
                    if (this.activity) {
                        this.followUp.id = this.activity.id;
                        this.followUp.involvementId = this.activity.involvementId;
                        this.followUp.requestId = this.activity.sourceId;
                        this.followUp.description = this.activity.description;
                        this.followUp.activityDate = this.activity.activityDate;
                    }
                    else {
                        this.followUp.involvementId = this.involvementId;
                        this.followUp.requestId = this.requestId;
                        this.followUp.activityDate = moment().toDate();
                        this.getRequestStatus();
                    }
                };
                FollowUp.prototype.getRequestStatus = function () {
                    var _this = this;
                    return this.datacontext.requests.getRequestStatus(this.requestId).then(function (result) {
                        var data = result;
                        if (data.status) {
                            _this.selectedStatus = data.status;
                            _this.followUp.statusDescription = data.statusDescription;
                        }
                    });
                };
                FollowUp.prototype.submit = function () {
                    var _this = this;
                    this.isSubmitting = true;
                    if (typeof this.followUp.activityDate !== 'string') {
                        this.followUp.activityDate = this.followUp.activityDate.toDateString();
                    }
                    this.followUp.status = this.selectedStatus;
                    if (this.selectedStatus !== 'Other') {
                        this.followUp.statusDescription = null;
                    }
                    var promise = this.followUp.id
                        ? this.datacontext.requests.updateFollowUp(this.followUp)
                        : this.datacontext.requests.createFollowUp(this.followUp);
                    promise
                        .then(function (request) {
                        if (request) {
                            _this.common.$mdToast.showSimple('Follow-Up ' + (_this.followUp.id ? 'updated' : 'created'));
                            _this.common.$rootScope.$broadcast('refreshBasicInfo');
                            _this.common.$state.go('^.list', { requestId: _this.requestId });
                        }
                    })
                        .finally(function () { return _this.isSubmitting = false; });
                };
                FollowUp.$inject = ['common', 'datacontext'];
                FollowUp = __decorate([
                    app.Component('app.requests.activities', 'ceRequestsActivitiesFollowUp', {
                        templateUrl: 'app/requests/activities/followUp/FollowUp.html',
                        bindings: {
                            involvementId: '<',
                            requestId: '<',
                            activity: '<',
                            caseOpenDate: '<'
                        }
                    })
                ], FollowUp);
                return FollowUp;
            }());
        })(activities = requests.activities || (requests.activities = {}));
    })(requests = app.requests || (app.requests = {}));
})(app || (app = {}));
var app;
(function (app) {
    var requests;
    (function (requests) {
        var activities;
        (function (activities) {
            var CloseRequest = (function () {
                function CloseRequest(common, datacontext) {
                    this.common = common;
                    this.datacontext = datacontext;
                    this.statuses = [];
                    this.isSubmitting = false;
                    this.activity = {};
                    'ngInject';
                    'ngInject';
                }
                CloseRequest.prototype.$onInit = function () {
                    this.activity.requestId = this.request.id;
                    this.activity.involvementId = this.request.involvementId;
                    this.activity.activityDate = moment().toDate();
                    this.maxDate = this.activity.activityDate;
                    this.getRequestStatus();
                };
                CloseRequest.prototype.getRequestStatus = function () {
                    var _this = this;
                    return this.datacontext.requests.getRequestStatus(this.request.id).then(function (result) {
                        var data = result;
                        if (data.status) {
                            _this.selectedStatus = data.status;
                            _this.activity.statusDescription = data.statusDescription;
                        }
                    });
                };
                CloseRequest.prototype.submit = function () {
                    var _this = this;
                    this.isSubmitting = true;
                    if (typeof this.activity.activityDate !== 'string') {
                        this.activity.activityDate = this.activity.activityDate.toDateString();
                    }
                    this.activity.status = this.selectedStatus;
                    if (this.selectedStatus.indexOf('Other') === -1) {
                        this.activity.statusDescription = null;
                    }
                    this.datacontext.requests
                        .close(this.activity)
                        .then(function (request) {
                        if (request) {
                            _this.common.$mdToast.showSimple('Request Closed');
                            _this.common.$rootScope.$broadcast('refreshBasicInfo');
                            _this.request.isActive = false;
                            _this.common.$state.go('^.list', { requestId: _this.request.id, involvementId: _this.request.involvementId });
                        }
                    })
                        .finally(function () { return _this.isSubmitting = false; });
                };
                CloseRequest.$inject = ['common', 'datacontext'];
                CloseRequest = __decorate([
                    app.Component('app.requests.activities', 'ceRequestsActivitiesClose', {
                        templateUrl: 'app/requests/activities/close/close.html',
                        bindings: {
                            request: '<'
                        }
                    })
                ], CloseRequest);
                return CloseRequest;
            }());
        })(activities = requests.activities || (requests.activities = {}));
    })(requests = app.requests || (app.requests = {}));
})(app || (app = {}));
var app;
(function (app) {
    var requests;
    (function (requests) {
        var activities;
        (function (activities) {
            var Note = (function () {
                function Note(common, datacontext, interrupt) {
                    this.common = common;
                    this.datacontext = datacontext;
                    this.interrupt = interrupt;
                    this.isSubmitting = false;
                    this.note = {};
                    'ngInject';
                    'ngInject';
                }
                Note.prototype.$onInit = function () {
                    this.maxDate = moment().toDate();
                    if (this.activity) {
                        this.note.id = this.activity.id;
                        this.note.requestId = this.activity.sourceId;
                        this.note.involvementId = this.activity.involvementId;
                        this.note.title = this.activity.title;
                        this.note.description = this.activity.description;
                        this.note.activityDate = this.activity.activityDate;
                    }
                    else {
                        this.note.involvementId = this.involvementId;
                        this.note.requestId = this.requestId;
                        this.note.activityDate = moment().toDate();
                        this.getRequestStatus();
                    }
                };
                Note.prototype.getRequestStatus = function () {
                    var _this = this;
                    return this.datacontext.requests.getRequestStatus(this.requestId).then(function (result) {
                        var data = result;
                        if (data.status) {
                            _this.selectedStatus = data.status;
                            _this.note.statusDescription = data.statusDescription;
                        }
                    });
                };
                Note.prototype.submit = function () {
                    var _this = this;
                    this.isSubmitting = true;
                    if (typeof this.note.activityDate !== 'string') {
                        this.note.activityDate = this.note.activityDate.toDateString();
                    }
                    this.note.status = this.selectedStatus;
                    if (this.selectedStatus !== 'Other') {
                        this.note.statusDescription = null;
                    }
                    var promise = this.note.id
                        ? this.datacontext.requests.updateNote(this.note)
                        : this.datacontext.requests.createNote(this.note);
                    promise
                        .then(function (request) {
                        if (request) {
                            _this.common.$mdToast.showSimple('Note ' + (_this.note.id ? 'updated' : 'created'));
                            _this.common.$rootScope.$broadcast('refreshBasicInfo');
                            _this.common.$state.go('^.list', { requestId: _this.requestId });
                        }
                    })
                        .finally(function () { return _this.isSubmitting = false; });
                };
                Note.$inject = ['common', 'datacontext', 'interrupt'];
                Note = __decorate([
                    app.Component('app.requests.activities', 'ceRequestsActivitiesNote', {
                        templateUrl: 'app/requests/activities/note/note.html',
                        bindings: {
                            involvementId: '<',
                            requestId: '<',
                            activity: '<',
                            caseOpenDate: '<'
                        }
                    })
                ], Note);
                return Note;
            }());
        })(activities = requests.activities || (requests.activities = {}));
    })(requests = app.requests || (app.requests = {}));
})(app || (app = {}));
var app;
(function (app) {
    var requests;
    (function (requests) {
        var activities;
        (function (activities_7) {
            var activities = [
                {
                    icon: 'history',
                    text: 'Conflict Check',
                    sref: '^.conflictCheck',
                    filter: function (i) { return i.isActive; }
                },
                {
                    icon: 'info',
                    text: 'Convert to I&R',
                    sref: '^.createInformationAndReferral',
                    filter: function (i) { return i.isActive; }
                },
                {
                    icon: 'assignment_turned_in',
                    text: 'Review',
                    sref: '^.review',
                    filter: function (i) { return i.isActive; }
                },
                {
                    icon: 'folder_shared',
                    text: 'Convert to Client Case',
                    sref: '^.createClientCase',
                    filter: function (i) { return i.isActive; }
                },
                {
                    icon: 'record_voice_over',
                    text: 'Follow-Up',
                    sref: '^.followUp',
                    filter: function (i) { return i.isActive; }
                },
                {
                    icon: 'folder',
                    text: 'Convert to Systemic Case',
                    sref: '^.createSystemicCase',
                    filter: function (i) { return i.isActive; }
                },
                {
                    icon: 'comment',
                    text: 'Note',
                    sref: '^.note',
                    filter: function (i) { return true; }
                },
                {
                    icon: 'highlight_off',
                    text: 'Close Request',
                    sref: '^.close',
                    filter: function (i) { return i.isActive; }
                },
                {
                    icon: 'folder_open',
                    text: 'Reopen Request',
                    sref: '^.reopen',
                    filter: function (i) { return !i.isActive; }
                },
                {
                    icon: 'group_work',
                    text: 'Case Assessment Meeting',
                    sref: '^.caseAssessmentMeeting',
                    filter: function (i) { return i.isActive; }
                },
                {
                    icon: 'approval',
                    text: 'Status Change',
                    sref: '^.statusChange',
                    filter: function (i) { return i.isActive; }
                },
            ];
            var NewRequestActivityDialogController = (function () {
                function NewRequestActivityDialogController($mdDialog, common, datacontext, request) {
                    var _this = this;
                    this.$mdDialog = $mdDialog;
                    this.common = common;
                    this.datacontext = datacontext;
                    this.request = request;
                    this.filterActivity = function (activity) {
                        return activity.filter(_this.request);
                    };
                    'ngInject';
                    'ngInject';
                }
                NewRequestActivityDialogController.prototype.$onInit = function () {
                    this.filteredActivities = activities.filter(this.filterActivity);
                };
                NewRequestActivityDialogController.prototype.selectActivity = function (item) {
                    this.common.$state.go(item.sref);
                    this.$mdDialog.hide();
                };
                NewRequestActivityDialogController.$inject = ['$mdDialog', 'common', 'datacontext', 'request'];
                NewRequestActivityDialogController = __decorate([
                    app.Controller('app.requests.activities', 'NewRequestActivityDialogController')
                ], NewRequestActivityDialogController);
                return NewRequestActivityDialogController;
            }());
        })(activities = requests.activities || (requests.activities = {}));
    })(requests = app.requests || (app.requests = {}));
})(app || (app = {}));
var app;
(function (app) {
    var requests;
    (function (requests) {
        var activities;
        (function (activities) {
            var Reopen = (function () {
                function Reopen(common, datacontext) {
                    this.common = common;
                    this.datacontext = datacontext;
                    this.isSubmitting = false;
                    this.activity = {};
                    'ngInject';
                    'ngInject';
                }
                Reopen.prototype.$onInit = function () {
                    this.activity.requestId = this.request.id;
                    this.activity.involvementId = this.request.involvementId;
                    this.activity.activityDate = moment().toDate();
                };
                Reopen.prototype.submit = function () {
                    var _this = this;
                    this.isSubmitting = true;
                    if (typeof this.activity.activityDate !== 'string') {
                        this.activity.activityDate = this.activity.activityDate.toDateString();
                    }
                    this.datacontext.requests
                        .reopen(this.activity)
                        .then(function (request) {
                        if (request) {
                            _this.common.$mdToast.showSimple('Request Reopened');
                            _this.common.$rootScope.$broadcast('refreshBasicInfo');
                            _this.request.isActive = true;
                            _this.common.$state.go('^.list', { requestId: _this.request.id, involvementId: _this.request.involvementId });
                        }
                    })
                        .finally(function () { return _this.isSubmitting = false; });
                };
                Reopen.$inject = ['common', 'datacontext'];
                Reopen = __decorate([
                    app.Component('app.requests.activities', 'ceRequestsActivitiesReopen', {
                        templateUrl: 'app/requests/activities/reopen/reopen.html',
                        bindings: {
                            request: '<'
                        }
                    })
                ], Reopen);
                return Reopen;
            }());
        })(activities = requests.activities || (requests.activities = {}));
    })(requests = app.requests || (app.requests = {}));
})(app || (app = {}));
var app;
(function (app) {
    var requests;
    (function (requests) {
        var activities;
        (function (activities) {
            var Review = (function () {
                function Review(common, datacontext) {
                    this.common = common;
                    this.datacontext = datacontext;
                    this.isSubmitting = false;
                    this.review = {};
                    'ngInject';
                    'ngInject';
                }
                Review.prototype.$onInit = function () {
                    this.maxDate = moment().toDate();
                    if (this.activity) {
                        this.review.id = this.activity.id;
                        this.review.requestId = this.activity.sourceId;
                        this.review.involvementId = this.activity.involvementId;
                        this.review.description = this.activity.description;
                        this.review.activityDate = this.activity.activityDate;
                    }
                    else {
                        this.getRequestStatus();
                        this.review.involvementId = this.involvementId;
                        this.review.requestId = this.requestId;
                        this.review.activityDate = moment().toDate();
                    }
                };
                Review.prototype.getRequestStatus = function () {
                    var _this = this;
                    return this.datacontext.requests.getRequestStatus(this.requestId).then(function (result) {
                        var data = result;
                        if (data.status) {
                            _this.selectedStatus = data.status;
                            _this.review.statusDescription = data.statusDescription;
                        }
                    });
                };
                Review.prototype.submit = function () {
                    var _this = this;
                    this.isSubmitting = true;
                    if (typeof this.review.activityDate !== 'string') {
                        this.review.activityDate = this.review.activityDate.toDateString();
                    }
                    this.review.status = this.selectedStatus;
                    if (this.selectedStatus !== 'Other') {
                        this.review.statusDescription = null;
                    }
                    var promise = this.review.id
                        ? this.datacontext.requests.updateReview(this.review)
                        : this.datacontext.requests.createReview(this.review);
                    promise
                        .then(function (request) {
                        if (request) {
                            _this.common.$mdToast.showSimple('Review ' + (_this.review.id ? 'updated' : 'created'));
                            _this.common.$rootScope.$broadcast('refreshBasicInfo');
                            _this.common.$state.go('^.list', { requestId: _this.requestId });
                        }
                    })
                        .finally(function () { return _this.isSubmitting = false; });
                };
                Review.$inject = ['common', 'datacontext'];
                Review = __decorate([
                    app.Component('app.requests.activities', 'ceRequestsActivitiesReview', {
                        templateUrl: 'app/requests/activities/review/review.html',
                        bindings: {
                            involvementId: '<',
                            requestId: '<',
                            activity: '<',
                            caseOpenDate: '<'
                        }
                    })
                ], Review);
                return Review;
            }());
        })(activities = requests.activities || (requests.activities = {}));
    })(requests = app.requests || (app.requests = {}));
})(app || (app = {}));
var app;
(function (app) {
    var requests;
    (function (requests) {
        var details;
        (function (details) {
            var RequestInformationForm = (function () {
                function RequestInformationForm(datacontext) {
                    this.datacontext = datacontext;
                    this.isLoading = false;
                    this.isSubmitting = false;
                    'ngInject';
                    'ngInject';
                }
                RequestInformationForm.prototype.$onInit = function () {
                    var _this = this;
                    this.datacontext.config.legalTeams.getLegalTeams(true).then(function (result) { return _this.legalTeams = result; });
                    this.getData();
                };
                RequestInformationForm.prototype.getData = function () {
                    var _this = this;
                    this.isLoading = true;
                    this.datacontext.requests
                        .getRequestInformation(this.requestId)
                        .then(function (result) { return _this.data = result; })
                        .finally(function () { return _this.isLoading = false; });
                };
                RequestInformationForm.prototype.submit = function () {
                    var _this = this;
                    this.isSubmitting = true;
                    this.datacontext.requests
                        .updateRequestInformation(this.data)
                        .then(function (result) {
                        if (result)
                            _this.onSubmit({ result: result });
                    }).finally(function () { return _this.isSubmitting = false; });
                };
                RequestInformationForm.$inject = ['datacontext'];
                RequestInformationForm = __decorate([
                    app.Component('app.requests.details', 'ceRequestInformationForm', {
                        templateUrl: 'app/requests/details/request-information/request-information-form.html',
                        bindings: {
                            requestId: '<',
                            onCancel: '&',
                            onSubmit: '&'
                        }
                    })
                ], RequestInformationForm);
                return RequestInformationForm;
            }());
        })(details = requests.details || (requests.details = {}));
    })(requests = app.requests || (app.requests = {}));
})(app || (app = {}));
var app;
(function (app) {
    var requests;
    (function (requests) {
        var details;
        (function (details) {
            var RequestInformation = (function () {
                function RequestInformation() {
                }
                RequestInformation = __decorate([
                    app.Component('app.requests.details', 'ceRequestInformation', {
                        templateUrl: 'app/requests/details/request-information/request-information.html',
                        bindings: {
                            data: '<',
                            onEdit: '&'
                        }
                    })
                ], RequestInformation);
                return RequestInformation;
            }());
        })(details = requests.details || (requests.details = {}));
    })(requests = app.requests || (app.requests = {}));
})(app || (app = {}));
var app;
(function (app) {
    var search;
    (function (search) {
        var AdvancedEmailAddressSearchResults = (function () {
            function AdvancedEmailAddressSearchResults() {
                var _this = this;
                this.paginate = function (page) {
                    _this.onPaginate({ page: page });
                };
                this.reorder = function () {
                    _this.onReorder();
                };
            }
            AdvancedEmailAddressSearchResults = __decorate([
                app.Component('app.search', 'ceAdvancedEmailAddressSearchResults', {
                    templateUrl: 'app/search/advanced-search/email-addresses/advanced-email-address-search-results.html',
                    bindings: {
                        promise: '=',
                        query: '=',
                        results: '=',
                        onPaginate: '&',
                        onReorder: '&'
                    }
                })
            ], AdvancedEmailAddressSearchResults);
            return AdvancedEmailAddressSearchResults;
        }());
    })(search = app.search || (app.search = {}));
})(app || (app = {}));
var app;
(function (app) {
    var search;
    (function (search) {
        var AdvancedEmailAddressSearch = (function () {
            function AdvancedEmailAddressSearch(datacontext) {
                var _this = this;
                this.datacontext = datacontext;
                this.query = { pageSize: 10, order: 'EmailAddress' };
                this.handlePaginate = function (page, limit) {
                    _this.query.page = page;
                    _this.search();
                };
                this.handleReorder = function (sortBy) {
                    _this.search();
                };
                'ngInject';
                'ngInject';
            }
            AdvancedEmailAddressSearch.prototype.search = function () {
                var _this = this;
                this.isSubmitting = true;
                this.promise = this.datacontext.entities
                    .searchEmailAddresses(this.query)
                    .then(function (result) {
                    _this.emailAddresses = result;
                    return !!result;
                })
                    .finally(function () { return _this.isSubmitting = false; });
                return this.promise;
            };
            AdvancedEmailAddressSearch.$inject = ['datacontext'];
            AdvancedEmailAddressSearch = __decorate([
                app.Component('app.search', 'ceAdvancedEmailAddressSearch', {
                    templateUrl: 'app/search/advanced-search/email-addresses/advanced-email-address-search.html'
                })
            ], AdvancedEmailAddressSearch);
            return AdvancedEmailAddressSearch;
        }());
    })(search = app.search || (app.search = {}));
})(app || (app = {}));
var app;
(function (app) {
    var search;
    (function (search) {
        var AdvancedInvolvementSearchResults = (function () {
            function AdvancedInvolvementSearchResults() {
                var _this = this;
                this.paginate = function (page) {
                    _this.onPaginate({ page: page });
                };
                this.reorder = function () {
                    _this.onReorder();
                };
            }
            AdvancedInvolvementSearchResults = __decorate([
                app.Component('app.search', 'ceAdvancedInvolvementSearchResults', {
                    templateUrl: 'app/search/advanced-search/involvements/advanced-involvement-search-results.html',
                    bindings: {
                        promise: '=',
                        query: '=',
                        results: '=',
                        onPaginate: '&',
                        onReorder: '&'
                    }
                })
            ], AdvancedInvolvementSearchResults);
            return AdvancedInvolvementSearchResults;
        }());
    })(search = app.search || (app.search = {}));
})(app || (app = {}));
var app;
(function (app) {
    var search;
    (function (search) {
        var AdvancedInvolvementSearch = (function () {
            function AdvancedInvolvementSearch(datacontext) {
                var _this = this;
                this.datacontext = datacontext;
                this.query = { pageSize: 10, order: 'OpenDate' };
                this.handlePaginate = function (page, limit) {
                    _this.query.page = page;
                    _this.search();
                };
                this.handleReorder = function (sortBy) {
                    _this.search();
                };
                'ngInject';
                'ngInject';
            }
            AdvancedInvolvementSearch.prototype.search = function () {
                var _this = this;
                this.isSubmitting = true;
                this.promise = this.datacontext.involvements
                    .searchCases(this.query)
                    .then(function (result) {
                    _this.cases = result;
                    return !!result;
                }).finally(function () { return _this.isSubmitting = false; });
                return this.promise;
            };
            AdvancedInvolvementSearch.$inject = ['datacontext'];
            AdvancedInvolvementSearch = __decorate([
                app.Component('app.search', 'ceAdvancedInvolvementSearch', {
                    templateUrl: 'app/search/advanced-search/involvements/advanced-involvement-search.html'
                })
            ], AdvancedInvolvementSearch);
            return AdvancedInvolvementSearch;
        }());
    })(search = app.search || (app.search = {}));
})(app || (app = {}));
var app;
(function (app) {
    var search;
    (function (search) {
        var AdvancedPhoneNumberSearchResults = (function () {
            function AdvancedPhoneNumberSearchResults() {
                var _this = this;
                this.paginate = function (page) {
                    _this.onPaginate({ page: page });
                };
                this.reorder = function () {
                    _this.onReorder();
                };
            }
            AdvancedPhoneNumberSearchResults = __decorate([
                app.Component('app.search', 'ceAdvancedPhoneNumberSearchResults', {
                    templateUrl: 'app/search/advanced-search/phone-numbers/advanced-phone-number-search-results.html',
                    bindings: {
                        promise: '=',
                        query: '=',
                        results: '=',
                        onPaginate: '&',
                        onReorder: '&'
                    }
                })
            ], AdvancedPhoneNumberSearchResults);
            return AdvancedPhoneNumberSearchResults;
        }());
    })(search = app.search || (app.search = {}));
})(app || (app = {}));
var app;
(function (app) {
    var search;
    (function (search) {
        var AdvancedPhoneNumberSearch = (function () {
            function AdvancedPhoneNumberSearch(datacontext) {
                var _this = this;
                this.datacontext = datacontext;
                this.query = { pageSize: 10, order: 'FormattedPhoneNumber' };
                this.handlePaginate = function (page, limit) {
                    _this.query.page = page;
                    _this.search();
                };
                this.handleReorder = function (sortBy) {
                    _this.search();
                };
                'ngInject';
                'ngInject';
            }
            AdvancedPhoneNumberSearch.prototype.search = function () {
                var _this = this;
                this.isSubmitting = true;
                this.promise = this.datacontext.entities
                    .searchPhoneNumbers(this.query)
                    .then(function (result) {
                    _this.phones = result;
                    return !!result;
                }).finally(function () { return _this.isSubmitting = false; });
                return this.promise;
            };
            AdvancedPhoneNumberSearch.$inject = ['datacontext'];
            AdvancedPhoneNumberSearch = __decorate([
                app.Component('app.search', 'ceAdvancedPhoneNumberSearch', {
                    templateUrl: 'app/search/advanced-search/phone-numbers/advanced-phone-number-search.html'
                })
            ], AdvancedPhoneNumberSearch);
            return AdvancedPhoneNumberSearch;
        }());
    })(search = app.search || (app.search = {}));
})(app || (app = {}));
var app;
(function (app) {
    var search;
    (function (search) {
        var AdvancedSearchForm = (function () {
            function AdvancedSearchForm() {
                this.isSubmitting = false;
            }
            AdvancedSearchForm.prototype.submit = function () {
                var _this = this;
                this.isSubmitting = true;
                this.onSubmit().finally(function () { return _this.isSubmitting = false; });
            };
            AdvancedSearchForm = __decorate([
                app.Component('app.search', 'ceAdvancedSearchForm', {
                    templateUrl: 'app/search/advanced-search/advanced-search-form.html',
                    bindings: {
                        query: '=',
                        onSubmit: '&'
                    }
                })
            ], AdvancedSearchForm);
            return AdvancedSearchForm;
        }());
    })(search = app.search || (app.search = {}));
})(app || (app = {}));
var app;
(function (app) {
    var search;
    (function (search) {
        var AdvancedSearchResults = (function () {
            function AdvancedSearchResults() {
                var _this = this;
                this.paginate = function (page) {
                    _this.onPaginate({ page: page });
                };
                this.reorder = function () {
                    _this.onReorder();
                };
            }
            AdvancedSearchResults = __decorate([
                app.Component('app.search', 'ceAdvancedSearchResults', {
                    templateUrl: 'app/search/advanced-search/advanced-search-results.html',
                    bindings: {
                        promise: '=',
                        query: '=',
                        results: '=',
                        onPaginate: '&',
                        onReorder: '&'
                    }
                })
            ], AdvancedSearchResults);
            return AdvancedSearchResults;
        }());
    })(search = app.search || (app.search = {}));
})(app || (app = {}));
var app;
(function (app) {
    var search;
    (function (search) {
        var AdvancedPersonSearch = (function () {
            function AdvancedPersonSearch(datacontext) {
                var _this = this;
                this.datacontext = datacontext;
                this.query = { pageSize: 10, order: 'FullName' };
                this.handlePaginate = function (page, limit) {
                    _this.query.page = page;
                    _this.search();
                };
                this.handleReorder = function (sortBy) {
                    _this.search();
                };
                'ngInject';
                'ngInject';
            }
            AdvancedPersonSearch.prototype.search = function () {
                var _this = this;
                this.promise = this.datacontext.persons
                    .getPersons(this.query)
                    .then(function (result) {
                    _this.persons = result;
                    return !!result;
                });
                return this.promise;
            };
            AdvancedPersonSearch.$inject = ['datacontext'];
            AdvancedPersonSearch = __decorate([
                app.Component('app.search', 'ceAdvancedPersonSearch', {
                    templateUrl: 'app/search/advanced-search/advanced-person-search.html'
                })
            ], AdvancedPersonSearch);
            return AdvancedPersonSearch;
        }());
    })(search = app.search || (app.search = {}));
})(app || (app = {}));
var app;
(function (app) {
    var systemicCases;
    (function (systemicCases) {
        var activities;
        (function (activities) {
            var SystemicCaseActivityList = (function () {
                function SystemicCaseActivityList($mdDialog, common, datacontext) {
                    var _this = this;
                    this.$mdDialog = $mdDialog;
                    this.common = common;
                    this.datacontext = datacontext;
                    this.activityFilter = {};
                    this.showFilters = false;
                    this.selectedActivityTypes = [];
                    this.paging = {
                        page: 1,
                        pageSize: 100
                    };
                    this.refresh = function () {
                        _this.getActivities();
                    };
                    'ngInject';
                    'ngInject';
                }
                SystemicCaseActivityList.prototype.$onInit = function () {
                    this.activityFilter.involvementId = this.case.involvementId;
                    this.activityFilter.sourceId = this.case.id;
                    this.activityFilter.source = 'Systemic Case';
                    this.getActivities();
                };
                SystemicCaseActivityList.prototype.getActivities = function () {
                    var _this = this;
                    if (!this.filtersInitialized()) {
                        return;
                    }
                    if (!this.showInvolvementActivities) {
                        this.activityFilter.sourceId = this.case.id;
                        this.activityFilter.source = 'Systemic Case';
                    }
                    else {
                        this.activityFilter.sourceId = null;
                        this.activityFilter.source = null;
                    }
                    this.activityFilter = __assign(__assign(__assign({}, this.activityFilter), this.paging), { activityTypes: this.selectedActivityTypes, q: this.noteFilter });
                    this.promise = this.datacontext.activities
                        .searchActivities(this.activityFilter)
                        .then(function (result) {
                        _this.activities = result;
                    });
                };
                SystemicCaseActivityList.prototype.getInvolvementActivities = function () {
                    this.showInvolvementActivities = !this.showInvolvementActivities;
                    this.getActivities();
                };
                SystemicCaseActivityList.prototype.newActivity = function (targetEvent) {
                    var parent = angular.element(document.body);
                    this.$mdDialog.show({
                        parent: parent,
                        targetEvent: targetEvent,
                        clickOutsideToClose: true,
                        templateUrl: 'app/systemic-cases/activities/new-activity/new-activity.html',
                        controller: 'NewSystemicCaseActivityDialogController',
                        controllerAs: '$ctrl',
                        locals: {
                            caseview: this.case
                        }
                    });
                };
                SystemicCaseActivityList.prototype.toggleFilters = function () {
                    this.showFilters = !this.showFilters;
                };
                SystemicCaseActivityList.prototype.filtersInitialized = function () {
                    return this.activityTypeFilterInitialized;
                };
                SystemicCaseActivityList.prototype.activityTypeFilterChanged = function (activityTypes) {
                    this.selectedActivityTypes = activityTypes;
                    this.activityTypeFilterInitialized = true;
                    this.getActivities();
                };
                SystemicCaseActivityList.prototype.onEdit = function (activity) {
                    switch (activity.activityType) {
                        case 'CaseAssessmentMeeting':
                            this.common.$state.go('^.caseAssessmentMeeting', { activity: activity });
                            break;
                        case 'CaseReview':
                            this.common.$state.go('^.caseReview', { activity: activity });
                            break;
                        case 'Note':
                            this.common.$state.go('^.note', { activity: activity });
                            break;
                        case 'StatusChange':
                            this.common.$state.go('^.statusChange', { activityId: activity.id });
                            break;
                    }
                };
                SystemicCaseActivityList.$inject = ['$mdDialog', 'common', 'datacontext'];
                SystemicCaseActivityList = __decorate([
                    app.Component('app.systemicCases.activities', 'ceSystemicCaseActivityList', {
                        templateUrl: 'app/systemic-cases/activities/activity-list/activity-list.html',
                        bindings: {
                            case: '<'
                        }
                    })
                ], SystemicCaseActivityList);
                return SystemicCaseActivityList;
            }());
        })(activities = systemicCases.activities || (systemicCases.activities = {}));
    })(systemicCases = app.systemicCases || (app.systemicCases = {}));
})(app || (app = {}));
var app;
(function (app) {
    var systemicCases;
    (function (systemicCases) {
        var CaseAssessmentMeeting = (function () {
            function CaseAssessmentMeeting(common, datacontext) {
                this.common = common;
                this.datacontext = datacontext;
                this.isSubmitting = false;
                this.meeting = {};
                'ngInject';
                'ngInject';
            }
            CaseAssessmentMeeting.prototype.$onInit = function () {
                this.maxDate = moment().toDate();
                if (this.activity) {
                    this.getCAM();
                }
                else {
                    this.meeting.involvementId = this.involvementId;
                    this.meeting.source = this.source;
                    this.meeting.sourceId = this.sourceId;
                    this.meeting.meetingDate = moment().toDate();
                }
                this.getStatus();
                this.returnSref = this.returnSref || '^.list';
            };
            CaseAssessmentMeeting.prototype.getCAM = function () {
                var _this = this;
                this.datacontext.involvements
                    .getCAM(this.involvementId, this.activity.id)
                    .then(function (result) { return _this.meeting = result; });
            };
            CaseAssessmentMeeting.prototype.getStatus = function () {
                var _this = this;
                return this.datacontext.systemicCases.getSystemicCaseStatus(this.sourceId).then(function (result) {
                    var data = result;
                    if (data.status) {
                        _this.selectedStatus = data.status;
                        _this.meeting.statusDescription = data.statusDescription;
                    }
                });
            };
            CaseAssessmentMeeting.prototype.submit = function () {
                var _this = this;
                this.isSubmitting = true;
                if (typeof this.meeting.meetingDate !== 'string') {
                    this.meeting.meetingDate = this.meeting.meetingDate.toDateString();
                }
                if (this.meeting.communicationDate && typeof this.meeting.communicationDate !== 'string') {
                    this.meeting.communicationDate = this.meeting.communicationDate.toDateString();
                }
                this.meeting.status = this.selectedStatus;
                if (this.selectedStatus && this.selectedStatus.indexOf('Other') === -1) {
                    this.meeting.statusDescription = null;
                }
                var promise = this.meeting.id
                    ? this.datacontext.involvements.updateCaseAssessmentMeeting(this.meeting)
                    : this.datacontext.involvements.createCaseAssessmentMeeting(this.meeting);
                promise
                    .then(function (request) {
                    if (request) {
                        _this.common.$mdToast.showSimple('Case Assessment Meeting ' + (_this.meeting.id ? 'updated' : 'created'));
                        _this.common.$timeout(function () { _this.common.$rootScope.$broadcast('refreshBasicInfo'); }, 2000);
                        _this.common.$state.go('^.list', { caseId: _this.sourceId, involvementId: _this.involvementId });
                    }
                })
                    .finally(function () { return _this.isSubmitting = false; });
            };
            CaseAssessmentMeeting.$inject = ['common', 'datacontext'];
            CaseAssessmentMeeting = __decorate([
                app.Component('app.systemicCases', 'ceSystemicCaseAssessmentMeeting', {
                    templateUrl: 'app/systemic-Cases/activities/case-assessment-meeting/case-assessment-meeting.html',
                    bindings: {
                        involvementId: '<',
                        source: '@',
                        sourceId: '<',
                        returnSref: '@',
                        showCommunicationDate: '@',
                        activity: '<',
                        caseOpenDate: '<'
                    }
                })
            ], CaseAssessmentMeeting);
            return CaseAssessmentMeeting;
        }());
    })(systemicCases = app.systemicCases || (app.systemicCases = {}));
})(app || (app = {}));
var app;
(function (app) {
    var systemicCases;
    (function (systemicCases) {
        var activities;
        (function (activities) {
            var Close = (function () {
                function Close(common, datacontext) {
                    this.common = common;
                    this.datacontext = datacontext;
                    this.statuses = [];
                    this.isSubmitting = false;
                    this.activity = {};
                    'ngInject';
                    'ngInject';
                }
                Close.prototype.$onInit = function () {
                    var _this = this;
                    this.activity.caseId = this.case.id;
                    this.activity.involvementId = this.case.involvementId;
                    this.activity.activityDate = moment().toDate();
                    this.datacontext.systemicCases.getSystemicCaseToDo(this.case.id, 'closing')
                        .then(function (items) {
                        _this.items = items;
                    });
                    this.maxDate = this.activity.activityDate;
                    this.getSystemicCaseStatus();
                };
                Close.prototype.getSystemicCaseStatus = function () {
                    var _this = this;
                    return this.datacontext.systemicCases.getSystemicCaseStatus(this.case.id).then(function (result) {
                        var data = result;
                        if (data.status) {
                            _this.selectedStatus = data.status;
                            _this.activity.statusDescription = data.statusDescription;
                        }
                    });
                };
                Close.prototype.submit = function () {
                    var _this = this;
                    this.isSubmitting = true;
                    if (typeof this.activity.activityDate !== 'string') {
                        this.activity.activityDate = this.activity.activityDate.toDateString();
                    }
                    this.activity.status = this.selectedStatus;
                    if (this.selectedStatus.indexOf('Other') === -1) {
                        this.activity.statusDescription = null;
                    }
                    this.datacontext.systemicCases
                        .close(this.activity)
                        .then(function (request) {
                        if (request) {
                            _this.common.$mdToast.showSimple('Case Closed');
                            _this.common.$rootScope.$broadcast('refreshBasicInfo');
                            _this.case.isActive = false;
                            _this.common.$state.go('^.list', { caseId: _this.case.id, involvementId: _this.case.involvementId });
                        }
                    })
                        .finally(function () { return _this.isSubmitting = false; });
                };
                Close.$inject = ['common', 'datacontext'];
                Close = __decorate([
                    app.Component('app.systemicCases.activities', 'ceSystemicCasesActivitiesClose', {
                        templateUrl: 'app/systemic-cases/activities/close/close.html',
                        bindings: {
                            case: '<'
                        }
                    })
                ], Close);
                return Close;
            }());
        })(activities = systemicCases.activities || (systemicCases.activities = {}));
    })(systemicCases = app.systemicCases || (app.systemicCases = {}));
})(app || (app = {}));
var app;
(function (app) {
    var systemicCases;
    (function (systemicCases) {
        var activities;
        (function (activities_8) {
            var activities = [
                {
                    icon: 'history',
                    text: 'Conflict Check',
                    sref: '^.conflictCheck',
                    filter: function (i) { return i.isActive; }
                },
                {
                    icon: 'info',
                    text: 'Convert to I&R',
                    sref: '^.createInformationAndReferral',
                    name: 'Create I&R',
                    filter: function (i) { return i.isActive; }
                },
                {
                    icon: 'assignment_turned_in',
                    text: 'Case Review',
                    sref: '^.caseReview',
                    filter: function (i) { return i.isActive; }
                },
                {
                    icon: 'folder_shared',
                    text: 'Convert to Client Case',
                    sref: '^.createClientCase',
                    name: 'Create Client Case',
                    filter: function (i) { return i.isActive; }
                },
                {
                    icon: 'comment',
                    text: 'Note',
                    sref: '^.note',
                    filter: function (i) { return true; }
                },
                {
                    icon: 'highlight_off',
                    text: 'Close Case',
                    sref: '^.close',
                    filter: function (i) { return i.isActive; }
                },
                {
                    icon: 'folder_open',
                    text: 'Reopen Case',
                    sref: '^.reopen',
                    filter: function (i) { return !i.isActive; }
                },
                {
                    icon: 'group_work',
                    text: 'Case Assessment Meeting',
                    sref: '^.caseAssessmentMeeting',
                    filter: function (i) { return i.isActive; }
                },
                {
                    icon: 'approval',
                    text: 'Status Change',
                    sref: '^.statusChange',
                    filter: function (i) { return i.isActive; }
                },
            ];
            var NewSystemicCaseActivityDialogController = (function () {
                function NewSystemicCaseActivityDialogController($mdDialog, common, caseview) {
                    var _this = this;
                    this.$mdDialog = $mdDialog;
                    this.common = common;
                    this.caseview = caseview;
                    this.filterActivity = function (activity) {
                        return activity.filter(_this.caseview);
                    };
                    'ngInject';
                    'ngInject';
                }
                NewSystemicCaseActivityDialogController.prototype.$onInit = function () {
                    this.filteredActivities = activities.filter(this.filterActivity);
                };
                NewSystemicCaseActivityDialogController.prototype.selectActivity = function (item) {
                    this.common.$state.go(item.sref, { involvementId: this.caseview.involvementId, sourceId: this.caseview.id });
                    this.$mdDialog.hide();
                };
                NewSystemicCaseActivityDialogController.$inject = ['$mdDialog', 'common', 'caseview'];
                NewSystemicCaseActivityDialogController = __decorate([
                    app.Controller('app.systemicCases.activities', 'NewSystemicCaseActivityDialogController')
                ], NewSystemicCaseActivityDialogController);
                return NewSystemicCaseActivityDialogController;
            }());
        })(activities = systemicCases.activities || (systemicCases.activities = {}));
    })(systemicCases = app.systemicCases || (app.systemicCases = {}));
})(app || (app = {}));
var app;
(function (app) {
    var systemicCases;
    (function (systemicCases) {
        var activities;
        (function (activities) {
            var Note = (function () {
                function Note(common, datacontext, interrupt) {
                    this.common = common;
                    this.datacontext = datacontext;
                    this.interrupt = interrupt;
                    this.isSubmitting = false;
                    this.note = {};
                    'ngInject';
                    'ngInject';
                }
                Note.prototype.$onInit = function () {
                    this.maxDate = moment().toDate();
                    if (this.activity) {
                        this.note.id = this.activity.id;
                        this.note.caseId = this.activity.sourceId;
                        this.note.involvementId = this.activity.involvementId;
                        this.note.title = this.activity.title;
                        this.note.description = this.activity.description;
                        this.note.activityDate = this.activity.activityDate;
                    }
                    else {
                        this.note.involvementId = this.involvementId;
                        this.note.caseId = this.caseId;
                        this.note.activityDate = moment().toDate();
                        this.getStatus();
                    }
                };
                Note.prototype.getStatus = function () {
                    var _this = this;
                    return this.datacontext.systemicCases.getSystemicCaseStatus(this.caseId).then(function (result) {
                        var data = result;
                        if (data.status) {
                            _this.selectedStatus = data.status;
                            _this.note.statusDescription = data.statusDescription;
                        }
                    });
                };
                Note.prototype.submit = function () {
                    var _this = this;
                    this.isSubmitting = true;
                    if (typeof this.note.activityDate !== 'string') {
                        this.note.activityDate = this.note.activityDate.toDateString();
                    }
                    this.note.status = this.selectedStatus;
                    if (this.selectedStatus !== 'Other') {
                        this.note.statusDescription = null;
                    }
                    var promise = this.note.id
                        ? this.datacontext.systemicCases.updateNote(this.note)
                        : this.datacontext.systemicCases.createNote(this.note);
                    promise
                        .then(function (note) {
                        if (note) {
                            _this.common.$mdToast.showSimple('Note ' + (_this.note.id ? 'updated' : 'created'));
                            _this.common.$rootScope.$broadcast('refreshBasicInfo');
                            _this.common.$state.go('^.list', { caseId: _this.caseId });
                        }
                    })
                        .finally(function () { return _this.isSubmitting = false; });
                };
                Note.$inject = ['common', 'datacontext', 'interrupt'];
                Note = __decorate([
                    app.Component('app.systemicCases.activities', 'ceSystemicCasesActivitiesNote', {
                        templateUrl: 'app/systemic-cases/activities/note/note.html',
                        bindings: {
                            involvementId: '<',
                            caseId: '<',
                            activity: '<',
                            caseOpenDate: '<'
                        }
                    })
                ], Note);
                return Note;
            }());
        })(activities = systemicCases.activities || (systemicCases.activities = {}));
    })(systemicCases = app.systemicCases || (app.systemicCases = {}));
})(app || (app = {}));
var app;
(function (app) {
    var systemicCases;
    (function (systemicCases) {
        var activities;
        (function (activities) {
            var Reopen = (function () {
                function Reopen(common, datacontext) {
                    this.common = common;
                    this.datacontext = datacontext;
                    this.isSubmitting = false;
                    this.activity = {};
                    'ngInject';
                    'ngInject';
                }
                Reopen.prototype.$onInit = function () {
                    this.activity.caseId = this.case.id;
                    this.activity.involvementId = this.case.involvementId;
                    this.activity.activityDate = moment().toDate();
                };
                Reopen.prototype.submit = function () {
                    var _this = this;
                    this.isSubmitting = true;
                    if (typeof this.activity.activityDate !== 'string') {
                        this.activity.activityDate = this.activity.activityDate.toDateString();
                    }
                    this.datacontext.systemicCases
                        .reopen(this.activity)
                        .then(function (sc) {
                        if (sc) {
                            _this.common.$mdToast.showSimple('Case Reopened');
                            _this.common.$rootScope.$broadcast('refreshBasicInfo');
                            _this.case.isActive = true;
                            _this.common.$state.go('^.list', { caseId: _this.case.id, involvementId: _this.case.involvementId });
                        }
                    })
                        .finally(function () { return _this.isSubmitting = false; });
                };
                Reopen.$inject = ['common', 'datacontext'];
                Reopen = __decorate([
                    app.Component('app.systemicCases.activities', 'ceSystemicCasesActivitiesReopen', {
                        templateUrl: 'app/systemic-cases/activities/reopen/reopen.html',
                        bindings: {
                            case: '<'
                        }
                    })
                ], Reopen);
                return Reopen;
            }());
        })(activities = systemicCases.activities || (systemicCases.activities = {}));
    })(systemicCases = app.systemicCases || (app.systemicCases = {}));
})(app || (app = {}));
var app;
(function (app) {
    var systemicCases;
    (function (systemicCases) {
        var closure;
        (function (closure) {
            var SystemicCaseClosureInformationForm = (function () {
                function SystemicCaseClosureInformationForm(common, datacontext) {
                    this.common = common;
                    this.datacontext = datacontext;
                    this.isLoading = false;
                    this.isSubmitting = false;
                    'ngInject';
                    'ngInject';
                }
                SystemicCaseClosureInformationForm.prototype.$onInit = function () {
                    this.getData();
                };
                SystemicCaseClosureInformationForm.prototype.getData = function () {
                    var _this = this;
                    this.isLoading = true;
                    this.datacontext.systemicCases
                        .getClosureInformation(this.caseId)
                        .then(function (result) { return _this.data = result; })
                        .finally(function () { return _this.isLoading = false; });
                };
                SystemicCaseClosureInformationForm.prototype.submit = function () {
                    var _this = this;
                    this.isSubmitting = true;
                    if (!this.data.monetarySettlement) {
                        this.data.monetarySettlementAmount = null;
                    }
                    this.datacontext.systemicCases
                        .updateClosureInformation(this.data)
                        .then(function (result) {
                        if (result) {
                            _this.common.$mdToast.showSimple('Remember to Complete Survey');
                            _this.onSubmit({ result: result });
                        }
                    }).finally(function () { return _this.isSubmitting = false; });
                };
                SystemicCaseClosureInformationForm.$inject = ['common', 'datacontext'];
                SystemicCaseClosureInformationForm = __decorate([
                    app.Component('app.systemicCases.closure', 'ceSystemicCaseClosureInformationForm', {
                        templateUrl: 'app/systemic-cases/closure/closure-information/closure-information-form.html',
                        bindings: {
                            caseId: '<',
                            onCancel: '&',
                            onSubmit: '&'
                        }
                    })
                ], SystemicCaseClosureInformationForm);
                return SystemicCaseClosureInformationForm;
            }());
        })(closure = systemicCases.closure || (systemicCases.closure = {}));
    })(systemicCases = app.systemicCases || (app.systemicCases = {}));
})(app || (app = {}));
var app;
(function (app) {
    var systemicCases;
    (function (systemicCases) {
        var closure;
        (function (closure) {
            var SystemicCaseClosureInformation = (function () {
                function SystemicCaseClosureInformation() {
                }
                SystemicCaseClosureInformation = __decorate([
                    app.Component('app.systemicCases.closure', 'ceSystemicCaseClosureInformation', {
                        templateUrl: 'app/systemic-cases/closure/closure-information/closure-information.html',
                        bindings: {
                            data: '<',
                            onEdit: '&'
                        }
                    })
                ], SystemicCaseClosureInformation);
                return SystemicCaseClosureInformation;
            }());
        })(closure = systemicCases.closure || (systemicCases.closure = {}));
    })(systemicCases = app.systemicCases || (app.systemicCases = {}));
})(app || (app = {}));
var app;
(function (app) {
    var systemicCases;
    (function (systemicCases) {
        var details;
        (function (details) {
            var SystemicCaseInformationForm = (function () {
                function SystemicCaseInformationForm(datacontext) {
                    this.datacontext = datacontext;
                    this.isLoading = false;
                    this.isSubmitting = false;
                    'ngInject';
                    'ngInject';
                }
                SystemicCaseInformationForm.prototype.$onInit = function () {
                    var _this = this;
                    this.getLegalTeams();
                    this.getLawCategoriesAndSubcategories().then(function () { return _this.getData(); });
                };
                SystemicCaseInformationForm.prototype.getLegalTeams = function () {
                    var _this = this;
                    this.datacontext.config.legalTeams.getLegalTeams(true).then(function (result) { return _this.legalTeams = result; });
                };
                SystemicCaseInformationForm.prototype.lawCategoryChanged = function () {
                    this.filterLawSubcategories();
                };
                SystemicCaseInformationForm.prototype.getLawCategoriesAndSubcategories = function () {
                    var _this = this;
                    return this.datacontext.config.getLawCategories()
                        .then(function (lawCategories) {
                        _this.lawCategories = lawCategories;
                        return _this.datacontext.config.getLawSubcategories().then(function (lawSubcategories) {
                            _this.lawSubcategories = lawSubcategories.data;
                        });
                    });
                };
                SystemicCaseInformationForm.prototype.getData = function () {
                    var _this = this;
                    this.isLoading = true;
                    this.datacontext.systemicCases
                        .getSystemicCaseInformation(this.caseId)
                        .then(function (result) {
                        _this.data = result;
                        _this.filterLawSubcategories();
                    })
                        .finally(function () { return _this.isLoading = false; });
                };
                SystemicCaseInformationForm.prototype.filterLawSubcategories = function () {
                    var _this = this;
                    this.validLawSubcategories = _.filter(this.lawSubcategories, function (ct) {
                        return ct.lawCategoryId === _this.data.lawCategoryId;
                    });
                };
                SystemicCaseInformationForm.prototype.submit = function () {
                    var _this = this;
                    this.isSubmitting = true;
                    this.datacontext.systemicCases
                        .updateSystemicCaseInformation(this.data)
                        .then(function (result) {
                        if (result)
                            _this.onSubmit({ result: result });
                    }).finally(function () { return _this.isSubmitting = false; });
                };
                SystemicCaseInformationForm.$inject = ['datacontext'];
                SystemicCaseInformationForm = __decorate([
                    app.Component('app.systemicCases.details', 'ceSystemicCaseInformationForm', {
                        templateUrl: 'app/systemic-cases/details/case-information/case-information-form.html',
                        bindings: {
                            caseId: '<',
                            onCancel: '&',
                            onSubmit: '&'
                        }
                    })
                ], SystemicCaseInformationForm);
                return SystemicCaseInformationForm;
            }());
        })(details = systemicCases.details || (systemicCases.details = {}));
    })(systemicCases = app.systemicCases || (app.systemicCases = {}));
})(app || (app = {}));
var app;
(function (app) {
    var systemicCases;
    (function (systemicCases) {
        var details;
        (function (details) {
            var SystemicCaseInformation = (function () {
                function SystemicCaseInformation() {
                }
                SystemicCaseInformation = __decorate([
                    app.Component('app.systemicCases.details', 'ceSystemicCaseInformation', {
                        templateUrl: 'app/systemic-cases/details/case-information/case-information.html',
                        bindings: {
                            data: '<',
                            onEdit: '&'
                        }
                    })
                ], SystemicCaseInformation);
                return SystemicCaseInformation;
            }());
        })(details = systemicCases.details || (systemicCases.details = {}));
    })(systemicCases = app.systemicCases || (app.systemicCases = {}));
})(app || (app = {}));
var app;
(function (app) {
    var training;
    (function (training) {
        var cle;
        (function (cle) {
            var activities;
            (function (activities) {
                var routes = [
                    {
                        name: 'app.cle.activities',
                        url: '/activities',
                        template: '<ui-view layout="column" flex></ui-view>',
                        redirectTo: 'app.cle.activities.list'
                    },
                    {
                        name: 'app.cle.activities.list',
                        url: '',
                        template: "<ce-c-l-e-activity-list\n                        cle-id=\"$resolve.cle.id\"\n                        layout=\"column\" flex>\n                    </ce-c-l-e-activity-list>",
                        data: {
                            title: 'CLE Activities',
                            permissions: ['access_activities']
                        }
                    },
                    {
                        name: 'app.cle.activities.note',
                        url: '/note',
                        template: "<ce-c-l-e-activities-note layout=\"column\" flex\n                        cle-Id=\"$resolve.cle.id\"\n                        activity-Type=\"CLENote\"\n                        name=\"Note\"\n                        activity=\"$resolve.activity\"\n                        case-open-date=\"$resolve.cle.startTime\"\n                        return-sref=\"app.training.cle.activities.list\">\n                    </ce-c-l-e-activities-note>",
                        params: { activity: null },
                        resolve: { activity: ['$stateParams', function (p) { return p.activity; }] },
                        data: { title: 'Note' }
                    }
                ];
                var runner = function (routingHelper) {
                    'ngInject';
                    routingHelper.configureRoutes(routes);
                };
                angular
                    .module('app.training.cle.activities')
                    .run(runner);
            })(activities = cle.activities || (cle.activities = {}));
        })(cle = training.cle || (training.cle = {}));
    })(training = app.training || (app.training = {}));
})(app || (app = {}));
var app;
(function (app) {
    var training;
    (function (training) {
        var cle;
        (function (cle) {
            var CLECalendarStateService = (function () {
                function CLECalendarStateService($localStorage) {
                    var _this = this;
                    this.$localStorage = $localStorage;
                    this.state = {};
                    this.loadState = function () {
                        if (_this.$localStorage['cleCalendarState']) {
                            angular.extend(_this.state, _this.$localStorage['cleCalendarState']);
                        }
                        else {
                            _this.$localStorage['cleCalendarState'] = _this.state;
                        }
                        if (_this.state.dateRange) {
                            _this.state.dateRange = moment.range(_this.state.dateRange.start, _this.state.dateRange.end);
                        }
                    };
                    'ngInject';
                    'ngInject';
                    if ($localStorage.calendarState) {
                        delete $localStorage.calendarState.dateRange;
                        delete $localStorage.calendarState.viewType;
                        delete $localStorage.calendarState.viewDate;
                    }
                    this.loadState();
                }
                CLECalendarStateService.prototype.save = function () {
                    this.$localStorage['cleCalendarState'] = this.state;
                };
                CLECalendarStateService.prototype.update = function (state) {
                    angular.extend(this.state, state);
                    angular.extend(this.$localStorage['cleCalendarState'], state);
                };
                return CLECalendarStateService;
            }());
            angular
                .module('app.training.cle')
                .service('cleCalendarState', CLECalendarStateService);
        })(cle = training.cle || (training.cle = {}));
    })(training = app.training || (app.training = {}));
})(app || (app = {}));
var app;
(function (app) {
    var training;
    (function (training) {
        var cle;
        (function (cle) {
            var CLECalendar = (function () {
                function CLECalendar($scope, $mdPanel, $mdSidenav, common, cleCalendarData, cleCalendarState, $mdDialog) {
                    var _this = this;
                    this.$scope = $scope;
                    this.$mdPanel = $mdPanel;
                    this.$mdSidenav = $mdSidenav;
                    this.common = common;
                    this.cleCalendarData = cleCalendarData;
                    this.cleCalendarState = cleCalendarState;
                    this.$mdDialog = $mdDialog;
                    this.state = this.cleCalendarState.state;
                    this.calendarOptions = {
                        aspectRatio: 0,
                        scrollTime: '06:00',
                        editable: false,
                        eventLimit: true,
                        header: false,
                        selectable: false,
                        selectHelper: false,
                        dayClick: function (targetDate, targetEvent, calendarView) {
                            _this.createEvent(targetDate);
                        },
                        eventClick: function (event) {
                            _this.eventClick(event);
                        },
                        views: {
                            highPriority: {
                                type: 'list',
                                duration: { months: 2 },
                                noEventsMessage: 'No High Priority events to display'
                            }
                        },
                        eventRender: function (event, element) { element.attr('aria-label', event.startTime.toLocaleDateString() + ' ' + element[0].innerText); }
                    };
                    this.refresh = function () {
                        _this.cleCalendarData.refresh(_this.source, _this.sourceId);
                    };
                    this.onViewRender = function (view) {
                        _this.$calendar = view.calendar;
                        _this.title = view.title;
                        var reload = true;
                        if (_this.state.dateRange) {
                            reload = !(_this.state.dateRange.contains(view.start) && _this.state.dateRange.contains(view.end));
                        }
                        var viewDate = view.start.clone();
                        if (view.type === 'month' && view.start.date() > 1) {
                            viewDate = viewDate.startOf('month').add(1, 'month');
                        }
                        _this.cleCalendarState.update({
                            viewType: view.type,
                            viewDate: viewDate
                        });
                        var start = view.start.local();
                        var end = view.end.local();
                        _this.visibleRange = moment.range(start, end);
                        _this.selectedRange = moment.range(start, start);
                        if (reload) {
                            _this.state.dateRange = _this.visibleRange;
                            _this.common.$timeout(_this.refresh);
                        }
                    };
                    this.loadState = function () {
                        _this.calendarOptions.defaultView = _this.state.viewType || 'month';
                        _this.calendarOptions.defaultDate = _this.state.viewDate;
                    };
                    this.setContentHeight = function () {
                        _this.$calendar.option('contentHeight', angular.element('#calendar').height());
                    };
                    'ngInject';
                    'ngInject';
                }
                CLECalendar.prototype.createEvent = function (targetDate) {
                    this.common.$state.go('^.eventSchedule', {
                        targetDate: targetDate,
                        source: this.source,
                        sourceId: this.sourceId
                    });
                };
                CLECalendar.prototype.eventClick = function (event) {
                    var _this = this;
                    var parent = angular.element(document.body);
                    return this.$mdDialog.show({
                        parent: parent,
                        fullscreen: false,
                        templateUrl: 'app/calendars/events/summary-dialog.html',
                        controller: 'EventSummaryDialogController',
                        controllerAs: '$ctrl',
                        locals: {
                            event: event,
                            eventScheduleId: event.eventScheduleId
                        }
                    })
                        .then(function (edit) {
                        if (edit === 'eventSchedule') {
                            _this.common.$state.go('^.eventSchedule', { eventScheduleId: event.eventScheduleId });
                        }
                        else {
                            _this.common.$state.go('^.event', { event: event });
                        }
                    })
                        .catch(function (error) { if (error)
                        throw error; });
                };
                CLECalendar.prototype.today = function () {
                    this.$calendar.today();
                };
                CLECalendar.prototype.next = function () {
                    this.$calendar.next();
                };
                CLECalendar.prototype.prev = function () {
                    this.$calendar.prev();
                };
                CLECalendar.prototype.changeView = function (view) {
                    this.$calendar.changeView(view);
                };
                CLECalendar.prototype.showFilters = function () {
                    this.$mdSidenav('calendarFilters').toggle();
                };
                CLECalendar.prototype.$onInit = function () {
                    this.eventSources = this.cleCalendarData.eventSources;
                    this.calendarOptions.viewRender = this.onViewRender;
                    this.loadState();
                    var cancelToken = this.$scope.$on(app.calendars.EVENT_ADDED, this.refresh);
                    this.$scope.$on('$destroy', cancelToken);
                    this.common.$timeout(this.setContentHeight, 100);
                    angular.element(this.common.$window).bind('resize', this.setContentHeight);
                };
                CLECalendar.$inject = ['$scope', '$mdPanel', '$mdSidenav', 'common', 'cleCalendarData', 'cleCalendarState', '$mdDialog'];
                CLECalendar = __decorate([
                    app.Component('app.training.cle', 'ceCLECalendar', {
                        templateUrl: 'app/training/continuing-legal-education/calendar/calendar.html',
                        bindings: {
                            source: '@',
                            sourceId: '<'
                        }
                    })
                ], CLECalendar);
                return CLECalendar;
            }());
        })(cle = training.cle || (training.cle = {}));
    })(training = app.training || (app.training = {}));
})(app || (app = {}));
var app;
(function (app) {
    var training;
    (function (training) {
        var cle;
        (function (cle) {
            var CLECalendarDataService = (function () {
                function CLECalendarDataService(common, datacontext, cleCalendarState) {
                    var _this = this;
                    this.common = common;
                    this.datacontext = datacontext;
                    this.cleCalendarState = cleCalendarState;
                    this.state = this.cleCalendarState.state;
                    this.refresh = function (source, sourceId) {
                        var params = {
                            source: source,
                            sourceId: sourceId,
                            from: _this.state.dateRange.start.toDate(),
                            to: _this.state.dateRange.end.toDate(),
                            highPriorityOnly: _this.state.viewType === 'highPriority'
                        };
                        _this.eventSources.splice(0);
                        _this.getEvents(params);
                    };
                    this.activate = function () {
                        return _this.getCalendars();
                    };
                    this.getCalendars = function () {
                        return _this.datacontext.calendars.getCalendars().then(function (result) {
                            _this.cleCalendarState.update({ calendars: result });
                        }).finally(function () {
                            var calendarIds = [];
                            angular.forEach(_this.state.calendars, function (c) { return calendarIds.push(c.id); });
                            _this.cleCalendarState.update({ calendarIds: calendarIds });
                        });
                    };
                    this.getEvents = function (params) {
                        _this.datacontext.training
                            .getCLEEvents(params)
                            .then(function (result) {
                            if (result) {
                                var results = _(result);
                                var events = results.map(function (e) { return _this.mapEvent(e); }).value();
                                _this.eventSources.push({ events: events });
                            }
                        });
                    };
                    this.mapEvent = function (e) {
                        var start = moment(e.rescheduledStartTime || e.startTime);
                        var end;
                        if (e.allDay) {
                            start = moment(e.eventDate).startOf('day');
                            end = moment(e.eventDate).endOf('day');
                        }
                        else {
                            end = moment(e.rescheduledEndTime || e.endTime);
                        }
                        var calendar = _.find(_this.state.calendars, function (c) { return c.id === e.calendar; });
                        var color;
                        var isPast = end.diff(moment()) < 0;
                        if (isPast) {
                            color = calendar.completedColor;
                        }
                        else {
                            color = calendar.color;
                        }
                        return __assign(__assign({}, e), { title: "".concat(e.title), color: color, start: start, end: end });
                    };
                    'ngInject';
                    'ngInject';
                    this.eventSources = [];
                    this.activate();
                }
                return CLECalendarDataService;
            }());
            angular
                .module('app.training.cle')
                .service('cleCalendarData', CLECalendarDataService);
        })(cle = training.cle || (training.cle = {}));
    })(training = app.training || (app.training = {}));
})(app || (app = {}));
var app;
(function (app) {
    var training;
    (function (training) {
        var cle;
        (function (cle) {
            var CLECreate = (function () {
                function CLECreate(datacontext, common) {
                    this.datacontext = datacontext;
                    this.common = common;
                    this.data = {};
                    this.isLoading = false;
                    this.isSubmitting = false;
                    'ngInject';
                    'ngInject';
                }
                CLECreate.prototype.$onInit = function () {
                    this.getData();
                    this.data.forum = [];
                    this.data.trainingMaterials = [];
                    this.data.difficultyLevel = [];
                };
                CLECreate.prototype.forumToggle = function (value) {
                    if (this.forumExists(value)) {
                        _.pull(this.data.forum, value);
                    }
                    else {
                        this.data.forum.push(value);
                    }
                };
                CLECreate.prototype.forumExists = function (value) {
                    return _.includes(this.data.forum, value);
                };
                CLECreate.prototype.materialToggle = function (value) {
                    if (this.materialExists(value)) {
                        _.pull(this.data.trainingMaterials, value);
                    }
                    else {
                        this.data.trainingMaterials.push(value);
                    }
                };
                CLECreate.prototype.materialExists = function (value) {
                    return _.includes(this.data.trainingMaterials, value);
                };
                CLECreate.prototype.difficultyLevelToggle = function (value) {
                    if (this.difficultyLevelExists(value)) {
                        _.pull(this.data.difficultyLevel, value);
                    }
                    else {
                        this.data.difficultyLevel.push(value);
                    }
                };
                CLECreate.prototype.difficultyLevelExists = function (value) {
                    return _.includes(this.data.difficultyLevel, value);
                };
                CLECreate.prototype.getData = function () {
                    var _this = this;
                    this.datacontext.config.lookups.getLookupType('course-forum').then(function (result) { return _this.courseForums = result.lookups; });
                };
                CLECreate.prototype.submit = function () {
                    var _this = this;
                    this.data.endTime.setMonth(this.data.startTime.getMonth());
                    this.data.endTime.setDate(this.data.startTime.getDate());
                    this.data.endTime.setFullYear(this.data.startTime.getFullYear());
                    this.data.status = 'Scheduled';
                    this.isSubmitting = true;
                    this.datacontext.training
                        .createCLE(this.data)
                        .then(function (result) {
                        if (result) {
                            _this.common.$state.go('^.cle', { cleId: result });
                        }
                    }).finally(function () { return _this.isSubmitting = false; });
                };
                CLECreate.$inject = ['datacontext', 'common'];
                CLECreate = __decorate([
                    app.Component('app.training.cle', 'ceCLECreate', {
                        templateUrl: 'app/training/continuing-legal-education/create/create.html',
                        bindings: {}
                    })
                ], CLECreate);
                return CLECreate;
            }());
        })(cle = training.cle || (training.cle = {}));
    })(training = app.training || (app.training = {}));
})(app || (app = {}));
var app;
(function (app) {
    var training;
    (function (training) {
        var cle;
        (function (cle) {
            var routes = [
                {
                    name: 'app.cle.entities',
                    url: '/entities',
                    template: '<ui-view flex></ui-view>',
                    redirectTo: 'app.cle.entities.listing',
                    data: { title: 'Request Entities' }
                },
                {
                    name: 'app.cle.entities.listing',
                    url: '/listing',
                    template: '<ce-training-entity-listing training-id="$resolve.cle.id" layout="column" flex></ce-training-entity-listing>',
                    data: { title: 'Request Entities' }
                },
                {
                    name: 'app.cle.entities.add',
                    url: '/add',
                    template: '<ce-select-entity-type flex></ce-select-entity-type>',
                    redirectTo: 'app.cle.entities.add.type',
                    data: { title: 'Add' }
                },
                {
                    name: 'app.cle.entities.add.type',
                    url: '/types',
                    template: '<ce-entity-types flex></ce-entity-types>'
                },
                {
                    name: 'app.cle.entities.add.person',
                    url: '/person',
                    template: '<ui-view layout flex></ui-view>',
                    redirectTo: 'app.cle.entities.add.person.search',
                },
                {
                    name: 'app.cle.entities.add.person.search',
                    url: '/search',
                    template: '<ce-person-search flex></ce-person-search>',
                    data: { title: 'Find Person' }
                },
                {
                    name: 'app.cle.entities.add.person.create',
                    url: '/create',
                    template: '<ce-create-person flex></ce-create-person>',
                    data: { title: 'Create Person' }
                },
                {
                    name: 'app.cle.entities.add.person.roles',
                    url: '/roles',
                    params: { entity: null },
                    resolve: { entity: ['$stateParams', function (p) { return p.entity; }] },
                    template: '<ce-training-entity-roles role-lookup-type-prefix="cle" training-id="$resolve.cle.id" entity="$resolve.entity" layout="column" flex></ce-training-entity-roles>',
                    data: { title: 'Select Roles' }
                },
                {
                    name: 'app.cle.entities.add.agency',
                    url: '/agency',
                    template: '<ui-view layout flex></ui-view>',
                    redirectTo: 'app.cle.entities.add.agency.search',
                },
                {
                    name: 'app.cle.entities.add.agency.search',
                    url: '/search',
                    template: '<ce-agency-search flex></ce-agenyc-search>',
                    data: { title: 'Find Agency' }
                },
                {
                    name: 'app.cle.entities.add.agency.create',
                    url: '/create',
                    template: '<ce-create-agency flex></ce-create-agency>',
                    data: { title: 'Create Agency' }
                },
                {
                    name: 'app.cle.entities.add.agency.roles',
                    url: '/roles',
                    params: { entity: null },
                    resolve: { entity: ['$stateParams', function (p) { return p.entity; }] },
                    template: '<ce-training-entity-roles role-lookup-type-prefix="cle" training-id="$resolve.cle.id" entity="$resolve.entity" layout="column" flex></ce-training-entity-roles>',
                    data: { title: 'Select Roles' }
                },
                {
                    name: 'app.cle.entities.edit',
                    url: '/edit',
                    params: { entity: null },
                    resolve: { entity: ['$stateParams', function (p) { return p.entity; }] },
                    template: '<ce-edit-training-entity-roles role-lookup-type-prefix="cle" training-id="$resolve.cle.id" entity="$resolve.entity" layout="column" flex></ce-edit-training-entity-roles>',
                    data: { title: 'Edit' }
                }
            ];
            var runner = function (routingHelper) {
                'ngInject';
                routingHelper.configureRoutes(routes);
            };
            angular
                .module('app.training.cle.entities')
                .run(runner);
        })(cle = training.cle || (training.cle = {}));
    })(training = app.training || (app.training = {}));
})(app || (app = {}));
var app;
(function (app) {
    var training;
    (function (training) {
        var cle;
        (function (cle) {
            var CLE = (function () {
                function CLE(datacontext) {
                    this.datacontext = datacontext;
                    this.editCLE = false;
                    'ngInject';
                    'ngInject';
                }
                CLE.prototype.updateCLE = function (result) {
                    angular.extend(this.data, result);
                    this.editCLE = false;
                };
                CLE.$inject = ['datacontext'];
                CLE = __decorate([
                    app.Component('app.training.cle', 'ceCLEDetails', {
                        templateUrl: 'app/training/continuing-legal-education/details/cle-details.html',
                        bindings: {
                            data: '<',
                            onEdit: '&'
                        }
                    })
                ], CLE);
                return CLE;
            }());
        })(cle = training.cle || (training.cle = {}));
    })(training = app.training || (app.training = {}));
})(app || (app = {}));
var app;
(function (app) {
    var training;
    (function (training) {
        var cle;
        (function (cle) {
            var CLEInformationForm = (function () {
                function CLEInformationForm(datacontext) {
                    this.datacontext = datacontext;
                    this.isLoading = false;
                    this.isSubmitting = false;
                    'ngInject';
                    'ngInject';
                }
                CLEInformationForm.prototype.$onInit = function () {
                    this.getData();
                };
                CLEInformationForm.prototype.forumToggle = function (value) {
                    if (this.forumExists(value)) {
                        _.pull(this.data.forum, value);
                    }
                    else {
                        this.data.forum.push(value);
                    }
                };
                CLEInformationForm.prototype.forumExists = function (value) {
                    if (this.isLoading) {
                        return;
                    }
                    return _.includes(this.data.forum, value);
                };
                CLEInformationForm.prototype.materialToggle = function (value) {
                    if (this.materialExists(value)) {
                        _.pull(this.data.trainingMaterials, value);
                    }
                    else {
                        this.data.trainingMaterials.push(value);
                    }
                };
                CLEInformationForm.prototype.materialExists = function (value) {
                    if (this.isLoading) {
                        return;
                    }
                    return _.includes(this.data.trainingMaterials, value);
                };
                CLEInformationForm.prototype.difficultyLevelToggle = function (value) {
                    if (this.difficultyLevelExists(value)) {
                        _.pull(this.data.difficultyLevel, value);
                    }
                    else {
                        this.data.difficultyLevel.push(value);
                    }
                };
                CLEInformationForm.prototype.difficultyLevelExists = function (value) {
                    if (this.isLoading) {
                        return;
                    }
                    return _.includes(this.data.difficultyLevel, value);
                };
                CLEInformationForm.prototype.getData = function () {
                    var _this = this;
                    this.isLoading = true;
                    this.datacontext.training
                        .getCLEInformation(this.cleId)
                        .then(function (result) { return _this.data = result; })
                        .finally(function () { return _this.isLoading = false; });
                    this.datacontext.config.lookups.getLookupType('course-forum').then(function (result) { return _this.courseForums = result.lookups; });
                };
                CLEInformationForm.prototype.submit = function () {
                    var _this = this;
                    this.isSubmitting = true;
                    this.datacontext.training
                        .updateCLEInformation(this.data)
                        .then(function (result) {
                        if (result) {
                            _this.onSubmit({ result: result });
                        }
                    }).finally(function () { return _this.isSubmitting = false; });
                };
                CLEInformationForm.$inject = ['datacontext'];
                CLEInformationForm = __decorate([
                    app.Component('app.training.cle', 'ceCLEInformationForm', {
                        templateUrl: 'app/training/continuing-legal-education/details/cle-information-form.html',
                        bindings: {
                            cleId: '<',
                            onCancel: '&',
                            onSubmit: '&'
                        }
                    })
                ], CLEInformationForm);
                return CLEInformationForm;
            }());
        })(cle = training.cle || (training.cle = {}));
    })(training = app.training || (app.training = {}));
})(app || (app = {}));
var app;
(function (app) {
    var training;
    (function (training) {
        var cle;
        (function (cle) {
            var CLEInformation = (function () {
                function CLEInformation() {
                }
                CLEInformation = __decorate([
                    app.Component('app.training.cle', 'ceCLEInformation', {
                        templateUrl: 'app/training/continuing-legal-education/details/cle-information.html',
                        bindings: {
                            data: '<',
                            onEdit: '&'
                        }
                    })
                ], CLEInformation);
                return CLEInformation;
            }());
        })(cle = training.cle || (training.cle = {}));
    })(training = app.training || (app.training = {}));
})(app || (app = {}));
var app;
(function (app) {
    var training;
    (function (training) {
        var cle;
        (function (cle) {
            var CLEOverview = (function () {
                function CLEOverview(datacontext) {
                    this.datacontext = datacontext;
                    'ngInject';
                    'ngInject';
                }
                CLEOverview.prototype.GetCLEInformation = function () {
                    var _this = this;
                    this.datacontext.training.getCLEInformation(this.data.id).then(function (result) { return _this.data = result; });
                };
                CLEOverview.$inject = ['datacontext'];
                CLEOverview = __decorate([
                    app.Component('app.training.cle', 'ceCLEOverview', {
                        templateUrl: 'app/training/continuing-legal-education/overview/cle-overview.html',
                        bindings: {
                            data: '<'
                        }
                    })
                ], CLEOverview);
                return CLEOverview;
            }());
        })(cle = training.cle || (training.cle = {}));
    })(training = app.training || (app.training = {}));
})(app || (app = {}));
var app;
(function (app) {
    var training;
    (function (training) {
        var cle;
        (function (cle) {
            var CLEInformation = (function () {
                function CLEInformation(common, confirmation, datacontext, timerService) {
                    this.common = common;
                    this.confirmation = confirmation;
                    this.datacontext = datacontext;
                    this.timerService = timerService;
                    'ngInject';
                    'ngInject';
                }
                CLEInformation.prototype.$onInit = function () {
                };
                CLEInformation.prototype.showDeleteDialog = function (id) {
                    var _this = this;
                    this.confirmation.show({
                        message: 'Are you sure you want to delete this CLE? This will delete all data attached to this CLE.',
                        ok: 'DELETE',
                        cancel: 'KEEP'
                    }).then(function () { _this.deleteCLE(id); });
                };
                CLEInformation.prototype.deleteCLE = function (id) {
                    var _this = this;
                    this.datacontext.training.deleteCLE(id).then(function (result) {
                        if (result) {
                            _this.timerService.killTimer();
                            _this.common.$state.go('app.cleList');
                        }
                    });
                };
                CLEInformation.$inject = ['common', 'confirmation', 'datacontext', 'timerService'];
                CLEInformation = __decorate([
                    app.Component('app.training.cle', 'ceCLEView', {
                        templateUrl: 'app/training/continuing-legal-education/view/cle-view.html',
                        bindings: {
                            data: '<'
                        }
                    })
                ], CLEInformation);
                return CLEInformation;
            }());
        })(cle = training.cle || (training.cle = {}));
    })(training = app.training || (app.training = {}));
})(app || (app = {}));
var app;
(function (app) {
    var training;
    (function (training) {
        var EntityRoles = (function () {
            function EntityRoles(common, datacontext) {
                var _this = this;
                this.common = common;
                this.datacontext = datacontext;
                this.selectedRoles = [];
                this.trainingEntity = {};
                this.$onInit = function () {
                    _this.getRoles().then(function () { return _this.getExistingRoles(); });
                };
                this.getRoles = function () {
                    var lookupTypeId = '';
                    if (_this.roleLookupTypePrefix === 'training-request') {
                        lookupTypeId = "".concat(_this.roleLookupTypePrefix, "-entity-role");
                    }
                    else {
                        if (_this.entity.isUser) {
                            lookupTypeId = "".concat(_this.roleLookupTypePrefix, "-user-role");
                        }
                        else {
                            lookupTypeId = "".concat(_this.roleLookupTypePrefix, "-").concat(_this.entity.entityType, "-role");
                        }
                    }
                    return _this.datacontext.config.lookups.getLookupType(lookupTypeId).then(function (result) {
                        _this.roles = result.lookups;
                    });
                };
                this.getExistingRoles = function () {
                    return _this.datacontext.training.getEntity(_this.trainingId, _this.entity.entityId).then(function (result) {
                        _this.trainingEntity = result;
                        _.forEach(_this.trainingEntity.roles, function (ieRole) {
                            _this.selectedRoles.push(_.find(_this.roles, function (role) {
                                return role.value === ieRole;
                            }));
                        });
                    });
                };
                this.onSubmit = function () {
                    _this.isSubmitting = true;
                    var roles = _.map(_this.selectedRoles, 'value');
                    angular.extend(_this.trainingEntity, {
                        roles: roles
                    });
                    var promise;
                    promise = _this.datacontext.training.updateEntity(_this.trainingEntity);
                    promise.finally(function () {
                        _this.isSubmitting = false;
                        _this.common.$mdToast.showSimple('Entity Updated');
                        _this.common.$state.go('^.listing');
                    });
                };
                'ngInject';
                'ngInject';
            }
            EntityRoles.prototype.transformChip = function (chip) {
                if (angular.isObject(chip)) {
                    return chip;
                }
                return { name: chip, type: 'new' };
            };
            EntityRoles.prototype.querySearch = function (query) {
                var results = query ? _.filter(this.roles, function (r) {
                    return query === '*' || r.value.toLowerCase().indexOf(query) > -1;
                }) : [];
                return results;
            };
            EntityRoles.$inject = ['common', 'datacontext'];
            EntityRoles = __decorate([
                app.Component('app.training.entities', 'ceEditTrainingEntityRoles', {
                    templateUrl: 'app/training/entities/roles/edit-roles.html',
                    bindings: {
                        entity: '<',
                        trainingId: '<',
                        roleLookupTypePrefix: '@'
                    }
                })
            ], EntityRoles);
            return EntityRoles;
        }());
    })(training = app.training || (app.training = {}));
})(app || (app = {}));
var app;
(function (app) {
    var training;
    (function (training) {
        var EntityRoles = (function () {
            function EntityRoles(common, datacontext) {
                var _this = this;
                this.common = common;
                this.datacontext = datacontext;
                this.selectedRoles = [];
                this.trainingEntity = {};
                this.$onInit = function () {
                    _this.getRoles().then(function () { return _this.getExistingRoles(); });
                };
                this.getRoles = function () {
                    var lookupTypeId = '';
                    if (_this.roleLookupTypePrefix === 'training-request') {
                        lookupTypeId = "".concat(_this.roleLookupTypePrefix, "-entity-role");
                    }
                    else {
                        if (_this.entity.isUser) {
                            lookupTypeId = "".concat(_this.roleLookupTypePrefix, "-user-role");
                        }
                        else {
                            lookupTypeId = "".concat(_this.roleLookupTypePrefix, "-").concat(_this.entity.entityType, "-role");
                        }
                    }
                    return _this.datacontext.config.lookups.getLookupType(lookupTypeId).then(function (result) {
                        _this.roles = result.lookups;
                    });
                };
                this.getExistingRoles = function () {
                    return _this.datacontext.training.getEntity(_this.trainingId, _this.entity.entityId).then(function (result) {
                        angular.extend(_this.trainingEntity, result);
                        _.forEach(_this.trainingEntity.roles, function (ieRole) {
                            _this.selectedRoles.push(_.find(_this.roles, function (role) {
                                return role.value === ieRole;
                            }));
                        });
                    });
                };
                this.onSubmit = function () {
                    _this.isSubmitting = true;
                    var roles = _.map(_this.selectedRoles, 'value');
                    angular.extend(_this.trainingEntity, {
                        entityId: _this.entity.entityId,
                        entityType: _this.entity.entityType,
                        trainingId: _this.trainingId,
                        roles: roles
                    });
                    var promise;
                    if (_this.trainingEntity.id) {
                        promise = _this.datacontext.training.updateEntity(_this.trainingEntity);
                    }
                    else {
                        promise = _this.datacontext.training.createEntity(_this.trainingEntity);
                    }
                    promise.finally(function () {
                        _this.isSubmitting = false;
                        _this.common.$mdToast.showSimple('Entity Updated');
                        _this.common.$state.go('^.^.^');
                    });
                };
                'ngInject';
                'ngInject';
            }
            EntityRoles.prototype.transformChip = function (chip) {
                if (angular.isObject(chip)) {
                    return chip;
                }
                return { name: chip, type: 'new' };
            };
            EntityRoles.prototype.querySearch = function (query) {
                var results = query ? _.filter(this.roles, function (r) {
                    return query === '*' || r.value.toLowerCase().indexOf(query) > -1;
                }) : [];
                return results;
            };
            EntityRoles.$inject = ['common', 'datacontext'];
            EntityRoles = __decorate([
                app.Component('app.training.entities', 'ceTrainingEntityRoles', {
                    templateUrl: 'app/training/entities/roles/roles.html',
                    bindings: {
                        entity: '<',
                        trainingId: '<',
                        roleLookupTypePrefix: '@'
                    }
                })
            ], EntityRoles);
            return EntityRoles;
        }());
    })(training = app.training || (app.training = {}));
})(app || (app = {}));
var app;
(function (app) {
    var training;
    (function (training) {
        var EntityList = (function () {
            function EntityList() {
            }
            EntityList = __decorate([
                app.Component('app.training.entities', 'ceTrainingEntityList', {
                    templateUrl: 'app/training/entities/entity-listing/entity-list.html',
                    bindings: {
                        entities: '<',
                        totalEntities: '<',
                        onDelete: '&'
                    }
                })
            ], EntityList);
            return EntityList;
        }());
    })(training = app.training || (app.training = {}));
})(app || (app = {}));
var app;
(function (app) {
    var training;
    (function (training) {
        var EntityListing = (function () {
            function EntityListing($mdDialog, common, datacontext) {
                var _this = this;
                this.$mdDialog = $mdDialog;
                this.common = common;
                this.datacontext = datacontext;
                this.$onInit = function () {
                    _this.showAdd = _this.showAdd || true;
                    _this.summary = _this.summary || false;
                    _this.getEntities();
                };
                this.getEntities = function () {
                    return _this.datacontext.training
                        .getEntityListing(_this.trainingId)
                        .then(function (result) {
                        _this.entities = result;
                    });
                };
                'ngInject';
                'ngInject';
            }
            EntityListing.prototype.deleteEntity = function (entity) {
                var _this = this;
                this.common.confirmation.show({
                    title: 'Delete Relationship',
                    message: 'Are you sure you want to delete this entity relationship?',
                    ok: 'Delete',
                    cancel: 'Keep'
                }).then(function () {
                    _this.datacontext.training
                        .deleteEntity(entity.id)
                        .then(function () {
                        _this.getEntities();
                    });
                }).catch(angular.noop);
            };
            EntityListing.$inject = ['$mdDialog', 'common', 'datacontext'];
            EntityListing = __decorate([
                app.Component('app.training.entities', 'ceTrainingEntityListing', {
                    templateUrl: 'app/training/entities/entity-listing/entity-listing.html',
                    bindings: {
                        trainingId: '<',
                        showAdd: '@',
                        summary: '<'
                    }
                })
            ], EntityListing);
            return EntityListing;
        }());
    })(training = app.training || (app.training = {}));
})(app || (app = {}));
var app;
(function (app) {
    var training;
    (function (training) {
        var EntityList = (function () {
            function EntityList() {
            }
            EntityList = __decorate([
                app.Component('app.training.entities', 'ceTrainingEntitySummaryList', {
                    templateUrl: 'app/training/entities/entity-listing/summary-list.html',
                    bindings: {
                        entities: '<',
                        totalEntities: '<'
                    }
                })
            ], EntityList);
            return EntityList;
        }());
    })(training = app.training || (app.training = {}));
})(app || (app = {}));
var app;
(function (app) {
    var training;
    (function (training) {
        var request;
        (function (request) {
            var RequestCreate = (function () {
                function RequestCreate(datacontext, common) {
                    this.datacontext = datacontext;
                    this.common = common;
                    this.data = {};
                    this.isLoading = false;
                    this.isSubmitting = false;
                    'ngInject';
                    'ngInject';
                }
                RequestCreate.prototype.$onInit = function () {
                    this.data.receivedDate = moment().toDate();
                };
                RequestCreate.prototype.submit = function () {
                    var _this = this;
                    this.isSubmitting = true;
                    this.datacontext.training
                        .createRequest(this.data)
                        .then(function (result) {
                        if (result) {
                            _this.common.$state.go('^.trainingRequestList');
                        }
                    }).finally(function () { return _this.isSubmitting = false; });
                };
                RequestCreate.$inject = ['datacontext', 'common'];
                RequestCreate = __decorate([
                    app.Component('app.training.request', 'ceTrainingRequestCreate', {
                        templateUrl: 'app/training/request/create/create.html',
                        bindings: {}
                    })
                ], RequestCreate);
                return RequestCreate;
            }());
        })(request = training.request || (training.request = {}));
    })(training = app.training || (app.training = {}));
})(app || (app = {}));
var app;
(function (app) {
    var training;
    (function (training) {
        var request;
        (function (request) {
            var Request = (function () {
                function Request(datacontext) {
                    this.datacontext = datacontext;
                    this.editRequest = false;
                    'ngInject';
                    'ngInject';
                }
                Request.prototype.updateRequest = function (result) {
                    angular.extend(this.data, result);
                    this.editRequest = false;
                };
                Request.$inject = ['datacontext'];
                Request = __decorate([
                    app.Component('app.training.request', 'ceTrainingRequestDetails', {
                        templateUrl: 'app/training/request/details/request-details.html',
                        bindings: {
                            data: '<',
                            onEdit: '&'
                        }
                    })
                ], Request);
                return Request;
            }());
        })(request = training.request || (training.request = {}));
    })(training = app.training || (app.training = {}));
})(app || (app = {}));
var app;
(function (app) {
    var training;
    (function (training) {
        var request;
        (function (request) {
            var RequestInformationForm = (function () {
                function RequestInformationForm(datacontext) {
                    this.datacontext = datacontext;
                    this.isLoading = false;
                    this.isSubmitting = false;
                    'ngInject';
                    'ngInject';
                }
                RequestInformationForm.prototype.$onInit = function () {
                    this.getData();
                };
                RequestInformationForm.prototype.getData = function () {
                    var _this = this;
                    this.isLoading = true;
                    this.datacontext.training
                        .getRequestInformation(this.requestId)
                        .then(function (result) { return _this.data = result; })
                        .finally(function () { return _this.isLoading = false; });
                };
                RequestInformationForm.prototype.submit = function () {
                    var _this = this;
                    this.isSubmitting = true;
                    this.datacontext.training
                        .updateRequestInformation(this.data)
                        .then(function (result) {
                        if (result) {
                            _this.onSubmit({ result: result });
                        }
                    }).finally(function () { return _this.isSubmitting = false; });
                };
                RequestInformationForm.$inject = ['datacontext'];
                RequestInformationForm = __decorate([
                    app.Component('app.training.request', 'ceTrainingRequestInformationForm', {
                        templateUrl: 'app/training/request/details/request-information-form.html',
                        bindings: {
                            requestId: '<',
                            onCancel: '&',
                            onSubmit: '&'
                        }
                    })
                ], RequestInformationForm);
                return RequestInformationForm;
            }());
        })(request = training.request || (training.request = {}));
    })(training = app.training || (app.training = {}));
})(app || (app = {}));
var app;
(function (app) {
    var training;
    (function (training_7) {
        var training;
        (function (training) {
            var TrainingInformation = (function () {
                function TrainingInformation() {
                }
                TrainingInformation = __decorate([
                    app.Component('app.training.training', 'ceTrainingRequestInformation', {
                        templateUrl: 'app/training/request/details/request-information.html',
                        bindings: {
                            data: '<',
                            onEdit: '&'
                        }
                    })
                ], TrainingInformation);
                return TrainingInformation;
            }());
        })(training = training_7.training || (training_7.training = {}));
    })(training = app.training || (app.training = {}));
})(app || (app = {}));
var app;
(function (app) {
    var training;
    (function (training) {
        var request;
        (function (request) {
            var routes = [
                {
                    name: 'app.trainingRequest.entities',
                    url: '/entities',
                    template: '<ui-view flex></ui-view>',
                    redirectTo: 'app.trainingRequest.entities.listing',
                    data: { title: 'Request Entities' }
                },
                {
                    name: 'app.trainingRequest.entities.listing',
                    url: '/listing',
                    template: '<ce-training-entity-listing training-id="$resolve.request.id" layout="column" flex></ce-training-entity-listing>',
                    data: { title: 'Request Entities' }
                },
                {
                    name: 'app.trainingRequest.entities.add',
                    url: '/add',
                    template: '<ce-select-entity-type flex></ce-select-entity-type>',
                    redirectTo: 'app.trainingRequest.entities.add.type',
                    data: { title: 'Add' }
                },
                {
                    name: 'app.trainingRequest.entities.add.type',
                    url: '/types',
                    template: '<ce-entity-types flex></ce-entity-types>'
                },
                {
                    name: 'app.trainingRequest.entities.add.person',
                    url: '/person',
                    template: '<ui-view layout flex></ui-view>',
                    redirectTo: 'app.trainingRequest.entities.add.person.search',
                },
                {
                    name: 'app.trainingRequest.entities.add.person.search',
                    url: '/search',
                    template: '<ce-person-search flex></ce-person-search>',
                    data: { title: 'Find Person' }
                },
                {
                    name: 'app.trainingRequest.entities.add.person.create',
                    url: '/create',
                    template: '<ce-create-person flex></ce-create-person>',
                    data: { title: 'Create Person' }
                },
                {
                    name: 'app.trainingRequest.entities.add.person.roles',
                    url: '/roles',
                    params: { entity: null },
                    resolve: { entity: ['$stateParams', function (p) { return p.entity; }] },
                    template: '<ce-training-entity-roles role-lookup-type-prefix="training-request" training-id="$resolve.request.id" entity="$resolve.entity" layout="column" flex></ce-training-entity-roles>',
                    data: { title: 'Select Roles' }
                },
                {
                    name: 'app.trainingRequest.entities.add.agency',
                    url: '/agency',
                    template: '<ui-view layout flex></ui-view>',
                    redirectTo: 'app.trainingRequest.entities.add.agency.search',
                },
                {
                    name: 'app.trainingRequest.entities.add.agency.search',
                    url: '/search',
                    template: '<ce-agency-search flex></ce-agenyc-search>',
                    data: { title: 'Find Agency' }
                },
                {
                    name: 'app.trainingRequest.entities.add.agency.create',
                    url: '/create',
                    template: '<ce-create-agency flex></ce-create-agency>',
                    data: { title: 'Create Agency' }
                },
                {
                    name: 'app.trainingRequest.entities.add.agency.roles',
                    url: '/roles',
                    params: { entity: null },
                    resolve: { entity: ['$stateParams', function (p) { return p.entity; }] },
                    template: '<ce-training-entity-roles role-lookup-type-prefix="training-request" training-id="$resolve.request.id" entity="$resolve.entity" layout="column" flex></ce-training-entity-roles>',
                    data: { title: 'Select Roles' }
                },
                {
                    name: 'app.trainingRequest.entities.edit',
                    url: '/edit',
                    params: { entity: null },
                    resolve: { entity: ['$stateParams', function (p) { return p.entity; }] },
                    template: '<ce-edit-training-entity-roles role-lookup-type-prefix="training-request" training-id="$resolve.request.id" entity="$resolve.entity" layout="column" flex></ce-edit-training-entity-roles>',
                    data: { title: 'Edit' }
                }
            ];
            var runner = function (routingHelper) {
                'ngInject';
                routingHelper.configureRoutes(routes);
            };
            angular
                .module('app.training.request.entities')
                .run(runner);
        })(request = training.request || (training.request = {}));
    })(training = app.training || (app.training = {}));
})(app || (app = {}));
var app;
(function (app) {
    var training;
    (function (training) {
        var request;
        (function (request) {
            var RequestOverview = (function () {
                function RequestOverview(datacontext) {
                    this.datacontext = datacontext;
                    'ngInject';
                    'ngInject';
                }
                RequestOverview.prototype.GetRequestInformation = function () {
                    var _this = this;
                    this.datacontext.training.getRequestInformation(this.data.id).then(function (result) { return _this.data = result; });
                };
                RequestOverview.$inject = ['datacontext'];
                RequestOverview = __decorate([
                    app.Component('app.training.request', 'ceTrainingRequestOverview', {
                        templateUrl: 'app/training/request/overview/request-overview.html',
                        bindings: {
                            data: '<'
                        }
                    })
                ], RequestOverview);
                return RequestOverview;
            }());
        })(request = training.request || (training.request = {}));
    })(training = app.training || (app.training = {}));
})(app || (app = {}));
var app;
(function (app) {
    var training;
    (function (training) {
        var request;
        (function (request) {
            var RequestInformation = (function () {
                function RequestInformation(common, confirmation, datacontext) {
                    this.common = common;
                    this.confirmation = confirmation;
                    this.datacontext = datacontext;
                    'ngInject';
                    'ngInject';
                    this.$state = common.$state;
                }
                RequestInformation.prototype.$onInit = function () {
                };
                RequestInformation.prototype.showDeleteDialog = function (id) {
                    var _this = this;
                    this.confirmation.show({
                        message: 'Are you sure you want to delete this Training Request? This will delete all data attached to this Training Request.',
                        ok: 'DELETE',
                        cancel: 'KEEP'
                    }).then(function () { _this.deleteTrainingRequest(id); });
                };
                RequestInformation.prototype.deleteTrainingRequest = function (id) {
                    var _this = this;
                    this.datacontext.training.deleteTrainingRequest(id).then(function (result) {
                        if (result) {
                            _this.common.$state.go('app.trainingRequestList');
                        }
                    });
                };
                RequestInformation.$inject = ['common', 'confirmation', 'datacontext'];
                RequestInformation = __decorate([
                    app.Component('app.training.request', 'ceTrainingRequestView', {
                        templateUrl: 'app/training/request/view/request-view.html',
                        bindings: {
                            data: '<'
                        }
                    })
                ], RequestInformation);
                return RequestInformation;
            }());
        })(request = training.request || (training.request = {}));
    })(training = app.training || (app.training = {}));
})(app || (app = {}));
var app;
(function (app) {
    var training;
    (function (training_8) {
        var training;
        (function (training) {
            var TrainingCalendarStateService = (function () {
                function TrainingCalendarStateService($localStorage) {
                    var _this = this;
                    this.$localStorage = $localStorage;
                    this.state = {};
                    this.loadState = function () {
                        if (_this.$localStorage['trainingCalendarState']) {
                            angular.extend(_this.state, _this.$localStorage['trainingCalendarState']);
                        }
                        else {
                            _this.$localStorage['trainingCalendarState'] = _this.state;
                        }
                        if (_this.state.dateRange) {
                            _this.state.dateRange = moment.range(_this.state.dateRange.start, _this.state.dateRange.end);
                        }
                    };
                    'ngInject';
                    'ngInject';
                    if ($localStorage.calendarState) {
                        delete $localStorage.calendarState.dateRange;
                        delete $localStorage.calendarState.viewType;
                        delete $localStorage.calendarState.viewDate;
                    }
                    this.loadState();
                }
                TrainingCalendarStateService.prototype.save = function () {
                    this.$localStorage['trainingCalendarState'] = this.state;
                };
                TrainingCalendarStateService.prototype.update = function (state) {
                    angular.extend(this.state, state);
                    angular.extend(this.$localStorage['trainingCalendarState'], state);
                };
                return TrainingCalendarStateService;
            }());
            angular
                .module('app.training.training')
                .service('trainingCalendarState', TrainingCalendarStateService);
        })(training = training_8.training || (training_8.training = {}));
    })(training = app.training || (app.training = {}));
})(app || (app = {}));
var app;
(function (app) {
    var training;
    (function (training_9) {
        var training;
        (function (training) {
            var TrainingCalendar = (function () {
                function TrainingCalendar($scope, $mdPanel, $mdSidenav, common, trainingCalendarData, trainingCalendarState, $mdDialog) {
                    var _this = this;
                    this.$scope = $scope;
                    this.$mdPanel = $mdPanel;
                    this.$mdSidenav = $mdSidenav;
                    this.common = common;
                    this.trainingCalendarData = trainingCalendarData;
                    this.trainingCalendarState = trainingCalendarState;
                    this.$mdDialog = $mdDialog;
                    this.state = this.trainingCalendarState.state;
                    this.calendarOptions = {
                        aspectRatio: 0,
                        scrollTime: '06:00',
                        editable: false,
                        eventLimit: true,
                        header: false,
                        selectable: false,
                        selectHelper: false,
                        dayClick: function (targetDate, targetEvent, calendarView) {
                            _this.createEvent(targetDate);
                        },
                        eventClick: function (event) {
                            _this.eventClick(event);
                        },
                        views: {
                            highPriority: {
                                type: 'list',
                                duration: { months: 2 },
                                noEventsMessage: 'No High Priority events to display'
                            }
                        },
                        eventRender: function (event, element) { element.attr('aria-label', event.startTime.toLocaleDateString() + ' ' + element[0].innerText); }
                    };
                    this.refresh = function () {
                        _this.trainingCalendarData.refresh(_this.source, _this.sourceId);
                    };
                    this.onViewRender = function (view) {
                        _this.$calendar = view.calendar;
                        _this.title = view.title;
                        var reload = true;
                        if (_this.state.dateRange) {
                            reload = !(_this.state.dateRange.contains(view.start) && _this.state.dateRange.contains(view.end));
                        }
                        var viewDate = view.start.clone();
                        if (view.type === 'month' && view.start.date() > 1) {
                            viewDate = viewDate.startOf('month').add(1, 'month');
                        }
                        _this.trainingCalendarState.update({
                            viewType: view.type,
                            viewDate: viewDate
                        });
                        var start = view.start.local();
                        var end = view.end.local();
                        _this.visibleRange = moment.range(start, end);
                        _this.selectedRange = moment.range(start, start);
                        if (reload) {
                            _this.state.dateRange = _this.visibleRange;
                            _this.common.$timeout(_this.refresh);
                        }
                    };
                    this.loadState = function () {
                        _this.calendarOptions.defaultView = _this.state.viewType || 'month';
                        _this.calendarOptions.defaultDate = _this.state.viewDate;
                    };
                    this.setContentHeight = function () {
                        _this.$calendar.option('contentHeight', angular.element('#calendar').height());
                    };
                    'ngInject';
                    'ngInject';
                }
                TrainingCalendar.prototype.createEvent = function (targetDate) {
                    this.common.$state.go('^.eventSchedule', {
                        targetDate: targetDate,
                        source: this.source,
                        sourceId: this.sourceId
                    });
                };
                TrainingCalendar.prototype.eventClick = function (event) {
                    var _this = this;
                    var parent = angular.element(document.body);
                    return this.$mdDialog.show({
                        parent: parent,
                        fullscreen: false,
                        templateUrl: 'app/calendars/events/summary-dialog.html',
                        controller: 'EventSummaryDialogController',
                        controllerAs: '$ctrl',
                        locals: {
                            event: event,
                            eventScheduleId: event.eventScheduleId
                        }
                    })
                        .then(function (edit) {
                        if (edit === 'eventSchedule') {
                            _this.common.$state.go('^.eventSchedule', { eventScheduleId: event.eventScheduleId });
                        }
                        else {
                            _this.common.$state.go('^.event', { event: event });
                        }
                    })
                        .catch(function (error) { if (error)
                        throw error; });
                };
                TrainingCalendar.prototype.today = function () {
                    this.$calendar.today();
                };
                TrainingCalendar.prototype.next = function () {
                    this.$calendar.next();
                };
                TrainingCalendar.prototype.prev = function () {
                    this.$calendar.prev();
                };
                TrainingCalendar.prototype.changeView = function (view) {
                    this.$calendar.changeView(view);
                };
                TrainingCalendar.prototype.showFilters = function () {
                    this.$mdSidenav('calendarFilters').toggle();
                };
                TrainingCalendar.prototype.$onInit = function () {
                    this.eventSources = this.trainingCalendarData.eventSources;
                    this.calendarOptions.viewRender = this.onViewRender;
                    this.loadState();
                    var cancelToken = this.$scope.$on(app.calendars.EVENT_ADDED, this.refresh);
                    this.$scope.$on('$destroy', cancelToken);
                    this.common.$timeout(this.setContentHeight, 100);
                    angular.element(this.common.$window).bind('resize', this.setContentHeight);
                };
                TrainingCalendar.$inject = ['$scope', '$mdPanel', '$mdSidenav', 'common', 'trainingCalendarData', 'trainingCalendarState', '$mdDialog'];
                TrainingCalendar = __decorate([
                    app.Component('app.training.training', 'ceTrainingCalendar', {
                        templateUrl: 'app/training/training/calendar/calendar.html',
                        bindings: {
                            source: '@',
                            sourceId: '<'
                        }
                    })
                ], TrainingCalendar);
                return TrainingCalendar;
            }());
        })(training = training_9.training || (training_9.training = {}));
    })(training = app.training || (app.training = {}));
})(app || (app = {}));
var app;
(function (app) {
    var training;
    (function (training_10) {
        var training;
        (function (training) {
            var TrainingCalendarDataService = (function () {
                function TrainingCalendarDataService(common, datacontext, trainingCalendarState) {
                    var _this = this;
                    this.common = common;
                    this.datacontext = datacontext;
                    this.trainingCalendarState = trainingCalendarState;
                    this.state = this.trainingCalendarState.state;
                    this.refresh = function (source, sourceId) {
                        var params = {
                            source: source,
                            sourceId: sourceId,
                            from: _this.state.dateRange.start.toDate(),
                            to: _this.state.dateRange.end.toDate(),
                            highPriorityOnly: _this.state.viewType === 'highPriority'
                        };
                        _this.eventSources.splice(0);
                        _this.getEvents(params);
                    };
                    this.activate = function () {
                        return _this.getCalendars();
                    };
                    this.getCalendars = function () {
                        return _this.datacontext.calendars.getCalendars().then(function (result) {
                            _this.trainingCalendarState.update({ calendars: result });
                        }).finally(function () {
                            var calendarIds = [];
                            angular.forEach(_this.state.calendars, function (c) { return calendarIds.push(c.id); });
                            _this.trainingCalendarState.update({ calendarIds: calendarIds });
                        });
                    };
                    this.getEvents = function (params) {
                        _this.datacontext.training
                            .getTrainingEvents(params)
                            .then(function (result) {
                            if (result) {
                                var results = _(result);
                                var events = results.map(function (e) { return _this.mapEvent(e); }).value();
                                _this.eventSources.push({ events: events });
                            }
                        });
                    };
                    this.mapEvent = function (e) {
                        var start = moment(e.rescheduledStartTime || e.startTime);
                        var end;
                        if (e.allDay) {
                            start = moment(e.eventDate).startOf('day');
                            end = moment(e.eventDate).endOf('day');
                        }
                        else {
                            end = moment(e.rescheduledEndTime || e.endTime);
                        }
                        var calendar = _.find(_this.state.calendars, function (c) { return c.id === e.calendar; });
                        var color;
                        var isPast = end.diff(moment()) < 0;
                        if (isPast) {
                            color = calendar.completedColor;
                        }
                        else {
                            color = calendar.color;
                        }
                        return __assign(__assign({}, e), { title: "".concat(e.title), color: color, start: start, end: end });
                    };
                    'ngInject';
                    'ngInject';
                    this.eventSources = [];
                    this.activate();
                }
                return TrainingCalendarDataService;
            }());
            angular
                .module('app.training.training')
                .service('trainingCalendarData', TrainingCalendarDataService);
        })(training = training_10.training || (training_10.training = {}));
    })(training = app.training || (app.training = {}));
})(app || (app = {}));
var app;
(function (app) {
    var training;
    (function (training_11) {
        var training;
        (function (training) {
            var activities;
            (function (activities) {
                var routes = [
                    {
                        name: 'app.training.activities',
                        url: '/activities',
                        template: '<ui-view layout="column" flex></ui-view>',
                        redirectTo: 'app.training.activities.list'
                    },
                    {
                        name: 'app.training.activities.list',
                        url: '',
                        template: "<ce-training-activity-list\n                        training-id=\"$resolve.training.id\"\n                        layout=\"column\" flex>\n                    </ce-training-activity-list>",
                        data: {
                            title: 'Training Activities',
                            permissions: ['access_activities']
                        }
                    },
                    {
                        name: 'app.training.activities.note',
                        url: '/note',
                        template: "<ce-training-activities-note layout=\"column\" flex\n                        training-Id=\"$resolve.training.id\"\n                        activity-Type=\"TrainingNote\"\n                        name=\"Note\"\n                        activity=\"$resolve.activity\"\n                        case-open-date=\"$resolve.training.startTime\"\n                        return-sref=\"app.training.training.activities.list\">\n                    </ce-training-activities-note>",
                        params: { activity: null },
                        resolve: { activity: ['$stateParams', function (p) { return p.activity; }] },
                        data: { title: 'Note' }
                    }
                ];
                var runner = function (routingHelper) {
                    'ngInject';
                    routingHelper.configureRoutes(routes);
                };
                angular
                    .module('app.training.training.activities')
                    .run(runner);
            })(activities = training.activities || (training.activities = {}));
        })(training = training_11.training || (training_11.training = {}));
    })(training = app.training || (app.training = {}));
})(app || (app = {}));
var app;
(function (app) {
    var training;
    (function (training_12) {
        var training;
        (function (training) {
            var TrainingInformationForm = (function () {
                function TrainingInformationForm(datacontext, common) {
                    this.datacontext = datacontext;
                    this.common = common;
                    this.data = {};
                    this.isLoading = false;
                    this.isSubmitting = false;
                    'ngInject';
                    'ngInject';
                }
                TrainingInformationForm.prototype.$onInit = function () {
                    this.getData();
                    this.data.forum = [];
                    this.data.subjectMatter = [];
                    this.data.disabilityType = [];
                };
                TrainingInformationForm.prototype.forumToggle = function (value) {
                    if (this.forumExists(value)) {
                        _.pull(this.data.forum, value);
                    }
                    else {
                        this.data.forum.push(value);
                    }
                };
                TrainingInformationForm.prototype.forumExists = function (value) {
                    return _.includes(this.data.forum, value);
                };
                TrainingInformationForm.prototype.subjectMatterToggle = function (value) {
                    if (this.subjectMatterExists(value)) {
                        _.pull(this.data.subjectMatter, value);
                    }
                    else {
                        this.data.subjectMatter.push(value);
                    }
                };
                TrainingInformationForm.prototype.subjectMatterExists = function (value) {
                    return _.includes(this.data.subjectMatter, value);
                };
                TrainingInformationForm.prototype.disabilityTypeToggle = function (value) {
                    if (value === '') {
                        return;
                    }
                    if (this.disabilityTypeExists(value)) {
                        _.pull(this.data.disabilityType, value);
                    }
                    else {
                        this.data.disabilityType.push(value);
                    }
                };
                TrainingInformationForm.prototype.disabilityTypeExists = function (value) {
                    if (this.isLoading) {
                        return;
                    }
                    return _.includes(this.data.disabilityType, value);
                };
                TrainingInformationForm.prototype.getData = function () {
                    var _this = this;
                    this.isLoading = true;
                    this.datacontext.config.lookups.getLookupType('training-subject-matter').then(function (result) { return _this.subjectMatters = result.lookups; }).finally(function () { return _this.isLoading = false; });
                    this.datacontext.config.lookups.getLookupType('training-disability').then(function (result) { return _this.disabilityTypes = result.lookups; });
                    this.datacontext.config.lookups.getLookupType('training-forum').then(function (result) { return _this.trainingForums = result.lookups; });
                };
                TrainingInformationForm.prototype.submit = function () {
                    var _this = this;
                    this.data.status = 'Scheduled';
                    this.isSubmitting = true;
                    this.datacontext.training
                        .createTraining(this.data)
                        .then(function (result) {
                        if (result)
                            _this.common.$state.go('^.training', { trainingId: result });
                    }).finally(function () { return _this.isSubmitting = false; });
                };
                TrainingInformationForm.$inject = ['datacontext', 'common'];
                TrainingInformationForm = __decorate([
                    app.Component('app.training.training', 'ceTrainingCreate', {
                        templateUrl: 'app/training/training/create/create.html',
                        bindings: {
                            trainingId: '<',
                            onCancel: '&',
                            onSubmit: '&'
                        }
                    })
                ], TrainingInformationForm);
                return TrainingInformationForm;
            }());
        })(training = training_12.training || (training_12.training = {}));
    })(training = app.training || (app.training = {}));
})(app || (app = {}));
var app;
(function (app) {
    var training;
    (function (training_13) {
        var training;
        (function (training) {
            var routes = [
                {
                    name: 'app.training.entities',
                    url: '/entities',
                    template: '<ui-view flex></ui-view>',
                    redirectTo: 'app.training.entities.listing',
                    data: { title: 'Request Entities' }
                },
                {
                    name: 'app.training.entities.listing',
                    url: '/listing',
                    template: '<ce-training-entity-listing training-id="$resolve.training.id" layout="column" flex></ce-training-entity-listing>',
                    data: { title: 'Request Entities' }
                },
                {
                    name: 'app.training.entities.add',
                    url: '/add',
                    template: '<ce-select-entity-type flex></ce-select-entity-type>',
                    redirectTo: 'app.training.entities.add.type',
                    data: {
                        title: 'Add'
                    }
                },
                {
                    name: 'app.training.entities.add.type',
                    url: '/types',
                    template: '<ce-entity-types flex></ce-entity-types>'
                },
                {
                    name: 'app.training.entities.add.person',
                    url: '/person',
                    template: '<ui-view layout flex></ui-view>',
                    redirectTo: 'app.training.entities.add.person.search',
                },
                {
                    name: 'app.training.entities.add.person.search',
                    url: '/search',
                    template: '<ce-person-search flex></ce-person-search>',
                    data: { title: 'Find Person' }
                },
                {
                    name: 'app.training.entities.add.person.create',
                    url: '/create',
                    template: '<ce-create-person flex></ce-create-person>',
                    data: { title: 'Create Person' }
                },
                {
                    name: 'app.training.entities.add.person.roles',
                    url: '/roles',
                    params: { entity: null },
                    resolve: { entity: ['$stateParams', function (p) { return p.entity; }] },
                    template: '<ce-training-entity-roles role-lookup-type-prefix="training" training-id="$resolve.training.id" entity="$resolve.entity" layout="column" flex></ce-training-entity-roles>',
                    data: { title: 'Select Roles' }
                },
                {
                    name: 'app.training.entities.add.agency',
                    url: '/agency',
                    template: '<ui-view layout flex></ui-view>',
                    redirectTo: 'app.training.entities.add.agency.search',
                },
                {
                    name: 'app.training.entities.add.agency.search',
                    url: '/search',
                    template: '<ce-agency-search flex></ce-agenyc-search>',
                    data: { title: 'Find Agency' }
                },
                {
                    name: 'app.training.entities.add.agency.create',
                    url: '/create',
                    template: '<ce-create-agency flex></ce-create-agency>',
                    data: { title: 'Create Agency' }
                },
                {
                    name: 'app.training.entities.add.agency.roles',
                    url: '/roles',
                    params: { entity: null },
                    resolve: { entity: ['$stateParams', function (p) { return p.entity; }] },
                    template: '<ce-training-entity-roles role-lookup-type-prefix="training" training-id="$resolve.training.id" entity="$resolve.entity" layout="column" flex></ce-training-entity-roles>',
                    data: { title: 'Select Roles' }
                },
                {
                    name: 'app.training.entities.edit',
                    url: '/edit',
                    params: { entity: null },
                    resolve: { entity: ['$stateParams', function (p) { return p.entity; }] },
                    template: '<ce-edit-training-entity-roles role-lookup-type-prefix="training" training-id="$resolve.training.id" entity="$resolve.entity" layout="column" flex></ce-edit-training-entity-roles>',
                    data: { title: 'Edit' }
                }
            ];
            var runner = function (routingHelper) {
                'ngInject';
                routingHelper.configureRoutes(routes);
            };
            angular
                .module('app.training.training.entities')
                .run(runner);
        })(training = training_13.training || (training_13.training = {}));
    })(training = app.training || (app.training = {}));
})(app || (app = {}));
var app;
(function (app) {
    var training;
    (function (training_14) {
        var training;
        (function (training) {
            var Training = (function () {
                function Training(datacontext) {
                    this.datacontext = datacontext;
                    this.editTraining = false;
                    'ngInject';
                    'ngInject';
                }
                Training.prototype.updateTraining = function (result) {
                    angular.extend(this.data, result);
                    this.editTraining = false;
                };
                Training.$inject = ['datacontext'];
                Training = __decorate([
                    app.Component('app.training.training', 'ceTrainingDetails', {
                        templateUrl: 'app/training/training/details/details.html',
                        bindings: {
                            data: '<',
                            paramNumberOfBrochures: '<'
                        }
                    })
                ], Training);
                return Training;
            }());
        })(training = training_14.training || (training_14.training = {}));
    })(training = app.training || (app.training = {}));
})(app || (app = {}));
var app;
(function (app) {
    var training;
    (function (training_15) {
        var training;
        (function (training) {
            var TrainingInformationForm = (function () {
                function TrainingInformationForm(datacontext) {
                    this.datacontext = datacontext;
                    this.isLoading = false;
                    this.isSubmitting = false;
                    'ngInject';
                    'ngInject';
                }
                TrainingInformationForm.prototype.$onInit = function () {
                    this.getData();
                };
                TrainingInformationForm.prototype.forumToggle = function (value) {
                    if (value === '') {
                        return;
                    }
                    if (this.forumExists(value)) {
                        _.pull(this.data.forum, value);
                    }
                    else {
                        this.data.forum.push(value);
                    }
                };
                TrainingInformationForm.prototype.forumExists = function (value) {
                    if (this.isLoading) {
                        return;
                    }
                    return _.includes(this.data.forum, value);
                };
                TrainingInformationForm.prototype.subjectMatterToggle = function (value) {
                    if (value === '') {
                        return;
                    }
                    if (this.subjectMatterExists(value)) {
                        _.pull(this.data.subjectMatter, value);
                    }
                    else {
                        this.data.subjectMatter.push(value);
                    }
                };
                TrainingInformationForm.prototype.subjectMatterExists = function (value) {
                    if (this.isLoading) {
                        return;
                    }
                    return _.includes(this.data.subjectMatter, value);
                };
                TrainingInformationForm.prototype.disabilityTypeToggle = function (value) {
                    if (value === '') {
                        return;
                    }
                    if (this.disabilityTypeExists(value)) {
                        _.pull(this.data.disabilityType, value);
                    }
                    else {
                        this.data.disabilityType.push(value);
                    }
                };
                TrainingInformationForm.prototype.disabilityTypeExists = function (value) {
                    if (this.isLoading) {
                        return;
                    }
                    return _.includes(this.data.disabilityType, value);
                };
                TrainingInformationForm.prototype.getData = function () {
                    var _this = this;
                    this.isLoading = true;
                    this.datacontext.training
                        .getTrainingInformation(this.trainingId)
                        .then(function (result) { return _this.data = result; })
                        .finally(function () { return _this.isLoading = false; });
                    this.datacontext.config.lookups.getLookupType('training-subject-matter').then(function (result) { return _this.subjectMatters = result.lookups; });
                    this.datacontext.config.lookups.getLookupType('training-disability').then(function (result) { return _this.disabilityTypes = result.lookups; });
                    this.datacontext.config.lookups.getLookupType('training-forum').then(function (result) { return _this.trainingForums = result.lookups; });
                };
                TrainingInformationForm.prototype.submit = function () {
                    var _this = this;
                    this.isSubmitting = true;
                    this.datacontext.training
                        .updateTrainingInformation(this.data)
                        .then(function (result) {
                        if (result)
                            _this.onSubmit({ result: result });
                    }).finally(function () { return _this.isSubmitting = false; });
                };
                TrainingInformationForm.$inject = ['datacontext'];
                TrainingInformationForm = __decorate([
                    app.Component('app.training.training', 'ceTrainingInformationForm', {
                        templateUrl: 'app/training/training/details/training-information-form.html',
                        bindings: {
                            trainingId: '<',
                            paramNumberOfBrochures: '<',
                            onCancel: '&',
                            onSubmit: '&'
                        }
                    })
                ], TrainingInformationForm);
                return TrainingInformationForm;
            }());
        })(training = training_15.training || (training_15.training = {}));
    })(training = app.training || (app.training = {}));
})(app || (app = {}));
var app;
(function (app) {
    var training;
    (function (training_16) {
        var training;
        (function (training) {
            var TrainingInformation = (function () {
                function TrainingInformation() {
                }
                TrainingInformation = __decorate([
                    app.Component('app.training.training', 'ceTrainingInformation', {
                        templateUrl: 'app/training/training/details/training-information.html',
                        bindings: {
                            data: '<',
                            paramNumberOfBrochures: '<',
                            onEdit: '&'
                        }
                    })
                ], TrainingInformation);
                return TrainingInformation;
            }());
        })(training = training_16.training || (training_16.training = {}));
    })(training = app.training || (app.training = {}));
})(app || (app = {}));
var app;
(function (app) {
    var training;
    (function (training_17) {
        var training;
        (function (training) {
            var TrainingOverview = (function () {
                function TrainingOverview(datacontext) {
                    this.datacontext = datacontext;
                    'ngInject';
                    'ngInject';
                }
                TrainingOverview.prototype.GetTrainingInformation = function () {
                    var _this = this;
                    this.datacontext.training.getTrainingInformation(this.data.id).then(function (result) { return _this.data = result; });
                };
                TrainingOverview.$inject = ['datacontext'];
                TrainingOverview = __decorate([
                    app.Component('app.training.training', 'ceTrainingOverview', {
                        templateUrl: 'app/training/training/overview/training-overview.html',
                        bindings: {
                            data: '<'
                        }
                    })
                ], TrainingOverview);
                return TrainingOverview;
            }());
        })(training = training_17.training || (training_17.training = {}));
    })(training = app.training || (app.training = {}));
})(app || (app = {}));
var app;
(function (app) {
    var training;
    (function (training_18) {
        var training;
        (function (training) {
            var TrainingInformation = (function () {
                function TrainingInformation(common, confirmation, datacontext, timerService) {
                    this.common = common;
                    this.confirmation = confirmation;
                    this.datacontext = datacontext;
                    this.timerService = timerService;
                    'ngInject';
                    'ngInject';
                }
                TrainingInformation.prototype.$onInit = function () {
                };
                TrainingInformation.prototype.showDeleteDialog = function (id) {
                    var _this = this;
                    this.confirmation.show({
                        message: 'Are you sure you want to delete this Training? This will delete all data attached to this Training.',
                        ok: 'DELETE',
                        cancel: 'KEEP'
                    }).then(function () { _this.deleteTraining(id); });
                };
                TrainingInformation.prototype.deleteTraining = function (id) {
                    var _this = this;
                    this.datacontext.training.deleteTraining(id).then(function (result) {
                        if (result) {
                            _this.timerService.killTimer();
                            _this.common.$state.go('app.trainingList');
                        }
                    });
                };
                TrainingInformation.$inject = ['common', 'confirmation', 'datacontext', 'timerService'];
                TrainingInformation = __decorate([
                    app.Component('app.training.training', 'ceTrainingView', {
                        templateUrl: 'app/training/training/view/training-view.html',
                        bindings: {
                            data: '<'
                        }
                    })
                ], TrainingInformation);
                return TrainingInformation;
            }());
        })(training = training_18.training || (training_18.training = {}));
    })(training = app.training || (app.training = {}));
})(app || (app = {}));
var app;
(function (app) {
    var admin;
    (function (admin) {
        var configuration;
        (function (configuration) {
            var baseUrl = 'api/configuration';
            var ConfigurationRepository = (function () {
                function ConfigurationRepository($http) {
                    this.$http = $http;
                    'ngInject';
                    'ngInject';
                }
                ConfigurationRepository.prototype.getLookupType = function (lookupTypeId) {
                    return this.$http.get("".concat(baseUrl, "/lookup-types/").concat(lookupTypeId));
                };
                ConfigurationRepository.prototype.getLookupTypes = function () {
                    return this.$http.get("".concat(baseUrl, "/lookup-types"));
                };
                ConfigurationRepository.prototype.updateLookupType = function (lookupTypeId, data) {
                    return this.$http.put("".concat(baseUrl, "/lookup-types/").concat(lookupTypeId), data);
                };
                ConfigurationRepository.prototype.getLawCategory = function (lawCategoryId, activeSubcategoriesOnly) {
                    if (activeSubcategoriesOnly === void 0) { activeSubcategoriesOnly = true; }
                    return this.$http.get("".concat(baseUrl, "/law-categories/").concat(lawCategoryId, "?activeSubcategoriesOnly=").concat(activeSubcategoriesOnly));
                };
                ConfigurationRepository.prototype.getLawCategories = function (activeOnly) {
                    if (activeOnly === void 0) { activeOnly = true; }
                    return this.$http.get("".concat(baseUrl, "/law-categories?activeOnly=").concat(activeOnly));
                };
                ConfigurationRepository.prototype.createLawCategory = function (data) {
                    return this.$http.post("".concat(baseUrl, "/law-categories"), data);
                };
                ConfigurationRepository.prototype.updateLawCategory = function (lawCategoryId, data) {
                    return this.$http.put("".concat(baseUrl, "/law-categories/").concat(lawCategoryId), data);
                };
                ConfigurationRepository.prototype.createLawSubcategory = function (lawCategoryId, data) {
                    return this.$http.post("".concat(baseUrl, "/law-categories/").concat(lawCategoryId, "/subcategories"), data);
                };
                ConfigurationRepository.prototype.updateLawSubcategory = function (lawCategoryId, lawSubcategoryId, data) {
                    return this.$http.put("".concat(baseUrl, "/law-categories/").concat(lawCategoryId, "/subcategories/").concat(lawSubcategoryId), data);
                };
                ConfigurationRepository.prototype.getLegalTeam = function (id) {
                    return this.$http.get("".concat(baseUrl, "/legal-teams/").concat(id));
                };
                ConfigurationRepository.prototype.getLegalTeams = function (activeOnly) {
                    if (activeOnly === void 0) { activeOnly = true; }
                    return this.$http.get("".concat(baseUrl, "/legal-teams?activeOnly=").concat(activeOnly));
                };
                ConfigurationRepository.prototype.createLegalTeam = function (data) {
                    return this.$http.post("".concat(baseUrl, "/legal-teams"), data);
                };
                ConfigurationRepository.prototype.updateLegalTeam = function (id, data) {
                    return this.$http.put("".concat(baseUrl, "/legal-teams/").concat(id), data);
                };
                ConfigurationRepository.prototype.getTaskCategory = function (taskCategoryId) {
                    return this.$http.get("".concat(baseUrl, "/task-categories/").concat(taskCategoryId));
                };
                ConfigurationRepository.prototype.getTaskCategories = function () {
                    return this.$http.get("".concat(baseUrl, "/task-categories"));
                };
                ConfigurationRepository.prototype.createTaskCategory = function (data) {
                    return this.$http.post("".concat(baseUrl, "/task-categories"), data);
                };
                ConfigurationRepository.prototype.updateTaskCategory = function (taskCategoryId, data) {
                    return this.$http.put("".concat(baseUrl, "/task-categories/").concat(taskCategoryId), data);
                };
                ConfigurationRepository.prototype.createTask = function (taskCategoryId, data) {
                    return this.$http.post("".concat(baseUrl, "/task-categories/").concat(taskCategoryId, "/tasks"), data);
                };
                ConfigurationRepository.prototype.updateTask = function (taskCategoryId, taskId, data) {
                    return this.$http.put("".concat(baseUrl, "/task-categories/").concat(taskCategoryId, "/tasks/").concat(taskId), data);
                };
                ConfigurationRepository.prototype.getRaceSubcategories = function () {
                    return this.$http.get("".concat(baseUrl, "/racesubcategories"));
                };
                ConfigurationRepository.$inject = ['$http'];
                __decorate([
                    app.ApiExecutor()
                ], ConfigurationRepository.prototype, "getLookupType", null);
                __decorate([
                    app.ApiExecutor()
                ], ConfigurationRepository.prototype, "getLookupTypes", null);
                __decorate([
                    app.ApiExecutor({ returnValue: true })
                ], ConfigurationRepository.prototype, "updateLookupType", null);
                __decorate([
                    app.ApiExecutor()
                ], ConfigurationRepository.prototype, "getLawCategory", null);
                __decorate([
                    app.ApiExecutor()
                ], ConfigurationRepository.prototype, "getLawCategories", null);
                __decorate([
                    app.ApiExecutor()
                ], ConfigurationRepository.prototype, "createLawCategory", null);
                __decorate([
                    app.ApiExecutor({ returnValue: true })
                ], ConfigurationRepository.prototype, "updateLawCategory", null);
                __decorate([
                    app.ApiExecutor()
                ], ConfigurationRepository.prototype, "createLawSubcategory", null);
                __decorate([
                    app.ApiExecutor()
                ], ConfigurationRepository.prototype, "updateLawSubcategory", null);
                __decorate([
                    app.ApiExecutor()
                ], ConfigurationRepository.prototype, "getLegalTeam", null);
                __decorate([
                    app.ApiExecutor()
                ], ConfigurationRepository.prototype, "getLegalTeams", null);
                __decorate([
                    app.ApiExecutor()
                ], ConfigurationRepository.prototype, "createLegalTeam", null);
                __decorate([
                    app.ApiExecutor()
                ], ConfigurationRepository.prototype, "updateLegalTeam", null);
                __decorate([
                    app.ApiExecutor()
                ], ConfigurationRepository.prototype, "getTaskCategory", null);
                __decorate([
                    app.ApiExecutor()
                ], ConfigurationRepository.prototype, "getTaskCategories", null);
                __decorate([
                    app.ApiExecutor()
                ], ConfigurationRepository.prototype, "createTaskCategory", null);
                __decorate([
                    app.ApiExecutor({ returnValue: true })
                ], ConfigurationRepository.prototype, "updateTaskCategory", null);
                __decorate([
                    app.ApiExecutor()
                ], ConfigurationRepository.prototype, "createTask", null);
                __decorate([
                    app.ApiExecutor()
                ], ConfigurationRepository.prototype, "updateTask", null);
                __decorate([
                    app.ApiExecutor()
                ], ConfigurationRepository.prototype, "getRaceSubcategories", null);
                ConfigurationRepository = __decorate([
                    app.Service('app.admin.configuration', 'admin.configuration.repository')
                ], ConfigurationRepository);
                return ConfigurationRepository;
            }());
        })(configuration = admin.configuration || (admin.configuration = {}));
    })(admin = app.admin || (app.admin = {}));
})(app || (app = {}));
var app;
(function (app) {
    var admin;
    (function (admin) {
        var identityManagement;
        (function (identityManagement) {
            var baseUrl = 'api/identity-management';
            var IdentityManagementRepository = (function () {
                function IdentityManagementRepository($http) {
                    this.$http = $http;
                    'ngInject';
                    'ngInject';
                }
                IdentityManagementRepository.prototype.createRole = function (data) {
                    return this.$http.post("".concat(baseUrl, "/roles"), data);
                };
                IdentityManagementRepository.prototype.createUser = function (data) {
                    return this.$http.post("".concat(baseUrl, "/users"), data);
                };
                IdentityManagementRepository.prototype.deleteRole = function (roleId) {
                    return this.$http.delete("".concat(baseUrl, "/roles/").concat(roleId));
                };
                IdentityManagementRepository.prototype.getPermissions = function () {
                    return this.$http.get("".concat(baseUrl, "/permissions"));
                };
                IdentityManagementRepository.prototype.getRole = function (roleId) {
                    return this.$http.get("".concat(baseUrl, "/roles/").concat(roleId));
                };
                IdentityManagementRepository.prototype.getRoles = function () {
                    return this.$http.get("".concat(baseUrl, "/roles"));
                };
                IdentityManagementRepository.prototype.getUser = function (userId) {
                    return this.$http.get("".concat(baseUrl, "/users/").concat(userId));
                };
                IdentityManagementRepository.prototype.getUsers = function (params) {
                    return this.$http.get("".concat(baseUrl, "/users"), { params: params });
                };
                IdentityManagementRepository.prototype.updateRole = function (roleId, data) {
                    return this.$http.put("".concat(baseUrl, "/roles/").concat(roleId), data);
                };
                IdentityManagementRepository.prototype.updateUser = function (userId, data) {
                    return this.$http.put("".concat(baseUrl, "/users/").concat(userId), data);
                };
                IdentityManagementRepository.$inject = ['$http'];
                __decorate([
                    app.ApiExecutor()
                ], IdentityManagementRepository.prototype, "createRole", null);
                __decorate([
                    app.ApiExecutor()
                ], IdentityManagementRepository.prototype, "createUser", null);
                __decorate([
                    app.ApiExecutor({ returnValue: true })
                ], IdentityManagementRepository.prototype, "deleteRole", null);
                __decorate([
                    app.ApiExecutor()
                ], IdentityManagementRepository.prototype, "getPermissions", null);
                __decorate([
                    app.ApiExecutor()
                ], IdentityManagementRepository.prototype, "getRole", null);
                __decorate([
                    app.ApiExecutor()
                ], IdentityManagementRepository.prototype, "getRoles", null);
                __decorate([
                    app.ApiExecutor()
                ], IdentityManagementRepository.prototype, "getUser", null);
                __decorate([
                    app.ApiExecutor()
                ], IdentityManagementRepository.prototype, "getUsers", null);
                __decorate([
                    app.ApiExecutor()
                ], IdentityManagementRepository.prototype, "updateRole", null);
                __decorate([
                    app.ApiExecutor()
                ], IdentityManagementRepository.prototype, "updateUser", null);
                IdentityManagementRepository = __decorate([
                    app.Service('app.admin.identityManagement', 'admin.identityManagement.repository')
                ], IdentityManagementRepository);
                return IdentityManagementRepository;
            }());
        })(identityManagement = admin.identityManagement || (admin.identityManagement = {}));
    })(admin = app.admin || (app.admin = {}));
})(app || (app = {}));
var app;
(function (app) {
    var funding;
    (function (funding) {
        var CAPSystemicIntakeForm = (function () {
            function CAPSystemicIntakeForm(datacontext) {
                this.datacontext = datacontext;
                'ngInject';
                'ngInject';
            }
            CAPSystemicIntakeForm.prototype.$onInit = function () {
                this.getAgeRanges();
                this.getRaces();
                this.getProblemAreas();
                this.getLivingArrangements();
            };
            CAPSystemicIntakeForm.prototype.getAgeRanges = function () {
                var _this = this;
                return this.datacontext.config.lookups.getLookupType('cap-systemic-age-ranges')
                    .then(function (lookup) { _this.ageRanges = lookup.lookups; });
            };
            CAPSystemicIntakeForm.prototype.getRaces = function () {
                var _this = this;
                return this.datacontext.config.lookups.getLookupType('cap-systemic-races')
                    .then(function (lookup) { _this.races = lookup.lookups; });
            };
            CAPSystemicIntakeForm.prototype.getProblemAreas = function () {
                var _this = this;
                return this.datacontext.config.lookups.getLookupType('cap-problem-areas')
                    .then(function (lookup) { _this.problemAreas = lookup.lookups; });
            };
            CAPSystemicIntakeForm.prototype.getLivingArrangements = function () {
                var _this = this;
                return this.datacontext.config.lookups.getLookupType('cap-living-arrangements')
                    .then(function (lookup) { _this.livingArrangements = lookup.lookups; });
            };
            CAPSystemicIntakeForm.$inject = ['datacontext'];
            CAPSystemicIntakeForm = __decorate([
                app.Component('app.funding', 'ceCapSystemicIntakeForm', {
                    templateUrl: 'app/funding/enrollments/cap/systemic/cap-intake-form.html',
                    bindings: {
                        intake: '<',
                        onCancel: '&',
                        onSubmit: '&',
                        livingArrangements: '<'
                    }
                })
            ], CAPSystemicIntakeForm);
            return CAPSystemicIntakeForm;
        }());
    })(funding = app.funding || (app.funding = {}));
})(app || (app = {}));
var app;
(function (app) {
    var funding;
    (function (funding) {
        var CAPSystemicIntakeInfo = (function () {
            function CAPSystemicIntakeInfo() {
                'ngInject';
            }
            CAPSystemicIntakeInfo = __decorate([
                app.Component('app.funding', 'ceCapSystemicIntakeInfo', {
                    templateUrl: 'app/funding/enrollments/cap/systemic/cap-intake-info.html',
                    bindings: {
                        intake: '<',
                        onEdit: '&',
                        livingArrangements: '<',
                        problemAreas: '<'
                    }
                })
            ], CAPSystemicIntakeInfo);
            return CAPSystemicIntakeInfo;
        }());
    })(funding = app.funding || (app.funding = {}));
})(app || (app = {}));
var app;
(function (app) {
    var funding;
    (function (funding) {
        var CAPSystemicIntake = (function () {
            function CAPSystemicIntake(common, datacontext) {
                this.common = common;
                this.datacontext = datacontext;
                'ngInject';
                'ngInject';
            }
            CAPSystemicIntake.prototype.$onInit = function () {
                this.intake = { id: this.enrollmentId };
                this.getLivingArrangements();
                this.getProblemAreas();
                this.getIntake();
            };
            CAPSystemicIntake.prototype.getIntake = function () {
                var _this = this;
                return this.datacontext.funding.getCAPSystemicIntake(this.enrollmentId)
                    .then(function (intake) {
                    if (intake) {
                        _this.intake = intake;
                    }
                });
            };
            CAPSystemicIntake.prototype.edit = function () {
                var _this = this;
                this.getIntake().then(function () {
                    _this.editing = true;
                });
            };
            CAPSystemicIntake.prototype.updateIntake = function () {
                var _this = this;
                this.datacontext.funding.updateCAPSystemicIntake(this.intake).then(function () {
                    _this.editing = false;
                });
            };
            CAPSystemicIntake.prototype.cancel = function () {
                var _this = this;
                this.getIntake().then(function () {
                    _this.editing = false;
                });
            };
            CAPSystemicIntake.prototype.done = function () {
                this.common.$state.go('^.');
            };
            CAPSystemicIntake.prototype.getLivingArrangements = function () {
                var _this = this;
                return this.datacontext.funding.getCAPLivingArrangementOptions()
                    .then(function (livingArrangements) { _this.livingArrangements = livingArrangements; });
            };
            CAPSystemicIntake.prototype.getProblemAreas = function () {
                var _this = this;
                return this.datacontext.funding.getCAPProblemAreaOptions()
                    .then(function (problemAreas) { _this.problemAreas = problemAreas; });
            };
            CAPSystemicIntake.$inject = ['common', 'datacontext'];
            CAPSystemicIntake = __decorate([
                app.Component('app.funding', 'ceCapSystemicIntake', {
                    templateUrl: 'app/funding/enrollments/cap/systemic/cap-intake.html',
                    bindings: {
                        enrollmentId: '<'
                    }
                })
            ], CAPSystemicIntake);
            return CAPSystemicIntake;
        }());
    })(funding = app.funding || (app.funding = {}));
})(app || (app = {}));
var app;
(function (app) {
    var funding;
    (function (funding) {
        var CAPSystemicOutcomeForm = (function () {
            function CAPSystemicOutcomeForm(common, datacontext) {
                this.common = common;
                this.datacontext = datacontext;
                'ngInject';
                'ngInject';
            }
            CAPSystemicOutcomeForm.prototype.$onInit = function () {
                this.getInterventionStrategies();
            };
            CAPSystemicOutcomeForm.prototype.checkInvestigation = function () {
                var _this = this;
                this.isInvestigation = false;
                _.forEach(this.outcome.InterventionStrategies, function (interventionStrategy) { if (interventionStrategy.contains('Investigation'))
                    _this.isInvestigation = true; });
            };
            CAPSystemicOutcomeForm.prototype.getInterventionStrategies = function () {
                var _this = this;
                return this.datacontext.config.lookups.getLookupType('cap-systemic-intervention-strategies')
                    .then(function (lookup) { _this.interventionStrategies = lookup.lookups; });
            };
            CAPSystemicOutcomeForm.$inject = ['common', 'datacontext'];
            CAPSystemicOutcomeForm = __decorate([
                app.Component('app.funding', 'ceCapSystemicOutcomeForm', {
                    templateUrl: 'app/funding/enrollments/cap/systemic/cap-outcome-form.html',
                    bindings: {
                        outcome: '<',
                        onCancel: '&',
                        onSubmit: '&'
                    }
                })
            ], CAPSystemicOutcomeForm);
            return CAPSystemicOutcomeForm;
        }());
    })(funding = app.funding || (app.funding = {}));
})(app || (app = {}));
var app;
(function (app) {
    var funding;
    (function (funding) {
        var CAPSystemicOutcomeInfo = (function () {
            function CAPSystemicOutcomeInfo(common, datacontext) {
                this.common = common;
                this.datacontext = datacontext;
                'ngInject';
                'ngInject';
            }
            CAPSystemicOutcomeInfo.prototype.$onChanges = function () {
                this.checkInvestigation();
            };
            CAPSystemicOutcomeInfo.prototype.checkInvestigation = function () {
                var _this = this;
                _.forEach(this.outcome.InterventionStrategies, function (interventionStrategy) { if (interventionStrategy.contains('Investigation'))
                    _this.isInvestigation = true; });
            };
            CAPSystemicOutcomeInfo.$inject = ['common', 'datacontext'];
            CAPSystemicOutcomeInfo = __decorate([
                app.Component('app.funding', 'ceCapSystemicOutcomeInfo', {
                    templateUrl: 'app/funding/enrollments/cap/systemic/cap-outcome-info.html',
                    bindings: {
                        outcome: '<',
                        onEdit: '&'
                    }
                })
            ], CAPSystemicOutcomeInfo);
            return CAPSystemicOutcomeInfo;
        }());
    })(funding = app.funding || (app.funding = {}));
})(app || (app = {}));
var app;
(function (app) {
    var funding;
    (function (funding) {
        var CAPSystemicOutcome = (function () {
            function CAPSystemicOutcome(common, datacontext) {
                this.common = common;
                this.datacontext = datacontext;
                this.outcome = {};
                'ngInject';
                'ngInject';
            }
            CAPSystemicOutcome.prototype.$onInit = function () {
                this.getOutcome();
            };
            CAPSystemicOutcome.prototype.getOutcome = function () {
                var _this = this;
                return this.datacontext.funding.getCAPSystemicOutcome(this.enrollmentId).then(function (result) {
                    if (result) {
                        _this.outcome = result;
                    }
                    else {
                        _this.outcome.id = _this.enrollmentId;
                    }
                });
            };
            CAPSystemicOutcome.prototype.edit = function () {
                var _this = this;
                this.getOutcome().then(function () { return _this.editing = true; });
            };
            CAPSystemicOutcome.prototype.updateOutcome = function () {
                var _this = this;
                this.datacontext.funding.updateCAPSystemicOutcome(this.outcome).then(function () {
                    _this.editing = false;
                });
            };
            CAPSystemicOutcome.prototype.cancel = function () {
                var _this = this;
                this.getOutcome().then(function () {
                    _this.editing = false;
                });
            };
            CAPSystemicOutcome.prototype.done = function () {
                this.common.$state.go('^.');
            };
            CAPSystemicOutcome.$inject = ['common', 'datacontext'];
            CAPSystemicOutcome = __decorate([
                app.Component('app.funding', 'ceCapSystemicOutcome', {
                    templateUrl: 'app/funding/enrollments/cap/systemic/cap-outcome.html',
                    bindings: {
                        enrollmentId: '<'
                    }
                })
            ], CAPSystemicOutcome);
            return CAPSystemicOutcome;
        }());
    })(funding = app.funding || (app.funding = {}));
})(app || (app = {}));
var app;
(function (app) {
    var funding;
    (function (funding) {
        var CAPIAndROutcomeForm = (function () {
            function CAPIAndROutcomeForm(common, datacontext) {
                this.common = common;
                this.datacontext = datacontext;
                'ngInject';
                'ngInject';
            }
            CAPIAndROutcomeForm.prototype.otherDisabilityToggle = function () {
                this.outcome.OtherAchievement = !this.outcome.OtherAchievement;
            };
            CAPIAndROutcomeForm.$inject = ['common', 'datacontext'];
            CAPIAndROutcomeForm = __decorate([
                app.Component('app.funding', 'ceCapIAndROutcomeForm', {
                    templateUrl: 'app/funding/enrollments/cap/i-and-r/cap-outcome-form.html',
                    bindings: {
                        outcome: '<',
                        onCancel: '&',
                        onSubmit: '&'
                    }
                })
            ], CAPIAndROutcomeForm);
            return CAPIAndROutcomeForm;
        }());
    })(funding = app.funding || (app.funding = {}));
})(app || (app = {}));
var app;
(function (app) {
    var funding;
    (function (funding) {
        var CAPIAndROutcomeInfo = (function () {
            function CAPIAndROutcomeInfo(common, datacontext) {
                this.common = common;
                this.datacontext = datacontext;
                'ngInject';
                'ngInject';
            }
            CAPIAndROutcomeInfo.$inject = ['common', 'datacontext'];
            CAPIAndROutcomeInfo = __decorate([
                app.Component('app.funding', 'ceCapIAndROutcomeInfo', {
                    templateUrl: 'app/funding/enrollments/cap/i-and-r/cap-outcome-info.html',
                    bindings: {
                        outcome: '<',
                        onEdit: '&'
                    }
                })
            ], CAPIAndROutcomeInfo);
            return CAPIAndROutcomeInfo;
        }());
    })(funding = app.funding || (app.funding = {}));
})(app || (app = {}));
var app;
(function (app) {
    var funding;
    (function (funding) {
        var CAPIAndROutcome = (function () {
            function CAPIAndROutcome(common, datacontext) {
                this.common = common;
                this.datacontext = datacontext;
                this.outcome = {};
                'ngInject';
                'ngInject';
            }
            CAPIAndROutcome.prototype.$onInit = function () {
                this.getOutcome();
            };
            CAPIAndROutcome.prototype.getOutcome = function () {
                var _this = this;
                return this.datacontext.funding.getCAPIAndROutcome(this.enrollmentId).then(function (result) {
                    if (result) {
                        _this.outcome = result;
                    }
                    else {
                        _this.outcome.id = _this.enrollmentId;
                    }
                });
            };
            CAPIAndROutcome.prototype.edit = function () {
                var _this = this;
                this.getOutcome().then(function () { return _this.editing = true; });
            };
            CAPIAndROutcome.prototype.updateOutcome = function () {
                var _this = this;
                this.datacontext.funding.updateCAPIAndROutcome(this.outcome).then(function () {
                    _this.editing = false;
                });
            };
            CAPIAndROutcome.prototype.cancel = function () {
                var _this = this;
                this.getOutcome().then(function () {
                    _this.editing = false;
                });
            };
            CAPIAndROutcome.prototype.done = function () {
                this.common.$state.go('^.');
            };
            CAPIAndROutcome.$inject = ['common', 'datacontext'];
            CAPIAndROutcome = __decorate([
                app.Component('app.funding', 'ceCapIAndROutcome', {
                    templateUrl: 'app/funding/enrollments/cap/i-and-r/cap-outcome.html',
                    bindings: {
                        enrollmentId: '<'
                    }
                })
            ], CAPIAndROutcome);
            return CAPIAndROutcome;
        }());
    })(funding = app.funding || (app.funding = {}));
})(app || (app = {}));
var app;
(function (app) {
    var funding;
    (function (funding) {
        var NDBEDPSystemicIntakeForm = (function () {
            function NDBEDPSystemicIntakeForm(common, datacontext) {
                this.common = common;
                this.datacontext = datacontext;
                'ngInject';
                'ngInject';
            }
            NDBEDPSystemicIntakeForm.$inject = ['common', 'datacontext'];
            NDBEDPSystemicIntakeForm = __decorate([
                app.Component('app.funding', 'ceNdbedpSystemicIntakeForm', {
                    templateUrl: 'app/funding/enrollments/ndbedp/systemic/ndbedp-systemic-intake-form.html',
                    bindings: {
                        intake: '<',
                        onCancel: '&',
                        onSubmit: '&'
                    }
                })
            ], NDBEDPSystemicIntakeForm);
            return NDBEDPSystemicIntakeForm;
        }());
    })(funding = app.funding || (app.funding = {}));
})(app || (app = {}));
var app;
(function (app) {
    var funding;
    (function (funding) {
        var NDBEDPSystemicIntakeInfo = (function () {
            function NDBEDPSystemicIntakeInfo(common, datacontext) {
                this.common = common;
                this.datacontext = datacontext;
                'ngInject';
                'ngInject';
            }
            NDBEDPSystemicIntakeInfo.$inject = ['common', 'datacontext'];
            NDBEDPSystemicIntakeInfo = __decorate([
                app.Component('app.funding', 'ceNdbedpSystemicIntakeInfo', {
                    templateUrl: 'app/funding/enrollments/ndbedp/systemic/ndbedp-systemic-intake-info.html',
                    bindings: {
                        intake: '<',
                        onEdit: '&'
                    }
                })
            ], NDBEDPSystemicIntakeInfo);
            return NDBEDPSystemicIntakeInfo;
        }());
    })(funding = app.funding || (app.funding = {}));
})(app || (app = {}));
var app;
(function (app) {
    var funding;
    (function (funding) {
        var NDBEDPSystemicIntake = (function () {
            function NDBEDPSystemicIntake(common, datacontext) {
                this.common = common;
                this.datacontext = datacontext;
                'ngInject';
                'ngInject';
            }
            NDBEDPSystemicIntake.prototype.$onInit = function () {
                this.getIntake();
            };
            NDBEDPSystemicIntake.prototype.getIntake = function () {
                var _this = this;
                return this.datacontext.funding.getNDBEDPSystemicIntake(this.enrollmentId)
                    .then(function (intake) { if (intake)
                    _this.intake = intake; });
            };
            NDBEDPSystemicIntake.prototype.edit = function () {
                var _this = this;
                this.getIntake().then(function () {
                    _this.editing = true;
                });
            };
            NDBEDPSystemicIntake.prototype.updateIntake = function () {
                var _this = this;
                this.datacontext.funding.updateNDBEDPSystemicIntake(this.intake)
                    .then(function () {
                    _this.editing = false;
                });
            };
            NDBEDPSystemicIntake.prototype.cancel = function () {
                var _this = this;
                this.getIntake().then(function () {
                    _this.editing = false;
                });
            };
            NDBEDPSystemicIntake.prototype.done = function () {
                this.common.$state.go('^.');
            };
            NDBEDPSystemicIntake.$inject = ['common', 'datacontext'];
            NDBEDPSystemicIntake = __decorate([
                app.Component('app.funding', 'ceNdbedpSystemicIntake', {
                    templateUrl: 'app/funding/enrollments/ndbedp/systemic/ndbedp-systemic-intake.html',
                    bindings: {
                        enrollmentId: '<'
                    }
                })
            ], NDBEDPSystemicIntake);
            return NDBEDPSystemicIntake;
        }());
    })(funding = app.funding || (app.funding = {}));
})(app || (app = {}));
var app;
(function (app) {
    var funding;
    (function (funding) {
        var NDBEDPSystemicOutcomeForm = (function () {
            function NDBEDPSystemicOutcomeForm(datacontext) {
                var _this = this;
                this.datacontext = datacontext;
                this.$onInit = function () {
                    _this.getServicesProvided();
                };
                'ngInject';
                'ngInject';
            }
            NDBEDPSystemicOutcomeForm.prototype.getServicesProvided = function () {
                var _this = this;
                return this.datacontext.config.lookups.getLookupType('ndbedp-systemic-services-provided')
                    .then(function (lookup) { _this.servicesProvided = lookup.lookups; });
            };
            NDBEDPSystemicOutcomeForm.$inject = ['datacontext'];
            NDBEDPSystemicOutcomeForm = __decorate([
                app.Component('app.funding', 'ceNdbedpSystemicOutcomeForm', {
                    templateUrl: 'app/funding/enrollments/ndbedp/systemic/ndbedp-systemic-outcome-form.html',
                    bindings: {
                        outcome: '<',
                        onCancel: '&',
                        onSubmit: '&'
                    }
                })
            ], NDBEDPSystemicOutcomeForm);
            return NDBEDPSystemicOutcomeForm;
        }());
    })(funding = app.funding || (app.funding = {}));
})(app || (app = {}));
var app;
(function (app) {
    var funding;
    (function (funding) {
        var TEPSystemicOutcomeInfo = (function () {
            function TEPSystemicOutcomeInfo(common, datacontext) {
                this.common = common;
                this.datacontext = datacontext;
                'ngInject';
                'ngInject';
            }
            TEPSystemicOutcomeInfo.$inject = ['common', 'datacontext'];
            TEPSystemicOutcomeInfo = __decorate([
                app.Component('app.funding', 'ceNdbedpSystemicOutcomeInfo', {
                    templateUrl: 'app/funding/enrollments/ndbedp/systemic/ndbedp-systemic-outcome-info.html',
                    bindings: {
                        outcome: '<',
                        onEdit: '&'
                    }
                })
            ], TEPSystemicOutcomeInfo);
            return TEPSystemicOutcomeInfo;
        }());
    })(funding = app.funding || (app.funding = {}));
})(app || (app = {}));
var app;
(function (app) {
    var funding;
    (function (funding) {
        var NDBEDPSystemicOutcome = (function () {
            function NDBEDPSystemicOutcome($mdDialog, common, datacontext) {
                this.$mdDialog = $mdDialog;
                this.common = common;
                this.datacontext = datacontext;
                this.outcome = {};
                'ngInject';
                'ngInject';
            }
            NDBEDPSystemicOutcome.prototype.$onInit = function () {
                this.getOutcome();
            };
            NDBEDPSystemicOutcome.prototype.getOutcome = function () {
                var _this = this;
                return this.datacontext.funding.getNDBEDPSystemicOutcome(this.enrollmentId).then(function (result) {
                    if (result) {
                        _this.outcome = result;
                    }
                    else {
                        _this.outcome.id = _this.enrollmentId;
                    }
                });
            };
            NDBEDPSystemicOutcome.prototype.edit = function () {
                var _this = this;
                this.getOutcome().then(function () { return _this.editing = true; });
            };
            NDBEDPSystemicOutcome.prototype.updateOutcome = function () {
                var _this = this;
                this.datacontext.funding.updateNDBEDPSystemicOutcome(this.outcome).then(function () {
                    _this.editing = false;
                });
            };
            NDBEDPSystemicOutcome.prototype.cancel = function () {
                var _this = this;
                this.getOutcome().then(function () {
                    _this.editing = false;
                });
            };
            NDBEDPSystemicOutcome.prototype.done = function () {
                this.common.$state.go('^.');
            };
            NDBEDPSystemicOutcome.$inject = ['$mdDialog', 'common', 'datacontext'];
            NDBEDPSystemicOutcome = __decorate([
                app.Component('app.funding', 'ceNdbedpSystemicOutcome', {
                    templateUrl: 'app/funding/enrollments/ndbedp/systemic/ndbedp-systemic-outcome.html',
                    bindings: {
                        enrollmentId: '<'
                    }
                })
            ], NDBEDPSystemicOutcome);
            return NDBEDPSystemicOutcome;
        }());
    })(funding = app.funding || (app.funding = {}));
})(app || (app = {}));
var app;
(function (app) {
    var funding;
    (function (funding) {
        var PAATIAndROutcomeForm = (function () {
            function PAATIAndROutcomeForm(common, datacontext) {
                this.common = common;
                this.datacontext = datacontext;
                'ngInject';
                'ngInject';
            }
            PAATIAndROutcomeForm.$inject = ['common', 'datacontext'];
            PAATIAndROutcomeForm = __decorate([
                app.Component('app.funding', 'cePaatIAndROutcomeForm', {
                    templateUrl: 'app/funding/enrollments/paat/i-and-r/paat-outcome-form.html',
                    bindings: {
                        outcome: '<',
                        onCancel: '&',
                        onSubmit: '&'
                    }
                })
            ], PAATIAndROutcomeForm);
            return PAATIAndROutcomeForm;
        }());
    })(funding = app.funding || (app.funding = {}));
})(app || (app = {}));
var app;
(function (app) {
    var funding;
    (function (funding) {
        var PAATIAndROutcomeInfo = (function () {
            function PAATIAndROutcomeInfo(common, datacontext) {
                this.common = common;
                this.datacontext = datacontext;
                'ngInject';
                'ngInject';
            }
            PAATIAndROutcomeInfo.$inject = ['common', 'datacontext'];
            PAATIAndROutcomeInfo = __decorate([
                app.Component('app.funding', 'cePaatIAndROutcomeInfo', {
                    templateUrl: 'app/funding/enrollments/paat/i-and-r/paat-outcome-info.html',
                    bindings: {
                        outcome: '<',
                        onEdit: '&'
                    }
                })
            ], PAATIAndROutcomeInfo);
            return PAATIAndROutcomeInfo;
        }());
    })(funding = app.funding || (app.funding = {}));
})(app || (app = {}));
var app;
(function (app) {
    var funding;
    (function (funding) {
        var PAATIAndROutcome = (function () {
            function PAATIAndROutcome(common, datacontext) {
                this.common = common;
                this.datacontext = datacontext;
                this.outcome = {};
                'ngInject';
                'ngInject';
            }
            PAATIAndROutcome.prototype.$onInit = function () {
                this.getOutcome();
            };
            PAATIAndROutcome.prototype.getOutcome = function () {
                var _this = this;
                return this.datacontext.funding.getPAATIAndROutcome(this.enrollmentId).then(function (result) {
                    if (result) {
                        _this.outcome = result;
                    }
                    else {
                        _this.outcome.id = _this.enrollmentId;
                    }
                });
            };
            PAATIAndROutcome.prototype.edit = function () {
                var _this = this;
                this.getOutcome().then(function () { return _this.editing = true; });
            };
            PAATIAndROutcome.prototype.updateOutcome = function () {
                var _this = this;
                this.datacontext.funding.updatePAATIAndROutcome(this.outcome).then(function () {
                    _this.editing = false;
                });
            };
            PAATIAndROutcome.prototype.cancel = function () {
                var _this = this;
                this.getOutcome().then(function () {
                    _this.editing = false;
                });
            };
            PAATIAndROutcome.prototype.done = function () {
                this.common.$state.go('^.');
            };
            PAATIAndROutcome.$inject = ['common', 'datacontext'];
            PAATIAndROutcome = __decorate([
                app.Component('app.funding', 'cePaatIAndROutcome', {
                    templateUrl: 'app/funding/enrollments/paat/i-and-r/paat-outcome.html',
                    bindings: {
                        enrollmentId: '<'
                    }
                })
            ], PAATIAndROutcome);
            return PAATIAndROutcome;
        }());
    })(funding = app.funding || (app.funding = {}));
})(app || (app = {}));
var app;
(function (app) {
    var funding;
    (function (funding) {
        var PAATSystemicIntakeForm = (function () {
            function PAATSystemicIntakeForm(datacontext) {
                this.datacontext = datacontext;
                'ngInject';
                'ngInject';
            }
            PAATSystemicIntakeForm.prototype.$onInit = function () {
                this.getAgeRanges();
                this.getRaces();
            };
            PAATSystemicIntakeForm.prototype.getAgeRanges = function () {
                var _this = this;
                return this.datacontext.config.lookups.getLookupType('paat-systemic-age-ranges')
                    .then(function (lookup) { _this.ageRanges = lookup.lookups; });
            };
            PAATSystemicIntakeForm.prototype.getRaces = function () {
                var _this = this;
                return this.datacontext.config.lookups.getLookupType('paat-systemic-races')
                    .then(function (lookup) { _this.races = lookup.lookups; });
            };
            PAATSystemicIntakeForm.$inject = ['datacontext'];
            PAATSystemicIntakeForm = __decorate([
                app.Component('app.funding', 'cePaatSystemicIntakeForm', {
                    templateUrl: 'app/funding/enrollments/paat/systemic/paat-intake-form.html',
                    bindings: {
                        intake: '<',
                        onCancel: '&',
                        onSubmit: '&',
                        livingArrangements: '<',
                        problemAreas: '<'
                    }
                })
            ], PAATSystemicIntakeForm);
            return PAATSystemicIntakeForm;
        }());
    })(funding = app.funding || (app.funding = {}));
})(app || (app = {}));
var app;
(function (app) {
    var funding;
    (function (funding) {
        var PAATSystemicIntakeInfo = (function () {
            function PAATSystemicIntakeInfo() {
                'ngInject';
            }
            PAATSystemicIntakeInfo.prototype.$onChanges = function (changes) {
                if (this.intake.livingArrangements && this.livingArrangements) {
                    this.setLivingArrangementsDisplayValue();
                }
                if (this.intake.problemAreas && this.problemAreas) {
                    this.setProblemAreasDisplayValue();
                }
            };
            PAATSystemicIntakeInfo.prototype.setProblemAreasDisplayValue = function () {
                var _this = this;
                this.intake.problemAreasDisplayValue = [];
                this.intake.problemAreas.forEach(function (paId) {
                    _this.problemAreas.forEach(function (pa) {
                        if (paId === pa.id) {
                            _this.intake.problemAreasDisplayValue.push(pa.value);
                        }
                    });
                });
            };
            PAATSystemicIntakeInfo.prototype.setLivingArrangementsDisplayValue = function () {
                var _this = this;
                this.intake.livingArrangementsDisplayValue = [];
                this.intake.livingArrangements.forEach(function (laId) {
                    _this.livingArrangements.forEach(function (la) {
                        if (laId === la.id) {
                            _this.intake.livingArrangementsDisplayValue.push(la.value);
                        }
                    });
                });
            };
            PAATSystemicIntakeInfo = __decorate([
                app.Component('app.funding', 'cePaatSystemicIntakeInfo', {
                    templateUrl: 'app/funding/enrollments/paat/systemic/paat-intake-info.html',
                    bindings: {
                        intake: '<',
                        onEdit: '&',
                        livingArrangements: '<',
                        problemAreas: '<'
                    }
                })
            ], PAATSystemicIntakeInfo);
            return PAATSystemicIntakeInfo;
        }());
    })(funding = app.funding || (app.funding = {}));
})(app || (app = {}));
var app;
(function (app) {
    var funding;
    (function (funding) {
        var PAATSystemicIntake = (function () {
            function PAATSystemicIntake(common, datacontext) {
                this.common = common;
                this.datacontext = datacontext;
                'ngInject';
                'ngInject';
            }
            PAATSystemicIntake.prototype.$onInit = function () {
                this.intake = { id: this.enrollmentId };
                this.getLivingArrangements();
                this.getProblemAreas();
                this.getIntake();
            };
            PAATSystemicIntake.prototype.getIntake = function () {
                var _this = this;
                return this.datacontext.funding.getPAATSystemicIntake(this.enrollmentId)
                    .then(function (intake) {
                    if (intake) {
                        _this.intake = intake;
                    }
                });
            };
            PAATSystemicIntake.prototype.edit = function () {
                var _this = this;
                this.getIntake().then(function () {
                    _this.editing = true;
                });
            };
            PAATSystemicIntake.prototype.updateIntake = function () {
                var _this = this;
                this.datacontext.funding.updatePAATSystemicIntake(this.intake).then(function () {
                    _this.editing = false;
                });
            };
            PAATSystemicIntake.prototype.cancel = function () {
                var _this = this;
                this.getIntake().then(function () {
                    _this.editing = false;
                });
            };
            PAATSystemicIntake.prototype.done = function () {
                this.common.$state.go('^.');
            };
            PAATSystemicIntake.prototype.getLivingArrangements = function () {
                var _this = this;
                return this.datacontext.funding.getPAATLivingArrangementOptions()
                    .then(function (livingArrangements) { _this.livingArrangements = livingArrangements; });
            };
            PAATSystemicIntake.prototype.getProblemAreas = function () {
                var _this = this;
                return this.datacontext.funding.getPAATProblemAreaOptions()
                    .then(function (problemAreas) { _this.problemAreas = problemAreas; });
            };
            PAATSystemicIntake.$inject = ['common', 'datacontext'];
            PAATSystemicIntake = __decorate([
                app.Component('app.funding', 'cePaatSystemicIntake', {
                    templateUrl: 'app/funding/enrollments/paat/systemic/paat-intake.html',
                    bindings: {
                        enrollmentId: '<'
                    }
                })
            ], PAATSystemicIntake);
            return PAATSystemicIntake;
        }());
    })(funding = app.funding || (app.funding = {}));
})(app || (app = {}));
var app;
(function (app) {
    var funding;
    (function (funding) {
        var PAATSystemicOutcomeForm = (function () {
            function PAATSystemicOutcomeForm(common, datacontext) {
                this.common = common;
                this.datacontext = datacontext;
                'ngInject';
                'ngInject';
            }
            PAATSystemicOutcomeForm.prototype.$onInit = function () {
                this.getInterventionStrategies();
            };
            PAATSystemicOutcomeForm.prototype.checkInvestigation = function () {
                var _this = this;
                this.isInvestigation = false;
                _.forEach(this.outcome.interventionStrategies, function (interventionStrategy) { if (interventionStrategy.contains('Investigation'))
                    _this.isInvestigation = true; });
            };
            PAATSystemicOutcomeForm.prototype.getInterventionStrategies = function () {
                var _this = this;
                return this.datacontext.config.lookups.getLookupType('paat-systemic-intervention-strategies')
                    .then(function (lookup) { _this.interventionStrategies = lookup.lookups; });
            };
            PAATSystemicOutcomeForm.$inject = ['common', 'datacontext'];
            PAATSystemicOutcomeForm = __decorate([
                app.Component('app.funding', 'cePaatSystemicOutcomeForm', {
                    templateUrl: 'app/funding/enrollments/paat/systemic/paat-outcome-form.html',
                    bindings: {
                        outcome: '<',
                        onCancel: '&',
                        onSubmit: '&'
                    }
                })
            ], PAATSystemicOutcomeForm);
            return PAATSystemicOutcomeForm;
        }());
    })(funding = app.funding || (app.funding = {}));
})(app || (app = {}));
var app;
(function (app) {
    var funding;
    (function (funding) {
        var PAATSystemicOutcomeInfo = (function () {
            function PAATSystemicOutcomeInfo(common, datacontext) {
                this.common = common;
                this.datacontext = datacontext;
                'ngInject';
                'ngInject';
            }
            PAATSystemicOutcomeInfo.prototype.$onChanges = function () {
                this.checkInvestigation();
            };
            PAATSystemicOutcomeInfo.prototype.checkInvestigation = function () {
                var _this = this;
                _.forEach(this.outcome.interventionStrategies, function (interventionStrategy) { if (interventionStrategy.contains('Investigation'))
                    _this.isInvestigation = true; });
            };
            PAATSystemicOutcomeInfo.$inject = ['common', 'datacontext'];
            PAATSystemicOutcomeInfo = __decorate([
                app.Component('app.funding', 'cePaatSystemicOutcomeInfo', {
                    templateUrl: 'app/funding/enrollments/paat/systemic/paat-outcome-info.html',
                    bindings: {
                        outcome: '<',
                        onEdit: '&'
                    }
                })
            ], PAATSystemicOutcomeInfo);
            return PAATSystemicOutcomeInfo;
        }());
    })(funding = app.funding || (app.funding = {}));
})(app || (app = {}));
var app;
(function (app) {
    var funding;
    (function (funding) {
        var PAATSystemicOutcome = (function () {
            function PAATSystemicOutcome(common, datacontext) {
                this.common = common;
                this.datacontext = datacontext;
                this.outcome = {};
                'ngInject';
                'ngInject';
            }
            PAATSystemicOutcome.prototype.$onInit = function () {
                this.getOutcome();
            };
            PAATSystemicOutcome.prototype.getOutcome = function () {
                var _this = this;
                return this.datacontext.funding.getPAATSystemicOutcome(this.enrollmentId).then(function (result) {
                    if (result) {
                        _this.outcome = result;
                    }
                    else {
                        _this.outcome.id = _this.enrollmentId;
                    }
                });
            };
            PAATSystemicOutcome.prototype.edit = function () {
                var _this = this;
                this.getOutcome().then(function () { return _this.editing = true; });
            };
            PAATSystemicOutcome.prototype.updateOutcome = function () {
                var _this = this;
                this.datacontext.funding.updatePAATSystemicOutcome(this.outcome).then(function () {
                    _this.editing = false;
                });
            };
            PAATSystemicOutcome.prototype.cancel = function () {
                var _this = this;
                this.getOutcome().then(function () {
                    _this.editing = false;
                });
            };
            PAATSystemicOutcome.prototype.done = function () {
                this.common.$state.go('^.');
            };
            PAATSystemicOutcome.$inject = ['common', 'datacontext'];
            PAATSystemicOutcome = __decorate([
                app.Component('app.funding', 'cePaatSystemicOutcome', {
                    templateUrl: 'app/funding/enrollments/paat/systemic/paat-outcome.html',
                    bindings: {
                        enrollmentId: '<'
                    }
                })
            ], PAATSystemicOutcome);
            return PAATSystemicOutcome;
        }());
    })(funding = app.funding || (app.funding = {}));
})(app || (app = {}));
var app;
(function (app) {
    var funding;
    (function (funding) {
        var PADDIAndROutcomeForm = (function () {
            function PADDIAndROutcomeForm(common, datacontext) {
                this.common = common;
                this.datacontext = datacontext;
                'ngInject';
                'ngInject';
            }
            PADDIAndROutcomeForm.$inject = ['common', 'datacontext'];
            PADDIAndROutcomeForm = __decorate([
                app.Component('app.funding', 'cePaddIAndROutcomeForm', {
                    templateUrl: 'app/funding/enrollments/padd/i-and-r/padd-outcome-form.html',
                    bindings: {
                        outcome: '<',
                        onCancel: '&',
                        onSubmit: '&'
                    }
                })
            ], PADDIAndROutcomeForm);
            return PADDIAndROutcomeForm;
        }());
    })(funding = app.funding || (app.funding = {}));
})(app || (app = {}));
var app;
(function (app) {
    var funding;
    (function (funding) {
        var PADDIAndROutcomeInfo = (function () {
            function PADDIAndROutcomeInfo(common, datacontext) {
                this.common = common;
                this.datacontext = datacontext;
                'ngInject';
                'ngInject';
            }
            PADDIAndROutcomeInfo.$inject = ['common', 'datacontext'];
            PADDIAndROutcomeInfo = __decorate([
                app.Component('app.funding', 'cePaddIAndROutcomeInfo', {
                    templateUrl: 'app/funding/enrollments/padd/i-and-r/padd-outcome-info.html',
                    bindings: {
                        outcome: '<',
                        onEdit: '&'
                    }
                })
            ], PADDIAndROutcomeInfo);
            return PADDIAndROutcomeInfo;
        }());
    })(funding = app.funding || (app.funding = {}));
})(app || (app = {}));
var app;
(function (app) {
    var funding;
    (function (funding) {
        var PADDIAndROutcome = (function () {
            function PADDIAndROutcome(common, datacontext) {
                this.common = common;
                this.datacontext = datacontext;
                this.outcome = {};
                'ngInject';
                'ngInject';
            }
            PADDIAndROutcome.prototype.$onInit = function () {
                this.getOutcome();
            };
            PADDIAndROutcome.prototype.getOutcome = function () {
                var _this = this;
                return this.datacontext.funding.getPADDIAndROutcome(this.enrollmentId).then(function (result) {
                    if (result) {
                        _this.outcome = result;
                    }
                    else {
                        _this.outcome.id = _this.enrollmentId;
                    }
                });
            };
            PADDIAndROutcome.prototype.edit = function () {
                var _this = this;
                this.getOutcome().then(function () { return _this.editing = true; });
            };
            PADDIAndROutcome.prototype.updateOutcome = function () {
                var _this = this;
                this.datacontext.funding.updatePADDIAndROutcome(this.outcome).then(function () {
                    _this.editing = false;
                });
            };
            PADDIAndROutcome.prototype.cancel = function () {
                var _this = this;
                this.getOutcome().then(function () {
                    _this.editing = false;
                });
            };
            PADDIAndROutcome.prototype.done = function () {
                this.common.$state.go('^.');
            };
            PADDIAndROutcome.$inject = ['common', 'datacontext'];
            PADDIAndROutcome = __decorate([
                app.Component('app.funding', 'cePaddIAndROutcome', {
                    templateUrl: 'app/funding/enrollments/padd/i-and-r/padd-outcome.html',
                    bindings: {
                        enrollmentId: '<'
                    }
                })
            ], PADDIAndROutcome);
            return PADDIAndROutcome;
        }());
    })(funding = app.funding || (app.funding = {}));
})(app || (app = {}));
var app;
(function (app) {
    var funding;
    (function (funding) {
        var PADDSystemicIntakeForm = (function () {
            function PADDSystemicIntakeForm(datacontext) {
                this.datacontext = datacontext;
                'ngInject';
                'ngInject';
            }
            PADDSystemicIntakeForm.prototype.$onInit = function () {
                this.getAgeRanges();
                this.getRaces();
            };
            PADDSystemicIntakeForm.prototype.getAgeRanges = function () {
                var _this = this;
                return this.datacontext.config.lookups.getLookupType('padd-systemic-age-ranges')
                    .then(function (lookup) { _this.ageRanges = lookup.lookups; });
            };
            PADDSystemicIntakeForm.prototype.getRaces = function () {
                var _this = this;
                return this.datacontext.config.lookups.getLookupType('padd-systemic-races')
                    .then(function (lookup) { _this.races = lookup.lookups; });
            };
            PADDSystemicIntakeForm.$inject = ['datacontext'];
            PADDSystemicIntakeForm = __decorate([
                app.Component('app.funding', 'cePaddSystemicIntakeForm', {
                    templateUrl: 'app/funding/enrollments/padd/systemic/padd-intake-form.html',
                    bindings: {
                        intake: '<',
                        onCancel: '&',
                        onSubmit: '&',
                        livingArrangements: '<',
                        problemAreas: '<'
                    }
                })
            ], PADDSystemicIntakeForm);
            return PADDSystemicIntakeForm;
        }());
    })(funding = app.funding || (app.funding = {}));
})(app || (app = {}));
var app;
(function (app) {
    var funding;
    (function (funding) {
        var PADDSystemicIntakeInfo = (function () {
            function PADDSystemicIntakeInfo() {
                'ngInject';
            }
            PADDSystemicIntakeInfo.prototype.$onChanges = function (changes) {
                if (this.intake.livingArrangements && this.livingArrangements) {
                    this.setLivingArrangementsDisplayValue();
                }
                if (this.intake.problemAreas && this.problemAreas) {
                    this.setProblemAreasDisplayValue();
                }
            };
            PADDSystemicIntakeInfo.prototype.setProblemAreasDisplayValue = function () {
                var _this = this;
                this.intake.problemAreasDisplayValue = [];
                this.intake.problemAreas.forEach(function (paId) {
                    _this.problemAreas.forEach(function (pa) {
                        if (paId === pa.id) {
                            _this.intake.problemAreasDisplayValue.push(pa.value);
                        }
                    });
                });
            };
            PADDSystemicIntakeInfo.prototype.setLivingArrangementsDisplayValue = function () {
                var _this = this;
                this.intake.livingArrangementsDisplayValue = [];
                this.intake.livingArrangements.forEach(function (laId) {
                    _this.livingArrangements.forEach(function (la) {
                        if (laId === la.id) {
                            _this.intake.livingArrangementsDisplayValue.push(la.value);
                        }
                    });
                });
            };
            PADDSystemicIntakeInfo = __decorate([
                app.Component('app.funding', 'cePaddSystemicIntakeInfo', {
                    templateUrl: 'app/funding/enrollments/padd/systemic/padd-intake-info.html',
                    bindings: {
                        intake: '<',
                        onEdit: '&',
                        livingArrangements: '<',
                        problemAreas: '<'
                    }
                })
            ], PADDSystemicIntakeInfo);
            return PADDSystemicIntakeInfo;
        }());
    })(funding = app.funding || (app.funding = {}));
})(app || (app = {}));
var app;
(function (app) {
    var funding;
    (function (funding) {
        var PADDSystemicIntake = (function () {
            function PADDSystemicIntake(common, datacontext) {
                this.common = common;
                this.datacontext = datacontext;
                'ngInject';
                'ngInject';
            }
            PADDSystemicIntake.prototype.$onInit = function () {
                this.intake = { id: this.enrollmentId };
                this.getLivingArrangements();
                this.getProblemAreas();
                this.getIntake();
            };
            PADDSystemicIntake.prototype.getIntake = function () {
                var _this = this;
                return this.datacontext.funding.getPADDSystemicIntake(this.enrollmentId)
                    .then(function (intake) { if (intake)
                    _this.intake = intake; });
            };
            PADDSystemicIntake.prototype.edit = function () {
                var _this = this;
                this.getIntake().then(function () {
                    _this.editing = true;
                });
            };
            PADDSystemicIntake.prototype.updateIntake = function () {
                var _this = this;
                this.datacontext.funding.updatePADDSystemicIntake(this.intake).then(function () {
                    _this.editing = false;
                });
            };
            PADDSystemicIntake.prototype.cancel = function () {
                var _this = this;
                this.getIntake().then(function () {
                    _this.editing = false;
                });
            };
            PADDSystemicIntake.prototype.done = function () {
                this.common.$state.go('^.');
            };
            PADDSystemicIntake.prototype.getLivingArrangements = function () {
                var _this = this;
                return this.datacontext.funding.getPADDLivingArrangementOptions()
                    .then(function (livingArrangements) { _this.livingArrangements = livingArrangements; });
            };
            PADDSystemicIntake.prototype.getProblemAreas = function () {
                var _this = this;
                return this.datacontext.funding.getPADDProblemAreaOptions()
                    .then(function (problemAreas) { _this.problemAreas = problemAreas; });
            };
            PADDSystemicIntake.$inject = ['common', 'datacontext'];
            PADDSystemicIntake = __decorate([
                app.Component('app.funding', 'cePaddSystemicIntake', {
                    templateUrl: 'app/funding/enrollments/padd/systemic/padd-intake.html',
                    bindings: {
                        enrollmentId: '<'
                    }
                })
            ], PADDSystemicIntake);
            return PADDSystemicIntake;
        }());
    })(funding = app.funding || (app.funding = {}));
})(app || (app = {}));
var app;
(function (app) {
    var funding;
    (function (funding) {
        var PADDSystemicOutcomeForm = (function () {
            function PADDSystemicOutcomeForm(common, datacontext) {
                this.common = common;
                this.datacontext = datacontext;
                'ngInject';
                'ngInject';
            }
            PADDSystemicOutcomeForm.prototype.$onInit = function () {
                this.getInterventionStrategies();
            };
            PADDSystemicOutcomeForm.prototype.checkInvestigation = function () {
                var _this = this;
                this.isInvestigation = false;
                _.forEach(this.outcome.interventionStrategies, function (interventionStrategy) { if (interventionStrategy.contains('Investigation'))
                    _this.isInvestigation = true; });
            };
            PADDSystemicOutcomeForm.prototype.getInterventionStrategies = function () {
                var _this = this;
                return this.datacontext.config.lookups.getLookupType('padd-systemic-intervention-strategies')
                    .then(function (lookup) { _this.interventionStrategies = lookup.lookups; });
            };
            PADDSystemicOutcomeForm.$inject = ['common', 'datacontext'];
            PADDSystemicOutcomeForm = __decorate([
                app.Component('app.funding', 'cePaddSystemicOutcomeForm', {
                    templateUrl: 'app/funding/enrollments/padd/systemic/padd-outcome-form.html',
                    bindings: {
                        outcome: '<',
                        onCancel: '&',
                        onSubmit: '&'
                    }
                })
            ], PADDSystemicOutcomeForm);
            return PADDSystemicOutcomeForm;
        }());
    })(funding = app.funding || (app.funding = {}));
})(app || (app = {}));
var app;
(function (app) {
    var funding;
    (function (funding) {
        var PADDSystemicOutcomeInfo = (function () {
            function PADDSystemicOutcomeInfo(common, datacontext) {
                this.common = common;
                this.datacontext = datacontext;
                'ngInject';
                'ngInject';
            }
            PADDSystemicOutcomeInfo.prototype.$onChanges = function () {
                this.checkInvestigation();
            };
            PADDSystemicOutcomeInfo.prototype.checkInvestigation = function () {
                var _this = this;
                _.forEach(this.outcome.interventionStrategies, function (interventionStrategy) { if (interventionStrategy.contains('Investigation'))
                    _this.isInvestigation = true; });
            };
            PADDSystemicOutcomeInfo.$inject = ['common', 'datacontext'];
            PADDSystemicOutcomeInfo = __decorate([
                app.Component('app.funding', 'cePaddSystemicOutcomeInfo', {
                    templateUrl: 'app/funding/enrollments/padd/systemic/padd-outcome-info.html',
                    bindings: {
                        outcome: '<',
                        onEdit: '&'
                    }
                })
            ], PADDSystemicOutcomeInfo);
            return PADDSystemicOutcomeInfo;
        }());
    })(funding = app.funding || (app.funding = {}));
})(app || (app = {}));
var app;
(function (app) {
    var funding;
    (function (funding) {
        var PADDSystemicOutcome = (function () {
            function PADDSystemicOutcome(common, datacontext) {
                this.common = common;
                this.datacontext = datacontext;
                this.outcome = {};
                'ngInject';
                'ngInject';
            }
            PADDSystemicOutcome.prototype.$onInit = function () {
                this.getOutcome();
            };
            PADDSystemicOutcome.prototype.getOutcome = function () {
                var _this = this;
                return this.datacontext.funding.getPADDSystemicOutcome(this.enrollmentId).then(function (result) {
                    if (result) {
                        _this.outcome = result;
                    }
                    else {
                        _this.outcome.id = _this.enrollmentId;
                    }
                });
            };
            PADDSystemicOutcome.prototype.edit = function () {
                var _this = this;
                this.getOutcome().then(function () { return _this.editing = true; });
            };
            PADDSystemicOutcome.prototype.updateOutcome = function () {
                var _this = this;
                this.datacontext.funding.updatePADDSystemicOutcome(this.outcome).then(function () {
                    _this.editing = false;
                });
            };
            PADDSystemicOutcome.prototype.cancel = function () {
                var _this = this;
                this.getOutcome().then(function () {
                    _this.editing = false;
                });
            };
            PADDSystemicOutcome.prototype.done = function () {
                this.common.$state.go('^.');
            };
            PADDSystemicOutcome.$inject = ['common', 'datacontext'];
            PADDSystemicOutcome = __decorate([
                app.Component('app.funding', 'cePaddSystemicOutcome', {
                    templateUrl: 'app/funding/enrollments/padd/systemic/padd-outcome.html',
                    bindings: {
                        enrollmentId: '<'
                    }
                })
            ], PADDSystemicOutcome);
            return PADDSystemicOutcome;
        }());
    })(funding = app.funding || (app.funding = {}));
})(app || (app = {}));
var app;
(function (app) {
    var funding;
    (function (funding) {
        var PATBIIAndROutcomeForm = (function () {
            function PATBIIAndROutcomeForm(common, datacontext) {
                this.common = common;
                this.datacontext = datacontext;
                'ngInject';
                'ngInject';
            }
            PATBIIAndROutcomeForm.$inject = ['common', 'datacontext'];
            PATBIIAndROutcomeForm = __decorate([
                app.Component('app.funding', 'cePatbiIAndROutcomeForm', {
                    templateUrl: 'app/funding/enrollments/patbi/i-and-r/patbi-outcome-form.html',
                    bindings: {
                        outcome: '<',
                        onCancel: '&',
                        onSubmit: '&'
                    }
                })
            ], PATBIIAndROutcomeForm);
            return PATBIIAndROutcomeForm;
        }());
    })(funding = app.funding || (app.funding = {}));
})(app || (app = {}));
var app;
(function (app) {
    var funding;
    (function (funding) {
        var PATBIIAndROutcomeInfo = (function () {
            function PATBIIAndROutcomeInfo(common, datacontext) {
                this.common = common;
                this.datacontext = datacontext;
                'ngInject';
                'ngInject';
            }
            PATBIIAndROutcomeInfo.$inject = ['common', 'datacontext'];
            PATBIIAndROutcomeInfo = __decorate([
                app.Component('app.funding', 'cePatbiIAndROutcomeInfo', {
                    templateUrl: 'app/funding/enrollments/patbi/i-and-r/patbi-outcome-info.html',
                    bindings: {
                        outcome: '<',
                        onEdit: '&'
                    }
                })
            ], PATBIIAndROutcomeInfo);
            return PATBIIAndROutcomeInfo;
        }());
    })(funding = app.funding || (app.funding = {}));
})(app || (app = {}));
var app;
(function (app) {
    var funding;
    (function (funding) {
        var PATBIIAndROutcome = (function () {
            function PATBIIAndROutcome(common, datacontext) {
                this.common = common;
                this.datacontext = datacontext;
                this.outcome = {};
                'ngInject';
                'ngInject';
            }
            PATBIIAndROutcome.prototype.$onInit = function () {
                this.getOutcome();
            };
            PATBIIAndROutcome.prototype.getOutcome = function () {
                var _this = this;
                return this.datacontext.funding.getPATBIIAndROutcome(this.enrollmentId).then(function (result) {
                    if (result) {
                        _this.outcome = result;
                    }
                    else {
                        _this.outcome.id = _this.enrollmentId;
                    }
                });
            };
            PATBIIAndROutcome.prototype.edit = function () {
                var _this = this;
                this.getOutcome().then(function () { return _this.editing = true; });
            };
            PATBIIAndROutcome.prototype.updateOutcome = function () {
                var _this = this;
                this.datacontext.funding.updatePATBIIAndROutcome(this.outcome).then(function () {
                    _this.editing = false;
                });
            };
            PATBIIAndROutcome.prototype.cancel = function () {
                var _this = this;
                this.getOutcome().then(function () {
                    _this.editing = false;
                });
            };
            PATBIIAndROutcome.prototype.done = function () {
                this.common.$state.go('^.');
            };
            PATBIIAndROutcome.$inject = ['common', 'datacontext'];
            PATBIIAndROutcome = __decorate([
                app.Component('app.funding', 'cePatbiIAndROutcome', {
                    templateUrl: 'app/funding/enrollments/patbi/i-and-r/patbi-outcome.html',
                    bindings: {
                        enrollmentId: '<'
                    }
                })
            ], PATBIIAndROutcome);
            return PATBIIAndROutcome;
        }());
    })(funding = app.funding || (app.funding = {}));
})(app || (app = {}));
var app;
(function (app) {
    var funding;
    (function (funding) {
        var PAIMISystemicIntakeForm = (function () {
            function PAIMISystemicIntakeForm(common, datacontext) {
                this.common = common;
                this.datacontext = datacontext;
                'ngInject';
                'ngInject';
            }
            PAIMISystemicIntakeForm.$inject = ['common', 'datacontext'];
            PAIMISystemicIntakeForm = __decorate([
                app.Component('app.funding', 'cePaimiSystemicIntakeForm', {
                    templateUrl: 'app/funding/enrollments/paimi/systemic/paimi-intake-form.html',
                    bindings: {
                        intake: '<',
                        onCancel: '&',
                        onSubmit: '&'
                    }
                })
            ], PAIMISystemicIntakeForm);
            return PAIMISystemicIntakeForm;
        }());
    })(funding = app.funding || (app.funding = {}));
})(app || (app = {}));
var app;
(function (app) {
    var funding;
    (function (funding) {
        var PAIMISystemicIntakeInfo = (function () {
            function PAIMISystemicIntakeInfo(common, datacontext) {
                this.common = common;
                this.datacontext = datacontext;
                'ngInject';
                'ngInject';
            }
            PAIMISystemicIntakeInfo.$inject = ['common', 'datacontext'];
            PAIMISystemicIntakeInfo = __decorate([
                app.Component('app.funding', 'cePaimiSystemicIntakeInfo', {
                    templateUrl: 'app/funding/enrollments/paimi/systemic/paimi-intake-info.html',
                    bindings: {
                        intake: '<',
                        onEdit: '&'
                    }
                })
            ], PAIMISystemicIntakeInfo);
            return PAIMISystemicIntakeInfo;
        }());
    })(funding = app.funding || (app.funding = {}));
})(app || (app = {}));
var app;
(function (app) {
    var funding;
    (function (funding) {
        var PAIMISystemicIntake = (function () {
            function PAIMISystemicIntake(common, datacontext) {
                this.common = common;
                this.datacontext = datacontext;
                'ngInject';
                'ngInject';
            }
            PAIMISystemicIntake.prototype.$onInit = function () {
                this.getIntake();
            };
            PAIMISystemicIntake.prototype.getIntake = function () {
                var _this = this;
                return this.datacontext.funding.getPAIMISystemicIntake(this.enrollmentId)
                    .then(function (intake) { if (intake)
                    _this.intake = intake; });
            };
            PAIMISystemicIntake.prototype.edit = function () {
                var _this = this;
                this.getIntake().then(function () {
                    _this.editing = true;
                });
            };
            PAIMISystemicIntake.prototype.updateIntake = function () {
                var _this = this;
                if (this.intake.deathReporter && this.intake.deathReporter.indexOf('Other') === -1) {
                    this.intake.deathReporterOther = null;
                }
                this.datacontext.funding.updatePAIMISystemicIntake(this.intake).then(function () {
                    _this.editing = false;
                });
            };
            PAIMISystemicIntake.prototype.cancel = function () {
                var _this = this;
                this.getIntake().then(function () {
                    _this.editing = false;
                });
            };
            PAIMISystemicIntake.prototype.done = function () {
                this.common.$state.go('^.');
            };
            PAIMISystemicIntake.$inject = ['common', 'datacontext'];
            PAIMISystemicIntake = __decorate([
                app.Component('app.funding', 'cePaimiSystemicIntake', {
                    templateUrl: 'app/funding/enrollments/paimi/systemic/paimi-intake.html',
                    bindings: {
                        enrollmentId: '<'
                    }
                })
            ], PAIMISystemicIntake);
            return PAIMISystemicIntake;
        }());
    })(funding = app.funding || (app.funding = {}));
})(app || (app = {}));
var app;
(function (app) {
    var funding;
    (function (funding) {
        var PAIMISystemicOutcomeForm = (function () {
            function PAIMISystemicOutcomeForm(common, datacontext) {
                this.common = common;
                this.datacontext = datacontext;
                'ngInject';
                'ngInject';
            }
            PAIMISystemicOutcomeForm.prototype.$onInit = function () {
                this.getPAIMISystemicOutcomeOptions();
            };
            PAIMISystemicOutcomeForm.prototype.getPAIMISystemicOutcomeOptions = function () {
                var _this = this;
                this.datacontext.funding.getPAIMISystemicOutcomeOptions()
                    .then(function (results) { return _this.systemicOutcomeOptions = results; });
            };
            PAIMISystemicOutcomeForm.prototype.outcomeOptionChanged = function (option) {
                if (!this.outcome.interventionOutcomes) {
                    this.outcome.interventionOutcomes = [];
                }
                var index = _.findIndex(this.outcome.interventionOutcomes, function (o) { return o === option.text; });
                if (index === -1) {
                    this.outcome.interventionOutcomes.push(option.text);
                }
                else {
                    _.remove(this.outcome.interventionOutcomes, function (p) { return p === option.text; });
                }
            };
            PAIMISystemicOutcomeForm.prototype.outcomeOptionSelected = function (option) {
                return _.findIndex(this.outcome.interventionOutcomes, function (p) { return p === option; }) > -1;
            };
            PAIMISystemicOutcomeForm.$inject = ['common', 'datacontext'];
            PAIMISystemicOutcomeForm = __decorate([
                app.Component('app.funding', 'cePaimiSystemicOutcomeForm', {
                    templateUrl: 'app/funding/enrollments/paimi/systemic/paimi-outcome-form.html',
                    bindings: {
                        outcome: '<',
                        onCancel: '&',
                        onSubmit: '&'
                    }
                })
            ], PAIMISystemicOutcomeForm);
            return PAIMISystemicOutcomeForm;
        }());
    })(funding = app.funding || (app.funding = {}));
})(app || (app = {}));
var app;
(function (app) {
    var funding;
    (function (funding) {
        var PAIMISystemicOutcomeInfo = (function () {
            function PAIMISystemicOutcomeInfo(common, datacontext) {
                this.common = common;
                this.datacontext = datacontext;
                'ngInject';
                'ngInject';
            }
            PAIMISystemicOutcomeInfo.$inject = ['common', 'datacontext'];
            PAIMISystemicOutcomeInfo = __decorate([
                app.Component('app.funding', 'cePaimiSystemicOutcomeInfo', {
                    templateUrl: 'app/funding/enrollments/paimi/systemic/paimi-outcome-info.html',
                    bindings: {
                        outcome: '<',
                        onEdit: '&'
                    }
                })
            ], PAIMISystemicOutcomeInfo);
            return PAIMISystemicOutcomeInfo;
        }());
    })(funding = app.funding || (app.funding = {}));
})(app || (app = {}));
var app;
(function (app) {
    var funding;
    (function (funding) {
        var PAIMISystemicOutcome = (function () {
            function PAIMISystemicOutcome(common, datacontext) {
                this.common = common;
                this.datacontext = datacontext;
                this.outcome = {};
                'ngInject';
                'ngInject';
            }
            PAIMISystemicOutcome.prototype.$onInit = function () {
                this.getOutcome();
            };
            PAIMISystemicOutcome.prototype.getOutcome = function () {
                var _this = this;
                return this.datacontext.funding.getPAIMISystemicOutcome(this.enrollmentId).then(function (result) {
                    if (result) {
                        _this.outcome = result;
                    }
                    else {
                        _this.outcome.id = _this.enrollmentId;
                    }
                });
            };
            PAIMISystemicOutcome.prototype.edit = function () {
                var _this = this;
                this.getOutcome().then(function () { return _this.editing = true; });
            };
            PAIMISystemicOutcome.prototype.updateOutcome = function () {
                var _this = this;
                this.datacontext.funding.updatePAIMISystemicOutcome(this.outcome).then(function () {
                    _this.editing = false;
                });
            };
            PAIMISystemicOutcome.prototype.cancel = function () {
                var _this = this;
                this.getOutcome().then(function () {
                    _this.editing = false;
                });
            };
            PAIMISystemicOutcome.prototype.done = function () {
                this.common.$state.go('^.');
            };
            PAIMISystemicOutcome.$inject = ['common', 'datacontext'];
            PAIMISystemicOutcome = __decorate([
                app.Component('app.funding', 'cePaimiSystemicOutcome', {
                    templateUrl: 'app/funding/enrollments/paimi/systemic/paimi-outcome.html',
                    bindings: {
                        enrollmentId: '<'
                    }
                })
            ], PAIMISystemicOutcome);
            return PAIMISystemicOutcome;
        }());
    })(funding = app.funding || (app.funding = {}));
})(app || (app = {}));
var app;
(function (app) {
    var funding;
    (function (funding) {
        var PATBISystemicIntakeForm = (function () {
            function PATBISystemicIntakeForm(datacontext) {
                this.datacontext = datacontext;
                'ngInject';
                'ngInject';
            }
            PATBISystemicIntakeForm.prototype.$onInit = function () {
                this.getAgeRanges();
                this.getRaces();
            };
            PATBISystemicIntakeForm.prototype.getAgeRanges = function () {
                var _this = this;
                return this.datacontext.config.lookups.getLookupType('patbi-systemic-age-ranges')
                    .then(function (lookup) { _this.ageRanges = lookup.lookups; });
            };
            PATBISystemicIntakeForm.prototype.getRaces = function () {
                var _this = this;
                return this.datacontext.config.lookups.getLookupType('patbi-systemic-races')
                    .then(function (lookup) { _this.races = lookup.lookups; });
            };
            PATBISystemicIntakeForm.$inject = ['datacontext'];
            PATBISystemicIntakeForm = __decorate([
                app.Component('app.funding', 'cePatbiSystemicIntakeForm', {
                    templateUrl: 'app/funding/enrollments/patbi/systemic/patbi-intake-form.html',
                    bindings: {
                        intake: '<',
                        onCancel: '&',
                        onSubmit: '&',
                        livingArrangements: '<',
                        problemAreas: '<'
                    }
                })
            ], PATBISystemicIntakeForm);
            return PATBISystemicIntakeForm;
        }());
    })(funding = app.funding || (app.funding = {}));
})(app || (app = {}));
var app;
(function (app) {
    var funding;
    (function (funding) {
        var PATBISystemicIntakeInfo = (function () {
            function PATBISystemicIntakeInfo(common, datacontext) {
                this.common = common;
                this.datacontext = datacontext;
                'ngInject';
                'ngInject';
            }
            PATBISystemicIntakeInfo.prototype.$onChanges = function (changes) {
                if (this.intake.livingArrangements && this.livingArrangements) {
                    this.setLivingArrangementsDisplayValue();
                }
                if (this.intake.problemAreas && this.problemAreas) {
                    this.setProblemAreasDisplayValue();
                }
            };
            PATBISystemicIntakeInfo.prototype.setProblemAreasDisplayValue = function () {
                var _this = this;
                this.intake.problemAreasDisplayValue = [];
                this.intake.problemAreas.forEach(function (paId) {
                    _this.problemAreas.forEach(function (pa) {
                        if (paId === pa.id) {
                            _this.intake.problemAreasDisplayValue.push(pa.value);
                        }
                    });
                });
            };
            PATBISystemicIntakeInfo.prototype.setLivingArrangementsDisplayValue = function () {
                var _this = this;
                this.intake.livingArrangementsDisplayValue = [];
                this.intake.livingArrangements.forEach(function (laId) {
                    _this.livingArrangements.forEach(function (la) {
                        if (laId === la.id) {
                            _this.intake.livingArrangementsDisplayValue.push(la.value);
                        }
                    });
                });
            };
            PATBISystemicIntakeInfo.$inject = ['common', 'datacontext'];
            PATBISystemicIntakeInfo = __decorate([
                app.Component('app.funding', 'cePatbiSystemicIntakeInfo', {
                    templateUrl: 'app/funding/enrollments/patbi/systemic/patbi-intake-info.html',
                    bindings: {
                        intake: '<',
                        onEdit: '&',
                        livingArrangements: '<',
                        problemAreas: '<'
                    }
                })
            ], PATBISystemicIntakeInfo);
            return PATBISystemicIntakeInfo;
        }());
    })(funding = app.funding || (app.funding = {}));
})(app || (app = {}));
var app;
(function (app) {
    var funding;
    (function (funding) {
        var PATBISystemicIntake = (function () {
            function PATBISystemicIntake(common, datacontext) {
                this.common = common;
                this.datacontext = datacontext;
                'ngInject';
                'ngInject';
            }
            PATBISystemicIntake.prototype.$onInit = function () {
                this.intake = { id: this.enrollmentId };
                this.getLivingArrangements();
                this.getProblemAreas();
                this.getIntake();
            };
            PATBISystemicIntake.prototype.getIntake = function () {
                var _this = this;
                return this.datacontext.funding.getPATBISystemicIntake(this.enrollmentId)
                    .then(function (intake) { if (intake)
                    _this.intake = intake; });
            };
            PATBISystemicIntake.prototype.edit = function () {
                var _this = this;
                this.getIntake().then(function () {
                    _this.editing = true;
                });
            };
            PATBISystemicIntake.prototype.updateIntake = function () {
                var _this = this;
                this.datacontext.funding.updatePATBISystemicIntake(this.intake).then(function () {
                    _this.editing = false;
                });
            };
            PATBISystemicIntake.prototype.cancel = function () {
                var _this = this;
                this.getIntake().then(function () {
                    _this.editing = false;
                });
            };
            PATBISystemicIntake.prototype.done = function () {
                this.common.$state.go('^.');
            };
            PATBISystemicIntake.prototype.getLivingArrangements = function () {
                var _this = this;
                return this.datacontext.funding.getPATBILivingArrangementOptions()
                    .then(function (livingArrangements) { _this.livingArrangements = livingArrangements; });
            };
            PATBISystemicIntake.prototype.getProblemAreas = function () {
                var _this = this;
                return this.datacontext.funding.getPATBIProblemAreaOptions()
                    .then(function (problemAreas) { _this.problemAreas = problemAreas; });
            };
            PATBISystemicIntake.$inject = ['common', 'datacontext'];
            PATBISystemicIntake = __decorate([
                app.Component('app.funding', 'cePatbiSystemicIntake', {
                    templateUrl: 'app/funding/enrollments/patbi/systemic/patbi-intake.html',
                    bindings: {
                        enrollmentId: '<'
                    }
                })
            ], PATBISystemicIntake);
            return PATBISystemicIntake;
        }());
    })(funding = app.funding || (app.funding = {}));
})(app || (app = {}));
var app;
(function (app) {
    var funding;
    (function (funding) {
        var PATBISystemicOutcomeForm = (function () {
            function PATBISystemicOutcomeForm(common, datacontext) {
                this.common = common;
                this.datacontext = datacontext;
                'ngInject';
                'ngInject';
            }
            PATBISystemicOutcomeForm.prototype.$onInit = function () {
                this.getInterventionStrategies();
            };
            PATBISystemicOutcomeForm.prototype.checkInvestigation = function () {
                var _this = this;
                this.isInvestigation = false;
                _.forEach(this.outcome.interventionStrategies, function (interventionStrategy) { if (interventionStrategy.contains('Investigation'))
                    _this.isInvestigation = true; });
            };
            PATBISystemicOutcomeForm.prototype.getInterventionStrategies = function () {
                var _this = this;
                return this.datacontext.config.lookups.getLookupType('patbi-systemic-intervention-strategies')
                    .then(function (lookup) { _this.interventionStrategies = lookup.lookups; });
            };
            PATBISystemicOutcomeForm.$inject = ['common', 'datacontext'];
            PATBISystemicOutcomeForm = __decorate([
                app.Component('app.funding', 'cePatbiSystemicOutcomeForm', {
                    templateUrl: 'app/funding/enrollments/patbi/systemic/patbi-outcome-form.html',
                    bindings: {
                        outcome: '<',
                        onCancel: '&',
                        onSubmit: '&'
                    }
                })
            ], PATBISystemicOutcomeForm);
            return PATBISystemicOutcomeForm;
        }());
    })(funding = app.funding || (app.funding = {}));
})(app || (app = {}));
var app;
(function (app) {
    var funding;
    (function (funding) {
        var PATBISystemicOutcomeInfo = (function () {
            function PATBISystemicOutcomeInfo(common, datacontext) {
                this.common = common;
                this.datacontext = datacontext;
                'ngInject';
                'ngInject';
            }
            PATBISystemicOutcomeInfo.prototype.$onChanges = function () {
                this.checkInvestigation();
            };
            PATBISystemicOutcomeInfo.prototype.checkInvestigation = function () {
                var _this = this;
                _.forEach(this.outcome.interventionStrategies, function (interventionStrategy) { if (interventionStrategy.contains('Investigation'))
                    _this.isInvestigation = true; });
            };
            PATBISystemicOutcomeInfo.$inject = ['common', 'datacontext'];
            PATBISystemicOutcomeInfo = __decorate([
                app.Component('app.funding', 'cePatbiSystemicOutcomeInfo', {
                    templateUrl: 'app/funding/enrollments/patbi/systemic/patbi-outcome-info.html',
                    bindings: {
                        outcome: '<',
                        onEdit: '&'
                    }
                })
            ], PATBISystemicOutcomeInfo);
            return PATBISystemicOutcomeInfo;
        }());
    })(funding = app.funding || (app.funding = {}));
})(app || (app = {}));
var app;
(function (app) {
    var funding;
    (function (funding) {
        var PATBISystemicOutcome = (function () {
            function PATBISystemicOutcome(common, datacontext) {
                this.common = common;
                this.datacontext = datacontext;
                this.outcome = {};
                'ngInject';
                'ngInject';
            }
            PATBISystemicOutcome.prototype.$onInit = function () {
                this.getOutcome();
            };
            PATBISystemicOutcome.prototype.getOutcome = function () {
                var _this = this;
                return this.datacontext.funding.getPATBISystemicOutcome(this.enrollmentId).then(function (result) {
                    if (result) {
                        _this.outcome = result;
                    }
                    else {
                        _this.outcome.id = _this.enrollmentId;
                    }
                });
            };
            PATBISystemicOutcome.prototype.edit = function () {
                var _this = this;
                this.getOutcome().then(function () { return _this.editing = true; });
            };
            PATBISystemicOutcome.prototype.updateOutcome = function () {
                var _this = this;
                this.datacontext.funding.updatePATBISystemicOutcome(this.outcome).then(function () {
                    _this.editing = false;
                });
            };
            PATBISystemicOutcome.prototype.cancel = function () {
                var _this = this;
                this.getOutcome().then(function () {
                    _this.editing = false;
                });
            };
            PATBISystemicOutcome.prototype.done = function () {
                this.common.$state.go('^.');
            };
            PATBISystemicOutcome.$inject = ['common', 'datacontext'];
            PATBISystemicOutcome = __decorate([
                app.Component('app.funding', 'cePatbiSystemicOutcome', {
                    templateUrl: 'app/funding/enrollments/patbi/systemic/patbi-outcome.html',
                    bindings: {
                        enrollmentId: '<'
                    }
                })
            ], PATBISystemicOutcome);
            return PATBISystemicOutcome;
        }());
    })(funding = app.funding || (app.funding = {}));
})(app || (app = {}));
var app;
(function (app) {
    var funding;
    (function (funding) {
        var PAVAIAndROutcomeForm = (function () {
            function PAVAIAndROutcomeForm(common, datacontext) {
                this.common = common;
                this.datacontext = datacontext;
                'ngInject';
                'ngInject';
            }
            PAVAIAndROutcomeForm.$inject = ['common', 'datacontext'];
            PAVAIAndROutcomeForm = __decorate([
                app.Component('app.funding', 'cePavaIAndROutcomeForm', {
                    templateUrl: 'app/funding/enrollments/pava/i-and-r/pava-outcome-form.html',
                    bindings: {
                        outcome: '<',
                        onCancel: '&',
                        onSubmit: '&'
                    }
                })
            ], PAVAIAndROutcomeForm);
            return PAVAIAndROutcomeForm;
        }());
    })(funding = app.funding || (app.funding = {}));
})(app || (app = {}));
var app;
(function (app) {
    var funding;
    (function (funding) {
        var PAVAIAndROutcomeInfo = (function () {
            function PAVAIAndROutcomeInfo(common, datacontext) {
                this.common = common;
                this.datacontext = datacontext;
                'ngInject';
                'ngInject';
            }
            PAVAIAndROutcomeInfo.$inject = ['common', 'datacontext'];
            PAVAIAndROutcomeInfo = __decorate([
                app.Component('app.funding', 'cePavaIAndROutcomeInfo', {
                    templateUrl: 'app/funding/enrollments/pava/i-and-r/pava-outcome-info.html',
                    bindings: {
                        outcome: '<',
                        onEdit: '&'
                    }
                })
            ], PAVAIAndROutcomeInfo);
            return PAVAIAndROutcomeInfo;
        }());
    })(funding = app.funding || (app.funding = {}));
})(app || (app = {}));
var app;
(function (app) {
    var funding;
    (function (funding) {
        var PAVAIAndROutcome = (function () {
            function PAVAIAndROutcome(common, datacontext) {
                this.common = common;
                this.datacontext = datacontext;
                this.outcome = {};
                'ngInject';
                'ngInject';
            }
            PAVAIAndROutcome.prototype.$onInit = function () {
                this.getOutcome();
            };
            PAVAIAndROutcome.prototype.getOutcome = function () {
                var _this = this;
                return this.datacontext.funding.getPAVAIAndROutcome(this.enrollmentId).then(function (result) {
                    if (result) {
                        _this.outcome = result;
                    }
                    else {
                        _this.outcome.id = _this.enrollmentId;
                    }
                });
            };
            PAVAIAndROutcome.prototype.edit = function () {
                var _this = this;
                this.getOutcome().then(function () { return _this.editing = true; });
            };
            PAVAIAndROutcome.prototype.updateOutcome = function () {
                var _this = this;
                this.datacontext.funding.updatePAVAIAndROutcome(this.outcome).then(function () {
                    _this.editing = false;
                });
            };
            PAVAIAndROutcome.prototype.cancel = function () {
                var _this = this;
                this.getOutcome().then(function () {
                    _this.editing = false;
                });
            };
            PAVAIAndROutcome.prototype.done = function () {
                this.common.$state.go('^.');
            };
            PAVAIAndROutcome.$inject = ['common', 'datacontext'];
            PAVAIAndROutcome = __decorate([
                app.Component('app.funding', 'cePavaIAndROutcome', {
                    templateUrl: 'app/funding/enrollments/pava/i-and-r/pava-outcome.html',
                    bindings: {
                        enrollmentId: '<'
                    }
                })
            ], PAVAIAndROutcome);
            return PAVAIAndROutcome;
        }());
    })(funding = app.funding || (app.funding = {}));
})(app || (app = {}));
var app;
(function (app) {
    var funding;
    (function (funding) {
        var PAVASystemicIntakeForm = (function () {
            function PAVASystemicIntakeForm(datacontext) {
                this.datacontext = datacontext;
                'ngInject';
                'ngInject';
            }
            PAVASystemicIntakeForm.prototype.$onInit = function () {
                this.getAgeRanges();
                this.getRaces();
            };
            PAVASystemicIntakeForm.prototype.getAgeRanges = function () {
                var _this = this;
                return this.datacontext.config.lookups.getLookupType('pava-systemic-age-ranges')
                    .then(function (lookup) { _this.ageRanges = lookup.lookups; });
            };
            PAVASystemicIntakeForm.prototype.getRaces = function () {
                var _this = this;
                return this.datacontext.config.lookups.getLookupType('pava-systemic-races')
                    .then(function (lookup) { _this.races = lookup.lookups; });
            };
            PAVASystemicIntakeForm.$inject = ['datacontext'];
            PAVASystemicIntakeForm = __decorate([
                app.Component('app.funding', 'cePavaSystemicIntakeForm', {
                    templateUrl: 'app/funding/enrollments/pava/systemic/pava-intake-form.html',
                    bindings: {
                        intake: '<',
                        onCancel: '&',
                        onSubmit: '&',
                        livingArrangements: '<',
                        problemAreas: '<'
                    }
                })
            ], PAVASystemicIntakeForm);
            return PAVASystemicIntakeForm;
        }());
    })(funding = app.funding || (app.funding = {}));
})(app || (app = {}));
var app;
(function (app) {
    var funding;
    (function (funding) {
        var PAVASystemicIntakeInfo = (function () {
            function PAVASystemicIntakeInfo() {
                'ngInject';
            }
            PAVASystemicIntakeInfo.prototype.$onChanges = function (changes) {
                if (this.intake.livingArrangements && this.livingArrangements) {
                    this.setLivingArrangementsDisplayValue();
                }
                if (this.intake.problemAreas && this.problemAreas) {
                    this.setProblemAreasDisplayValue();
                }
            };
            PAVASystemicIntakeInfo.prototype.setProblemAreasDisplayValue = function () {
                var _this = this;
                this.intake.problemAreasDisplayValue = [];
                this.intake.problemAreas.forEach(function (paId) {
                    _this.problemAreas.forEach(function (pa) {
                        if (paId === pa.id) {
                            _this.intake.problemAreasDisplayValue.push(pa.value);
                        }
                    });
                });
            };
            PAVASystemicIntakeInfo.prototype.setLivingArrangementsDisplayValue = function () {
                var _this = this;
                this.intake.livingArrangementsDisplayValue = [];
                this.intake.livingArrangements.forEach(function (laId) {
                    _this.livingArrangements.forEach(function (la) {
                        if (laId === la.id) {
                            _this.intake.livingArrangementsDisplayValue.push(la.value);
                        }
                    });
                });
            };
            PAVASystemicIntakeInfo = __decorate([
                app.Component('app.funding', 'cePavaSystemicIntakeInfo', {
                    templateUrl: 'app/funding/enrollments/pava/systemic/pava-intake-info.html',
                    bindings: {
                        intake: '<',
                        onEdit: '&',
                        livingArrangements: '<',
                        problemAreas: '<'
                    }
                })
            ], PAVASystemicIntakeInfo);
            return PAVASystemicIntakeInfo;
        }());
    })(funding = app.funding || (app.funding = {}));
})(app || (app = {}));
var app;
(function (app) {
    var funding;
    (function (funding) {
        var PAVASystemicIntake = (function () {
            function PAVASystemicIntake(common, datacontext) {
                this.common = common;
                this.datacontext = datacontext;
                'ngInject';
                'ngInject';
            }
            PAVASystemicIntake.prototype.$onInit = function () {
                this.intake = { id: this.enrollmentId };
                this.getLivingArrangements();
                this.getProblemAreas();
                this.getIntake();
            };
            PAVASystemicIntake.prototype.getIntake = function () {
                var _this = this;
                return this.datacontext.funding.getPAVASystemicIntake(this.enrollmentId)
                    .then(function (intake) { if (intake)
                    _this.intake = intake; });
            };
            PAVASystemicIntake.prototype.edit = function () {
                var _this = this;
                this.getIntake().then(function () {
                    _this.editing = true;
                });
            };
            PAVASystemicIntake.prototype.updateIntake = function () {
                var _this = this;
                this.datacontext.funding.updatePAVASystemicIntake(this.intake).then(function () {
                    _this.editing = false;
                });
            };
            PAVASystemicIntake.prototype.cancel = function () {
                var _this = this;
                this.getIntake().then(function () {
                    _this.editing = false;
                });
            };
            PAVASystemicIntake.prototype.done = function () {
                this.common.$state.go('^.');
            };
            PAVASystemicIntake.prototype.getLivingArrangements = function () {
                var _this = this;
                return this.datacontext.funding.getPAVALivingArrangementOptions()
                    .then(function (livingArrangements) { _this.livingArrangements = livingArrangements; });
            };
            PAVASystemicIntake.prototype.getProblemAreas = function () {
                var _this = this;
                return this.datacontext.funding.getPAVAProblemAreaOptions()
                    .then(function (problemAreas) { _this.problemAreas = problemAreas; });
            };
            PAVASystemicIntake.$inject = ['common', 'datacontext'];
            PAVASystemicIntake = __decorate([
                app.Component('app.funding', 'cePavaSystemicIntake', {
                    templateUrl: 'app/funding/enrollments/pava/systemic/pava-intake.html',
                    bindings: {
                        enrollmentId: '<'
                    }
                })
            ], PAVASystemicIntake);
            return PAVASystemicIntake;
        }());
    })(funding = app.funding || (app.funding = {}));
})(app || (app = {}));
var app;
(function (app) {
    var funding;
    (function (funding) {
        var PAVASystemicOutcomeForm = (function () {
            function PAVASystemicOutcomeForm(common, datacontext) {
                this.common = common;
                this.datacontext = datacontext;
                'ngInject';
                'ngInject';
            }
            PAVASystemicOutcomeForm.prototype.$onInit = function () {
                this.getInterventionStrategies();
            };
            PAVASystemicOutcomeForm.prototype.checkInvestigation = function () {
                var _this = this;
                this.isInvestigation = false;
                _.forEach(this.outcome.interventionStrategies, function (interventionStrategy) { if (interventionStrategy.contains('Investigation'))
                    _this.isInvestigation = true; });
            };
            PAVASystemicOutcomeForm.prototype.getInterventionStrategies = function () {
                var _this = this;
                return this.datacontext.config.lookups.getLookupType('pava-systemic-intervention-strategies')
                    .then(function (lookup) { _this.interventionStrategies = lookup.lookups; });
            };
            PAVASystemicOutcomeForm.$inject = ['common', 'datacontext'];
            PAVASystemicOutcomeForm = __decorate([
                app.Component('app.funding', 'cePavaSystemicOutcomeForm', {
                    templateUrl: 'app/funding/enrollments/pava/systemic/pava-outcome-form.html',
                    bindings: {
                        outcome: '<',
                        onCancel: '&',
                        onSubmit: '&'
                    }
                })
            ], PAVASystemicOutcomeForm);
            return PAVASystemicOutcomeForm;
        }());
    })(funding = app.funding || (app.funding = {}));
})(app || (app = {}));
var app;
(function (app) {
    var funding;
    (function (funding) {
        var PAVASystemicOutcomeInfo = (function () {
            function PAVASystemicOutcomeInfo(common, datacontext) {
                this.common = common;
                this.datacontext = datacontext;
                'ngInject';
                'ngInject';
            }
            PAVASystemicOutcomeInfo.$inject = ['common', 'datacontext'];
            PAVASystemicOutcomeInfo = __decorate([
                app.Component('app.funding', 'cePavaSystemicOutcomeInfo', {
                    templateUrl: 'app/funding/enrollments/pava/systemic/pava-outcome-info.html',
                    bindings: {
                        outcome: '<',
                        onEdit: '&'
                    }
                })
            ], PAVASystemicOutcomeInfo);
            return PAVASystemicOutcomeInfo;
        }());
    })(funding = app.funding || (app.funding = {}));
})(app || (app = {}));
var app;
(function (app) {
    var funding;
    (function (funding) {
        var PAVASystemicOutcome = (function () {
            function PAVASystemicOutcome(common, datacontext) {
                this.common = common;
                this.datacontext = datacontext;
                this.outcome = {};
                'ngInject';
                'ngInject';
            }
            PAVASystemicOutcome.prototype.$onInit = function () {
                this.getOutcome();
            };
            PAVASystemicOutcome.prototype.getOutcome = function () {
                var _this = this;
                return this.datacontext.funding.getPAVASystemicOutcome(this.enrollmentId).then(function (result) {
                    if (result) {
                        _this.outcome = result;
                    }
                    else {
                        _this.outcome.id = _this.enrollmentId;
                    }
                });
            };
            PAVASystemicOutcome.prototype.edit = function () {
                var _this = this;
                this.getOutcome().then(function () { return _this.editing = true; });
            };
            PAVASystemicOutcome.prototype.updateOutcome = function () {
                var _this = this;
                this.datacontext.funding.updatePAVASystemicOutcome(this.outcome).then(function () {
                    _this.editing = false;
                });
            };
            PAVASystemicOutcome.prototype.cancel = function () {
                var _this = this;
                this.getOutcome().then(function () {
                    _this.editing = false;
                });
            };
            PAVASystemicOutcome.prototype.done = function () {
                this.common.$state.go('^.');
            };
            PAVASystemicOutcome.$inject = ['common', 'datacontext'];
            PAVASystemicOutcome = __decorate([
                app.Component('app.funding', 'cePavaSystemicOutcome', {
                    templateUrl: 'app/funding/enrollments/pava/systemic/pava-outcome.html',
                    bindings: {
                        enrollmentId: '<'
                    }
                })
            ], PAVASystemicOutcome);
            return PAVASystemicOutcome;
        }());
    })(funding = app.funding || (app.funding = {}));
})(app || (app = {}));
var app;
(function (app) {
    var funding;
    (function (funding) {
        var TEPSystemicIntakeForm = (function () {
            function TEPSystemicIntakeForm(common, datacontext) {
                this.common = common;
                this.datacontext = datacontext;
                'ngInject';
                'ngInject';
            }
            TEPSystemicIntakeForm.$inject = ['common', 'datacontext'];
            TEPSystemicIntakeForm = __decorate([
                app.Component('app.funding', 'ceTepSystemicIntakeForm', {
                    templateUrl: 'app/funding/enrollments/tep/systemic/tep-systemic-intake-form.html',
                    bindings: {
                        intake: '<',
                        onCancel: '&',
                        onSubmit: '&'
                    }
                })
            ], TEPSystemicIntakeForm);
            return TEPSystemicIntakeForm;
        }());
    })(funding = app.funding || (app.funding = {}));
})(app || (app = {}));
var app;
(function (app) {
    var funding;
    (function (funding) {
        var TEPSystemicIntakeInfo = (function () {
            function TEPSystemicIntakeInfo(common, datacontext) {
                this.common = common;
                this.datacontext = datacontext;
                'ngInject';
                'ngInject';
            }
            TEPSystemicIntakeInfo.$inject = ['common', 'datacontext'];
            TEPSystemicIntakeInfo = __decorate([
                app.Component('app.funding', 'ceTepSystemicIntakeInfo', {
                    templateUrl: 'app/funding/enrollments/tep/systemic/tep-systemic-intake-info.html',
                    bindings: {
                        intake: '<',
                        onEdit: '&'
                    }
                })
            ], TEPSystemicIntakeInfo);
            return TEPSystemicIntakeInfo;
        }());
    })(funding = app.funding || (app.funding = {}));
})(app || (app = {}));
var app;
(function (app) {
    var funding;
    (function (funding) {
        var TEPSystemicIntake = (function () {
            function TEPSystemicIntake(common, datacontext) {
                this.common = common;
                this.datacontext = datacontext;
                'ngInject';
                'ngInject';
            }
            TEPSystemicIntake.prototype.$onInit = function () {
                this.getIntake();
            };
            TEPSystemicIntake.prototype.getIntake = function () {
                var _this = this;
                return this.datacontext.funding.getTEPSystemicIntake(this.enrollmentId)
                    .then(function (intake) { if (intake)
                    _this.intake = intake; });
            };
            TEPSystemicIntake.prototype.edit = function () {
                var _this = this;
                this.getIntake().then(function () {
                    _this.editing = true;
                });
            };
            TEPSystemicIntake.prototype.updateIntake = function () {
                var _this = this;
                this.datacontext.funding.updateTEPSystemicIntake(this.intake)
                    .then(function () {
                    _this.editing = false;
                });
            };
            TEPSystemicIntake.prototype.cancel = function () {
                var _this = this;
                this.getIntake().then(function () {
                    _this.editing = false;
                });
            };
            TEPSystemicIntake.prototype.done = function () {
                this.common.$state.go('^.');
            };
            TEPSystemicIntake.$inject = ['common', 'datacontext'];
            TEPSystemicIntake = __decorate([
                app.Component('app.funding', 'ceTepSystemicIntake', {
                    templateUrl: 'app/funding/enrollments/tep/systemic/tep-systemic-intake.html',
                    bindings: {
                        enrollmentId: '<'
                    }
                })
            ], TEPSystemicIntake);
            return TEPSystemicIntake;
        }());
    })(funding = app.funding || (app.funding = {}));
})(app || (app = {}));
var app;
(function (app) {
    var funding;
    (function (funding) {
        var TEPSystemicOutcomeForm = (function () {
            function TEPSystemicOutcomeForm(datacontext) {
                var _this = this;
                this.datacontext = datacontext;
                this.$onInit = function () {
                    _this.getServicesProvided();
                };
                'ngInject';
                'ngInject';
            }
            TEPSystemicOutcomeForm.prototype.getServicesProvided = function () {
                var _this = this;
                return this.datacontext.config.lookups.getLookupType('tep-systemic-services-provided')
                    .then(function (lookup) { _this.servicesProvided = lookup.lookups; });
            };
            TEPSystemicOutcomeForm.$inject = ['datacontext'];
            TEPSystemicOutcomeForm = __decorate([
                app.Component('app.funding', 'ceTepSystemicOutcomeForm', {
                    templateUrl: 'app/funding/enrollments/tep/systemic/tep-systemic-outcome-form.html',
                    bindings: {
                        outcome: '<',
                        onCancel: '&',
                        onSubmit: '&'
                    }
                })
            ], TEPSystemicOutcomeForm);
            return TEPSystemicOutcomeForm;
        }());
    })(funding = app.funding || (app.funding = {}));
})(app || (app = {}));
var app;
(function (app) {
    var funding;
    (function (funding) {
        var TEPSystemicOutcomeInfo = (function () {
            function TEPSystemicOutcomeInfo(common, datacontext) {
                this.common = common;
                this.datacontext = datacontext;
                'ngInject';
                'ngInject';
            }
            TEPSystemicOutcomeInfo.$inject = ['common', 'datacontext'];
            TEPSystemicOutcomeInfo = __decorate([
                app.Component('app.funding', 'ceTepSystemicOutcomeInfo', {
                    templateUrl: 'app/funding/enrollments/tep/systemic/tep-systemic-outcome-info.html',
                    bindings: {
                        outcome: '<',
                        onEdit: '&'
                    }
                })
            ], TEPSystemicOutcomeInfo);
            return TEPSystemicOutcomeInfo;
        }());
    })(funding = app.funding || (app.funding = {}));
})(app || (app = {}));
var app;
(function (app) {
    var funding;
    (function (funding) {
        var TEPSystemicOutcome = (function () {
            function TEPSystemicOutcome($mdDialog, common, datacontext) {
                this.$mdDialog = $mdDialog;
                this.common = common;
                this.datacontext = datacontext;
                this.outcome = {};
                'ngInject';
                'ngInject';
            }
            TEPSystemicOutcome.prototype.$onInit = function () {
                this.getOutcome();
            };
            TEPSystemicOutcome.prototype.getOutcome = function () {
                var _this = this;
                return this.datacontext.funding.getTEPSystemicOutcome(this.enrollmentId).then(function (result) {
                    if (result) {
                        _this.outcome = result;
                    }
                    else {
                        _this.outcome.id = _this.enrollmentId;
                    }
                });
            };
            TEPSystemicOutcome.prototype.edit = function () {
                var _this = this;
                this.getOutcome().then(function () { return _this.editing = true; });
            };
            TEPSystemicOutcome.prototype.updateOutcome = function () {
                var _this = this;
                this.datacontext.funding.updateTEPSystemicOutcome(this.outcome).then(function () {
                    _this.editing = false;
                });
            };
            TEPSystemicOutcome.prototype.cancel = function () {
                var _this = this;
                this.getOutcome().then(function () {
                    _this.editing = false;
                });
            };
            TEPSystemicOutcome.prototype.done = function () {
                this.common.$state.go('^.');
            };
            TEPSystemicOutcome.$inject = ['$mdDialog', 'common', 'datacontext'];
            TEPSystemicOutcome = __decorate([
                app.Component('app.funding', 'ceTepSystemicOutcome', {
                    templateUrl: 'app/funding/enrollments/tep/systemic/tep-systemic-outcome.html',
                    bindings: {
                        enrollmentId: '<'
                    }
                })
            ], TEPSystemicOutcome);
            return TEPSystemicOutcome;
        }());
    })(funding = app.funding || (app.funding = {}));
})(app || (app = {}));
var app;
(function (app) {
    var training;
    (function (training) {
        var cle;
        (function (cle) {
            var activities;
            (function (activities) {
                var CLEActivityList = (function () {
                    function CLEActivityList($mdDialog, common, datacontext) {
                        var _this = this;
                        this.$mdDialog = $mdDialog;
                        this.common = common;
                        this.datacontext = datacontext;
                        this.showFilters = false;
                        this.selectedActivityTypes = [];
                        this.activityFilter = {};
                        this.paging = {
                            page: 1,
                            pageSize: 100
                        };
                        this.refresh = function () {
                            _this.getActivities();
                        };
                        'ngInject';
                        'ngInject';
                    }
                    CLEActivityList.prototype.$onInit = function () {
                        this.activityFilter.sourceId = this.cleId;
                        this.activityFilter.source = 'CLE';
                        this.getActivities();
                    };
                    CLEActivityList.prototype.getActivities = function () {
                        var _this = this;
                        if (!this.filtersInitialized()) {
                            return;
                        }
                        this.activityFilter = __assign(__assign(__assign({}, this.activityFilter), this.paging), { activityTypes: this.selectedActivityTypes, q: this.noteFilter });
                        this.promise = this.datacontext.activities
                            .searchActivities(this.activityFilter)
                            .then(function (result) {
                            _this.activities = result;
                        });
                    };
                    CLEActivityList.prototype.newActivity = function (targetEvent) {
                        var parent = angular.element(document.body);
                        this.$mdDialog.show({
                            parent: parent,
                            targetEvent: targetEvent,
                            clickOutsideToClose: true,
                            templateUrl: 'app/training/continuing-legal-education/activities/new-activity/new-activity.html',
                            controller: 'NewCLEActivityDialogController',
                            controllerAs: '$ctrl',
                            locals: {
                                cleId: this.cleId
                            }
                        });
                    };
                    CLEActivityList.prototype.toggleFilters = function () {
                        this.showFilters = !this.showFilters;
                    };
                    CLEActivityList.prototype.filtersInitialized = function () {
                        return this.activityTypeFilterInitialized;
                    };
                    CLEActivityList.prototype.activityTypeFilterChanged = function (activityTypes) {
                        this.selectedActivityTypes = activityTypes;
                        this.activityTypeFilterInitialized = true;
                        this.getActivities();
                    };
                    CLEActivityList.prototype.onEdit = function (activity) {
                        switch (activity.activityType) {
                            case 'Note':
                                this.common.$state.go('^.note', { activity: activity });
                                break;
                        }
                    };
                    CLEActivityList.$inject = ['$mdDialog', 'common', 'datacontext'];
                    CLEActivityList = __decorate([
                        app.Component('app.training.cle.activities', 'ceCLEActivityList', {
                            templateUrl: 'app/training/continuing-legal-education/activities/activity-list/activity-list.html',
                            bindings: {
                                cleId: '<'
                            }
                        })
                    ], CLEActivityList);
                    return CLEActivityList;
                }());
            })(activities = cle.activities || (cle.activities = {}));
        })(cle = training.cle || (training.cle = {}));
    })(training = app.training || (app.training = {}));
})(app || (app = {}));
var app;
(function (app) {
    var training;
    (function (training) {
        var cle;
        (function (cle) {
            var activities;
            (function (activities_9) {
                var activities = [
                    {
                        icon: 'comment',
                        text: 'Note',
                        sref: '^.note',
                    }
                ];
                var NewCLEActivityDialogController = (function () {
                    function NewCLEActivityDialogController($mdDialog, common, cleId) {
                        this.$mdDialog = $mdDialog;
                        this.common = common;
                        this.cleId = cleId;
                        'ngInject';
                        'ngInject';
                    }
                    NewCLEActivityDialogController.prototype.$onInit = function () {
                        this.filteredActivities = activities;
                    };
                    NewCLEActivityDialogController.prototype.selectActivity = function (item) {
                        this.common.$state.go(item.sref, { cleId: this.cleId });
                        this.$mdDialog.hide();
                    };
                    NewCLEActivityDialogController.$inject = ['$mdDialog', 'common', 'cleId'];
                    NewCLEActivityDialogController = __decorate([
                        app.Controller('app.training.cle.activities', 'NewCLEActivityDialogController')
                    ], NewCLEActivityDialogController);
                    return NewCLEActivityDialogController;
                }());
            })(activities = cle.activities || (cle.activities = {}));
        })(cle = training.cle || (training.cle = {}));
    })(training = app.training || (app.training = {}));
})(app || (app = {}));
var app;
(function (app) {
    var training;
    (function (training) {
        var cle;
        (function (cle) {
            var activities;
            (function (activities) {
                var Note = (function () {
                    function Note(common, datacontext, interrupt) {
                        this.common = common;
                        this.datacontext = datacontext;
                        this.interrupt = interrupt;
                        this.isSubmitting = false;
                        this.note = {};
                        'ngInject';
                        'ngInject';
                    }
                    Note.prototype.$onInit = function () {
                        this.maxDate = moment().toDate();
                        if (this.activity) {
                            this.note.id = this.activity.id;
                            this.note.cleId = this.activity.sourceId;
                            this.note.title = this.activity.title;
                            this.note.description = this.activity.description;
                            this.note.activityDate = this.activity.activityDate;
                        }
                        else {
                            this.note.cleId = this.cleId;
                            this.note.activityDate = moment().toDate();
                        }
                    };
                    Note.prototype.submit = function () {
                        var _this = this;
                        this.isSubmitting = true;
                        if (typeof this.note.activityDate !== 'string') {
                            this.note.activityDate = this.note.activityDate.toDateString();
                        }
                        var promise = this.note.id
                            ? this.datacontext.training.updateCLENote(this.note)
                            : this.datacontext.training.createCLENote(this.note);
                        promise
                            .then(function (note) {
                            if (note) {
                                _this.common.$mdToast.showSimple('Note ' + (_this.note.id ? 'updated' : 'created'));
                                _this.common.$state.go('^.list', { cleId: _this.cleId });
                            }
                        })
                            .finally(function () { return _this.isSubmitting = false; });
                    };
                    Note.$inject = ['common', 'datacontext', 'interrupt'];
                    Note = __decorate([
                        app.Component('app.training.cle.activities', 'ceCLEActivitiesNote', {
                            templateUrl: 'app/training/continuing-legal-education/activities/note/note.html',
                            bindings: {
                                cleId: '<',
                                activity: '<',
                                caseOpenDate: '<'
                            }
                        })
                    ], Note);
                    return Note;
                }());
            })(activities = cle.activities || (cle.activities = {}));
        })(cle = training.cle || (training.cle = {}));
    })(training = app.training || (app.training = {}));
})(app || (app = {}));
var app;
(function (app) {
    var training;
    (function (training_19) {
        var training;
        (function (training) {
            var activities;
            (function (activities) {
                var TrainingActivityList = (function () {
                    function TrainingActivityList($mdDialog, common, datacontext) {
                        var _this = this;
                        this.$mdDialog = $mdDialog;
                        this.common = common;
                        this.datacontext = datacontext;
                        this.showFilters = false;
                        this.selectedActivityTypes = [];
                        this.activityFilter = {};
                        this.paging = {
                            page: 1,
                            pageSize: 100
                        };
                        this.refresh = function () {
                            _this.getActivities();
                        };
                        'ngInject';
                        'ngInject';
                    }
                    TrainingActivityList.prototype.$onInit = function () {
                        this.activityFilter.sourceId = this.trainingId;
                        this.activityFilter.source = 'Training';
                        this.getActivities();
                    };
                    TrainingActivityList.prototype.getActivities = function () {
                        var _this = this;
                        if (!this.filtersInitialized()) {
                            return;
                        }
                        this.activityFilter = __assign(__assign(__assign({}, this.activityFilter), this.paging), { activityTypes: this.selectedActivityTypes, q: this.noteFilter });
                        this.promise = this.datacontext.activities
                            .searchActivities(this.activityFilter)
                            .then(function (result) {
                            _this.activities = result;
                        });
                    };
                    TrainingActivityList.prototype.newActivity = function (targetEvent) {
                        var parent = angular.element(document.body);
                        this.$mdDialog.show({
                            parent: parent,
                            targetEvent: targetEvent,
                            clickOutsideToClose: true,
                            templateUrl: 'app/training/training/activities/new-activity/new-activity.html',
                            controller: 'NewTrainingActivityDialogController',
                            controllerAs: '$ctrl',
                            locals: {
                                trainingId: this.trainingId
                            }
                        });
                    };
                    TrainingActivityList.prototype.toggleFilters = function () {
                        this.showFilters = !this.showFilters;
                    };
                    TrainingActivityList.prototype.filtersInitialized = function () {
                        return this.activityTypeFilterInitialized;
                    };
                    TrainingActivityList.prototype.activityTypeFilterChanged = function (activityTypes) {
                        this.selectedActivityTypes = activityTypes;
                        this.activityTypeFilterInitialized = true;
                        this.getActivities();
                    };
                    TrainingActivityList.prototype.onEdit = function (activity) {
                        switch (activity.activityType) {
                            case 'Note':
                                this.common.$state.go('^.note', { activity: activity });
                                break;
                        }
                    };
                    TrainingActivityList.$inject = ['$mdDialog', 'common', 'datacontext'];
                    TrainingActivityList = __decorate([
                        app.Component('app.training.training.activities', 'ceTrainingActivityList', {
                            templateUrl: 'app/training/training/activities/activity-list/activity-list.html',
                            bindings: {
                                trainingId: '<'
                            }
                        })
                    ], TrainingActivityList);
                    return TrainingActivityList;
                }());
            })(activities = training.activities || (training.activities = {}));
        })(training = training_19.training || (training_19.training = {}));
    })(training = app.training || (app.training = {}));
})(app || (app = {}));
var app;
(function (app) {
    var training;
    (function (training_20) {
        var training;
        (function (training) {
            var activities;
            (function (activities_10) {
                var activities = [
                    {
                        icon: 'comment',
                        text: 'Note',
                        sref: '^.note',
                    }
                ];
                var NewTrainingActivityDialogController = (function () {
                    function NewTrainingActivityDialogController($mdDialog, common, trainingId) {
                        this.$mdDialog = $mdDialog;
                        this.common = common;
                        this.trainingId = trainingId;
                        'ngInject';
                        'ngInject';
                    }
                    NewTrainingActivityDialogController.prototype.$onInit = function () {
                        this.filteredActivities = activities;
                    };
                    NewTrainingActivityDialogController.prototype.selectActivity = function (item) {
                        this.common.$state.go(item.sref, { trainingId: this.trainingId });
                        this.$mdDialog.hide();
                    };
                    NewTrainingActivityDialogController.$inject = ['$mdDialog', 'common', 'trainingId'];
                    NewTrainingActivityDialogController = __decorate([
                        app.Controller('app.training.training.activities', 'NewTrainingActivityDialogController')
                    ], NewTrainingActivityDialogController);
                    return NewTrainingActivityDialogController;
                }());
            })(activities = training.activities || (training.activities = {}));
        })(training = training_20.training || (training_20.training = {}));
    })(training = app.training || (app.training = {}));
})(app || (app = {}));
var app;
(function (app) {
    var training;
    (function (training_21) {
        var training;
        (function (training) {
            var activities;
            (function (activities) {
                var Note = (function () {
                    function Note(common, datacontext, interrupt) {
                        this.common = common;
                        this.datacontext = datacontext;
                        this.interrupt = interrupt;
                        this.isSubmitting = false;
                        this.note = {};
                        'ngInject';
                        'ngInject';
                    }
                    Note.prototype.$onInit = function () {
                        this.maxDate = moment().toDate();
                        if (this.activity) {
                            this.note.id = this.activity.id;
                            this.note.trainingId = this.activity.sourceId;
                            this.note.title = this.activity.title;
                            this.note.description = this.activity.description;
                            this.note.activityDate = this.activity.activityDate;
                        }
                        else {
                            this.note.trainingId = this.trainingId;
                            this.note.activityDate = moment().toDate();
                        }
                    };
                    Note.prototype.submit = function () {
                        var _this = this;
                        this.isSubmitting = true;
                        if (typeof this.note.activityDate !== 'string') {
                            this.note.activityDate = this.note.activityDate.toDateString();
                        }
                        var promise = this.note.id
                            ? this.datacontext.training.updateTrainingNote(this.note)
                            : this.datacontext.training.createTrainingNote(this.note);
                        promise
                            .then(function (note) {
                            if (note) {
                                _this.common.$mdToast.showSimple('Note ' + (_this.note.id ? 'updated' : 'created'));
                                _this.common.$state.go('^.list', { trainingId: _this.trainingId });
                            }
                        })
                            .finally(function () { return _this.isSubmitting = false; });
                    };
                    Note.$inject = ['common', 'datacontext', 'interrupt'];
                    Note = __decorate([
                        app.Component('app.training.training.activities', 'ceTrainingActivitiesNote', {
                            templateUrl: 'app/training/training/activities/note/note.html',
                            bindings: {
                                trainingId: '<',
                                activity: '<',
                                caseOpenDate: '<'
                            }
                        })
                    ], Note);
                    return Note;
                }());
            })(activities = training.activities || (training.activities = {}));
        })(training = training_21.training || (training_21.training = {}));
    })(training = app.training || (app.training = {}));
})(app || (app = {}));
var app;
(function (app) {
    var admin;
    (function (admin) {
        var configuration;
        (function (configuration) {
            var CreateLawCategory = (function () {
                function CreateLawCategory(common, datacontext) {
                    this.common = common;
                    this.datacontext = datacontext;
                    this.isSubmitting = false;
                    this.data = { isActive: true };
                    'ngInject';
                    'ngInject';
                }
                CreateLawCategory.prototype.submit = function () {
                    var _this = this;
                    this.isSubmitting = true;
                    this.datacontext.admin.configuration
                        .createLawCategory(this.data)
                        .then(function (result) {
                        if (result) {
                            _this.common.$mdToast.showSimple('Law Category created');
                            _this.common.$state.go('^.lawCategoryDetails', { lawCategoryId: result.id });
                        }
                    }).finally(function () { return _this.isSubmitting = false; });
                };
                CreateLawCategory.$inject = ['common', 'datacontext'];
                CreateLawCategory = __decorate([
                    app.Component('app.admin.configuration', 'ceAdminCreateLawCategory', {
                        templateUrl: 'app/admin/modules/configuration/views/create-law-category/create-law-category.html',
                        bindings: {}
                    })
                ], CreateLawCategory);
                return CreateLawCategory;
            }());
        })(configuration = admin.configuration || (admin.configuration = {}));
    })(admin = app.admin || (app.admin = {}));
})(app || (app = {}));
var app;
(function (app) {
    var admin;
    (function (admin) {
        var configuration;
        (function (configuration) {
            var CreateTaskCategory = (function () {
                function CreateTaskCategory(common, datacontext) {
                    this.common = common;
                    this.datacontext = datacontext;
                    this.isSubmitting = false;
                    this.data = { isActive: true };
                    'ngInject';
                    'ngInject';
                }
                CreateTaskCategory.prototype.submit = function () {
                    var _this = this;
                    this.isSubmitting = true;
                    this.datacontext.admin.configuration
                        .createTaskCategory(this.data)
                        .then(function (result) {
                        if (result) {
                            _this.common.$mdToast.showSimple('Task Category created');
                            _this.common.$state.go('^.taskCategoryDetails', { taskCategoryId: result.id });
                        }
                    }).finally(function () { return _this.isSubmitting = false; });
                };
                CreateTaskCategory.$inject = ['common', 'datacontext'];
                CreateTaskCategory = __decorate([
                    app.Component('app.admin.configuration', 'ceAdminCreateTaskCategory', {
                        templateUrl: 'app/admin/modules/configuration/views/create-task-category/create-task-category.html',
                        bindings: {}
                    })
                ], CreateTaskCategory);
                return CreateTaskCategory;
            }());
        })(configuration = admin.configuration || (admin.configuration = {}));
    })(admin = app.admin || (app.admin = {}));
})(app || (app = {}));
var app;
(function (app) {
    var admin;
    (function (admin) {
        var configuration;
        (function (configuration) {
            var LawCategoryDetails = (function () {
                function LawCategoryDetails(common, datacontext) {
                    this.common = common;
                    this.datacontext = datacontext;
                    this.isSubmitting = false;
                    'ngInject';
                    'ngInject';
                }
                LawCategoryDetails.prototype.addSubcategory = function () {
                    this.lawCategory.subcategories.unshift({ isActive: true, isEditMode: true });
                };
                LawCategoryDetails.prototype.cancelSubcategory = function (index) {
                    var subcategory = this.lawCategory.subcategories[index];
                    subcategory.isEditMode = false;
                    if (!subcategory.id) {
                        this.lawCategory.subcategories.splice(index, 1);
                    }
                };
                LawCategoryDetails.prototype.saveSubcategory = function (subcategory) {
                    var _this = this;
                    this.isSubmitting = true;
                    var promise = subcategory.id
                        ? this.datacontext.admin.configuration.updateLawSubcategory(this.lawCategory.id, subcategory.id, subcategory)
                        : this.datacontext.admin.configuration.createLawSubcategory(this.lawCategory.id, subcategory);
                    promise
                        .then(function (result) {
                        subcategory.isEditMode = false;
                        _this.common.$mdToast.showSimple('Law Subcategory ' + (subcategory.id ? 'updated' : 'created'));
                        if (result) {
                            subcategory.id = result.id;
                        }
                    })
                        .finally(function () { return _this.isSubmitting = false; });
                };
                LawCategoryDetails.prototype.submit = function () {
                    var _this = this;
                    this.isSubmitting = true;
                    this.datacontext.admin.configuration
                        .updateLawCategory(this.lawCategory.id, this.lawCategory)
                        .then(function (result) {
                        if (result) {
                            _this.common.$mdToast.showSimple('Law Category updated');
                        }
                    }).finally(function () { return _this.isSubmitting = false; });
                };
                LawCategoryDetails.prototype.$onChanges = function () {
                    this.common.setTitle("".concat(this.lawCategory.name, " (Law Category)"));
                };
                LawCategoryDetails.$inject = ['common', 'datacontext'];
                LawCategoryDetails = __decorate([
                    app.Component('app.admin.configuration', 'ceAdminLawCategoryDetails', {
                        templateUrl: 'app/admin/modules/configuration/views/law-category-details/law-category-details.html',
                        bindings: {
                            lawCategory: '<'
                        }
                    })
                ], LawCategoryDetails);
                return LawCategoryDetails;
            }());
        })(configuration = admin.configuration || (admin.configuration = {}));
    })(admin = app.admin || (app.admin = {}));
})(app || (app = {}));
var app;
(function (app) {
    var admin;
    (function (admin) {
        var configuration;
        (function (configuration) {
            var LawCategoryList = (function () {
                function LawCategoryList() {
                }
                LawCategoryList = __decorate([
                    app.Component('app.admin.configuration', 'ceAdminLawCategoryList', {
                        templateUrl: 'app/admin/modules/configuration/views/law-category-list/law-category-list.html',
                        bindings: {
                            lawCategories: '<'
                        }
                    })
                ], LawCategoryList);
                return LawCategoryList;
            }());
        })(configuration = admin.configuration || (admin.configuration = {}));
    })(admin = app.admin || (app.admin = {}));
})(app || (app = {}));
var app;
(function (app) {
    var admin;
    (function (admin) {
        var configuration;
        (function (configuration) {
            var LegalTeamDetails = (function () {
                function LegalTeamDetails(common, datacontext) {
                    this.common = common;
                    this.datacontext = datacontext;
                    this.isSubmitting = false;
                    this.data = {};
                    'ngInject';
                    'ngInject';
                }
                LegalTeamDetails.prototype.$onInit = function () {
                    var _a;
                    var _b = (_a = this.legalTeam) !== null && _a !== void 0 ? _a : {}, _c = _b.isActive, isActive = _c === void 0 ? true : _c, name = _b.name;
                    this.data.name = name;
                    this.data.isActive = isActive;
                };
                LegalTeamDetails.prototype.submit = function () {
                    var _this = this;
                    this.isSubmitting = true;
                    var promise = this.legalTeam
                        ? this.datacontext.admin.configuration.updateLegalTeam(this.legalTeam.id, this.data)
                        : this.datacontext.admin.configuration.createLegalTeam(this.data);
                    promise
                        .then(function (result) {
                        _this.common.$mdToast.showSimple('Legal Team ' + (_this.legalTeam ? 'updated' : 'created'));
                        _this.common.$state.go('^.legalTeamList');
                    }).finally(function () { return _this.isSubmitting = false; });
                };
                LegalTeamDetails.$inject = ['common', 'datacontext'];
                LegalTeamDetails = __decorate([
                    app.Component('app.admin.configuration', 'ceAdminLegalTeamDetails', {
                        templateUrl: 'app/admin/modules/configuration/views/legal-team-details/legal-team-details.html',
                        bindings: {
                            legalTeam: '<'
                        }
                    })
                ], LegalTeamDetails);
                return LegalTeamDetails;
            }());
        })(configuration = admin.configuration || (admin.configuration = {}));
    })(admin = app.admin || (app.admin = {}));
})(app || (app = {}));
var app;
(function (app) {
    var admin;
    (function (admin) {
        var configuration;
        (function (configuration) {
            var LegalTeamList = (function () {
                function LegalTeamList() {
                }
                LegalTeamList = __decorate([
                    app.Component('app.admin.configuration', 'ceAdminLegalTeamList', {
                        templateUrl: 'app/admin/modules/configuration/views/legal-team-list/legal-team-list.html',
                        bindings: {
                            legalTeams: '<'
                        }
                    })
                ], LegalTeamList);
                return LegalTeamList;
            }());
        })(configuration = admin.configuration || (admin.configuration = {}));
    })(admin = app.admin || (app.admin = {}));
})(app || (app = {}));
var app;
(function (app) {
    var admin;
    (function (admin) {
        var configuration;
        (function (configuration) {
            var LookupTypeDetails = (function () {
                function LookupTypeDetails(common, datacontext) {
                    this.common = common;
                    this.datacontext = datacontext;
                    this.isSubmitting = false;
                    'ngInject';
                    'ngInject';
                }
                LookupTypeDetails.prototype.addLookup = function () {
                    this.lookupType.lookups.push({ isActive: true });
                };
                LookupTypeDetails.prototype.removeLookup = function (index) {
                    this.lookupType.lookups.splice(index, 1);
                };
                LookupTypeDetails.prototype.submit = function () {
                    var _this = this;
                    this.isSubmitting = true;
                    this.datacontext.admin.configuration
                        .updateLookupType(this.lookupType.id, this.lookupType)
                        .then(function (result) {
                        if (result) {
                            _this.common.$mdToast.showSimple('Lookup type updated');
                            _this.common.$state.go('^.lookupTypeList');
                        }
                    }).finally(function () { return _this.isSubmitting = false; });
                };
                LookupTypeDetails.prototype.$onChanges = function () {
                    this.common.setTitle("".concat(this.lookupType.id, " (Lookups)"));
                    if (!this.lookupType.lookups.length) {
                        this.addLookup();
                    }
                };
                LookupTypeDetails.$inject = ['common', 'datacontext'];
                LookupTypeDetails = __decorate([
                    app.Component('app.admin.configuration', 'aimsAdminLookupTypeDetails', {
                        templateUrl: 'app/admin/modules/configuration/views/lookup-type-details/lookup-type-details.html',
                        bindings: {
                            lookupType: '<'
                        }
                    })
                ], LookupTypeDetails);
                return LookupTypeDetails;
            }());
        })(configuration = admin.configuration || (admin.configuration = {}));
    })(admin = app.admin || (app.admin = {}));
})(app || (app = {}));
var app;
(function (app) {
    var admin;
    (function (admin) {
        var configuration;
        (function (configuration) {
            var TaskCategoryDetails = (function () {
                function TaskCategoryDetails(common, datacontext) {
                    this.common = common;
                    this.datacontext = datacontext;
                    this.isSubmitting = false;
                    'ngInject';
                    'ngInject';
                }
                TaskCategoryDetails.prototype.addTask = function () {
                    this.taskCategory.tasks.unshift({ isActive: true, isEditMode: true });
                };
                TaskCategoryDetails.prototype.cancelTask = function (index) {
                    var task = this.taskCategory.tasks[index];
                    task.isEditMode = false;
                    if (!task.id) {
                        this.taskCategory.tasks.splice(index, 1);
                    }
                };
                TaskCategoryDetails.prototype.saveTask = function (task) {
                    var _this = this;
                    this.isSubmitting = true;
                    var promise = task.id
                        ? this.datacontext.admin.configuration.updateTask(this.taskCategory.id, task.id, task)
                        : this.datacontext.admin.configuration.createTask(this.taskCategory.id, task);
                    promise
                        .then(function (result) {
                        task.isEditMode = false;
                        _this.common.$mdToast.showSimple('Task ' + (task.id ? 'updated' : 'created'));
                        if (result) {
                            task.id = result.id;
                        }
                    })
                        .finally(function () { return _this.isSubmitting = false; });
                };
                TaskCategoryDetails.prototype.submit = function () {
                    var _this = this;
                    this.isSubmitting = true;
                    this.datacontext.admin.configuration
                        .updateTaskCategory(this.taskCategory.id, this.taskCategory)
                        .then(function (result) {
                        if (result) {
                            _this.common.$mdToast.showSimple('Task Category updated');
                        }
                    }).finally(function () { return _this.isSubmitting = false; });
                };
                TaskCategoryDetails.prototype.$onChanges = function () {
                    this.common.setTitle("".concat(this.taskCategory.name, " (Task Category)"));
                };
                TaskCategoryDetails.$inject = ['common', 'datacontext'];
                TaskCategoryDetails = __decorate([
                    app.Component('app.admin.configuration', 'ceAdminTaskCategoryDetails', {
                        templateUrl: 'app/admin/modules/configuration/views/task-category-details/task-category-details.html',
                        bindings: {
                            taskCategory: '<'
                        }
                    })
                ], TaskCategoryDetails);
                return TaskCategoryDetails;
            }());
        })(configuration = admin.configuration || (admin.configuration = {}));
    })(admin = app.admin || (app.admin = {}));
})(app || (app = {}));
var app;
(function (app) {
    var admin;
    (function (admin) {
        var configuration;
        (function (configuration) {
            var LookupTypeList = (function () {
                function LookupTypeList() {
                }
                LookupTypeList = __decorate([
                    app.Component('app.admin.configuration', 'aimsAdminLookupTypeList', {
                        templateUrl: 'app/admin/modules/configuration/views/lookup-type-list/lookup-type-list.html',
                        bindings: {
                            lookupTypes: '<'
                        }
                    })
                ], LookupTypeList);
                return LookupTypeList;
            }());
        })(configuration = admin.configuration || (admin.configuration = {}));
    })(admin = app.admin || (app.admin = {}));
})(app || (app = {}));
var app;
(function (app) {
    var admin;
    (function (admin) {
        var configuration;
        (function (configuration) {
            var TaskCategoryList = (function () {
                function TaskCategoryList() {
                }
                TaskCategoryList = __decorate([
                    app.Component('app.admin.configuration', 'ceAdminTaskCategoryList', {
                        templateUrl: 'app/admin/modules/configuration/views/task-category-list/task-category-list.html',
                        bindings: {
                            taskCategories: '<'
                        }
                    })
                ], TaskCategoryList);
                return TaskCategoryList;
            }());
        })(configuration = admin.configuration || (admin.configuration = {}));
    })(admin = app.admin || (app.admin = {}));
})(app || (app = {}));
var app;
(function (app) {
    var admin;
    (function (admin) {
        var identityManagement;
        (function (identityManagement) {
            var Role = (function () {
                function Role(common, datacontext) {
                    var _this = this;
                    this.common = common;
                    this.datacontext = datacontext;
                    this.data = {};
                    this.isSubmitting = false;
                    this.addPermssion = function (id) {
                        if (!_.some(_this.data.permissions, function (p) { return p.permission === id; })) {
                            _this.data.permissions.push({ permission: id });
                        }
                        var permission = _.find(_this.permissions, function (p) { return p.id === id; });
                        _.forEach(permission.dependencies, _this.addPermssion);
                    };
                    this.removePermission = function (id) {
                        _.remove(_this.data.permissions, function (p) { return p.permission === id; });
                        _(_this.permissions)
                            .filter(function (p) { return p.dependencies && p.dependencies.indexOf(id) > -1; })
                            .map(function (p) { return p.id; })
                            .forEach(_this.removePermission);
                    };
                    'ngInject';
                    'ngInject';
                }
                Role.prototype.hasPermission = function (id) {
                    return _.some(this.data.permissions, function (p) { return p.permission === id; });
                };
                Role.prototype.togglePermission = function (id) {
                    if (!this.hasPermission(id)) {
                        this.addPermssion(id);
                    }
                    else {
                        this.removePermission(id);
                    }
                };
                Role.prototype.submit = function () {
                    var _this = this;
                    this.datacontext.admin.identityManagement
                        .createRole(this.data)
                        .then(function (result) {
                        if (result) {
                            _this.common.$mdToast.showSimple('Role created');
                            _this.common.$state.go('^');
                        }
                    }).finally(function () { return _this.isSubmitting = false; });
                };
                Role.prototype.$onInit = function () {
                    this.data.permissions = [];
                    this.permissionsByCategory = _(this.permissions)
                        .orderBy(function (p) { return p.category; })
                        .groupBy(function (p) { return p.category || 'No Category'; })
                        .value();
                };
                Role.$inject = ['common', 'datacontext'];
                Role = __decorate([
                    app.Component('app.admin.identityManagement', 'ceAdminCreateRole', {
                        templateUrl: 'app/admin/modules/identityManagement/views/create-role/create-role.html',
                        bindings: {
                            permissions: '<'
                        }
                    })
                ], Role);
                return Role;
            }());
        })(identityManagement = admin.identityManagement || (admin.identityManagement = {}));
    })(admin = app.admin || (app.admin = {}));
})(app || (app = {}));
var app;
(function (app) {
    var admin;
    (function (admin) {
        var identityManagement;
        (function (identityManagement) {
            var CreateUser = (function () {
                function CreateUser(common, datacontext) {
                    this.common = common;
                    this.datacontext = datacontext;
                    this.data = {};
                    this.isSubmitting = false;
                    'ngInject';
                    'ngInject';
                }
                CreateUser.prototype.$onInit = function () {
                    this.data.roleIds = [];
                    this.data.isActive = true;
                    this.data.timeslipTimerEnabled = true;
                    if (this.person) {
                        this.data.firstName = this.person.firstName;
                        this.data.lastName = this.person.lastName;
                        this.data.id = this.person.id;
                        this.data.existingPerson = true;
                    }
                };
                CreateUser.prototype.submit = function () {
                    var _this = this;
                    this.isSubmitting = true;
                    this.datacontext.admin.identityManagement
                        .createUser(this.data)
                        .then(function (result) {
                        if (result) {
                            _this.common.$mdToast.showSimple('User created');
                            _this.common.$state.go('^.^');
                        }
                    })
                        .finally(function () { return _this.isSubmitting = false; });
                };
                CreateUser.prototype.hasRole = function (roleId) {
                    return this.data.roleIds.indexOf(roleId) > -1;
                };
                CreateUser.prototype.toggleRole = function (roleId) {
                    var index = this.data.roleIds.indexOf(roleId);
                    if (index > -1) {
                        this.data.roleIds.splice(index, 1);
                    }
                    else {
                        this.data.roleIds.push(roleId);
                    }
                };
                CreateUser.$inject = ['common', 'datacontext'];
                CreateUser = __decorate([
                    app.Component('app.admin.identityManagement', 'ceAdminCreateUser', {
                        templateUrl: 'app/admin/modules/identityManagement/views/create-user/create-user.html',
                        bindings: {
                            person: '<',
                            roles: '<'
                        }
                    })
                ], CreateUser);
                return CreateUser;
            }());
        })(identityManagement = admin.identityManagement || (admin.identityManagement = {}));
    })(admin = app.admin || (app.admin = {}));
})(app || (app = {}));
var app;
(function (app) {
    var admin;
    (function (admin) {
        var identityManagement;
        (function (identityManagement) {
            var FindPerson = (function () {
                function FindPerson($scope, common, datacontext) {
                    var _this = this;
                    this.$scope = $scope;
                    this.common = common;
                    this.datacontext = datacontext;
                    this.params = {
                        page: 1,
                        pageSize: 5
                    };
                    this.refresh = function () {
                        _this.search();
                    };
                    this.search = function () {
                        if (!_this.params.q) {
                            _this.searchResults = null;
                            return;
                        }
                        return _this.datacontext.persons
                            .getPersons(_this.params)
                            .then(function (result) { return _this.searchResults = result; });
                    };
                    'ngInject';
                    'ngInject';
                }
                FindPerson.prototype.navigate = function (person) {
                    if (person.isUser) {
                        this.common.$state.go('^.^.userDetails', { userId: person.id });
                    }
                    else {
                        this.common.$state.go('^.create', { person: person });
                    }
                };
                FindPerson.$inject = ['$scope', 'common', 'datacontext'];
                FindPerson = __decorate([
                    app.Component('app.admin.identityManagement', 'ceAdminFindPerson', {
                        templateUrl: 'app/admin/modules/identityManagement/views/find-person/find-person.html',
                        bindings: {}
                    })
                ], FindPerson);
                return FindPerson;
            }());
        })(identityManagement = admin.identityManagement || (admin.identityManagement = {}));
    })(admin = app.admin || (app.admin = {}));
})(app || (app = {}));
var app;
(function (app) {
    var admin;
    (function (admin) {
        var identityManagement;
        (function (identityManagement) {
            var Role = (function () {
                function Role(common, datacontext) {
                    var _this = this;
                    this.common = common;
                    this.datacontext = datacontext;
                    this.isSubmitting = false;
                    this.addPermssion = function (id) {
                        if (!_.some(_this.data.permissions, function (p) { return p.permission === id; })) {
                            _this.data.permissions.push({ permission: id });
                        }
                        var permission = _.find(_this.permissions, function (p) { return p.id === id; });
                        _.forEach(permission.dependencies, _this.addPermssion);
                    };
                    this.deleteRoleCore = function () {
                        return _this.datacontext.admin.identityManagement
                            .deleteRole(_this.role.id)
                            .then(function (result) {
                            if (result) {
                                _this.common.$mdToast.showSimple('Role deleted');
                                _this.common.$state.go('^');
                            }
                        });
                    };
                    this.removePermission = function (id) {
                        _.remove(_this.data.permissions, function (p) { return p.permission === id; });
                        _(_this.permissions)
                            .filter(function (p) { return p.dependencies && p.dependencies.indexOf(id) > -1; })
                            .map(function (p) { return p.id; })
                            .forEach(_this.removePermission);
                    };
                    'ngInject';
                    'ngInject';
                }
                Role.prototype.deleteRole = function () {
                    this.common.confirmation.show({
                        message: "Delete ".concat(this.role.id, " role?"),
                        ok: 'Delete',
                        cancel: 'Keep'
                    }).then(this.deleteRoleCore);
                };
                Role.prototype.hasPermission = function (id) {
                    return _.some(this.data.permissions, function (p) { return p.permission === id; });
                };
                Role.prototype.togglePermission = function (id) {
                    if (!this.hasPermission(id)) {
                        this.addPermssion(id);
                    }
                    else {
                        this.removePermission(id);
                    }
                };
                Role.prototype.submit = function () {
                    var _this = this;
                    this.datacontext.admin.identityManagement
                        .updateRole(this.role.id, this.data)
                        .then(function (result) {
                        if (result) {
                            _this.common.$mdToast.showSimple('Role updated');
                            _this.common.$state.go('^');
                        }
                    }).finally(function () { return _this.isSubmitting = false; });
                };
                Role.prototype.$onInit = function () {
                    this.common.setTitle("".concat(this.role.id, " (Role)"));
                    var description = this.role.description;
                    this.data = {
                        description: description,
                        permissions: _.map(this.role.permissions, function (p) { return ({ permission: p.permission }); })
                    };
                    this.permissionsByCategory = _(this.permissions)
                        .orderBy(function (p) { return p.category; })
                        .groupBy(function (p) { return p.category || 'No Category'; })
                        .value();
                };
                Role.$inject = ['common', 'datacontext'];
                Role = __decorate([
                    app.Component('app.admin.identityManagement', 'ceAdminRoleDetails', {
                        templateUrl: 'app/admin/modules/identityManagement/views/role-details/role-details.html',
                        bindings: {
                            role: '<',
                            permissions: '<'
                        }
                    })
                ], Role);
                return Role;
            }());
        })(identityManagement = admin.identityManagement || (admin.identityManagement = {}));
    })(admin = app.admin || (app.admin = {}));
})(app || (app = {}));
var app;
(function (app) {
    var admin;
    (function (admin) {
        var identityManagement;
        (function (identityManagement) {
            var RoleList = (function () {
                function RoleList() {
                }
                RoleList = __decorate([
                    app.Component('app.admin.identityManagement', 'ceAdminRoleList', {
                        templateUrl: 'app/admin/modules/identityManagement/views/role-list/role-list.html',
                        bindings: {
                            roles: '<'
                        }
                    })
                ], RoleList);
                return RoleList;
            }());
        })(identityManagement = admin.identityManagement || (admin.identityManagement = {}));
    })(admin = app.admin || (app.admin = {}));
})(app || (app = {}));
var app;
(function (app) {
    var admin;
    (function (admin) {
        var identityManagement;
        (function (identityManagement) {
            var User = (function () {
                function User(common, datacontext) {
                    this.common = common;
                    this.datacontext = datacontext;
                    this.isSubmitting = false;
                    this.users = [];
                    'ngInject';
                    'ngInject';
                }
                User.prototype.submit = function () {
                    var _this = this;
                    this.isSubmitting = true;
                    if (this.data.userType !== 'Employee') {
                        this.data.employeeType = null;
                    }
                    this.datacontext.admin.identityManagement
                        .updateUser(this.user.id, this.data)
                        .then(function (result) {
                        if (result) {
                            _this.common.$mdToast.showSimple('User updated');
                            _this.common.$state.go('^');
                        }
                    })
                        .finally(function () { return _this.isSubmitting = false; });
                };
                User.prototype.hasRole = function (roleId) {
                    return this.data.roleIds.indexOf(roleId) > -1;
                };
                User.prototype.toggleRole = function (roleId) {
                    var index = this.data.roleIds.indexOf(roleId);
                    if (index > -1) {
                        this.data.roleIds.splice(index, 1);
                    }
                    else {
                        this.data.roleIds.push(roleId);
                    }
                };
                User.prototype.$onChanges = function () {
                    this.common.setTitle("".concat(this.user.fullName, " (User)"));
                    this.initializeData();
                };
                User.prototype.initializeData = function () {
                    var _this = this;
                    var _a = this.user, firstName = _a.firstName, lastName = _a.lastName, userName = _a.userName, userType = _a.userType, employeeType = _a.employeeType, isActive = _a.isActive, timeslipTimerEnabled = _a.timeslipTimerEnabled;
                    var roleIds = _.map(this.user.roles, function (r) { return r.id; });
                    this.data = {
                        firstName: firstName,
                        lastName: lastName,
                        userName: userName,
                        userType: userType,
                        employeeType: employeeType,
                        isActive: isActive,
                        timeslipTimerEnabled: timeslipTimerEnabled,
                        roleIds: roleIds
                    };
                    this.datacontext.entities
                        .getRelatedEntities(this.user.id)
                        .then(function (result) {
                        result = _.filter(result, function (r) { return r.relatedEntityId === _this.user.id; });
                        _.forEach(result, function (r) { return _this.users.push({ id: r.entityId, fullName: r.fullName, firstName: '', lastName: '' }); });
                    });
                };
                User.prototype.userSearch = function (searchText) {
                    return this.datacontext.users
                        .getUsers(searchText);
                };
                User.prototype.addUser = function (user) {
                    if (!user)
                        return;
                    this.userSearchText = null;
                    var exists = _.find(this.users, function (u) { return u.id === user.id; });
                    if (!exists) {
                        this.users.push(user);
                        this.datacontext.entities.createAssociation({ entityId: user.id, relatedEntityId: this.user.id, roles: ['Supervisor'] });
                    }
                };
                User.prototype.removeUser = function (user) {
                    _.remove(this.users, function (u) { return u.id === user.id; });
                    this.datacontext.entities.deleteAssociation(user.id, this.user.id);
                };
                User.$inject = ['common', 'datacontext'];
                User = __decorate([
                    app.Component('app.admin.identityManagement', 'ceAdminUserDetails', {
                        templateUrl: 'app/admin/modules/identityManagement/views/user-details/user-details.html',
                        bindings: {
                            user: '<',
                            roles: '<'
                        }
                    })
                ], User);
                return User;
            }());
        })(identityManagement = admin.identityManagement || (admin.identityManagement = {}));
    })(admin = app.admin || (app.admin = {}));
})(app || (app = {}));
var app;
(function (app) {
    var admin;
    (function (admin) {
        var identityManagement;
        (function (identityManagement) {
            var UserList = (function () {
                function UserList(datacontext) {
                    this.datacontext = datacontext;
                    this.showActiveOnly = true;
                    'ngInject';
                    'ngInject';
                }
                UserList.prototype.$onInit = function () {
                    this.getUsers();
                };
                UserList.prototype.getUsers = function () {
                    var _this = this;
                    this.datacontext.admin.identityManagement
                        .getUsers({ q: this.nameFilter, activeOnly: this.showActiveOnly })
                        .then(function (result) { return _this.users = result; });
                };
                UserList.prototype.filterActiveOnly = function () {
                    this.showActiveOnly = !this.showActiveOnly;
                    this.getUsers();
                };
                UserList.$inject = ['datacontext'];
                UserList = __decorate([
                    app.Component('app.admin.identityManagement', 'ceAdminUserList', {
                        templateUrl: 'app/admin/modules/identityManagement/views/user-list/user-list.html',
                        bindings: {}
                    })
                ], UserList);
                return UserList;
            }());
        })(identityManagement = admin.identityManagement || (admin.identityManagement = {}));
    })(admin = app.admin || (app.admin = {}));
})(app || (app = {}));
var app;
(function (app) {
    var admin;
    (function (admin) {
        var tools;
        (function (tools) {
            var AdminAgencyMerge = (function () {
                function AdminAgencyMerge(common, datacontext) {
                    var _this = this;
                    this.common = common;
                    this.datacontext = datacontext;
                    this.getCases = function (agency) {
                        if (!agency) {
                            return;
                        }
                        _this.datacontext.entities
                            .getCaseListing(agency.id, { pageSize: 50 })
                            .then(function (result) { return agency.cases = result.list; });
                    };
                    'ngInject';
                    'ngInject';
                }
                AdminAgencyMerge.prototype.agencySearch = function (searchText) {
                    var params = { q: searchText };
                    return this.datacontext.agencies
                        .getAgencies(params)
                        .then(function (results) {
                        return results.list;
                    });
                };
                AdminAgencyMerge.prototype.onWinnerChange = function (winner) {
                    this.setWinner(winner);
                };
                AdminAgencyMerge.prototype.setWinner = function (winner) {
                    this.winner = winner;
                    this.getCases(this.winner);
                };
                AdminAgencyMerge.prototype.onLoserChange = function (loser) {
                    this.setLoser(loser);
                };
                AdminAgencyMerge.prototype.setLoser = function (loser) {
                    this.loser = loser;
                    this.getCases(this.loser);
                };
                AdminAgencyMerge.prototype.onSubmit = function () {
                    var _this = this;
                    this.isSubmitting = true;
                    this.datacontext.agencies
                        .merge(this.winner.id, this.loser.id)
                        .then(function (result) {
                        if (!result) {
                            _this.isSubmitting = false;
                            return;
                        }
                        _this.common.$mdToast.showSimple('Merge completed');
                        _this.common.$state.go('^.select');
                    });
                };
                AdminAgencyMerge.$inject = ['common', 'datacontext'];
                AdminAgencyMerge = __decorate([
                    app.Component('app.admin.tools', 'ceAdminAgencyMerge', {
                        templateUrl: 'app/admin/modules/tools/entity-merge/views/agency-merge/agency-merge.html',
                    })
                ], AdminAgencyMerge);
                return AdminAgencyMerge;
            }());
        })(tools = admin.tools || (admin.tools = {}));
    })(admin = app.admin || (app.admin = {}));
})(app || (app = {}));
var app;
(function (app) {
    var admin;
    (function (admin) {
        var tools;
        (function (tools) {
            var AdminPersonMerge = (function () {
                function AdminPersonMerge(common, datacontext) {
                    var _this = this;
                    this.common = common;
                    this.datacontext = datacontext;
                    this.getCases = function (person) {
                        if (!person) {
                            return;
                        }
                        _this.datacontext.entities
                            .getCaseListing(person.id, { pageSize: 50 })
                            .then(function (result) { return person.cases = result.list; });
                    };
                    'ngInject';
                    'ngInject';
                }
                AdminPersonMerge.prototype.personSearch = function (searchText) {
                    var params = { q: searchText };
                    return this.datacontext.persons
                        .getPersons(params)
                        .then(function (results) {
                        return results.list;
                    });
                };
                AdminPersonMerge.prototype.onWinnerChange = function (winner) {
                    this.setWinner(winner);
                };
                AdminPersonMerge.prototype.setWinner = function (winner) {
                    this.winner = winner;
                    this.getCases(this.winner);
                };
                AdminPersonMerge.prototype.onLoserChange = function (loser) {
                    this.setLoser(loser);
                };
                AdminPersonMerge.prototype.setLoser = function (loser) {
                    this.loser = loser;
                    this.getCases(this.loser);
                };
                AdminPersonMerge.prototype.onSubmit = function () {
                    var _this = this;
                    this.isSubmitting = true;
                    this.datacontext.persons
                        .merge(this.winner.id, this.loser.id)
                        .then(function (result) {
                        if (!result) {
                            _this.isSubmitting = false;
                            return;
                        }
                        _this.common.$mdToast.showSimple('Merge completed');
                        _this.common.$state.go('^.select');
                    });
                };
                AdminPersonMerge.$inject = ['common', 'datacontext'];
                AdminPersonMerge = __decorate([
                    app.Component('app.admin.tools', 'ceAdminPersonMerge', {
                        templateUrl: 'app/admin/modules/tools/entity-merge/views/person-merge/person-merge.html',
                    })
                ], AdminPersonMerge);
                return AdminPersonMerge;
            }());
        })(tools = admin.tools || (admin.tools = {}));
    })(admin = app.admin || (app.admin = {}));
})(app || (app = {}));
var app;
(function (app) {
    var admin;
    (function (admin) {
        var tools;
        (function (tools) {
            var SelectEntityMergeType = (function () {
                function SelectEntityMergeType() {
                }
                SelectEntityMergeType = __decorate([
                    app.Component('app.admin.tools', 'ceAdminEntityMerge', {
                        templateUrl: 'app/admin/modules/tools/entity-merge/views/select-type/select-type.html',
                        bindings: {}
                    })
                ], SelectEntityMergeType);
                return SelectEntityMergeType;
            }());
        })(tools = admin.tools || (admin.tools = {}));
    })(admin = app.admin || (app.admin = {}));
})(app || (app = {}));
var app;
(function (app) {
    var admin;
    (function (admin) {
        var tools;
        (function (tools) {
            var AdminTimeslipsLock = (function () {
                function AdminTimeslipsLock(common, datacontext) {
                    this.common = common;
                    this.datacontext = datacontext;
                    'ngInject';
                    'ngInject';
                }
                AdminTimeslipsLock.prototype.$onInit = function () {
                    this.maxDate = moment().toDate();
                    this.getLastLock();
                };
                AdminTimeslipsLock.prototype.onSubmit = function () {
                    var _this = this;
                    this.isSubmitting = true;
                    var data = {
                        lockDate: this.newLockDate.toDateString()
                    };
                    this.datacontext.time
                        .lockTimeslips(data)
                        .then(function (result) {
                        _this.getLastLock();
                        _this.common.$mdToast.showSimple('Timeslips locked');
                    })
                        .finally(function () { return _this.isSubmitting = false; });
                };
                AdminTimeslipsLock.prototype.getLastLock = function () {
                    var _this = this;
                    this.datacontext.time
                        .getLastLock()
                        .then(function (result) {
                        _this.lastLock = result;
                        if (_this.lastLock.lockDate) {
                            _this.minDate = moment(_this.lastLock.lockDate).add(1, 'days').toDate();
                        }
                    });
                };
                AdminTimeslipsLock.$inject = ['common', 'datacontext'];
                AdminTimeslipsLock = __decorate([
                    app.Component('app.admin.tools', 'ceAdminTimeslipsLock', {
                        templateUrl: 'app/admin/modules/tools/timeslip-lock/views/lock-timeslips/lock-timeslips.html',
                    })
                ], AdminTimeslipsLock);
                return AdminTimeslipsLock;
            }());
        })(tools = admin.tools || (admin.tools = {}));
    })(admin = app.admin || (app.admin = {}));
})(app || (app = {}));
