namespace app.analysis {
   @Component('app.analysis', 'aimsAnalysisQueryList', {
      templateUrl: 'app/analysis/views/query-list/query-list.html',
      bindings: {
      }
   })
   class QueryList {
      static $inject = ['confirmation', 'datacontext'];
      constructor(
         private confirmation: IConfirmationService,
         private datacontext: data.IDataContextService) {
         'ngInject';
      }

      public queries: IQueryListItem[];
      public search: IQuerySearch;

      private $onInit() {
         this.getQueries();
      }

      private getQueries() {
         this.datacontext.analysis
            .getQueryList(this.search)
            .then((result) => this.queries = result);
      }

      public deleteQuery(id: string) {
         this.confirmation.show({
            message: 'Are you sure you want to delete this Query and related Dashboard items?',
            ok: 'DELETE',
            cancel: 'KEEP'
         }).then(() => this.deleteQueryCore(id));
      }

      private deleteQueryCore(id: string) {
         this.datacontext.analysis
            .deleteQuery(id)
            .then(() => this.getQueries());
      }
   }
}