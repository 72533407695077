namespace app.mail {
   @Controller('app.mail', 'MailFolderDialogController')
   class FolderDialogController {
      static $inject = ['common', '$mdDialog', 'datacontext', 'source', 'sourceId', 'involvementId', 'folder'];
      constructor(
         private common: core.ICommonService,
         private $mdDialog: angular.material.IDialogService,
         private datacontext: data.IDataContextService,
         private source: string,
         private sourceId: string,
         private involvementId: string,
         private folder: IMessageFolder) {
         'ngInject';
      }

      public data = {} as ISaveFolderData;
      public isSubmitting = false;

      public submit() {
         this.isSubmitting = true;

         const promise = this.data.id
            ? this.datacontext.mail.updateFolder(this.data)
            : this.datacontext.mail.createFolder(this.data);

         promise
            .then(() => this.$mdDialog.hide())
            .finally(() => this.isSubmitting = false);
      }

      private $onInit() {
         this.setData();
      }

      private setData() {
         this.data.involvementId = this.involvementId;
         this.data.source = this.source;
         this.data.sourceId = this.sourceId;

         if (this.folder) {
            this.data.id = this.folder.id;
            this.data.folderName = this.folder.subject;
         }
      }

      public cancel() {
         this.$mdDialog.cancel();
      }
   }
}