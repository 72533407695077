namespace app.analysis {
   export type DataPoint = [number, number];
   export type Series = DataPoint[];
   export type Tick = [number, string];

   export interface IAnalysisDataService {
      barSeries(chartData: IChartData[]): Series;
      barPercentageSeries(data: IChartData[]): Series;
      columnSeries(data: IChartData[]): Series;
      columnPercentageSeries(data: IChartData[]): Series;
      sum(data: IChartData[]): number;
      ticks(data: IChartData[]): Tick[];
   }

   @Service('app.analysis', 'analysisData')
   class AnalysisDataService implements IAnalysisDataService {
      public barSeries(chartData: IChartData[]) {
         return _.chain(chartData)
            .reverse()
            .map<DataPoint>((d, i) => [d.data, i])
            .value();
      }

      public barPercentageSeries(data: IChartData[]) {
         const total = this.sum(data);

         return _.chain(data)
            .map<DataPoint>((x, i) => [Math.round(x.data / total * 100), i])
            .value();
      }

      public columnSeries(chartData: IChartData[]) {
         return _.chain(chartData)
            .map<DataPoint>((d, i) => [i, d.data])
            .value();
      }

      public columnPercentageSeries(data: IChartData[]) {
         const total = this.sum(data);

         return _.chain(data)
            .map<DataPoint>((x, i) => [i, Math.round(x.data / total * 100)])
            .value();
      }

      public sum(data: IChartData[]) {
         return _.reduce(data, (sum: number, x: IChartData) => sum + x.data, 0);
      }

      public ticks(chartData: IChartData[]) {
         return _.chain(chartData)
            .map(x => x.group0)
            .uniq()
            .map<[number, string]>((t, i) => [i, t])
            .value();
      }
   }
}