namespace app.analysis {
   @Component('app.analysis', 'aimsAnalysisLineChart', {
      template: `<flot dataset="$ctrl.dataset" options="$ctrl.options"></flot>`,
      bindings: {
         data: '<',
         scale: '<'
      }
   })
   class LineChartComponent {
      static $inject = ['$element', 'common', 'analysisConstants'];
      constructor(
         private $element: angular.IRootElementService,
         private common: core.ICommonService,
         private analysisConstants: IAnalysisConstants) {
         'ngInject';
      }

      public data: IChartData[];
      public dataset: any[];
      public scale: TrendScale;
      public options = {
         grid: {
            borderColor: '#eee',
            borderWidth: 1,
            hoverable: true,
            backgroundColor: '#fcfcfc',
            labelMargin: 25
         },
         legend: {
            position: 'ne',
            noColumns: 0
         },
         tooltip: true,
         tooltipOpts: {
            content: (label, x, y, item) => {
               return `${moment(x).format('M/D/YY')} : ${y}`;
            },
            zIndex: 2000
         },
         xaxis: {
            mode: 'time',
            tickColor: '#fcfcfc',
            tickLength: 0,
            tickSize: [1, 'day'],
            autoscaleMargin: 0 // 0.02 to outsie ticks
         },
         yaxis: {
            tickColor: '#eee',
            tickDecimals: 0,
            autoscaleMargin: 0.2
         }
      };

      private $onChanges() {
         this.common.$timeout(() => {
            if (!(this.data && this.data.length)) return;

            this.dataset = _(this.data)
               .groupBy(d => d.group0)
               .toPairs()
               .map((x: [string, any[]], i) => {
                  return {
                     label: x[0],
                     color: this.analysisConstants.colorPalette[i],
                     data: _.map(x[1], d => [d.trend.getTime(), d.data])
                  };
               }).value();

            // TODO: Move to resize handler
            const min = _.minBy(this.data, d => d.trend).trend;
            const max = _.maxBy(this.data, d => d.trend).trend;

            let scale = this.scale || 'Day';
            if (scale === 'Week') scale = 'Day';

            const delta = moment(max).diff(min, scale);

            let maxTicks = Math.floor(this.$element.width() / 50);
            if (maxTicks === 0) maxTicks = 7;

            let tickSize = Math.floor(delta / maxTicks) + 1;
            this.options.xaxis.tickSize = [tickSize, scale.toLowerCase()];
         }, 50);
      }
   }
}