namespace app.core.security {
   const routes: routing.IStateExtended[] = [
      {
         name: '403',
         url: '/403',
         templateUrl: 'app/core/security/403/403.html',
         data: {
            bypassAuthentication: true
         }
      }
   ];

   const runner = (routingHelper: routing.IRoutingHelperService) => {
      'ngInject';
      routingHelper.configureRoutes(routes);
   };

   angular
      .module('app.core.security')
      .run(runner);
}