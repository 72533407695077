namespace app.funding {
   export interface IIllness {
      key: string;
      value: string;
   }

   @Component('app.funding', 'cePaimiIntakeForm', {
      templateUrl: 'app/funding/enrollments/paimi/paimi-intake-form.html',
      bindings: {
         intake: '<',
         onCancel: '&',
         onSubmit: '&'
      }
   })
   class PAIMIIntakeForm {
      static $inject = ['common', 'datacontext'];
      constructor(
         private common: core.ICommonService,
         private datacontext: data.IDataContextService) {
         'ngInject';
      }
      public intake = {} as IPAIMIIntake;
      public illnesses: IIllness[] = [];
      public approvedIllnesses: IIllness[] = [];
      public approvedAttorneySearchText: string;

      public showDischargeStatus() {
         if (this.intake.livingArrangement === 'Parental, Guardian or other Family Home' || this.intake.livingArrangement === 'Independent') {
            return true;
         }
         else {
            return false;
         }
      }

      public $onInit() {
         this.loadIllnesses();
      }

      public approvedAttorneySearch(searchText: string) {
         return this.datacontext.users
            .getUsers(searchText);
      }

      public setApprovedAttorney(attorney: app.users.IUser) {
         this.intake.approvedBy = attorney.fullName;
      }

      public mentalIllnessToggle(mentalIllness: string) {
         if (this.mentalIllnessExists(mentalIllness)) {
            _.pull(this.intake.mentalIllnesses, mentalIllness);
         }
         else {
            this.intake.mentalIllnesses.push(mentalIllness);
         }
      }

      public mentalIllnessExists(mentalIllness: string) {
         return _.includes(this.intake.mentalIllnesses, mentalIllness);
      }

      public approvedIllnessToggle(mentalIllness: string) {
         if (this.approvedIllnessExists(mentalIllness)) {
            _.pull(this.intake.approvedMentalIllnesses, mentalIllness);
         }
         else {
            this.intake.approvedMentalIllnesses.push(mentalIllness);
         }
      }

      public approvedIllnessExists(mentalIllness: string) {
         return _.includes(this.intake.approvedMentalIllnesses, mentalIllness);
      }

      private loadIllnesses() {
         this.illnesses.push({ key: 'Anxiety', value: 'Anxiety disorders' });
         this.illnesses.push({ key: 'Bipolar', value: 'Bipolar and related disorders' });
         this.illnesses.push({ key: 'Depressive', value: 'Depressive disorders' });
         this.illnesses.push({ key: 'Disruptive', value: 'Disruptive, impulse control, and conduct disorders' });
         this.illnesses.push({ key: 'Dissociative', value: 'Dissociative disorders' });
         this.illnesses.push({ key: 'Feeding', value: 'Feeding and eating disorders that are psychologically based' });
         this.illnesses.push({ key: 'Neurocognitive', value: 'Neurocognitive disorders (including Dementia/Alzheimer\'s)' });
         this.illnesses.push({ key: 'OCD', value: 'Obsessive-compulsive and related disorders' });
         this.illnesses.push({ key: 'Personality', value: 'Personality disorders' });
         this.illnesses.push({ key: 'Schizophrenia', value: 'Schizophrenia and related disorders' });
         this.illnesses.push({ key: 'Substance', value: 'Substance-related and addictive disorders (must be 18 or over)' });
         this.illnesses.push({ key: 'Trauma', value: 'Trauma and stressor-related disorders' });
         this.illnesses.push({ key: 'Other', value: 'Other mental disorders included in DSM (Somatic symptom and related disorders, elimination disorders, sleep-wake disorders, sexual dysfunctions, gender dysphoria, and paraphilic disorders)' });

         this.approvedIllnesses.push({ key: 'Neurodevelopmental', value: 'Neurodevelopmental disorders not covered by PADD or PAIR (this may included ADHD depending upon severity of behavior)' });
         this.approvedIllnesses.push({ key: 'Other', value: 'Other mental disorder (other specified or unspecified mental disorder due to another medical condition or other specified or unspecified mental disorder)(includes "emotional disturbance")' });
      }
   }
}