namespace app.analysis {
   @Component('app.analysis', 'aimsAnalysisChartForm', {
      templateUrl: 'app/analysis/views/create-chart/chart-form.html',
      bindings: {
         chart: '<',
         dataSource: '<'
      },
      require: {
         form: '^^form'
      }
   })
   class ChartEditorForm {
      static $inject = ['analysisConstants'];
      constructor(private analysisConstants: IAnalysisConstants) {
         'ngInject';
         this.chartTypes = analysisConstants.chartTypes;
      }

      public chart: IChart;
      public chartType: IChartType;
      public chartTypes: IChartType[];
      public onChartTypeChanged() {
         this.chart.metadata.type = this.chartType.name;

         if (this.chartType.trending) {
            this.chart.metadata.sortBy = 'Label';
            this.chart.metadata.sortDirection = 'Asc';
         } else {
            delete this.chart.metadata.trendField;
            delete this.chart.metadata.trendScale;
         }

         if (!this.chartType.percentages) {
            this.chart.metadata.showPercentages = false;
         }
         if (!this.chartType.totals) {
            this.chart.metadata.showTotals = false;
         }

         this.chart.metadata.groupBy.splice(this.chartType.groupByCount);
      }

      public subGroupByPlaceholder() {
         if (!this.chart || !this.chart.metadata) return;

         switch (this.chart.metadata.type) {
            case 'StackedBar': return 'Y - Axis';
            case 'StackedColumn': return 'X - Axis';
            case 'PivotTable': return 'Columns';
            default: return null;
         }
      }
   }
}