namespace app.core.routing {
   angular
      .module('app.core.routing')
      .directive('formInterrupt', formInterrupt);

   // Note: this directive does not support multiple active forms
   function formInterrupt(interrupt: IInterruptService) {
      'ngInject';
      return {
         require: 'form',
         link: (scope: angular.IScope, element: angular.IAugmentedJQuery, attrs: any, form: angular.IFormController) => {
            scope.$watch(() => form.$pristine, (value) => {
               if (value) {
                  interrupt.allowNavigation();
               }
               else {
                  interrupt.preventNavigation();
               }
            });
            scope.$on('$destroy', () => interrupt.allowNavigation());
         }
      };
   }
}