namespace app.admin.configuration {
   export function lookupTypeResolver($stateParams: angular.ui.IStateParamsService, datacontext: data.IDataContextService) {
      'ngInject';
      const { lookupTypeId } = $stateParams;
      return datacontext.admin.configuration.getLookupType(lookupTypeId);
   }

   export function lookupTypesResolver(datacontext: data.IDataContextService) {
      'ngInject';
      return datacontext.admin.configuration.getLookupTypes();
   }

   export function lawCategoriesResolver(datacontext: data.IDataContextService) {
      'ngInject';
      return datacontext.admin.configuration.getLawCategories(false);
   }

   export function lawCategoryResolver($stateParams: angular.ui.IStateParamsService, datacontext: data.IDataContextService) {
      'ngInject';
      const { lawCategoryId } = $stateParams;
      return datacontext.admin.configuration.getLawCategory(lawCategoryId, false);
   }

   export function legalTeamsResolver(datacontext: data.IDataContextService) {
      'ngInject';
      return datacontext.admin.configuration.getLegalTeams(false);
   }

   export function legalTeamResolver($stateParams: angular.ui.IStateParamsService, datacontext: data.IDataContextService) {
      'ngInject';
      const { legalTeamId } = $stateParams;
      return datacontext.admin.configuration.getLegalTeam(legalTeamId);
   }

   export function taskCategoriesResolver(datacontext: data.IDataContextService) {
      'ngInject';
      return datacontext.admin.configuration.getTaskCategories();
   }

   export function taskCategoryResolver($stateParams: angular.ui.IStateParamsService, datacontext: data.IDataContextService) {
      'ngInject';
      const { taskCategoryId } = $stateParams;
      return datacontext.admin.configuration.getTaskCategory(taskCategoryId);
   }
}