namespace app.requests {
   'use strict';

   export interface IRequestRepository {
      getRequestBasicInfo(id: string): angular.IPromise<IRequestBasicInfo>;
      getRequestInformation(id: string): angular.IPromise<IRequestInformation>;
      getRequestSummary(id: string): angular.IHttpPromise<IRequestSummary>;
      getRequestActivities(requestId: string): angular.IPromise<IRequestActivityModel[]>;
      getRequestListing(params: IGetRequestsParams): data.IPagedListPromise<IRequestListingView>;
      getRequestListingDownload(params: IGetRequestsParams): angular.IPromise<app.analysis.IDownloadDataResult>;
      getRequestStatus(id: string): angular.IHttpPromise<IRequestStatus>;
      getRequestToDo(id: string): angular.IPromise<common.IToDoItem[]>;
      createRequest(request: ICreateRequest): angular.IPromise<string>;
      close(activity: ICreateRequestActivity): angular.IHttpPromise<ICreateRequestActivity>;
      reopen(activity: ICreateRequestActivity): angular.IHttpPromise<ICreateRequestActivity>;
      conflictCheck(activity: ICreateRequestActivity): angular.IHttpPromise<ICreateRequestActivity>;
      createFollowUp(activity: ICreateRequestActivity): angular.IHttpPromise<ICreateRequestActivity>;
      updateFollowUp(activity: ICreateRequestActivity): angular.IHttpPromise<ICreateRequestActivity>;
      createReview(activity: ICreateRequestActivity): angular.IHttpPromise<ICreateRequestActivity>;
      updateReview(activity: ICreateRequestActivity): angular.IHttpPromise<ICreateRequestActivity>;
      createNote(activity: ICreateRequestActivity): angular.IHttpPromise<ICreateRequestActivity>;
      updateNote(activity: ICreateRequestActivity): angular.IHttpPromise<ICreateRequestActivity>;
      updateRequestInformation(info: IRequestInformation): angular.IPromise<IRequestInformation>;
      getActiveRequestCount(userIds: string[]): angular.IPromise<number>;
      deleteRequest(id: string): angular.IPromise<string>;
   }

   const baseUrl = 'api/requests';

   class RequestRepository implements IRequestRepository {
      static $inject = ['$http', 'exception'];
      constructor(
         private $http: angular.IHttpService,
         private exception: core.exception.IExceptionService,
      ) {
      }

      @ApiExecutor()
      public getRequestBasicInfo(id: string) {
         return this.$http.get<IRequestBasicInfo>(`${baseUrl}/${id}/basicinfo`) as any;
      }

      @ApiExecutor()
      public getActiveRequestCount(userIds: string[]) {
         return this.$http.get<number>(`${baseUrl}/activeRequestCount`, { params: { userIds: userIds } }) as any;
      }

      @ApiExecutor()
      public getRequestSummary(id: string) {
         return this.$http.get<IRequestSummary>(`${baseUrl}/${id}/summary`) as any;
      }

      @ApiExecutor()
      public getRequestInformation(id: string) {
         return this.$http.get<IRequestInformation>(`${baseUrl}/${id}/information`) as any;
      }

      @ApiExecutor()
      public getRequestListing(params: IGetRequestsParams) {
         return this.$http.get<data.IPagedListPromise<IRequestListingView>>(baseUrl, { params }) as any;
      }

      public getRequestListingDownload(params: IGetRequestsParams) {
         return this.$http.get<any>(`${baseUrl}/csv`, { params, responseType: 'arraybuffer' }).then(
            (response) => {
               return {
                  buffer: response.data,
                  contentType: response.headers()['content-type'],
                  fileName: response.headers()['content-disposition'].split(';')[1].split('=')[1].replace(/"/g, '')
               };
            }).catch(error => this.exception.catcher('XHR for getCsvData failed')(error)) as any;
      }

      @ApiExecutor()
      public getRequestActivities(requestId: string) {
         return this.$http.get<IRequestActivityModel[]>(`${baseUrl}/${requestId}/activities`) as any;
      }

      @ApiExecutor()
      public getRequestToDo(id: string) {
         return this.$http.get<common.IToDoItem[]>(`${baseUrl}/${id}/todo`) as any;
      }

      @ApiExecutor()
      public createRequest(request: ICreateRequest) {
         return this.$http.post<ICreateRequest>(baseUrl, request) as any;
      }

      @ApiExecutor()
      public close(activity: ICreateRequestActivity) {
         return this.$http.post<ICreateRequestActivity>(`${baseUrl}/${activity.requestId}/close`, activity) as any;
      }

      @ApiExecutor()
      public reopen(activity: ICreateRequestActivity) {
         return this.$http.post<ICreateRequestActivity>(`${baseUrl}/${activity.requestId}/reopen`, activity) as any;
      }

      @ApiExecutor()
      public conflictCheck(activity: ICreateRequestActivity) {
         return this.$http.post<ICreateRequestActivity>(`${baseUrl}/${activity.requestId}/conflictCheck`, activity) as any;
      }

      @ApiExecutor()
      public createFollowUp(activity: ICreateRequestActivity) {
         return this.$http.post<ICreateRequestActivity>(`${baseUrl}/${activity.requestId}/followUp`, activity) as any;
      }

      @ApiExecutor()
      public updateFollowUp(activity: ICreateRequestActivity) {
         return this.$http.put<ICreateRequestActivity>(`${baseUrl}/${activity.requestId}/followUp/${activity.id}`, activity) as any;
      }

      @ApiExecutor()
      public createReview(activity: ICreateRequestActivity) {
         return this.$http.post<ICreateRequestActivity>(`${baseUrl}/${activity.requestId}/review`, activity) as any;
      }

      @ApiExecutor()
      public updateReview(activity: ICreateRequestActivity) {
         return this.$http.put<ICreateRequestActivity>(`${baseUrl}/${activity.requestId}/review/${activity.id}`, activity) as any;
      }

      @ApiExecutor()
      public createNote(activity: ICreateRequestActivity) {
         return this.$http.post<ICreateRequestActivity>(`${baseUrl}/${activity.requestId}/notes`, activity) as any;
      }

      @ApiExecutor()
      public updateNote(activity: ICreateRequestActivity) {
         return this.$http.put<ICreateRequestActivity>(`${baseUrl}/${activity.requestId}/notes/${activity.id}`, activity) as any;
      }

      @ApiExecutor()
      public getRequestStatus(id: string) {
         return this.$http.get<IRequestStatus>(`${baseUrl}/${id}/status`) as any;
      }

      @ApiExecutor()
      public updateRequestInformation(info: IRequestInformation) {
         return this.$http.post<IRequestInformation>(`${baseUrl}/${info.id}/information`, info) as any;
      }

      public deleteRequest(id: string) {
         return this.$http.delete(`${baseUrl}/${id}`).catch(error => this.exception.catcher('Cannot Delete Request')(error)) as any;
      }
   }

   angular
      .module('app.requests')
      .service('requests.repository', RequestRepository);
}