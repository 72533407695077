namespace app.clientCases.closure {
   @Component('app.clientCases.closure', 'ceSurveyInformation', {
      templateUrl: 'app/client-cases/closure/survey-information/survey-information.html',
      bindings: {
         data: '<',
         onEdit: '&'
      }
   })
   class SurveyInformation {
   }
}