namespace app.clientCases.closure {
   @Component('app.clientCases.closure', 'ceClientCaseClosureInformation', {
      templateUrl: 'app/client-cases/closure/closure-information/closure-information.html',
      bindings: {
         data: '<',
         onEdit: '&'
      }
   })
   class ClientCaseClosureInformation {
   }
}