namespace app.preferences.favorites {
   @Component('app.preferences.favorites', 'ceFavoritesButton', {
      template: `
<md-button aria-label="Favorites"
           class="md-icon-button"
           ng-click="$ctrl.open($event)">
   <md-tooltip>Favorites</md-tooltip>
   <md-icon>star</md-icon>
</md-button>`
   })
   class FavoritesButtonComponent {
      static $inject = ['$mdSidenav', 'common'];
      constructor(
         private $mdSidenav: angular.material.ISidenavService,
         private common: core.ICommonService) {
         'ngInject';
      }

      public open() {
         const sidenav = this.$mdSidenav('favorites');

         if (sidenav.isOpen()) {
            sidenav.close();
         } else {
            sidenav.open();
         }
      }
   }
}