namespace app.funding {
   @Component('app.funding', 'ceNdbedpOutcomeForm', {
      templateUrl: 'app/funding/enrollments/ndbedp/ndbedp-outcome-form.html',
      bindings: {
         outcome: '<',
         onCancel: '&',
         onSubmit: '&'
      }
   })
   class NDBEDPOutcomeForm {

      static $inject = ['datacontext'];
      constructor(
         private datacontext: data.IDataContextService, ) {
         'ngInject';
      }

      public servicesProvided: app.config.ILookup[];
      public outcome: ITEPOutcome;

      public $onInit = () => {
         this.getServicesProvided();
      }

      public getServicesProvided() {
         return this.datacontext.config.lookups.getLookupType('ndbedp-services-provided')
            .then(lookup => { this.servicesProvided = lookup.lookups; });
      }
   }
}