namespace app.requests.overview {
   @Component('app.requests.overview', 'ceRequestOverview', {
      templateUrl: 'app/requests/overview/overview.html',
      bindings: {
         request: '<'
      }
   })
   class RequestDetails {
      static $inject = ['common', 'datacontext'];
      constructor(
         private common: core.ICommonService,
         private datacontext: data.IDataContextService) {
         'ngInject';
      }

      public $onInit() {
      }

      private request: IRequestBasicInfo;
   }
}