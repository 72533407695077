namespace app.requests {
   @Component('app.requests', 'ceRequestsCreateRequest', {
      templateUrl: 'app/requests/create-request/create-request.html',
      bindings: {
         entity: '<',
         entityType: '@'
      }
   })
   class CreateRequest {
      static $inject = ['common', 'datacontext'];
      constructor(
         private common: core.ICommonService,
         private datacontext: data.IDataContextService) {
         'ngInject';
      }

      public entity: app.entities.IEntityBasicInfo;
      public entityType: string;
      public roles: config.ILookup[];
      public selectedRoles: config.ILookup[] = [];
      public isSubmitting = false;
      public request = {} as ICreateRequest;
      public legalTeams: app.config.ILegalTeam[];
      public maxDate: Date;

      private $onInit() {
         this.maxDate = moment().toDate();
         this.request.requestedAt = moment().seconds(0).milliseconds(0).toDate();
         this.request.requestMethod = 'Voicemail';
         angular.element(document).ready(() => {
            // required to set focus to datepicker input field
            angular.element(document).find('#date')['0'].children[0].children[0];
         });

         this.getLegalTeams();

         if (this.entity) {
            this.getRoles();
         }
      }

      private getLegalTeams() {
         this.datacontext.config.legalTeams
            .getLegalTeams(true)
            .then(result => this.legalTeams = result);
      }

      private transformChip(chip) {
         if (angular.isObject(chip)) {
            return chip;
         }
         return { name: chip, type: 'new' };
      }

      private querySearch(query) {
         let results = query ? _.filter(this.roles, (r: any) => {
            return query === '*' || r.value.toLowerCase().indexOf(query) > -1;
         }) : [];
         return results;
      }

      private getRoles = () => {
         const lookupTypeId: string = `involvement-${this.entityType}-role`;

         return this.datacontext.config.lookups.getLookupType(lookupTypeId).then(
            result => {
               this.roles = result.lookups;
            });
      }

      public dateKeypress(event: KeyboardEvent) {
         if (event.keyCode === 32 /*space*/) {
         }
      }

      public submit() {
         this.isSubmitting = true;

         this.datacontext.requests
            .createRequest(this.request)
            .then(id => {
               if (id) {
                  if (this.entity) {
                     const roles: string[] = _.map(this.selectedRoles, 'value');

                     const data = {
                        entityId: this.entity.id,
                        involvementId: id,
                        roles: roles
                     } as involvements.IInvolvementEntity;

                     this.datacontext.involvements
                        .createEntity(data)
                        .then(() => {
                           this.common.$mdToast.showSimple('Request created');
                           this.common.$state.go('app.requests', { requestId: id });
                        });
                  } else {
                     this.common.$mdToast.showSimple('Request created');
                     this.common.$state.go('app.requests', { requestId: id });
                  }
               }
            })
            .finally(() => this.isSubmitting = false);
      }
   }
}