namespace app.core.routing {

   const setupHandler = ($transitions: any, $rootScope: IRootScopeService) => {
      'ngInject';

      $rootScope.previousState = {};

      $transitions.onSuccess({}, transition => {
         const fromState = transition.$from();
         const fromParams = transition.params('from');
         $rootScope.previousState.name = fromState.name;
         $rootScope.previousState.params = fromParams;
      });
   };

   angular
      .module('app.core.routing')
      .run(setupHandler);
}