namespace app.activities {
   export const ActivityConfig: ActivityConfig[] = [
      {
         activityType: 'CaseAssessmentMeeting',
         reportName: 'SingleNote',
         canEdit: true
      },
      {
         activityType: 'CaseReview',
         reportName: 'SingleNote',
         canEdit: true
      },
      {
         activityType: 'FollowUp',
         reportName: 'SingleNote',
         canEdit: true
      },
      {
         activityType: 'Note',
         reportName: 'SingleNote',
         canEdit: true
      },
      {
         activityType: 'MonitoringIssue',
         reportName: null,
         canEdit: true
      },
      {
         activityType: 'MonitoringMeeting',
         reportName: null,
         canEdit: true
      },
      {
         activityType: 'MonitoringContact',
         reportName: null,
         canEdit: true
      },
      {
         activityType: 'Representation',
         reportName: null,
         canEdit: true
      },
      {
         activityType: 'CovidVaccine',
         reportName: null,
         canEdit: true
      },
      {
         activityType: 'StatusChange',
         reportName: null,
         canEdit: false
      },
   ];
}