namespace app.projects {
   @Component('app.projects', 'ceCreateProject', {
      templateUrl: 'app/projects/create-project/create-project.html',
      bindings: {
         fundingPrograms: '<'
      }
   })
   class CreateProject {
      static $inject = ['common', 'datacontext'];
      constructor(
         private common: core.ICommonService,
         private datacontext: data.IDataContextService) {
         'ngInject';
      }

      public project = {} as ICreateProject;
      public isSubmitting: boolean;
      public maxDate: Date;

      private $onInit() {
         this.maxDate = moment().toDate();
         this.project.openDate = moment().toDate();
      }

      public submit() {
         this.isSubmitting = true;

         if (typeof this.project.openDate !== 'string') {
            this.project.openDate = this.project.openDate.toDateString();
         }

         this.datacontext.projects
            .createProject(this.project)
            .then(project => {
               if (project) {
                  this.common.$mdToast.showSimple('Project created');
                  this.common.$state.go('app.projects.overview', { projectId: project });
               }
            })
            .finally(() => this.isSubmitting = false);
      }
   }
}