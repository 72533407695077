namespace app.entities.details {
   @Component('app.entities.details', 'ceEntityAddressForm', {
      templateUrl: 'app/entities/details/address/address-form.html',
      bindings: {
         address: '<',
         onCancel: '&',
         onSubmit: '&'
      }
   })
   @Inject('common', 'addressHelper')
   class AddressForm {
      static $inject = ['common', 'addressHelper'];
      constructor(
         private common: core.ICommonService,
         private addressHelper: utils.IAddressHelperService) {
      }

      public data: IEntityAddress;
      public address: IEntityAddress;

      public onPlaceSelected(place: any) {
         this.common.$timeout(() => {
            angular.extend(this.data, this.addressHelper.mapFromGooglePlaceResult(place));
         });
      }

      private $onChanges() {
         this.data = angular.extend({}, this.address);
      }
   }
}