namespace app.core.security {
   interface IScope extends angular.IScope {
      roles: RoleId[];
   }

   const directive: angular.IDirectiveFactory = (auth: IAuthorizationService, $rootScope: core.IRootScopeService) => {
      'ngInject';
      return {
         restrict: 'A',
         scope: {
            roles: '=amRoles'
         },
         link: (scope: IScope, elem: angular.IAugmentedJQuery) => {
            if (auth.checkRoles(...scope.roles)) {
               elem.show();
            } else {
               elem.hide();
            }
         }
      };
   };

   angular
      .module('app.core.security')
      .directive('amRoles', directive);
}