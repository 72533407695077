namespace app.admin.identityManagement {
   @Component('app.admin.identityManagement', 'ceAdminFindPerson', {
      templateUrl: 'app/admin/modules/identityManagement/views/find-person/find-person.html',
      bindings: {
      }
   })
   class FindPerson {
      static $inject = ['$scope', 'common', 'datacontext'];
      constructor(
         private $scope: angular.IScope,
         private common: core.ICommonService,
         private datacontext: data.IDataContextService) {
         'ngInject';
      }

      public searchResults: data.IPagedList<persons.IPerson>;
      public params = {
         page: 1,
         pageSize: 5
      } as persons.IGetPersonsParams;

      private navigate(person: persons.IPersonBasicInfo) {
         if (person.isUser) {
            this.common.$state.go('^.^.userDetails', { userId: person.id });
         } else {
            this.common.$state.go('^.create', { person: person });
         }
      }

      public refresh = () => {
         this.search();
      }

      private search = () => {
         if (!this.params.q) {
            this.searchResults = null;
            return;
         }

         return this.datacontext.persons
            .getPersons(this.params)
            .then(result => this.searchResults = result);
      }
   }
}