namespace app.clientCases.funding {
   const routes: core.routing.IStateExtended[] = [
      {
         name: 'app.clientCases.funding',
         url: '/funding',
         template: '<ce-funding involvement-id="$resolve.case.involvementId" layout="column" flex></ce-funding>',
         redirectTo: 'app.clientCases.funding.summary',
         data: { title: 'Client Case Funding Summary' }
      },
      {
         name: 'app.clientCases.funding.summary',
         url: '/summary',
         template: '<ce-funding-summary involvement-id="$resolve.case.involvementId" layout="column" flex></ce-funding-summary>',
         data: { title: 'Client Case Funding' }
      },
      {
         name: 'app.clientCases.funding.eligibility',
         url: '/eligibility',
         template: '<ui-view layout flex></ui-view>',
         redirectTo: 'app.clientCases.funding.eligibility.list',
         data: { title: 'Client Case Funding' }
      },
      {
         name: 'app.clientCases.funding.eligibility.list',
         url: '/list',
         template: `<am-funding-eligibility-view
                    [involvement-id]="$resolve.case.involvementId"
                    source-type="clientCases"
                    layout="column" flex></am-funding-eligibility-view>`,
         data: { title: 'Client Case Funding' }
      },
      {
         name: 'app.clientCases.funding.eligibility.form',
         url: '/form',
         template: '<am-funding-form-eligibility involvement-id="$resolve.case.involvementId" source="Client Case" layout="column" flex></am-funding-form-eligibility>',
         data: { title: 'Eligibility Form' }
      },
      {
         name: 'app.clientCases.funding.eligibility.cap',
         url: '/cap',
         params: { fundingProgramId: null },
         resolve: { fundingProgramId: ['$stateParams', p => p.fundingProgramId] },
         template: '<ce-cap-eligibility involvement-id="$resolve.case.involvementId" funding-program-id="$resolve.fundingProgramId" layout="column" flex></ce-cap-eligibility>',
         data: { title: 'CAP Eligibility' }
      },
      {
         name: 'app.clientCases.funding.eligibility.paat',
         url: '/paat',
         params: { fundingProgramId: null },
         resolve: { fundingProgramId: ['$stateParams', p => p.fundingProgramId] },
         template: '<ce-paat-eligibility involvement-id="$resolve.case.involvementId" funding-program-id="$resolve.fundingProgramId" layout="column" flex></ce-paat-eligibility>',
         data: { title: 'PAAT Eligibility' }
      },
      {
         name: 'app.clientCases.funding.eligibility.pabss',
         url: '/pabss',
         params: { fundingProgramId: null },
         resolve: { fundingProgramId: ['$stateParams', p => p.fundingProgramId] },
         template: '<ce-pabss-eligibility involvement-id="$resolve.case.involvementId" funding-program-id="$resolve.fundingProgramId" layout="column" flex></ce-pabss-eligibility>',
         data: { title: 'PABSS Eligibility' }
      },
      {
         name: 'app.clientCases.funding.eligibility.padd',
         url: '/padd',
         params: { fundingProgramId: null },
         resolve: { fundingProgramId: ['$stateParams', p => p.fundingProgramId] },
         template: '<ce-padd-eligibility involvement-id="$resolve.case.involvementId" funding-program-id="$resolve.fundingProgramId" layout="column" flex></ce-padd-eligibility>',
         data: { title: 'PADD Eligibility' }
      },
      {
         name: 'app.clientCases.funding.eligibility.paimi',
         url: '/paimi',
         params: { fundingProgramId: null },
         resolve: { fundingProgramId: ['$stateParams', p => p.fundingProgramId] },
         template: '<ce-paimi-eligibility involvement-id="$resolve.case.involvementId" funding-program-id="$resolve.fundingProgramId" layout="column" flex></ce-paimi-eligibility>',
         data: { title: 'PAIMI Eligibility' }
      },
      {
         name: 'app.clientCases.funding.eligibility.pair',
         url: '/pair',
         params: { fundingProgramId: null },
         resolve: { fundingProgramId: ['$stateParams', p => p.fundingProgramId] },
         template: '<ce-pair-eligibility involvement-id="$resolve.case.involvementId" funding-program-id="$resolve.fundingProgramId" layout="column" flex></ce-pair-eligibility>',
         data: { title: 'PAIR Eligibility' }
      },
      {
         name: 'app.clientCases.funding.eligibility.patbi',
         url: '/patbi',
         params: { fundingProgramId: null },
         resolve: { fundingProgramId: ['$stateParams', p => p.fundingProgramId] },
         template: '<ce-patbi-eligibility involvement-id="$resolve.case.involvementId" funding-program-id="$resolve.fundingProgramId" layout="column" flex></ce-patbi-eligibility>',
         data: { title: 'PATBI Eligibility' }
      },
      {
         name: 'app.clientCases.funding.enrollment',
         url: '/primary',
         template: '<ui-view layout flex></ui-view>',
         redirectTo: 'app.clientCases.funding.enrollment.list'
      },
      {
         name: 'app.clientCases.funding.enrollment.list',
         url: '/list',
         template: '<ce-primary-funding-source-listing involvement-id="$resolve.case.involvementId" layout="column" source="Client Case" flex></ce-primary-funding-source-listing>',
         data: { title: 'Primary List' }
      },
      {
         name: 'app.clientCases.funding.enrollment.primary',
         url: '/primary',
         template: '<ce-create-primary-funding involvement-id="$resolve.case.involvementId" layout="column" flex></ce-create-primary-funding>',
         data: { title: 'Primary Funding' }
      },
      {
         name: 'app.clientCases.funding.enrollment.updatePrimary',
         url: '/updateprimary',
         params: { enrollment: null },
         resolve: { enrollment: ['$stateParams', p => p.enrollment] },
         template: '<ce-edit-primary-funding enrollment="$resolve.enrollment" layout="column" flex></ce-edit-primary-funding>',
         data: { title: 'Update Funding' }
      },
      {
         name: 'app.clientCases.funding.enrollment.pavaIntake',
         url: '/pava-intake/{enrollmentId}',
         template: '<ce-pava-intake enrollment-id="$resolve.enrollmentId" layout="column" flex></ce-pava-intake>',
         params: { enrollmentId: null },
         resolve: { enrollmentId: ['$stateParams', p => p.enrollmentId] },
         data: { title: 'PAVA Intake' }
      },
      {
         name: 'app.clientCases.funding.enrollment.pavaOutcome',
         url: '/pava-outcome/{enrollmentId}',
         template: '<ce-pava-outcome enrollment-id="$resolve.enrollmentId" layout="column" flex></ce-pava-outcome>',
         params: { enrollmentId: null },
         resolve: { enrollmentId: ['$stateParams', p => p.enrollmentId] },
         data: { title: 'PAVA Outcome' }
      },
      {
         name: 'app.clientCases.funding.enrollment.paddIntake',
         url: '/padd-intake/{enrollmentId}',
         template: '<ce-padd-intake enrollment-id="$resolve.enrollmentId" layout="column" flex></ce-padd-intake>',
         params: { enrollmentId: null },
         resolve: { enrollmentId: ['$stateParams', p => p.enrollmentId] },
         data: { title: 'PADD Intake' }
      },
      {
         name: 'app.clientCases.funding.enrollment.paddOutcome',
         url: '/padd-outcome/{enrollmentId}',
         template: '<ce-padd-outcome enrollment-id="$resolve.enrollmentId" layout="column" flex></ce-padd-outcome>',
         params: { enrollmentId: null },
         resolve: { enrollmentId: ['$stateParams', p => p.enrollmentId] },
         data: { title: 'PADD Outcome' }
      },
      {
         name: 'app.clientCases.funding.enrollment.pabssIntake',
         url: '/pabss-intake/{enrollmentId}',
         template: '<ce-pabss-intake enrollment-id="$resolve.enrollmentId" layout="column" flex></ce-pabss-intake>',
         params: { enrollmentId: null },
         resolve: { enrollmentId: ['$stateParams', p => p.enrollmentId] },
         data: { title: 'PABSS Intake' }
      },
      {
         name: 'app.clientCases.funding.enrollment.pabssOutcome',
         url: '/pabss-outcome/{enrollmentId}',
         template: '<ce-pabss-outcome enrollment-id="$resolve.enrollmentId" layout="column" flex></ce-pabss-outcome>',
         params: { enrollmentId: null },
         resolve: { enrollmentId: ['$stateParams', p => p.enrollmentId] },
         data: { title: 'PABSS Outcome' }
      },
      {
         name: 'app.clientCases.funding.enrollment.capIntake',
         url: '/cap-intake/{enrollmentId}',
         template: '<ce-cap-intake enrollment-id="$resolve.enrollmentId" layout="column" flex></ce-cap-intake>',
         params: { enrollmentId: null },
         resolve: { enrollmentId: ['$stateParams', p => p.enrollmentId] },
         data: { title: 'CAP Intake' }
      },
      {
         name: 'app.clientCases.funding.enrollment.capOutcome',
         url: '/cap-outcome/{enrollmentId}',
         template: '<ce-cap-outcome enrollment-id="$resolve.enrollmentId" layout="column" flex></ce-cap-outcome>',
         params: { enrollmentId: null },
         resolve: { enrollmentId: ['$stateParams', p => p.enrollmentId] },
         data: { title: 'CAP Outcome' }
      },
      {
         name: 'app.clientCases.funding.enrollment.paatIntake',
         url: '/paat-intake/{enrollmentId}',
         template: '<ce-paat-intake enrollment-id="$resolve.enrollmentId" layout="column" flex></ce-paat-intake>',
         params: { enrollmentId: null },
         resolve: { enrollmentId: ['$stateParams', p => p.enrollmentId] },
         data: { title: 'PAAT Intake' }
      },
      {
         name: 'app.clientCases.funding.enrollment.paatOutcome',
         url: '/paat-outcome/{enrollmentId}',
         template: '<ce-paat-outcome enrollment-id="$resolve.enrollmentId" layout="column" flex></ce-paat-outcome>',
         params: { enrollmentId: null },
         resolve: { enrollmentId: ['$stateParams', p => p.enrollmentId] },
         data: { title: 'PAAT Outcome' }
      },
      {
         name: 'app.clientCases.funding.enrollment.pairIntake',
         url: '/pair-intake/{enrollmentId}',
         template: '<ce-pair-intake enrollment-id="$resolve.enrollmentId" layout="column" flex></ce-pair-intake>',
         params: { enrollmentId: null },
         resolve: { enrollmentId: ['$stateParams', p => p.enrollmentId] },
         data: { title: 'PAIR Intake' }
      },
      {
         name: 'app.clientCases.funding.enrollment.pairOutcome',
         url: '/pair-outcome/{enrollmentId}',
         template: '<ce-pair-outcome enrollment-id="$resolve.enrollmentId" layout="column" flex></ce-pair-outcome>',
         params: { enrollmentId: null },
         resolve: { enrollmentId: ['$stateParams', p => p.enrollmentId] },
         data: { title: 'PAIR Outcome' }
      },
      {
         name: 'app.clientCases.funding.enrollment.patbiIntake',
         url: '/patbi-intake/{enrollmentId}',
         template: '<ce-patbi-intake enrollment-id="$resolve.enrollmentId" layout="column" flex></ce-patbi-intake>',
         params: { enrollmentId: null },
         resolve: { enrollmentId: ['$stateParams', p => p.enrollmentId] },
         data: { title: 'PATBI Intake' }
      },
      {
         name: 'app.clientCases.funding.enrollment.patbiOutcome',
         url: '/patbi-outcome/{enrollmentId}',
         template: '<ce-patbi-outcome enrollment-id="$resolve.enrollmentId" layout="column" flex></ce-patbi-outcome>',
         params: { enrollmentId: null },
         resolve: { enrollmentId: ['$stateParams', p => p.enrollmentId] },
         data: { title: 'PATBI Outcome' }
      },
      {
         name: 'app.clientCases.funding.enrollment.paimiIntake',
         url: '/paimi-intake/{enrollmentId}',
         template: '<ce-paimi-intake enrollment-id="$resolve.enrollmentId" layout="column" flex></ce-paimi-intake>',
         params: { enrollmentId: null },
         resolve: { enrollmentId: ['$stateParams', p => p.enrollmentId] },
         data: { title: 'PAIMI Intake' }
      },
      {
         name: 'app.clientCases.funding.enrollment.paimiOutcome',
         url: '/paimi-outcome/{enrollmentId}',
         template: '<ce-paimi-outcome enrollment-id="$resolve.enrollmentId" layout="column" flex></ce-paimi-outcome>',
         params: { enrollmentId: null },
         resolve: { enrollmentId: ['$stateParams', p => p.enrollmentId] },
         data: { title: 'PAIMI Outcome' }
      },
      {
         name: 'app.clientCases.funding.secondary',
         url: '/secondary',
         template: '<ui-view flex></ui-view>',
         redirectTo: 'app.clientCases.funding.secondary.view',
         data: { title: 'Secondary Funding' }
      },
      {
         name: 'app.clientCases.funding.secondary.edit',
         url: '/edit',
         params: { fundingProgramId: null },
         resolve: { fundingProgramId: ['$stateParams', p => p.fundingProgramId] },
         template: '<ce-edit-secondary-funding involvement-id="$resolve.case.involvementId" funding-program-id="$resolve.fundingProgramId" layout="column" flex></ce-edit-secondary-funding>',
         data: { title: 'Secondary Funding' }
      },
      {
         name: 'app.clientCases.funding.secondary.view',
         url: '/views',
         template: '<ce-secondary-funding-view involvement-id="$resolve.case.involvementId" layout="column" flex></ce-secondary-funding-view>',
         data: { title: 'Secondary Funding' }
      },
      {
         name: 'app.clientCases.funding.enrollment.deaf services (tep)Intake',
         url: '/deaf services (tep)Intake/{enrollmentId}',
         template: '<ce-tep-intake enrollment-id="$resolve.enrollmentId" layout="column" flex></ce-tep-intake>',
         params: { enrollmentId: null },
         resolve: { enrollmentId: ['$stateParams', p => p.enrollmentId] },
         data: { title: 'TEP Intake' }
      },
      {
         name: 'app.clientCases.funding.enrollment.deaf services (tep)Outcome',
         url: '/deaf services (tep)Outcome/{enrollmentId}',
         template: '<ce-tep-outcome enrollment-id="$resolve.enrollmentId" layout="column" flex></ce-tep-outcome>',
         params: { enrollmentId: null },
         resolve: { enrollmentId: ['$stateParams', p => p.enrollmentId] },
         data: { title: 'TEP Outcome' }
      },
      {
         name: 'app.clientCases.funding.enrollment.deaf services (ndbedp)Intake',
         url: '/deaf services (ndbedp)Intake/{enrollmentId}',
         template: '<ce-ndbedp-intake enrollment-id="$resolve.enrollmentId" layout="column" flex></ce-ndbedp-intake>',
         params: { enrollmentId: null },
         resolve: { enrollmentId: ['$stateParams', p => p.enrollmentId] },
         data: { title: 'NDBEDP Intake' }
      },
      {
         name: 'app.clientCases.funding.enrollment.deaf services (ndbedp)Outcome',
         url: '/deaf services (ndbedp)Outcome/{enrollmentId}',
         template: '<ce-ndbedp-outcome enrollment-id="$resolve.enrollmentId" layout="column" flex></ce-ndbedp-outcome>',
         params: { enrollmentId: null },
         resolve: { enrollmentId: ['$stateParams', p => p.enrollmentId] },
         data: { title: 'NDBEDP Outcome' }
      }
   ];

   const runner = (routingHelper: core.routing.IRoutingHelperService) => {
      'ngInject';
      routingHelper.configureRoutes(routes);
   };

   angular
      .module('app.clientCases.funding')
      .run(runner);
}