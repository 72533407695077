namespace app.filters {
   @Component('app.filters', 'ceLegalTeamFilter', {
      templateUrl: 'app/filters/legalTeam/legalTeam-filter.html',
      bindings: {
         onChange: '&',
         legalTeams: '<',
         selectedLegalTeams: '<',
      }
   })
   class LegalTeamFilter {
      static $inject = ['common', 'datacontext'];
      constructor(
         private common: core.ICommonService,
         private datacontext: data.IDataContextService) {
         'ngInject';
      }

      private name: string;
      private searchText: string;
      private legalTeams: config.ILegalTeam[];
      private selectedLegalTeams: config.ILegalTeam[];
      private onChange: ({ }) => {};
      private enabled: boolean;

      private $onInit() {
         this.legalTeams = this.legalTeams || [];
         this.selectedLegalTeams = this.selectedLegalTeams || [];
         this.getLegalTeams();
      }

      private toggleState() {
         this.enabled = !this.enabled;
         if (this.enabled) {
            angular.element('#legalTeam0').focus();
         }
         this.changed();
      }

      public getLegalTeams() {
         return this.datacontext.config.legalTeams.getLegalTeams(false).then(
            legalTeams => {
               this.legalTeams.push(...legalTeams);
               this.legalTeams = _.sortBy(this.legalTeams, ['name']);
               this.changed();
            });
      }

      public changed() {
         if (this.enabled) {
            this.onChange({ legalTeams: this.selectedLegalTeams });
         }
         else {
            this.onChange({ legalTeams: [] });
         }
      }

      public isLegalTeamToggled(legalTeam: config.ILegalTeam) {
         return this.selectedLegalTeams.indexOf(legalTeam) > -1;
      }

      public toggleLegalTeam(legalTeam: config.ILegalTeam) {
         const exists = _.find(this.selectedLegalTeams, lt => lt.id === legalTeam.id);
         if (exists) {
            _.remove(this.selectedLegalTeams, lt => lt.id === legalTeam.id);
         }
         else {
            this.selectedLegalTeams.push(legalTeam);
         }

         this.changed();
      }
   }
}