namespace app.involvements {
   @Component('app.involvements', 'ceEntityList', {
      templateUrl: 'app/involvements/entities/entity-listing/entity-list.html',
      bindings: {
         entities: '<',
         totalEntities: '<',
         onDelete: '&'
      }
   })
   class EntityList {
   }
}