namespace app.projects {
   export interface IProjectCalendarState {
      dateRange: moment.Range;
      viewType: string;
      viewDate: moment.Moment;
      showCompleted: boolean;
      calendars: ICalendar[];
   }

   export interface IProjectCalendarStateService {
      state: IProjectCalendarState;
      save();
      update(state: any);
   }

   class ProjectCalendarStateService implements IProjectCalendarStateService {
      constructor(private $localStorage: angular.storage.IStorageService) {
         'ngInject';

         if ($localStorage.calendarState) {
            delete $localStorage.calendarState.dateRange;
            delete $localStorage.calendarState.viewType;
            delete $localStorage.calendarState.viewDate;
         }

         this.loadState();
      }

      public state = {} as IProjectCalendarState;

      public save() {
         this.$localStorage['projectCalendarState'] = this.state;
      }

      public update(state) {
         angular.extend(this.state, state);
         angular.extend(this.$localStorage['projectCalendarState'], state);
      }

      private loadState = () => {
         if (this.$localStorage['projectCalendarState']) {
            angular.extend(this.state, this.$localStorage['projectCalendarState']);
         } else {
            this.$localStorage['projectCalendarState'] = this.state;
         }

         if (this.state.dateRange) {
            this.state.dateRange = moment.range(this.state.dateRange.start, this.state.dateRange.end);
         }
      }
   }

   angular
      .module('app.projects')
      .service('projectCalendarState', ProjectCalendarStateService);
}