namespace app.funding {
   @Component('app.funding', 'cePavaSystemicOutcomeInfo', {
      templateUrl: 'app/funding/enrollments/pava/systemic/pava-outcome-info.html',
      bindings: {
         outcome: '<',
         onEdit: '&'
      }
   })
   class PAVASystemicOutcomeInfo {
      static $inject = ['common', 'datacontext'];
      constructor(
         private common: core.ICommonService,
         private datacontext: data.IDataContextService) {
         'ngInject';
      }
   }
}