namespace app.clientCases.details {
   @Component('app.clientCases.details', 'ceClientCaseDetails', {
      templateUrl: 'app/client-cases/details/details.html',
      bindings: {
         case: '<',
         paramCaseName: '<',
         paramPovertyLevel: '<',
         paramAtPoverty: '<',
         paramRaceSubCategory: '<'
      }
   })
   class ClientCaseDetails {
      static $inject = ['$mdDialog', 'common', 'datacontext'];
      constructor(
         private $mdDialog: angular.material.IDialogService,
         private common: core.ICommonService,
         private datacontext: data.IDataContextService) {
         'ngInject';
      }

      private case: IClientCaseBasicInfoView;
      private caseInformation: IClientCaseInformation;
      private clientInformation: persons.IDemographics;
      private callerInformation: persons.IDemographics;
      private editCaseInformation: boolean = false;
      private editSpecialEducationInformation: boolean = false;
      private editClientInformation: boolean = false;
      private editCallerInformation: boolean = false;

      public $onInit() {
         let promise = null;

         promise = this.getCase();
         promise.then(
            () => {
               this.getCaseInformation();
               this.getClientInformation();
               this.getCallerInformation();
            });
      }

      public getCase() {
         return this.datacontext.clientCases.getClientCaseBasicInfoView(this.case.id)
            .then(cc => this.case = cc);
      }

      public updateCaseInformation(result: IClientCaseInformation) {
         this.caseInformation = result;
         this.editCaseInformation = false;
         this.editSpecialEducationInformation = false;
      }

      public updateClientInformation(result: persons.IDemographics) {
         this.clientInformation = result;
         this.editClientInformation = false;
      }
      public updateCallerInformation(result: persons.IDemographics) {
         this.callerInformation = result;
         this.editCallerInformation = false;
      }
      public getCaseInformation() {
         this.datacontext.clientCases.getClientCaseInformation(this.case.id).then(
            result => this.caseInformation = result
         );
      }
      public getClientInformation() {
         if (this.case.clientPersonId) {
            this.datacontext.persons.getDemographics(this.case.clientPersonId).then(
               result => this.clientInformation = result
            );
         }
      }
      public getCallerInformation() {
         if (this.case.requesterPersonId && this.case.clientPersonId !== this.case.requesterPersonId) {
            this.datacontext.persons.getDemographics(this.case.requesterPersonId).then(
               result => this.callerInformation = result
            );
         }
      }
   }
}