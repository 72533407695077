namespace app.alerts {
   @Component('app.alerts', 'ceNotificationsList', {
      templateUrl: 'app/alerts/views/notifications-list/notifications-list.html',
      bindings: {
         notifications: '<'
      }
   })
   class NotificationsList {
      static $inject = ['$scope', 'common', 'datacontext'];
      constructor(
         private $scope: angular.IScope,
         private common: core.ICommonService,
         private datacontext: data.IDataContextService
      ) {
         'ngInject';
      }

      public badge: number | string;
      public count: number;
      public loading = false;
      public notifications: data.IPagedList<INotification>;
      public pagination = { page: 1, limit: 25, order: '' };
      public selectedNotifications: INotification[] = [];
      public query: IGetNotificationsParams = { unclearedOnly: true };

      public cancelSelection() {
         this.selectedNotifications = [];
      }

      public clearNotifications(notifications: INotification[]) {
         const count = notifications.length;
         const promises: angular.IPromise<any>[] = [];
         angular.forEach(notifications, n => promises.push(this.clear(n)));
         this.common.$q.all<any>(promises).then(() => {
            this.common.$mdToast.showSimple(`${count} notification(s) cleared`);
         });
      }

      private clear = (notification: INotification) => {
         return this.datacontext.alerts.clearNotification(notification.id).then(result => {
            if (result) {
               this.removeNotification(notification);
            }
         });
      };

      private removeNotification = (notification: INotification) => {
         const { id } = notification;
         _.remove(this.selectedNotifications, { id });
         _.remove(this.notifications.list, { id });
         this.notifications.count = this.notifications.count - 1;
      };

      private activate = () => {
         this.query.page = this.notifications.page;
         this.query.pageSize = this.notifications.pageSize;
      };

      private getNotifications = () => {
         this.loading = true;
         return this.datacontext.alerts
            .getNotifications(this.query)
            .then(result => {
               this.notifications = result;
            })
            .finally(() => this.loading = false);
      };
   }
}