namespace app.documentManagement {
   @Component('app.documentManagement', 'ceDocumentsList', {
      templateUrl: 'app/documentManagement/views/documents-list/documents-list.html',
      bindings: {
         involvementId: '<',
         source: '@',
         sourceId: '<'
      }
   })
   class DocumentsList {
      static $inject = ['$mdDialog', 'common', 'datacontext', 'blobHelper'];
      constructor(
         private $mdDialog: angular.material.IDialogService,
         private common: core.ICommonService,
         private datacontext: data.IDataContextService,
         private blobHelper: utils.IBlobHelperService) {
         'ngInject';
      }

      public documents: data.IPagedList<IDocumentFolder>;
      public involvementId: string;
      public source: string;
      public sourceId: string;
      private folderId: string;
      public folderName: string;
      public showInvolvementDocuments: boolean;
      public nameFilter: string;
      public promise: angular.IPromise<any>;
      public paging: data.IPagedListParams = {
         page: 1,
         pageSize: 100
      };

      private openDocumentDialog(targetEvent: MouseEvent) {
         const parent = angular.element(document.body);
         return this.$mdDialog.show({
            parent,
            targetEvent,
            fullscreen: true,
            templateUrl: 'app/documentManagement/new-document/new-document-dialog.html',
            controller: 'NewDocumentDialogController',
            controllerAs: '$ctrl',
            locals: {
               involvementId: this.involvementId,
               source: this.source,
               sourceId: this.sourceId,
               folderId: this.folderId
            }
         })
            .catch((error) => { if (error) throw error; })
            .finally(() => { this.getDocuments(); });
      }

      public download(documentId: string) {
         this.datacontext.documentManagement
            .downloadDocument(documentId)
            .then(result => this.blobHelper.open(result));
      }

      public $onInit() {
         this.getDocuments();
      }

      public refresh = () => {
         this.getDocuments();
      }

      public deleteDocument(document: IDocumentFolder) {
         this.common.confirmation.show({
            title: 'Delete Document',
            message: 'Are you sure you want to delete this document?',
            ok: 'Delete',
            cancel: 'Keep'
         }).then(() => {
            this.datacontext.documentManagement
               .deleteDocument(document.id)
               .then(result => {
                  if (result) {
                     _.remove(this.documents.list, document);
                     this.documents.count--;
                  }
               });
         }).catch(angular.noop);
      }

      public getInvolvementDocuments() {
         this.showInvolvementDocuments = !this.showInvolvementDocuments;
         this.getDocuments();
      }

      public showFolder(document: IDocumentFolder) {
         if (document) {
            this.folderId = document.id;
            this.folderName = document.name;
         } else {
            this.folderId = null;
            this.folderName = null;
         }

         this.getDocuments();
      }

      public createFolder(targetEvent: MouseEvent) {
         this.openFolderDialog(targetEvent, null);
      }

      public renameFolder(targetEvent: MouseEvent, folder: IDocumentFolder) {
         this.openFolderDialog(targetEvent, folder);
      }

      private openFolderDialog(targetEvent: MouseEvent, folder: IDocumentFolder) {
         const parent = angular.element(document.body);
         return this.$mdDialog.show({
            parent,
            targetEvent,
            fullscreen: true,
            templateUrl: 'app/documentManagement/views/folder-dialog/folder-dialog.html',
            controller: 'DocumentFolderDialogController',
            controllerAs: '$ctrl',
            locals: {
               folder: folder,
               involvementId: this.involvementId,
               source: this.source,
               sourceId: this.sourceId
            }
         })
            .then(() => { this.getDocuments(); })
            .catch((error) => { if (error) throw error; });
      }

      private getDocuments = () => {
         const params: IGetFoldersParams = {
            involvementId: this.involvementId,
            source: this.showInvolvementDocuments ? null : this.source,
            sourceId: this.showInvolvementDocuments ? null : this.sourceId,
            folderId: this.folderId,
            q: this.nameFilter,
            ...this.paging
         };

         this.promise = this.datacontext.documentManagement
            .getFolders(params)
            .then(result => this.documents = result);
      }

      public changeDocumentFolder(targetEvent: MouseEvent, doc: IDocumentFolder) {
         const parent = angular.element(document.body);
         return this.$mdDialog.show({
            parent,
            targetEvent,
            fullscreen: true,
            templateUrl: 'app/documentManagement/views/change-document-folder-dialog/change-document-folder-dialog.html',
            controller: 'ChangeDocumentFolderDialogController',
            controllerAs: '$ctrl',
            locals: {
               document: doc
            }
         })
            .then(() => { this.getDocuments(); })
            .catch((error) => { if (error) throw error; });
      }

      public moveDocument(targetEvent: MouseEvent, doc: IDocumentFolder) {
         const parent = angular.element(document.body);
         return this.$mdDialog.show({
            parent,
            targetEvent,
            fullscreen: true,
            templateUrl: 'app/documentManagement/views/move-document-dialog/move-document-dialog.html',
            controller: 'MoveDocumentDialogController',
            controllerAs: '$ctrl',
            locals: {
               document: doc
            }
         })
            .then(() => { this.getDocuments(); })
            .catch((error) => { if (error) throw error; });
      }

      public copyDocument(targetEvent: MouseEvent, doc: IDocumentFolder) {
         const parent = angular.element(document.body);
         return this.$mdDialog.show({
            parent,
            targetEvent,
            fullscreen: true,
            templateUrl: 'app/documentManagement/views/copy-document-dialog/copy-document-dialog.html',
            controller: 'CopyDocumentDialogController',
            controllerAs: '$ctrl',
            locals: {
               document: doc
            }
         })
            .then(() => { this.getDocuments(); })
            .catch((error) => { if (error) throw error; });
      }

      public renameDocument(targetEvent: MouseEvent, doc: IDocumentFolder) {
         const parent = angular.element(document.body);
         return this.$mdDialog.show({
            parent,
            targetEvent,
            fullscreen: true,
            templateUrl: 'app/documentManagement/views/rename-document-dialog/rename-document-dialog.html',
            controller: 'RenameDocumentDialogController',
            controllerAs: '$ctrl',
            locals: {
               document: doc
            }
         })
            .then(() => { this.getDocuments(); })
            .catch((error) => { if (error) throw error; });
      }

      public deleteFolder(folder: IDocumentFolder) {
         this.common.confirmation.show({
            title: 'Delete Folder',
            message: 'Are you sure you want to delete this folder?',
            ok: 'Delete',
            cancel: 'Keep'
         }).then(() => {
            this.datacontext.documentManagement
               .deleteFolder(folder.id)
               .then(result => {
                  if (result) {
                     this.refresh();
                  }
               });
         }).catch(angular.noop);
      }
   }
}