namespace app.utils {
   export interface IHeightFilter {
      (value: string): string;
   }

   angular
      .module('app.utils')
      .filter('height', () => (value: string) => {
         const totalInches = +value;

         if (isNaN(totalInches)) return;

         const feet = Math.floor(totalInches / 12);
         const inches = totalInches % 12;

         return `${feet}' ${inches}"`;
      });
}