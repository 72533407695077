namespace app.clientCases.closure {
   @Component('app.clientCases.closure', 'ceClientCaseClosure', {
      templateUrl: 'app/client-cases/closure/closure.html',
      bindings: {
         case: '<'
      }
   })
   class ClientCaseClosure {
      static $inject = ['$mdDialog', 'common', 'datacontext'];
      constructor(
         private $mdDialog: angular.material.IDialogService,
         private common: core.ICommonService,
         private datacontext: data.IDataContextService) {
         'ngInject';
      }

      private case: IClientCaseBasicInfoView;
      private closureInformation: IClientCaseClosureInformation;
      private surveyInformation: IClientCaseSurveyInformation;
      private specialEducation: IClosureSpecialEducation;
      private editClosureInformation: boolean = false;
      private editSurveyInformation: boolean = false;
      private editSpecialEducation: boolean = false;

      public $onInit() {
         this.GetClosureInformation();
         this.GetSurveyInformation();
         this.GetSpecialEducationInformation();
      }

      public updateClosureInformation(result: IClientCaseClosureInformation) {
         this.closureInformation = result;
         this.editClosureInformation = false;
      }

      public GetClosureInformation() {
         this.datacontext.clientCases.getClosureInformation(this.case.id).then(
            result => this.closureInformation = result
         );
      }

      public updateSurveyInformation(result: IClientCaseSurveyInformation) {
         this.surveyInformation = result;
         this.editSurveyInformation = false;
      }

      public GetSurveyInformation() {
         this.datacontext.clientCases.getSurveyInformation(this.case.id).then(
            result => this.surveyInformation = result
         );
      }

      public updateClosureSpecialEducation(result: IClosureSpecialEducation) {
         this.specialEducation = result;
         this.editSpecialEducation = false;
      }

      public GetSpecialEducationInformation() {
         this.datacontext.clientCases.getSpecialEducation(this.case.id).then(
            result => this.specialEducation = result
         );
      }
   }
}