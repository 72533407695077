namespace app.analysis {
   @Component('app.analysis', 'aimsAnalysisPivotTableChart', {
      templateUrl: 'app/analysis/components/chart/pivot-table-chart.html',
      bindings: {
         data: '<'
      }
   })
   class PivotTableChartComponent {
      static $inject = ['common', 'analysisConstants'];
      constructor(
         private common: core.ICommonService,
         private analysisConstants: IAnalysisConstants) {
         'ngInject';
      }
      public data: IChartData[];
      public dataset: any[];

      public grandTotal() {
         return _(this.dataset).map('total').sum();
      }

      private $onChanges = () => {
         this.common.$timeout(() => {
            const yaxis = _(this.data).map('group0').uniq().value();
            const xaxis = _(this.data).map('group1').uniq().value();

            this.dataset = _.map(yaxis, y => {
               return {
                  label: moment(y).isValid() ? moment(y).format('M/D/YY') : y,
                  total: _(this.data).filter(d => d.group0 === y).map('data').sum(),
                  data: _.map(xaxis, x => {
                     return {
                        label: moment(x).isValid() ? moment(x).format('M/D/YY') : x,
                        value: _(this.data).filter(d => d.group0 === y && d.group1 === x).map('data').sum(),
                        total: _(this.data).filter(d => d.group1 === x).map('data').sum()
                     };
                  })
               };
            });
         });
      };
   }
}