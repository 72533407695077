namespace app.clientCases {
    @Component('app.clientCases', 'ceClientCaseList', {
        templateUrl: 'app/client-cases/client-case-listing/client-case-list.html',
        bindings: {
            cases: '<',
            refresh: '&',
            paging: '<',
            promise: '<',
            showDeleteDialog: '&'
        }
    })
    class ClientCaseList { }
}