namespace app.search {
   @Component('app.search', 'ceSearch', {
      templateUrl: 'app/search/search-bar/search-bar.html'
   })
   class SearchBar {
      static $inject = ['common', 'ageFilter', 'datacontext'];
      constructor(
         private common: core.ICommonService,
         private ageFilter: app.utils.IAgeFilter,
         private datacontext: data.IDataContextService) {
         'ngInject';
      }

      public searchText: string;

      public advancedSearch() {
         this.common.$state.go('app.search');
      }

      public handleSelectedItemChange(entity: entities.IEntityBasicInfo) {
         if (!entity) return;

         if (entity.entityType === 'Person') {
            this.common.$state.go('app.person', { personId: entity.id });
         }
         else {
            this.common.$state.go('app.agency', { agencyId: entity.id });
         }

         this.searchText = null;
      }

      public search(searchText: string) {
         let searchResults = [];

         let people = this.datacontext.persons
            .getPersons({ q: this.searchText, pageSize: 10 });

         people.then((result) => {
            let results: any[] = result.list;
            _.forEach(results, (r) => {
               if (r.dateOfBirth) {
                  r.age = this.ageFilter(r.dateOfBirth);
                  r.formattedDoB = moment(r.dateOfBirth).format('MM/DD/YYYY');
               }
               r.entityType = 'Person';
               r.sortBy = r.lastName + ' ' + r.firstName;
            });
            searchResults.push(...results);
         });

         let agencies = this.datacontext.agencies
            .getAgencies({ q: this.searchText, pageSize: 10 });

         agencies.then((result) => {
            let results: any[] = result.list;
            _.forEach(results, (r) => {
               r.entityType = 'Agency';
               r.sortBy = r.fullName;
            });
            searchResults.push(...results);
         });

         return this.common.$q.all([people, agencies]).then(() => {
            return _.sortBy(searchResults, 'sortBy');
         });

      }
   }
}