namespace app.core.routing {
   export function redirectHandler(transition: any) {
      const $state: angular.ui.IStateService = transition.injector().get('$state');
      const preferences: IPreferenceStateService = transition.injector().get('preferences');

      const { data } = transition.to();

      let result = preferences.getPreference(data.redirectPreference);
      if (!$state.href(result, transition.params())) {
         result = undefined;
      }

      return result || data.redirectFallback;
   }

   const getRedirectPreferenceFromState = (state) => {
      for (let i = state.path.length - 2; i >= 0; i--) {
         if (state.data && state.data.redirectPreference) {
            return state.data.redirectPreference;
         }
      }
   };

   const setupExitHandler = ($transitions: any, preferences: IPreferenceStateService) => {
      $transitions.onSuccess({
         to: state => !!getRedirectPreferenceFromState(state)
      }, transition => {
         const to: angular.ui.IState = transition.$to();
         const preferenceName = getRedirectPreferenceFromState(to);
         preferences.setPreference(preferenceName, to.name);
      });
   };

   angular
      .module('app.core.routing')
      .run(setupExitHandler);
}