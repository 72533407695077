namespace app.core.security {
   const runner = (auth: security.IAuthorizationService) => {
      'ngInject';

      auth.login();
   };

   angular
      .module('app.core.security')
      .run(runner);
}