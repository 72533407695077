namespace app.systemicCases {
   'use strict';

   const routes: core.routing.IStateExtended[] = [
      {
         name: 'app.createSystemicCase',
         url: '/systemic-cases/create',
         template: '<ce-create-systemic-case layout="column" flex legalTeams="$resolve.legalTeams"></ce-create-systemic-case>',
         data: {
            title: 'Create Systemic Case',
            permissions: ['systemic-cases.edit']
         }
      },
      {
         name: 'app.systemicCaseListing',
         url: '/systemic-cases',
         template: '<ce-systemic-case-listing layout="column" flex users="$resolve.users"></ce-systemic-case-listing > ',
         data: {
            title: 'Systemic Case Listing',
            permissions: ['systemic-cases.view']
         },
         params: { users: null },
         resolve: {
            users: ['$stateParams', p => p.users]
         }
      },
      {
         name: 'app.systemicCases',
         url: '/systemic-cases/{systemicCaseId}',
         template: '<ce-systemic-case-view case="$resolve.case" layout="column" flex></ce-systemic-case-view>',
         redirectTo: 'app.systemicCases.overview',
         data: {
            title: 'Systemic Case View',
            permissions: ['systemic-cases.view']
         },
         resolve: { case: caseBasicInfoResolver }
      },
      {
         name: 'app.systemicCases.overview',
         url: '/overview',
         template: '<ce-systemic-case-overview case="$resolve.case" layout="column" flex></ce-systemic-case-overview>',
         data: {
            title: 'Systemic Case Overview',
            permissions: ['systemic-cases.view']
         }
      },
      {
         name: 'app.systemicCases.details',
         url: '/details',
         template: '<ce-systemic-case-details case="$resolve.case" layout="column" flex></ce-systemic-case-details>',
         data: {
            title: 'Systemic Case Overview',
            permissions: ['systemic-cases.view']
         }
      },
      {
         name: 'app.systemicCases.involvement',
         url: '/involvement',
         template: '<ce-involvement-case-listing involvement-id="$resolve.case.involvementId" flex></ce-involvement-case-listing>',
         data: { title: 'Systemic Case Involvement' }
      },
      {
         name: 'app.systemicCases.documents',
         url: '/documents',
         template: '<ce-documents-list involvement-id="$resolve.case.involvementId" source="Systemic Case" source-id="$resolve.case.id" layout="column" flex></ce-document-list>',
         data: {
            title: 'Systemic Case Documents',
            permissions: ['documents.view']
         }
      },
      {
         name: 'app.systemicCases.uploadDocuments',
         url: '/upload',
         params: {
            folderId: null
         },
         resolve: {
            folderId: ['$stateParams', p => p.folderId],
         },
         template: '<ce-upload-documents-activity layout flex folder-id="$resolve.folderId" involvement-id="$resolve.case.involvementId" source="Systemic Case" source-id="$resolve.case.id"></ce-upload-documents-activity>',
         data: { permissions: ['documents.edit'] }
      },
      {
         name: 'app.systemicCases.emails',
         url: '/emails',
         template: '<ui-view flex layout="column"></ui-view>',
         redirectTo: 'app.systemicCases.emails.listing',
         data: {
            title: 'Systemic Case Emails',
            permissions: ['mail.view']
         }
      },
      {
         name: 'app.systemicCases.emails.listing',
         url: '',
         template: '<ce-email-listing involvement-id="$resolve.case.involvementId" source="Systemic Case" source-id="$resolve.case.id" layout="column" flex></ce-email-listing>',
         data: {
            title: 'Systemic Case Emails',
            permissions: ['mail.view']
         }
      },
      {
         name: 'app.systemicCases.emails.message',
         url: '/{messageId}',
         template: '<ce-email-view layout="column" flex message-id="$resolve.messageId"></ce-email-view>',
         data: {
            title: 'Message',
            permissions: ['mail.view']
         },
         resolve: {
            messageId: ['$stateParams', p => p.messageId]
         }
      },
      {
         name: 'app.systemicCases.emails.link',
         url: '/link',
         template: '<ce-link-emails layout="column" flex involvement-id="$resolve.case.involvementId" source="Systemic Case" source-id="$resolve.case.id"></ce-link-emails>',
         data: {
            title: 'Inbox Messages',
            permissions: ['mail.link']
         }
      },
      {
         name: 'app.systemicCases.timeslipListing',
         url: '/timeslips',
         template: '<ce-timeslip-listing source="Systemic Case" source-name="$resolve.case.idNumber" source-name2="$resolve.case.name" source-id="$resolve.case.id" involvement-id="$resolve.case.involvementId" enable-filters="false" flex layout="column"></ce-timeslip-listing>',
         data: {
            title: 'Systemic Case Timeslips',
            permissions: ['timeslips.view']
         }
      },
      {
         name: 'app.systemicCases.timeslipCalendar',
         url: '/timeslips-calendar',
         template: '<ce-timeslip-calendar-frame source="Systemic Case" source-name="$resolve.case.idNumber" source-name2="$resolve.case.name" source-id="$resolve.case.id" involvement-id="$resolve.case.involvementId" enable-filters="false" flex layout="column"></ce-timeslip-calendar-frame>',
         data: {
            title: 'Systemic Case Timeslips',
            permissions: ['timeslips.view']
         }
      },
      {
         name: 'app.systemicCases.closure',
         url: '/closure',
         template: '<ce-systemic-case-closure case="$resolve.case" layout="column" flex></ce-systemic-case-closure>',
         data: {
            title: 'Systemic Case Closure',
            permissions: ['systemic-cases.view']
         }
      },
      {
         name: 'app.systemicCases.grievances',
         url: '/grievances',
         template: '<ce-grievance-listing case="$resolve.case" case-type="Systemic Case" flex></ce-grievance-listing>',
         data: {
            title: 'Systemic Case Grievances',
            permissions: ['grievances.view']
         }
      },
      {
         name: 'app.systemicCases.calendar',
         url: '/calendar',
         template: '<ce-involvements-calendar source="Systemic Case" source-id="$resolve.case.id" involvement-id="$resolve.case.involvementId" layout="column" flex></ce-involvements-calendar>',
         data: {
            title: 'Calendar',
         }
      },
      {
         name: 'app.systemicCases.eventSchedule',
         url: '/eventSchedule',
         template: `<ce-calendar-event-schedule
                     event-schedule-id="$resolve.eventScheduleId"
                     target-date="$resolve.targetDate"
                     source="Systemic Case"
                     source-name="$resolve.case.idNumber"
                     source-id="$resolve.case.id"
                     involvement-id="$resolve.case.involvementId"></ce-calendar-event-schedule>`,
         params: {
            eventScheduleId: null,
            targetDate: null
         },
         resolve: {
            eventScheduleId: ['$stateParams', p => p.eventScheduleId],
            targetDate: ['$stateParams', p => p.targetDate],
         },
         data: { title: 'Event Schedule', permissions: ['events.view'], }
      },
      {
         name: 'app.systemicCases.event',
         url: '/event',
         template: '<ce-calendar-event event="$resolve.event"></ce-calendar-event>',
         params: {
            event: null
         },
         resolve: {
            event: ['$stateParams', e => e.event]
         },
         data: {
            title: 'Event',
            permissions: ['events.view']
         }
      }
   ];

   const runner = (routingHelper: core.routing.IRoutingHelperService) => {
      'ngInject';
      routingHelper.configureRoutes(routes);
   };

   angular
      .module('app.systemicCases')
      .run(runner);
}