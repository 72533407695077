namespace app.funding {

   export interface ICAPSystemicIntakeModel extends ICAPSystemicIntake {
      livingArrangementsDisplayValue?: string[];
      problemAreasDisplayValue?: string[];
   }

   @Component('app.funding', 'ceCapSystemicIntakeInfo', {
      templateUrl: 'app/funding/enrollments/cap/systemic/cap-intake-info.html',
      bindings: {
         intake: '<',
         onEdit: '&',
         livingArrangements: '<',
         problemAreas: '<'
      }
   })
   class CAPSystemicIntakeInfo {
      public intake: ICAPSystemicIntakeModel;
      public livingArrangements: ILivingArrangement[];
      public problemAreas: IProblemArea[];

      constructor() {
         'ngInject';
      }

   }
}