namespace app.calendars {
   export type CalendarId = 'case' | 'frontdesk' | 'intake' | 'meeting' | 'personal' | 'training';

   export const CalendarId = {
      Case: 'case' as CalendarId,
      FrontDesk: 'frontdesk' as CalendarId,
      Intake: 'intake' as CalendarId,
      Meeting: 'meeting' as CalendarId,
      Personal: 'personal' as CalendarId,
      Training: 'training' as CalendarId
   };

   export interface ICalendarScope extends angular.IScope {
      calendarVm: ICalendarViewModel;
   }

   export interface ICalendarViewModel {
      selectedRange: moment.Range;
      visibleRange: moment.Range;
   }

   export interface ICalendar {
      id: CalendarId;
      title: string;
      color: string;
      completedColor?: string;
   }

   export interface IEvent extends fc.IEvent {
      eventScheduleId: string;
      eventDate: Date;
      startTime: Date;
      endTime: Date;
      rescheduledEventDate: Date;
      rescheduledStartTime: Date;
      rescheduledEndTime: Date;
      calendar: string;
      isCompleted: boolean;
   }

   export interface IRescheduleEvent {
      id: string;
      eventScheduleId: string;
      startTime: Date;
      endTime: Date;
      rescheduledStartTime: Date;
      rescheduledEndTime: Date;
   }

   export interface IDeleteEvent {
      id: string;
      eventScheduleId: string;
      startTime: Date;
      endTime: Date;
   }

   export interface IEventSummary {
      id: string;
      involvementId?: string;
      type: string;
      title: string;
      eventScheduleId: string;
      eventDate: Date;
      startTime: Date;
      endTime: Date;
      calendar: string;
      staff: IUserBasic[];
      sources: ISourceBasic[];
      frequency: string;
      summary: string;
      description: string;
      isCompleted: boolean;
      allDay: boolean;
   }

   export interface IEventSource extends fc.IEventSource {
      events: IEvent[];
   }

   export interface IGetEventsParams {
      calendars: string[];
      userIds: string[];
      from: string | Date;
      to: string | Date;
      highPriorityOnly: boolean;
   }

   export interface ISchedule {
      dayOfMonth?: number;
      dayOfWeek?: string;
      endDate?: Date;
      endTime?: Date;
      allDay: boolean;
      frequency?: string;
      interval: number;
      repeatBy?: string;
      skipInterval?: number;
      startDate: Date;
      startTime?: Date;
      summary?: string;
      weekdays: DayOfWeek[];
      weekNumber?: number;
   }

   export interface IEventSchedule {
      id: string;
      calendar: string;
      title: string;
      description?: string;
      highPriority: boolean;
      notificationAmount?: number;
      notificationUnit?: string;
      scheduleData: ISchedule;
   }

   export interface ISourceBasic {
      sourceId: string;
      source: string;
      idNumber: string;
      sourceName: string;
      involvementId: string;
   }
   export interface IUserBasic {
      userId: string;
      fullName: string;
   }
}