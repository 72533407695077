namespace app.reports {
   export interface IReportRepository {
      runReport(data: IRunReportData): angular.IPromise<any>;
   }

   const baseUrl = 'api/reports';

   class ReportRepository implements IReportRepository {
      constructor(private $http: angular.IHttpService) {
         'ngInject';
      }

      @ApiExecutor()
      public runReport(data: IRunReportData) {
         return this.$http.post(baseUrl, data, {
            responseType: 'arraybuffer'
         }) as any;
      }
   }

   angular
      .module('app.reports')
      .service('reports.repository', ReportRepository);
}