namespace app.filters {
   @Component('app.filters', 'ceTimeslipUsersFilter', {
      templateUrl: 'app/filters/timeslip-user/user-filter.html',
      bindings: {
         name: '@',
         onChange: '&',
         users: '<',
         selectedUsers: '<',
         enabled: '<',
         supervisedOnly: '<'
      }
   })
   class TimeslipUsersFilter {
      static $inject = ['common', 'datacontext'];
      constructor(
         private common: core.ICommonService,
         private datacontext: data.IDataContextService) {
         'ngInject';
      }

      private name: string;
      private searchText: string;
      private users: users.IUser[];
      private selectedUsers: users.IUser[];
      private onChange: ({ }) => {};
      private enabled: boolean;
      private supervisedOnly: boolean;

      private $onInit() {
         this.name = this.name || 'STAFF';
         this.users = this.users || [];
         this.selectedUsers = this.selectedUsers || [];
         if (this.users.length > 0) {
            this.changed();
         }
         else {
            this.addCurrentUser();
         }
      }

      public addCurrentUser(): angular.IPromise<users.IUser> {
         return this.datacontext.users.getCurrentUser().then(
            (u) => {
               const user = {
                  id: u.id,
                  firstName: u.firstName,
                  lastName: u.lastName,
                  fullName: u.fullName
               };

               const index = _.findIndex(this.users, user => user.id === u.id);
               if (index === -1) {
                  this.users.push(user);
                  this.addSelectedUser(user);
               }

               return user;
            }
         );
      }

      private toggleState() {
         this.enabled = !this.enabled;

         if (this.enabled) {
            angular.element('#user0').focus();
            if (this.users.length === 0) {
               this.addCurrentUser();
            }
         }

         this.changed();
      }

      private changed() {
         this.users = _.sortBy(this.users, ['lastName', 'firstName']);
         if (this.enabled) {
            this.onChange({ users: this.selectedUsers });
         }
         else {
            this.onChange({ users: [] });
         }
      }

      public addSelectedUser(user: users.IUser) {
         if (!user) return;
         let changed = false;

         this.searchText = null;
         let exists = _.find(this.users, u => u.id === user.id);
         if (!exists) {
            this.users.push(user);
            changed = true;
         }

         exists = _.find(this.selectedUsers, u => u.id === user.id);
         if (!exists) {
            this.selectedUsers.push(user);
            changed = true;
         }

         if (changed) {
            this.changed();
         }
      }

      public isUserToggled(user: users.IUser) {
         return this.selectedUsers.indexOf(user) > -1;
      }

      public toggleUser(user: users.IUser) {
         const exists = _.find(this.selectedUsers, u => u.id === user.id);
         if (exists) {
            _.remove(this.selectedUsers, u => u.id === user.id);
         }
         else {
            this.selectedUsers.push(user);
         }

         this.changed();
      }

      public removeSelectedUser(user: users.IUser) {
         _.remove(this.users, u => u.id === user.id);
         _.remove(this.selectedUsers, u => u.id === user.id);
         this.changed();
      }

      public search(searchText: string) {
         return this.datacontext.users.getTimeslipUsers(this.searchText);
      }
   }
}