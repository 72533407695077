namespace app.funding {
   @Component('app.funding', 'ceCapIAndROutcomeForm', {
      templateUrl: 'app/funding/enrollments/cap/i-and-r/cap-outcome-form.html',
      bindings: {
         outcome: '<',
         onCancel: '&',
         onSubmit: '&'
      }
   })
   class CAPIAndROutcomeForm {
      static $inject = ['common', 'datacontext'];
      constructor(
         private common: core.ICommonService,
         private datacontext: data.IDataContextService
      ) {
         'ngInject';
      }

      public interventionStrategies: app.config.ILookup[];
      public outcome: ICAPIAndROutcome;

      public otherDisabilityToggle() {
         this.outcome.OtherAchievement = !this.outcome.OtherAchievement;
      }
   }
}