namespace app.funding {
   @Component('app.funding', 'ceEditSecondaryFunding', {
      templateUrl: 'app/funding/secondary/edit/edit.html',
      bindings: {
         involvementId: '<',
         fundingProgramId: '<'
      }
   })
   class EditSecondaryFunding {
      static $inject = ['$mdDialog', 'common', 'datacontext'];
      constructor(
         private $mdDialog: angular.material.IDialogService,
         private common: core.ICommonService,
         private datacontext: data.IDataContextService) {
         'ngInject';
      }

      public fundingPrograms: IEnrollmentEligibilityView[];
      public fundingProgramId: string;
      public involvementId: string;
      public enrollment = {} as IEnrollment;

      public $onInit() {
         this.getEligibleFundingSources();
         this.enrollment.involvementId = this.involvementId;
      }

      private getEligibleFundingSources = () => {
         return this.datacontext.funding
            .getEnrollmentEligibilityView(this.involvementId)
            .then(result => {
               this.fundingPrograms = _.filter(result, r => {
                  // TODO: Add SecondaryFunding Flag to Funding.Program
                  return _.includes(['PADD', 'PAIMI', 'PAIR'], r.fundingProgramName);
               });
            });
      };

      public submit() {
         this.datacontext.funding.setSecondaryFundingSource(this.enrollment)
            .then(result => { this.common.$state.go('^.'); });
      }
   }
}