namespace app.training.training {
   @Component('app.training.training', 'ceTrainingRequestInformation', {
      templateUrl: 'app/training/request/details/request-information.html',
      bindings: {
         data: '<',
         onEdit: '&'
      }
   })
   class TrainingInformation {
   }
}