namespace app.utils {
   const inArrayFilter = ($filter: angular.IFilterService) => {
      'ngInject';

      return (list: any[], arrayFilter: any[], element: any) => {
         return $filter('filter')(list, (listItem: any) => {
            return arrayFilter && arrayFilter.indexOf(listItem[element]) !== -1;
         });
      };
   };

   angular
      .module('app.utils')
      .filter('inArray', inArrayFilter);
}