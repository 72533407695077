namespace app.entities.details {
   @Component('app.entities.details', 'ceEntityPhoneNumbers', {
      templateUrl: 'app/entities/details/phone-number/phone-numbers.html',
      bindings: {
         entityId: '<',
         defaultPhoneType: '@'
      }
   })
   class PhoneNumbers {
      static $inject = ['common', 'datacontext'];
      constructor(
         private common: core.ICommonService,
         private datacontext: data.IDataContextService) {
         'ngInject';
      }

      public editMode = false;
      public mouseOver: boolean = false;
      public entityId: string;
      public phoneNumbers: IEntityPhoneNumber[];
      public selectedPhoneNumber: IEntityPhoneNumber;
      public defaultPhoneNumberType: string;

      public deletePhoneNumber(phoneNumber: IEntityPhoneNumber) {
         this.selectedPhoneNumber = phoneNumber;
         this.common.confirmation.show({
            title: 'Delete Phone Number',
            message: 'Are you sure you want to delete this phone number?',
            ok: 'Delete',
            cancel: 'Keep'
         }).then(this.deletePhoneNumberCore);
      }

      public cancelEdit() {
         this.editMode = false;
         this.selectedPhoneNumber = null;
      }

      public addPhoneNumber() {
         let phoneNumber = {} as IEntityPhoneNumber;
         // phoneNumber.phoneNumberType = this.defaultPhoneNumberType;
         this.editPhoneNumber(phoneNumber);
      }

      public editPhoneNumber(phoneNumber: IEntityPhoneNumber) {
         this.selectedPhoneNumber = phoneNumber;
         this.editMode = true;
      }

      public savePhoneNumber(data: IEntityPhoneNumber) {
         const phoneNumber: common.IPhone = {
            phone: data.phone,
            extension: data.extension,
            formattedNumber: data.formattedPhoneNumber,
            allowsVoiceMail: data.allowsVoiceMail
         };

         const phone: any = {
            id: this.selectedPhoneNumber.id,
            entityId: this.entityId,
            phoneNumber: phoneNumber,
            phoneNumberType: data.phoneNumberType,
            isPrimary: data.isPrimary
         };

         const promise = this.selectedPhoneNumber.id
            ? this.datacontext.entities.updateEntityPhoneNumber(phone)
            : this.datacontext.entities.createEntityPhoneNumber(phone);

         if (data.isPrimary) {
            this.phoneNumbers.forEach(a => a.isPrimary = false);
         }

         promise.then(result => {
            if (result) {
               if (!this.selectedPhoneNumber.id) {
                  this.phoneNumbers.push(result);
               } else {
                  angular.extend(this.selectedPhoneNumber, result);
               }

               this.cancelEdit();
            }
         });
      }

      private $onInit() {
         // if (this.common.auth.checkPermission('person.phoneNumbers')) {
         this.getPhoneNumbers();
         // }
      }

      private deletePhoneNumberCore = () => {
         this.datacontext.entities.deleteEntityPhoneNumber(this.selectedPhoneNumber)
            .then(() => {
               const index = this.phoneNumbers.indexOf(this.selectedPhoneNumber);
               this.phoneNumbers.splice(index, 1);
               this.cancelEdit();
            });
      };

      private getPhoneNumbers = () => {
         this.datacontext.entities
            .getEntityPhoneNumbers(this.entityId)
            .then(result => this.phoneNumbers = result);
      };
   }
}