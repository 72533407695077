namespace app.analysis {
   @Component('app.analysis', 'aimsAnalysisBarChart', {
      template: `<flot dataset="$ctrl.dataset" options="$ctrl.options"></flot>`,
      bindings: {
         chartData: '<',
         showPercentages: '<',
         showTotal: '<'
      }
   })
   class BarChartComponent {
      static $inject = ['common', 'analysisData', 'analysisConstants'];
      constructor(
         private common: core.ICommonService,
         private analysisData: IAnalysisDataService,
         private analysisConstants: IAnalysisConstants) {
         'ngInject';
      }

      public chartData: IChartData[];
      public dataset: any[];
      public dataSeries: Series;
      public percentSeries: Series;
      public showPercentages: boolean;
      public showTotal: boolean;
      public total: number;

      public options = {
         series: {
            bars: {
               align: 'center',
               barWidth: 0.6,
               fill: 0.9,
               horizontal: true,
               lineWidth: 0,
               show: true
            }
         },
         grid: {
            borderColor: '#eee',
            borderWidth: 0,
            hoverable: true,
            backgroundColor: 'transparent',
            labelMargin: 25,
            margin: 10
         },
         tooltip: true,
         tooltipOpts: {
            content: (label, x, y, item) => {
               let tooltip = `${item.series.yaxis.ticks[item.series.yaxis.ticks.length - y - 1].label} : ${this.dataSeries[y][0].toFixed(0)}`;
               if (this.showPercentages) {
                  tooltip += `, ${this.percentSeries[y][0].toFixed(1)}%`;
               }
               return tooltip;
            },
            zIndex: 2000
         },
         xaxis: {
            autoscaleMargin: .1,
            tickColor: '#fcfcfc',
            tickDecimals: 0,
            max: null as number
         }
         ,
         yaxis: {
            tickColor: '#eee',
            ticks: null as Tick[]
         }
      };

      private $onChanges() {
         if (!this.chartData) return;

         const color = this.analysisConstants.colorPalette[0];
         this.dataSeries = this.analysisData.barSeries(this.chartData);
         const ticks = _.reverse(this.analysisData.ticks(this.chartData));

         if (this.showPercentages) {
            this.percentSeries = this.analysisData.barPercentageSeries(this.chartData);
         }

         if (this.showTotal) {
            this.total = this.analysisData.sum(this.chartData);
            this.dataSeries.push([this.total, this.dataSeries.length]);
            ticks.push([ticks.length, 'Total']);

            if (this.showPercentages) {
               this.percentSeries.push([100, this.percentSeries.length]);
            }
         }

         const dataset = [{ color, data: this.percentSeries || this.dataSeries }];
         this.common.$timeout(() => {
            this.dataset = dataset;
            this.options.yaxis.ticks = ticks;
         }, 50);
      }
   }
}