namespace app.documentManagement {
   @Controller('app.documentManagement', 'MoveDocumentDialogController')
   class MoveDocumentDialogController {
      static $inject = ['$mdDialog', 'datacontext', 'document'];
      constructor(
         private $mdDialog: angular.material.IDialogService,
         private datacontext: data.IDataContextService,
         private document: IDocumentFolder) {
         'ngInject';
      }

      public data = {} as IMoveDocumentData;
      public isSubmitting = false;

      public submit() {
         this.isSubmitting = true;
         this.datacontext.documentManagement
            .moveDocument(this.data)
            .then(() => this.$mdDialog.hide())
            .finally(() => this.isSubmitting = false);
      }

      private $onInit() {
         this.data.documentId = this.document.id;
      }

      public setSource(source: app.common.ISource) {
         this.data.source = source.source;
         this.data.sourceId = source.sourceId;
         this.data.involvementId = source.involvementId;
      }

      public cancel() {
         this.$mdDialog.cancel();
      }
   }
}