namespace app.time {
   const setupTransitionHandler = ($transitions: any, $mdDialog: angular.material.IDialogService, $q: angular.IQService) => {
      'ngInject';

      $transitions.onStart({}, transition => {
         const $injector = transition.injector();
         let timerService: ITimerService = $injector.get('timerService');
         const targetState: any = transition.targetState();
         const timerPromise = $q.defer();

         if (timerService.hours > 0 && moduleChange(transition.$to().name, transition.$from().name)) {
            askAboutTimeslip().then(
               buttonText => { processButtonText(buttonText); },
               () => { resetTimerAndNavigate(); })
            .catch((error) => { if (error) throw error; });

            return timerPromise.promise.catch(() => { transition.abort(); });
         }

         function moduleChange(fromState, toState) {
            // state is like 'app.clientcases.overview'
            let fromStateSplit = fromState.split('.');
            let toStateSplit = toState.split('.');

            if (fromStateSplit[1] !== toStateSplit[1]) {
               return true;
            }
            else {
               return false;
            }
         }

         function askAboutTimeslip() {
            return $mdDialog.show({
               fullscreen: true,
               templateUrl: 'app/utils/ternary-confirmation/ternary-confirmation.html',
               controller: 'TernaryConfirmationDialogController',
               controllerAs: '$ctrl',
               locals: {
                  title: 'Create Timeslip?',
                  message: 'You are leaving this page with a timer running. Do you want to create a timeslip first?',
                  submitButtonText: 'Yes/Enter',
                  submitButtonTooltip: 'Hit Enter to Create a Timeslip then leave',
                  middleButtonText: 'No/Esc',
                  middleButtonTooltip: 'Hit Esc to skip creating a timeslip, continue to the page you clicked on',
                  leftButtonText: 'Cancel',
                  leftButtonTooltip: 'Don\'t leave, stay on this page. You must open links in a new tab to keep the timer running'
               }
            });
         }

         function processButtonText(buttonText) {
            switch (buttonText) {
               case 'Yes/Enter': {
                  showTimeslipDialog();
                  break;
               }
               case 'No/Esc': {
                  resetTimerAndNavigate();
                  break;
               }
               default: {
                  stayOnCurrentPage();
                  break;
               }
            }
         }

         function showTimeslipDialog() {
            $mdDialog.show({
               fullscreen: true,
               templateUrl: 'app/time/timeslip-dialog/timeslip-dialog.html',
               controller: 'TimeslipDialogController',
               controllerAs: '$ctrl',
               locals: {
                  id: null,
                  involvementId: timerService.involvementId,
                  source: timerService.source,
                  sourceId: timerService.sourceId,
                  sourceName: timerService.sourceName,
                  sourceName2: timerService.sourceName2,
                  hours: timerService.hours,
                  date: moment().toDate(),
                  fundingProgramId: null
               }
            }).then(
               () => resetTimerAndNavigate(),
               () => stayOnCurrentPage())
               .catch((error) => { if (error) throw error; });
         }

         function stayOnCurrentPage() {
            timerPromise.reject();
         }

         function resetTimerAndNavigate() {
            timerService.killTimer();
            timerPromise.resolve();
         }
      });
   };

   angular
      .module('app.time')
      .run(setupTransitionHandler);
}