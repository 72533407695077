namespace app.data {
   const interceptorName = 'fingerprintInterceptor';

   const interceptor = ($q: angular.IQService, configurationProvider: IConfigurationProvider) => {
      'ngInject';
      return {
         'request': (request: any) => {
            if (request.url.match(/\app\/.*\.html$/i)) {
               request.url += '?v=' + configurationProvider.config.version;
            }
            return request;
         }
      };
   };

   const configure = ($httpProvider: angular.IHttpProvider) => {
      'ngInject';
      $httpProvider.interceptors.push(interceptorName);
   };

   angular
      .module('app.data')
      .factory(interceptorName, interceptor)
      .config(configure);
}