namespace app.persons.views {
   @Component('app.persons.views', 'ceClientInformation', {
      templateUrl: 'app/persons/views/client-information/client-information.html',
      bindings: {
         data: '<',
         paramPovertyLevel: '<',
         paramAtPoverty: '<',
         paramRaceSubCategory: '<',
         onEdit: '&'
      }
   })
   class ClientInformation {
      public paramPovertyLevel: boolean;
      public paramAtPoverty: boolean;
      public paramRaceSubCategory: boolean;
   }
}