namespace app.agencies.overview {
   @Component('app.agencies.overview', 'ceAgencyImportantInformation', {
      templateUrl: 'app/agencies/important/important.html',
      bindings: {
         agency: '<'
      }
   })
   class AgencyImportantInformation {
      static $inject = ['$mdDialog', 'common', 'datacontext'];
      constructor(
         private $mdDialog: angular.material.IDialogService,
         private common: core.ICommonService,
         private datacontext: data.IDataContextService) {
         'ngInject';
      }

      public agency: IAgency;
      public data: IAgencyImportantInformation;

      public $onInit() {
         this.datacontext.agencies.getImportantInformation(this.agency.id)
            .then(result => this.data = result);
      }
   }
}