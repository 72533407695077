namespace app.projects {
   @Component('app.projects.entities', 'ceEditProjectEntityRoles', {
      templateUrl: 'app/projects/entities/roles/edit-roles.html',
      bindings: {
         entity: '<',
         projectId: '<'
      }
   })
   class ProjectEntityRoles {
      static $inject = ['common', 'datacontext'];
      constructor(
         private common: core.ICommonService,
         private datacontext: data.IDataContextService) {
         'ngInject';
      }

      public roles: config.ILookup[];
      public selectedRoles: config.ILookup[] = [];
      public isSubmitting: boolean;
      public entity: app.entities.IEntityBasicInfo;
      public projectId: string;
      public projectEntity = {} as IProjectEntity;

      public $onInit = () => {
         this.getRoles().then(() => this.getExistingRoles());
      }

      private transformChip(chip) {
         if (angular.isObject(chip)) {
            return chip;
         }
         return { name: chip, type: 'new' };
      }

      private querySearch(query) {
         let results = query ? _.filter(this.roles, (r: any) => {
            return query === '*' || r.value.toLowerCase().indexOf(query) > -1;
         }) : [];
         return results;
      }

      private getRoles = () => {
         let lookupTypeId: string = '';

         if (this.entity.isUser) {
            lookupTypeId = 'project-user-role';
         } else {
            lookupTypeId = `project-${this.entity.entityType}-role`;
         }

         return this.datacontext.config.lookups.getLookupType(lookupTypeId).then(
            result => {
               this.roles = result.lookups;
            });
      }

      private getExistingRoles = () => {
         return this.datacontext.projects.getEntity(this.projectId, this.entity.entityId).then(
            (result) => {
               this.projectEntity = result;
               _.forEach(this.projectEntity.roles, (ieRole) => {
                  let existingLookup = _.find(this.roles, (role) => {
                     return role.value === ieRole;
                  });

                  if (existingLookup) {
                     this.selectedRoles.push(existingLookup);
                  }
                  else {
                     this.selectedRoles.push({ lookupTypeId: 'roles', isActive: false, value: ieRole } as app.config.ILookup);
                  }
               });
            });
      }

      private onSubmit = () => {
         this.isSubmitting = true;
         let roles: string[] = _.map(this.selectedRoles, 'value');

         angular.extend(this.projectEntity, {
            roles: roles
         });

         let promise: angular.IPromise<IProjectEntity>;
         promise = this.datacontext.projects.updateEntity(this.projectEntity);

         promise.finally(() => {
            this.isSubmitting = false;
            this.common.$mdToast.showSimple('Entity Updated');
            this.common.$state.go('^.listing');
         });
      }
   }
}