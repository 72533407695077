namespace app.clientCases.closure {
   @Component('app.clientCases.closure', 'ceSurveyInformationForm', {
      templateUrl: 'app/client-cases/closure/survey-information/survey-information-form.html',
      bindings: {
         caseId: '<',
         involvementId: '<',
         onCancel: '&',
         onSubmit: '&'
      }
   })
   class SurveyInformationForm {
      static $inject = ['datacontext', 'common'];
      constructor(
         private datacontext: data.IDataContextService,
         private common: core.ICommonService) {
         'ngInject';
      }
      public onSubmit: (args: { result: IClientCaseSurveyInformation }) => void;
      public data = {} as IClientCaseSurveyInformation;
      public entities: involvements.IEntityBasicInfo[];
      public isLoading: boolean = false;
      public isSubmitting: boolean = false;
      public caseId: string;
      public involvementId: string;
      public phoneNumber: common.IPhone;
      public phoneNumbers: app.entities.IEntityPhoneNumber[];

      public $onInit() {
         this.getSurvey()
            .then(() => this.getEntities())
            .then(() => this.getPhoneNumbers());
      }

      public getEntities() {
         return this.datacontext.involvements.getEntitiesBasic(this.involvementId)
            .then(entities => {
               this.entities = entities;
            });
      }

      private getPhoneNumbers = () => {
         if (this.data.entityId) {
            this.datacontext.entities
               .getEntityPhoneNumbers(this.data.entityId)
               .then(result => this.phoneNumbers = result);
         }
      };

      public createPhoneNumber(phone: common.IPhone): angular.IPromise<app.entities.IEntityPhoneNumber> {
         const phoneNumber: any = {
            entityId: this.data.entityId,
            phoneNumber: {
               phone: this.phoneNumber.phone,
               extension: this.phoneNumber.extension,
               allowsVoiceMail: true
            },
            isPrimary: true
         };

         return this.datacontext.entities.createEntityPhoneNumber(phoneNumber);
      }

      public loadPhoneNumbers() {
         this.data.phoneNumberId = null;
         this.getPhoneNumbers();
      }

      public phoneChanged(phone: common.IPhone) {
         this.phoneNumber = phone;
      }

      public getSurvey() {
         this.isLoading = true;
         return this.datacontext.clientCases
            .getSurveyInformation(this.caseId)
            .then(result => this.data = result)
            .finally(() => this.isLoading = false);
      }

      public submit() {
         this.isSubmitting = true;
         this.data.id = this.caseId;

         if (this.data.shouldConduct === false) {
            this.data.entityId = null;
            this.data.phoneNumberId = null;
            this.data.accommodations = null;
         } else {
            this.data.comment = null;
         }

         let promise = this.common.$q.when<string>('');

         if (this.data.phoneNumberId === 'create') {
            promise = this.createPhoneNumber(this.phoneNumber).then(phone => this.data.phoneNumberId = phone.id);
         }

         promise.then(() => this.datacontext.clientCases
            .updateSurveyInformation(this.data)
            .then(result => {
               if (result) this.onSubmit({ result });
            })).finally(() => this.isSubmitting = false);
      }
   }
}