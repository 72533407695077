namespace app.involvements {
   @Component('app.involvements', 'ceInvolvementEntityListing', {
      template: `<md-content>
      <md-card>
        <md-toolbar>
          <div class="md-toolbar-tools">
            <span flex>Entity Listing</span>
            <md-button class="md-icon-button" ui-sref="^.add">
              <md-tooltip>Add Entity</md-tooltip>
              <md-icon>add</md-icon>
            </md-button>
          </div>
        </md-toolbar>
        <md-divider></md-divider>
        <md-card-content layout="row" class="p0">
          <md-divider ng-show="$ctrl.showFilters"></md-divider>
          <ce-entity-list entities="$ctrl.entities"
                          involvement-id="$ctrl.involvementId"
                          on-delete="$ctrl.deleteEntity(entity)"
                          flex></ce-entity-list>
        </md-card-content>
      </md-card>
      </md-content>`,
      bindings: {
         involvementId: '<'
      }
   })
   class EntityListing {
      static $inject = ['$mdDialog', 'common', 'datacontext'];
      constructor(
         private $mdDialog: angular.material.IDialogService,
         private common: core.ICommonService,
         private datacontext: data.IDataContextService) {
         'ngInject';
      }

      public entities: IEntityListing[];
      public entity: IEntityBasicInfo;
      public involvementId: string;

      private $onInit = () => {
         this.getEntities();
      }

      private getEntities = () => {
         return this.datacontext.involvements
            .getEntityListing(this.involvementId)
            .then(result => {
               this.entities = result;
            });
      };

      public deleteEntity(entity: IEntityListing) {
         this.common.confirmation.show({
            title: 'Delete Relationship',
            message: 'Are you sure you want to delete this entity relationship?',
            ok: 'Delete',
            cancel: 'Keep'
         }).then(() => {
            this.datacontext.involvements
               .deleteEntity(entity.id)
               .then(() => {
                  this.getEntities();
                  this.common.$rootScope.$broadcast('refreshBasicInfo');
               });
         }).catch(angular.noop);
      }
   }
}