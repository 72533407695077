namespace app.calendars {
   export interface ICalendarState {
      dateRange: moment.Range;
      viewType: string;
      viewDate: moment.Moment;
      calendarIds: CalendarId[];
      calendars: ICalendar[];
      userIds: string[];
      users: users.IUser[];
      showCompleted: boolean;
   }

   export interface ICalendarStateService {
      state: ICalendarState;
      save();
      update(state: any);
   }

   class CalendarStateService implements ICalendarStateService {
      constructor(private $localStorage: angular.storage.IStorageService) {
         'ngInject';

         if ($localStorage.calendarState) {
            delete $localStorage.calendarState.dateRange;
            delete $localStorage.calendarState.viewType;
            delete $localStorage.calendarState.viewDate;
         }

         this.loadState();
      }

      public state = { calendarIds: [], calendars: [], userIds: [], users: [] } as ICalendarState;

      public save() {
         this.$localStorage['calendarState'] = this.state;
      }

      public update(state) {
         angular.extend(this.state, state);
         angular.extend(this.$localStorage['calendarState'], state);
      }

      private loadState = () => {
         if (this.$localStorage['calendarState']) {
            angular.extend(this.state, this.$localStorage['calendarState']);
         } else {
            this.$localStorage['calendarState'] = this.state;
         }

         if (this.state.dateRange) {
            this.state.dateRange = moment.range(this.state.dateRange.start, this.state.dateRange.end);
         }
      }
   }

   angular
      .module('app.calendars')
      .service('calendarState', CalendarStateService);
}