namespace app.involvements {
   @Component('app.involvements', 'ceInvolvementCaseListing', {
      templateUrl: 'app/involvements/case-listing/case-listing.html',
      bindings: {
         involvementId: '<'
      }
   })
   class CaseListing {
      static $inject = ['$mdDialog', 'common', 'datacontext'];
      constructor(
         private $mdDialog: angular.material.IDialogService,
         private common: core.ICommonService,
         private datacontext: data.IDataContextService) {
         'ngInject';
      }

      public cases: ICaseListing[];
      public involvementId: string;
      public entityId: string;

      public $onInit = () => {
         this.getCases();
      }

      private getCases = () => {
         return this.datacontext.involvements
            .getCaseListing(this.involvementId)
            .then(result => {
               const cases: any = result;
               this.cases = cases;
            });
      };
   }
}