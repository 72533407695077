namespace app.funding {
   @Component('app.funding', 'cePaimiOutcomeForm', {
      templateUrl: 'app/funding/enrollments/paimi/paimi-outcome-form.html',
      bindings: {
         outcome: '<',
         onCancel: '&',
         onSubmit: '&'
      }
   })
   class PAIMIOutcomeForm {
      static $inject = ['common', 'datacontext'];
      constructor(
         private common: core.ICommonService,
         private datacontext: data.IDataContextService) {
         'ngInject';
      }
      public enrollmentId: string;
      public outcome: IPAIMIOutcome;
      public problemAndInterventionOptions: IPAIMIProblemAndInterventionOption[];
      public CaseOutcomeOptions: IPAIMIOption[];

      public $onInit() {
         this.getPAIMIOptions();
      }

      private getPAIMIOptions() {
         this.datacontext.funding.getPAIMIProblemAndInterventionOptions()
            .then(results => this.problemAndInterventionOptions = results);

         this.datacontext.funding.getPAIMICaseOutcomeOptions()
            .then(results => this.CaseOutcomeOptions = results);
      }

      public problemAndInterventionChanged(problem: IPAIMIProblemAndInterventionOption) {
         const index = _.findIndex(this.outcome.problemsAndInterventions, p => p.problemId === problem.id);
         if (index === -1) {
            this.outcome.problemsAndInterventions.push({ problemId: problem.id, group: problem.group, problemText: problem.text, outcomeText: problem.text, outcomes: [] });
         }
         else {
            _.remove(this.outcome.problemsAndInterventions, p => p.problemId === problem.id);
         }
      }

      public problemAndInterventionSelected(problemId: number) {
         return _.findIndex(this.outcome.problemsAndInterventions, p => p.problemId === problemId) > -1;
      }

      public outcomeChanged(problemId: number, outcome: IPAIMIOption) {
         const p = _.find(this.outcome.problemsAndInterventions, p => p.problemId === problemId);
         const o = _.find(p.outcomes, o => o.outcomeId === outcome.id);
         if (o == null) {
            p.outcomes.push({ outcomeId: outcome.id, outcomeText: outcome.text });
         }
         else {
            _.pull(p.outcomes, o);
         }
      }

      public outcomeSelected(problemId: number, outcomeId: number) {
         const p = _.find(this.outcome.problemsAndInterventions, p => p.problemId === problemId);
         const o = _.findIndex(p.outcomes, o => o.outcomeId === outcomeId);
         return o > -1;
      }

      public dispositionSelected(problemId: number, disposition: IPAIMIOption) {
         const p = _.find(this.outcome.problemsAndInterventions, p => p.problemId === problemId);
         p.dispositionId = disposition.id;
         p.dispositionText = disposition.text;
      }

      public getProblem(problemId: number) {
         const p = _.find(this.outcome.problemsAndInterventions, p => p.problemId === problemId);
         return p;
      }

      public problemAreasOtherSelected() {
         const otherProblem = _.find(this.problemAndInterventionOptions, p => p.text === 'Other');
         const index = _.findIndex(this.outcome.problemsAndInterventions, p => p.problemId === otherProblem.id);
         return index > -1;
      }
   }
}