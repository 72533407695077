namespace app.core.security {
   interface IScope extends angular.IScope {
      feature: Feature;
   }

   function featureDirective(auth: IAuthorizationService) {
      'ngInject';
      return {
         restrict: 'A',
         link: (scope: IScope, elem: angular.IAugmentedJQuery, attrs: any) => {
            if (!auth.checkFeature(attrs.amFeature)) {
               elem.hide();
            }
         }
      };
   }

   angular
      .module('app.core.security')
      .directive('amFeature', featureDirective);
}