namespace app.admin.configuration {
   @Component('app.admin.configuration', 'aimsAdminLookupTypeDetails', {
      templateUrl: 'app/admin/modules/configuration/views/lookup-type-details/lookup-type-details.html',
      bindings: {
         lookupType: '<'
      }
   })
   class LookupTypeDetails {
      static $inject = ['common', 'datacontext'];
      constructor(
         private common: core.ICommonService,
         private datacontext: data.IDataContextService) {
         'ngInject';
      }

      public isSubmitting = false;
      public lookupType: ILookupType;

      public addLookup() {
         this.lookupType.lookups.push({ isActive: true } as ILookup);
      }

      public removeLookup(index: number) {
         this.lookupType.lookups.splice(index, 1);
      }

      public submit() {
         this.isSubmitting = true;
         this.datacontext.admin.configuration
            .updateLookupType(this.lookupType.id, this.lookupType)
            .then(result => {
               if (result) {
                  this.common.$mdToast.showSimple('Lookup type updated');
                  this.common.$state.go('^.lookupTypeList');
               }
            }).finally(() => this.isSubmitting = false);
      }

      private $onChanges() {
         this.common.setTitle(`${this.lookupType.id} (Lookups)`);

         if (!this.lookupType.lookups.length) {
            this.addLookup();
         }
      }
   }
}