namespace app.systemicCases.overview {
   @Component('app.systemicCases.overview', 'ceSystemicCaseSummary', {
      templateUrl: 'app/systemic-cases/summary/summary.html',
      bindings: {
         case: '<'
      }
   })
   class SystemicCaseSummary {
      static $inject = ['$mdDialog', 'common', 'datacontext'];
      constructor(
         private $mdDialog: angular.material.IDialogService,
         private common: core.ICommonService,
         private datacontext: data.IDataContextService) {
         'ngInject';
      }

      public caseSummary: ISystemicCaseSummaryView;
      public case: ISystemicCaseBasicInfoView;
      private openDuration: string;
      private closeDuration: string;

      public $onInit() {
         this.datacontext.systemicCases.getSystemicCaseSummaryView(this.case.id)
            .then(result => {
               let data: any = result;
               this.caseSummary = data;
            });
      }

      public init() {
      }
   }
}