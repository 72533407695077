namespace app.persons {
   const routes: core.routing.IStateExtended[] = [
      {
         name: 'app.person',
         url: '/persons/{personId}',
         template: '<ce-person-view layout="column" flex person="$resolve.person"></ce-person-view>',
         redirectTo: 'app.person.overview',
         resolve: {
            person: personResolver
         }
      },
      {
         name: 'app.person.overview',
         url: '/overview',
         template: '<ce-person-overview-view person="$resolve.person" layout="column" flex></ce-person-overview-view>',
         data: { title: 'Person Overview' }
      },
      {
         name: 'app.person.cases',
         url: '/cases',
         template: '<ce-entity-case-listing entity-id="$resolve.person.id" layout="column" flex></ce-entity-case-listing>',
         data: { title: 'Person Involvements' }
      },
      {
         name: 'app.person.activities',
         url: '/activities',
         template: '<ui-view layout flex></ui-view>',
         redirectTo: 'app.person.activities.list'
      },
      {
         name: 'app.person.activities.list',
         url: '',
         template: '<ce-entity-activities entity-id="$resolve.person.id" layout="column" flex></ce-entity-activities>',
         data: {
            title: 'Person Activities',
            permissions: ['access_activities']
         }
      },
      {
         name: 'app.person.activities.createRequest',
         url: '/createRequest',
         template: `<ce-requests-create-request layout="column" flex
                        entity="$resolve.person"
                        entity-type="Person">
                    </ce-requests-create-request>`,
         data: {
            title: 'Create Request',
            permissions: ['requests.edit']
         }
      },
      {
         name: 'app.person.activities.createInformationAndReferral',
         url: '/createInformationAndReferral',
         template: `<ce-create-information-and-referral layout="column" flex
                        entity="$resolve.person"
                        entity-type="Person">
                    </ce-create-information-and-referral>`,
         data: {
            title: 'Create I&R',
            permissions: ['i-and-rs.edit']
         }
      },
      {
         name: 'app.person.activities.createClientCase',
         url: '/createclientcase',
         template: `<ce-create-client-case layout="column" flex
                        entity="$resolve.person"
                        entity-type="Person">
                    </ce-create-client-case>`,
         data: {
            title: 'Create Client Case',
            permissions: ['client-cases.edit']
         }
      },
      {
         name: 'app.person.details',
         url: '/details',
         template: '<ce-person-details-view person="$resolve.person" layout="column" flex></ce-person-details-view>',
         redirectTo: 'app.person.details.contactInfo'
      },
      {
         name: 'app.person.details.contactInfo',
         url: '/contact-info',
         template: '<ce-person-contact-info person="$resolve.person" layout="column" flex></ce-person-contact-info>',
         data: { title: 'Contact Info' }
      },
      {
         name: 'app.person.details.demographics',
         url: '/demographics',
         template: `<ce-person-demographics layout="column" flex
                   person="$resolve.person"
                   param-poverty-level="$resolve.paramPovertyLevel"
                   param-at-poverty="$resolve.paramAtPoverty"
                   param-race-Sub-Category="$resolve.paramRaceSubCategory"></ce-person-demographics>`,
         data: { title: 'Demographics' },
         resolve: {
            paramPovertyLevel: showPovertyLevelResolver,
            paramAtPoverty: showAtPovertyLevelResolver,
            paramRaceSubCategory: showRaceSubCategoryResolver
         }
      },
      {
         name: 'app.person.details.specialConsiderations',
         url: '/specialConsiderations',
         template: '<ce-person-special-considerations person="$resolve.person" layout="column" flex></ce-person-special-considerations>',
         data: { title: 'Special Considerations' }
      },
      {
         name: 'app.person.entities',
         url: '/entities',
         template: '<ui-view flex></ui-view>',
         redirectTo: 'app.person.entities.listing',
         data: { title: 'Entities' }
      },
      {
         name: 'app.person.entities.listing',
         url: '',
         template: '<ce-related-entities entity-id="$resolve.person.id" layout="column" flex></ce-related-entities>',
         data: { title: 'Entities' }
      },
      {
         name: 'app.person.entities.add',
         url: '/add',
         template: '<ce-select-entity-type flex></ce-select-entity-type>',
         redirectTo: 'app.person.entities.add.type',
         data: { title: 'Add' }
      },
      {
         name: 'app.person.entities.add.type',
         url: '/types',
         template: '<ce-entity-types flex></ce-entity-types>'
      },
      {
         name: 'app.person.entities.add.person',
         url: '/person',
         template: '<ui-view layout flex></ui-view>',
         redirectTo: 'app.person.entities.add.person.search',
      },
      {
         name: 'app.person.entities.add.person.search',
         url: '/search',
         template: '<ce-person-search flex></ce-person-search>',
         data: { title: 'Find Person' }
      },
      {
         name: 'app.person.entities.add.person.create',
         url: '/create',
         template: '<ce-create-person flex></ce-create-person>',
         data: { title: 'Create Person' }
      },
      {
         name: 'app.person.entities.add.person.roles',
         url: '/roles',
         params: { entity: null },
         resolve: { entity: ['$stateParams', p => p.entity] },
         template: '<ce-entity-relationships person="$resolve.person" related-entity="$resolve.entity" layout="column" flex></ce-entity-relationships>',
         data: { title: 'Select Relationships' }
      },
      {
         name: 'app.person.entities.add.agency',
         url: '/agency',
         template: '<ui-view layout flex></ui-view>',
         redirectTo: 'app.person.entities.add.agency.search',
      },
      {
         name: 'app.person.entities.add.agency.search',
         url: '/search',
         template: '<ce-agency-search flex></ce-agenyc-search>',
         data: { title: 'Find Agency' }
      },
      {
         name: 'app.person.entities.add.agency.create',
         url: '/create',
         template: '<ce-create-agency flex></ce-create-agency>',
         data: { title: 'Create Agency' }
      },
      {
         name: 'app.person.entities.add.agency.roles',
         url: '/roles',
         params: { entity: null },
         resolve: { entity: ['$stateParams', p => p.entity] },
         template: '<ce-entity-relationships person="$resolve.person" related-entity="$resolve.entity" layout="column" flex></ce-entity-relationships>',
         data: { title: 'Select Relationships' }
      },
      {
         name: 'app.person.entities.edit',
         url: '/edit',
         params: { association: null },
         resolve: { association: ['$stateParams', p => p.association] },
         template: '<ce-edit-entity-relationships association="$resolve.association" layout="column" flex></ce-edit-entity-relationships>',
         data: { title: 'Edit' }
      },
      {
         name: 'app.person.grievances',
         url: '/grievances',
         template: '<ce-grievance-listing person-id="$resolve.person.id" flex></ce-grievance-listing>',
         data: {
            title: 'Person Grievances',
            permissions: ['grievances.view']
         }
      }
   ];

   const runner = (routingHelper: core.routing.IRoutingHelperService) => {
      'ngInject';
      routingHelper.configureRoutes(routes);
   };

   angular
      .module('app.persons')
      .run(runner);
}