namespace app.analysis {
   @Component('app.analysis', 'aimsAnalysisQueryDetails', {
      templateUrl: 'app/analysis/views/query-details/query-details.html',
      bindings: {
         query: '<'
      }
   })
   class QueryDetails {
      static $inject = ['$state'];
      constructor(public $state: angular.ui.IStateService) {
         'ngInject';
      }

      public currentTab: string;

      private $onInit() {
         this.currentTab = this.$state.current.name.split('.')[3];
      }
   }
}